import React from 'react';

import { FormHeader } from '@/components';
import FormLogo from '@/components/FormLogo';
import GuestFormCard from '@/components/GuestFormCard';

import './styles.scss';

const ThankPageProviderForm = () => (
  <div className='thank-page-container'>
    <FormHeader />
    <FormLogo />
    <GuestFormCard>
      <div className='form-content-container'>
        <h2 className='title'>Thank you for Submitting</h2>
        <p className='sub-title'>
          Thank you for submitting. The team will be reviewing the information
          you have provided and you will receive an email when your account is
          open.
        </p>
      </div>
    </GuestFormCard>
  </div>
);

export default ThankPageProviderForm;
