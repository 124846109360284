import React, { useMemo } from 'react';

import { Form } from 'antd';
import { get, isNil, orderBy, round, sum, sumBy, toNumber } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import ListView from '@/components/ListView';
import {
  SICK_NO_SHOW_LABEL,
  TYPES_SICK_NO_SHOW,
} from '@/pages/Payroll/constants';
import { DAYS_OF_WEEK } from '@/pages/Timesheet/Invoice/constants';
import { time } from '@/utils';
import Formatter from '@/utils/Formatter';

import './styles.scss';

const formatMinutes = (mins) =>
  moment.utc(moment.duration(mins, 'minutes').asMilliseconds()).format('HH:mm');

const ListTimesheets = ({
  tableColor,
  infoDetail,
  largeHeight,
  jobType,
  customizedColumns,
  status,
  loading,
  dateRange,
}) => {
  const [form] = Form.useForm();

  const DAYSNAME = Object.fromEntries(
    dateRange.map((date, index) => [
      index,
      `${
        DAYS_OF_WEEK[moment(date).format('ddd').toLowerCase()]
      } ${jobType} ${status}`,
    ]),
  );

  const totalCharge = round(
    sum((infoDetail ?? []).map((record) => toNumber(record?.total_charge))),
    2,
  );

  const totalHours = sumBy(infoDetail, 'total');

  const renderCell = (record, index) => {
    const pushFromTimesheetId = record?.push_from_timesheet_id ?? false;
    const isPushed = pushFromTimesheetId ? 'pushed ' : '';
    const key = `${isPushed}${DAYSNAME[index]}`;
    const hours = record?.[key] ?? [];

    // eslint-disable-next-line arrow-body-style
    return hours?.map((item) => {
      const { noShow: /** @type {Object} */ noShowInfo } = item;
      const type = get(noShowInfo, 'no_show_type') ?? 'hide';
      return (
        <div className='cell-container'>
          <p>{item?.checkin.format('HH:mm')}</p>
          <p>{item?.checkout.format('HH:mm')}</p>
          <p>{formatMinutes(item?.break)}</p>
          {!isNil(noShowInfo) && (
            <span className={`tag-cell ${TYPES_SICK_NO_SHOW[type]}`}>
              {SICK_NO_SHOW_LABEL[type]}
            </span>
          )}
        </div>
      );
    });
  };

  const renderDay = () =>
    dateRange.map((day, index) => ({
      title: (
        <div>
          <div>{moment(day).format('ddd')}</div>
          <p>{moment(day).format('DD/MM/YYYY')}</p>
          <div>Start/Finish</div>
          <div>/Break</div>
        </div>
      ),
      dataIndex: DAYS_OF_WEEK[moment(day).format('ddd')],
      width: '6.4',
      render: (_, record) => renderCell(record, index),
    }));

  const columns = [
    {
      title: (
        <div>
          <p>{jobType}</p>
          <div>Names</div>
        </div>
      ),
      dataIndex: 'name',
      width: '15%',
    },
    ...renderDay(),
    {
      title: 'Charge Per hour',
      dataIndex: 'charge_rate',
      width: '6.4',
      render: (cellData) => (
        <span className='total-charge'>{Formatter.currencyGBP(cellData)}</span>
      ),
    },
    {
      title: 'Total Weekly hours',
      dataIndex: 'total',
      width: '6.4',
      render: (total) => <span>{time.convertTimeHoursCell(total)}</span>,
    },
    {
      title: 'Total Charge',
      dataIndex: 'total_charge',
      width: '6.4',
      render: (cellData) => (
        <span className='total-charge'>{Formatter.currencyGBP(cellData)}</span>
      ),
    },
  ];

  const TableButton = () =>
    useMemo(
      () => (
        <div className='table-footer-timesheets'>
          <div className='job-type'>
            {jobType} Totals: {infoDetail.length}
          </div>
          <div className='total-pay'>
            Total Hours: {time.convertTimeHoursCell(totalHours)}
          </div>
          <div className='total-charge'>
            Total Charge {Formatter.currencyGBP(totalCharge)}
          </div>
        </div>
      ),
      [],
    );

  return (
    <div className='list-timesheets'>
      <Form form={form} className='form-list-timesheets'>
        <ListView
          bordered
          headerColumns={customizedColumns ?? columns}
          infoDetail={orderBy(infoDetail, 'name')}
          rowClassName='editable-row'
          className={`list-timesheets-table ${tableColor}`}
          pagination={false}
          footer={TableButton}
          loading={loading}
        />
      </Form>
    </div>
  );
};

ListTimesheets.propTypes = {
  jobType: PropTypes.string,
  infoDetail: PropTypes.array,
  tableColor: PropTypes.string,
  status: PropTypes.string,
  largeHeight: PropTypes.bool,
  customizedColumns: PropTypes.array,
  loading: PropTypes.bool,
  dateRange: PropTypes.array,
};

ListTimesheets.defaultProps = {
  jobType: '',
  largeHeight: false,
  infoDetail: [],
  tableColor: '',
  customizedColumns: null,
  status: '',
  loading: false,
  dateRange: [],
};

export default ListTimesheets;
