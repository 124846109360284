import React from 'react';

import { range } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Input, FormItem, DatePicker, TimePicker } from '@/components';
import { disabledDateOf } from '@/utils';

const END_OF_DAY = 24;
const END_OF_MINS = 60;

const ShiftInput = ({
  action,
  name,
  value,
  required,
  label,
  dateTime,
  duration,
  onStartDateChange,
  onStartTimeChange,
  onEndDateChange,
  onEndTimeChange,
  isEditable,
  isDisableTime,
  fixedStartDate,
  fixedEndDate,
  isInListView,
}) => {
  const disabledDate = (date) => {
    const fixedEndDateWeek = fixedEndDate && moment(fixedEndDate).week();
    const currentWeekNumber = moment().week();

    if (isInListView) {
      return date > moment.now();
    }

    if (currentWeekNumber === fixedEndDateWeek) {
      return (
        disabledDateOf.Start(date, moment()) ||
        disabledDateOf.End(date, moment(fixedStartDate))
      );
    }

    if (fixedStartDate && fixedEndDate) {
      return (
        disabledDateOf.Start(date, moment(fixedEndDate)) ||
        disabledDateOf.End(date, moment(fixedStartDate))
      );
    }
    return false;
  };

  const disabledStartTime = (now) => {
    const currentHours = now.hours();
    const { startTime } = dateTime;
    const isToday = now
      .clone()
      .startOf('day')
      .isSame(startTime.clone().startOf('day'));
    return {
      disabledHours: () => (isToday ? range(currentHours + 1, END_OF_DAY) : []),
      disabledMinutes: (selectedHour) => {
        const currentMinutes = now.minutes();
        const disabledRange =
          selectedHour === currentHours && isToday
            ? range(currentMinutes + 1, END_OF_MINS)
            : [];

        return disabledRange;
      },
    };
  };

  const disabledEndTime = (now) => {
    const currentHours = now.hours();
    const { endTime } = dateTime;
    const isToday = now
      .clone()
      .startOf('day')
      .isSame(endTime.clone().startOf('day'));

    return {
      disabledHours: () => isToday && range(currentHours + 1, END_OF_DAY),
      disabledMinutes: (selectedHour) => {
        const currentMins = now.minutes();

        const disabledRange =
          selectedHour === currentHours &&
          isToday &&
          range(currentMins + 1, END_OF_MINS);

        return disabledRange;
      },
    };
  };

  return (
    <div className='job-datetime'>
      <FormItem
        name={name.startDate}
        className='date-picker-style'
        label={label.startDate}
        required={required}
      >
        <DatePicker
          onChange={onStartDateChange}
          format='DD MMM'
          value={value.startDate}
          name={name.startDate}
          disabled={!isEditable || isDisableTime}
          disabledDate={disabledDate}
        />
      </FormItem>
      <FormItem
        name={name.startTime}
        label={label.startTime}
        className='date-picker-style'
        required={required}
      >
        <TimePicker
          value={moment(value?.startTime, 'HH:mm')}
          disabled={!isEditable || isDisableTime}
          onChange={(time, timeString) => onStartTimeChange(time, timeString)}
          name={name?.startTime}
          format='HH:mm'
          {...(isInListView && { disabledTime: disabledStartTime })}
        />
      </FormItem>
      <FormItem
        name={name.endDate}
        required={required}
        className='date-picker-style'
        label={label.endDate}
      >
        <DatePicker
          value={value.endDate}
          format='DD MMM'
          onChange={onEndDateChange}
          name={name.endDate}
          disabled={!isEditable || isDisableTime}
          disabledDate={disabledDate}
        />
      </FormItem>
      <FormItem
        name={name.endTime}
        label={label.endTime}
        className='date-picker-style'
        required={required}
      >
        <TimePicker
          value={moment(value?.endTime, 'HH:mm')}
          disabled={!isEditable || isDisableTime}
          onChange={(time, timeString) => onEndTimeChange(time, timeString)}
          name={name?.endTime}
          format='HH:mm'
          {...(isInListView && { disabledTime: disabledEndTime })}
        />
      </FormItem>

      {duration && (
        <FormItem name={name.duration} className='date-picker-style'>
          <Input disabled />
        </FormItem>
      )}
      {action()}
    </div>
  );
};

ShiftInput.propTypes = {
  action: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onStartTimeChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onEndTimeChange: PropTypes.func,
  name: PropTypes.object,
  value: PropTypes.object,
  dateTime: PropTypes.object,
  required: PropTypes.bool,
  duration: PropTypes.bool,
  label: PropTypes.object,
  isEditable: PropTypes.bool,
  isDisableTime: PropTypes.bool,
  fixedStartDate: PropTypes.string,
  fixedEndDate: PropTypes.string,
  isInListView: PropTypes.bool,
};

ShiftInput.defaultProps = {
  action: () => {},
  onStartDateChange: () => {},
  onStartTimeChange: () => {},
  onEndDateChange: () => {},
  onEndTimeChange: () => {},
  name: {},
  label: {},
  value: {},
  dateTime: {},
  required: false,
  duration: false,
  isEditable: false,
  isInListView: false,
  isDisableTime: false,
};

export default ShiftInput;
