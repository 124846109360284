import React from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Tabs } from '@/components';

import './styles.scss';

const tabPanes = [
  {
    key: '/costs',
    tab: 'Costs',
  },
  {
    key: '/suppliers',
    tab: 'Suppliers',
  },
];

const CostAndSupplierTab = ({ defaultActiveKey }) => {
  const history = useHistory();

  const handleTabChange = (activeKey) => history.push(activeKey);

  return (
    <Tabs
      className='cost-supplier-tab'
      tabPanes={tabPanes}
      defaultActiveKey={defaultActiveKey}
      onChange={handleTabChange}
    />
  );
};

CostAndSupplierTab.propTypes = {
  defaultActiveKey: PropTypes.string,
};

CostAndSupplierTab.defaultProps = {
  defaultActiveKey: '',
};

export default CostAndSupplierTab;
