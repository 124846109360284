import isValidHttpUrl from './isValidHttpUrl';

const openFilePDFNewTab = (filePDF) => {
  if (!filePDF) return undefined;

  if (isValidHttpUrl(filePDF)) {
    return window.open(filePDF, '_blank');
  }

  return window.open('#', '_blank').document.write(`
        <title>cvUpload</title>
        <iframe width='100%' height='100%' src='${filePDF}'></iframe>
      `);
};

export default openFilePDFNewTab;
