import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getAttendances = async ({ seekerId, filter = {}, page = {} }) => {
  const endpoint = `seekers/employees/${seekerId}/attendances`;
  const res = await request.get(endpoint, {
    params: {
      filter,
      page,
    },
  });
  const normalize = normalizeIndexResponse(res);

  return normalize;
};

export default getAttendances;
