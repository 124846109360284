import React, { useState } from 'react';

import { Form, Popconfirm, message } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';

import {
  Button,
  FormItem,
  InputNumber,
  Modal,
  ModalConfirm,
} from '@/components';
import { usePayCharge } from '@/hooks';
import { updatePayRate } from '@/services/timesheetService';
import { rateCard } from '@/utils';
import Formatter from '@/utils/Formatter';
import useModal from '@/utils/useModal';

import { checkShouldUpdate } from '../../utils/select';
import { UPDATE_KEY } from '../constants';
import { useTenantContext } from '../../../../TenantWrapper';

const JobTypeAddColumn = ({ editKey, setEditKey }) => {
  const { defaultLimitPayRate, currency } = useTenantContext();
  const queryClient = useQueryClient();
  const { id: timesheetId } = useParams();
  const formInstance = Form.useFormInstance();
  const { providerRateCard } = usePayCharge({ id: timesheetId });
  const [isConfirm, toggleConfirm] = useToggle();
  const [updateKey, setUpdateKey] = useState(null);

  const [isDisplayModal, showModal, hiddenModal] = useModal();

  const { mutate: updatePayRateMutation, isLoading: isUpdatingPayRate } =
    useMutation(
      ({ timecardIds, payrate, chargeRate }) =>
        updatePayRate({ timesheetId, timecardIds, payrate, chargeRate }),
      {
        onSuccess: () => {
          message.success('Update pay rate successfully');
          queryClient.invalidateQueries({
            queryKey: ['timesheetDetail', timesheetId],
          });
        },
        onError: (error) => message.error(error?.message),
        onSettled: () => {
          setEditKey(null);
          toggleConfirm();
        },
      },
    );

  const getAllTimecardBySkill = ({ formData, skillId }) => {
    const currentJobs = formData?.jobTypeAdded?.current ?? {};
    const currentJobData = _.flatMapDeep(_.values(currentJobs), (jobTypeData) =>
      _.map(jobTypeData[skillId], (timesheet) =>
        _.map(_.values(timesheet.timecards), 'id'),
      ),
    );
    return currentJobData;
  };

  const actions = {
    [UPDATE_KEY.selected]: ({ formData, skillId, allTimecardsSelected }) => {
      const allTimecardIdsBySkill = getAllTimecardBySkill({
        formData,
        skillId,
      });
      return _.intersection(allTimecardsSelected, allTimecardIdsBySkill);
    },
    [UPDATE_KEY.all]: ({ formData, skillId, allTimecardsSelected }) =>
      getAllTimecardBySkill({ formData, skillId }),
  };

  const handleUpdate = () => {
    const formData = formInstance.getFieldsValue();
    const allTimecardsSelected = formInstance.getFieldValue(
      'selectedTimecardIds',
    );
    const skillId = _.get(formData, ['payRate', editKey, 'skillId']);
    const timecardIds = actions[updateKey]({
      formData,
      skillId,
      allTimecardsSelected,
    });
    const payrate = _.get(formData, ['payRate', editKey, 'payrate']);
    const chargeRate = _.get(formData, ['payRate', editKey, 'chargeRate']);
    if (payrate < defaultLimitPayRate) {
      return showModal();
    }
    return updatePayRateMutation({ timecardIds, payrate, chargeRate });
  };
  const closeEditMode = (record) => {
    const { id: payRateId } = record;
    setEditKey(null);
    formInstance.setFields([
      {
        name: ['payRate', payRateId, 'payrate'],
        value: record.payrate,
      },
      { name: ['payRate', payRateId, 'chargeRate'], value: record.chargeRate },
    ]);
  };

  const handlePayRateChange = ({ payRateId, value }) => {
    const chargeRate = rateCard.calcChargeRate(
      providerRateCard,
      parseFloat(value),
    );

    formInstance.setFields([
      {
        name: ['payRate', `${payRateId}`, 'chargeRate'],
        value: chargeRate,
      },
    ]);
  };

  const handleFormatNumber = (value, { userTyping }) => {
    if (userTyping) {
      return value;
    }
    return Number(value).toFixed(2);
  };

  const onConfirm = () => {
    const formData = formInstance.getFieldsValue();
    const allTimecardsSelected = formInstance.getFieldValue(
      'selectedTimecardIds',
    );
    const skillId = _.get(formData, ['payRate', editKey, 'skillId']);
    const timecardIds = actions[updateKey]({
      formData,
      skillId,
      allTimecardsSelected,
    });
    const payrate = _.get(formData, ['payRate', editKey, 'payrate']);
    const chargeRate = _.get(formData, ['payRate', editKey, 'chargeRate']);
    hiddenModal();
    updatePayRateMutation({ timecardIds, payrate, chargeRate });
  };

  const columns = [
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      width: '20%',
      render: (jobTypeName, record) => (
        <FormItem name={['payRate', `${record.id}`]}>{jobTypeName}</FormItem>
      ),
    },
    {
      title: 'Pay Rate',
      dataIndex: 'payrate',
      editable: true,
      width: '20%',
      render: (payRate, record) => (
        <div>
          <FormItem
            name={['payRate', `${record.id}`, 'payrate']}
            rules={[{ required: true, message: 'Please type your Pay Rate!' }]}
          >
            {editKey === record.id ? (
              <InputNumber
                min={1}
                onChange={(value) =>
                  handlePayRateChange({ payRateId: record.id, value })
                }
                formatter={handleFormatNumber}
              />
            ) : (
              Formatter.currencyGBP(payRate)
            )}
          </FormItem>
          <ModalConfirm
            visible={isDisplayModal}
            onClickNo={hiddenModal}
            onClickYes={() => onConfirm()}
          >
            Are you sure you want to update the pay rate to below minimum wage
            of {currency}
            {defaultLimitPayRate}?
          </ModalConfirm>
        </div>
      ),
    },
    {
      title: 'Charge Rate',
      dataIndex: 'chargeRate',
      render: (chargeRate, record) => (
        <FormItem
          name={['payRate', `${record.id}`, 'chargeRate']}
          dependencies={['payRate', `${record.id}`, 'payrate']}
          rules={[
            {
              required: true,
              message: 'Please type your Charge Rate!',
            },
            ({ getFieldValue }) => ({
              validator(__, value) {
                const payRate = getFieldValue([
                  'payRate',
                  `${record.id}`,
                  'payrate',
                ]);
                if (value > payRate) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Charge Rate must be larger than Pay Rate'),
                );
              },
            }),
          ]}
        >
          {editKey === record.id ? (
            <InputNumber
              min={defaultLimitPayRate}
              formatter={handleFormatNumber}
            />
          ) : (
            Formatter.currencyGBP(chargeRate)
          )}
        </FormItem>
      ),
      width: '20%',
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '10%',
      render: (__, record) => {
        const { id, skillId } = record;
        const isEdit = editKey === id;
        const unapprovedJobs = formInstance.getFieldValue([
          'jobTypeAdded',
          'current',
          'unapproved',
          `${skillId}`,
        ]);
        const approvedJobs = formInstance.getFieldValue([
          'jobTypeAdded',
          'current',
          'approved',
          `${skillId}`,
        ]);
        const isEmptyJobs = _.isEmpty(_.merge(unapprovedJobs, approvedJobs));
        return (
          <Popconfirm
            title='Sure to cancel!'
            disabled={!isEdit || isEmptyJobs}
            onConfirm={() => closeEditMode(record)}
          >
            <Button
              className='black-button'
              disabled={isEmptyJobs}
              {...(!isEdit && { onClick: () => setEditKey(record.id) })}
            >
              {isEdit ? 'Cancel' : 'Edit'}
            </Button>
          </Popconfirm>
        );
      },
    },
    {
      title: 'Select All job type',
      dataIndex: 'buttonUpdateAll',
      width: '15%',
      render: (__, record) => {
        const isEdit = editKey === record.id;
        return (
          <Button
            className={classNames({ 'yellow-button': true, hide: !isEdit })}
            onClick={() => {
              setUpdateKey(UPDATE_KEY.all);
              toggleConfirm();
            }}
          >
            Update All
          </Button>
        );
      },
    },
    {
      title: 'Select All Selected in job type',
      dataIndex: 'buttonUpdateSelected',
      width: '15%',
      render: (__, record) => {
        const { id, skillId } = record;
        const isEdit = editKey === id;
        return (
          <FormItem
            shouldUpdate={(prevValues, currValues) =>
              checkShouldUpdate({ prevValues, currValues })
            }
          >
            {({ getFieldValue }) => {
              const timecardIds = getFieldValue('selectedTimecardIds');
              const formData = getFieldValue('jobTypeAdded');
              const currentJobs = formData?.current ?? {};

              const currentJobType = _.flatMapDeep(
                _.values(currentJobs),
                (jobTypeData) =>
                  _.map(jobTypeData[skillId], (timesheet) =>
                    _.map(_.values(timesheet.timecards), 'id'),
                  ),
              );
              const timecardIdsChecked = _.intersection(
                timecardIds,
                currentJobType,
              );
              return (
                <>
                  <Button
                    className={classNames({
                      'yellow-button': true,
                      hide: !isEdit,
                    })}
                    disabled={_.isEmpty(timecardIdsChecked)}
                    onClick={() => {
                      setUpdateKey(UPDATE_KEY.selected);
                      toggleConfirm();
                    }}
                  >
                    Update Selected
                  </Button>
                  <Modal
                    visible={isConfirm}
                    title='Update Pay / Charge Rates'
                    confirmLoading={isUpdatingPayRate}
                    onClickNo={toggleConfirm}
                    onClickYes={handleUpdate}
                  >
                    Are you sure you want to update the pay and charge rates for
                    all selected job type
                  </Modal>
                </>
              );
            }}
          </FormItem>
        );
      },
    },
  ];

  return columns;
};

JobTypeAddColumn.propTypes = {
  editKey: PropTypes.number,
  setEditKey: PropTypes.func,
};

JobTypeAddColumn.defaultProps = {
  editKey: null,
  setEditKey: () => {},
};

export default JobTypeAddColumn;
