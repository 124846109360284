import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { FormItem, Button, CSV, ModalConfirm } from '@/components';
import ListView from '@/components/ListView';
import { useDownloadCsv } from '@/hooks';
import {
  WeeklyWagesCsv,
  WeeklyWagesCsvConfirmPaid,
} from '@/services/payrollService';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

export const WagesPreview = ({
  filter,
  weeklyWagesPreview,
  isWeeklyWagesFetching,
}) => {
  const [isDownloadWages, setIsDownloadWages] = useState(false);
  const [isShowFinaliseModal, setIsShowFinaliseModal] = useState(false);

  const { year, weekNum } = filter;

  const SHOW_DATA = [
    {
      key: 'previous_week',
      title: 'Previous week',
    },
    {
      key: 'current_week',
      title: `Payroll Week ${weekNum}, ${year}`,
    },
  ];

  const showData = SHOW_DATA.map((e) => ({
    ...e,
    data: weeklyWagesPreview[e.key],
  }));

  useEffect(() => {
    setIsDownloadWages(false);
  }, [weekNum]);

  const {
    refCSVLink,
    data: weeklyWagesCsv,
    refetch: refetchWeeklyWagesCsv,
    isFetching: isFetchingWagesDownload,
  } = useDownloadCsv(['weekly-wages-csv'], () => WeeklyWagesCsv(filter));

  const {
    refCSVLink: refCSVLinkConfirmPaid,
    data: weeklyWagesCsvConfirmPaid,
    refetch: refetchWeeklyWagesCsvConfirmPaid,
  } = useDownloadCsv(['weekly-wages-csv-confirmPaid'], () =>
    WeeklyWagesCsvConfirmPaid(filter),
  );

  const wagePreviewColumns = generateHeaderTable([
    {
      title: 'Week Number',
      dataIndex: 'title',
      render: (_, record, index) => record.title,
    },
    {
      title: 'Employees',
      dataIndex: 'total_employees',
      render: (_, record, index) => record.data?.total_employees ?? 0,
    },
    {
      title: 'Total Wages',
      dataIndex: 'total_wages',
      render: (_, record, index) =>
        !isNaN(parseFloat(record.data?.total_wages))
          ? Formatter.currencyGBP(record.data?.total_wages ?? 0, 'en-GB', 'GBP')
          : undefined,
    },
  ]);

  return (
    <div className='show-details'>
      <FormItem label='Weekly Wages Preview'>
        <ListView
          headerColumns={wagePreviewColumns}
          infoDetail={[
            ...showData,
            {
              title: 'Holidays',
              data: {
                total_employees:
                  weeklyWagesPreview.total_employees_request_holidays,
              },
            },
          ]}
          loading={isWeeklyWagesFetching}
          className='wages-table'
        />
      </FormItem>
      <Button
        ref={refCSVLink}
        onClick={() => {
          const allTimesheetsWereFinalised =
            weeklyWagesPreview.all_timesheets_were_finalised;

          refetchWeeklyWagesCsv();
          setIsDownloadWages(allTimesheetsWereFinalised);
        }}
        loading={isFetchingWagesDownload}
        className='yellow-button wage-btn input-height'
      >
        Test Download CSV
      </Button>
      <Button
        // onClick={refetchWeeklyWagesCsv}
        onClick={() => setIsShowFinaliseModal(true)}
        className='yellow-button wage-btn input-height'
        disabled={!isDownloadWages}
      >
        Download Weekly Wages Csv and confirm Paid
      </Button>
      <CSV ref={refCSVLink} filename='weekly-wages.csv' data={weeklyWagesCsv} />
      <CSV
        ref={refCSVLinkConfirmPaid}
        filename='weekly-wages-confirm-paid.csv'
        data={weeklyWagesCsvConfirmPaid}
      />
      <ModalConfirm
        onClickNo={() => setIsShowFinaliseModal(false)}
        onClickYes={() => {
          refetchWeeklyWagesCsvConfirmPaid();
          setIsShowFinaliseModal(false);
          setIsDownloadWages(false);
        }}
        visible={isShowFinaliseModal}
        title='Finalise Payroll'
      >
        <div>
          Are you sure you want to download the CSV and finalise Payroll?
        </div>
        <div>This cannot be amended once downloaded. </div>
      </ModalConfirm>
    </div>
  );
};

WagesPreview.propTypes = {
  filter: PropTypes.object,
  weeklyWagesPreview: PropTypes.array,
  isWeeklyWagesFetching: PropTypes.bool,
};

export default WagesPreview;
