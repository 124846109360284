/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, CheckboxGroup } from '@/components';

import './styles.scss';

const Declarations3 = ({ nextStep, onValuesChange, initialValues, form }) => {
  const { isVerifiedStoreAndUse, isVerifiedReadTerms } = initialValues;

  return (
    <div className='flex-one-column declarations-3'>
      <div className='title'>
        Declarations
        <p>View Data Protection Notice</p>
      </div>

      <Form
        form={form}
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='detail-form-container'
      >
        <FormItem
          rules={[
            {
              required: true,
              message: 'Please select both options',
            },
          ]}
          name='isVerifiedStoreAndUse'
        >
          <CheckboxGroup
            className='recruitment-checkbox'
            options={[
              {
                label: `I declare that the information given in this form is complete and
            accurate. I understand that any false information or deliberate
            omissions will disqualify me from employment or may render me liable
            to summary dismissal.*`,
                value: true,
              },
            ]}
          />
        </FormItem>
        <FormItem
          rules={[
            {
              required: true,
              message: 'Please select both options',
            },
          ]}
          name='isVerifiedReadTerms'
        >
          <CheckboxGroup
            className='recruitment-checkbox'
            options={[
              {
                label: `I understand these details will be held in confidence by the
                Company, for the purposes of ongoing personnel administration and
                payroll administration in compliance with the Data Protection Act
                1998. I undertake to notify the Company immediately of any changes
                to the above details.*`,
                value: true,
              },
            ]}
          />
        </FormItem>
        <Button
          htmlType='submit'
          className='confirm-button shared-button '
          disabled={!isVerifiedStoreAndUse?.[0] || !isVerifiedReadTerms?.[0]}
        >
          Next Step
        </Button>
      </Form>
    </div>
  );
};
Declarations3.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default Declarations3;
