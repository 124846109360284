import { get, mapValues, omit, some, values } from 'lodash';

const checkShouldUpdate = ({ prevValues, curValues, name }) =>
  get(prevValues, name) !== get(curValues, name);

const checkAll = ({ form, selectedShift, type }) => {
  const formData = omit(form.getFieldsValue(), 'datePicker');
  const isCheckedAll = get(
    formData,
    [selectedShift, 'checkedAll', type],
    false,
  );
  const dataSchedules = get(formData, [selectedShift], []);
  const mappedValue = mapValues(dataSchedules, (item) => ({
    ...item,
    checked: {
      ...item.checked,
      [type]: isCheckedAll,
    },
  }));

  form.setFieldsValue({ [selectedShift]: mappedValue });
};

const checkRecord = ({ form, selectedShift, name, status = '' }) => {
  const formData = omit(form.getFieldsValue(), 'datePicker');

  const findCondition = {
    checked: {
      [name]: false,
    },
    ...(status && { status }),
  };

  const isCheckedAll = !some(
    values(omit(formData[selectedShift], 'checkedAll')),
    findCondition,
  );
  form.setFields([
    {
      name: [selectedShift, 'checkedAll', name],
      value: isCheckedAll,
    },
  ]);
};

const Select = {
  checkShouldUpdate,
  checkAll,
  checkRecord,
};

export default Select;
