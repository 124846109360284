import request from '@/request';

const deleteHours = async (
  /** @type {string|number} */ timesheetId,
  /** @type {string[]} */ timecardIds,
) => {
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/timecards/delete_hours`;
  const res = await request.post(endpoint, {
    timecardIds,
  });
  return res.data;
};

export default deleteHours;
