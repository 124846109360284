import request from '@/request';

const downloadCandidateCSV = async (params) => {
  const response = await request.get('/recruitments/candidates/csv', {
    params,
  });
  const csvFile = response.data;

  return csvFile;
};

export default downloadCandidateCSV;
