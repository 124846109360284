/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Table } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const TimesheetTable = ({ inforDetail, ...props }) => (
  <Table
    className='timesheet-table-container'
    dataSource={inforDetail}
    {...props}
    pagination={false}
  />
);

export default TimesheetTable;

TimesheetTable.propTypes = { inforDetail: PropTypes.array };

TimesheetTable.defaultProps = { inforDetail: [] };
