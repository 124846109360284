import { head, some, values } from 'lodash';

export const checkExistEmploymentOrCV = (form) => {
  const formValues = form.getFieldsValue();
  const cv = some(
    formValues?.documentationDocs,
    (doc) => doc?.typeValue === 'cvUpload' && !!doc?.fileValue,
  );
  const employment = formValues.vacancyApplicantEmploymentsAttributes;

  if (employment.length > 1) return true;

  const isEmptyEmployment = values(head(employment)).every((item) => !item);
  return !isEmptyEmployment || cv;
};
