/* eslint-disable max-len */
import { message } from 'antd';
import { useMutation } from 'react-query';

const useRequest = (
  /** @type {import("react-query").MutationFunction<unknown, void>} */ mutationFn,
  /** @type {Omit<import("react-query").UseMutationOptions<unknown, unknown, void, unknown>, "mutationFn"> | undefined} */ options,
) => {
  const state = useMutation(mutationFn, {
    onError: (errors) => {
      if (errors instanceof Error) message.error(errors.message);
    },
    ...options,
  });

  return { ...state };
};

export default useRequest;
