import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const getComments = async (/** @type {string|number} */ id) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/comments`;
  const res = await request.get(endpoint);
  const data = normalizeIndexResponse(res);
  return data?.data;
};

export const createComment = async (
  /** @type {string/number} */ id,
  /** @type {string} */ comment,
) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/comments`;
  const res = await request.post(endpoint, { timesheet_comment: { comment } });
  return res;
};

export const deleteComment = async (
  /** @type {string/number} */ id,
  commentId,
) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/comments/${commentId}`;
  const res = await request.delete(endpoint);
  return res;
};
