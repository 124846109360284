import request from '@/request';

const patchInvoiceStatus = async ({ invoiceIds }) => {
  const response = await request.patch(
    'trackers/invoicing/invoices/export_csv',
    {
      invoiceIds,
    },
  );
  const { data } = response;

  return data;
};

export default patchInvoiceStatus;
