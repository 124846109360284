/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';

import { DatePicker as AntdDatePicker, Form } from 'antd';
import classNames from 'classnames';
import { range, uniqueId } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import ArrowLeft from '@/assets/icons/ArrowLeft.png';
import ArrowRight from '@/assets/icons/ArrowRight.png';
import { ReactComponent as CalendarIcon } from '@/assets/icons/calendarIcon.svg';
import { useConstant } from '@/hooks';

import DateRender from './DateRender';
import PanelRender from './PanelRender';
import YearMode from './yearMode';

import './styles.scss';

const defaultYears = range(1940, 2101, 1);

moment.updateLocale('en', {
  week: {
    dow: 0,
  },
});

const DatePickerInline = ({
  className,
  popupClassName,
  dropdownClassName,
  format,
  onSelect,
  years,
  name,
  showClear,
  datePickerMode,
  defaultValue,
  alwaysShow,
  listSelected,
  onDateClick,
  listSecond,
  disabled,
  value,
  ...props
}) => {
  const yearLength = years.length;
  const defaultYearLength = defaultYears.length;
  const maxYear =
    yearLength > 0
      ? years[yearLength - 1]
      : defaultYears[defaultYearLength - 1];

  const formInstance = Form.useFormInstance();

  const [isDisableNextIcon, setIsDisableNextIcon] = useState(false);
  const [mode, setMode] = useState(datePickerMode);
  const [datetime, setDatetime] = useState(moment(defaultValue ?? value));
  const [selectedYear, setSelectedYear] = useState(
    moment().toDate().getFullYear(),
  );

  const yearRef = useRef();
  const datepickerPanelRef = useRef();

  const key = useConstant(() => uniqueId('date-picker-inline'));

  useEffect(() => {
    setDatetime(moment(value));
  }, [value]);

  useEffect(() => {
    yearRef.current?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
  });

  const onPanelChange = (val) => {
    const changedYear = moment(val).year();
    const changedMonth = val.format('MM');

    if (changedYear === maxYear && changedMonth === '12') {
      return setIsDisableNextIcon(true);
    }

    if (selectedYear > maxYear) {
      return setIsDisableNextIcon(true);
    }

    setDatetime(moment(val));

    return setIsDisableNextIcon(false);
  };

  const renderDate = (crrDate) => {
    const date = moment(crrDate).format('YYYY-MM-DD');

    const isSelected = listSelected.includes(date);

    const isInListSecond = listSecond.includes(date);

    return (
      <DateRender
        crrDate={crrDate}
        selectedYear={selectedYear}
        name={name}
        formInstance={formInstance}
        setDatetime={setDatetime}
        weekSelected={datetime?.weeks()}
        isSelected={isSelected}
        onClick={() => handlePickerDate(crrDate)}
        isInListSecond={isInListSecond}
        disabled={disabled}
      />
    );
  };

  const handlePickerDate = (crrDate) => {
    setDatetime(moment(crrDate));
    onDateClick(crrDate);
  };

  const renderPanel = (originalPanel) => (
    <PanelRender
      originalPanel={originalPanel}
      datetime={datetime}
      datepickerPanelRef={datepickerPanelRef}
      mode={mode}
      setMode={setMode}
      setIsOpen
      setDatetime={setDatetime}
      isDisableNextIcon={isDisableNextIcon}
      years={years}
      yearMode={
        <YearMode
          years={years}
          selectedYear={selectedYear}
          yearRef={yearRef}
          datetime={datetime}
          setDatetime={setDatetime}
          setMode={setMode}
          setSelectedYear={setSelectedYear}
          formInstance={formInstance}
          name={name}
          onPanelChange={onPanelChange}
          setIsDisableNextIcon={setIsDisableNextIcon}
        />
      }
    />
  );

  const getPopupContainer = () =>
    key ? document.getElementById(key) : undefined;

  return (
    <div className='date-calendar-inline' id={key}>
      <div className='ref-container'>
        <AntdDatePicker
          getPopupContainer={getPopupContainer}
          {...props}
          defaultValue={defaultValue}
          panelRender={renderPanel}
          className={classNames('primary-date-picker input-height', className)}
          popupClassName={classNames('popup-datepicker', popupClassName)}
          format={format}
          suffixIcon={<CalendarIcon className='calendar-icon' />}
          dateRender={renderDate}
          nextIcon={
            <img className='arrow-date' src={ArrowRight} alt='right-arrow' />
          }
          prevIcon={
            <img className='arrow-date' src={ArrowLeft} alt='right-arrow' />
          }
          superNextIcon={null}
          superPrevIcon={null}
          showToday={false}
          open
          value={datetime}
          picker={mode}
          placement='bottomRight'
          dropdownAlign={{ offset: [0, 4] }}
          onPanelChange={onPanelChange}
          allowClear={false}
          dropdownClassName={dropdownClassName}
        />
      </div>
    </div>
  );
};

DatePickerInline.propTypes = {
  showClear: PropTypes.bool,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  value: PropTypes.any,
  format: PropTypes.string,
  popupClassName: PropTypes.string,
  onSelect: PropTypes.func,
  years: PropTypes.array,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  datePickerMode: PropTypes.string,
  defaultValue: PropTypes.any,
  alwaysShow: PropTypes.any,
  listSelected: PropTypes.array,
  onDateClick: PropTypes.func,
  listSecond: PropTypes.array,
  disabled: PropTypes.bool,
  keyId: PropTypes.string,
};

DatePickerInline.defaultProps = {
  showClear: true,
  className: '',
  format: 'DD/MM/YYYY',
  onSelect: () => {},
  popupClassName: '',
  dropdownClassName: '',
  years: [],
  datePickerMode: 'date',
  listSelected: [],
  onDateClick: () => {},
  listSecond: [],
  disabled: false,
  keyId: '',
};

export default DatePickerInline;
