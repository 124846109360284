import { useEffect, useRef, useState } from 'react';

import { message } from 'antd';
import { useQuery } from 'react-query';

const useDownloadCsv = (
  /** @type {any} */ queryKey,
  /** @type {import("react-query").QueryFunction<unknown, any>} */ queryFn,
  // eslint-disable-next-line max-len
  /** @type {Omit<import("react-query").UseQueryOptions<unknown, unknown, unknown, any>, "queryKey" | "queryFn"> | undefined} */ options,
) => {
  const [isDownload, setIsDownload] = useState(false);
  const refCSVLink = useRef(null);

  const {
    isFetching,
    data = [],
    refetch,
  } = useQuery(queryKey, queryFn, {
    cacheTime: 0,
    retry: false,
    enabled: false,
    onSuccess: () => {
      setIsDownload(true);
    },
    onError: (error) => message.error(error?.message ?? 'Download failed'),
    ...options,
  });

  useEffect(() => {
    if (isDownload) {
      refCSVLink?.current?.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  return { isDownload, setIsDownload, isFetching, data, refetch, refCSVLink };
};

export default useDownloadCsv;
