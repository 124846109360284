import { useQuery } from 'react-query';

import { fetchDepartments } from '@/services/dropdownServices/getUserDepartments';

export function useDepartments({ select } = {}) {
  const { data, ...rest } = useQuery(
    ['fetchUserDepartments'],
    () => fetchDepartments(),
    {
      select,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...rest,
    data: data || [],
  };
}

export function useDepartmentOptions() {
  const { data } = useDepartments({
    select: (data) => {
      return data.map((datumn) => {
        return {
          label: datumn.name,
          value: datumn.id,
        };
      });
    },
  });

  return data;
}
