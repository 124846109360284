import { ORDER_BY, ORDER_DIRECTION } from '@/constants';
import request from '@/request';

const downloadJobCsv = async ({
  id,
  sortOrder,
  pageNumber,
  pageSize,
  filter,
}) => {
  const page = {
    size: pageSize,
    number: pageNumber,
  };

  const order = {
    orderBy: ORDER_BY,
    orderDirection: sortOrder || ORDER_DIRECTION,
  };

  const endpoint = `seekers/employees/${id}/jobs/csv`;
  const res = await request.get(endpoint, {
    params: {
      page,
      filter,
      order,
    },
  });
  return res.data;
};

export default downloadJobCsv;
