/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Form, Modal } from 'antd';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { time } from '@/utils';
import useModal from '@/utils/useModal';

import { getBackgroundColor } from '../../utils';
import Event from '../Event';
import './styles.scss';

/**
 * Renders an event view for the month calendar.
 *
 * @param {{
 * title: string,
 * event: object,
 * setJobIdEdit: Function,
 * setRepostId: Function,
 * setShowPopupCreate: Function,
 * refetchCalendarJobs: Function,
 * openManageWorkerModal: Function,
 * setModalContent: Function
 * }} props - The component props.
 * @return {JSX.Element} The rendered event view.
 */
const EventMonthView = ({
  title,
  event,
  setJobIdEdit,
  setRepostId,
  setShowPopupCreate,
  refetchCalendarJobs,
  openManageWorkerModal,
  setModalContent,
}) => {
  const formInstance = Form.useFormInstance();
  const isCompletedTab = Form.useWatch('isCompletedTab', formInstance);

  const status = get(event, 'status', null);
  const totalAssignments = get(event, 'totalAssignments', 0);
  const totalFills = get(event, 'totalFills', 0);

  const background = isCompletedTab
    ? 'completed'
    : getBackgroundColor(status, totalFills, totalAssignments);

  const [isDisplayModal, showModal, hiddenModal] = useModal();

  const endTime = time.formatDate(event.schedule.lastItem?.end, 'HH:mm');
  const startTime = time.formatDate(event.start, 'HH:mm');

  return (
    <div>
      <div
        className={classNames('month-view-container', background)}
        onClick={() => showModal()}
      >
        <div className='month-view-container-item'>
          {startTime}-{endTime}
        </div>
        <div className='month-view-container-item'>{title}</div>
        <div className='month-view-container-item'>
          ({totalFills} / {totalAssignments})
        </div>
      </div>
      <Modal
        centered
        visible={isDisplayModal}
        footer={null}
        onCancel={() => hiddenModal()}
        closable={false}
        maskClosable
        className='event-month-view-modal'
      >
        <Event
          event={event}
          title={title}
          setJobIdEdit={setJobIdEdit}
          setRepostId={setRepostId}
          setShowPopupCreate={setShowPopupCreate}
          refetchCalendarJobs={refetchCalendarJobs}
          openManageWorkerModal={openManageWorkerModal}
          setModalContent={setModalContent}
        />
      </Modal>
    </div>
  );
};

EventMonthView.propTypes = {
  title: PropTypes.string,
  event: PropTypes.object,
  setJobIdEdit: PropTypes.func,
  setRepostId: PropTypes.func,
  setShowPopupCreate: PropTypes.func,
  refetchCalendarJobs: PropTypes.func,
  openManageWorkerModal: PropTypes.func,
  setModalContent: PropTypes.func,
};

export default EventMonthView;
