import React from 'react';

import { Form, Radio, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Input, Textarea } from '@/components';
import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const INIT_VALUES = {
  medicalCondition: '',
  medication: '',
  note: '',
};

const MedicalInformation = ({
  nextStep,
  onValuesChange,
  initialValues,
  form,
}) => {
  const tenant = useTenantContext();
  const hasMedical = Form.useWatch('hasMedical', form);

  return (
    <div className='flex-one-column medical-information'>
      <div className='title additional-info'>Medical Information</div>
      <span className='text-bold'>
        Do you have any previous or existing medical conditions that, as your
        employer, we at {tenant.name} should be made aware of for health and
        safety reasons or which may impact your ability to undertake any of the
        jobs or roles available with us.
      </span>
      <Form
        onFinish={nextStep}
        form={form}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <FormItem
          required
          rules={[{ required: true, message: 'Please choose medical' }]}
          name='hasMedical'
        >
          <Radio.Group className='recruitment-radio'>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </Radio.Group>
        </FormItem>
        {hasMedical === 1 && (
          <Form.List
            name='medicalConditionsAttributes'
            initialValue={[INIT_VALUES]}
          >
            {(fields, { add }) => (
              <div>
                {fields.map((field) => (
                  <div className='medical-input-container'>
                    <Row gutter={10}>
                      <Col xl={12}>
                        <FormItem
                          label='Medical Condition'
                          className='input-item half'
                          name={[field.name, 'medicalCondition']}
                        >
                          <Input placeholder='Medical Condition' />
                        </FormItem>
                      </Col>
                      <Col xl={12}>
                        <FormItem
                          label='Medication'
                          className='input-item half'
                          name={[field.name, 'medication']}
                        >
                          <Input placeholder='Medication' />
                        </FormItem>
                      </Col>
                    </Row>
                    <FormItem
                      label='Note'
                      className='input-item full'
                      name={[field.name, 'note']}
                    >
                      <Textarea maxLength={255} placeholder='Note' />
                    </FormItem>
                  </div>
                ))}
                <FormItem>
                  <div className='add-button-container'>
                    <Button
                      onClick={() => add()}
                      className='black-button add-button-center'
                    >
                      Add Another
                    </Button>
                  </div>
                </FormItem>
              </div>
            )}
          </Form.List>
        )}
        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};
MedicalInformation.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default MedicalInformation;
