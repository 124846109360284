import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';

import { statuses } from './constants';
import './styles.scss';

const TooltipStatus = () => (
  <Tooltip
    className='tooltip-container'
    placement='topRight'
    title={
      <div className='tooltip-status'>
        <div className='tooltip-status-header'>Status Code</div>
        {statuses.map((status) => (
          <div key={status.desc} className='status-item'>
            <div className='status-note'>
              {status.label} - {status.desc}
            </div>
            <div className={`timesheets-status status-${status.color}`}>
              <Checkbox defaultChecked />
            </div>
          </div>
        ))}
      </div>
    }
  >
    <span>Status</span> <InfoCircleOutlined />
  </Tooltip>
);

export default TooltipStatus;
