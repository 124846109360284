import React, { useEffect, useState } from 'react';

import { Form, Tag } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { Checkbox, FormItem, MultipleSelect, Select } from '@/components';
import { useRoleItems } from '@/hooks/dropdowns';
import { useCertificates } from '@/hooks/useCertificates';

const LicencesAndTraining = ({ listProviders, disabled }) => {
  const { data: listSkill } = useRoleItems();
  const { data: certificates } = useCertificates();

  const form = Form.useFormInstance();

  const [skillRequisit, setSkillRequisit] = useState();
  const [providerRequisit, setProviderRequisit] = useState();

  const licenses = (Form.useWatch('licenses', form) ?? []).filter(
    (e) => e !== 'all',
  );

  const listPreRequisit = Form.useWatch('preRequisits', form) ?? [];
  const allowWithoutLicences = Form.useWatch('allowWithoutLicences', form);

  const onChangeAllowWithoutLicences = (event) => {
    form.setFieldsValue({
      allowWithoutLicences: event.target.checked,
    });
  };

  useEffect(() => {
    if (skillRequisit && providerRequisit) {
      const dataUpdate = [...listPreRequisit];

      dataUpdate.push({
        employerId: providerRequisit,
        skillId: skillRequisit,
      });

      form.setFieldsValue({ preRequisits: dataUpdate });

      setSkillRequisit();
      setProviderRequisit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, providerRequisit, skillRequisit]);

  const onChangeSkillId = (item) => {
    setSkillRequisit(item);
  };

  const onChangeProvider = (item) => {
    setProviderRequisit(item);
  };

  const onRemovePreRequisit = (index, data) => {
    const dataUpdate = [...data];

    dataUpdate.splice(index, 1);

    form.setFieldsValue({ preRequisits: dataUpdate });
  };

  const onRemoveLicencesType = (licence) => {
    form.setFieldsValue({
      licenses: licenses.filter((l) => l !== licence.slug),
    });
  };

  return (
    <div className='licences'>
      <FormItem name='initPreRequisits' />
      <div className='title-job-row'>Pre-Requisit Shift</div>
      <div className='job-fields'>
        <FormItem className='input-container' label='Provider'>
          <Select
            options={listProviders}
            placeholder='Provider'
            onChange={onChangeProvider}
            value={providerRequisit}
            disabled={disabled}
          />
        </FormItem>
        <FormItem className='input-container' label='Position'>
          <Select
            options={listSkill}
            placeholder='Position'
            onChange={onChangeSkillId}
            value={skillRequisit}
            disabled={disabled}
          />
        </FormItem>
      </div>
      <FormItem name='preRequisits' noStyle />
      <div className='pre-requisit-list'>
        {listPreRequisit.map((preRequisit, index) => {
          const provider = listProviders.find(
            (e) => e.value === preRequisit.employerId,
          );
          const position = listSkill.find(
            (e) => e.value === preRequisit.skillId,
          );

          return (
            <Tag
              key={`${preRequisit.skillId}-${preRequisit.employerId}`}
              closable
              closeIcon={<XIconBlack />}
              className='option-selected-tag pre-requisit-selected-tag'
              onClose={() =>
                disabled ? null : onRemovePreRequisit(index, listPreRequisit)
              }
            >
              <div className='pre-requisit-item'>
                <span className='pre-requisit-item-provider'>
                  {provider?.label ?? ''}
                </span>
                <span>{position?.label ?? ''}</span>
              </div>
            </Tag>
          );
        })}
      </div>

      <div className='job-fields'>
        <FormItem
          name='licenses'
          className='input-container'
          label='Licences and Training'
        >
          <MultipleSelect
            options={Object.values(certificates).map(({ name, slug }) => {
              return {
                label: name,
                value: slug,
              };
            })}
            placeholder='Select Licence Type'
            name='licenses'
            disabled={disabled}
          />
        </FormItem>
        <FormItem>
          {licenses.map((type) => {
            const licence = certificates[type];

            return (
              <div key={licence.id} className='lincence-tags'>
                <Tag
                  closable
                  closeIcon={<XIconBlack />}
                  key={licence?.slug}
                  className='option-selected-tag'
                  onClose={() =>
                    disabled ? null : onRemoveLicencesType(licence)
                  }
                >
                  <span className='option-selected-text'>{licence?.name}</span>
                </Tag>
              </div>
            );
          })}
        </FormItem>
      </div>
      <div className='job-fields'>
        <FormItem
          className='form-item'
          valuePropName='checked'
          name='allowWithoutLicences'
        >
          <span className='checkbox-label'>
            Allow Seeker without the above licences to see the job posting
          </span>
          <span>
            <Checkbox
              disabled={disabled}
              checked={allowWithoutLicences}
              onChange={onChangeAllowWithoutLicences}
            />
          </span>
        </FormItem>
      </div>
    </div>
  );
};

LicencesAndTraining.propTypes = {
  listProviders: PropTypes.array,
  disabled: PropTypes.bool,
};

LicencesAndTraining.defaultProps = { listProviders: [], disabled: false };

export default LicencesAndTraining;
