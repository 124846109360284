import React, { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Tabs, GeneralTemplate } from '@/components';
import { useQueryString } from '@/hooks';
import JobModalCreate from '@/pages/Jobs/JobModal/Create';
import PaymentView from '@/pages/PayTemplate/View';

import EditProfileWrapper from '../components/EditProfileWrapper';
import ProviderJobs from '../components/Jobs';

import './styles.scss';

const EditProvider = () => {
  const [showPopupCreate, setShowPopupCreate] = useState(false);

  const { providerId } = useParams();
  const [breadCrumb, setBreadCrumb] = useState([
    { item: 'Provider', to: '/view-all-providers' },
    { item: 'Edit provider', to: '#' },
  ]);

  const [buttonCreateNew, setButtonCreateNew] = useState({
    headerText: 'Create New Provider',
    buttonLink: '/create-new-provider',
  });

  const [queryString, setQueryString] = useQueryString(
    () => {},
    () => {},
    false,
  );

  const tabPanes = useMemo(
    () => [
      {
        tab: 'View Profile',
        key: 'viewProfile',
        child: (
          <EditProfileWrapper
            providerId={Number(providerId)}
            setBreadCrumb={setBreadCrumb}
          />
        ),
      },
      {
        tab: 'View Jobs',
        key: 'viewJobs',
        child: <ProviderJobs />,
      },
      {
        tab: 'Pay Templates',
        key: 'payTemplates',
        child: (
          <PaymentView isProviderDetail providerDetailId={Number(providerId)} />
        ),
      },
    ],
    [providerId],
  );

  const handleOnProviderTabsChange = (tabKey) => {
    setQueryString({ tab: tabKey });

    if (tabPanes.at(0).key === tabKey) {
      setButtonCreateNew({
        headerText: 'Create New Provider',
        buttonLink: '/create-new-provider',
        buttonCallback: undefined,
      });
    }

    if (tabPanes.at(1).key === tabKey) {
      setButtonCreateNew({
        headerText: 'Create Job',
        buttonLink: '?tab=viewJobs',
        buttonCallback: () => setShowPopupCreate('create'),
      });
    }
  };

  return (
    <GeneralTemplate
      data={breadCrumb}
      mainItem='Provider'
      headerText={breadCrumb[1].item}
      buttonLink={buttonCreateNew.buttonLink}
      buttonContent={buttonCreateNew.headerText}
      buttonCallback={buttonCreateNew.buttonCallback}
      subTemplateClassName='provider-container'
    >
      <div className='edit-provider-container'>
        <div className='edit-provider-info-input-container'>
          <Tabs
            onChange={handleOnProviderTabsChange}
            className='provider-tabs'
            tabPanes={tabPanes}
            activeKey={queryString?.tab ?? 'viewProfile'}
          />
        </div>
      </div>
      {showPopupCreate && (
        <JobModalCreate
          hidePopup={() => {
            setShowPopupCreate(false);
          }}
          showPopup={showPopupCreate}
        />
      )}
    </GeneralTemplate>
  );
};
export default EditProvider;
