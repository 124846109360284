import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

/**
 * @function getRoleItems role items are
 * @returns
 */
export const getRoleItems = async (filter) => {
  const res = await request.get('/options/skills', {
    params: filter ? { filter } : {},
  });

  const { data } = normalizeIndexResponse(res);

  return data || [];
};
