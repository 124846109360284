/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import { Table } from 'antd';
import PropTypes from 'prop-types';

import './index.scss';
import Formatter from '@/utils/Formatter';

const TableInvoice = ({ title, data, hasTotal }) => {
  const columns = [
    {
      title,
      dataIndex: 'detail',
      key: 'detail',
      width: '40%',
      render: (text) => <div className='table-colum invoice-line'>{text}</div>,
    },
    {
      title: 'Net',
      dataIndex: 'omniNetPay',
      key: 'omniNetPay',
      width: '20%',
      render: (text) => (
        <div className='table-colum'>{Formatter.currencyGBP(text)}</div>
      ),
    },
    {
      title: 'Vat',
      dataIndex: 'omniVat',
      key: 'omniVat',
      width: '20%',
      render: (text) => (
        <div className='table-colum'>{Formatter.currencyGBP(text)}</div>
      ),
    },
    {
      title: hasTotal ? 'Total' : '',
      dataIndex: 'total',
      key: 'total',
      width: '20%',
      render: (text) => (
        <div className='table-colum'>
          {text ? Formatter.currencyGBP(text) : undefined}
        </div>
      ),
    },
  ];

  return (
    <Table
      className='table_invoice'
      columns={columns}
      dataSource={data ?? []}
      pagination={false}
    />
  );
};

TableInvoice.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  hasTotal: PropTypes.bool,
};

TableInvoice.defaultProps = {
  title: '',
  data: [],
  hasTotal: false,
};

export default TableInvoice;

// awr:
// 0
// bh:
// 0
// description:
// 'For services provided - 2023-07-17 - 2023-07-23'
// hours:
// 1
// id:
// '29457'
// invoiceDetailType:
// 'labour'
// invoiceLine:
// '1 Hours @ 12.25 Per Hour'
// omniBh:
// null
// omniNetPay:
// 12.25
// omniRate:
// 12.25
