import React from 'react';

import PropTypes from 'prop-types';

import { Table } from '@/components';

import './styles.scss';

const TableRota = ({ data, columns, pagination }) => (
  <Table
    className='worker-rota-table'
    infoDetail={data}
    columns={columns}
    dividers={{ left: true, right: true }}
    pagination={pagination}
  />
);

TableRota.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  pagination: PropTypes.bool,
};

TableRota.defaultProps = {
  data: [],
  columns: [],
  pagination: false,
};
export default TableRota;
