import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getChargeRate = async (/** @type {string|number} */ id) => {
  const res = await request.get('trackers/timekeeping/charge_rates', {
    params: {
      filter: {
        timesheet_ids: [id],
      },
    },
  });

  const data = normalizeIndexResponse(res);

  return data?.data;
};

export default getChargeRate;
