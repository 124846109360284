/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { memo } from 'react';

import { Checkbox, Form, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  FormItem,
  Input,
  InputNumber,
  RangePicker,
  TimePicker,
} from '@/components';
import { time } from '@/utils';
import Formatter from '@/utils/Formatter';

import TimesheetTable from '../components/TimesheetTable';
import {
  cellColors,
  daysOfWeek,
  defaultDataIndex,
  hourlyDataIndex,
  totalCellNames,
  weeklyTotalCellNames,
} from '../constants';
import { genDefaultColumns } from '../utils';
import { useTenantContext } from '../../../TenantWrapper';

const CELL_TYPES = {
  text: 'text',
  number: 'number',
  date: 'date',
  header: 'header',
  range: 'range',
};

const Timecard = memo(
  ({
    panelId,
    timecard = {},
    seekerName = '',
    timesheetData = {},
    loading = false,
    isHourlyTemplate = false,
    isEdit = false,
    weeklyTotals = false,
    skillName = '',
    summaryFunc,
  }) => {
    const formInstance = Form.useFormInstance();
    const { currency } = useTenantContext();

    const { timecardsInfo } = timecard;

    const calBreakValue = (recordId) => {
      const formData = formInstance.getFieldsValue();
      const { breakDuration, totalHours, payRate, chargeRate } =
        formData[panelId][timecard.id][recordId];

      const convertedBreakDurationHrs = breakDuration / 60;

      const calOtPayRate =
        (totalHours - convertedBreakDurationHrs) * Number(payRate);
      const calOtChargeRate =
        (totalHours - convertedBreakDurationHrs) * Number(chargeRate);

      formData[panelId][timecard.id][recordId].otPayRate =
        Formatter.fixedNumber({ value: calOtPayRate });
      formData[panelId][timecard.id][recordId].otChargeRate =
        Formatter.fixedNumber({ value: calOtChargeRate });
      formInstance.setFieldsValue(formData);
    };

    const parseTimeFormat = (timeValue) => moment(timeValue, 'HH:mm');

    const parseShiftTimeToDate = ({
      startDate,
      endDate,
      startTime,
      endTime,
    }) => {
      const originalStartDate = moment(startDate);
      const originalEndDate = moment(endDate);
      const parsedStartTime = time.parseTime(startTime);
      const parsedEndTime = time.parseTime(endTime);

      const updatedStartTime = originalStartDate.clone().set({
        hours: parsedStartTime.hours(),
        minutes: parsedStartTime.minutes(),
        seconds: 0,
      });
      const updatedEndTime = originalEndDate.clone().set({
        hours: parsedEndTime.hours(),
        minutes: parsedEndTime.minutes(),
        seconds: 0,
      });

      if (parsedEndTime.isSameOrBefore(parsedStartTime)) {
        updatedEndTime.add(1, 'day');
      }
      return [updatedStartTime, updatedEndTime];
    };

    const handleChangeShiftTime = ({
      timeString: [startTime, endTime],
      record,
      startDate,
      endDate,
    }) => {
      const [updatedStartTime, updatedEndTime] = parseShiftTimeToDate({
        startDate,
        endDate,
        startTime,
        endTime,
      });

      const formData = formInstance.getFieldsValue();
      formData[panelId][timecard.id][record.id].shiftTime = [
        updatedStartTime,
        updatedEndTime,
      ];

      formInstance.setFieldsValue(formData);
    };

    const renderCellValue = ({
      value,
      label,
      type = 'header',
      name,
      prefix = '',
      column = '',
      isOverTime = false,
      onChange = () => {},
      rules = [],
      readOnly = false,
    }) => {
      const elementInput = {
        text: (
          <FormItem
            initialValue={value}
            {...(!isNil(name) && { name })}
            {...(!isEmpty(rules) && { rules })}
          >
            <Input
              {...(isOverTime && { className: 'overtime-color' })}
              readOnly={!isEdit || readOnly}
              value={value}
              onChange={onChange}
            />
          </FormItem>
        ),
        number: (
          <FormItem
            name={name}
            initialValue={value}
            {...(!isEmpty(rules) && { rules })}
          >
            <InputNumber
              {...(isOverTime && { className: 'overtime-color' })}
              placeholder={`${prefix}00.00`}
              readOnly={!isEdit || readOnly}
              formatter={(numberValue) =>
                `${numberValue ? prefix : ''}${numberValue}`
              }
              onChange={onChange}
            />
          </FormItem>
        ),
        date: (
          <FormItem name={name} initialValue={value}>
            <TimePicker
              {...(isOverTime && { className: 'overtime-color' })}
              readOnly={!isEdit || readOnly}
              onChange={onChange}
            />
          </FormItem>
        ),
        range: (
          <FormItem name={name} initialValue={value}>
            <RangePicker
              {...(isOverTime && { className: 'overtime-color' })}
              disabled={!isEdit || readOnly}
              order={false}
              format='HH:mm'
              onChange={onChange}
            />
          </FormItem>
        ),
        header: (
          <span {...(isOverTime && { className: 'overtime-color' })}>
            {prefix && prefix}
            {label ?? value}
          </span>
        ),
      };

      return (
        <div
          className={classNames('cell-item', {
            'total-color': cellColors.includes(column),
          })}
        >
          <Tooltip
            className='cell-tooltip'
            placement='topLeft'
            title={!isNil(label) ? label : value}
          >
            {elementInput[type]}
          </Tooltip>
        </div>
      );
    };

    const renderDay = daysOfWeek.map((day) => ({
      title: renderCellValue({ label: day.label, type: 'header' }),
      width: '4%',
      render: (_, record) =>
        renderCellValue({
          label: Formatter.fixedNumber({ value: record[day.dataIndex] }),
          isOverTime: record?.isOverTime,
        }),
    }));

    const handleSelectTimecard = () => {
      const timecardData = formInstance.getFieldsValue();

      const isNotSelectedAll = Object.keys(timecardData[panelId]).some(
        (id) => !timecardData?.[panelId]?.[id]?.select && id !== 'selectedAll',
      );

      timecardData[panelId].selectedAll = !isNotSelectedAll;

      formInstance.setFieldsValue(timecardData);
    };

    const handleSelectAll = () => {
      const formData = formInstance.getFieldsValue();

      Object.keys(formData[panelId]).forEach((id) => {
        if (!isEmpty(formData[panelId]?.[id])) {
          formData[panelId][id].select = formData[panelId].selectedAll;
        }
      });

      formInstance.setFieldsValue(formData);
    };

    const hourlyColumns = [
      {
        children: [
          {
            title: renderCellValue({ label: seekerName, type: 'header' }),
            width: '10%',
            dataIndex: 'dayOfWeek',
            render: (day) => !isEmpty(day) && renderCellValue({ label: day }),
          },
        ],
      },
      {
        children: [
          {
            title: renderCellValue({ label: 'Shift Times' }),
            dataIndex: 'shiftTimes',
            width: '10%',
            render: (shiftTimeValue, record, indexRow) => {
              if (indexRow === 1) {
                return renderCellValue({ label: skillName });
              }

              const { startDate, endDate } = record;
              const [startTime, endTime] = parseShiftTimeToDate({
                startDate,
                endDate,
                startTime: record.startShift,
                endTime: record.endTime,
              });

              return (
                !isEmpty(shiftTimeValue) &&
                renderCellValue({
                  value: [parseTimeFormat(startTime), parseTimeFormat(endTime)],
                  label: shiftTimeValue,
                  type: CELL_TYPES.range,
                  name: [
                    panelId,
                    `${timecard.id}`,
                    `${record.id}`,
                    'shiftTime',
                  ],
                  onChange: (_, timeString) =>
                    handleChangeShiftTime({
                      timeString,
                      record,
                      startDate,
                      endDate,
                    }),
                })
              );
            },
          },
        ],
      },
      {
        title: renderCellValue({ label: 'Breakdown of total hours by day' }),
        dataIndex: 'payrollTemplateHours',
        children: [
          {
            title: renderCellValue({ label: 'Start' }),
            dataIndex: 'startAt',
            width: '5%',
            render: (startAtValue, record) =>
              renderCellValue({
                label: startAtValue,
                isOverTime: record?.isOverTime,
              }),
          },
          {
            title: renderCellValue({ label: 'End' }),
            dataIndex: 'endAt',
            width: '5%',
            render: (endAtValue, record) =>
              renderCellValue({
                label: endAtValue,
                isOverTime: record?.isOverTime,
              }),
          },
          {
            title: renderCellValue({ label: 'Time in pay template' }),
            children: [
              {
                dataIndex: 'totalHours',
                width: '4%',
                render: (timeInPayTemplate, record) => (
                  <>
                    <FormItem
                      initialValue={record.id}
                      name={[panelId, `${timecard.id}`, `${record.id}`, 'id']}
                      hidden
                    />
                    <FormItem
                      initialValue={record?.isOverTime ?? false}
                      name={[
                        panelId,
                        `${timecard.id}`,
                        `${record.id}`,
                        'isOverTime',
                      ]}
                      hidden
                    />
                    {renderCellValue({
                      label: Formatter.fixedNumber({
                        value: timeInPayTemplate,
                      }),
                      isOverTime: record?.isOverTime,
                      value: timeInPayTemplate,
                      type: CELL_TYPES.number,
                      name: [
                        panelId,
                        `${timecard.id}`,
                        `${record.id}`,
                        'totalHours',
                      ],
                    })}
                  </>
                ),
              },
              {
                dataIndex: 'totalTimeUnit',
                width: '4%',
                render: (totalTimeUnit) => renderCellValue({ label: 'hours' }),
              },
            ],
          },
          {
            title: renderCellValue({ label: 'Pay' }),
            dataIndex: 'payRate',
            width: '5%',
            render: (pay, record, indexRow) =>
              renderCellValue({
                label: `${currency}${pay}`,
                value: pay,
                type: CELL_TYPES.number,
                prefix: currency,
                isOverTime: record?.isOverTime,
                name: [panelId, `${timecard.id}`, `${record.id}`, 'payRate'],
              }),
          },
          {
            title: renderCellValue({ label: 'Charge' }),
            dataIndex: 'chargeRate',
            width: '5%',
            render: (charge, record, indexRow) =>
              renderCellValue({
                label: `${currency}${charge}`,
                value: charge,
                type: CELL_TYPES.number,
                isOverTime: record?.isOverTime,
                prefix: currency,
                name: [panelId, `${timecard.id}`, `${record.id}`, 'chargeRate'],
              }),
          },
          {
            title: renderCellValue({ label: 'Template' }),
            dataIndex: 'templateName',
            width: '10%',
            render: (template, record, indexRow) =>
              renderCellValue({
                value: template,
                label: template,
                name: [
                  panelId,
                  `${timecard.id}`,
                  `${record.id}`,
                  'templateName',
                ],
              }),
          },
          {
            title: renderCellValue({ label: 'Pay Template' }),
            dataIndex: 'payTemplateName',
            width: '8%',
            render: (payTemplateName, record) =>
              renderCellValue({
                label: payTemplateName + (record?.isOverTime ? ' (OT)' : ''),
                isOverTime: record?.isOverTime,
              }),
          },
          {
            title: renderCellValue({ label: 'Total Pay' }),
            dataIndex: 'totalPay',
            width: '5.4%',
            render: (totalPay, record) =>
              renderCellValue({
                label: `${currency}${(totalPay ?? 0).toFixed(2)}`,
                isOverTime: record?.isOverTime,
              }),
          },
          {
            title: renderCellValue({ label: 'Total Charge' }),
            dataIndex: 'totalCharge',
            width: '5.4%',
            render: (totalCharge, record) =>
              renderCellValue({
                label: `${currency}${(totalCharge ?? 0).toFixed(2)}`,
                isOverTime: record?.isOverTime,
              }),
          },
        ],
      },
      {
        title: renderCellValue({ label: 'Amendments for Breaks' }),
        dataIndex: 'breaks',
        children: [
          {
            title: renderCellValue({ label: 'Break' }),
            dataIndex: ['breakInfo', 'breakDuration'],
            width: '4%',
            render: (breakDuration, record) =>
              renderCellValue({
                value: breakDuration,
                name: [
                  panelId,
                  `${timecard.id}`,
                  `${record.id}`,
                  'breakDuration',
                ],
                readOnly: true,
                type: CELL_TYPES.number,
                onChange: () => calBreakValue(record.id),
                isOverTime: record?.isOverTime,
              }),
          },
          {
            title: renderCellValue({ label: 'Time' }),
            dataIndex: 'time',
            width: '5%',
            render: () => renderCellValue({ value: 'minutes' }),
          },
          {
            title: renderCellValue({ label: 'Hours' }),
            width: '4%',
            render: (data, record) => {
              const totalHours =
                (data?.totalHours ?? 0) -
                (data?.breakInfo?.breakDuration ?? 0) / 60;
              return renderCellValue({
                value: Formatter.fixedNumber({ value: totalHours }),
                isOverTime: record?.isOverTime,
              });
            },
          },
          {
            title: renderCellValue({ label: 'Total Pay' }),
            dataIndex: ['breakInfo', 'payRate'],
            width: '5.4%',
            render: (payRate, record) =>
              renderCellValue({
                value: Formatter.fixedNumber({ value: payRate }),
                type: 'number',
                prefix: currency,
                name: [panelId, `${timecard.id}`, `${record.id}`, 'otPayRate'],
                isOverTime: record?.isOverTime,
                readOnly: true,
              }),
          },
          {
            title: renderCellValue({ label: 'Total Charge' }),
            dataIndex: ['breakInfo', 'chargeRate'],
            width: '5.4%',
            render: (chargeRate, record) =>
              renderCellValue({
                value: Formatter.fixedNumber({ value: chargeRate }),
                type: 'number',
                prefix: currency,
                name: [
                  panelId,
                  `${timecard.id}`,
                  `${record.id}`,
                  'otChargeRate',
                ],
                isOverTime: record?.isOverTime,
                readOnly: true,
              }),
          },
        ],
      },
    ];

    const columns = isHourlyTemplate
      ? hourlyColumns
      : genDefaultColumns({
          renderCellValue,
          renderDay,
          panelId,
          timecard,
          currency,
        });

    const renderCheckboxCell = ({ lastIndex, isSelectedAll = false }) => {
      if (isHourlyTemplate) {
        const name = isSelectedAll
          ? [panelId, 'selectedAll']
          : [panelId, `${timecard.id}`, 'select'];

        const handleSelect = isSelectedAll
          ? handleSelectAll
          : handleSelectTimecard;
        return (
          <Table.Summary.Cell className='checkbox-cell' index={lastIndex}>
            <FormItem name={name} initialValue={false} valuePropName='checked'>
              <Checkbox onChange={handleSelect} />
            </FormItem>
          </Table.Summary.Cell>
        );
      }
      return null;
    };

    const renderTotalCell = (dataIndexArray, totalCells) =>
      dataIndexArray.map((column, index) => {
        if (!totalCellNames.includes(column)) {
          return <Table.Summary.Cell className='empty-cell' index={index} />;
        }

        const isShiftTimes = column === 'shiftTimes';
        if (isShiftTimes && timecardsInfo?.length !== 1) {
          return <Table.Summary.Cell className='empty-cell' index={index} />;
        }

        const isStartAtColumn = column === 'startAt';
        const isEndAtColumn = column === 'endAt';

        const formattedValue = !isStartAtColumn
          ? Formatter.fixedNumber({ value: totalCells?.[column] })
          : 'Daily totals';
        const isBreakColumn = column === 'break';
        const isAddPrefix = ![
          'shiftTimes',
          'startAt',
          'break',
          'totalHours',
          'totalHoursSubTotalBreak',
        ].includes(column);

        return (
          <>
            <Table.Summary.Cell
              index={index}
              className={classNames({
                'empty-cell': isBreakColumn && !isHourlyTemplate,
                'hidden-cell': isEndAtColumn,
              })}
              colSpan={isStartAtColumn ? 2 : 1}
            >
              {renderCellValue({
                value: isShiftTimes ? skillName : formattedValue,
                column,
                ...(isAddPrefix && { prefix: currency }),
              })}
            </Table.Summary.Cell>
            {index + 1 === dataIndexArray.length &&
              renderCheckboxCell({ lastIndex: index })}
          </>
        );
      });

    const renderWeeklyCell = (dataIndexArray) =>
      dataIndexArray.map((column, index) => {
        if (!weeklyTotalCellNames.includes(column)) {
          return <Table.Summary.Cell className='empty-cell' index={index} />;
        }

        const formatValue =
          column !== 'startAt'
            ? Formatter.fixedNumber({ value: weeklyTotals[column] })
            : weeklyTotals[column];
        const isStartAtColumn = column === 'startAt';
        const isEndAtColumn = column === 'endAt';
        const isAddPrefix = ['pay', 'charge'].includes(column);

        return (
          <>
            <Table.Summary.Cell
              index={index}
              colSpan={isStartAtColumn ? 2 : 1}
              className={isEndAtColumn ? 'hidden-cell' : ''}
            >
              {renderCellValue({
                value: formatValue,
                column,
                ...(isAddPrefix && { prefix: currency }),
              })}
            </Table.Summary.Cell>
            {index + 1 === dataIndexArray.length &&
              renderCheckboxCell({ lastIndex: index, isSelectedAll: true })}
          </>
        );
      });

    const renderSummary = (pageData) => {
      const totalCells = summaryFunc(pageData);

      const dataIndexArray = isHourlyTemplate
        ? hourlyDataIndex
        : defaultDataIndex;

      const totalCellElements = renderTotalCell(dataIndexArray, totalCells);

      const weeklyCellElements = renderWeeklyCell(dataIndexArray);

      return (
        <>
          <Table.Summary.Row>{totalCellElements}</Table.Summary.Row>
          {weeklyTotals && isHourlyTemplate && (
            <Table.Summary.Row>{weeklyCellElements}</Table.Summary.Row>
          )}
        </>
      );
    };

    return (
      <TimesheetTable
        loading={loading}
        columns={[...columns]}
        inforDetail={isHourlyTemplate ? timecardsInfo : timesheetData}
        summary={renderSummary}
      />
    );
  },
);

export default Timecard;

Timecard.propTypes = {
  isHourlyTemplate: PropTypes.bool,
  panelId: PropTypes.number,
  timesheetData: PropTypes.array,
  timecard: PropTypes.object,
  seekerName: PropTypes.string,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  weeklyTotals: PropTypes.bool || PropTypes.object,
  skillName: PropTypes.string,
  summaryFunc: PropTypes.func,
};

Timecard.defaultValues = {
  isHourlyTemplate: false,
  panelId: null,
  timesheetData: [],
  timecard: {},
  seekerName: '',
  loading: false,
  isEdit: false,
  weeklyTotals: false,
  skillName: '',
  summaryFunc: () => 0,
};
