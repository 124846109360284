const convictions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const title = [
  { value: 'Mr', label: 'Mr.' },
  { value: 'Mrs', label: 'Mrs.' },
  { value: 'Ms', label: 'Ms.' },
  { value: 'Miss', label: 'Miss.' },
];

const marital = [
  { value: 'S', label: 'Single' },
  { value: 'M', label: 'Married' },
  { value: 'D', label: 'Divorced' },
  { value: 'W', label: 'Widowed' },
];

const levelLanguage = {
  1: 'Basic',
  2: 'Intermediate',
  3: 'Advanced',
  4: 'Fluent',
  5: 'Native',
};

export { convictions, title, marital, levelLanguage };
