import React, { useEffect } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, message, Switch, Tooltip } from 'antd';
import { isEmpty, filter } from 'lodash';
import PropTypes from 'prop-types';

import { Checkbox, FormItem, Input, Radio, Select } from '@/components';
import { RATE_TYPES } from '@/constants';
import { useFetch } from '@/hooks';
import { useProviderLocations } from '@/hooks/dropdowns';
import { JobServices, ProviderService } from '@/services';
import getListPayTemplate from '@/services/dropdownServices/getPayTemplates';
import { rateCard } from '@/utils';
import { preventInvalidValueInput } from '@/utils/input';
import { rulesSelect } from '@/utils/rulesInput';

import { JOB_RESPONSE_STATUS, postedOption } from './constant';
import { useTenantContext } from '../../../TenantWrapper';

/**
 *
 * @typedef {Object} Props
 * @property {any} listProviders
 * @property {any} listSkill
 * @property {boolean} disabled
 * @property {Function} getTemplate
 */

/**
 * @param {Props} props
 */
const ProviderAndLocation = ({
  disabled,
  getTemplate,
  listProviders,
  listSkill,
  group,
}) => {
  const formInstance = Form.useFormInstance();
  const payrate = Form.useWatch('payrate', formInstance);
  const payrollTemplate = Form.useWatch('payrollTemplate', formInstance);
  const shiftType = Form.useWatch('shiftType', formInstance);
  const providerId = Form.useWatch('employerId', formInstance);
  const prevProviderId = Form.useWatch('prevProviderId', formInstance);
  const prevSkillId = Form.useWatch('prevSkillId', formInstance);
  const prevRateType = Form.useWatch('prevRateType', formInstance);
  const skillId = Form.useWatch('skillId', formInstance);
  const locationId = Form.useWatch('locationId', formInstance);
  const rateType = Form.useWatch('rateType', formInstance);
  const addSeekers =
    Form.useWatch('jobResponsesAttributes', formInstance) ?? [];
  const seekersWithAcceptedStatus = filter(addSeekers, {
    status: JOB_RESPONSE_STATUS.ACCEPTED,
  });

  const isOver18 = Form.useWatch('isOver18', formInstance);
  const { currency } = useTenantContext();
  const limitPayRate = group?.[skillId]?.limitPayRate;

  const disablePosted = shiftType === 'individual';

  const { data: locations } = useProviderLocations(providerId);

  useFetch(
    ['listBlockedSeekers', providerId],
    () => ProviderService.getListBlockedSeekers(providerId),
    {
      onSuccess: (data) => {
        formInstance.setFieldsValue({
          blockedSeekers: data,
        });
      },
      enabled: !!providerId,
    },
  );

  useFetch(
    ['listWorkerPool', providerId],
    () => ProviderService.getListWorkerPool(providerId),
    {
      onSuccess: (data) => {
        formInstance.setFieldsValue({
          workerPool: data,
        });
      },
      enabled: !!providerId,
    },
  );

  const handleUpdateTemplate = ({ skillIdValue, locationIdValue }) => {
    const updateSkillId = skillIdValue ?? skillId;
    const updateLocationId = locationIdValue ?? locationId;
    if (updateSkillId && providerId && updateLocationId && !payrollTemplate) {
      getTemplate({ skillIdValue, locationIdValue });
    }
  };

  const onChangePosted = (value) => {
    if (value < seekersWithAcceptedStatus.length) {
      message.error('Posted must be greater than Number of seeker added');
    } else {
      formInstance.setFieldsValue({
        requestedNumber: value,
      });
    }
  };

  useEffect(() => {
    debounceForChangePayrateFunc({ target: { value: limitPayRate } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: specificRates } = useFetch(
    ['getSpecificRate', providerId],
    () => ProviderService.getChargeRates(providerId),
    {
      enabled: !!providerId,
    },
  );

  const jobSpecificRate = (specificRates ?? []).find(
    (item) => item.skillId === skillId && rateType === item.rateType,
  );

  const setSpecificPayChargeRate = () => {
    jobSpecificRate &&
      formInstance.setFields([
        {
          name: 'payrate',
          value: jobSpecificRate.payRate,
        },
        {
          name: 'chargeRate',
          value: jobSpecificRate.chargeRate,
        },
      ]);
  };

  const shouldSetSpecificPayChargeRate =
    !isEmpty(specificRates) &&
    (providerId !== prevProviderId ||
      rateType !== prevRateType ||
      skillId !== prevSkillId);

  if (shouldSetSpecificPayChargeRate) {
    formInstance.setFieldsValue({
      prevProviderId: providerId,
      prevRateType: rateType,
      prevSkillId: skillId,
    });
    setSpecificPayChargeRate();
  }

  const { data: relatedResults } = useFetch(
    ['calculatechargeRate', providerId],
    () => JobServices.calculateChargeRate(providerId),
    {
      onError: () => message.error('Failed to return charge rate'),
      enabled: !!providerId,
      initialData: {},
      onSuccess: (data) =>
        !jobSpecificRate &&
        debounceForChangePayrateFunc({ target: { value: payrate } }, data),
    },
  );

  const handleChangeSwitch = () => {
    formInstance.setFieldsValue({
      jobResponsesAttributes: [],
    });
  };

  const handleErrorSwitch = () => {
    const formData = formInstance.getFieldsValue();

    formData.payrollTemplate = false;
    formData.jobResponsesAttributes = formData.initJobAttributes
      ? [...formData.initJobAttributes]
      : [];
    formInstance.setFieldsValue(formData);

    message.error('There is no Pay Template for this Provider and Location');
  };

  if ((!providerId || !locationId) && payrollTemplate) {
    handleErrorSwitch();
  }
  useFetch(
    ['getPayTemplates', providerId, locationId, payrollTemplate],
    () => getListPayTemplate(providerId, locationId),
    {
      onSettled: (data) => {
        isEmpty(data?.templates) && payrollTemplate && handleErrorSwitch();
      },
      onSuccess: (data) => {
        formInstance.setFieldsValue({ payTemplates: data.payTemplatesDetail });
      },
      enabled: !!(providerId && locationId && payrollTemplate),
    },
  );

  const onPayRateChange = ({ target }, newRelated) => {
    const related = newRelated ?? relatedResults;

    const chargeRate = rateCard.calcChargeRate(
      related,
      parseFloat(target.value),
    );

    formInstance.setFieldsValue({
      chargeRate: isNaN(Number(chargeRate)) ? 0 : chargeRate,
    });
  };

  const rateCheck = [
    {
      value: false,
      label: 'GIG Posted Job',
    },
    {
      value: true,
      label: (
        <div>
          <span>Internal Posted Job</span>
          <Tooltip
            title={
              <>
                <p>
                  GIG posted jobs are for all GIG Seekers verified on the
                  platform.
                </p>
                <p>
                  Internal jobs are for your internal employees only, you can
                  let GIG seekers view this job at any time.
                </p>
              </>
            }
          >
            <InfoCircleOutlined className='tooltip-icon' />
          </Tooltip>
        </div>
      ),
    },
  ];

  const onChangeEmployerId = () => {
    formInstance.setFieldsValue({ locationId: undefined });
  };

  const onChangeCheckboxIsOver18 = (event) => {
    formInstance.setFieldsValue({ isOver18: event.target.checked });
  };

  const debounceForChangePayrateFunc = (e, newRelated) =>
    onPayRateChange(e, newRelated);

  return (
    <div className='provider-location-container'>
      <FormItem name='blockedSeekers' noStyle />
      <FormItem name='workerPool' noStyle />
      <FormItem name='paymentType' noStyle />
      <FormItem name='payTemplates' noStyle />
      <FormItem name='positionChargeRate' hidden />
      <FormItem name='prevSkillId' hidden />
      <FormItem name='prevRateType' hidden />
      <FormItem name='prevProviderId' hidden />

      <FormItem
        required
        name='employerId'
        label='Provider'
        rules={rulesSelect('Provider', !disabled)}
        className='lg-form-item'
      >
        <Select
          placeholder='Provider'
          options={listProviders}
          onChange={onChangeEmployerId}
          disabled={disabled}
          required
        />
      </FormItem>
      <FormItem
        required
        name='locationId'
        label='Choose Location'
        rules={rulesSelect('Location', !disabled)}
        className='lg-form-item'
      >
        <Select
          options={locations}
          placeholder='Choose Location'
          disabled={disabled}
          onChange={(value) =>
            handleUpdateTemplate({ locationIdValue: value, skillIdValue: null })
          }
          required
        />
      </FormItem>
      <div className='row-container'>
        <FormItem
          required
          name='skillId'
          label='Position Needed'
          rules={rulesSelect('Position Needed', !disabled)}
          className='lg-form-item'
        >
          <Select
            placeholder='Position Needed'
            options={listSkill}
            disabled={disabled}
            onChange={(value) =>
              handleUpdateTemplate({
                skillIdValue: value,
                locationIdValue: null,
              })
            }
            required
          />
        </FormItem>
        <FormItem
          name='rateType'
          label='Rate Type'
          className='sm-form-item'
          initialValue={RATE_TYPES[0].value}
        >
          <Select
            options={RATE_TYPES}
            placeholder='Rate Type'
            disabled={payrollTemplate}
          />
        </FormItem>
      </div>
      <div className='row-container'>
        <FormItem
          rules={rulesSelect('Posted', !disabled)}
          required
          name='posted'
          label='Posted'
          className='sm-form-item'
        >
          <Select
            placeholder='Posted'
            options={postedOption}
            onChange={onChangePosted}
            disabled={disablePosted || disabled}
          />
        </FormItem>
        <FormItem
          className='sm-form-item'
          name='requestedNumber'
          label='Requested'
        >
          <Input
            onKeyDown={(evt) =>
              preventInvalidValueInput(evt, ['e', 'E', '+', '-'])
            }
            placeholder='Requested'
            disabled={disablePosted || disabled}
          />
        </FormItem>
        <FormItem
          valuePropName='checked'
          className='sm-form-item'
          name='isOver18'
        >
          <Checkbox
            label='Over 18 Only'
            disabled={disabled}
            checked={isOver18}
            onChange={(e) => onChangeCheckboxIsOver18(e)}
          />
        </FormItem>
      </div>
      <div className='row-container'>
        <FormItem
          name='payrate'
          className='sm-form-item'
          label='Pay Rate'
          required
        >
          <Input
            prefix={currency}
            type='number'
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            placeholder='Pay Rate'
            onChange={debounceForChangePayrateFunc}
            disabled={disabled || payrollTemplate}
          />
        </FormItem>
        <FormItem
          name='chargeRate'
          className='sm-form-item'
          label='Charge Rate'
          rules={[
            {
              required: true,
              message: 'Please enter Charge Rate',
            },
            {
              validator: async (_, val) => {
                const valNumber = parseFloat(val);

                if (valNumber < payrate) {
                  throw new Error('Charge rate must be larger than pay rate');
                }
              },
              message: 'Charge rate must be larger than pay rate',
            },
          ]}
        >
          <Input
            prefix={currency}
            type='number'
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            placeholder='Charge Rate'
            disabled
          />
        </FormItem>
        <div className='template-mode'>
          <p>Flexible Pay Rate</p>
          <FormItem
            name='payrollTemplate'
            initialValue={false}
            valuePropName='checked'
          >
            <Switch onChange={handleChangeSwitch} />
          </FormItem>
          <p>Pay Template</p>
        </div>
      </div>
      <FormItem name='isPrivated'>
        <Radio
          className='posted-check'
          options={rateCheck}
          defaultValue={false}
          disabled={disabled}
        />
      </FormItem>
    </div>
  );
};

ProviderAndLocation.propTypes = {
  listProviders: PropTypes.array,
  listSkill: PropTypes.array,
  disabled: PropTypes.bool,
  getTemplate: PropTypes.func,
};

ProviderAndLocation.defaultProps = {
  listProviders: [],
  listSkill: [],
  disabled: false,
  getTemplate: () => {},
};

export default ProviderAndLocation;
