import React from 'react';

import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const CircleCheckBox = ({ label, name, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Checkbox className='circular-checkbox' name={name} {...props}>
    {label}
  </Checkbox>
);

export default CircleCheckBox;

CircleCheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

CircleCheckBox.defaultProps = {
  label: '',
  name: '',
};
