import React from 'react';

import AddSections from './AddSection';
import CostsSections from './CostsSections';
import HoursSections from './HoursSections';

import './styles.scss';

const BodySection = () => (
  <div className='invoice-body-container'>
    <HoursSections />
    <CostsSections />
    <AddSections />
  </div>
);

export default BodySection;
