import React, { useState } from 'react';

import { Form, message } from 'antd';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { SICK_NO_SHOW_LABEL } from '@/pages/Payroll/constants';
import { TimesheetService } from '@/services';

import CommentPopover from '../../components/CommentPopover';

const SickNoShowDetail = ({ sicknessInfo, namePath }) => {
  const queryClient = useQueryClient();
  const formInstance = Form.useFormInstance();
  const { id: timesheetId } = useParams();
  const [isVisibleEditComment, setVisibleEditComment] = useState(false);

  const noShowType = get(sicknessInfo, 'noShowType') ?? 'hide';
  const { mutate: updateCommentMutation, isLoading } = useMutation(
    ({ comment }) =>
      TimesheetService.updateSickNoShowComment({
        timesheetId,
        comment,
      }),
    {
      onSuccess: () => {
        message.success('Update comment successfully');
        queryClient.invalidateQueries({
          queryKey: ['timesheetDetail', timesheetId],
        });
        setVisibleEditComment(false);
      },
    },
  );
  const handleEdit = () => {
    const noshowFormData = formInstance.getFieldValue(namePath);
    const { comment: content, assignedScheduleId } = get(
      noshowFormData,
      'comment',
    );
    updateCommentMutation({ comment: { content, assignedScheduleId } });
  };
  return (
    <CommentPopover
      type={noShowType}
      namePath={namePath}
      isLoading={isLoading}
      handleEdit={handleEdit}
      isOpen={isVisibleEditComment}
      setIsOpen={setVisibleEditComment}
      updateCommentMutation={updateCommentMutation}
    >
      <span
        className={classNames('tag-cell', {
          [noShowType]: true,
        })}
      >
        {SICK_NO_SHOW_LABEL[noShowType]}
      </span>
    </CommentPopover>
  );
};

export default SickNoShowDetail;

SickNoShowDetail.defaultProps = {
  sicknessInfo: false,
  namePath: [],
};

SickNoShowDetail.propTypes = {
  sicknessInfo: PropTypes.bool,
  namePath: PropTypes.array,
};
