import React from 'react';

import { Form } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { useFetch } from '@/hooks';
import { getOneRota } from '@/services/rota';

import EditRotaTable from '../components/EditRotaTable';

const EditRota = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const startTime = Form.useWatch('startTime', form);
  const endTime = Form.useWatch('endTime', form);

  const { refetch: refetchGetRota } = useFetch(['rota'], () => getOneRota(id), {
    onSuccess: (data) =>
      form.setFieldsValue({
        ...data,
        startTime: moment(data?.originalStartTime),
        endTime: moment(data?.originalEndTime),
        rotaWeeksAttributes: data?.rotaWeekAttributes,
      }),
  });

  return (
    <EditRotaTable
      headerText='Edit Worker Rota'
      cancelButton='Cancel'
      createButton='Save'
      allocateButton='Allocate'
      startTime={startTime}
      endTime={endTime}
      form={form}
      refetchGetRota={refetchGetRota}
    />
  );
};

export default EditRota;
