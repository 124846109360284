import { useQuery } from 'react-query';

import { getRoleItems } from '@/services/dropdownServices/getRoleItems';

const useRoleItems = (params) => {
  const { data, ...rest } = useQuery(
    ['getRoleItems'],
    () => getRoleItems(params),
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.reduce(
          (result, item) => {
            const { id, name } = item;

            result.data.push({
              value: id,
              label: name,
            });

            result.group[id] = item;

            return result;
          },
          {
            data: [],
            group: {},
          },
        );
      },
    },
  );

  return {
    ...rest,
    ...data,
  };
};

export default useRoleItems;
