import React, { useEffect, useRef, useState } from 'react';

import { Pagination, Form, message, Tooltip, Spin } from 'antd';
import { debounce } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  Button,
  FormItem,
  Select,
  AutoComplete,
  GeneralTemplate,
  ListView,
  MultipleSelect,
  Modal,
  CSV,
} from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import { RADIUSES, DEBOUNCE_WAIT, PAGE_SIZE_OPTIONS } from '@/constants';
import { useSessionStorage } from '@/hooks';
import {
  useLocations,
  useStatusOfLead,
  useManagersOfLead,
} from '@/hooks/dropdowns';
import { LeadService } from '@/services';
import Formatter from '@/utils/Formatter';
import { switchStatusLead } from '@/utils/switchStatus';
import useModal from '@/utils/useModal';

import SelectDate from './components/SelectDate';
import './styles.scss';

const ViewLeads = () => {
  const refCSVLink = useRef();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isDownload, setIsDownload] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState(null);
  const [statusValue, setStatusValue] = useState({
    leadRowId: '',
    leadStatus: '',
  });
  const [paginationPageSize, setPaginationPageSize] = useState(25);

  const [storedValue, setSession, removeSession] =
    useSessionStorage('viewLeads');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { listLocations } = useLocations();
  const { data: statuses } = useStatusOfLead();
  const { data: managers } = useManagersOfLead();
  const [isDisplayModal, showModal, hiddenModal] = useModal();

  // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
  const Status = ({ statusId, leadId, openModalChangeStatus }) => (
    <Select
      placeholder='Status'
      className='status-select'
      options={statuses}
      defaultValue={statusId}
      onClick={(e) => e.stopPropagation()}
      onChange={(statusIdSelected) => {
        openModalChangeStatus(statusIdSelected, leadId);
      }}
    />
  );

  const headerColumns = [
    {
      title: 'Number',
      key: 'key',
      dataIndex: 'key',
      width: '6%',
    },
    {
      title: 'Client Name',
      key: 'name',
      dataIndex: 'name',
      width: '10%',
      render: (name) => (
        <Tooltip placement='topLeft' title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement='topLeft' title={email}>
          {email}
        </Tooltip>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      width: '10%',
      render: (phoneNumber) => (
        <Tooltip placement='topLeft' title={phoneNumber}>
          {phoneNumber}
        </Tooltip>
      ),
    },
    {
      title: 'Postcode',
      dataIndex: 'postCode',
      key: 'postCode',
      width: '7%',
    },
    {
      title: 'Tell us a little about your needs',
      dataIndex: 'tellUs',
      key: 'tellUs',
      ellipsis: {
        showTitle: false,
      },
      render: (needs) => (
        <Tooltip placement='topLeft' title={needs}>
          {needs}
        </Tooltip>
      ),
    },
    {
      title: 'Application Date',
      dataIndex: 'applicationDate',
      key: 'applicationDate',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '7%',
      render: (statusId, { id: leadId }) => (
        <Status
          statusId={statusId}
          leadId={leadId}
          openModalChangeStatus={openModalChangeStatus}
        />
      ),
    },
    {
      title: 'Allocated To',
      dataIndex: 'managerId',
      key: 'allocatedTo',
      width: '7%',
      render: (managerId, record) => (
        <Select
          placeholder='Allocated To'
          className='status-select'
          defaultValue={managerId}
          options={managers}
          onClick={(e) => e.stopPropagation()}
          onChange={(accountManagerId) =>
            handleUpdateLeadAccountManager(accountManagerId, record?.id)
          }
        />
      ),
    },
  ];

  const {
    isFetching: isFetchingDownloadCsv,
    data: dataLeadExportCSV = [],
    refetch: refetchLeadExportCSV,
  } = useQuery(
    ['provider-export-csv'],
    () =>
      LeadService.downloadCsv({
        searchTerm,
        ordering: sortOrder,
        ...form.getFieldsValue(),
      }),
    {
      cacheTime: 0,
      retry: false,
      enabled: false,
      onSuccess: () => {
        setIsDownload(true);
      },
    },
  );

  const { data: listNameAndEmail = [] } = useQuery(
    ['getListNameAndEmailLeads', searchTerm],
    () => LeadService.getListEmailAndName(searchTerm),
    {
      onSuccess: (data = []) =>
        data.map((item) => ({ label: item, value: item })),
      onError: (error) => message.error(error.message),
      refetchOnWindowFocus: false,
    },
  );

  const {
    isFetching,
    data: responseData = {},
    refetch: refetchGetLeads,
  } = useQuery(
    ['getLeads', sortOrder, currentPage, paginationPageSize],
    () =>
      LeadService.getAll({
        searchTerm,
        number: currentPage,
        ordering: sortOrder,
        size: paginationPageSize,
        ...form.getFieldsValue(),
      }),
    {
      onError: (_error) => {
        message.error('Get leads unsuccessfully!');
      },
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (isDownload) {
      refCSVLink.current.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const handleSearchTermChange = debounce(setSearchTerm, DEBOUNCE_WAIT);

  const handleSort = (order) => {
    setSortOrder(Formatter.makeAbbrevSortType(order));
  };

  const handleNavigate = (leadId) => {
    history.push(`/edit-lead/${leadId}`);
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    form.resetFields();
    removeSession();
    refetchGetLeads();
  };

  const handleShowSizeChange = (_current, pageSize) => {
    setPaginationPageSize(pageSize);
  };

  const { mutate: mutateUpdateStatus, isLoading: isUpdateStatusLoading } =
    useMutation(LeadService.updateStatus, {
      onSuccess: () => {
        message.success('Update status of a lead successfully!');
        hiddenModal();
        refetchGetLeads();
      },
      onError: (error) => {
        message.error(error.message);
        refetchGetLeads();
      },
    });

  const {
    isLoading: isUpdateAccountManagerStatusLoading,
    mutate: mutateUpdateLeadAccountManager,
  } = useMutation(LeadService.updateLeadAccountManager, {
    onSuccess: () => {
      message.success('Update Account Manager of a lead successfully!');
    },
    onError: (error) => {
      message.error(error.message);
      refetchGetLeads();
    },
  });

  const handleUpdateLeadAccountManager = (value, leadId) => {
    mutateUpdateLeadAccountManager({
      id: leadId,
      allocatedAccountManager: value,
    });
  };

  const handleSearchLeads = () => {
    setCurrentPage(1);
    setSession(form.getFieldsValue());
    refetchGetLeads();
  };

  const handleChangeLeadStatus = () => {
    mutateUpdateStatus({
      id: statusValue.leadRowId,
      status: statusValue.leadStatus,
    });
  };

  const handleUnChangeLeadStatus = () => {
    hiddenModal();
  };

  const openModalChangeStatus = (statusIdSelected, leadId) => {
    showModal();
    setStatusValue({ leadRowId: leadId, leadStatus: statusIdSelected });
  };

  return (
    <GeneralTemplate
      mainItem='Provider'
      buttonContent='Create Lead'
      headerText='Leads'
      data={[{ item: 'Leads', to: '/view-all-leads' }]}
      buttonLink='/create-new-lead'
      subTemplateClassName='lead-container'
    >
      <Spin
        spinning={
          isFetchingDownloadCsv ||
          isUpdateStatusLoading ||
          isUpdateAccountManagerStatusLoading
        }
      >
        <div className='lead-search-wrapper'>
          <Form
            form={form}
            onFinish={handleSearchLeads}
            className='lead-search-form'
          >
            <div className='row'>
              <FormItem name='searchTerm' className='form-item' label='Search'>
                <AutoComplete
                  defaultActiveFirstOption
                  options={listNameAndEmail}
                  onSearch={handleSearchTermChange}
                  placeholder='Name or email address'
                />
              </FormItem>
              <FormItem
                name='managerId'
                className='form-item'
                label='Allocated Account Manager'
              >
                <MultipleSelect
                  name='managerId'
                  options={managers}
                  placeholder='Allocated Account Manager'
                />
              </FormItem>
              <FormItem
                name='localOffice'
                className='form-item'
                label='Location'
              >
                <MultipleSelect
                  name='localOffice'
                  options={listLocations}
                  placeholder='Location'
                />
              </FormItem>
            </div>
            <div className='row'>
              <SelectDate
                startDateFieldName='registrationDateFrom'
                endDateFieldName='registrationDateTo'
                label='Registration Date'
              />

              <FormItem name='status' className='form-item' label='Status'>
                <MultipleSelect
                  name='status'
                  options={statuses}
                  placeholder='Status'
                />
              </FormItem>
              <FormItem className='form-item' name='zip' label='Post Code'>
                <PostCodeUK />
              </FormItem>

              <FormItem name='radius' className='form-item' label='Radius'>
                <Select name='radius' options={RADIUSES} placeholder='Radius' />
              </FormItem>

              <div className='button-search'>
                <FormItem>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='yellow-button input-height btn-style'
                  >
                    Search
                  </Button>
                </FormItem>

                <FormItem>
                  <Button
                    onClick={handleClearFilter}
                    htmlType='button'
                    className='filter-button input-height btn-style'
                  >
                    Clear Filters
                  </Button>
                </FormItem>
              </div>
            </div>
          </Form>
          <ListView
            scroll={{ y: 650 }}
            loading={isFetching}
            headerColumns={headerColumns}
            infoDetail={responseData?.leads || []}
            onRow={handleNavigate}
            handleSort={handleSort}
            locale={{
              emptyText: 'No Leads match your search criteria',
            }}
          />

          <div className='table-footer-container'>
            <div className='pagination-container'>
              <Pagination
                responsive
                showSizeChanger
                current={currentPage}
                pageSize={paginationPageSize}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                total={responseData?.totalCount || 0}
                onChange={setCurrentPage}
                onShowSizeChange={handleShowSizeChange}
              />
            </div>
            <Button
              className='yellow-button download-csv-btn'
              disabled={isFetchingDownloadCsv}
              onClick={refetchLeadExportCSV}
            >
              {isFetchingDownloadCsv ? 'Loading csv...' : 'Download CSV'}
            </Button>
            <CSV
              ref={refCSVLink}
              filename='data-lead.csv'
              data={dataLeadExportCSV}
            />
          </div>
        </div>
      </Spin>
      <Modal
        visible={isDisplayModal}
        title={switchStatusLead(statusValue.leadStatus).title}
        isLoading={isUpdateStatusLoading}
        onClickNo={handleUnChangeLeadStatus}
        onClickYes={handleChangeLeadStatus}
      >
        {switchStatusLead(statusValue.leadStatus).content}
      </Modal>
    </GeneralTemplate>
  );
};
export default ViewLeads;
