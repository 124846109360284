/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Table } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';
import trackingTableColumns from './trackingTableColumns';

const initColumns = trackingTableColumns();

const TrackingTable = ({ infoDetail, columns, ...props }) => (
  <div className='tracking__container'>
    <Table
      dataSource={infoDetail}
      columns={initColumns}
      pagination={false}
      {...props}
    />
  </div>
);
export default TrackingTable;

TrackingTable.propTypes = {
  infoDetail: PropTypes.array,
  columns: PropTypes.array,
};

TrackingTable.defaultProps = {
  infoDetail: [],
  columns: [],
};
