import React from 'react';

import { Typography } from 'antd';
import { camelize } from 'humps';

// this function aims to create elements of key and title
// formatted into camelCase according to title
export const generateHeaderTable = (columns) => {
  // ex: columns = [{ title: 'some strings', render: 'anything' }];

  const header = columns.map((col) => {
    if (typeof col.title === 'string') {
      return {
        key: camelize(col.id ? col.id : col.title),
        dataIndex: camelize(col.id ? col.id : col.title),
        render: (content) => (
          <Typography.Text ellipsis>{content}</Typography.Text>
        ),
        ...col,
      };
    }
    return col;
  });

  return header;
};
