import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Button, FormItem, Input, Select } from '@/components';
import { LIMIT_PHONE_NUMBER, vocativeTitles } from '@/constants';
import { rulesEmail, rulesValidatePhone } from '@/utils/rulesInput';

import './styles.scss';

const RecruitmentDetail = ({
  nextStep,
  form,
  onValuesChange,
  initialValues,
}) => {
  const { id } = useParams();

  return (
    <div className='flex-one-column your-details'>
      <div className='title'>Your Details</div>
      <Form
        onFinish={nextStep}
        form={form}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='detail-form-container'
      >
        <FormItem
          className='input-container'
          name='title'
          label='Title'
          rules={[{ required: true, message: 'Please select your title' }]}
        >
          <Select options={vocativeTitles} placeholder='Title' required />
        </FormItem>

        <FormItem
          className='input-container'
          name='firstname'
          label='First Name'
          rules={[{ required: true, message: 'Please type your Forename' }]}
          required
        >
          <Input placeholder='First Name' maxLength={48} />
        </FormItem>

        <FormItem
          className='input-container'
          name='lastname'
          label='Last Name'
          rules={[{ required: true, message: 'Please type your Surname' }]}
          required
        >
          <Input placeholder='Last Name' maxLength={48} />
        </FormItem>

        <FormItem
          className='input-container'
          name='email'
          label='Email'
          rules={rulesEmail()}
          required
        >
          <Input placeholder='Email' />
        </FormItem>

        <FormItem
          className='input-container'
          name='phone'
          label='Phone Number'
          rules={rulesValidatePhone(id)}
          required
        >
          <Input maxLength={LIMIT_PHONE_NUMBER} placeholder='Phone Number' />
        </FormItem>

        <Button htmlType='submit' className='shared-button confirm-button'>
          Next Step
        </Button>
      </Form>
    </div>
  );
};
RecruitmentDetail.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default RecruitmentDetail;
