/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Checkbox } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

const CheckboxGroup = ({ label, options, className, ...props }) => (
  <div className={classNames('checkbox-container', className)}>
    <Checkbox.Group options={options} {...props} />
  </div>
);

CheckboxGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  options: [],
  className: '',
  label: '',
};

export default CheckboxGroup;
