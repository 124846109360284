import moment from 'moment';

const columsComment = [
  {
    title: 'Comment',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: 'User',
    dataIndex: 'createdBy',
    key: 'user',
  },
  {
    title: 'Date/Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm A'),
  },
];

// TODO: Need to update this option, confirm with team backend later.
const columnsAction = [
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'User',
    dataIndex: 'createdBy',
    key: 'user',
  },
  {
    title: 'Date/Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm A'),
  },
];

const listBlock = [
  {
    label: 'Visa or Right to Work',
    value: 'visa_or_work_right',
  },
  {
    label: '3 Strikes',
    value: 'strike',
  },
  {
    label: 'Blocked - Behaviour',
    value: 'behavior',
  },
  {
    label: 'Block Imported',
    value: 'imported',
  },
  {
    label: 'Unblock',
    value: null,
  },
];

const listStatus = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Resigned - P45',
    value: 'resigned',
  },
  {
    label: 'Terminated - P45',
    value: 'terminated',
  },
];

export { columsComment, listBlock, listStatus, columnsAction };
