import React from 'react';

import { Spin, Card } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import './styles.scss';

const GridColumn = ({ title, data, isLoading }) => {
  const renderData = () => (
    <div className='column-body'>
      {data.map((dt) => {
        const {
          key,
          name,
          email,
          phone,
          otherRoles,
          generalRole,
          location,
          zip,
          createdAt,
        } = dt;

        return (
          <Card className='info-detail' key={key}>
            <div className='header-card'>
              <div>{name}</div>
              <div>{generalRole}</div>
              <div>{location}</div>
            </div>
            <div className='body-card'>
              <div>
                <p>{email}</p>
                <p>{phone}</p>
              </div>
              <div>
                <p>{otherRoles}</p>
              </div>
              <div>
                <p>{zip}</p>
                <p>{createdAt ? moment(createdAt).format('DD/MM/YYYY') : ''}</p>
              </div>
            </div>
          </Card>
        );
      })}
      {isEmpty(data) && <p>No data</p>}
    </div>
  );

  return (
    <div className='grid-column-container'>
      <div className='column-header'>{title}</div>
      {isLoading ? <Spin /> : <div className='wrap-info'>{renderData()}</div>}
    </div>
  );
};

GridColumn.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

GridColumn.defaultProps = {
  title: '',
  data: [],
  isLoading: false,
};

export default GridColumn;
