import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';

export const fetchPresignedUrl = async (params) => {
  try {
    const { filename, filetype } = params;
    const response = await request.get('/presigned_url', {
      params: { filename, filetype },
    });

    return response.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};
