import { STATUS_JOB_CODE, STATUS_OPTIONS } from '@/constants';

const getStatusQuery = (/** @type {Array} */ sttArr) => {
  const statusQuery = [];
  sttArr.forEach((item) => {
    if (
      ![STATUS_OPTIONS.filled.value, STATUS_OPTIONS.part_filled.value].includes(
        item,
      )
    ) {
      statusQuery.push(item);
    } else
      !statusQuery.includes(STATUS_OPTIONS.posted.value) &&
        statusQuery.push(STATUS_OPTIONS.posted.value);
  });
  return statusQuery;
};

const filterJobByStatus = (
  /** @type {Array} */ sttArr,
  /** @type {Array} */ jobs,
) => {
  let filteredJobs = [];
  const optionsFilterPostedCode = [
    STATUS_OPTIONS.posted.value,
    STATUS_OPTIONS.filled.value,
    STATUS_OPTIONS.part_filled.value,
  ];
  const hasFilterJobPostedCode = sttArr.some((item) =>
    optionsFilterPostedCode.includes(item),
  );

  const hasFilterNotPostedCode = sttArr.some(
    (item) => !optionsFilterPostedCode.includes(item),
  );

  if (!hasFilterJobPostedCode || hasFilterNotPostedCode) {
    filteredJobs = jobs.filter((job) => job.status !== STATUS_JOB_CODE.posted);
  }

  if (hasFilterJobPostedCode) {
    jobs.forEach((job) => {
      const { status, totalAssignments, totalFills } = job;

      if (status === STATUS_JOB_CODE.posted) {
        const isFilled = totalFills === totalAssignments;
        const isPartFilled = totalFills > 0 && totalFills < totalAssignments;
        const filterPartFilled =
          isPartFilled && sttArr.includes(STATUS_OPTIONS.part_filled.value);
        const filterFilled =
          isFilled && sttArr.includes(STATUS_OPTIONS.filled.value);
        const filterPosted =
          !isFilled &&
          !isPartFilled &&
          sttArr.includes(STATUS_OPTIONS.posted.value);

        if (filterFilled || filterPartFilled || filterPosted) {
          filteredJobs.push(job);
        }
      }
    });
  }

  return filteredJobs;
};

export { getStatusQuery, filterJobByStatus };
