import React from 'react';

import { Tooltip } from 'antd';
import moment from 'moment';

const breadCrumb = [
  { item: 'Timesheets', to: '/timesheets' },
  { item: 'Suppliers', to: '/suppliers' },
];

const columns = [
  {
    title: 'Supplier Name',
    width: '20%',
    dataIndex: 'name',
    render: (name) => (
      <Tooltip placement='topLeft' title={name}>
        {name}
      </Tooltip>
    ),
  },

  {
    title: 'Email',
    width: '15%',
    render: (/** @type {string } */ email) => (
      <Tooltip placement='topLeft' title={email}>
        {email}
      </Tooltip>
    ),
  },
  {
    title: 'Phone Number',
    width: '10%',
    dataIndex: 'phone',
    render: (phoneNumber) => (
      <Tooltip placement='topLeft' title={phoneNumber}>
        {phoneNumber}
      </Tooltip>
    ),
  },
  {
    title: 'Key contact',
    width: '7%',
    dataIndex: 'mainContact',
    render: (key) => (
      <Tooltip placement='topLeft' title={key}>
        {key}
      </Tooltip>
    ),
  },
  {
    title: 'Supply Type',
    width: '15%',
    dataIndex: 'supplierTypes',
    render: (type) => (
      <Tooltip placement='topLeft' title={type.join(' ')}>
        {type.join(' ')}
      </Tooltip>
    ),
  },
  {
    title: 'Date Created',
    width: '15%',
    defaultSortOrder: 'descend',
    sorter: true,
    dataIndex: 'createdAt',
    render: (/** @type {string[]} */ datetime) =>
      moment(datetime).format('DD/MM/YYYY'),
  },
];

export { breadCrumb, columns };
