const getFormData = (formInstance) => formInstance.getFieldsValue();
const getFormDataByName = ({ formInstance, name }) =>
  formInstance.getFieldValue(name);

const FormAccess = {
  getFormData,
  getFormDataByName,
};

export default FormAccess;
