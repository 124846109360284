import request from '@/request';

const updateTimecards = async ({
  /** @type {string | number} */ timesheetId,
  /** @type {string[] | number[]} */ timecardsAttributes,
}) => {
  const res = await request.patch(
    `trackers/timekeeping/timesheets/${timesheetId}`,
    {
      timesheet: {
        timecardsAttributes,
      },
    },
  );
  return res?.data;
};

export default updateTimecards;
