/* eslint-disable no-underscore-dangle */
import React, { useMemo, useState } from 'react';

import { Divider, Form, Typography } from 'antd';
import classNames from 'classnames';
import { groupBy } from 'lodash';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { FormItem, Input, InputNumber, Modal, Select } from '@/components';
import { useRoleItems } from '@/hooks/dropdowns';

import { useTenantContext } from '../../../../../TenantWrapper';

const HourSection = () => {
  const { data: roleItems, group } = useRoleItems();
  const { currency } = useTenantContext();
  const formInstance = Form.useFormInstance();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hoursSection = Form.useWatch('hoursSection', formInstance) ?? [];
  const descriptions = useMemo(
    () => groupBy(hoursSection, 'description'),
    [hoursSection],
  );
  const onChangeDescription = (description, value) => {
    hoursSection.forEach((item) => {
      if (item.description === description) {
        item.description = value;
      }
    });
    formInstance.setFieldsValue({ hoursSection });
  };

  const calHoursSection = ({ hoursValue, userTyping, index }) => {
    const changedSection = hoursSection[index];
    const newHours = Number(hoursValue ?? 0);
    const { omniRate } = changedSection;

    const netAmount =
      newHours && omniRate ? newHours * parseFloat(omniRate) : 0;
    const omniVat = (netAmount * 20) / 100;

    hoursSection[index] = {
      ...changedSection,
      totalValue: userTyping ? hoursValue : newHours.toFixed(2),
      hours: userTyping ? hoursValue : newHours.toFixed(2),
      omniNetPay: netAmount,
      omniNetPayAmount: netAmount.toFixed(2),
      omniVat: Number(omniVat ?? 0).toFixed(2),
      invoiceLine: `${newHours} Hours @ ${currency}${omniRate} Per Hour`,
    };

    formInstance.setFieldsValue({ hoursSection });
    return userTyping ? hoursValue : newHours.toFixed(2);
  };

  const calRate = ({ rateValue = 0, index, userTyping }) => {
    const changedSection = hoursSection[index];
    const newRate = Number(rateValue);
    const { hours } = changedSection;

    const netAmount = (
      newRate && hours ? parseFloat(hours) * newRate : 0
    ).toFixed(2);
    const omniVat = ((netAmount * 20) / 100).toFixed(2);

    hoursSection[index] = {
      ...changedSection,
      omniRate: userTyping ? rateValue : newRate.toFixed(2),
      omniNetPay: netAmount,
      omniNetPayAmount: netAmount,
      omniVat,
      invoiceLine: `${hours} Hours @ ${currency}${newRate} Per Hour`,
    };

    formInstance.setFieldsValue({ hoursSection });
    return userTyping ? rateValue : newRate.toFixed(2);
  };

  return (
    <>
      <FormItem name='hoursSection' noStyle />
      <div
        className={classNames('sections-container', {
          'hide-section':
            hoursSection.filter((item) => !item._destroy).length === 0,
        })}
      >
        {Object.keys(descriptions).map((desc) => (
          <>
            <Typography.Text className='description-label'>
              Description
            </Typography.Text>

            <Input
              value={desc}
              onChange={(e) => onChangeDescription(desc, e.target.value)}
            />

            <Form.List name='hoursSection'>
              {() => {
                const sectionOfDesc = hoursSection.filter(
                  (item) => item.description === desc,
                );
                const indexArr = sectionOfDesc.map((item) =>
                  hoursSection.indexOf(item),
                );
                return indexArr.map((index) => {
                  const isDestroy = formInstance.getFieldValue([
                    'hoursSection',
                    index,
                    '_destroy',
                  ]);
                  const role = formInstance.getFieldValue([
                    'hoursSection',
                    index,
                    'labourTypeId',
                  ]);
                  const limitPayRate = group?.[role]?.limitPayRate;

                  return (
                    !isDestroy && (
                      <div className='hour-section-container'>
                        <div className='left-section'>
                          <div className='fields'>
                            <FormItem
                              name={[index, 'labourTypeId']}
                              label='Role'
                              className='half-width'
                              rules={[
                                {
                                  required: true,
                                  message: 'Role is required',
                                },
                              ]}
                            >
                              <Select placeholder='Role' options={roleItems} />
                            </FormItem>
                          </div>
                          <div className='fields'>
                            <FormItem
                              label='Invoice Lines'
                              className='half-width'
                              name={[index, 'invoiceLine']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Invoice Line is required',
                                },
                              ]}
                            >
                              <Input placeholder='Invoice Lines' />
                            </FormItem>
                          </div>
                          <div className='fields'>
                            <FormItem
                              name={[index, 'totalValue']}
                              label='Hours'
                              className='medium-width'
                              rules={[
                                {
                                  required: true,
                                  message: 'Hours is required',
                                },
                              ]}
                            >
                              <InputNumber
                                className='input-number'
                                min={0}
                                formatter={(hoursValue, { userTyping }) =>
                                  calHoursSection({
                                    index,
                                    hoursValue,
                                    userTyping,
                                  })
                                }
                                placeholder='Hours'
                              />
                            </FormItem>
                          </div>
                          <div className='fields'>
                            <FormItem
                              name={[index, 'omniRate']}
                              label='Rate'
                              className='medium-width'
                              rules={[
                                {
                                  required: true,
                                  message: 'Rate is required',
                                },
                                {
                                  // eslint-disable-next-line consistent-return
                                  validator: async (_, value) => {
                                    if (Number(value) < limitPayRate) {
                                      return Promise.reject(
                                        new Error(
                                          `Value must be greater than ${limitPayRate}`,
                                        ),
                                      );
                                    }
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                className='input-number'
                                min={0}
                                formatter={(rateValue, { userTyping }) =>
                                  calRate({ userTyping, index, rateValue })
                                }
                                placeholder='Rate'
                              />
                            </FormItem>
                          </div>

                          <div className='close-icon-container'>
                            <Modal
                              visible={openDeleteModal}
                              onClickYes={() => {
                                hoursSection[deleteIndex]._destroy = true;
                                setDeleteIndex(null);
                                setOpenDeleteModal(false);
                                return formInstance.setFieldsValue({
                                  hoursSection,
                                });
                              }}
                              onClickNo={() => setOpenDeleteModal(false)}
                            >
                              <span>Are you sure to want to remove?</span>
                            </Modal>
                            <ClosedIcon
                              onClick={() => {
                                setDeleteIndex(index);
                                setOpenDeleteModal(true);
                              }}
                            />
                          </div>
                        </div>
                        <div className='right-section'>
                          <FormItem
                            name={[index, 'omniNetPayAmount']}
                            label='NET Amount:'
                          >
                            <Input
                              prefix={currency}
                              className='none-style'
                              readOnly
                            />
                          </FormItem>
                          <Divider type='vertical' className='divider-amount' />
                          <FormItem name={[index, 'omniVat']} label='20% VAT:'>
                            <Input
                              prefix={currency}
                              className='none-style'
                              readOnly
                            />
                          </FormItem>
                        </div>
                      </div>
                    )
                  );
                });
              }}
            </Form.List>
          </>
        ))}
      </div>
    </>
  );
};

export default HourSection;
