import request from '@/request';

const create = async (/** @type {any} */ params) => {
  const { comment, documentsFile, suppliersTypeId, ...resData } = params;

  const result = await request.post('/trackers/expense/costs', {
    cost: {
      ...resData,
    },
  });

  return result;
};

export default create;
