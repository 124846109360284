import { useCallback, useState } from 'react';

/**
 * @param {boolean} initialState
 * @return {[boolean, ()=> void, ()=> void]}
 */
const useModal = (initialState = false) => {
  const [isDisplayModal, setShow] = useState(initialState);
  const showModal = useCallback(() => setShow(true), []);
  const hiddenModal = useCallback(() => setShow(false), []);

  return [isDisplayModal, showModal, hiddenModal];
};

export default useModal;
