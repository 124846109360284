import request from '@/request';

const getAssignedSchedules = async ({ positionId }) => {
  const endpoint = `jobs/positions/${positionId}/assigned_schedules/date_with_statuses`;
  const res = await request.get(endpoint);

  return res.data.data;
};

export default getAssignedSchedules;
