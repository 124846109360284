/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Checkbox } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const ColorCheckbox = ({ className, ...props }) => (
  <div className={classNames('color-checkbox', className)}>
    <Checkbox {...props} />
  </div>
);

ColorCheckbox.propTypes = {
  className: PropTypes.string,
};

ColorCheckbox.defaultProps = {
  className: '',
};

export default ColorCheckbox;
