import { useQuery } from 'react-query';

import { getStatusOfLead } from '@/services/dropdownServices/getStatusOfLead';

const useStatusOfLead = (queryOptions = {}) =>
  useQuery(['statusOfLead'], getStatusOfLead, {
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

export default useStatusOfLead;
