import React from 'react';

import { Image } from '@/components';
import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const FormLogo = () => {
  const { iconLogo } = useTenantContext();

  return (
    <>
      <div className='logo-container'>
        <div className='logo-circle '>
          <svg
            className='svg-logo-circle'
            preserveAspectRatio='none'
            data-bbox='0 0 200 200'
            xmlns='http://www.w3.org/2000/svg'
            width='200'
            height='200'
            viewBox='0 0 200 200'
            role='presentation'
            aria-hidden='true'
          >
            <g>
              <path d='M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z' />
            </g>
          </svg>
        </div>
      </div>
      <Image className='gig-logo' src={iconLogo.url} alt='Logo' />
    </>
  );
};

export default FormLogo;
