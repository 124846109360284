/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Pagination as AntdPagination } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { PAGE_SIZE_OPTIONS } from '@/constants';

import './styles.scss';

const Pagination = ({ className, ...props }) => (
  <AntdPagination
    {...props}
    responsive
    showSizeChanger
    pageSizeOptions={PAGE_SIZE_OPTIONS}
    className={classNames('primary-pagination', className)}
  />
);

Pagination.propTypes = {
  className: PropTypes.string,
};

Pagination.defaultProps = {
  className: '',
};

export default Pagination;
