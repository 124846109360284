import React from 'react';

import PropTypes from 'prop-types';

import TimesheetTable from '@/pages/PayTemplate/components/TimesheetTable';

const Timecard = ({ loading, columns, inforDetail, summary }) => (
  <TimesheetTable
    loading={loading}
    columns={columns}
    inforDetail={inforDetail}
    summary={summary}
  />
);

export default Timecard;

Timecard.propTypes = {
  loading: PropTypes.bool,
  summary: PropTypes.node,
  columns: PropTypes.array,
  inforDetail: PropTypes.array,
};

Timecard.defaultValues = {
  columns: [],
  summary: null,
  loading: false,
  inforDetail: [],
};
