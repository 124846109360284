import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const ColorItem = ({ title, status }) => (
  <div className='color-item-container'>
    <div className={classNames('color-item', status)} />
    {title}
  </div>
);

ColorItem.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
};

export default ColorItem;
