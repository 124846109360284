const statuses = [
  {
    value: 0,
    label: 'Confirmed',
    color: 'white',
    id: 'white',
  },
  {
    value: 1,
    label: 'Finalised',
    color: 'green',
    id: 'green',
  },
];

const mappingStatuses = {
  finalised: {
    label: 'finalised',
    color: 'green',
  },
  confirmed: {
    label: 'confirmed',
    color: 'white',
  },
};

export { statuses, mappingStatuses };
