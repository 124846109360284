import React, { useState } from 'react';

import { Form, Switch } from 'antd';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';

import { Tabs, GeneralTemplate, FormItem } from '@/components';
import { useFetch, useQueryString, useSessionStorage } from '@/hooks';
import getCountPayroll from '@/services/payrollTemplateService/getCountPayroll';
import useModal from '@/utils/useModal';

import AddHolidayRequest from './AddHolidayRequest';
import Holidays from './Holidays';
import PayrollListView from './PayrollListView';
import PayrollWage from './PayrollWage';
import PLReport from './PLReport';

const PayrollPage = () => {
  const [form] = Form.useForm();
  const [storedValue] = useSessionStorage('payrollListViewSearch');

  const filterPayrollTemplate = Form.useWatch('filterPayrollTemplate', form);
  const weekNumWatching = Form.useWatch('weekNum', form);
  const yearWatching = Form.useWatch('year', form);

  const [weekNumber, setWeekNumber] = useState(
    storedValue?.weekNumber ?? moment().week(),
  );

  const [isShowButtonCreateHoliday, setIsShowButtonCreateHoliday] =
    useState(false);
  const [shouldRefetchHolidays, setShouldRefetchHolidays] = useState(false);

  const [isDisplayModal, showModal, hiddenModal] = useModal();

  const hiddenModalHoliday = () => {
    form.setFieldsValue({
      holidays: [],
    });
    return hiddenModal();
  };

  const tabPanes = [
    {
      key: 'payroll',
      tab: 'Payroll List View',
      child: <PayrollListView form={form} />,
      forceRender: true,
    },
    {
      key: 'holiday',
      tab: 'Holidays',
      child: (
        <Holidays
          shouldRefetchHolidays={shouldRefetchHolidays}
          setShouldRefetchHolidays={setShouldRefetchHolidays}
          isShowButtonCreateHoliday={isShowButtonCreateHoliday}
          weekNum={weekNumber}
          setWeekNumber={setWeekNumber}
        />
      ),
      forceRender: true,
    },
    {
      key: 'wages',
      tab: 'Payroll Wage',
      child: (
        <PayrollWage weekNumber={weekNumber} setWeekNumber={setWeekNumber} />
      ),
      forceRender: true,
    },
    {
      key: 'pl-report',
      tab: 'P&L Report',
      child: <PLReport />,
      forceRender: true,
    },
  ];

  const breadCrumb = [
    { item: `Payroll - Week ${weekNumWatching}`, to: '/payroll' },
  ];

  const [queryString, setQueryString] = useQueryString();

  const handleTabChange = (value) => {
    setQueryString({ tab: value });
    setIsShowButtonCreateHoliday(value === 'holiday');
  };

  const { data: countData } = useFetch(
    ['countPayroll', yearWatching, weekNumWatching],
    () => {
      const filter = {
        year: yearWatching,
        weekNum: weekNumWatching,
      };
      return getCountPayroll(filter);
    },
  );

  const renderToggleMode = () => (
    <Form form={form}>
      <div className='header-actions'>
        <div className='template-mode'>
          <div>
            <div className='title-template-mode'>
              {countData && (
                <div
                  className={classNames('number-count', {
                    enable: !filterPayrollTemplate,
                  })}
                >
                  {countData?.flexiblePayRate ?? 0}
                </div>
              )}
              <p>Flexible Pay Rate</p>
            </div>
          </div>
          <FormItem
            name='isPayTemplate'
            valuePropName='checked'
            initialValue={get(storedValue, 'isPayTemplate', false)}
          >
            <Switch />
          </FormItem>
          <div className='title-template-mode'>
            {countData && (
              <div
                className={classNames('number-count', 'right', {
                  enable: filterPayrollTemplate,
                })}
              >
                {countData?.payTemplate ?? 0}
              </div>
            )}
            <p>Pay Template</p>
          </div>
        </div>
      </div>
    </Form>
  );

  return (
    <GeneralTemplate
      mainItem='Payroll'
      data={breadCrumb}
      headerText={`Week ${weekNumWatching}`}
      subTemplateClassName='payroll-container'
      hasButton
      buttonLink='#'
      buttonCallback={() => showModal(true)}
      buttonCustom={renderToggleMode()}
    >
      <Tabs
        className='job-tabs'
        tabPanes={tabPanes}
        activeKey={queryString?.tab ?? 'payroll'}
        onChange={handleTabChange}
        defaultActiveKey='payroll'
      />

      <AddHolidayRequest
        isDisplayModal={isDisplayModal}
        hiddenModal={hiddenModalHoliday}
        setShouldRefetchHolidays={setShouldRefetchHolidays}
        form={form}
      />
    </GeneralTemplate>
  );
};

export default PayrollPage;
