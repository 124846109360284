import React, { useEffect, useState } from 'react';

import { Form, Pagination } from 'antd';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  AutoComplete,
  Button,
  FormItem,
  GeneralTemplate,
  ListView,
  MultipleSelect,
} from '@/components';
import { DEBOUNCE_WAIT, PAGE_SIZE_OPTIONS } from '@/constants';
import { useFetch, usePagination, useSessionStorage } from '@/hooks';
import { useProviders } from '@/hooks/dropdowns';
import { CostService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import CostAndSupplierTab from '../../components/Tabs';
import { listWeek } from '../../constants';
import { costRoles } from '../mockData';
import { breadCrumb, columns } from './constants';
import './styles.scss';

const Costs = () => {
  const {
    currentPage,
    handleShowSizeChange,
    paginationPageSize,
    setCurrentPage,
  } = usePagination();
  const [searchTerm, setSearchTerm] = useState();
  const [sortOrder, setSortOrder] = useState('desc');

  const providers = useProviders();
  const [form] = Form.useForm();
  const history = useHistory();

  const [storedValue, setSession, removeSession] =
    useSessionStorage('costSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Get email and name of costs by searchTerm.
  const { data: emailAndNameOfCosts } = useFetch(
    ['getEmailAndNameOfCosts', searchTerm],
    () => [],
  );

  const {
    data: costs,
    isFetching,
    refetch,
  } = useFetch(
    ['getCosts', currentPage, currentPage, paginationPageSize, sortOrder],
    () =>
      CostService.getAll({
        ...form.getFieldsValue(),
        sortOrder,
        pageNumber: currentPage,
        pageSize: paginationPageSize,
      }),
  );

  const headerTable = generateHeaderTable(columns);

  const handleNavigate = (rowId) => history.push(`/edit-cost/${rowId}`);

  const handleSort = (order) =>
    setSortOrder(Formatter.makeAbbrevSortType(order));

  const onsearchTermChange = debounce(setSearchTerm, DEBOUNCE_WAIT);

  const onFilter = () => {
    setSession(form.getFieldsValue());
    refetch();
  };

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadCrumb}
      headerText='Costs'
      buttonContent='Create Cost'
      buttonLink='/create-cost'
      subTemplateClassName='cost-container'
    >
      <CostAndSupplierTab defaultActiveKey={breadCrumb[1].to} />

      <div className='cost-detail-container'>
        <div className='cost-detail-box-shadow'>
          <Form
            form={form}
            onFinish={onFilter}
            className='cost-info-input-container'
          >
            <FormItem name='searchTerm' label='Search' className='form-item'>
              <AutoComplete
                options={emailAndNameOfCosts}
                onSearch={onsearchTermChange}
                placeholder='Name or email address'
                defaultActiveFirstOption
              />
            </FormItem>

            <FormItem name='week' label='Choose week' className='form-item'>
              <MultipleSelect
                name='week'
                placeholder='Choose week'
                options={listWeek}
                value={form.getFieldValue('chooseWeek')}
              />
            </FormItem>

            <FormItem
              name='supplierTypes'
              label='Supplier type'
              className='form-item'
            >
              <MultipleSelect
                options={costRoles}
                name='supplierTypes'
                placeholder='Choose Supplier type'
              />
            </FormItem>

            <FormItem
              name='providerAllocated'
              label='Provider Allocated To'
              className='form-item'
            >
              <MultipleSelect
                options={providers}
                name='providerAllocated'
                placeholder='Provider Allocated To'
              />
            </FormItem>

            <div className='form-button'>
              <FormItem className='form-item'>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='yellow-button input-height button-search'
                >
                  Search
                </Button>
              </FormItem>
              <FormItem className='form-item'>
                <Button
                  className='filter-button input-height button'
                  onClick={() => {
                    form.resetFields();
                    removeSession();
                    refetch();
                  }}
                >
                  Clear Filters
                </Button>
              </FormItem>
            </div>
          </Form>
          <ListView
            onRow={handleNavigate}
            headerColumns={headerTable}
            infoDetail={costs?.data}
            scroll={{ y: 650 }}
            loading={isFetching}
            handleSort={handleSort}
            locale={{
              emptyText: 'No Costs match your search criteria',
            }}
          />
        </div>

        <div className='table-footer-container'>
          <div className='pagination-container'>
            <Pagination
              responsive
              showSizeChanger
              current={currentPage}
              total={costs?.totalCount}
              pageSize={paginationPageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              onChange={setCurrentPage}
              onShowSizeChange={handleShowSizeChange}
            />
          </div>
        </div>
      </div>
    </GeneralTemplate>
  );
};
export default Costs;
