import React, { useState, memo, useCallback } from 'react';

import { FileWordOutlined } from '@ant-design/icons';
import { Button, Modal as AntdModal, Spin, Form, message } from 'antd';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import {
  Input,
  Select,
  CheckboxGroup,
  Textarea,
  FormItem,
  Modal,
  AutoRenderImageOrPDF,
} from '@/components';
import { LIMIT_PHONE_NUMBER } from '@/constants';
import { useSkillTypes, useStatus } from '@/hooks/dropdowns';
import { ChangeStatusApplicationService } from '@/services';
import { getApplicantID } from '@/services/applicantService/get';
import { switchStatus } from '@/utils/switchStatus';
import './styles.scss';
import { useTenantContext } from '../../../TenantWrapper';

const ApplicantModal = ({
  applicantID,
  visible: visibleQuickView,
  onVisibleInfoModal,
  onReFetchApplicantPage,
}) => {
  const [form] = Form.useForm();
  const { listStatuses } = useStatus();
  const [rowKey, setRowKey] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [visibleStatusModal, setVisibleStatusModal] = useState({
    title: '',
    content: '',
    visible: false,
  });
  const listRoles = useSkillTypes();
  const { visible, title, content } = visibleStatusModal;
  const tenant = useTenantContext();

  const {
    data: dataApplicant,
    isFetching,
    refetch: refetchApplicant,
  } = useQuery(
    ['getApplicantID-detail', applicantID],
    () => getApplicantID(applicantID),
    {
      onSuccess: (data) => {
        const listRoleAplicant = data?.preferredRoles
          ?.split(',')
          ?.map((/** @type {string} */ role) => parseInt(role, 10));
        form.setFieldsValue({
          ...data,
          preferredRoles: listRoleAplicant,
        });
      },
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!applicantID,
    },
  );

  const { mutate: changeStatus, isLoading } = useMutation(
    ({ id, status }) =>
      ChangeStatusApplicationService.changeStatusApplication(id, status),
    {
      onSuccess: () => {
        refetchApplicant();
        onReFetchApplicantPage();
        setStatusValue();
      },
      onError: (error) => {
        setStatusValue();
        message.error(error.message || 'Can not change status');
      },
    },
  );

  const handleChangeStatus = (value, option) => {
    setStatusValue(value);
    setRowKey(applicantID);
    const status = switchStatus(value);

    setVisibleStatusModal({
      visible: true,
      title: status.title,
      content: status.content,
    });
  };

  const handleConfirmStatus = useCallback(() => {
    changeStatus({ id: rowKey, status: statusValue });

    setVisibleStatusModal({ visible: false });
  }, [changeStatus, rowKey, statusValue]);

  const checkStatus = (status) => {
    if (status === 0 || status) {
      return true;
    }

    return false;
  };

  const renderUploadFile = () => {
    if (dataApplicant?.cvUpload) {
      if (dataApplicant?.cvUpload?.includes('.doc')) {
        return (
          <a className='doc-file-container' href={dataApplicant?.cvUpload}>
            <FileWordOutlined className='file-icon' />
            <span className='file-title'>CV.doc</span>
          </a>
        );
      }

      return (
        <div className='applicant-pdf'>
          <AutoRenderImageOrPDF data={dataApplicant?.cvUpload} />
        </div>
      );
    }

    return <p className='text-desc'>No CV Uploaded</p>;
  };

  return (
    <AntdModal
      centered
      title={null}
      footer={null}
      visible={visibleQuickView}
      onCancel={() => onVisibleInfoModal(false)}
      wrapClassName='modal-applicants'
    >
      <Modal
        visible={visible}
        title={title}
        onClickNo={useCallback(() => {
          setVisibleStatusModal({ visible: false });
          setStatusValue();
        }, [])}
        onClickYes={handleConfirmStatus}
      >
        {content}
      </Modal>
      {isFetching || isLoading ? (
        <div className='modal-loading'>
          <Spin size='large' />
        </div>
      ) : (
        <Form form={form}>
          <div className='modal-header'>
            <p className='modal-title'>
              QuickView - {dataApplicant?.firstname} {dataApplicant?.lastname}
            </p>
            <div className='modal-actions'>
              <Link
                to={{
                  pathname: `/edit-applicant/${applicantID}`,
                }}
              >
                <Button className='btn-view'>View Full Application</Button>
              </Link>
              <Select
                disabled={checkStatus(dataApplicant?.status)}
                options={listStatuses}
                placeholder='Status'
                onChange={(value, option) => handleChangeStatus(value, option)}
                value={statusValue || dataApplicant?.status}
              />
            </div>
          </div>
          <div className='modal-container'>
            <div className='modal-content'>
              <div className='span-col-2 row-box'>
                <FormItem disabled name='firstname' label='First Name'>
                  <Input
                    disabled
                    className='item-filed'
                    maxLength={48}
                    placeholder='First Name'
                  />
                </FormItem>
                <FormItem name='lastname' label='Last Name'>
                  <Input
                    disabled
                    className='item-filed'
                    placeholder='Last Name'
                    maxLength={48}
                  />
                </FormItem>
                <FormItem name='email' label='Email'>
                  <Input placeholder='Email' disabled className='item-filed' />
                </FormItem>
                <FormItem name='phone' label='Phone Number'>
                  <Input
                    maxLength={LIMIT_PHONE_NUMBER}
                    placeholder='Phone Number'
                    disabled
                    className='item-filed'
                  />
                </FormItem>
              </div>
              <div className='row-info'>
                <div className='row-item'>
                  <p className='text-label'>Roles</p>
                  <FormItem name='preferredRoles'>
                    <div className='box-roles'>
                      <CheckboxGroup
                        value={form.getFieldValue('preferredRoles')}
                        options={listRoles}
                      />
                    </div>
                  </FormItem>
                  <p className='text-label'>Employment History</p>
                  <FormItem
                    label='Don’t have a CV? Please give us information of your last 3
                    jobs.'
                    className='box-area'
                    name='leave'
                  >
                    <Textarea
                      disabled
                      placeholder='Please include start date, finish date, job title and responsibilities'
                    />
                  </FormItem>
                  <FormItem
                    className='box-area'
                    name='tellUs'
                    label={[
                      'Tell us why you want to apply for',
                      `${tenant.name} and what makes you a great candidate?`,
                    ].join(' ')}
                  >
                    <Textarea
                      disabled
                      placeholder={[
                        `I want to work for ${tenant.name} because I think that`,
                        'I will be a great addition to the team I am a hard worker and have experience in the Warehouse Industry.',
                      ].join(' ')}
                    />
                  </FormItem>
                </div>
                <div className='row-item'>
                  <p className='text-label'>CV</p>
                  {renderUploadFile()}
                  {/* {dataApplicant?.cvUpload ? (
                    <div className="applicant-pdf">
                      <AutoRenderImageOrPDF data={dataApplicant?.cvUpload} />
                    </div>
                  ) : (
                    <p className="text-desc">No CV Uploaded</p>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </AntdModal>
  );
};

export default memo(ApplicantModal);

ApplicantModal.propTypes = {
  applicantID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.bool,
  onVisibleInfoModal: PropTypes.func,
  onReFetchApplicantPage: PropTypes.func,
};
