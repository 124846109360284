import { useQuery } from 'react-query';

import { fetchCertificates } from '@/services/dropdownServices/getCertificates';

const key = 'fetchCertificates';

export function useCertificates({ select } = {}) {
  const defaultSelect = (data) => {
    return data.reduce((result, certificate) => {
      result[certificate.slug] = certificate;

      return result;
    }, {});
  };

  select = select || defaultSelect;

  const { data, ...rest } = useQuery([key], () => fetchCertificates(), {
    select,
    refetchOnWindowFocus: false,
  });

  return {
    ...rest,
    data: data || {},
  };
}

export function useELearnings() {
  const { data } = useCertificates({
    select: (data) => {
      return data.reduce((result, certificate) => {
        if (certificate.type !== 'e-learning') {
          return result;
        }

        result[certificate.slug] = certificate;

        return result;
      }, {});
    },
  });

  return data;
}

export function useLicences() {
  const { data } = useCertificates({
    select: (data) => {
      return data.reduce((result, certificate) => {
        if (certificate.type !== 'licence') {
          return result;
        }

        result[certificate.slug] = certificate;

        return result;
      }, {});
    },
  });

  return data;
}
