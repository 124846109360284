import React from 'react';

import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button, Input, FormItem } from '@/components';

import { useTenantContext } from '../../../../../TenantWrapper';

const Header = ({
  providerName,
  providerLocation,
  invoiceWeek,
  invoiceYear,
  rangeWeekDate,
  total,
  timesheetId,
}) => {
  const history = useHistory();
  const { currency } = useTenantContext();

  return (
    <div className='invoice-header'>
      <div className='header-item row'>
        <div className='text-container'>
          <p>{providerName ?? <Spin />}</p>
          <p>{providerLocation ?? <Spin />}</p>
          <p>
            {invoiceWeek && invoiceYear ? (
              `Week ${invoiceWeek}/${invoiceYear}`
            ) : (
              <Spin />
            )}
          </p>
          <p>{rangeWeekDate}</p>
        </div>
        <div className='button-container'>
          <Button
            onClick={() => history.push(`/timesheet-detail/${timesheetId}`)}
            className='yellow-button'
          >
            View Timesheet
          </Button>
        </div>
      </div>
      <div className='header-item'>
        <FormItem
          name='invoiceId'
          label='Invoice Number'
          className='form-item'
          colon
        >
          <Input placeholder='Invoice Number will be auto create' />
        </FormItem>
        <FormItem
          name='invoiceDate'
          label='Date/Tax Point'
          className='form-item'
          colon
        >
          <Input disabled placeholder='Date/Tax Point' />
        </FormItem>
        <FormItem
          name='customerSageCode'
          label='Account'
          className='form-item'
          colon
        >
          <Input />
        </FormItem>
      </div>
      <div className='header-item'>
        <p>
          Total Invoice Amount: {currency}
          {total?.toFixed(2) ?? 0}
        </p>
      </div>
    </div>
  );
};

Header.propTypes = {
  providerName: PropTypes.string,
  providerLocation: PropTypes.string,
  invoiceWeek: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invoiceYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeWeekDate: PropTypes.string,
  total: PropTypes.number,
  timesheetId: PropTypes.string,
};

Header.defaultProps = {
  providerName: '',
  providerLocation: '',
  invoiceWeek: '',
  invoiceYear: '',
  rangeWeekDate: '',
  total: 0,
  timesheetId: '',
};

export default Header;
