import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const getJobSeekersByStatus = async (rowId, status) => {
  try {
    const endpoint = `/jobs/positions/${rowId}/job_responses/seekers`;

    const res = await request.get(endpoint, {
      params: { filter: { status } },
    });

    const normalizeData = normalizeIndexResponse(res);

    return normalizeData?.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};
