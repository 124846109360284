/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef } from 'react';

import _ from 'lodash';
import QueryString from 'qs';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

const removeQueryCharacter = (search) => {
  const s2 = search.substring(1);
  return QueryString.parse(s2);
};

export default function useQueryString(
  onChange = () => {},
  firstLoadQuery = () => {},
  getSearchLocation = true,
) {
  const location = useLocation();
  const history = useHistory();
  const isFirst = useRef(true);

  const updateQuery = (data) => {
    let qsObject = {};

    if (getSearchLocation) {
      qsObject = removeQueryCharacter(location.search);
    }

    if (!_.isEqual(qsObject, data)) {
      history.push({
        search: `?${QueryString.stringify({ ...qsObject, ...data })}`,
      });
    }
  };

  const qsObject = removeQueryCharacter(location.search);

  const queryString = useMemo(() => qsObject, [qsObject]);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      firstLoadQuery(queryString);
    }

    onChange(queryString);
  }, [queryString]);

  return [queryString, updateQuery];
}
