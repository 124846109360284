import React from 'react';

import { Form } from 'antd';

import { Button, DatePicker, FormItem, Input, Select } from '@/components';
import {
  LIMIT_PHONE_NUMBER,
  MARITAL,
  listGenders,
  vocativeTitles,
} from '@/constants';
import { date } from '@/utils';
import {
  rulesBirth,
  rulesEmail,
  rulesPhone,
  rulesPostcode,
  rulesSelect,
  rulesTextInput,
} from '@/utils/rulesInput';

import './styles.scss';

const BasicInformation = () => {
  const form = Form.useFormInstance();

  const gender = Form.useWatch(
    ['omniEmployeeAttributes', 'employeeGender'],
    form,
  );

  return (
    <div className='seeker-basic-information'>
      <FormItem
        required
        name='firstname'
        label='First Name'
        rules={rulesTextInput('First Name')}
      >
        <Input placeholder='First Name' maxLength={48} />
      </FormItem>
      <FormItem
        required
        name='lastname'
        label='Last Name'
        rules={rulesTextInput('Last Name')}
      >
        <Input placeholder='Last Name' maxLength={48} />
      </FormItem>
      <FormItem required name='email' label='Email' rules={rulesEmail()}>
        <Input placeholder='Email' />
      </FormItem>
      <FormItem required name='phone' label='Phone Number' rules={rulesPhone()}>
        <Input maxLength={LIMIT_PHONE_NUMBER} placeholder='Phone Number' />
      </FormItem>
      <FormItem label='Password'>
        <Button className='reset-password yellow-button'>Reset Password</Button>
      </FormItem>
      <FormItem label='Manual Reset Password' className='manual-reset-password'>
        <div className='password-and-repeat'>
          <FormItem>
            <Input placeholder='Password' />
          </FormItem>
          <FormItem>
            <Input placeholder='Repeat Password' />
          </FormItem>
        </div>
      </FormItem>

      <FormItem
        required
        name='title'
        label='Title'
        rules={rulesSelect('Title')}
      >
        <Select options={vocativeTitles} placeholder='Title' required />
      </FormItem>
      <FormItem
        required
        name='marital'
        label='Marital Status'
        rules={rulesSelect('Marital Status')}
      >
        <Select options={MARITAL} placeholder='Marital Status' required />
      </FormItem>
      <FormItem
        required
        name='birth'
        label='Date of Birth'
        className='birth-form-item'
        rules={rulesBirth}
        normalize={date.convertDateTime}
      >
        <DatePicker name='birth' />
      </FormItem>
      <FormItem
        className='gender-form-item'
        name={['omniEmployeeAttributes', 'employeeGender']}
        required
        rules={rulesTextInput('Gender')}
        label='Gender at Birth (or on Documentation)'
      >
        <Select
          value={gender}
          options={listGenders}
          placeholder='Gender'
          onChange={(value) =>
            form.setFieldsValue({
              omniEmployeeAttributes: {
                employeeGender: value,
              },
            })
          }
          required
        />
      </FormItem>

      <FormItem
        label='Address - Building'
        name='address'
        rules={rulesTextInput('Address - Building')}
        required
      >
        <Input placeholder='Address - Building' />
      </FormItem>
      <FormItem
        label='Street'
        name='address2'
        required
        rules={rulesTextInput('Street')}
      >
        <Input placeholder='Street' />
      </FormItem>
      <FormItem
        label='City'
        name='city'
        required
        rules={rulesTextInput('City')}
      >
        <Input placeholder='City' />
      </FormItem>

      <FormItem
        label='Post Code'
        name='zip'
        required
        rules={rulesPostcode(form, 'zip')}
      >
        <Input placeholder='Post Code' />
      </FormItem>
      <FormItem
        required
        label='Registration Date'
        name='createdAt'
        normalize={date.convertDateTime}
      >
        <DatePicker name='createdAt' disabled />
      </FormItem>
    </div>
  );
};

export default BasicInformation;
