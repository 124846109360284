import request from '@/request';

const createJobType = async (
  /** @type {string|number} */ timesheetId,
  /** @type {object} */ jobTypeInfo,
  /** @type {string} */ employerId,
  /** @type {boolean} */ isPayroll,
) => {
  const {
    jobResponsesAttributes,
    items,
    locationId,
    content,
    breakTime,
    payrate,
    posted,
    chargeRate,
    skillId,
  } = jobTypeInfo;

  const resAttributes = jobResponsesAttributes.map((attribute) => {
    const { seekerName, ...res } = attribute;
    return res;
  });

  const formattedItems = items.map((item) => {
    const { startTime, endTime } = item;

    const startAt = startTime.unix();
    const endAt = endTime.unix();

    return {
      startAt,
      endAt,
    };
  });

  const position = {
    shiftAttributes: {
      locationId,
      employerId,
    },
    commentsAttributes: [{ content }],
    schedule: { items: formattedItems },
    jobResponsesAttributes: resAttributes,
    breakTime,
    payrate,
    posted,
    chargeRate,
    skillId,
  };

  const res = await request.post(
    `trackers/timekeeping/timesheets/${timesheetId}/positions`,
    { position, isPayroll },
  );

  return res.data;
};

export default createJobType;
