import { isEmpty } from 'lodash';
import moment from 'moment';

import { DAY_OF_MONTH, REGEX_EXPIRY_DATE } from '@/constants';

export const getDuration = (
  /** @type {moment.MomentInput} */ startTime,
  /** @type {moment.MomentInput} */ endTime,
) =>
  // note: startTime: @type:string, endTime: @type:string
  moment
    .duration(
      moment(endTime, 'YYYY/MM/DD HH:mm').diff(
        moment(startTime, 'YYYY/MM/DD HH:mm'),
      ),
    )
    .asHours();

export const getEndTime = (/** @type {string} */ startTime) => {
  // note: startTime: @type:string ('11:30')

  const [hour, min] = startTime.split(':');

  const date = moment.utc().hour(hour).minute(min);

  date.add({ hour: 1 });

  return date.format('HH:mm');
};

export const toString = (
  /** @type {moment.MomentInput} */ date,
  /** @type {string} */ time,
) => {
  // note: date: @type:moment, hour: @type:string
  // eslint-disable-next-line prefer-template
  const dateToMoment = moment(date).format('YYYY-MM-DD');
  const dateString = `${dateToMoment} ${time}`;

  return dateString;
};

export const timestampTo = (/** @type {number} */ timestamp) => {
  // note: timestamp: @type:timestamp

  const dateTime = new Date(timestamp * 1000);

  const date = moment(dateTime).format('DD MMM');
  const time = moment(dateTime).format('HH:mm');
  const fullDate = moment(dateTime).format('YYYY-MM-DD HH:mm');

  return { date, time, fullDate };
};

export const toDateTimeItems = (/** @type {any[]} */ items) => {
  // note item:@type : array
  //  ex: items = [{startAt: timestamp, endAt: timestamp}]

  const timestampItems = items.map(
    (/** @type {{ startAt: any; endAt: any; }} */ item) => {
      const { startAt, endAt } = item;

      const {
        date: startDate,
        time: startTime,
        fullDate: fullStartDate,
      } = timestampTo(startAt);

      const {
        date: finishDate,
        time: finishTime,
        fullDate: fullEndDate,
      } = timestampTo(endAt);

      const duration = getDuration(fullStartDate, fullEndDate);

      return {
        startDate,
        startTime,
        finishDate,
        finishTime,
        duration,
        fullStartDate,
        fullEndDate,
      };
    },
  );

  return timestampItems;
};

/**
 *
 * @param {string} expiryDate
 * @returns
 */
export const splitExpiryDate = (expiryDate) => {
  const exMonth = moment(expiryDate, 'MM/YYYY').month();
  const exYear = moment(expiryDate, 'MM/YYYY').year();

  const today = moment().toDate();
  const someday = moment().toDate();
  someday.setFullYear(exYear, exMonth, DAY_OF_MONTH);

  return {
    exMonth,
    exYear,
    today,
    someday,
  };
};

/**
 *
 * @param {string} expiryDate
 * @returns
 */
const isExpiryDateValid = (expiryDate) => REGEX_EXPIRY_DATE.test(expiryDate);

/**
 *
 * @param {string} expiryDate
 * @returns
 */
export const checkExpiryDate = async (expiryDate) => {
  const { today, someday } = splitExpiryDate(expiryDate);

  // Do not need check when value is empty.
  if (isEmpty(expiryDate)) return true;

  if (!isExpiryDateValid(expiryDate)) {
    throw Error('Please enter a valid expiration date');
  }

  if (someday < today) {
    throw Error('Please enter a valid expiration date');
  }

  return true;
};

export const convertDateTime = (/** @type {moment.MomentInput} */ dateTime) =>
  dateTime && moment(dateTime).format('YYYY-MM-DD');

export const calculateBonusTime = (
  /** @type {string | number} */ bonusType,
  /** @type {number} */ bonusValue,
) => {
  const TYPES = { no_bonus: 0, deduction_hours: -1, bonus_hours: 1 };
  const type = TYPES[bonusType] || TYPES.no_bonus;

  return bonusValue * type;
};

export const calculateHoursInShift = ({
  checkinTime,
  checkoutTime,
  breakTime,
  bonusType,
  bonusValue,
}) => {
  if (!checkinTime || !checkoutTime) return 0;

  const breakHours = parseFloat(breakTime) / 60 || 0;
  const hours =
    getDuration(checkinTime, checkoutTime) -
    breakHours +
    calculateBonusTime(bonusType, bonusValue);
  return hours;
};

export const getCurrentWeekDays = (/** @type {moment.MomentInput} */ date) => {
  const weekStart = moment(date).startOf('week');

  const days = [];
  for (let i = 0; i <= 6; i += 1) {
    days.push(moment(weekStart).add(i, 'days').format('DD/MM/YYYY'));
  }

  return days;
};
export const datesOfWeek = (/** @type {moment.MomentInput} */ value) => ({
  mon: moment(value).weekday(0),
  tues: moment(value).weekday(1),
  wed: moment(value).weekday(2),
  thurs: moment(value).weekday(3),
  fri: moment(value).weekday(4),
  sat: moment(value).weekday(5),
  sun: moment(value).weekday(6),
  start: moment(value).startOf('week'),
  end: moment(value).endOf('week'),
});

const weekFormat = 'DD/MM';
const fullDateFormat = 'DD/MM/YYYY';

export const customWeekStartEndFormat = (/** @type {string} */ value) =>
  `${datesOfWeek(value).start.format(weekFormat)} - ${datesOfWeek(
    value,
  ).end.format(fullDateFormat)}`;

export const convertToISOString = (/** @type {moment.MomentInput} */ value) =>
  moment(value).toISOString();

/**
 * return the difference between given date and today in days
 * @param {import('moment').MomentInput} date
 * @returns {number}
 */
export const getDateDiffInDays = (date) => {
  const today = moment().startOf('d');
  return moment(date).diff(today, 'd');
};
