import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getTimesheetPositions = async ({
  /** @type {string|number} */ id,
  /** @type {'timesheet'|'payroll'} */ resource,
}) => {
  const res = await request.get(
    `trackers/timekeeping/timesheets/${id}/positions`,
    {
      params: {
        filter: {
          resource,
        },
      },
    },
  );
  const data = normalizeIndexResponse(res);
  return data?.data;
};

export default getTimesheetPositions;
