import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import favourite from '@/assets/icons/favourite.png';
import jobs from '@/assets/icons/jobs.png';
import thumbsup from '@/assets/icons/thumbsup.png';
import { icons } from '@/constants';

import Image from '../Image';

import './styles.scss';

const SeekerOption = ({
  className,
  src,
  name,
  like,
  rate,
  onClick,
  id,
  setIsShowProfile,
  setSeekerId,
}) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
  <li
    key={id}
    onClick={onClick}
    className={classNames('seeker-popout', className)}
  >
    <Image
      containerClassName='ava-container'
      className='seeker-img'
      src={src}
      alt='avatar'
    />
    <div className='info-container'>
      <h2>{name}</h2>
      <div className='icons above'>
        <img className='jobs-icon seeker-icon' src={jobs} alt='' />
        <span className='text-info'>{like}</span>
        <img className='thumbsup-icon seeker-icon' src={thumbsup} alt='' />
        <span className='text-info'>{rate}</span>
        <img className='favourite-icon seeker-icon' src={favourite} alt='' />
      </div>
      <div className='icons'>
        {icons.map((icon) => (
          <img
            className={classNames('seeker-icon', icon.className)}
            src={icon.src}
            alt={icon.className}
            key={icon.className}
          />
        ))}
      </div>
    </div>
    <div className='status-icon-container'>
      <InfoCircleOutlined
        onClick={(e) => {
          e.stopPropagation();

          setSeekerId(id);
          setIsShowProfile(true);
        }}
        className='status-icon'
      />
    </div>
  </li>
);

SeekerOption.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  like: PropTypes.number,
  rate: PropTypes.number,
  onClick: PropTypes.func,
  setIsShowProfile: PropTypes.func,
  setSeekerId: PropTypes.func,
};

SeekerOption.defaultProps = {
  className: '',
  src: '',
  name: '',
  id: '',
  like: 0,
  rate: 0,
  onClick: () => {},
  setIsShowProfile: () => {},
  setSeekerId: () => {},
};

export default SeekerOption;
