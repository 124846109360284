import React, { useState } from 'react';

import { Form, Alert, message } from 'antd';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ModalConfirm, GeneralTemplate, Button, FormItem } from '@/components';
import { useCheckUkIreland } from '@/features/nationality/hooks';
import { CandidatesService } from '@/services';
import { checkExistEmploymentOrCV } from '@/utils/seeker';
import useModal from '@/utils/useModal';

import { FORM_TYPES } from '../constants';
import FormCandidate from '../FormCandidate';

import '../styles.scss';

const breadcrumb = [
  { item: 'Candidate', to: '/recruitment/candidate' },
  { item: 'Create New Candidate', to: '/create-candidate' },
];

const missingFields = {
  passport: 'Please Upload Passport or Birth certificate and ni proof.',
  birthCertificate: 'Please Upload Passport or Birth certificate and ni proof.',
  niProof: 'Please Upload Passport or Birth certificate and ni proof.',
  contract: 'Missing Contract file',
  comments: 'Please add comments',
  shareCode: 'Missing Share Code',
  employeeWpExpiry: 'The share code expiration date is required',
  shareCodeDocument: 'Share code document is required',
};

/**
 *
 * @param {Array<[boolean, string]>} values
 * @returns {String}
 */
const returnContent = (values = []) => {
  if (!isArray(values)) return '';
  const value = values.find(([item]) => item);
  return value?.at(1) ?? '';
};

const CreateNewCandidate = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [displayModal, showModal, hiddenModal] = useModal();
  const checkUkIreland = useCheckUkIreland();

  const [modalContent, setModalContent] = useState();
  const [modalStatus, showModalStatus, hiddenModalStatus] = useModal();

  const [error, setError] = useState('');
  const [activeKey, setActiveKey] = useState(['Contact Information']);

  const {
    mutate: createCandidate,
    isLoading,
    isSuccess,
  } = useMutation(
    async (newCandidate) =>
      CandidatesService.createCandidateUpdated(newCandidate),
    {
      onSuccess: () => {
        message.success('Candidate created successfully');
        history.push('/recruitment/candidate');
      },
      onError: (err) => {
        if (err) {
          setError(err.message);
          hiddenModal();
          window.scrollTo(0, 0);
        }
      },
    },
  );

  const handleSubmit = () => {
    const candidateStatus = form.getFieldValue('candidateStatus');
    const shareCode = form.getFieldValue('shareCode');
    const employeeWpExpiry = form.getFieldValue('employeeWpExpiry');
    const comments = form.getFieldValue('comments');
    const nationality = form.getFieldValue('nationality');
    const isUkOrIreland = checkUkIreland(nationality);

    // field from documentation section
    const documentationDocs = form.getFieldValue('documentationDocs');
    const contractFileFound = (documentationDocs ?? []).find(
      (item) => item.typeValue === 'contractFile',
    );

    // field from RTW section
    const rightToWorkDocs = form.getFieldValue('rightToWorkDocs');
    const passportId1FileFound = rightToWorkDocs.find(
      (item) => item.typeValue === 'passportId1File',
    );
    const birthCertificateFound = rightToWorkDocs.find(
      (item) => item.typeValue === 'birthCertificate',
    );
    const niProofFound = rightToWorkDocs.find(
      (item) => item.typeValue === 'niProof',
    );

    const shareCodeFileValue = form.getFieldValue('shareCodeFileValue');

    // Case 1: includes uk or ireland
    // Checks: is approved
    // is include uk or ireland
    // not empty Passport file uploaded or birth certificate and ni proof
    const hasMissingFields =
      (!passportId1FileFound?.fileValue &&
        (!birthCertificateFound?.fileValue || !niProofFound?.fileValue)) ||
      !contractFileFound?.fileValue ||
      isEmpty(comments);

    if (candidateStatus === 1 && isUkOrIreland && hasMissingFields) {
      const fields = [];

      if (
        !passportId1FileFound?.fileValue &&
        (!birthCertificateFound?.fileValue || !niProofFound?.fileValue)
      ) {
        fields.push([!passportId1FileFound?.fileValue, missingFields.passport]);
        fields.push([
          !birthCertificateFound?.fileValue,
          missingFields.birthCertificate,
        ]);
        fields.push([!niProofFound?.fileValue, missingFields.niProof]);
      } else if (!contractFileFound?.fileValue) {
        fields.push([!contractFileFound?.fileValue, missingFields.contract]);
      } else {
        fields.push([isEmpty(comments), missingFields.comments]);
      }

      setModalContent(returnContent(fields));
      return showModalStatus();
    }

    // Case 2: not includes uk or ireland
    // Checks: is approved
    // not empty share code or contract file or comments
    if (
      candidateStatus === 1 &&
      !isUkOrIreland &&
      (!shareCode ||
        !contractFileFound?.fileValue ||
        isEmpty(comments) ||
        isEmpty(employeeWpExpiry) ||
        isEmpty(shareCodeFileValue))
    ) {
      const content = returnContent([
        [!shareCode, missingFields.shareCode],
        [!contractFileFound?.fileValue, missingFields.contract],
        [isEmpty(comments), missingFields.comments],
        [isEmpty(employeeWpExpiry), missingFields.employeeWpExpiry],
        [isEmpty(shareCodeFileValue), missingFields.shareCodeDocument],
      ]);
      setModalContent(content);

      return showModalStatus();
    }
    return createCandidate(form.getFieldsValue());
  };

  const handleGoBack = () => history.goBack();

  const validateBeforeSubmit = () => {
    const isExistCVOrPastjobs = checkExistEmploymentOrCV(form);
    if (!isExistCVOrPastjobs) {
      message.warning(
        // eslint-disable-next-line quotes
        "Please provide Employment History OR seeker's CV",
      );
      return;
    }
    showModal();
  };

  return (
    <GeneralTemplate
      mainItem='Recruitment'
      headerText='Create New Candidate'
      data={breadcrumb}
      hasButton={false}
    >
      <div className='candidate-body'>
        {error && (
          <Alert
            message='Error'
            description={error}
            type='error'
            showIcon
            closable
            onClose={() => setError('')}
          />
        )}
        <Form
          form={form}
          onFinish={validateBeforeSubmit}
          onFinishFailed={(err) => {
            const errorName = err.errorFields[0].name;

            setActiveKey([
              'Contact Information',
              'Licences and Docs',
              'Past Work Details',
              'Worker Payroll and AWR information',
              'References and Medical Information',
              'Comments and Status updates',
            ]);
            form.scrollToField(errorName, {
              skipOverflowHiddenElements: true,
              block: 'center',
              behavior: 'smooth',
            });
          }}
          initialValues={{
            formType: FORM_TYPES.create,
            convict: 'Yes',
            createdAt: moment(),
            worktime: 0,
            vacancyApplicantLanguagesAttributes: [],
            maxWeeklyHours: 20,
            studentMaxHours: 20,
          }}
        >
          <FormItem name='formType' hidden />
          <FormCandidate activeKey={activeKey} setActiveKey={setActiveKey} />
          <div className='candidate-footer'>
            <Button
              className='btn-cancel'
              onClick={() => history.push('/recruitment/candidate')}
            >
              Cancel
            </Button>
            <Button className='btn-save' htmlType='submit'>
              Save
            </Button>
          </div>
        </Form>
      </div>
      <ModalConfirm
        title='Create New Candidate?'
        titleSuccess='Candidate created successfully'
        visible={displayModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleGoBack : handleSubmit}
        onClickNo={hiddenModal}
      >
        Are you sure you want to create new Candidate?
      </ModalConfirm>
      <ModalConfirm
        titleSuccess={modalContent}
        visible={modalStatus}
        onClickYes={hiddenModalStatus}
        isSuccess
      />
    </GeneralTemplate>
  );
};

export default CreateNewCandidate;
