import request from '@/request';

const endpoint = 'payments/employee_holidays';

const approvePayrollHolidayStatus = async ({
  holidayId,
  numberDaysProcess,
}) => {
  const { data } = await request.patch(`${endpoint}/${holidayId}`, {
    employeeHoliday: {
      status: 'approved',
      numberDaysProcess,
    },
  });

  return data;
};

export default approvePayrollHolidayStatus;
