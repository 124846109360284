import moment from 'moment';

import { DATE_FORMAT } from '@/constants';
import request from '@/request';
import { time } from '@/utils';
import { ServiceError } from '@/utils/errorUtil';
import { Normalize, FormatShowStrategy } from '@/utils/normalizeResponse';

export async function getInvoiceId(invoiceId, currency) {
  try {
    const endpoint = `trackers/invoicing/invoices/${invoiceId}`;
    const res = await request.get(endpoint);

    const normalize = new Normalize();
    const strategy = new FormatShowStrategy(res);
    normalize.setStrategy(strategy);
    const { data } = normalize.formatResponse() ?? {};

    const { invoiceDetails = [], weekStartDate, weekEndDate } = data ?? {};

    const hoursSection = invoiceDetails.filter(
      (item) => item.invoiceDetailType === 'labour',
    );

    const groupByLabourId = hoursSection.group(
      ({ labourTypeId }) => labourTypeId,
    );

    const sameLabourIds = Object.values(groupByLabourId).filter(
      (item) => item.length > 1,
    );
    const diffLabourIds = Object.values(groupByLabourId)
      .filter((item) => item.length === 1)
      ?.flat();

    const groupByHoursAndRates = Object.values(sameLabourIds)
      .map((item) => {
        const groupByRate = item.group(({ omniRate }) => omniRate);

        const sameOmniRates = Object.values(groupByRate)
          .filter((it) => it.length > 1)
          ?.flat();
        const diffOmniRates = Object.values(groupByRate)
          .filter((it) => it.length === 1)
          ?.flat();

        const result = sameOmniRates.reduce((hash, value) => {
          if (!hash[value.omniRate]) {
            hash[value.omniRate] = {
              ...value,
              hours: value.hours,
              omniNetPay: value.hours * value.omniRate ?? 0,
              omniVat: (20 / 100) * (value.hours * value.omniRate) ?? 0,
            };
          } else hash[value.omniRate].hours += value.hours;
          return hash;
        }, {});
        const groupSameRate = Object.values(result).map((resItem) => ({
          ...resItem,
          invoiceLine: `${resItem.hours} Hours @ Per ${resItem.omniRate}`,
        }));

        return [...diffOmniRates, ...groupSameRate];
      })
      ?.flat();

    const hoursSectionGroup = [...diffLabourIds, ...groupByHoursAndRates];

    const formattedHours = hoursSectionGroup.map((item) => {
      const omniRate = item?.omniRate ?? 0;
      const hours = item?.hours ?? 0;
      const omniNetPay = hours * omniRate ?? 0;
      const omniVat = (20 / 100) * omniNetPay ?? 0;
      const [startDateOrigin, endDateOrigin] = item?.dateRangeOrigin?.split(
        ' - ',
      ) ?? [weekStartDate, weekEndDate];

      const formattedStartDate = time.formatDate(startDateOrigin);
      const formattedEndDate = time.formatDate(endDateOrigin);
      const dateRange = `${formattedStartDate} - ${formattedEndDate}`;

      return {
        ...item,
        hours,
        totalValue: hours,
        omniVat: omniVat.toFixed(2),
        omniRate,
        omniNetPay: omniNetPay.toFixed(2),
        omniNetPayAmount: omniNetPay.toFixed(2),
        invoiceLine: `${hours ?? 0} Hours @ ${currency}${omniRate ?? 0} Per Hour`,
        description: `For services provided - ${dateRange}`,
      };
    });

    const costsSection = invoiceDetails.filter(
      (item) => item.invoiceDetailType === 'cost',
    );

    const formattedCosts = costsSection.map((item) => {
      const omniNetPay = parseFloat(item?.omniNetPay);
      const omniVat = (20 / 100) * omniNetPay ?? 0;
      const omniGross = (omniNetPay + omniVat).toFixed(2);

      return {
        ...item,
        omniVat,
        omniGross,
        omniNetPay,
        omniNetPayAmount: omniNetPay,
      };
    });
    const convertInvoiceDate = moment(data.invoiceDate).format(DATE_FORMAT);
    return {
      ...data,
      weekStartDate: time.formatDate(weekStartDate),
      weekEndDate: time.formatDate(weekEndDate),
      invoiceDate: convertInvoiceDate,
      hoursSection: formattedHours,
      costsSection: formattedCosts,
      invoiceDetails: [...costsSection, ...hoursSectionGroup],
    };
  } catch (error) {
    throw new ServiceError(error);
  }
}
export default getInvoiceId;
