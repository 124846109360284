/* eslint-disable no-shadow */

import React, { useRef, useEffect, useMemo, useState } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Form, message, Modal as AntdModal, Spin } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import {
  Button,
  ColorCheckbox,
  ModalConfirm,
  Pagination,
  PDF,
} from '@/components';
import { PAGE_SIZE_OPTIONS } from '@/constants';
import {
  usePagination,
  useFetch,
  useReactToPdf,
  useSessionStorage,
} from '@/hooks';
import useWindowSize from '@/hooks/useWindow';
import request from '@/request';
import { InvoiceService, TimesheetService } from '@/services';
import { InvoiceSendMail } from '@/services/mandrill';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import openFilePDFNewTab from '@/utils/openFilePDFNewTab';
import uploadFileS3 from '@/utils/uploadFileS3';
import useModal from '@/utils/useModal';

import ListInvoices from '../components/InvoicesTable';
import { VIEW_SEND_PRINTEDS } from '../constants';
import FormFields from '../formFields';
import ViewTimesheetPDF from '../ViewTimesheetPDF';

import './styles.scss';

const noopify = (possibleFunction) =>
  typeof possibleFunction === 'function' ? possibleFunction : () => {};

const WIDTH_RATIO = 1.6;

// eslint-disable-next-line react/prop-types
const ViewSend = ({ form, activeKey }) => {
  const ref = useRef();
  const windowSize = useWindowSize();
  const [to, setTo] = useState();
  const [linkDownloadTimesheetPDF, setLinkDownloadTimesheetPDF] = useState();
  const [linkDownloadInvoicePDF, setLinkDownloadInvoicePDF] = useState();
  const [isShowDetail, setIsShowDetail] = useState({
    isDownload: false,
    isEmailInvoice: false,
  });
  const [invoiceId, setInvoiceId] = useState();
  const [checkedId, setCheckedId] = useState();
  const [invoiceFile, setInvoiceFile] = useState();
  const [timesheetId, setTimesheetId] = useState('');
  const [isDownloadPDF, setIsDownloadPDF] = useState(false);
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const [uploadedProgress, setUploadedProgress] = useState(0);
  const [isShowInvoice, setIsShowInvoice] = useState(false);
  const [isCreatePDF, setIsCreatePDF] = useState(false);
  const [visibleTimeSheetPDF, setVisibleTimeSheetPDF] = useState(false);

  const { toPdf } = useReactToPdf({ targetRef: ref });

  const year = Form.useWatch('year', form);
  const stateWeekNum = Form.useWatch('weekNum', form);

  const { isDownload, isEmailInvoice } = isShowDetail;

  const [storedValue, setSession, removeSession] = useSessionStorage(
    'invoicingViewSearch',
  );

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickPreviewInvoice = () => {
    openFilePDFNewTab(invoiceFile);
  };

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'providerName',
      sorter: (a, b) => a.providerName.localeCompare(b.providerName),
      align: 'center',
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      align: 'center',
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      align: 'center',
    },
    {
      title: 'Year',
      dataIndex: 'invoiceYear',
      align: 'center',
    },
    {
      title: 'Payment Week',
      dataIndex: 'invoiceWeek',
      align: 'center',
    },
    {
      title: 'Payment Week dates',
      dataIndex: 'paymentWeekDate',
      align: 'center',
    },
    {
      title: 'Invoice Number',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Last edited By',
      dataIndex: 'lastEditedBy',
      align: 'center',
    },
    {
      title: 'Last edited At',
      dataIndex: 'lastEditedAt',
      align: 'center',
      render: (data) => data && moment(data).format('DD/MM/YYYY'),
    },
    {
      title: 'View Invoice',
      dataIndex: 'filename',
      align: 'center',
      render: (filename, { id }) =>
        filename && (
          <Button
            type='link'
            onClick={() => openInvoicePreview(filename)}
            className='link-color'
          >
            View Invoice
          </Button>
        ),
    },

    {
      title: 'Timesheet Pdf',
      dataIndex: 'timesheetPdfFile',
      align: 'center',
      width: '10%',
      render: (timesheetFilename, { timesheetId }) =>
        timesheetFilename && (
          <Button
            type='link'
            className='color-link'
            onClick={() => {
              setTimesheetId(timesheetId);
              setVisibleTimeSheetPDF(true);
            }}
          >
            View Timesheet.pdf
          </Button>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'printed',
      align: 'center',
      render: (printed) =>
        VIEW_SEND_PRINTEDS[printed] || VIEW_SEND_PRINTEDS.default,
    },
    {
      title: 'Action Invoice',
      dataIndex: 'timesheetId',
      align: 'center',
      render: (id, record) => {
        const { timesheetPdfFile, filename } = record;
        return (
          <ColorCheckbox
            checked={id === checkedId}
            onChange={(e) => {
              if (e.target.checked) {
                setTimesheetId(id);
                setInvoiceId(record?.id);
                setCheckedId(id);
                setLinkDownloadTimesheetPDF(timesheetPdfFile);
                setLinkDownloadInvoicePDF(filename);

                timesheetPdfFile ? setIsCreatePDF(false) : setIsCreatePDF(true);
              } else {
                setInvoiceId(null);
                setLinkDownloadTimesheetPDF();
                setTimesheetId();
                setCheckedId();
                setIsCreatePDF(false);
              }
            }}
          />
        );
      },
    },
  ];

  const {
    currentPage: number = 1,
    paginationPageSize: size = 25,
    handlePagination,
    handleShowSizeChange,
  } = usePagination();

  const [orderDirection, setOrderDirection] = useState('desc');

  const headerTable = generateHeaderTable(columns);

  const openInvoicePreview = (pdfUrl) => {
    setInvoiceFile(pdfUrl);
    setIsShowInvoice(true);
  };

  const updateTimesheetLink = async (
    invoiceId,
    timesheetPdfFile,
    timesheetId,
  ) => {
    const { data } = await request.put(
      `trackers/invoicing/invoices/${invoiceId}`,
      {
        invoice: {
          invoiceId,
          timesheetPdfFile,
          timesheetId,
        },
      },
    );

    return data;
  };

  const { mutate: mutateCreateTimesheetPDF, isLoading } = useMutation(
    async () => {
      const doc = document.getElementById('timesheet-pdf-container');

      const minHeight = Math.min(doc?.scrollHeight, doc?.offsetHeight);
      const maxWidth = doc?.scrollWidth;

      const { blob } = await toPdf({
        width: maxWidth,
        height: minHeight,
        applyLandscape: true,
      });

      const filenamePdf = 'invoice.pdf';
      const { getUrl: filename } = await uploadFileS3(
        filenamePdf,
        blob,
        setUploadedProgress,
      );

      const data = await updateTimesheetLink(invoiceId, filename, timesheetId);

      return data?.data ?? {};
    },
    {
      onError: (error) => {
        message.error(error.message ?? 'Can not create timesheet PDf');
      },
      onSuccess: (data) => {
        message.success('Create timesheet pdf successfully');
        setVisibleTimeSheetPDF(false);
        setLinkDownloadTimesheetPDF(data?.attributes?.timesheet_pdf_file);
        refetchGetInvoices();
      },
    },
  );

  const { mutate: mutateInvoiceSendToAccounts, isLoading: isSendingToAccount } =
    useMutation(InvoiceSendMail.toAccounts, {
      onSuccess: () => {
        hiddenModal();
        message.success('Sent invoice to account success');
        refetchGetInvoices();
      },
      onError: (error) => {
        message.error(error.message ?? 'can not send email');
      },
    });

  const { mutate: mutateInvoiceSendToClient, isLoading: isSendingToClient } =
    useMutation(InvoiceSendMail.toClient, {
      onSuccess: () => {
        hiddenModal();
        message.success('Sent invoice to client success');
        refetchGetInvoices();
      },
      onError: (error) => {
        message.error(error.message ?? 'can not send email');
      },
    });

  const handleSort = (order) => {
    setOrderDirection(Formatter.makeAbbrevSortType(order));
  };

  const {
    data: invoices,
    refetch: refetchGetInvoices,
    isFetching,
  } = useFetch(
    [
      'page-view-send-get-view-send',
      size,
      number,
      orderDirection,
      activeKey,
      year,
      stateWeekNum,
    ],
    () => {
      const { searchTerm, employerId, invoiceId } = form.getFieldsValue();

      return InvoiceService.getViewSend({
        size,
        number,
        orderDirection,
        weekNum: stateWeekNum,
        searchTerm,
        employerId,
        year,
        invoiceId,
      });
    },
  );

  const { data, isFetching: timesheetFetching } = useFetch(
    ['get-one-timesheet-detail', timesheetId],
    () => TimesheetService.timesheetPdf(timesheetId),
    {
      enabled: !!timesheetId,
    },
  );

  const jobs = useMemo(() => data?.jobs ?? {}, [data]);
  const weeklyTotal = useMemo(() => data?.weekly_totals ?? [], [data]);
  const pushedRecords = useMemo(() => data?.pushedRecords ?? {}, [data]);

  const handleSearchInvoices = () => {
    setSession({ ...form.getFieldsValue() });
    refetchGetInvoices();
  };

  const handleResetSearch = () => {
    handlePagination(1);
    form.resetFields();
    removeSession();
    refetchGetInvoices();
  };

  const handleCreateTimesheetPdf = async () => {
    setVisibleTimeSheetPDF(true);
  };

  function download(url, filename) {
    setIsDownloadPDF(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch((error) => message.error(error.message ?? 'Download pdf fail'))
      .finally(() => setIsDownloadPDF(false));
  }

  const handleDownloadTimesheetPDF = () => {
    if (linkDownloadTimesheetPDF) {
      download(linkDownloadTimesheetPDF, `timesheet-${timesheetId}.pdf`);
    } else {
      message.error('No file to download');
    }
  };

  const handleDownloadInvoicePDF = () => {
    if (linkDownloadInvoicePDF) {
      download(linkDownloadInvoicePDF, `invoice-${invoiceId}.pdf`);
    } else {
      message.error('No file to download');
    }
  };

  const handleEmailInvoiceToAccounts = () =>
    mutateInvoiceSendToAccounts([invoiceId]);

  const handleEmailInvoiceToClient = () =>
    mutateInvoiceSendToClient([invoiceId]);

  const handleOpenModalToSendEmail = (sendTo) => {
    if (isEmpty(invoiceId)) {
      message.warning('Please select invoice to send');
      return;
    }
    setTo(sendTo);
    showModal();
  };

  const handleClickYesToSendEmail = () => {
    if (to === 'accounts') return handleEmailInvoiceToAccounts();
    if (to === 'client') return handleEmailInvoiceToClient();

    return message.warning('Please click again');
  };

  const renderFooter = () => {
    const renderHiddenButtons = ({
      title,
      firstButton,
      secondButton,
      handleClickButtonFirst,
      handleClickButtonSecond,
    }) => (
      <div className='hidden-buttons'>
        <p className='text-style'>{title}</p>
        <Button
          onClick={noopify(handleClickButtonFirst)}
          className='yellow-button input-height download-item'
        >
          {firstButton}
        </Button>
        <Button
          onClick={noopify(handleClickButtonSecond)}
          className='yellow-button input-height download-item'
        >
          {secondButton}
        </Button>
      </div>
    );

    return (
      <div className='view-send-footer'>
        <div className='buttons'>
          <div className='showed-buttons'>
            <Button
              onClick={() =>
                setIsShowDetail({
                  isDownload: !isDownload,
                  isEmailInvoice: false,
                })
              }
              className={classNames('shared-button input-height style-btn', {
                'yellow-bg': isDownload,
              })}
            >
              Download
              <RightOutlined />
            </Button>
            <Button
              onClick={() =>
                setIsShowDetail({
                  isDownload: false,
                  isEmailInvoice: !isEmailInvoice,
                })
              }
              className={classNames('shared-button input-height style-btn', {
                'yellow-bg': isEmailInvoice,
              })}
            >
              Email Invoice
              <RightOutlined />
            </Button>
          </div>
          {isDownload &&
            renderHiddenButtons({
              title: 'Download',
              firstButton: 'Download Timesheet pdf',
              secondButton: 'Download Invoice',
              handleClickButtonSecond: handleDownloadInvoicePDF,
              handleClickButtonFirst: handleDownloadTimesheetPDF,
            })}
          {isEmailInvoice &&
            renderHiddenButtons({
              title: 'Email',
              firstButton: 'Email invoice to Accounts',
              secondButton: 'Email invoice to Client',
              handleClickButtonFirst: () =>
                handleOpenModalToSendEmail('accounts'),
              handleClickButtonSecond: () =>
                handleOpenModalToSendEmail('client'),
            })}
        </div>
        <Button
          className='yellow-button input-height create-pdf-btn'
          onClick={handleCreateTimesheetPdf}
          disabled={!isCreatePDF}
        >
          Create Timesheet Pdf
        </Button>
      </div>
    );
  };

  return (
    <div className='view-send-invoice'>
      <Form
        form={form}
        onFinish={handleSearchInvoices}
        onReset={handleResetSearch}
        initialValues={{
          year: moment().year(),
          weekNum: moment().week(),
        }}
      >
        <FormFields activeKey={activeKey} />
      </Form>
      <ListInvoices
        isFetching={isFetching}
        data={invoices?.data}
        handleSort={handleSort}
        headerTable={headerTable}
      />
      <div className='table-footer-container'>
        <div className='pagination-container'>
          <Pagination
            responsive
            showSizeChanger
            current={number}
            pageSize={size}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            total={invoices?.totalCount ?? 0}
            onChange={handlePagination}
            onShowSizeChange={handleShowSizeChange}
          />
        </div>
      </div>
      {renderFooter()}

      <AntdModal
        visible={isShowInvoice}
        onOk={() => setIsShowInvoice(false)}
        onCancel={() => setIsShowInvoice(false)}
        wrapClassName='invoice-preview'
        footer={null}
      >
        <button
          type='button'
          style={{ all: 'unset', width: '100%' }}
          onClick={handleClickPreviewInvoice}
        >
          <PDF fileName={invoiceFile} width={windowSize.width / WIDTH_RATIO} />
        </button>
      </AntdModal>

      <AntdModal
        wrapClassName='view-timesheet-container'
        className='view-timesheet-body'
        visible={visibleTimeSheetPDF}
        onCancel={() => setVisibleTimeSheetPDF(false)}
        onYes={() => setVisibleTimeSheetPDF(false)}
        footer={null}
      >
        <Spin spinning={timesheetFetching} />
        {isCreatePDF && (
          <div className='confirm-btn-container'>
            <Button
              disabled={timesheetFetching}
              onClick={mutateCreateTimesheetPDF}
              className='black-button'
            >
              Confirm to create timesheet PDF
            </Button>
          </div>
        )}
        <div ref={ref}>
          <ViewTimesheetPDF
            jobs={jobs}
            weeklyTotal={weeklyTotal}
            pushedRecords={pushedRecords}
            weekStartDate={data?.week_start_date}
            weekEndDate={data?.week_end_date}
            locationName={data?.location_name}
            employerName={data?.employer_name}
            timesheetFetching={timesheetFetching}
          />
        </div>
      </AntdModal>

      <AntdModal
        wrapClassName='loading-modal'
        footer={null}
        visible={isLoading}
        closable={false}
      >
        <Spin tip={`Uploading invoice Pdf ${uploadedProgress.toFixed(0)}%`} />
      </AntdModal>

      <AntdModal
        wrapClassName='loading-modal'
        footer={null}
        visible={isDownloadPDF}
        closable={false}
      >
        <Spin tip='Downloading Pdf' />
      </AntdModal>

      <ModalConfirm
        title='Email Invoice?'
        visible={isDisplayModal}
        onClickYes={handleClickYesToSendEmail}
        onClickNo={hiddenModal}
        isLoading={isSendingToAccount || isSendingToClient}
      >
        {to === 'accounts'
          ? 'Do you want to email this invoice to the account team to send on to the client?'
          : 'Are you sure you want to email the invoice to all allocated people for this provider? This will have a copy of the T&C’s and the Timesheet pdf if this was created. This will also attach any files uploaded within the Timesheet module for this provider and week'}
      </ModalConfirm>
    </div>
  );
};

ViewSend.propTypes = {
  form: PropTypes.object,
  activeKey: PropTypes.string,
};

export default ViewSend;
