const costRoles = [
  {
    value: 1,
    label: 'Transport',
  },
  {
    value: 2,
    label: 'Accommodation',
  },

  {
    value: 3,
    label: 'Uniform',
  },

  {
    value: 4,
    label: 'Training',
  },

  {
    value: 5,
    label: 'Other',
  },
];

export { costRoles };
