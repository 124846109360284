import React from 'react';

import { Button, Form } from 'antd';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { Input, FormItem, Select, DatePicker, Textarea } from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import { LIMIT_PHONE_NUMBER } from '@/constants';
import { useLocations } from '@/hooks/dropdowns';
import { date, copyToClipboard } from '@/utils';
import {
  rulesTextInput,
  rulesPhone,
  rulesEmail,
  rulesSelect,
  rulesPostcode,
} from '@/utils/rulesInput';
import { useTenantContext } from '../../../TenantWrapper';

const ApplicantContact = () => {
  const { listLocations } = useLocations();
  const { id } = useParams();
  const form = Form.useFormInstance();
  const tenant = useTenantContext();

  return (
    <div className='applicant-contact'>
      <div className='row-box'>
        <div>
          <p className='text-label'>Contact Details</p>
          <div className='contact-fields'>
            <FormItem
              required
              label='First Name'
              rules={rulesTextInput('First Name')}
              name='firstname'
            >
              <Input placeholder='First Name' maxLength={48} />
            </FormItem>
            <FormItem
              required
              label='Last Name'
              rules={rulesTextInput('Last Name')}
              name='lastname'
            >
              <Input placeholder='Last Name' maxLength={48} />
            </FormItem>
            <FormItem required label='Email' rules={rulesEmail()} name='email'>
              <Input placeholder='Email' />
            </FormItem>
            <FormItem
              required
              name='phone'
              label='Phone Number'
              rules={rulesPhone()}
            >
              <Input
                maxLength={LIMIT_PHONE_NUMBER}
                placeholder='Phone Number'
              />
            </FormItem>
            <FormItem
              label='Location'
              rules={rulesSelect('Location')}
              required
              name='officeId'
            >
              <Select
                showSearch={false}
                options={listLocations}
                placeholder='Location'
              />
            </FormItem>
            <FormItem
              name='zip'
              label='Post Code'
              rules={rulesPostcode(form, 'zip')}
              required
            >
              <PostCodeUK />
            </FormItem>
          </div>
          <div className={classNames('contact-url', { 'date-container': !id })}>
            {id && (
              <>
                <FormItem
                  className='url'
                  label='Candidate Form URL'
                  colon
                  name='candidateUrl'
                >
                  <Input disabled />
                </FormItem>
                <Button
                  className='btn-copy'
                  onClick={() =>
                    copyToClipboard(form?.getFieldValue('candidateUrl'))
                  }
                >
                  Copy
                </Button>
              </>
            )}
            <FormItem
              name='applicantDate'
              className='date'
              label='Application Date'
              colon
              normalize={date.convertDateTime}
            >
              <DatePicker
                name='applicantDate'
                disabled
                placeholder='Application Date'
              />
            </FormItem>
          </div>
        </div>
        <div className='white-box'>
          <FormItem
            required
            name='tellUs'
            label={[
              'Tell us why you want to apply for',
              `${tenant.name} and what makes you a great candidate?`,
            ].join(' ')}
            rules={rulesTextInput('Reason')}
          >
            <Textarea
              rows={10}
              placeholder={[
                `I want to work for ${tenant.name} because I think that`,
                'I will be a great addition to the team I am a hard worker and have experience in the Warehouse Industry.',
              ].join(' ')}
            />
          </FormItem>
        </div>
      </div>
    </div>
  );
};

export default ApplicantContact;
