import React from 'react';

import { Pagination, Form } from 'antd';

import { FormItem, MultipleSelect, Button, AutoComplete } from '@/components';
import Listview from '@/components/ListView';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import './styles.scss';

import { usersGIGProvider } from './mockData';

const headerTable = generateHeaderTable([
  {
    title: 'Name',
    width: '10%',
  },
  {
    title: 'Company',
    width: '10%',
  },

  {
    title: 'Access level',
    width: '10%',
  },
  {
    title: 'Providers',
    ellipsis: true,
    width: '50%',
  },
  {
    title: 'Last Action',
    render: (dt) => (
      <div className='last-action'>
        <p>{dt.date}</p>
        <p>{dt.time}</p>
      </div>
    ),
    width: '10%',
  },
]);

const UsersGIGProvider = () => (
  <div className='users-gig-provider'>
    <Form className='input-container'>
      <FormItem className='form-item' label='Search'>
        <AutoComplete placeholder='Name or email address' />
      </FormItem>
      <FormItem className='form-item' label='Provider'>
        <MultipleSelect placeholder='Provider' />
      </FormItem>
      <FormItem className='form-item' label='Access Level'>
        <MultipleSelect placeholder='Access Level' />
      </FormItem>
      <div className='buttons form-item'>
        <Button className='yellow-button button-action'>Search</Button>
        <Button className='filter-button button-action'>Clear Filter</Button>
      </div>
    </Form>
    <Listview
      headerColumns={headerTable}
      infoDetail={usersGIGProvider}
      scroll={{ y: 650 }}
    />
    <div className='user-footer'>
      <div className='pagination-container'>
        <Pagination />
      </div>
      <Button className='yellow-button download-csv-btn'>Download CSV</Button>
    </div>
  </div>
);
export default UsersGIGProvider;
