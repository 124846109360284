import {
  rulesCompanyNumber,
  rulesPhone,
  rulesPostcode,
  rulesTextInput,
  rulesValidateProviderEmail,
  rulesValidateProviderPhone,
  rulesVatNumber,
} from '@/utils/rulesInput';

const companyDetailInputs = [
  {
    label: 'Registered Company Name',
    rules: rulesTextInput('Registered Company Name'),
    type: 'input',
    name: 'abbrvName',
    className: 'primary-input-container',
    require: true,
    placeholder: 'Registered Company Name',
  },
  {
    label: 'Company Number',
    rules: rulesCompanyNumber(),
    type: 'input',
    name: 'companyNumber',
    className: 'primary-input-container',
    maxLength: 8,
    placeholder: 'Company Number (8 digits)',
  },
  {
    label: 'VAT Number',
    rules: rulesVatNumber(),
    type: 'input',
    name: 'vatNumber',
    className: 'primary-input-container',
    maxLength: 9,
    placeholder: 'VAT Number (9 digits)',
  },
];

const contactDetailInputs = [
  {
    label: 'First Name',
    rules: rulesTextInput('First Name'),
    type: 'input',
    name: 'firstName',
    className: 'primary-input-container',
    maxLength: 48,
    required: true,
  },
  {
    label: 'Last Name',
    rules: rulesTextInput('Last Name'),
    type: 'input',
    name: 'lastName',
    className: 'primary-input-container',
    maxLength: 48,
    required: true,
  },

  {
    label: 'Email',
    rules: rulesValidateProviderEmail(),
    type: 'input',
    name: 'email',
    className: 'primary-input-container',
    required: true,
  },
  {
    label: 'Phone Number',
    rules: rulesValidateProviderPhone(),
    type: 'input',
    name: 'phone',
    className: 'primary-input-container',
    required: true,
    maxLength: 11,
  },

  {
    label: 'Job Title',
    type: 'input',
    name: 'jobTitle',
    className: 'primary-input-container',
  },
  {
    label: 'Department',
    type: 'input',
    name: 'department',
    className: 'primary-input-container',
  },
];

const companyAddress = (form) => [
  {
    label: 'Building Number',
    type: 'input',
    name: 'baBuilding',
    className: 'primary-input-container',
    rules: rulesTextInput('Building Number'),
    required: true,
  },
  {
    label: 'Street Address',
    type: 'input',
    name: 'baStreet',
    className: 'primary-input-container',
    rules: rulesTextInput('Street Address'),
    required: true,
  },
  {
    label: 'City',
    type: 'input',
    name: 'baCity',
    className: 'primary-input-container',
    rules: rulesTextInput('City'),
    required: true,
  },
  {
    label: 'Post Code',
    type: 'input',
    name: 'baPostcode',
    className: 'primary-input-container',
    required: true,
    rules: rulesPostcode(form, 'baPostcode'),
  },
];

const contactForBookingsInput = [
  {
    label: 'First Name',
    type: 'input',
    name: ['contactBooking', 0, 'firstName'],
    className: 'primary-input-container',
    placeholder: 'First Name',
  },
  {
    label: 'Last Name',
    type: 'input',
    name: ['contactBooking', 0, 'lastName'],
    className: 'primary-input-container',
    placeholder: 'Last Name',
  },
  {
    label: 'Email',
    type: 'input',
    name: ['contactBooking', 0, 'email'],
    className: 'primary-input-container',
  },
  {
    label: 'Phone Number',
    type: 'input',
    name: ['contactBooking', 0, 'phone'],
    className: 'primary-input-container',
    rules: rulesPhone(false),
    maxLength: 11,
  },
  {
    label: 'Job Title',
    type: 'input',
    name: ['contactBooking', 0, 'jobTitle'],
    className: 'primary-input-container',
    placeholder: 'Job Title',
  },
  {
    label: 'Department',
    type: 'input',
    name: ['contactBooking', 0, 'department'],
    className: 'primary-input-container',
    placeholder: 'Department',
  },
];

const contactForTimesheetsInput = [
  {
    label: 'First Name',
    type: 'input',
    name: ['contactTimesheet', 0, 'firstName'],
    className: 'primary-input-container',
    placeholder: 'First Name',
  },
  {
    label: 'Last Name',
    type: 'input',
    name: ['contactTimesheet', 0, 'lastName'],
    className: 'primary-input-container',
    placeholder: 'Last Name',
  },
  {
    label: 'Email',
    type: 'input',
    name: ['contactTimesheet', 0, 'email'],
    className: 'primary-input-container',
  },
  {
    label: 'Phone Number',
    type: 'input',
    name: ['contactTimesheet', 0, 'phone'],
    className: 'primary-input-container',
    rules: rulesPhone(false),
    maxLength: 11,
  },
  {
    label: 'Job Title',
    type: 'input',
    name: ['contactTimesheet', 0, 'jobTitle'],
    className: 'primary-input-container',
    placeholder: 'Job Title',
  },
  {
    label: 'Department',
    type: 'input',
    name: ['contactTimesheet', 0, 'department'],
    className: 'primary-input-container',
    placeholder: 'Department',
  },
];

const contactForAccountsInput = [
  {
    label: 'First Name',
    type: 'input',
    name: ['contactAccount', 0, 'firstName'],
    className: 'primary-input-container',
    placeholder: 'First Name',
  },
  {
    label: 'Last Name',
    type: 'input',
    name: ['contactAccount', 0, 'lastName'],
    className: 'primary-input-container',
    placeholder: 'Last Name',
  },
  {
    label: 'Email',
    type: 'input',
    name: ['contactAccount', 0, 'email'],
    className: 'primary-input-container',
    placeholder: 'Email',
  },
  {
    label: 'Phone Number',
    type: 'input',
    name: ['contactAccount', 0, 'phone'],
    className: 'primary-input-container',
    rules: rulesPhone(false),
    maxLength: 11,
  },
  {
    label: 'Job Title',
    type: 'input',
    name: ['contactAccount', 0, 'jobTitle'],
    className: 'primary-input-container',
    placeholder: 'Job Title',
  },
  {
    label: 'Department',
    type: 'input',
    name: ['contactAccount', 0, 'department'],
    className: 'primary-input-container',
    placeholder: 'Department',
  },
];

const contactForInvoicesInput = [
  {
    label: 'First Name',
    type: 'input',
    name: ['contactInvoice', 0, 'firstName'],
    className: 'primary-input-container',
    placeholder: 'First Name',
  },
  {
    label: 'Last Name',
    type: 'input',
    name: ['contactInvoice', 0, 'lastName'],
    className: 'primary-input-container',
    placeholder: 'Last Name',
  },
  {
    label: 'Email',
    type: 'input',
    name: ['contactInvoice', 0, 'email'],
    className: 'primary-input-container',
  },
  {
    label: 'Phone Number',
    type: 'input',
    name: ['contactInvoice', 0, 'phone'],
    className: 'primary-input-container',
    rules: rulesPhone(false),
    maxLength: 11,
  },
  {
    label: 'Job Title',
    type: 'input',
    name: ['contactInvoice', 0, 'jobTitle'],
    className: 'primary-input-container',
    placeholder: 'Job Title',
  },
  {
    label: 'Department',
    type: 'input',
    name: ['contactInvoice', 0, 'department'],
    className: 'primary-input-container',
    placeholder: 'Department',
  },
];

const siteLocationsInputs = (form) => [
  {
    label: 'Main Booking Location Name',
    type: 'input',
    name: ['employerLocationsAttributes', 0, 'name'],
    className: 'primary-input-container',
    placeholder: 'Location Name',
  },
  {
    label: 'Building Number',
    type: 'input',
    name: ['employerLocationsAttributes', 0, 'building'],
    className: 'primary-input-container',
  },
  {
    label: 'Street Address',
    type: 'input',
    name: ['employerLocationsAttributes', 0, 'street'],
    className: 'primary-input-container',
  },
  {
    label: 'City',
    type: 'input',
    name: ['employerLocationsAttributes', 0, 'city'],
    className: 'primary-input-container',
  },

  {
    label: 'Post Code',
    type: 'input',
    name: ['employerLocationsAttributes', 0, 'postcode'],
    className: 'primary-input-container',
  },
  {
    label: 'Country',
    type: 'input',
    name: ['employerLocationsAttributes', 0, 'country'],
    className: 'primary-input-container',
  },
];

export {
  companyDetailInputs,
  contactDetailInputs,
  contactForBookingsInput,
  contactForTimesheetsInput,
  contactForAccountsInput,
  contactForInvoicesInput,
  companyAddress,
  siteLocationsInputs,
};
