import { isArray } from 'lodash';

import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

export default async function getOne(id) {
  try {
    const endpoint = `/trackers/expense/costs/${id}`;
    const res = await request.get(endpoint);

    const { data } = normalizeShowResponse(res);

    const { comments, costDocuments } = data;

    return {
      ...data,
      commentsAttributes: isArray(comments) ? comments : [...comments],
      costDocumentsAttributes: isArray(costDocuments)
        ? costDocuments
        : [...costDocuments],
    };
  } catch (error) {
    throw new ServiceError(error);
  }
}
