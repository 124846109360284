/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Radio } from 'antd';

import { FormItem } from '@/components';
import './styles.scss';

const Declarations = () => {
  const title = (
    <span>
      P46 Statement: Read all the following statements carefully and select the
      one that applies to you
    </span>
  );

  return (
    <div className='declarations-container'>
      <span>Declarations</span>
      <FormItem
        rules={[
          {
            required: true,
            message: 'Please select at least one',
          },
        ]}
        required
        label={title}
        name='p46s'
      >
        <Radio.Group className='recruitment-radio'>
          <Radio value={1}>
            This is my first job since last 6 April and I have not been
            receiving taxable Jobseeker's Allowance, Employement and Support
            Allowance or taxable Incapacity Benefit or a state of occupational
            pension
          </Radio>
          <Radio value={2}>
            This is now my only job, but since last 6 April I have had another
            job, or have received taxable Jobseeker's Allowance, Employement and
            Support Allowance or taxable Incapacity Benefit or a state of
            occupational pension.
          </Radio>
          <Radio value={3}>
            I have another job or receive state of occupational pension.
          </Radio>
        </Radio.Group>
      </FormItem>
    </div>
  );
};

export default Declarations;
