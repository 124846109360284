import React from 'react';

import { Table } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const History = ({ title, columns, dataSource, pagination, loading }) => (
  <div className='history-container'>
    <p className='history-title'>{title}</p>
    <Table
      className='history-table'
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      loading={loading}
    />
  </div>
);

History.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  pagination: PropTypes.bool,
  loading: PropTypes.bool,
};

History.defaultProps = {
  title: null,
  columns: [],
  dataSource: [],
  pagination: false,
  loading: false,
};

export default History;
