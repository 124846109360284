import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { DateLocalizer } from 'react-big-calendar';

import Skeleton from '@/components/Skeleton';
import { DATE_FORMAT } from '@/constants';

const Header = ({ date, localizer, isLoading }) => (
  <div className='week-header'>
    {isLoading ? (
      <Skeleton lines={2} hiddenTitle />
    ) : (
      <>
        <div className='week-day'>{localizer.format(date, 'dddd')}</div>
        <div>{moment(date).format(DATE_FORMAT)}</div>
      </>
    )}
  </div>
);

export default Header;

Header.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isLoading: PropTypes.bool,
  localizer: PropTypes.instanceOf(DateLocalizer).isRequired,
};

Header.defaultProps = {
  isLoading: false,
};
