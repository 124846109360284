import { useQuery } from 'react-query';

import { fetchOmniUsers } from '@/services/dropdownServices/getOmniUsers';

const useOmniUsers = () => {
  const { data } = useQuery(['omniUsers'], fetchOmniUsers, {
    refetchOnWindowFocus: false,
  });

  return data;
};

export default useOmniUsers;
