/**
- Holiday Pay (HP) = Pay Rate (PR) * 12.07%
- Pension Pay = ( PR + HP ) x 3,50%
- Employer Ni :
- ((((PR + HP) * 40)-156 )* 15.05%)/40
- Nếu Margin là %	Margin = (PR + HP + Pension Pay + Employer NI ) x 9%
- Nếu Margin là số X	Margin = X

- Attention: 40 and 156 are the default values
 */

import { round, sum } from 'lodash';

// eslint-disable-next-line import/no-cycle
import { DEFAULT_RATES } from '@/constants';
import Formatter from '@/utils/Formatter';

const calc = ({
  payrate = 0, // Pay Rate
  pensionAndApprentice = 3.5,
  marginIsCostType = 'fixed',
  marginPercent = 9,
  marginFixed = 1.25,
  otherCost = 0,
}) => {
  if (payrate === 0) return 0;
  const payRateFixed = 0.1207;
  const employerNIFixed = 0.1505;

  const payrateNum = parseFloat(payrate);
  const pensionAndApprentcieNum = parseFloat(pensionAndApprentice) / 100;
  const marginPercentNum = parseFloat(marginPercent);
  const otherCostNum = parseFloat(otherCost);

  const holidayPay = payrateNum * payRateFixed;
  const pensionPay = (payrateNum + holidayPay) * pensionAndApprentcieNum;

  const employerNI =
    (((payrateNum + holidayPay) * 40 - 156) * employerNIFixed) / 40;

  const margin =
    marginIsCostType === 'percent'
      ? ((payrateNum + holidayPay + pensionPay + employerNI) *
          marginPercentNum) /
        100
      : marginFixed;

  const ChargeRate =
    payrateNum + holidayPay + pensionPay + employerNI + margin + otherCostNum;

  return round(ChargeRate, 2);
};

export { calc };

export const calcPayRateHoliday = ({ days, payRate }) =>
  (payRate * days) / (260 - days);

export const calcPayRatePension = ({
  payRate,
  holidayPayRate,
  employersNiHourWeek,
  pensionThreshold,
  pensionPercent,
}) =>
  (((payRate + holidayPayRate) * employersNiHourWeek - pensionThreshold) *
    pensionPercent) /
  employersNiHourWeek;

export const calcPayRateApprenticeship = ({
  payRate,
  apprenticeship,
  holidayPayRate,
}) => (payRate + holidayPayRate) * apprenticeship;

export const calcPayRateEmployerNI = ({
  payRate,
  holidayPayRate,
  employersNiHourWeek,
  employersNiPerWeekThreshold,
  employersNiPerWeekPercent,
}) =>
  (((payRate + holidayPayRate) * employersNiHourWeek -
    employersNiPerWeekThreshold) *
    employersNiPerWeekPercent) /
  employersNiHourWeek;

export const calcPayRateMarginPercent = ({
  payRate,
  totalStatutory,
  marginPercent,
}) => (payRate + totalStatutory) * marginPercent;

export const calcChargeRate = (providerRateCard, payRate) => {
  const otherCosts =
    Number(providerRateCard?.other_cost) || DEFAULT_RATES.OTHER_COSTS;
  const marginIsCostType =
    providerRateCard?.margin_is_cost_type || DEFAULT_RATES.MARGIN_COST_TYPE;
  const marginFixed =
    Number(providerRateCard?.margin_fixed) || DEFAULT_RATES.MARGIN_FIXED;
  const marginPercent =
    Number(providerRateCard?.margin_percent) ||
    DEFAULT_RATES.MARGIN_PERCENT / 100;
  const pensionPercent =
    Number(providerRateCard?.pension_percent) ||
    DEFAULT_RATES.PENSION_PERCENT / 100;
  const pensionThreshold =
    Number(providerRateCard?.pension_threshold) ||
    DEFAULT_RATES.PENSION_THRESHOLD;
  const holidayDays =
    Number(providerRateCard?.holiday) || DEFAULT_RATES.HOLIDAY_DAYS;
  const employersNiPerWeekThreshold =
    Number(providerRateCard?.employers_ni_per_week_threshold) ||
    DEFAULT_RATES.EMPLOYER_NI_THRESHOLD;
  const employersNiPerWeekPercent =
    Number(providerRateCard?.employers_ni_per_week_percent) ||
    DEFAULT_RATES.EMPLOYER_NI_PER_WEEK_PERCENT / 100;
  const employersNiHourWeek =
    Number(providerRateCard?.employers_ni_hour_week) ||
    DEFAULT_RATES.EMPLOYER_NI_HOURS_WEEK;
  const apprenticeshipLevyPercent =
    Number(providerRateCard?.apprenticeship_levy_percent) ||
    DEFAULT_RATES.APPRENTICESHIP / 100;

  const holidayPayRate = calcPayRateHoliday({
    payRate,
    days: holidayDays,
  });

  const pensionPayRate = calcPayRatePension({
    employersNiHourWeek,
    payRate,
    holidayPayRate,
    pensionPercent,
    pensionThreshold,
  });

  const apprenticeshipPayRate = calcPayRateApprenticeship({
    payRate,
    apprenticeship: apprenticeshipLevyPercent,
    holidayPayRate,
  });

  const employersNIPayRate = calcPayRateEmployerNI({
    payRate,
    holidayPayRate,
    employersNiPerWeekPercent,
    employersNiPerWeekThreshold,
    employersNiHourWeek,
  });

  const totalStatutoryPayRate =
    holidayPayRate +
    pensionPayRate +
    employersNIPayRate +
    apprenticeshipPayRate;

  const totalStatutory = totalStatutoryPayRate;

  const marginPercentPayRate = calcPayRateMarginPercent({
    payRate,
    totalStatutory,
    marginPercent,
  });
  const marginPayRate =
    marginIsCostType === 'percent'
      ? Number(Formatter.fixedNumber({ value: marginPercentPayRate }))
      : marginFixed;

  const chargeRate = payRate + totalStatutory + marginPayRate + otherCosts;

  return Formatter.fixedNumber({ value: chargeRate });
};

export const calculateTotalPayAndTotalCharge = (infoDetail) => {
  if (!infoDetail)
    return {
      totalPay: 0,
      totalCharge: 0,
    };

  const listTotalPay = infoDetail?.map((record) =>
    sum(
      record?.dayworks?.map((item) => parseFloat(item.hours) * record.payrate),
    ),
  );

  const listTotalCharge = infoDetail?.map((record) =>
    sum(
      record?.dayworks?.map(
        (item) => parseFloat(item.hours) * record.charge_rate,
      ),
    ),
  );

  return {
    totalPay: round(sum(listTotalPay), 2),
    totalCharge: round(sum(listTotalCharge), 2),
  };
};
