import React from 'react';

import { Form } from 'antd';

import { FormItem, Input } from '@/components';
import { ROTA_TYPES } from '@/constants';

import SpecificSites from './SpecificSites';

const RollingPattern = () => {
  const formInstance = Form.useFormInstance();

  const selectedTypes = Form.useWatch('selectedTypes', formInstance) ?? [];

  const isHidden =
    selectedTypes.length === 1 && selectedTypes[0] === ROTA_TYPES[0].value;

  return (
    <div className='type-detail' hidden={isHidden}>
      <div className='label'>Worker Site Allocation</div>
      <FormItem name='siteAllocation' hidden />

      <SpecificSites />

      <Form.List name='seekerRota'>
        {(fields) => (
          <div hidden={!selectedTypes.includes(ROTA_TYPES[2].value)}>
            {fields.map((_field, index) => (
              <div className='rota-type'>
                <div className='items space-top'>
                  <FormItem label='Provider' name={[index, 'employerName']}>
                    <div className='item col'>
                      {formInstance.getFieldValue([
                        'seekerRota',
                        index,
                        'employerName',
                      ])}
                    </div>
                  </FormItem>
                  <FormItem
                    label='Location'
                    name={[index, 'employerLocationName']}
                  >
                    <div className='item col'>
                      {formInstance.getFieldValue([
                        'seekerRota',
                        index,
                        'employerLocationName',
                      ])}
                    </div>
                  </FormItem>
                </div>
                <div className='items space-top'>
                  <FormItem label='Job Type' name={[index, 'employerName']}>
                    <div className='item col'>
                      {formInstance.getFieldValue([
                        'seekerRota',
                        index,
                        'skillName',
                      ])}
                    </div>
                  </FormItem>
                  <FormItem
                    label='Contract Type'
                    name={[index, 'employerLocationName']}
                  >
                    <div className='item col'>
                      {formInstance.getFieldValue([
                        'seekerRota',
                        index,
                        'contractType',
                      ])}
                    </div>
                  </FormItem>
                </div>
              </div>
            ))}
          </div>
        )}
      </Form.List>

      <FormItem
        className='pay-template'
        name='payTemplateName'
        label='Pay Template'
        hidden={!selectedTypes.includes(ROTA_TYPES[1].value)}
      >
        <Input readOnly />
      </FormItem>
    </div>
  );
};

export default RollingPattern;
