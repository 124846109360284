import React from 'react';

import { Form, message } from 'antd';
import { useMutation } from 'react-query';

import { Button, GeneralTemplate } from '@/components';
import { UserService } from '@/services';

import './styles.scss';
import GIGProviderForm from '../components/GIGProviderForm';
import { repeatPassword } from '../constants';

const CreateGIGProvider = () => {
  const [form] = Form.useForm();

  const { mutate: createNewGIGProvider } = useMutation(
    UserService.createGIGProviderUser,
    {
      onError: (error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        message.success('Create new user successfully!');
      },
    },
  );

  const handleSubmitCreateGIGProvider = (values) => {
    if (form.getFieldError(repeatPassword).length > 0) return;
    createNewGIGProvider(values);
  };

  return (
    <GeneralTemplate
      data={[
        { item: 'User', to: '/users' },
        { item: 'Create GIGProvider User', to: '/create-new-gig-provider' },
      ]}
      mainItem='User'
      headerText='Create GIG Provider User'
      buttonLink='/create-new-gig-provider'
      buttonContent='Create GIG Provider log in'
      subTemplateClassName='gig-provider-form-container'
    >
      <Form
        className='create-gig-provider-form'
        form={form}
        onFinish={handleSubmitCreateGIGProvider}
      >
        <GIGProviderForm />

        <div className='button-action'>
          <Button className='black-button'>Cancel</Button>
          <Button className='yellow-button' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
    </GeneralTemplate>
  );
};

export default CreateGIGProvider;
