import moment from 'moment';

import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';

export const editRota = async ({ id, values }) => {
  const endpoint = `/rotas/rotas/${id}`;
  // Just get a specific date to convert to iso string
  const today = moment().format('YYYY/MM/DD');
  const { rotaWeeksAttributes, ...restValues } = values;

  const formattedRotaWeeksAttributes = rotaWeeksAttributes?.map((dt) => {
    const { rotaShiftsAttributes } = dt;

    const formattedRotaShiftsAttributes = rotaShiftsAttributes.map((shift) => {
      const { startTime, endTime, status } = shift;

      // Only use start time and end time, the date is not important
      // today is just a random date to format
      const startTimeToIsoString =
        status === 'work'
          ? new Date(moment(`${today} ${startTime}`))?.toISOString()
          : 'off';

      const endTimeToIsoString =
        status === 'work'
          ? new Date(moment(`${today} ${endTime}`))?.toISOString()
          : 'off';

      return {
        ...shift,
        startTime: startTimeToIsoString,
        endTime: endTimeToIsoString,
      };
    });

    return { ...dt, rotaShiftsAttributes: formattedRotaShiftsAttributes };
  });

  const resData = await request.patch(endpoint, {
    rota: {
      ...restValues,
      rotaWeeksAttributes: formattedRotaWeeksAttributes,
    },
  });

  return resData;
};

export const downloadRotaCsv = async (id) => {
  try {
    const endpoint = `/rotas/rota_shift_plans/${id}/csv`;
    const res = await request.get(endpoint);

    return res.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};
