import React, { useState } from 'react';

import { Divider, Form, message, Spin } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button } from '@/components';
import { ProviderService } from '@/services';

import { INITIAL_RATE } from '../../constants';
import Profile from '../Profile';
import { useTenantContext } from '../../../../../TenantWrapper';

const CreateProfileWrapper = () => {
  const [activeKey, setActiveKey] = useState(['company-information']);
  const { defaultLimitPayRate } = useTenantContext();

  const history = useHistory();
  const [form] = Form.useForm();

  const { isLoading, mutate: mutateCreateProvider } = useMutation(
    ProviderService.create,
    {
      onError: (error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        message.success('Provider has been created successfully');
        history.push('/view-all-providers');
      },
    },
  );

  const handleFinishFailed = () =>
    setActiveKey([
      'company-information',
      'contact-information',
      'rates',
      'bank-and-official-information',
      'locations',
      'comments-and-status-updates',
    ]);

  const handleClickToRedirect = () => history.push('/view-all-providers');

  return (
    <Form
      form={form}
      onFinish={mutateCreateProvider}
      onFinishFailed={handleFinishFailed}
      className='provider-view-profile'
      initialValues={{
        dateOfRegistration: moment(),
        locationAttributes: [],
        ...INITIAL_RATE,
      }}
      scrollToFirstError={{
        skipOverflowHiddenElements: true,
        block: 'center',
      }}
    >
      <Spin spinning={isLoading}>
        <Profile activeKey={activeKey} setActiveKey={setActiveKey} />

        <Divider />
        <div className='footer-button-container'>
          <Button
            onClick={handleClickToRedirect}
            className='black-button action-button'
          >
            Cancel
          </Button>
          <Button className='yellow-button action-button' htmlType='submit'>
            Save
          </Button>
        </div>
      </Spin>
    </Form>
  );
};

export default CreateProfileWrapper;
