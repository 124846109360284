import React, { useState } from 'react';

import { Form, message } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { usePayCharge } from '@/hooks';
import Timecard from '@/pages/PayTemplate/Payroll/components/Timecard';
import calculation from '@/pages/PayTemplate/Payroll/utils/calculation';
import DataProcessing from '@/pages/PayTemplate/Payroll/utils/dataProcessing';
import { PayrollService } from '@/services';

import payrollPayTemplateColumns from './payrollPayTemplateColumns';
import { renderSummary } from './renderSummary';

const TimecardTable = ({
  timecard,
  isEdit,
  panelId,
  skillName,
  seekerName,
}) => {
  const queryClient = useQueryClient();
  const { id: timesheetId } = useParams();
  const { providerRateCard } = usePayCharge(timesheetId);
  const formInstance = Form.useFormInstance();
  const [isOpen, setOpen] = useState(false);

  const date = get(timecard, 'date');
  const dayOfWeek = moment(date).format('dddd');
  const startShift = get(timecard, 'startTime');
  const endShift = get(timecard, 'endTime');
  const shiftTime = [moment(startShift), moment(endShift)];
  const payTemplateName = get(timecard, 'payrollTemplate.payrollTemplateName');
  const noShowAttrs = get(timecard, 'noShow', null);
  const noShowComment = get(timecard, 'comment', null);
  const inforDetail = get(timecard, 'payrollTemplate.hourTemplates', []);

  const dailyTotals = calculation.calDailySummary(inforDetail);

  const {
    mutate: updateSickNoShowComment,
    isLoading: isUpdateSickNoShowComment,
  } = useMutation(
    (comment) =>
      PayrollService.updateSickNoShowComment({
        payrollId: timesheetId,
        comment,
      }),
    {
      onSuccess: () => {
        message.success('Update comment successfully');
        queryClient.invalidateQueries({
          queryKey: ['listTimeSheet', timesheetId],
        });
        setOpen(false);
      },
    },
  );

  const handleEditComment = ({ namePath }) => {
    const comment = DataProcessing.getSickNoShowCommentData({
      formInstance,
      namePath,
    });
    updateSickNoShowComment(comment);
  };

  const columns = payrollPayTemplateColumns.genHourlyColumns({
    isEdit,
    panelId,
    timecard,
    skillName,
    dayOfWeek,
    shiftTime,
    seekerName,
    formInstance,
    payTemplateName,
    providerRateCard,
  });

  const summary = () =>
    renderSummary({
      panelId,
      timecard,
      noShowAttrs,
      noShowComment,
      handleEditComment,
      isUpdateSickNoShowComment,
      isOpen,
      setOpen,
      dailyTotals,
    });
  return (
    <div>
      <Timecard
        key={timecard?.id}
        inforDetail={inforDetail}
        columns={columns}
        summary={summary}
      />
    </div>
  );
};

export default TimecardTable;

TimecardTable.propTypes = {
  timecard: PropTypes.object,
  isEdit: PropTypes.bool,
  panelId: PropTypes.number,
  skillName: PropTypes.string,
  seekerName: PropTypes.string,
};

TimecardTable.defaultProps = {
  timecard: {},
  isEdit: false,
  panelId: null,
  skillName: '',
  seekerName: '',
};
