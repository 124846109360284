/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Modal, Form } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import defaultAvatar from '@/assets/images/defaultAvatar.png';
import { AutoComplete, ListView } from '@/components';
import { DEBOUNCE_WAIT } from '@/constants';
import { useDebounce, useFetch } from '@/hooks';
import { ProviderService } from '@/services';
import useModal from '@/utils/useModal';

import Button from './Button';
import './styles.scss';

const ListSeeker = ({
  title,
  value, // props mandatory to use antd form item
  handleAddSeekers,
  handleRemoveSeeker,
  setLoading,
  onChange, // props mandatory to use antd form item
  isEditProvider,
  name,
}) => {
  const form = Form.useFormInstance();
  const list = Form.useWatch(name, form) ?? [];
  const [searchText, setSearchText] = useState('');
  const [listSeekerSelected, setListSeekerSelected] = useState([]);

  const { providerId } = useParams();
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const debouncedSearchTerm = useDebounce(searchText, DEBOUNCE_WAIT);

  const onSelectSeeker = (item) => {
    const index = listSeekerSelected.findIndex(
      (seeker) => seeker.employeeId === item.employeeId,
    );

    const selectedSeekers = [...listSeekerSelected];
    if (index === -1) {
      selectedSeekers.push(item);
    } else {
      selectedSeekers.splice(index, 1);
    }
    setListSeekerSelected([...selectedSeekers]);
  };

  const { data: dataSeekers, isFetching } = useFetch(
    ['getSeeker', debouncedSearchTerm],
    () =>
      ProviderService.searchSeekersNotTrustedBlocked({
        employerId: providerId,
        searchTerm: debouncedSearchTerm,
      }),
    {
      enabled: !!debouncedSearchTerm,
    },
  );

  const addSeekers = () => {
    if (isEditProvider) {
      setLoading(true);
      handleAddSeekers(listSeekerSelected);
    } else {
      form.setFields([{ name, value: [...list, ...listSeekerSelected] }]);
    }
    hiddenModal();
    setSearchText('');

    setListSeekerSelected([]);
  };

  const removeSeeker = (id) => {
    if (isEditProvider) {
      setLoading(true);
      handleRemoveSeeker(id);
    } else {
      const index = list.findIndex((seeker) => seeker.employeeId === id);
      list.splice(index, 1);
      form.setFields([{ name, value: [...list] }]);
    }
  };

  const columns = useMemo(
    () => [
      {
        dataIndex: 'profilepic',
        key: 'profilepic',
        width: '10%',
        render: (dt) => <Avatar size={35} icon={<UserOutlined />} src={dt} />,
      },
      {
        dataIndex: 'employeeFullname',
        key: 'employeeFullname',
        width: '75%',
        render: (dt, record) => (
          <Link to={`/seeker-profile/${record.employeeId}`}>
            <span className='seeker-name'>{dt}</span>
          </Link>
        ),
      },
      {
        dataIndex: 'employeeId',
        key: 'remove',
        width: '15%',
        render: (dt) => (
          <Button
            name={name}
            onClick={() => removeSeeker(dt)}
            title='Remove'
            disabled={false}
            className='float-right'
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCancel = () => {
    hiddenModal();
    setSearchText('');
    setListSeekerSelected([]);
  };

  const removeSeekerSelected = (index) => {
    listSeekerSelected.splice(index, 1);
    setListSeekerSelected([...listSeekerSelected]);
  };

  return (
    <div className='trust-block'>
      <div className='trust-block__header'>
        <div>{title}</div>
        <Button
          name={name}
          onClick={showModal}
          title={`Add ${title}`}
          disabled={false}
        />
      </div>
      <ListView
        className='trust-block__list'
        headerColumns={columns}
        infoDetail={value}
        showHeader={false}
        scroll={{ y: 270 }}
      />
      <Modal
        onCancel={onCancel}
        visible={isDisplayModal}
        footer={
          <Button
            name={name}
            onClick={addSeekers}
            disabled={isEmpty(listSeekerSelected)}
            title={`Add ${title}`}
          />
        }
        className='modal'
      >
        <h2>{title}</h2>
        <div className='block-title'>Search Seeker</div>
        <div className='search-container'>
          <AutoComplete
            placeholder='Search seeker'
            onChange={(e) => setSearchText(e)}
            loading={isFetching}
            className='search-input'
            value={searchText}
          />
          {searchText && dataSeekers?.length > 0 && !isFetching ? (
            <div className='list-seeker-search' style={{ flex: 1 }}>
              {dataSeekers?.map((record) => {
                const isSelected = listSeekerSelected.some(
                  (seeker) => seeker.employeeId === record.employeeId,
                );

                return (
                  <div
                    className={classNames('seeker-field', {
                      'seeker-field-selected': isSelected,
                    })}
                    onClick={() => onSelectSeeker(record)}
                  >
                    <Avatar
                      size={40}
                      icon={<UserOutlined />}
                      src={record.profilepic ?? defaultAvatar}
                    />
                    <div className='name-seeker'>{`${record.employeeFullname}`}</div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className='block-title'>Seekers Selected</div>
        <div className='list-selected'>
          {listSeekerSelected.map((item, index) => (
            <div className='item'>
              <Avatar
                size={40}
                icon={<UserOutlined />}
                src={item.profilepic ?? defaultAvatar}
              />
              <div className='name-seeker'>{`${item.employeeFullname}`}</div>
              <XIconBlack onClick={() => removeSeekerSelected(index)} />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

ListSeeker.propTypes = {
  title: PropTypes.string,
  value: PropTypes.array,
  handleAddSeekers: PropTypes.func,
  handleRemoveSeeker: PropTypes.func,
  setLoading: PropTypes.func,
  onChange: PropTypes.func,
  isEditProvider: PropTypes.bool,
  name: PropTypes.string,
};

export default ListSeeker;
