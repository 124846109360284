import React, { useMemo, useRef, useState } from 'react';

import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Modal, message } from 'antd';
import classNames from 'classnames';
import { debounce, isEmpty, isNil, get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/warningIcon.svg';
import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import {
  Button,
  FormItem,
  Input,
  Select,
  Textarea,
  AutoComplete,
  InputNumber,
} from '@/components';
import { DEBOUNCE_WAIT } from '@/constants';
import { useDebounce, useFetch, useShiftTime } from '@/hooks';
import {
  useProviderLocations,
  useRoleItems,
  useSkillNoLimitPayrate,
} from '@/hooks/dropdowns';
import { JobServices, ProviderService, SeekerService } from '@/services';
import { createJobType, createTimecards } from '@/services/timesheetService';
import { rateCard } from '@/utils';
import { convertTimeMoment } from '@/utils/convertTimeMoment';
import {
  rulesSelect,
  rulesTextInput,
  rulesNumberInput,
} from '@/utils/rulesInput';

import ShiftInput from '../JobRateInfo/ShiftInput';
import Shifts from '../JobRateInfo/shifts';
import ModalJobSeeker from '../JobSeeker/ModalJobSeeker';
import { useTenantContext } from '../../TenantWrapper';
import './styles.scss';

const label = {
  startDate: 'Start Date',
  startTime: 'Start Time',
  endDate: 'End Date',
  endTime: 'End Time',
};

const ModalAddAdditionalJobType = ({
  year,
  onOk,
  onCancel,
  employerId,
  setIsVisible,
  weekNumber,
  isPayroll,
  weekStartDate,
  weekEndDate,
  timesheetRefetch,
  refetchListPayCharge,
  locationId,
  isInListView,
  listProviders,
  ...props
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [seekerId, setSeekerId] = useState(null);
  const [openModalSeekerInfo, setOpenModalSeekerInfo] = useState(false);
  const { currency } = useTenantContext();

  const providerId = Form.useWatch('providerId', form) ?? employerId;
  const { data: locations } = useProviderLocations(providerId);
  const { data: skills, group } = useRoleItems();
  const { data: skillWithoutMinPayRate } = useSkillNoLimitPayrate();
  const postedWatching = Form.useWatch('posted', form);
  const searchTermWatching = Form.useWatch('searchTerm', form);
  const skillIdWatching = Form.useWatch('skillId', form);
  const debouncedSearchTerm = useDebounce(searchTermWatching, DEBOUNCE_WAIT);

  const [isUpdatePayChargeRate, setUpdatePayChargeRate] = useState(false);

  const isUnderagePosition = (skillWithoutMinPayRate ?? []).some(
    (position) => position.value === skillIdWatching,
  );

  const jobResponsesAttributesWatching = Form.useWatch(
    'jobResponsesAttributes',
    form,
  );

  const isFullSeekerChosen =
    jobResponsesAttributesWatching?.length === postedWatching;

  const {
    value,
    setDateTime,
    dateTime,
    startTime,
    hour,
    minute,
    shiftList,
    startDate,
    endTime,
    duration,
    endDate,
    setShiftList,
    removeShift,
    handleAddShift,
  } = useShiftTime(isInListView);

  const startDateRef = useRef('');
  const endDateRef = useRef('');
  const weekNumberRef = useRef();
  const providerRef = useRef('');
  const limitPayRate = group?.[skillIdWatching]?.limitPayRate;

  const updateStartEndDate = (startDateVal, endDateVal) => {
    setDateTime({
      startTime: convertTimeMoment(
        startDateVal,
        moment(value.startTime).format('HH:mm a'),
      ),
      endTime: convertTimeMoment(
        startDateVal,
        moment(value.endTime).format('HH:mm a'),
      ),
      startDate: startDateVal,
      endDate: startDateVal,
    });
  };

  if (weekStartDate && weekStartDate !== startDateRef.current) {
    startDateRef.current = weekStartDate;
    endDateRef.current = weekEndDate;
    updateStartEndDate(weekStartDate, weekEndDate);
  }

  if (weekNumber && !weekStartDate) {
    if (
      providerId !== providerRef.current ||
      weekNumber !== weekNumberRef.current
    ) {
      weekNumberRef.current = weekNumber;
      providerRef.current = providerId;
      form.setFieldsValue({ locationId: '' });
      let providerPaymentWeekStartDate = moment()
        .isoWeek(weekNumber)
        .startOf('isoWeek');

      if (providerId) {
        const providerPaymentWeekStartDay = (listProviders ?? []).find(
          (item) => item.value === providerId,
        )?.startCwday;

        providerPaymentWeekStartDate = providerPaymentWeekStartDay
          ? moment().isoWeek(weekNumber).isoWeekday(providerPaymentWeekStartDay)
          : providerPaymentWeekStartDate;
      }

      const providerPaymentWeekEndDate = providerPaymentWeekStartDate
        .clone()
        .add(6, 'day');

      startDateRef.current = providerPaymentWeekStartDate.format('YYYY-MM-DD');
      endDateRef.current = providerPaymentWeekEndDate.format('YYYY-MM-DD');

      updateStartEndDate(startDateRef.current, endDateRef.current);
    }
  }

  const shifts = [...shiftList];

  const resetFormData = () => {
    form.resetFields();
    setShiftList([]);
  };

  const jobResponsesAttributes =
    Form.useWatch('jobResponsesAttributes', form) ?? [];

  const { data: relatedResults } = useFetch(
    ['calculatechargeRate', providerId],
    () => JobServices.calculateChargeRate(providerId),
    {
      onError: () => message.error('Failed to return charge rate'),
      enabled: !!providerId,
      initialData: {},
    },
  );

  const { data: chargeRateEmployer } = useFetch(
    ['get-charge-rates', providerId],
    () => JobServices.chargeRatesFilterByEmployerId(providerId),
    {
      onError: () => message.error('Failed to return position needed'),
      onSuccess: () => skillIdWatching && setUpdatePayChargeRate(true),
      enabled: !!providerId,
      initialData: {},
    },
  );

  const { data: blockedSeekers = [] } = useFetch(
    ['listBlockedSeekers', providerId],
    () => ProviderService.getListBlockedSeekers(providerId),
    {
      enabled: !!providerId,
    },
  );

  if (isUpdatePayChargeRate) {
    const found = chargeRateEmployer.find(
      (ele) => ele?.skill_id === skillIdWatching,
    );
    setUpdatePayChargeRate(false);
    form.setFieldsValue({
      payrate: found?.pay_rate ?? limitPayRate,
      chargeRate: found?.charge_rate
        ? found?.charge_rate
        : rateCard.calcChargeRate(relatedResults, limitPayRate),

      minPayrate: found?.pay_rate ?? limitPayRate,
    });
  }

  const { data: seekers, isFetching: isSearching } = useFetch(
    ['getSeeker', debouncedSearchTerm],
    () =>
      !debouncedSearchTerm
        ? { data: [] }
        : SeekerService.getSeekers({
            searchTerm: debouncedSearchTerm,
            employee_status: 'active',
          }),
    { initialData: {} },
  );

  const { mutate: postAsTimecard, isLoading: isPostingTimecard } = useMutation(
    () => {
      const jobTypeInfo = form.getFieldsValue();
      if (isInListView) {
        const timesheetAttributes = {
          employerId: providerId,
          locationId: locationId ?? form.getFieldValue('locationId'),
          weekNum: weekNumber,
          year,
        };
        return createTimecards(jobTypeInfo, timesheetAttributes, providerId);
      }
      return createJobType(id, jobTypeInfo, employerId, isPayroll);
    },
    {
      onSuccess: () => {
        setIsVisible(false);
        message.success('Add addition job type successfully');
        timesheetRefetch();
        resetFormData();
        !isInListView && refetchListPayCharge?.();
      },
      onError: (error) => message.error(error.message ?? 'Add job type failed'),
    },
  );

  const dataSeeker = seekers?.data ?? [];

  const isOptionBlocked = (employeeId) =>
    blockedSeekers.some((item) => item.employeeId === employeeId);

  const renderLabel = (opt, isBlocked) => (
    <div className='option-label'>
      <div>{opt.fullname}</div>
      {isBlocked && <XIconBlack />}
    </div>
  );

  const options = dataSeeker.map((opt) => ({
    value: opt.fullname,
    label: renderLabel(opt, isOptionBlocked(opt.employeeId)),
    key: opt.employeeId,
  }));

  const onPayRateChange = debounce((val) => {
    const payrate = parseInt(val, 10);
    const chargeRate = rateCard.calcChargeRate(relatedResults, payrate);

    form.setFieldsValue({
      chargeRate: isNaN(chargeRate) ? '0' : `${chargeRate}`,
    });
  }, 500);

  const onSkillChange = () => providerId && setUpdatePayChargeRate(true);

  const handleCancel = () => {
    resetFormData();
    onCancel();
  };

  const minPayrate = Form.useWatch('minPayrate', form) || limitPayRate;

  const FooterModal = () =>
    useMemo(
      () => [
        <div className='actions-button'>
          <div className='post-as-timecard-button'>
            <Button htmlType='submit' className='yellow-button'>
              Post as Timecard
            </Button>
          </div>

          <div className='close-button'>
            <Button onClick={onCancel} className='black-button'>
              Close
            </Button>
          </div>
        </div>,
      ],
      [],
    );

  const removeShiftIcon = (shiftId, index) => (
    <div className='icon-container input-height remove-shift-icon'>
      <ClosedIcon
        onClick={() => removeShift({ shiftId, form })}
        className={classNames('close-icon')}
      />
    </div>
  );

  const handleButtonAddShift = () => {
    const now = moment();
    const { startTime: inputStartTime, endTime: inputEndTime } = dateTime ?? {};

    const isStartAfterNow = inputStartTime.isAfter(now);

    const isEndBeforeStart = inputEndTime.isBefore(inputStartTime);

    const isEndTimeAfterNow = moment(inputEndTime).isSameOrAfter(now);

    const isSameStartTime = shifts.some((item) =>
      inputStartTime.isSame(item?.startTime),
    );

    const isSameEndTime = shifts.some((item) =>
      inputEndTime.isSame(item?.endTime),
    );

    if (isStartAfterNow && isInListView) {
      message.warn('The start time should be before current time');
      return;
    }

    if (isEndTimeAfterNow) {
      message.warn('The end time should be before current time');
      return;
    }

    if (isEndBeforeStart) {
      message.warn('The end time should be after the start time');
      return;
    }

    if (isSameStartTime && isSameEndTime) {
      message.warn('The job has overlapping schedules');
      return;
    }

    handleAddShift(shifts, form);
  };

  const handleChangeTime = (timeString, isStartTime = true) => {
    if (isStartTime) {
      setDateTime({
        ...value,
        startTime: convertTimeMoment(startDate, timeString),
        endTime: convertTimeMoment(startDate, timeString).add(1, 'hours'),
      });
    } else {
      setDateTime({
        ...value,
        endTime: convertTimeMoment(endDate, timeString),
      });
    }
  };

  const handleChangeDate = (vl, isStartDate = true) => {
    if (isStartDate) {
      setDateTime({
        ...value,
        startDate: moment(vl),
        endDate: moment(vl),
        startTime: convertTimeMoment(vl, value?.startTime.format('HH:mm a')),
        endTime: convertTimeMoment(vl, value?.endTime.format('HH:mm a')),
      });
    } else {
      setDateTime({
        ...value,
        endDate: moment(vl),
        endTime: convertTimeMoment(vl, value?.endTime.format('HH:mm a')),
      });
    }
  };

  const rules = (dateText) => [
    {
      validator: async (_, val) => {
        const valueFormat = moment(val).format('YYYY-MM-DD');

        const isValidDate = moment(valueFormat).isBetween(
          weekStartDate,
          weekEndDate,
        );

        if (!isValidDate) {
          message.error(
            `Please select ${dateText} between ${weekStartDate} and ${weekEndDate}`,
          );
        }

        return Promise.resolve();
      },
    },
  ];

  const addShiftButton = () => (
    <div className='input-height add-shift-container date-picker-style'>
      <Button
        onClick={() => handleButtonAddShift()}
        className='black-button add-shilt-btn input-height'
        disabled={!(startDate && startTime && endDate && endTime)}
      >
        <span>Add Shift</span>
        <RightOutlined />
      </Button>
    </div>
  );

  const validateSearchSeeker = async (_, data) => {
    if (data) {
      try {
        await form.validateFields(['skillId', 'posted', 'content']);
      } catch (error) {
        form.setFieldsValue({
          searchTerm: '',
        });
        const errorName = error.errorFields[0].name;
        form.scrollToField(errorName, {
          skipOverflowHiddenElements: true,
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  };

  const handleFinishFailed = (err) => {
    const errorMessage = get(
      err,
      'errorFields[0].errors[0]',
      'Something went wrong. Please try again',
    );
    message.warn(errorMessage);
  };

  const handleChooseSeeker = (index) => {
    const employeeId = form.getFieldValue([
      'jobResponsesAttributes',
      index,
      'employeeId',
    ]);
    setSeekerId(employeeId);
    setOpenModalSeekerInfo(true);
  };

  const handleCheckFullSeekers = () => {
    if (isFullSeekerChosen) {
      message.warning('Quantity of seekers is full');
      form.resetFields(['searchTerm']);
    }
  };

  const onSelectSeeker = (_value, option) => {
    if (isOptionBlocked(option.key)) {
      message.error(
        `${option.value} is blocked and cannot be added to the shift`,
      );
    } else {
      const fieldsValue = {
        searchTerm: '',
        jobResponsesAttributes: [
          ...jobResponsesAttributes,
          {
            employeeId: option.key,
            status: 'completed',
            seekerName: option.value,
          },
        ],
      };
      form.setFieldsValue(fieldsValue);
    }
  };

  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onOk={onOk}
      onCancel={handleCancel}
      footer={FooterModal}
      className='modal-add-additional-job-type'
    >
      <div className='warning-icon-wrapper'>
        <WarningIcon className='warning-icon' />
      </div>

      <div className='head'>
        <div className='title'>Add Additional Job Type</div>
        <div className='cap'>
          Can only post timecards for hours within this current week
        </div>
      </div>

      <div className='body'>
        <Form
          form={form}
          className='form-wrapper'
          onFinish={postAsTimecard}
          onFinishFailed={handleFinishFailed}
          initialValues={{
            chargeRate: '00.00',
            breakTime: '0',
          }}
          scrollToFirstError={{
            skipOverflowHiddenElements: true,
            block: 'center',
          }}
        >
          <div className='row'>
            {isInListView && (
              <div className='field'>
                <FormItem
                  required
                  name='providerId'
                  label='Choose Provider'
                  className='field-label input-gray'
                  initialValue={employerId}
                >
                  <Select
                    placeholder='Choose Provider'
                    options={listProviders}
                    required
                  />
                </FormItem>
              </div>
            )}
            <div className={classNames('field', { gray: !isInListView })}>
              <FormItem
                required
                name='locationId'
                label='Choose Location'
                className='field-label input-gray'
                initialValue={locationId}
              >
                <Select
                  placeholder='Choose Location'
                  options={locations}
                  required
                  disabled={!isInListView}
                />
              </FormItem>
            </div>

            <div className='field'>
              <FormItem
                required
                rules={rulesSelect('Position Needed')}
                label='Position Needed'
                name='skillId'
                className='field-label input-gray'
              >
                <Select
                  placeholder='Position Needed'
                  options={skills}
                  onChange={onSkillChange}
                  required
                />
              </FormItem>
            </div>

            <div className='field'>
              <FormItem
                required
                rules={rulesSelect('Number of Seekers')}
                name='posted'
                label='Number of Seekers'
                className='field-label input-gray'
              >
                <InputNumber placeholder='Number of Seekers' required min={1} />
              </FormItem>
            </div>
          </div>

          <div className='row'>
            <div className='shift-job-info'>
              <ShiftInput
                isInListView={isInListView}
                isEditable
                value={value}
                dateTime={dateTime}
                setDateTime={setDateTime}
                startTime={startTime}
                hour={hour}
                minute={minute}
                action={addShiftButton}
                label={label}
                required
                onStartDateChange={(vl) => handleChangeDate(vl)}
                onEndDateChange={(vl) => handleChangeDate(vl, false)}
                onStartTimeChange={(time, timeString) =>
                  handleChangeTime(timeString)
                }
                onEndTimeChange={(time, timeString) =>
                  handleChangeTime(timeString, false)
                }
                fixedStartDate={startDateRef.current}
                fixedEndDate={endDateRef.current}
              />
              {!!shiftList.length && (
                <Shifts
                  shiftList={shiftList}
                  startDate={startDate}
                  startTime={startTime}
                  endTime={endTime}
                  duration={duration}
                  hour={hour}
                  minute={minute}
                  endDate={endDate}
                  removeShiftIcon={removeShiftIcon}
                  isEditable
                  isDisableTime
                  rulesStartDate={rules('Start Date')}
                  rulesEndDate={rules('End Date')}
                />
              )}
            </div>
          </div>

          <div className='row'>
            <FormItem required rules={rulesTextInput('Comment')} name='content'>
              <Textarea
                placeholder='Add a comment if needed - This will appear on the reports.'
                rows={4}
              />
            </FormItem>
          </div>

          <div className='row cell-has-width'>
            <div className='field light-gray'>
              <FormItem
                required
                {...(!isUnderagePosition && {
                  rules: rulesNumberInput('Pay Rate'),
                })}
                label='Pay Rate'
                name='payrate'
                className='input-light-gray'
              >
                <InputNumber
                  onChange={onPayRateChange}
                  placeholder='Pay rate'
                  type='number'
                  min={isUnderagePosition ? 0 : minPayrate}
                  prefix={currency}
                  className='input-number'
                />
              </FormItem>
            </div>
            <div className='field light-gray'>
              <FormItem
                required
                rules={[
                  {
                    required: true,
                    message: 'Please enter Charge Rate',
                  },
                  {
                    validator: async (_, chargeRate) => {
                      const payrate = form.getFieldValue('payrate');
                      if (isNil(payrate)) {
                        form.setFields([
                          {
                            name: 'payrate',
                            errors: ['Please enter Pay Rate'],
                          },
                        ]);
                      }

                      if (chargeRate <= payrate) {
                        return Promise.reject(
                          new Error('Value must be greater than Pay rate'),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                label='Charge Rate'
                name='chargeRate'
                className='input-light-gray'
              >
                <InputNumber
                  placeholder='Charge rate'
                  type='number'
                  prefix={currency}
                  className='input-number'
                />
              </FormItem>
            </div>
            {/* This line take an empty space on UI */}
            <FormItem name='minPayrate' hidden />
            <div className='field' />
          </div>

          <div className='row cell-has-width'>
            <div className='field'>
              <FormItem
                required
                rules={rulesTextInput('Break Time')}
                label='Break Time (Mins)'
                name='breakTime'
                className='input-dark-gray'
              >
                <Input placeholder='Select Time' />
              </FormItem>
            </div>

            <div className='field'>
              <FormItem
                label='Search Seeker'
                name='searchTerm'
                rules={[{ validator: validateSearchSeeker }]}
                className='input-light-gray'
              >
                <AutoComplete
                  loading={isSearching}
                  onChange={handleCheckFullSeekers}
                  onSelect={onSelectSeeker}
                  options={options}
                  placeholder='Search seeker'
                />
              </FormItem>
            </div>

            <div className='field'>
              <div className='ant-form-item-label-fake' />
              <Form.List
                initialValue={[]}
                name='jobResponsesAttributes'
                className='form-seeker-list'
              >
                {(fields, { remove }) =>
                  fields.map((field, index) => (
                    <div className='item-seeker-name' key={field.key}>
                      <InfoCircleOutlined
                        className='info-icon'
                        onClick={() => handleChooseSeeker(index)}
                      />
                      <FormItem
                        className='form-item seeker-name bg-white'
                        name={[index, 'seekerName']}
                      >
                        <Input readOnly />
                      </FormItem>
                      <FormItem name={[index, 'employeeId']} hidden />
                      <Button type='text' onClick={() => remove(field.name)}>
                        <XIconBlack />
                      </Button>
                    </div>
                  ))
                }
              </Form.List>
            </div>
          </div>
          <div className='section-post-timecard'>
            <Button
              htmlType='submit'
              className='post-timecard-btn yellow-button'
              loading={isPostingTimecard}
              disabled={
                isEmpty(jobResponsesAttributesWatching) || isEmpty(shiftList)
              }
            >
              Post as timecard
            </Button>
          </div>
        </Form>
        {seekerId && (
          <ModalJobSeeker
            visibleModal={openModalSeekerInfo}
            setVisibleModal={setOpenModalSeekerInfo}
            seekerId={seekerId}
          />
        )}
      </div>
    </Modal>
  );
};

ModalAddAdditionalJobType.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  employerId: PropTypes.number,
  setIsVisible: PropTypes.func,
  weekNumber: PropTypes.number,
  isPayroll: PropTypes.bool,
  weekStartDate: PropTypes.string,
  weekEndDate: PropTypes.string,
  timesheetRefetch: PropTypes.func,
  refetchListPayCharge: PropTypes.func,
  locationId: PropTypes.string,
  isInListView: PropTypes.bool,
  year: PropTypes.number,
  listProviders: PropTypes.array,
};

ModalAddAdditionalJobType.default = {
  isInListView: false,
};

export default ModalAddAdditionalJobType;
