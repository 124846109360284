/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';

import { Form, message } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';

import ListView from '@/components/ListView';
import useDynamicTableHeight from '@/hooks/useDynamicTableHeight';
import { TimesheetService } from '@/services';
import { pullRecord, undoSickNoShow } from '@/services/timesheetService';

import { ACTIONS } from '../../constants';
import useUpdateStatusMutation from '../../hooks/updateStatusMutation';
import { formattedTimecardsPayload } from '../../utils/formatted';
import { getAllTimeCardIsChecked } from '../../utils/select';
import { orderByEmployees } from '../../utils/sort';
import { useActions } from './hooks/useActions';
import listJobTypeColumns from './listJobTypeColumns';
import renderSummary from './renderSummary';
import TimecardFooter from './TimecardFooter';

import './styles.scss';

const BORDER = 3;

const ListJobTypes = ({
  status,
  jobTypeId,
  timesheetDetails,
  isFetching,
  isPushed,
  payrollStatus,
  isCurrentWeekPushed,
  pushedToTimesheetId,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { id: timesheetId } = useParams();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isExpand, toggleExpand] = useToggle();
  const { nodesRef, tableHeight } = useDynamicTableHeight();

  const orderedTimesheetDetails = orderByEmployees(timesheetDetails);

  const LIMIT_ROWS = {
    threeRows: { y: tableHeight + BORDER },
    fullRow: null,
  };

  const resetAfterMutation = () => {
    setIsEdit(false);
    form.resetFields();
    queryClient.invalidateQueries({
      queryKey: ['timesheetDetail', timesheetId],
    });
  };

  const initialValues = {
    [jobTypeId]: timesheetDetails,
  };

  const { mutate: updateStatusMutation, isLoading: isUpdatingStatus } =
    useUpdateStatusMutation({
      timesheetId,
      options: {
        onSuccess: (__, { status: updatedStatus }) => {
          const actionText = _.find(ACTIONS, [
            'updateStatus',
            updatedStatus,
          ]).label;
          message.success(`${actionText} successfully`);
        },
        onSettled: () => resetAfterMutation(),
      },
    });

  const { mutate: deleteHoursMutation, isLoading: isDeletingTimecards } =
    useMutation(
      (timecardIds) =>
        TimesheetService.deleteHours({ timesheetId, timecardIds }),
      {
        onSuccess: (data) => {
          message.success('Delete Hours successfully');
          if (_.isNil(data?.timesheetId)) {
            history.push('/timesheets');
          }
        },
        onError: (error) => message.error(error?.message),
        onSettled: () => resetAfterMutation(),
      },
    );

  const { mutate: updateTimecardsMutation, isLoading: isUpdatingTimecards } =
    useMutation(
      ({ timecardsAttributes }) =>
        TimesheetService.updateTimecards({ timesheetId, timecardsAttributes }),
      {
        onSuccess: (__, { status: updateStatus }) => {
          if (!updateStatus) {
            message.success('Updated Timecards successfully');
            resetAfterMutation();
          }
        },
        onError: (error) => message.error(error?.message),
      },
    );

  const { mutate: pullBackMutation, isLoading: isLoadingPullBack } =
    useMutation(
      (timecardIds) =>
        pullRecord({
          timesheetId,
          timecardIds,
          pushedToTimesheetId,
        }),
      {
        onSuccess: ({ data: resData }) => {
          const { originWeekNum, originTimesheetId, destroyedTimesheet } =
            resData;
          message.success(`Pull back success to Week ${originWeekNum}`);

          if (destroyedTimesheet) {
            history.push(`/timesheet-detail/${originTimesheetId}`);
          }
        },
        onError: (error) => message.error(error.message),
        onSettled: () => resetAfterMutation(),
      },
    );
  const { mutate: sickNoShowMutation, isLoading: isUpdatingSickNoShow } =
    useMutation(
      ({ timecardIds, noShowAttributes }) =>
        TimesheetService.sickNoShow({
          timesheetId,
          timecardIds,
          noShowAttributes,
        }),
      {
        onSuccess: (__, { noShowAttributes }) => {
          const { noShowType } = noShowAttributes;
          const type = _.find(ACTIONS, ['updateStatus', noShowType]).key;
          message.success(`Updated ${ACTIONS[type].label} successfully`);
        },
        onError: (error) => message.error(error?.message),
        onSettled: () => resetAfterMutation(),
      },
    );

  const { mutate: undoMutation, isLoading: isUndoing } = useMutation(
    (timecardIds) => undoSickNoShow({ timesheetId, timecardIds }),
    {
      onSuccess: () => message.success('Undo Successfully'),
      onError: (error) => message.error(error?.message),
      onSettled: () => resetAfterMutation(),
    },
  );

  const getAllTimecardAttributes = () => {
    const formData = form.getFieldsValue();
    const comment = _.get(formData, 'comment', '');
    const allTimecardsIsChecked = getAllTimeCardIsChecked({
      formData: formData[jobTypeId],
    });

    return {
      ...allTimecardsIsChecked,
      comment,
    };
  };

  const actions = useActions({
    undoMutation,
    pullBackMutation,
    sickNoShowMutation,
    deleteHoursMutation,
    updateStatusMutation,
    updateTimecardsMutation,
    getAllTimecardAttributes,
    formattedTimecardsPayload,
  });

  const columns = listJobTypeColumns({
    form,
    isEdit,
    status,
    nodesRef,
    jobTypeId,
    payrollStatus,
    timesheetDetails,
    isCurrentWeekPushed,
  });

  const extraColumns = columns.map((col) => ({
    ...col,
    ...(col?.title !== 'Employee' && { width: '5.8%' }),
  }));

  extraColumns.splice(3, 0, {
    title: 'Pushed Record',
    dataIndex: 'pushedRecord',
    width: '5.8%',
    render: (__, record) => {
      const prevWeek = _.get(record, 'pushFromTimesheet.preWeek');
      const currentWeek = _.get(record, 'currentWeek');
      const originTimesheetId = _.get(record, 'pushFromTimesheetId');
      return (
        <div className='weeks-container'>
          <Link to={`/timesheet-detail/${originTimesheetId}`}>
            <p className='red-color'>Week {prevWeek}</p>
          </Link>
          -
          <Link to={`/timesheet-detail/${pushedToTimesheetId}`}>
            <p className='red-color'>Week {currentWeek}</p>
          </Link>
        </div>
      );
    },
  });

  const summary = (pageData) =>
    renderSummary({
      jobTypeId,
      pageData,
      status,
      isExpand,
      isPushed,
      toggleExpand,
    });

  const footer = (rowData) => (
    <TimecardFooter
      isPushed={isPushed}
      isCurrentWeekPushed={isCurrentWeekPushed}
      jobTypeId={jobTypeId}
      status={status}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      actions={actions}
      rowData={rowData}
    />
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [timesheetDetails]);

  const isLoading =
    isUndoing ||
    isFetching ||
    isUpdatingStatus ||
    isLoadingPullBack ||
    isUpdatingTimecards ||
    isDeletingTimecards ||
    isUpdatingSickNoShow;

  return (
    <Form
      {...(!isCurrentWeekPushed && { name: status })}
      form={form}
      initialValues={initialValues}
    >
      <div className='list-job-types'>
        <ListView
          bordered
          summary={summary}
          pagination={false}
          headerColumns={isPushed ? extraColumns : columns}
          loading={isLoading}
          rowClassName='editable-row'
          scroll={LIMIT_ROWS.threeRows}
          footer={footer}
          infoDetail={orderedTimesheetDetails}
          className={classNames('list-job-types-table', {
            [status]: true,
            'show-all': isExpand,
            gray: isPushed,
          })}
        />
      </div>
    </Form>
  );
};

ListJobTypes.propTypes = {
  status: PropTypes.string,
  jobTypeId: PropTypes.number,
  isFetching: PropTypes.bool,
  payrollStatus: PropTypes.string,
  timesheetDetails: PropTypes.object,
  isPushed: PropTypes.bool,
  isCurrentWeekPushed: PropTypes.bool,
  pushedToTimesheetId: PropTypes.number,
};

ListJobTypes.defaultProps = {
  status: '',
  isFetching: false,
  payrollStatus: '',
  timesheetDetails: {},
  isPushed: false,
  isCurrentWeekPushed: false,
  pushedToTimesheetId: null,
};

export default ListJobTypes;
