import React from 'react';

import { Menu as AntdMenu } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const Menu = ({ className, menuItems, onClick }) => (
  <AntdMenu
    className={classNames(className)}
    onClick={({ domEvent, key }) => onClick(domEvent, key)}
  >
    {menuItems.map((item) => (
      <Menu.Item key={item.item}>{item.item}</Menu.Item>
    ))}
  </AntdMenu>
);

Menu.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.array,
  onClick: PropTypes.func,
};

Menu.defaultProps = {
  className: '',
  menuItems: [],
  onClick: () => {},
};

export default Menu;
