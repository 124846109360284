import { listRoles } from '@/constants';
import { useLocations } from '@/hooks/dropdowns';

export const GetOtherRolesByListId = (
  /** @type {number[]} */ listRolesValue,
) => {
  const filteredListRole = listRoles.filter((role) =>
    listRolesValue?.includes(parseInt(role.value, 10)),
  );
  return filteredListRole.map((role) => role.label).join(', ');
};

export const GetGeneralRoleById = (/** @type {number} */ id) => {
  const generalRole = listRoles?.find(
    (role) => parseInt(role.value, 10) === id,
  );
  return generalRole?.label;
};

export const GetOfficeById = (/** @type {number} */ id) => {
  const { listLocations } = useLocations();
  const office = listLocations?.find(
    (location) => parseInt(location.value, 10) === id,
  );
  return office?.label;
};
