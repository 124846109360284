import React from 'react';

import PropTypes from 'prop-types';

import { useSessionStorage } from '@/hooks';

import CalendarView from '../../JobListPage/CalendarView';
import SharedLayout from '../sharedLayout';
import SwitchMode from '../SwitchMode';
import './styles.scss';

/**
 * Renders the JobListView component.
 *
 * @param {Object} props
 *   - onClickEditJob: Function
 *   - setShowPopupCreate: Function
 *   - setRepostId: Function
 *   - setJobIdEdit: Function
 *   - query: Object
 *   - isCalendarMode: boolean
 *   - toggleCalendarMode: Function
 *   - setRowId: Function
 *   - rowId: string
 *   - isCompletedTab: boolean
 *   - roles: Array
 *   - providers: Array
 *   - locations: Array
 *   - industries: Array
 *   - allFetched: boolean
 * @return {JSX.Element}
 */
const JobListView = ({
  onClickEditJob,
  setShowPopupCreate,
  setRepostId,
  setJobIdEdit,
  query,
  isCalendarMode,
  toggleCalendarMode,
  setRowId,
  rowId,
  isCompletedTab,
  roles,
  providers,
  locations,
  industries,
  allFetched,
}) => {
  const [, setJobsFilter] = useSessionStorage('searchJobs');

  const openManageWorkerModal = (form, id) => {
    setRowId(id);
    form.setFieldsValue({ showManageModal: true });
  };

  const onChangeSwitch = (isChecked) => {
    setJobsFilter({
      jobMode: isChecked,
    });
    toggleCalendarMode();
  };

  return (
    <div className='job-list-view'>
      <SwitchMode
        className='list-view-switch-mode'
        isChecked={isCalendarMode}
        name='jobMode'
        leftTitle='List View'
        rightTitle='Calendar'
        onChange={onChangeSwitch}
      />

      {isCalendarMode ? (
        <CalendarView
          setRepostId={setRepostId}
          setJobIdEdit={setJobIdEdit}
          setShowPopupCreate={setShowPopupCreate}
          openManageWorkerModal={openManageWorkerModal}
          rowId={rowId}
          isCompletedTab={isCompletedTab}
          providers={providers}
          locations={locations}
          allFetched={allFetched}
        />
      ) : (
        <SharedLayout
          onRow={onClickEditJob}
          setShowPopupCreate={setShowPopupCreate}
          setRepostId={setRepostId}
          setJobIdEdit={setJobIdEdit}
          query={query}
          openManageWorkerModal={openManageWorkerModal}
          rowId={rowId}
          isCompletedTab={isCompletedTab}
          roles={roles}
          providers={providers}
          locations={locations}
          industries={industries}
          allFetched={allFetched}
        />
      )}
    </div>
  );
};

JobListView.propTypes = {
  isCalendarMode: PropTypes.bool,
  onClickEditJob: PropTypes.func,
  setShowPopupCreate: PropTypes.func,
  setRepostId: PropTypes.func,
  setJobIdEdit: PropTypes.func,
  query: PropTypes.object,
  toggleCalendarMode: PropTypes.func,
  setRowId: PropTypes.func,
  rowId: PropTypes.number || PropTypes.string,
  isCompletedTab: PropTypes.bool,
  roles: PropTypes.array,
  providers: PropTypes.array,
  locations: PropTypes.array,
  industries: PropTypes.array,
  allFetched: PropTypes.bool,
};

JobListView.defaultProps = {
  isCalendarMode: false,
  onClickEditJob: () => {},
  setShowPopupCreate: () => {},
  setRepostId: () => {},
  setJobIdEdit: () => {},
  query: {},
  toggleCalendarMode: () => {},
  setRowId: () => {},
  rowId: '',
  isCompletedTab: false,
  roles: [],
  providers: [],
  locations: [],
  industries: [],
  allFetched: false,
};

export default JobListView;
