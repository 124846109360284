import { ACTIONS } from '@/pages/Timesheet/constants';

export const useActions = ({
  undoMutation,
  pullBackMutation,
  sickNoShowMutation,
  deleteHoursMutation,
  updateStatusMutation,
  updateTimecardsMutation,
  getAllTimecardAttributes,
  formattedTimecardsPayload,
}) => ({
  [ACTIONS.approveHours.key]: () => {
    const { timecardIds, timecardsAttributes } = getAllTimecardAttributes();
    const formattedToRes = formattedTimecardsPayload(timecardsAttributes);

    updateStatusMutation({
      timecardIds,
      status: ACTIONS.approveHours.updateStatus,
    });
    updateTimecardsMutation({
      timecardsAttributes: formattedToRes,
      status: ACTIONS.approveHours.key,
    });
  },
  [ACTIONS.confirmHours.key]: () => {
    const { timecardIds, timecardsAttributes } = getAllTimecardAttributes();
    const formattedToRes = formattedTimecardsPayload(timecardsAttributes);

    updateStatusMutation({
      timecardIds,
      status: ACTIONS.confirmHours.updateStatus,
    });
    updateTimecardsMutation({
      timecardsAttributes: formattedToRes,
      status: ACTIONS.confirmHours.key,
    });
  },
  [ACTIONS.updateTimecards.key]: () => {
    const { timecardsAttributes } = getAllTimecardAttributes();
    const formattedToRes = formattedTimecardsPayload(timecardsAttributes);
    updateTimecardsMutation({ timecardsAttributes: formattedToRes });
  },
  [ACTIONS.deleteHours.key]: () => {
    const { timecardIds } = getAllTimecardAttributes();
    deleteHoursMutation(timecardIds);
  },
  [ACTIONS.sick.key]: () => {
    const { timecardIdsWithoutNoShow: timecardIds, comment } =
      getAllTimecardAttributes();
    const noShowAttributes = {
      noShowType: ACTIONS.sick.updateStatus,
      comment,
    };
    sickNoShowMutation({
      timecardIds,
      noShowAttributes,
    });
  },
  [ACTIONS.noShow.key]: () => {
    const { timecardIdsWithoutNoShow: timecardIds, comment } =
      getAllTimecardAttributes();
    const noShowAttributes = {
      noShowType: ACTIONS.noShow.updateStatus,
      comment,
    };
    sickNoShowMutation({ timecardIds, noShowAttributes });
  },
  [ACTIONS.pullBack.key]: () => {
    const { timecardIds } = getAllTimecardAttributes();
    pullBackMutation(timecardIds);
  },
  [ACTIONS.undo.key]: () => {
    const { timecardIdsSickNoShow: timecardIds } = getAllTimecardAttributes();
    undoMutation(timecardIds);
  },
});
