/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import _ from 'lodash';

import { FORM_SELECT_NAMES } from '../constants';

const findNoSelect = ({ object, checkboxType }) =>
  _.findKey(object, (item) =>
    _.isObject(item) ? !_.get(item, checkboxType, false) : undefined,
  );

const selectAll = ({ object, checkboxType, value }) =>
  _.cloneDeepWith(object, (__, key) => {
    if (key === checkboxType) {
      return value;
    }
  });

const selectSickness = ({ object, isChecked }) =>
  _.cloneDeepWith(object, (value) => {
    if (_.has(value, 'noShow')) {
      return {
        ...value,
        isCheckedCell: isChecked,
      };
    }
  });

const checkAndSelectCells = ({
  formData,
  namePath,
  jobTypeId,
  timesheetDetailId,
  value = false,
}) => {
  const isCellChecked = _.get(
    formData,
    [...namePath, FORM_SELECT_NAMES.cell],
    value,
  );
  formData[jobTypeId][timesheetDetailId] = selectSickness({
    object: formData[jobTypeId][timesheetDetailId],
    isChecked: isCellChecked,
  });
};

const checkAndSelectRows = ({ formData, jobTypeId, timesheetDetailId }) => {
  const isAllTimecardsChecked = !findNoSelect({
    object: formData[jobTypeId][timesheetDetailId].timecards,
    checkboxType: ['attributes', FORM_SELECT_NAMES.cell],
  });
  formData[jobTypeId][timesheetDetailId].isCheckedRow = isAllTimecardsChecked;
};

const checkAndSelectAll = ({ formData, jobTypeId }) => {
  const isSelectAllRows = !findNoSelect({
    object: formData[jobTypeId],
    checkboxType: FORM_SELECT_NAMES.rows,
  });
  formData[jobTypeId].isCheckedAll = isSelectAllRows;
};

const getAllTimeCardIsChecked = ({ formData }) => {
  const timecardsAttributes = _.values(formData).flatMap((timesheet) =>
    _.values(timesheet?.timecards ?? {}).filter(
      (timecard) => timecard?.attributes?.isCheckedCell,
    ),
  );

  const timecardAttributesWithoutNoShow = _.filter(
    timecardsAttributes,
    (item) => !_.has(item, 'attributes.noShow'),
  );
  const timecardIds = _.map(timecardsAttributes, (timecard) =>
    _.get(timecard, 'attributes.id'),
  );
  const timecardIdsWithoutNoShow = _.map(
    timecardAttributesWithoutNoShow,
    'attributes.id',
  );
  const timecardIdsSickNoShow = _.difference(
    timecardIds,
    timecardIdsWithoutNoShow,
  );
  const schedules = _.map(timecardsAttributes, (timecard) =>
    _.get(timecard, 'attributes.schedule'),
  );
  return {
    schedules,
    timecardIds,
    timecardsAttributes,
    timecardIdsSickNoShow,
    timecardIdsWithoutNoShow,
  };
};

const getAllTimeCardIdsBySkill = ({ formData }) => {
  const timecardIds = _.flatMap(_.values(formData), (timesheet) =>
    _.map(_.values(timesheet.timecards), 'id'),
  );
  return { timecardIds };
};

const checkShouldUpdate = ({ prevValues, currValues }) => {
  const prevData = _.get(prevValues, ['selectedTimecardIds']);
  const currData = _.get(currValues, ['selectedTimecardIds']);
  return !_.isEqual(prevData, currData);
};

const updateSelectedTimecardIds = (
  payRateForm,
  fieldNameChanged,
  namePathChanged,
  statusChanged,
  valueChanged,
) => {
  const selectedTimecardIds = payRateForm.getFieldValue([
    'selectedTimecardIds',
  ]);
  let allTimecardIds = [];
  const [jobTypeId, key] = namePathChanged;

  const getFieldPath = () => {
    switch (fieldNameChanged) {
      case FORM_SELECT_NAMES.cell:
        const fieldCellPath = namePathChanged.slice(0, -1);
        const { id: timecardId } = payRateForm.getFieldValue([
          'jobTypeAdded',
          'current',
          statusChanged,
          ...fieldCellPath,
        ]);
        allTimecardIds = [timecardId.toString()];
        break;
      case FORM_SELECT_NAMES.rows:
        const timesheetDetailInfo = payRateForm.getFieldValue([
          'jobTypeAdded',
          'current',
          statusChanged,
          jobTypeId,
          key,
        ]);
        allTimecardIds = _.map(_.values(timesheetDetailInfo.timecards), 'id');
        break;
      case FORM_SELECT_NAMES.all:
        const timesheetDetails = payRateForm.getFieldValue([
          'jobTypeAdded',
          'current',
          statusChanged,
          jobTypeId,
        ]);
        allTimecardIds = _.flatMap(
          _.values(timesheetDetails),
          (timesheetDetail) => _.map(_.values(timesheetDetail.timecards), 'id'),
        );
        break;
      default:
        break;
    }
  };

  getFieldPath();

  const updatedTimecardIds = valueChanged
    ? _.uniq([...selectedTimecardIds, ...allTimecardIds])
    : _.difference(selectedTimecardIds, allTimecardIds);

  return updatedTimecardIds;
};

export {
  findNoSelect,
  selectAll,
  selectSickness,
  checkShouldUpdate,
  checkAndSelectAll,
  checkAndSelectRows,
  checkAndSelectCells,
  getAllTimeCardIsChecked,
  getAllTimeCardIdsBySkill,
  updateSelectedTimecardIds,
};
