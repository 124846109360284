import React, { useEffect, useState } from 'react';

import { Col, Form, Row, Typography, Spin, Tooltip } from 'antd';
import { isEmpty, parseInt, round } from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Pagination } from '@/components';
import Modal from '@/components/AllocateToRotaModal';
import { useFetch, useMergeState, useQueryString } from '@/hooks';
import { getAll } from '@/services/rota';

import InputForm from '../components/InputForm';
import TableRota from '../components/TableRota';

import './styles.scss';

const { Title } = Typography;

const RotaListView = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [allocateActive, setAllocateActive] = useState(false);
  const [currentRotaId, setCurrentRotaId] = useState(null);
  const [panigation, setPanigation] = useMergeState({
    pageSize: 25,
    current: 1,
    total: 0,
  });

  const [query] = useQueryString();

  const { state = {} } = useLocation();
  const editedId = state?.id;

  useEffect(() => {
    if (editedId) {
      setCurrentRotaId(editedId);
      setAllocateActive(true);
    }
  }, [editedId]);

  useEffect(() => {
    const { location, provider, positions } = query;

    form.setFieldsValue({
      location: location?.toInt(),
      provider: provider?.toInt(),
      positions: positions?.toInt(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      width: '15%',
      ellipsis: true,
      render: (_, record) => (
        <Tooltip placement='topLeft' title={record?.employer?.name}>
          {record?.employer?.name}
        </Tooltip>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      ellipsis: true,
      render: (_, record) => (
        <Tooltip placement='topLeft' title={record?.employerLocation?.name}>
          {record?.employerLocation?.name}
        </Tooltip>
      ),
    },
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      key: 'jobType',
      width: '15%',
      ellipsis: true,
      render: (_, record) => (
        <Tooltip placement='topLeft' title={record?.skill?.name}>
          {record?.skill?.name}
        </Tooltip>
      ),
    },
    {
      title: 'Contract Type',
      dataIndex: 'contactType',
      key: 'contactType',
      width: '10%',
      ellipsis: true,
      render: (data) => (
        <Tooltip placement='topLeft' title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: 'Shift',
      dataIndex: 'age',
      key: 'age',
      width: '15%',
      ellipsis: true,
      render: (text, record = {}) => {
        const { startTime, endTime } = record;

        const formattedStartTime = moment(startTime).format('A - HH:mm');
        const formattedEndTime = moment(endTime).format('HH:mm');

        const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

        return (
          <Tooltip placement='topLeft' title={formattedTimeRange}>
            {formattedTimeRange}
          </Tooltip>
        );
      },
    },
    {
      title: 'Workers',
      dataIndex: 'numberOfEmployees',
      key: 'numberOfEmployees',
      width: '5%',
      ellipsis: true,
      render: (data) => (
        <Tooltip placement='topLeft' title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: 'Average Weekly Hours',
      dataIndex: 'totalHours',
      key: 'totalHours',
      width: '5%',
      ellipsis: true,
      render: (value) => (
        <Tooltip placement='topLeft' title={round(parseInt(value) / 12, 2)}>
          {round(parseInt(value) / 12, 2)}
        </Tooltip>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (text, record) => (
        <div className='actions'>
          <Button
            className='edit-btn'
            type='primary'
            size='medium'
            onClick={() => history.push(`edit-rota/${text.key}`)}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setAllocateActive(true);
              setCurrentRotaId(text.key);
            }}
            className='allocate-btn'
            type='primary'
            size='medium'
          >
            Allocate
          </Button>
        </div>
      ),
    },
  ];

  const {
    data: dataAllRota,
    refetch: refetchGetRota,
    isFetching,
  } = useFetch(
    ['getRota', panigation.pageSize, panigation.current],
    () => {
      const { location, positions, provider } = form.getFieldsValue();

      return getAll({
        employerId: provider,
        locationId: location,
        skillId: positions,
        page: {
          size: panigation.pageSize,
          number: panigation.current,
        },
      });
    },

    {
      onSuccess: (data) =>
        setPanigation({
          current: data.currentPage,
          total: data.totalCount,
        }),
    },
  );
  const handleSearchRota = () => {
    refetchGetRota();
  };

  const handleClearFilter = () => {
    form.resetFields();
    refetchGetRota();
  };

  return (
    <>
      <div className='worker-rota'>
        <Row className='input-search-form'>
          <Col span={20}>
            <Form form={form} className='input-container'>
              <InputForm
                handleClearFilter={handleClearFilter}
                handleSearchRota={handleSearchRota}
              />
            </Form>
          </Col>
        </Row>
        <Row className='worker-rota-table-wrapper'>
          <Spin spinning={isFetching}>
            {!isEmpty(dataAllRota?.data) && (
              <>
                <Title level={3}>Rota Totals</Title>
                <TableRota
                  data={dataAllRota?.data ?? []}
                  columns={columns}
                  pagination
                />
                <Pagination
                  pageSize={panigation.pageSize}
                  current={panigation.current}
                  total={panigation.total}
                  onChange={(pageNumber, pageSize) => {
                    setPanigation({
                      current: pageNumber,
                      pageSize,
                    });
                  }}
                />
              </>
            )}
          </Spin>
        </Row>
      </div>
      <Modal
        rotaId={currentRotaId}
        visible={allocateActive}
        refetchGetRota={refetchGetRota}
        onClickNo={() => setAllocateActive(false)}
      />
    </>
  );
};

RotaListView.defaultProps = {
  statuses: '',
  isShowStatus: false,
};
export default RotaListView;
