/* eslint-disable max-len */
import { isArray } from 'lodash';
import moment from 'moment';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const getDetailRota = async (id) => {
  const endpoint = `/rotas/rotas/${id}`;

  const res = await request.get(endpoint);
  const { data = {} } = normalizeShowResponse(res);

  const {
    rotaWeeks = [],
    contactTypeAttributes = {},
    employerLocationName = '',
    skillName,
    startTime,
    endTime,
    employeesRotasAttributes = [],
  } = data;

  const { name = '' } = contactTypeAttributes;
  const formattedStartTime = moment(startTime).format('hh:mm');
  const formattedEndTime = moment(endTime).format('hh:mm');
  const parseEmployeesRotasAttributes = isArray(employeesRotasAttributes)
    ? employeesRotasAttributes
    : [employeesRotasAttributes];

  const title = `${skillName} at ${employerLocationName} on rota ${name}, ${formattedStartTime} - ${formattedEndTime}`;

  return {
    rotaWeeks,
    title,
    employeesRotasAttributes: parseEmployeesRotasAttributes,
  };
};

export default getDetailRota;
