import React from 'react';

import { Drawer, Col, Form, message } from 'antd';
import { isNil } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useWindowSize } from 'usehooks-ts';

import { FormItem } from '@/components';
import { useFetch } from '@/hooks';
import { getJobSeekersByStatus } from '@/services/jobServices/getSeekers';
import { updateJobSeeker } from '@/services/jobServices/updateJobSeeker';
import { timestampTo } from '@/utils/date';

import { ATTENDANCES_STATUS } from '../components/constants';
import AssignList from './AssignList';
import { MANAGE_WORKER_TABS } from './constants';
import Header from './Header';

import './styles.scss';

/**
 * @param {string | Date} time
 * @returns {string}
 */
const SPECIFIC_FORMAT = 'DD MMMM YYYY HH:mm:ss';
const formatSpecificTime = (time) => moment(time).format(SPECIFIC_FORMAT);

/**
 *
 * @param {{
 * rowId: number | string,
 * refetchJobsListview: Function,
 * setModalContent: Function,
 * updatedAt: string,
 * createdAt: string,
 * jobDetail: object,
 * }} params- The event object containing information about the event.
 * @return {JSX.Element} The rendered event component.
 */
const CheckinCheckoutJobsDrawer = ({
  rowId,
  refetchJobsListview,
  setModalContent,
  updatedAt,
  createdAt,
  jobDetail,
}) => {
  const formInstance = Form.useFormInstance();
  const { width: currentWidth, height } = useWindowSize();

  const appliedList = Form.useWatch('appliedList', formInstance);
  const removedList = Form.useWatch('removedList', formInstance);
  const activeKey = Form.useWatch('activeKey', formInstance);
  const seekers = Form.useWatch('seekers', formInstance);
  const showManageModal = Form.useWatch('showManageModal', formInstance);
  const isCompletedTab = Form.useWatch('isCompletedTab', formInstance);

  const setShowManageModal = (value) =>
    formInstance.setFieldsValue({ showManageModal: value });

  const { refetch: refetchSeekers, isFetching: isFetchingJobsStatus } =
    useFetch(
      [activeKey, rowId],
      () => getJobSeekersByStatus(rowId, activeKey),
      {
        onSuccess: (data) => {
          const firstShift = data?.[0]?.assignedSchedules?.[0]?.schedule ?? {};
          const { startAt, endAt } = firstShift;
          const { fullDate: formatedStartAt } = timestampTo(startAt);
          const { fullDate: formatedEndAt } = timestampTo(endAt);

          formInstance.setFields([
            {
              name: 'checkIn',
              value: moment(formatedStartAt),
            },
            {
              name: 'checkOut',
              value: moment(formatedEndAt),
            },
            {
              name: 'seekers',
              value: data,
            },
          ]);
        },
        enabled: !!rowId,
      },
    );
  const { mutate: updateSeekers } = useMutation(
    ({ seekerList, statusUpdate }) =>
      updateJobSeeker(rowId, seekerList, statusUpdate),
    {
      onSuccess: () => {
        setModalContent({ visible: false });
        refetchSeekers();
        refetchJobsListview();
      },

      onError: (err) => {
        message.error(err.message);
      },
    },
  );

  const setActiveKey = (value) =>
    formInstance.setFieldsValue({
      activeKey: value,
    });

  const setAppliedList = (value) =>
    formInstance.setFieldsValue({ appliedList: value });

  const setRemovedList = (value) =>
    formInstance.setFieldsValue({ removedList: value });

  const declineSeekers = (seekerList) =>
    setModalContent({
      visible: true,
      title: 'Decline?',
      content: 'Are you sure want to decline all selected Seekers',
      titleSuccess: 'Declined seeker successfully',
      onClickYes: () => updateSeekers({ seekerList, statusUpdate: 'declined' }),
    });

  const acceptSeekers = (seekerList) =>
    setModalContent({
      visible: true,
      title: 'Accept?',
      content: 'Are you sure want to accept all selected Seekers',
      titleSuccess: 'Accepted seeker successfully',
      onClickYes: () => updateSeekers({ seekerList, statusUpdate: 'accepted' }),
    });

  const selectAllApplied = (e) => {
    if (e.target.checked) {
      const appliedItems = seekers.map((item) => item.id);
      return setAppliedList(appliedItems);
    }
    return setAppliedList([]);
  };

  const selectAppliedSeekers = (e, record) => {
    const { id } = record;
    if (e.target.checked) {
      return setAppliedList([...appliedList, id]);
    }

    const updatedList = appliedList.filter((item) => item !== id);
    return setAppliedList(updatedList);
  };

  const selectAllDeclined = (e) => {
    if (e.target.checked) {
      const removedItems = seekers.filter(
        (item) => item.attendanceStatus === ATTENDANCES_STATUS.UNCHECKED,
      );
      const removedIds = removedItems.map((item) => item.id);

      return setRemovedList(removedIds);
    }
    return setRemovedList([]);
  };

  const selectDeclinedSeekers = (e, record) => {
    const { id } = record;
    if (e.target.checked) {
      return setRemovedList([...removedList, id]);
    }

    const updatedList = removedList.filter((item) => item !== id);
    return setRemovedList(updatedList);
  };

  return (
    <Drawer
      title='Manage Workers'
      placement='right'
      closable={false}
      width={currentWidth * 0.6}
      bodyStyle={{ height, overflowY: 'scroll' }}
      onClose={() => {
        setShowManageModal(false);
        setActiveKey(MANAGE_WORKER_TABS.APPLIED);
        refetchJobsListview();
      }}
      visible={showManageModal}
      key='right'
      destroyOnClose
      extra={
        <Col className='col-time-edited'>
          <div className='assigned-at'>
            Posted at: {formatSpecificTime(createdAt)}
          </div>
          {!isNil(updatedAt) && (
            <div className='assigned-at'>
              Updated at:{formatSpecificTime(updatedAt)}
            </div>
          )}
        </Col>
      }
    >
      <FormItem
        name='activeKey'
        initialValue={MANAGE_WORKER_TABS.APPLIED}
        hidden
      />
      <FormItem name='appliedList' initialValue={[]} hidden />
      <FormItem name='removedList' initialValue={[]} hidden />
      <FormItem name='seekers' initialValue={[]} />
      <Header jobDetail={jobDetail} />
      <AssignList
        isLoading={isFetchingJobsStatus}
        positionId={rowId}
        seekers={seekers}
        jobDetail={jobDetail}
        activeKey={activeKey}
        appliedList={appliedList}
        removedList={removedList}
        setActiveKey={setActiveKey}
        declineSeekers={declineSeekers}
        acceptSeekers={acceptSeekers}
        selectAllApplied={selectAllApplied}
        selectAppliedSeekers={selectAppliedSeekers}
        selectAllDeclined={selectAllDeclined}
        selectDeclinedSeekers={selectDeclinedSeekers}
        isCompletedTab={isCompletedTab}
        refetchJobsListview={refetchJobsListview}
      />
    </Drawer>
  );
};

export default CheckinCheckoutJobsDrawer;

CheckinCheckoutJobsDrawer.propTypes = {
  rowId: PropTypes.number,
  refetchJobsListview: PropTypes.func,
  setModalContent: PropTypes.func,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  jobDetail: PropTypes.object,
};

CheckinCheckoutJobsDrawer.defaultProps = {
  rowId: null,
  refetchJobsListview: () => {},
};
