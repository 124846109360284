import React from 'react';

import { Image as AntdImage } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import defaultAvatar from '@/assets/images/defaultAvatar.png';

import PDF from '../PDF';
import './styles.scss';

const ThumbnailFile = ({
  src,
  alt,
  type,
  className,
  containerClassName,
  width,
}) => (
  <div className={classNames('img-container', containerClassName)}>
    {type === 'application' ? (
      <PDF fileName={src} width={width} />
    ) : (
      <AntdImage
        className={className}
        src={src || defaultAvatar}
        alt={alt}
        fallback={defaultAvatar}
      />
    )}
  </div>
);

ThumbnailFile.defaultProps = {
  src: '',
  className: '',
  containerClassName: '',
  alt: '',
  type: '',
  width: 3000,
};

ThumbnailFile.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
};

export default ThumbnailFile;
