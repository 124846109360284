/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';

import {
  DownloadOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, List, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { ReactComponent as CheckedIcon } from '@/assets/icons/checkedIcon.svg';
import { ReactComponent as DeclineButton } from '@/assets/icons/closedCircle.svg';
import { Modal } from '@/components';
import ModalJobSeeker from '@/components/JobSeeker/ModalJobSeeker';
import { useFetch } from '@/hooks';
import { getJobSeekersByStatus } from '@/services/jobServices/getSeekers';
import { updateJobSeeker } from '@/services/jobServices/updateJobSeeker';
import useModal from '@/utils/useModal';

import './styles.scss';

/**
 * @typedef {Object} AssignedScheduleAttributesParams
 * @property {number | string} id
 */

/**
 * Represents an array of arrays containing job response data.
 * Each inner array contains objects with specific properties.
 * @typedef {Array<Array<{
 *   id: number,
 *   fullname: string,
 *   status: string,
 *   jobResponsesId: number,
 *   employeeId: number,
 *   startTime: number,
 *   endTime: number
 * }>>} JobSeekersProps
 */

/**
 * Represents the type of `seekerId`.
 * @typedef {string|number} SeekerIdType
 */

/**
 *
 * @param {Object} param
 * @param {string|number} param.eventId
 * @param {Function} param.refetchCalendarJobs
 * @param {Function} param.downloadCSVJobs
 * @returns
 */
const AppliedTab = ({ eventId, refetchCalendarJobs, downloadCSVJobs }) => {
  const { data: jobSeekers } = useFetch(['eventAppliedSeekers', eventId], () =>
    getJobSeekersByStatus(eventId, 'applied'),
  );

  const [seekerId, setSeekerId] = useState(null);
  const [isDisplayModal, showModal, hiddenModal] = useModal();

  const [modalContent, setModalContent] = useState({
    title: '',
    content: '',
    onClickYes: () => {},
  });

  const handleViewInfo = ({ employeeId }) => {
    setSeekerId(employeeId);
  };

  const { mutate: updateSeekerForJob, isLoading } = useMutation(
    ({ selectedIds, status }) => updateJobSeeker(eventId, selectedIds, status),
    {
      onError: (err) => {
        if (err instanceof Error) {
          message.error(err?.message);
        }
        hiddenModal();
      },
      onSuccess: () => {
        hiddenModal();
        refetchCalendarJobs();
      },
    },
  );

  const handleDecline = (jobResponsesId) => {
    updateSeekerForJob({
      selectedIds: [jobResponsesId],
      status: 'declined',
    });
  };

  const handleAccept = (jobResponsesId) =>
    updateSeekerForJob({
      selectedIds: [jobResponsesId],
      status: 'accepted',
    });

  const declineSeeker = (jobResponsesId) => {
    showModal();
    setModalContent({
      title: 'Decline?',
      content: 'Are you sure want to decline this seeker?',
      onClickYes: () => handleDecline(jobResponsesId),
    });
  };

  const acceptSeeker = (jobResponsesId) => {
    showModal();
    setModalContent({
      title: 'Accept?',
      content: 'Are you sure want to accept all selected Seekers',
      onClickYes: () => handleAccept(jobResponsesId),
    });
  };

  const renderItem = (item) => {
    const { employeeId, employeeName } = item;
    return (
      <div>
        <List.Item
          actions={[
            <p>
              <CheckedIcon
                height={20}
                width={20}
                onClick={() => acceptSeeker(item.id)}
              />
            </p>,

            <DeclineButton
              height={20}
              width={20}
              onClick={() => declineSeeker(item.id)}
            />,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar size={30} src={<UserOutlined />} />}
            title={
              <div className='event-applied-title'>
                <div
                  title={`${employeeName}`}
                  className='event-applied-title-fullname'
                >
                  {employeeName}
                </div>
                <InfoCircleOutlined
                  onClick={() =>
                    handleViewInfo({
                      employeeId,
                    })
                  }
                />
              </div>
            }
          />
        </List.Item>
      </div>
    );
  };

  return (
    <div className='event-applied-container'>
      <div className='event-applied-download'>
        <div
          className='event-applied-row-container'
          onClick={() => downloadCSVJobs('applied')}
        >
          <DownloadOutlined />
          <div>Download List</div>
        </div>
      </div>
      <List dataSource={jobSeekers} renderItem={renderItem} />
      {seekerId && (
        <ModalJobSeeker
          seekerId={seekerId}
          visibleModal
          setVisibleModal={setSeekerId}
        />
      )}
      <Modal
        visible={isDisplayModal}
        onClickNo={hiddenModal}
        onClickYes={modalContent.onClickYes}
        isLoading={isLoading}
      >
        <Typography.Title level={3}>{modalContent.title}</Typography.Title>
        <div className='time-picker-modal'>
          <p>{modalContent.content}</p>
        </div>
      </Modal>
    </div>
  );
};

export default AppliedTab;

AppliedTab.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refetchCalendarJobs: PropTypes.func,
  downloadCSVJobs: PropTypes.func,
};

AppliedTab.defaultProps = {
  eventId: null,
};
