/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { Form, message } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  Select,
  Comments,
  Document,
  TotalCard,
  InvoiceProcessed,
} from '@/components';
import { useFetch } from '@/hooks';
import useTimesheetDetailWeeks from '@/hooks/dropdowns/useTimesheetDetailWeeks';
import { DISABLED_STATUS, listYear } from '@/pages/Timesheet/constants';
import { PayrollService, TimesheetService } from '@/services';
import { pushRecord } from '@/services/timesheetService';

import { updateSelectedTimecardIds } from '../../utils/select';
import ListJobTypeAdded from '../ListJobTypeAdded';
import ListTableStatus from '../ListTableStatus';
import { useTenantContext } from '../../../../TenantWrapper';

import './styles.scss';

const TimesheetDetail = ({ setHeader }) => {
  const { id } = useParams();
  const history = useHistory();
  const [payRateForm] = Form.useForm();
  const weekTimesheetDetail = useTimesheetDetailWeeks(id);
  const { currency } = useTenantContext();

  const [year, setYear] = useState();
  const {
    data,
    isFetching,
    isRefetching,
    refetch: timesheetRefetch,
  } = useFetch(['timesheetDetail', id], () => TimesheetService.getOne(id), {
    parentPath: '/timesheets',
    onSuccess: (res) => {
      const timesheetDetails = res?.timesheetDetails ?? {};
      const pickedApproved = _.pick(timesheetDetails, 'approved');
      const pickedUnApproved = _.pick(timesheetDetails, 'unapproved');
      const pickedPushedApproved = _.pick(res?.timesheetCurrPushed, 'approved');
      const pickedunPushedUnApproved = _.pick(
        res?.timesheetCurrPushed,
        'unapproved',
      );
      payRateForm.setFields([
        {
          name: ['jobTypeAdded', 'current'],
          value: _.merge(
            {},
            pickedApproved,
            pickedUnApproved,
            pickedPushedApproved,
            pickedunPushedUnApproved,
          ),
        },
      ]);
      payRateForm.resetFields(['selectedTimecardIds']);
    },
  });

  const { data: dataInvoiceDetail } = useFetch(
    ['InvoiceDetail', id],
    () => PayrollService.getInvoiceDetails(id),
    {},
  );
  const {
    totalCost = 0,
    totalPay = 0,
    totalCharge = 0,
    weekStartDate = '',
    weekEndDate = '',
    payrollStatus = '',
    weekNum: { currentWeekNum = 0 } = {},
    timesheetDetailsPushed: { timesheetId: pushedToTimesheetId } = {},
    employer: { id: employerId = null, name: providerName = '' } = {},
    employerLocation: { id: locationId = '', name: locationName = '' } = {},
  } = _.get(data, 'data', {});

  useEffect(() => {
    setHeader({
      weekNumber: currentWeekNum,
      providerName,
      locationName,
    });
  }, [currentWeekNum, locationName, providerName, setHeader]);

  const { mutate: pushRecordNextWeek, isLoading: isPushingRecordNextWeek } =
    useMutation((timecardIds) => pushRecord({ id, timecardIds }), {
      onSuccess: ({ data: resData }) => {
        const {
          next_week_num: nextWeekNumber,
          destroyed_timesheet: destroyedTimesheet,
        } = resData.data;
        message.success(`Push Record success to Week ${nextWeekNumber}`);
        if (destroyedTimesheet) {
          return history.push('/timesheets');
        }
        return timesheetRefetch();
      },
      onError: (error) => message.error(error.message),
    });

  const payAndCost = totalPay + totalCost;
  const difference = totalCharge - payAndCost;
  const margin = totalCharge ? (difference / totalCharge) * 100 : 0;

  const totalCards = [
    {
      title: 'Total Costs',
      amount: totalCost,
      isStart: true,
      unit: currency,
    },
    {
      title: 'Total Pay',
      amount: Number(totalPay ?? 0).toFixed(2),
      isStart: true,
      unit: currency,
    },
    {
      title: 'Total Charge',
      amount: Number(totalCharge ?? 0)?.toFixed(2),
      isStart: true,
      isRed: true,
      unit: currency,
    },
    {
      title: 'Margin',
      amount: margin.toFixed(2),
      unit: '%',
    },
  ];

  const isShowInvoiceProcessed = !!dataInvoiceDetail?.length;

  const handlePushRecord = () => {
    const timecardIds = payRateForm.getFieldValue('selectedTimecardIds');
    pushRecordNextWeek(timecardIds);
  };

  return (
    <Form.Provider
      onFormChange={(statusChanged, { changedFields }) => {
        if (_.isNil(statusChanged) || DISABLED_STATUS.includes(statusChanged)) {
          return;
        }

        const { name: namePathChanged, value: valueChanged } =
          _.head(changedFields);
        const fieldNameChanged = _.last(namePathChanged);
        const updatedTimecardIds = updateSelectedTimecardIds(
          payRateForm,
          fieldNameChanged,
          namePathChanged,
          statusChanged,
          valueChanged,
        );
        payRateForm.setFields([
          {
            name: 'selectedTimecardIds',
            value: updatedTimecardIds,
          },
        ]);
      }}
    >
      <div className='timesheet-detail'>
        <div className='row-search-year-week'>
          <div className='change-timesheet-week'>
            <div className='title'>Change timesheet year</div>
            <Select
              className='field-select'
              placeholder='Choose year'
              name='timesheet_year'
              options={listYear}
              value={year}
              onChange={(value) => setYear(value)}
            />
          </div>
          <div className='change-timesheet-week'>
            <div className='title'>Change timesheet week</div>
            <Select
              className='field-select'
              placeholder='Choose week'
              name='timesheet_week'
              options={weekTimesheetDetail}
              value={currentWeekNum}
              onChange={(value) =>
                setHeader((prevState) => ({
                  ...prevState,
                  weekNumber: value,
                }))
              }
            />
          </div>
        </div>

        <Form form={payRateForm}>
          <div className='row'>
            <Form.Item name='jobTypeAdded' hidden />
            <Form.Item name='selectedTimecardIds' hidden initialValue={[]} />
            <ListJobTypeAdded
              className='timesheet-table'
              locationId={locationId}
              employerId={employerId}
              payrollStatus={payrollStatus}
              weekNumber={currentWeekNum}
              weekStartDate={weekStartDate}
              weekEndDate={weekEndDate}
              timesheetRefetch={timesheetRefetch}
            />
          </div>
          <Form.Item
            className='push-record-container'
            shouldUpdate={(prevValues, currentValues) =>
              !_.isEqual(
                prevValues?.selectedTimecardIds,
                currentValues?.selectedTimecardIds,
              )
            }
          >
            {({ getFieldValue }) => {
              const allTimecardIds = getFieldValue('selectedTimecardIds');
              return (
                <Button
                  onClick={handlePushRecord}
                  className='yellow-button push-btn'
                  loading={isPushingRecordNextWeek}
                  disabled={_.isEmpty(allTimecardIds)}
                >
                  Push Record to next week
                </Button>
              );
            }}
          </Form.Item>
        </Form>

        <ListTableStatus
          data={data}
          isFetching={isFetching}
          isRefetching={isRefetching}
          payrollStatus={payrollStatus}
          pushedToTimesheetId={pushedToTimesheetId}
        />

        <div className='row'>
          <div className='column'>
            <Form>
              <Comments />
            </Form>

            <Form>
              <Document />
            </Form>
          </div>
          <div className='column'>
            <div className='total-card-row'>
              {totalCards.map((item) => (
                <div key={item.title} className='total-card-column'>
                  <TotalCard
                    title={item.title}
                    amount={item.amount}
                    unit={item.unit}
                    isStart={item.isStart}
                    isRed={item.isRed}
                  />
                </div>
              ))}
            </div>

            {isShowInvoiceProcessed && (
              <InvoiceProcessed
                data={dataInvoiceDetail}
                totalCost={+totalCards[0].amount + +totalCards[1].amount}
              />
            )}
          </div>
        </div>
      </div>
    </Form.Provider>
  );
};

TimesheetDetail.propTypes = {
  setHeader: PropTypes.func,
};

TimesheetDetail.defaultProps = {
  setHeader: () => {},
};

export default TimesheetDetail;
