import { isArray, isEmpty } from 'lodash';
import moment from 'moment';

import { date as dateObj } from '@/utils';

export const calculateRequiredNumber = (
  /** @type {number} */ weekVolume,
  /** @type {number} */ numberOfEmployees,
  /** @type {number} */ kpi,
  /** @type {number} */ shiftLength,
) => {
  if (
    typeof weekVolume !== 'number' ||
    typeof numberOfEmployees !== 'number' ||
    typeof kpi !== 'number' ||
    typeof shiftLength !== 'number'
  ) {
    return {
      weekRequiredPeople: 0,
      weekRequiredHour: 0,
      differencePeople: 0,
      differenceHour: 0,
      numberOfEmployeesHour: 0,
    };
  }
  const weekRequiredPeople = Math.ceil(weekVolume / (kpi * shiftLength));
  const weekRequiredHour = weekRequiredPeople * shiftLength;
  const differencePeople = numberOfEmployees - weekRequiredPeople;
  const differenceHour = differencePeople * shiftLength;
  const numberOfEmployeesHour = numberOfEmployees * shiftLength;

  return {
    weekRequiredPeople,
    weekRequiredHour,
    differencePeople,
    differenceHour,
    numberOfEmployeesHour,
  };
};

export const getRequiredFields = (
  formValuesArray,
  valueTimeString,
  weekRequiredPeople,
) =>
  formValuesArray
    .filter((dt) => {
      const dateChange = moment(dt?.start).format('DD/MM/YYYY');
      return dateChange === valueTimeString;
    })
    .map((dt) => ({
      name: [dt?.id, 'numberOfRequired'],
      value: weekRequiredPeople,
    }));

export const getDifferenceFields = (
  formValuesArray,
  valueTimeString,
  differencePeople,
) =>
  formValuesArray
    .filter((dt) => {
      const dateChange = moment(dt?.start).format('DD/MM/YYYY');
      return dateChange === valueTimeString;
    })
    .map((dt) => ({
      name: [dt?.id, 'differencePeople'],
      value: differencePeople,
    }));

export const getFieldsCard = ({
  form,
  initKpi,
  initShiftLength,
  initWeekVolume,
  valueTimeString,
}) => {
  const weekVolume =
    initWeekVolume ??
    Number(form.getFieldValue([valueTimeString, 'weekVolume']));
  const numberOfEmployees = Number(
    form.getFieldValue([valueTimeString, 'numberOfEmployees']),
  );
  const kpi = initKpi ?? Number(form.getFieldValue('kpi'));
  const shiftLength =
    initShiftLength ?? Number(form.getFieldValue('shiftLength'));
  if (
    isNaN(weekVolume) ||
    isNaN(numberOfEmployees) ||
    isNaN(kpi) ||
    isNaN(shiftLength)
  )
    return [];

  const formValues = form.getFieldsValue(true);
  const formValuesArray = Object.keys(formValues)
    .map((key) => formValues[parseInt(key, 10)])
    .filter((key) => !!key);
  const {
    weekRequiredPeople,
    weekRequiredHour,
    differencePeople,
    differenceHour,
    numberOfEmployeesHour,
  } = calculateRequiredNumber(weekVolume, numberOfEmployees, kpi, shiftLength);

  const requiredFields = getRequiredFields(
    formValuesArray,
    valueTimeString,
    weekRequiredPeople,
  );
  const differenceFields = getDifferenceFields(
    formValuesArray,
    valueTimeString,
    differencePeople,
  );

  return [
    ...requiredFields,
    ...differenceFields,
    {
      name: [valueTimeString, 'weekVolume'],
      value: weekVolume,
    },

    {
      name: [valueTimeString, 'kpi'],
      value: kpi,
    },
    {
      name: [valueTimeString, 'shiftLength'],
      value: shiftLength,
    },
    {
      name: [valueTimeString, 'weekRequiredPeople'],
      value: weekRequiredPeople,
    },
    {
      name: [valueTimeString, 'weekRequiredHour'],
      value: weekRequiredHour,
    },
    {
      name: [valueTimeString, 'differencePeople'],
      value: differencePeople,
    },
    {
      name: [valueTimeString, 'differenceHour'],
      value: differenceHour,
    },
    {
      name: [valueTimeString, 'numberOfEmployeesHour'],
      value: numberOfEmployeesHour,
    },
  ];
};

export const setFieldsCard = ({
  form,
  initKpi,
  initShiftLength,
  initWeekVolume,
  valueTimeString,
}) => {
  const fields = getFieldsCard({
    form,
    initKpi,
    initShiftLength,
    initWeekVolume,
    valueTimeString,
  });

  if (isArray(fields) && !isEmpty(fields)) form.setFields(fields);
};

export const setFieldsFromKpiAndShift = ({
  date,
  form,
  initKpi,
  initShiftLength,
  initWeekVolume,
}) => {
  const fields = [];
  const currentWeek = dateObj.getCurrentWeekDays(date);
  currentWeek.forEach((dateKey) => {
    const field = getFieldsCard({
      form,
      initKpi,
      initShiftLength,
      initWeekVolume,
      valueTimeString: dateKey,
    });
    fields.push(...field);
  });

  if (!isEmpty(fields)) form.setFields(fields);
};
