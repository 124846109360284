import React from 'react';

import { Menu, Dropdown, Tooltip } from 'antd';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useAuthStore } from '@/store/authStore';

import Image from '../Image';
import './styles.scss';
import { useTenantContext } from '../../TenantWrapper';

const Header = () => {
  const { pathname } = useLocation();
  const userName = useAuthStore((state) => state.userName);
  const logout = useAuthStore((state) => state.logout);
  const history = useHistory();
  const { iconLogo } = useTenantContext();

  const redirectToLoginPage = () => {
    history.push('/login');
  };

  const handleLogout = () => {
    const isLogout = logout();

    if (isLogout) {
      redirectToLoginPage();
    }
  };

  const items = [
    {
      label: (
        <NavLink className='menu-label' to='/pay-templates'>
          Pay Templates
        </NavLink>
      ),
      key: '/pay-templates',
    },
    // {
    //   label: (
    //     <NavLink className='menu-label' to='/'>
    //       Dashboard
    //     </NavLink>
    //   ),
    //   key: '/',
    // },
    {
      label: <span className='menu-label'>Recruitment</span>,
      key: 'recruitment',
      children: [
        {
          label: (
            <NavLink className='menu-sublabel' to='/'>
              Applicant
            </NavLink>
          ),
          key: '/',
        },
        {
          label: (
            <NavLink className='menu-sublabel' to='/recruitment/candidate'>
              Candidate
            </NavLink>
          ),
          key: '/recruitment/candidate',
        },
      ],
    },
    {
      label: (
        <NavLink className='menu-label' to='/seeker'>
          Seeker
        </NavLink>
      ),
      key: '/seeker',
    },
    {
      label: <span className='menu-label'>Provider</span>,
      key: 'provider',
      children: [
        {
          label: (
            <NavLink className='menu-sublabel' to='/view-all-leads'>
              Leads
            </NavLink>
          ),
          key: '/view-all-leads',
        },
        {
          label: (
            <NavLink className='menu-sublabel' to='/view-all-providers'>
              Providers
            </NavLink>
          ),
          key: '/view-all-providers',
        },
      ],
    },
    {
      label: (
        <NavLink className='menu-label' to='/jobs'>
          Jobs
        </NavLink>
      ),
      key: '/jobs',
    },
    // {
    //   label: (
    //     <NavLink className='menu-label' to='/rota'>
    //       Rota
    //     </NavLink>
    //   ),
    //   key: '/rota',
    // },
    {
      label: <span className='menu-label'>Timesheets</span>,
      key: 'timesheets',
      children: [
        {
          label: (
            <NavLink className='menu-sublabel' to='/timesheets'>
              Timesheets
            </NavLink>
          ),
          key: '/timesheets',
        },
        {
          label: (
            <NavLink className='menu-sublabel' to='/costs'>
              Costs
            </NavLink>
          ),
          key: '/costs',
        },
        {
          label: (
            <NavLink className='menu-sublabel' to='/suppliers'>
              Suppliers
            </NavLink>
          ),
          key: '/suppliers',
        },
        {
          label: (
            <NavLink className='menu-sublabel' to='/invoicing'>
              Invoicing
            </NavLink>
          ),
          key: '/invoicing',
        },
      ],
    },
    {
      label: (
        <NavLink className='menu-label' to='/payroll'>
          Payroll
        </NavLink>
      ),
      key: '/payroll',
    },
    // {
    //   label: (
    //     <NavLink className='menu-label' to='/reports'>
    //       Reports
    //     </NavLink>
    //   ),
    //   key: '/reports',
    // },
    {
      label: (
        <NavLink className='menu-label' to='/users'>
          Users
        </NavLink>
      ),
      key: '/users',
    },
  ];

  const menuItems = (
    <Menu
      className='avatar-dropdown'
      items={[
        {
          key: 'View Profile',
          label: <Menu.Item>View Profile</Menu.Item>,
        },
        {
          key: 'Log out',
          label: <Menu.Item onClick={handleLogout}>Log out</Menu.Item>,
        },
      ]}
    />
  );

  return (
    <div className='wrapper-menu'>
      <div className='menu-gig'>
        <div>
          <img className='icon-logo' src={iconLogo.url} alt='icon-logo' />
        </div>
        <div className='menu-list'>
          <div className='menu-item'>
            <Menu
              selectedKeys={[pathname]}
              mode='horizontal'
              items={items}
              triggerSubMenuAction='click'
            />
          </div>
          <div className='menu-profile'>
            <Tooltip title={userName}>
              <span className='menu-label'>Hello, {userName}</span>
            </Tooltip>
            <Dropdown overlay={menuItems} placement='bottom'>
              <a href='#profile'>
                <Image
                  containerClassName='profile-pic-container'
                  className='avatar'
                />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
