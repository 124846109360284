import create from 'zustand';

const useBuildRotaStore = create((set) => ({
  volume: 0,
  required: 0,
  kpi: 0,
  shiftLength: 0,
  agency: 0,
  setVolume: (value) => set(() => ({ volume: value })),
  setRequired: (value) => set(() => ({ required: value })),
  setKpi: (value) => set(() => ({ kpi: value })),
  setShiftLength: (value) => set(() => ({ shiftLength: value })),
  setAgency: (value) => set(() => ({ agency: value })),
}));

export default useBuildRotaStore;
