import React from 'react';

import { Form } from 'antd';

import { FormItem, InputNumber, Radio } from '@/components';

const WorkingTimeDirective = () => {
  const formInstance = Form.useFormInstance();

  const workTime = Form.useWatch('worktime', formInstance);

  return (
    <div className='working-time-directive'>
      <div className='left'>
        <FormItem
          name='worktime'
          label='Working Time Directive'
          required
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Please select working time directive',
            },
          ]}
        >
          <Radio
            options={[
              { label: 'Opted Out', value: 0 },
              { label: 'Opted In', value: 1 },
            ]}
            // value={workTime}
          />
        </FormItem>

        {workTime === 1 && (
          <FormItem
            name='maxWeeklyHours'
            className='maximum-weekly-hours'
            label='Maximum weekly hours'
            rules={[
              {
                required: true,
                message: 'Please input maximum weekly hours',
              },
            ]}
          >
            <InputNumber controls={false} placeholder='Maximum weekly hours' />
          </FormItem>
        )}
      </div>
    </div>
  );
};

export default WorkingTimeDirective;
