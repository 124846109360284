import request from '@/request';

const updateTimesheetDetailsAttributes = async ({
  timesheetId,
  timesheetDetailsAttributes,
  timecardAttributes,
}) => {
  const payload = {
    payroll: {
      timesheetDetailsAttributes,
      timecardAttributes,
    },
  };
  const { data } = await request.patch(
    `payments/payrolls/${timesheetId}`,
    payload,
  );

  return data?.data ?? [];
};

export default updateTimesheetDetailsAttributes;
