import React from 'react';

import { Tabs } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import AppliedTab from './AppliedTab';
import ConfirmedTab from './ConfirmedTab';
import DeclinedTab from './DeclinedTab';

import './styles.scss';

/**
 * Renders the AssignEvent component.
 * @param {Object} props
 * @param {boolean} props.isExpandAssignEvent
 * @param {string} props.eventId
 * @param {string} props.date
 * @param {function} props.refetchCalendarJobs
 * @param {function} props.downloadCSVJobs
 * @return {JSX.Element}
 */
const AssignEvent = ({
  isExpandAssignEvent,
  eventId,
  date,
  refetchCalendarJobs,
  downloadCSVJobs,
}) => (
  <div
    className={classNames('assign-event-container', {
      expanded: isExpandAssignEvent,
    })}
  >
    <Tabs>
      <Tabs.TabPane key='applied' tab='Applied'>
        <AppliedTab
          eventId={eventId}
          refetchCalendarJobs={refetchCalendarJobs}
          downloadCSVJobs={downloadCSVJobs}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key='confirmed' tab='Confirmed'>
        <ConfirmedTab
          eventId={eventId}
          date={date}
          refetchCalendarJobs={refetchCalendarJobs}
          downloadCSVJobs={downloadCSVJobs}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key='declined' tab='Declined'>
        <DeclinedTab eventId={eventId} downloadCSVJobs={downloadCSVJobs} />
      </Tabs.TabPane>
    </Tabs>
  </div>
);

export default AssignEvent;

AssignEvent.propTypes = {
  date: PropTypes.string,
  isExpandAssignEvent: PropTypes.bool,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  refetchCalendarJobs: PropTypes.func,
  downloadCSVJobs: PropTypes.func,
};

AssignEvent.defaultProps = {
  date: '',
  isExpandAssignEvent: false,
};
