import { useQuery } from 'react-query';

import { fetchTimesheetDetailWeeks } from '@/services/dropdownServices/getTimesheetDetailWeek';

const useTimesheetDetailWeeks = (/** @type {string|number} */ id) => {
  const { data } = useQuery(
    ['timesheetDetailWeeks'],
    () => fetchTimesheetDetailWeeks(id),
    {
      refetchOnWindowFocus: false,
    },
  );
  return data;
};

export default useTimesheetDetailWeeks;
