import { get, head } from 'lodash';
/**
 * @typedef {Object} Tparams
 * @property {import('antd').FormInstance} form
 * @property {string[]} namePath
 */

/**
 *
 * @param {Tparams} param
 */
const checkValue = ({ form, namePath }) => ({
  validator() {
    const hourCellFormData = form.getFieldValue(namePath);

    const value = get(hourCellFormData, 'schedule.value', 0);
    if (value < 1) {
      form.setFields([
        {
          name: [...namePath, 'schedule', 'value'],
          errors: ['Value must larger than an hour'],
        },
      ]);
      return Promise.reject();
    }
    form.setFields([
      {
        name: [...namePath, 'schedule', 'value'],
        errors: [],
      },
    ]);
    return Promise.resolve();
  },
});

const scrollToFirstError = ({ form, error }) => {
  const errorName = get(head(get(error, 'errorFields')), 'name');
  form.scrollToField(errorName, {
    skipOverflowHiddenElements: true,
    block: 'center',
    behavior: 'smooth',
  });
};

export { checkValue, scrollToFirstError };
