/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import {
  DownloadOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, List } from 'antd';
import PropTypes from 'prop-types';

import ModalJobSeeker from '@/components/JobSeeker/ModalJobSeeker';
import { useFetch } from '@/hooks';
import { getJobSeekersByStatus } from '@/services/jobServices/getSeekers';

import './styles.scss';

/**
 * @typedef {Object} AssignedScheduleAttributesParams
 * @property {number | string} id
 */

/**
 * Represents an array of arrays containing job response data.
 * Each inner array contains objects with specific properties.
 * @typedef {Array<Array<{
 *   id: number,
 *   fullname: string,
 *   status: string,
 *   jobResponsesId: number,
 *   employeeId: number,
 *   startTime: number,
 *   endTime: number
 * }>>} JobSeekersProps
 */

/**
 * Represents the type of `seekerId`.
 * @typedef {string|number} SeekerIdType
 */

/**
 *
 * @param {Object} param
 * @param {string|number} param.eventId
 * @param {Function} param.downloadCSVJobs
 * @returns
 */
const DeclinedTab = ({ eventId, downloadCSVJobs }) => {
  const { data: jobSeekers } = useFetch(['eventDeclinedSeekers', eventId], () =>
    getJobSeekersByStatus(eventId, 'declined'),
  );

  const [seekerId, setSeekerId] = useState(null);

  const handleViewInfo = ({ employeeId }) => {
    setSeekerId(employeeId);
  };

  const renderItem = (item) => {
    const { employeeId, employeeName } = item;
    return (
      <div>
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar size={30} src={<UserOutlined />} />}
            title={
              <div className='event-declined-title'>
                <div
                  title={`${employeeName}`}
                  className='event-declined-title-fullname'
                >
                  {employeeName}
                </div>
                <InfoCircleOutlined
                  onClick={() =>
                    handleViewInfo({
                      employeeId,
                    })
                  }
                />
              </div>
            }
          />
        </List.Item>
      </div>
    );
  };

  return (
    <div className='event-declined-container'>
      <div className='event-declined-download'>
        <div
          className='event-declined-row-container'
          onClick={() => downloadCSVJobs('declined')}
        >
          <DownloadOutlined />
          <div>Download List</div>
        </div>
      </div>
      <List dataSource={jobSeekers} renderItem={renderItem} />
      {seekerId && (
        <ModalJobSeeker
          seekerId={seekerId}
          visibleModal
          setVisibleModal={setSeekerId}
        />
      )}
    </div>
  );
};

export default DeclinedTab;

DeclinedTab.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  downloadCSVJobs: PropTypes.func,
};

DeclinedTab.defaultProps = {
  eventId: null,
};
