import request from '@/request';

const updateShiftTime = async ({
  /** @type {string|number} */ timesheetId,
  /** @type {object} */ timesheet,
}) => {
  const res = await request.patch(
    `trackers/timekeeping/timesheets/${timesheetId}`,
    {
      ...timesheet,
    },
  );
  return res.data;
};

export default updateShiftTime;
