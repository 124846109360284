import React from 'react';

import { find } from 'lodash';

import { FormItem } from '@/components';
import { PAYROLL_ENTRY_CODES_PT } from '@/constants';
import Formatter from '@/utils/Formatter';

import { renderCellValue, renderDay } from './utils';

const genDefaultColumns = ({ panelId }) => [
  {
    children: [
      {
        title: renderCellValue({ label: 'Name' }),
        dataIndex: 'seekerName',
        width: '9%',
        render: (name) => (
          <>
            <FormItem name={[`${panelId}`]} hidden />
            {renderCellValue({ label: name })}
          </>
        ),
      },
    ],
  },
  {
    title: renderCellValue({ label: 'Breakdown of total hours by day' }),
    children: [
      ...renderDay,
      {
        title: renderCellValue({ label: 'Template' }),
        width: '9%',
        dataIndex: 'payrollTemplateName',
        render: (payrollTemplateName) =>
          renderCellValue({
            label: payrollTemplateName,
          }),
      },
      {
        title: renderCellValue({ label: 'Pay Template' }),
        width: '8.5%',
        render: (_, record) =>
          renderCellValue({
            label:
              (record?.templateName ?? '') +
              (record?.isOverTime ? ' (OT)' : ''),
            isOverTime: record?.isOverTime,
          }),
      },
    ],
  },
  {
    children: [
      {
        title: renderCellValue({ label: 'Pay' }),
        dataIndex: 'payRate',
        width: '3.5%',
        render: (payRate, record) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: payRate }),
            isOverTime: record?.isOverTime,
            prefix: '£',
          }),
      },
      {
        title: renderCellValue({ label: 'Charge' }),
        dataIndex: 'chargeRate',
        width: '3.5%',
        render: (chargeRate, record) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: chargeRate }),
            isOverTime: record?.isOverTime,
            prefix: '£',
          }),
      },
    ],
  },
  {
    title: renderCellValue({ label: 'Breaks' }),
    dataIndex: 'breaks',
    children: [
      {
        title: renderCellValue({ label: 'Break' }),
        dataIndex: 'breakDuration',
        width: '3.5%',
        render: (breakDuration, record) =>
          renderCellValue({
            label: breakDuration,
            isOverTime: record?.isOverTime,
          }),
      },
      {
        title: renderCellValue({ label: 'Time' }),
        dataIndex: 'time',
        width: '2.5%',
        render: () =>
          renderCellValue({
            label: 'mins',
          }),
      },
      {
        title: renderCellValue({ label: 'Pay' }),
        width: '3%',
        dataIndex: 'payRateWithBreak',
        render: (payRateWithBreak, record) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: payRateWithBreak }),
            prefix: '£',
            isOverTime: record?.isOverTime,
          }),
      },
      {
        title: renderCellValue({ label: 'Charge' }),
        width: '3%',
        dataIndex: 'chargeRateWithBreak',
        render: (chargeRateWithBreak, record) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: chargeRateWithBreak }),
            prefix: '£',
            isOverTime: record?.isOverTime,
          }),
      },
    ],
  },
  {
    title: renderCellValue({
      label: 'Addition or Deduction by Pay Template',
    }),
    children: [
      {
        width: '4%',
        dataIndex: 'bonusType',
        render: (bonusType) => {
          const { label } = find(PAYROLL_ENTRY_CODES_PT, ['value', bonusType]);
          return renderCellValue({
            label,
          });
        },
      },
      {
        width: '3.5%',
        dataIndex: 'bonusValue',
        render: (bonusValue) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: bonusValue }),
          }),
      },
    ],
  },
  {
    title: renderCellValue({ label: 'Payroll Totals' }),
    dataIndex: 'PayrollTotal',
    children: [
      {
        title: renderCellValue({ label: 'Hours' }),
        width: '3.5%',
        dataIndex: 'hourWithBonus',
        render: (hourWithBonus) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: hourWithBonus }),
          }),
      },
      {
        title: renderCellValue({ label: 'Pay' }),
        width: '3.5%',
        dataIndex: 'payRateWithBonus',
        render: (payRateWithBonus) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: payRateWithBonus }),
            prefix: '£',
          }),
      },
      {
        title: renderCellValue({ label: 'Charge' }),
        width: '3.5%',
        dataIndex: 'chargeRateWithBreak',
        render: (chargeRateWithBreak) =>
          renderCellValue({
            label: Formatter.fixedNumber({ value: chargeRateWithBreak }),
            prefix: '£',
          }),
      },
    ],
  },
];

export default genDefaultColumns;
