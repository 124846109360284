import { camelizeKeys } from 'humps';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const getChargeRates = async (providerId) => {
  const res = await request.get(
    `providers/employers/${providerId}/charge_rates`,
  );

  const { data } = normalizeShowResponse(camelizeKeys(res));
  return data;
};

export default getChargeRates;
