import React from 'react';

import { Tabs } from '@/components';
import { useQueryString } from '@/hooks';

import JobSeeker from './jobSeekers';

import './styles.scss';

const SeekerJos = () => {
  const tabPanes = [
    {
      tab: 'Past',
      key: 'seeker-past',
      child: <JobSeeker statuses={['past']} />,
    },
    {
      tab: 'Confirmed',
      key: 'seeker-confirmed',
      child: <JobSeeker statuses={['confirmed']} />,
    },
    {
      tab: 'Applied',
      key: 'seeker-timesheets',
      child: <JobSeeker statuses={['applied']} />,
    },
  ];

  const [queryString, setQueryString] = useQueryString();

  const onChangeTabKey = (key) => {
    setQueryString({ tabStatusJob: key });
  };

  return (
    <Tabs
      tabPanes={tabPanes}
      activeKey={queryString?.tabStatusJob ?? 'seeker-past'}
      onChange={onChangeTabKey}
    />
  );
};

export default SeekerJos;
