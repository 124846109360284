import React, { useState } from 'react';

import { Divider, Form, message } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button, Modal, GeneralTemplate } from '@/components';
import { LeadService } from '@/services';

import LeadForm from '../LeadForm';
import './styles.scss';

const CreateLead = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();

  const onFormSubmitted = (formData) =>
    formData.status === undefined || formData.status === null
      ? undefined
      : setVisibleModal(true);

  const { mutate: mutateCreateLead } = useMutation(LeadService.create, {
    onSuccess: () => {
      setVisibleModal(false);
      setVisibleModalSuccess(true);
      message.success('Lead has been created successfully');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const onConfirmSave = () => mutateCreateLead(form.getFieldsValue());

  const handleClickToRedirect = () => history.push('/view-all-leads');

  return (
    <GeneralTemplate
      mainItem='Provider'
      buttonContent='Create Lead'
      headerText='Create New Lead'
      data={[{ item: 'Leads', to: '/view-all-leads' }]}
      buttonLink='/create-new-lead'
      subTemplateClassName='provider-container'
      className='lead-profile-template'
    >
      <div className='create-lead-container'>
        <Form
          form={form}
          onFinish={onFormSubmitted}
          initialValues={{
            registrationDate: moment(),
          }}
        >
          <div className='create-lead-info-input-container'>
            <LeadForm />

            <Divider />
            <div className='create-lead-buttons'>
              <Button
                onClick={handleClickToRedirect}
                className='black-button action-button'
              >
                Cancel
              </Button>
              <Button className='yellow-button action-button' htmlType='submit'>
                Save
              </Button>
            </div>
          </div>
        </Form>

        <Modal
          onClickYes={onConfirmSave}
          onClickNo={() => setVisibleModal(false)}
          visible={visibleModal}
          title='Create New Lead?'
        >
          Are you sure you want to create new Lead?
        </Modal>
        <Modal
          onClickYes={() => {
            setVisibleModalSuccess(false);
            history.push('/view-all-leads');
          }}
          visible={visibleModalSuccess}
          title='Success'
          isSuccess
        >
          Create Lead successfully
        </Modal>
      </div>
    </GeneralTemplate>
  );
};
export default CreateLead;
