import request from '@/request';

const endpoint = 'payments/employee_holidays';

const declinePayrollHolidayStatus = async (holidayId, numberDaysProcess) => {
  const { data } = await request.patch(`${endpoint}/${holidayId}`, {
    employeeHoliday: {
      status: 'declined',
      numberDaysProcess,
    },
  });

  return data;
};

export default declinePayrollHolidayStatus;
