import React from 'react';

import { Card, Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { debounce, isNaN, parseInt } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { FormItem, Input } from '@/components';

import './styles.scss';
import { setFieldsCard } from './helper';

const CustomDateCellWrapper = ({
  value,
  children,
  refetchGetBuildRota,
  forceUpdate,
}) => {
  const form = Form.useFormInstance();
  const valueTimeString = moment(value).format('DD/MM/YYYY');
  const differencePeople = Form.useWatch(
    [valueTimeString, 'differencePeople'],
    form,
  );

  const onChangeVolume = (e) => {
    const weekVolume = isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    setFieldsCard({ form, initWeekVolume: weekVolume, valueTimeString });
    forceUpdate();
  };
  return (
    <div className='weekly-view'>
      {/* Your custom content goes here */}
      <Card className='header-card'>
        <Card className='card'>
          <h4 className='input-headers'>{moment(value).format('dddd')}</h4>
          <h4 className='input-headers'>
            {moment(value).format('DD/MM/YYYY')}
          </h4>
        </Card>
        <FormItem name='forceRender' noStyle />
        <FormItem name={[valueTimeString, 'countRota']} noStyle />
        <FormItem name={[valueTimeString, 'weekVolume']} className='card'>
          <Input
            className='input-field'
            onChange={debounce((e) => onChangeVolume(e), 1000)}
            key={[valueTimeString, 'weekVolume']}
          />
        </FormItem>
        <Row>
          <Col span={12}>
            <Card className='card-end'>People</Card>
            <FormItem
              className='card-end'
              name={[valueTimeString, 'numberOfEmployees']}
              initialValue={0}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              className='card-end'
              name={[valueTimeString, 'weekRequiredPeople']}
            >
              <Input />
            </FormItem>
            <FormItem
              className='card-end'
              name={[valueTimeString, 'agencyPeople']}
            >
              <Input />
            </FormItem>
            <FormItem
              className={classNames('card-end difference', {
                yellow: differencePeople > 0,
                red: differencePeople < 0,
                green: differencePeople === 0,
              })}
              name={[valueTimeString, 'differencePeople']}
            >
              <Input disabled />
            </FormItem>
          </Col>

          <Col span={12}>
            <Card className='card-start'>Hours</Card>
            <FormItem
              className='card-start'
              name={[valueTimeString, 'numberOfEmployeesHour']}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              className='card-start'
              name={[valueTimeString, 'weekRequiredHour']}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              className='card-start'
              name={[valueTimeString, 'agencyHour']}
            >
              <Input />
            </FormItem>
            <FormItem
              className={classNames('card-start difference', {
                yellow: differencePeople > 0,
                red: differencePeople < 0,
                green: differencePeople === 0,
              })}
              name={[valueTimeString, 'differenceHour']}
            >
              <Input disabled />
            </FormItem>
          </Col>
        </Row>
      </Card>

      {/* Render the default date cell content */}
      {children}
    </div>
  );
};

CustomDateCellWrapper.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  refetchGetBuildRota: PropTypes.func,
  forceUpdate: PropTypes.any,
};

export default CustomDateCellWrapper;
