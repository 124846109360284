import React from 'react';

import { Form } from 'antd';

import { FormItem } from '@/components';
import Formatter from '@/utils/Formatter';

import { calTotal } from '../../utils/calculation';

import './styles.scss';
import { useTenantContext } from '../../../../../TenantWrapper';

const BodyTotal = () => {
  const formIntance = Form.useFormInstance();
  const { invoiceInfo = {} } = useTenantContext();
  const {
    contact = {},
    registeredCompany = {},
    payment = {},
    bank = {},
  } = invoiceInfo;
  const { phoneNumber } = contact;
  const { vatRegNumber, name, regNumber } = registeredCompany;
  const { paymentTerm, sortCode, accountHolderName } = payment;
  const { name: bankName, accountNumber } = bank;

  const hoursSections = Form.useWatch('labour', formIntance) ?? [];
  const costSections = Form.useWatch('cost', formIntance) ?? [];

  const { totalNet, totalVat, totalInvoice } = calTotal([
    ...hoursSections,
    ...costSections,
  ]);

  return (
    <div className='pay-template-invoice-total'>
      <div className='pay-template-invoice-total-left'>
        <div className='basic-info'>
          <p>Invoice Enquiries Tel: {phoneNumber}</p>
          <p>VAT REG NUMBER: {vatRegNumber}</p>
          <p>{name}</p>
          <p>Company Registration No: {regNumber}</p>
        </div>
        <div className='total-info'>
          <FormItem name='subTotal' hidden />
          <FormItem name='total' hidden />
          <FormItem name='vat' hidden />
          <p>Total NET £{Formatter.fixedNumber({ value: totalNet })}</p>
          <p>Total VAT £{Formatter.fixedNumber({ value: totalVat })}</p>
          <p>Invoice Total £{Formatter.fixedNumber({ value: totalInvoice })}</p>
        </div>
      </div>
      <div className='pay-template-invoice-total-right'>
        <p>Holder Test - Cannot be edited here:</p>
        <p>
          Our Standard Terms are Payment within {paymentTerm} Days by BACS. Our
          Bank details are as follows:
        </p>
        <p>
          A/C Name: {accountHolderName}. Bank: {bankName}. Bank Account No.:{' '}
          {accountNumber} Sort Code : {sortCode}
        </p>
        <p>
          Any Changes To Bank Details Will Be Confirmed In Writting Via Post And
          E-mail And Need To Be Verified Verbally By Calling Accounts On{' '}
          {phoneNumber} And Writting To The Above Address. Subject To Our
          Standard Terms And Conditions
        </p>
      </div>
    </div>
  );
};

export default BodyTotal;
