import { useQuery } from 'react-query';

import { getVacancyApplicantEmploymentPosition } from '@/services/dropdownServices/getVacancyApplicantEmploymentPosition';

const useVacancyApplicantEmploymentPosition = () => {
  const { data } = useQuery(
    ['getVacancyApplicantEmploymentPosition'],
    getVacancyApplicantEmploymentPosition,
    {
      refetchOnWindowFocus: false,
    },
  );

  return data ?? [];
};

export default useVacancyApplicantEmploymentPosition;
