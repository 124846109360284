import React from 'react';

import { Form, Table } from 'antd';
import moment from 'moment';

import { Button, FormItem, Select } from '@/components';
import { LIST_YEAR } from '@/constants';
import { useFetch } from '@/hooks';
import { ReportService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import './style.scss';

const YearlyReport = () => {
  const [form] = Form.useForm();

  const {
    data = [],
    isFetching,
    refetch,
  } = useFetch(['weekly-report'], () => {
    const { year } = form.getFieldsValue();
    return ReportService.yearly.getYearlyReport({ filter: { year } });
  });

  const dataLength = data?.length;

  const columns = [
    {
      title: 'Week Number',
      dataIndex: 'invoice_week',
    },
    {
      title: 'Total Number of invoices',
      dataIndex: 'number_of_invoices',
    },
    {
      title: 'NET Total',
      dataIndex: 'net_total',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          Formatter.currencyGBP(dt)
        ),
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          Formatter.currencyGBP(dt)
        ),
    },
    {
      title: <div className='green'>Total Invoiced</div>,
      dataIndex: 'total_invoiced',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background green'>
            {Formatter.currencyGBP(dt)}
          </div>
        ) : (
          <div className='green'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Worker Pay</div>,
      dataIndex: 'worker_pay',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Holiday Pay</div>,
      dataIndex: 'holiday_pay',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Costs</div>,
      dataIndex: 'total_cost',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Cost of NI</div>,
      dataIndex: 'ni_cost',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: 'Margin %',
      dataIndex: 'margin',
      render: (dt, _, index) => {
        const roundData = dt?.toFixed(2);
        return index === dataLength - 1 ? (
          <div className='gray-background'>{`${roundData}%`}</div>
        ) : (
          `${roundData}%`
        );
      },
    },
    {
      title: 'Profit/Loss',
      dataIndex: 'profit_loss',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          Formatter.currencyGBP(dt)
        ),
    },
  ];
  const tableColumns = generateHeaderTable(columns);

  return (
    <div className='year-report-container'>
      <Form form={form} onFinish={refetch} className='header-container'>
        <FormItem
          name='year'
          className='form-item-style'
          label='Choose Year'
          initialValue={moment().year()}
        >
          <Select options={LIST_YEAR} placeholder='Choose Year' />
        </FormItem>
        <Button htmlType='submit' className='yellow-button view-button'>
          View
        </Button>
      </Form>
      <div className='body-container'>
        <h2>Profit and Loss Report</h2>
        <div className='report-table-container'>
          <Table
            bordered
            pagination={false}
            columns={tableColumns}
            dataSource={data}
            loading={isFetching}
          />
        </div>
      </div>
    </div>
  );
};
export default YearlyReport;
