import React from 'react';

import BasicInformation from './BasicInformation';
import CriminalConvictions from './CriminalConvictions';
import Languages from './Languages';
import NextOfKin from './NextOfKin';

import './styles.scss';

const ContactInformation = () => (
  <div className='seeker-contact-information'>
    <div className='left'>
      <BasicInformation />
    </div>
    <div className='right'>
      <NextOfKin />
      <Languages />
      <CriminalConvictions />
    </div>
  </div>
);

export default ContactInformation;
