import React, { cloneElement, useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Form, message, Row, Spin, Steps } from 'antd';
import _ from 'lodash';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button } from '@/components';
import { REFERENCE_BY_FRIEND } from '@/constants';
import { useFetch } from '@/hooks';
import { GuestService } from '@/services';

import AboutYou from './components/AboutYou';
import AboutYou2 from './components/AboutYou2';
import AdditionalInformation from './components/AdditionalInformation';
import ApplicationSubmitted from './components/ApplicationSubmitted';
import BankDetails from './components/BankDetails';
import Declarations from './components/Declarations';
import Declarations2 from './components/Declarations2';
import Declarations3 from './components/Declarations3';
import Declarations4 from './components/Declarations4';
import Experience from './components/Experience';
import HeaderStep from './components/HeaderStep';
import LanguageRecruitment from './components/LanguageRecruitment';
import MedicalInformation from './components/MedicalInformation';
import NextOfKin from './components/NextOfKin';
import RecruitmentDetail from './components/RecruitmentDetail';
import RecruitmentWelcome from './components/RecruitmentWelcome';
import Reference from './components/Reference';
import Registration from './components/Registration';
import Role from './components/Role';
import './index.scss';
import { useTenantContext } from '../../TenantWrapper';

const steps = [
  {
    content: <Registration />,
  },
  {
    content: <RecruitmentWelcome />, // page 1.
  },
  {
    content: <RecruitmentDetail />, // page 2.
  },
  {
    content: <AboutYou />, // page 3
  },
  {
    content: <AboutYou2 />, // page 4
  },
  {
    content: <Role />,
  },
  {
    content: <Experience />, // page 5
  },
  {
    content: <LanguageRecruitment />, // page 7
  },
  {
    content: <AdditionalInformation />, // page 8
  },
  {
    content: <MedicalInformation />, // page 9
  },
  {
    content: <Reference />, // page 10
  },
  {
    content: <BankDetails />, // page 11
  },
  {
    content: <NextOfKin />, // page 12
  },
  {
    content: <Declarations />, // page 13
  },
  {
    content: <Declarations2 />, // page 14
  },
  {
    content: <Declarations3 />, // page 15
  },
  {
    content: <Declarations4 />, // page 16
  },
  {
    content: <ApplicationSubmitted />, // page 17
  },
];

const lastStep = steps.length - 1;
const submitStep = steps.length - 2;

const RecruitmentForm = () => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState();
  const { id } = useParams();
  const { mainLogo } = useTenantContext();

  const { mutate, isLoading: isLoadingSubmit } = useMutation(
    () =>
      GuestService.updateCandidate({
        id,
        values,
      }),
    {
      onError: (error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        setCurrent(lastStep);
      },
    },
  );

  const nextStep = () => setCurrent(current + 1);

  const handleChangeYear = (dateTime) => {
    setValues({ ...values, birth: dateTime });
  };

  const onValuesChange = (changedValues, allValues, notMerge = false) => {
    const isVerifiedStoreAndUse = allValues.isVerifiedStoreAndUse?.[0];
    const isVerifiedReadTerms = allValues.isVerifiedReadTerms?.[0];

    const isHearFieldChanged = !_.isNil(changedValues?.hear);
    const isNotReferredByFriend =
      changedValues?.hear !== REFERENCE_BY_FRIEND.value;
    const isResetFriendName = isHearFieldChanged && isNotReferredByFriend;

    if (notMerge) {
      setValues({
        isVerifiedStoreAndUse,
        isVerifiedReadTerms,
        ...values,
        ...allValues,
        ...(isResetFriendName && { friendName: null }),
      });
    } else {
      const mergeValues = _.merge(values, changedValues);

      setValues({
        isVerifiedStoreAndUse,
        isVerifiedReadTerms,
        ...mergeValues,
        ...(isResetFriendName && { friendName: null }),
      });
    }
  };

  const { isLoading } = useFetch(
    ['guest-applicant'],
    () => GuestService.getApplicant(id),
    {
      onSuccess: (data) => {
        const { isVerifiedStoreAndUse, isVerifiedReadTerms } = data;

        setValues({
          ...data,
          nobank: 1,
          hasMedical: 0,
          isVerifiedStoreAndUse: [isVerifiedStoreAndUse],
          isVerifiedReadTerms: [isVerifiedReadTerms],
          worktime: 0,
          omniEmployeeAttributes: {
            ...(data?.omniEmployeeAttributes ?? {}),
            employeePaymentMethod: 'bacs_bank',
          },
        });

        return {};
      },
      enabled: !!id,
    },
  );

  return (
    <Steps current={current} className='recruitment-steps'>
      <div className='recruitment-container'>
        <img src={mainLogo.url} className='gig-logo' alt='' />
        <div className='body-container'>
          {isLoading ? (
            <Row className='spin-container' align='center'>
              <Spin size='large' />
            </Row>
          ) : (
            <div className='recruitment-form'>
              {current === 0 &&
                cloneElement(steps[current].content, {
                  initialValues: values,
                  nextStep,
                  form,
                  onValuesChange,
                })}

              {current > 0 && (
                <div className='form-container'>
                  {current > 1 && current !== lastStep && (
                    <div className='back-container'>
                      <Button
                        className='arrow-container yellow-button'
                        onClick={() => setCurrent(current - 1)}
                      >
                        <LeftOutlined className='back-icon' />
                        <span>Back</span>
                      </Button>
                    </div>
                  )}
                  {current >= 1 && <HeaderStep current={current} />}
                  {current > 0 &&
                    current !== submitStep &&
                    cloneElement(steps[current].content, {
                      initialValues: values,
                      nextStep,
                      handleChangeYear,
                      form,
                      onValuesChange,
                    })}
                  {current > 0 &&
                    current === submitStep &&
                    cloneElement(steps[current].content, {
                      initialValues: values,
                      submit: mutate,
                      form,
                      isLoading: isLoadingSubmit,
                      onValuesChange,
                    })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Steps>
  );
};

export default RecruitmentForm;
