import React from 'react';

import { Tooltip } from 'antd';

import { generateHeaderTable } from '@/utils/generateHeaderTable';

export const tempData = [];
export const breadcrumb = [{ item: 'Rota', to: '/rota' }];

export const headerTable = generateHeaderTable([
  {
    title: 'Contract Type',
    render: (data) => (
      <Tooltip placement='topLeft' title={data}>
        {data}
      </Tooltip>
    ),
  },

  {
    title: 'Shift',
    render: (data) => (
      <Tooltip placement='topLeft' title={data}>
        {data}
      </Tooltip>
    ),
  },

  {
    title: 'Workers',
    render: (data) => (
      <Tooltip placement='topLeft' title={data}>
        {data}
      </Tooltip>
    ),
  },
  {
    title: 'Weekly Hours',
    render: (data) => (
      <Tooltip placement='topLeft' title={data}>
        {data}
      </Tooltip>
    ),
  },
]);

export const dataSource = [
  {
    id: 1,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'A',
  },
  {
    id: 2,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'B',
  },
  {
    id: 3,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'C',
  },
  {
    id: 4,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'D',
  },
  {
    id: 5,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'E',
  },
  {
    id: 6,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'F',
  },
  {
    id: 7,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'G',
  },
  {
    id: 8,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'H',
  },
  {
    id: 9,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'I',
  },
  {
    id: 10,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'J',
  },
  {
    id: 11,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'K',
  },
  {
    id: 12,
    monday: '',
    tuesday: '',
    wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
    TargetRota: '',
    pattern: 'L',
  },
];

export const tabPanes = [
  {
    key: 'Worker-rota',
    tab: 'Worker Rota',
    forceRender: true,
  },
  {
    key: 'Build-rota',
    tab: 'Build Rota',
    forceRender: true,
  },
  {
    key: 'live-rota',
    tab: 'Live Rota',
    forceRender: true,
  },
];
export const allocateData = [
  {
    id: 1,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'A',
  },
  {
    id: 2,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'B',
  },
  {
    id: 3,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'C',
  },
  {
    id: 4,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'D',
  },
  {
    id: 5,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'E',
  },
  {
    id: 6,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'F',
  },
  {
    id: 7,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'G',
  },
  {
    id: 8,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'H',
  },
  {
    id: 9,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'I',
  },
  {
    id: 10,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'J',
  },
  {
    id: 11,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'K',
  },
  {
    id: 12,
    totalOnRota: '',
    targetOnRota: '',
    pattern: 'L',
  },
];
