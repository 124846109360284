const NO_SHOW_TYPE = 'noShow';
const CHECK_IN_TYPE = 'checkIn';
const CHECK_OUT_TYPE = 'checkOut';
const DECLINED_TYPE = 'declined';
const DATE_FORMAT = 'YYYY-MM-DD';

const TYPE_CHECKING = {
  [CHECK_IN_TYPE]: {
    disabled: 'unchecked',
    updateStatus: 'checked_in',
    label: 'Check-in',
    fieldItem: 'in',
  },
  [CHECK_OUT_TYPE]: {
    disabled: 'checked_in',
    updateStatus: 'checked_out',
    label: 'Check-out',
    fieldItem: 'out',
  },
  [NO_SHOW_TYPE]: {
    updateStatus: 'no_show',
    label: 'No Show',
  },
};

const ASSIGNED_SCHEDULES_STATUSES = [CHECK_IN_TYPE, CHECK_OUT_TYPE];

const HOURS_PER_DAY = 24;

const LIMIT_HOURS = {
  beforeStart: 2,
  afterEnd: 6,
};

const CHECK_IN_SHIFT_TYPES = {
  unchecked: 'unchecked',
  completedCheckedOut: 'completedCheckedOut',
  availableCheckinCheckout: 'availableCheckinCheckout',
  completedCheckedIn: 'completedCheckedIn',
  timesheetProcessed: 'timecardsConfirmed',
};

const ERROR_CHECK_IN_NOT_VALID_TIME =
  `You can only check-in ${LIMIT_HOURS.beforeStart} hours before ` +
  `and ${LIMIT_HOURS.afterEnd} hours after the shift start!`;

const ERROR_CHECK_OUT_NOT_VALID_TIME =
  `You can only check-out ${LIMIT_HOURS.beforeStart} hours before ` +
  `and ${LIMIT_HOURS.afterEnd} hours after the shift end!`;

export {
  DATE_FORMAT,
  DECLINED_TYPE,
  TYPE_CHECKING,
  HOURS_PER_DAY,
  NO_SHOW_TYPE,
  LIMIT_HOURS,
  CHECK_IN_TYPE,
  CHECK_OUT_TYPE,
  ASSIGNED_SCHEDULES_STATUSES,
  CHECK_IN_SHIFT_TYPES,
  ERROR_CHECK_IN_NOT_VALID_TIME,
  ERROR_CHECK_OUT_NOT_VALID_TIME,
};
