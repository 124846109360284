import React, { useState } from 'react';

import { Form } from 'antd';
import { uniqueId } from 'lodash';

import { Button, Textarea, FormItem } from '@/components';

import TableMedical from './tableMedical';

const Medical = () => {
  const formInstance = Form.useFormInstance();

  const medicalConditions =
    Form.useWatch('medicalConditions', formInstance) ?? [];

  const [medical, setMedical] = useState();
  const [medicalNote, setMedicalNote] = useState();
  const [medicalCondition, setMedicalCondition] = useState();

  const handleAddMedical = () => {
    if (medicalConditions.length >= 5) {
      return;
    }

    if (medical && medicalNote && medicalCondition) {
      formInstance.setFieldsValue({
        medicalConditions: [
          {
            key: uniqueId(Math.random()),
            medicalCondition,
            medication: medical,
            note: medicalNote,
          },
          ...medicalConditions,
        ],
      });
    }
  };

  return (
    <div>
      <p className='text-label'>Medical Conditions</p>
      <div className='medical-fields'>
        <FormItem label='Medical Condition'>
          <Textarea
            placeholder='Add Medical Issues'
            onChange={(e) => setMedicalCondition(e.target.value)}
          />
        </FormItem>
        <FormItem label='Medication'>
          <Textarea
            placeholder='Medication'
            onChange={(e) => setMedical(e.target.value)}
          />
        </FormItem>
        <FormItem label='Notes'>
          <Textarea
            placeholder='Note'
            onChange={(e) => setMedicalNote(e.target.value)}
          />
        </FormItem>
      </div>
      <div>
        <Button className='btn-add fr yellow-button' onClick={handleAddMedical}>
          Add Medical Condition
        </Button>
      </div>
      <FormItem name='medicalConditions'>
        <TableMedical dataSource={medicalConditions} />
      </FormItem>
    </div>
  );
};

export default Medical;
