import React from 'react';

import { Collapse, message, Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import Button from '@/components/Button';
import Image from '@/components/Image';
import { useVacancyApplicantEmploymentPosition } from '@/hooks/dropdowns';
import { getSeekerProfile } from '@/services/seeker/getSeekerProfile';
import { pastGig } from '@/services/seeker/pastGig';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import { levelLanguage } from './constants';
import './styles.scss';

const { Panel } = Collapse;

const SeekerProfile = ({ setIsShowProfile, seekerId }) => {
  const listRoles = useVacancyApplicantEmploymentPosition();
  const { data: seekerInfo } = useQuery(
    ['seekers', seekerId],
    () => getSeekerProfile(seekerId),
    {
      onError: () => message.error('Load seeker information failed'),
      initialData: {},
    },
  );

  const { data: pastGigInfo, isLoading } = useQuery(
    ['pastGig', seekerId],
    () => pastGig(seekerId),
    {
      onError: () => message.error('Load pastGIG failed'),
      initialData: [],
    },
  );

  const {
    vacancyApplicantAttributes,
    comments = [],
    employeeAddress1,
    employeeFname,
    employeeSname,
    employeeAttributes,
    employeeNotes = [],
  } = seekerInfo;
  const vacancyApplicantEmployments =
    vacancyApplicantAttributes?.vacancyApplicantEmployments ?? [];
  const vacancyApplicantLanguages =
    vacancyApplicantAttributes?.vacancyApplicantLanguages ?? [];

  const renderLicencesAdnDocs = () => (
    <div className='licences-and-doc-container'>
      {/* {licencesAndDocsIcons.map((item, index) => (
        <div key={item.className} className="icon-item">
          <img src={item.src} className={item.className} alt="" />
          <span className="text-item-style">{item.name}</span>
        </div>
      ))} */}
    </div>
  );

  const renderPastGig = () => {
    const columns = generateHeaderTable([
      {
        title: 'Date',
      },
      {
        title: 'Role',
        dataIndex: 'skill_type_name',
        key: 'skill_type_name',
      },
      {
        title: 'Provider',
        dataIndex: 'provider_name',
        key: 'provider_name',
      },
    ]);

    return (
      <Table
        pagination={false}
        columns={columns}
        dataSource={pastGigInfo}
        loading={isLoading}
      />
    );
  };

  const renderExperience = () =>
    vacancyApplicantEmployments.map((emp) => (
      <div key={emp.leave} className='experience-container'>
        <h3>
          {listRoles.find((item) => item.value === emp.positionId)?.label ??
            'No experience'}
        </h3>
        <p>{emp.leave ?? 'No Description'}</p>
      </div>
    ));

  const renderComments = () => {
    const commentList = [...comments, ...employeeNotes];
    return commentList.map((cmt) => {
      const time = moment(cmt.createdAt).format('HH:mm');
      const date = moment(cmt.createdAt).format('DD/MM/YYYY');
      return (
        <div key={cmt.content} className='comment-container'>
          <h3>
            {cmt.createdBy} - {time} - {date}
          </h3>
          <p>{cmt.content ?? 'No comment'}</p>
        </div>
      );
    });
  };

  const panels = [
    {
      title: 'Licences and Docs',
      key: 'licence',
      component: renderLicencesAdnDocs(),
    },
    {
      title: 'Past GIG’s',
      key: 'pastGig',
      component: renderPastGig(),
      className: 'past-gig',
    },
    {
      title: 'Experience',
      key: 'experience',
      component: renderExperience(),
    },
    {
      title: 'Comments',
      key: 'comment',
      component: renderComments(),
    },
  ];

  return (
    <div className='seeker-profile-container'>
      <div className='header-container'>
        <Image
          containerClassName='profile-pic-container'
          className='profile-pic'
          src={employeeAttributes?.profilepic}
        />
        <div className='info-header-container'>
          <h1 className='title-style'>{`${employeeFname}  ${employeeSname}`}</h1>
          <div className='row-container'>
            <div className='items'>
              <p className='text-style'>Home Office: {employeeAddress1}</p>
              <p className='text-style'>
                Phone Number: {vacancyApplicantAttributes?.phone}
              </p>
            </div>
            <div className='items'>
              <div className='row-container'>
                <p className='text-style'>Languages:</p>
                <ul>
                  {vacancyApplicantLanguages.map((language) => (
                    <li key={language.language} className='text-style'>
                      {`${language.name} - ${levelLanguage[language.level]}`}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='body-container'>
        <Collapse
          defaultActiveKey={['licence', 'pastGig', 'experience', 'comment']}
          expandIconPosition='right'
        >
          {panels.map((panel) => (
            <Panel
              className={panel.className}
              key={panel.key}
              header={panel.title}
            >
              {panel.component}
            </Panel>
          ))}
        </Collapse>
      </div>
      <div className='footer-container'>
        <Button
          onClick={() => setIsShowProfile(false)}
          className='black-button'
        >
          Close
        </Button>
      </div>
    </div>
  );
};

SeekerProfile.propTypes = {
  seekerId: PropTypes.bool,
  setIsShowProfile: PropTypes.func,
};

SeekerProfile.defaultProps = {
  seekerId: false,
  setIsShowProfile: () => {},
};

export default SeekerProfile;
