import { rateCard } from '@/utils';

const payRate = ({
  formInstance,
  namePath,
  valueChanged,
  providerRateCard,
}) => {
  const otherCost = providerRateCard?.other_cost ?? 0;
  const marginIsCostType = providerRateCard?.margin_is_cost_type;
  const marginFixed = providerRateCard?.margin_fixed;
  const marginPercent = providerRateCard?.margin_percent;
  const pensionAndApprentice = providerRateCard?.pension_apprenticeship_percent;

  const chargeRate = rateCard.calc({
    payrate: parseFloat(valueChanged),
    pensionAndApprentice,
    marginIsCostType,
    marginPercent,
    marginFixed,
    otherCost,
  });
  formInstance.setFields([
    {
      name: [...namePath, 'chargeRate'],
      value: chargeRate,
    },
  ]);
};

const handleChange = {
  payRate,
};

export default handleChange;
