import React from 'react';

import { message, Upload } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { Button, FormItem } from '@/components';
import { useFetch } from '@/hooks';
import { documents } from '@/services/timesheetService';
import uploadFileS3 from '@/utils/uploadFileS3';
import { dummyRequest, renameBeforeUpload } from '@/utils/uploadFileUtil';

import History from '../History';
import './styles.scss';

const Document = () => {
  const { id } = useParams();

  const columnsTableDocument = [
    {
      title: 'Document',
      dataIndex: 'name',
      width: '60%',
      render: (_, record) => {
        const name = record.file.split('/').pop();

        return (
          <a
            target='_blank'
            href={record.file}
            alt='file-name'
            rel='noreferrer'
            className='document-link'
          >
            {name}
          </a>
        );
      },
    },
    {
      dataIndex: 'createdBy',
      width: '20%',
    },
    {
      dataIndex: 'createdAt',
      width: '20%',
      render: (dt) => (
        <span>
          {moment(dt).format('DD/MM/YYYY')} {moment(dt).format('hh:mm')}
        </span>
      ),
    },
    {
      title: '',
      className: 'close-icon-container',
      render: (_, record) => (
        <ClosedIcon
          onClick={() => deleteDocument(record.id)}
          className='close-icon-style'
        />
      ),
    },
  ];

  const {
    data: historyDocuments,
    isFetching: documentFetching,
    refetch: documentRefetch,
  } = useFetch(['show-documents', id], () => documents.getDocuments(id), {
    initialData: [],
  });

  const { mutate: addDocument, isLoading: isUploadDocument } = useMutation(
    async (uploadedFile) => {
      const { getUrl } = await uploadFileS3(
        renameBeforeUpload(uploadedFile.file.name),
        uploadedFile.file.originFileObj,
      );

      return documents.createDocument(id, getUrl);
    },
    {
      onSuccess: () => documentRefetch(),
      onError: () => message.error('Add document failed'),
    },
  );

  const { mutate: deleteDocument } = useMutation(
    (docId) => documents.deleteDocument(id, docId),
    {
      onSuccess: () => documentRefetch(),
      onError: () => message.error('Delete document failed'),
    },
  );

  return (
    <div className='documents'>
      <FormItem label='Document'>
        <Upload
          multiple
          name='file'
          accept='.csv, .doc, .docx, .png, .jpeg, .jpg, .pdf'
          customRequest={dummyRequest}
          showUploadList={false}
          disabled={historyDocuments.length >= 3}
          onChange={(uploadedFile) => {
            if (uploadedFile.file.status === 'done') {
              addDocument(uploadedFile);
            }
          }}
        >
          <Button
            className='yellow-button upload-button'
            loading={isUploadDocument}
          >
            Upload Document
          </Button>
        </Upload>
      </FormItem>

      <History
        title='History'
        columns={columnsTableDocument}
        dataSource={historyDocuments}
        loading={documentFetching}
      />
    </div>
  );
};
export default Document;
