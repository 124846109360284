/* eslint-disable no-prototype-builtins */
import { round, sum } from 'lodash';

import { calculateHoursInShift } from './date';

const calculateBonusMoney = (bonusType, bonusValue) => {
  const TYPES = { no_bonus: 0, deduction_money: -1, bonus_money: 1 };
  const type = TYPES[bonusType] || TYPES.no_bonus;

  return bonusValue * type;
};

export const calculateTotalPayAndTotalCharge = (
  /** @type {any[]} */ infoDetail,
  /** @type {import("antd").FormInstance<any>} */ form,
) => {
  if (!infoDetail)
    return {
      totalPay: 0,
      totalCharge: 0,
    };
  const listTotalPay = [];
  const listTotalCharge = [];
  const listHour = [];

  infoDetail.forEach((info) => {
    const listTimecards = Object.values(info.dayworks || {})
      .flat(1)
      .filter((item) => item.id);

    listTimecards.forEach((item) => {
      const hours = calculateHoursInShift({
        checkinTime: item.checkin_time,
        checkoutTime: item.checkout_time,
        breakTime: item.break,
        bonusType: item.bonus_type,
        bonusValue: item.bonus_value,
      });
      const bonusMoney = calculateBonusMoney(item.bonus_type, item.bonus_value);
      listTotalPay.push(round(hours * info.adjusted_payrate + bonusMoney, 2));
      listTotalCharge.push(round(hours * info.charge_rate, 2));
      listHour.push(hours);
    });
  });

  return {
    totalPay: round(sum(listTotalPay), 2),
    totalCharge: round(sum(listTotalCharge), 2),
    hours: sum(listHour),
  };
};

function sumObjectsByKey(...objs) {
  return objs.reduce((a, b) => {
    for (const k in b) {
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
    }
    return a;
  }, {});
}

export const sumTotalByJobType = (jobs = []) => {
  if (!jobs) {
    return [];
  }

  const result = {};

  Object.keys(jobs).forEach((status) =>
    Object.keys(jobs[status]).forEach((jobType) => {
      const total = calculateTotalPayAndTotalCharge(
        Object.values(jobs[status][jobType] || {}),
      );

      result[jobType] = sumObjectsByKey(result[jobType] ?? {}, total);
    }),
  );
  return result;
};

export const INVOICE_TYPE = {
  COST: 'cost',
  NORMAL: 'labour',
};
