/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';

import { Divider, Form } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { FormItem, Input, Modal, Select } from '@/components';
import { COST_ROLES } from '@/constants';

import { useTenantContext } from '../../../../../TenantWrapper';

const CostSection = ({ onChangeNetCost, onChangeService }) => {
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { currency } = useTenantContext();

  const formInstance = Form.useFormInstance();
  const costsSection = Form.useWatch('costsSection', formInstance) ?? [];

  return (
    <div
      className={classNames('sections-container', {
        'hide-section':
          costsSection.filter((item) => !item._destroy).length === 0,
      })}
    >
      <Form.List name='costsSection'>
        {() =>
          costsSection.map((field, index) => {
            const isDestroy = field._destroy;
            return (
              !isDestroy && (
                <div key={field.key} className='description'>
                  <div className='left-section'>
                    <div className='fields'>
                      <div className='row-container'>
                        <FormItem
                          name={[index, 'invoiceDetailType']}
                          noStyle
                          initValue='cost'
                        />
                        <FormItem
                          name={[index, 'description']}
                          label='Description'
                          className='large-width'
                          rules={[
                            {
                              required: true,
                              message: 'Description is required',
                            },
                          ]}
                        >
                          <Input
                            defaultValue='Additional Cost'
                            placeholder='Additional Cost'
                          />
                        </FormItem>

                        <FormItem
                          name={[index, 'labourTypeId']}
                          label='Service'
                          className='medium-width'
                          rules={[
                            {
                              required: true,
                              message: 'Service is required',
                            },
                          ]}
                        >
                          <Select
                            onChange={(_e, option) =>
                              onChangeService(option, index)
                            }
                            options={COST_ROLES}
                            placeholder='Service'
                          />
                        </FormItem>

                        <FormItem
                          name={[index, 'omniNetPay']}
                          label='Net Cost'
                          className='small-width'
                          rules={[
                            {
                              required: true,
                              message: 'Net Cost is required',
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => onChangeNetCost(e, index)}
                            onBlur={(e) => onChangeNetCost(e, index)}
                            placeholder='NET Cost'
                          />
                        </FormItem>
                        <FormItem
                          name={[index, 'omniGross']}
                          label='GROSS Cost'
                          className='small-width'
                        >
                          <Input disabled placeholder='Gross Cost' />
                        </FormItem>
                      </div>

                      <Modal
                        visible={openDeleteModal}
                        onClickYes={() => {
                          costsSection[deleteIndex]._destroy = true;
                          setDeleteIndex(null);
                          setOpenDeleteModal(false);
                          formInstance.setFieldsValue({ costsSection });
                        }}
                        onClickNo={() => setOpenDeleteModal(false)}
                      >
                        <span>Are you sure to want to remove?</span>
                      </Modal>

                      <div className='close-icon-container'>
                        <ClosedIcon
                          onClick={() => {
                            setDeleteIndex(index);
                            setOpenDeleteModal(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='right-section'>
                    <FormItem
                      name={[index, 'omniNetPayAmount']}
                      label='NET Amount:'
                    >
                      <Input prefix={currency} className='none-style' />
                    </FormItem>
                    <Divider type='vertical' className='divider-amount' />
                    <FormItem name={[index, 'omniVat']} label='20% VAT:'>
                      <Input prefix={currency} className='none-style' />
                    </FormItem>
                  </div>
                </div>
              )
            );
          })
        }
      </Form.List>
    </div>
  );
};

CostSection.propTypes = {
  onChangeNetCost: PropTypes.func,
  onChangeService: PropTypes.func,
};

CostSection.defaultProps = {
  onChangeNetCost: () => {},
  onChangeService: () => {},
};
export default CostSection;
