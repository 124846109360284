/* eslint-disable import/order */
import React, { memo } from 'react';

import { useLocation } from 'react-router-dom';

import { Modal as AntdModal, Card, Divider, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import './styles.scss';

import { Tabs } from '..';
import EditView from './Components/EditView';
import SwapsView from './Components/SwapsView';
import Attendances from './Components/Attendances';
import useBuildRotaStore from '@/store/rotaStore';
import { getDuration } from '@/utils/date';

const formatDate = (event) =>
  `${moment(event?.start).format('HH:mm')} - ${moment(event?.end).format(
    'HH:mm',
  )}`;

const ModalEdit = ({
  children,
  visible,
  onClickNo,
  onClickYes,
  isSuccess,
  isLoading,
  isShowFooter,
  isWarning,
  rotaShiftId,
  rotaWeekId,
  data,
  event,
  formBuildRota,
  ...props
}) => {
  const agency = useBuildRotaStore((state) => state.agency);
  const state = useLocation();
  const tabKey = state?.tabKey;
  const formInstance = Form.useFormInstance();

  const contracted =
    Form.useWatch([rotaShiftId, 'numberOfEmployees'], formInstance) ?? 0;
  const hours = getDuration(event?.start, event?.end);
  const { required } = data;
  const difference = contracted - required + agency;

  const tabPanes = [
    {
      key: 'edits',
      tab: 'Edits',
      child: (
        <EditView data={{ contracted, hours }} rotaShiftId={rotaShiftId} />
      ),
      forceRender: true,
    },
    {
      key: 'swaps',
      tab: 'Swaps',
      child: (
        <SwapsView
          rotaWeekId={rotaWeekId}
          formBuildRota={formBuildRota}
          event={event}
        />
      ),
      forceRender: true,
    },
    {
      key: 'attendance',
      tab: 'Attendance',
      child: <Attendances rotaShiftId={rotaShiftId} event={event} />,
      forceRender: true,
    },
  ];
  let color;

  if (difference < 0) {
    color = '#DD9DA0';
  } else if (difference > 0 && difference !== contracted) {
    color = '#F6E6A7';
  } else {
    color = '#BAD0CA';
  }

  return (
    <AntdModal
      visible={visible}
      centered={false}
      onCancel={onClickNo}
      wrapClassName='custom-modal'
      footer={null}
      closable={false}
      mask
    >
      <div style={{ backgroundColor: color }} className='table-content'>
        <Card className='top-header-content'>
          <h5 className='text-title'>
            {moment(event?.start).format('ddd DD MMM YYYY')}
          </h5>
          <h5 className='text-title'>{formatDate(event)}</h5>
          <h5 className='text-title'>{event?.skillName}</h5>
        </Card>
        <Card className='top-header-content'>
          <p className='text-content'>
            Contracted {contracted}({contracted * hours}hrs)
          </p>
          <p className='text-content'>
            Required {required} ({required * hours}hrs)
          </p>
          <p className='text-content'>
            Difference {difference} ({difference * hours}hrs)
          </p>
        </Card>
        <Card className='top-header-content'>
          <p className='text-content'>Confirmed TBH</p>
          <p className='text-content'>Attended TBH</p>
        </Card>
      </div>
      <Divider className='modal-divider' />
      <Tabs
        className='job-tabs'
        tabPanes={tabPanes}
        defaultActiveKey={tabKey}
      />
    </AntdModal>
  );
};

ModalEdit.propTypes = {
  children: PropTypes.string,
  visible: PropTypes.bool,
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  isShowFooter: PropTypes.bool,
  isWarning: PropTypes.bool,
  data: PropTypes.string,
  rotaShiftId: PropTypes.string,
  rotaWeekId: PropTypes.string,
  event: PropTypes.object,
  formBuildRota: PropTypes.object,
};

ModalEdit.defaultProps = {
  children: '',
  visible: false,
  onClickNo: () => {},
  onClickYes: () => {},
  isSuccess: false,
  isLoading: false,
  isShowFooter: true,
  isWarning: true,
  data: {},
};

export default memo(ModalEdit);
