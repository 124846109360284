import { useQuery } from 'react-query';

import { fetchManagersOfLead } from '@/services/dropdownServices/getManagersOfLead';

const useManagersOfLead = (queryOptions = {}) =>
  useQuery(['managersOfLead'], fetchManagersOfLead, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...queryOptions,
  });

export default useManagersOfLead;
