/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Form, Upload, message } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-query';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { Button, FormItem } from '@/components';
import { useAuthStore } from '@/store/authStore';
import uploadFileS3 from '@/utils/uploadFileS3';
import { dummyRequest, beforeUploadImage } from '@/utils/uploadFileUtil';

import './styles.scss';
import History from '../History';

const InvoiceDocument = () => {
  const form = Form.useFormInstance();
  const userName = useAuthStore((state) => state.userName);

  const columnsTableDocument = [
    {
      title: 'Allocated Client',
      dataIndex: 'allocatedClient',
      width: '15%',
    },
    {
      title: 'Week',
      dataIndex: 'week',
      width: '15%',
    },
    {
      title: 'Total NET Amount',
      dataIndex: 'totalNETAmount',
      width: '15%',
    },
    {
      title: 'Document',
      dataIndex: 'file',
      render: (_, record) => (
        <a
          href={record.file}
          target='_blank'
          alt='file-name'
          rel='noreferrer'
          className='doc-name'
        >
          {record.name}
        </a>
      ),
    },
    {
      title: 'User',
      dataIndex: 'createdBy',
      width: '10%',
    },
    {
      title: 'Date and Time',
      dataIndex: 'createdAt',
      width: '15%',
      render: (/** @type {string[]} */ datetime) =>
        moment(datetime).format('DD/MM/YYYY hh:mm:ss'),
    },

    {
      title: 'Approved',
      dataIndex: 'approved',
      width: '15%',
      render: () => 'Approved',
    },
    {
      title: '',
      className: 'close-icon-container',
      render: (_, record) => (
        <ClosedIcon
          onClick={() => deleteDocument(record?.id ?? record?.index)}
          className='close-icon-style'
        />
      ),
    },
  ];
  const historyDocuments =
    Form.useWatch('supplierDocumentsAttributes', form) ?? [];
  const historyDocumentsAttributes = historyDocuments.filter(
    // eslint-disable-next-line no-underscore-dangle
    (document) => !document._destroy,
  );

  const { mutate: uploadDocument, isLoading } = useMutation(
    async (uploadedFile) =>
      uploadFileS3(uploadedFile.file.name, uploadedFile.file.originFileObj),
    {
      onSuccess: (res) => {
        const data = [
          {
            index: `${Math.floor(Math.random() * 10)}${moment().unix()}`,
            file: res.getUrl,
            name: res.fileName,
            createdBy: userName,
          },
          ...historyDocumentsAttributes,
        ];

        form.setFieldsValue({ supplierDocumentsAttributes: data });
        form.setFieldsValue({ comment: '' });
        message.success('Upload document successfully, click save to persist!');
      },
      onError: () => message.error('Add document failed'),
    },
  );
  const deleteDocument = (id) => {
    const foundItem = historyDocumentsAttributes.findIndex(
      (document) => document.id === id || document.index === id,
    );

    historyDocumentsAttributes[foundItem]._destroy = '1';

    form.setFieldsValue({
      supplierDocumentsAttributes: historyDocumentsAttributes,
    });
  };

  return (
    <div className='documents'>
      <FormItem label='Invoice Upload' name='documents_file'>
        <Upload
          name='file'
          accept='.png, .jpeg, .jpg'
          customRequest={dummyRequest}
          beforeUpload={beforeUploadImage}
          multiple
          onChange={(uploadedFile) => {
            if (uploadedFile.file.status === 'done') {
              uploadDocument(uploadedFile);
            }
          }}
          showUploadList={false}
        >
          <Button className='yellow-button upload-button'>
            Upload Document
          </Button>
        </Upload>
      </FormItem>
      <History
        title='Invoice History'
        formItemPropertyName='supplierDocumentsAttributes'
        columns={columnsTableDocument}
        dataSource={historyDocumentsAttributes}
        isLoading={isLoading}
      />
    </div>
  );
};
export default InvoiceDocument;
