import React, { useState, useRef } from 'react';

import { Form, message, Modal as AntdModal, Spin } from 'antd';
import { debounce, isNaN, sumBy } from 'lodash';
import { useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';

import { Button, GeneralTemplate } from '@/components';
import { useFetch, useReactToPdf } from '@/hooks';
import { InvoiceService } from '@/services';
import uploadFileS3 from '@/utils/uploadFileS3';

import PreviewInvoice from '../PreviewInvoice';
import { Header, BodySection, BodyTotal, Footer } from './components';
import { useTenantContext } from '../../../../TenantWrapper';
import './styles.scss';

const data = [
  { item: 'Invoicing', to: '/invoicing' },
  { item: 'Edit Invoices', to: '/edit-invoice' },
];

const EditInvoice = () => {
  const ref = useRef();
  const history = useHistory();
  const [form] = Form.useForm();
  const { invoiceId } = useParams();
  const { toPdf } = useReactToPdf({
    targetRef: ref,
  });
  const { currency } = useTenantContext();

  const hoursSection = Form.useWatch('hoursSection', form) ?? [];
  const costsSection = Form.useWatch('costsSection', form) ?? [];
  const invoiceDetailsAttributes = hoursSection
    .concat(costsSection)
    .filter(
      (invoiceDetail) =>
        !!Number(invoiceDetail.omniNetPay) &&
        Number(invoiceDetail.omniNetPay) > 0,
    );

  const [blobPdf, setBlobPdf] = useState();
  const [isPreview, setIsPreview] = useState(false);
  const [isShowInvoice, setIsShowInvoice] = useState(false);
  const [uploadedProgress, setUploadedProgress] = useState(0);
  const [isCreateInvoice, setIsCreateInvoice] = useState(false);

  const { data: invoice, isFetching: fetchingSection } = useFetch(
    ['invoice-detail', invoiceId],
    () => InvoiceService.getInvoiceId(invoiceId, currency),
    {
      onSuccess: (dt) => {
        form.setFieldsValue(dt);
      },
      initialData: {},
    },
  );
  const {
    providerName,
    providerLocation,
    invoiceWeek,
    invoiceYear,
    weekStartDate,
    weekEndDate,
    timesheetId,
    employerId,
    locationId,
  } = invoice;

  const rangeWeekDate = fetchingSection ? (
    <Spin />
  ) : (
    `${weekStartDate} - ${weekEndDate}`
  );

  const totalNetAmount = sumBy(invoiceDetailsAttributes, (item) =>
    // eslint-disable-next-line no-underscore-dangle
    item?._destroy ? 0 : Number(item?.omniNetPay ?? 0),
  );

  const totalOmniVat = sumBy(
    invoiceDetailsAttributes,
    // eslint-disable-next-line no-underscore-dangle
    (item) => (item?._destroy ? 0 : Number(item?.omniVat ?? 0)),
  );

  const total = totalNetAmount + totalOmniVat;

  const { mutate: createInvoice, isLoading } = useMutation(
    async () => {
      const values = form.getFieldsValue();

      const filenamePdf = `invoice-${[
        employerId,
        locationId,
        invoiceYear,
        invoiceWeek,
      ].join('-')}.pdf`;

      const { getUrl: filename } = await uploadFileS3(
        filenamePdf,
        blobPdf,
        setUploadedProgress,
      );

      return InvoiceService.editInvoice({
        ...values,
        invoiceDetailsAttributes,
        invoiceDate: values.invoiceDate,
        invoiceId,
        timesheetId,
        filename,
        subTotal: totalNetAmount,
        vat: totalOmniVat,
        total,
      });
    },
    {
      onSuccess: () => {
        message.success('Create invoice successfully');
        history.push('/invoicing');
      },
      onError: (error) =>
        message.error(error.message ?? 'Create invoice failed'),
    },
  );

  const formatToNumber = (value, fixedNumber = 0) =>
    isNaN(value) ? 0 : value.toFixed(fixedNumber);

  const onChangeNetCost = debounce((e, index) => {
    const changedSection = costsSection[index];
    const updatedOmniNetPay = parseFloat(e.target.value);
    const updatedOmniVat = (20 / 100) * updatedOmniNetPay ?? 0;
    const updatedOmniGross = updatedOmniNetPay + updatedOmniVat;

    let omniNetPay = e.target.value;
    if (e.type === 'blur') {
      omniNetPay = formatToNumber(updatedOmniNetPay, 2);
    }
    costsSection[index] = {
      ...changedSection,
      omniNetPay,
      omniNetPayAmount: formatToNumber(updatedOmniNetPay, 2),
      omniVat: formatToNumber(updatedOmniVat, 2),
      omniGross: formatToNumber(updatedOmniGross, 2),
    };

    form.setFieldsValue({ costsSection });
  }, 500);

  const onChangeService = (option, index) => {
    const changedSection = costsSection[index];
    const { label, value } = option;

    costsSection[index] = {
      ...changedSection,
      labourTypeId: value,
      description: `For ${label} provided to and from the warehouse ${rangeWeekDate}`,
      invoiceDetailType: 'cost',
    };

    form.setFieldsValue({ costsSection });
  };

  const confirmInvoice = async () => {
    const doc = document.getElementById('invoice-pdf-container');

    const maxHeight = Math.max(doc?.scrollHeight, doc?.offsetHeight);
    const maxWidth = doc?.scrollWidth;

    const { blob } = await toPdf({ width: maxWidth, height: maxHeight });
    setBlobPdf(blob);
    setIsShowInvoice(false);
  };

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={data}
      headerText='Preview - Edit Invoice'
      hasButton={false}
      className='edit-invoice'
    >
      <Form
        onFieldsChange={() => setIsPreview(false)}
        form={form}
        onFinish={createInvoice}
        className='invoice-container'
      >
        <p className='invoice-title'>Invoice Address and Week:</p>
        <Header
          providerName={providerName}
          providerLocation={providerLocation}
          invoiceWeek={invoiceWeek}
          invoiceYear={invoiceYear}
          weekStartDate={weekStartDate}
          weekEndDate={weekEndDate}
          rangeWeekDate={rangeWeekDate}
          total={total}
          timesheetId={timesheetId}
        />
        <div className='invoice-body'>
          <BodySection
            fetchingSection={fetchingSection}
            rangeWeekDate={rangeWeekDate}
            onChangeNetCost={onChangeNetCost}
            onChangeService={onChangeService}
          />
          <BodyTotal
            totalNetAmount={totalNetAmount}
            totalOmniVat={totalOmniVat}
            total={total}
          />
        </div>
        <Footer
          setIsCreateInvoice={setIsCreateInvoice}
          isCreateInvoice={isCreateInvoice}
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          setIsShowInvoice={setIsShowInvoice}
          blobPdf={blobPdf}
        />
      </Form>
      <AntdModal
        wrapClassName='loading-modal'
        footer={null}
        visible={isLoading}
        closable={false}
      >
        <Spin tip={`Uploading invoice Pdf ${uploadedProgress.toFixed(0)}%`} />
      </AntdModal>
      <AntdModal
        visible={isShowInvoice}
        onCancel={() => setIsShowInvoice(false)}
        wrapClassName='invoice-preview'
        footer={null}
      >
        <PreviewInvoice
          pdfRef={ref}
          totalNetAmount={totalNetAmount}
          invoice={invoice}
          totalOmniVat={totalOmniVat}
          total={total}
          invoiceDetails={invoiceDetailsAttributes}
          rangeWeekDate={rangeWeekDate}
          createAt={form.getFieldValue('invoiceDate')}
        />
        <div className='confirm-btn-container'>
          <Button
            className='yellow-button confirm-button'
            onClick={confirmInvoice}
          >
            Confirm invoice
          </Button>
        </div>
      </AntdModal>
    </GeneralTemplate>
  );
};

export default EditInvoice;
