import { ORDER_BY, ORDER_DIRECTION } from '@/constants';
import request from '@/request';

import { getAddressInfomation } from '../getAddressInfomation';

const downloadCsv = async ({ sortOrder, pageNumber, pageSize, ...filter }) => {
  const { employeePostCode } = filter;

  const geogecode = employeePostCode
    ? await getAddressInfomation(`${employeePostCode}-uk`)
    : [];

  filter = {
    ...filter,
    latitude: geogecode?.[0]?.geometry?.location?.lat,
    longitude: geogecode?.[0]?.geometry?.location?.lng,
    employeePostCode: geogecode?.[0]?.address_components?.[0]?.long_name,
  };

  const page = {
    size: pageSize,
    number: pageNumber,
  };

  const order = {
    orderBy: ORDER_BY,
    orderDirection: sortOrder || ORDER_DIRECTION,
  };

  return (
    await request.get('/seekers/employees/csv', {
      params: {
        page,
        filter,
        order,
      },
    })
  ).data;
};

export default downloadCsv;
