import request from '@/request';

const getListSearchTerms = async (searchTerm) => {
  const response = await request.get('/omniusers/users/autocomplete', {
    params: {
      filter: {
        searchTerm,
      },
    },
  });

  return (
    response.data.data?.map((item) => ({
      value: item,
      label: item,
    })) ?? []
  );
};

export default getListSearchTerms;
