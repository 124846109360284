import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const fetchDepartments = async () => {
  const res = await request.get('/options/user_departments');

  const data = normalizeIndexResponse(res);

  return data.data;
};
