import request from '@/request';

const editOmniUser = (id, payload) => {
  request.put(`/omniusers/users/${id}`, {
    omniUser: {
      userName: payload?.userName,
      userFullname: payload?.userFullname,
      userTels: payload?.userTels,
      userEmail: payload?.userEmail,
      password: payload?.password,
      userPosition: payload?.userPosition,
      holidayApproval: payload?.holidayApproval,
      userDepartmentId: payload?.userDepartmentId,
      userLevelId: payload?.userLevelId,
      departmentId: payload?.departmentId,
    },
  });
};

export default editOmniUser;
