import React from 'react';

import { Row, Form, Card, Col, message, Modal as AntdModal, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { Button, FormItem, Select } from '@/components';
import { useFetch } from '@/hooks';
import { editRotaWeekPlan, getOneRotaWeekPlan } from '@/services/rota';
import { date as dateObj } from '@/utils';

import './styles.scss';

const changeCellColor = (record) => {
  let color;
  if (record > 0) {
    color = '#F6E6A7';
  } else if (record < 0) {
    color = '#D49FA1';
  } else {
    color = '#84BAAB';
  }
  return color;
};

const RotaTable = ({ rotaWeekId, formBuildRota, event }) => {
  const [form] = Form.useForm();

  const listDate = dateObj.getCurrentWeekDays(event?.start);

  const employeesAttributes = Form.useWatch('employeesAttributes', form);

  const countNumberOfPeople = Array(7).fill(0);

  employeesAttributes?.forEach((element) => {
    element?.employeesRotaShiftPlansAttributes?.forEach((e, i) => {
      if (e.dateSelect !== 'off') {
        countNumberOfPeople[i] += 1;
      }
    });
  });

  const { refetch } = useFetch(
    ['rota_week_plans', rotaWeekId],
    () => getOneRotaWeekPlan(rotaWeekId),
    {
      onSuccess: (response) => {
        form.setFieldsValue(response);
      },
    },
  );

  const { mutate: editRotaMutate, isLoading: isUpdating } = useMutation(
    (values) => editRotaWeekPlan({ id: rotaWeekId, values }),
    {
      onSuccess: () => {
        message.success('Rota edited successfully');
        refetch();
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const seekers =
    form
      .getFieldsValue(true)
      .employeesAttributes?.filter((employee) => employee) ?? [];
  const options = form.getFieldsValue(true)?.dateSelect
    ? [
        {
          label: form.getFieldsValue(true)?.dateSelect,
          value: form.getFieldsValue(true)?.dateSelect,
        },
        { label: 'off', value: 'off' },
      ]
    : [];

  const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const handleSubmit = (values) => {
    editRotaMutate(values);
  };

  return (
    <Card className='swapsview-card'>
      <Form form={form} onFinish={handleSubmit}>
        <AntdModal
          className='loading-modal'
          visible={isUpdating}
          footer={false}
        >
          <Spin />
          <p className='loading-text'>Updating</p>
        </AntdModal>

        <Row>
          <Col flex={1}>
            <Card className='event-tag'>Workers</Card>
          </Col>
          <Col flex={7}>
            <Row span={6}>
              {weekDays.map((day) => (
                <Col key={day} flex={1}>
                  <Card className='event-tag'>{day}</Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <Card className='event-tag'>
              <p>{}</p>
            </Card>
          </Col>
          <Col flex={7}>
            <Row span={6}>
              {listDate.map((date, index) => (
                <Col key={date} flex={1}>
                  <Card
                    className='event-tag'
                    style={{
                      backgroundColor: changeCellColor(
                        countNumberOfPeople[index] -
                          (form?.getFieldValue([
                            'differencePeopleList',
                            index,
                          ]) ?? 0),
                      ),
                    }}
                  >
                    {countNumberOfPeople[index] -
                      (form?.getFieldValue(['differencePeopleList', index]) ??
                        0)}
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        {seekers.map((employee, employeeIndex) => (
          <Row key={employee.id}>
            <Card className='event-tag'>
              <Col flex={1}>
                <FormItem
                  noStyle
                  name={['employeesAttributes', employeeIndex, 'id']}
                />

                <span>{employee?.fullname}</span>
              </Col>
            </Card>

            <Col flex={8}>
              <Row justify='start'>
                {Array.from({ length: 7 }).map((shift, shiftNumber) => (
                  <Col key={shift}>
                    <Card className='event-tag'>
                      <FormItem
                        name={[
                          'employeesAttributes',
                          employeeIndex,
                          'employeesRotaShiftPlansAttributes',
                          shiftNumber,
                          'dateSelect',
                        ]}
                      >
                        <Select
                          dropdownClassName='dropdown-select'
                          options={options}
                          onChange={(value) => {
                            form.setFields([
                              {
                                name: [
                                  'employeesAttributes',
                                  employeeIndex,
                                  'employeesRotaShiftPlansAttributes',
                                  shiftNumber,
                                  'dateSelect',
                                ],
                                value,
                              },
                              {
                                name: [
                                  'employeesAttributes',
                                  employeeIndex,
                                  'employeesRotaShiftPlansAttributes',
                                  shiftNumber,
                                  '_destroy',
                                ],
                                value: value === 'off',
                              },
                            ]);
                          }}
                        />
                      </FormItem>
                    </Card>

                    <FormItem
                      noStyle
                      name={[
                        'employeesAttributes',
                        employeeIndex,
                        'employeesRotaShiftPlansAttributes',
                        shiftNumber,
                        'id',
                      ]}
                    />

                    <FormItem
                      noStyle
                      name={[
                        'employeesAttributes',
                        employeeIndex,
                        'employeesRotaShiftPlansAttributes',
                        shiftNumber,
                        'rotaShiftPlanId',
                      ]}
                    />
                    <FormItem
                      noStyle
                      name={[
                        'employeesAttributes',
                        employeeIndex,
                        'employeesRotaShiftPlansAttributes',
                        shiftNumber,
                        '_destroy',
                      ]}
                      initialValue={false}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ))}
        <div className='save-button'>
          <Button className='yellow-button' htmlType='submit'>
            Save Swaps
          </Button>
        </div>
      </Form>
    </Card>
  );
};
RotaTable.propTypes = {
  rotaWeekId: PropTypes.string,
  formBuildRota: PropTypes.object,
  event: PropTypes.object,
};

export default RotaTable;
