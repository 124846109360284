import React from 'react';

import { Form } from 'antd';

import { FormItem, Input, Select } from '@/components';
import { listHears } from '@/constants';
import { useLocations } from '@/hooks/dropdowns';
import {
  rulesTextInput,
  rulesSelect,
  rulesAccountNumber,
  rulesNationalInsuranceNo,
  rulesSortCode,
} from '@/utils/rulesInput';

import { paymentMethods } from './constants';

const WorkerPayrollAndBankDetails = () => {
  const { listLocations } = useLocations();

  const paymentCheque = Form.useWatch(['employeePaymentMethod']);

  const isPaymentCheque = paymentCheque === 'cheque';

  const formInstance = Form.useFormInstance();

  const onChange = (item) => {
    if (item === 'cheque') {
      formInstance.setFields([
        { name: ['vacancyApplicantAttributes', 'bankAccName'], errors: [] },
        { name: ['vacancyApplicantAttributes', 'bankSort'], errors: [] },
        { name: ['vacancyApplicantAttributes', 'bank'], errors: [] },
        { name: ['vacancyApplicantAttributes', 'bankAcc'], errors: [] },
        { name: ['vacancyApplicantAttributes', 'ni'], errors: [] },
      ]);
    }
  };

  return (
    <div className='worker-payroll-and-bank-details'>
      <div className='label'>Worker Payroll and Bank Details</div>

      <div className='items'>
        <FormItem
          label='Employee Number'
          name={['vacancyApplicantAttributes', 'employeeNumber']}
        >
          <Input placeholder='Employee Number' disabled />
        </FormItem>
        <FormItem
          label='Past Employee Numbers'
          name={['vacancyApplicantAttributes', 'pastEmployeeNumber']}
        >
          <Input placeholder='Past Employee Numbers' />
        </FormItem>
        <FormItem
          label='Payment Method'
          name='employeePaymentMethod'
          rules={rulesSelect('Payment Method')}
          required
        >
          <Select
            options={paymentMethods}
            placeholder='Payment Method'
            onChange={onChange}
          />
        </FormItem>
        <FormItem
          label='Account Holder Name'
          name={['vacancyApplicantAttributes', 'bankAccName']}
          rules={
            !isPaymentCheque ? rulesTextInput('Account Holder') : undefined
          }
          required={!isPaymentCheque}
        >
          <Input placeholder='Account Name' />
        </FormItem>
        <FormItem
          label='Account Number'
          name={['vacancyApplicantAttributes', 'bankAcc']}
          required={!isPaymentCheque}
          rules={!isPaymentCheque ? rulesAccountNumber() : undefined}
        >
          <Input placeholder='Account Number' />
        </FormItem>
        <FormItem
          label='Sort Code'
          name={['vacancyApplicantAttributes', 'bankSort']}
          rules={!isPaymentCheque ? rulesSortCode() : undefined}
          required={!isPaymentCheque}
        >
          <Input placeholder='Sort Code' maxLength={16} />
        </FormItem>
        <FormItem
          label='Full Name of Bank'
          name={['vacancyApplicantAttributes', 'bank']}
          rules={!isPaymentCheque ? rulesTextInput('Full Name') : undefined}
          required={!isPaymentCheque}
        >
          <Input placeholder='Bank Name' />
        </FormItem>
        <FormItem label='Building Society No' name='employeeBuildingSocNo'>
          <Input placeholder='Building Society No' />
        </FormItem>
        <FormItem
          label='National Insuarance No'
          name={['vacancyApplicantAttributes', 'ni']}
          rules={!isPaymentCheque ? rulesNationalInsuranceNo() : undefined}
          required={!isPaymentCheque}
        >
          <Input placeholder='National Insuarance No' />
        </FormItem>
        <FormItem label='Tax Code' name='employeeTaxCode'>
          <Input placeholder='Tax Code' />
        </FormItem>
        <FormItem
          required
          label='Location'
          className='office'
          name={['vacancyApplicantAttributes', 'officeId']}
          rules={rulesSelect('location')}
        >
          <Select
            options={listLocations}
            showSearch={false}
            placeholder='Location'
          />
        </FormItem>

        <FormItem
          label='How did you hear about us?'
          className='office'
          name={['vacancyApplicantAttributes', 'hear']}
          required
          rules={rulesSelect('hear about us')}
        >
          <Select placeholder='Select option' options={listHears} />
        </FormItem>
      </div>
    </div>
  );
};

export default WorkerPayrollAndBankDetails;
