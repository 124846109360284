import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const fetchSuppliers = async () => {
  const res = await request.get('/options/suppliers');
  const data = normalizeIndexResponse(res);

  const result = data?.data.map(({ id, name, supplierTypes }) => ({
    value: id,
    label: name,
    supplierTypes,
  }));

  return result || [];
};
