import React from 'react';

import { Form, Popconfirm, message } from 'antd';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { Button } from '@/components';
import {
  deleteHour,
  updateShiftTime,
  updateStatus,
  updateTimecard,
} from '@/services/payrollTemplateService';

import { STATUS_TO_ACTION } from '../constants';

const TimecardFooter = ({
  timesheetId,
  status,
  panelInfo,
  isHourlyTemplate,
  isEdit,
  setIsEdit,
  timesheetRefetch,
  isPayroll,
}) => {
  const formInstance = Form.useFormInstance();

  const actions = STATUS_TO_ACTION[status];
  const formWatching = Form.useWatch(panelInfo.id, formInstance) ?? {};
  const isDisabled = !Object.values(formWatching ?? {}).some(
    (timecard) => timecard?.select,
  );

  const { mutate: deleteHourMutation, isLoading: deleteHourLoading } =
    useMutation(
      ({ listTimecardIds }) => deleteHour(timesheetId, listTimecardIds),
      {
        onSuccess: () => {
          message.success('Delete success');
          timesheetRefetch();
        },
      },
    );

  const { mutate: updateStatusMutation, isLoading: updateStatusLoading } =
    useMutation(
      ({ listTimecardIds, updatedStatus }) =>
        updateStatus({ timesheetId, listTimecardIds, status: updatedStatus }),
      {
        onSuccess: () => {
          message.success('Update success');
          timesheetRefetch();
        },
      },
    );

  const handleActions = ({ actionType, panelId }) => {
    const panelChecked = formInstance.getFieldsValue([panelId]);

    const { true: checkedTimecards = {} } = Object.keys(
      panelChecked[panelId] ?? {},
    ).reduce((acc, timecardId) => {
      const { select: isChecked } = panelChecked[panelId][timecardId];

      acc[isChecked] = { ...acc[isChecked] } || {};
      acc[isChecked][timecardId] = panelChecked[panelId][timecardId] || {};
      return acc;
    }, {});

    if (actionType === STATUS_TO_ACTION.deletable.actionType) {
      deleteHourMutation({ listTimecardIds: Object.keys(checkedTimecards) });
    } else {
      updateStatusMutation({
        listTimecardIds: Object.keys(checkedTimecards),
        updatedStatus: actionType,
      });
    }
  };

  const handleActionOnPanel = ({ actionType }) => {
    const timecardIds = panelInfo.timecards.map((timecard) => timecard.id);

    updateStatusMutation({
      listTimecardIds: timecardIds,
      updatedStatus: actionType,
    });
  };

  const {
    // TODO: Reopen this code if api has been done
    // mutate: updateTimecardMutation
    isLoading: isLoadingUpdateTimecard,
  } = useMutation(
    (timesheetDetail) =>
      updateTimecard({ timesheetId, panelId: panelInfo.id, timesheetDetail }),
    {
      onSuccess: () => {
        message.success('Update break time success');
        setIsEdit(false);
        timesheetRefetch();
      },
    },
  );

  const {
    mutate: updateShiftTimeMutation,
    isLoading: isLoadingUpdateShiftTime,
  } = useMutation((timesheet) => updateShiftTime({ timesheetId, timesheet }), {
    onSuccess: () => {
      message.success('Update shift time success');
      setIsEdit(false);
      timesheetRefetch();
    },
    onError: (errors) => {
      message.error(errors.message);
    },
  });

  const handleCancel = () => {
    setIsEdit(false);
    formInstance.resetFields();
  };

  const handleAction = () => {
    if (isHourlyTemplate) {
      handleActions({
        actionType: actions.actionType,
        panelId: panelInfo.id,
      });
    } else {
      handleActionOnPanel({
        actionType: actions.actionType,
        panelInfo,
      });
    }
  };

  const updateCheckinCheckout = (payrollTemplate) =>
    (payrollTemplate.shiftTime ?? []).map((shift, index) => ({
      checktype: index === 0 ? 'checkin' : 'checkout',
      createdAt: moment(shift).toISOString(),
    }));

  const updatePayrollTemplate = (payrollTemplateHours, timecardId) =>
    payrollTemplateHours.reduce((total, payrollTemplate) => {
      if (isNil(payrollTemplate.id)) {
        return total;
      }
      const { isOverTime } = payrollTemplate;
      const updatedPayrollTemplateHrs = {
        id: payrollTemplate.id,
        timecardId: Number(timecardId),
        chargeRate: isOverTime ? 0 : Number(payrollTemplate.chargeRate),
        payRate: isOverTime ? 0 : Number(payrollTemplate.payRate),
        otChargeRate: isOverTime ? Number(payrollTemplate.otChargeRate) : 0,
        otPayRate: isOverTime ? Number(payrollTemplate.otPayRate) : 0,
        breakDuration: Number(payrollTemplate.breakDuration),
      };
      const checkinCheckoutsAttributes = updateCheckinCheckout(payrollTemplate);

      if (!isEmpty(total.payrollTemplateHoursAttributes)) {
        if (isEmpty(checkinCheckoutsAttributes)) {
          return {
            ...total,
            payrollTemplateHoursAttributes: [
              ...total.payrollTemplateHoursAttributes,
              updatedPayrollTemplateHrs,
            ],
          };
        }
        return {
          ...total,
          payrollTemplateHoursAttributes: [
            ...total.payrollTemplateHoursAttributes,
            updatedPayrollTemplateHrs,
          ],
          timecardsAttributes: [
            ...total.timecardsAttributes,
            { id: Number(timecardId), checkinCheckoutsAttributes },
          ],
        };
      }

      return {
        ...total,
        payrollTemplateHoursAttributes: [updatedPayrollTemplateHrs],
        ...(isEmpty(checkinCheckoutsAttributes)
          ? { timecardsAttributes: [] }
          : {
              timecardsAttributes: [
                { id: Number(timecardId), checkinCheckoutsAttributes },
              ],
            }),
      };
    }, {});

  const handleUpdate = () => {
    if (isDisabled) {
      message.error('Please select timecard to update!');
      return;
    }

    const panelChecked = formInstance.getFieldsValue([panelInfo.id]);

    const updateTimesheet = Object.keys(
      panelChecked[panelInfo.id] ?? {},
    ).reduce((acc, timecardId) => {
      const { select: isChecked } = panelChecked[panelInfo.id][timecardId];

      if (isChecked) {
        const payrollTemplateHours = Object.values(
          panelChecked[panelInfo.id][timecardId],
        );

        const updatedPayrollTemplates = updatePayrollTemplate(
          payrollTemplateHours,
          timecardId,
        );

        return {
          ...acc,
          payrollTemplateHoursAttributes: [
            ...(acc?.payrollTemplateHoursAttributes ?? []),
            ...updatedPayrollTemplates.payrollTemplateHoursAttributes,
          ],
          timecardsAttributes: [
            ...(acc?.timecardsAttributes ?? []),
            ...updatedPayrollTemplates.timecardsAttributes,
          ],
        };
      }
      return acc;
    }, {});

    const updatedShiftTimeRes = {
      timesheet: {
        id: panelInfo.id,
        timecardsAttributes: updateTimesheet.timecardsAttributes,
      },
    };

    // TODO: Reopen this code if api has been done
    // const updatedRes = {
    //   timesheetDetail: {
    //     payrollTemplateHoursAttributes:
    //       updateTimesheet.payrollTemplateHoursAttributes,
    //   },
    // };

    updateShiftTimeMutation(updatedShiftTimeRes);
    // updateTimecardMutation(updatedRes);
  };
  const enableFinalise =
    (status === 'confirmed' && isPayroll) || status !== 'confirmed';

  return (
    <div className='actions'>
      {!isEmpty(actions) && enableFinalise && (
        <>
          {actions.actionType && (
            <>
              <Popconfirm
                title={`Do you want to ${actions.label}?`}
                onConfirm={handleAction}
              >
                <Button
                  className={`actions-btn ${actions.className}`}
                  loading={updateStatusLoading}
                  disabled={isDisabled && isHourlyTemplate}
                >
                  {actions.label}
                </Button>
              </Popconfirm>
              {!isEdit && isHourlyTemplate && (
                <Button
                  className={`actions-btn ${STATUS_TO_ACTION.editable.className}`}
                  onClick={() => setIsEdit(true)}
                >
                  {STATUS_TO_ACTION.editable.label}
                </Button>
              )}
              {isEdit && isHourlyTemplate && (
                <Popconfirm
                  title='Do you want to Delete?'
                  onConfirm={() =>
                    handleActions({
                      actionType: STATUS_TO_ACTION.deletable.actionType,
                      panelId: panelInfo.id,
                    })
                  }
                >
                  <Button
                    className={classNames('actions-btn', {
                      [STATUS_TO_ACTION.deletable.className]: !isDisabled,
                    })}
                    disabled={isDisabled}
                    loading={deleteHourLoading}
                  >
                    {STATUS_TO_ACTION.deletable.label}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
          {isEdit && isHourlyTemplate && (
            <>
              <Button
                className={`actions-btn ${STATUS_TO_ACTION.cancel.className}`}
                onClick={handleCancel}
              >
                {STATUS_TO_ACTION.cancel.label}
              </Button>
              <Popconfirm
                title='Do you want to Update?'
                onConfirm={handleUpdate}
              >
                <Button
                  loading={isLoadingUpdateTimecard || isLoadingUpdateShiftTime}
                  className={`actions-btn ${STATUS_TO_ACTION.save.className}`}
                >
                  Save
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TimecardFooter;

TimecardFooter.propTypes = {
  timesheetId: PropTypes.number || PropTypes.string,
  status: PropTypes.string,
  panelInfo: PropTypes.object,
  isHourlyTemplate: PropTypes.bool,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  timesheetRefetch: PropTypes.func,
  isPayroll: PropTypes.bool,
};

TimecardFooter.defaultProps = {
  status: '',
  panelInfo: {},
  isHourlyTemplate: false,
  isEdit: false,
  setIsEdit: () => {},
  timesheetRefetch: () => {},
};
