import { isEmpty } from 'lodash';

import { ORDER_BY, RESOURCE_TYPE } from '@/constants';
import request from '@/request';
import { array, date } from '@/utils';
import Formatter from '@/utils/Formatter';
import { formatResponse } from '@/utils/thunkUtil';

import { getAddressInfomation } from '../getAddressInfomation';

const STATUS = {
  active: 'Activated',
  inactivate: 'Deactivated',
};

const getUndefinedOrValue = (data) =>
  isEmpty(String(data)) ? undefined : data;

const getAll = async ({
  size,
  radius,
  number,
  statuses,
  jobRoles,
  sortOrder,
  locations,
  searchTerm,
  industries,
  generalRole,
  providerName,
  registrationDateTo,
  registrationDateFrom,
  shiftsPostedDateFrom,
  shiftsPostedDateTo,
  zip,
}) => {
  const page = {
    size: getUndefinedOrValue(size),
    number: getUndefinedOrValue(number),
  };

  const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

  const filter = {
    status: array.remove(statuses, 'all'),
    id: array.remove(providerName, 'all'),
    roleIds: array.remove(jobRoles, 'all'),
    officeId: array.remove(locations, 'all'),
    industryIds: array.remove(industries, 'all'),
    managerIds: array.remove(generalRole, 'all'),
    searchTerm: getUndefinedOrValue(searchTerm),
    shiftPostedTo: date.convertDateTime(shiftsPostedDateTo),
    shiftPostedFrom: date.convertDateTime(shiftsPostedDateFrom),
    registrationDateTo: date.convertDateTime(registrationDateTo),
    registrationDateFrom: date.convertDateTime(registrationDateFrom),
    latitude: geogecode?.[0]?.geometry?.location?.lat,
    longitude: geogecode?.[0]?.geometry?.location?.lng,
    postcode: geogecode?.[0]?.address_components?.[0]?.long_name,
    radius,
  };

  const order = {
    orderBy: ORDER_BY,
    orderDirection: sortOrder,
  };

  const endpoint = '/providers/employers';
  const res = await request.get(endpoint, {
    params: {
      page,
      order,
      filter,
    },
  });

  const responseFormatted = formatResponse(res, { endpoint });

  const { data: dataBuildEndpoint } = Formatter.buildEndpoint(
    endpoint,
    responseFormatted,
    RESOURCE_TYPE.providers,
  );

  const { totalCount, totalPage, currentPage } = Formatter.mergeMeta(
    responseFormatted.omni_employee,
    endpoint,
    responseFormatted.meta,
  )[endpoint];

  const providers = dataBuildEndpoint.map(
    ({
      id,
      name,
      baCity,
      industry,
      createdAt,
      emailUser,
      contactNameUser,
      nameOmniUser,
      baPhone,
      firstPostedShift,
      lastPostedShift,
      status,
    }) => ({
      key: id,
      city: baCity,
      number: id,
      clientName: name,
      phoneNumber: baPhone,
      emailAddress: emailUser,
      contactName: contactNameUser,
      industry: industry?.join(', '),
      allocatedAccountManager: nameOmniUser,
      applicationDate: date.convertDateTime(createdAt),
      firstPostedShift: date.convertDateTime(firstPostedShift),
      lastPostedShift: date.convertDateTime(lastPostedShift),
      status: STATUS[status],
    }),
  );

  return {
    providers,
    totalCount,
    totalPage,
    currentPage,
  };
};

export default getAll;
