import React, { useState } from 'react';

import { Form, Spin, Row } from 'antd';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  ModalConfirm,
  ErrorsAlert,
  GeneralTemplate,
} from '@/components';
import { RECRUITMENT_VIEW } from '@/constants';
import { editApplicant } from '@/services/applicantService/edit';
import { getApplicantID } from '@/services/applicantService/get';
import { forcePreferredRoles } from '@/utils/forcePreferredRoles';
import handleUploadFile from '@/utils/upLoadFiles';

import ApplicantContact from '../applicantContact';
import ApplicantExperience from '../applicantExp';
import ApplicantRole from '../applicantRole';
import '../styles.scss';

const EditApplicant = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  const [isShowModal, setShowModal] = useState(false);
  const [isShowModalCancel, setShowModalCancel] = useState(false);

  const [isShowErr, setShowErr] = useState(false);
  const [listErrorDetails, setListErrorDetails] = useState([]);

  const breadcrumb = [
    { item: 'Applicant', to: `/recruitment/${RECRUITMENT_VIEW.APPLICATION}` },
    {
      item: 'Edit/View Applicant',
      to: `/edit-applicant/${id}`,
    },
  ];

  const { data: dataApplicant, isFetching } = useQuery(
    ['getApplicantID', id],
    // eslint-disable-next-line consistent-return
    () => {
      if (id) {
        return getApplicantID(id);
      }
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        const { applicantDate, preferredRoles, cvUpload, ...resData } = data;

        const mapPreferredRoles = forcePreferredRoles(preferredRoles);

        form.setFieldsValue({
          applicantDate: moment(applicantDate),
          preferredRoles: mapPreferredRoles,
          candidateUrl: `${window.location.hostname}/recruitment-form/${id}`,
          ...resData,
        });
      },
    },
  );

  const {
    mutate: editApplicantID,
    isLoading,
    isSuccess,
    reset,
  } = useMutation(
    async (newApplicant) => {
      const listFileUpload = await handleUploadFile(form);
      return editApplicant(
        newApplicant,
        listFileUpload,
        dataApplicant?.idLeave,
        id,
      );
    },
    {
      onError: (err) => {
        setShowModal(false);
        const { status, data: dataError } = err;

        if (status) {
          const listErrors = dataError.errors.reduce(
            (acc, cur) => [...acc, cur.detail],
            [],
          );

          setListErrorDetails(listErrors);
          setShowErr(true);
          setShowModal(false);
          window.scrollTo(0, 0);
        }
      },
    },
  );

  const onFinish = () => {
    setShowModal(true);
  };

  const handleConfirm = async () => {
    const formValue = form.getFieldsValue();
    editApplicantID(formValue);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };

  return (
    <GeneralTemplate
      mainItem='Recruitment'
      headerText='Edit/View Applicant'
      hasButton={false}
      data={breadcrumb}
      subTemplateClassName='applicant-form'
    >
      {isFetching ? (
        <Row align='center'>
          <Spin />
        </Row>
      ) : (
        <Form
          scrollToFirstError={{
            skipOverflowHiddenElements: true,
            block: 'center',
          }}
          onFinish={onFinish}
          className='applicant-form'
          form={form}
        >
          <div className='applicant-body'>
            {isShowErr && (
              <ErrorsAlert
                message='Oops! - Something went wrong'
                details={listErrorDetails}
                closable
                afterClose={() => setShowErr(false)}
              />
            )}
            <ApplicantContact />
            <ApplicantRole />
            <ApplicantExperience urlCv={dataApplicant?.cvUpload} />
            <div className='applicant-footer'>
              <Button
                className='btn-cancel'
                onClick={() => setShowModalCancel(true)}
              >
                Cancel
              </Button>
              <Button className='btn-save' htmlType='submit'>
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
      <ModalConfirm
        title='Save Updates?'
        titleSuccess='Applicant updated successfully'
        visible={isShowModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleCloseModal : handleConfirm}
        onClickNo={handleCloseModal}
      >
        Are you sure you want to save the updates made to the profile?
      </ModalConfirm>
      <ModalConfirm
        title="Don't Want to Save Updates?"
        visible={isShowModalCancel}
        onClickYes={() => history.push('/recruitment/application')}
        onClickNo={() => setShowModalCancel(false)}
      >
        Are you sure you want to leave without saving? You will lost any uploads
        and changes you have made.
      </ModalConfirm>
    </GeneralTemplate>
  );
};
export default EditApplicant;
