import request from '@/request';

const saveInvoice = async (invoice) => {
  const { invoiceId } = invoice;

  const endpoint = `pay_templates/invoices/${invoiceId}`;

  const res = await request.put(endpoint, { invoice });
  return res?.data?.data;
};
export default saveInvoice;
