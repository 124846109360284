import normalize from 'json-api-normalizer';
import moment from 'moment';

import { DATA_PAYPMENT_REPORT_KEYS, DATA_PROTECTION_KEYS } from '@/constants';
import request from '@/request';

const createResponseObject = ({ dataFormated, providerId }) => {
  const {
    chargeRateProperty,
    comment,
    contact,
    employer,
    chargeRate,
    employerIndustry,
  } = dataFormated;

  const attrs = employer[`${providerId}`]?.attributes || {};

  const paymentReport = [];
  const dataProtection = [];
  const contactForAccounts = [];
  const contactForBookings = [];
  const contactForInvoices = [];
  const contactForTimesheets = [];
  let contactUser = { id: null, email: null, name: null, phone: null };
  let contactEmployer = { id: null, email: null, name: null, phone: null };

  const {
    card,
    hear,
    name,
    baCity,
    baStreet,
    abbrvName,
    creditCard,
    baBuilding,
    baPostcode,
    vatNumber,
    companyNumber,
    clientNumber,
    createdAt,
    industry,
    officeId,
    showAwr,
    locations,
    payWeekend,
    postingAlert,
    isVerifiedReadTerms,
    isVerifiedStoreAndUse,
    isAcceptedReceivingUpdates,
    status,
  } = attrs;

  if (showAwr) {
    paymentReport.push(DATA_PAYPMENT_REPORT_KEYS.SHOW_AWR_REPORT);
  }

  if (payWeekend) {
    paymentReport.push(
      DATA_PAYPMENT_REPORT_KEYS.SUNDAY_TO_SATURDAY_PAYMENT_WEEK,
    );
  }

  if (isVerifiedReadTerms) {
    dataProtection.push(
      DATA_PROTECTION_KEYS.VERIFIED_READ_TERMS_AND_CONDITIONS,
    );
  }

  if (isVerifiedStoreAndUse) {
    dataProtection.push(DATA_PROTECTION_KEYS.VERIFIED_STORE_AND_USE);
  }

  if (isAcceptedReceivingUpdates) {
    dataProtection.push(DATA_PROTECTION_KEYS.ACCEPTED_RECIEVING_UPDATES);
  }

  Object.values(contact || {}).forEach(({ attributes, id }) => {
    switch (`${attributes.type}`) {
      case 'user':
        contactUser = { ...attributes, id };
        break;
      case 'omni_user':
        contactEmployer = { ...attributes, id };
        break;
      case 'account':
        contactForAccounts.push({ ...attributes, id });
        break;
      case 'invoice':
        contactForInvoices.push({ ...attributes, id });
        break;
      case 'booking':
        contactForBookings.push({ ...attributes, id });
        break;
      case 'timesheet':
        contactForTimesheets.push({ ...attributes, id });
        break;
      default:
        break;
    }
  });

  const chargeRatesAttributes = Object.values(chargeRate || {}).map(
    ({ attributes, id }) => ({
      id,
      ...attributes,
      position: attributes.skill_id,
    }),
  );

  const chargeRateId = Object.values(chargeRateProperty || {}).at(0)?.id;

  const {
    otherCosts = 0,
    marginFixed = 0,
    marginPercent = 0,
    pensionAndApprentice = 0,
    // marginIsCostType = 'percent', // TODO: Update later.
  } = Object.values(chargeRateProperty || {})
    // normalize create this property to an array with length is one
    .at(0)?.attributes || {
    // marginIsCostType: 'percent',
    otherCosts: 0,
    marginFixed: 0,
    marginPercent: 0,
    pensionAndApprentice: 0,
  };

  const locationsConverted = (locations || []).map(
    ({
      id,
      name: locationName,
      city,
      street,
      building: buildingNumber,
      postcode: postCode,
    }) => ({
      id,
      locationName,
      city,
      street,
      buildingNumber,
      postCode,
    }),
  );

  const historyComments = Object.values(comment || {}).map(
    ({ id, attributes: { content, userId, createdAt: commentCreatedAt } }) => ({
      id,
      key: id,
      content,
      userId,
      createdAt: commentCreatedAt,
    }),
  );

  const provider = {
    // Credit card details
    creditCard,

    // Company information
    city: baCity,
    street: baStreet,
    postCode: baPostcode,
    building: baBuilding,
    providerName: name,
    vatNumber,
    registeredCompanyName: abbrvName,
    companyNumber,
    clientNumber,
    dateOfRegistration: moment(createdAt),

    // Contact Information
    contactId: contactUser.id,
    contactName: contactUser.name,
    contactEmail: contactUser.email,
    contactPhone: contactUser.phone,
    accountManagerId: contactEmployer.id, // this is contact `id`.
    accountManagerName: +contactEmployer.name, // property `name` is `id` of `manager`
    accountManagerPhone: contactEmployer.phone,
    accountManagerSecondaryManagerEmail: contactEmployer.email,
    contactForAccounts,
    contactForBookings,
    contactForInvoices,
    contactForTimesheets,

    // Rates
    chargeRatesAttributes,
    chargeRateId,
    otherCosts: String(otherCosts),
    marginFixed: String(marginFixed),
    marginPercent: String(marginPercent),
    pensionAndApprentice: String(pensionAndApprentice),

    // Bank And Official Information
    postingAlerts: postingAlert,
    paymentReport,

    // Locations
    aboutUs: hear,
    industries: industry,
    industriesHidden: employerIndustry,
    locations: locationsConverted,

    // Comments And Status Updates
    dataProtection,
    historyComments,
    offices: officeId,

    status,
  };

  const { name: nameOnCard, number, expMonth, expYear, cvc } = card;
  if (nameOnCard && expYear && expMonth) {
    const expiryDate = `${expMonth}/${expYear}`;

    provider.CVV = cvc;
    provider.nameOnCard = nameOnCard;
    provider.expiryDate = expiryDate;
    provider.longCardNumber = number;

    provider.oldCard = JSON.stringify({
      CVV: cvc,
      nameOnCard,
      expiryDate,
      longCardNumber: number,
    });
  }

  return provider;
};

/**
 * @function deactivate
 * @param {string | number} providerId
 * @returns
 */
const deactivate = async (providerId) => {
  const { data } = await request.patch(
    `/providers/employers/${providerId}/update_status`,
    {
      provider: {
        status: 'blocked',
      },
    },
  );

  const dataFormated = normalize(data);
  return createResponseObject({ dataFormated, providerId });
};

export default deactivate;
