import moment from 'moment';

import {
  REGEX_ACCOUNT_NUMBER,
  REGEX_PHONE_NUMBER,
  REGEX_SORT_CODE,
  REGEX_NATIONAL_INSURANCE_NO,
  LIMITED_AGE,
  REGEX_VAT_NUMBER,
  REGEX_COMPANY_REGISTRATION_NUMBER,
  REGEX_EMAIL,
  TRAINING,
} from '@/constants';
import { getAddressInfomation } from '@/services/getAddressInfomation';
import { validateApplication, validateProvider } from '@/services/guestService';

const rulesAcceptPrivacyPolicy = (rule, value) => {
  if (!value) {
    return Promise.reject(new Error('Please accept the terms & conditions'));
  }
  return Promise.resolve();
};

const rulesTextInput = (/** @type {string} */ fieldName) => {
  const rules = [
    {
      required: true,
      message: `Please enter ${fieldName}`,
    },
    { whitespace: true, message: `${fieldName} can not be empty` },
  ];

  return rules;
};

const rulesRepeatPassword = (password) => {
  const rules = [
    {
      validator: (_, value) =>
        password !== value ? Promise.reject() : Promise.resolve(),
      message: 'Repeat password does not match password!',
    },
  ];

  return rules;
};

const rulesTextInputMin = (min) => {
  const rules = [
    {
      validator: (_, value) => {
        const stringLength = value.length;

        return stringLength < min ? Promise.reject() : Promise.resolve();
      },
      message: 'Please input at least 20 characters',
    },
  ];

  return rules;
};

const rulesDescription = () => [
  {
    required: true,
    message: 'Please input your description',
  },
  {
    whitespace: true,
    message: 'Job decription can not be empty',
  },
  rulesTextareaMin100Character(),
];

const rulesTextareaMin100Character = () => ({
  validator: (_, value) => {
    const valueTrim = value?.trim();

    if (valueTrim?.length >= 100) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  message: 'Job decription must be at least 100 characters.',
});

const rulesNumberInput = (/** @type {string} */ fieldName) => {
  const rules = [
    {
      required: true,
      message: `Please enter ${fieldName}`,
    },
    {
      pattern:
        // eslint-disable-next-line max-len
        /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/,
      message: 'Should be a number',
    },
  ];

  return rules;
};

const rulesFloatNumberInput = (/** @type {string} */ fieldName) => {
  const rules = [
    {
      required: true,
      message: `Please enter ${fieldName}`,
    },
    {
      pattern: /[+-]?([0-9]*[.])?[0-9]+/,
      message: 'Should be a float number',
    },
  ];

  return rules;
};

const rulesAccountNumber = () => {
  const rules = [
    { required: true, message: 'Please enter Account Number' },
    {
      pattern: REGEX_ACCOUNT_NUMBER,
      message: 'Incorrect Account Number',
    },
  ];

  return rules;
};

const rulesSortCode = () => {
  const rules = [
    { required: true, message: 'Please enter Sort Code' },
    {
      pattern: REGEX_SORT_CODE,
      message: 'Enter only 6 digits sort code',
    },
    {
      max: 16,
      message: 'sort code must be smaller than 16',
    },
  ];

  return rules;
};

const rulesNationalInsuranceNo = () => {
  const rules = [
    {
      required: true,
      message: 'Please enter National Insurance No',
    },
    {
      pattern: REGEX_NATIONAL_INSURANCE_NO,
      message: 'Incorrect National Insurance No',
    },
  ];

  return rules;
};

/**
 * @param {boolean} required
 * @param {string} fieldName
 * @return {array}
 */
const rulesEmail = (required = true, fieldName = 'Email') => {
  const rules = [
    {
      required,
      message: `Please enter ${fieldName}`,
    },
    { type: 'email', message: 'Please enter a valid email' },
  ];
  return rules;
};

const rulesValidateEmail = (id, required = true) => {
  const rules = [
    {
      validator: async (_, value) => {
        if (value) {
          if (!REGEX_EMAIL.test(value.toString())) {
            return Promise.reject(new Error('Please enter a valid email'));
          }

          try {
            const valid = await validateApplication({ id, email: value });

            return valid
              ? Promise.resolve()
              : Promise.reject(new Error('Email is already taken'));
          } catch (error) {
            return Promise.reject(new Error('Please enter Email'));
          }
        }

        return required
          ? Promise.reject(new Error('Please enter Email'))
          : Promise.resolve();
      },
    },
  ];

  return rules;
};

const rulesValidateProviderEmail = (id, required = true) => {
  const rules = [
    {
      validator: async (_, value) => {
        if (value) {
          if (!REGEX_EMAIL.test(value.toString())) {
            return Promise.reject(new Error('Please enter a valid email'));
          }

          try {
            const valid = await validateProvider({ id, email: value });

            return valid
              ? Promise.resolve()
              : Promise.reject(new Error('Email is already taken'));
          } catch (error) {
            return Promise.reject(new Error('Please enter Email'));
          }
        }

        return required
          ? Promise.reject(new Error('Please enter Email'))
          : Promise.resolve();
      },
    },
  ];

  return rules;
};

const rulesSelect = (
  /** @type {string} */ fieldName,
  /** @type {boolean} */ visible = true,
) => {
  const rules = [
    {
      required: true,
      message: `Please choose ${fieldName}`,
    },
  ];

  return visible ? rules : [];
};

const rulesSelectHearAboutUs = (fieldName) => {
  const rules = [
    {
      required: true,
      message: 'Please let us know how did you hear about us',
    },
  ];

  return rules;
};
const rulesBirth = [
  {
    required: true,
    message: 'Please choose Date of Birth',
  },
  () => ({
    validator(_, value) {
      if (value) {
        const selectedYear = moment(value).year();
        const crrYear = moment().year();
        const age = crrYear - selectedYear;

        if (age < LIMITED_AGE) {
          return Promise.reject(
            new Error('Please select the age of 16 or above'),
          );
        }
      }

      return Promise.resolve();
    },
  }),
];

const rulesPhone = (required = true) => {
  const rules = [
    {
      validator: (_, value) => {
        if (value) {
          if (value.charAt(0) !== '0') {
            return Promise.reject(
              new Error('Phone numbers must be starting with 0'),
            );
          }

          if (!REGEX_PHONE_NUMBER.test(value.toString())) {
            return Promise.reject(new Error('Please enter 11 digits'));
          }
          return Promise.resolve();
        }

        return required
          ? Promise.reject(new Error('Please enter Phone Number'))
          : Promise.resolve();
      },
    },
  ];

  return rules;
};

const rulesValidatePhone = (id, required = true) => {
  const rules = [
    {
      validator: async (_, value) => {
        if (value) {
          if (value.charAt(0) !== '0') {
            return Promise.reject(
              new Error('Phone numbers must be starting with 0'),
            );
          }

          if (!REGEX_PHONE_NUMBER.test(value.toString())) {
            return Promise.reject(new Error('Please enter 11 digits'));
          }

          try {
            const valid = await validateApplication({ id, phone: value });

            return valid
              ? Promise.resolve()
              : Promise.reject(new Error('Phone number is already taken'));
          } catch (error) {
            return Promise.reject(new Error('Please enter Phone Number'));
          }
        }

        return required
          ? Promise.reject(new Error('Please enter Phone Number'))
          : Promise.resolve();
      },
    },
  ];

  return rules;
};

const rulesValidateProviderPhone = (id, required = true) => {
  const rules = [
    {
      validator: async (_, value) => {
        if (value) {
          if (value.charAt(0) !== '0') {
            return Promise.reject(
              new Error('Phone numbers must be starting with 0'),
            );
          }

          if (!REGEX_PHONE_NUMBER.test(value.toString())) {
            return Promise.reject(new Error('Please enter 11 digits'));
          }

          try {
            const valid = await validateProvider({ id, phone: value });

            return valid
              ? Promise.resolve()
              : Promise.reject(new Error('Phone number is already taken'));
          } catch (error) {
            return Promise.reject(new Error('Please enter Phone Number'));
          }
        }

        return required
          ? Promise.reject(new Error('Please enter Phone Number'))
          : Promise.resolve();
      },
    },
  ];

  return rules;
};

const rulesPostcode = (formInstance, name) => {
  const rules = [
    {
      validator: async (_, value) => {
        if (!value || value.trim().length === 0) {
          return Promise.reject(new Error('Please enter a valid Post Code'));
        }

        try {
          const geogecode = await getAddressInfomation(`${value}-uk`);

          const addressComponent = geogecode[0].address_components;

          const address = addressComponent.filter(
            (item) =>
              item.long_name.replace(/ /g, '').toLowerCase() ===
                value.replace(/ /g, '').toLowerCase() &&
              item.types.indexOf('postal_code') !== -1,
          );

          const validPostcode = address?.[0].long_name;

          if (validPostcode) {
            formInstance.setFields([{ name, value: validPostcode }]);
          }

          return address.length === 0 ? Promise.reject() : Promise.resolve();
        } catch (error) {
          return Promise.reject();
        }
      },
      message: 'Please enter a valid Post Code',
    },
  ];

  return rules;
};

const rulesVatNumber = () => [
  {
    required: true,
    message: 'Please enter VAT Number',
  },
  {
    pattern: REGEX_VAT_NUMBER,
    message: 'Please enter 9 numbers',
  },
  // TODO: @Harvey Will add validation VAT number can not be empty
];
const rulesCompanyNumber = () => [
  {
    required: true,
    message: 'Please enter Company Number',
  },
  {
    whitespace: true,
    message: 'Company Number can not be empty',
  },
  {
    pattern: REGEX_COMPANY_REGISTRATION_NUMBER,
    message: 'Please enter 8 numbers',
  },
];

const payRateRules = (conditionValue, minPayrate) =>
  conditionValue === TRAINING
    ? rulesNumberInput('Pay Rate')
    : [
        rulesNumberInput('Pay Rate'),
        {
          validator: (_, valueNumber) =>
            Number(valueNumber) < Number(minPayrate)
              ? Promise.reject(
                  new Error(
                    `Please enter the number greater then  ${minPayrate}`,
                  ),
                )
              : Promise.resolve(),
        },
      ];

/**
 * @param {string|Array<string>} name
 * @returns {import('antd/lib/form').Rule[]}
 */
function rulesConfirmPassword(name) {
  return [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(name) === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('The new password that you entered do not match!'),
        );
      },
    }),
  ];
}
export {
  payRateRules,
  rulesAcceptPrivacyPolicy,
  rulesAccountNumber,
  rulesBirth,
  rulesCompanyNumber,
  rulesConfirmPassword,
  rulesDescription,
  rulesEmail,
  rulesFloatNumberInput,
  rulesNationalInsuranceNo,
  rulesNumberInput,
  rulesPhone,
  rulesPostcode,
  rulesRepeatPassword,
  rulesSelect,
  rulesSelectHearAboutUs,
  rulesSortCode,
  rulesTextInput,
  rulesTextInputMin,
  rulesTextareaMin100Character,
  rulesValidateEmail,
  rulesValidatePhone,
  rulesValidateProviderEmail,
  rulesValidateProviderPhone,
  rulesVatNumber,
};
