import moment from 'moment';

const LIST_YEAR = [];
for (let i = moment().year(); i >= 2006; i -= 1) {
  LIST_YEAR.push({
    label: i,
    value: i,
  });
}

const VIEW_SEND_PRINTEDS = {
  Printed: 'Sent',
  default: '',
};

const EXPORTEDS = {
  Exported: 'Exported',
  default: '',
};

const DAYS_OF_WEEK = {
  mon: 'mon',
  tue: 'tues',
  wed: 'wed',
  thu: 'thurs',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun',
};

const INVOICE_TYPES = {
  payTemplate: {
    key: 'payTemplate',
    label: 'Pay Template',
  },
  flexiblePayRate: {
    key: 'flexiblePayRate',
    label: 'Flexible Pay Rate',
  },
};

export {
  LIST_YEAR,
  VIEW_SEND_PRINTEDS,
  EXPORTEDS,
  DAYS_OF_WEEK,
  INVOICE_TYPES,
};
