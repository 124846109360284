import { useQuery } from 'react-query';

import { getEmployerLocations } from '@/services/dropdownServices/getEmployerLocations';

const useEmployerLocations = (/** @type {number[]} */ providerIds = []) => {
  const { data } = useQuery(
    ['getEmployerLocations', providerIds],
    () => getEmployerLocations(providerIds),
    {
      refetchOnWindowFocus: false,
    },
  );

  return data ?? [];
};

export default useEmployerLocations;
