import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
import { TenantProvider } from '@/TenantWrapper';

import '@/assets/scss/vars.scss';

const queryClient = new QueryClient();
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <TenantProvider>
      <App />
    </TenantProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
