import { range, round, toNumber } from 'lodash';
import moment from 'moment';

import { DATE_FORMAT } from '@/constants';

export const convertMinToHour = (mins = 0) => {
  if (isNaN(mins)) {
    mins = 0;
  }

  const h = mins > 0 ? Math.floor(mins / 60) : Math.ceil(mins / 60);
  const m = round(mins % 60);

  return moment(`${h}:${m}`, 'HH:mm');
};

export const convertTimeToHours = (value) => {
  const totalMins = moment.duration(value).asMinutes();
  const { _i } = convertMinToHour(totalMins);
  return _i;
};

export const stringToHoursAndMins = (/** @type {string} */ str) => {
  const [hours, minutes] = str.split(':').map((item) => toNumber(item));
  return {
    hours,
    minutes,
  };
};

export const extractHours = ({ hours, minutes }) =>
  hours + parseFloat(minutes) / 60;

export const formatISOString = (timeString = '') =>
  timeString ? moment(timeString, 'HH:mm').toISOString() : '';

export const formatISORangePicker = (timeRange) =>
  timeRange &&
  timeRange.map((shiftTime) => {
    const timeString = shiftTime.format('HH:mm');
    return formatISOString(timeString);
  });

export const convertTimeValue = ({ hrs = 0, mins = 0 }) => hrs + mins / 60;

export const parseTime = (timeString) => moment(timeString, 'HH:mm');

export const formatMinutes = (minutes) => String(minutes).padStart(2, '0');

export const convertTimeHoursCell = (hoursValue) => {
  const mins = moment.duration(Number(hoursValue), 'hours').asMinutes();

  const h = mins > 0 ? Math.floor(mins / 60) : Math.ceil(mins / 60);
  const m = formatMinutes(round(mins % 60));

  return `${h}.${m}`;
};

export const convertHoursToMin = (hours = 0) => {
  const duration = moment.duration(hours, 'hours');
  const minutes = duration.asMinutes();
  return minutes;
};

/**
 * @param {number} mins param mins must number type
 * @returns {number} return hours as type of number
 */
export const convertMinstoHours = (mins) => {
  const duration = moment.duration(mins, 'minutes');
  const hours = duration.asHours();
  return hours;
};

const MONDAY_INDEX = 0;
const SUNDAY_INDEX = 7;
export const formatDate = (date, format = DATE_FORMAT) =>
  moment(date).format(format);

export const getDatesFromMonToSun = ({ date }) => {
  const startDate = date.clone().startOf('isoWeek');
  return range(MONDAY_INDEX, SUNDAY_INDEX).map((day) => {
    const dateTime = startDate.clone().add(day, 'days');
    return {
      dateString: dateTime.format('YYYY-MM-DD'),
      dayOfWeek: dateTime.format('dd'),
      momentTime: dateTime.clone(),
      dateNumber: dateTime.date(),
    };
  });
};
export const durationBetweenTimes = ({ startTime, endTime }) =>
  moment.duration(moment(endTime).diff(moment(startTime))).asHours();
