/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import under18 from '@/assets/icons/under18.png';
import { Select } from '@/components';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import './styles.scss';

/**
 * @typedef {{
 * infoDetail: Array;
 * onRow: Function;
 * handleSort: Function;
 * options:Array;
 * onChange: Function;
 * headerColumns: Array;
 * isCandidate?: Boolean;
 * children: React.ReactNode;
 * footer: Function;
 * }} AdditionalProps
 */
/**
 * Renders a list view component with the given data and options.
 *
 * @param {import ('antd').TableProps & AdditionalProps} props

 */
const ListView = ({
  infoDetail,
  onRow,
  handleSort,
  options,
  onChange,
  headerColumns,
  className,
  isCandidate,
  children,
  footer,
  ...props
}) => {
  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const dataRender = (data, dataShow) =>
    !data || data === 'null' ? '' : dataShow;

  const columns = [
    {
      title: null,
      width: '5%',
      hidden: !isCandidate,
      render: (record) => {
        const { isOver18 } = record;
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {!isOver18 && (
              <img
                className='favourite-icon seeker-icon'
                src={under18}
                alt=''
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Number',
      width: '6%',
      render: (dt) => dataRender(dt, dt),
    },
    {
      title: 'Full Name',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (/** @type {string} */ name) =>
        dataRender(
          name,
          <Tooltip placement='topLeft' title={name}>
            {name}
          </Tooltip>,
        ),
    },
    {
      title: 'Email Address',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (/** @type {string} */ email) =>
        dataRender(
          email,
          <Tooltip placement='topLeft' title={email}>
            {email}
          </Tooltip>,
        ),
    },
    {
      title: 'Phone Number',
      width: '10%',
      render: (dt) =>
        dataRender(
          dt,
          <Tooltip placement='topLeft' title={dt}>
            {dt}
          </Tooltip>,
        ),
    },
    {
      title: 'Postcode',
      width: '7%',
      render: (dt) =>
        dataRender(
          dt,
          <Tooltip placement='topLeft' title={dt}>
            {dt}
          </Tooltip>,
        ),
    },
    {
      title: 'Specific Role Applied ',
      ellipsis: {
        showTitle: false,
      },
      render: (/** @type {string} */ originalRoles) =>
        dataRender(
          originalRoles,
          <Tooltip placement='topLeft' title={originalRoles}>
            {originalRoles}
          </Tooltip>,
        ),
    },
    {
      title: 'Other Roles',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (/** @type {string} */ otherRoles) =>
        dataRender(
          otherRoles,
          <Tooltip placement='topLeft' title={otherRoles}>
            {otherRoles}
          </Tooltip>,
        ),
    },
    {
      title: 'Registration Date',
      sorter: true,
      render: (dt) =>
        dataRender(
          dt,
          <Tooltip placement='topLeft' title={dt}>
            {dt}
          </Tooltip>,
        ),
    },
    {
      title: 'Location',
      ellipsis: {
        showTitle: false,
      },
      render: (/** @type {string} */ location) =>
        dataRender(
          location,
          <Tooltip placement='topLeft' title={location}>
            {location}
          </Tooltip>,
        ),
    },
    {
      title: 'Status',
      width: '7%',
      render: (status, record) => (
        <Select
          options={options}
          placeholder='Status'
          onClick={handlePropagation}
          onChange={(value) => onChange(record?.key, value)}
          className='status-select input-height'
          value={status}
          disabled
        />
      ),
    },
  ].filter((column) => !column.hidden);

  const generalColumns = generateHeaderTable(columns);

  return (
    <div className={classNames('list-view-container', className)}>
      <Table
        {...props}
        columns={isEmpty(headerColumns) ? generalColumns : headerColumns}
        dataSource={infoDetail}
        pagination={false}
        onRow={(row) => ({
          onClick: () => {
            onRow(row?.key ?? row?.id, row);
          },
        })}
        onChange={(_pagination, _filters, sorter, _extra) => {
          handleSort(sorter.order);
        }}
        footer={() => footer(infoDetail)}
      >
        {children}
      </Table>
    </div>
  );
};

ListView.propTypes = {
  infoDetail: PropTypes.array,
  onRow: PropTypes.func,
  handleSort: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
  headerColumns: PropTypes.array,
  className: PropTypes.string,
  isCandidate: PropTypes.bool,
  children: PropTypes.node,
  footer: PropTypes.func,
};

ListView.defaultProps = {
  infoDetail: [],
  onRow: () => {},
  handleSort: () => {},
  options: [],
  onChange: () => {},
  headerColumns: [],
  className: '',
  isCandidate: false,
  children: null,
  footer: () => null,
};

export default ListView;
