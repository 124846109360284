import React from 'react';

import './styles.scss';
import { FormItem, Input } from '@/components';
import { LIMIT_PHONE_NUMBER } from '@/constants';
import {
  rulesAccountNumber,
  rulesEmail,
  rulesPhone,
  rulesSortCode,
  rulesVatNumber,
  rulesCompanyNumber,
} from '@/utils/rulesInput';

const BasicInfo = () => (
  <div className='basic-info'>
    <FormItem
      name='name'
      label='Supplier Name'
      rules={[
        {
          required: true,
          message: 'Please enter Supplier Name',
        },
        {
          whitespace: true,
          message: 'Supplier Name can not be empty',
        },
      ]}
    >
      <Input placeholder='Supplier Name' />
    </FormItem>

    <FormItem
      name='mainContact'
      label='Main contact Name'
      rules={[
        {
          required: true,
          message: 'Please enter Main contact Name',
        },
        {
          whitespace: true,
          message: 'Main contact Name can not be empty',
        },
      ]}
    >
      <Input placeholder='Main contact Name' />
    </FormItem>

    <FormItem name='phone' label='Phone Number' rules={rulesPhone()}>
      <Input maxLength={LIMIT_PHONE_NUMBER} placeholder='Phone Number' />
    </FormItem>

    <FormItem name='email' label='Email' rules={rulesEmail()}>
      <Input placeholder='Email' />
    </FormItem>

    <FormItem
      name='companyId'
      label='Company Number'
      rules={rulesCompanyNumber()}
    >
      <Input placeholder='Company Number' />
    </FormItem>

    <FormItem name='vatNumber' label='VAT Number' rules={rulesVatNumber()}>
      <Input placeholder='VAT Number' />
    </FormItem>

    {/* Put a space in grid view */}

    <FormItem
      name='bankName'
      label='Bank Name'
      rules={[
        {
          required: true,
          message: 'Please enter Bank Name',
        },
        {
          whitespace: true,
          message: 'Bank Name can not be empty',
        },
      ]}
    >
      <Input placeholder='Bank Name' />
    </FormItem>

    <FormItem
      name='accountNumber'
      label='Account Number'
      rules={rulesAccountNumber()}
    >
      <Input placeholder='Account Number' />
    </FormItem>

    <FormItem name='sortCode' label='Sort Code' rules={rulesSortCode()}>
      <Input placeholder='Sort Code' />
    </FormItem>

    <FormItem
      name='bankAccountType'
      label='Bank account type'
      rules={[
        {
          required: true,
          message: 'Please enter Bank account type',
        },
        {
          whitespace: true,
          message: 'Bank account type can not be empty',
        },
      ]}
    >
      <Input placeholder='Bank account type' />
    </FormItem>
  </div>
);

export default BasicInfo;
