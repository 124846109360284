import { useAuthStore } from '@/store/authStore';

const useAuth = () => {
  const token = useAuthStore((state) => state.token);

  return {
    isAuth: !!token,
    token,
  };
};

export default useAuth;
