import { useEffect, useRef, useState } from 'react';

import { isBoolean } from 'lodash';

const keysOpenModal = {
  chef: {
    label: 'chef',
    value: 14,
  },
  welder: {
    label: 'welder',
    value: 26,
  },
};

const useShowSpecificRoles = ({ roles }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalClose = (val) => setModalVisible(false);

  const refListRoleKeys = useRef({
    [keysOpenModal.chef.value]: false,
    [keysOpenModal.welder.value]: false,
  });

  const onRoleExist = (listRole, role) => {
    if (listRole.find((value) => role === value)) {
      if (refListRoleKeys.current[role] === false) {
        setModalVisible(true);
        refListRoleKeys.current[role] = true;
      }
    } else {
      refListRoleKeys.current[role] = false;
    }
  };

  const handleRolesChange = (values) => {
    onRoleExist(values, keysOpenModal.chef.value);
    onRoleExist(values, keysOpenModal.welder.value);
  };

  useEffect(() => {
    roles.forEach((role) => {
      if (
        isBoolean(refListRoleKeys.current[role]) &&
        refListRoleKeys.current[role] === false
      ) {
        refListRoleKeys.current[role] = true;
      }
    });
  }, [roles]);

  return [modalVisible, handleModalClose, handleRolesChange];
};

export default useShowSpecificRoles;
