import React, { Fragment } from 'react';

import { Col, Divider, Form, Row } from 'antd';
import { sortBy, uniqueId } from 'lodash';

import { ReactComponent as XiconBlack } from '@/assets/icons/XiconBlack.svg';
import {
  AutoComplete,
  Button,
  Checkbox,
  CheckboxGroup,
  FormItem,
  Select,
} from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import { useIndustries, useLocations } from '@/hooks/dropdowns';
import './styles.scss';
import { rulesPostcode } from '@/utils/rulesInput';

import { GUTTER_FOR_ANTD } from '../../constant';
import { HEAR_ABOUT_US, PAYMENT_START_WEEK } from './constants';

const ORDER_LIST = [
  'Hospitality',
  'Retail',
  'Events',
  'Security and Stewarding',
  'Stadiums',
  'Other',
  'Warehousing and Logistics',
];

const Locations = () => {
  const formInstance = Form.useFormInstance();
  const { listLocations } = useLocations();
  const { listIndustries } = useIndustries();

  const industries = sortBy(listIndustries, ({ label }) =>
    ORDER_LIST.indexOf(label),
  );

  const jobTemplatesAttributes =
    Form.useWatch('jobTemplatesAttributes', formInstance) ?? [];
  const paymentStartWeek = Form.useWatch('paymentStartWeek', formInstance);
  const locations = Form.useWatch('locations', formInstance) ?? [];
  const handleRemoveJobTemplate = (location) => {
    const listUpdate = jobTemplatesAttributes.map((e) =>
      `${e.locationId}` !== location.id ? e : { _destroy: true },
    );

    formInstance.setFieldsValue({ jobTemplatesAttributes: listUpdate });
  };

  const rangeTimeSheet = PAYMENT_START_WEEK.find(
    (option) => option.value === paymentStartWeek,
  )?.label;

  return (
    <div className='locations profile-provider__collapse'>
      <div className='locations__wrapper'>
        <div className='locations__wrapper-container'>
          <div className='wrapper-container'>
            <div className='input-group input-group--border'>
              <div className='list__input list__input-columns--three'>
                <Form.List
                  name='locations'
                  initialValue={[
                    {
                      locationName: null,
                      city: null,
                      street: null,
                      buildingNumber: null,
                      postCode: null,
                      id: uniqueId('customId'),
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Fragment key={field.key}>
                          <div className='list_locations'>
                            <div className='locations_fields'>
                              <FormItem name={[index, 'value']} noStyle />
                              <FormItem name={[index, 'id']} noStyle />
                              <FormItem name='label' />
                              <FormItem
                                name={[index, 'locationName']}
                                className='form-item'
                                label='Location Name'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter Location Name',
                                  },
                                ]}
                              >
                                <AutoComplete placeholder='Location Name' />
                              </FormItem>
                              <FormItem
                                name={[index, 'buildingNumber']}
                                className='form-item'
                                label='Building Number'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter Building Number',
                                  },
                                ]}
                              >
                                <AutoComplete placeholder='Building Number' />
                              </FormItem>
                              <FormItem
                                name={[index, 'street']}
                                className='form-item'
                                label='Street'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter Street',
                                  },
                                ]}
                              >
                                <AutoComplete placeholder='Street' />
                              </FormItem>
                              <FormItem
                                name={[index, 'city']}
                                className='form-item'
                                label='City'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter City',
                                  },
                                ]}
                              >
                                <AutoComplete placeholder='City' />
                              </FormItem>

                              <FormItem
                                name={[index, 'postCode']}
                                className='form-item'
                                label='Post Code'
                                rules={rulesPostcode(formInstance, [
                                  index,
                                  'postCode',
                                ])}
                              >
                                <PostCodeUK />
                              </FormItem>
                              <FormItem
                                name={[index, 'country']}
                                className='form-item'
                                label='Country'
                              >
                                <AutoComplete placeholder='UK' disabled />
                              </FormItem>
                            </div>
                            {fields.length > 1 && (
                              <div className='remove-field'>
                                <Button
                                  type='text'
                                  onClick={() => {
                                    handleRemoveJobTemplate(locations[index]);
                                    remove(field.name);
                                  }}
                                >
                                  <XiconBlack />
                                </Button>
                              </div>
                            )}
                          </div>

                          <Divider />
                        </Fragment>
                      ))}
                      <Button
                        className='yellow-button button__add-more'
                        onClick={() =>
                          add({
                            id: uniqueId('customId'),
                          })
                        }
                      >
                        Add Another
                      </Button>
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </div>
          <Row
            gutter={GUTTER_FOR_ANTD.SMALL}
            className='wrapper-container wrapper-container--industry'
          >
            <Col span={12} className='input-group'>
              <Row className='input-group--border'>
                <div className='profile-provider__collapse__box'>
                  <div className='list__input list__input-industry '>
                    <FormItem
                      name='industries'
                      className='form-item'
                      label='Site Category'
                    >
                      <CheckboxGroup>
                        <Row gutter={GUTTER_FOR_ANTD.BIG}>
                          {industries.map((item) => (
                            <Col span={12}>
                              <Checkbox value={item.value}>
                                {item.label}
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </CheckboxGroup>
                    </FormItem>
                  </div>
                </div>
              </Row>
            </Col>

            <Col className='input-group' span={12}>
              <Row className='input-group--border profile-provider__collapse__row'>
                <Row
                  gutter={GUTTER_FOR_ANTD.SMALL}
                  className='list__input list__input-offices'
                >
                  <Col span={12}>
                    <FormItem
                      name='offices'
                      className='form-item office-item'
                      label='Offices'
                      rules={[
                        { required: true, message: 'Please select Offices' },
                      ]}
                    >
                      <Select
                        placeholder='Offices'
                        options={listLocations}
                        showSearch={false}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name='aboutUs'
                      className='form-item office-item'
                      label='How did you hear about us?'
                    >
                      <Select
                        placeholder='How did you hear about us?'
                        options={HEAR_ABOUT_US}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Row>
              <Row className='input-group--border input-group--spacing-top profile-provider__collapse__row'>
                <Row
                  gutter={GUTTER_FOR_ANTD.SMALL}
                  className='list__input list__input-payment'
                >
                  <FormItem
                    name='paymentStartWeek'
                    className='form-item office-item'
                    label='Payment week start day'
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select payment week start day',
                      },
                    ]}
                    initialValue={PAYMENT_START_WEEK[0].value}
                  >
                    <Select
                      placeholder='Payment week start day'
                      options={PAYMENT_START_WEEK}
                      showSearch={false}
                    />
                  </FormItem>

                  <p className='form-item office-item note'>
                    Timesheets will be auto created for a {rangeTimeSheet}
                    Payment
                  </p>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default Locations;
