import React, { useState, useEffect } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button } from '@/components';
import { useFetch, useSessionStorage } from '@/hooks';
import { getWeeklyWagesPreview } from '@/services/payrollService';

import {
  AllEmployeeCsv,
  NewEmployeeCsv,
  InputFieldsWage,
  WagesPreview,
} from '../components';

import './styles.scss';

const PayrollWage = ({ setWeekNumber, weekNumber }) => {
  const buttonTypes = {
    clear: 'none',
    wagesPreview: 'wagesPreview',
    newEmployee: 'newEmployee',
    allEmployee: 'allEmployee',
  };

  const { wagesPreview, newEmployee, allEmployee } = buttonTypes;

  const [form] = Form.useForm();
  const [year, setYear] = useState(moment().year());

  const [activePanel, setActivePanel] = useState(buttonTypes.wagesPreview);

  const yearN = Form.useWatch('year', form);

  const [storedValue, setSession, removeSession] =
    useSessionStorage('payrollWageSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: weeklyWagesPreview,
    isFetching: isWeeklyWagesFetching,
    refetch,
  } = useFetch(
    ['wages', yearN, weekNumber],
    () => {
      const fields = { ...form.getFieldsValue(), weekNum: weekNumber };
      setYear(fields.year);
      setSession({ ...form.getFieldsValue(), weekNum: weekNumber });
      return getWeeklyWagesPreview({
        filter: fields,
      });
    },
    {
      enabled: true,
      initialData: [],
    },
  );

  const handleSearchData = () => {
    setSession({ ...form.getFieldsValue(), weekNum: weekNumber });
    refetch();
  };

  const handleClearFilter = () => {
    form.setFieldsValue({ year: moment().year(), weekNum: moment().week() });
    setWeekNumber(moment().week());
    removeSession();
    refetch();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSearchData}
        className='inputs'
        initialValues={{
          year: moment().year(),
          weekNum: moment().week(),
        }}
      >
        <InputFieldsWage
          handleClearFilter={handleClearFilter}
          setWeekNumber={setWeekNumber}
          weekNumber={weekNumber}
        />
      </Form>
      <div className='wages-container'>
        <div className='button-detail-container'>
          <div className='button-items'>
            <Button
              onClick={() => setActivePanel(wagesPreview)}
              className={classNames('shared-button style-btn input-height', {
                'yellow-bg': activePanel === wagesPreview,
              })}
            >
              <span>Weekly Wages Preview</span>
              <RightOutlined />
            </Button>
            <Button
              onClick={() => setActivePanel(newEmployee)}
              className={classNames('shared-button style-btn input-height', {
                'yellow-bg': activePanel === newEmployee,
              })}
            >
              <span>New Employee CSV</span>
              <RightOutlined />
            </Button>
            <Button
              onClick={() => setActivePanel(allEmployee)}
              className={classNames('shared-button style-btn input-height', {
                'yellow-bg': activePanel === allEmployee,
              })}
            >
              <span>All Employee CSV</span>
              <RightOutlined />
            </Button>
          </div>
          {activePanel === wagesPreview && (
            <WagesPreview
              filter={{ year, weekNum: weekNumber }}
              weeklyWagesPreview={weeklyWagesPreview}
              isWeeklyWagesFetching={isWeeklyWagesFetching}
            />
          )}
          {activePanel === newEmployee && (
            <NewEmployeeCsv filter={{ year, weekNum: weekNumber }} />
          )}
          {activePanel === allEmployee && <AllEmployeeCsv />}
        </div>
      </div>
    </>
  );
};
PayrollWage.propTypes = {
  setWeekNumber: PropTypes.func,
  weekNumber: PropTypes.number,
};

PayrollWage.defaultProps = {
  setWeekNumber: () => {},
};
export default PayrollWage;
