import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, Input, FormItem } from '@/components';
import { LIMIT_PHONE_NUMBER } from '@/constants';
import {
  rulesEmail,
  rulesPhone,
  rulesPostcode,
  rulesTextInput,
} from '@/utils/rulesInput';
import { useTenantContext } from '../../../../TenantWrapper';

const NextOfKin = ({ nextStep, onValuesChange, initialValues, form }) => {
  const tenant = useTenantContext();

  return (
    <div className='flex-one-column'>
      <div className='title' style={{ marginBottom: '40px' }}>
        Next of Kin
      </div>
      <p>
        In order to ensure your safety whilst working with us at {tenant.name}{' '}
        we require contact details for a next of kin. This is a person close to
        you (usually a family member or someone you live with) who we can
        contact in the event of an emergency and who will be able to provide
        help and support to you in such an event.
      </p>
      <Form
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        form={form}
        className='detail-form-container'
      >
        <FormItem
          className='input-container'
          name='kinFirstname'
          rules={rulesTextInput('first name')}
          label='First name'
        >
          <Input placeholder='First name' />
        </FormItem>
        <FormItem
          className='input-container'
          name='kinLastname'
          rules={rulesTextInput('last name')}
          label='Last name'
        >
          <Input placeholder='Last name' />
        </FormItem>
        <FormItem
          className='input-container'
          name='kinRelation'
          rules={rulesTextInput('relationship')}
          label='Relationship'
        >
          <Input
            className='experience_description'
            placeholder='Relationship'
          />
        </FormItem>

        <FormItem
          className='input-container'
          name='kinEmail'
          rules={rulesEmail()}
          label='Email Address'
        >
          <Input
            className='experience_description'
            placeholder='Email Address'
          />
        </FormItem>
        <FormItem
          className='input-container'
          name='kinPhone'
          rules={rulesPhone('phone number')}
          label='Phone number'
        >
          <Input maxLength={LIMIT_PHONE_NUMBER} placeholder='Phone number' />
        </FormItem>
        <FormItem
          className='input-container'
          name='kinAddress2'
          rules={rulesTextInput('address - street')}
          label='Address - Street'
        >
          <Input
            className='experience_description'
            placeholder='Address - Street'
          />
        </FormItem>
        <FormItem
          className='input-container'
          name='kinAddress'
          rules={rulesTextInput('address')}
          label='Building Number'
        >
          <Input placeholder='Building Number' />
        </FormItem>
        <FormItem
          className='input-container'
          name='kinZip'
          rules={rulesPostcode(form, 'kinZip')}
          label='Post code'
        >
          <Input placeholder='Post code' />
        </FormItem>
        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

NextOfKin.propTypes = {
  form: PropTypes.object,
  nextStep: PropTypes.func,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default NextOfKin;
