import { camelizeKeys } from 'humps';

import request from '@/request';

const getPayrollDetail = async (/** @type {string|number} */ id) => {
  const { data } = await request.get(`pay_templates/payrolls/${id}`);
  const camelizedRes = camelizeKeys(data?.data);
  return camelizedRes;
};

export default getPayrollDetail;
