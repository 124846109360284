import React from 'react';

import { Modal as AntdModal, Spin } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from '@/assets/icons/warningIcon.svg';
import Button from '@/components/Button';

import './styles.scss';

/**
 * @typedef {{
 * titleSuccess?: string;
 * onClickNo: (e: import('react').MouseEvent<HTMLElement, MouseEvent>) => void | function;
 * onClickYes: function;
 * isLoading?: boolean;
 * isSuccess?: boolean;
 * successIcon?: JSX.Element;
 * extraContent?: JSX.Element;
 * visible: boolean;
 * }} AdditionalProps
 */

/**
 * @param {import ('antd').ModalProps & AdditionalProps} props
 * @return {JSX.Element}
 */
const Modal = ({
  title,
  titleSuccess,
  children,
  visible,
  onClickNo,
  onClickYes,
  isLoading,
  isSuccess,
  successIcon,
  extraContent,
  ...props
}) => (
  <AntdModal
    centered
    title={null}
    footer={null}
    visible={visible}
    onCancel={onClickNo}
    wrapClassName='modal-confirm'
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <div className='modal-wrapper'>
      {isLoading ? (
        <div className='modal-loading'>
          <Spin size='large' />
        </div>
      ) : (
        <>
          {isSuccess && !!successIcon ? (
            successIcon
          ) : (
            <WarningIcon className='warning-icon' />
          )}
          <p className='modal-title'>{isSuccess ? titleSuccess : title}</p>
          {!isSuccess && <p className='modal-desc'>{children}</p>}
          {extraContent}
          <div className='modal-footer'>
            <Button className='btn-save' onClick={onClickYes}>
              {isSuccess ? 'Ok' : 'Yes'}
            </Button>
            {!isSuccess && (
              <Button className='btn-cancel' onClick={onClickNo}>
                No
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  </AntdModal>
);

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  titleSuccess: PropTypes.string,
  extraContent: PropTypes.node,
  successIcon: PropTypes.node,
};

export default Modal;
