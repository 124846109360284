import request from '@/request';

const createRota = async (data) => {
  const endpoint = '/rotas/rotas';

  const {
    location,
    positions,
    provider,
    contactTypeAttributes,
    startTime,
    endTime,
    rotaWeeksAttributes,
  } = data;

  const resData = await request.post(endpoint, {
    rota: {
      employerId: provider,
      employerLocationId: location,
      skillId: positions,
      startTime,
      endTime,
      contactTypeAttributes,
      rotaWeeksAttributes,
    },
  });

  return resData;
};

export default createRota;
