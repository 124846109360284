import { message } from 'antd';

export const copyToClipboard = async (/** @type {string} */ value) => {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(value);
  } else {
    // text area method
    const textArea = document.createElement('textarea');
    textArea.value = value;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    await document.execCommand('copy');

    textArea.remove();
  }
  message.success('Copy successfully!');
};
