import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const createInvoice = async (invoice) => {
  const endpoint = 'trackers/invoicing/invoices';

  const res = await request.post(endpoint, { invoice });
  const data = normalizeShowResponse(res);
  return data?.data;
};

export default createInvoice;
