import React from 'react';

import { Card } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const CustomTimeSlotWrapper = ({ children }) => (
  <div className='weekly-view'>
    <Card className='header-card'>
      <Card className='card-gutter'>
        <h4 className='input-headers'>Day</h4>
        <h4 className='input-headers'>Date</h4>
      </Card>
      <Card className='card-volume'>Volume</Card>
      <Card className='card'>Contract</Card>
      <Card className='card'>Required</Card>
      <Card className='card'>Agency</Card>
      <Card className='card'>Difference</Card>
    </Card>
    {children}
  </div>
);
CustomTimeSlotWrapper.propTypes = {
  children: PropTypes.any,
};
export default CustomTimeSlotWrapper;
