import { message } from 'antd';

import request from '@/request';
import { convertObjectToArray } from '@/utils/convertObjectToArray';

export const fetchLanguageLevel = async () => {
  try {
    const res = await request.get('/options/language_levels');
    const { data } = res.data;

    return { listLanguageLevel: convertObjectToArray(data) };
  } catch (error) {
    message.error('Something went wrong!');
    throw error;
  }
};
