import request from '@/request';
import { normalizeDropdownResponse } from '@/utils/normalizeResponse';

export const getVacancyApplicantEmploymentPosition = async () => {
  const res = await request.get(
    '/options/vacancy_applicant_employment_position',
  );
  const result = normalizeDropdownResponse(res);

  return result;
};
