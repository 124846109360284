import React from 'react';

import { Divider, Form, Checkbox } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button, DatePicker, FormItem, Input, Select } from '@/components';
import { useSkillTypes } from '@/hooks/dropdowns';
import { date } from '@/utils';
import './styles.scss';

const reference = ({
  index,
  firstname,
  email,
  phone,
  name,
  role,
  startAt,
  endAt,
  isStillWork,
  isFirstJob,
  listRoles,
  handleSetDefaultDay,
  isRules,
  isDisabledFuture,
  startDateVal,
  endDateVal,
  handleChangeYear,
}) => (
  <div className='reference-detail-container'>
    <FormItem
      name={['vacancyApplicantReferencesAttributes', index, 'id']}
      noStyle
    />

    <FormItem
      className='input-container'
      name={['vacancyApplicantReferencesAttributes', index, firstname]}
      label='Full Name of Referee'
    >
      <Input
        disabled={isFirstJob}
        placeholder='Full Name of Referee'
        maxLength={48}
      />
    </FormItem>
    <FormItem
      className='input-container'
      name={['vacancyApplicantReferencesAttributes', index, email]}
      label='Email Address of Referee'
    >
      <Input disabled={isFirstJob} placeholder='Email Address of Referee' />
    </FormItem>
    <FormItem
      className='input-container'
      name={['vacancyApplicantReferencesAttributes', index, phone]}
      label='Phone Number of Referee'
    >
      <Input disabled={isFirstJob} placeholder='Phone Number of Referee' />
    </FormItem>
    <FormItem
      className='input-container'
      name={['vacancyApplicantReferencesAttributes', index, name]}
      label='Company you worked for'
    >
      <Input
        disabled={isFirstJob}
        maxLength={48}
        placeholder='Company you worked for'
      />
    </FormItem>
    <FormItem
      className='input-container'
      name={['vacancyApplicantReferencesAttributes', index, role]}
      label='Role you worked in'
    >
      <Select
        disabled={isFirstJob}
        options={listRoles}
        placeholder='Role you worked in'
        required
      />
    </FormItem>
    <div className='date-container'>
      <FormItem
        className='input-container date'
        name={['vacancyApplicantReferencesAttributes', index, startAt]}
        label='Start Date'
        normalize={date.convertDateTime}
      >
        <DatePicker
          placeholder='Start Date'
          name={['vacancyApplicantReferencesAttributes', index, startAt]}
          disabledDate={(disabledDate) =>
            endDateVal && moment(disabledDate) > moment(endDateVal)
          }
          handleChangeYear={(fullDateTime) => {
            handleChangeYear(index, startAt, fullDateTime);
          }}
          disabled={isFirstJob}
        />
      </FormItem>
      <FormItem
        className='input-container date'
        name={['vacancyApplicantReferencesAttributes', index, endAt]}
        label='End Date'
        normalize={date.convertDateTime}
      >
        <DatePicker
          placeholder='End Date'
          name={['vacancyApplicantReferencesAttributes', index, endAt]}
          disabledDate={(disabledDate) =>
            startDateVal && moment(disabledDate) < moment(startDateVal)
          }
          handleChangeYear={(fullDateTime) => {
            handleChangeYear(index, endAt, fullDateTime);
          }}
          disabled={isFirstJob}
        />
      </FormItem>
    </div>
    <FormItem
      name={['vacancyApplicantReferencesAttributes', index, isStillWork]}
      className='work-this-role'
      valuePropName='checked'
    >
      <Checkbox
        className='still-work'
        onChange={(e) => handleSetDefaultDay(index, e)}
      >
        I still work in this role
      </Checkbox>
    </FormItem>
  </div>
);

const Reference = ({ nextStep, onValuesChange, form, initialValues }) => {
  const listRoles = useSkillTypes();
  const isStillWorkFirst = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 0, 'isStillWork'],
    form,
  );

  const isStillWorkSecond = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 1, 'isStillWork'],
    form,
  );

  const endDate1 = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 0, 'endAt'],
    form,
  );

  const startDate1 = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 0, 'startAt'],
    form,
  );

  const startDate2 = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 1, 'startAt'],
    form,
  );

  const endDate2 = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 1, 'endAt'],
    form,
  );

  const handleChangeYear = (index, attribute, value) => {
    form.setFields([
      {
        name: ['vacancyApplicantReferencesAttributes', index, attribute],
        value,
      },
    ]);
    onValuesChange(
      {
        vacancyApplicantReferencesAttributes: [{ [attribute]: value }],
      },
      form.getFieldsValue(),
    );
  };

  const disableReference = (
    /** @type {{ target: { checked: boolean; }; }} */ e,
  ) => {
    form.setFieldsValue({
      isFirstJob: e.target.checked,
    });
  };

  const handleSetDefaultDay = (
    /** @type {number} */ index,
    /** @type {{ target: { checked: boolean; }; }} */ e,
  ) => {
    const endDate = moment().format('YYYY-MM-DD');
    if (e.target.checked)
      form.setFields([
        {
          name: ['vacancyApplicantReferencesAttributes', index, 'isStillWork'],
          value: 1,
        },
        {
          name: ['vacancyApplicantReferencesAttributes', index, 'endAt'],
          value: endDate,
        },
      ]);
    else
      form.setFields([
        {
          name: ['vacancyApplicantReferencesAttributes', index, 'isStillWork'],
          value: 0,
        },
      ]);
  };
  const isFirstJob = Form.useWatch('isFirstJobNoReference', form);

  return (
    <div className='reference-container'>
      <Form
        form={form}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        onFinish={nextStep}
        onValuesChange={onValuesChange}
      >
        <div className='ref-header-container'>
          <div className='title reference-title'>Reference</div>
          <h2 className='sub-title'>
            Please provide 2 references from past jobs.
          </h2>
        </div>
        <div className='ref-body-container'>
          {reference({
            index: 0,
            firstname: 'firstname',
            email: 'email',
            phone: 'phone',
            name: 'name',
            role: 'role',
            startAt: 'startAt',
            endAt: 'endAt',
            isStillWork: 'isStillWork',
            isDisabledFuture: isStillWorkFirst,
            isFirstJob,
            listRoles,
            handleSetDefaultDay,
            isRules: true,
            startDateVal: startDate1,
            endDateVal: endDate1,
            handleChangeYear,
          })}
          <Divider className='ref-divider' />
          {reference({
            index: 1,
            firstname: 'firstname',
            email: 'email',
            phone: 'phone',
            name: 'name',
            role: 'role',
            startAt: 'startAt',
            endAt: 'endAt',
            isStillWork: 'isStillWork',
            isDisabledFuture: isStillWorkSecond,
            isFirstJob,
            listRoles,
            handleSetDefaultDay,
            isRules: false,
            startDateVal: startDate2,
            endDateVal: endDate2,
            handleChangeYear,
          })}
        </div>
        <div className='ref-footer-container'>
          <p className='footer-item'>
            Note: If you successfully make it to the next stage with us we will
            contact your referee for confirmation of your work history.
          </p>
          <FormItem
            name='isFirstJobNoReference'
            className='footer-item'
            valuePropName='checked'
          >
            <Checkbox className='still-work' onChange={disableReference}>
              This is my first job so I don&apos;t have a reference.
            </Checkbox>
          </FormItem>
          <Button htmlType='submit' className='shared-button confirm-button'>
            Next Step
          </Button>
        </div>
      </Form>
    </div>
  );
};
Reference.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.object,
};

Reference.defaultProps = {
  nextStep: () => {},
  form: {},
  onValuesChange: () => {},
  initialValues: {},
};
export default Reference;
