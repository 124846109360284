import request from '@/request';
import { CustomError } from '@/utils/errorUtil';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const searchSeekersNotTrustedBlocked = async ({
  pageSize,
  employerId,
  ...filter
}) => {
  const res = await request.get(
    `/providers/employers/${employerId}/search_employees`,
    {
      params: {
        filter,
      },
    },
  );
  const { data } = normalizeIndexResponse(res);
  return data;
};

const addTrustedSeekers = async (providerId, trustedSeekers = []) => {
  const trustedSeekersId = trustedSeekers.map((seeker) => seeker.employeeId);
  try {
    const res = await request.post(
      `/providers/employers/${providerId}/employer_trusted_employees`,
      {
        employer_trusted_employee: {
          employee_ids: trustedSeekersId,
        },
      },
    );
    return normalizeIndexResponse(res);
  } catch (error) {
    throw new CustomError(error);
  }
};

const addBlockedSeekers = async (providerId, blockedSeekers) => {
  const blockedSeekersId = blockedSeekers.map((seeker) => seeker.employeeId);
  try {
    const res = await request.post(
      `/providers/employers/${providerId}/employer_blocked_employees`,
      {
        employer_blocked_employee: {
          employee_ids: blockedSeekersId,
        },
      },
    );

    return normalizeIndexResponse(res);
  } catch (error) {
    throw new CustomError(error);
  }
};

const removeTrustedSeeker = async (employerId, employeeId) => {
  try {
    const res = await request.delete(
      `/providers/employers/${employerId}/employer_trusted_employees/${employeeId}`,
    );
    return res;
  } catch (error) {
    throw new CustomError(error);
  }
};

const removeBlockedSeeker = async (employerId, employeeId) => {
  try {
    const res = await request.delete(
      `/providers/employers/${employerId}/employer_blocked_employees/${employeeId}`,
    );
    return res;
  } catch (error) {
    throw new CustomError(error);
  }
};

const getListBlockedSeekers = async (employerId) => {
  const res = await request.get(
    `/providers/employers/${employerId}/employer_blocked_employees`,
  );
  const { data } = normalizeIndexResponse(res);
  return data;
};

const getListWorkerPool = async (employerId) => {
  const res = await request.get(
    `/providers/employers/${employerId}/employer_trusted_employees`,
  );
  const { data } = normalizeIndexResponse(res);
  return data;
};

export {
  searchSeekersNotTrustedBlocked,
  addTrustedSeekers,
  addBlockedSeekers,
  removeTrustedSeeker,
  removeBlockedSeeker,
  getListBlockedSeekers,
  getListWorkerPool,
};
