/* eslint-disable max-len */
import React from 'react';

import { Button, Form, Tag } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Navigate, Views } from 'react-big-calendar';

import ArrowLeft from '@/assets/icons/ArrowLeft.png';
import ArrowRight from '@/assets/icons/ArrowRight.png';
import { Image } from '@/components';
import SwitchMode from '@/pages/Jobs/components/SwitchMode';

import ColorBox from './ColorBox';

/**
 *
 * @param {{
 * label: string,
 * onNavigate: (navigate: import('react-big-calendar').NavigateAction) => void,
 * date: Date
 * }} param
 * @returns {JSX.Element}
 */
const Toolbar = ({ label, onNavigate, date }) => {
  const formInstance = Form.useFormInstance();
  const provider = Form.useWatch('providerName', formInstance);
  const viewMode = Form.useWatch('viewMode', formInstance);
  const startDate = Form.useWatch('startDate', formInstance);
  const endDate = Form.useWatch('startDate', formInstance);

  const isMonthlyView = viewMode === Views.MONTH;

  const onChange = () => {
    formInstance.setFieldsValue({
      viewMode: isMonthlyView ? Views.WEEK : Views.MONTH,
    });
  };

  const onChangeRangeDate = (type) => {
    onNavigate(type);
    if (isMonthlyView) {
      const monthDiff = type === Navigate.PREVIOUS ? -1 : 1;
      const newStartDate = moment(date)
        .add(monthDiff, 'month')
        .startOf('month');
      const newEndDate = moment(date).add(monthDiff, 'month').endOf('month');
      return formInstance.setFieldsValue({
        startDate: newStartDate,
        endDate: newEndDate,
      });
    }

    if (type === Navigate.PREVIOUS) {
      const startOfWeek = moment(date).subtract(7, 'day').startOf('week');
      const endOfWeek = moment(date).subtract(7, 'day').endOf('week');
      return (
        startOfWeek < startDate &&
        formInstance.setFieldsValue({
          startDate: startOfWeek,
          endDate: endOfWeek,
        })
      );
    }

    const startOfWeek = moment(date).add(7, 'day').startOf('week');
    const endOfWeek = moment(date).add(7, 'day').endOf('week');
    return (
      endOfWeek > endDate &&
      formInstance.setFieldsValue({
        startDate: startOfWeek,
        endDate: endOfWeek,
      })
    );
  };

  return (
    <div className='calendar-toolbar'>
      <div className='calendar-toolbar-item'>{provider}</div>
      <div className='calendar-toolbar-item'>
        <div className='week-controller'>
          <Button
            className='arrow-button arrow-button-left'
            onClick={() => onChangeRangeDate(Navigate.PREVIOUS)}
            icon={
              <Image
                src={ArrowLeft}
                containerClassName='arrow-icon'
                preview={false}
              />
            }
          />
          <Tag className='toolbar-tag'>{label}</Tag>
          <Button
            className='arrow-button arrow-button-right'
            onClick={() => onChangeRangeDate(Navigate.NEXT)}
            icon={
              <Image
                src={ArrowRight}
                containerClassName='arrow-icon'
                preview={false}
              />
            }
          />
        </div>
      </div>
      <div className='calendar-toolbar-item'>
        <ColorBox />
        <SwitchMode
          isChecked={isMonthlyView}
          leftTitle='Weekly'
          rightTitle='Monthly'
          onChange={onChange}
          name='isMonthlyView'
        />
      </div>
    </div>
  );
};

export default Toolbar;

Toolbar.propTypes = {
  label: PropTypes.string,
  onNavigate: PropTypes.func,
  date: PropTypes.instanceOf(Date),
};

Toolbar.defaultProps = {
  label: '',
  onNavigate: () => {},
};
