import React from 'react';

import { Form } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import {
  Button,
  FormItem,
  DatePicker,
  AutoComplete,
  MultipleSelect,
  Select,
} from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import { candidateStatuses, RADIUSES } from '@/constants';
import { useLocations, useSkillTypes } from '@/hooks/dropdowns';
import { candidateAutoCompleteService } from '@/services';
import { disabledDateOf } from '@/utils';

const ratings = [
  {
    label: (
      <span>
        A<sup>*</sup>
      </span>
    ),
    value: 1,
  },
  {
    label: 'A',
    value: 2,
  },
  {
    label: 'B',
    value: 3,
  },
  {
    label: 'C',
    value: 4,
  },
  {
    label: 'D',
    value: 5,
  },
];

const CandidateFields = ({ setCurrentPage, onClearFilter }) => {
  const { listLocations } = useLocations();
  const listRoles = useSkillTypes();

  const form = Form.useFormInstance();

  const startDate = Form.useWatch('registrationDateFrom', form);
  const endDate = Form.useWatch('registrationDateTo', form);

  const { data: dataSearchMail, refetch: refetchSearchMail } = useQuery(
    'getSearchcode',
    () => {
      const searchTerm = form.getFieldValue('searchTerm');

      return candidateAutoCompleteService({
        filter: {
          search_term: searchTerm,
        },
      });
    },
    { refetchOnWindowFocus: false, enabled: false },
  );

  const handleSearchMail = debounce(refetchSearchMail, 500);

  return (
    <div className='candidate-fiedls'>
      <FormItem label='Search' name='searchTerm'>
        <AutoComplete
          onSearch={handleSearchMail}
          options={dataSearchMail}
          placeholder='Search'
        />
      </FormItem>
      <FormItem label='Job Roles Applied' name='generalRole'>
        <MultipleSelect
          options={listRoles}
          placeholder='Specific Role'
          name='generalRole'
        />
      </FormItem>
      <FormItem label='Other Interested Roles' name='preferredRoles'>
        <MultipleSelect
          options={listRoles}
          placeholder='Other Job Roles'
          name='preferredRoles'
        />
      </FormItem>
      <FormItem label='Location' name='officeId'>
        <MultipleSelect
          options={listLocations}
          placeholder='Location'
          name='officeId'
          showSearch={false}
        />
      </FormItem>
      <div className='applicant-date'>
        <p className='applicant-label'>Registration Date</p>
        <div className='row-fields'>
          <FormItem name='registrationDateFrom'>
            <DatePicker
              disabledDate={(disabledDate) =>
                disabledDateOf.Start(disabledDate, endDate)
              }
              placeholder='Select Date'
              name='registrationDateFrom'
            />
          </FormItem>
          <FormItem name='registrationDateTo'>
            <DatePicker
              disabledDate={(disabledDate) =>
                disabledDateOf.End(disabledDate, startDate)
              }
              placeholder='Select Date'
              name='registrationDateTo'
            />
          </FormItem>
        </div>
      </div>
      <FormItem label='Status' name='candidateStatus'>
        <MultipleSelect
          placeholder='Status'
          options={candidateStatuses}
          name='candidateStatus'
        />
      </FormItem>
      <FormItem label='Post Code' name='zip'>
        <PostCodeUK />
      </FormItem>
      <FormItem label='Radius' name='radius'>
        <Select options={RADIUSES} placeholder='Radius' />
      </FormItem>
      <FormItem label='Ratings' name='stars'>
        <Select options={ratings} placeholder='Rating' />
      </FormItem>
      <div className='row-fields span-row-2 button-action'>
        <Button
          className='yellow-button input-height style-btn'
          type='primary'
          htmlType='submit'
        >
          Search
        </Button>
        <Button
          className='btn-clear input-height style-btn'
          htmlType='button'
          onClick={onClearFilter}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default CandidateFields;

CandidateFields.propTypes = {
  setCurrentPage: PropTypes.func,
  onClearFilter: PropTypes.func,
};
