/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

// import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker as AntdDatePicker, Form } from 'antd';
import classNames from 'classnames';
import { range } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import ArrowLeft from '@/assets/icons/ArrowLeft.png';
import ArrowRight from '@/assets/icons/ArrowRight.png';
import { ReactComponent as CalendarIcon } from '@/assets/icons/calendarIcon.svg';

import DateRender from './DateRender';
import PanelRender from './PanelRender';
import YearMode from './yearMode';

import './styles.scss';

const defaultYears = range(1940, 2101, 1);

moment.updateLocale('en', {
  week: {
    dow: 0,
  },
});

const DatePicker = ({
  className,
  popupClassName,
  dropdownClassName,
  format,
  onSelect,
  years,
  value,
  name,
  showClear,
  datePickerMode,
  defaultValue,
  handleChangeYear,
  onCancelClick,
  ...props
}) => {
  const yearLength = years.length;
  const defaultYearLength = defaultYears.length;
  const maxYear =
    yearLength > 0
      ? years[yearLength - 1]
      : defaultYears[defaultYearLength - 1];

  const formInstance = Form.useFormInstance();

  const [isDisableNextIcon, setIsDisableNextIcon] = useState(false);
  const [mode, setMode] = useState(datePickerMode);
  const [datetime, setDatetime] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(
    moment().toDate().getFullYear(),
  );

  const yearRef = useRef();
  const wrapperRef = useRef();
  const datepickerInputRef = useRef();
  const datepickerPanelRef = useRef();

  const openModal = () => {
    setMode('date');
    setIsOpen(true);
  };

  const closeModal = (e) => {
    const [input] = wrapperRef.current.querySelectorAll(
      ':scope .ant-picker-input  > input',
    );

    if (e.target === input) {
      return;
    }

    if (e.target === datepickerInputRef.current) {
      return;
    }

    // without close modal when it's in year mode
    if (typeof e.target.className === 'string') {
      if (e.target.className.includes('year-number')) {
        return;
      }
    }

    // close modal when click on outside
    if (
      datepickerPanelRef.current &&
      !datepickerPanelRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };
  useLayoutEffect(() => {
    const [input] = wrapperRef.current.querySelectorAll(
      ':scope .ant-picker-input',
    );

    datepickerInputRef.current = input;

    datepickerInputRef.current?.addEventListener('click', openModal);
    document.addEventListener('click', closeModal);

    return () => {
      datepickerInputRef.current?.removeEventListener('click', openModal);
      document.removeEventListener('click', closeModal);
    };
  }, []);

  useEffect(() => {
    yearRef.current?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
  });

  useEffect(() => {
    if (value) {
      setDatetime(moment(value));
    }
  }, [value]);

  const onPanelChange = (val) => {
    const changedYear = moment(val).year();
    const changedMonth = val.format('MM');

    if (changedYear === maxYear && changedMonth === '12') {
      return setIsDisableNextIcon(true);
    }

    if (selectedYear > maxYear) {
      return setIsDisableNextIcon(true);
    }

    return setIsDisableNextIcon(false);
  };

  const renderDate = (crrDate) => (
    <DateRender
      crrDate={crrDate}
      selectedYear={selectedYear}
      name={name}
      formInstance={formInstance}
      setDatetime={setDatetime}
      weekSelected={datetime?.weeks()}
    />
  );

  const renderPanel = (originalPanel) => (
    <PanelRender
      originalPanel={originalPanel}
      datetime={datetime}
      datepickerPanelRef={datepickerPanelRef}
      mode={mode}
      name={name}
      setMode={setMode}
      setIsOpen={setIsOpen}
      setDatetime={setDatetime}
      isDisableNextIcon={isDisableNextIcon}
      years={years}
      onCancelClick={onCancelClick}
      yearMode={
        <YearMode
          years={years}
          selectedYear={selectedYear}
          yearRef={yearRef}
          datetime={datetime}
          setDatetime={setDatetime}
          setMode={setMode}
          setSelectedYear={setSelectedYear}
          formInstance={formInstance}
          handleChangeYear={handleChangeYear}
          name={name}
          onPanelChange={onPanelChange}
          setIsDisableNextIcon={setIsDisableNextIcon}
        />
      }
    />
  );

  const getPopupContainer = (trigger) => trigger.parentNode;

  return (
    <div className='ref-container' ref={wrapperRef}>
      <AntdDatePicker
        getPopupContainer={getPopupContainer}
        {...props}
        defaultValue={defaultValue}
        panelRender={renderPanel}
        className={classNames('primary-date-picker input-height', className)}
        popupClassName={classNames('popup-datepicker', popupClassName)}
        format={format}
        suffixIcon={<CalendarIcon className='calendar-icon' />}
        dateRender={renderDate}
        nextIcon={
          <img className='arrow-date' src={ArrowRight} alt='right-arrow' />
        }
        prevIcon={
          <img className='arrow-date' src={ArrowLeft} alt='right-arrow' />
        }
        superNextIcon={null}
        superPrevIcon={null}
        showToday={false}
        open={isOpen}
        value={datetime}
        picker={mode}
        onOk={() => setIsOpen(false)}
        placement='bottomRight'
        dropdownAlign={{ offset: [0, 4] }}
        onPanelChange={onPanelChange}
        allowClear={false}
        dropdownClassName={dropdownClassName}
      />
    </div>
  );
};

DatePicker.propTypes = {
  showClear: PropTypes.bool,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  value: PropTypes.any,
  format: PropTypes.string,
  popupClassName: PropTypes.string,
  onSelect: PropTypes.func,
  years: PropTypes.array,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  datePickerMode: PropTypes.string,
  defaultValue: PropTypes.any,
  handleChangeYear: PropTypes.func,
  onCancelClick: PropTypes.func,
};

DatePicker.defaultProps = {
  showClear: true,
  className: '',
  format: 'DD/MM/YYYY',
  onSelect: () => {},
  popupClassName: '',
  dropdownClassName: '',
  years: [],
  datePickerMode: 'date',
  handleChangeYear: () => {},
  onCancelClick: () => {},
};

export default DatePicker;
