import request from '@/request';

const updateSickNoShowComment = async ({ timesheetId, comment }) => {
  // eslint-disable-next-line max-len
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/assigned_schedule_comments`;

  const res = await request.post(endpoint, { comment });

  return res?.data?.data ?? {};
};

export default updateSickNoShowComment;
