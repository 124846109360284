/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { FormItem, Input, Checkbox } from '@/components';
import { REGEX_EMAIL, REGEX_PHONE_NUMBER } from '@/constants';
import { useFetch } from '@/hooks';
import { ProviderService } from '@/services';

import { contactTypes, EXCEPTION_MESSAGE } from './constants';
import ContactAction from './ContactAction';
import ContactType from './ContactType';
import RightSection from './RightSection';

import './styles.scss';

const ContactInformation = () => {
  const formInstance = Form.useFormInstance();
  const selectedContactType =
    Form.useWatch('selectedContactType', formInstance) ?? contactTypes[0];

  const accountManagerName = Form.useWatch('accountManagerName', formInstance);

  const contactNameWatching = Form.useWatch('contactName', formInstance);
  const contactEmailWatching = Form.useWatch('contactEmail', formInstance);
  const contactPhoneWatching = Form.useWatch('contactPhone', formInstance);
  const contactJobTitleWatching = Form.useWatch(
    'contactJobTitle',
    formInstance,
  );
  const contactDepartmentWatching = Form.useWatch(
    'contactDepartment',
    formInstance,
  );

  useFetch(
    ['getAccountManagerInfo', accountManagerName],
    () => ProviderService.getManagerInfoById(accountManagerName),
    {
      /**
       * @param {object} param
       * @return {void}
       */
      onSuccess: ({ accountManagerGIGEmail, accountManagerPhone }) =>
        formInstance.setFieldsValue({
          accountManagerGIGEmail,
          accountManagerPhone,
        }),
    },
  );

  const handleSelectContactType = (
    /** @type {{ fieldName: string; }} */ type,
  ) => {
    const selectedTypeValue = formInstance.getFieldValue(type.fieldName);

    formInstance.setFieldsValue({
      selectedContactType: type,
      [`${type.fieldName}Index`]: isEmpty(selectedTypeValue) ? null : 0,
    });
  };

  const isHidden = (type) => selectedContactType?.fieldName !== type.fieldName;

  const rules = [
    {
      validator: () => {
        const { contactName, contactPhone, contactEmail } =
          formInstance.getFieldsValue();
        const hasMissingFields = !contactEmail || !contactName || !contactPhone;
        const hasInvalidValues =
          !REGEX_EMAIL.test(contactEmail) ||
          !REGEX_PHONE_NUMBER.test(contactPhone);

        if (hasMissingFields) {
          return Promise.reject(new Error(EXCEPTION_MESSAGE.missFields));
        }
        if (hasInvalidValues) {
          return Promise.reject(new Error(EXCEPTION_MESSAGE.invalidValues));
        }
        return Promise.resolve();
      },
    },
  ];

  const isMainContactChecked =
    contactNameWatching ||
    contactEmailWatching ||
    contactPhoneWatching ||
    contactJobTitleWatching ||
    contactDepartmentWatching;

  return (
    <div className='contact-information contact-information__wrapper'>
      <FormItem
        name='selectedContactType'
        initialValue={contactTypes[0]}
        hidden
      />

      <div className='wrapper-container--left'>
        <div className='input-group'>
          <div className='input-group__label'>Contacts</div>
          <div className='list__input list__input-columns--two'>
            <div className='left'>
              <div className='label'>Department</div>
              <FormItem name='accountManagerName' hidden />

              <div className='document-item-checkbox'>
                <Form.Item name={contactTypes[0].fieldName} rules={rules}>
                  <Checkbox
                    onClick={() => handleSelectContactType(contactTypes[0])}
                    className='item-checkbox'
                    checked={
                      contactTypes[0].label === selectedContactType?.label
                    }
                  >
                    <div>{contactTypes[0].label}</div>
                    <Checkbox checked={isMainContactChecked} />
                  </Checkbox>
                </Form.Item>
              </div>
              {contactTypes.slice(1).map((type) => (
                <ContactType
                  type={type}
                  handleSelectContactType={handleSelectContactType}
                  selectedContactType={selectedContactType}
                />
              ))}
            </div>
            <div className='right'>
              <div className='label'>Name</div>
              <div className='form-item-wrapper'>
                <div className='flex' hidden={isHidden(contactTypes[0])}>
                  <FormItem name='contactId' className='form-item' hidden />

                  <FormItem
                    name='contactName'
                    className='form-item form-item--full-width'
                  >
                    <Input
                      placeholder='Name'
                      readOnly
                      className='contact-input'
                    />
                  </FormItem>
                  <RightOutlined
                    className={classNames('right-arrow', {
                      'right-arrow--hide': isHidden(contactTypes[0]),
                    })}
                  />
                </div>
                <FormItem name='accountManagerGIGEmail' hidden />
                <FormItem name='accountManagerPhone' hidden />
                <FormItem name='accountManagerSecondaryManagerEmail' hidden />
                <FormItem name='accountManagerId' hidden />
                <FormItem name='accountManagerGIGEmail' hidden />

                {contactTypes.slice(1).map((type) => (
                  <ContactAction
                    isHidden={isHidden}
                    fieldName={type.fieldName}
                    selectedContactType={selectedContactType}
                    type={type}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSection isHidden={isHidden} />
    </div>
  );
};
export default ContactInformation;
