import React, { useState } from 'react';

import { Form, Tooltip, message } from 'antd';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button, FormItem, Pagination, Select } from '@/components';
import './styles.scss';
import { useFetch, usePagination } from '@/hooks';
import { useProviderLocations, useProviders } from '@/hooks/dropdowns';
import { allocateWorkers } from '@/services/payrollTemplateService';
import getPayrollTemplates from '@/services/payrollTemplateService/getPayrollTemplates';

import AllocateWorkerModal from '../components/AllocateWorkerModal';
import ListView from '../components/ListView';
import { allocateWorkersModalFields } from '../constants';

/**
 * @param {object} props
 * @param {boolean} props.isProviderDetail to toggle view between pay template or provider detail
 * @param {number} props.providerDetailId the id of provider in detail view
 * @returns {React.ReactNode}
 */
const PaymentView = ({ isProviderDetail, providerDetailId }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const listProviders = useProviders();
  const providerId = Form.useWatch('employer_id', form);
  const { data: locations } = useProviderLocations(
    isProviderDetail ? providerDetailId : providerId,
  );
  const [openAllocateModal, setOpenAllocateModal] = useState(false);

  const {
    currentPage,
    handlePagination,
    paginationPageSize,
    handleShowSizeChange,
  } = usePagination();

  const {
    data: templateData,
    refetch: handleSearchProvider,
    isFetching: isFetchListTemplate,
  } = useFetch(
    ['getListPayrollTemplate', paginationPageSize, currentPage],
    () =>
      getPayrollTemplates({
        filter: isProviderDetail
          ? { employer_id: providerDetailId }
          : form.getFieldsValue(),
        page: {
          size: paginationPageSize,
          number: currentPage,
        },
      }),
  );

  const { mutate: allocateWokerMutation } = useMutation(
    () => {
      const seekerIds = (form.getFieldValue('appliedSeekers') || []).map(
        (seeker) => seeker.employeeId,
      );
      return allocateWorkers({
        templateId: form.getFieldValue('templateName'),
        payload: {
          employees_attributes: seekerIds,
        },
      });
    },
    {
      onSuccess: () => {
        message.success('Allocate workers success');
        setOpenAllocateModal(false);
        form.resetFields(allocateWorkersModalFields);
      },
      onError: (error) => {
        message.error(error.message ?? 'Allocate workers failed');
      },
    },
  );

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'employerName',
      key: 'employerName',
      width: '20%',
      ellipsis: true,
      render: (employerName) => (
        <Tooltip placement='topLeft' title={employerName}>
          {employerName}
        </Tooltip>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'employerLocations',
      key: 'employerLocations',
      width: '15%',
      ellipsis: true,
      render: (employerLocations) => {
        const locationNames = employerLocations
          .map((item) => item.name)
          .join(', ');
        return (
          <Tooltip placement='topLeft' title={locationNames}>
            {locationNames}
          </Tooltip>
        );
      },
    },
    {
      title: 'Templates',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ellipsis: true,
      render: (name, record) => (
        <Tooltip placement='topLeft' className='underline' title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      ellipsis: true,
      render: (_, record) => (
        <div className='actions' placement='topLeft'>
          <Button
            className='black-button actions-btn'
            onClick={() => history.push(`/edit-payroll-templates/${record.id}`)}
          >
            Edit
          </Button>
          <Button
            className='yellow-button actions-btn'
            onClick={() =>
              history.push(`/clone-payroll-templates/${record.id}`)
            }
          >
            Clone
          </Button>
        </div>
      ),
    },
    {
      title: 'Allocated Workers',
      dataIndex: 'allocatedWorkers',
      key: 'allocatedWorkers',
      width: '10%',
      ellipsis: true,
      render: (_, record) => (
        <div className='actions' placement='topLeft'>
          <Button
            className='actions-btn__full gray-button'
            onClick={() => history.push(`/allocate-workers/${record.id}`)}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const handleChangeProvider = () => {
    form.resetFields(['employer_location_id']);
  };

  const handleClear = () => {
    if (!isNil(providerId)) {
      form.resetFields(['employer_id', 'employer_location_id']);
      handleSearchProvider();
    }
  };

  return (
    <div className='pay-templates-container'>
      <Form form={form} className='pay-form' onFinish={handleSearchProvider}>
        {!isProviderDetail && (
          <>
            <FormItem
              valuePropName='required'
              label='Provider'
              name='employer_id'
            >
              <Select options={listProviders} onChange={handleChangeProvider} />
            </FormItem>
            <FormItem label='Location' name='employer_location_id'>
              <Select options={locations} />
            </FormItem>
            <div className='options-btn'>
              <FormItem>
                <Button
                  className='yellow-button'
                  type='primary'
                  htmlType='submit'
                >
                  Search
                </Button>
              </FormItem>
              <Button className='clear-btn' onClick={handleClear} type='text'>
                Clear Filters
              </Button>
            </div>
          </>
        )}

        <AllocateWorkerModal
          width='40%'
          providerDetailId={providerDetailId}
          listProviders={listProviders}
          locations={locations}
          isShow={openAllocateModal}
          onSubmit={() => allocateWokerMutation()}
          onCancel={() => {
            setOpenAllocateModal(false);
            form.resetFields(allocateWorkersModalFields);
          }}
        />
      </Form>
      <div className='pay-templates-table'>
        <div className='pay-templates-table-body'>
          <ListView
            columns={columns}
            infoDetail={templateData?.data}
            loading={isFetchListTemplate}
          />
          <Button
            className='yellow-button allocate-btn'
            onClick={() => setOpenAllocateModal(true)}
            disabled={templateData?.data.length === 0}
          >
            Allocate Worker
          </Button>
        </div>
        <Pagination
          current={currentPage}
          total={templateData?.paginations?.count}
          pageSize={paginationPageSize}
          onChange={handlePagination}
          responsive
          showSizeChanger
          onShowSizeChange={handleShowSizeChange}
        />
      </div>
    </div>
  );
};

PaymentView.propTypes = {
  isProviderDetail: PropTypes.bool,
  providerDetailId: PropTypes.number,
};

PaymentView.defaultProps = {
  isProviderDetail: false,
};

export default PaymentView;
