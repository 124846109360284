import React, { memo } from 'react';

import { Form } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import {
  Button,
  FormItem,
  DatePicker,
  AutoComplete,
  MultipleSelect,
  Select,
} from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import { RADIUSES } from '@/constants';
import { useLocations, useSkillTypes, useStatus } from '@/hooks/dropdowns';
import { applicationAutoCompleteService } from '@/services';
import { disabledDateOf } from '@/utils';

const ApplicationFields = ({ onClearFilter }) => {
  const { listStatuses } = useStatus();
  const { listLocations } = useLocations();
  const listRoles = useSkillTypes();

  const form = Form.useFormInstance();

  const startDate = Form.useWatch('registrationDateFrom', form);
  const endDate = Form.useWatch('registrationDateTo', form);

  const { data: dataSearchMail, refetch: refetchSearchMail } = useQuery(
    'getSearchcode',
    () => {
      const searchTerm = form.getFieldValue('searchTerm');

      return applicationAutoCompleteService.getAutoCompleteItems({
        filter: {
          search_term: searchTerm,
        },
      });
    },
    { refetchOnWindowFocus: false, enabled: false },
  );

  const handleSearchMail = debounce(refetchSearchMail, 500);

  return (
    <div className='application-fiedls'>
      <FormItem label='Search' name='searchTerm'>
        <AutoComplete
          onSearch={handleSearchMail}
          options={dataSearchMail}
          placeholder='Name or email address'
        />
      </FormItem>
      <FormItem label='Past Experience' name='preferredRoles'>
        <MultipleSelect
          options={listRoles}
          placeholder='Past Experience'
          name='preferredRoles'
        />
      </FormItem>
      <FormItem label='Location' name='location'>
        <MultipleSelect
          options={listLocations}
          placeholder='Location'
          name='location'
          showSearch={false}
        />
      </FormItem>
      <div className='applicant-date'>
        <p className='applicant-label'>Registration Date</p>
        <div className='row-fields'>
          <FormItem name='registrationDateFrom'>
            <DatePicker
              disabledDate={(disabledDate) =>
                disabledDateOf.Start(disabledDate, endDate)
              }
              placeholder='Select Date'
              value={startDate}
              name='registrationDateFrom'
            />
          </FormItem>
          <FormItem name='registrationDateTo'>
            <DatePicker
              disabledDate={(disabledDate) =>
                disabledDateOf.End(disabledDate, startDate)
              }
              placeholder='Select Date'
              value={endDate}
              name='registrationDateTo'
            />
          </FormItem>
        </div>
      </div>
      <FormItem label='Status' name='status'>
        <MultipleSelect
          placeholder='Status'
          options={listStatuses}
          name='status'
        />
      </FormItem>
      <FormItem label='Post Code' name='zip'>
        <PostCodeUK />
      </FormItem>
      <FormItem label='Radius' name='radius'>
        <Select options={RADIUSES} placeholder='Radius' />
      </FormItem>
      <div className='row-fields'>
        <Button
          className='yellow-button input-height style-btn'
          type='primary'
          htmlType='submit'
        >
          Search
        </Button>
        <Button
          className='btn-clear input-height style-btn'
          htmlType='button'
          onClick={onClearFilter}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default memo(ApplicationFields);

ApplicationFields.propTypes = {
  onClearFilter: PropTypes.func,
};
