import request from '@/request';

const updateStatus = async ({
  /** @type {string|number} */ timesheetId,
  /** @type {string[]} */ listTimecardIds,
  /** @type {string} */ status,
}) => {
  const res = await request.patch(
    `trackers/timekeeping/timesheets/${timesheetId}/timecards/update_status`,
    {
      timecard_ids: listTimecardIds,
      status,
    },
  );
  return res.data;
};

export default updateStatus;
