import request from '@/request';

const createPayrollTemplate = async (payload = {}) => {
  const res = await request.post('pay_templates/payroll_templates', {
    payroll_template: payload,
  });

  return res.data?.data;
};

export default createPayrollTemplate;
