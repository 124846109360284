import request from '@/request';

const editCommentSickNoShow = async (
  /** @type {string/number} */ timesheetId,
  /** @type {string/number} */ assignedScheduleId,
  /** @type {string} */ comment,
) => {
  // eslint-disable-next-line max-len
  const endpoint = `/trackers/timekeeping/timesheets/${timesheetId}/assigned_schedule_comments`;
  const res = await request.post(endpoint, {
    comment: {
      content: comment,
      assigned_schedule_id: assignedScheduleId,
    },
  });

  return res;
};
export default editCommentSickNoShow;
