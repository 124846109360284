import request from '@/request';

const sickNoShow = async ({ timesheetId, timecardIds, noShowAttributes }) => {
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/timecards/sick_no_show`;
  const res = await request.patch(endpoint, {
    timecardIds,
    noShowAttributes,
  });

  return res?.data;
};

export default sickNoShow;
