/* eslint-disable no-underscore-dangle */
/* eslint-disable no-bitwise */
import { message } from 'antd';
import { isEmpty, repeat, upperFirst, isNil, map } from 'lodash';
import moment from 'moment';

import { time as timeUtils } from '@/utils';
import { convertToISOString } from '@/utils/date';
import Formatter from '@/utils/Formatter';

import {
  END_OF_DAY,
  ERROR_TEMPLATE_COVER,
  START_OF_DAY,
  daysOfWeek,
} from '../constants';

const convertToSnakeCase = (str) =>
  str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

const convertFieldsObject = (obj) =>
  Object.keys(obj ?? {}).reduce((acc, currKey) => {
    const types = ['startAt', 'endAt'];
    const updateValue = types.includes(currKey)
      ? convertToISOString(obj[currKey])
      : obj[currKey];

    return {
      ...acc,
      [convertToSnakeCase(currKey)]: updateValue,
    };
  }, {});

const convertTrackingDataToNumber = (trackingData) =>
  map(trackingData, (timeInfo) => ({
    ...timeInfo,
    timeRange: map(timeInfo.timeRange, (timeRangeInfo) => {
      const startAt = Number(timeRangeInfo.startAt);
      const endAt = Number(timeRangeInfo.endAt);
      return {
        ...timeRangeInfo,
        startAt,
        endAt,
      };
    }),
  }));

const updateNextDay = (trackingData) => {
  const convertedTrackingData = convertTrackingDataToNumber(trackingData);
  return convertedTrackingData.map((data, index) => {
    const convertTimesToday = data.timeRange.map((time) => {
      if (time?.startAt >= time?.endAt) {
        return {
          ...time,
          endAt: END_OF_DAY,
        };
      }
      return time;
    });

    const lastDayIndex = convertedTrackingData.length - 1;
    const prevDay =
      index === 0
        ? convertedTrackingData[lastDayIndex]
        : convertedTrackingData[index - 1];

    const convertTimes = prevDay.timeRange
      .filter((time) => time?.startAt >= time?.endAt)
      .map((time) => ({
        ...time,
        startAt: START_OF_DAY,
      }));

    const updatedTimeRange = [...convertTimes, ...convertTimesToday];

    return {
      ...data,
      timeRange: updatedTimeRange,
    };
  });
};

const convertTime = (time) =>
  Formatter.fixedNumber({
    value: moment(time).hours() + moment(time).minutes() / 60,
  });

// Generate colors function by using hash stringValue
export const generateColor = ({ string, opacity }) => {
  const repeatedString = repeat(string, 3);
  const stringLength = repeatedString.length;
  let hash = 0;
  let i;

  for (i = 0; i < stringLength; i += 1) {
    hash = repeatedString.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = opacity ? 'rgba(' : '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    if (opacity) {
      color += `${value},`;
    } else {
      color += `00${value.toString(16)}`.slice(-2);
    }
  }

  if (opacity) {
    color += `${opacity})`;
  }

  return color;
};

const convertToTrackingData = (allValues) =>
  daysOfWeek.map((dayInfo) => {
    const listInfo = allValues.filter((value) => value?.[dayInfo.dataIndex]);
    const timeRange = listInfo.map((info, index) => {
      const startAt = convertTime(info.startAt);
      const endAt = convertTime(info.endAt);
      const color = generateColor({ string: info.name });

      return {
        startAt,
        endAt,
        color: !isEmpty(info.startAt) && !isEmpty(info.endAt) && color,
      };
    });
    return {
      dayOfWeek: dayInfo.label,
      timeRange,
    };
  });

const handleAddProvider = ({ form, listProviders = [], locations = [] }) => {
  const formData = form.getFieldsValue();

  const {
    employerIdOption = null,
    employerLocationIdsOptions = null,
    locationsSelected = [],
    providerSelected = {},
  } = formData;

  const foundProvider = listProviders.find(
    (provider) => provider.value === employerIdOption,
  );

  const isExistLocations = locationsSelected.some(
    (location) => location.value === employerLocationIdsOptions,
  );

  const foundLocation = locations.find(
    (location) =>
      location.value === employerLocationIdsOptions && !isExistLocations,
  );

  formData.providerSelected = foundProvider;

  if (foundLocation) {
    formData.locationsSelected = [...locationsSelected, foundLocation];
  }

  form.setFieldsValue(formData);

  if (providerSelected?.value) {
    if (providerSelected.value !== employerIdOption) {
      if (foundLocation) {
        form.setFieldsValue({
          locationsSelected: [foundLocation],
        });
      } else {
        form.setFieldsValue({
          locationsSelected: [],
        });
      }
    }
  }
};

export const genHourlyColumns = ({
  seekerName,
  renderCellValue,
  panelId,
  timecardId,
}) => [
  {
    children: [
      {
        title: renderCellValue({ label: seekerName, type: 'header' }),
        width: '10%',
        dataIndex: 'dayOfWeek',
        render: (day, record, indexRow) =>
          !isEmpty(day) &&
          indexRow === 0 &&
          renderCellValue({ label: upperFirst(day) }),
      },
    ],
  },
  {
    children: [
      {
        title: renderCellValue({ label: 'Shift Times' }),
        dataIndex: 'shiftTimes',
        width: '7%',
        render: (shiftTimeValue, _, indexRow) =>
          !isEmpty(shiftTimeValue) &&
          indexRow === 0 &&
          renderCellValue({ label: shiftTimeValue }),
      },
    ],
  },
  {
    title: renderCellValue({ label: 'Breakdown of total hours by day' }),
    dataIndex: 'payrollTemplate',
    children: [
      {
        title: renderCellValue({ label: 'Start' }),
        dataIndex: 'startAt',
        width: '5%',
        render: (startAtValue) => renderCellValue({ label: startAtValue }),
      },
      {
        title: renderCellValue({ label: 'End' }),
        dataIndex: 'endAt',
        width: '5%',
        render: (endAtValue) => renderCellValue({ label: endAtValue }),
      },
      {
        title: renderCellValue({ label: 'Time in pay template' }),
        dataIndex: 'timeInPayTemplate',
        children: [
          {
            dataIndex: 'totalTime',
            width: '4%',
            render: (timeInPayTemplate) =>
              renderCellValue({
                value: timeUtils.convertMinToHour(timeInPayTemplate)?._i,
              }),
          },
          {
            dataIndex: 'totalTimeUnit',
            width: '4%',
            render: (totalTimeUnit) => renderCellValue({ label: 'hours' }),
          },
        ],
      },
      {
        title: renderCellValue({ label: 'Pay' }),
        dataIndex: 'payRate',
        width: '6.4%',
        render: (pay, record, indexRow) =>
          renderCellValue({
            label: `£${pay}`,
            value: pay,
            type: 'number',
            prefix: '£',
            name: [panelId, `${timecardId}`, `${record.id}`, 'payRate'],
          }),
      },
      {
        title: renderCellValue({ label: 'Charge' }),
        dataIndex: 'chargeRate',
        width: '6.4%',
        render: (charge, record, indexRow) =>
          renderCellValue({
            label: `£${charge}`,
            value: charge,
            type: 'number',
            prefix: '£',
            name: [panelId, `${timecardId}`, `${record.id}`, 'chargeRate'],
          }),
      },
      {
        title: renderCellValue({ label: 'Template' }),
        dataIndex: 'templateName',
        width: '10%',
        render: (template, record, indexRow) =>
          renderCellValue({
            value: template,
            label: template,
            name: [panelId, `${timecardId}`, `${record.id}`, 'templateName'],
          }),
      },
      {
        title: renderCellValue({ label: 'Pay Template' }),
        dataIndex: 'payTemplateName',
        width: '10%',
        render: (payTemplateName, record) =>
          renderCellValue({
            value: payTemplateName,
            label: payTemplateName,
            type: 'text',
            name: [panelId, `${timecardId}`, `${record.id}`, 'templateName'],
          }),
      },
      {
        title: renderCellValue({ label: 'Pay' }),
        dataIndex: 'otPayRate',
        width: '6.4%',
        render: (totalPay, record, indexRow) =>
          renderCellValue({
            label: `£${totalPay}`,
            value: totalPay,
            prefix: '£',
            type: 'number',
            name: [panelId, `${timecardId}`, `${record.id}`, 'otPayRate'],
          }),
      },
      {
        title: renderCellValue({ label: 'Charge' }),
        dataIndex: 'otChargeRate',
        width: '6.4%',
        render: (totalCharge, record, indexRow) =>
          renderCellValue({
            label: `£${totalCharge}`,
            value: totalCharge,
            type: 'number',
            prefix: '£',
            name: [panelId, `${timecardId}`, `${record.id}`, 'otChargeRate'],
          }),
      },
    ],
  },
  {
    title: renderCellValue({ label: 'Amendemnts for Breaks' }),
    dataIndex: 'breaks',
    children: [
      {
        title: renderCellValue({ label: 'Break' }),
        dataIndex: 'break',
        width: '4%',
        render: (breakTime, record) =>
          renderCellValue({
            value: breakTime,
            name: [panelId, `${timecardId}`, `${record.id}`, 'break'],
            type: 'number',
          }),
      },
      {
        title: renderCellValue({ label: 'Time' }),
        dataIndex: 'time',
        width: '5%',
        render: (timeValue, record) =>
          renderCellValue({ value: 'minutes', type: 'text' }),
      },
      {
        title: renderCellValue({ label: 'Pay' }),
        dataIndex: 'pay',
        width: '5%',
        render: (pay, record) =>
          renderCellValue({
            value: pay,
            type: 'number',
            prefix: '£',
            name: [panelId, `${timecardId}`, `${record.id}`, 'pay'],
          }),
      },
      {
        title: renderCellValue({ label: 'Charge' }),
        dataIndex: 'charge',
        width: '5%',
        render: (charge, record) =>
          renderCellValue({
            value: charge,
            type: 'number',
            prefix: '£',
            name: [panelId, `${timecardId}`, `${record.id}`, 'charge'],
          }),
      },
    ],
  },
];

export const genDefaultColumns = ({
  renderCellValue,
  renderDay,
  panelId,
  timecard,
  currency,
}) => [
  {
    children: [
      {
        title: renderCellValue({ label: 'Name' }),
        dataIndex: 'seekerName',
        width: '10%',
        render: (name) => renderCellValue({ label: name }),
      },
    ],
  },
  {
    title: renderCellValue({ label: 'Breakdown of total hours by day' }),
    children: [
      ...renderDay,
      {
        title: renderCellValue({ label: 'Template' }),
        width: '9%',
        render: (template, record, indexRow) =>
          renderCellValue({
            label: record?.payrollTemplateName,
          }),
      },
      {
        title: renderCellValue({ label: 'Pay Template' }),
        width: '9%',
        render: (payTemplateName, record) =>
          renderCellValue({
            label:
              (record?.templateName ?? '') +
              (record?.isOverTime ? ' (OT)' : ''),
            isOverTime: record?.isOverTime,
          }),
      },
    ],
  },
  {
    children: [
      {
        title: renderCellValue({ label: 'Pay' }),
        dataIndex: 'payRate',
        width: '6%',
        render: (payRate, record) =>
          renderCellValue({
            label: payRate,
            value: payRate,
            isOverTime: record?.isOverTime,
            prefix: currency,
            type: 'number',
            name: [panelId, `${timecard.id}`, `${record.id}`, 'payRate'],
          }),
      },
      {
        title: renderCellValue({ label: 'Charge' }),
        dataIndex: 'chargeRate',
        width: '6%',
        render: (charge, record) =>
          renderCellValue({
            label: charge,
            value: charge,
            isOverTime: record?.isOverTime,
            prefix: currency,
            type: 'number',
            name: [panelId, `${timecard.id}`, `${record.id}`, 'chargeRate'],
          }),
      },
      {
        title: renderCellValue({ label: 'Hours' }),
        dataIndex: 'totalHours',
        width: '6%',
        render: (time, record) =>
          renderCellValue({
            value: Formatter.fixedNumber({ value: time }),
            isOverTime: record?.isOverTime,
          }),
      },
    ],
  },
  {
    title: renderCellValue({ label: 'Breaks' }),
    dataIndex: 'breaks',
    children: [
      {
        title: renderCellValue({ label: 'Break' }),
        dataIndex: ['breakInfo', 'breakDuration'],
        width: '4%',
        render: (breakDuration, record) =>
          renderCellValue({
            value: breakDuration,
            isOverTime: record?.isOverTime,
            name: [panelId, `${timecard.id}`, `${record.id}`, 'breakDuration'],
            type: 'number',
          }),
      },
      {
        title: renderCellValue({ label: 'Time' }),
        dataIndex: 'time',
        width: '5%',
        render: () =>
          renderCellValue({
            value: 'minutes',
            type: 'text',
          }),
      },
      {
        title: renderCellValue({ label: 'Hours' }),
        width: '4%',
        render: (data, record) => {
          const totalHours =
            (data?.totalHours ?? 0) -
            (data?.breakInfo?.breakDuration ?? 0) / 60;
          return renderCellValue({
            value: Formatter.fixedNumber({ value: totalHours }),
            isOverTime: record?.isOverTime,
          });
        },
      },
      {
        title: renderCellValue({ label: 'Total Pay' }),
        dataIndex: ['breakInfo', 'payRate'],
        width: '6%',
        render: (payRate, record) =>
          renderCellValue({
            value: Formatter.fixedNumber({ value: payRate }),
            type: 'number',
            prefix: currency,
            name: [panelId, `${timecard.id}`, `${record.id}`, 'otPayRate'],
            isOverTime: record?.isOverTime,
          }),
      },
      {
        title: renderCellValue({ label: 'Total Charge' }),
        dataIndex: ['breakInfo', 'chargeRate'],
        width: '6%',
        render: (chargeRate, record) =>
          renderCellValue({
            value: Formatter.fixedNumber({ value: chargeRate }),
            type: 'number',
            prefix: currency,
            name: [panelId, `${timecard.id}`, `${record.id}`, 'otChargeRate'],
            isOverTime: record?.isOverTime,
          }),
      },
    ],
  },
];
const updateTimeValue = (updateRules, timeField) => {
  const { hrs = 0, mins = 0 } = updateRules[timeField] ?? {};
  updateRules[timeField] = timeUtils.convertTimeValue({ hrs, mins });
};

const listLeft = (data) => {
  const initialRange = { startAt: START_OF_DAY, endAt: END_OF_DAY };

  data.sort((a, b) => a.startAt - b.startAt);
  for (const currentRange of data) {
    if (
      currentRange.startAt < initialRange.startAt ||
      (initialRange.startAt === initialRange.endAt &&
        currentRange.startAt &&
        currentRange.startAt)
    ) {
      return 'Overlapping template';
    }
    if (currentRange.startAt > initialRange.startAt) {
      return ERROR_TEMPLATE_COVER;
    }
    initialRange.startAt = currentRange.endAt;
  }

  if (initialRange.startAt < initialRange.endAt) {
    return ERROR_TEMPLATE_COVER;
  }

  return null;
};

const checkAllTimeChecked = (allDays) => {
  let data;
  allDays.every((day) => {
    if (!isEmpty(day.timeRange)) {
      data = listLeft(day.timeRange);
      return data == null;
    }
    data = ERROR_TEMPLATE_COVER;
    return false;
  });

  return data;
};

const checkBeforeUpdate = (formData) => {
  if (isEmpty(formData?.hourTemplates) || isNil(formData?.hourTemplates)) {
    message.error('Empty Pay Template');
    return false;
  }
  if (
    isEmpty(formData?.payrollTemplateRuleAttributes) ||
    isNil(formData?.payrollTemplateRuleAttributes)
  ) {
    message.error('Please Add Rules to save the Pay Template');
    return false;
  }
  return true;
};

const hasValue = (obj) => !(isNil(obj?.hrs) || !isNil(obj?.mins));

const getDeletedRow = ({ updateHrsTemplates, templateDetail }) =>
  templateDetail.hourTemplates.reduce((total, template) => {
    const notDeletedRow = updateHrsTemplates.find(
      (timecard) => Number(timecard?.id) === template.id,
    );

    if (!notDeletedRow) {
      const updateTemplate = {
        ...template,
        _destroy: true,
      };
      return [...total, updateTemplate];
    }

    return total;
  }, []);

export {
  convertToSnakeCase,
  convertFieldsObject,
  checkAllTimeChecked,
  checkBeforeUpdate,
  hasValue,
  updateNextDay,
  convertToTrackingData,
  handleAddProvider,
  updateTimeValue,
  getDeletedRow,
};
