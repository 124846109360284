import request from '@/request';

const create = async (/** @type {any} */ params) => {
  const result = await request.post('/trackers/expense/suppliers', {
    supplier: params,
  });
  return result;
};

export default create;
