import request from '@/request';

const addAllocateWeeks = async ({ employeesRotasAttributes, id }) => {
  const endpoint = `/rotas/rota_weeks/${id}`;

  const data = await request.patch(endpoint, {
    rotaWeek: {
      employeesRotasAttributes,
    },
  });

  return data;
};

export default addAllocateWeeks;
