import request from '@/request';

const createOmniUser = (values) => {
  const {
    userName,
    userFullname,
    userTels,
    userEmail,
    userPosition,
    userDepartmentId,
    holidayApproval,
    userLevelId,
    password,
    recruitment,
    timesheets,
    payroll,
    users,
    reports,
    departmentId,
  } = values;

  const payload = {
    omni_user: {
      user_name: userName,
      user_fullname: userFullname,
      user_tels: userTels,
      user_email: userEmail,
      user_position: userPosition,
      user_department_id: userDepartmentId,
      user_access: 0,
      holiday_approval: holidayApproval,
      department_id: departmentId,
      user_level_id: userLevelId,
      password,
      recruitment,
      provider: [], // Hard-code
      timesheets,
      invoices: [], // Hard-code
      seekers: [], // Hard-code
      jobs: [], // Hard-code
      payroll,
      users,
      reports,
    },
  };

  return request.post('omniusers/users', payload);
};

export default createOmniUser;
