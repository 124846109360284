import React from 'react';

import { Form, Spin } from 'antd';
import { useHistory } from 'react-router-dom';

import { Button, Input, FormItem } from '@/components';
import Formatter from '@/utils/Formatter';

import { calTotal } from '../../utils/calculation';

import './styles.scss';

const Header = () => {
  const history = useHistory();
  const formInstance = Form.useFormInstance();

  const rangeDate = Form.useWatch('paymentWeekDate', formInstance);
  const providerName = Form.useWatch('providerName', formInstance);
  const weekStartDate = Form.useWatch('weekStartDate', formInstance);
  const providerLocation = Form.useWatch('providerLocation', formInstance);

  const hoursSections = Form.useWatch('labour', formInstance) ?? [];
  const costSections = Form.useWatch('cost', formInstance) ?? [];

  const { totalInvoice } = calTotal([...hoursSections, ...costSections]);

  const handleNavigateTimesheet = () => {
    const timesheetId = formInstance.getFieldValue('timesheetId');
    history.push(`/pay-template-timesheet/${timesheetId}`);
  };

  return (
    <div className='invoice-header'>
      <div className='header-item row'>
        <FormItem name='timesheetId' hidden />
        <div className='text-container'>
          <FormItem name='providerName'>
            <p>{providerName ?? <Spin />}</p>
          </FormItem>
          <FormItem name='providerLocation'>
            <p>{providerLocation ?? <Spin />}</p>
          </FormItem>
          <FormItem name='weekStartDate'>
            <p>Week {weekStartDate ?? <Spin />}</p>
          </FormItem>
          <FormItem name='paymentWeekDate'>
            <p>{rangeDate ?? <Spin />}</p>
          </FormItem>
        </div>
        <div className='button-container'>
          <Button className='yellow-button' onClick={handleNavigateTimesheet}>
            View Timesheet
          </Button>
        </div>
      </div>
      <div className='header-item'>
        <FormItem
          name='invoiceId'
          label='Invoice Number'
          className='form-item'
          colon
        >
          <Input placeholder='Invoice Number will be auto create' />
        </FormItem>
        <FormItem
          name='invoiceDate'
          label='Date/Tax Point'
          className='form-item'
          colon
        >
          <Input disabled placeholder='Date/Tax Point' />
        </FormItem>
        <FormItem name='account' label='Account' className='form-item' colon>
          <Input placeholder='Account' />
        </FormItem>
      </div>
      <div className='header-item total-invoice'>
        <p>
          Total Invoice Amount: £
          {Formatter.fixedNumber({ value: totalInvoice })}
        </p>
      </div>
    </div>
  );
};

export default Header;
