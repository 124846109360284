const statuses = {
  notEnough: 0,
  contacted: 1,
  successAuto: 2,
  successPersonal: 3,
  rejectPermenant: 4,
  rejectNoRole: 5,
};

export { statuses };
