import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { STATUES_ACTIONS } from '@/pages/PayTemplate/Payroll/constants';
import DataProcessing from '@/pages/PayTemplate/Payroll/utils/dataProcessing';
import { PayrollService } from '@/services';
import { sickNoShow } from '@/services/payrollTemplateService';
import updatePayrollTimecard from '@/services/payrollTemplateService/updatePayrollTimecards';

const useActionMutations = ({ form, timesheetId, setIsEdit }) => {
  const queryClient = useQueryClient();
  const resetAfterMutation = ({ msg, closeEditMode = false }) => {
    message.success(msg);
    form.resetFields();
    queryClient.invalidateQueries({
      queryKey: ['listTimeSheet', timesheetId],
    });
    if (closeEditMode) {
      setIsEdit(false);
    }
  };

  const { mutate: updateStatusMutation, isLoading: isUpdateStatusLoading } =
    useMutation(
      (timecardAttributes) =>
        PayrollService.updateTimeCardAttributes({
          payrollId: timesheetId,
          timecardAttributes,
        }),
      {
        onSuccess: () => {
          resetAfterMutation({
            msg: 'Update Finalise Success',
          });
        },
      },
    );

  const {
    mutate: updateTimecardsMutation,
    isLoading: isUpdateTimecardsLoading,
  } = useMutation(
    (payrollTemplateHoursAttributes) =>
      updatePayrollTimecard({
        timesheetId,
        payrollTemplateHoursAttributes,
      }),
    {
      onSuccess: () => {
        resetAfterMutation({
          msg: 'Update timecards success',
          closeEditMode: true,
        });
      },
    },
  );

  const { mutate: sickNoShowMutation, isLoading: isUpdateSickNoShowLoading } =
    useMutation(
      ({ timecardIds, noShowAttributes }) =>
        sickNoShow({ timesheetId, timecardIds, noShowAttributes }),
      {
        onSuccess: (_, { label }) => {
          resetAfterMutation({ msg: `Update ${label} successfully!` });
        },
      },
    );

  const actionMutations = {
    [STATUES_ACTIONS.finalise.key]: {
      loading: isUpdateStatusLoading,
      action: ({ panelId }) => {
        const timecardAttributes = DataProcessing.updateStatus({
          formInstance: form,
          panelId,
          status: STATUES_ACTIONS.finalise.updateStatus,
        });
        updateStatusMutation(timecardAttributes);
      },
    },
    [STATUES_ACTIONS.finaliseAtPanel.key]: {
      loading: isUpdateStatusLoading,
      action: ({ panelInfo, actionType }) => {
        const timecardAttributes = DataProcessing.updateStatusAtPanel({
          panelInfo,
          actionType,
        });
        updateStatusMutation(timecardAttributes);
      },
    },
    [STATUES_ACTIONS.sick.key]: {
      loading: isUpdateSickNoShowLoading,
      action: ({ panelId }) => {
        const sickNoShowAttribute = DataProcessing.getSickNoShowData({
          formInstance: form,
          panelId,
          noShowType: STATUES_ACTIONS.sick.key,
        });
        sickNoShowMutation({
          ...sickNoShowAttribute,
          label: STATUES_ACTIONS.sick.label,
        });
      },
    },
    [STATUES_ACTIONS.noShow.key]: {
      loading: isUpdateSickNoShowLoading,
      action: ({ panelId }) => {
        const sickNoShowAttribute = DataProcessing.getSickNoShowData({
          formInstance: form,
          panelId,
          noShowType: STATUES_ACTIONS.noShow.subKey,
        });
        sickNoShowMutation({
          ...sickNoShowAttribute,
          label: STATUES_ACTIONS.noShow.label,
        });
      },
    },
    [STATUES_ACTIONS.save.key]: {
      loading: isUpdateTimecardsLoading,
      action: ({ panelId }) => {
        const payrollTemplateHoursAttributes = DataProcessing.updateTimecards({
          formInstance: form,
          panelId,
        });
        updateTimecardsMutation(payrollTemplateHoursAttributes);
      },
    },
  };
  return actionMutations;
};

export default useActionMutations;
