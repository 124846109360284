import React, { useRef, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Modal as AntdModal, Form, message, Progress } from 'antd';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';

import { Button, FormItem, CheckboxGroup } from '@/components';
import { useReactToPdf } from '@/hooks';
import { InvoicePayTemplateService } from '@/services';
import uploadFileS3 from '@/utils/uploadFileS3';

import PreviewInvoice from '../PreviewInvoice';

import './styles.scss';

const CONFIRM_OPTION = [
  { label: 'Confirm changes and create Invoice', value: '1' },
];

const UPLOAD_MSG = 'Uploading Invoice PDF';

const Footer = () => {
  const pdfRef = useRef();
  const history = useHistory();
  const { toPdf } = useReactToPdf({
    targetRef: pdfRef,
  });
  const formInstance = Form.useFormInstance();
  const [isShowPreview, toggleShowPreview] = useToggle();
  const [isViewedInvoice, setViewedInvoice] = useState(false);
  const [uploadedProgress, setUploadedProgress] = useState(0);
  const [pdfBlob, setPdfBlob] = useState(null);
  const isConfirmedInvoice = Form.useWatch('confirmed', formInstance);

  const hoursSections = Form.useWatch('labour', formInstance) ?? [];
  const costSections = Form.useWatch('cost', formInstance) ?? [];

  const isDisabledPreview = isEmpty(hoursSections) && isEmpty(costSections);

  const { mutate: createInvoiceMutation, isLoading: isCreatingInvoice } =
    useMutation(
      async ({ invoice }) => {
        const { invoiceId } = invoice;
        const fileNamePdf = `invoice-pay-template-${invoiceId}.pdf`;

        const { getUrl: filename } = await uploadFileS3(
          fileNamePdf,
          pdfBlob,
          setUploadedProgress,
        );
        return InvoicePayTemplateService.saveInvoice({ ...invoice, filename });
      },
      {
        onSuccess: () => {
          message.success('Create invoice successfully');
          history.push('/invoicing');
        },
        onError: (error) =>
          message.error(error.message ?? 'Create invoice failed'),
      },
    );

  const handleConfirmInvoice = async () => {
    const doc = document.getElementById('invoice-pdf-container');

    const maxHeight = Math.max(doc?.scrollHeight, doc?.offsetHeight);
    const maxWidth = doc?.scrollWidth;

    const { blob } = await toPdf({ width: maxWidth, height: maxHeight });

    setPdfBlob(blob);
    setViewedInvoice(true);
    toggleShowPreview();
  };

  const handleCreateInvoice = () => {
    const formData = formInstance.getFieldsValue();

    const invoiceDetailsAttributes = [...hoursSections, ...costSections];
    const invoice = {
      ...formData,
      invoiceDetailsAttributes,
    };

    createInvoiceMutation({ invoice });
  };

  return (
    <div className='invoice-footer'>
      <div className='footer-item-left'>
        <Button
          className='yellow-button preview-invoice'
          onClick={toggleShowPreview}
          disabled={isDisabledPreview}
        >
          Preview Invoice
        </Button>
      </div>
      <div className='footer-item-right'>
        <FormItem name='confirmed'>
          <CheckboxGroup
            className='confirm-check'
            options={CONFIRM_OPTION}
            disabled={isConfirmedInvoice}
          />
        </FormItem>
        <Tooltip
          title='Please preview and confirm invoice'
          placement='topRight'
        >
          <InfoCircleOutlined className='info-icon' />
        </Tooltip>
        <Button
          className='yellow-button create-invoice-btn'
          onClick={handleCreateInvoice}
          disabled={!isViewedInvoice || !isConfirmedInvoice}
        >
          Create Invoice
        </Button>
      </div>
      <AntdModal
        visible={isShowPreview}
        onCancel={toggleShowPreview}
        wrapClassName='invoice-preview'
        footer={null}
      >
        <PreviewInvoice pdfRef={pdfRef} />
        <div className='confirm-btn-container'>
          <Button
            className='yellow-button confirm-button'
            onClick={handleConfirmInvoice}
          >
            Confirm invoice
          </Button>
        </div>
      </AntdModal>
      <AntdModal
        visible={isCreatingInvoice}
        className='uploaded-invoice-modal'
        footer={null}
        closable={false}
      >
        <Progress
          type='circle'
          percent={uploadedProgress.toFixed()}
          width={70}
        />
        <span className='uploaded-label'>{UPLOAD_MSG}</span>
      </AntdModal>
    </div>
  );
};

export default Footer;
