import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';

const downloadTimesheetCsv = async (id) => {
  try {
    const endpoint = `/trackers/timekeeping/timesheets/${id}/export_xls`;

    const res = await request.get(endpoint, { responseType: 'blob' });

    return res.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};

export default downloadTimesheetCsv;
