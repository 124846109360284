import React from 'react';

const RotaTypeInfo = () => (
  <div>
    <p>
      If a worker is allocated as Flexible then they can see All shifts posted
      on the system.
    </p>
    <p>
      If a workers is allocated to Specific Sites they will only see shifts from
      those specific sites
    </p>
    <p>
      If a worker is allocated to a Rota they can also be allocated to specific
      sites or flexible to see all the other shifts available on either of those
      options in addition to their Rota.
    </p>
  </div>
);

export default RotaTypeInfo;
