import React, { useState } from 'react';

import { Row } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';

import openFilePDFNewTab from '@/utils/openFilePDFNewTab';

// eslint-disable-next-line max-len
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const DEFAULT_PAGE_REVIEW = 1;

const PDF = ({ className, fileName, isPreview, width }) => {
  const [numPages, setNumPages] = useState(DEFAULT_PAGE_REVIEW);

  const onDocumentLoadSuccess = ({ numPages: numberPages }) => {
    if (isPreview) return setNumPages(DEFAULT_PAGE_REVIEW);
    return setNumPages(numberPages);
  };

  return (
    <Row onClick={() => openFilePDFNewTab(fileName)}>
      <Document
        className={classNames('pdf-container', className)}
        file={fileName}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={width}
          />
        ))}
      </Document>
    </Row>
  );
};

PDF.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string,
  isPreview: PropTypes.bool, // flag display PDF only first page
  width: PropTypes.number,
};

PDF.defaultProps = {
  isPreview: true,
};

export default PDF;
