import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getInvoiceDetails = async (/** @type {string|number} */ id) => {
  const res = await request.get(`invoices/timesheets/${id}/invoice_details`);

  const normalizeResponses = normalizeIndexResponse(res);

  return normalizeResponses.data;
};

export default getInvoiceDetails;
