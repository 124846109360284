import React from 'react';

import { flatMap, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as LogoGiG } from '@/assets/images/logo-gig.svg';
import { ListTimesheets, WeeklyTotals } from '@/components';

import './styles.scss';
import { DAYS_OF_WEEK } from '../constants';

const STATUS_CODES = {
  unapproved: {
    content: 'Not Approved - No action taken',
    color: 'red',
  },
  approved: {
    content: 'Approved - Timesheets approved in WebApp',
    color: 'yellow',
  },
  confirmed: {
    content: 'Confirmed - Timesheet confirmed for payroll',
    color: 'green',
  },
  paid: {
    content: 'Paid - Payroll has been processed and paid',
    color: 'yellow',
  },
};

const listTables = [
  {
    status: 'confirmed',
    tableColor: 'red',
    statusColor: STATUS_CODES.confirmed.color,
    colorButtonSave: 'gray',
    colorButtonApprove: 'gray',
  },
  {
    status: 'finalised',
    tableColor: 'red',
    statusColor: STATUS_CODES.confirmed.color,
    colorButtonSave: 'gray',
    colorButtonApprove: 'gray',
  },
];

const allowedStatus = ['confirmed', 'finalised'];

const ViewTimesheetPDF = ({
  jobs,
  weeklyTotal,
  pushedRecords,
  weekEndDate,
  weekStartDate,
  locationName,
  employerName,
  timesheetFetching,
}) => {
  const allRecords = () => {
    const flatedJobs = flatMap(jobs || {}, (innerObject) =>
      flatMap(innerObject),
    );
    const flatedPushedJobs = flatMap(pushedRecords || {}, (innerObject) =>
      flatMap(innerObject),
    );

    return [...flatedJobs, ...flatedPushedJobs];
  };

  const isEmptyInvoice = allRecords().some(
    (record) => !allowedStatus.includes(record.status),
  );

  const convertToHour = (formattedString) => {
    const hours = Math.floor(Number(formattedString));
    const ratioMinute = Number(formattedString.split('.')[1]) / 60;

    return hours + ratioMinute;
  };

  const parseWeeklyTotals = (weeklyTotal ?? []).map((item) => {
    let isCurrentWeek = false;
    const { timesheet_detail_ids: timesheetDetailIds } = item;
    const timesheetDetails = timesheetDetailIds.map((id) => {
      const timesheetDetail = allRecords().find(
        (record) => record.timesheet_detail_id === id,
      );
      isCurrentWeek = isNil(timesheetDetail?.push_from_timesheet_id);
      return timesheetDetail;
    });
    return {
      ...item,
      timesheetDetails,
      isCurrentWeek,
    };
  });

  const week = weekStartDate ? moment(weekStartDate).week() : null;
  const timeSheetWeek =
    ![weekEndDate, weekStartDate, week].includes(undefined) &&
    `Timesheet Week ${week} - ${weekStartDate} to ${weekEndDate}`;

  const timeSheetTitle =
    ![locationName, employerName].includes(undefined) &&
    [locationName, employerName].join(', ');

  const handleTotalDetail = (records) => {
    const groupedRecords = records.reduce((acc, record) => {
      const {
        job_type: jobType,
        charge_rate: chargeRate,
        status,
        total_charge: totalCharge,
      } = record;

      const key = `${jobType}-${chargeRate}`;

      if (!acc[key]) {
        acc[key] = {
          jobType,
          totalCharge,
          chargeRate,
          totalWeeklyHours: 0,
        };
      } else {
        acc[key].totalCharge += totalCharge;
      }

      Object.values(DAYS_OF_WEEK).forEach((currDay) => {
        const pushFromWeekId = record?.push_from_timesheet_id ?? false;
        const isPushed = pushFromWeekId ? 'pushed ' : '';
        const recordKey = `${isPushed}${currDay} ${jobType} ${status} total`;
        const sicknessKey = `${isPushed}${currDay} ${jobType} ${status} sickness`;
        const isSickness = record?.[sicknessKey];
        const currDayRecordTotal = !isSickness
          ? convertToHour(record[recordKey])
          : 0;

        acc[key][currDay] = Number(acc[key][currDay] || 0) + currDayRecordTotal;

        acc[key].totalWeeklyHours += currDayRecordTotal;
      });
      return acc;
    }, {});

    return Object.values(groupedRecords);
  };

  return (
    <div id='timesheet-pdf-container' className='view-timesheet-pdf'>
      <div className='header'>
        <div className='logo'>
          <LogoGiG />
        </div>

        <div className='content'>
          <div className='desc'>
            Timesheet Summary for services provided and charged for in the
            attached invoice.
          </div>

          <div className='names-provider-and-times'>
            {timeSheetTitle}. {timeSheetWeek}
          </div>
        </div>
      </div>
      <div className='main'>
        {week && <div className='week'>Week {week}</div>}
        <div className='cards'>
          {listTables.map(({ status, ...props }) =>
            Object.keys(jobs[status] || {}).map(
              (jobType) =>
                jobs[status][jobType] && (
                  <div className='row'>
                    <ListTimesheets
                      {...props}
                      dateRange={
                        jobs[status][jobType][0].date_range_timecard || []
                      }
                      loading={timesheetFetching}
                      labelTotal={`${jobType} Totals:`}
                      infoDetail={Object.values(jobs[status][jobType] || {})}
                      className='timesheet-table'
                      jobType={jobType}
                      status={status}
                    />
                  </div>
                ),
            ),
          )}
        </div>
        <div className='cards'>
          {listTables.map(({ status, ...props }) =>
            Object.keys(pushedRecords[status] || {}).map(
              (jobType) =>
                pushedRecords[status][jobType] && (
                  <div className='row'>
                    <ListTimesheets
                      {...props}
                      dateRange={
                        pushedRecords[status][jobType][0].date_range_timecard ||
                        []
                      }
                      loading={timesheetFetching}
                      labelTotal={`${jobType} Totals:`}
                      infoDetail={Object.values(
                        pushedRecords[status][jobType] || {},
                      )}
                      className='timesheet-table'
                      jobType={jobType}
                      status={status}
                      tableColor='gray'
                    />
                  </div>
                ),
            ),
          )}
        </div>
        {!isEmpty(parseWeeklyTotals) &&
          !isEmptyInvoice &&
          parseWeeklyTotals.map((item) => {
            const totalDetail = handleTotalDetail(item.timesheetDetails);
            return (
              <div className='cards'>
                <WeeklyTotals
                  tableColor={item.isCurrentWeek ? 'yellow' : 'gray'}
                  infoDetail={totalDetail}
                  totalWeeklyHours={item.total_hours}
                  totalCharge={item.total_charge}
                  weeklyTotal={item.timesheetDetails.length}
                  dateRange={item.date_range}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
ViewTimesheetPDF.propTypes = {
  jobs: PropTypes.object,
  weeklyTotal: PropTypes.array,
  pushedRecords: PropTypes.object,
  weekEndDate: PropTypes.string,
  weekStartDate: PropTypes.string,
  locationName: PropTypes.string,
  employerName: PropTypes.string,
  timesheetFetching: PropTypes.bool,
};

ViewTimesheetPDF.defaultProps = {
  jobs: {},
  weeklyTotal: [],
  pushedRecords: {},
  timesheetFetching: false,
};
export default ViewTimesheetPDF;
