import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const fetchCertificates = async (type) => {
  const res = await request.get('/options/certificates', {
    params: {
      type,
    },
  });

  const data = normalizeIndexResponse(res);

  return data.data;
};
