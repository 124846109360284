const paymentMethods = [
  { value: 'bacs_bank', label: 'BACS -  Bank' },
  { value: 'cheque', label: 'Cheque' },
  { value: 'bacs_bs', label: 'BACS - Building Soc' },
];

const hearAboutUs = [
  { value: 'Google', label: 'Google' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Indeed', label: 'Indeed' },
  { value: 'Monster', label: 'Monster' },
  { value: 'Caterer', label: 'Caterer' },
  { value: 'CV Library', label: 'CV Library' },
  { value: 'Event', label: 'Event' },
  { value: 'World of mouth', label: 'World of mouth' },
  { value: 'Other', label: 'Other' },
];

const workingTimeDirective = [
  { label: 'Opted Out', value: 0 },
  { label: 'Opted In', value: 1 },
];

export { paymentMethods, hearAboutUs, workingTimeDirective };
