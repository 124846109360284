import React, { useState } from 'react';

import { Spin, Switch } from 'antd';
import { useParams } from 'react-router-dom';

import { GeneralTemplate } from '@/components';

import PayrollDetailPayTemplate from './PayrollDetailPayTemplate';

const PayrollPayTemplateDetail = () => {
  const { id } = useParams();
  const [weekNumber, setWeekNumber] = useState();
  const [providerName, setProviderName] = useState();
  const [isHourlyTemplate, setIsHourlyTemplate] = useState();
  const [locationName, setLocationName] = useState();
  const breadcrumb = [
    { item: 'Payroll', to: '/payroll' },
    {
      item: providerName ?? <Spin />,
      to: `/payroll-detail/${id}`,
    },
    { item: weekNumber ? `Week ${weekNumber}` : <Spin />, to: '#' },
    {
      item: locationName ?? <Spin />,
    },
  ];

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadcrumb}
      headerText={breadcrumb.at(2).item}
      hasButton={false}
      className='timesheets-container payroll-container'
    >
      <div className='timesheet-detail-options'>
        <div>View</div>
        <div className='toggle'>
          <span>Total hours per day </span>
          <Switch onChange={setIsHourlyTemplate} />
          <span>Shift breakdown by Hourly Template</span>
        </div>
      </div>

      <PayrollDetailPayTemplate
        weekNumber={weekNumber}
        setWeekNumber={setWeekNumber}
        setProviderName={setProviderName}
        setLocationName={setLocationName}
        isHourlyTemplate={isHourlyTemplate}
      />
    </GeneralTemplate>
  );
};

export default PayrollPayTemplateDetail;
