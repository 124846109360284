import { useQuery } from 'react-query';

import { getIndustryExperiences } from '@/services/dropdownServices/getIndustryExperiences';

const useIndustryExperiences = () => {
  const { data } = useQuery(['industriesExperiences'], getIndustryExperiences, {
    refetchOnWindowFocus: false,
  });

  return data;
};

export default useIndustryExperiences;
