/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';

/**
 * @typedef {Object} TDynamicTable
 * @property {React.MutableRefObject<HTMLElement[]>} nodesRef
 * @property {number} tableHeight
 */

/**
 * @param {number} limitRow default limitRow is 3
 * @returns {TDynamicTable} return TDynamicTable
 */
const useDynamicTableHeight = (limitRow = 3) => {
  const nodesRef = useRef([]);

  const [tableHeight, setTableHeight] = useState(0);

  const handleResize = () => {
    if (nodesRef.current?.length) {
      setTableHeight(
        nodesRef.current
          .filter((node) => node?.offsetHeight > 0)
          .slice(0, limitRow)
          .reduce((total, node) => total + node.offsetHeight, 0),
      );
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return { nodesRef, tableHeight };
};

export default useDynamicTableHeight;
