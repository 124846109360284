/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { Spin, Tooltip } from 'antd';
import fileDownload from 'js-file-download';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import downloadIcon from '@/assets/icons/download.png';
import { GeneralTemplate } from '@/components';
import { TimesheetService } from '@/services';

import TimesheetDetail from './TimesheetDetail';
import './index.scss';

const Detail = () => {
  const { id } = useParams();
  const [header, setHeader] = useState({});

  const { mutate: downloadTimesheetCSV } = useMutation(
    ['download-timesheet-csv', id],
    () =>
      TimesheetService.downloadTimesheet(id).then((response) => {
        fileDownload(response, `timesheet${id}.xlsx`);
      }),
  );

  const breadcrumb = [
    { item: 'Timesheets', to: '/timesheets' },
    {
      item: header?.providerName ?? <Spin />,
      to: `/timesheet-detail/${id}`,
      buttonCustom: (
        <Tooltip title='download time sheet' placement='topLeft'>
          <div className='button-download' onClick={downloadTimesheetCSV}>
            <img
              className='download-icon'
              src={downloadIcon}
              alt='download-icon'
            />
          </div>
        </Tooltip>
      ),
    },
    {
      item: header?.weekNumber ? `Week ${header?.weekNumber}` : <Spin />,
      to: '#',
    },
    { item: header?.locationName ? header?.locationName : <Spin />, to: '#' },
  ];

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadcrumb}
      headerText={breadcrumb.at(2).item}
      hasButton
      className='timesheets-container'
      buttonCustom={breadcrumb.at(1).buttonCustom}
    >
      <TimesheetDetail setHeader={setHeader} />
    </GeneralTemplate>
  );
};

export default Detail;
