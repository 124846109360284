/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';

import { Alert } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

const ErrorsAlert = ({ message, details, className, ...props }) => (
  <Alert
    description={
      <>
        {details.map((detail) => (
          <Fragment key={detail}>
            {detail}
            <br />
          </Fragment>
        ))}
      </>
    }
    type='error'
    message={message || 'Error'}
    className={classNames('errors-alert', className)}
    {...props}
  />
);

ErrorsAlert.propTypes = {
  message: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default ErrorsAlert;
