import request from '@/request';

const updatePayrollTemplate = async ({ payload, templateId }) => {
  const res = await request.put(
    `pay_templates/payroll_templates/${templateId}`,
    {
      payroll_template: payload,
    },
  );

  return res.data?.data;
};

export default updatePayrollTemplate;
