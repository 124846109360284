import { isEmpty } from 'lodash';

import { ORDER_BY } from '@/constants';
import request from '@/request';
import { array, date } from '@/utils';

import { getAddressInfomation } from '../getAddressInfomation';

const getUndefinedOrValue = (data) =>
  isEmpty(String(data)) ? undefined : data;

const downloadCsv = async ({
  radius,
  statuses,
  jobRoles,
  sortOrder,
  locations,
  searchTerm,
  industries,
  generalRole,
  providerName,
  registrationDateTo,
  registrationDateFrom,
  shiftsPostedDateFrom,
  shiftsPostedDateTo,
  zip,
}) => {
  const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

  const filter = {
    status: array.remove(statuses, 'all'),
    id: array.remove(providerName, 'all'),
    roleIds: array.remove(jobRoles, 'all'),
    officeId: array.remove(locations, 'all'),
    industryIds: array.remove(industries, 'all'),
    managerIds: array.remove(generalRole, 'all'),
    searchTerm: getUndefinedOrValue(searchTerm),
    shiftPostedTo: date.convertDateTime(shiftsPostedDateTo),
    shiftPostedFrom: date.convertDateTime(shiftsPostedDateFrom),
    registrationDateTo: date.convertDateTime(registrationDateTo),
    registrationDateFrom: date.convertDateTime(registrationDateFrom),
    latitude: geogecode?.[0]?.geometry?.location?.lat,
    longitude: geogecode?.[0]?.geometry?.location?.lng,
    postcode: geogecode?.[0]?.address_components?.[0]?.long_name,
    radius,
  };

  const order = {
    orderBy: ORDER_BY,
    orderDirection: sortOrder,
  };

  return (
    await request.get('providers/employers/csv', {
      params: {
        order,
        filter,
      },
    })
  ).data;
};

export default downloadCsv;
