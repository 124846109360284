import React from 'react';

import moment from 'moment';

const columnsComment = [
  {
    title: 'Comment',
    dataIndex: 'content',
    key: 'content',
    render: (content) => <p className='wrap-content-lg'>{content}</p>,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user) => <p className='wrap-content-md'>{user}</p>,
  },
  {
    title: 'Date/Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => (
      <p className='wrap-content-md'>
        {moment(createdAt).format('DD/MM/YYYY hh:mm A')}
      </p>
    ),
  },
];

const listBlock = [
  {
    label: 'Visa or Right to Work',
    value: 0,
  },
  {
    label: '3 Strikes',
    value: 1,
  },
  {
    label: 'Behaviour',
    value: 2,
  },
];

const listStatus = [
  {
    label: 'Active',
    value: 0,
  },
  {
    label: 'Resigned - P45',
    value: 1,
  },
  {
    label: 'Terminated - P45',
    value: 2,
  },
];

export { columnsComment, listBlock, listStatus };
