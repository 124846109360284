import {
  filter,
  get,
  groupBy,
  keyBy,
  map,
  mapValues,
  omit,
  range,
} from 'lodash';
import moment from 'moment';

import { convertToISOString } from '@/utils/date';

import {
  ASSIGNED_SCHEDULES_STATUSES,
  HOURS_PER_DAY,
  TYPE_CHECKING,
} from '../constant';

const assignedSchedules = ({ form, type }) => {
  const formData = form.getFieldsValue();
  const timePicked = get(formData, 'timePicker');
  const createdAt = convertToISOString(timePicked);
  const omittedFields = omit(formData, [
    'isSelectCheckInAll',
    'isSelectCheckOutAll',
    'timePicker',
    'datePicker',
  ]);
  const filtered = filter(omittedFields, {
    [TYPE_CHECKING[type].fieldItem]: true,
  });

  const assignedSchedulesAttributes = map(filtered, ({ id }) => ({
    id,
    status: TYPE_CHECKING[type].updateStatus,
    createdAt,
  }));

  return assignedSchedulesAttributes;
};

const assignedSchedulesSingleRecord = ({ form, id, type }) => {
  const recordDataForm = form.getFieldValue(id);
  const timePicked = form.getFieldValue('timePicker');
  const createdAt = convertToISOString(timePicked);
  const isAssignType = ASSIGNED_SCHEDULES_STATUSES.includes(type);

  const assignedSchedulesAttributes = [
    {
      id: recordDataForm.id,
      status: TYPE_CHECKING[type].updateStatus,
      ...(isAssignType && { createdAt }),
    },
  ];
  return assignedSchedulesAttributes;
};

const createHoursWithOutRange = ({ start, end }) =>
  range(HOURS_PER_DAY).filter((hour) => !range(start, end + 1).includes(hour));

const getSelectedIds = ({ form, selectedShift, type }) => {
  const formData = omit(form.getFieldValue([selectedShift]), 'checkedAll');
  return map(filter(formData, (seeker) => seeker.checked[type]));
};

const initialValuesForm = (data) => {
  const shiftTimes = map(data, (shift) => {
    const startTime = moment.unix(shift.startTime);
    const endTime = moment.unix(shift.endTime);
    const shiftTime = `${startTime.format('HH:mm')} - ${endTime.format(
      'HH:mm',
    )}`;
    return {
      ...shift,
      shiftTime,
      startTime,
      endTime,
    };
  });
  const dataGroupByDate = groupBy(shiftTimes, 'shiftTime');
  const keyByIds = mapValues(dataGroupByDate, (shift) => keyBy(shift, 'id'));

  return keyByIds;
};

const PrepareData = {
  assignedSchedules,
  assignedSchedulesSingleRecord,
  initialValuesForm,
  getSelectedIds,
  createHoursWithOutRange,
};

export default PrepareData;
