import request from '@/request';

const resetPassword = async (id) => {
  const endpoint = `seekers/employees/${id}/reset_password`;
  const res = await request.patch(endpoint);

  return res?.data;
};

export default resetPassword;
