import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { DatePicker, FormItem } from '@/components';
import useHandleCancelDatePicker from '@/hooks/useCancelDatePicker';
import { disabledDateOf } from '@/utils';

const SelectDate = ({ startDateFieldName, endDateFieldName, label }) => {
  const form = Form.useFormInstance();
  const startDate = Form.useWatch(startDateFieldName, form);
  const endDate = Form.useWatch(endDateFieldName, form);

  const handleCancelClickFrom = useHandleCancelDatePicker(
    form,
    startDateFieldName,
  );
  const handleCancelClickTo = useHandleCancelDatePicker(form, endDateFieldName);

  return (
    <div className='date-selecter'>
      <FormItem
        name={startDateFieldName}
        className='form-item date-container'
        label={label}
      >
        <DatePicker
          disabledDate={(disabledDate) =>
            disabledDateOf.Start(disabledDate, endDate)
          }
          placeholder='Select Date'
          value={startDate}
          name={startDateFieldName}
          onCancelClick={handleCancelClickFrom}
        />
      </FormItem>

      <FormItem name={endDateFieldName} className='form-item date-container'>
        <DatePicker
          disabledDate={(disabledDate) =>
            disabledDateOf.End(disabledDate, startDate)
          }
          placeholder='Select Date'
          value={endDate}
          name={endDateFieldName}
          onCancelClick={handleCancelClickTo}
        />
      </FormItem>
    </div>
  );
};

SelectDate.propTypes = {
  startDateFieldName: PropTypes.string,
  endDateFieldName: PropTypes.string,
  label: PropTypes.string,
};

SelectDate.defaultProps = {
  startDateFieldName: '',
  endDateFieldName: '',
  label: '',
};

export default SelectDate;
