/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, message, Row, Upload } from 'antd';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  Button,
  CheckboxGroup,
  FormHeader,
  FormItem,
  Input,
  Select,
  Textarea,
} from '@/components';
import FormLogo from '@/components/FormLogo';
import GuestFormCard from '@/components/GuestFormCard';
import { LIMIT_PHONE_NUMBER } from '@/constants';
import { useLocations, useSkillTypes } from '@/hooks/dropdowns';
import { useUploadFile } from '@/hooks/useUploadFile';
import { GuestService } from '@/services';
import openFilePDFNewTab from '@/utils/openFilePDFNewTab';
import {
  rulesEmail,
  rulesPhone,
  rulesPostcode,
  rulesSelect,
  rulesTextInput,
} from '@/utils/rulesInput';
import { beforeUploadFile, dummyRequest } from '@/utils/uploadFileUtil';
import './styles.scss';
import { useTenantContext } from '../../TenantWrapper';

export const JobApplication = () => {
  const [form] = Form.useForm();
  const tenant = useTenantContext();

  const listRoles = useSkillTypes();

  const inputs = [
    {
      label: 'First Name',
      rules: rulesTextInput('First Name'),
      type: 'input',
      name: 'firstname',
      className: 'half-container',
      maxLength: 48,
    },
    {
      label: 'Last Name',
      rules: rulesTextInput('Last Name'),
      type: 'input',
      name: 'lastname',
      className: 'half-container',
      maxLength: 48,
    },
    {
      label: 'Email',
      rules: rulesEmail(),
      type: 'input',
      name: 'email',
      className: 'half-container',
    },
    {
      label: 'Phone Number',
      rules: rulesPhone(),
      type: 'input',
      name: 'phone',
      className: 'half-container',
      maxLength: LIMIT_PHONE_NUMBER,
    },
    {
      label: 'Post Code',
      rules: rulesPostcode(form, 'zip'),
      type: 'input',
      name: 'zip',
      className: 'half-container',
    },
    {
      label: 'Choose your local area',
      rules: rulesSelect('your local area'),
      type: 'select',
      name: 'officeId',
      className: 'half-container',
    },
    {
      label: 'What do you have past experience working in?',
      type: 'checkbox',
      name: 'preferredRoles',
      options: listRoles,
      className: 'full-container',
    },
  ];

  const history = useHistory();
  const [url, changeFile, fileList] = useUploadFile();
  const { listLocations } = useLocations();

  const { mutate: createApplication, isLoading } = useMutation(
    () => GuestService.createApplication({ form }),
    {
      onError: (error) => {
        message.error(error?.message);
      },
      onSuccess: (id) => {
        message.success('Create new application success!');
        history.push('/thank-you-for-submitting', {
          id,
        });
      },
    },
  );

  return (
    <div className='application-form-wrapper'>
      <FormHeader />
      <FormLogo />
      <GuestFormCard>
        <div className='form-content-container'>
          <Form
            form={form}
            className='form-container'
            onFinish={createApplication}
            scrollToFirstError={{
              skipOverflowHiddenElements: true,
              block: 'center',
            }}
          >
            <h1 className='title'>Job Application</h1>
            <Row className='form-input' gutter={10}>
              {inputs.map((item) => {
                const inners = {
                  input: (
                    <Input
                      placeholder={item.label}
                      maxLength={item.maxLength}
                    />
                  ),
                  select: (
                    <Select placeholder={item.label} options={listLocations} />
                  ),
                  checkbox: <CheckboxGroup options={item.options} />,
                };
                return (
                  <Col
                    className='gutter-row form-input-container'
                    xs={item.name === 'preferredRoles' ? 24 : 12}
                  >
                    <FormItem
                      name={item.name}
                      label={item.label}
                      rules={item.rules}
                      key={item.label}
                      className={item.className}
                      required={item.type !== 'checkbox'}
                    >
                      {inners[item.type]}
                    </FormItem>
                  </Col>
                );
              })}
            </Row>
            <div className='upload-container'>
              <FormItem
                label='Upload your CV (pdf or word only)'
                name='cvUpload'
              >
                <Upload
                  name='cvUpload'
                  fileList={fileList}
                  onChange={changeFile}
                  showUploadList={false}
                  customRequest={dummyRequest}
                  beforeUpload={beforeUploadFile}
                  accept='.doc, application/msword, .pdf, application/pdf'
                  multiple={false}
                >
                  <Button className='shared-button btn-upload'>
                    Upload your CV
                    <PlusOutlined />
                  </Button>
                </Upload>
              </FormItem>
              {url && (
                <span
                  className='filename'
                  onClick={() => openFilePDFNewTab(url)}
                >
                  {fileList.at(-1)?.originFileObj?.name}
                </span>
              )}
            </div>
            <FormItem
              className='text-area-container'
              label='Dont have a CV? Please give us information of your last 3 Jobs'
              name='leave'
              rules={[
                {
                  max: 100,
                  message: 'Value should be less than 100 character',
                },
              ]}
              initialValue=''
            >
              <Textarea placeholder='Please include start date, finish date, job title and responsibilities.' />
            </FormItem>
            <FormItem
              className='text-area-container'
              required
              name='tellUs'
              label={[
                'Tell us why you want to apply for',
                `${tenant.name} and what makes you a great candidate?`,
              ].join(' ')}
              rules={[
                ...rulesTextInput('the reason'),
                {
                  max: 100,
                  message: 'Value should be less than 100 character',
                },
              ]}
            >
              <Textarea
                placeholder={[
                  `I want to work for ${tenant.name} because I think that`,
                  'I will be a great addition to the team I am a hard worker and have experience in the Warehouse Industry.',
                ].join(' ')}
              />
            </FormItem>
            <FormItem
              className='checkbox-container'
              name='isAcceptedReceivingUpdate'
              rules={rulesSelect('privacy policy')}
            >
              <CheckboxGroup
                options={[
                  {
                    value: 1,
                    label: (
                      <span>
                        I accept{' '}
                        <a
                          target='_blank'
                          className='privacy-policy'
                          href='https://www.gigtogig.co.uk/privacy-policy'
                          rel='noreferrer'
                        >
                          Privacy Policy.
                        </a>
                      </span>
                    ),
                  },
                ]}
              />
            </FormItem>
            <div className='button-submit-container'>
              <Button
                disabled={isLoading}
                className='yellow-button submit-btn'
                htmlType='submit'
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </GuestFormCard>
    </div>
  );
};
export default JobApplication;
