import moment from 'moment';

import { getListYear } from '@/utils';

const YEAR_STEP = 1;
const weeknumber = 52;

const week = `Week ${weeknumber}`;

const listWeek = [];
for (let i = 1; i <= weeknumber; i += 1) {
  listWeek.push({
    label: `Week ${i}`,
    value: i,
  });
}

const currentYear = moment().year();

const getStartAndEndDaysOfFirstWeek = (year) => {
  const date = new Date(year, 0, 1);
  const dayOfWeek = date.getDay();
  const firstDayOfYear = new Date(year, 0, 1);
  if (dayOfWeek <= 4) {
    firstDayOfYear.setDate(firstDayOfYear.getDate() - dayOfWeek + 1);
  } else {
    firstDayOfYear.setDate(firstDayOfYear.getDate() + 7 - dayOfWeek + 1);
  }
  const startDay = new Date(firstDayOfYear);
  const endDay = new Date(firstDayOfYear);
  endDay.setDate(firstDayOfYear.getDate() + 6);
  return { startDay, endDay };
};

const listWeekAndDayofWeek = (weekNum, year) => {
  const { startDay, endDay } = getStartAndEndDaysOfFirstWeek(year);
  const weeks = [
    {
      label: `Week 1 (${moment(startDay).format('DD/MM')} - ${moment(
        endDay,
      ).format('DD/MM/YYYY')})`,
      value: 1,
    },
  ];
  startDay.setDate(endDay.getDate() + 1);
  for (let i = 2; i <= weekNum; i += 1) {
    const startDayOfWeek = new Date(
      startDay.getFullYear(),
      startDay.getMonth(),
      startDay.getDate(),
    );

    const endDayOfWeek = new Date(
      startDay.getFullYear(),
      startDay.getMonth(),
      startDay.getDate() + 6,
    );

    weeks.push({
      label: `Week ${i} (${moment(startDayOfWeek).format('DD/MM')} - ${moment(
        endDayOfWeek,
      ).format('DD/MM/YYYY')}) `,
      value: i,
    });
    startDay.setDate(startDay.getDate() + 7);
  }

  return weeks;
};

const weeksAndDayofWeek = listWeekAndDayofWeek(weeknumber, currentYear);

const listYear = getListYear({
  startYear: 2005,
  endYear: currentYear,
  step: YEAR_STEP,
});

const statuses = [
  {
    value: 0,
    label: 'Not Approved',
    desc: 'No action token',
    color: 'red',
  },
  {
    value: 1,
    label: 'Approved',
    desc: 'Timesheets approved in WebApp',
    color: 'yellow',
  },
  {
    value: 2,
    label: 'Confirmed',
    desc: 'Timesheets comfirmed for payroll',
    color: 'green',
  },
  {
    value: 3,
    label: 'Paid',
    desc: 'Payroll has been processed and paid',
    color: 'green',
  },
];

const mappingStatuses = {
  unapproved: {
    label: 'Not Approved',
    color: 'red',
  },
  approved: {
    label: 'Approved',
    color: 'yellow',
  },
  confirmed: {
    label: 'Confirmed',
    color: 'green',
  },
  finalised: {
    label: 'Confirmed',
    color: 'green',
  },
  paid: {
    label: 'Paid',
    color: 'green',
  },
  pushed: {
    label: 'Pushed',
    color: 'gray',
  },
  no_show: {
    color: 'gray',
  },
};

const tempData = [];
for (let i = 0; i < 10; i += 1) {
  tempData.push({
    key: i,
    provider: 'Provider',
    clientID: 21555,
    year: moment().year(),
    paymentWeek: `Week ${weeknumber}`,
    paymentWeekDates: '07/03/2022 - 13/03/2022',
    lastEditedBy: 'Esther Jenkinson',
    lastEditedAt: '14/06/2022 11:00 - 23:00',
    numberOfSeekers: 10,
    totalHours: 12,
    totalPay: '£300',
    status: 'yellow',
    statusDetail: 'Confirmed',
  });
}

const daysInWeek = ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'];

const FORM_SELECT_NAMES = {
  all: 'isCheckedAll',
  rows: 'isCheckedRow',
  cell: 'isCheckedCell',
};

const DISABLED_STATUS = ['confirmed', 'finalised', 'paid'];

const ACTIONS = {
  approveHours: {
    key: 'approveHours',
    updateStatus: 'approved',
    label: 'Approve Hours',
  },
  confirmHours: {
    key: 'confirmHours',
    updateStatus: 'confirmed',
    label: 'Confirm Hours',
  },
  updateTimecards: {
    key: 'updateTimecards',
    label: 'Update Timecards',
  },
  deleteHours: {
    key: 'deleteHours',
    label: 'Delete Hours',
  },
  pullBack: {
    key: 'pullBack',
    label: 'Pull back',
  },
  sick: {
    key: 'sick',
    label: 'Sick',
    updateStatus: 'sick',
  },
  noShow: {
    key: 'noShow',
    label: 'No Show',
    updateStatus: 'hide',
  },
  undo: {
    key: 'undo',
    label: 'Undo',
  },
};

const TAB_KEY = {
  createInvoice: { key: 'Create Invoice', label: 'Create Invoices' },
  viewSend: { key: 'View/Send', label: 'View / Send' },
  export: {
    key: 'Export',
    label: 'Export',
  },
};

export {
  daysInWeek,
  listWeek,
  listYear,
  weeknumber,
  week,
  statuses,
  tempData,
  TAB_KEY,
  mappingStatuses,
  weeksAndDayofWeek,
  ACTIONS,
  FORM_SELECT_NAMES,
  DISABLED_STATUS,
};
