import React from 'react';

import { Form, message } from 'antd';
import * as PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { useFetch } from '@/hooks';
import { JobServices } from '@/services';

import JobModal from '..';

const JobModalCreate = ({
  showPopup,
  hidePopup,
  onPostSuccess,
  repostId,
  onPostFailed,
}) => {
  const [form] = Form.useForm();

  const { mutate: createJob, isLoading: isLoadingCreateJob } = useMutation(
    JobServices.createJob,
    {
      onError: (errors) => {
        if (errors instanceof Error) return onPostFailed(errors.message);

        return null;
      },
      onSuccess: () => {
        message.success('Create job successfully');

        hidePopup();
        onPostSuccess();
      },
    },
  );

  const { isLoading } = useFetch(
    ['getJobId', repostId],
    () => JobServices.getJobIdWithJobModal(repostId),
    {
      onSuccess: (dt) => {
        form.setFieldsValue({
          ...dt,
          jobResponsesAttributes: [],
          nudgesAttributes: [],
          initJobAttributes: [],
          initNudgesAttributes: [],
        });
      },
      initialData: {},
      enabled: !!repostId,
    },
  );

  const onSubmitJobPosting = () => createJob(form.getFieldsValue());

  return (
    <JobModal
      onSubmit={onSubmitJobPosting}
      isLoading={isLoading || isLoadingCreateJob}
      status='create'
      showPopup={showPopup}
      hidePopup={hidePopup}
      form={form}
    />
  );
};

JobModalCreate.propTypes = {
  hidePopup: PropTypes.func,
  showPopup: PropTypes.bool,
  onPostSuccess: PropTypes.func,
  repostId: PropTypes.number,
  onPostFailed: PropTypes.func,
};

JobModalCreate.defaultProps = {
  hidePopup: () => {},
  showPopup: false,
  onPostFailed: () => {},
};

export default JobModalCreate;
