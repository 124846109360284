import { get } from 'lodash';

import Formatter from '@/utils/Formatter';

import { VAT_PERCENT } from '../../../constants';

const handleDeleteSection = ({ remove, name }) => remove(name);

const formatNumber = ({ numberValue, userTyping, prefix = '' }) => {
  if (!numberValue) {
    return '';
  }
  if (userTyping) {
    return `${prefix}${numberValue}`;
  }
  return `${prefix}${Formatter.fixedNumber({ value: numberValue })}`;
};

const handleChangeHoursRates = ({ formInstance, name }) => {
  const formData = formInstance.getFieldsValue();
  const hoursSectionData = get(formData, name);
  const { hours, omniRate } = hoursSectionData;

  const omniNetPay = (hours ?? 0) * omniRate;
  const omniVat = omniNetPay * VAT_PERCENT;

  hoursSectionData.omniNetPay = Formatter.fixedNumber({ value: omniNetPay });
  hoursSectionData.omniVat = Formatter.fixedNumber({ value: omniVat });

  formInstance.setFieldsValue(formData);
};

const handleChangeNetCosts = ({ formInstance, name }) => {
  const formData = formInstance.getFieldsValue();
  const costSectionData = get(formData, name);
  const { netCost } = costSectionData;

  const omniVat = netCost * VAT_PERCENT ?? 0;
  const grossCost = netCost + omniVat;

  costSectionData.grossCost = Formatter.fixedNumber({ value: grossCost });
  costSectionData.omniNetPay = Formatter.fixedNumber({ value: netCost });
  costSectionData.omniVat = Formatter.fixedNumber({ value: omniVat });

  formInstance.setFieldsValue(formData);
};

export {
  handleDeleteSection,
  formatNumber,
  handleChangeHoursRates,
  handleChangeNetCosts,
};
