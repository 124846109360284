import React from 'react';

import PropTypes from 'prop-types';

import {
  SICK_NO_SHOW_LABEL,
  TYPES_SICK_NO_SHOW,
} from '@/pages/Payroll/constants';

const TitlePopover = ({ type }) => {
  const label = SICK_NO_SHOW_LABEL[type];
  return (
    <div className={`comment-popover-title-container ${type}`}>
      <span>Comment - {label}</span>
    </div>
  );
};

export default TitlePopover;

TitlePopover.propTypes = {
  type: PropTypes.string,
};

TitlePopover.defaultProps = {
  type: TYPES_SICK_NO_SHOW.sick,
};
