import React from 'react';

import { Button } from '@/components';

import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const ApplicationSubmitted = () => {
  const tenant = useTenantContext();
  const { name, contact } = tenant;

  return (
    <div className='flex-one-column application-submitted'>
      <div className='submit-page-container'>
        <h2 className='title'>Application Submitted</h2>
        <div className='submit-page-body'>
          <p>Thank you for registering with {name}.</p>
          <p>
            In order for you to get access to the app and begin working when you
            want you will need to complete and interview and right to work check
            with one of the team.
          </p>
          <p>
            Please note: you will not be able to log into the app until the team
            have approved your profile.
          </p>
          <p>We look forward to having you as part of the {name} team soon.</p>
        </div>
        <div className='book-button-container'>
          {contact.interviewUrl ? (
            <Button
              className='yellow-button book-button'
              href={contact.interviewUrl}
              target='_blank'
            >
              Book interview Now
            </Button>
          ) : (
            <h2>One of the team will be in touch to book an interview.</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationSubmitted;
