import { useTenantContext } from '../../../../TenantWrapper';

export const usePayrollEntryCodes = () => {
  const { currency } = useTenantContext();

  return [
    { value: 'no_bonus', label: '' },
    { value: 'deduction_hours', label: 'DH' },
    { value: 'deduction_money', label: `D${currency}`, disabled: true },
    { value: 'bonus_hours', label: 'BH' },
    { value: 'bonus_money', label: `B${currency}` },
  ];
};
