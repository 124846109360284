import { useQuery } from 'react-query';

import { fetchCostWeek } from '@/services/dropdownServices/getCostWeeks';

const useCostWeeks = ({ employerId, locationId }) => {
  const { data } = useQuery(
    ['costWeek', employerId, locationId],
    () => fetchCostWeek({ employerId, locationId }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return data;
};

export default useCostWeeks;
