import React, { useEffect } from 'react';

import { Modal as AntdModal, Card, Col, Divider, Input, Row, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import { getDuration } from '@/utils/date';

import FormItem from '../FormItem';

import './styles.scss';

const formatDate = (event) =>
  `${moment(new Date(event?.start)).format('HH:mm')} - ${moment(
    new Date(event?.end),
  ).format('HH:mm')}`;

const Modal = ({
  data,
  titleSuccess,
  children,
  visible,
  onClickNo,
  onClickYes,
  isLoading,
  isSuccess,
  position,
  onClick,
  event,
  ...props
}) => {
  let color;
  const formInstance = Form.useFormInstance();
  const { id } = event ?? {};
  const contracted =
    Form.useWatch([id, 'numberOfEmployees'], formInstance) ?? 0;
  const required = Form.useWatch([id, 'numberOfRequired'], formInstance) ?? 0;
  const hours = getDuration(event?.start, event?.end);
  const difference = contracted - required;

  useEffect(() => {
    if (id) {
      formInstance.setFields([{ name: [id, 'difference'], value: difference }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracted, formInstance, id, required]);

  return (
    <AntdModal
      title={null}
      footer={null}
      centered={false}
      visible={visible}
      onCancel={onClickNo}
      wrapClassName='modal-event'
      style={{
        position: 'absolute',
        top: `${position.x} + ${20}`,
        left: position.y,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <div className='modal-wrapper'>
        <h3>{event?.skillName}</h3>
        <h3>{moment(event?.start).format('ddd DD MMM YYYY')}</h3>
        <h3>{formatDate(event)}</h3>
        <Divider />
        <Row>
          <Col span={8}>
            <Card className='event-tag'>Columns</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>People</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>Hours</Card>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Card className='event-tag'>Contracted</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>
              <FormItem name={[id, 'numberOfEmployees']}>
                <Input className='input-field' disabled />
              </FormItem>
            </Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>{contracted * hours}</Card>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Card className='event-tag'>Required</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>
              <FormItem name={[id, 'numberOfRequired']}>
                <Input className='input-field' disabled />
              </FormItem>
            </Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>{required * hours}</Card>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Card className='event-tag'>Agency</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>
              <Input className='input-field' disabled />
            </Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>0</Card>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Card
              style={{
                backgroundColor: color,
              }}
              className='event-tag'
            >
              Difference
            </Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>
              <FormItem name={[id, 'difference']}>
                <Input className='input-field' disabled />
              </FormItem>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              className='event-tag'
              style={{
                backgroundColor: color,
              }}
            >
              {difference * hours}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Card className='event-tag'>Confirmed</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>TBC</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>TBC</Card>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Card className='event-tag'>Attended</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>TBC</Card>
          </Col>
          <Col span={8}>
            <Card className='event-tag'>TBC</Card>
          </Col>
        </Row>
        <div className='edit-modal-button'>
          <Button className='yellow-button' onClick={onClick}>
            Edit
          </Button>
        </div>
      </div>
    </AntdModal>
  );
};

Modal.propTypes = {
  data: PropTypes.object,
  children: PropTypes.string,
  visible: PropTypes.bool,
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  titleSuccess: PropTypes.string,
  position: PropTypes.object,
  onClick: PropTypes.func,
  required: PropTypes.string,
  setRequired: PropTypes.func,
  event: PropTypes.object,
};

export default Modal;
