import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown as AntdDropdown } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

const Dropdown = ({ overlay, dropdownLabel, onClick, className }) => (
  <AntdDropdown
    className={classNames('dropdown-button', className)}
    onClick={onClick}
    overlay={overlay}
  >
    <Button>
      {dropdownLabel} <DownOutlined />
    </Button>
  </AntdDropdown>
);

Dropdown.propTypes = {
  overlay: PropTypes.node,
  dropdownLabel: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Dropdown.defaultProps = {
  dropdownLabel: '',
  onClick: () => {},
  className: '',
};

export default Dropdown;
