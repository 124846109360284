import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Radio, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { FormItem, Button } from '@/components';
import './styles.scss';

const rateCheck = [
  {
    value: false,
    label: 'GIG Posted Job',
  },
  {
    value: true,
    label: (
      <div>
        <span>Internal Posted Job</span>
        <Tooltip
          title={
            <>
              <p>
                GIG posted jobs are for all GIG Seekers verified on the
                platform.
              </p>
              <p>
                Internal jobs are for your internal employees only, you can let
                GIG seekers view this job at any time.
              </p>
            </>
          }
        >
          <InfoCircleOutlined className='tooltip-icon' />
        </Tooltip>
      </div>
    ),
  },
];

const PostShift = ({ jobId }) => (
  <div className='post-shift'>
    <FormItem name='isPrivated'>
      <Radio.Group
        className='posted-check'
        disabled={jobId}
        options={rateCheck}
        defaultValue={false}
      />
    </FormItem>
    {!jobId && (
      <Button htmlType='submit' className='black-button'>
        Post Shift
      </Button>
    )}
  </div>
);

PostShift.propTypes = {
  jobId: PropTypes.string,
};

PostShift.defaultProps = {
  jobId: '',
};

export default PostShift;
