import React from 'react';

import { Breadcrumb as AntdBreadcrumb } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as HomeIcon } from '@/assets/icons/homeIcon.svg';

import './styles.scss';

const Breadcrumb = ({ data, mainItem, className }) => (
  // ex: const data = [{item: 'Recrutiment', to: '/recruitment'}]
  <AntdBreadcrumb
    separator=''
    className={classNames('breadcrumb-container', className)}
  >
    <AntdBreadcrumb.Item className='main-item'>{mainItem}</AntdBreadcrumb.Item>
    <AntdBreadcrumb.Separator>
      <div className='separator'>|</div>
    </AntdBreadcrumb.Separator>
    <AntdBreadcrumb.Item className='breadcrumb-item'>
      <Link className='breadcrumb-icon-link' to='/'>
        <HomeIcon className='home-icon' />
      </Link>
    </AntdBreadcrumb.Item>
    {data.map((dt, index) => (
      <AntdBreadcrumb.Item className='breadcrumb-item text' key={dt.item}>
        <Link key={dt.to} to={dt.to}>
          <span className='dash'>&#8212;</span>
          <span className='item'>{dt.item}</span>
        </Link>
      </AntdBreadcrumb.Item>
    ))}
  </AntdBreadcrumb>
);

Breadcrumb.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      item: PropTypes.string,
    }),
  ),
  mainItem: PropTypes.string,
  className: PropTypes.string,
};

Breadcrumb.defaultProps = {
  data: [],
  mainItem: '',
  className: '',
};

export default Breadcrumb;
