import React from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Button, CSV, Pagination, Table } from '@/components';
import { useDownloadCsv } from '@/hooks';
import downloadAttendance from '@/services/seeker/downloadAttendance';

import columns from './attendanceColumns';

import './styles.scss';

const AttendanceView = ({
  attendances,
  currentPage,
  paginationPageSize,
  handlePagination,
  handleShowSizeChange,
  isLoading,
}) => {
  const { id } = useParams();
  const {
    refCSVLink,
    data: attendanceData,
    refetch: refetchDownload,
    isFetching,
  } = useDownloadCsv(['downloadAttendanceCsv', id], () =>
    downloadAttendance(id),
  );
  return (
    <div className='attendance-table'>
      <Table
        columns={columns}
        infoDetail={attendances?.data ?? []}
        loading={isLoading}
      />
      <div className='attendance-footer'>
        <Pagination
          current={currentPage}
          pageSize={paginationPageSize}
          total={get(attendances, 'meta.pagy.count', 0)}
          onChange={handlePagination}
          responsive
          showSizeChanger
          onShowSizeChange={handleShowSizeChange}
        />
        <Button
          className='custom-button yellow-button'
          onClick={refetchDownload}
          loading={isFetching}
        >
          Download CSV
        </Button>
        <CSV
          ref={refCSVLink}
          filename='attendances.csv'
          data={attendanceData}
        />
      </div>
    </div>
  );
};

export default AttendanceView;

AttendanceView.propTypes = {
  isLoading: PropTypes.bool,
  attendances: PropTypes.object,
  currentPage: PropTypes.number,
  handlePagination: PropTypes.func,
  paginationPageSize: PropTypes.func,
  handleShowSizeChange: PropTypes.func,
};

AttendanceView.defaultProps = {
  isLoading: false,
  attendances: {},
  currentPage: 1,
  paginationPageSize: 25,
  handlePagination: () => {},
  handleShowSizeChange: () => {},
};
