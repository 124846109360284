import request from '@/request';

import { getAddressInfomation } from '../getAddressInfomation';

const create = async ({
  city,
  note,
  email,
  status,
  tellUs,
  company,
  fullName,
  phoneNumber,
  localOffice,
  sitePostCode,
  allocatedAccountManager,
}) => {
  const geogecode = await getAddressInfomation(`${sitePostCode}-uk`);

  const { geometry } = geogecode[0];

  await request.post('/providers/leads', {
    lead: {
      city,
      email,
      status: Number(status),
      company,
      name: fullName,
      tellUs,
      phone: phoneNumber,
      internalNote: note,
      postCode: sitePostCode,
      localOffice: Number(localOffice),
      managerId: allocatedAccountManager,
      locationAttributes: {
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
      },
    },
  });
};

export default create;
