import React from 'react';

import { Spin } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ListJobTypes from '../ListJobTypes';

const ListTableStatus = ({
  data,
  isFetching,
  isRefetching,
  payrollStatus,
  pushedToTimesheetId,
}) =>
  isFetching && !isRefetching ? (
    <div className='row-loading'>
      <Spin />
    </div>
  ) : (
    <>
      <div>
        {_.toPairs(data?.timesheetDetails).map(([status, listJobTypes]) =>
          _.toPairs(listJobTypes).map(
            ([jobTypeId, /** @type {Array} */ timesheetDetails]) => (
              <div className='row' key={jobTypeId}>
                <ListJobTypes
                  status={status}
                  jobTypeId={jobTypeId}
                  isFetching={isFetching}
                  payrollStatus={payrollStatus}
                  timesheetDetails={timesheetDetails}
                />
              </div>
            ),
          ),
        )}
      </div>
      <div>
        {_.toPairs(data?.timesheetCurrPushed).map(([status, listJobTypes]) =>
          _.toPairs(listJobTypes).map(
            ([jobTypeId, /** @type {Array} */ timesheetDetails]) => (
              <div className='row' key={jobTypeId}>
                <ListJobTypes
                  isPushed
                  status={status}
                  jobTypeId={jobTypeId}
                  isFetching={isFetching}
                  payrollStatus={payrollStatus}
                  timesheetDetails={timesheetDetails}
                />
              </div>
            ),
          ),
        )}
      </div>
      <div>
        {_.toPairs(data?.timesheetPushed).map(([status, listJobTypes]) =>
          _.toPairs(listJobTypes).map(
            ([jobTypeId, /** @type {Array} */ timesheetDetails]) => (
              <div className='row' key={jobTypeId}>
                <ListJobTypes
                  isPushed
                  status={status}
                  isCurrentWeekPushed
                  jobTypeId={jobTypeId}
                  isFetching={isFetching}
                  payrollStatus={payrollStatus}
                  timesheetDetails={timesheetDetails}
                  pushedToTimesheetId={pushedToTimesheetId}
                />
              </div>
            ),
          ),
        )}
      </div>
    </>
  );

export default ListTableStatus;

ListTableStatus.propTypes = {
  data: PropTypes.object,
  isFetching: PropTypes.bool,
  isRefetching: PropTypes.bool,
  payrollStatus: PropTypes.string,
  pushedToTimesheetId: PropTypes.number,
};

ListTableStatus.defaultValue = {
  data: {},
  isFetching: false,
  isRefetching: false,
  payrollStatus: '',
  pushedToTimesheetId: null,
};
