import {
  get,
  groupBy,
  keyBy,
  keys,
  map,
  mapValues,
  omit,
  pickBy,
  values,
} from 'lodash';

import FormAccess from './formAccess';

const updateStatus = ({ formInstance, panelId, status }) => {
  const formPanelData = FormAccess.getFormDataByName({
    formInstance,
    name: [panelId, 'timecards'],
  });
  const timecardIdsChecked = keys(pickBy(formPanelData, (item) => item.select));
  const timecardAttributes = map(timecardIdsChecked, (id) => ({ id, status }));
  return timecardAttributes;
};

const updateStatusAtPanel = ({ panelInfo, actionType }) => {
  const timecardAttributes = map(panelInfo.timecards, ({ timecardId: id }) => ({
    id,
    status: actionType,
  }));
  return timecardAttributes;
};

const updateTimecards = ({ formInstance, panelId }) => {
  const formPanelData = FormAccess.getFormDataByName({
    formInstance,
    name: [panelId, 'timecards'],
  });
  const hoursTemplateChecked = get(
    groupBy(values(formPanelData), 'select'),
    true,
  );
  const keyByHoursTemplate = (hoursTemplateChecked ?? []).reduce(
    (total, curr) => {
      const omittedSelect = omit(curr, 'select');
      return {
        ...total,
        ...omittedSelect,
      };
    },
    {},
  );
  const payrollTemplateHoursAttributes = map(
    values(keyByHoursTemplate),
    ({
      id,
      bonusType,
      bonusValue,
      adjustedPayrate,
      breakDuration,
      chargeRate,
    }) => ({
      id,
      bonusType,
      bonusValue,
      adjustedPayrate,
      breakDuration,
      chargeRate,
    }),
  );
  return payrollTemplateHoursAttributes;
};

const parseDataToForm = (data) => {
  const { timesheetDetailId } = data;

  const payrollTemplateHours = keyBy(data.payrollTemplateHours, 'id');
  const groupedTimecards = keyBy(data.timecards, 'timecardId');
  const sickNoShowAttrs = mapValues(
    groupedTimecards,
    ({ comment = '', noShow = null }) => ({ comment, noShow }),
  );
  const timecards = mapValues(groupedTimecards, (timecard) =>
    keyBy(timecard.payrollTemplate.hourTemplates, 'id'),
  );
  return {
    [timesheetDetailId]: {
      ...data,
      payrollTemplateHours,
      timecards,
      sickNoShowAttrs,
    },
  };
};

const getSickNoShowData = ({ formInstance, panelId, noShowType }) => {
  const formPanelData = FormAccess.getFormDataByName({
    formInstance,
    name: [panelId, 'timecards'],
  });
  const comment = FormAccess.getFormDataByName({
    formInstance,
    name: ['comment'],
  });
  const timecardIds = keys(pickBy(formPanelData, (item) => item.select));
  const noShowAttributes = {
    noShowType,
    comment,
  };
  return { timecardIds, noShowAttributes };
};

const getSickNoShowCommentData = ({ formInstance, namePath }) => {
  const formSickNoShowCommentData = FormAccess.getFormDataByName({
    formInstance,
    name: namePath,
  });

  const { id } = get(formSickNoShowCommentData, 'noShow');
  const { comment: content, assignedScheduleId } = get(
    formSickNoShowCommentData,
    'comment',
  );

  const comment = {
    assignedScheduleId,
    content,
    id,
  };
  return comment;
};

const DataProcessing = {
  updateStatus,
  updateTimecards,
  parseDataToForm,
  getSickNoShowData,
  updateStatusAtPanel,
  getSickNoShowCommentData,
};

export default DataProcessing;
