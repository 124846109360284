import { toNumber } from 'lodash';

import request from '@/request';
import { FormatIndexStrategy, Normalize } from '@/utils/normalizeResponse';

export const getPayrollSeeker = async ({ id, year }) => {
  const res = await request.get(`seekers/employees/${id}/payrolls`, {
    params: {
      year,
    },
  });
  const normalize = new Normalize();
  const strategy = new FormatIndexStrategy(res);
  normalize.setStrategy(strategy);
  const data = normalize.formatResponse();

  const result = new Array(52).fill(0).map((_, index) => ({
    weekNumber: index + 1,
    payrollWages: 0,
  }));

  data?.data?.flat(1).forEach(({ weekNumber, payrollWages }) => {
    result[toNumber(weekNumber) - 1] = {
      weekNumber,
      payrollWages,
    };
  });

  return result || [];
};
