import request from '@/request';

/**
 * @function resetContactPassword
 * @param {number} providerId
 * @param {string} manualPassword
 * @param {string} repeatPassword
 * @returns
 */
const resetContactPassword = ({ providerId, manualPassword, repeatPassword }) =>
  request.patch(`/providers/${providerId}/reset_password`, {
    manual_password: manualPassword,
    reset_password: repeatPassword,
  });

export default resetContactPassword;
