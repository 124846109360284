import React from 'react';

import { Form, Table } from 'antd';
import moment from 'moment';

import { Button, FormItem, Select } from '@/components';
import { listWeek, LIST_YEAR } from '@/constants';
import { useFetch } from '@/hooks';
import { ReportService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import './style.scss';

const WeeklyReport = () => {
  const [form] = Form.useForm();
  const {
    data = [],
    isFetching,
    refetch,
  } = useFetch(['weekly-report'], () => {
    const { startWeek, startYear, endWeek, endYear } = form.getFieldsValue();
    return ReportService.weekly.getWeeklyReport({
      filter: { startWeek, startYear, endWeek, endYear },
    });
  });

  const dataLength = data?.length;

  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'id',
    },
    {
      title: 'Client Name',
      dataIndex: 'provider',
    },
    {
      title: 'Client Location',
      dataIndex: 'locationName',
    },
    {
      title: 'Payroll Week',
      dataIndex: 'invoiceWeek',
    },
    {
      title: 'NET Total',
      dataIndex: 'netTotal',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          Formatter.currencyGBP(dt)
        ),
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          Formatter.currencyGBP(dt)
        ),
    },
    {
      title: <div className='green'>Total Invoiced</div>,
      dataIndex: 'totalInvoiced',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background green'>
            {Formatter.currencyGBP(dt)}
          </div>
        ) : (
          <div className='green'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Worker Pay</div>,
      dataIndex: 'workerPay',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Holiday Pay</div>,
      dataIndex: 'holidayPay',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Costs</div>,
      dataIndex: 'totalCost',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: <div className='red'>Cost of NI</div>,
      dataIndex: 'niCost',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background red'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          <div className='red'>{Formatter.currencyGBP(dt)}</div>
        ),
    },
    {
      title: 'Margin %',
      dataIndex: 'margin',
      render: (dt, _, index) => {
        const roundData = dt?.toFixed(2);
        return index === dataLength - 1 ? (
          <div className='gray-background'>{`${roundData}%`}</div>
        ) : (
          `${roundData}%`
        );
      },
    },
    {
      title: 'Profit/Loss',
      dataIndex: 'profitLoss',
      render: (dt, _, index) =>
        index === dataLength - 1 ? (
          <div className='gray-background'>{Formatter.currencyGBP(dt)}</div>
        ) : (
          Formatter.currencyGBP(dt)
        ),
    },
  ];

  const tableColumns = generateHeaderTable(columns);

  return (
    <div className='week-report-container'>
      <Form onFinish={refetch} form={form} className='header-container'>
        <FormItem
          name='startWeek'
          className='form-item-style'
          label='Start Week'
          initialValue={moment().week()}
        >
          <Select options={listWeek} placeholder='Start Week' />
        </FormItem>
        <FormItem
          name='startYear'
          className='form-item-style'
          label='Choose Year'
          initialValue={moment().year()}
        >
          <Select options={LIST_YEAR} placeholder='Choose Year' />
        </FormItem>
        <FormItem
          name='endWeek'
          className='form-item-style'
          label='End Week'
          initialValue={moment().week()}
        >
          <Select options={listWeek} placeholder='End Week' />
        </FormItem>
        <FormItem
          name='endYear'
          className='form-item-style'
          label='Choose Year'
          initialValue={moment().year()}
        >
          <Select options={LIST_YEAR} placeholder='Choose Year' />
        </FormItem>
        <Button htmlType='submit' className='yellow-button view-btn'>
          View
        </Button>
      </Form>
      <div className='body-container'>
        <h2>Profit and Loss Report ({Math.max(dataLength - 1, 0)} invoices)</h2>
        <div className='report-table-container'>
          <Table
            bordered
            pagination={false}
            columns={tableColumns}
            dataSource={data}
            loading={isFetching}
            locale={{
              emptyText: 'No data match your search criteria',
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default WeeklyReport;
