import React from 'react';

import Experiences from './Experiences';
import IndustryExperience from './IndustryExperience';
import SpecificRoleApplied from './SpecificRoleApplied';

import './styles.scss';

const PastWorkDetailsNew = () => (
  <div className='seeker-past-work-details'>
    <div className='left'>
      <Experiences />
    </div>
    <div className='mid'>
      <IndustryExperience />
    </div>
    <div className='right'>
      <SpecificRoleApplied />
    </div>
  </div>
);

export default PastWorkDetailsNew;
