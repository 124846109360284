import React, { useState } from 'react';

import { Form, Tooltip, message } from 'antd';
import { isArray, isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import {
  Button,
  FormItem,
  GeneralTemplate,
  Input,
  ModalConfirm,
} from '@/components';
import './styles.scss';
import { useFetch } from '@/hooks';
import { getPayTemplateInfo } from '@/services/payrollTemplateService';
import updatePayrollTemplate from '@/services/payrollTemplateService/edit';

import ListView from '../../components/ListView';

const AllocatedWorkers = () => {
  const history = useHistory();
  const { templateId } = useParams();
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [locationIds, setLocationsIds] = useState([]);
  const [disabledSave, setDisabledSave] = useState(true);
  const [chooseProvider, setChooseProvider] = useState({});
  const [confirmRemoveSeeker, setConfirmRemoveSeeker] = useState();

  const dataToObject = (data) => (isArray(data) ? { ...data } : data);

  const handleRemove = (seekerId) => {
    const seekers = [...dataTable];
    const seekerIndex = dataTable.findIndex(
      (data) => data.seeker.id === seekerId,
    );
    seekers.splice(seekerIndex, 1);
    if (!isEmpty(seekers[0])) {
      seekers[0] = {
        ...seekers[0],
        provider: dataToObject(dataTable[0].provider),
        locations: dataToObject(dataTable[0].locations),
        templates: dataToObject(dataTable[0].templates),
      };
    }
    setDisabledSave(false);
    setDataTable(seekers);
  };

  const { isFetching: isFetchingTemplateInfo } = useFetch(
    ['getTemplateInfo'],
    () => getPayTemplateInfo(templateId),
    {
      onSuccess: (data) => {
        form.setFieldsValue({
          payTemplate: data.name,
        });

        const updateDataTable = (data.employees || []).map((item, index) => ({
          ...(index === 0 && {
            provider: {
              label: data?.employerAttributes?.name,
              value: data?.employerAttributes?.id,
            },
            locations: data?.employerLocations
              .map((location) => location.name)
              .join(', '),
            templates: {
              label: data.name,
              value: data.id,
            },
          }),
          seeker: {
            id: item.id,
            name: item.fullname,
          },
        }));
        setDataTable(updateDataTable);

        setChooseProvider({
          value: data?.employerAttributes?.id,
          label: data?.employerAttributes?.name,
        });

        const locationsInfo = data?.employerLocations?.map((location) => ({
          value: location?.id,
          label: location?.name,
        }));
        setLocationsIds(locationsInfo);
      },
    },
  );

  const { mutate: updateAllocatedWorkers } = useMutation(
    (seekerIds) =>
      updatePayrollTemplate({
        templateId,
        payload: {
          employee_ids: seekerIds,
        },
      }),
    {
      onSuccess: () => {
        message.success('Update allocated workers success');
        history.push('/pay-templates');
      },
    },
  );

  const handleSubmit = () => {
    const seekerIds = dataTable.map((row) => Number(row.seeker.id));
    updateAllocatedWorkers(seekerIds);
  };

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'provider',
      render: (provider) => {
        if (!isEmpty(provider)) {
          return (
            <FormItem className='not-empty'>
              <Input disabled className='custom-input' value={provider.label} />
            </FormItem>
          );
        }
        return <div className='empty' />;
      },
    },
    {
      title: 'Location',
      dataIndex: 'locations',
      render: (locations) => {
        if (!isEmpty(locations)) {
          return (
            <FormItem className='not-empty'>
              <Input disabled className='custom-input' value={locations} />
            </FormItem>
          );
        }
        return <div className='empty' />;
      },
    },
    {
      title: 'Templates',
      dataIndex: 'templates',
      render: (templates) => {
        if (!isEmpty(templates)) {
          return (
            <FormItem className='not-empty'>
              <Tooltip placement='topLeft' title={templates.label}>
                <Input
                  disabled
                  className='custom-input'
                  value={templates.label}
                />
              </Tooltip>
            </FormItem>
          );
        }
        return <div className='empty' />;
      },
    },
    {
      title: 'Allocated Worker',
      dataIndex: 'seeker',
      render: (seeker) => {
        if (!isEmpty(seeker)) {
          return (
            <div className='not-empty seeker-item'>
              <Tooltip placement='topLeft' title={seeker.name}>
                <span>{seeker.name}</span>
                <Button
                  type='text'
                  onClick={() => setConfirmRemoveSeeker(seeker)}
                >
                  <XIconBlack />
                </Button>
              </Tooltip>
            </div>
          );
        }
        return <div className='empty' />;
      },
    },
  ];

  return (
    <GeneralTemplate
      mainItem='Payroll Templates'
      data={[{ item: 'Allocated Workers' }]}
      buttonContent='Create Pay Template'
      buttonLink='/create-payroll-templates'
      headerText='Pay Templates'
      subTemplateClassName='templates-body'
      className='allocated-workers-container'
      hasButton={false}
    >
      <Form form={form}>
        <div className='allocated-workers-options'>
          <div className='form-row'>
            <FormItem
              className='form-item'
              label='Provider'
              style={{
                width: 400,
              }}
            >
              <Input disabled value={chooseProvider.label || null} />
            </FormItem>
          </div>
          <div className='form-row'>
            {!isEmpty(locationIds) ? (
              <div className='form-column'>
                {locationIds.map((location, index) => (
                  <FormItem
                    className='form-item'
                    label={index === 0 && 'Location'}
                    style={{
                      width: 300,
                    }}
                  >
                    <Input disabled value={location.label || null} />
                  </FormItem>
                ))}
              </div>
            ) : (
              <FormItem
                className='form-item'
                label='Location'
                style={{
                  width: 300,
                }}
              >
                <Input disabled />
              </FormItem>
            )}
          </div>
          <div className='form-row'>
            <FormItem
              className='form-item'
              name='payTemplate'
              label='Pay Template'
            >
              <Input placeholder='Pay Template' disabled />
            </FormItem>
          </div>
        </div>

        <div className='allocated-workers-body'>
          <ListView
            loading={isFetchingTemplateInfo}
            infoDetail={dataTable}
            columns={columns}
          />
          <Button
            disabled={disabledSave}
            className='yellow-button save-btn'
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </Form>

      <ModalConfirm
        title='Remove Seeker'
        onClickNo={() => setConfirmRemoveSeeker()}
        onClickYes={() => {
          handleRemove(confirmRemoveSeeker.id);
          setConfirmRemoveSeeker();
        }}
        visible={!!confirmRemoveSeeker}
      >
        Are you sure you want to remove {confirmRemoveSeeker?.name}.
      </ModalConfirm>
    </GeneralTemplate>
  );
};

export default AllocatedWorkers;
