import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { camelCase, isEmpty, some } from 'lodash';
import moment from 'moment';

import { ReactComponent as ClosedCircle } from '@/assets/icons/closedCircle.svg';
import { Button, Checkbox, FormItem } from '@/components';
import CheckInShifts from '@/pages/Jobs/components/CheckInShifts';

import ManageWorkerDownloadFeature from '../DownloadFeature';
import {
  CHECK_IN_TYPE,
  CHECK_OUT_TYPE,
  NO_SHOW_TYPE,
  TYPE_CHECKING,
} from './constant';
import Select from './utils/select';

const LIMIT_HOURS = {
  beforeStart: 2,
  afterStart: 1,
  afterEnd: 24,
};

const genConfirmedCols = ({
  form,
  dateRange,
  setActionKey,
  setIsVisibleAssignSchedule,
  downloadCSVJobs,
  togglePreviewJobPdf,
  shiftTimes,
  selectedShift,
  setSelectedShift,
  setIsOpenDeclinedModal,
  openModalSeekerInfo,
  inforDetail,
  setSelectedNoShowId,
  isCompletedTab,
}) => {
  const handleNoShow = ({ type, id }) => {
    setActionKey(type);
    setSelectedNoShowId(id);
    setIsVisibleAssignSchedule(true);
  };

  const handleCheckAll = ({ type }) => {
    const isCheckedAll = form.getFieldValue([
      `${selectedShift}`,
      'checkedAll',
      type,
    ]);
    if (!isCheckedAll) {
      form.setFields([
        {
          name: [`${selectedShift}`, 'checkedAll', type],
          value: !isCheckedAll,
        },
      ]);
    }
    Select.checkAll({ form, selectedShift, type });
  };

  const handleCheckRecord = ({ id, name, status = '' }) => {
    form.setFields([
      {
        name: [selectedShift, id, 'checked', name],
        value: true,
      },
    ]);
    Select.checkRecord({
      form,
      selectedShift,
      name,
      status,
    });
  };

  const checkEnabledCheckIn = ({ status, startTime }) => {
    const isTimeToCheckIn = moment().isBetween(
      moment(startTime).subtract(LIMIT_HOURS.beforeStart, 'hours'),
      moment(startTime).add(LIMIT_HOURS.afterStart, 'hours'),
    );
    return status === TYPE_CHECKING[CHECK_IN_TYPE].disabled && isTimeToCheckIn;
  };

  const checkEnabledCheckOut = ({ status }) =>
    status === TYPE_CHECKING[CHECK_OUT_TYPE].disabled;

  const checkEnabledCheckInAll = () => {
    if (isEmpty(inforDetail)) return false;
    const isDisabled = some(inforDetail, ({ status, startTime }) =>
      checkEnabledCheckIn({ status, startTime }),
    );
    return isDisabled;
  };

  const checkEnabledCheckOutAll = () => {
    if (isEmpty(inforDetail)) return false;
    const isDisabled = some(inforDetail, ({ status }) =>
      checkEnabledCheckOut({ status }),
    );
    return isDisabled;
  };

  const disabledDecline = isEmpty(inforDetail);

  const handleButtonCheckAll = ({ type, actionKey }) => {
    handleCheckAll({ type });
    setIsVisibleAssignSchedule(true);
    setActionKey(actionKey);
  };

  const handleButtonCheckRecord = ({ id, type }) => {
    handleCheckRecord({
      id,
      name: TYPE_CHECKING[type].fieldItem,
      status: TYPE_CHECKING[type].disabled,
    });
    setIsVisibleAssignSchedule(true);
    setActionKey(type);
  };

  const handleDeclineAll = () => {
    if (isCompletedTab || disabledDecline) return;
    handleCheckAll({ type: 'decline' });
    setIsOpenDeclinedModal(true);
  };

  const handleDecline = (id) => {
    if (isCompletedTab) return;
    handleCheckRecord({ id, name: 'decline' });
    setIsOpenDeclinedModal(true);
  };

  const enableCheckInAll = !isCompletedTab && checkEnabledCheckInAll();
  const enableCheckOutAll = !isCompletedTab && checkEnabledCheckOutAll();

  return [
    {
      children: [
        {
          children: [
            {
              title: (
                <ManageWorkerDownloadFeature
                  togglePreviewJobPdf={togglePreviewJobPdf}
                  downloadCSVJobs={downloadCSVJobs}
                />
              ),
              width: '50%',
              dataIndex: 'fullname',
              render: (employeeName, { id, employeeId }) => (
                <div className='padding-cell name-cell'>
                  <FormItem name={[`${selectedShift}`, `${id}`, 'id']} hidden />
                  <FormItem
                    name={[`${selectedShift}`, `${id}`, 'status']}
                    hidden
                  />
                  <FormItem
                    name={[`${selectedShift}`, `${id}`, 'startTime']}
                    hidden
                  />
                  <FormItem
                    name={[`${selectedShift}`, `${id}`, 'endTime']}
                    hidden
                  />
                  <FormItem
                    name={[`${selectedShift}`, `${id}`, 'jobResponsesId']}
                    hidden
                  />
                  <span>{employeeName}</span>
                  <InfoCircleOutlined
                    onClick={() => openModalSeekerInfo(employeeId)}
                  />
                </div>
              ),
            },
            {
              title: (
                <div className='declined-padding'>
                  <div className='declined-title'>Decline</div>
                  <div className='declined-checkbox'>
                    <FormItem
                      name={[`${selectedShift}`, 'checkedAll', 'decline']}
                      valuePropName='checked'
                      initialValue={false}
                    >
                      <Checkbox
                        disabled={isCompletedTab || disabledDecline}
                        onChange={() =>
                          Select.checkAll({
                            form,
                            selectedShift,
                            type: 'decline',
                          })
                        }
                      />
                    </FormItem>
                    <ClosedCircle onClick={handleDeclineAll} />
                  </div>
                </div>
              ),
              width: '10%',
              render: (_, { id }) => (
                <div className='padding-cell decline-cell'>
                  <FormItem
                    name={[`${selectedShift}`, `${id}`, 'checked', 'decline']}
                    valuePropName='checked'
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={() =>
                        Select.checkRecord({
                          form,
                          selectedShift,
                          name: 'decline',
                        })
                      }
                      disabled={isCompletedTab}
                    />
                  </FormItem>
                  <ClosedCircle onClick={() => handleDecline(id)} />
                </div>
              ),
            },
            {
              title: null,
              width: '10%',
            },
          ],
        },
      ],
    },
    {
      title: (
        <CheckInShifts
          dateRange={dateRange}
          shiftTimes={shiftTimes}
          selectedShift={selectedShift}
          setSelectedShift={setSelectedShift}
        />
      ),
      children: [
        {
          title: (
            <FormItem
              className='padding-cell border-left'
              shouldUpdate={(prevValues, curValues) =>
                Select.checkShouldUpdate({
                  prevValues,
                  curValues,
                  name: 'isSelectCheckInAll',
                })
              }
            >
              <Button
                onClick={() =>
                  handleButtonCheckAll({ type: 'in', actionKey: CHECK_IN_TYPE })
                }
                disabled={!enableCheckInAll}
                className='black-button full-width'
              >
                Check-In All
              </Button>
            </FormItem>
          ),
          width: '10%',
          align: 'center',
          render: (_, { id, startTime, status }) => (
            <FormItem
              className='padding-cell border-left center'
              shouldUpdate={(prevValues, curValues) =>
                Select.checkShouldUpdate({
                  prevValues,
                  curValues,
                  name: [id, 'isSelectedCheckIn'],
                })
              }
            >
              {() => (
                <Tooltip
                  overlayInnerStyle={{ alignContent: 'center' }}
                  title={moment.unix(startTime).format('HH:mm')}
                  placement='rightTop'
                >
                  <Button
                    className='black-button full-width'
                    disabled={
                      isCompletedTab ||
                      !checkEnabledCheckIn({ startTime, status })
                    }
                    onClick={() =>
                      handleButtonCheckRecord({ id, type: CHECK_IN_TYPE })
                    }
                  >
                    Check-In
                  </Button>
                </Tooltip>
              )}
            </FormItem>
          ),
        },
        {
          title: (
            <FormItem
              className='padding-cell'
              valuePropName='checked'
              name={[`${selectedShift}`, 'checkedAll', 'in']}
              initialValue={false}
            >
              <Checkbox
                disabled={!enableCheckInAll}
                onChange={() =>
                  Select.checkAll({
                    form,
                    selectedShift,
                    type: 'in',
                  })
                }
              />
            </FormItem>
          ),
          render: (_, { id, status, startTime }) => {
            const isEnabled =
              checkEnabledCheckIn({ status, startTime }) && !isCompletedTab;
            return (
              <FormItem
                className='padding-cell'
                name={[`${selectedShift}`, `${id}`, 'checked', 'in']}
                {...(isEnabled && { valuePropName: 'checked' })}
                initialValue={false}
              >
                <Checkbox
                  disabled={!isEnabled}
                  onChange={() =>
                    Select.checkRecord({
                      form,
                      selectedShift,
                      name: 'in',
                      status: 'unchecked',
                    })
                  }
                />
              </FormItem>
            );
          },
          width: '5%',
        },
        {
          title: (
            <FormItem
              className='padding-cell'
              shouldUpdate={(prevValues, curValues) =>
                Select.checkShouldUpdate({
                  prevValues,
                  curValues,
                  name: 'isSelectCheckOutAll',
                })
              }
            >
              {() => (
                <Button
                  className='black-button full-width'
                  disabled={!enableCheckOutAll}
                  onClick={() =>
                    handleButtonCheckAll({
                      type: 'out',
                      actionKey: CHECK_OUT_TYPE,
                    })
                  }
                >
                  Check-Out All
                </Button>
              )}
            </FormItem>
          ),
          width: '10%',
          align: 'center',
          render: (_, { id, status }) => (
            <FormItem
              className='padding-cell'
              shouldUpdate={(prevValues, curValues) =>
                Select.checkShouldUpdate({
                  prevValues,
                  curValues,
                  name: [id, 'isSelectedCheckOut'],
                })
              }
            >
              {() => (
                <Button
                  className='black-button full-width'
                  disabled={isCompletedTab || !checkEnabledCheckOut({ status })}
                  onClick={() =>
                    handleButtonCheckRecord({ id, type: CHECK_OUT_TYPE })
                  }
                >
                  Check-Out
                </Button>
              )}
            </FormItem>
          ),
        },
        {
          title: (
            <FormItem
              className='padding-cell border-right'
              name={[`${selectedShift}`, 'checkedAll', 'out']}
              valuePropName='checked'
              initialValue={false}
            >
              <Checkbox
                disabled={!enableCheckOutAll}
                onChange={() =>
                  Select.checkAll({
                    form,
                    selectedShift,
                    type: 'out',
                  })
                }
              />
            </FormItem>
          ),
          width: '5%',
          render: (_, { id, status }) => {
            const isEnabled =
              checkEnabledCheckOut({ status }) && !isCompletedTab;
            return (
              <FormItem
                className='padding-cell border-right'
                name={[`${selectedShift}`, `${id}`, 'checked', 'out']}
                {...(isEnabled && { valuePropName: 'checked' })}
                initialValue={false}
              >
                <Checkbox
                  disabled={!isEnabled}
                  onChange={() =>
                    Select.checkRecord({
                      form,
                      selectedShift,
                      name: TYPE_CHECKING[CHECK_OUT_TYPE].fieldItem,
                      status: TYPE_CHECKING[CHECK_OUT_TYPE].disabled,
                    })
                  }
                />
              </FormItem>
            );
          },
        },
      ],
    },
    {
      title: <div className='empty-box' />,
      width: '4%',
      render: (_, { id, status }) => {
        const disabledNoShow = camelCase(status) === NO_SHOW_TYPE;
        return (
          <FormItem className='padding-cell'>
            <Button
              className='yellow-button'
              disabled={isCompletedTab || disabledNoShow}
              onClick={() => handleNoShow({ type: NO_SHOW_TYPE, id })}
            >
              No Show
            </Button>
          </FormItem>
        );
      },
    },
  ];
};

export default genConfirmedCols;
