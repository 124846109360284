import React from 'react';

import { FileWordOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { ACCEPT_TYPE } from '@/constants';

import AutoRenderImageOrPDF from '../AutoRenderImageOrPDF';
import './styles.scss';

const UploadFileThumbnail = ({ src, file, mime }) => {
  const thumbnailWithTypeFile = () => {
    const isDocTypeInAWS =
      src?.includes('.doc') || src?.includes('.docx') || src?.includes('.odt');

    const isDocTypeFromLocal =
      src?.includes(ACCEPT_TYPE.docx) ||
      src?.includes(ACCEPT_TYPE.doc) ||
      src?.includes(ACCEPT_TYPE.odt);

    if (src) {
      if (isDocTypeInAWS || isDocTypeFromLocal) {
        let fileName = '';

        if (isDocTypeInAWS) {
          const splitUrl = src.split('/');
          fileName = splitUrl?.[splitUrl.length - 1];
        } else {
          fileName = file.file.name;
        }

        return (
          <a className='doc-file-container' href={src}>
            <FileWordOutlined className='file-icon' />
            <span className='file-title'>{fileName}</span>
          </a>
        );
      }

      const isImage = mime?.includes('image/');

      return (
        <div className='applicant-pdf'>
          <AutoRenderImageOrPDF
            data={src}
            isImage={isImage}
            containerClassName='img-no-boder'
          />
        </div>
      );
    }

    return <p className='text-desc'>No document uploaded</p>;
  };

  return <div className='img-container'>{thumbnailWithTypeFile()}</div>;
};

UploadFileThumbnail.propTypes = {
  src: PropTypes.string,
  file: PropTypes.any,
  mime: PropTypes.string,
};

UploadFileThumbnail.defaultProps = {
  src: '',
};

export default UploadFileThumbnail;
