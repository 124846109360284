import { isEmpty } from 'lodash';

const splitTimesheetToShowData = ({ dataListTimeSheet, listStatues }) => {
  const splitData = {
    finalised: {},
    paid: {},
    confirmed: {},
  };
  if (!isEmpty(dataListTimeSheet)) {
    listStatues.forEach(
      ({ status }) =>
        !isEmpty(dataListTimeSheet[status] ?? []) &&
        dataListTimeSheet[status].forEach((panelInfo) => {
          // eslint-disable-next-line no-shadow
          const data =
            !isEmpty(splitData[status]) &&
            !isEmpty(splitData[status][panelInfo?.skillName])
              ? splitData[status][panelInfo?.skillName]
              : [];

          data.push(panelInfo);
          splitData[status][panelInfo?.skillName] = data;
        }),
    );
  }

  return splitData;
};

export { splitTimesheetToShowData };
