import moment from 'moment';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const convertToHoursAndMinutes = (duration) => ({
  hrs: duration.hours(),
  mins: duration.minutes(),
});

const convertData = (data) => {
  const {
    overtimePaidAfter = 0,
    guaranteedMinimumWeeklyHours = 0,
    guaranteedMinimumShiftLength = 0,
  } = data.payrollTemplateRuleAttributes;

  const overtimePaidAfterDuration = moment.duration(overtimePaidAfter, 'hours');
  const guaranteedMinimumWeeklyHoursDuration = moment.duration(
    guaranteedMinimumWeeklyHours,
    'hours',
  );
  const guaranteedMinimumShiftLengthDuration = moment.duration(
    guaranteedMinimumShiftLength,
    'hours',
  );

  const payrollTemplateRuleAttributes = {
    ...data.payrollTemplateRuleAttributes,
    overtimePaidAfter: convertToHoursAndMinutes(overtimePaidAfterDuration),
    guaranteedMinimumWeeklyHours: convertToHoursAndMinutes(
      guaranteedMinimumWeeklyHoursDuration,
    ),
    guaranteedMinimumShiftLength: convertToHoursAndMinutes(
      guaranteedMinimumShiftLengthDuration,
    ),
  };

  const hourTemplates = data.hourTemplates.map((template) => ({
    ...template,
    startAt: moment(template.startAt),
    endAt: moment(template.endAt),
  }));

  return {
    ...data,
    name: data.name,
    startAt: moment(data.startAt),
    workerTypeId: data.workerTypeId,
    hourTemplates,
    payrollTemplateRuleAttributes,
    providerSelected: {
      value: Number(data?.employerAttributes?.id),
      label: data?.employerAttributes?.name,
    },
    locationsSelected: data?.employerLocations?.map((location) => ({
      value: Number(location.id),
      label: location.name,
      isDestroy: false,
    })),
  };
};

const getPayTemplateInfo = async (/** @type {string|number} */ id) => {
  const res = await request.get(`pay_templates/payroll_templates/${id}`);

  const normalizeResponses = normalizeShowResponse(res);

  return convertData(normalizeResponses.data);
};

export { getPayTemplateInfo };
