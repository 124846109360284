import { message } from 'antd';

import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const fetchLocations = async () => {
  try {
    const res = await request.get('/options/locations');
    const { data } = normalizeIndexResponse(res);
    const result = data?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    return { listLocations: result };
  } catch (error) {
    message.error('Something went wrong!');
    throw error;
  }
};
