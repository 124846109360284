import React, { useEffect, useState } from 'react';

import { Form, Input as AntdInput, message } from 'antd';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { FormItem, Input, Select, Button, Modal } from '@/components';
import { ACCESS_LEVEL } from '@/constants';
import './styles.scss';
import { useOmniUsers } from '@/hooks/dropdowns';
import { UserService } from '@/services';
import {
  rulesEmail,
  rulesPhone,
  rulesSelect,
  rulesTextInput,
} from '@/utils/rulesInput';
import { useDepartmentOptions } from '../../../hooks';

const WorkInformation = () => {
  const { userId } = useParams();
  const formInstance = Form.useFormInstance();
  const [showModal, setShowModal] = useState(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const departments = useDepartmentOptions();

  const password = Form.useWatch('password', formInstance) ?? '';
  const repeatPassword = Form.useWatch('repeatPassword', formInstance) ?? '';

  const listOmniUsers = useOmniUsers();

  useEffect(() => {
    if (password !== repeatPassword) {
      formInstance.setFields([
        {
          name: 'repeatPassword',
          errors: ['Password not match'],
        },
      ]);
      setIsPasswordMatched(false);
    } else {
      formInstance.setFields([
        {
          name: 'repeatPassword',
          errors: [],
        },
      ]);
      setIsPasswordMatched(true);
    }
  }, [password, repeatPassword, formInstance]);

  const { mutate: mutateResetPassword } = useMutation(
    () => UserService.resetPassword(userId, password),
    {
      onSuccess: () => {
        message.success('Reset password successfully!');
        setShowModal(false);
      },
      onError: () => {
        message.error('Reset password unsuccessfully!');
      },
    },
  );

  const renderLeftBody = () => (
    <div className='body-item'>
      <div className='row-item'>
        <FormItem
          name='userName'
          className='form-item'
          label='User Name'
          required
          rules={rulesTextInput('User Name')}
        >
          <Input placeholder='User Name' />
        </FormItem>
        <FormItem
          name='userFullname'
          className='form-item'
          label='Full Name'
          required
          rules={rulesTextInput('Full Name')}
        >
          <Input placeholder='Full Name' />
        </FormItem>
      </div>
      <div className='row-item'>
        <FormItem
          name='userEmail'
          className='form-item'
          label='Work Email'
          required
          rules={rulesEmail(true, 'Work Email')}
        >
          <Input placeholder='Work Email' />
        </FormItem>
        <div className='password-form'>
          <div className='password-container'>
            <FormItem
              name='password'
              className='password'
              label='Manually Reset Password'
            >
              <AntdInput.Password
                autoComplete='new-password'
                className='input-height password-input'
                placeholder='Password'
              />
            </FormItem>
            <FormItem name='repeatPassword' className='password'>
              <AntdInput.Password
                autoComplete='new-password'
                className='input-height password-input'
                placeholder='Repeat Password'
              />
            </FormItem>
          </div>
          <FormItem className='password-container'>
            <Button
              onClick={() => setShowModal(true)}
              className='yellow-button password'
              disabled={!isPasswordMatched || !userId}
            >
              Reset Password
            </Button>
          </FormItem>
        </div>
      </div>
      <div className='row-item'>
        <FormItem
          name='userTels'
          className='form-item'
          label='Work Number'
          required
          rules={rulesPhone()}
        >
          <Input placeholder='Work Number' />
        </FormItem>
      </div>
    </div>
  );

  const renderRightBody = () => (
    <div className='body-item'>
      <div className='row-item'>
        <FormItem
          name='userPosition'
          className='form-item'
          label='Position'
          required
          rules={rulesTextInput('Position')}
        >
          <Input placeholder='Position' />
        </FormItem>
        <FormItem
          name='departmentId'
          className='form-item'
          label='Department'
          required
          rules={rulesSelect('Department')}
        >
          <Select placeholder='Department' options={departments} />
        </FormItem>
      </div>
      <div className='row-item'>
        <FormItem
          name='holidayApproval'
          className='form-item'
          label='Line Manager'
          required
          rules={rulesSelect('Line Manager')}
        >
          <Select options={listOmniUsers} placeholder='Line Manager' />
        </FormItem>
        <FormItem name='userLevelId' className='form-item' label='Access Level'>
          <Select placeholder='Access Level' options={ACCESS_LEVEL} />
        </FormItem>
      </div>
    </div>
  );

  return (
    <div className='work-information'>
      {renderLeftBody()}
      {renderRightBody()}

      <Modal
        // footer={null}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        closable={false}
        onClickYes={() => mutateResetPassword()}
        onClickNo={() => setShowModal(false)}
        title='Reset Password?'
      >
        Are you sure you want to reset password? An email will be sent to the
        User to reset their Password.
      </Modal>
    </div>
  );
};

export default WorkInformation;
