import request from '@/request';
import { getAddressInfomation } from '@/services/getAddressInfomation';

const createLeads = async (dataFormSubmited, postcode) => {
  const geogecode = await getAddressInfomation(`${postcode}-uk`);
  const { location } = geogecode[0].geometry;
  const { lat, lng } = location;

  const payload = {
    ...dataFormSubmited,
    manager_id: null,
    locationAttributes: {
      latitude: lat,
      longitude: lng,
    },
  };

  return request.post('/guests/leads', payload);
};

export default createLeads;
