import React from 'react';

import { Form } from 'antd';
import { has } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useToggle } from 'usehooks-ts';

import { Button } from '@/components';
import ModalAddAdditionalJobType from '@/components/ModalAddAdditionalJobType';

const TableFooter = ({
  locationId,
  employerId,
  weekStartDate,
  weekEndDate,
  weekNumber,
  refetchListPayCharge,
  timesheetRefetch,
}) => {
  const formInstance = Form.useFormInstance();
  const [isVisible, toggleVisible] = useToggle();

  const isAfterCurrWeek = moment(weekStartDate).isAfter(moment());

  const disabledAddJobType = () => {
    const jobsData = formInstance.getFieldValue(['jobTypeAdded', 'current']);
    return !(has(jobsData, 'unapproved') || has(jobsData, 'approved'));
  };
  return (
    <>
      <Button
        onClick={toggleVisible}
        className='yellow-button table-footer'
        disabled={disabledAddJobType() || isAfterCurrWeek}
      >
        <p className='plus-icon'>+</p>
        <p className='button-content'>Add Timecards</p>
      </Button>
      <ModalAddAdditionalJobType
        locationId={locationId}
        visible={isVisible}
        onOk={toggleVisible}
        employerId={employerId}
        weekStartDate={weekStartDate}
        weekEndDate={weekEndDate}
        onCancel={toggleVisible}
        setIsVisible={toggleVisible}
        weekNumber={weekNumber}
        refetchListPayCharge={refetchListPayCharge}
        timesheetRefetch={timesheetRefetch}
      />
    </>
  );
};

TableFooter.propTypes = {
  locationId: PropTypes.number,
  employerId: PropTypes.number,
  weekStartDate: PropTypes.string,
  weekEndDate: PropTypes.string,
  weekNumber: PropTypes.number,
  refetchListPayCharge: PropTypes.func,
  timesheetRefetch: PropTypes.func,
};

TableFooter.defaultProps = {
  locationId: null,
  employerId: null,
  refetchListPayCharge: () => {},
  timesheetRefetch: () => {},
};

export default TableFooter;
