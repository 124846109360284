import { StorageKey } from '@/constants';

export function getAuthUser() {
  try {
    return JSON.parse(localStorage.getItem(StorageKey.authUser));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
}

export function setAuthUser(user) {
  localStorage.setItem(StorageKey.authUser, JSON.stringify(user));
}

export function removeAuthUser() {
  localStorage.removeItem(StorageKey.authUser);
}

export function getAuthToken() {
  try {
    return JSON.parse(localStorage.getItem(StorageKey.authToken));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
}

export function setAuthToken(token) {
  localStorage.setItem(StorageKey.authToken, JSON.stringify(token));
}

export function removeAuthToken() {
  localStorage.removeItem(StorageKey.authToken);
}

/**
 * @param {string} username
 */
export function setUserName(username) {
  localStorage.setItem(StorageKey.authUser, username);
}

/**
 *  getUserName
 * @returns
 */
export function getUserName() {
  return localStorage.getItem(StorageKey.authUser) ?? '';
}

export function removeUserName() {
  localStorage.removeItem(StorageKey.authUser);
}

export function setUserId(userId) {
  localStorage.setItem(StorageKey.authUserId, userId);
}

export function getUserId() {
  return localStorage.getItem(StorageKey.authUserId) ?? '';
}

export function removeUserId() {
  localStorage.removeItem(StorageKey.authUserId);
}
