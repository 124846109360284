import React from 'react';

import { groupBy, sumBy, union } from 'lodash';
import PropTypes from 'prop-types';

import './index.scss';
import { TableInvoice, TotalCard } from '@/components';
import { handleCombineInvoice } from '@/pages/Timesheet/Detail/constants';
import Formatter from '@/utils/Formatter';
import { INVOICE_TYPE } from '@/utils/payrollHelper';

const InvoiceProcessed = ({ data, totalCost }) => {
  const totalCharge = sumBy(data, (cur) => cur.omniNetPay || 0);

  const margin =
    totalCharge !== 0
      ? (((totalCharge - totalCost) / totalCharge) * 100).toFixed(2)
      : NaN;

  const separateData = (arr) =>
    groupBy(arr, (e) =>
      e.invoiceDetailType === INVOICE_TYPE.COST
        ? 'dataAdditional'
        : 'dataNormal',
    );

  const calAndGroupData = (arr) => {
    const { dataAdditional = [], dataNormal = [] } = separateData(arr);

    const combineInvoice = handleCombineInvoice(dataNormal ?? []);
    const groupDataNormal = groupBy(combineInvoice, 'skillName');

    return { dataAdditional, dataNormal: { ...groupDataNormal } };
  };

  const { dataAdditional = [], dataNormal = {} } = calAndGroupData(data);

  return (
    <div className='invoice-processed'>
      <div className='title-detail'>Invoice Processed</div>
      {Object.keys(dataNormal).map((skillName) => (
        <TableInvoice
          key={union('table-normal')}
          title={skillName}
          data={dataNormal[skillName].map((e) => ({
            ...e,
            detail: `${e.hours} Hours @ ${Formatter.currencyGBP(
              e.omniRate,
            )} Per Hour`,
          }))}
        />
      ))}

      {dataAdditional.length > 0 && (
        <TableInvoice
          title='Additional Cost'
          data={dataAdditional.map((e) => {
            const { description } = e;
            const costType = description.split(' ')?.[1];
            return {
              ...e,
              detail: costType,
            };
          })}
        />
      )}

      <TableInvoice
        title='Total Invoice Cost'
        data={[
          {
            omniNetPay: totalCharge,
            omniVat: (totalCharge * 0.2).toFixed(2),
            total: (totalCharge + totalCharge * 0.2).toFixed(2),
            detail: 'Total',
          },
        ]}
        hasTotal
      />
      <div className='total-list'>
        <div className='total-card-column'>
          <TotalCard
            title='Total Costs'
            amount={Formatter.currencyGBP(totalCost)}
          />
        </div>
        <div className='total-card-column total-charge'>
          <TotalCard
            title='Total NET Charge'
            amount={Formatter.currencyGBP(totalCharge)}
            isRed
          />
        </div>
        <div className='total-card-column'>
          <TotalCard title='Margin' amount={margin} unit='%' />
        </div>
      </div>
    </div>
  );
};

InvoiceProcessed.propTypes = {
  data: PropTypes.array,
  totalCost: PropTypes.number,
};

InvoiceProcessed.defaultProps = {
  data: [],
  totalCost: 0,
};

export default InvoiceProcessed;
