import moment from 'moment';

import { DEFAULT_PAYRATE, PAYROLL_ENTRY_CODES_PT } from '@/constants';
import handleChange from '@/pages/PayTemplate/Payroll/utils/handleChange';
import Formatter from '@/utils/Formatter';

import { CELL_TYPES } from './constants';
import { renderCellValue } from './utils';

const genHourlyColumns = ({
  isEdit,
  panelId,
  timecard,
  skillName,
  dayOfWeek,
  shiftTime,
  seekerName,
  formInstance,
  payTemplateName,
  providerRateCard,
}) => {
  const namePath = [`${panelId}`, 'timecards', `${timecard.timecardId}`];
  return [
    {
      children: [
        {
          title: renderCellValue({ label: seekerName, type: 'header' }),
          width: '7%',
          render: (_, record, indexRow) =>
            indexRow === 0 && renderCellValue({ label: dayOfWeek }),
        },
      ],
    },
    {
      children: [
        {
          title: renderCellValue({ label: 'Shift Times' }),
          width: '7%',
          render: (_, record, indexRow) => {
            if (indexRow === 0) {
              const [startShift, endShift] = shiftTime;
              const label = `${startShift.format('HH:mm')} - ${endShift.format(
                'HH:mm',
              )}`;
              return renderCellValue({
                label,
                initialValue: shiftTime,
                type: CELL_TYPES.range,
                name: [...namePath, `${record.id}`, 'shiftTime'],
              });
            }
            if (indexRow === 1) {
              return renderCellValue({ label: skillName });
            }
            return null;
          },
        },
      ],
    },
    {
      title: renderCellValue({ label: 'Breakdown of total hours by day' }),
      dataIndex: 'payrollTemplateHours',
      children: [
        {
          title: renderCellValue({ label: 'Start' }),
          dataIndex: 'startAt',
          width: '3.5%',
          render: (startAt, record) => {
            const parsedToHoursMins = moment(startAt).format('HH:mm');
            return renderCellValue({
              label: parsedToHoursMins,
              isOverTime: record?.isOverTime,
            });
          },
        },
        {
          title: renderCellValue({ label: 'End' }),
          dataIndex: 'endAt',
          width: '3.5%',
          render: (endAt, record) => {
            const parsedToHoursMins = moment(endAt).format('HH:mm');
            return renderCellValue({
              label: parsedToHoursMins,
              isOverTime: record?.isOverTime,
            });
          },
        },
        {
          title: renderCellValue({ label: 'Time in pay template' }),
          children: [
            {
              width: '4.5%',
              render: (_, record) => {
                const { startAt, endAt } = record;
                const duration = moment
                  .duration(moment(endAt).diff(moment(startAt)))
                  .asHours();
                return renderCellValue({
                  label: duration,
                  isOverTime: record?.isOverTime,
                });
              },
            },
            {
              width: '2.5%',
              render: () => renderCellValue({ label: 'hrs' }),
            },
          ],
        },
        {
          title: renderCellValue({ label: 'Pay' }),
          dataIndex: 'adjustedPayrate',
          width: '4.5%',
          render: (adjustedPayrate, record) =>
            renderCellValue({
              label: `£${adjustedPayrate}`,
              initialValue: adjustedPayrate,
              type: CELL_TYPES.number,
              isEdit,
              prefix: '£',
              isOverTime: record?.isOverTime,
              name: [...namePath, `${record.id}`, 'adjustedPayrate'],
              rules: [
                {
                  validator: async (_, val) => {
                    const payRate = Number(val);

                    if (payRate <= DEFAULT_PAYRATE) {
                      return Promise.reject(
                        new Error(
                          `Value must be greater than ${DEFAULT_PAYRATE}`,
                        ),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ],
              onChange: (valueChanged) =>
                handleChange.payRate({
                  formInstance,
                  namePath: [...namePath, `${record.id}`],
                  valueChanged,
                  providerRateCard,
                }),
            }),
        },
        {
          title: renderCellValue({ label: 'Charge' }),
          dataIndex: 'chargeRate',
          width: '4.5%',
          render: (chargeRate, record) =>
            renderCellValue({
              label: `£${chargeRate}`,
              type: CELL_TYPES.number,
              isOverTime: record?.isOverTime,
              prefix: '£',
              name: [...namePath, `${record.id}`, 'chargeRate'],
            }),
        },
        {
          title: renderCellValue({ label: 'Template' }),
          width: '7%',
          render: (record) =>
            renderCellValue({
              label: payTemplateName + (record?.isOverTime ? ' (OT)' : ''),
              isOverTime: record?.isOverTime,
            }),
        },
        {
          title: renderCellValue({ label: 'Pay Template' }),
          dataIndex: 'hourTemplateName',
          width: '7%',
          render: (hourTemplateName, record) =>
            renderCellValue({
              label: hourTemplateName,
              name: [...namePath, `${record.id}`, 'hourTemplateName'],
            }),
        },
        {
          title: renderCellValue({ label: 'Pay' }),
          dataIndex: 'payRateWithoutBreak',
          width: '4.5%',
          render: (payRateWithoutBreak, { isOverTime }) =>
            renderCellValue({
              isOverTime,
              prefix: '£',
              label: Formatter.fixedNumber({ value: payRateWithoutBreak }),
            }),
        },
        {
          title: renderCellValue({ label: 'Charge' }),
          width: '4.5%',
          dataIndex: 'chargeRateWithoutBreak',
          render: (chargeRateWithBreak) =>
            renderCellValue({
              prefix: '£',
              label: Formatter.fixedNumber({ value: chargeRateWithBreak }),
            }),
        },
      ],
    },
    {
      title: renderCellValue({ label: 'Amendments for Breaks' }),
      children: [
        {
          title: renderCellValue({ label: 'Break' }),
          dataIndex: 'breakDuration',
          width: '4.5%',
          render: (_, record) =>
            renderCellValue({
              isEdit,
              name: [...namePath, `${record.id}`, 'breakDuration'],
              type: 'number',
              isOverTime: record?.isOverTime,
            }),
        },
        {
          title: renderCellValue({ label: 'Time' }),
          dataIndex: 'time',
          width: '2.5%',
          render: () => renderCellValue({ label: 'min' }),
        },
        {
          title: renderCellValue({ label: 'Pay' }),
          dataIndex: 'payRateWithBreak',
          width: '3%',
          render: (payRateWithBreak, { isOverTime }) =>
            renderCellValue({
              prefix: '£',
              label: Formatter.fixedNumber({ value: payRateWithBreak }),
              isOverTime,
            }),
        },
        {
          title: renderCellValue({ label: 'Charge' }),
          dataIndex: 'chargeRateWithBreak',
          width: '3%',
          render: (chargeRateWithBreak, { isOverTime }) =>
            renderCellValue({
              prefix: '£',
              label: Formatter.fixedNumber({ value: chargeRateWithBreak }),
              isOverTime,
            }),
        },
      ],
    },
    {
      title: renderCellValue({
        label: 'Addition or Deduction by Pay Template',
      }),
      children: [
        {
          className: 'select-cell',
          width: '5%',
          dataIndex: 'bonusType',
          render: (_, record) =>
            renderCellValue({
              optionsSelect: PAYROLL_ENTRY_CODES_PT,
              type: 'select',
              isEdit,
              name: [...namePath, `${record.id}`, 'bonusType'],
            }),
        },
        {
          width: '4.5%',
          dataIndex: 'bonusValue',
          render: (bonusValue, record) =>
            renderCellValue({
              type: CELL_TYPES.number,
              isEdit,
              name: [...namePath, `${record.id}`, 'bonusValue'],
            }),
        },
      ],
    },
    {
      title: renderCellValue({ label: 'Payroll Totals' }),
      dataIndex: 'PayrollTotal',
      children: [
        {
          title: renderCellValue({ label: 'Hours' }),
          dataIndex: 'hourWithBonus',
          width: '4.5%',
          render: (hourWithBonus) =>
            renderCellValue({
              label: Formatter.fixedNumber({ value: hourWithBonus }),
            }),
        },
        {
          title: renderCellValue({ label: 'Pay' }),
          dataIndex: 'payRateWithBonus',
          width: '4.5%',
          render: (payRateWithBonus) =>
            renderCellValue({
              label: Formatter.fixedNumber({ value: payRateWithBonus }),
              prefix: '£',
            }),
        },
        {
          title: renderCellValue({ label: 'Charge' }),
          width: '4.5%',
          dataIndex: 'chargeRateWithBreak',
          render: (chargeRateWithBreak) =>
            renderCellValue({
              label: Formatter.fixedNumber({ value: chargeRateWithBreak }),
              prefix: '£',
            }),
        },
      ],
    },
  ];
};

export default genHourlyColumns;
