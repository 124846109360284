import { useQuery } from 'react-query';

import { fetchLanguageLevel } from '@/services/dropdownServices/getLanguageLevel';

const useLanguageLevel = () => {
  const { data: resLanguageLevel } = useQuery(
    ['languageLevel'],
    fetchLanguageLevel,
    { refetchOnWindowFocus: false },
  );

  return { ...resLanguageLevel };
};

export default useLanguageLevel;
