import { useState } from 'react';

import moment from 'moment';

import { date } from '@/utils';
import { convertTimeMoment } from '@/utils/convertTimeMoment';

import useMergeState from './useMergeState';

const useShiftTime = (isInListView) => {
  let hour = moment().toDate().getHours();
  let minute = moment().toDate().getMinutes();
  let endHour = hour + 1; // endTime = startTime + 1h as default

  if (minute >= 30) {
    minute = '00';
    endHour += 1;
    hour += 1;
  } else {
    minute = 30;
  }

  const [dateTime, setDateTime] = useMergeState({
    startDate: moment().toDate(),
    startTime: isInListView
      ? moment()
      : convertTimeMoment(moment().toDate(), `${hour}:${minute}`),
    endDate: moment().toDate(),
    endTime: convertTimeMoment(moment().toDate(), `${endHour}:${minute}`),
  });

  const { startDate, startTime, endDate, endTime } = dateTime;

  const value = { startDate, startTime, endDate, endTime };

  const duration = Number(date.getDuration(startTime, endTime) ?? 0).toFixed(2);

  // the shift list has a first item is current date time
  const [shiftList, setShiftList] = useState([]);

  const handleAddShift = (shifts, form) => {
    shifts.push({
      id: `${Math.floor(Math.random() * 10)}${moment().unix()}`,
      startDate,
      startTime,
      endTime,
      endDate,
      duration,
    });

    form.setFieldsValue({
      items: shifts,
    });

    setShiftList(shifts);
  };

  const removeShift = ({ shiftId, form }) => {
    const shifts = shiftList;
    const updatedShifts = shifts.filter((shift) => shift.id !== shiftId);

    form.setFieldsValue({ items: updatedShifts });

    setShiftList(updatedShifts);
  };

  return {
    value,
    setDateTime,
    dateTime,
    startTime,
    hour,
    minute,
    shiftList,
    setShiftList,
    startDate,
    endTime,
    endDate,
    duration,
    handleAddShift,
    removeShift,
  };
};

export default useShiftTime;
