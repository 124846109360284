/* eslint-disable no-underscore-dangle */
import React from 'react';

import PropTypes from 'prop-types';

import Body from './Body';
import Footer from './Footer';
import Header from './Header';

import './styles.scss';

const PreviewInvoice = ({ pdfRef }) => (
  <div ref={pdfRef} id='invoice-pdf-container' className='preview-invoice-pdf'>
    <Header />
    <Body />
    <Footer />
  </div>
);

export default PreviewInvoice;

PreviewInvoice.propTypes = {
  pdfRef: PropTypes.object,
};

PreviewInvoice.defaultProps = {
  pdfRef: null,
};
