import { sortBy, upperFirst } from 'lodash';
import moment from 'moment';

import { days } from '../../constants';

const daysOrder = days.map((day) => upperFirst(day));

const sortByDay = (data) =>
  sortBy(data, ({ date }) => {
    const dayOfWeek = moment(date).format('dddd');
    return daysOrder.indexOf(dayOfWeek);
  });

const sort = {
  sortByDay,
};

export default sort;
