import { isArray, isEmpty, remove as lodashRemove, toNumber } from 'lodash';
import moment from 'moment';

import { REGEX_EMAIL } from '@/constants';

/**
 * @param {Array} array
 * @return {Array|undefined}
 */
export const isArrayOverZero = (array) => {
  const isValidArray = Array.isArray(array) && array.length > 0;
  if (!isValidArray) return undefined;
  return array;
};

/**
 * @param {Array} array
 * @return {string}
 */
export const toStringOfNumber = (array) => {
  const isValidArray = Array.isArray(array) && array.length > 0;
  if (!isValidArray) return '';

  const hasAllOption = array.includes('all');
  if (hasAllOption) return array.slice(0, -1).join(',');

  return array.join(',');
};

/**
 * @param {Array} array
 * @param {any} key
 * @return {Array|undefined}
 */
export const remove = (array, key) => {
  if (isArray(array)) {
    lodashRemove(array, (k) => k === key);
    return array;
  }

  return undefined;
};

/**
 * @param {Array} array
 * @param {number} initialValue
 * @return {number}
 */
export const totalOfValue = (array, initialValue) => {
  const total = array.reduce((accumulator, currentValue) => {
    if (isNaN(toNumber(currentValue))) {
      return accumulator;
    }

    return accumulator + toNumber(currentValue);
  }, initialValue);

  return parseFloat(total);
};

/**
 * @param {Array} weekItems
 * @return {number}
 */
export const getFirstWeekItem = (weekItems) => {
  const currentWeek = moment().week();

  if (
    !isArray(weekItems) ||
    weekItems.find(({ value }) => value === currentWeek)
  ) {
    return currentWeek;
  }

  return weekItems.at(-1).value;
};

/**
 * @param {Array} options
 * @param {Array} values
 * @return {Array}
 */
export const getLabels = (options, values) =>
  values
    ?.map((val) => options?.filter((item) => val === item.value && item))
    ?.flat() ?? [];

export function toArray(value = []) {
  return Array.isArray(value) ? value : [value];
}

/**
 * @param {Array} array
 * @return {boolean}
 */
export const checkAllFieldsTruthy = (array = []) =>
  !isEmpty(array) &&
  array.every((obj) => {
    const allValues = Object.values(obj);
    return !isEmpty(allValues) && allValues.every(Boolean);
  });

/**
 * @param {Array} array
 * @param {Array} keys
 * @return {boolean}
 */
export const isRequireKeysValueTruthy = (array = [], keys = []) =>
  !isEmpty(array) &&
  !isEmpty(keys) &&
  array.every((obj) => keys.every((key) => Boolean(obj[key])));

export const isValidAllEmail = (array = []) =>
  !isEmpty(array) && array.every((obj) => REGEX_EMAIL.test(obj.email));
