/* eslint-disable max-len */
import moment from 'moment';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const getOneRota = async (/** @type {string|number} */ id) => {
  const endpoint = `/rotas/rotas/${id}`;

  const res = await request.get(endpoint);
  const data = normalizeShowResponse(res);
  const rotaDetail = data?.data;
  rotaDetail.rotaWeekAttributes = rotaDetail.rotaWeekAttributes.map((week) => ({
    ...week,
    rotaShiftsAttributes: week.rotaShifts.map((shift) => {
      const { status } = shift;
      const dateSelect =
        status === 'work'
          ? `${moment(shift.startTime).format('HH:mm')} - ${moment(
              shift.endTime,
            ).format('HH:mm')}`
          : 'off';
      return {
        ...shift,
        dateSelect,
        startTime: moment(shift.startTime).format('HH:mm'),
        endTime: moment(shift.endTime).format('HH:mm'),
      };
    }),
  }));

  return {
    ...rotaDetail,
    originalStartTime: rotaDetail.startTime,
    originalEndTime: rotaDetail.endTime,
    startTime: moment(rotaDetail.startTime).format('HH:mm'),
    endTime: moment(rotaDetail.endTime).format('HH:mm'),
  };
};

export default getOneRota;
