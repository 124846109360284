import request from '@/request';

const undoSickNoShow = async ({ timesheetId, timecardIds }) => {
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/timecards/undo_sick_no_show`;
  const res = await request.patch(endpoint, {
    timecardIds,
  });

  return res;
};

export default undoSickNoShow;
