import request from '@/request';
import Formatter from '@/utils/Formatter';

export const getStatusOfLead = async () => {
  const res = await request.get('options/lead_statuses');
  const { data: statuses } = res.data;
  const data = Object.entries(statuses ?? {}).map(([label, value]) => ({
    label: Formatter.capitalizeWords(`${label}`.replaceAll('_', ' ')),
    value,
  }));

  return data;
};
