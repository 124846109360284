import React from 'react';

import { CheckboxGroup, FormItem } from '@/components';
import { rulesSelect } from '@/utils/rulesInput';

import { supplierRoles } from '../../mockData';
import './styles.scss';

const Roles = () => (
  <FormItem
    name='supplierTypes'
    label='Supplier Type'
    className='form-item supplier-type'
    rules={rulesSelect('Supplier Type')}
    required
  >
    <CheckboxGroup multiple options={supplierRoles} />
  </FormItem>
);

export default Roles;
