import React from 'react';

import { Form, Typography } from 'antd';

import ResultCard from '../../ResultCard';
import CostsSectionItem from './CostsSectionItem';

import './styles.scss';

const CostsSections = () => (
  <div className='costs-section-container'>
    <Typography.Title level={5}>Additional Costs</Typography.Title>
    <div className='costs-inputs'>
      <Form.List name='cost'>
        {(fields, { remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className='hour-section-container'>
                <CostsSectionItem name={name} remove={remove} />
                <ResultCard name={name} />
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  </div>
);

export default CostsSections;
