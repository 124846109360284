import React from 'react';

import { Form, Tooltip } from 'antd';

import { ReactComponent as Note } from '@/assets/icons/note.svg';
import { CheckboxGroup, FormItem, Input, Select } from '@/components';
import {
  useCostWeeks,
  useProviderLocations,
  useProviders,
  useSuppliers,
} from '@/hooks/dropdowns';
import { rulesNumberInput, rulesSelect } from '@/utils/rulesInput';

import { costRoles } from '../../mockData';
import Comments from '../Comments';
import InvoiceDocument from '../InvoiceDocument';

import './styles.scss';

const CostDetail = () => {
  const suppliers = useSuppliers();
  const formInstance = Form.useFormInstance();
  const providers = useProviders();
  const providerId = Form.useWatch('allocateAgainstProvider');
  const providerLocationId = Form.useWatch('employerLocationId');
  const weeks = useCostWeeks({
    employerId: providerId,
    locationId: providerLocationId,
  });
  const { data: providerLocations } = useProviderLocations(providerId);

  const supplierTypes = Form.useWatch('supplierTypes', formInstance);

  const onChangeSupplier = (val) => {
    const supplierItem = suppliers.find((item) => item.value === val);

    formInstance.setFieldsValue({
      supplierTypes: supplierItem.supplierTypes,
    });
  };

  const handleChange = () => {
    formInstance.setFieldsValue({
      employerLocationId: null,
      week: null,
    });
  };

  return (
    <div className='form-wrapper'>
      <div className='fields-left'>
        <div className='basic'>
          <FormItem
            name='supplierId'
            label='Supplier'
            rules={rulesSelect('Supplier')}
          >
            <Select
              onChange={(val) => onChangeSupplier(val)}
              options={suppliers}
              placeholder='Supplier'
            />
          </FormItem>

          <FormItem
            required
            name='invoiceId'
            label='Invoice Number'
            rules={rulesNumberInput('Invoice Number')}
          >
            <Input placeholder='Invoice Number' />
          </FormItem>

          <FormItem
            required
            name='totalNetCost'
            label='Total NET Cost'
            rules={rulesNumberInput('Total NET Cost')}
          >
            <Input placeholder='Total NET Cost' />
          </FormItem>

          <FormItem
            name='weekSpreadCost'
            className='spread-of-cost-container'
            label={
              <div className='label-note-container'>
                <div>Weeks to spread of cost</div>
                <Tooltip
                  color='white'
                  overlayClassName='cost-tooltip-label-note'
                  placement='topLeft'
                  title='This will spread the total cost over the chosen number of future weeks.'
                >
                  <Note />
                </Tooltip>
              </div>
            }
            rules={rulesNumberInput('Weeks to spread of cost')}
            initialValue={1}
          >
            <Input placeholder='Weeks to spread of cost' />
          </FormItem>

          <FormItem
            name='allocateAgainstProvider'
            label='Allocate against Client'
            rules={rulesSelect('Allocate against Client')}
          >
            <Select
              placeholder='Allocate against Client'
              options={providers}
              onChange={handleChange}
            />
          </FormItem>

          <FormItem
            name='employerLocationId'
            label='Location'
            rules={rulesSelect('Location')}
          >
            <Select
              placeholder='Location'
              options={providerLocations}
              defaultValue={providerLocations?.at(-1).value}
            />
          </FormItem>

          <FormItem
            name='week'
            label='Weekly Timesheet to allocate'
            rules={rulesSelect('Weekly Timesheet to allocate')}
          >
            {/* // TODO: update later */}
            <Select
              placeholder='Weekly Timesheet to allocate'
              options={weeks}
              defaultValue={weeks?.at(-1).value}
            />
          </FormItem>
        </div>
        <div className='roles'>
          {/* // TODO: @Harvey will update role only choose 1 */}
          <FormItem
            name='supplierTypes'
            label='Supplier Type'
            className='form-item cost-roles'
            valuePropName='checked'
          >
            <CheckboxGroup options={costRoles} value={supplierTypes} />
          </FormItem>
        </div>
        <div className='invoice-document'>
          <InvoiceDocument />
        </div>
      </div>
      <div className='fields-right'>
        <Comments />
      </div>
    </div>
  );
};
export default CostDetail;
