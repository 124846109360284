import { useState } from 'react';

const useSessionStorage = (keyName, defaultValue) => {
  const removeSession = () => {
    sessionStorage.removeItem(keyName);
  };

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = sessionStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      }
      sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    sessionStorage.setItem(keyName, JSON.stringify(newValue));
    setStoredValue(newValue);
  };

  return [storedValue, setValue, removeSession];
};

export default useSessionStorage;
