import React, { useEffect, useState } from 'react';

import { Form, Pagination, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import under18 from '@/assets/icons/under18.png';
import { ReactComponent as XIcon } from '@/assets/icons/Xicon.svg';
import { Button, CSV, FormItem, GeneralTemplate, ListView } from '@/components';
import { PAGE_SIZE_OPTIONS } from '@/constants';
import {
  useDownloadCsv,
  useFetch,
  usePagination,
  useSessionStorage,
} from '@/hooks';
import { SeekerService } from '@/services';
import downloadCsv from '@/services/seeker/downloadCsv';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import { breadcrumb } from './constants';
import SeekerSearchFields from './seekerSearchFields';
import './styles.scss';

const checkDate = (/** @type {moment.MomentInput} */ value) => {
  const formatDate = moment(value).utc().format('DD-MM-YYYY');
  return formatDate === 'Invalid date' ? '' : formatDate;
};

const columns = generateHeaderTable([
  {
    title: undefined,
    width: '7%',
    key: 'blockType',
    dataIndex: 'blockType',
    render: (value, record) => (
      <div style={{ display: 'flex' }}>
        {!isNaN(parseInt(record.blockType, 10)) && <XIcon />}
        {!record.isOver18 && (
          <img className='favourite-icon seeker-icon' src={under18} alt='' />
        )}
      </div>
    ),
  },
  {
    title: 'Number',
    width: '7%',
    key: 'id',
    dataIndex: 'number',
  },
  {
    title: 'Full Name',
    width: '10%',
    dataIndex: 'fullname',
    render: (/** @type {string} */ fullname) => (
      <Tooltip placement='topLeft' title={fullname}>
        {fullname}
      </Tooltip>
    ),
  },
  {
    title: 'Email Address',
    width: '15%',
    ellipsis: {
      showTitle: false,
    },
    dataIndex: 'email',
    render: (/** @type {string} */ email) => (
      <Tooltip placement='topLeft' title={email}>
        {email}
      </Tooltip>
    ),
  },
  {
    title: 'Phone Number',
    width: '10%',
  },
  {
    title: 'Post Code',
    width: '7%',
    dataIndex: 'postcode',
  },
  {
    title: 'Last Client Worked For',
    dataIndex: 'lastJobWorked',
  },
  {
    title: 'Last Shift Worked',
    defaultSortOrder: 'descend',
    sorter: true,
  },
  {
    title: 'Application Date',
    defaultSortOrder: 'descend',
    sorter: true,
    render: (/** @type {string} */ value) => checkDate(value),
  },
  {
    title: 'Location',
    width: '7%',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ location) => (
      <Tooltip placement='topLeft' title={location}>
        {location}
      </Tooltip>
    ),
  },
]);

const SeekerListPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [sortOrder, setSortOrder] = useState('desc');

  const [storedValue, setSession, removeSession] =
    useSessionStorage('seekerSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    currentPage,
    handlePagination,
    paginationPageSize,
    handleShowSizeChange,
  } = usePagination();

  const {
    refCSVLink,
    data: dataSeekerExportCSV,
    refetch: refetchSeekerExportCSV,
    isFetching: isFetchingDownloadCsv,
  } = useDownloadCsv(['seeker-export-csv'], () =>
    downloadCsv({
      ...form.getFieldsValue(),
      sortOrder,
      pageNumber: currentPage,
      pageSize: paginationPageSize,
    }),
  );

  const {
    refetch,
    isFetching,
    data: dataSeeker,
  } = useFetch(
    ['seekers', currentPage, currentPage, paginationPageSize, sortOrder],
    () =>
      SeekerService.getSeekers({
        ...form.getFieldsValue(),
        sortOrder,
        pageNumber: currentPage,
        pageSize: paginationPageSize,
      }),
  );

  const clearFilter = () => {
    form.resetFields();
    removeSession();
    refetch();
  };

  const onFilter = () => {
    setSession(form.getFieldsValue());
    refetch();
  };

  const handleListViewSort = (order) =>
    setSortOrder(Formatter.makeAbbrevSortType(order));
  return (
    <GeneralTemplate
      hasButton={false}
      data={breadcrumb}
      mainItem='Seekers'
      headerText='Seekers'
      subTemplateClassName='seeker-container'
    >
      <Spin spinning={isFetchingDownloadCsv}>
        <div className='seeker-detail-container'>
          <Form form={form} onFinish={onFilter}>
            <SeekerSearchFields />
            <div className='seeker-actions'>
              <FormItem className='btn-container'>
                <Button
                  htmlType='submit'
                  className='btn-style yellow-button input-height'
                >
                  Search
                </Button>
              </FormItem>
              <FormItem className='btn-container'>
                <Button
                  className='btn-clear filter-button input-height btn-style'
                  onClick={clearFilter}
                >
                  Clear Filters
                </Button>
              </FormItem>
            </div>
          </Form>
          <ListView
            loading={isFetching}
            headerColumns={columns}
            infoDetail={dataSeeker?.data}
            locale={{
              emptyText: 'No Seekers match your search criteria',
            }}
            onRow={(/** @type {number} */ rowId) => {
              history.push(`/seeker-profile/${rowId}`);
            }}
            handleSort={handleListViewSort}
          />
          <div className='seeker-paginations'>
            <div className='pagination-container'>
              <Pagination
                responsive
                showSizeChanger
                current={currentPage}
                total={dataSeeker?.totalCount}
                pageSize={paginationPageSize}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                onChange={handlePagination}
                onShowSizeChange={handleShowSizeChange}
              />
            </div>
            <Button
              className='btn-submit yellow-button'
              onClick={refetchSeekerExportCSV}
              disabled={isFetchingDownloadCsv}
            >
              {isFetchingDownloadCsv ? 'Loading csv...' : 'Download CSV'}
            </Button>
            <CSV
              ref={refCSVLink}
              filename='data-seeker.csv'
              data={dataSeekerExportCSV}
            />
          </div>
        </div>
      </Spin>
    </GeneralTemplate>
  );
};

export default SeekerListPage;
