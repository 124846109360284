import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';

const declinedColumns = ({ openModalSeekerInfo }) => {
  const columns = [
    {
      dataIndex: 'employeeName',
      width: '20%',
      render: (data, record) => (
        <div>
          <span>{data}</span>
          <InfoCircleOutlined
            className='info-icon'
            onClick={() => openModalSeekerInfo(record.employeeId)}
          />
        </div>
      ),
    },
  ];

  return columns;
};
export default declinedColumns;
