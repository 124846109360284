/* eslint-disable max-len */
import React from 'react';

import {
  AutoComplete,
  DatePicker,
  FormItem,
  MultipleSelect,
} from '@/components';
import { useProviders, useRoleItems } from '@/hooks/dropdowns';
import { date } from '@/utils';

const SeekerJobsSearchFields = () => {
  const listProviders = useProviders();
  const { data: listRoles } = useRoleItems();

  return (
    <>
      <FormItem className='seeker-field' label='Search' name='searchTerm'>
        <AutoComplete placeholder='Name or email address' />
      </FormItem>
      <FormItem className='seeker-field' label='Roles' name='role'>
        <MultipleSelect
          options={listRoles}
          placeholder='Select role'
          name='role'
        />
      </FormItem>
      <FormItem className='seeker-field' label='Provider' name='provider'>
        <MultipleSelect
          placeholder='Provider'
          options={listProviders}
          name='provider'
        />
      </FormItem>
      <div className='seeker-field'>
        <div className='seeker-text-label'>Job Date</div>
        <div className='seeker-date'>
          <FormItem
            className='seeker-date-item'
            name='registrationDateFrom'
            normalize={date.convertDateTime}
          >
            <DatePicker name='registrationDateFrom' placeholder='Select Date' />
          </FormItem>
          <FormItem name='registrationDateTo' normalize={date.convertDateTime}>
            <DatePicker name='registrationDateTo' placeholder='Select Date' />
          </FormItem>
        </div>
      </div>
    </>
  );
};

export default SeekerJobsSearchFields;
