import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ListView } from '@/components';

import './styles.scss';

const columns = [
  {
    dataIndex: 'employerProfilePic',
    key: 'employerProfilePic',
    width: '10%',
    render: (dt) => <Avatar size={35} icon={<UserOutlined />} src={dt} />,
  },
  {
    dataIndex: 'employerName',
    key: 'employerName',
    width: '75%',
    render: (dt, record) => (
      <Link to={`/edit-provider/${record.employerId}`}>
        <span className='provider-name'>{dt}</span>
      </Link>
    ),
  },
];

const ListProvider = ({ title, list }) => (
  <div className='trust-block'>
    <div className='trust-block__header'>{title}</div>
    <ListView
      className='trust-block__list'
      headerColumns={columns}
      infoDetail={list}
      showHeader={false}
      scroll={{ y: 270 }}
    />
  </div>
);

ListProvider.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
};

export default ListProvider;
