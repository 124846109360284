import request from '@/request';

const update = async (
  /** @type {string| number} */ id,
  /** @type {any} */ supplier,
) => {
  const result = await request.patch(`/trackers/expense/suppliers/${id}`, {
    supplier,
  });
  return result;
};

export default update;
