import React, { useEffect, useRef, useState } from 'react';

import { Pagination, Form, Tooltip, message, Typography, Spin } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import {
  Button,
  FormItem,
  AutoComplete,
  ListView,
  GeneralTemplate,
  MultipleSelect,
  CSV,
  Select,
} from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import { DEBOUNCE_WAIT, PAGE_SIZE_OPTIONS, RADIUSES } from '@/constants';
import { useFetch, useSessionStorage } from '@/hooks';
import {
  useIndustries,
  useLocations,
  useManagersOfProvider,
  useProviders,
  useRoleItems,
} from '@/hooks/dropdowns';
import { ProviderService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import { PROVIDER_STATUSES } from '../constants';
import SelectDate from './components/SelectDate';

import './styles.scss';

const headerColumns = generateHeaderTable([
  {
    title: 'Number',
    width: '7%',
  },
  {
    title: 'Client Name',
    width: '7%',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ clientName) => (
      <Tooltip placement='topLeft' title={clientName}>
        {clientName}
      </Tooltip>
    ),
  },
  {
    title: 'Contact Name',
    width: '7%',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ contactName) => (
      <Tooltip placement='topLeft' title={contactName}>
        {contactName}
      </Tooltip>
    ),
  },
  {
    title: 'Email Address',
    width: '13%',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ email) => (
      <Tooltip placement='topLeft' title={email}>
        <Typography.Text ellipsis>{email}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Phone Number',
    width: '10%',
  },
  {
    title: 'City',
    width: '7%',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ city) => (
      <Tooltip placement='topLeft' title={city}>
        {city}
      </Tooltip>
    ),
  },
  {
    title: 'Industry',
    width: '15%',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ industry) => (
      <Tooltip placement='topLeft' title={industry}>
        {industry}
      </Tooltip>
    ),
  },
  {
    title: 'Allocated Account Manager',
    width: '11%',
  },
  {
    title: 'Application Date',
    width: '8%',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment().diff(a.date) - moment().diff(b.date),
  },
  {
    title: 'First Posted Shift',
  },
  {
    title: 'Last Posted Shift',
  },
  {
    title: 'Status',
    render: (_, record) => (
      <Tooltip title={record.status}>{record.status}</Tooltip>
    ),
  },
]);

const ViewProviders = () => {
  const refCSVLink = useRef();
  const history = useHistory();
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationPageSize, setPaginationPageSize] = useState(25);
  const [isDownload, setIsDownload] = useState(false);

  const { listIndustries } = useIndustries();
  const listProviders = useProviders();
  const { listLocations } = useLocations();
  const { data: managers } = useManagersOfProvider();
  const { data: listRoles } = useRoleItems();

  const [storedValue, setSession, removeSession] =
    useSessionStorage('providerSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    isFetching: isFetchingDownloadCsv,
    data: dataProviderExportCSV = [],
    refetch: refetchProviderExportCSV,
  } = useFetch(
    ['provider-export-csv'],
    () =>
      ProviderService.downloadCsv({
        sortOrder,
        ...form.getFieldsValue(),
      }),
    {
      enabled: false,
      onSuccess: () => {
        setIsDownload(true);
      },
      onError: () => {
        // eslint-disable-next-line quotes
        message.error("Can't download CSV");
      },
    },
  );

  const { data: listNameAndEmail = [] } = useFetch(
    ['getListNameAndEmailProviders', searchTerm],
    () => ProviderService.getListEmailAndName(searchTerm),
    {
      onSuccess: (items = []) =>
        items.map((item) => ({ label: item, value: item })),
    },
  );

  useEffect(() => {
    if (isDownload) {
      refCSVLink.current.link.click();

      setIsDownload(false);
    }
  }, [isDownload]);

  const {
    isFetching,
    data: responseData,
    refetch: refetchGetProviders,
  } = useFetch(
    ['getProviders', sortOrder, currentPage, paginationPageSize],
    () =>
      ProviderService.getAll({
        sortOrder,
        number: currentPage,
        size: paginationPageSize,
        ...form.getFieldsValue(),
      }),
    {
      initialData: {
        providers: [],
        currentPage: 1,
        totalCount: 0,
        totalPage: 1,
      },
    },
  );

  const handleClearFilter = () => {
    form.resetFields();
    removeSession();
    refetchGetProviders();
  };

  const handleClickToNavigate = (providerId) => {
    history.push(`/edit-provider/${providerId}`);
  };

  const handleListViewSort = (order) => {
    setSortOrder(Formatter.makeAbbrevSortType(order));
  };

  const handleShowSizeChange = (_current, pageSize) => {
    setPaginationPageSize(pageSize);
  };

  const handleSearchTermChange = (/** @type {string} */ value) =>
    debounce(() => setSearchTerm(value), DEBOUNCE_WAIT);

  const onFilter = () => {
    setSession(form.getFieldsValue());
    refetchGetProviders();
  };

  return (
    <GeneralTemplate
      mainItem='Provider'
      buttonContent='Create Provider'
      headerText='Provider'
      data={[{ item: 'Providers', to: '/view-all-providers' }]}
      buttonLink='/create-new-provider'
      subTemplateClassName='provider-container'
    >
      <Spin spinning={isFetchingDownloadCsv}>
        <div className='provider-search-wrapper'>
          <Form
            form={form}
            onFinish={onFilter}
            className='provider-search-form'
          >
            <div className='row'>
              <FormItem name='searchTerm' label='Search' className='form-item'>
                <AutoComplete
                  defaultActiveFirstOption
                  onSearch={(value) => handleSearchTermChange(value)}
                  options={listNameAndEmail}
                  placeholder='Name or email address'
                />
              </FormItem>

              <FormItem
                name='providerName'
                label='Provider'
                className='form-item'
              >
                <MultipleSelect
                  name='providerName'
                  defaultActiveFirstOption
                  options={listProviders}
                  placeholder='Provider'
                />
              </FormItem>

              <FormItem name='locations' label='Location' className='form-item'>
                <MultipleSelect
                  name='locations'
                  options={listLocations}
                  placeholder='Location'
                  showSearch={false}
                />
              </FormItem>

              <SelectDate
                startDateFieldName='shiftsPostedDateFrom'
                endDateFieldName='shiftsPostedDateTo'
                label='Shifts Posted'
              />

              <FormItem name='jobRoles' label='Job Roles' className='form-item'>
                <MultipleSelect
                  name='jobRoles'
                  options={listRoles}
                  placeholder='Job Roles'
                />
              </FormItem>

              <FormItem
                name='industries'
                label='Industry'
                className='form-item'
              >
                <MultipleSelect
                  name='industries'
                  options={listIndustries}
                  placeholder='Industry'
                />
              </FormItem>

              <SelectDate
                startDateFieldName='registrationDateFrom'
                endDateFieldName='registrationDateTo'
                label='Registration Date'
              />

              <FormItem
                name='generalRole'
                label='Allocated Account Manager'
                className='form-item'
              >
                <MultipleSelect
                  name='generalRole'
                  options={managers}
                  placeholder='Allocated Account Manager'
                />
              </FormItem>

              <FormItem name='statuses' label='Status' className='form-item'>
                <MultipleSelect
                  name='statuses'
                  options={PROVIDER_STATUSES}
                  placeholder='Status'
                />
              </FormItem>

              <FormItem label='Post Code' name='zip' className='form-item'>
                <PostCodeUK />
              </FormItem>

              <FormItem name='radius' label='Radius' className='form-item'>
                <Select name='radius' options={RADIUSES} placeholder='Radius' />
              </FormItem>

              <div className='button-search'>
                <FormItem>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='yellow-button input-height btn-style'
                  >
                    Search
                  </Button>
                </FormItem>

                <FormItem>
                  <Button
                    onClick={handleClearFilter}
                    htmlType='button'
                    className='filter-button input-height btn-style'
                  >
                    Clear Filters
                  </Button>
                </FormItem>
              </div>
            </div>
          </Form>

          <ListView
            scroll={{ y: 650 }}
            loading={isFetching}
            options={PROVIDER_STATUSES}
            headerColumns={headerColumns}
            infoDetail={responseData?.providers || []}
            locale={{
              emptyText: 'No Providers match your search criteria',
            }}
            handleSort={handleListViewSort}
            onRow={handleClickToNavigate}
          />

          <div className='table-footer-container'>
            <div className='pagination-container'>
              <Pagination
                responsive
                showSizeChanger
                current={currentPage}
                pageSize={paginationPageSize}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                total={responseData?.totalCount || 0}
                onChange={setCurrentPage}
                onShowSizeChange={handleShowSizeChange}
              />
            </div>
            <Button
              className='yellow-button download-csv-btn'
              onClick={refetchProviderExportCSV}
              disabled={isFetchingDownloadCsv}
            >
              {isFetchingDownloadCsv ? 'Loading csv...' : 'Download CSV'}
            </Button>
            <CSV
              ref={refCSVLink}
              filename='data-provider.csv'
              data={dataProviderExportCSV}
            />
          </div>
        </div>
      </Spin>
    </GeneralTemplate>
  );
};
export default ViewProviders;
