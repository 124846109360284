import { isEmpty } from 'lodash';

import { ORDER_BY } from '@/constants';
import request from '@/request';
import { array, date } from '@/utils';

import { getAddressInfomation } from '../getAddressInfomation';

const getUndefinedOrValue = (data) => (isEmpty(data) ? undefined : data);

const downloadCsv = async ({
  ordering,
  radius,
  status,
  managerId,
  searchTerm,
  localOffice,
  registrationDateTo,
  registrationDateFrom,
  zip,
}) => {
  const order = {
    ordering,
    orderBy: ORDER_BY,
  };

  const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

  const filter = {
    status: array.remove(status, 'all'),
    managerId: array.remove(managerId, 'all'),
    localOffice: array.remove(localOffice, 'all'),
    searchTerm: getUndefinedOrValue(searchTerm),
    registrationDateTo: date.convertDateTime(registrationDateTo),
    registrationDateFrom: date.convertDateTime(registrationDateFrom),
    latitude: geogecode?.[0]?.geometry?.location?.lat,
    longitude: geogecode?.[0]?.geometry?.location?.lng,
    postCode: geogecode?.[0]?.address_components?.[0]?.long_name,
    radius,
  };

  return (
    await request.get('/providers/leads/csv', {
      params: {
        order,
        filter,
      },
    })
  ).data;
};

export default downloadCsv;
