import request from '@/request';
import { getAddressInfomation } from '@/services/getAddressInfomation';
import { checkAllFieldsTruthy } from '@/utils/array';

const createProvider = async (provider) => {
  const {
    baBuilding,
    baStreet,
    baCity,
    baPostcode,
    firstName,
    lastName,
    email,
    jobTitle,
    department,
    phone,
    contactAccount,
    contactBooking,
    contactInvoice,
    contactTimesheet,
    employerLocationsAttributes,
  } = provider;

  const name = `${firstName} ${lastName}`;

  const {
    name: empName,
    building: empBuilding,
    street: empStreet,
    city: empCity,
    postcode: empPostcode,
  } = employerLocationsAttributes;

  const defaultEmployerLocation = [
    {
      name: empName || `${baStreet} ${baBuilding}`,
      building: empBuilding || baBuilding,
      street: empStreet || baStreet,
      city: empCity || baCity,
      postcode: empPostcode || baPostcode,
      country: empCity || '',
    },
  ];

  const contactsAttributes = [
    {
      name,
      phone,
      email,
      jobTitle,
      department,
      type: 'user',
    },
  ];

  if (checkAllFieldsTruthy(contactBooking)) {
    contactsAttributes.push({
      ...contactBooking[0],
      name: `${contactBooking[0].firstName} ${contactBooking[0].lastName}`,
      type: 'booking',
    });
  }
  if (checkAllFieldsTruthy(contactAccount)) {
    contactsAttributes.push({
      ...contactAccount[0],
      name: `${contactAccount[0].firstName} ${contactAccount[0].lastName}`,
      type: 'account',
    });
  }

  if (checkAllFieldsTruthy(contactInvoice)) {
    contactsAttributes.push({
      ...contactInvoice[0],
      name: `${contactInvoice[0].firstName} ${contactInvoice[0].lastName}`,
      type: 'invoice',
    });
  }

  if (checkAllFieldsTruthy(contactTimesheet)) {
    contactsAttributes.push({
      ...contactTimesheet[0],
      name: `${contactTimesheet[0].firstName} ${contactTimesheet[0].lastName}`,
      type: 'timesheet',
    });
  }

  provider.contactsAttributes = contactsAttributes;

  const geogecode = await getAddressInfomation(
    `${defaultEmployerLocation[0].postcode}-uk`,
  );
  const { location } = geogecode[0].geometry;
  const { lat, lng } = location;
  defaultEmployerLocation[0] = {
    ...defaultEmployerLocation[0],
    longitude: lng,
    latitude: lat,
    emailInvoice: '',
    territoryId: 1,
  };

  provider.employerLocationsAttributes = defaultEmployerLocation;

  await request.post('/guests/providers', {
    provider: {
      ...provider,
      isAcceptedReceivingUpdates: 1,
      name: '',
      paymentStartWeek: 1,
    },
  });
};

export default createProvider;
