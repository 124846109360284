import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, DatePicker, FormItem, Select } from '@/components';
import { useProviderLocations, useProviders } from '@/hooks/dropdowns';
import { date } from '@/utils';

import './styles.scss';

const SICK_NOSHOW_OPTIONS = [
  { label: 'Sick', value: 'sick' },
  {
    label: 'No Show',
    value: 'hide',
  },
];

const Options = ({ onClear, isLoading }) => {
  const formInstance = Form.useFormInstance();

  const providerOptions = useProviders();
  const providerId = Form.useWatch(['options', 'employerId'], formInstance);
  const { data: locationOptions } = useProviderLocations(providerId);

  return (
    <div className='attendance-options'>
      <FormItem name={['options', 'status']} label='Status'>
        <Select
          placeholder='No-Show or Sickness'
          options={SICK_NOSHOW_OPTIONS}
        />
      </FormItem>
      <FormItem name={['options', 'employerId']} label='Provider'>
        <Select placeholder='Provider' options={providerOptions} />
      </FormItem>
      <FormItem name={['options', 'employerLocationId']} label='Location'>
        <Select placeholder='Location' options={locationOptions} />
      </FormItem>
      <FormItem
        name={['options', 'dateStart']}
        label='Date Range'
        normalize={date.convertDateTime}
      >
        <DatePicker />
      </FormItem>
      <FormItem name={['options', 'dateEnd']} normalize={date.convertDateTime}>
        <DatePicker />
      </FormItem>
      <Button className='yellow-button' htmlType='submit' disabled={isLoading}>
        Search
      </Button>
      <Button className='filter-button' onClick={onClear}>
        Clear filters
      </Button>
    </div>
  );
};

export default Options;

Options.propTypes = {
  onClear: PropTypes.func,
  isLoading: PropTypes.bool,
};

Options.defaultProps = {
  onClear: () => {},
  isLoading: false,
};
