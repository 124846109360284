import { get } from 'lodash';
import moment from 'moment';

import { time } from '@/utils';

/**
 * @typedef {Object} Tparams
 * @property {number} value
 * @property {import('antd').FormInstance} form
 * @property {string[]} namePath
 */

/**
 *
 * @param {Tparams} param
 */
const handleBreakChange = ({ value = 0, form, namePath }) => {
  const hourCellFormData = form.getFieldValue(namePath);
  const checkin = moment(get(hourCellFormData, ['schedule', 'checkin']));
  const checkout = moment(get(hourCellFormData, ['schedule', 'checkout']));
  const durationInMinutes = checkout.diff(checkin, 'minutes');
  const calValue = durationInMinutes - value;
  const convertValueToHours = time.convertMinstoHours(calValue);

  form.setFields([
    {
      name: [...namePath, 'schedule', 'value'],
      value: convertValueToHours,
    },
  ]);
  return value;
};

const getCheckinCheckout = ({ checkType, value, hourCellFormData }) => {
  const checkin =
    checkType === 'checkin'
      ? moment(value)
      : moment(get(hourCellFormData, ['schedule', 'checkin']));
  const checkout =
    checkType === 'checkin'
      ? moment(get(hourCellFormData, ['schedule', 'checkout']))
      : moment(value);
  return { checkin, checkout };
};

const handleCheckOvernight = ({ value, checkType, hourCellFormData }) => {
  const { checkin, checkout } = getCheckinCheckout({
    checkType,
    value,
    hourCellFormData,
  });
  if (checkin.isSameOrAfter(checkout)) {
    checkout.add(1, 'day');
  } else if (checkin.isBefore(checkout.clone().subtract(1, 'day'))) {
    checkout.subtract(1, 'day');
  }
  return { checkin, checkout };
};

/**
 *
 * @param {Tparams} param0
 */
const handleCheckinCheckoutChange = ({ value, form, namePath, checkType }) => {
  const hourCellFormData = form.getFieldValue(namePath);

  const { checkin, checkout } = handleCheckOvernight({
    hourCellFormData,
    value,
    checkType,
  });

  const durationInMinutes = Math.abs(checkout.diff(checkin, 'minutes'));
  const breakValue = get(hourCellFormData, 'break', 0);
  const convertValueToHours = time.convertMinstoHours(
    durationInMinutes - breakValue,
  );

  const checkTypeValue = {
    checkin,
    checkout,
  };

  const checkTypeLeft = checkType === 'checkin' ? 'checkout' : 'checkin';

  form.setFields([
    {
      name: [...namePath, 'schedule', 'value'],
      value: convertValueToHours,
    },
    {
      name: [...namePath, 'schedule', checkTypeLeft],
      value: checkTypeValue[checkTypeLeft],
    },
  ]);
  return checkTypeValue[checkType];
};

export { handleBreakChange, handleCheckinCheckoutChange };
