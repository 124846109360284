import React, { useEffect, useState } from 'react';

import { Divider, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DatePicker, FormItem } from '@/components';
import { useVacancyApplicantEmploymentPosition } from '@/hooks/dropdowns';
import { date as dateUtils } from '@/utils';

const ExperienceDetailShort = ({
  fieldName,
  index,
  isStillEmployed,
  listEmployment,
}) => {
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();

  const { name, positionId } = listEmployment[index] ?? {};

  const listRoles = useVacancyApplicantEmploymentPosition();
  const role = listRoles.find((r) => r.value === positionId);

  const formInstance = Form.useFormInstance();

  const disabledStartDate = (disabledDates) =>
    disabledDates > moment().subtract(1, 'day');
  const disabledEndDate = (disabledDates) => {
    if (!startDate) return disabledDates > moment().subtract(1, 'day');

    return (
      disabledDates < startDate || disabledDates > moment().subtract(1, 'day')
    );
  };

  useEffect(() => {
    if (isStillEmployed) {
      formInstance.setFields([
        {
          name: ['vacancyApplicantEmploymentsAttributes', fieldName, 'startAt'],
          errors: [],
        },
        {
          name: [
            'vacancyApplicantEmploymentsAttributes',
            fieldName,
            'finishAt',
          ],
          errors: [],
        },
      ]);
    }
  }, [isStillEmployed, fieldName, index, formInstance]);

  return (
    <div className='seeker-experience-detail-short'>
      <div className='history'>
        <div className='role-and-company'>
          {role?.label} at {name} from
        </div>

        <div className='start-and-end-date'>
          <FormItem
            className='start-date'
            name={[fieldName, 'startAt']}
            normalize={dateUtils.convertDateTime}
            noStyle
          >
            <DatePicker
              value={startDate}
              disabled={isStillEmployed}
              name={[
                'vacancyApplicantEmploymentsAttributes',
                fieldName,
                'startAt',
              ]}
              disabledDate={disabledStartDate}
              onChange={(date) => setStartDate(date)}
              handleChangeYear={(fullDateTime) =>
                formInstance.setFields([
                  {
                    name: [
                      'vacancyApplicantEmploymentsAttributes',
                      fieldName,
                      'startAt',
                    ],
                    value: fullDateTime,
                  },
                ])
              }
            />
          </FormItem>
          <span className='date-to'>to</span>
          <FormItem
            className='end-date'
            name={[fieldName, 'finishAt']}
            normalize={dateUtils.convertDateTime}
            noStyle
          >
            <DatePicker
              value={endDate}
              disabled={isStillEmployed}
              name={[
                'vacancyApplicantEmploymentsAttributes',
                fieldName,
                'finishAt',
              ]}
              disabledDate={disabledEndDate}
              onChange={(date) => setEndDate(date)}
              handleChangeYear={(fullDateTime) =>
                formInstance.setFields([
                  {
                    name: [
                      'vacancyApplicantEmploymentsAttributes',
                      fieldName,
                      'finishAt',
                    ],
                    value: fullDateTime,
                  },
                ])
              }
            />
          </FormItem>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default ExperienceDetailShort;

ExperienceDetailShort.propTypes = {
  listEmployment: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fieldName: PropTypes.number,
  index: PropTypes.number,
  isStillEmployed: PropTypes.bool,
};

ExperienceDetailShort.defaultProps = {
  isStillEmployed: false,
};
