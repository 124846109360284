import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const TotalCard = ({ title, amount, unit, isRed, isStart }) => (
  <div
    className={classNames('total-card', {
      red: isRed,
    })}
  >
    <div className='title'>{title}</div>
    <div className='content'>
      {isStart ? <span className='unit'>{unit}</span> : null}
      <span className='amount'>{amount}</span>
      {!isStart ? <span className='unit'>{unit}</span> : null}
    </div>
  </div>
);

TotalCard.propTypes = {
  isRed: PropTypes.bool,
  unit: PropTypes.string,
  title: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStart: PropTypes.bool,
};

TotalCard.defaultProps = {
  amount: 0,
  unit: null,
  title: null,
  isRed: false,
  isStart: false,
};
export default TotalCard;
