import React from 'react';

import { Form } from 'antd';

import { CheckboxGroup, FormItem, Input } from '@/components';
import { useIndustryExperiences } from '@/hooks/dropdowns';

import { INDUSTRY } from './constants';

const IndustryExperience = () => {
  const formInstance = Form.useFormInstance();
  const listIndustryExperiences = useIndustryExperiences();

  const industryExperience = Form.useWatch(
    [
      'vacancyApplicantAttributes',
      'industryExperiencesVacancyApplicantsAttributes',
    ],
    formInstance,
  );

  const isIncludesRoleOther = Object.values(industryExperience ?? {}).includes(
    INDUSTRY.OTHER,
  );

  return (
    <div className='roles-section'>
      <FormItem
        label='Your Industry Experience'
        name={[
          'vacancyApplicantAttributes',
          'industryExperiencesVacancyApplicantsAttributes',
        ]}
      >
        <CheckboxGroup
          className='check-roles'
          options={listIndustryExperiences}
        />
      </FormItem>

      <FormItem
        name={['vacancyApplicantAttributes', 'industryExperienceAttributesOld']}
        noStyle
      />

      {isIncludesRoleOther && (
        <FormItem
          name={['vacancyApplicantAttributes', 'industryExperienceValueInput']}
          className='text-role-other'
        >
          <Input />
        </FormItem>
      )}
    </div>
  );
};

export default IndustryExperience;
