import { isArray, isEmpty, toArray } from 'lodash';

import { INDUSTRY } from '@/constants';
import { checkAllFieldsTruthy } from '@/utils/array';
import { evaluationNumberToText } from '@/utils/evaluation';
import { parseCertificate } from '../../../../utils/seeker';

const boxTypes = {
  eLearning: 'learning',
  license: 'license',
  documentation: 'documentation',
};

const DEFAULT_SHARE_CODE = '000000000';

const INIT_STUDENT_HOLIDAY = [
  {
    name: 0,
    key: 0,
    isListField: true,
    fieldKey: 0,
  },
];

const convertData = (data, isUkOrIreland) => {
  const {
    vacancyApplicantReferences,
    vacancyApplicantReferenceAttributes,
    vacancyApplicantEmployments,
    vacancyApplicantLanguages,
    nationality,
    limitVisa,
    restriction,
    omniEmployeeAttributes,
    employeeAttributes,
    shareCode = DEFAULT_SHARE_CODE,
    ...restData
  } = data ?? {};

  const {
    employeeWpExpiry,
    phone,
    employeeStudent,
    contractFile,
    visaBrpFile,
    p45Document,
    passportId1File,
    universityTermDateLetterRtw,
    visaRtw,
  } = omniEmployeeAttributes ?? {};

  if (!isEmpty(phone) && !phone.startsWith('0')) {
    data.omniEmployeeAttributes.phone = `0${phone}`;
  }

  const { worktime, profilepic } = employeeAttributes ?? {};

  const hasConvict = !!data?.convict;

  const isStudentLimitedVisa =
    employeeStudent === 'student' || restriction ? 1 : 0;

  const evaluation = evaluationNumberToText(data?.evaluation);
  const vacancyApplicantAttributes = {};
  vacancyApplicantAttributes.nationality = nationality;

  const industryExperienceAttributesOld =
    data?.industryExperiencesVacancyApplicantsAttributes;

  let industryExperienceAttributes;
  let industryExperienceValueInput;

  if (isArray(industryExperienceAttributesOld)) {
    industryExperienceAttributes =
      data?.industryExperiencesVacancyApplicantsAttributes?.map((industry) => {
        if (industry?.industryExperienceId === INDUSTRY.OTHER) {
          industryExperienceValueInput = industry?.otherValue;
        }
        return industry?.industryExperienceId;
      });
  } else {
    industryExperienceAttributes = [
      data?.industryExperiencesVacancyApplicantsAttributes
        ?.industryExperienceId,
    ];
    industryExperienceValueInput =
      data?.industryExperiencesVacancyApplicantsAttributes?.otherValue;
  }

  const studentHolidaysAttributes = !isEmpty(data?.studentHolidays)
    ? data.studentHolidays
    : INIT_STUDENT_HOLIDAY;

  // Files
  const friendName = data?.friendName;
  const cvUpload = data?.cvUpload;
  const universityLetter = data?.universityLetter;
  const studentLetter = data?.studentLetter;
  const proofAddress = data?.proofAddress;
  const proofNi = data?.proofNi;
  const niProof = data?.niProof;
  const secondaryId = data?.secondaryId;
  const dbsCheck = data?.dbsCheck;
  const birthCertificate = data?.birthCertificate;

  const shareCodeFile = data?.shareCodeFile;
  const employeeCertificates = data.employeeAttributes.employeeCertificates;

  const blankBoxesAttributes = toArray(data?.blankBoxes);

  // End Files

  const blankBoxDocumentation = blankBoxesAttributes.filter(
    (item) => item.boxType === boxTypes.documentation,
  );

  // End Files
  const documentationDocs = [
    {
      fileBlob: studentLetter,
      fileValue: studentLetter,
      typeValue: 'studentLetter',
      typeLabel: 'Student letter',
    },
    {
      fileBlob: visaBrpFile,
      fileValue: visaBrpFile,
      typeValue: 'visaBrpFile',
      typeLabel: 'Visa Application Letter',
    },
    {
      fileBlob: p45Document,
      fileValue: p45Document,
      typeValue: 'p45Document',
      typeLabel: 'Past P45',
    },
    {
      fileBlob: proofAddress,
      fileValue: proofAddress,
      typeValue: 'proofAddress',
      typeLabel: 'Proof of Address',
    },
    {
      fileBlob: proofNi,
      fileValue: proofNi,
      typeValue: 'proofNi',
      typeLabel: 'Proof of NI',
    },
    {
      fileBlob: secondaryId,
      fileValue: secondaryId,
      typeValue: 'secondaryId',
      typeLabel: 'Secondary ID',
    },
    {
      fileBlob: dbsCheck,
      fileValue: dbsCheck,
      typeValue: 'dbsCheck',
      typeLabel: 'DBS',
    },
    ...blankBoxDocumentation.map((item) => ({
      fileBlob: item.link,
      fileValue: item.link,
      typeValue: `blankBox-${item.name}`,
      typeLabel: isEmpty(item.name) ? 'Blank Box' : item.name,
      id: item.id,
    })),
  ].filter((item) => item.fileBlob);

  const rightToWorkDocs = isUkOrIreland
    ? [
        {
          fileBlob: passportId1File,
          fileValue: passportId1File,
          typeValue: 'passportId1File',
          typeLabel: 'Passport',
        },
        {
          fileBlob: birthCertificate,
          fileValue: birthCertificate,
          typeValue: 'birthCertificate',
          typeLabel: 'Birth Certificate',
        },
        {
          fileBlob: niProof,
          fileValue: niProof,
          typeValue: 'niProof',
          typeLabel: 'NI Proof',
        },
      ]
    : [
        {
          fileBlob: passportId1File,
          fileValue: passportId1File,
          typeValue: 'passportId1File',
          typeLabel: 'Passport',
        },
        {
          fileBlob: visaRtw,
          fileValue: visaRtw,
          typeValue: 'visaRtw',
          typeLabel: 'Visa',
        },
        {
          fileBlob: universityTermDateLetterRtw,
          fileValue: universityTermDateLetterRtw,
          typeValue: 'universityTermDateLetterRtw',
          typeLabel: 'University Term Date Letter',
        },
      ].filter((item) => item.fileBlob);

  const [eLearnings, licences] = parseCertificate(employeeCertificates);

  return {
    ...restData,
    nationality,
    friendName,
    hasConvict,
    worktime,
    evaluation,
    industryExperienceAttributes,
    limitVisa: isStudentLimitedVisa,
    restriction,
    industryExperiences: industryExperienceAttributes,
    industryExperienceAttributesOld,
    vacancyApplicantLanguagesAttributes: vacancyApplicantLanguages ?? [],
    vacancyApplicantEmploymentsAttributes: vacancyApplicantEmployments ?? [],
    industryExperienceValueInput,
    vacancyApplicantReferencesAttributes: vacancyApplicantReferences ?? [],
    documentationDocs: [
      {
        fileBlob: contractFile,
        fileValue: contractFile,
        typeValue: 'contractFile',
        typeLabel: 'Contract',
      },
      {
        fileBlob: profilepic,
        fileValue: profilepic,
        typeValue: 'profilepic',
        typeLabel: 'Profile Picture',
      },
      {
        fileBlob: cvUpload,
        fileValue: cvUpload,
        typeValue: 'cvUpload',
        typeLabel: 'CV',
      },
      ...documentationDocs,
    ],
    eLearnings,
    licences,
    rightToWorkDocs,
    vacancyApplicantAttributes,
    studentHolidaysAttributes,
    // Files for Section RTW (Right To Work)
    universityLetterFileBlob: universityLetter,
    universityLetterFileValue: universityLetter,
    passportId1FileBlob: passportId1File,
    passportId1FileValue: passportId1File,
    shareCodeFileBlob: shareCodeFile,
    shareCodeFileValue: shareCodeFile,
    employeeWpExpiry,
    initCandidateStatus: restData.candidateStatus,
    isFirstJobNoReference: !checkAllFieldsTruthy(
      vacancyApplicantReferences ?? [],
    ),
    omniEmployeeAttributes,
    employeeAttributes,
    shareCode,
  };
};
export default convertData;
