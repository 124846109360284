import { camelizeKeys } from 'humps';

import request from '@/request';

const getCountPayroll = async (filter) => {
  const endpoint = 'payments/payrolls/payment_type_size';
  const { data } = await request.get(endpoint, {
    params: {
      filter,
    },
  });

  return camelizeKeys(data?.data);
};

export default getCountPayroll;
