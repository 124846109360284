import React, { useEffect } from 'react';

import { Checkbox, Form, Pagination, Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ListView from '@/components/ListView';
import {
  ORDER_BY_PROVIDER,
  ORDER_DIRECTION_ASC,
  PAGE_SIZE_OPTIONS,
  PAYMENT_TYPE,
} from '@/constants';
import { useFetch, usePagination, useSessionStorage } from '@/hooks';
import { getPayrollList } from '@/services/payrollService';
import { time } from '@/utils';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import { InputFields } from '../components';
import TooltipStatus from '../components/TooltipStatus';
import { mappingStatuses } from '../components/TooltipStatus/constants';

import './styles.scss';

const PayrollListView = ({ form }) => {
  const history = useHistory();

  const {
    currentPage,
    handlePagination,
    paginationPageSize,
    handleShowSizeChange,
  } = usePagination();

  const weekNumWatching = Form.useWatch('weekNum', form);
  const isPayTemplate = Form.useWatch('isPayTemplate', form);

  const [storedValue, setSession, removeSession] = useSessionStorage(
    'payrollListViewSearch',
  );

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: payroll,
    isFetching,
    refetch,
  } = useFetch(
    [
      'payroll',
      currentPage,
      paginationPageSize,
      weekNumWatching,
      isPayTemplate,
    ],
    () => {
      const filter = form.getFieldsValue();
      setSession(filter);
      const endDate = filter.weekEndDate;
      const startDate = filter.weekStartDate;

      filter.weekEndDate = endDate?.format('YYYY-MM-DD');
      filter.weekStartDate = startDate?.format('YYYY-MM-DD');
      filter.paymentType = isPayTemplate
        ? PAYMENT_TYPE.payrollTemplate
        : PAYMENT_TYPE.flexiblePayRate;

      return getPayrollList({
        filter,
        page: {
          size: paginationPageSize,
          num: currentPage,
        },
        order: {
          orderBy: ORDER_BY_PROVIDER,
          orderDirection: ORDER_DIRECTION_ASC,
        },
      });
    },
  );

  const columnsTable = generateHeaderTable([
    {
      title: 'Provider',
      dataIndex: 'providerName',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => a.providerName.localeCompare(b.providerName),
      render: (/** @type {string} */ name) => (
        <Tooltip placement='topLeft' title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'providerLocation',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (/** @type {string} */ location) => (
        <Tooltip placement='topLeft' title={location}>
          {location}
        </Tooltip>
      ),
    },
    { title: 'Year', width: '5%' },
    { title: 'Payment Week', dataIndex: 'weekNum' },
    {
      title: 'Payment Week Dates',
      width: '12%',
      dataIndex: 'paymentWeekDate',
      render: (paymentWeekDate) => (
        <Tooltip placement='topLeft' title={paymentWeekDate}>
          {paymentWeekDate}
        </Tooltip>
      ),
    },
    {
      title: 'Last Edited By',
      render: (/** @type {string} */ name) => (
        <Tooltip placement='topLeft' title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Last Edited At',
      dataIndex: 'lastEditedOn',
      render: (dt) => {
        const content = (
          <div className='last-edited-at'>
            <p>{moment(dt).format('DD/MM/YYYY')}</p>
            <p>{moment(dt).format('hh:mm')}</p>
          </div>
        );

        return (
          <Tooltip placement='topLeft' title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    { title: 'Number of Seekers', dataIndex: 'countEmployees' },
    {
      title: 'Total Hours',
      dataIndex: 'timecardHoursTotal',
      render: (totalHours) => {
        const content = time.convertTimeHoursCell(totalHours ?? 0);
        return (
          <Tooltip placement='topLeft' title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    {
      title: 'Total Pay',
      dataIndex: 'payTotal',
      render: (dt) => {
        const content = Formatter.currencyGBP(dt);
        return (
          <Tooltip placement='topLeft' title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    {
      title: <TooltipStatus />,
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <div
          className={classNames(
            'color-checkbox',
            mappingStatuses[record.payrollStatus]?.color ?? 'green',
          )}
        >
          <Checkbox />
        </div>
      ),
    },
    {
      title: <div className='color-check-container'>Status Detail</div>,
      key: 'payrollStatus',
      dataIndex: 'payrollStatus',
      render: (status) => {
        const statuses = {
          paid: 'Paid',
          confirmed: 'Confirmed',
          default: 'Finalised',
        };
        const content = statuses[status] || statuses.default;
        return (
          <div className='status_detail'>
            <Tooltip placement='topLeft' title={content}>
              {content}
            </Tooltip>
          </div>
        );
      },
    },
  ]);

  const handleSearchData = () => {
    setSession(form.getFieldsValue());
    refetch();
  };

  const handleClearFilter = () => {
    form.resetFields();
    removeSession();
    refetch();
  };

  const handleNavigate = (id) => {
    const pathRoute = isPayTemplate
      ? 'pay-template-payroll-detail'
      : 'payroll-detail';
    return history.push(`/${pathRoute}/${id}`);
  };

  return (
    <>
      <Form form={form} onFinish={handleSearchData} className='inputs'>
        <InputFields handleClearFilter={handleClearFilter} />
      </Form>
      <ListView
        headerColumns={columnsTable}
        infoDetail={payroll?.data ?? []}
        loading={isFetching}
        locale={{
          emptyText: 'No Payrolls match your search criteria',
        }}
        className='payroll-listview'
        onRow={handleNavigate}
      />
      <div className='pagination-container'>
        <Pagination
          current={currentPage}
          total={payroll?.totalCount ?? 0}
          pageSize={paginationPageSize}
          onChange={handlePagination}
          responsive
          showSizeChanger
          onShowSizeChange={handleShowSizeChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
        />
      </div>
    </>
  );
};

PayrollListView.propTypes = {
  form: PropTypes.object,
};

PayrollListView.defaultProps = {
  form: null,
};
export default PayrollListView;
