import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, CheckboxGroup, FormItem, Input } from '@/components';
import { useIndustryExperiences } from '@/hooks/dropdowns';

import './styles.scss';
import { INDUSTRY } from './constants';

const Role = ({ nextStep, onValuesChange, initialValues, form }) => {
  const listIndustryExperiences = useIndustryExperiences();

  const industryExperiences = Form.useWatch(
    'industryExperiencesVacancyApplicantsAttributes',
    form,
  );
  const isIncludesRoleOther = Object.values(industryExperiences ?? {}).includes(
    INDUSTRY.OTHER,
  );

  return (
    <div className='flex-one-column'>
      <div className='title'>Your Industry Experience</div>
      <div>
        Please select the industries / sectors that you have previously worked
        in or have experience in. This is so we can also match you with other
        jobs that you may be suitable for in the future.
      </div>

      <Form
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        form={form}
        className='experience-check'
      >
        <div className='roles-container'>
          <FormItem
            name='industryExperiencesVacancyApplicantsAttributes'
            label='Please select all that are relevant.'
            rules={[
              {
                required: true,
                message: 'Please select all that are relevant.',
              },
            ]}
          >
            <CheckboxGroup
              className='check-roles'
              options={listIndustryExperiences}
            />
          </FormItem>

          {isIncludesRoleOther && (
            <FormItem
              name='industryExperienceOther'
              className='text-role-other'
            >
              <Input />
            </FormItem>
          )}
        </div>

        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

Role.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default Role;
