import { useQuery } from 'react-query';

import { useRoleItems } from '@/hooks/dropdowns';
import { fetchIndustries } from '@/services/dropdownServices/getIndustries';
import { fetchLocations } from '@/services/dropdownServices/getLocations';
import { fetchProviders } from '@/services/dropdownServices/getProviders';

export const useFetchJobsOptions = () => {
  const { data: roles, isFetched: isRolesFetched } = useRoleItems();
  const { data: providers, isFetched: isProvidersFetched } = useQuery(
    ['providers'],
    () => fetchProviders(false),
    { refetchOnWindowFocus: false },
  );

  const { data: locations, isFetched: isLocationsFetched } = useQuery(
    ['locations'],
    fetchLocations,
    { refetchOnWindowFocus: false },
  );

  const { data: industries, isFetched: isIndustriesFetched } = useQuery(
    ['industries'],
    fetchIndustries,
    { refetchOnWindowFocus: false },
  );

  const allFetched =
    isRolesFetched &&
    isProvidersFetched &&
    isLocationsFetched &&
    isIndustriesFetched;

  return {
    roles,
    providers,
    locations,
    industries,
    allFetched,
  };
};
