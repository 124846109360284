import { isEmpty } from 'lodash';
import moment from 'moment';

import { RESOURCE_TYPE } from '@/constants';
import request from '@/request';
import Formatter from '@/utils/Formatter';
import { formatResponse } from '@/utils/thunkUtil';

export const getApplicationPage = async (params) => {
  const endpoint = '/recruitments/applicants';
  const resData = await request.get(endpoint, { params });
  const applicant = resData?.data?.data;

  if (isEmpty(applicant)) {
    return [];
  }

  const applicantPageData = formatResponse(resData, { endpoint });

  const { data } = Formatter.buildEndpoint(
    applicantPageData.endpoint,
    applicantPageData,
    RESOURCE_TYPE.vacancyApplicant,
  );
  const { totalCount, totalPage, currentPage } = Formatter.mergeMeta(
    applicantPageData.vacancyApplicant,
    endpoint,
    applicantPageData.meta,
  )[endpoint];
  const applicantListInfo = data.map((item) => ({
    key: item?.id,
    number: item?.id,
    fullName: `${item?.firstname} ${item?.lastname}`,
    emailAddress: item?.email,
    phoneNumber: item?.phone,
    postcode: item?.zip,
    specificRoleApplied: item?.generalRole,
    otherRoles: `${item?.otherRoles}`,
    registrationDate: item?.createdAt
      ? moment(item?.createdAt).utc().format('DD/MM/YYYY')
      : '',
    location: item?.location,
    status: item?.status,
  }));

  return {
    applicantListInfo: applicantListInfo || [],
    totalCount: totalCount ?? 0,
    totalPage: totalPage ?? 0,
    currentPage: currentPage ?? 0,
  };
};
