import React, { useEffect, useState } from 'react';

import { Form, message } from 'antd';
import { useMutation } from 'react-query';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { Button, FormItem, Input, Select } from '@/components';
import { ProviderService, UserService } from '@/services';

import './styles.scss';
import { password, repeatPassword } from '../../constants';

const GIGProviderForm = () => {
  const formInstance = Form.useFormInstance();

  const [isPasswordMatched, setIsPasswordMatched] = useState(true);

  const passwordWatching = Form.useWatch(password, formInstance) ?? '';
  const repeatPasswordWatching =
    Form.useWatch(repeatPassword, formInstance) ?? '';

  useEffect(() => {
    if (passwordWatching !== repeatPasswordWatching) {
      formInstance.setFields([
        {
          name: repeatPassword,
          errors: ['Password not match'],
        },
      ]);
      setIsPasswordMatched(false);
    } else {
      formInstance.setFields([
        {
          name: repeatPassword,
          errors: [],
        },
      ]);
      setIsPasswordMatched(true);
    }
  }, [passwordWatching, formInstance, repeatPasswordWatching]);

  const { mutate: sendLogin } = useMutation(UserService.sendLogin, {
    onError: (error) => {
      message.error(error.message);
    },
    onSuccess: () => {
      message.success('Send login sucessfully!');
    },
  });

  const { mutate: resetPassword } = useMutation(
    () =>
      // TODO: Update params for the reset password function when we have logic
      ProviderService.resetContactPassword({
        // providerId,
        // contactManualPassword,
        // contactRepeatPassword,
      }),
    {
      onSuccess: () => {
        message.success('Reset password successfully!');
      },
      onError: () => {
        message.error('Reset password unsuccessfully!');
      },
    },
  );

  return (
    <div className='gig-provider-form-container'>
      <div className='fields-left'>
        <div className='row'>
          <FormItem
            name='firstName'
            className='form-item'
            label='First Name'
            required
            rules={[
              {
                required: true,
                message: 'Please enter First Name',
              },
            ]}
          >
            <Input placeholder='First Name' maxLength={48} />
          </FormItem>
          <FormItem
            name='lastName'
            className='form-item'
            label='Last Name'
            required
            rules={[
              {
                required: true,
                message: 'Please enter First Name',
              },
            ]}
          >
            <Input maxLength={48} placeholder='Last Name' />
          </FormItem>
        </div>
        <div className='row'>
          <FormItem
            name='workEmail'
            className='form-item'
            label='Work Email'
            required
            rules={[
              {
                required: true,
                message: 'Please enter First Name',
              },
            ]}
          >
            <Input placeholder='Work Email' />
          </FormItem>
          <FormItem name='workNumber' className='form-item' label='Work number'>
            <Input placeholder='Work number' />
          </FormItem>
        </div>
        <div className='row'>
          <FormItem label='Password'>
            <Button
              className='yellow-button reset-password-button'
              onClick={resetPassword}
              disabled={!isPasswordMatched}
            >
              Reset Password
            </Button>
          </FormItem>

          <div className='manually-reset-password'>
            <div className='manually-reset-password-label'>
              Manually Reset Password
            </div>

            <div className='manually-reset-password-fields'>
              <FormItem className='form-item' name={password}>
                <Input
                  isPassword
                  autoComplete='new-password'
                  placeholder='Password'
                />
              </FormItem>
              <FormItem className='form-item' name={repeatPassword}>
                <Input
                  isPassword
                  autoComplete='new-password'
                  placeholder='Repeat Password'
                />
              </FormItem>
            </div>
          </div>
        </div>
        <div className='row'>
          <FormItem name='position' className='form-item' label='Position'>
            <Input placeholder='Position' />
          </FormItem>
          <FormItem name='department' className='form-item' label='Department'>
            <Input placeholder='Department' />
          </FormItem>
        </div>
      </div>
      <div className='fields-right'>
        <h3>GIG Provider</h3>

        <FormItem name='accessLavel' className='form-item' label='Access Level'>
          <Select />
        </FormItem>
        <FormItem name='accessName' className='form-item'>
          <Select />
        </FormItem>

        <Form.List
          name='listAccessName'
          // TODO: remove temp data.
          initialValue={[
            {
              accessNameItem: 'iForce Corby',
            },
            {
              accessNameItem: 'iForce Rugby',
            },
          ]}
        >
          {(fields, { remove }) =>
            fields.map((field, index) => (
              <div className='list-provider-names' key={field.key}>
                <FormItem
                  className='form-item'
                  name={[index, 'accessNameItem']}
                >
                  <Input />
                </FormItem>

                <Button onClick={() => remove(field.name)} type='text'>
                  <XIconBlack />
                </Button>
              </div>
            ))
          }
        </Form.List>

        <Button className='yellow-button send-login-button' onClick={sendLogin}>
          Send Login
        </Button>
      </div>
    </div>
  );
};

export default GIGProviderForm;
