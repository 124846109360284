import request from '@/request';

const editInvoices = async (invoice) => {
  const { invoiceId } = invoice;

  const endpoint = `trackers/invoicing/invoices/${invoiceId}`;

  const res = await request.put(endpoint, { invoice });
  return res?.data?.data;
};
export default editInvoices;
