import request from '@/request';

const updatePayRate = async ({
  /** @type {string|number} */ timesheetId,
  /** @type {string|number} */ payrate,
  /** @type {string[]|number[]} */ timecardIds,
  /** @type {string|number} */ chargeRate,
}) => {
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/timecards/update_rates`;

  const res = await request.post(endpoint, {
    timecardIds,
    payrate,
    chargeRate,
  });
  return res?.data;
};

export default updatePayRate;
