/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Image as AntdImage, Form, message } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import defaultAvatar from '@/assets/images/defaultAvatar.png';
import { AutoComplete, FormItem } from '@/components';
import { DEBOUNCE_WAIT } from '@/constants';
import { useDebounce, useFetch } from '@/hooks';
import { SeekerService } from '@/services';

import { MESSAGE_TIME_LIMIT, TYPE_SEEKER_ITEM, TYPE_SHIFTS } from '../constant';
import SeekerItemRow from './SeekerSelected/index';

const SeekerItem = ({
  title,
  type,
  onChange,
  listSeekerSelected,
  removeSeekersList,
  disabled,
  openModalSeekerInfo,
  blockedSeekers,
  workerPool,
}) => {
  const formInstance = Form.useFormInstance();

  const shiftType = Form.useWatch('shiftType', formInstance);

  const payrollTemplate = Form.useWatch('payrollTemplate', formInstance);

  const schedule = Form.useWatch(['schedule', 'items'], formInstance) ?? [];

  const employerLocationId = Form.useWatch('locationId', formInstance) ?? '';

  const postedGroup = Form.useWatch('posted', formInstance);

  const posted =
    shiftType !== TYPE_SHIFTS.INDIVIDUAL
      ? postedGroup
      : schedule?.[0]?.posted ?? 0;

  const [searchText, setSearchText] = useState('');

  const debouncedSearchTerm = useDebounce(searchText, DEBOUNCE_WAIT);

  const enableSearch =
    (!payrollTemplate && debouncedSearchTerm) || payrollTemplate;

  const { data = [], isFetching } = useFetch(
    ['searchSeekers', debouncedSearchTerm, payrollTemplate],
    () =>
      SeekerService.searchSeekerJobPosting({
        searchTerm: debouncedSearchTerm,
        payrollTemplate,
        employerLocationId,
      }),
    {
      enabled: !!enableSearch,
    },
  );

  const onRemoveSeeker = (item) => {
    onChange(
      listSeekerSelected.filter((e) => e.employeeId !== item.employeeId),
    );
  };

  const onSelectSeeker = (item, isBlocked) => {
    if (isBlocked)
      return message.error(
        `${item.employeeFname} ${item.employeeSname} is blocked and cannot be added to the shift`,
      );
    if (disabled) return;

    const index = listSeekerSelected.findIndex(
      (e) => e.employeeId === item.employeeId,
    );

    const seekersPayTemplates =
      formInstance.getFieldValue('seekersPayTemplates') ?? [];

    if (index === -1) {
      if (
        type === TYPE_SEEKER_ITEM.ADD &&
        listSeekerSelected.length >= posted
      ) {
        message.error(
          'Number of seeker added cannot be greater than posted',
          MESSAGE_TIME_LIMIT,
        );
        return;
      }

      if (payrollTemplate) {
        const templates = formInstance.getFieldValue('payTemplates') ?? [];
        const seekerTemplates = templates.find((template) =>
          template.employees.some((ele) => ele.id === item.employeeId),
        );

        const listShiftStartAt = schedule.map((ele) => ele.startAt);
        const minStartAt = Math.min(...listShiftStartAt);

        if (moment(minStartAt) < moment(seekerTemplates.startAt)) {
          message.error(
            'Shift date and time must be on or later than Pay Template starting date and time',
          );
          return;
        }
        seekersPayTemplates.push(seekerTemplates);
        formInstance.setFieldsValue({ seekersPayTemplates });
      }

      listSeekerSelected.push(item);
    } else {
      listSeekerSelected.splice(index, 1);
      if (payrollTemplate) {
        seekersPayTemplates.splice(index, 1);
        formInstance.setFieldsValue({ seekersPayTemplates });
      }
    }
    onChange(listSeekerSelected);
  };

  const removeSeekersListData = removeSeekersList?.map((e) => e.employeeId);

  const listSearchSeekerShow = (data ?? []).filter(
    (seeker) => !removeSeekersListData.includes(seeker.employeeId),
  );

  return (
    <div className='seeker-item'>
      <div className='title-job-row'>{title}</div>

      <div className='job-fields'>
        <FormItem>
          <AutoComplete
            placeholder='Search seeker'
            onChange={(e) => setSearchText(e)}
            loading={isFetching}
            disabled={disabled}
          />

          <div className='list-seeker-search'>
            {(searchText ? listSearchSeekerShow : workerPool).map((seeker) => {
              const isSelected = listSeekerSelected
                .map((e) => e.employeeId)
                .includes(seeker.employeeId);

              const isBlocked = blockedSeekers.some(
                (item) => item.employeeId === seeker.employeeId,
              );
              const name =
                seeker.employeeFullname ??
                `${seeker.employeeFname} ${seeker.employeeSname}`;

              return (
                <div
                  className={classNames('seeker-field', {
                    'seeker-field-selected': isSelected,
                  })}
                  onClick={() => onSelectSeeker(seeker, isBlocked)}
                >
                  <AntdImage
                    src={seeker.profilepic ?? defaultAvatar}
                    className='avatar-seeker'
                    preview={false}
                  />
                  {isBlocked && (
                    <XIconBlack
                      className='blocked-icon'
                      width={10}
                      height={10}
                    />
                  )}
                  <div className='name-seeker'>{name}</div>
                </div>
              );
            })}
          </div>
        </FormItem>
        <div className='list-seeker-selected'>
          {listSeekerSelected.map((seeker) => (
            <SeekerItemRow
              seeker={seeker}
              onClose={onRemoveSeeker}
              disabled={disabled}
              openModalSeekerInfo={openModalSeekerInfo}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SeekerItem.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  listSeekerSelected: PropTypes.array,
  removeSeekersList: PropTypes.array,
  disabled: PropTypes.bool,
  openModalSeekerInfo: PropTypes.func,
  blockedSeekers: PropTypes.array,
  workerPool: PropTypes.array,
};

SeekerItem.defaultProps = {
  title: '',
  type: '',
  listSeekerSelected: [],
  removeSeekersList: [],
  disabled: false,
  openModalSeekerInfo: () => {},
};

export default SeekerItem;
