import moment from 'moment';

import request from '@/request';

const getPreview = async (/** @type {string|number} */ invoiceId) => {
  const {
    data: { attributes },
  } = await (
    await request.get(
      `trackers/invoicing/invoices/${invoiceId}/preview_invoice`,
    )
  ).data;

  // get end and start of week.
  const momentGetDateOfWeek = moment()
    .isoWeekYear(attributes?.invoice_year ?? '')
    .isoWeek(attributes?.invoice_week ?? '');
  const endOfWeek = momentGetDateOfWeek.endOf('week').format('DD/MM/YYYY');
  const startOfWeek = momentGetDateOfWeek.startOf('week').format('DD/MM/YYYY');

  // custom data for listview.
  const listInvoiceDetails = (attributes?.invoice_details ?? []).map(
    (invoiceDetail) => ({
      serviceProvided: {
        hours: invoiceDetail?.total_value,
        money: invoiceDetail?.rate,
        text: invoiceDetail?.role,
      },
      vat: invoiceDetail?.omni_vat,
      netAmount: invoiceDetail?.net_amount,
    }),
  );

  return {
    endOfWeek,
    startOfWeek,
    listInvoiceDetails,
    totalVAT: attributes.vat,
    account: attributes.account,
    totalNet: attributes.sub_total,
    invoiceTotal: attributes.total,
    providerName: attributes.provider_name,
    providerLocation: attributes.provider_location,
    postCode: attributes.post_code,
    taxPoint: moment(attributes.invoice_date).format('DD/MM/YYYY'),
  };
};

export default getPreview;
