import React from 'react';

import { Button, Layout } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import './styles.scss';

const { Content } = Layout;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error('Something went wrong.');
  }

  render() {
    const { hasError } = this.state;
    const { children, history } = this.props;

    const backToHome = () => {
      history.push('/');
      window.location.reload();
    };

    if (hasError) {
      return (
        <Layout className='layout'>
          <Content className='content'>
            <h1>Something went wrong.</h1>
            <Button type='primary' onClick={backToHome}>
              Back to home
            </Button>
          </Content>
        </Layout>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

export default withRouter(ErrorBoundary);
