/* eslint-disable import/order */
// eslint-disable-next-line import/order

import React from 'react';

import { Checkbox, Col, Form, Row, message } from 'antd';

import {
  Button,
  FormHeader,
  FormItem,
  Input,
  Select,
  Textarea,
} from '@/components';
import FormLogo from '@/components/FormLogo';

import './styles.scss';
import GuestFormCard from '@/components/GuestFormCard';
import { GuestService } from '@/services';
import {
  rulesEmail,
  rulesPhone,
  rulesPostcode,
  rulesSelect,
  rulesTextInput,
  rulesAcceptPrivacyPolicy,
} from '@/utils/rulesInput';

import { useMutation } from 'react-query';
import { useLocations } from '@/hooks/dropdowns';
import { useHistory } from 'react-router-dom';

const messageKey = 'updatable';

const NeedStaff = () => {
  const [form] = Form.useForm();
  const postcode = Form.useWatch('postCode', form);

  const history = useHistory();
  const { listLocations } = useLocations();
  const { mutate: createNewLead, isLoading } = useMutation(
    (lead) => GuestService.createLeads(lead, postcode),
    {
      onError: (error) => {
        message.error({
          key: messageKey,
          content: error?.message ?? 'Can not create lead',
        });
      },
      onSuccess: ({ id }) => {
        message.success({
          key: messageKey,
          content: 'Create new lead successfully!',
        });
        history.push('/thank-you-for-submitting-lead-form', {
          id,
        });
      },
    },
  );

  const handleSendNeedStaff = (credentials) => createNewLead(credentials);

  return (
    <div className='need-staff-form-container'>
      <FormHeader />
      <FormLogo />
      <GuestFormCard>
        <div className='form-content-container'>
          <h2 className='title'>
            <span className='title_1'>Do you need quick and flexible, </span>
            <span className='title_2'>staffing support?</span>
          </h2>
          <h2 className='sub-title'>
            {/* eslint-disable-next-line max-len */}
            Please fill in the form and let us know a little bit about your
            company and the type of staff you are looking for and one of the
            team will be in touch
          </h2>
          <Form
            form={form}
            className='form-container'
            layout='vertical'
            onFinish={handleSendNeedStaff}
            initialValues={{
              name: '',
              phone: '',
              city: '',
              email: '',
              company: '',
              postCode: '',
              tellUs: '',
            }}
          >
            <Row className='form-input' gutter={10}>
              <Col className='gutter-row' xs={12}>
                <FormItem
                  name='name'
                  className='primary-input-container'
                  required
                  rules={rulesTextInput('name')}
                >
                  <Input className='input-item' placeholder='Full name' />
                </FormItem>
              </Col>
              <Col className='gutter-row' xs={12}>
                <FormItem
                  name='email'
                  className='primary-input-container'
                  required
                  rules={rulesEmail()}
                >
                  <Input className='input-item' placeholder='Email' />
                </FormItem>
              </Col>
              <Col className='gutter-row' xs={12}>
                <FormItem
                  name='phone'
                  className='primary-input-container'
                  required
                  rules={rulesPhone()}
                >
                  <Input className='input-item' placeholder='Phone number' />
                </FormItem>
              </Col>
              <Col className='gutter-row' xs={12}>
                <FormItem name='company' className='primary-input-container'>
                  <Input className='input-item' placeholder='Company' />
                </FormItem>
              </Col>

              <Col className='gutter-row' xs={12}>
                <FormItem
                  name='city'
                  className='primary-input-container'
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please enter City',
                    },
                  ]}
                >
                  <Input className='input-item' placeholder='City' />
                </FormItem>
              </Col>

              <Col className='gutter-row' xs={12}>
                <FormItem
                  name='postCode'
                  className='primary-input-container'
                  rules={rulesPostcode(form, 'postCode')}
                >
                  <Input className='input-item' placeholder='Site Post Code' />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              className='primary-input-container padding-horizontal location-select-container'
              required
              name='localOffice'
              rules={rulesSelect('Local Office')}
            >
              <Select
                options={listLocations}
                placeholder='Choose Your Local Office'
                className='location-select'
              />
            </FormItem>

            <FormItem
              className='primary-input-container padding-horizontal'
              name='tellUs'
              label='Tell us a little about your needs'
            >
              <Textarea
                className='reason-textarea'
                placeholder='What sort of staff do you require, how quickly and how many?'
              />
            </FormItem>
            <FormItem
              name='acceptPrivacyPolicy'
              className='checkbox-container accept-privacy-policy'
              rules={[{ validator: rulesAcceptPrivacyPolicy }]}
              valuePropName='checked'
            >
              <Checkbox className='accept_privacy_policy primary-input-container'>
                <span>I accept the </span>
                <a
                  target='_blank'
                  href='https://www.gigtogig.co.uk/privacy-policy'
                  rel='noreferrer'
                >
                  <span className='privacy-policy'>Privacy Policy</span>
                </a>
              </Checkbox>
            </FormItem>

            <Button
              disabled={isLoading}
              htmlType='submit'
              className='yellow-button primary-input-container'
            >
              Submit
            </Button>
            <div className='footer-text'>
              <span className='footer-text_1'>
                Someone will be in touch shortly
              </span>
              <span className='footer-text_2'> to discuss your needs </span>
            </div>
          </Form>
        </div>
      </GuestFormCard>
    </div>
  );
};

export default NeedStaff;
