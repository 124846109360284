import { message } from 'antd';

import request from '@/request';
import { convertObjectToArray } from '@/utils/convertObjectToArray';

export const fetchStatus = async () => {
  try {
    const responses = await Promise.all([
      request.get('/options/vacancy_statuses'),
      request.get('/options/candidate_statuses'),
    ]);
    const status = convertObjectToArray(responses[0].data.data);
    const candidateStatus = convertObjectToArray(responses[1].data.data);

    return {
      listStatuses: status,
      lisCandidateStatuses: candidateStatus,
    };
  } catch (error) {
    message.error('Something went wrong!');
    throw error;
  }
};
