import React from 'react';

import { GeneralTemplate } from '@/components';

import PaymentView from './View';

const PayTemplates = () => (
  <GeneralTemplate
    mainItem='Pay Templates'
    data={[{ item: 'Pay Templates', to: '/pay-templates' }]}
    buttonContent='Create Pay Template'
    buttonLink='/create-payroll-templates'
    headerText='Pay Templates'
    subTemplateClassName='template-dashboard-container'
  >
    <PaymentView />
  </GeneralTemplate>
);

export default PayTemplates;
