import React from 'react';

import { Form } from 'antd';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { Button, GeneralTemplate, ModalConfirm } from '@/components';
import { useFetch } from '@/hooks';
import { SupplierService } from '@/services';
import useModal from '@/utils/useModal';

import BasicInfo from '../components/BasicInfo';
import Comments from '../components/Comments';
import InvoiceDocument from '../components/InvoiceDocument';
import Roles from '../components/Roles';

import '../styles.scss';

const EditSupplier = () => {
  const { supplierId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const [displayModal, showModal, hiddenModal] = useModal();
  const [isDisplayModal, showModalCancelSave, hiddenModalCancelSave] =
    useModal();

  const breadCrumb = [
    { item: 'Timesheets', to: '/timesheets' },
    { item: 'Suppliers', to: '/suppliers' },
    { item: form.getFieldValue('name'), to: '#' }, // TODO: update later
  ];
  const {
    mutate: updateSupplier,
    isLoading,
    isSuccess,
  } = useMutation(
    async () => SupplierService.update(supplierId, form.getFieldsValue()),
    {
      onError: (err) => {
        if (err.status && err.message) {
          hiddenModal();
          window.scrollTo(0, 0);
        }
      },
    },
  );
  const { isLoading: isLoadingSupplier } = useFetch(
    ['supplier'],
    () => SupplierService.show(supplierId),
    {
      onSuccess: (data) => {
        form.setFieldsValue({
          ...data,
        });
      },
    },
  );

  const handleSubmit = () => {
    updateSupplier();
  };
  const handleGoBack = () => history.push('/suppliers');
  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadCrumb}
      headerText={!isLoadingSupplier ? breadCrumb[2].item : ''}
      buttonContent='Create Supplier'
      buttonLink='/create-supplier'
      subTemplateClassName='supplier-container'
    >
      <Form
        form={form}
        onFinish={showModal}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='form-supplier'
      >
        <div className='form-wrapper'>
          <div className='fields-left'>
            <div className='basic'>
              <BasicInfo />
            </div>
            <div className='role'>
              <Roles />
            </div>
          </div>
          <div className='fields-right'>
            <Comments />
          </div>
        </div>

        <div className='invoice-document'>
          <InvoiceDocument />
        </div>

        <div className='actions'>
          <Button
            onClick={showModalCancelSave}
            className='black-button input-height'
          >
            Cancel
          </Button>
          <Button className='yellow-button input-height' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>
      <ModalConfirm
        title='Edit Supplier?'
        titleSuccess='Supplier edited successfully'
        visible={displayModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleGoBack : handleSubmit}
        onClickNo={hiddenModal}
      >
        Are you sure you want to edit supplier?
      </ModalConfirm>
      <ModalConfirm
        title="Don't Want to Save Updates?"
        visible={isDisplayModal}
        onClickYes={handleGoBack}
        onClickNo={hiddenModalCancelSave}
      >
        Are you sure you want to leave without saving? You will lost any uploads
        and changes you have made.
      </ModalConfirm>
    </GeneralTemplate>
  );
};

export default EditSupplier;
