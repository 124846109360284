/* eslint-disable max-len */
import React from 'react';

import { Form } from 'antd';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';

import { Select, FormItem, MultipleSelect, AutoComplete } from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import { listGenders, RADIUSES, languages } from '@/constants';
import {
  useLocations,
  useProviders,
  useRoleItems,
  useSkillTypes,
} from '@/hooks/dropdowns';
import { SeekerAutoCompleteService } from '@/services';

import { listStatus } from './constants';
import DateSeekerFields from './dateSeekerFields';
import './styles.scss';

const ratings = [
  {
    label: (
      <span>
        A<sup>*</sup>
      </span>
    ),
    value: 5,
  },
  {
    label: 'A',
    value: 4,
  },
  {
    label: 'B',
    value: 3,
  },
  {
    label: 'C',
    value: 2,
  },
  {
    label: 'D',
    value: 1,
  },
];

const SeekerSearchFields = () => {
  const { listLocations } = useLocations();
  const listRoles = useSkillTypes();

  const { data: listSkills } = useRoleItems();

  const listProviders = useProviders();

  const form = Form.useFormInstance();

  const startDate = Form.useWatch('startRegistration', form);
  const endDate = Form.useWatch('endRegistration', form);

  const startWorkedDate = Form.useWatch('startLastWorkDate', form);
  const endWorkedDate = Form.useWatch('endLastWorkDate', form);

  const { data: dataSearchMail, refetch: refetchSearchMail } = useQuery(
    'getSearchcode',
    () => {
      const searchTerm = form.getFieldValue('searchTerm');

      return SeekerAutoCompleteService.getSeekerAutoComplete({
        filter: {
          search_term: searchTerm,
          not_blocked: true,
        },
      });
    },
    { refetchOnWindowFocus: false, enabled: false },
  );

  const handleSearchMail = debounce(refetchSearchMail, 500);

  return (
    <div className='seeker-search-fields'>
      <FormItem label='Search' name='searchTerm'>
        <AutoComplete
          placeholder='Search'
          onSearch={handleSearchMail}
          options={dataSearchMail}
        />
      </FormItem>
      <FormItem name='skillIds' label='Roles Worked'>
        <MultipleSelect
          name='skillIds'
          options={listSkills}
          placeholder='Specific Role'
        />
      </FormItem>
      <FormItem name='employerId' label='Client Worked'>
        <MultipleSelect
          name='employerId'
          options={listProviders}
          placeholder='Client Worked For'
        />
      </FormItem>
      <FormItem label='Post Code' name='employeePostCode'>
        <PostCodeUK />
      </FormItem>
      <FormItem label='Gender' name='employeeGender'>
        <Select options={listGenders} placeholder='Gender' />
      </FormItem>
      <FormItem label='Specific Role Applied for' name='generalRoleId'>
        <MultipleSelect
          name='generalRoleId'
          options={listRoles}
          placeholder='Specific Role'
        />
      </FormItem>
      <DateSeekerFields
        title='Registration Date'
        nameStartDate='registrationDateFrom'
        nameEndDate='registrationDateTo'
        startDate={startDate}
        endDate={endDate}
      />
      <DateSeekerFields
        title='Last Worked Date'
        nameStartDate='lastWorkDateFrom'
        nameEndDate='lastWorkDateTo'
        startDate={startWorkedDate}
        endDate={endWorkedDate}
      />
      <FormItem label='Status' name='employeeStatus'>
        <Select options={listStatus} placeholder='Status' />
      </FormItem>
      <FormItem label='Radius' name='radius'>
        <Select options={RADIUSES} placeholder='Radius' />
      </FormItem>
      <FormItem label='Location' name='leadLocationId'>
        <MultipleSelect
          name='leadLocationId'
          options={listLocations}
          placeholder='Location'
          showSearch={false}
        />
      </FormItem>
      <FormItem label='Roles Interested In' name='preferredRoles'>
        <MultipleSelect
          options={listRoles}
          placeholder='Roles Interested'
          name='preferredRoles'
        />
      </FormItem>
      <FormItem label='Ratings' name='stars'>
        <Select options={ratings} placeholder='Ratings' />
      </FormItem>

      <FormItem label='Languages' name='languages'>
        <MultipleSelect
          options={languages}
          placeholder='Languages'
          name='languages'
        />
      </FormItem>
    </div>
  );
};

export default SeekerSearchFields;
