/* eslint-disable max-len */
import { message } from 'antd';
import { useMutation } from 'react-query';

import { TimesheetService } from '@/services';

/**
 * @typedef {Object} TUseUpdateStatus
 * @property {Omit<import("react-query").UseMutationOptions<unknown, unknown, { timecardIds: string[]; status: string }, unknown>, "mutationFn"> | undefined} options
 * @property {string | number} timesheetId
 */

/**
 * @typedef {import("react-query").UseMutationResult<unknown, unknown, { timecardIds: string[]; status: string }, unknown>} UpdateStatusResult
 */

/**
 * @param {TUseUpdateStatus} params
 * @returns {UpdateStatusResult}
 */
const useUpdateStatus = ({ timesheetId, options }) => {
  const updatedStatus = useMutation(
    /**
     * @type {import("react-query").MutationFunction<unknown, { timecardIds: string[]; status: string }>}
     */
    ({ timecardIds, status }) =>
      TimesheetService.updateStatus({
        timesheetId,
        timecardIds,
        status,
      }),
    {
      onError: ({ errorMessage }) => message.error(errorMessage),
      ...options,
    },
  );

  return updatedStatus;
};

export default useUpdateStatus;
