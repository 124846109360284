import React from 'react';

import { Divider } from 'antd';
import PropTypes from 'prop-types';

import { FormItem, Input } from '@/components';

import './styles.scss';
import { useTenantContext } from '../../../../../TenantWrapper';

const ResultCard = ({ name }) => {
  const { currency } = useTenantContext();

  return (
    <div className='right-section card'>
      <FormItem name={[name, 'omniNetPay']} label='NET Amount:'>
        <Input prefix={currency} bordered={false} readOnly />
      </FormItem>
      <Divider type='vertical' className='divider-amount' />
      <FormItem name={[name, 'omniVat']} label='20% VAT:'>
        <Input prefix={currency} bordered={false} readOnly />
      </FormItem>
    </div>
  );
};
export default ResultCard;

ResultCard.propTypes = {
  name: PropTypes.string,
};

ResultCard.defaultProps = {
  name: '',
};
