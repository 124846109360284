import React, { useEffect, useRef, useState } from 'react';

import { Form, message, Modal } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import {
  Button,
  ColorCheckbox,
  CSV,
  Modal as ModalWarning,
  Pagination,
  PDF,
} from '@/components';
import { PAGE_SIZE_OPTIONS } from '@/constants';
import { useFetch, usePagination, useSessionStorage } from '@/hooks';
import useWindowSize from '@/hooks/useWindow';
import { InvoiceService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import openFilePDFNewTab from '@/utils/openFilePDFNewTab';
import useModal from '@/utils/useModal';

import ListInvoices from '../components/InvoicesTable';
import { EXPORTEDS } from '../constants';
import FormFields from '../formFields';

import './styles.scss';

const Export = ({ activeKey, form }) => {
  const refCSVLink = useRef();
  const windowSize = useWindowSize();
  const [checkBoxesChecked, setCheckBoxesChecked] = useState([]);
  const [invoiceFile, setInvoiceFile] = useState();
  const [isShowInvoice, setIsShowInvoice] = useState(false);

  const [isShow, showModal, hideModal] = useModal();

  const [storedValue, setSession, removeSession] = useSessionStorage(
    'invoicingExportSearch',
  );

  const stateWeekNum = Form.useWatch('weekNum', form);

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'providerName',
      align: 'center',
      sorter: (a, b) => a.providerName.localeCompare(b.providerName),
    },
    {
      title: 'Client ID',
      align: 'center',
      dataIndex: 'clientId',
    },
    {
      title: 'Year',
      align: 'center',
      dataIndex: 'invoiceYear',
    },
    {
      title: 'Payment Week',
      align: 'center',
      dataIndex: 'invoiceWeek',
    },
    {
      title: 'Payment Week Dates',
      align: 'center',
      dataIndex: 'paymentWeekDate',
    },
    {
      title: 'Invoice Number',
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: 'Last edited By',
      align: 'center',
      dataIndex: 'lastEditedBy',
    },
    {
      title: 'Last edited At',
      dataIndex: 'lastEditedAt',
      align: 'center',
      render: (data) => data && moment(data).format('DD/MM/YYYY'),
    },
    {
      title: 'View Invoice',
      dataIndex: 'filename',
      align: 'center',
      render: (filename, { id }) =>
        filename && (
          <Button
            type='link'
            onClick={() => openInvoicePreview(filename)}
            className='link-color'
          >
            View Invoice
          </Button>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'exported',
      align: 'center',
      render: (exported) => EXPORTEDS[exported] || EXPORTEDS.default,
    },
    {
      title: 'Export',
      dataIndex: 'exported',
      align: 'center',
      render: (exported, { id }) => (
        <ColorCheckbox
          onChange={({ target }) => {
            // set id when checked
            if (target.checked) {
              setCheckBoxesChecked((prev) => [...prev, id]);
            } else {
              setCheckBoxesChecked((prev) =>
                [...prev].filter((item) => item !== id),
              );
            }
          }}
        />
      ),
    },
  ];

  const {
    currentPage: number = 1,
    paginationPageSize: size = 25,
    handlePagination,
    handleShowSizeChange,
  } = usePagination();

  const [orderDirection, setOrderDirection] = useState('desc');

  const headerTable = generateHeaderTable(columns);

  const {
    data: exportInvoices,
    refetch: refetchGetInvoices,
    isFetching,
  } = useFetch(
    [
      'page-view-send-get-view-send',
      size,
      number,
      orderDirection,
      activeKey,
      stateWeekNum,
    ],
    () => {
      const { searchTerm, employerId, year, invoiceId } = form.getFieldsValue();

      return InvoiceService.getViewSend({
        size,
        number,
        orderDirection,
        weekNum: stateWeekNum,
        searchTerm,
        employerId,
        year,
        invoiceId,
      });
    },
  );

  const {
    data: csvData = '',
    mutate: mutatePatchInvoiceStatus,
    isLoading: loadingDownloadCsv,
  } = useMutation(
    () => InvoiceService.patchInvoiceStatus({ invoiceIds: checkBoxesChecked }),
    {
      onError: (error) => {
        message.error(error.message ?? 'Can not export csv');
      },
      onSuccess: () => {
        refetchGetInvoices();
      },
    },
  );

  const handleSort = (order) => {
    setOrderDirection(Formatter.makeAbbrevSortType(order));
  };

  const openInvoicePreview = (pdfUrl) => {
    setInvoiceFile(pdfUrl);
    setIsShowInvoice(true);
  };

  const handleClickPreviewInvoice = () => {
    openFilePDFNewTab(invoiceFile);
  };

  const handleSearchInvoices = () => {
    setSession({ ...form.getFieldsValue() });
    refetchGetInvoices();
  };

  const handleResetSearch = () => {
    handlePagination(1);
    form.resetFields();
    removeSession();
    refetchGetInvoices();
  };

  useEffect(() => {
    if (loadingDownloadCsv === false && csvData) {
      refCSVLink.current.link.click();
    }
  }, [loadingDownloadCsv, csvData]);

  return (
    <div className='export-container'>
      <Form
        form={form}
        onFinish={handleSearchInvoices}
        onReset={handleResetSearch}
        initialValues={{
          year: moment().year(),
          weekNum: moment().week(),
        }}
      >
        <FormFields activeKey={activeKey} />
      </Form>
      <ListInvoices
        isFetching={isFetching}
        handleSort={handleSort}
        headerTable={headerTable}
        data={exportInvoices?.data}
      />
      <div className='table-footer-container'>
        <div className='pagination-container'>
          <Pagination
            responsive
            showSizeChanger
            current={number}
            pageSize={size}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            total={exportInvoices?.totalCount ?? 0}
            onChange={handlePagination}
            onShowSizeChange={handleShowSizeChange}
          />
        </div>
      </div>

      <div className='export-footer'>
        <Button
          className='yellow-button style-btn'
          onClick={() => {
            if (!checkBoxesChecked.length) {
              showModal();
            } else {
              mutatePatchInvoiceStatus();
            }
          }}
        >
          {loadingDownloadCsv ? 'Loading csv...' : 'Export to CSV'}
        </Button>

        <CSV ref={refCSVLink} filename='Invoice_export.csv' data={csvData} />
      </div>

      <Modal
        visible={isShowInvoice}
        onOk={() => setIsShowInvoice(false)}
        onCancel={() => setIsShowInvoice(false)}
        wrapClassName='invoice-preview'
        footer={null}
      >
        <button
          type='button'
          style={{ all: 'unset', width: '100%' }}
          onClick={handleClickPreviewInvoice}
        >
          <PDF fileName={invoiceFile} width={windowSize.width / 1.7} />
        </button>
      </Modal>

      <ModalWarning visible={isShow} onClickYes={hideModal} isSuccess>
        No file to export
      </ModalWarning>
    </div>
  );
};

Export.propTypes = {
  activeKey: PropTypes.string,
  form: PropTypes.object,
};

export default Export;
