import React, { useRef } from 'react';

import { Modal, Divider, Table, Tag } from 'antd';
import { get, includes, isString, split } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button } from '@/components';
import { useReactToPdf } from '@/hooks';
import Header from '@/pages/Jobs/CheckinCheckoutJobsDrawer/Header';

import './styles.scss';

const renderTimeCell = (checkedTime) =>
  checkedTime ? moment(checkedTime).format('HH:mm') : '';

const columns = ({ timeIn, timeOut }) => [
  {
    title: 'Name',
    dataIndex: 'fullname',
    render: (name, record) => name || record.employeeName,
  },
  {
    title: 'Number',
    dataIndex: 'employeeId',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    render: (phone, record) => phone || record.employeePhone,
  },
  {
    title: 'Arrived/Signed',
    dataIndex: 'arrived',
  },
  {
    title: `Time In (${timeIn})`,
    dataIndex: 'checkin',
    render: (checkInTime) => renderTimeCell(checkInTime),
  },
  {
    title: `Time Out (${timeOut})`,
    dataIndex: 'checkout',
    render: (checkOutTime) => renderTimeCell(checkOutTime),
  },
];

const splitTime = ({ time, separator = '', defaultValues = [null, null] }) =>
  isString(time) && includes(time, separator)
    ? split(time, separator, 2)
    : defaultValues;

/**
 * Represents a job response.
 * @typedef {Object} InforDedailProps
 * @property {string} fullname
 * @property {number} employeeId
 * @property {string} phone
 * @property {?number} checkin
 * @property {?number} checkout
 */

/**
 * Preview to print PDF file
 * @param {object} PreviewJobPdfProps
 * @prop {boolean} isVisible
 * @prop {function} toggleVisible
 * @prop {object} jobDetail
 * @prop {string} date
 * @prop {InforDedailProps[]} inforDetail
 * @returns {JSX.Element}
 */
const PreviewJobPdf = ({
  isVisible,
  toggleVisible,
  jobDetail,
  date,
  inforDetail,
}) => {
  const time = get(jobDetail, 'shiftDateAndTime.time', null);
  const [timeIn, timeOut] = splitTime({ time, separator: ' - ' });
  const pdfRef = useRef(null);

  const { toPdf } = useReactToPdf({
    targetRef: pdfRef,
  });

  const handlePrint = async () => {
    const doc = document.getElementById('preview-job-pdf-container');
    const maxHeight = Math.max(doc?.scrollHeight, doc?.offsetHeight);
    const maxWidth = doc?.scrollWidth;

    const { pdf } = await toPdf({
      width: maxWidth,
      height: maxHeight,
      applyLandscape: true,
    });
    pdf.save(`job-seeker-${date}.pdf`);
  };

  return (
    <Modal
      wrapClassName='preview-job-pdf'
      visible={isVisible}
      width={950}
      closable={false}
      footer={
        <div className='preview-job-pdf-footer'>
          <Button className='black-button' onClick={toggleVisible}>
            Cancel
          </Button>
          <Button className='yellow-button' onClick={handlePrint}>
            Download
          </Button>
        </div>
      }
    >
      <div ref={pdfRef} id='preview-job-pdf-container'>
        <Header jobDetail={jobDetail} />
        <Divider />
        <div className='preview-job-pdf-body'>
          <Tag className='tag'>
            {moment(date, 'YYYY-MM-DD').format('ddd')} - {date}
          </Tag>
          <Divider />
          <Table
            columns={columns({ timeIn, timeOut })}
            dataSource={inforDetail}
            pagination={false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PreviewJobPdf;

PreviewJobPdf.propTypes = {
  date: PropTypes.string,
  isVisible: PropTypes.bool,
  jobDetail: PropTypes.object,
  inforDetail: PropTypes.array,
  toggleVisible: PropTypes.func,
};

PreviewJobPdf.defaultProps = {
  date: '',
  isVisible: false,
  inforDetail: [],
  jobDetail: {},
  toggleVisible: () => {},
};
