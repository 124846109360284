/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Tabs as AntdTabs } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const { TabPane } = AntdTabs;

/**
 * @typedef {object} TabPanesProps
 * @property {string} tab
 * @property {React.ReactNode} child
 * @property {string | number} key
 */
/**
 * @param {object} props
 * @param {TabPanesProps[]} props.tabPanes
 * @param {string} props.className
 * @param {string} props.defaultActiveKey
 * @return {JSX.Element}
 */
const Tabs = ({ tabPanes, className, defaultActiveKey, ...props }) => (
  <div className='tabs-container'>
    <AntdTabs
      defaultActiveKey={defaultActiveKey}
      className={classNames('primary-tabs', className)}
      {...props}
    >
      {tabPanes.map((item) => (
        <TabPane tab={item.tab} key={item.key}>
          {item.child}
        </TabPane>
      ))}
    </AntdTabs>
  </div>
);

Tabs.propTypes = {
  className: PropTypes.string,
  defaultActiveKey: PropTypes.string,
  tabPanes: PropTypes.array,
};

Tabs.defaultProps = {
  tabPanes: [],
  defaultActiveKey: {},
  className: '',
};

export default Tabs;
