import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Textarea } from '@/components';

import './styles.scss';

const DEFAULT_TYPE = 'sick';

const TYPES_LABELS = {
  sick: 'Sick',
  noShow: 'No Show',
};

const footer = (onCancel, onConfirm, isLoading) => (
  <div className='sick-noshow-modal-footer'>
    <Button
      loading={isLoading}
      className='yellow-button button-container'
      onClick={onConfirm}
    >
      Save
    </Button>
    <Button
      disabled={isLoading}
      className='black-button button-container'
      onClick={onCancel}
    >
      Cancel
    </Button>
  </div>
);

const SickNoShowModal = ({
  type = DEFAULT_TYPE,
  name,
  onCancel,
  onConfirm,
  isLoading,
  ...props
}) => (
  <Modal
    className='sick-noshow-modal-container'
    footer={footer(onCancel, onConfirm, isLoading)}
    loading={isLoading}
    onCancel={onCancel}
    {...props}
  >
    <div className='modal-header-container'>
      <h2>Worker marked as {TYPES_LABELS[type]}?</h2>
      <h2>Add a Comment.</h2>
      <h2>
        Do you want to add a comment to add to the {TYPES_LABELS[type]} Record?
      </h2>
    </div>
    <FormItem name={name}>
      <Textarea
        disabled={isLoading}
        placeholder='Example: Worker sent sick note to confirm'
      />
    </FormItem>
  </Modal>
);

export default SickNoShowModal;

SickNoShowModal.propTypes = {
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

SickNoShowModal.defaultProps = {
  name: '',
  type: 'sick',
  isLoading: false,
  onCancel: () => {},
  onConfirm: () => {},
};
