import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getPositionJobs = async ({ positionId, date }) => {
  const filter = {
    date,
  };
  const endpoint = `jobs/positions/${positionId}/assigned_schedules`;
  const res = await request.get(endpoint, {
    params: {
      filter,
    },
  });

  const { data } = normalizeIndexResponse(res);

  return data;
};

export default getPositionJobs;
