import _ from 'lodash';

import { RESOURCE_TYPE } from '@/constants';
import request from '@/request';
import Formatter from '@/utils/Formatter';
import { formatResponse } from '@/utils/thunkUtil';

export const getWeeklyReport = async (params) => {
  const endpoint = '/dashboard/reports/invoices/weekly';
  const resData = await request.get(endpoint, { params });

  const formattedData = formatResponse(resData, { endpoint });
  const { data = [] } = Formatter.buildEndpoint(
    formattedData.endpoint,
    formattedData,
    RESOURCE_TYPE.invoice,
  );

  const dataLength = data?.length ?? 0;

  const sumWeekly = (key) => {
    const sum = _.sumBy(data, (item) => item[key]);
    return dataLength > 0 && sum;
  };

  const calDataItems =
    dataLength > 0
      ? data.map((item, index) => {
          const {
            totalInvoiced = 0,
            vat = 0,
            workerPay = 0,
            holidayPay = 0,
            totalCost = 0,
            niCost = 0,
            netTotal,
          } = item;

          const profitLoss =
            totalInvoiced - vat - workerPay - holidayPay - totalCost - niCost;

          const totalPay = workerPay + holidayPay;
          const payAndCost = totalPay + totalCost;
          const difference = netTotal - payAndCost;
          const margin = (difference / netTotal) * 100;

          item.profitLoss = profitLoss;
          item.margin = margin;
          return item;
        })
      : [];

  const sumNetTotal = sumWeekly('netTotal');
  const sumVat = sumWeekly('vat');
  const sumTotalInvoiced = sumWeekly('totalInvoiced');
  const sumWorkerPay = sumWeekly('workerPay');
  const sumHolidayPay = sumWeekly('holidayPay');
  const sumTotalCost = sumWeekly('totalCost');
  const sumNiCost = sumWeekly('niCost');
  const sumProfitLoss = sumWeekly('profitLoss') ?? 0;
  const sumMargin = sumWeekly('margin') ?? 0;

  const everageItems = {
    netTotal: sumNetTotal,
    vat: sumVat,
    totalInvoiced: sumTotalInvoiced,
    workerPay: sumWorkerPay,
    holidayPay: sumHolidayPay,
    totalCost: sumTotalCost,
    niCost: sumNiCost,
    profitLoss: sumProfitLoss,
    margin: sumMargin,
  };

  dataLength > 0 && calDataItems.push(everageItems);
  return calDataItems;
};
