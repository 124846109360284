import React from 'react';

import classNames from 'classnames';

import { SICK_NO_SHOW_LABEL } from '@/pages/Payroll/constants';
import { time } from '@/utils';

const columns = [
  {
    title: 'Provider',
    dataIndex: 'providerName',
    ellipsis: true,
    width: '7%',
    render: (providerName) => <span className='cell-item'>{providerName}</span>,
  },
  {
    title: 'Location',
    dataIndex: 'locationName',
    ellipsis: true,
    width: '7%',
    render: (locationName) => <span className='cell-item'>{locationName}</span>,
  },
  {
    title: 'Position',
    dataIndex: 'skillName',
    ellipsis: true,
    width: '7%',
    render: (skillName) => <span className='cell-item'>{skillName}</span>,
  },
  {
    title: 'Week',
    dataIndex: ['assignedSchedule', 'weekNum'],
    ellipsis: true,
    width: '5%',
    render: (weekNum) => <span className='cell-item'>{weekNum}</span>,
  },
  {
    title: 'Shift Date and Time',
    dataIndex: ['assignedSchedule', 'shiftDate'],
    ellipsis: true,
    width: '7%',
    render: (shiftDate, record) => {
      const { shiftStart, shiftEnd } = record?.assignedSchedule ?? {};
      const formatType = 'HH:mm';
      const formattedShiftStart = time.formatDate(shiftStart, formatType);
      const formattedShiftEnd = time.formatDate(shiftEnd, formatType);
      return (
        <div className='cell-item flex-cell'>
          <span>{shiftDate}</span>
          <span>
            {formattedShiftStart} - {formattedShiftEnd}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Hours',
    dataIndex: ['assignedSchedule', 'hours'],
    width: '4%',
    render: (hours) => <span className='cell-item'>{hours}</span>,
  },
  {
    title: 'Status',
    dataIndex: 'noShowType',
    width: '5%',
    render: (status) => (
      <div className={classNames('cell-item center-cell', status)}>
        {SICK_NO_SHOW_LABEL[status]}
      </div>
    ),
  },
  {
    title: 'Last Edited By',
    dataIndex: 'lastEditedBy',
    ellipsis: true,
    width: '7%',
    render: (lastEditedBy) => <span className='cell-item'>{lastEditedBy}</span>,
  },
  {
    title: 'Comment',
    dataIndex: 'commentContent',
    ellipsis: true,
    width: '20%',
    render: (commentContent) => (
      <span className='cell-item'>{commentContent}</span>
    ),
  },
];

export default columns;
