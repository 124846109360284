import React, { useState } from 'react';

import { Form, Checkbox } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import {
  Input,
  FormItem,
  Select,
  DatePicker,
  Button,
  Modal,
} from '@/components';
import { LIMIT_PHONE_NUMBER } from '@/constants';
import { useSkillTypes } from '@/hooks/dropdowns';
import { date } from '@/utils';
import {
  rulesEmail,
  rulesPhone,
  rulesSelect,
  rulesTextInput,
} from '@/utils/rulesInput';

const Reference = () => {
  const { id } = useParams();
  const listRoles = useSkillTypes();
  const formInstance = Form.useFormInstance();
  const isFirstJob = Form.useWatch('isFirstJobNoReference', formInstance);

  const vacancyApplicantReferencesAttributes = Form.useWatch(
    'vacancyApplicantReferencesAttributes',
    formInstance,
  );
  const startAt1 = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 0, 'startAt'],
    formInstance,
  );
  const startAt2 = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 1, 'startAt'],
    formInstance,
  );

  const zip2 = Form.useWatch(
    ['vacancyApplicantReferencesAttributes', 1, 'zip'],
    formInstance,
  );

  const [visibleRefRequest, setVisibleRefRequest] = useState(false);

  const onChange = (/** @type {{ target: { checked: boolean; }; }} */ e) => {
    if (e.target.checked) {
      const refs = vacancyApplicantReferencesAttributes.map((item) => ({
        ...item,
        firstname: '',
        lastname: '',
        fullName: '',
        rel: '',
        email: '',
        phone: '',
        name: '',
        address: '',
        address2: '',
        address3: '',
        start_at: '',
        end_at: '',
        city: '',
        zip: '',
        role: '',
        _destroy: true,
      }));

      formInstance.setFieldsValue({
        vacancyApplicantReferencesAttributes: refs,
        isReference: e.target.checked,
      });
    }
  };

  const reference = ({ index, required = false, startAt, zip }) => (
    <div className='reference'>
      <FormItem
        name={['vacancyApplicantReferencesAttributes', index, 'id']}
        noStyle
      />

      <FormItem
        required={required}
        rules={required && rulesTextInput('Full Name')}
        label='Full Name'
        name={['vacancyApplicantReferencesAttributes', index, 'firstname']}
        className={classNames({
          'reference-item-container': isFirstJob,
        })}
      >
        <Input disabled={isFirstJob} placeholder='Full Name' maxLength={48} />
      </FormItem>

      <FormItem
        rules={required && rulesTextInput('Company')}
        label='Company'
        name={['vacancyApplicantReferencesAttributes', index, 'name']}
        required={required}
        className={classNames({
          'reference-item-container': isFirstJob,
        })}
      >
        <Input disabled={isFirstJob} maxLength={48} placeholder='Company' />
      </FormItem>

      <FormItem
        required={required}
        name={['vacancyApplicantReferencesAttributes', index, 'email']}
        label='Email'
        rules={required && rulesEmail()}
        className={classNames({
          'reference-item-container': isFirstJob,
        })}
      >
        <Input disabled={isFirstJob} placeholder='Email' />
      </FormItem>

      <FormItem
        label='Phone Number'
        rules={required && rulesPhone()}
        required={required}
        name={['vacancyApplicantReferencesAttributes', index, 'phone']}
        className={classNames({
          'reference-item-container': isFirstJob,
        })}
      >
        <Input
          maxLength={LIMIT_PHONE_NUMBER}
          disabled={isFirstJob}
          placeholder='Phone Number'
        />
      </FormItem>

      <div className='half-container'>
        <FormItem
          rules={required && rulesSelect('Start Date')}
          required={required}
          label='Start Date'
          name={['vacancyApplicantReferencesAttributes', index, 'startAt']}
          className={classNames('half-width', {
            'reference-item-container': isFirstJob,
          })}
          normalize={date.convertDateTime}
        >
          <DatePicker
            disabled={isFirstJob}
            name={['vacancyApplicantReferencesAttributes', index, 'startAt']}
          />
        </FormItem>

        <FormItem
          rules={required && rulesSelect('End Date')}
          required={required}
          label='End Date'
          name={['vacancyApplicantReferencesAttributes', index, 'endAt']}
          className={classNames('half-width', {
            'reference-item-container': isFirstJob,
          })}
          normalize={date.convertDateTime}
        >
          <DatePicker
            disabledDate={(disabledDate) =>
              startAt && moment(disabledDate) < moment(startAt)
            }
            name={['vacancyApplicantReferencesAttributes', index, 'endAt']}
            disabled={isFirstJob}
          />
        </FormItem>
      </div>

      <div className='half-container'>
        <FormItem
          rules={required && rulesSelect('Role')}
          required={required}
          label='Role'
          name={['vacancyApplicantReferencesAttributes', index, 'role']}
          className={classNames('half-width', {
            'reference-item-container': isFirstJob,
          })}
        >
          <Select
            disabled={isFirstJob}
            options={listRoles}
            placeholder='Role'
          />
        </FormItem>
      </div>
    </div>
  );

  return (
    <div className='wrap-reference'>
      <div className='reference'>
        <div className='col-span-2'>
          <p className='text-label'>Reference</p>
        </div>

        <div className='references-container'>
          {reference({
            index: 0,
            startAt: startAt1,
            zip: !isFirstJob,
          })}
          {reference({
            index: 1,
            startAt: startAt2,
            zip: zip2,
          })}
        </div>
        <FormItem name='isFirstJobNoReference' valuePropName='checked'>
          <Checkbox className='checkbox-label' onChange={onChange}>
            First Job no Reference
          </Checkbox>
        </FormItem>
        <div className='group-button'>
          {id && (
            <Button className='yellow-button'>
              Send Auto Reference request
            </Button>
          )}
        </div>

        <Modal
          title='Send Reference Request?'
          visible={visibleRefRequest}
          onClickNo={() => setVisibleRefRequest(false)}
        >
          <p>Are you sure you want to send reference</p>
          <p>request to the Seekers past employer?</p>
        </Modal>
      </div>
    </div>
  );
};

export default Reference;
