import { isEmpty } from 'lodash';
import moment from 'moment';

import { ORDER_BY, RESOURCE_TYPE } from '@/constants';
import request from '@/request';
import { array, date } from '@/utils';
import Formatter from '@/utils/Formatter';
import { formatResponse } from '@/utils/thunkUtil';

import { getAddressInfomation } from '../getAddressInfomation';

const getUndefinedOrValue = (data) => (isEmpty(data) ? undefined : data);

const getAll = async ({
  number,
  size,
  ordering,
  radius,
  status,
  managerId,
  searchTerm,
  localOffice,
  registrationDateTo,
  registrationDateFrom,
  zip,
}) => {
  const page = {
    number,
    size,
  };

  const order = {
    ordering,
    orderBy: ORDER_BY,
  };

  const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

  const filter = {
    status: array.remove(status, 'all'),
    managerId: array.remove(managerId, 'all'),
    localOffice: array.remove(localOffice, 'all'),
    searchTerm: getUndefinedOrValue(searchTerm),
    registrationDateTo: date.convertDateTime(registrationDateTo),
    registrationDateFrom: date.convertDateTime(registrationDateFrom),
    latitude: geogecode?.[0]?.geometry?.location?.lat,
    longitude: geogecode?.[0]?.geometry?.location?.lng,
    postCode: geogecode?.[0]?.address_components?.[0]?.long_name,
    radius,
  };

  const endpoint = '/providers/leads';
  const res = await request.get(endpoint, {
    params: {
      page,
      order,
      filter,
    },
  });

  const responseFormatted = formatResponse(res, { endpoint });

  const { data: dataBuildEndpoint } = Formatter.buildEndpoint(
    responseFormatted.endpoint,
    responseFormatted,
    RESOURCE_TYPE.lead,
  );

  const { totalCount, totalPage, currentPage } = Formatter.mergeMeta(
    responseFormatted.omni_employee,
    endpoint,
    responseFormatted.meta,
  )[endpoint];

  const leads = dataBuildEndpoint.map((lead) => ({
    ...lead,
    key: lead.id,
    applicationDate: moment(lead.applicationDate).format('DD-MM-YYYY'),
    allocatedTo: lead.managerId,
  }));

  return {
    leads,
    totalCount,
    totalPage,
    currentPage,
  };
};

export default getAll;
