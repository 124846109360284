import axios from 'axios';

import { ServiceError } from '@/utils/errorUtil';

export const uploadS3 = async ({ url, file, setUploadedProgress }) => {
  try {
    const options = {
      headers: { 'Content-Type': file.type, acl: 'public-read' },
      onUploadProgress: (progress) => {
        const { loaded = 0, total = 0 } = progress ?? {};
        const percentage = (loaded / total) * 100;
        setUploadedProgress?.(percentage);
      },
    };

    const response = await axios.put(url, file, options);
    return response;
  } catch (error) {
    throw new ServiceError(error);
  }
};
