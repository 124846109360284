import {
  LEAD_STATUS_KEY,
  LEAD_STATUS_MODAL_CONTENT,
  STATUS,
  STATUS_CANDIDATE,
} from '@/constants';

export const switchStatus = (value) => {
  switch (value) {
    case STATUS.NOT_ENOUGH_INFORMATION:
      return {
        visible: true,
        title: 'Not enough information?',
        content:
          'Are you sure you do not have enough information? An email will be sent to the Applicant to request further work and personal information.',
      };

    case STATUS.CONTACTED:
      return {
        visible: true,
        title: 'Contacted?',
        content:
          'Have you contacted the applicant? No action will be taken on this profile.',
      };

    case STATUS.SUCCESSFUL_AUTO_EMAIL:
      return {
        visible: true,
        title: 'Onboarding?',
        content:
          'Are you sure you want to move this applicant forward? They will receive an email to book an interview and complete full registration.',
      };

    case STATUS.SUCCESSFUL_PERSONAL:
      return {
        visible: true,
        title: 'Successful - Manual?',
        content:
          'Are you sure you want to mark as successful - Manual? No automated email will be sent to the Applicant. Only confirm if you have invited the applicant manually.',
      };

    case STATUS.REJECT_PERMENANT:
      return {
        visible: true,
        title: 'Rejected - Applicant?',
        content:
          'Are you sure you want to Reject this applicant? They will receive an email their application will no longer be available to view.',
      };

    case STATUS.REJECT_NO_ROLES:
      return {
        visible: true,
        title: 'Rejected - Applicant?',
        content:
          'Are you sure you want to Reject this applicant? They will receive an email their application will no longer be available to view.',
      };

    default:
      return { visible: false, title: '', content: '' };
  }
};

export const switchCandidateStatus = (value) => {
  switch (value) {
    case STATUS_CANDIDATE.REJECTED:
      return {
        visible: true,
        title: 'Reject?',
        content:
          'Are you sure you want to reject this candidate? They will receive a rejection email and not be allowed access to the App.',
      };

    case STATUS_CANDIDATE.APPROVED:
      return {
        visible: true,
        title: 'Approve?',
        content:
          'Are you sure you want to approve this candidate? They will be converted to a Seeker and receive their log in details.',
      };

    case STATUS_CANDIDATE.HOLD:
      return {
        visible: true,
        title: 'Hold?',
        content:
          'Are you sure you want to put this candidate on hold? This should only be temporarily while waiting for documents or more information. ',
      };

    default:
      return { visible: false, title: '', content: '' };
  }
};

export const switchStatusLead = (key) => {
  switch (key) {
    case LEAD_STATUS_KEY.NOT_ENOUGH_INFOMATION:
      return LEAD_STATUS_MODAL_CONTENT[key];
    case LEAD_STATUS_KEY.CONTACTED:
      return LEAD_STATUS_MODAL_CONTENT[key];
    case LEAD_STATUS_KEY.SUCCESSFUL_AUTO:
      return LEAD_STATUS_MODAL_CONTENT[key];
    case LEAD_STATUS_KEY.REJECT_LEAD:
      return LEAD_STATUS_MODAL_CONTENT[key];
    default:
      return {
        title: null,
        content: null,
      };
  }
};
