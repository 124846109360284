/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';

import { Form, Modal } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { AutoComplete, Button, FormItem, Input, Select } from '@/components';
import './styles.scss';
import { DEBOUNCE_WAIT } from '@/constants';
import { useDebounce, useFetch } from '@/hooks';
import { useProviderLocations } from '@/hooks/dropdowns';
import { SeekerService } from '@/services';
import getListPayTemplate from '@/services/dropdownServices/getPayTemplates';
import getProvidersOfPayTemplate from '@/services/dropdownServices/getProvidersOfPayTemplate';

/**
 *
 * @param {object} props
 * @param {boolean} props.isShow show the modal
 * @param {Function} props.onSubmit handle modal submit event
 * @param {Function} props.onCancel handle modal cancel event
 * @param {number} props.providerDetailId the id of provider in detail view
 * @returns {React.ReactNode}
 */
const AllocateWorkerModal = ({
  isShow,
  onSubmit,
  onCancel,
  providerDetailId,
  ...props
}) => {
  const { data: listProviders } = useFetch('getProvidersOfPayTemplate', () =>
    getProvidersOfPayTemplate(),
  );
  const formInstance = Form.useFormInstance();
  const providerId = Form.useWatch('provider', formInstance);
  const { data: locations } = useProviderLocations(
    providerDetailId || providerId,
  );
  const locationId = Form.useWatch('locations', formInstance);
  const searchSeeker = Form.useWatch('searchSeeker', formInstance);
  const debouncedSearchTerm = useDebounce(searchSeeker, DEBOUNCE_WAIT);
  const appliedSeekersWatching = Form.useWatch('appliedSeekers', formInstance);
  const templateName = Form.useWatch('templateName', formInstance);
  const defaultProvider = useMemo(() => {
    const foundProvider = listProviders?.find(
      (item) => item.value === providerDetailId,
    );
    return foundProvider;
  }, [listProviders, providerDetailId]);

  const isDisabledAllocate =
    !locationId ||
    isEmpty(appliedSeekersWatching) ||
    !providerId ||
    !templateName;

  const { data: listPayTemplateOptions } = useFetch(
    ['getListPayTemplate', providerId, locationId],
    () => getListPayTemplate(providerId, locationId),
    {
      enabled: !!(providerId && locationId),
    },
  );

  const { data: seekers, isFetching: isSearching } = useFetch(
    ['getSeeker', debouncedSearchTerm],
    () =>
      SeekerService.getSeekers({
        searchTerm: debouncedSearchTerm,
        employeeStatus: 'active',
        checkAllocated: true,
      }),
    { initialData: {}, enabled: !!debouncedSearchTerm },
  );

  const handleChangeProvider = () => {
    formInstance.resetFields(['locations', 'templateName']);
  };

  const handleChangeLocation = () => {
    formInstance.resetFields(['templateName']);
  };

  const dataSeeker = seekers.data || [];

  const options = dataSeeker.map((opt) => ({
    value: opt.fullname,
    label: opt.fullname,
    key: opt.employeeId,
  }));

  const renderFooter = (
    <div>
      <Button
        htmlType='submit'
        key='submit'
        className='allocate-btn yellow-button'
        disabled={isDisabledAllocate}
        onClick={onSubmit}
      >
        Allocate
      </Button>
    </div>
  );

  return (
    <Modal
      className='allocate-modal-wrapper'
      title='Pay Template'
      visible={isShow}
      onCancel={onCancel}
      destroyOnClose
      centered
      footer={renderFooter}
      {...props}
    >
      <div className='allocate-modal--description'>
        <div>Allocate worker to Pay Template.</div>
      </div>
      <div className='allocate-modal--body'>
        <div className='allocate-row'>
          <FormItem className='allocate-item' name='provider' label='Provider'>
            <Select
              defaultValue={defaultProvider}
              options={listProviders}
              onChange={handleChangeProvider}
            />
          </FormItem>
          <FormItem className='allocate-item' name='locations' label='Location'>
            <Select options={locations} onChange={handleChangeLocation} />
          </FormItem>
        </div>
        <div className='allocate-row'>
          <FormItem
            className='allocate-item'
            name='templateName'
            label='Pay Template'
          >
            <Select options={listPayTemplateOptions?.templates} />
          </FormItem>
        </div>
        <div className='allocate-row'>
          <FormItem
            className='allocate-item search-seeker'
            name='searchSeeker'
            label='Search Seeker'
          >
            <AutoComplete
              loading={isSearching}
              onSelect={(_value, option) => {
                const fieldsValue = {
                  searchSeeker: '',
                  appliedSeekers: [
                    ...appliedSeekersWatching,
                    {
                      employeeId: option.key,
                      status: 'completed',
                      seekerName: option.value,
                    },
                  ],
                };

                formInstance.setFieldsValue(fieldsValue);
              }}
              options={options}
              placeholder='Search seeker'
            />
          </FormItem>
          <div className='list-seekers'>
            <Form.List initialValue={[]} name='appliedSeekers'>
              {(fields, { remove }) =>
                fields.map((field, index) => (
                  <div className='seeker-item' key={field.key}>
                    <FormItem name={[index, 'seekerName']}>
                      <Input readOnly />
                    </FormItem>
                    <Button type='text' onClick={() => remove(field.name)}>
                      <XIconBlack />
                    </Button>
                  </div>
                ))
              }
            </Form.List>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AllocateWorkerModal;

AllocateWorkerModal.propTypes = {
  isShow: PropTypes.bool,
  onSubmit: PropTypes.func,
  onApply: PropTypes.func,
  children: PropTypes.node,
  onCancel: PropTypes.func,
  providerDetailId: PropTypes.number,
};

AllocateWorkerModal.defaultProps = {
  isShow: false,
  onSubmit: () => {},
  onApply: () => {},
  onCancel: () => {},
  children: null,
  providerDetailId: null,
};
