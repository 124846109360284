import React, { useState } from 'react';

import { Modal } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, FormItem, Textarea } from '@/components';

import './styles.scss';

const DEFAULT_TYPE = 'sick';

const TYPES_LABELS = {
  sick: 'Sick',
  hide: 'No Show',
};

const footer = (onConfirm, isLoading) => (
  <Button loading={isLoading} className='yellow-button' onClick={onConfirm}>
    Edit
  </Button>
);

const ModalEditCommentNoShow = ({
  type = DEFAULT_TYPE,
  name,
  onConfirm,
  isLoading,
  comment,
  assignScheduleId,
  ...props
}) => {
  const [text, setText] = useState(comment ?? '');
  const renderTitle = () => (
    <span className={type}>Comment - {TYPES_LABELS[type]}</span>
  );
  const handleEdit = () => {
    onConfirm({ assignScheduleId, comment: text });
  };

  return (
    <Modal
      title={renderTitle()}
      className={classNames('sick-noshow-modal-container', type)}
      footer={footer(handleEdit, isLoading)}
      loading={isLoading}
      {...props}
    >
      {name ? (
        <FormItem name={name}>
          <Textarea disabled={isLoading} />
        </FormItem>
      ) : (
        <Textarea value={text} onChange={(e) => setText(e.target.value)} />
      )}
    </Modal>
  );
};

export default ModalEditCommentNoShow;

ModalEditCommentNoShow.propTypes = {
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onConfirm: PropTypes.func,
  comment: PropTypes.string,
  assignScheduleId: PropTypes.string || PropTypes.number,
};

ModalEditCommentNoShow.defaultProps = {
  name: '',
  type: 'sick',
  isLoading: false,
  onConfirm: () => {},
};
