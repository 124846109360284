import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Form, Pagination, Spin } from 'antd';
import { useQuery, useMutation } from 'react-query';

import { Modal, Button, GeneralTemplate, ListView, CSV } from '@/components';
import { ORDER_BY } from '@/constants';
import { useSessionStorage } from '@/hooks';
import { useStatus } from '@/hooks/dropdowns';
import {
  ChangeStatusApplicationService,
  DownloadCsvApplicationService,
} from '@/services';
import { getApplicationPage } from '@/services/applicantService/searchPage';
import { getAddressInfomation } from '@/services/getAddressInfomation';
import { array, date } from '@/utils';
import { switchStatus } from '@/utils/switchStatus';

import ApplicantModal from './applicantModal';
import ApplicationFields from './applicationFields';
import './styles.scss';

const breadCrumb = [{ item: 'Application', to: '/recruitment/application' }];

const ApplicationView = () => {
  const [form] = Form.useForm();
  const { listStatuses } = useStatus();
  const refCSVLink = useRef();
  const [rowKey, setRowKey] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusValue, setStatusValue] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isDownload, setIsDownload] = useState(false);
  const [visibleInfoModal, setVisibleInfoModal] = useState(false);

  const [visibleStatusModal, setVisibleStatusModal] = useState({
    title: '',
    content: '',
    visible: false,
  });

  const [storedValue, setSession, removeSession] =
    useSessionStorage('applicantSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { visible, title, content } = visibleStatusModal;

  // Fetch applicant page
  const {
    data: dataApplicantPage,
    refetch,
    isFetching,
  } = useQuery(
    ['applicant-page', currentPage, pageSize, sortOrder],
    async () => {
      const {
        zip,
        searchTerm,
        status,
        location,
        generalRole,
        preferredRoles,
        registrationDateFrom,
        registrationDateTo,
        radius,
      } = form.getFieldsValue();
      const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

      const filter = {
        searchTerm,
        status: array.remove(status, 'all'),
        officeId: array.remove(location, 'all'),
        generalRole: array.remove(generalRole, 'all'),
        preferredRoles: array.remove(preferredRoles, 'all'),
        registrationDateTo: date.convertDateTime(registrationDateTo),
        registrationDateFrom: date.convertDateTime(registrationDateFrom),
        latitude: geogecode?.[0]?.geometry?.location?.lat,
        longitude: geogecode?.[0]?.geometry?.location?.lng,
        zip: geogecode?.[0]?.address_components?.[0]?.long_name,
        radius,
      };

      return getApplicationPage({
        page: {
          number: currentPage,
          size: pageSize,
        },
        order: {
          orderBy: ORDER_BY,
          orderDirection: sortOrder,
        },
        filter,
      });
    },
    { refetchOnWindowFocus: false, cacheTime: 0, retry: false },
  );

  // Set change status
  const { mutate: changeStatus } = useMutation(
    // eslint-disable-next-line no-shadow
    ({ rowKey, statusValue }) =>
      ChangeStatusApplicationService.changeStatusApplication(
        rowKey,
        statusValue,
      ),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const {
    data: dataCSV = [],
    isFetching: isFetchingDownloadCsv,
    refetch: refetchApplicantExportCSV,
  } = useQuery(
    ['applicant-download-csv'],
    async () => {
      const {
        zip,
        searchTerm,
        status,
        location,
        generalRole,
        preferredRoles,
        registrationDateFrom,
        registrationDateTo,
        radius,
      } = form.getFieldsValue();

      const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

      const filter = {
        searchTerm,
        status: array.remove(status, 'all'),
        officeId: array.remove(location, 'all'),
        generalRole: array.remove(generalRole, 'all'),
        preferredRoles: array.remove(preferredRoles, 'all'),
        registrationDateTo: date.convertDateTime(registrationDateTo),
        registrationDateFrom: date.convertDateTime(registrationDateFrom),
        latitude: geogecode?.[0]?.geometry?.location?.lat,
        longitude: geogecode?.[0]?.geometry?.location?.lng,
        zip: geogecode?.[0]?.address_components?.[0]?.long_name,
        radius,
      };

      return DownloadCsvApplicationService.downloadCSV({
        order: {
          orderBy: ORDER_BY,
          orderDirection: sortOrder,
        },
        filter,
      });
    },
    {
      cacheTime: 0,
      retry: false,
      enabled: false,
      onSuccess: () => {
        setIsDownload(true);
      },
    },
  );

  useEffect(() => {
    if (isDownload) {
      refCSVLink.current.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const handleSearchData = () => {
    setCurrentPage(1);
    setSession(form.getFieldsValue());
    refetch();
  };

  const handleSort = (order) => {
    setCurrentPage(1);
    return order === 'ascend' ? setSortOrder('asc') : setSortOrder('desc');
  };

  const handleClearFilter = useCallback(() => {
    setCurrentPage(1);
    removeSession();
    form.resetFields();

    refetch();
  }, [removeSession, form, refetch, setCurrentPage]);

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const handleChangeStatus = (id, value) => {
    setStatusValue(value);
    setRowKey(id);
    const status = switchStatus(value);

    setVisibleStatusModal({
      visible: status.visible,
      title: status.title,
      content: status.content,
    });
  };

  const handleChangePage = (page) => setCurrentPage(page);

  const handleConfirmStatus = useCallback(() => {
    changeStatus({ rowKey, statusValue });
    setVisibleStatusModal({ visible: false });
  }, [changeStatus, rowKey, statusValue]);

  const onRow = (row) => {
    setRowKey(row);
    setVisibleInfoModal(true);
  };

  return (
    <GeneralTemplate
      mainItem='Recruitment'
      buttonContent='Create Application'
      headerText='Applications'
      data={breadCrumb}
      buttonLink='/create-new-applicant'
      className='applicant-page'
    >
      <Spin spinning={isFetchingDownloadCsv}>
        <Form className='wrap-content' form={form} onFinish={handleSearchData}>
          <ApplicationFields
            setCurrentPage={setCurrentPage}
            onClearFilter={handleClearFilter}
          />
        </Form>
        <div className='wrap-content'>
          <div className='view-box'></div>
        </div>
        <ListView
          pagination={false}
          scroll={{ y: 650 }}
          loading={isFetching}
          locale={{
            emptyText: 'No Applicants match your search criteria',
          }}
          handlePropagation={handlePropagation}
          infoDetail={dataApplicantPage?.applicantListInfo || []}
          onRow={(row) => onRow(row)}
          onChange={handleChangeStatus}
          options={listStatuses}
          handleSort={handleSort}
          isCandidate={false}
          className='list-view'
        />
        <div className='applicant-paginations'>
          <div className='pagination-container'>
            <Pagination
              responsive
              pageSize={pageSize}
              current={currentPage}
              total={dataApplicantPage?.totalCount}
              onChange={handleChangePage}
              showSizeChanger
              onShowSizeChange={(current, pageSizes) => {
                setCurrentPage(current);
                setPageSize(pageSizes);
              }}
            />
          </div>
          <Button
            className='yellow-button download-csv-btn'
            onClick={refetchApplicantExportCSV}
            disabled={isFetchingDownloadCsv}
          >
            {isFetchingDownloadCsv ? 'Loading csv...' : 'Download CSV'}
          </Button>
          <CSV ref={refCSVLink} filename='data-applicant.csv' data={dataCSV} />
        </div>
      </Spin>
      <Modal
        visible={visible}
        title={title}
        onClickNo={useCallback(
          () => setVisibleStatusModal({ visible: false }),
          [],
        )}
        onClickYes={handleConfirmStatus}
      >
        {content}
      </Modal>
      <ApplicantModal
        applicantID={rowKey}
        visible={visibleInfoModal}
        onVisibleInfoModal={setVisibleInfoModal}
        onReFetchApplicantPage={refetch}
      />
    </GeneralTemplate>
  );
};

export default ApplicationView;
