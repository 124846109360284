import request from '@/request';

const editBreak = async (
  /** @type {string/number} */ id,
  /** @type {array} */ timecardIds,
  /** @type {string/number} */ breakValue,
) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/timecards/break_time`;
  const res = await request.patch(endpoint, {
    timecard_ids: timecardIds,
    break: breakValue,
  });

  return res;
};
export default editBreak;
