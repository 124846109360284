import React from 'react';

import ColorItem from './ColorItem';
import './styles.scss';

const ColorBox = () => (
  <div className='color-box'>
    <div className='color-box-title'>Colour Key</div>
    <div className='color-list'>
      <ColorItem title='Posted' status='posted' />
      <ColorItem title='Part-Filled' status='part_filled' />
      <ColorItem title='Filled' status='filled' />
      <ColorItem title='In Progress' status='live' />
      <ColorItem title='Completed' status='completed' />
    </div>
  </div>
);

export default ColorBox;
