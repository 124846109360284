import React from 'react';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import CommentsAndStatusUpdates from './components/CommentsAndStatusUpdates';
import CompanyInformation from './components/CompanyInformation';
import ContactInformation from './components/ContactInformation';
import JobTemplate from './components/JobTemplate';
import Locations from './components/Locations';
import RateCard from './components/RateCard';
import './styles.scss';
import TrustAndBlockSeeker from './components/TrustedAndBlockedSeeker';

const Profile = ({
  activeKey,
  setActiveKey,
  refetchGetOneProvider,
  setLoading,
}) => {
  const location = useLocation();

  const isEditProvider = location.pathname.includes('edit-provider');

  const { Panel } = Collapse;
  return (
    <Collapse
      expandIconPosition='right'
      activeKey={activeKey}
      onChange={setActiveKey}
    >
      <Panel header='Company Information' key='company-information' forceRender>
        <CompanyInformation />
      </Panel>

      <Panel header='Contact Information' forceRender key='contact-information'>
        <ContactInformation />
      </Panel>

      <Panel header='Rates' forceRender key='rates'>
        <RateCard />
      </Panel>

      <Panel header='Locations and Industries' forceRender key='locations'>
        <Locations />
      </Panel>

      {isEditProvider && (
        <Panel
          header='Worker Pool and Blocked Seekers'
          forceRender
          key='trusted-blocked'
        >
          <TrustAndBlockSeeker
            setLoading={setLoading}
            isEditProvider={isEditProvider}
          />
        </Panel>
      )}

      {isEditProvider && (
        <Panel header='Job Template' forceRender key='job-template'>
          <JobTemplate />
        </Panel>
      )}

      <Panel
        header='Comments And Status Updates'
        forceRender
        key='comments-and-status-updates'
      >
        <CommentsAndStatusUpdates
          isEditProvider={isEditProvider}
          refetchGetOneProvider={refetchGetOneProvider}
        />
      </Panel>
    </Collapse>
  );
};

Profile.propTypes = {
  activeKey: PropTypes.array,
  setActiveKey: PropTypes.func,
  refetchGetOneProvider: PropTypes.func,
  setLoading: PropTypes.func,
};

export default Profile;
