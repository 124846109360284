import React, { useState } from 'react';

import { Form } from 'antd';
import { isEmpty } from 'lodash';

import { useLicences } from '@/hooks/useCertificates';
import { Checkbox, DatePicker, FormItem, RemoteUploader } from '@/components';

import { AddAnotherButton } from '../AddAnotherButton';

export function LicenceInput() {
  const licences = useLicences();
  const formInstance = Form.useFormInstance();
  const [selected, setSelected] = useState();

  const handleSelectDoc = (index) => {
    const licenceDocs = formInstance.getFieldValue('licences') ?? [];

    if (!licenceDocs) {
      return;
    }

    if (licenceDocs[index].checked) {
      return;
    }

    setSelected(index);

    const newLicenceDocs = licenceDocs.map((doc, idx) => ({
      ...doc,
      checked: index === idx,
    }));

    formInstance.setFieldsValue({
      licences: newLicenceDocs,
    });
  };

  const licenceDocs = Form.useWatch('licences', formInstance);
  const selectedLicence = licenceDocs?.[selected];

  const handleOnSuccess = ({ signedId, url }) => {
    const licenceDocs = formInstance.getFieldValue('licences') ?? [];
    const selectedLicence = licenceDocs[selected];

    if (!selectedLicence) {
      return;
    }

    const newDocs = licenceDocs.with(selected, {
      ...selectedLicence,
      attachment: {
        ...selectedLicence.attachment,
        url,
        signedId,
      },
    });

    formInstance.setFieldsValue({
      licences: newDocs,
    });
  };

  return (
    <div className='block-container'>
      <h3>Licences/Qualifications</h3>

      <div className='body-block licence'>
        <div className='left-block'>
          <div className='documents'>
            <Form.List name='licences' initialValue={[]}>
              {(fields) => (
                <>
                  {fields.map((field, index) => {
                    const value = formInstance.getFieldValue('licences')[index];

                    return (
                      <div className='document-item' key={field.key}>
                        <FormItem name={[field.name, 'attachment']} noStyle />
                        <FormItem name={[field.name, 'name']} noStyle />
                        <FormItem name={[field.name, 'type']} noStyle />
                        <FormItem name={[field.name, 'slug']} noStyle />
                        <FormItem name={[field.name, 'id']} noStyle />

                        <div className='document-item-checkbox'>
                          <Checkbox
                            checked={value.checked}
                            className='item-checkbox'
                            onClick={() => handleSelectDoc(index)}
                          >
                            <div>{value.name}</div>
                            <Checkbox
                              checked={!isEmpty(value.attachment?.url)}
                            />
                          </Checkbox>
                        </div>
                        <FormItem name={[field.name, 'expiresAt']}>
                          <DatePicker name={[field.name, 'expiresAt']} />
                        </FormItem>
                      </div>
                    );
                  })}

                  <AddAnotherButton
                    type='licence'
                    name='licences'
                    list={licences}
                  />
                </>
              )}
            </Form.List>
          </div>
        </div>
        <div className='right-block'>
          <RemoteUploader
            disabled={!selectedLicence}
            label={selectedLicence?.name}
            onUploadSuccess={handleOnSuccess}
            file={selectedLicence?.attachment}
          />
        </div>
      </div>
    </div>
  );
}
