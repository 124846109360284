/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import React from 'react';

import { Progress, Tooltip } from 'antd';
import { find, get, size } from 'lodash';
import moment from 'moment';

import acoholLicenceIcon from '@/assets/icons/acoholLicenceYellow.png';
import dbsCheckIcon from '@/assets/icons/dbsCheckYellow.png';
import drivingLicenceIcon from '@/assets/icons/drivingLicenceYellow.png';
import foodSafetyIcon from '@/assets/icons/foodSafetyYellow.png';
import forkliftDriverIcon from '@/assets/icons/forkliftDriverYellow.png';
import hgvLicenceIcon from '@/assets/icons/hgvLicenceYellow.png';
import manualHandlingIcon from '@/assets/icons/manualHandlingYellow.png';
import { ReactComponent as MoreIcon } from '@/assets/icons/moreIcon.svg';
import scooterLicenceIcon from '@/assets/icons/scooterLicenceYellow.png';
import { DATE_FORMAT } from '@/constants';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import Actions from '../components/actions';

export const tempData = [];
const sorterForTimeShift = (a, b) => {
  const shiftA = a?.shiftDateAndTime;
  const shiftB = b?.shiftDateAndTime;

  const timeStartA = shiftA?.time?.split(' - ')[0];
  const timeStartB = shiftB?.time?.split(' - ')[0];
  const momentA = moment(
    `${shiftA.date?.startDate} ${
      !timeStartA && timeStartA === '' ? '23:59' : timeStartA
    }`,
    'DD/MM/YYYY hh:mm',
  );

  const momentB = moment(
    `${shiftB.date?.startDate} ${
      !timeStartB && timeStartB === '' ? '23:59' : timeStartB
    }`,
    'DD/MM/YYYY hh:mm',
  );

  return momentA.isAfter(momentB);
};

const formatTime = (date, format) => moment(date, DATE_FORMAT).format(format);

const STATUES = [
  { value: 1, status: 'live', label: 'In Progress' },
  { value: 2, status: 'completed', label: 'Completed' },
  { value: 3, status: 'filled', label: 'Filled' },
  { value: 4, status: 'part_filled', label: 'Part Filled' },
  { value: 0, status: 'posted', label: 'Posted' },
];

const POSTED_JOB_STATUSES = {
  posted: 0,
  filled: 3,
  part_filled: 4,
};

const STATUS_POSTED = 0;

const FULL_PERCENT = 100;

const Status = (props) => (
  <Progress status='active' showInfo={false} {...props} />
);

const getStatusJob = (status, totalAssignments, filled) => {
  if (status !== STATUS_POSTED) {
    return status;
  }
  if (filled > 0) {
    return totalAssignments === filled
      ? POSTED_JOB_STATUSES.filled
      : POSTED_JOB_STATUSES.part_filled;
  }
  return POSTED_JOB_STATUSES.posted;
};

export const headerTable = ({
  jobIdActions,
  openManageWorkerModal,
  showAction,
  setShowAction,
  setShowPopupCreate,
  setRepostId,
  setJobIdEdit,
  setJobIdActions,
  deleteShift,
  promoteSelectedJob,
  query,
  form,
}) => {
  const handleOpenModal = (e, formInstance, key) => {
    e.stopPropagation();
    openManageWorkerModal(formInstance, key);
  };
  return generateHeaderTable([
    {
      title: 'Provider',
      width: '10%',
      render: (data) => (
        <Tooltip placement='topLeft' title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: 'Location',
      width: '10%',
      render: (data) => (
        <Tooltip placement='topLeft' title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: 'Job Role',
      width: '10%',
      render: (data) => (
        <Tooltip placement='topLeft' title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: 'Pay',
      align: 'center',
      width: '5%',
      dataIndex: 'payRate',
      render: (payRate) => (
        <Tooltip placement='topLeft' title={Formatter.currencyGBP(payRate)}>
          {Formatter.currencyGBP(payRate)}
        </Tooltip>
      ),
    },
    {
      title: 'Charge',
      align: 'center',
      width: '5%',
      dataIndex: 'chargeRate',
      render: (chargeRate) => (
        <Tooltip placement='topLeft' title={Formatter.currencyGBP(chargeRate)}>
          {Formatter.currencyGBP(chargeRate)}
        </Tooltip>
      ),
    },
    {
      title: 'Shift Day',
      width: '5%',
      dataIndex: 'shiftDateAndTime',
      sorter: sorterForTimeShift,
      render: (item) => {
        const startTimeDay = formatTime(item.date?.startDate, 'ddd');
        const isExistEndDay = item.date?.endDate;
        const endTimeDay = formatTime(item.date?.endDate, 'ddd');
        const isNextDay = get(item, 'date.isNextDay', false);
        const content = (
          <div className='shift-day-col'>
            <span>{startTimeDay}</span>
            <span className='next-day'>{isNextDay && ' +1'}</span>
            <span>{isExistEndDay && !isNextDay && ` - ${endTimeDay}`}</span>
          </div>
        );

        return (
          <Tooltip placement='topLeft' title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    {
      title: 'Shift Date',
      width: '8%',
      dataIndex: 'shiftDateAndTime',
      sorter: sorterForTimeShift,

      render: (item, record) => {
        const isNextDay = get(item, 'date.isNextDay', false);
        const schedule = get(record, 'schedule.items');
        const isExistEndDay = item.date?.endDate;
        const quantitySchedule = size(schedule);

        const content = (
          <div className='shift-day-col'>
            <p>
              {item.date?.startDate} {item.date?.endDate && !isNextDay && '-'}
            </p>
            {!isNextDay && <p>{item.date?.endDate}</p>}
            {!isExistEndDay && quantitySchedule > 1 && (
              <p>{item.date?.startDate}</p>
            )}
          </div>
        );

        return (
          <Tooltip placement='topLeft' title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    {
      title: 'Shift Time',
      width: '8%',
      dataIndex: 'shiftDateAndTime',
      align: 'center',

      render: (item, record) => {
        const schedule = get(record, 'schedule.items');
        const counterScheduleStr =
          size(schedule) > 1 && `${size(schedule)} shifts`;
        const content = (
          <div>
            <div>{counterScheduleStr}</div>
            <p>{item.time}</p>
          </div>
        );

        return (
          <Tooltip placement='topLeft' title={content}>
            {content}
          </Tooltip>
        );
      },
    },
    {
      title: 'Hours',
      width: '4%',
      align: 'center',
      render: (data) => {
        const value = Formatter.fixedNumber({ value: data });
        return (
          <Tooltip placement='topLeft' title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      title: (
        <>
          <div>Filled/</div>
          <div>Posted</div>
        </>
      ),
      width: '4%',
      align: 'center',
      render: (_, { key, filled, totalAssignments }) => (
        <div
          className='expand-centered-cell'
          onClick={(e) => handleOpenModal(e, form, key)}
        >
          {filled}/{totalAssignments}
        </div>
      ),
    },
    {
      title: 'Applied',
      align: 'center',
      width: '5%',
      render: (data, { key }) => (
        <div
          className='cell-container expand-centered-cell'
          onClick={(e) => handleOpenModal(e, form, key)}
        >
          {data}
        </div>
      ),
    },
    {
      title: 'Request',
      width: '5%',
      dataIndex: 'requestedNumber',
      render: (data, { key }) => (
        <div
          className='request-col expand-centered-cell'
          onClick={(e) => handleOpenModal(e, form, key)}
        >
          {data}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'Declined',
      width: '5%',
      render: (data, { key }) => (
        <div
          className='cell-container expand-centered-cell'
          onClick={(e) => handleOpenModal(e, form, key)}
        >
          {data}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'Status',
      width: '6%',
      render: (_, record) => {
        const { filled, totalAssignments, status, key } = record;

        const statusValue = getStatusJob(status, totalAssignments, filled);

        const { status: jobStatus, label } = find(STATUES, {
          value: statusValue,
        });

        const percent = (filled / totalAssignments) * FULL_PERCENT;

        return (
          <div
            className='status-container expand-centered-cell'
            onClick={(e) => handleOpenModal(e, form, key)}
          >
            <Status percent={percent} className={jobStatus} />
            <span className='status-label'>{label}</span>
          </div>
        );
      },
    },

    {
      title: 'Actions',
      align: 'center',
      width: '5%',
      render: (_, record) => (
        <Tooltip
          trigger='click'
          placement='bottomLeft'
          visible={jobIdActions === record.key && showAction}
          onVisibleChange={() => setShowAction(!showAction)}
          title={
            <Actions
              record={record}
              setShowAction={setShowAction}
              setJobIdEdit={setJobIdEdit}
              setShowPopupCreate={setShowPopupCreate}
              setRepostId={setRepostId}
              deleteShift={deleteShift}
              promoteSelectedJob={promoteSelectedJob}
              tab={query.tab}
              openManageWorkerModal={openManageWorkerModal}
            />
          }
        >
          <MoreIcon
            onClick={(e) => {
              e.stopPropagation();
              setShowAction(!showAction);
              setJobIdActions(record.key);
            }}
          />
        </Tooltip>
      ),
    },
  ]);
};

export const postedOption = Array.from(Array(100), (_, index) => ({
  value: index + 1,
  label: index + 1,
}));

export const listLicensenIcon = {
  drivingLicense: {
    name: 'Driving License',
    id: 'driving_license',
    icon: drivingLicenceIcon,
  },

  alcoholLicense: {
    name: 'Alcohol License',
    icon: acoholLicenceIcon,
    id: 'alcohol_license',
  },

  scooterLicense: {
    name: 'Scooter License',
    icon: scooterLicenceIcon,
    id: 'scooter_license',
  },

  forkLiftTruck: {
    name: 'Fork Lift License',
    icon: forkliftDriverIcon,
    id: 'fork_lift_truck',
  },
  foodSafetyAllergens: {
    name: 'Food Safety',
    id: 'food_safety_allergens',
    icon: foodSafetyIcon,
  },

  hgvLicense: {
    name: 'HGV License',
    id: 'hgv_license',
    icon: hgvLicenceIcon,
  },

  manualHanding: {
    name: 'Manual Handing',
    id: 'manual_handing',
    icon: manualHandlingIcon,
  },

  spectatorsSafety: {
    name: 'Spectators Safety',
    id: 'spectators_safety',
    icon: dbsCheckIcon,
  },
  sia: {
    name: 'SIA',
    id: 'sia',
  },
  welder: {
    name: 'Welder',
    id: 'welder',
  },
  llopTraining: {
    name: 'LLOP Training',
    id: 'llop_training',
  },
  induction: {
    name: 'Induction',
    id: 'induction',
  },
};

export const coverDateToStringFormatDMY = (date) => {
  if (!date) {
    return '';
  }
  const timeStamp = moment(date);
  return moment(timeStamp).format('ddd Do MMM');
};

export const coverCeilTo15Minutes = (timeMoment = moment()) => {
  const hour = timeMoment.hour();

  const minute = timeMoment.minute();

  let i = Math.ceil(minute / 15);
  const m = minute % 15;

  if (m === 0) {
    i += 1;
  }

  if (i === 4) {
    timeMoment.set({
      minute: 0,
      hour: hour + 1,
    });
  } else {
    timeMoment.set({
      minute: i * 15,
      hour,
    });
  }

  return timeMoment;
};

export const checkOverlap = (range1, range2) => {
  const start1 = moment(range1.startAt);
  const end1 = moment(range1.endAt);
  const start2 = moment(range2.startAt);
  const end2 = moment(range2.endAt);

  if (start2.isBetween(start1, end1) || end2.isBetween(start1, end1)) {
    return true;
  }

  if (start1.isBetween(start2, end2) || end1.isBetween(start2, end2)) {
    return true;
  }

  if (
    start1.isSame(start2, 'minute') ||
    end1.isSame(end2, 'minute') ||
    start1.isSame(end2, 'minute') ||
    start2.isSame(end1, 'minute')
  ) {
    return true;
  }

  return false;
};

export const checkErrorShift = (items = [], seekersPayTemplates = []) => {
  const errors = [];

  const minDateTime = moment.min(
    seekersPayTemplates.map((item) => moment(item.startAt)),
  );
  for (let index = 0; index < items.length; index += 1) {
    const element = items[index];

    const sMoment = moment(element.startAt);
    const eMoment = moment(element.endAt);

    if (sMoment < moment()) {
      errors.push('The job must have a future start date and time');
      // eslint-disable-next-line no-continue
      continue;
    }

    const breakTime = parseInt(element.break, 10);

    if (isNaN(parseInt(element.break, 10))) {
      errors.push('Break Time is number');
      // eslint-disable-next-line no-continue
      continue;
    }

    const durations = eMoment.diff(sMoment, 'minute', true) - (breakTime ?? 0);

    if (durations < 60) {
      errors.push('Job duration must be larger or equal 1 hr');
      // eslint-disable-next-line no-continue
      continue;
    }
    if (durations > 1080) {
      errors.push('Maximum duration is only 18 hr');
      // eslint-disable-next-line no-continue
      continue;
    }

    if (items.length) {
      let flag = false;

      for (let i = 0; i < items.length; i += 1) {
        const e = items[i];
        if (index !== i) {
          if (checkOverlap(element, e)) {
            errors.push('The job has overlapping schedules');
            flag = true;

            break;
          }
        }
      }

      if (flag) {
        // eslint-disable-next-line no-continue
        continue;
      }
    }

    if (sMoment < moment(minDateTime) && seekersPayTemplates.length > 0) {
      errors.push(
        'Shift date and time must be on or later than Pay Template starting date and time',
      );
    }

    errors.push('');
  }

  return errors;
};

export const TYPE_SEEKER_ITEM = {
  ADD: 'ADD',
  NUDGE: 'NUDGE',
};

export const TYPE_SHIFTS = {
  GROUP: 'group_shift',
  INDIVIDUAL: 'individual_shift',
};

export const OPTIONS_TYPE_SHIFTS = [
  {
    label: 'Group Shifts',
    value: TYPE_SHIFTS.GROUP,
  },
  {
    label: 'Individual Shifts',
    value: TYPE_SHIFTS.INDIVIDUAL,
  },
];

export const MESSAGE_TIME_LIMIT = 1;

export const JOB_RESPONSE_STATUS = {
  ACCEPTED: 'accepted',
};

export const JOB_DESCRIPTION_NO_LIMIT = 65000;
