import moment from 'moment';

import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getBuildRota = async ({
  employerId,
  employerLocationId,
  skillId,
  week,
  date,
  view,
}) => {
  const endpoint = '/rotas/rota_shift_plans';
  const res = await request.get(endpoint, {
    params: {
      filter: {
        employerId,
        employerLocationId,
        skillId,
        week: view === 'week' ? week : null,
        isLive: 'plan',
        date: view === 'day' ? moment(date).format('YYYY-MM-DD') : null,
      },
    },
  });

  const { data = [], meta } = normalizeIndexResponse(res);

  const rotaTotals = {
    cost: meta?.statistical?.cost,
    hours: meta?.statistical?.hours,
  };

  const listShifts = data.map((item) => ({
    ...item,
    start: new Date(moment(item.start).format('YYYY-MM-DDTHH:mm:ssZ')),
    end: new Date(moment(item.end).format('YYYY-MM-DDTHH:mm:ssZ')),
    contracted: item.numberOfEmployees,
    weekRequiredPeople: item.numberOfRequired,
  }));

  const statistical = meta?.statistical?.statistical.map((item) => ({
    numberOfEmployees: item.number_employees,
    dateKey: moment(item.date_on).format('DD/MM/YYYY'),
    countRota:
      listShifts?.filter((e) => e.weekDay === moment(item.date_on).weekday())
        .length ?? 0,
  }));

  return {
    data: listShifts,
    statistical,
    rotaTotals,
  };
};

export default getBuildRota;
