import React, { useMemo, useState } from 'react';

import { Button, Collapse, Form, message, Row, Spin } from 'antd';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { ModalConfirm } from '@/components';
import { EXPIRED_VISA_DAYS } from '@/constants';
import { useCheckUkIreland } from '@/features/nationality/hooks';
import { useFetch } from '@/hooks';
import { getSeekerProfile } from '@/services/seeker/getSeekerProfile';
import { updateSeekerProfile } from '@/services/seeker/updateSeekerProfile';
import { getDateDiffInDays } from '@/utils/date';
import { checkExistEmploymentOrCV } from '@/utils/seeker';
import useModal from '@/utils/useModal';

import CommentAndStatus from './CommentAndStatusUpdate';
import ContactInformation from './ContactInformation';
import SeekerProfileContext from './context';
import { convertData } from './convertDataSeeker';
import LicencesAndDocs from './LicencesAndDocs';
import PastWorkDetails from './PastWorkDetails';
import MedicalInformation from './ReferencesAndMedicalInformation';
import SetRotaAndWorkInformation from './SetRotaAndWorkInformation';
import TrustedAndBlocked from './TrustedAndBlockedSeeker';
import WorkerPayrollAndAWRInformation from './WorkerPayrollAndAWRInformation';

const { Panel } = Collapse;
const panels = [
  {
    component: <ContactInformation />,
    header: 'Contact Information',
  },
  {
    component: <PastWorkDetails />,
    header: 'Past Work Details',
  },
  {
    component: <LicencesAndDocs />,
    header: 'Licences and Docs',
  },
  {
    component: <SetRotaAndWorkInformation />,
    header: 'Rota, Site and Work Information',
  },
  {
    component: <WorkerPayrollAndAWRInformation />,
    header: 'Worker Payroll and AWR information',
  },
  {
    component: <TrustedAndBlocked />,
    header: 'Trusted and Blocked Providers',
  },
  {
    component: <MedicalInformation />,
    header: 'References and Medical Information',
  },
  {
    component: <CommentAndStatus />,
    header: 'Comments and Status updates',
  },
];

const SeekerProfile = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const employeeWpExpiry = Form.useWatch('employeeWpExpiry', form);
  const diff = employeeWpExpiry ? getDateDiffInDays(employeeWpExpiry) : null;
  const history = useHistory();
  const [displayEdit, showEdit, hiddenEdit] = useModal();
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const [activeKey, setActiveKey] = useState(['Contact Information']);
  const [flag, setFlag] = useState(false);
  const checkUkIreland = useCheckUkIreland();

  const { isLoading } = useFetch(
    ['seeker-profile'],
    () => {
      setFlag(false);
      return getSeekerProfile(id);
    },
    {
      onSuccess: (data = {}) => {
        const {
          vacancyApplicantAttributes: { nationality },
        } = data;
        const dataConvert = convertData(data, checkUkIreland(nationality));

        setFlag(true);

        form.setFieldsValue({
          ...data,
          ...dataConvert,
        });
      },
    },
  );

  const {
    mutate: mutateSeekerProfile,
    isLoading: isLoadingUpdate,
    isSuccess,
    reset,
  } = useMutation(
    async (seekerForm) => updateSeekerProfile({ id, seekerForm }),
    {
      onError: (error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        message.success('Update seeker successfully!');
        history.push('/seeker');
      },
    },
  );

  const handleSubmit = () => {
    mutateSeekerProfile(form);
  };

  const onChangeCollapse = (key) => setActiveKey(key);

  const handleCloseModal = () => {
    hiddenEdit();
    reset();
  };

  const value = useMemo(() => ({ flag, setFlag }), [flag]);

  return (
    <div>
      {isLoading ? (
        <Row align='center'>
          <Spin size='large' />
        </Row>
      ) : (
        <SeekerProfileContext.Provider value={value}>
          <Form
            form={form}
            onFinish={() => {
              if (!checkExistEmploymentOrCV(form)) {
                return message.warning(
                  // eslint-disable-next-line quotes
                  "Please provide Employment History OR seeker's CV",
                );
              }

              return showEdit();
            }}
            onFinishFailed={(error) => {
              const errorName = error.errorFields[0].name;

              setActiveKey([
                'Contact Information',
                'Past Work Details',
                'Licences and Docs',
                'Worker Payroll and AWR information',
                'References and Medical Information',
                'Comments and Status updates',
              ]);
              setTimeout(() => {
                form.scrollToField(errorName, {
                  skipOverflowHiddenElements: true,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 500);
            }}
            initialValues={{
              convictions: 'Yes',
              vacancyApplicantAttributes: {
                maxWeeklyHours: 20,
              },
            }}
          >
            <Collapse
              expandIconPosition='right'
              activeKey={activeKey}
              onChange={onChangeCollapse}
            >
              {panels.map((item) => (
                <Panel
                  accordion
                  forceRender
                  key={item.header}
                  header={item.header}
                >
                  {item.component}
                </Panel>
              ))}
            </Collapse>
            <div className='seeker-profile-footer'>
              <Button className='btn-cancel' onClick={showModal}>
                Cancel
              </Button>
              <Button className='btn-save' htmlType='submit'>
                Save
              </Button>
            </div>
          </Form>
          <ModalConfirm
            title='Save Updates?'
            titleSuccess='Save updates successfully'
            visible={displayEdit}
            isLoading={isLoadingUpdate}
            isSuccess={isSuccess}
            onClickYes={isSuccess ? handleCloseModal : handleSubmit}
            onClickNo={hiddenEdit}
          >
            Are you sure you want to save the updates made to the profile?
            {diff &&
              diff <= EXPIRED_VISA_DAYS &&
              'This seeker has an expired or expiring visa'}
          </ModalConfirm>

          <ModalConfirm
            title="Don't Want to Save Updates?"
            visible={isDisplayModal}
            onClickYes={() => history.push('/seeker')}
            onClickNo={hiddenModal}
          >
            Are you sure you want to leave without saving? You will lost any
            uploads and changes you have made.
          </ModalConfirm>
        </SeekerProfileContext.Provider>
      )}
    </div>
  );
};

export default SeekerProfile;
