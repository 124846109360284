const breadCrumb = [{ item: 'Jobs', to: '/jobs' }];

const STATUS_KEY = {
  notFilled: 'part_filled',
  filled: 'filled',
  posted: 'posted',
  live: 'live',
  completed: 'completed',
};

const EVENT_STATUSES = {
  posted: {
    value: 0,
    status: STATUS_KEY.posted,
    label: 'Posted',
    className: STATUS_KEY.posted,
  },
  live: {
    value: 1,
    status: STATUS_KEY.live,
    label: 'In Progress',
    className: STATUS_KEY.live,
  },
  completed: {
    value: 2,
    status: STATUS_KEY.completed,
    label: 'Completed',
    className: STATUS_KEY.completed,
  },
  filled: {
    value: 3,
    status: STATUS_KEY.filled,
    label: 'Filled',
    className: STATUS_KEY.filled,
  },
  partFilled: {
    value: 4,
    status: STATUS_KEY.notFilled,
    label: 'Part Filled',
    className: STATUS_KEY.partFilled,
  },
};
const FORM_NAME = {
  CALENDAR_FORM: 'calendarForm',
  LIST_JOBS_FORM: 'listJobsForm',
  POSTING_FORM: 'jobPostingForm',
};

export { breadCrumb, STATUS_KEY, EVENT_STATUSES, FORM_NAME };
