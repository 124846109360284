import { sumBy } from 'lodash';

import request from '@/request';
import { date } from '@/utils';
import { FormatIndexStrategy, Normalize } from '@/utils/normalizeResponse';

const endpoint = 'payments/employee_holidays';

const getPayrollHolidays = async (params) => {
  const res = await request.get(endpoint, { params });

  const normalize = new Normalize();
  const strategy = new FormatIndexStrategy(res);
  normalize.setStrategy(strategy);
  const dataFormatted = normalize.formatResponse();

  const data = (dataFormatted?.data || []).map(
    ({
      id,
      employeeId,
      name,
      startOn,
      endOn,
      numberRequestedDays,
      accruedDay,
      daysRequested,
      daysWorked,
      dailyPay,
      status,
    }) => ({
      id,
      payrollNumber: employeeId,
      name,
      accruedDays: accruedDay.toFixed(2),
      requestedDays:
        sumBy(daysRequested, (day) => day.hours) / 8 || numberRequestedDays,
      weekRequested: date.customWeekStartEndFormat(startOn),
      endOn,
      daysRequested,
      daysWorkedThisWeek: daysWorked,
      averageDailyPay: dailyPay,
      numberOfDaysHolidayToProcess: null,
      approveDeclineRequest: null,
      status,
    }),
  );

  return {
    ...dataFormatted,
    data,
  };
};

export default getPayrollHolidays;
