import React, { useState } from 'react';

import { message, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ReactComponent as AcceptedIcon } from '@/assets/icons/acceptedIcon.svg';
import { ReactComponent as AddIcon } from '@/assets/icons/addIcon.svg';
import { ReactComponent as DeclinedIcon } from '@/assets/icons/declinedIcon.svg';
import { Table, Button } from '@/components';
import { JobServices } from '@/services';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import AddSeekerModal from './AddSeekerModal';
import ModalJobSeeker from './ModalJobSeeker';
import './styles.scss';

const JobSeeker = ({
  seekers,
  loading,
  posted,
  isDisibleButton,
  refetchSeeker,
}) => {
  const formInstance = Form.useFormInstance();
  const requestedNumber = Form.useWatch('requestedNumber', formInstance);
  const [visibleModal, setVisibleModal] = useState(false);
  const [seekerId, setSeekerId] = useState(0);
  const [visibleAddModal, setVisibleAddmodal] = useState(false);
  const { jobId } = useParams();
  const acceptedSeekers = seekers.filter((item) => item.status !== 'declined');
  const seekerLength = acceptedSeekers.length;
  const requested = parseInt(requestedNumber, 10);
  const isExceedRequested = seekerLength >= requested;

  const { mutate: updateSeekerForJob } = useMutation(
    ({ jobResponseId, status }) =>
      JobServices.updateSeekerForJob(jobId, jobResponseId, status),
    {
      onError: () => message.error('Decline Seeker failure'),
      onSuccess: (status) => {
        message.success(
          status === 'accepted'
            ? 'Confirm seeker success'
            : 'Decline Seeker success',
        );
        refetchSeeker();
      },
    },
  );

  const statuses = {
    accepted: 'Confirmed',
    pending: 'Applied',
    declined: 'Declined',
  };

  const handleDeclineSeeker = (jobResponseId) => {
    updateSeekerForJob({ jobResponseId, status: 'declined' });
  };
  const handleAcceptSeeker = (jobResponseId) => {
    updateSeekerForJob({ jobResponseId, status: 'accepted' });
  };

  const columns = generateHeaderTable([
    {
      title: 'Status',
      render: (status) => statuses[status] && <span>{statuses[status]}</span>,
    },
    {
      title: 'Seeker Name',
      render: (_, dt) => (
        <Button
          className='seeker-name'
          onClick={() => {
            setSeekerId(dt.userID);
            setVisibleModal(true);
          }}
        >
          {dt.seekerName}
        </Button>
      ),
    },
    {
      title: 'Applied at:',
      render: (_, dt) =>
        dt.appliedAt && moment(dt.appliedAt).format('Do MMMM hh:mm'),
    },
    {
      title: 'Options',
      render: (status, dt) => {
        if (status === 'accepted') {
          return (
            <DeclinedIcon
              onClick={() => handleDeclineSeeker(dt.jobResponseId)}
            />
          );
        }

        if (status === 'pending') {
          return (
            <div className='icons'>
              <AcceptedIcon
                onClick={() => handleAcceptSeeker(dt.jobResponseId)}
                className='accepted-icon'
                style={{ marginRight: '15%' }}
              />
              <DeclinedIcon
                onClick={() => handleDeclineSeeker(dt.jobResponseId)}
                className='declined-icon'
              />
            </div>
          );
        }

        return null;
      },
    },
    {
      title: 'User ID',
      render: (_, record) => (
        <div className='user-id'>
          <p>{record.userID}</p>
        </div>
      ),
    },
  ]);

  return (
    <div className='job-seeker-container'>
      <div className='job-seeker-title'>
        <span className='label-text'>Seekers</span>
        <Button
          onClick={() => {
            if (isExceedRequested) {
              return message.warn(
                'You have exceeded the number of seeker requested',
              );
            }

            return setVisibleAddmodal(true);
          }}
          className='yellow-button add-seeker-btn'
          disabled={isDisibleButton || isExceedRequested}
        >
          <AddIcon className='plus-icon' />
          <span>Add Seeker</span>
        </Button>
      </div>
      <AddSeekerModal
        visibleAddModal={visibleAddModal}
        setVisibleAddmodal={setVisibleAddmodal}
        visible={visibleAddModal}
        refetchSeeker={refetchSeeker}
        seekerLength={seekerLength}
        requested={requested}
      />
      {visibleModal && seekerId && (
        <ModalJobSeeker
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          seekerId={seekerId}
        />
      )}
      <Table
        loading={loading}
        className='seeker-table'
        infoDetail={seekers}
        columns={columns}
        locale={{
          emptyText: 'No Seekers match your search criteria',
        }}
      />
    </div>
  );
};

JobSeeker.propTypes = {
  seekers: PropTypes.array,
  loading: PropTypes.bool,
  posted: PropTypes.number,
  isDisibleButton: PropTypes.bool,
  refetchSeeker: PropTypes.func,
};

JobSeeker.defaultProps = {
  seekers: [],
  loading: false,
  posted: 0,
};

export default JobSeeker;
