import request from '@/request';
import { normalizeDropdownResponse } from '@/utils/normalizeResponse';

const getWorkTypes = async () => {
  const res = await request.get('options/worker_types');
  const data = normalizeDropdownResponse(res);

  return data;
};

export default getWorkTypes;
