import { toArray } from '../array';

export function parseCertificate(certificates = []) {
  return toArray(certificates).reduce(
    (result, certificate) => {
      let arr;

      if (certificate.type === 'e-learning') {
        arr = result[0];
      } else {
        arr = result[1];
      }

      arr.push(certificate);

      return result;
    },
    [[], []],
  );
}
