/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Form } from 'antd';
import classNames from 'classnames';
import { groupBy, isEmpty } from 'lodash';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { Button, FormItem, MultipleSelect, Select } from '@/components';
import { ROTA_TYPES } from '@/constants';
import { useEmployerLocations, useProviders } from '@/hooks/dropdowns';

import './styles.scss';

const SpecificSites = () => {
  const formInstance = Form.useFormInstance();
  const siteAllocation = Form.useWatch('siteAllocation', formInstance) ?? [];

  const providerId = Form.useWatch('provider', formInstance);

  const locationIds = (Form.useWatch('location', formInstance) ?? []).filter(
    (item) => Boolean(Number(item)),
  );

  const listProviders = useProviders() ?? [];
  const locations = useEmployerLocations(providerId) ?? [];

  const handleAddSite = () => {
    const selectedProvider = listProviders.find(
      (item) => item.value === providerId,
    );
    const selectedLocations = locationIds.map((id) =>
      locations.find((item) => item.value === id),
    );
    selectedLocations.forEach((item) => {
      const index = siteAllocation.findIndex(
        (ele) =>
          ele.employerId === providerId &&
          ele.employerLocationId === item.value,
      );

      if (index >= 0) {
        siteAllocation[index]._destroy && delete siteAllocation[index]._destroy;
      } else {
        siteAllocation.push({
          employerId: selectedProvider.value,
          employerLocationId: item.value,
          employerName: selectedProvider.label,
          employerLocationName: item.label,
        });
      }
    });

    formInstance.setFieldsValue({
      location: [],
      provider: null,
      siteAllocation,
    });
  };

  const siteGroupedByProvider = groupBy(
    siteAllocation
      // eslint-disable-next-line no-underscore-dangle
      .filter((item) => !item._destroy),
    (item) => item.employerId,
  );

  const selectedTypes = Form.useWatch('selectedTypes', formInstance) ?? [];

  const handleRemoveProvider = (id) => {
    const updatedList = siteAllocation.map((item) => {
      if (item.employerId === id) {
        return {
          ...item,
          _destroy: true,
        };
      }
      return item;
    });
    formInstance.setFieldsValue({ siteAllocation: updatedList });
  };

  const handleRemoveLocation = (id) => {
    const index = siteAllocation.findIndex(
      (item) => item.employerLocationId === id,
    );
    siteAllocation.splice(index, 1, {
      ...siteAllocation[index],
      _destroy: true,
    });
    formInstance.setFieldsValue({ siteAllocation });
  };

  return (
    <>
      <div
        className='items space-top'
        hidden={!selectedTypes.includes(ROTA_TYPES[1].value)}
      >
        <FormItem name='provider' label='Provider' required>
          <Select
            options={listProviders}
            onChange={() => formInstance.setFieldsValue({ location: [] })}
          />
        </FormItem>

        <FormItem
          label='Choose Location'
          required
          name='location'
          initialValue={[]}
          valuePropName='value'
        >
          <MultipleSelect
            className='input-height'
            placeholder='Location'
            options={locations}
            name='location'
            value={locationIds}
          />
        </FormItem>
      </div>
      <div className='items space-top'>
        <Button
          className={classNames('yellow-button ')}
          hidden={!selectedTypes.includes(ROTA_TYPES[1].value)}
          onClick={() => handleAddSite()}
          disabled={isEmpty(locationIds)}
        >
          Add Sites
        </Button>
      </div>
      <div hidden={!selectedTypes.includes(ROTA_TYPES[1].value)}>
        {Object.values(siteGroupedByProvider).map((provider) => (
          <div className='items'>
            <div className='col'>
              <div className='item'>
                <span className='content'>{provider[0].employerName}</span>
                <XIconBlack
                  onClick={() => handleRemoveProvider(provider[0].employerId)}
                  className='content'
                />
              </div>
            </div>

            <div className='col'>
              {provider.map((item) => (
                <div className='item'>
                  <span className='content'>{item.employerLocationName}</span>
                  <XIconBlack
                    onClick={() =>
                      handleRemoveLocation(item.employerLocationId)
                    }
                    className='content'
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SpecificSites;
