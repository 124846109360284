import { useState } from 'react';

const INIT_PAGE = 1;
const PAGE_SIZE = 25;

const usePagination = (initPage = INIT_PAGE, pageSize = PAGE_SIZE) => {
  const [currentPage, setCurrentPage] = useState(initPage);
  const [paginationPageSize, setPaginationPageSize] = useState(pageSize);

  const handlePagination = (/** @type {number} */ page) => setCurrentPage(page);

  const handleShowSizeChange = (
    /** @type {any} */ _,
    /** @type {number} */ size,
  ) => setPaginationPageSize(size);

  return {
    currentPage,
    handlePagination,
    paginationPageSize,
    handleShowSizeChange,
    setCurrentPage,
    setPaginationPageSize,
  };
};

export default usePagination;
