import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';

import { ReactComponent as CheckedIcon } from '@/assets/icons/checkedIcon.svg';
import { ReactComponent as ClosedCircle } from '@/assets/icons/closedCircle.svg';
import { Checkbox } from '@/components';

/**
 * Generates the columns configuration for the applied table.
 *
 * @param {Object} options
 * @param {Function} options.acceptSeekers
 * @param {Array} options.appliedList
 * @param {Function} options.declineSeekers
 * @param {Array} options.removedList
 * @param {Function} options.selectAllApplied
 * @param {Function} options.selectAppliedSeekers
 * @param {Function} options.selectAllDeclined
 * @param {Function} options.selectDeclinedSeekers
 * @param {Function} options.openModalSeekerInfo
 * @param {boolean} options.isCompletedTabWatching
 * @return {Array}
 */
const appliedColumns = ({
  acceptSeekers,
  appliedList,
  declineSeekers,
  removedList,
  selectAllApplied,
  selectAppliedSeekers,
  selectAllDeclined,
  selectDeclinedSeekers,
  openModalSeekerInfo,
  isCompletedTabWatching,
}) => {
  const columns = [
    {
      dataIndex: 'employeeName',
      width: '20%',
      render: (data, record) => (
        <div>
          <span>{data}</span>
          <InfoCircleOutlined
            className='info-icon'
            onClick={() => openModalSeekerInfo(record.employeeId)}
          />
        </div>
      ),
    },
    {
      title: (
        <CheckedIcon
          onClick={() => !isCompletedTabWatching && acceptSeekers(appliedList)}
          className='icon-style'
        />
      ),
      dataIndex: 'appliedCheckbox',
      width: '4%',
      render: (_, record) => (
        <CheckedIcon
          onClick={() => !isCompletedTabWatching && acceptSeekers([record.id])}
          className='icon-style'
          color='red'
        />
      ),
    },
    {
      title: (
        <Checkbox
          onChange={(e) => selectAllApplied(e)}
          className='checkbox-item'
          disabled={isCompletedTabWatching}
        />
      ),
      dataIndex: 'appliedCheckboxAll',
      width: '4%',
      render: (_, record) => (
        <Checkbox
          checked={appliedList.includes(record.id)}
          onChange={(e) => selectAppliedSeekers(e, record)}
          className='checkbox-item'
          disabled={isCompletedTabWatching}
        />
      ),
    },
    {
      title: (
        <ClosedCircle
          className='icon-style'
          onClick={() => !isCompletedTabWatching && declineSeekers(removedList)}
        />
      ),
      dataIndex: 'removeSeeker',
      width: '3%',
      render: (_, record) => (
        <ClosedCircle
          className='icon-style'
          onClick={() => !isCompletedTabWatching && declineSeekers([record.id])}
        />
      ),
    },
    {
      title: (
        <Checkbox
          onChange={(e) => selectAllDeclined(e)}
          className='checkbox-item'
          disabled={isCompletedTabWatching}
        />
      ),
      dataIndex: 'removetAll',
      width: '5%',
      render: (_, record) => (
        <Checkbox
          onChange={(e) => selectDeclinedSeekers(e, record)}
          checked={removedList.includes(record.id)}
          className='checkbox-item'
          disabled={isCompletedTabWatching}
        />
      ),
    },
  ];
  return columns;
};

export default appliedColumns;
