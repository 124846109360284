import { useQuery } from 'react-query';

import { fetchUniforms } from '@/services/dropdownServices/getUniforms';

const useUniforms = () =>
  useQuery(['uniforms'], fetchUniforms, {
    refetchOnWindowFocus: false,
  });

export default useUniforms;
