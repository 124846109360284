import { useQuery } from 'react-query';

import { fetchNationalities } from '@/services/dropdownServices/getNationalities';

export const useNationalities = ({ ukIreland = false, select } = {}) => {
  const { data: resNationalities } = useQuery(
    [ukIreland ? ['nationalities-uk-ireland'] : ['nationalities']],
    () => fetchNationalities({ ukIreland }),
    {
      select,
      refetchOnWindowFocus: false,
    },
  );
  return { ...resNationalities };
};

export const useCheckUkIreland = () => {
  const { nationalities } = useNationalities({
    ukIreland: true,
    select: (data) => ({
      nationalities: data.nationalities.reduce((result, item) => {
        result[item.label] = true;
        result[item.value] = true;

        return result;
      }, {}),
    }),
  });

  return (nationality) => {
    if (!nationalities) {
      return false;
    }

    return !!nationalities[nationality];
  };
};
