import React from 'react';

import TimecardTable from './TimecardTable';

const HourlyTemplate = ({
  isEdit,
  panelId,
  skillName,
  seekerName,
  hourlyTemplateData,
}) =>
  hourlyTemplateData.map((timecard) => (
    <TimecardTable
      timecard={timecard}
      isEdit={isEdit}
      panelId={panelId}
      skillName={skillName}
      seekerName={seekerName}
    />
  ));

export default HourlyTemplate;
