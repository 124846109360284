import React, { useState } from 'react';

import { Select, FormItem, Input } from '@/components';
import { rulesSelect } from '@/utils/rulesInput';

import './styles.scss';
import RotaTable from '../components/RotaTable';
import { dataSource } from '../constants';

const CreateNewRota = () => {
  const [startTime, setStartTime] = useState('06:00');
  const [endTime, setEndTime] = useState('14:00');
  const options = [
    {
      label: `${startTime} - ${endTime}`,
      value: `${startTime} - ${endTime}`,
    },
    { label: 'Off', value: 'off' },
  ];

  const columns = [
    {
      title: 'Pattern',
      dataIndex: 'pattern',
      key: 'pattern',
      width: 100,
      render: (_, record, index) => (
        <>
          <FormItem
            noStyle
            name={['rotaWeeksAttributes', index, 'pattern']}
            initialValue={record.pattern}
          />
          {record.pattern}
        </>
      ),
    },
    {
      title: 'Monday',
      dataIndex: 'monday',
      key: 'monday',
      width: 100,
      render: (_, record, index) => (
        <FormItem
          name={[
            'rotaWeeksAttributes',
            index,
            'rotaShiftsAttributes',
            0,
            'dataSelect',
          ]}
          required
          rules={rulesSelect('Monday')}
        >
          <Select options={options} required />
        </FormItem>
      ),
    },
    {
      title: 'Tuesday',
      dataIndex: 'tuesday',
      key: 'tuesday',
      width: 100,
      render: (_, record, index) => (
        <FormItem
          style={{
            background: record.tuesday === 'off' ? 'red' : 'inherit', // Change background color based on selected option
          }}
          name={[
            'rotaWeeksAttributes',
            index,
            'rotaShiftsAttributes',
            1,
            'dataSelect',
          ]}
          required
          rules={rulesSelect('Tuesday')}
        >
          <Select options={options} required />
        </FormItem>
      ),
    },
    {
      title: 'Wednesday',
      dataIndex: 'wednesday',
      key: 'wednesday',
      width: 100,
      render: (_, record, index) => (
        <FormItem
          name={[
            'rotaWeeksAttributes',
            index,
            'rotaShiftsAttributes',
            2,
            'dataSelect',
          ]}
          required
          rules={rulesSelect('Wednesday')}
        >
          <Select options={options} required />
        </FormItem>
      ),
    },
    {
      title: 'Thursday',
      dataIndex: 'thursday',
      key: 'thursday',
      width: 100,
      render: (_, record, index) => (
        <FormItem
          name={[
            'rotaWeeksAttributes',
            index,
            'rotaShiftsAttributes',
            3,
            'dataSelect',
          ]}
          required
          rules={rulesSelect('Thursday')}
        >
          <Select options={options} required />
        </FormItem>
      ),
    },
    {
      title: 'Friday',
      dataIndex: 'friday',
      key: 'friday',
      width: 100,
      render: (_, record, index) => (
        <FormItem
          name={[
            'rotaWeeksAttributes',
            index,
            'rotaShiftsAttributes',
            4,
            'dataSelect',
          ]}
          required
          rules={rulesSelect('Friday')}
        >
          <Select options={options} required />
        </FormItem>
      ),
    },
    {
      title: 'Saturday',
      dataIndex: 'saturday',
      key: 'saturday',
      width: 100,
      render: (_, record, index) => (
        <FormItem
          name={[
            'rotaWeeksAttributes',
            index,
            'rotaShiftsAttributes',
            5,
            'dataSelect',
          ]}
          required
          rules={rulesSelect('Saturday')}
        >
          <Select options={options} required />
        </FormItem>
      ),
    },
    {
      title: 'Sunday',
      dataIndex: 'sunday',
      key: 'sunday',
      width: 100,
      render: (_, record, index) => (
        <FormItem
          name={[
            'rotaWeeksAttributes',
            index,
            'rotaShiftsAttributes',
            6,
            'dataSelect',
          ]}
          required
          rules={rulesSelect(['Sunday'])}
        >
          <Select options={options} required />
        </FormItem>
      ),
    },
    {
      title: 'Target Rota',
      dataIndex: 'targetrota',
      key: 'targetrota',
      width: 100,
      render: (_, record, index) => (
        <FormItem name={['rotaWeeksAttributes', index, 'numberTargetRotas']}>
          <Input
            style={{ border: 'none' }}
            type='number'
            defaultValue={record.targetrota}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
          />
        </FormItem>
      ),
    },
  ];

  return (
    <RotaTable
      columns={columns}
      dataSource={dataSource}
      headerText='Create New Worker Rota'
      cancelButton='Cancel'
      createButton='Create'
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      startTime={startTime}
      endTime={endTime}
    />
  );
};

export default CreateNewRota;
