import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Breadcrumb, Button } from '@/components';

import './styles.scss';

const ProviderTemplate = ({
  className,
  data,
  headerText,
  hasAddButton,
  buttonClassName,
  children,
  subTemplateClassName,
  buttonContent,
  buttonLink,
}) => (
  <div className={classNames('provider-template-container', className)}>
    <Breadcrumb
      className='provider-breadcrumb'
      mainItem='Provider'
      data={data}
    />
    <div className='sub-template-container'>
      <div className='sub-template-header'>
        <p className='header-text'>{headerText}</p>
        {hasAddButton && (
          <Link className='create-button' to={buttonLink}>
            <Button
              className={classNames('yellow-button', {
                buttonClassName,
              })}
            >
              <p className='plus-icon'>+</p>
              <p className='button-content'>{buttonContent}</p>
            </Button>
          </Link>
        )}
      </div>
      <div className={classNames('sub-template-body', subTemplateClassName)}>
        {children}
      </div>
    </div>

    {/* 
      // TODO: change href text.
    */}
    <footer className='common-footer'>
      <div className='copyright'>2021 (c) - GIG</div>
      <div>
        <Link to='/about'>About</Link>
        <Link to='/privacy'>Privacy</Link>
        <Link to='/terms-and-conditions-agreement'>T&amp;C</Link>
      </div>
    </footer>
  </div>
);

ProviderTemplate.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  subTemplateClassName: PropTypes.string,
  buttonContent: PropTypes.string,
  data: PropTypes.array,
  headerText: PropTypes.string,
  buttonLink: PropTypes.string,
  hasAddButton: PropTypes.bool,
  children: PropTypes.node,
};

ProviderTemplate.defaultProps = {
  className: '',
  buttonClassName: '',
  subTemplateClassName: '',
  buttonContent: '',
  data: [],
  headerText: '',
  buttonLink: '',
  hasAddButton: true,
};

export default ProviderTemplate;
