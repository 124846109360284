import { useEffect, useState } from 'react';

import { isEmpty, keyBy } from 'lodash';

import { JobServices, TimesheetService } from '@/services';

import useFetch from './useFetch';

const usePayCharge = ({
  /** @type {string | number} */ id,
  /** @type {import('antd').FormInstance} */ form,
  /** @type {'timesheet' | 'payroll'} */ resource = 'timesheet',
  /** @type {string | number} */ employerId,
}) => {
  const [listPayCharge, setListPayCharge] = useState([]);
  const {
    data: dataListPayCharge,
    refetch,
    isFetching: fetchPayCharge,
  } = useFetch(
    ['payCharge', id, resource],
    () => TimesheetService.getTimesheetPositions({ id, resource }),
    {
      onSuccess: (data) => {
        refetchGetRateCardProvider();
        setListPayCharge(data);

        if (form) {
          const groupedIds = keyBy(data, 'id');
          form.setFields([
            {
              name: ['payRate'],
              value: groupedIds,
            },
          ]);
        }
      },
    },
  );

  const { data: providerRateCard, refetch: refetchGetRateCardProvider } =
    useFetch(
      ['get-charge-rate-by-provider-id', dataListPayCharge, employerId],
      () => {
        if (employerId) return JobServices.calculateChargeRate(employerId);
        return {};
      },
    );

  useEffect(() => {
    if (isEmpty(listPayCharge)) setListPayCharge(dataListPayCharge);
  }, [dataListPayCharge, listPayCharge]);

  return {
    listPayCharge,
    fetchPayCharge,
    providerRateCard,
    refetch,
    setListPayCharge,
  };
};

export default usePayCharge;
