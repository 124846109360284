import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

export async function login({ email, password }) {
  try {
    const res = await request.post('/users/login', {
      user: {
        email,
        password,
      },
    });
    const data = normalizeShowResponse(res);

    return data?.data;
  } catch (error) {
    throw new ServiceError({
      message: 'Unauthorize!',
      details: ['Invalid email or password'],
    });
  }
}
