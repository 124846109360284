import request from '@/request';

const invoiceSendMail = async ({ invoiceIds, receiverType }) => {
  const payload = {
    invoiceIds,
    receiverType,
  };

  const response = request.patch(
    'trackers/invoicing/invoices/send_email',
    payload,
  );

  return response;
};

const sendToAccounts = (invoiceIds) =>
  invoiceSendMail({ invoiceIds, receiverType: 'accounts' });

const sendToClient = (invoiceIds) =>
  invoiceSendMail({ invoiceIds, receiverType: 'employer' });

const InvoiceSendMail = {
  toAccounts: sendToAccounts,
  toClient: sendToClient,
};

export default InvoiceSendMail;
