import { ORDER_BY, ORDER_DIRECTION } from '@/constants';
import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

import { getAddressInfomation } from './getAddressInfomation';

const DEFAULT_PAGE_SIZE = 25;

export async function getSeekers({
  sortOrder,
  pageNumber,
  pageSize,
  ...filter
}) {
  const page = {
    size: pageSize,
    number: pageNumber,
  };

  const order = {
    orderBy: ORDER_BY,
    orderDirection: sortOrder || ORDER_DIRECTION,
  };

  const { employeePostCode } = filter;

  const geogecode = employeePostCode
    ? await getAddressInfomation(`${employeePostCode}-uk`)
    : [];

  filter = {
    ...filter,
    latitude: geogecode?.[0]?.geometry?.location?.lat,
    longitude: geogecode?.[0]?.geometry?.location?.lng,
    employeePostCode: geogecode?.[0]?.address_components?.[0]?.long_name,
  };

  const endpoint = '/seekers/employees';
  const res = await request.get(endpoint, {
    params: {
      page,
      filter,
      order,
    },
  });

  const data = normalizeIndexResponse(res);
  const convertData = data?.data?.map((item) => ({
    ...item,
    id: item.employeeId,
  }));
  const parseData = {
    ...data,
    data: convertData,
  };
  return parseData;
}

export async function searchSeekerJobPosting({
  pageSize = DEFAULT_PAGE_SIZE,
  ...filter
}) {
  const endpoint = '/seekers/employees/search';
  const res = await request.get(endpoint, {
    params: {
      filter,
    },
  });

  const { data } = normalizeIndexResponse(res);

  return data;
}
