import React, { useState } from 'react';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import autocompletePostCode from '@/services/postcodeUK/autocomplete';

import AutoComplete from '../AutoComplete';

const PostCodeUK = ({ value = '', onChange, disabled }) => {
  const [postCode, setPostCode] = useState('');

  const { data: listPostCode = [], refetch: refetchPostcode } = useQuery(
    ['getPostcode', postCode],
    () => autocompletePostCode(postCode),
    { refetchOnWindowFocus: false, enabled: false },
  );
  const handleSearchPostcode = debounce(refetchPostcode, 500);

  const handleChange = (values) => {
    onChange?.(values);
    setPostCode(values);
  };

  return (
    <AutoComplete
      value={value}
      disabled={disabled}
      options={listPostCode}
      onSearch={handleSearchPostcode}
      placeholder='Post Code'
      onChange={handleChange}
    />
  );
};

export default PostCodeUK;

PostCodeUK.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
