import React from 'react';

import { Checkbox, Table } from 'antd';
import PropTypes from 'prop-types';

import { FormItem } from '@/components';

const CheckBoxCell = ({ panelId, timecard }) => {
  const name = [`${panelId}`, 'timecards', `${timecard.timecardId}`, 'select'];

  return (
    <Table.Summary.Cell className='checkbox-cell'>
      <FormItem name={name} initialValue={false} valuePropName='checked'>
        <Checkbox />
      </FormItem>
    </Table.Summary.Cell>
  );
};

export default CheckBoxCell;

CheckBoxCell.propTypes = {
  panelId: PropTypes.number,
  timecard: PropTypes.object,
};

CheckBoxCell.defaultProps = {
  panelId: null,

  timecard: {},
};
