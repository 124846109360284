import request from '@/request';

const getCountTimeSheetByWeek = async (year, week) => {
  const endpoint = 'trackers/timekeeping/timesheets/payment_type_size';
  const res = await request.get(endpoint, {
    params: {
      filter: {
        year,
        week_num: week,
      },
    },
  });
  const { data } = res;

  return data?.data;
};

export default getCountTimeSheetByWeek;
