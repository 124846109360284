/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { Image as AntdImage, Tag } from 'antd';
import PropTypes from 'prop-types';

import InfoIcon from '@/assets/icons/infoIcon.png';
import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import defaultAvatar from '@/assets/images/defaultAvatar.png';

import './styles.scss';

const SeekerSelected = ({ seeker, onClose, disabled, openModalSeekerInfo }) => {
  const name =
    seeker.employeeFullname ??
    `${seeker.employeeFname} ${seeker.employeeSname}`;
  return (
    <Tag
      closable
      closeIcon={<XIconBlack />}
      className='seeker-selected'
      onClose={() => (disabled ? null : onClose(seeker))}
    >
      <div className='seeker-field'>
        <AntdImage
          className='avatar-seeker'
          src={seeker.profilepic ?? defaultAvatar}
          preview={false}
        />
        <div className='info-seeker'>
          <div className='section-top-seeker'>
            <span>{name}</span>
            <img
              src={InfoIcon}
              alt='info-icon'
              className='info-icon'
              onClick={() => openModalSeekerInfo(seeker.employeeId)}
            />
          </div>
          <div>
            {(seeker.certificates || []).reduce(
              (result, { type, icon }, index) => {
                if (!icon) {
                  return result;
                }

                result.push(
                  <img key={index} width={20} alt={type} src={icon} />,
                );

                return result;
              },
              [],
            )}
          </div>
        </div>
      </div>
    </Tag>
  );
};

SeekerSelected.propTypes = {
  seeker: PropTypes.object,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  openModalSeekerInfo: PropTypes.func,
};

SeekerSelected.defaultProps = {
  seeker: {},
  disabled: false,
  openModalSeekerInfo: () => {},
};

export default SeekerSelected;
