import React from 'react';

import { Form, Typography } from 'antd';
import PropTypes from 'prop-types';

import { FormItem, Input } from '@/components';
import { LIMIT_INPUT } from '@/constants';
import { rulesEmail, rulesPhone, rulesTextInput } from '@/utils/rulesInput';

import { contactTypes } from './constants';
import ContactDetails from './ContactDetails';
import './styles.scss';

const { Text } = Typography;

const RightSection = ({ isHidden }) => {
  const formInstance = Form.useFormInstance();

  const onChangeMainContact = () => {
    formInstance.setFields([
      {
        name: contactTypes[0].fieldName,
        errors: [],
      },
    ]);
  };

  return (
    <div className='wrapper-container--right'>
      <div
        className='input-group input-group__border'
        hidden={isHidden(contactTypes[0])}
      >
        <FormItem
          name='contactName'
          className='form-item form-item--full-width'
        >
          <Text className='center-wrapper-label'>
            {formInstance.getFieldValue('contactName')}
          </Text>
        </FormItem>

        <div className='center-wrapper-center'>
          <div
            className='center-wrapper-center--left'
            hidden={isHidden(contactTypes[0])}
          >
            <FormItem
              name='contactName'
              label='Name'
              className='form-item form-item--full-width'
              rules={rulesTextInput('Name')}
              required
            >
              <Input placeholder='Name' onChange={onChangeMainContact} />
            </FormItem>
            <FormItem
              name='contactJobTitle'
              className='form-item form-item--full-width'
              label='Job Title'
            >
              <Input placeholder='Job Title' />
            </FormItem>
            <FormItem
              name='contactPhone'
              className='form-item form-item--full-width'
              label='Phone number'
              rules={rulesPhone(true)}
              required
            >
              <Input
                placeholder='Phone Number'
                maxLength={LIMIT_INPUT.phoneNumber}
                onChange={onChangeMainContact}
              />
            </FormItem>
          </div>
          <div
            className='center-wrapper-center--right'
            hidden={isHidden(contactTypes[0])}
          >
            <FormItem
              name='contactEmail'
              className='form-item form-item--full-width'
              label='Email'
              rules={rulesEmail()}
              required
            >
              <Input placeholder='Email' onChange={onChangeMainContact} />
            </FormItem>
            <FormItem
              name='contactDepartment'
              className='form-item form-item--full-width'
              label='Department'
            >
              <Input placeholder='Department' />
            </FormItem>
          </div>
        </div>
      </div>

      {contactTypes.slice(1).map((type) => (
        <ContactDetails isHidden={isHidden} type={type} />
      ))}
    </div>
  );
};

RightSection.propTypes = {
  isHidden: PropTypes.func,
};

export default RightSection;
