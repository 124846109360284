// Hard code data
const hardCodeRolesAppliedTo = [
  { label: 'Warehoouse Operative', value: 'Warehoouse Operative' },
  { label: 'Waite', value: 'Waite' },
  { label: 'Che', value: 'Che' },
  {
    label: 'Events Staff',
    value: 'Events Staff',
  },
  {
    label: 'Customer Care Steward',
    value: 'Customer Care Steward',
  },
  { label: 'Bartende', value: 'Bartende' },
  { label: 'Retail Assistan', value: 'Retail Assistan' },
  { label: 'Kitchen Porte', value: 'Kitchen Porte' },
  { label: 'Cleaner / Room Attendant', value: 'Cleaner / Room Attendant' },
  { label: 'Other', value: 'Other' },
];

const hardCodeGenders = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
];

const hardCodeStatus = [
  { label: 'Active', value: 'Active' },
  { label: 'Blocked', value: 'Blocked' },
];

const hardCodeLocation = [
  { label: 'London and the South East', value: 'London and the South East' },
  {
    label: 'The Midlands inc Northamptonshire and Staffordshire',
    value: 'The Midlands inc Northamptonshire and Staffordshire',
  },
  {
    label: 'The North West Inc Manchester and Liverpool  ',
    value: 'The North West Inc Manchester and Liverpool  ',
  },
  { label: 'Scotland', value: 'Scotland' },
];

const hardcodeClientWorded = [
  { label: 'iForce Corby', value: 'iForce Corby' },
  { label: 'iForce Rugby', value: 'iForce Rugby' },
  { label: 'iForce Redditch', value: 'iForce Redditch' },
  { label: 'iForce Eurohub', value: 'iForce Eurohub' },
  { label: 'iHotel', value: 'iHotel' },
  { label: 'Wincanton for Waitrose', value: 'Wincanton for Waitrose' },
  { label: 'Wincanton', value: 'Wincanton' },
];

const useHardCodeSeekers = () => {
  const hardCodeTimeSheets = [
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
    {
      provider: 'Hilton London',
      location: 'Hilton Regent St',
      week: 11,
      weekStart: '14/03/2022',
      weekEnd: '21/03/2022',
      totalPay: 1234,
      lastEditedBy: 'Amin Halchoi',
      totalHours: 16,
    },
  ];

  const timesheetDataTable = [
    {
      month1: 315,
      month2: 250,
      month3: 250,
      month4: 250,
      month5: 250,
      month6: 250,
      month7: 250,
      month8: 250,
      month9: 250,
      month10: null,
      month11: null,
      month12: null,
      total: 1985.75,
    },
    {
      month1: 12,
      month2: 12,
      month3: 12,
      month4: 12,
      month5: 12,
      month6: 12,
      month7: 12,
      month8: 12,
      month9: 12,
      month10: 12,
      month11: 12,
      month12: 12,
      total: 11.5,
    },
    {
      month1: '£10.5',
      month2: '£10.5',
      month3: '£10.5',
      month4: '£10.5',
      month5: '£10.5',
      month6: '£10.5',
      month7: '£10.5',
      month8: '£10.5',
      month9: '£10.5',
      month10: '£10.5',
      month11: '£10.5',
      month12: '£10.5',
      total: '£10.5',
    },
    {
      month1: null,
      month2: null,
      month3: null,
      month4: null,
      month5: null,
      month6: null,
      month7: null,
      month8: null,
      month9: null,
      month10: null,
      month11: null,
      month12: null,
      total: '£122',
    },
    {
      month1: 2.33,
      month2: 2.33,
      month3: 2.33,
      month4: 2.33,
      month5: 2.33,
      month6: 2.33,
      month7: 2.33,
      month8: 2.33,
      month9: 2.33,
      month10: 2.33,
      month11: 2.33,
      month12: 2.33,
      total: '14.68 days',
    },
    {
      month1: 0.17,
      month2: 0.17,
      month3: 0.17,
      month4: 0.17,
      month5: 0.17,
      month6: 0.17,
      month7: 0.17,
      month8: 0.17,
      month9: 0.17,
      month10: 0.17,
      month11: 0.17,
      month12: 0.17,
      total: '14.68 days',
    },
    {
      month1: 0,
      month2: 0,
      month3: 0,
      month4: 0,
      month5: 4,
      month6: 0,
      month7: 0,
      month8: 0,
      month9: 3,
      month10: 0,
      month11: 5,
      month12: 0,
      total: '12 days',
    },
  ];

  const payrollData1 = [
    {
      weekNumber: 1,
      payrollWages: 7975,
    },
    {
      weekNumber: 2,
      payrollWages: 7975,
    },
    {
      weekNumber: 3,
      payrollWages: 7975,
    },
    {
      weekNumber: 4,
      payrollWages: 7975,
    },
    {
      weekNumber: 5,
      payrollWages: 7975,
    },
    {
      weekNumber: 6,
      payrollWages: 7975,
    },
    {
      weekNumber: 7,
      payrollWages: 7975,
    },
    {
      weekNumber: 8,
      payrollWages: 7975,
    },
    {
      weekNumber: 9,
      payrollWages: 7975,
    },
    {
      weekNumber: 10,
      payrollWages: 7975,
    },
    {
      weekNumber: 11,
      payrollWages: 7975,
    },
    {
      weekNumber: 12,
      payrollWages: 7975,
    },
    {
      weekNumber: 13,
      payrollWages: 7975,
    },
    {
      weekNumber: 14,
      payrollWages: 7975,
    },
    {
      weekNumber: 15,
      payrollWages: 7975,
    },
    {
      weekNumber: 16,
      payrollWages: 7975,
    },
    {
      weekNumber: 17,
      payrollWages: 7975,
    },
    {
      weekNumber: 18,
      payrollWages: 7975,
    },
  ];

  const payrollData2 = [
    {
      weekNumber: 19,
      payrollWages: 7975,
    },
    {
      weekNumber: 20,
      payrollWages: 7975,
    },
    {
      weekNumber: 21,
      payrollWages: 7975,
    },
    {
      weekNumber: 22,
      payrollWages: 7975,
    },
    {
      weekNumber: 23,
      payrollWages: 7975,
    },
    {
      weekNumber: 24,
      payrollWages: 7975,
    },
    {
      weekNumber: 25,
      payrollWages: 7975,
    },
    {
      weekNumber: 26,
      payrollWages: 7975,
    },
    {
      weekNumber: 27,
      payrollWages: 7975,
    },
    {
      weekNumber: 28,
      payrollWages: 7975,
    },
    {
      weekNumber: 29,
      payrollWages: 7975,
    },
    {
      weekNumber: 30,
      payrollWages: 7975,
    },
    {
      weekNumber: 31,
      payrollWages: 7975,
    },
    {
      weekNumber: 32,
      payrollWages: 7975,
    },
    {
      weekNumber: 33,
      payrollWages: 7975,
    },
    {
      weekNumber: 34,
      payrollWages: 7975,
    },
    {
      weekNumber: 35,
      payrollWages: 7975,
    },
    {
      weekNumber: 36,
      payrollWages: 7975,
    },
  ];

  const payrollData3 = [
    {
      weekNumber: 37,
      payrollWages: 7975,
    },
    {
      weekNumber: 38,
      payrollWages: 7975,
    },
    {
      weekNumber: 39,
      payrollWages: 7975,
    },
    {
      weekNumber: 40,
      payrollWages: 7975,
    },
    {
      weekNumber: 41,
      payrollWages: 7975,
    },
    {
      weekNumber: 42,
      payrollWages: 7975,
    },
    {
      weekNumber: 43,
      payrollWages: 7975,
    },
    {
      weekNumber: 44,
      payrollWages: 7975,
    },
    {
      weekNumber: 45,
      payrollWages: 7975,
    },
    {
      weekNumber: 46,
      payrollWages: 7975,
    },
    {
      weekNumber: 47,
      payrollWages: 7975,
    },
    {
      weekNumber: 48,
      payrollWages: 7975,
    },
    {
      weekNumber: 49,
      payrollWages: 7975,
    },
    {
      weekNumber: 50,
      payrollWages: 7975,
    },
    {
      weekNumber: 51,
      payrollWages: 7975,
    },
    {
      weekNumber: 52,
      payrollWages: 7975,
    },
  ];

  const timesheets = hardCodeTimeSheets;
  const timesheetTable = timesheetDataTable;
  const payroll1 = payrollData1;
  const payroll2 = payrollData2;
  const payroll3 = payrollData3;

  return {
    timesheets,
    timesheetTable,
    payroll1,
    payroll2,
    payroll3,
  };
};

const createObjectListZips = (listZips = []) =>
  listZips.map((zip) => ({ label: zip, value: zip })) || [];

export {
  hardCodeRolesAppliedTo,
  hardCodeGenders,
  hardCodeStatus,
  hardCodeLocation,
  hardcodeClientWorded,
  useHardCodeSeekers,
  createObjectListZips,
};
