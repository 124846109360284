import { camelizeKeys } from 'humps';
import {
  first,
  last,
  isArray,
  min,
  max,
  map,
  sortBy,
  uniqWith,
  isEqual,
  head,
  size,
} from 'lodash';
import moment from 'moment';

import { DATE_FORMAT, PAYMENT_TYPE, RESOURCE_TYPE } from '@/constants';
import { TYPE_SHIFTS } from '@/pages/Jobs/JobModal/constant';
import request from '@/request';
import { array, date } from '@/utils';
import { convertTimeMoment } from '@/utils/convertTimeMoment';
import { getDuration } from '@/utils/date';
import { CustomError, ServiceError } from '@/utils/errorUtil';
import Formatter from '@/utils/Formatter';
import {
  normalizeIndexResponse,
  normalizeShowResponse,
} from '@/utils/normalizeResponse';
import { formatResponse } from '@/utils/thunkUtil';

export const chargeRatesFilterByEmployerId = async (providerId) => {
  const res = await request.get('trackers/timekeeping/charge_rates', {
    params: {
      filter: {
        employerId: isArray(providerId) ? providerId : [providerId],
      },
    },
  });

  const { data } = normalizeShowResponse(res);

  return data;
};

export const updatePromote = ({ jobId, isPromoted }) =>
  request.patch(`jobs/positions/${jobId}`, {
    position: {
      isPromoted,
    },
  });

export const getJobsOfProvider = async ({ providerId, filter, page }) => {
  try {
    const response = await request.get(
      `/providers/employers/${providerId}/positions`,
      {
        params: { filter, page },
      },
    );

    const jobs = formatResponse(response, { endpoint: 'position' });

    const { currentPage, totalPage, totalCount } =
      Formatter.mergeMeta(jobs.jobs, 'position', jobs.meta)?.position ?? {};

    const { data } = Formatter.buildEndpoint(jobs.endpoint, jobs, 'position');

    const jobInfo = data.map(
      ({
        id,
        providerName,
        locationName,
        skillName,
        description,
        datePosted,
        payrate,
        chargeRate,
        breakTime,
        schedule: { items },
      }) => {
        const start = first(items);
        const end = last(items);
        const mStartAt = moment.unix(start.startAt);
        const mEndAt = moment.unix(end.endAt);

        const startDate = mStartAt.format(DATE_FORMAT);
        const endDate = mEndAt.format(DATE_FORMAT);

        let dateData = startDate;
        let timeData = `${mStartAt.format('HH:MM')} - ${mEndAt.format(
          'HH:MM',
        )}`;

        if (startDate !== endDate) {
          dateData = `${startDate} - ${endDate}`;
          timeData = '';
        }

        // Convert timestamp to string date
        const getFullDate = date.toDateTimeItems(items);

        // Calculate duration of each item
        const durationList = getFullDate.map((fdate) => {
          const { fullStartDate, fullEndDate } = fdate;

          const duration =
            moment(fullEndDate).diff(moment(fullStartDate), 'hours') -
            +breakTime / 60;

          return duration;
        });

        // Sum up all duration of a job
        const hours = durationList.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
        );

        return {
          key: id,
          provider: providerName,
          location: locationName,
          jobTypes: skillName,
          jobDescriptions: description,
          datePosted: moment(datePosted).format(DATE_FORMAT),
          chargeRate,
          payRate: payrate,
          shift: { date: dateData, time: timeData },
          hours,
        };
      },
    );

    return { jobInfo, totalPage, currentPage, totalCount };
  } catch (error) {
    throw new ServiceError(error);
  }
};

const formatTimestamp = ({ timestamp, format = DATE_FORMAT }) =>
  moment.unix(timestamp).format(format);

const HOURS_FORMAT = 'HH:mm';

const checkNextDay = ({ startDate, endDate }) => {
  const momentStartAt = moment(startDate, DATE_FORMAT);
  const momentEndAt = moment(endDate, DATE_FORMAT);
  return momentEndAt.diff(momentStartAt, 'days') === 1;
};

/**
 * @typedef {Object} ShiftTimesProps
 * @prop {number} startAt Number represent in timestamp
 * @prop {number} endAt Number represent in timestamp
 */

/**
 * @param {Array<ShiftTimesProps>} shiftTimes
 */
const prepareShiftTime = (shiftTimes) => {
  const listTimeFormatted = map(shiftTimes, ({ startAt, endAt }) => ({
    startAt: formatTimestamp({ timestamp: startAt, format: HOURS_FORMAT }),
    endAt: formatTimestamp({ timestamp: endAt, format: HOURS_FORMAT }),
  }));
  const uniqueShiftTimes = uniqWith(listTimeFormatted, isEqual);
  if (uniqueShiftTimes.length > 1) {
    return 'Various time';
  }
  const headSchedule = head(uniqueShiftTimes);
  return `${headSchedule?.startAt} - ${headSchedule?.endAt}`;
};

export const processDate = ({ startAt, endAt, schedule }) => {
  const startDate = formatTimestamp({ timestamp: startAt });
  const endDate = formatTimestamp({ timestamp: endAt });
  const timeData = prepareShiftTime(schedule);

  const isNotEqual = startDate !== endDate;
  const isNextDay = checkNextDay({ startDate, endDate }) && size(schedule) < 2;
  const shiftDateAndTime = {
    date: { startDate, ...(isNotEqual && { endDate, isNextDay }) },
    time: timeData,
  };
  return shiftDateAndTime;
};

// GET JOBS
export const getJobs = async (params, signal) => {
  try {
    const endpoint = '/jobs/positions';
    const res = await request.get(endpoint, { params, signal });

    const jobs = formatResponse(res, { endpoint });

    const { totalPage, currentPage, totalCount } = Formatter.mergeMeta(
      jobs.jobs,
      endpoint,
      jobs.meta,
    )[endpoint];

    const { data } = Formatter.buildEndpoint(
      jobs.endpoint,
      jobs,
      RESOURCE_TYPE.job,
    );

    const jobInfo = data.map((dt) => {
      const {
        id,
        providerName,
        locationName,
        jobRole,
        createdAt,
        payrate,
        chargeRate,
        hours,
        schedule: { items },
        applied,
        totalFills,
        isPromoted,
        status,
        totalAssignments,
        declined,
      } = dt;
      const startAtArray = map(items, 'startAt');
      const endAtArray = map(items, 'endAt');

      const minStartDate = min(startAtArray);
      const maxEndDate = max(endAtArray);

      const shiftDateAndTime = processDate({
        startAt: minStartDate,
        endAt: maxEndDate,
        schedule: items,
      });

      return {
        ...dt,
        key: id,
        provider: providerName,
        location: locationName,
        jobRole,
        datePosted: moment(createdAt).format(DATE_FORMAT),
        payRate: payrate,
        chargeRate,
        hours,
        shiftDateAndTime,
        applied,
        status,
        filled: totalFills,
        isPromoted,
        totalAssignments,
        declined,
        createdAt,
      };
    });

    return { jobInfo, totalPage, currentPage, totalCount };
  } catch (error) {
    throw new ServiceError(error);
  }
};

// GET JOB ID
export const getJobId = async (jobId) => {
  try {
    const endpoint = `/jobs/positions/${jobId}`;
    const res = await request.get(endpoint);

    const job = formatResponse(res, { endpoint });

    const { data = [] } = Formatter.buildEndpoint(
      job.endpoint,
      job,
      RESOURCE_TYPE.updateJob,
    );

    const jobInfo = data[0] ?? {};
    const {
      providerId,
      skillId,
      createdAt,
      schedule,
      totalAssignments,
      posted,
      positionsUniforms,
      ...resData
    } = jobInfo;
    const StyleUniforms = {
      1: 'shirt',
      2: 'jean',
      3: 'shoes',
    };
    const uniform = positionsUniforms.reduce((re, item) => {
      re[StyleUniforms[item.uniform.styleId]] =
        re[StyleUniforms[item.uniform.styleId]] || [];
      re[StyleUniforms[item.uniform.styleId]].push(item.uniformId);
      return re;
    }, {});
    uniform.listAdditionalUniforms = uniform[undefined];
    delete uniform[undefined];
    const uniformIds = positionsUniforms.map((item) => item.id);

    const items = schedule?.items?.map((item) => {
      const { startAt, endAt } = item;
      const mStartAt = moment.unix(startAt);
      const mEndAt = moment.unix(endAt);

      const startDate = mStartAt.format('DD MMM YY');
      const startTime = convertTimeMoment(startDate, mStartAt.format('HH:mm'));
      const fullStartDate = mStartAt.format('YYYY-MM-DD HH:mm');

      const endDate = mEndAt.format('DD MMM YY');
      const endTime = convertTimeMoment(endDate, mEndAt.format('HH:mm'));
      const fullEndDate = mEndAt.format('YYYY-MM-DD HH:mm');

      const duration = getDuration(fullStartDate, fullEndDate);

      return {
        startDate,
        startTime,
        endDate,
        endTime,
        duration,
        fullStartDate,
        fullEndDate,
      };
    });
    return {
      ...resData,
      employerId: resData?.shift.employerId,
      locationId: resData?.shift.locationId,
      skillId,
      createdAt: moment(createdAt),
      posted,
      shirt: uniform?.shirt?.[0],
      jean: uniform?.jean?.[0],
      shoe: uniform?.shoes?.[0],
      shirtId: uniformIds?.[0],
      jeanId: uniformIds?.[1],
      shoeId: uniformIds?.[2],
      items,
      additionalUniform: uniform.listAdditionalUniforms ?? [],
      isOver18: [resData?.isOver18],
      isPrivated: resData?.shift.isPrivated,
    };
  } catch (error) {
    throw new ServiceError(error);
  }
};

// DOWNLOAD CSV LIST CONFIRMED / APPLIED SEEKERS
/**
 *
 * @returns {Promise<Blob>}
 */
export const downloadSeekerListCsv = async ({
  positionId,
  dateExport,
  status,
}) => {
  const endpoint = `/jobs/positions/${positionId}/job_responses/export_xls`;
  const filter = {
    date: dateExport,
    status,
  };
  const res = await request.get(endpoint, {
    responseType: 'blob',
    params: { filter },
  });
  return res.data;
};

// CREATE JOB
export const createJob = async (newJob) => {
  try {
    const {
      jean,
      shoe,
      schedule,
      shirt,
      posted,
      skillId,
      payrate,
      isOver18,
      chargeRate,
      employerId,
      locationId,
      description,
      requestedNumber,
      specialRequirements,
      additionalUniform,
      isPrivated,
      jobResponsesAttributes,
      nudgesAttributes,
      payrollTemplate,

      allowWithoutLicences,
      shiftType,
      checkboxRepeat,
      preRequisits,
    } = newJob;

    const uniformAdditionalConverted =
      array.remove(additionalUniform, 'all')?.map((uniformId) => ({
        uniformId,
      })) ?? [];

    if (shirt) {
      uniformAdditionalConverted.push({
        uniformId: shirt,
      });
    }

    if (jean) {
      uniformAdditionalConverted.push({
        uniformId: jean,
      });
    }

    if (shoe) {
      uniformAdditionalConverted.push({
        uniformId: shoe,
      });
    }

    const isIndividualShift = shiftType === TYPE_SHIFTS.INDIVIDUAL;

    const items = schedule?.items?.map((e) => ({
      startAt: parseInt(e.startAt / 1000, 10),
      endAt: parseInt(e.endAt / 1000, 10),
      break: parseInt(e.break, 10),
      ...(isIndividualShift && { posted: e.posted }),
    }));

    const payload = {
      position: {
        ...newJob,
        paymentType: payrollTemplate
          ? PAYMENT_TYPE.payrollTemplate
          : PAYMENT_TYPE.flexiblePayRate,
        chargeRate: payrollTemplate ? 0 : chargeRate,
        payrate: payrollTemplate ? 0 : payrate,
        description,
        isOver18: isOver18 ?? false,
        isPromoted: false,
        skillId,
        requestedNumber,
        posted,
        specialRequirements,
        assignmentsCount: posted,
        schedule: {
          items,
        },

        shiftAttributes: {
          employerId,
          locationId,
          isPrivated,
        },
        positionsUniformsAttributes:
          uniformAdditionalConverted.length > 0
            ? uniformAdditionalConverted
            : undefined,

        jobResponsesAttributes: (jobResponsesAttributes ?? []).map(
          (seeker) => ({
            employeeId: seeker.employeeId,
            status: 'accepted',
          }),
        ),
        assignmentsFilled: jobResponsesAttributes.length,
        licenses: (newJob?.licenses ?? []).filter((e) => e !== 'all'),
        nudgesAttributes: (nudgesAttributes ?? []).map((seeker) => ({
          employeeId: seeker.employeeId,
        })),
        preRequisitsAttributes: preRequisits.map((preRequisit) => ({
          employerId: preRequisit.employerId,
          skillId: preRequisit.skillId,
        })),
        allowWithoutLicences,
        shiftType,
        repeatFor:
          checkboxRepeat === 1 ? parseInt(newJob.repeatFor, 10) : undefined,
        untilEndDate:
          checkboxRepeat === 2
            ? moment(newJob.untilEndDate).format('YYYY-MM-DD')
            : undefined,
      },
    };

    return await request.post('/jobs/positions', payload);
  } catch (error) {
    throw new ServiceError(error);
  }
};

// EDIT JOB
export const editJob = async (jobId, newInfo) => {
  try {
    const {
      jean,
      shoe,
      schedule,
      shirt,
      posted,
      skillId,
      payrate,
      isOver18,
      chargeRate,
      employerId,
      locationId,
      description,
      requestedNumber,
      specialRequirements,
      additionalUniform,
      isPrivated,
      jobResponsesAttributes,
      nudgesAttributes,
      allowWithoutLicences,
      shiftType,
      checkboxRepeat,
      preRequisits,
      initJobAttributes,
      initNudgesAttributes,
      initPreRequisits,
      payrollTemplate,
    } = newInfo;

    const uniformAdditionalConverted =
      array.remove(additionalUniform, 'all')?.map((uniformId) => ({
        uniformId,
      })) ?? [];

    if (shirt) {
      uniformAdditionalConverted.push({
        uniformId: shirt,
      });
    }

    if (jean) {
      uniformAdditionalConverted.push({
        uniformId: jean,
      });
    }

    if (shoe) {
      uniformAdditionalConverted.push({
        uniformId: shoe,
      });
    }

    const isIndividualShift = shiftType === TYPE_SHIFTS.INDIVIDUAL;

    let items = schedule?.items?.map((e) => ({
      startAt: parseInt(e.startAt / 1000, 10),
      endAt: parseInt(e.endAt / 1000, 10),
      break: parseInt(e.break, 10),
      ...(isIndividualShift && { posted: e.posted }),
    }));

    items = sortBy(items, ['startAt']);

    const jobResponsesConvert = jobResponsesAttributes.map((e) => {
      const initJob = initJobAttributes.find(
        (job) => job.employeeId === e.employeeId,
      );

      return !initJob
        ? {
            employeeId: e.employeeId,
            status: 'accepted',
          }
        : {
            id: initJob.id,
            employeeId: e.employeeId,
            status: 'accepted',
          };
    });

    const assignmentsFilled = jobResponsesAttributes.length;

    initJobAttributes.forEach((initJob) => {
      const { id, employeeId, status } = initJob;
      const jobResponses = jobResponsesConvert.find(
        (e) => parseInt(employeeId, 10) === e.employeeId,
      );

      if (!jobResponses) {
        if (status !== 'declined' && status !== 'pending') {
          jobResponsesConvert.push({
            id,
            _destroy: true,
          });
        } else {
          jobResponsesConvert.push({
            id,
            employeeId,
            status,
          });
        }
      }
    });

    const preRequisitsConvert = preRequisits.map((e) => {
      const initJob = initPreRequisits.find(
        (job) => job.skillId === e.skillId && job.employerId === e.employerId,
      );

      return !initJob
        ? {
            skillId: e.skillId,
            employerId: e.employerId,
          }
        : {
            // id: initJob.id,
            skillId: e.skillId,
            employerId: e.employerId,
          };
    });

    initPreRequisits.forEach((initJob) => {
      const jobResponses = preRequisitsConvert.find(
        (e) =>
          initJob.skillId === e.skillId && initJob.employerId === e.employerId,
      );

      if (!jobResponses) {
        preRequisitsConvert.push({
          id: initJob.id,
          _destroy: true,
        });
      }
    });

    const nudgesSeekerConvert = nudgesAttributes.map((e) => {
      const initJob = initNudgesAttributes.find(
        (job) => job.employeeId === e.employeeId,
      );

      return !initJob
        ? {
            employeeId: e.employeeId,
          }
        : {
            id: initJob.id,
            employeeId: e.employeeId,
          };
    });

    initNudgesAttributes.forEach((initJob) => {
      const jobResponses = nudgesSeekerConvert.find(
        (e) => parseInt(initJob.employeeId, 10) === e.employeeId,
      );

      if (!jobResponses) {
        nudgesSeekerConvert.push({
          id: initJob.id,
          _destroy: true,
        });
      }
    });

    const position = {
      ...newInfo,
      chargeRate,
      description,
      isOver18: isOver18 ?? false,
      isPromoted: false,
      payrate,
      skillId,
      requestedNumber,
      posted,
      specialRequirements,
      assignmentsFilled,
      assignmentsCount: posted,
      schedule: {
        items,
      },

      shiftAttributes: {
        employerId,
        locationId,
        isPrivated,
      },
      positionsUniformsAttributes:
        uniformAdditionalConverted.length > 0
          ? uniformAdditionalConverted
          : undefined,

      jobResponsesAttributes: jobResponsesConvert,
      licenses: (newInfo?.licenses ?? []).filter((e) => e !== 'all'),
      nudgesAttributes: nudgesSeekerConvert,

      allowWithoutLicences,
      shiftType,
      repeatFor:
        checkboxRepeat === 1 ? parseInt(newInfo.repeatFor, 10) : undefined,
      untilEndDate:
        checkboxRepeat === 2
          ? moment(newInfo.untilEndDate).format('YYYY-MM-DD')
          : undefined,
      preRequisitsAttributes: preRequisitsConvert,
      paymentType: payrollTemplate
        ? PAYMENT_TYPE.payrollTemplate
        : PAYMENT_TYPE.flexiblePayRate,
    };

    return await request.patch(`/jobs/positions/${jobId}`, { position });
  } catch (error) {
    throw new ServiceError(error);
  }
};

// DELETE JOB
export const deleteJob = async (jobId) => {
  try {
    const endpoint = `jobs/positions/${jobId}`;
    const res = await request.delete(endpoint);
    return res;
  } catch (error) {
    throw new ServiceError(error);
  }
};

// PROMOTE JOB
export const promoteJob = async (jobId) => {
  try {
    const endpoint = `/jobs/positions/${jobId}/promote`;
    const res = await request.patch(endpoint);
    return res;
  } catch (error) {
    throw new ServiceError(error);
  }
};

// GET SEEKERS
export const getJobSeekers = async (jobId) => {
  try {
    const endpoint = `jobs/positions/${jobId}/job_responses`;
    const res = await request.get(endpoint);
    const data = normalizeIndexResponse(res);

    return data?.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};

// GET JOB TIMECARD
export const getJobTimecards = async (jobId) => {
  try {
    const endpoint = `/jobs/positions/${jobId}/timecards`;
    const res = await request.get(endpoint);
    const jobTimecards = formatResponse(res, { endpoint });

    const { data } = Formatter.buildEndpoint(
      jobTimecards.endpoint,
      jobTimecards,
      RESOURCE_TYPE.jobTimecard,
    );

    const formattedData = data.map((dt) => ({
      jobType: dt.jobTypeName,
      employee: dt.approvedName,
      payroll: dt.payroll,
      checkin: dt.checkin,
      checkout: dt.checkout,
      duration: dt.duration,
      timecard: {
        date: moment(dt.startTime).format('dd mmm'),
        startTime: moment(dt.startTime).format('hh:mm'),
        endTime: moment(dt.endTime).format('hh:mm'),
        break: dt.break,
        hours: dt.hours,
      },
      totalPay: dt.totalPay,
      totalCharge: dt.totalCharge,
      approvedBy: dt.approvedBy,
    }));

    return formattedData;
  } catch (error) {
    throw new ServiceError(error);
  }
};

// ADD SEEKER FOR JOB
export const addSeekerForJob = async (jobId, seekerIds) => {
  try {
    const endpoint = `/jobs/positions/${jobId}/job_responses`;
    const listSeeker = seekerIds.map((id) => ({
      employee_id: id,
      status: 'accepted',
    }));
    const res = await request.post(endpoint, {
      position: {
        job_responses_attributes: listSeeker,
      },
    });

    return res;
  } catch (error) {
    throw new CustomError(error);
  }
};

export const updateSeekerForJob = async (jobId, jobResponseId, status) => {
  try {
    const endpoint = `/jobs/positions/${jobId}/job_responses/status`;
    await request.patch(endpoint, {
      position: {
        job_responses_attributes: {
          id: jobResponseId,
          status,
        },
      },
    });

    return status;
  } catch (error) {
    throw new ServiceError(error);
  }
};

export const downloadJobCsv = async (params) => {
  try {
    const endpoint = '/jobs/positions/csv';
    const res = await request.get(endpoint, { params });

    return res.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};

export const calculateChargeRate = async (providerId) => {
  try {
    const endpoint = `jobs/employers/${providerId}/charge_rate_property`;
    const { data } = await request.get(endpoint);
    return data?.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};

export const getDefaultPayRate = async (employerId, skillId) => {
  try {
    const endpoint = 'providers/employers/show_payrate';

    const { data } = await request.get(endpoint, {
      params: { employerId, skillId },
    });

    return data?.data;
  } catch (error) {
    throw new ServiceError(error);
  }
};

export const getTemplateDataJob = async (providerId, filter) => {
  try {
    const endpoint = `providers/employers/${providerId}/job_templates`;

    const res = await request.get(endpoint, {
      params: { filter },
    });

    const normalizeData = normalizeShowResponse(res);
    return normalizeData?.data;
  } catch (error) {
    throw new ServiceError({
      ...error,
      message:
        error.status === 404
          ? 'No Job Template created for this role'
          : error.message,
    });
  }
};

export const getJobIdWithJobModal = async (jobId) => {
  try {
    const endpoint = `/jobs/positions/${jobId}`;
    const res = await request.get(endpoint);

    const normalizeData = normalizeShowResponse(res);

    const jobInfo = normalizeData.data;

    const {
      providerId,
      skillId,
      createdAt,
      totalAssignments,
      posted,
      schedule,
      positionsUniforms,
      preRequisits,
      untilEndDate,
      repeatFor,
      searchedOmniEmployeeAttributes,
      nudgeSeekers,
      seekers,
      jobResponses: initJobAttributes,
      nudgesInfo: initNudgesAttributes,
      paymentType,
      ...resData
    } = jobInfo;

    const StyleUniforms = {
      1: 'shirt',
      2: 'jean',
      3: 'shoes',
    };

    const jobResponses = {};

    seekers?.forEach((e) => {
      jobResponses[e.employeeId] = e;
    });

    initJobAttributes?.forEach((e) => {
      jobResponses[e.employeeId] = jobResponses[e.employeeId]
        ? { ...jobResponses[e.employeeId], ...e }
        : e;
    });

    const uniform = positionsUniforms.reduce((re, item) => {
      re[StyleUniforms[item.uniform.styleId]] =
        re[StyleUniforms[item.uniform.styleId]] || [];
      re[StyleUniforms[item.uniform.styleId]].push(item.uniformId);
      return re;
    }, {});

    uniform.listAdditionalUniforms = uniform[undefined];
    delete uniform[undefined];
    const uniformIds = positionsUniforms.map((item) => item.id);

    let items = schedule?.items
      .filter((e) => !e.isRepeated)
      .map((e) => ({
        startAt: e.startAt * 1000,
        endAt: e.endAt * 1000,
        break: e.break ?? 0,
        posted,
      }));

    items = sortBy(items, ['startAt']);

    let checkboxRepeat = 0;
    if (untilEndDate !== null) {
      checkboxRepeat = 2;
    } else if (repeatFor !== null && repeatFor !== 0) {
      checkboxRepeat = 1;
    }

    return {
      ...resData,
      employerId: resData?.shiftAttributes.employerId,
      locationId: resData?.shiftAttributes.locationId,
      skillId,
      createdAt: moment(createdAt),
      posted,
      shirt: uniform?.shirt?.[0],
      jean: uniform?.jean?.[0],
      shoe: uniform?.shoes?.[0],
      shirtId: uniformIds?.[0],
      jeanId: uniformIds?.[1],
      shoeId: uniformIds?.[2],
      additionalUniform: uniform.listAdditionalUniforms ?? [],
      isOver18: resData?.isOver18,
      isPrivated: resData?.shiftAttributes.isPrivated,
      schedule: {
        items,
      },
      jobResponsesAttributes:
        Object.values(jobResponses).filter(
          (e) => e.status !== 'declined' && e.status !== 'pending',
        ) ?? [],
      nudgesAttributes: nudgeSeekers ?? [],
      preRequisits: preRequisits ?? [],
      checkboxRepeat,
      repeatFor,
      untilEndDate: untilEndDate !== null ? moment(untilEndDate) : undefined,
      initJobAttributes: Object.values(jobResponses) ?? [],
      initNudgesAttributes,
      initPreRequisits: preRequisits ?? [],
      paymentType,
      payrollTemplate: paymentType === 'pay_template',
    };
  } catch (error) {
    throw new ServiceError(error);
  }
};

/**
 * @typedef {Object} ScheduleType
 * @property {number} startAt
 * @property {number} endAt
 * @property {Date} start
 * @property {Date} end
 */

/**
 * @typedef {Object} ScheduleJobs
 * @property {string|number} id
 * @property {boolean} isOver18
 * @property {boolean} isPromoted
 * @property {number} applied
 * @property {string} locationName
 * @property {string} status
 * @property {string} title
 * @property {number} totalAssignments
 * @property {number} totalFills
 * @property {number} hours
 * @property {string|number} grossPay
 * @property {Array<ScheduleType>} schedule
 * @property {Date} start
 * @property {Date} end
 */

/**
 *
 * @param {{
 * provider: string,
 * location: string,
 * statuses: string[],
 * shiftPostedFrom: string,
 * shiftPostedTo: string
 * }} CalendarJobs
 *
 * @returns {Promise<ScheduleJobs[]>}
 */
export const getCalendarJobs = async ({
  provider,
  location,
  statuses,
  shiftPostedFrom,
  shiftPostedTo,
}) => {
  const filter = {
    provider,
    location,
    statuses,
    shiftPostedFrom,
    shiftPostedTo,
  };

  const endpoint = '/jobs/positions';
  const res = await request.get(endpoint, { params: { filter } });
  const { data } = camelizeKeys(normalizeShowResponse(res));

  return map(data, (job) => {
    const {
      jobRole,
      schedule: { items },
    } = job;

    const customSchedule = map(items, (shift) => ({
      ...shift,
      start: moment.unix(shift.startAt).toDate(),
      end: moment.unix(shift.endAt).toDate(),
    }));

    return {
      ...job,
      schedule: customSchedule,
      title: jobRole,
    };
  });
};
