import React, { useEffect, useState } from 'react';

import { Form, message } from 'antd';
import { find, get, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button, CSV, FormItem, ModalConfirm, Pagination } from '@/components';
import ListView from '@/components/ListView';
import { STATUS_FILTER_DROPDOWN_OPTIONS } from '@/constants';
import {
  useDownloadCsv,
  useFetch,
  usePagination,
  useSessionStorage,
} from '@/hooks';
import { JobServices } from '@/services';
import { array, date } from '@/utils';
import Formatter from '@/utils/Formatter';

import CheckinCheckoutJobsDrawer from '../../CheckinCheckoutJobsDrawer';
import { FORM_NAME } from '../../JobListPage/constants';
import { headerTable } from '../../JobModal/constant';
import InputForm from '../InputForm';
import { filterJobByStatus, getStatusQuery } from '../JobListView/status';

import './styles.scss';

const ORDER_BY = 'schedule';
const ORDER_DIR_TAB = {
  'posted/live': 'asc',
  completed: 'desc',
};

const SharedLayout = ({
  onRow,
  setShowPopupCreate,
  setRepostId,
  setJobIdEdit,
  query,
  rowId,
  openManageWorkerModal,
  isCompletedTab,
  roles,
  providers,
  locations,
  industries,
  allFetched,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [storedValue, setSession, removeSession] =
    useSessionStorage('searchJobs');

  const [modalContent, setModalContent] = useState({ visible: false });
  const { visible, title, content, extraContent, titleSuccess, onClickYes } =
    modalContent;

  const {
    currentPage,
    paginationPageSize,
    handlePagination,
    handleShowSizeChange,
  } = usePagination();

  const orderByTab = get(ORDER_DIR_TAB, query.tab, 'asc');
  const [sortOrder, setSortOrder] = useState(orderByTab);
  const [showAction, setShowAction] = useState(false);
  const [jobIdActions, setJobIdActions] = useState(null);
  const defaultStatuses = isCompletedTab
    ? ['completed']
    : map(STATUS_FILTER_DROPDOWN_OPTIONS, 'value');

  const handleSort = (ord) => setSortOrder(Formatter.makeAbbrevSortType(ord));

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providerIds = Form.useWatch('provider', form);
  const roleIds = Form.useWatch('role', form);
  const status = Form.useWatch('status', form);
  const showManageModal = Form.useWatch('showManageModal', form);
  const isCompletedTabWatching = Form.useWatch('isCompletedTab', form);

  if (isCompletedTab !== isCompletedTabWatching) {
    form.setFieldsValue({ isCompletedTab });
  }

  const statusFilter = isEmpty(status) ? defaultStatuses : status;

  const {
    data: getJobs,
    refetch,
    isFetching,
  } = useFetch(
    [
      'get-jobs',
      sortOrder,
      currentPage,
      paginationPageSize,
      isCompletedTab,
      providerIds,
    ],
    ({ signal }) => {
      const {
        role,
        location,
        industry,
        searchTerm,
        shiftPostedFrom,
        shiftPostedTo,
      } = form.getFieldsValue();

      const page = {
        size: paginationPageSize,
        number: currentPage,
      };

      const order = {
        orderBy: ORDER_BY,
        orderDirection: sortOrder,
      };

      const filter = {
        statuses: getStatusQuery(statusFilter),
        searchTerm,
        role: array.remove(role, 'all'),
        provider: array.remove(providerIds, 'all'),
        officeId: array.remove(location, 'all'),
        industry: array.remove(industry, 'all'),
        shiftPostedFrom: date.convertDateTime(shiftPostedFrom),
        shiftPostedTo: date.convertDateTime(shiftPostedTo),
      };

      return JobServices.getJobs(
        {
          page,
          order,
          filter,
        },
        signal,
      );
    },
    {
      enabled: allFetched,
      select: (/** @type{object} */ data) => {
        const { jobInfo } = data;
        return {
          ...data,
          jobInfo: filterJobByStatus(statusFilter, jobInfo),
        };
      },
    },
  );

  const {
    refCSVLink,
    data: dataSJobExportCSV,
    refetch: refetchJobExportCSV,
    isFetching: isFetchingDownloadCsv,
  } = useDownloadCsv(
    [
      'download-job-csv',
      {
        statusFilter,
        sortOrder,
        currentPage,
        paginationPageSize,
      },
    ],
    () => {
      const { role, industry, searchTerm, shiftPostedFrom, shiftPostedTo } =
        form.getFieldsValue();
      const order = {
        orderBy: ORDER_BY,
        orderDirection: sortOrder,
      };

      const filter = {
        statuses: statusFilter,
        searchTerm,
        role: array.remove(role, 'all'),
        provider: array.remove(providerIds, 'all'),
        industry: array.remove(industry, 'all'),
        shiftPostedFrom: date.convertDateTime(shiftPostedFrom),
        shiftPostedTo: date.convertDateTime(shiftPostedTo),
      };

      return JobServices.downloadJobCsv({
        order,
        filter,
        page: {
          size: paginationPageSize,
          number: currentPage,
        },
      });
    },
  );

  const jobs = get(getJobs, 'jobInfo', []);
  const jobInfo = find(jobs, { key: rowId });
  const createdAt = get(jobInfo, 'createdAt', null);
  const updatedAt = get(jobInfo, 'updatedAt', null);

  if (showManageModal && isEmpty(jobInfo)) {
    form.setFieldsValue({ setShowManageModal: false });
    history.push('/jobs?tab=completed');
  }

  const { mutate: deleteJobShift } = useMutation(
    (id) => JobServices.deleteJob(id),
    {
      onSuccess: () => {
        setModalContent({ visible: false });
        refetch();
      },

      onError: () => {
        message.error('Delete Job fail');
      },
    },
  );

  const deleteShift = (id) => {
    setModalContent({
      visible: true,
      title: 'Delete?',
      content: (
        <div>
          Are you sure you want to delete this shift? <br /> Applied and
          confirmed worker lists cannot be retrieved.
        </div>
      ),
      titleSuccess: 'Deleted seeker succesfully',
      onClickYes: () => deleteJobShift(id),
    });
  };

  const { mutate: promote } = useMutation((id) => JobServices.promoteJob(id), {
    onSuccess: () => {
      setModalContent({ visible: false });
      refetch();
    },
  });

  const promoteSelectedJob = (id) => {
    setModalContent({
      visible: true,
      title: 'Promote?',
      content: 'Are you sure you want to promote this job?',
      titleSuccess: 'Promoted job successfully',
      onClickYes: () => promote(id),
    });
  };

  const columns = headerTable({
    jobIdActions,
    openManageWorkerModal,
    showAction,
    setShowAction,
    setJobIdEdit,
    setShowPopupCreate,
    setRepostId,
    setJobIdActions,
    deleteShift,
    promoteSelectedJob,
    query,
    form,
  });

  const renderJobsFooter = () => (
    <div className='jobs-footer'>
      <div className='pagination-container'>
        <Pagination
          pageSize={paginationPageSize}
          current={currentPage}
          total={getJobs?.totalCount}
          onChange={handlePagination}
          onShowSizeChange={(current, pageSizes) =>
            handleShowSizeChange(current, pageSizes)
          }
        />
      </div>
      <Button
        onClick={refetchJobExportCSV}
        disabled={isFetchingDownloadCsv}
        className='yellow-button download-csv-btn'
      >
        Download CSV
      </Button>
    </div>
  );

  const handleClearFilter = () => {
    removeSession();
    onFilter();
  };
  const onFilter = () => {
    setSession(form.getFieldsValue());
    refetch();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFilter}
        className='input-container'
        onReset={handleClearFilter}
        name={FORM_NAME.LIST_JOBS_FORM}
      >
        <FormItem name='statusFilter' initialValue={defaultStatuses} hidden />
        <FormItem name='showManageModal' initialValue={false} hidden />
        <FormItem name='isCompletedTab' hidden />

        <InputForm
          isShowStatus={!isCompletedTab}
          listRoles={roles}
          listProviders={providers}
          locations={locations?.listLocations}
          listIndustries={industries?.listIndustries}
          providerIds={providerIds}
          roleIds={roleIds}
        />
        <ListView
          className='jobs-list'
          headerColumns={columns}
          infoDetail={jobs}
          onRow={onRow}
          size='small'
          loading={isFetching}
          handleSort={handleSort}
          rowClassName={(record) => {
            const isSelectedManageWorker =
              showManageModal && rowId === record.key;
            return isSelectedManageWorker && 'highlight-row';
          }}
          onClassName
          locale={{
            emptyText: 'No Jobs match your search criteria',
          }}
        />
        {renderJobsFooter()}
        <CheckinCheckoutJobsDrawer
          rowId={rowId}
          refetchJobsListview={refetch}
          setModalContent={setModalContent}
          createdAt={createdAt}
          updatedAt={updatedAt}
          jobDetail={jobInfo}
        />
        <CSV
          ref={refCSVLink}
          filename='data-job.csv'
          data={dataSJobExportCSV}
        />
      </Form>
      <ModalConfirm
        visible={visible}
        title={title}
        titleSuccess={titleSuccess}
        onClickNo={() => setModalContent({ visible: false })}
        onClickYes={onClickYes}
        extraContent={extraContent}
        className='share-modal-container'
      >
        {content}
      </ModalConfirm>
    </>
  );
};

SharedLayout.propTypes = {
  onRow: PropTypes.func,
  setShowPopupCreate: PropTypes.func,
  setRepostId: PropTypes.func,
  setJobIdEdit: PropTypes.func,
  query: PropTypes.object,
  openManageWorkerModal: PropTypes.func,
  rowId: PropTypes.number || PropTypes.string,
  isCompletedTab: PropTypes.bool,
  roles: PropTypes.array,
  providers: PropTypes.array,
  locations: PropTypes.array,
  industries: PropTypes.array,
  allFetched: PropTypes.bool,
};

SharedLayout.defaultProps = {
  onRow: () => {},
  setShowPopupCreate: () => {},
  setRepostId: () => {},
  setJobIdEdit: () => {},
  query: {},
};

export default SharedLayout;
