import React from 'react';

import { Form } from 'antd';
import { useParams } from 'react-router-dom';

import { GeneralTemplate } from '@/components';
import { useFetch } from '@/hooks';
import { InvoicePayTemplateService } from '@/services';

import { Header, BodySection, BodyTotal, Footer } from './components';

import './styles.scss';

const breadCrums = [
  { item: 'Invoicing', to: '/invoicing' },
  { item: 'Edit Invoices Pay Template' },
];

const PayTemplateInvoice = () => {
  const [form] = Form.useForm();
  const { id: invoiceId } = useParams();

  const { data: invoiceData } = useFetch(
    ['payTemplateInvoice', invoiceId],
    () => InvoicePayTemplateService.getDetail(invoiceId),
    {
      onSuccess: (data) => {
        form.setFieldsValue(data);
      },
    },
  );

  return (
    <GeneralTemplate
      mainItem='Pay Template'
      data={breadCrums}
      headerText='Preview - Edit Invoice'
      hasButton={false}
      className='edit-invoice-pay-template'
    >
      <Form
        form={form}
        initialValues={invoiceData}
        className='invoice-container'
      >
        <p className='invoice-title'>Invoice Address and Week:</p>
        <Header />
        <BodySection />
        <BodyTotal />
        <Footer />
      </Form>
    </GeneralTemplate>
  );
};

export default PayTemplateInvoice;
