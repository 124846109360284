/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Form, message } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-query';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { Button, FormItem } from '@/components';
import Upload from '@/components/Upload';
import { useAuthStore } from '@/store/authStore';
import uploadFileS3 from '@/utils/uploadFileS3';
import { dummyRequest, beforeUploadImage } from '@/utils/uploadFileUtil';

import History from '../History';
import './styles.scss';

const InvoiceDocument = () => {
  const form = Form.useFormInstance();
  const userName = useAuthStore((state) => state.userName);

  const columnsTableDocument = [
    {
      title: 'Document',
      dataIndex: 'file',
      width: '60%',
      render: (_, record) => (
        <a
          href={record.file}
          target='_blank'
          alt='file-name'
          rel='noreferrer'
          className='doc-name'
        >
          {record.name}
        </a>
      ),
    },
    {
      title: 'User',
      dataIndex: 'createdBy',
      width: '20%',
    },
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      width: '20%',
      render: (/** @type {string[]} */ datetime) =>
        moment(datetime).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: '',
      className: 'close-icon-container',
      render: (_, record) => (
        <ClosedIcon
          onClick={() => deleteDocument(record?.id ?? record?.index)}
          className='close-icon-style'
        />
      ),
    },
  ];

  const costDocuments = Form.useWatch('costDocumentsAttributes', form) ?? [];
  const costDocumentsAttributes = costDocuments.filter(
    (document) => !document._destroy,
  );

  const deleteDocument = (id) => {
    const foundItem = costDocumentsAttributes.findIndex(
      (document) => document.id === id || document.index === id,
    );
    costDocumentsAttributes[foundItem]._destroy = '1';
    form.setFieldsValue({ costDocumentsAttributes });
  };

  const { mutate: uploadDocument, isLoading } = useMutation(
    async (uploadedFile) =>
      uploadFileS3(uploadedFile.file.name, uploadedFile.file.originFileObj),
    {
      onSuccess: (res) => {
        const data = [
          {
            index: `${Math.floor(Math.random() * 10)}${moment().unix()}`,
            file: res.getUrl,
            name: res.fileName,
            createdBy: userName,
          },
          ...costDocumentsAttributes,
        ];

        form.setFieldsValue({ costDocumentsAttributes: data });
        form.setFieldsValue({ comment: '' });
        message.success('Upload document successfully, click save to persist!');
      },
      onError: () => message.error('Add document failed'),
    },
  );

  return (
    <div className='documents'>
      <FormItem label='Invoice Upload' name='documents_file'>
        <Upload
          name='file'
          accept='image/*'
          customRequest={dummyRequest}
          beforeUpload={beforeUploadImage}
          multiple
          showUploadList={false}
          onChange={(uploadedFile) => {
            if (uploadedFile.file.status === 'done') {
              uploadDocument(uploadedFile);
            }
          }}
        >
          <Button className='yellow-button upload-button'>
            Upload Document
          </Button>
        </Upload>
      </FormItem>
      <History
        formItemPropertyName='costDocumentsAttributes'
        columns={columnsTableDocument}
        dataSource={costDocumentsAttributes}
        isLoading={isLoading}
      />
    </div>
  );
};
export default InvoiceDocument;
