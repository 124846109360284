import request from '@/request';

export const getPostCode = async (params) => {
  const resData = await request.get('/options/zipcodes', { params });
  const { data } = resData.data;
  const result = (data ?? []).map((item) => ({
    key: item,
    label: item,
    value: item,
  }));

  return result;
};
