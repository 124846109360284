/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { Collapse, Form, Spin, Switch } from 'antd';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { Comments, GeneralTemplate, TotalCard } from '@/components';
import { useFetch } from '@/hooks';
import { getTimesheetDetail } from '@/services/payrollTemplateService';
import Formatter from '@/utils/Formatter';

import ListJobTypeStatus from './ListJobTypeStatus';
import { useTenantContext } from '../../../TenantWrapper';

import './styles.scss';

const { Panel } = Collapse;

const listStatues = [
  { status: 'unapproved', color: 'red' },
  { status: 'approved', color: 'yellow' },
  { status: 'confirmed', color: 'green' },
  { status: 'finalised', color: 'green' },
  { status: 'paid', color: 'green' },
];

const PayTimesheet = () => {
  const { id: timesheetId } = useParams();

  const [isHourlyTemplate, setIsHourlyTemplate] = useState(false);
  const { currency } = useTenantContext();

  const {
    data: timesheetData = {},
    isFetching: isLoadingPayTemplate,
    refetch: timesheetRefetch,
  } = useFetch('timesheetDetail', () => getTimesheetDetail(timesheetId));

  const provider = timesheetData?.employerAttributes?.name;
  const { weekNum, totalPay, totalCost, totalCharge, locationName } =
    timesheetData;

  const payAndCost = totalPay + totalCost;
  const difference = totalCharge - payAndCost;
  const margin = totalCharge ? (difference / totalCharge) * 100 : 0;

  const totalCards = [
    {
      title: 'Total Costs',
      amount: Formatter.fixedNumber({ value: totalCost }),
      isStart: true,
      unit: currency,
    },
    {
      title: 'Total Pay',
      amount: Formatter.fixedNumber({ value: totalPay }),
      isStart: true,
      unit: currency,
    },
    {
      title: 'Total Charge',
      amount: Formatter.fixedNumber({ value: totalCharge }),
      isStart: true,
      isRed: true,
      unit: currency,
    },
    {
      title: 'Margin',
      amount: Formatter.fixedNumber({ value: margin }),
      unit: '%',
    },
  ];

  const breadcrumb = [
    { item: 'Timesheets', to: '/timesheets' },
    {
      item: provider ?? <Spin />,
    },
    {
      item: weekNum ? `Week ${weekNum}` : <Spin />,
    },
    {
      item: locationName ?? <Spin />,
    },
  ];

  const onSwitchChange = (checked) => {
    if (checked) {
      setIsHourlyTemplate(true);
    } else {
      setIsHourlyTemplate(false);
    }
  };

  const splitTimesheetToShowData = () => {
    const splitData = {
      unapproved: {},
      approved: {},
      confirmed: {},
      finalised: {},
      paid: {},
    };

    listStatues.forEach(
      ({ status }) =>
        !isEmpty(timesheetData[status]) &&
        timesheetData[status].forEach((panelInfo) => {
          const data =
            !isEmpty(splitData[status]) &&
            !isEmpty(splitData[status][panelInfo?.skillName])
              ? splitData[status][panelInfo?.skillName]
              : [];

          data.push(panelInfo);
          splitData[status][panelInfo?.skillName] = data;
        }),
    );

    return splitData;
  };

  const timeSheetShowData = splitTimesheetToShowData();
  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadcrumb}
      headerText={breadcrumb.at(2).item}
      hasButton={false}
      className='timesheets-container'
      buttonCustom={breadcrumb.at(1).buttonCustom}
    >
      <div className='timesheet-detail'>
        <div className='timesheet-detail-options'>
          <div>View</div>
          <div className='toggle'>
            <span>Total hours per day </span>
            <Switch onChange={onSwitchChange} />
            <span>Shift breakdown by Hourly Template</span>
          </div>
        </div>

        {listStatues.map(
          ({ status, color: statusColor }) =>
            !isEmpty(timeSheetShowData[status]) && (
              <div className='timesheet-detail-body'>
                {Object.keys(timeSheetShowData[status]).map((key) => (
                  <>
                    <Panel
                      className='total-worker'
                      // eslint-disable-next-line max-len
                      header={`${timeSheetShowData[status][key].length} workers - ${key}`}
                      key='header'
                      collapsible='disabled'
                      showArrow={false}
                    />

                    <ListJobTypeStatus
                      isHourlyTemplate={isHourlyTemplate}
                      isLoadingPayTemplate={isLoadingPayTemplate}
                      timesheetRefetch={timesheetRefetch}
                      data={timeSheetShowData[status][key]}
                      status={status}
                      statusColor={statusColor}
                    />
                  </>
                ))}
              </div>
            ),
        )}

        <div className='row'>
          <div className='column'>
            <Form>
              <Comments />
            </Form>
          </div>
          <div className='column'>
            <div className='total-card-row'>
              {totalCards.map((item) => (
                <div key={item.title} className='total-card-column'>
                  <TotalCard
                    title={item.title}
                    amount={item.amount}
                    unit={item.unit}
                    isStart={item.isStart}
                    isRed={item.isRed}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </GeneralTemplate>
  );
};

export default PayTimesheet;
