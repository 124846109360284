/* eslint-disable max-classes-per-file */
import { camelize } from 'humps';
import normalize from 'json-api-normalizer';
import { omit, isArray, isObject, isEmpty } from 'lodash';
import build from 'redux-object';

export class Normalize {
  #strategy;

  constructor() {
    this.#strategy = new Strategy();
  }

  /**
   * @param {Strategy} strategy
   */
  setStrategy(strategy) {
    this.#strategy = strategy;
  }

  /**
   * @returns any
   */
  formatResponse() {
    return this.#strategy.normalize();
  }
}

class Strategy {
  response;

  /**
   * @type {string | undefined}
   */
  typeResource;

  normalizedResponse;

  constructor(response) {
    this.response = response;
  }

  /**
   * @returns any
   */
  // eslint-disable-next-line class-methods-use-this
  normalize() {
    throw new Error('Method normalize() must be implemented.');
  }
}

export class FormatIndexStrategy extends Strategy {
  /**
   * @override
   */
  normalize() {
    if (isEmpty(this.response.data) || isEmpty(this.response.data.data))
      return { data: [], currentPage: 1, totalPage: 1, totalCount: 1 };

    this.normalizedResponse = normalize(this?.response?.data);
    this.typeResource = camelize(this.response?.data?.data[0]?.type);
    const ids = this?.response.data?.data.map((item) => item.id);
    const data = build(this.normalizedResponse, this.typeResource, ids);
    const meta = this.response.data?.meta;
    const pagy = meta?.pagy;
    return {
      data,
      meta,
      currentPage: pagy?.page,
      totalPage: pagy?.pages,
      totalCount: pagy?.count,
    };
  }
}

// TODO: @Harvey will build 1 function parse all level Attributes
const buildData = (state, type) => {
  const resources = Object.keys(state);
  let result = {};
  resources.forEach((resource) => {
    const keys = Object.keys(state[resource]);
    if (resource === type) {
      result = { ...result, ...build(state, resource, keys[0]) };
    } else if (keys.length === 1)
      result[`${resource}Attributes`] = build(state, resource, keys[0]);
    else result[`${resource}Attributes`] = build(state, resource, keys);
  });
  return omit(result, resources);
};

const getLevelObject = (
  /** @type {{ [s: string]: any; } } */ obj,
  /** @type {number} */ level,
) => {
  if (level === 0) return null;
  const result = Object.entries(obj).map(([k, v]) => {
    if (isArray(v)) {
      return {
        [k]: v,
      };
    }
    if (isObject(v))
      return {
        [k]: getLevelObject(v, level - 1),
      };
    return {
      [k]: v,
    };
  });

  if (!result.length) {
    return null;
  }

  return Object.assign(...result);
};

export class FormatShowStrategy extends Strategy {
  normalize() {
    this.normalizedResponse = normalize(this?.response?.data);
    const resData = this.response.data.data;

    if (resData?.length === 0) {
      return { data: [] };
    }

    if (resData?.length > 0) {
      const formatedData = resData.map((dt) => dt.attributes);
      return {
        data: formatedData,
      };
    }

    this.typeResource = camelize(this.response.data.data.type);

    const data = buildData(this.normalizedResponse, this.typeResource);
    return {
      data: getLevelObject(data, 3),
    };
  }
}

/**
 * @param res {import('axios').AxiosResponse<any,any>}
 * @returns any
 */
export const normalizeIndexResponse = (res) => {
  const normalizeIndex = new Normalize();
  const strategy = new FormatIndexStrategy(res);
  normalizeIndex.setStrategy(strategy);
  const data = normalizeIndex.formatResponse();
  return data;
};

export const normalizeShowResponse = (
  /** @type {import('axios').AxiosResponse<any,any>} */ res,
) => {
  const normalizeShow = new Normalize();
  const strategy = new FormatShowStrategy(res);
  normalizeShow.setStrategy(strategy);
  const data = normalizeShow.formatResponse();
  return data;
};

export const normalizeDropdownResponse = (
  /** @type {import("axios").AxiosResponse<any, any>} */ res,
) => {
  const data = normalizeIndexResponse(res);
  const result = data?.data?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return result || [];
};
