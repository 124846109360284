/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Form } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CopyIcon from '@/assets/icons/copyIcon.png';
import DeleteIcon from '@/assets/icons/deleteIcon.png';
import EditIcon from '@/assets/icons/editIcon.png';
import ManageIcon from '@/assets/icons/manageIcon.png';
import RepostIcon from '@/assets/icons/repostIcon.png';
import WhiteStar from '@/assets/icons/whiteStar.png';
import { ENV } from '@/constants';
import { copyToClipboard } from '@/utils';

import './styles.scss';

/**
 * Renders a set of action buttons for a given record.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - record: The record object.
 *   - setShowAction: A function to set the visibility of the action buttons.
 *   - setJobIdEdit: A function to set the job ID for editing.
 *   - setShowPopupCreate: A function to set the visibility of the create popup.
 *   - setRepostId: A function to set the ID for reposting.
 *   - deleteShift: A function to delete a shift.
 *   - promoteSelectedJob: A function to promote a selected job.
 *   - tab: The current tab.
 *   - openManageWorkerModal: A function to open the manage worker modal.
 * @return {JSX.Element} The rendered set of action buttons.
 */
const Actions = ({
  record,
  setShowAction,
  setJobIdEdit,
  setShowPopupCreate,
  setRepostId,
  deleteShift,
  promoteSelectedJob,
  tab,
  openManageWorkerModal,
}) => {
  const formInstance = Form.useFormInstance();
  const actions = [
    {
      alt: 'manage-icon',
      src: ManageIcon,
      text: 'Manage Workers',
      // disable: tab === 'completed',
      onClick: (/** @type {{ stopPropagation: () => void; }} */ e) => {
        e.stopPropagation();
        openManageWorkerModal(formInstance, record.key);
        setShowAction(false);
      },
    },
    {
      alt: 'edit-icon',
      src: EditIcon,
      text: 'Edit Shift',
      disable: tab === 'completed',
      onClick: (e) => {
        e.stopPropagation();
        setJobIdEdit(record.key);
        setShowAction(false);
      },
    },
    {
      alt: 'repost-icon',
      src: RepostIcon,
      text: 'Repost Shift',
      onClick: (e) => {
        e.stopPropagation();
        setRepostId(record.key);
        setShowPopupCreate(true);
        setShowAction(false);
      },
    },
    {
      alt: 'delete-icon',
      src: DeleteIcon,
      text: 'Delete Shift',
      disable: tab === 'completed',
      onClick: (e) => {
        e.stopPropagation();
        deleteShift(record.key);
        setShowAction(false);
      },
    },
    {
      alt: 'copy-icon',
      src: CopyIcon,
      text: 'Copy Link',
      disable: tab === 'completed',
      onClick: (e) => {
        e.stopPropagation();
        copyToClipboard(`${ENV.API_BASE_URL}/redirectToApp/${record.key}`);
      },
    },
    {
      alt: 'star-icon',
      src: WhiteStar,
      text: 'Promote',
      disable: tab === 'completed',
      class:
        (record.status !== 0 || record.filled === record.totalAssignments) &&
        'disabled',
      onClick: (e) => {
        const { status, filled, totalAssignments: posted } = record;
        e.stopPropagation();

        if (status === 0 && filled !== posted) {
          promoteSelectedJob(record.key);
          setShowAction(false);
        }
      },
    },
  ];

  return (
    <div className='action-container'>
      {actions.map((item) => (
        <div
          onClick={!item.disable ? item.onClick : null}
          key={item.alt}
          className={classNames('row-container', item.class, {
            disabled: item.disable,
          })}
        >
          <img src={item.src} alt={item.alt} className='icon-style' />
          <span className='text-style'>{item.text}</span>
        </div>
      ))}
    </div>
  );
};

Actions.propTypes = {
  record: PropTypes.object,
  openManageWorkerModal: PropTypes.func,
  setShowAction: PropTypes.func,
  setJobIdEdit: PropTypes.func,
  setShowPopupCreate: PropTypes.func,
  setRepostId: PropTypes.func,
  deleteShift: PropTypes.func,
  promoteSelectedJob: PropTypes.func,
  tab: PropTypes.string,
};

Actions.defaultProps = {
  record: {},
  openManageWorkerModal: () => {},
  setShowAction: () => {},
  setJobIdEdit: () => {},
  setShowPopupCreate: () => {},
  setRepostId: () => {},
  deleteShift: () => {},
  promoteSelectedJob: () => {},
  tab: '',
};
export default Actions;
