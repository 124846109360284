import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '@/components/Button';

const PanelRender = ({
  originalPanel,
  datetime,
  datepickerPanelRef,
  mode,
  setMode,
  setIsOpen,
  setDatetime,
  yearMode,
  isDisableNextIcon,
  onCancelClick,
}) => {
  const year = moment(datetime).year();

  const dateMonth = moment(datetime).format('dddd, MMM DD');

  const onOkClick = () => {
    setDatetime(datetime);
    setIsOpen(false);
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    onCancelClick();
  };

  const renderExtraFooter = () => (
    <div className='calendar-footer'>
      <Button onClick={handleCancelClick}>Cancel</Button>
      <Button onClick={onOkClick}>OK</Button>
    </div>
  );

  return (
    <div
      className={classNames('calendar-container', {
        'year-format': mode === 'year',
      })}
      ref={datepickerPanelRef}
    >
      <div className='calendar-header'>
        <Button onClick={() => setMode('year')} className='extra-title'>
          {year}
        </Button>
        <Button
          onClick={() => setMode('date')}
          className='extra-title title-hide'
        >
          {dateMonth}
        </Button>
      </div>
      <div className='calendar-body'>
        {mode === 'date' && <div className='hide-month-year' />}
        {isDisableNextIcon && <div className='hide-next-icon' />}
        {mode === 'year' ? yearMode : originalPanel}
      </div>
      {renderExtraFooter()}
    </div>
  );
};

PanelRender.propTypes = {
  originalPanel: PropTypes.node,
  yearMode: PropTypes.node,
  datetime: PropTypes.any,
  datepickerPanelRef: PropTypes.object,
  mode: PropTypes.string,
  setMode: PropTypes.func,
  setIsOpen: PropTypes.func,
  setDatetime: PropTypes.func,
  isDisableNextIcon: PropTypes.bool,
  onCancelClick: PropTypes.func,
};

PanelRender.defaultProps = {
  mode: false,
  setMode: () => {},
  setIsOpen: () => {},
  setDatetime: () => {},
  onCancelClick: () => {},
  isDisableNextIcon: false,
};
export default PanelRender;
