export const deleteFieldFile = ({
  prevValues,
  fieldName,
  list,
  formInstance,
  setFile,
  setBase64,
  setFileState,
}) => {
  setFile();
  setBase64();
  setFileState((prev) => ({
    ...prev,
    value: undefined,
    file: undefined,
  }));
  const findIndex = prevValues.findIndex((doc) => doc.typeValue === fieldName);

  prevValues[findIndex] = {
    ...prevValues[findIndex],
    checked: false,
    fileValue: '',
    fileBlob: '',
  };

  formInstance.setFieldsValue({
    [list]: prevValues,
  });
};
