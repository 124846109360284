import React, { useEffect } from 'react';

import { Form, Typography, message } from 'antd';
import { toNumber } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { ReactComponent as AcceptedIcon } from '@/assets/icons/acceptedIcon.svg';
import { ReactComponent as DeclinedIcon } from '@/assets/icons/declinedIcon.svg';
import {
  Button,
  FormItem,
  Input,
  ListView,
  Pagination,
  Select,
} from '@/components';
import { PAGE_SIZE_OPTIONS } from '@/constants';
import { useFetch, usePagination, useSessionStorage } from '@/hooks';
import { weeksAndDayofWeek } from '@/pages/Timesheet/constants';
import { HolidayService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import './styles.scss';
import { numberOfDaysHolidayToProcessOptions } from '../constants';

const Holidays = ({
  shouldRefetchHolidays,
  setShouldRefetchHolidays,
  weekNum,
  setWeekNumber,
}) => {
  const [form] = Form.useForm();

  const [storedValue, setSession, removeSession] =
    useSessionStorage('holidaysSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    currentPage,
    handlePagination,
    paginationPageSize,
    handleShowSizeChange,
  } = usePagination();

  const {
    data: queryData,
    isFetching,
    refetch: handleSearchHolidays,
  } = useFetch(
    ['get-holidays', weekNum, paginationPageSize, currentPage],
    () => {
      setSession({ ...form.getFieldsValue(), week: weekNum });
      return HolidayService.getPayrollHolidays({
        filter: { ...form.getFieldsValue(), week: weekNum },
        page: {
          size: paginationPageSize,
          num: currentPage,
        },
      });
    },
    {
      initialData: {
        data: [],
        totalCount: 0,
      },
    },
  );

  const { mutate: mutateApprove } = useMutation(
    (value) => {
      const { id, numberOfDaysHolidayToProcess } = value;
      HolidayService.approvePayrollHolidayStatus({
        holidayId: id,
        numberDaysProcess: numberOfDaysHolidayToProcess,
      });
    },
    {
      onSuccess: () => {
        handleSearchHolidays();
        message.success('Approved successfully');
      },
      onError: (error) => {
        handleSearchHolidays();
        message.error(error?.message ?? 'Can not Approve holiday');
      },
    },
  );
  const { mutate: mutateDecline } = useMutation(
    HolidayService.declinePayrollHolidayStatus,
    {
      onSuccess: () => {
        handleSearchHolidays();
        message.success('Declined successfully');
      },
      onError: (error) => {
        handleSearchHolidays();
        message.error(error?.message ?? 'Can not Decline holiday');
      },
    },
  );

  useEffect(() => {
    if (shouldRefetchHolidays) {
      handleSearchHolidays();
      setShouldRefetchHolidays(false);
    }
  }, [shouldRefetchHolidays, setShouldRefetchHolidays, handleSearchHolidays]);

  const handleDeclineHoliday = (id, numberDaysProcess) =>
    mutateDecline(id, numberDaysProcess);

  const onClickApproveHoliday = ({ id, accruedDays, requestedDays }) => {
    const { numberOfDaysHolidayToProcess } = form.getFieldsValue(true)[id];

    if (
      toNumber(accruedDays) <
      toNumber(numberOfDaysHolidayToProcess ?? requestedDays)
    ) {
      return message.open({
        key: 'updateMessageKey',
        type: 'warning',
        content: 'Accrued Days less than Number of days Holiday to process',
      });
    }
    return mutateApprove({ id, numberOfDaysHolidayToProcess });
  };

  const onChangeNumberOfDaysHolidayToProcess = (value, id) => {
    form.setFieldsValue({
      [id]: {
        numberOfDaysHolidayToProcess: value,
      },
    });
  };

  const columnsTable = generateHeaderTable([
    { title: 'Payroll Number', dataIndex: 'payrollNumber', width: '6%' },
    { title: 'Name', dataIndex: 'name', width: '15%' },
    { title: 'Accrued Days', dataIndex: 'accruedDays', width: '7%' },
    { title: 'Requested Days', dataIndex: 'requestedDays', width: '7%' },
    { title: 'Week Requested', dataIndex: 'weekRequested', width: '12%' },
    {
      title: 'Days Requested',
      dataIndex: 'daysRequested',
      width: '8%',
      render: (value, record) => (
        <>
          {value.map((day, index) => (
            <div key={`${record.id}-${day}`}>
              {day.hours === 4 ? (
                <span key={day.date} className='font-bold'>
                  {moment(day.date).format('Do')}
                </span>
              ) : (
                <span key={day.date}>{moment(day.date).format('Do')}</span>
              )}
              {index < value.length - 1 && ', '}
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Days Worked this week',
      dataIndex: 'daysWorkedThisWeek',
      width: '8%',
    },
    {
      title: 'Average Daily Pay',
      dataIndex: 'averageDailyPay',
      width: '8%',
      render: (value) => Formatter.currencyGBP(value),
    },
    {
      title: 'Number of days Holiday to process',
      dataIndex: 'numberOfDaysHolidayToProcess',
      width: '10%',
      render: (value, record) => (
        <Select
          onChange={() =>
            onChangeNumberOfDaysHolidayToProcess(value, record.id)
          }
          options={numberOfDaysHolidayToProcessOptions}
          defaultValue={value ?? record?.requestedDays}
          disabled={record.status !== 'pending'}
        />
      ),
    },
    {
      title: 'Approve/Decline Request',
      dataIndex: 'status',
      width: '7%',
      render: (statusText, record) =>
        statusText === 'pending' ? (
          <div className='button-approve-and-decline'>
            <Button
              type='text'
              onClick={() => onClickApproveHoliday(record ?? {})}
            >
              <AcceptedIcon />
            </Button>
            <Button
              type='text'
              onClick={() =>
                handleDeclineHoliday(
                  record?.id,
                  record?.numberOfDaysHolidayToProcess,
                )
              }
            >
              <DeclinedIcon />
            </Button>
          </div>
        ) : (
          <Typography className='center-status-text'>
            {Formatter.capitalizeWords(statusText)}
          </Typography>
        ),
    },
  ]);

  const handleClearFilter = () => {
    removeSession();
    form.setFieldsValue({
      week: undefined,
      searchTerm: undefined,
    });
    setWeekNumber(moment().week());
  };

  const handleFinishSearchHoliday = () => {
    setSession(form.getFieldsValue());
    handleSearchHolidays();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleFinishSearchHoliday}
        className='holiday-search-form'
        initialValues={{
          week: moment().week(),
        }}
      >
        <FormItem name='searchTerm' label='Search' className='search-form-item'>
          <Input placeholder='Name or payroll number' />
        </FormItem>

        <FormItem label='Week' className='search-form-item'>
          <Select
            value={
              weeksAndDayofWeek.find((item) => item.value === weekNum).label
            }
            placeholder='Choose Week'
            options={weeksAndDayofWeek}
            onChange={(val) => {
              setSession({ ...form.getFieldsValue(), week: val });
              setWeekNumber(val);
            }}
          />
        </FormItem>
        <Button
          htmlType='submit'
          className='search-form-item button yellow-button'
        >
          Search
        </Button>
        <Button
          className='search-form-item button filter-button'
          onClick={handleClearFilter}
        >
          Clear Filters
        </Button>
      </Form>
      <ListView
        headerColumns={columnsTable}
        infoDetail={queryData.data}
        loading={isFetching}
        locale={{
          emptyText: 'No Holidays match your search criteria',
        }}
        className='holiday-list-view'
      />
      <div className='pagination-container'>
        <Pagination
          current={currentPage}
          total={queryData.totalCount}
          pageSize={paginationPageSize}
          onChange={handlePagination}
          responsive
          showSizeChanger
          onShowSizeChange={handleShowSizeChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
        />
      </div>
    </>
  );
};

Holidays.propTypes = {
  shouldRefetchHolidays: PropTypes.bool,
  setShouldRefetchHolidays: PropTypes.func,
  weekNum: PropTypes.number,
  setWeekNumber: PropTypes.func,
};

Holidays.defaultProps = {
  setShouldRefetchHolidays: () => {},
};

export default Holidays;
