import React, { useCallback } from 'react';

import { Calendar, message } from 'antd';
import moment from 'moment';
import { useQuery } from 'react-query';

import { Button } from '@/components';
import { HolidayService } from '@/services';

import './styles.scss';
import CalendarHeader from './CalendarHeader';
import RequestCard from './RequestCard';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
});

const Holidays = () => {
  const { data: holidays } = useQuery(
    ['getHolidays'],
    () => HolidayService.getHolidays(),
    {
      retry: false,
      cacheTime: 0,
      initialData: [],
      refetchOnWindowFocus: false,
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const getHolidaysByDay = useCallback(
    (date) => {
      if (date.month() === moment().month()) {
        return holidays[date.date()] || [];
      }
      return [];
    },
    [holidays],
  );

  const dateCellRender = (value) => {
    const listData = getHolidaysByDay(value);

    return (
      <ul className='events'>
        {listData.map(({ id, type, name, startDate, endDate, title }) => {
          if (!(startDate && endDate)) {
            return (
              <li key={id}>
                <RequestCard
                  border
                  title={title}
                  name={name}
                  type={type}
                  endDate={endDate}
                  startDate={startDate}
                />
              </li>
            );
          }

          return (
            <li key={id}>
              <RequestCard
                background
                name={name}
                type={type}
                endDate={endDate}
                startDate={startDate}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  const renderHeaderCalendar = ({ value, onChange }) => (
    <CalendarHeader value={value} onChange={onChange} />
  );

  const disabledDate = (currentDate) => {
    const weekday = currentDate.weekday();

    if (weekday === 5) {
      return true;
    }

    if (weekday === 6) {
      return true;
    }

    return false;
  };

  return (
    <div className='holidays'>
      <div className='holidays-body'>
        <Calendar
          headerRender={({ value, onChange }) =>
            renderHeaderCalendar({ value, onChange })
          }
          dateCellRender={dateCellRender}
          disabledDate={disabledDate}
          className='holiday-calendar'
        />
      </div>
      <div className='holidays-footer'>
        <Button className='black-button btn-footer'>Cancel</Button>
        <Button className='yellow-button btn-footer'>Save</Button>
      </div>
    </div>
  );
};

export default Holidays;
