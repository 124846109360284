import { useQuery } from 'react-query';

import { fetchManagersOfProvider } from '@/services/dropdownServices/getManagersOfProvider';

const useManagersOfProvider = (queryOptions = {}) =>
  useQuery(['managersOfProvider'], fetchManagersOfProvider, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...queryOptions,
  });

export default useManagersOfProvider;
