/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';

import { Form, message } from 'antd';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { Button, GeneralTemplate } from '@/components';
import { useFetch } from '@/hooks';
import { useProviderLocations, useProviders } from '@/hooks/dropdowns';
import useWorkerTypes from '@/hooks/dropdowns/useWorkerTypes';
import Options from '@/pages/PayTemplate/components/Options';
import RulesModal from '@/pages/PayTemplate/components/RulesModal';
import RulesView from '@/pages/PayTemplate/components/RulesView';
import TemplatesTable from '@/pages/PayTemplate/components/TemplateTable';
import templateTableColumns from '@/pages/PayTemplate/components/TemplateTable/templateTableColumns';
import TrackingTable from '@/pages/PayTemplate/components/TrackingTable';
import {
  initValues as initTrackingTable,
  templateRow,
  timeFieldsToUpdate,
} from '@/pages/PayTemplate/constants';
import { getPayTemplateInfo } from '@/services/payrollTemplateService';
import './styles.scss';
import updatePayrollTemplate from '@/services/payrollTemplateService/edit';
import { date } from '@/utils';

import {
  checkAllTimeChecked,
  checkBeforeUpdate,
  convertFieldsObject,
  convertToTrackingData,
  getDeletedRow,
  updateNextDay,
  updateTimeValue,
} from '../../utils';
import { useTenantContext } from '../../../../TenantWrapper';

const EditPayTemplate = () => {
  const history = useHistory();
  const { templateId } = useParams();

  const [form] = Form.useForm();

  const [dataTable, setDataTable] = useState([]);

  const [trackingTableData, setTrackingTableData] = useState(initTrackingTable);
  const [showModal, setShowModal] = useState(false);
  const [isAppliedRules, setIsAppliedRules] = useState(false);

  const workerTypes = useWorkerTypes();
  const listProviders = useProviders();
  const providerId = Form.useWatch('employerIdOption', form);
  const hourTemplatesWatching = Form.useWatch('hourTemplates', form);
  const { data: locations } = useProviderLocations(providerId);
  const { currency } = useTenantContext();

  const { mutate: updatePayTemplate, isLoading: isUploadPayTemplate } =
    useMutation((payload) => updatePayrollTemplate({ templateId, payload }), {
      onSuccess: () => {
        message.success('Edit Payroll template success');
        history.push('/pay-templates');
      },
      onError: (err) => {
        if (err?.data?.errors[0].detail ?? false) {
          form.setFields([
            {
              name: 'name',
              errors: [err.data.errors[0].detail],
            },
          ]);
          form.scrollToField(['name'], {
            skipOverflowHiddenElements: true,
            block: 'start',
            behavior: 'smooth',
          });
          message.error(err.data.errors[0].detail);
        }
      },
    });

  const handleAddRow = () => {
    const addRow = {
      ...templateRow,
      isAdded: true,
      id: dataTable?.[dataTable.length - 1]?.id
        ? dataTable[dataTable.length - 1].id + 1
        : dataTable.length,
    };

    setDataTable([...dataTable, addRow]);
  };

  const handleCancelRules = () => {
    if (!isAppliedRules) {
      form.setFieldsValue({
        payroll_template_rule_attributes: null,
      });
    }
    setShowModal(false);
  };

  const handleUpdate = () => {
    const formData = form.getFieldsValue();
    const hoursTemplates = { ...formData.hourTemplates };

    if (!checkBeforeUpdate(formData)) {
      return;
    }

    const updateTrackingData = convertToTrackingData(
      Object.values(hoursTemplates ?? {}),
    );
    const allDays = updateNextDay(updateTrackingData);
    const errorAllTimeChecked = checkAllTimeChecked(allDays);

    if (errorAllTimeChecked) {
      message.error(errorAllTimeChecked);
      return;
    }

    const updateHrsTemplates = Object.keys(hoursTemplates ?? {}).reduce(
      (total, id) => {
        if (hoursTemplates[id].isAdded) {
          return [...total, convertFieldsObject(hoursTemplates[id])];
        }
        const updatedHoursTemplate = {
          ...convertFieldsObject(hoursTemplates[id]),
          id,
        };
        return [...total, updatedHoursTemplate];
      },
      [],
    );
    const deletedRows = getDeletedRow({ updateHrsTemplates, templateDetail });

    const updateRules = convertFieldsObject(
      formData.payrollTemplateRuleAttributes ?? {},
    );

    timeFieldsToUpdate.forEach((timeField) => {
      updateTimeValue(updateRules, timeField);
    });

    const updateData = {
      name: formData?.name,
      worker_type_id: formData?.workerTypeId,
      start_at: date.convertToISOString(formData?.startAt),
      employer_id: formData.providerSelected.value,
      employer_location_ids: formData.locationsSelected.map(
        (location) => location.value,
      ),
      hour_templates_attributes: [...updateHrsTemplates, ...deletedRows],
      payroll_template_rule_attributes: updateRules,
    };
    updatePayTemplate(updateData);
  };

  const handleApplyRules = () => {
    setIsAppliedRules(true);
    setShowModal(false);
  };

  const handleValuesChange = (_, allValues) => {
    if (!isEmpty(allValues.hourTemplates)) {
      const updateTrackingData = convertToTrackingData(
        Object.values(allValues.hourTemplates),
      );
      const sortedDate = updateNextDay(updateTrackingData);

      setTrackingTableData(sortedDate);
    }
  };

  const columns = templateTableColumns({
    dataTable,
    setDataTable,
    form,
    hourTemplatesWatching,
    currency,
  });

  const { data: templateDetail, isFetching: isFetchingTemplateInfo } = useFetch(
    ['getTemplateInfo'],
    () => getPayTemplateInfo(templateId),
    {
      onSuccess: (data) => {
        form.setFieldsValue({
          ...data,
          hourTemplates: [],
        });
        const updateTrackingData = convertToTrackingData(data.hourTemplates);
        const sortedDate = updateNextDay(updateTrackingData);
        setTrackingTableData(sortedDate);

        setDataTable(data.hourTemplates);

        if (!isEmpty(data.payrollTemplateRuleAttributes)) {
          setIsAppliedRules(true);
        }
      },
    },
  );

  const handleFinishFailed = (err) => {
    const errorName = err.errorFields[0].name;

    form.scrollToField(errorName, {
      skipOverflowHiddenElements: true,
      block: 'start',
      behavior: 'smooth',
    });
  };

  return (
    <GeneralTemplate
      mainItem='Pay Templates'
      data={[{ item: 'Edit Pay Templates', to: '/pay-templates' }]}
      headerText='Pay Templates'
      subTemplateClassName='templates-body'
      className='edit-templates-container'
      hasButton={false}
    >
      <Form
        form={form}
        onFinish={handleUpdate}
        onFinishFailed={handleFinishFailed}
        onValuesChange={handleValuesChange}
      >
        <Options
          locations={locations}
          listProviders={listProviders}
          workerTypes={workerTypes}
          removableLocations
        />

        <div className='edit-template-container'>
          <div className='template-table'>
            <TemplatesTable
              loading={isFetchingTemplateInfo}
              scroll={{ y: 250 }}
              columns={columns}
              infoDetail={dataTable}
            />
            <Button className='add-btn black-button' onClick={handleAddRow}>
              <p className='plus-icon'>+</p>
            </Button>
          </div>
          <div className='tracking-table'>
            <TrackingTable
              loading={isFetchingTemplateInfo}
              infoDetail={trackingTableData}
            />
          </div>
        </div>

        <RulesView
          setShowModal={setShowModal}
          isAppliedRules={isAppliedRules}
        />
        <div className='edit-templates-footer'>
          <Button
            loading={isUploadPayTemplate}
            className='edit-btn'
            type='primary'
            htmlType='submit'
          >
            Save
          </Button>
        </div>
        <RulesModal
          width='60%'
          onCancel={handleCancelRules}
          isShow={showModal}
          onApply={handleApplyRules}
        />
      </Form>
    </GeneralTemplate>
  );
};
export default EditPayTemplate;
