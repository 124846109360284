/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';

import { Form } from 'antd';
import { groupBy, isObject, omit } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Comments, Document, InvoiceProcessed, TotalCard } from '@/components';
import { useFetch, usePayCharge } from '@/hooks';
import { ProviderDetail } from '@/pages/Timesheet/context/ContextProviderDetail';
import { PayrollService, TimesheetService } from '@/services';
import { INVOICE_TYPE } from '@/utils/payrollHelper';

import ListJobTypePayrollAdded from '../ListJobTypePayrollAdded';
import './styles.scss';
import ListJobTypesPayroll from '../ListJobTypesPayroll';
import { useTenantContext } from '../../../../TenantWrapper';

const ACTIONS_TYPE = {
  finalised: 'finalised',
};

const STATUS_CODES = {
  unapproved: {
    content: 'Not Approved - No action taken',
    color: 'green',
  },
  approved: {
    content: 'Approved - Timesheets approved in WebApp',
    color: 'green',
  },
};

const calculatorMargin = (totalCharge, totalPay, totalCosts) => {
  const margin = (
    ((+totalCharge - (+totalPay + +totalCosts)) / totalCharge) *
    100
  ).toFixed(2);
  const result = isNaN(margin) ? 0 : margin;
  return result;
};

const EntryCodes = () => {
  const { currency } = useTenantContext();

  return (
    <div className='card'>
      <div className='title'>
        <span>Payroll Entry Codes</span>
      </div>
      <div className='body'>
        <div className='text'>
          <span>Deduction Hours</span>
          <span>DH</span>
        </div>
        <div className='text'>
          <span>Deduction Money</span>
          <span>D{currency}</span>
        </div>
        <div className='text'>
          <span>Bonus Hours</span>
          <span>BH</span>
        </div>
        <div className='text'>
          <span>Bonus {currency}.</span>
          <span>B{currency}</span>
        </div>
      </div>
    </div>
  );
};

const PayrollDetail = ({ setWeekNumber, setProviderName, weekNumber }) => {
  const { id } = useParams();
  const { currency } = useTenantContext();

  const [nextWeekIds, setNextWeekIds] = useState([]);
  const { providerRateCard } = usePayCharge({ id });

  const queryClient = useQueryClient();

  const { data: dataListPayCharge, refetch: refetchJobTypeAdd } = useFetch(
    ['payCharge', id],
    () => TimesheetService.getTimesheetPositions({ id, resource: 'payroll' }),
  );

  const {
    data,
    isFetching,
    refetch: payrollRefetch,
  } = useFetch(['PayrollDetail', id], () => PayrollService.getOne(id), {
    parentPath: '/payroll',
  });

  const employerId = data?.employer_id;

  const { data: dataInvoiceDetail } = useFetch(
    ['InvoiceDetail', id],
    () => PayrollService.getInvoiceDetails(id),
    {},
  );

  const jobs = data?.jobs || {};
  const providerName = data?.employer_name;
  const weekEndDate = data?.week_end_date;
  const weekStartDate = data?.week_start_date;
  const currentWeekNum = data?.week_num?.current_week_num;
  const dateRange = useMemo(() => data?.date_range ?? [], [data]);

  const [form] = Form.useForm();
  const [formPayCharge] = Form.useForm();

  const updateTimesheetDetail = (jobsSkill) =>
    jobsSkill.reduce((total, timecard) => {
      const isExistTimesheetDetail = total.some(
        (timecardInfo) =>
          timecardInfo.timesheet_detail_id === timecard.timesheet_detail_id,
      );
      if (!isExistTimesheetDetail && isObject(timecard)) {
        const updatedTimecard = {
          timesheet_detail_id: timecard.timesheet_detail_id,
          status: ACTIONS_TYPE.finalised,
        };

        return [...total, updatedTimecard];
      }

      return total;
    }, []);

  const finalise = (formInstance, skillId) => {
    const jobsSkill = formInstance.getFieldValue(skillId) ?? {};

    const timecardsSelected = Object.values(jobsSkill).filter(
      (timecard) => timecard.isChecked,
    );

    const timecardAttributes = timecardsSelected.map((timecard) => {
      const removedKey = omit(timecard, 'key');
      return {
        ...removedKey,
        status: ACTIONS_TYPE.finalised,
      };
    });

    const timesheetDetailsAttributes =
      updateTimesheetDetail(timecardAttributes);

    mutateFinalise({
      timesheetDetailsAttributes,
      timecardAttributes,
    });
  };

  const onUpdateSelected = async (skillId, payrate) => {
    const requests = Object.values(form.getFieldsValue()?.[skillId] || {})
      .filter((item) => item.isChecked && item.id)
      .map((item) => {
        const removeKey = omit({ ...item, adjustedPayrate: payrate }, 'key');
        return removeKey;
      });

    const filterStatus = requests.filter(
      (item) => item.status !== 'finalised' && item.status !== 'paid',
    );
    mutate(filterStatus);
  };

  const onUpdateAll = async (skillId, payrate) => {
    const requests = Object.values(form.getFieldsValue()?.[skillId] || {})
      .filter((item) => item.id)
      .map((item) => {
        const removedKey = omit({ ...item, adjustedPayrate: payrate }, 'key');
        return removedKey;
      });

    const filterStatus = requests.filter(
      (item) => item.status !== 'finalised' && item.status !== 'paid',
    );

    mutate(filterStatus);
  };

  const { mutate, isLoading: updateTimecardLoading } = useMutation(
    (timecardAttributes) =>
      PayrollService.updateTimeCardAttributes({
        payrollId: id,
        timecardAttributes,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['PayrollDetail', id] });
      },
    },
  );

  const {
    mutate: mutateFinalise,
    isLoading,
    isSuccess,
  } = useMutation(
    ({ timesheetDetailsAttributes, timecardAttributes }) =>
      PayrollService.updateTimesheetDetailsAttributes({
        timesheetId: id,
        timesheetDetailsAttributes,
        timecardAttributes,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['PayrollDetail', id] });
      },
    },
  );

  const listTables = [
    {
      status: 'confirmed',
      tableColor: 'blue',
      statusColor: STATUS_CODES.unapproved.color,
      colorButtonApprove: 'yellow',
      textButtonApprove: 'Finalise',
      handleFormFinished: finalise,
    },
    {
      status: 'finalised',
      tableColor: 'red',
      statusColor: STATUS_CODES.approved.color,
      colorButtonSave: 'gray',
      colorButtonApprove: 'gray',
      textButtonApprove: 'Finalised',
    },
  ];

  useEffect(() => {
    if (currentWeekNum) {
      setWeekNumber(currentWeekNum);
    }
    if (providerName) {
      setProviderName(providerName);
    }
  }, [currentWeekNum, dateRange, providerName, setProviderName, setWeekNumber]);

  const isShowInvoiceProcessed =
    !!dataInvoiceDetail?.length &&
    dataInvoiceDetail.some(
      (element) => element.invoiceDetailType === INVOICE_TYPE.COST,
    );
  const groupByDateRange = (jobType) =>
    Object.values(groupBy(jobType, 'date_range_time_card'));
  return (
    <ProviderDetail>
      <div className='timesheet-detail'>
        <Form form={formPayCharge}>
          <div className='row'>
            <div className='left'>
              <ListJobTypePayrollAdded
                className='timesheet-table'
                listJobTypes={dataListPayCharge}
                refetchJobTypeAdd={refetchJobTypeAdd}
                loading={isFetching}
                onUpdateSelected={onUpdateSelected}
                onUpdateAll={onUpdateAll}
                providerRateCard={providerRateCard}
                employerId={employerId}
                weekNumber={weekNumber}
                weekStartDate={weekStartDate}
                weekEndDate={weekEndDate}
                timesheetRefetch={payrollRefetch}
                locationId={data?.location_id}
                jobs={jobs}
              />
            </div>
            <div className='right'>
              <EntryCodes />
            </div>
          </div>
        </Form>

        <div>
          <Form form={form}>
            {Object.keys(jobs).map((status) =>
              Object.keys(jobs[status]).map(
                (jobType) =>
                  jobs[status][jobType] &&
                  groupByDateRange(jobs[status][jobType]).map(
                    (group) =>
                      jobs[status][jobType] && (
                        <div className='row'>
                          <ListJobTypesPayroll
                            {...listTables.find(
                              (item) => item.status === status,
                            )}
                            status={status}
                            labelTotal={jobType}
                            infoDetail={group || []}
                            className='timesheet-table'
                            dateRange={group[0].date_range_time_card}
                            loading={
                              isFetching || isLoading || updateTimecardLoading
                            }
                            nextWeekIds={nextWeekIds}
                            setNextWeekIds={setNextWeekIds}
                            isLoading={isLoading}
                            isSuccess={isSuccess}
                            payrollId={id}
                          />
                        </div>
                      ),
                  ),
              ),
            )}
          </Form>
        </div>

        <div className='row'>
          <div className='column'>
            <Form>
              <Comments />
            </Form>

            <Form>
              <Document />
            </Form>
          </div>

          <div className='column'>
            <div className='total-card-row'>
              <div className='total-card-column'>
                <TotalCard
                  title='Total Costs'
                  amount={`${currency}${(data?.total_cost || 0).toFixed(2)}`}
                />
              </div>
              <div className='total-card-column'>
                <TotalCard
                  title='Total Pay'
                  amount={`${currency}${(data?.total_pay || 0).toFixed(2)}`}
                />
              </div>
              <div className='total-card-column'>
                <TotalCard
                  title='Total Charge'
                  amount={`${currency}${(data?.total_charge || 0).toFixed(2)}`}
                  isRed
                />
              </div>
              <div className='total-card-column'>
                <TotalCard
                  title='Margin'
                  amount={calculatorMargin(
                    data?.total_charge,
                    data?.total_pay,
                    data?.total_cost,
                  )}
                  unit='%'
                />
              </div>
            </div>

            {isShowInvoiceProcessed && (
              <InvoiceProcessed
                data={dataInvoiceDetail}
                totalCost={(data?.total_cost ?? 0) + (data?.total_pay ?? 0)}
              />
            )}
          </div>
        </div>
      </div>
    </ProviderDetail>
  );
};

PayrollDetail.propTypes = {
  breadcrumb: PropTypes.array,
  nextWeekRecords: PropTypes.array,
  setNextWeekRecord: PropTypes.func,
  isEditable: PropTypes.bool,
  weekNumber: PropTypes.number,
  setWeekNumber: PropTypes.func,
  setProviderName: PropTypes.func,
};

PayrollDetail.defaultProps = {
  breadcrumb: [],
  nextWeekRecords: [],
  setNextWeekRecord: () => {},
  isEditable: false,
};
export default PayrollDetail;
