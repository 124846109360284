import React, { useState } from 'react';

import { Form } from 'antd';
import { isEmpty } from 'lodash';

import { useELearnings } from '@/hooks/useCertificates';
import { Checkbox, DatePicker, FormItem, RemoteUploader } from '@/components';

import { AddAnotherButton } from '../AddAnotherButton';

export function ELearningInput() {
  const eLearnings = useELearnings();
  const formInstance = Form.useFormInstance();
  const [selected, setSelected] = useState();

  const handleSelectDoc = (index) => {
    const eLearningDocs = formInstance.getFieldValue('eLearnings') ?? [];

    if (!eLearningDocs) {
      return;
    }

    if (eLearningDocs[index].checked) {
      return;
    }

    setSelected(index);

    const newELearningDocs = eLearningDocs.map((doc, idx) => ({
      ...doc,
      checked: index === idx,
    }));

    formInstance.setFieldsValue({
      eLearnings: newELearningDocs,
    });
  };

  const eLearningDocs = Form.useWatch('eLearnings', formInstance);
  const selectedELearning = eLearningDocs?.[selected];

  const handleOnSuccess = ({ signedId, url }) => {
    const eLearningDocs = formInstance.getFieldValue('eLearnings') ?? [];
    const selectedElearning = eLearningDocs[selected];

    if (!selectedELearning) {
      return;
    }

    const newDocs = eLearningDocs.with(selected, {
      ...selectedElearning,
      attachment: {
        ...selectedElearning.attachment,
        url,
        signedId,
      },
    });

    formInstance.setFieldsValue({
      eLearnings: newDocs,
    });
  };

  return (
    <div className='block-container'>
      <h3>E-Learning</h3>

      <div className='body-block e-learning'>
        <div className='left-block'>
          <div className='documents'>
            <Form.List name='eLearnings' initialValue={[]}>
              {(fields) => (
                <>
                  {fields.map((field, index) => {
                    const value =
                      formInstance.getFieldValue('eLearnings')[index];

                    return (
                      <div className='document-item' key={field.key}>
                        <FormItem name={[field.name, 'attachment']} noStyle />
                        <FormItem name={[field.name, 'name']} noStyle />
                        <FormItem name={[field.name, 'type']} noStyle />
                        <FormItem name={[field.name, 'slug']} noStyle />
                        <FormItem name={[field.name, 'id']} noStyle />

                        <div className='document-item-checkbox'>
                          <Checkbox
                            checked={value.checked}
                            className='item-checkbox'
                            onClick={() => handleSelectDoc(index)}
                          >
                            <div>{value.name}</div>
                            <Checkbox
                              checked={!isEmpty(value.attachment?.url)}
                            />
                          </Checkbox>
                        </div>
                        <FormItem name={[field.name, 'expiresAt']}>
                          <DatePicker name={[field.name, 'expiresAt']} />
                        </FormItem>
                      </div>
                    );
                  })}

                  <AddAnotherButton
                    list={eLearnings}
                    name='eLearnings'
                    type='e-learning'
                  />
                </>
              )}
            </Form.List>
          </div>
        </div>
        <div className='right-block'>
          <RemoteUploader
            disabled={!selectedELearning}
            label={selectedELearning?.name}
            onUploadSuccess={handleOnSuccess}
            file={selectedELearning?.attachment}
          />
        </div>
      </div>
    </div>
  );
}
