import Axios from 'axios';
import { MD5, enc, lib } from 'crypto-js';

import request from '@/request';

function generateFileChecksum(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onload = async (file) => {
      resolve(
        enc.Base64.stringify(MD5(lib.WordArray.create(file.target?.result))),
      );
    };

    fileReader.onerror = () => {
      resolve('');
    };

    fileReader.readAsArrayBuffer(file);
  });
}

export async function getPresignedUrl(file) {
  const checksum = await generateFileChecksum(file);

  const res = await request.post('/presigned_url', {
    checksum,
    byteSize: file.size,
    filename: file.name,
    contentType: file.type,
  });

  const { key, headers, signed_id: signedId, url } = res.data.data;

  return {
    url,
    key,
    headers,
    checksum,
    signedId,
  };
}

export function upload({ url, file, headers }) {
  return Axios.request({
    url,
    method: 'PUT',
    data: file,
    headers: {
      Accept: '*/*',
      'Content-Type': file.type,
      'Content-MD5': headers['Content-MD5'],
    },
  });
}

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}
