import React from 'react';

import { beforeUploadFile } from '@/utils/uploadFileUtil';

import Button from '../Button';
import Upload from '../Upload';
import UploadFileThumbnail from '../UploadFileThumbnail';
import { getPresignedUrl, upload, convertFileToBase64 } from './utils';

import './styles.scss';

export function RemoteUploader({ label, file, disabled, onUploadSuccess }) {
  const customRequest = (data) => {
    getPresignedUrl(data.file).then(({ url, headers, signedId }) => {
      upload({
        url,
        headers,
        file: data.file,
      }).then(async () => {
        const fileUrl = await convertFileToBase64(data.file);

        onUploadSuccess({
          signedId,
          url: fileUrl,
        });
      });
    });
  };

  return (
    <div className='remote-uploader'>
      <p>{label}</p>
      <UploadFileThumbnail src={file?.url} mime={file?.contentType} />
      <Upload
        required
        disabled={disabled}
        showUploadList={false}
        customRequest={customRequest}
        beforeUpload={beforeUploadFile}
      >
        <div className='choose-file'>
          <Button disabled={disabled} className='white-button'>
            Choose File
          </Button>
          {!file?.url && <span>No file chosen</span>}
        </div>
      </Upload>
    </div>
  );
}
