import React, { useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Table, Tooltip, Checkbox as AntdCheckBox, Radio } from 'antd';
import { isEmpty, max, range, sum } from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import {
  Button,
  CheckboxGroup,
  FormItem,
  Input,
  Modal,
  Select,
  Textarea,
} from '@/components';

import {
  columnsAction,
  columsComment,
  listBlock,
  listStatus,
} from './constans';
import './styles.scss';

const options = [
  { label: 'Strike 1', value: 0 },
  { label: 'Strike 2', value: 1 },
  { label: 'Strike 3', value: 2 },
];

const dataProtection = [
  { name: 'isVerifiedStoreAndUse', label: 'Verified Store & Use' },
  {
    name: 'isVerifiedReadTerms',
    label: 'Verified Read Terms & Conditions',
  },
  {
    name: 'isAcceptedReceivingUpdate',
    label: 'Accepted Receiving Updates',
  },
];

const rating = [
  {
    content: (
      <>
        A<sup>*</sup>
      </>
    ),
    color: '#6EB02E',
    value: 5,
  },
  {
    content: 'A',
    color: '#8BC456',
    value: 4,
  },
  {
    content: 'B',
    color: '#FECE57',
    value: 3,
  },
  {
    content: 'C',
    color: '#FC957C',
    value: 2,
  },
  {
    content: 'D',
    color: '#E66960',
    value: 1,
  },
];

const CommentAndStatus = () => {
  const params = useParams();

  const [visibleP45, setVisibleP45] = useState(false);
  const [isNoCommentModal, setIsNoCommentModal] = useState(false);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [is3StrikeModal, setIs3StrikeModal] = useState(false);
  const [isAddStrikeModal, setIsAddStrikeModal] = useState(false);
  const [isAddNewComment, setIsaddNewComment] = useState(false);

  const formInstance = Form.useFormInstance();
  const [oldValueCheckbox, setOldValueCheckbox] = useState(
    formInstance.getFieldValue('strikeStatus'),
  );

  const strikeStatus = Form.useWatch('strikeStatus', formInstance);
  const commentText = Form.useWatch('commentText', formInstance);
  const blockValue = Form.useWatch(
    ['omniUserBlockedOmniEmployeeAttributes', 'blockType'],
    formInstance,
  );
  const employeeStatus =
    Form.useWatch('employeeStatus', formInstance) !== listStatus[0].value;
  const listComment = [];
  const fullCommentsAttributes =
    Form.useWatch('fullCommentsAttributes', formInstance) || [];
  const evaluation = Form.useWatch('evaluation', formInstance) ?? [];

  // TODO: Update feature change Evaluation for seeker
  // const { mutate: mutateChangeEvaluation } = useMutation(changeEvaluation, {
  //   onSuccess: () => message.success('Changed evaluation successfully'),
  //   onError: (error) =>
  //     message.error(error?.message ?? 'Can not change evaluation'),
  // });

  const handleAddComment = () => {
    setIsaddNewComment(true);

    formInstance.setFieldsValue({
      commentsAttributes: [
        {
          content: commentText,
          createdAt: moment(),
        },
        ...listComment,
      ],
      fullCommentsAttributes: [
        {
          content: commentText,
          createdAt: moment(),
        },
        ...fullCommentsAttributes,
      ],
      commentText: '',
    });

    formInstance.setFields([
      {
        name: 'commentText',
        errors: [],
      },
      {
        name: 'strikeStatus',
        touched: false,
      },
      {
        name: ['omniUserBlockedOmniEmployeeAttributes', 'blockType'],
        touched: false,
      },
    ]);
  };

  const handleSetNewStrike = (maxValues) => {
    const newValueCheckbox = range(maxValues + 1);

    setOldValueCheckbox(newValueCheckbox);
    return formInstance.setFieldsValue({
      strikeStatus: newValueCheckbox,
    });
  };

  const handleCheckboxChange = (values) => {
    if (isEmpty(values)) return [];

    let maxValues;

    // Case select strike 2
    if (values.length > oldValueCheckbox.length && values.includes(2)) {
      if (!isAddNewComment) {
        return setIsNoCommentModal(true);
      }
      return setIs3StrikeModal(true);
    }

    const maxNumber = max(values);
    // values is 0 .. n+1 => length of array = max + 1;
    const lengthOfValues = maxNumber + 1;

    // case select strike 0
    if (
      lengthOfValues === 1 &&
      (!oldValueCheckbox.includes(0) || !oldValueCheckbox.includes(1))
    ) {
      return setIsAddStrikeModal(true);
    }
    // case select strike 1
    if (
      lengthOfValues === 2 &&
      (!oldValueCheckbox.includes(0) || !oldValueCheckbox.includes(1))
    ) {
      return setIsAddStrikeModal(true);
    }

    // 3 strikes => values = [0, 1, 2] => sum(values) === 6
    // 3 + 2 = 5
    // 3 + 1 = 4
    // => from and less than 6 means only 2 numbers => not 3 strike
    if (sum(values) <= 3) {
      formInstance.setFieldsValue({
        omniUserBlockedOmniEmployeeAttributes: {
          blockType: null,
        },
      });
    }

    if (max(values) < max(oldValueCheckbox)) {
      maxValues = max(values);
    } else {
      maxValues = Math.abs(sum(oldValueCheckbox) - sum(values));
    }

    return handleSetNewStrike(maxValues);
  };

  const handleClickYesStrike = () => {
    const maxValue = max(formInstance.getFieldValue('strikeStatus'));

    if (maxValue < 2) {
      formInstance.setFieldsValue({
        omniUserBlockedOmniEmployeeAttributes: {
          blockType: null,
        },
      });
    }

    handleSetNewStrike(maxValue);
    setIsAddStrikeModal(false);
    setIs3StrikeModal(false);
  };

  const handleClickNoStrike = () => {
    formInstance.setFieldsValue({
      strikeStatus: oldValueCheckbox,
    });
    setIsAddStrikeModal(false);
  };

  const handleBlockTypeChange = (value) => {
    if (!isAddNewComment && value) {
      return setIsNoCommentModal(true);
    }

    if (value) {
      setIsBlockModal(true);
    }

    return setIsNoCommentModal(false);
  };

  const handleConfirmNoComment = () => {
    formInstance.setFieldsValue({
      omniUserBlockedOmniEmployeeAttributes: {
        blockType: null,
      },
    });

    formInstance.setFieldsValue({ strikeStatus: oldValueCheckbox });

    setIsNoCommentModal(false);
  };

  const handleClickYesBlock = () => {
    if (blockValue === 'strike') {
      setIsaddNewComment(false);
      formInstance.setFieldsValue({ strikeStatus: [0, 1, 2] });
    } else if (strikeStatus.length === 3) {
      formInstance.setFieldsValue({ strikeStatus: [] });
    }

    setIsBlockModal(false);
    setIsAddStrikeModal(false);
    setIs3StrikeModal(false);
  };

  const handleClickNoBlock = () => {
    setIsBlockModal(false);
    setIsAddStrikeModal(false);
    formInstance.setFields([
      {
        name: 'strikeStatus',
        touched: false,
      },
      {
        name: ['omniUserBlockedOmniEmployeeAttributes', 'blockType'],
        value: undefined,
        touched: false,
      },
      {
        name: 'strikeStatus',
        value: [],
      },
    ]);
  };

  // const handleChangeEvaluation = ({ target }) =>
  //   mutateChangeEvaluation({
  //     evaluationText: target.value,
  //     candidateId: id,
  //   });

  useEffect(() => {
    if (strikeStatus?.length === 3) {
      formInstance.setFieldsValue({
        omniUserBlockedOmniEmployeeAttributes: {
          blockType: 'strike',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strikeStatus]);

  return (
    <div className='comment-status'>
      <Modal
        visible={visibleP45}
        onClickNo={() => setVisibleP45(false)}
        title='Request P45?'
      >
        <p className='content-style'>
          Are you sure you want to request the Seekers P45?{' '}
        </p>
        <p className='content-style'>
          They will not be able to book any further work with GIG,.
        </p>
      </Modal>
      {/* 'omniUserBlockedOmniEmployeeAttributes', 'blockType' */}

      <Modal
        visible={isNoCommentModal}
        title='No Comment!'
        footer={
          <Button
            className='yellow-button ok-button'
            onClick={handleConfirmNoComment}
          >
            Ok
          </Button>
        }
        onClickYes={handleConfirmNoComment}
        onClickNo={handleConfirmNoComment}
      >
        You cannot block a Seeker without first adding a comment. Please add a
        comment then change status to block.
      </Modal>
      <Modal
        onClickYes={handleClickYesStrike}
        onClickNo={() => {
          formInstance.setFieldsValue({ strikeStatus: oldValueCheckbox });
          setIs3StrikeModal(false);
        }}
        visible={is3StrikeModal}
        title='3 Strikes?'
      >
        Are you sure you want to add a third strike. This will block the seekers
        access to the App and they will receive an email.
      </Modal>
      <Modal
        title='Block?'
        onClickYes={handleClickYesBlock}
        onClickNo={handleClickNoBlock}
        visible={isBlockModal}
      >
        Are you sure your want to block the seeker for the chosen reason?
      </Modal>
      <Modal
        title='Add a Strike?'
        onClickYes={handleClickYesStrike}
        onClickNo={handleClickNoStrike}
        visible={isAddStrikeModal}
      >
        Are you sure you want to add a Strike to the Seekers profile. They will
        receive an email to let them know.
      </Modal>
      <div>
        <FormItem label='Comments' required name='commentText'>
          <Textarea
            rows={7}
            placeholder='Add comment here'
            maxLength={Number.POSITIVE_INFINITY}
          />
        </FormItem>
        <div className='wrap-btn'>
          <Button
            disabled={!commentText}
            className='btn-req'
            onClick={handleAddComment}
          >
            Add Comments
          </Button>
        </div>
        <FormItem
          name='fullCommentsAttributes'
          label='History'
          rules={[
            {
              required: true,
              message: 'Please add comments history',
            },
          ]}
        >
          <Table
            className='table-comments'
            dataSource={fullCommentsAttributes || []}
            columns={columsComment}
            pagination={false}
            scroll={{ y: 200 }}
          />
        </FormItem>
        <FormItem name='commentsAttributes' label='History' noStyle />

        {/* TODO: This field is new. confirm with PC. */}
        <FormItem name='' label='Actions' className='actions'>
          <Table
            className='table-comments'
            dataSource={[]} // Not know what data is it here.
            columns={columnsAction}
            pagination={false}
            scroll={{ y: 200 }}
          />
        </FormItem>

        <div className='rating-and-protection'>
          <div className='rating'>
            <FormItem
              name={['vacancyApplicantAttributes', 'evaluation']}
              label='Rating'
            >
              <Radio.Group
                // onChange={handleChangeEvaluation}
                value={evaluation}
              >
                {rating.map((item) => (
                  <Radio
                    value={item.value}
                    style={{ backgroundColor: item.color }}
                  >
                    {item.content}
                  </Radio>
                ))}
              </Radio.Group>
            </FormItem>
          </div>
          <div className='protections'>
            <p className='text-label'>Data Protection</p>
            {/* TODO: Move from Past Work Details */}
            {dataProtection.map((item) => (
              <FormItem
                name={item.name}
                valuePropName='checked'
                getValueFromEvent={(e) => (e?.target?.checked ? 1 : 0)}
                key={item.name}
              >
                <AntdCheckBox className='data-protection-checkbox'>
                  {item.label}
                </AntdCheckBox>
              </FormItem>
            ))}
          </div>
        </div>
      </div>
      <div>
        <FormItem label='Internal note from Application' name='internalNote'>
          <Textarea rows={3} placeholder='Note' disabled={!!params?.id} />
        </FormItem>
        <div className='status'>
          <p className='status-label'>Status</p>
          <div className='status-content'>
            <div className='status-fields'>
              <FormItem
                label='Block'
                name={['omniUserBlockedOmniEmployeeAttributes', 'blockType']}
              >
                <Select
                  placeholder='Block'
                  options={listBlock}
                  onChange={handleBlockTypeChange}
                />
              </FormItem>
              <FormItem
                name='employeeStatus'
                label={
                  <div className='employment-formitem'>
                    Employment Status
                    <Tooltip
                      title={
                        <div className='container-status'>
                          <InfoCircleOutlined height={1.25} width={1.25} />
                          <p>
                            Employment Status must be changed to Resigned - P45
                            or Terminated - P45 to Request P45
                          </p>
                        </div>
                      }
                      overlayClassName='employment-status'
                    >
                      <InfoCircleOutlined height={1.25} width={1.25} />
                    </Tooltip>
                  </div>
                }
              >
                <Select placeholder='Employment Status' options={listStatus} />
              </FormItem>

              <div className='wrap-btn'>
                <Button
                  disabled={!employeeStatus}
                  className={!employeeStatus ? 'btn-disable' : 'btn-req'}
                >
                  Reactivate Account
                </Button>
              </div>
            </div>
            <div className='status-note'>
              <p>Note: You must add a comment to action a profile.</p>
              <p>
                If a profile is Blocked choose the reason but also add comment
                for more information.
              </p>
            </div>
          </div>
        </div>
        <div className='strikes'>
          <p>Strikes</p>
          <div className='strikes-box'>
            <FormItem name='strikeStatus'>
              <CheckboxGroup
                options={options}
                onChange={handleCheckboxChange}
              />
            </FormItem>
            <div className='strike-inputs'>
              <FormItem name='strikeOne'>
                <Input />
              </FormItem>
              <FormItem name='strikeTwo'>
                <Input />
              </FormItem>
              <FormItem name='strikeThree'>
                <Input />
              </FormItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentAndStatus;
