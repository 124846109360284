import React from 'react';

import { Form, message } from 'antd';
import classNames from 'classnames';
import { isEmpty, startCase } from 'lodash';
import PropTypes from 'prop-types';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import {
  Button,
  Checkbox,
  FormItem,
  InputNumber,
  ModalConfirm,
  MultipleSelect,
  Select,
  Textarea,
} from '@/components';
import { LICENCES } from '@/constants';
import { array, getValueFromList } from '@/utils';
import { rulesDescription, rulesSelect } from '@/utils/rulesInput';
import useModal from '@/utils/useModal';

const TemplateDetail = ({
  jobTemplatesAttributes,
  jobTemplates,
  selectedTemplate,
  uniforms,
  listUniforms,
  listProviders,
  listSkill,
  disableSaveTemplate,
  mutateSaveTemplate,
}) => {
  const formInstance = Form.useFormInstance();
  const [isDisplayModal, showModal, hiddenModal] = useModal();

  const onChangeCheckbox = (key, value) => {
    formInstance.setFields([{ name: key, value }]);
  };

  const handleAddPreRequisit = ({
    employerId,
    skillId,
    preRequistAttributes,
    index,
    preRequisitJobTemplatesAttributes,
  }) => {
    const isSameItem = preRequistAttributes.some(
      (item) => item.employerId === employerId && item.skillId === skillId,
    );

    if (isSameItem) return message.warn('this provider and position existed');

    return formInstance.setFields([
      {
        name: [
          'jobTemplatesAttributes',
          index,
          'preRequisitJobTemplatesAttributes',
        ],
        value: [...preRequisitJobTemplatesAttributes, { employerId, skillId }],
      },
      {
        name: ['jobTemplatesAttributes', index, 'employerId'],
        value: undefined,
      },
      {
        name: ['jobTemplatesAttributes', index, 'skillId'],
        value: undefined,
      },
    ]);
  };

  const handleRemovePreRequisit = (
    preRequistAttributes,
    attributeIndex,
    index,
  ) => {
    const updatedItem = preRequistAttributes.map((item, itemIndex) => {
      if (itemIndex === attributeIndex) {
        return { ...item, _destroy: true };
      }
      return item;
    });
    formInstance.setFields([
      {
        name: [
          'jobTemplatesAttributes',
          index,
          'preRequisitJobTemplatesAttributes',
        ],
        value: updatedItem,
      },
    ]);
  };

  const handleRemoveLicence = (indexLicenses, indexRemoveItem, data) => {
    data.splice(indexRemoveItem, 1);
    formInstance.setFields([
      {
        name: ['jobTemplatesAttributes', indexLicenses, 'licenses'],
        value: data,
      },
    ]);
  };

  const index = jobTemplates.findIndex((item) =>
    selectedTemplate?.id
      ? selectedTemplate.id === item.id
      : item.positionId === selectedTemplate?.positionId &&
        item.locationId === selectedTemplate?.locationId,
  );

  const currentTemplateDetail =
    formInstance.getFieldValue(['jobTemplatesAttributes', index]) ?? {};

  const saveTemplate = () => {
    hiddenModal();
    mutateSaveTemplate(currentTemplateDetail);
  };

  const {
    licenses,
    employerId,
    skillId,
    preRequisitJobTemplatesAttributes = [],
    additionalUniform = [],
    shirt,
    jean,
    shoe,
    isOver18,
    allowWithoutLicences,
    jobTemplatesUniformsAttributes = [],
  } = currentTemplateDetail;

  const preRequistAttributes = preRequisitJobTemplatesAttributes.filter(
    // eslint-disable-next-line no-underscore-dangle
    (item) => !item._destroy,
  );

  if (index >= 0) {
    const updatedUniforms = [];
    const formJobTemplatesUniformsAttributes =
      array
        .remove(additionalUniform, 'all')
        ?.map((uniformId) => ({ uniformId })) ?? [];

    if (shirt)
      formJobTemplatesUniformsAttributes.push({
        uniformId: shirt,
      });
    if (jean)
      formJobTemplatesUniformsAttributes.push({
        uniformId: jean,
      });
    if (shoe)
      formJobTemplatesUniformsAttributes.push({
        uniformId: shoe,
      });

    formJobTemplatesUniformsAttributes.map((item) => {
      const uniform = jobTemplatesUniformsAttributes.find(
        (ele) => ele.uniformId === item.uniformId,
      );
      if (uniform) {
        // eslint-disable-next-line no-underscore-dangle
        delete uniform._destroy;
      }

      return updatedUniforms.push(uniform ?? item);
    });
    jobTemplatesUniformsAttributes
      .filter(
        (item) =>
          !formJobTemplatesUniformsAttributes.some(
            (ele) => ele.uniformId === item.uniformId,
          ),
      )
      .map((item) => updatedUniforms.push({ ...item, _destroy: true }));

    formInstance.setFields([
      {
        name: [
          'jobTemplatesAttributes',
          index,
          'jobTemplatesUniformsAttributes',
        ],
        value: updatedUniforms,
      },
    ]);
  }

  return (
    <>
      <Form.List name='jobTemplatesAttributes'>
        {() =>
          index >= 0 &&
          !isEmpty(jobTemplatesAttributes) && (
            <div className={classNames('right-section')}>
              <h3 className='sub-title'>Job Template</h3>
              <div className='right-body'>
                <FormItem name={[index, 'positionId']} noStyle />
                <FormItem name={[index, 'locationId']} noStyle />
                <FormItem name={[index, 'id']} noStyle />
                <div className='row-container'>
                  {uniforms.map(({ name, placeholder, options, label }) => (
                    <FormItem
                      key={placeholder}
                      label={label}
                      name={[index, name]}
                      required
                      rules={rulesSelect(label)}
                    >
                      <Select
                        placeholder={placeholder}
                        options={options}
                        disabled={!jobTemplates[index]?.isEdit}
                      />
                    </FormItem>
                  ))}
                </div>
                <div className='row-container'>
                  <div className='row-item'>
                    <FormItem
                      name={[index, 'additionalUniform']}
                      label='Additional Uniform'
                    >
                      <MultipleSelect
                        placeholder='Choose Additional'
                        className='uniform'
                        options={listUniforms?.listAdditionalUniforms}
                        name={[
                          'jobTemplatesAttributes',
                          index,
                          'additionalUniform',
                        ]}
                        disabled={!jobTemplates[index]?.isEdit}
                      />
                    </FormItem>
                  </div>
                  <div className='row-item'>
                    <div className='checkbox-wrapper'>
                      <FormItem name={[index, 'isOver18']}>
                        <Checkbox
                          className='custom-checkbox'
                          checked={isOver18}
                          onChange={(event) =>
                            onChangeCheckbox(
                              ['jobTemplatesAttributes', index, 'isOver18'],
                              event.target.checked,
                            )
                          }
                          disabled={!jobTemplates[index]?.isEdit}
                        />
                      </FormItem>
                    </div>
                    <div className='checkbox-label-wrapper'>
                      <div className='sub-title'>Over 18 Only</div>
                    </div>
                  </div>
                  <div className='row-item'>
                    <FormItem name={[index, 'breakTime']} label='Break'>
                      <InputNumber
                        className='custom-input'
                        placeholder='Break'
                        disabled={!jobTemplates[index]?.isEdit}
                        size='large'
                      />
                    </FormItem>
                  </div>
                </div>
                <FormItem
                  name={[index, 'description']}
                  label='Job Description'
                  required
                  rules={rulesDescription()}
                >
                  <Textarea
                    placeholder='Job Description'
                    disabled={!jobTemplates[index]?.isEdit}
                  />
                </FormItem>
                <FormItem
                  name={[index, 'specialRequirements']}
                  label='Special Requirements'
                >
                  <Textarea
                    placeholder='Special Requirements'
                    disabled={!jobTemplates[index]?.isEdit}
                  />
                </FormItem>
                <div className='requirement-item-container'>
                  <div className='sub-title'>
                    Pre-Requisit Shift Requirements
                  </div>
                  <div className='row-container'>
                    <div className='requirement-item'>
                      <FormItem name={[index, 'employerId']} label='Provider'>
                        <Select
                          options={listProviders}
                          placeholder='Provider'
                          disabled={!jobTemplates[index]?.isEdit}
                        />
                      </FormItem>
                    </div>
                    <div className='requirement-item'>
                      <FormItem
                        name={[index, 'skillId']}
                        label='Position Needed'
                      >
                        <Select
                          options={listSkill}
                          placeholder='Position Needed'
                          disabled={!jobTemplates[index]?.isEdit}
                        />
                      </FormItem>
                    </div>
                    <Button
                      className='yellow-button'
                      disabled={!employerId || !skillId}
                      onClick={() =>
                        handleAddPreRequisit({
                          employerId,
                          index,
                          preRequisitJobTemplatesAttributes,
                          skillId,
                          preRequistAttributes,
                        })
                      }
                    >
                      Add
                    </Button>
                  </div>
                  <Form.List
                    name={[index, 'preRequisitJobTemplatesAttributes']}
                  >
                    {() =>
                      preRequistAttributes.map((attr, attributeIndex) => (
                        <div className='row-container'>
                          <span className='requirement-item'>
                            {
                              getValueFromList(listProviders, attr.employerId)
                                ?.label
                            }
                          </span>
                          <span className='requirement-item'>
                            {getValueFromList(listSkill, attr.skillId)?.label}
                          </span>
                          <span className='requirement-item'>
                            <XIconBlack
                              disabled={!jobTemplates[index]?.isEdit}
                              onClick={() =>
                                handleRemovePreRequisit(
                                  preRequistAttributes,
                                  attributeIndex,
                                  index,
                                )
                              }
                            />
                          </span>
                        </div>
                      ))
                    }
                  </Form.List>
                </div>
                <div className='requirement-item-container'>
                  <h3 className='sub-title'>
                    Pre-Requisit Training Requirements
                  </h3>
                  <div className='row-container start'>
                    <FormItem
                      className='requirement-item'
                      label='Licences and Training'
                      name={[index, 'licenses']}
                    >
                      <MultipleSelect
                        options={LICENCES}
                        placeholder='Select Licence Type'
                        name={['jobTemplatesAttributes', index, 'licenses']}
                        disabled={!jobTemplates[index]?.isEdit}
                      />
                    </FormItem>
                    <div className='requirement-item training'>
                      {licenses
                        ?.filter((e) => e !== 'all')
                        .map((item, indexItems) => (
                          <div className='items' key={item}>
                            <span className='item-text'>{startCase(item)}</span>
                            <XIconBlack
                              onClick={() => {
                                handleRemoveLicence(
                                  index,
                                  indexItems,
                                  licenses,
                                );
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='row-container row-container-footer'>
                    <div className='footer-left'>
                      <div className='footer-left--first-col'>
                        Allow workers without the above licences to see the job
                        posting
                      </div>
                      <div className='footer-left--second-col'>
                        <FormItem name={[index, 'allowWithoutLicences']}>
                          <Checkbox
                            disabled={!jobTemplates[index]?.isEdit}
                            onChange={(event) =>
                              onChangeCheckbox(
                                [
                                  'jobTemplatesAttributes',
                                  index,
                                  'allowWithoutLicences',
                                ],
                                event.target.checked,
                              )
                            }
                            checked={allowWithoutLicences}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className='footer-right'>
                      <Button
                        className='yellow-button'
                        disabled={disableSaveTemplate(index)}
                        onClick={showModal}
                        block
                      >
                        Save Template
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Form.List>

      <ModalConfirm
        visible={isDisplayModal}
        title='Save Job Template'
        titleSuccess='Save Job Template workers successfully'
        onClickNo={hiddenModal}
        onClickYes={saveTemplate}
      >
        Are you sure you want to save the Job Template.
        <br />
        <br />
        This can be edited or updated at the job posting.
      </ModalConfirm>
    </>
  );
};

TemplateDetail.propTypes = {
  jobTemplatesAttributes: PropTypes.array,
  jobTemplates: PropTypes.array,
  selectedTemplate: PropTypes.object,
  uniforms: PropTypes.array,
  listUniforms: PropTypes.array,
  listProviders: PropTypes.array,
  listSkill: PropTypes.array,
  disableSaveTemplate: PropTypes.func,
  mutateSaveTemplate: PropTypes.func,
};

export default TemplateDetail;
