import React from 'react';

import { Form } from 'antd';
import { useParams } from 'react-router-dom';

import { useFetch, usePagination } from '@/hooks';
import getAttendances from '@/services/seeker/getAttendances';

import Options from './Options';
import AttendanceView from './View';

import './styles.scss';

const Attendance = () => {
  const [form] = Form.useForm();

  const { id: seekerId } = useParams();

  const {
    currentPage,
    handlePagination,
    paginationPageSize,
    handleShowSizeChange,
  } = usePagination();

  const {
    data: attendances,
    refetch: attendanceRefetch,
    isLoading,
  } = useFetch(
    ['seekerProfile', 'attendance', seekerId, paginationPageSize, currentPage],
    () =>
      getAttendances({
        seekerId,
        filter: form.getFieldValue('options'),
        page: {
          size: paginationPageSize,
          number: currentPage,
        },
      }),
  );

  const onFilter = () => attendanceRefetch();

  const onClear = () => {
    form.resetFields();
    attendanceRefetch();
  };

  return (
    <Form className='attendance-container' form={form} onFinish={onFilter}>
      <Options onClear={onClear} isLoading={isLoading} />
      <AttendanceView
        isLoading={isLoading}
        attendances={attendances}
        currentPage={currentPage}
        handlePagination={handlePagination}
        handleShowSizeChange={handleShowSizeChange}
      />
    </Form>
  );
};

export default Attendance;
