import React from 'react';

import { Tag } from 'antd';
import PropTypes from 'prop-types';

import { ListView } from '@/components';

import { INVOICE_TYPES } from '../../constants';

import './styles.scss';

const InvoicesTable = ({
  type,
  infoDetail,
  isFetching,
  handleSort,
  headerTable,
}) => (
  <div className='table-section'>
    <Tag className='tag-label'>{INVOICE_TYPES[type].label}</Tag>
    <ListView
      className='invoice-listview'
      headerColumns={headerTable}
      infoDetail={infoDetail}
      locale={{
        emptyText: 'No Invoices match your search criteria',
      }}
      handleSort={handleSort}
      loading={isFetching}
    />
  </div>
);

export default InvoicesTable;

InvoicesTable.propTypes = {
  type: PropTypes.string,
  isFetching: PropTypes.bool,
  handleSort: PropTypes.func,
  headerTable: PropTypes.object,
  infoDetail: PropTypes.object,
};

InvoicesTable.defaultProps = {
  type: '',
  infoDetail: [],
  headerTable: [],
  isFetching: false,
  handleSort: () => {},
};
