/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Radio } from '@/components';

import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const Declarations4 = ({
  form,
  initialValues,
  onValuesChange,
  submit,
  isLoading,
}) => {
  const tenant = useTenantContext();
  const { isAcceptedReceivingUpdate } = initialValues;

  return (
    <div className='flex-one-column declarations-4'>
      <div className='title'>Declarations</div>
      <p>
        It is important to us that the people on our platform are honest,
        hard-working and doing a good job.
      </p>
      <p>With this in mind, please be aware that:</p>
      <ul className='list-policy'>
        <li>
          If it becomes apparent that you have not been honest regarding your
          level of experience, qualifications or references, your profile will
          be removed and you will no longer have access the platform.
        </li>
        <li>
          If you fail to turn up for a shift without notifying {tenant.name} and
          without a relevant excuse, your profile may be suspended or
          permanently blocked.
        </li>
        <li>
          If you cancel a shift with less than 24 hours notice, your account may
          be suspended or permanently blocked at the discretion of our Account
          Managers.
        </li>
        <li>
          If you are consistently late to work or show poor conduct during
          working hours then your account may be suspended or permanently
          blocked at the discretion of our Account Managers.
        </li>
        <li>
          If you are registering for any promotional offers, you will need to
          make the recruitment team aware of this before signing up.
        </li>
        <li>
          If it becomes apparent that you have not been honest regarding your
          level of experience, qualifications or references, your profile will
          be removed and you will no longer have access the platform.
        </li>
      </ul>
      <Form
        form={form}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        onFinish={submit}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='detail-form-container'
      >
        <FormItem
          rules={[
            {
              required: true,
              message: 'T&C’s and Privacy Policy must be accepted',
            },
          ]}
          name='isAcceptedReceivingUpdate'
          className='recruitment-radio'
        >
          <Radio
            className='check-policy'
            options={[
              {
                label:
                  'I accept the above terms and conditions regarding attendance and pay*',
                value: true,
              },
            ]}
          />
        </FormItem>
        <Button
          loading={isLoading}
          htmlType='submit'
          className='shared-button confirm-button '
          disabled={!isAcceptedReceivingUpdate}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

Declarations4.propTypes = {
  submit: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Declarations4;
