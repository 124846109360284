import { first, last } from 'lodash';
import moment from 'moment';

import { ORDER_BY, ORDER_DIRECTION } from '@/constants';
import request from '@/request';
import Formatter from '@/utils/Formatter';
import { FormatIndexStrategy, Normalize } from '@/utils/normalizeResponse';

const MS_PER_HOUR = 60 * 60 * 1000;
const MIN_PER_HOUR = 60;

export async function getJobsSeeker({
  id,
  sortOrder,
  orderBy,
  pageNumber,
  pageSize,
  filter,
}) {
  const page = {
    size: pageSize,
    number: pageNumber,
  };

  const order = {
    orderBy: orderBy || ORDER_BY,
    orderDirection: sortOrder || ORDER_DIRECTION,
  };

  const endpoint = `seekers/employees/${id}/jobs`;
  const res = await request.get(endpoint, {
    params: {
      page,
      filter,
      order,
    },
  });
  const normalize = new Normalize();
  const strategy = new FormatIndexStrategy(res);
  normalize.setStrategy(strategy);
  const dataJobs = normalize.formatResponse();

  const data = dataJobs?.data.map((dt) => {
    const isMultiShift = dt?.schedule?.items?.length > 1;
    const startUnixTime = first(dt?.schedule?.items)?.startAt;
    const endUnixTime = last(dt?.schedule?.items)?.endAt;
    const startDate = moment.unix(startUnixTime).format('DD/MM/YYYY');
    const endDate = isMultiShift
      ? moment.unix(endUnixTime).format('DD/MM/YYYY')
      : undefined;
    const time = !isMultiShift
      ? `${moment.unix(startUnixTime).format('HH:mm')} - ${moment
          .unix(endUnixTime)
          .format('HH:mm')}`
      : undefined;
    const totalHours = dt?.schedule?.items.reduce(
      (total, val) =>
        total +
        moment.unix(val?.endAt).diff(moment.unix(val?.startAt)) / MS_PER_HOUR -
        (dt?.breakTime ?? 0) / MIN_PER_HOUR,
      0,
    );

    const { payrate } = dt;
    const netPay = payrate * totalHours;
    const grossPay = parseFloat(netPay * 1.2).toFixed(2);
    const createdAt = moment(dt.createdAt).format('DD/MM/YYYY - HH:mm');

    return {
      ...dt,
      grossPay,
      netPay,
      createdAt,
      scheduleItem: {
        startDate,
        endDate,
        time,
        totalHours: Number(Formatter.fixedNumber({ value: totalHours })),
      },
    };
  });

  return { ...dataJobs, data };
}
