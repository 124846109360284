import React, { useState } from 'react';

import { Form } from 'antd';

import { FormItem, AutoComplete, Button, Image } from '@/components';
import PostCodeUK from '@/components/PostCodeUK';
import Upload from '@/components/Upload';
import { ACCEPT_TYPE } from '@/constants';
import { useUploadFileS3 } from '@/hooks/useUploadFile';
import {
  rulesPostcode,
  rulesCompanyNumber,
  rulesVatNumber,
  rulesTextInput,
} from '@/utils/rulesInput';
import { beforeUploadFile, dummyRequest } from '@/utils/uploadFileUtil';

import './styles.scss';

const CompanyInformation = () => {
  const formInstance = Form.useFormInstance();
  const [prevBase64, setPrevBase64] = useState();

  const [fileState, setFileState] = useState({
    field: '',
    label: '',
    value: undefined,
    file: undefined,
  });
  const { base64, setBase64, fileList, changeFile, file, setFile } =
    useUploadFileS3();

  if (base64 && fileState.field && prevBase64 !== base64) {
    const logoThumbnail = formInstance.getFieldValue('logoThumbnail') ?? '';

    const updatedAvatar = {
      ...logoThumbnail,
      fileValue: base64,
      fileBlob: file,
    };

    formInstance.setFieldsValue({
      logoThumbnail: updatedAvatar,
    });

    setFileState((prev) => ({ ...prev, value: base64, file }));
    setBase64();
    setFile();
    setPrevBase64(base64);
  }

  return (
    <div className='company-information'>
      <div className='company-information__wrapper'>
        <div className='company-information__wrapper-container'>
          <FormItem
            name='providerName'
            className='form-item form-item__width--half'
            label='Provider Name - As shown on the App'
            required
            rules={rulesTextInput('Provider Name')}
          >
            <AutoComplete placeholder='Name' />
          </FormItem>
          <FormItem
            name='registeredCompanyName'
            className='form-item form-item__width--half'
            label='Registered Company Name '
            required
            rules={rulesTextInput('Registered Company Name')}
          >
            <AutoComplete placeholder='Registered Company Name ' />
          </FormItem>
          <FormItem
            name='companyNumber'
            className='form-item form-item__width--half'
            label='Company Number'
            required
            rules={rulesCompanyNumber()}
          >
            <AutoComplete placeholder='Company Number' />
          </FormItem>
          <FormItem
            name='vatNumber'
            className='form-item form-item__width--half'
            label='VAT Number'
            required
            rules={rulesVatNumber()}
          >
            <AutoComplete placeholder='VAT number' />
          </FormItem>
          <FormItem
            name='customerSageCode'
            className='form-item form-item__width--half'
            label='Customer SAGE Number'
            required
            rules={rulesTextInput('SAGE code')}
          >
            <AutoComplete placeholder='Sage Code' />
          </FormItem>
        </div>
        <div className='company-information__wrapper-container'>
          <FormItem
            name='building'
            className='form-item form-item__width--half'
            label='Registered Company Address - Building'
            required
            rules={rulesTextInput('Head of Address - Building')}
          >
            <AutoComplete placeholder='Building' />
          </FormItem>
          <FormItem
            name='street'
            className='form-item form-item__width--half'
            label='Street'
            required
            rules={rulesTextInput('Street')}
          >
            <AutoComplete placeholder='Street' />
          </FormItem>
          <FormItem
            name='city'
            className='form-item form-item__width--half'
            label='City'
            required
            rules={rulesTextInput('City')}
          >
            <AutoComplete placeholder='City' />
          </FormItem>
          <FormItem
            name='postCode'
            className='form-item form-item__width--half'
            label='Post Code'
            required
            rules={rulesPostcode(formInstance, 'postCode')}
          >
            <PostCodeUK />
          </FormItem>
          <div className='row-image-container'>
            <div className='left'>
              <Image
                src={base64 ?? formInstance.getFieldValue('logoThumbnail')}
                containerClassName='avatar-container'
                className='avatar'
              />
              <FormItem name='logoThumbnail' label='Client Logo'>
                <Upload
                  name='logoThumbnail'
                  showUploadList={false}
                  accept={ACCEPT_TYPE.allImage}
                  customRequest={dummyRequest}
                  beforeUpload={beforeUploadFile}
                  onChange={changeFile}
                  fileList={fileList}
                  maxCount={1}
                >
                  <div className='choose-file'>
                    <Button className='white-button'>Choose File</Button>
                  </div>
                </Upload>
              </FormItem>
            </div>
            <FormItem
              name='clientNumber'
              className='margin-left form-item form-item__width--half form-item--disabled'
              label='Client Number '
            >
              <div className='typography'>
                {formInstance.getFieldValue('clientNumber')}
              </div>
            </FormItem>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyInformation;
