import React from 'react';

import { Form } from 'antd';
import { useParams } from 'react-router-dom';

import { FormItem } from '@/components';

import { useTenantContext } from '../../../../../TenantWrapper';

const Header = () => {
  const { id } = useParams();
  const { mainLogo, invoiceInfo = {} } = useTenantContext();
  const formInstance = Form.useFormInstance();
  const { contact = {}, address = {} } = invoiceInfo;
  const { website, email, phoneNumber } = contact;
  const { buildingNumber, street, city, postcode } = address;

  const postCode = Form.useWatch('postCode', formInstance);
  const invoiceDate = Form.useWatch('invoiceDate', formInstance);
  const providerName = Form.useWatch('providerName', formInstance);
  const providerLocation = Form.useWatch('providerLocation', formInstance);
  const customerSageCode = Form.useWatch('customerSageCode', formInstance);
  return (
    <div className='preview-invoice-header'>
      <img className='gig-logo' src={mainLogo.base64} alt='logo' />
      <p className='text'>{website}</p>
      <p className='text'>
        {[buildingNumber, street].filter((s) => !!s).join(' ')}
      </p>
      <p className='text'>{city}</p>
      <p className='text'>{postcode}</p>
      <p className='email-info'>
        {phoneNumber && <span>T: {phoneNumber}</span>}
        {email && <span style={{ marginLeft: '5px' }}>E: {email}</span>}
      </p>
      <div className='sub-info-container'>
        <div className='info'>
          <p className='text'>{providerName}</p>
          <p className='text'>{providerLocation}</p>
          <FormItem name='postCode'>
            <p className='text'>{postCode}</p>
          </FormItem>
        </div>
        <div className='provider-name'>{providerName}</div>
        <div className='info'>
          <p className='text'>Invoice No: {id}</p>
          <p className='text'>Date/Tax Point: {invoiceDate}</p>
          <FormItem name='customerSageCode'>
            <p className='text'>Account: {customerSageCode}</p>
          </FormItem>
        </div>
      </div>
    </div>
  );
};

export default Header;
