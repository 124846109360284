import React from 'react';

import PropTypes from 'prop-types';

import { FormItem, Input, TimePicker } from '@/components';
import { date } from '@/utils';
import { rulesTextInput } from '@/utils/rulesInput';
import './styles.scss';

const InputDropdown = ({ handleChange, setStartTime, setEndTime }) => {
  const renderInputItems = () => (
    <div className='contract-type-container'>
      <FormItem
        name={['contactTypeAttributes', 'name']}
        className='contract-input'
        label='Contract Type'
        required
        rules={rulesTextInput('Contract Type')}
      >
        <Input placeholder='contract type' />
      </FormItem>
      <div className='date-container'>
        <div className='seeker-text-label'>Shift Time</div>
        <div className='date-items'>
          <FormItem
            className='seeker-date-item'
            name='registrationDateTo'
            normalize={date.convertDateTime}
          >
            <TimePicker
              format='HH:mm'
              placeholder='start time'
              onChange={(time, timeString) => setStartTime(timeString)}
            />{' '}
          </FormItem>
          <FormItem
            className='seeker-date-item'
            name='registrationDateFrom'
            normalize={date.convertDateTime}
          >
            <TimePicker
              format='HH:mm'
              placeholder='End time'
              onChange={(time, timeString) => setEndTime(timeString)}
            />{' '}
          </FormItem>
        </div>
      </div>
    </div>
  );

  return <>{renderInputItems()}</>;
};

InputDropdown.propTypes = {
  handleChange: PropTypes.func,
  setStartTime: PropTypes.func,
  setEndTime: PropTypes.func,
};

InputDropdown.defaultProps = {
  handleChange: () => {},
  setStartTime: () => {},
  setEndTime: () => {},
};

export default InputDropdown;
