import request from '@/request';

const update = async (
  /** @type {any of number || string} */ id,
  /** @type {any} */ params,
) => {
  const res = await request.patch(`/trackers/expense/costs/${id}`, {
    cost: params,
  });

  return res.data;
};

export default update;
