import React from 'react';

import { Checkbox, Popconfirm, Tag, Tooltip } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from '@/components';
import {
  STATUS_UPDATE_TYPE,
  STATUS_UPDATE_TYPE_PAYROLL,
} from '@/pages/PayTemplate/constants';

const TotalHeader = ({
  statusColor = '',
  status = '',
  panelInfo = {},
  updateStatusMutation,
  isUpdateStatusLoading,
}) => {
  const totalPay = get(panelInfo, 'totalPay', 0);
  const totalCharge = get(panelInfo, 'totalCharge', 0);
  const totalHours = get(panelInfo, 'totalValue', 0);

  const listDisabledStatus = [
    STATUS_UPDATE_TYPE.finalised.status,
    STATUS_UPDATE_TYPE.paid.status,
  ];

  const buttonLabel =
    status === STATUS_UPDATE_TYPE_PAYROLL.confirmed.status
      ? 'Finalise'
      : STATUS_UPDATE_TYPE_PAYROLL[status].contentOnHeader;

  const isDisabledActions = listDisabledStatus.includes(status);

  const handleActionOnPanel = ({ actionType }) =>
    updateStatusMutation({ panelInfo, actionType });

  return (
    <div className='total-cards-container'>
      <Tag className='sm-width'>
        <Tooltip
          placement='topRight'
          title={`Total Hours: ${panelInfo.totalValue}`}
        >
          Total Hours: {totalHours}
        </Tooltip>
      </Tag>
      <Tag className='md-width'>
        <Tooltip placement='topRight' title={`Total Pay: £${totalPay}`}>
          Total Pay: £{totalPay}
        </Tooltip>
      </Tag>
      <Tag className='lg-width'>
        <Tooltip placement='topRight' title={`Total Pay: £${totalCharge}`}>
          Total Charge: £{totalCharge}
        </Tooltip>
      </Tag>
      <div className='btn-container'>
        <Popconfirm
          title='Do you want to update?'
          onConfirm={(e) => {
            handleActionOnPanel({
              actionType: STATUS_UPDATE_TYPE_PAYROLL[status].action,
            });
            e.stopPropagation();
          }}
          onCancel={(e) => e.stopPropagation()}
          disabled={isDisabledActions}
        >
          <Button
            className={`${status}-btn`}
            loading={isUpdateStatusLoading}
            onClick={(e) => e.stopPropagation()}
          >
            {buttonLabel}
          </Button>
        </Popconfirm>
      </div>

      <div className='timesheets-status'>
        <Checkbox className={`status-${statusColor}`} checked />
      </div>
    </div>
  );
};

export default TotalHeader;

TotalHeader.propTypes = {
  status: PropTypes.string,
  panelInfo: PropTypes.object,
  statusColor: PropTypes.string,
  updateStatusMutation: PropTypes.func,
  isUpdateStatusLoading: PropTypes.bool,
};

TotalHeader.defaultProps = {
  status: '',
  panelInfo: {},
  statusColor: '',
  updateStatusMutation: () => {},
  isUpdateStatusLoading: false,
};
