/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Button as AntdButton } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

const Button = ({ children, className, ...props }) => (
  <AntdButton className={classNames('input-height', className)} {...props}>
    {children}
  </AntdButton>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Button.defaultProps = {
  className: '',
};

export default Button;
