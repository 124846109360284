import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Input } from '@/components';

import { BREAK_DEDUCTED_OPTIONS, payrollTemplateAttr } from '../../constants';

const RulesView = ({ isAppliedRules, setShowModal }) => {
  const formInstance = Form.useFormInstance();
  const breakPaid = Form.useWatch(
    [payrollTemplateAttr, 'breakPaid'],
    formInstance,
  );
  const deductedFrom =
    Form.useWatch([payrollTemplateAttr, 'deductedFrom'], formInstance) ?? 0;

  return (
    <div className='pay-templates-rule'>
      <div>Rate rules</div>
      <div>
        Rate determined by shift booked start and end time Unless - Flexible Pay
        is selected at shift posting.
      </div>
      {isAppliedRules ? (
        <>
          <div>
            <FormItem name={[payrollTemplateAttr, 'breakPaid']} hidden />
            Breaks are {!breakPaid && 'not'} paid
          </div>
          <div className='rule-row'>
            <div>Breaks are</div>
            <FormItem
              className='rule-form-item'
              name={[payrollTemplateAttr, 'breakDuration']}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>mins</div>
          </div>
          <div className='rule-row'>
            <FormItem name={[payrollTemplateAttr, 'deductedFrom']} hidden />
            <div>Breaks are deducted from the</div>
            <div className='deducted-from'>
              {
                BREAK_DEDUCTED_OPTIONS.find(
                  (breakRule) => breakRule.value === deductedFrom,
                )?.label
              }
            </div>
            <div>of the shift</div>
          </div>
          <div className='rule-row'>
            <div>Breaks apply to shifts longer than</div>
            <FormItem
              className='rule-form-item'
              name={[payrollTemplateAttr, 'thresholdApply']}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>hours</div>
          </div>
          <div className='rule-row'>
            <div>Overtime is paid after</div>
            <FormItem
              className='rule-form-item'
              name={[payrollTemplateAttr, 'overtimePaidAfter', 'hrs']}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>hours</div>
            <FormItem
              className='rule-form-item'
              name={[payrollTemplateAttr, 'overtimePaidAfter', 'mins']}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>minutes</div>
          </div>
          <div className='rule-row'>
            <div>Guaranteed minimum shift length</div>
            <FormItem
              className='rule-form-item'
              name={[
                payrollTemplateAttr,
                'guaranteedMinimumShiftLength',
                'hrs',
              ]}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>hours</div>
            <FormItem
              className='rule-form-item'
              name={[
                payrollTemplateAttr,
                'guaranteedMinimumShiftLength',
                'mins',
              ]}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>minutes</div>
          </div>
          <div className='rule-row'>
            <div>Guaranteed minimum Weekly Hours</div>
            <FormItem
              className='rule-form-item'
              name={[
                payrollTemplateAttr,
                'guaranteedMinimumWeeklyHours',
                'hrs',
              ]}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>hours</div>
            <FormItem
              className='rule-form-item'
              name={[
                payrollTemplateAttr,
                'guaranteedMinimumWeeklyHours',
                'mins',
              ]}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>minutes</div>
          </div>
          <div className='rule-row'>
            <div>Annual holiday allowance</div>
            <FormItem
              className='rule-form-item'
              name={[payrollTemplateAttr, 'annualHolidayAllowance']}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>days</div>
          </div>
          <div className='rule-row'>
            <div>Holiday hours per day</div>
            <FormItem
              className='rule-form-item'
              name={[payrollTemplateAttr, 'holidayHoursPerDay']}
            >
              <Input className='rule-input' readOnly />
            </FormItem>
            <div>hours</div>
          </div>
        </>
      ) : (
        <div>No Rules set</div>
      )}
      <div>
        <Button className='edit-btn' onClick={() => setShowModal(true)}>
          Edit
        </Button>
      </div>
    </div>
  );
};

RulesView.propTypes = {
  setShowModal: PropTypes.func,
  isAppliedRules: PropTypes.bool,
};

RulesView.defaultProps = {
  setShowModal: () => {},
  isAppliedRules: false,
};

export default RulesView;
