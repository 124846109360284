import { camelizeKeys } from 'humps';
import { map } from 'lodash';
import moment from 'moment';

import request from '@/request';

const formatTime = (time) => moment(time).format('HH:mm');

const getShiftsWithStatus = async ({ positionId, date }) => {
  const filter = {
    date,
  };
  const endpoint = `jobs/positions/${positionId}/assigned_schedules/shifts_with_statuses`;
  const res = await request.get(endpoint, {
    params: {
      filter,
    },
  });

  const shifts = map(
    camelizeKeys(res.data.data),
    ({ startAt, endAt, status }) => {
      const formattedStartTime = formatTime(startAt);
      const formattedEndTime = formatTime(endAt);
      const shiftTime = `${formattedStartTime} - ${formattedEndTime}`;
      return { shiftTime, status };
    },
  );

  return shifts;
};

export default getShiftsWithStatus;
