import React from 'react';

import { Form } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { Button, DatePicker, FormItem, Input, Select } from '@/components';
import { handleAddProvider } from '@/pages/PayTemplate/utils';
import { date } from '@/utils';
import { rulesTextInput, rulesSelect } from '@/utils/rulesInput';

const Options = ({
  locations,
  workerTypes,
  listProviders,
  removableLocations,
}) => {
  const formInstance = Form.useFormInstance();
  const locationsSelected =
    Form.useWatch('locationsSelected', formInstance) ?? [];

  const disabledDate = (current) =>
    current && current < moment().startOf('day');

  const handleChangeProvider = () => {
    formInstance.resetFields(['employerLocationIdsOptions']);
  };

  const renderLocations = () => (
    <div className='form-column'>
      <Form.List name='locationsSelected'>
        {(fields, { remove }) =>
          locationsSelected.map((location, index) => {
            const isHasValue = location?.value;
            return (
              isHasValue && (
                <div className='location-container'>
                  <FormItem
                    className='form-item md-width'
                    label={index === 0 && 'Location'}
                    key={location.value}
                    required
                    rules={rulesTextInput('Location')}
                    name={[index, 'label']}
                  >
                    <Input readOnly />
                  </FormItem>
                  {removableLocations && (
                    <div
                      className={classNames('remove-container', {
                        'first-index': index === 0,
                      })}
                    >
                      <ClosedIcon onClick={() => remove(index)} />
                    </div>
                  )}
                </div>
              )
            );
          })
        }
      </Form.List>
    </div>
  );

  return (
    <div className='filter-create-pay-templates'>
      <div className='form-row'>
        <FormItem
          className='form-item'
          name='employerIdOption'
          label='Provider'
        >
          <Select
            options={listProviders}
            placeholder='Select Provider'
            onChange={handleChangeProvider}
          />
        </FormItem>
        <FormItem
          name='workerTypeId'
          className='form-item'
          label='Worker Type'
          required
          rules={rulesSelect('Worker Type')}
        >
          <Select
            options={workerTypes}
            name='workerTypeId'
            placeholder='Select'
          />
        </FormItem>
        <FormItem
          name='name'
          className='form-item'
          label='Template Name'
          required
          rules={rulesTextInput('Template Name')}
        >
          <Input placeholder='Add Template Name' />
        </FormItem>
      </div>
      <div className='form-row'>
        <FormItem
          className='form-item'
          name={['employerLocationIdsOptions']}
          label='Location'
        >
          <Select options={locations} placeholder='Select Location' />
        </FormItem>

        <FormItem
          className='form-item'
          label='Template Start Date'
          initialValue={moment()}
          name='startAt'
          normalize={date.convertToISOString}
          required
          rules={[
            {
              required: true,
              message: 'Please select Start Date',
            },
          ]}
        >
          <DatePicker disabledDate={disabledDate} showToday />
        </FormItem>
      </div>
      <div className='form-row'>
        <FormItem className='form-item margin sm-width'>
          <Button
            className='add-btn yellow-button'
            onClick={() =>
              handleAddProvider({
                form: formInstance,
                listProviders,
                locations,
              })
            }
          >
            Add
          </Button>
        </FormItem>
      </div>
      <div className='form-row'>
        <FormItem
          className='form-item'
          label='Provider'
          name={['providerSelected', 'value']}
          hidden
        />
        <FormItem
          className='form-item md-width'
          label='Provider'
          name={['providerSelected', 'label']}
          required
          rules={rulesTextInput('Provider')}
        >
          <Input readOnly />
        </FormItem>
      </div>
      <div className='form-row'>{renderLocations()}</div>
      {isEmpty(locationsSelected) && (
        <div className='form-row'>
          <div className='form-column'>
            <FormItem
              className='form-item md-width'
              label='Location'
              required
              rules={rulesTextInput('Location')}
              name='emptyLocation'
            >
              <Input readOnly />
            </FormItem>
          </div>
        </div>
      )}
    </div>
  );
};

Options.propTypes = {
  locations: PropTypes.array,
  workerTypes: PropTypes.array,
  listProviders: PropTypes.array,
  removableLocations: PropTypes.bool,
};

Options.defaultProps = {
  locations: [],
  workerTypes: [],
  listProviders: [],
  removableLocations: false,
};

export default Options;
