import React, { useMemo } from 'react';

import { FileWordOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { AutoRenderImageOrPDF, FormItem, Textarea } from '@/components';
import Upload from '@/components/Upload';
import { ACCEPT_TYPE } from '@/constants';
import { useUploadFile } from '@/hooks/useUploadFile';
import { dummyRequest, beforeUploadFile } from '@/utils/uploadFileUtil';

const ApplicantExperience = ({ urlCv }) => {
  // eslint-disable-next-line no-unused-vars
  const [fileSelected, changeFile, fileList, type, setFileUrl, file] =
    useUploadFile();
  const formInstance = Form.useFormInstance();
  const leave = Form.useWatch('leave', formInstance);
  const localFile = useMemo(() => {
    if (fileSelected) {
      return fileSelected;
    }

    return '';
  }, [fileSelected]);

  const renderUploadFile = () => {
    if (localFile) {
      if (
        type === ACCEPT_TYPE.docx ||
        type === ACCEPT_TYPE.docx ||
        type === ACCEPT_TYPE.odt
      ) {
        return (
          <a className='doc-file-container' href={localFile}>
            <FileWordOutlined className='file-icon' />
            <span className='file-title'>{file.name}</span>
          </a>
        );
      }

      return (
        <div className='applicant-pdf'>
          <AutoRenderImageOrPDF data={localFile || urlCv} />
        </div>
      );
    }

    if (urlCv) {
      if (
        urlCv?.includes('.doc') ||
        urlCv?.includes('.docx') ||
        urlCv?.includes('.odt')
      ) {
        const splitUrl = urlCv.split('/');
        const fileName = splitUrl?.[splitUrl.length - 1];
        return (
          <a className='doc-file-container' href={urlCv}>
            <FileWordOutlined className='file-icon' />
            <span className='file-title'>{fileName}</span>
          </a>
        );
      }

      return (
        <div className='applicant-pdf'>
          <AutoRenderImageOrPDF data={urlCv} />
        </div>
      );
    }

    return <p className='text-desc'>No CV Uploaded</p>;
  };

  return (
    <div className='applicant-exp'>
      <div className='gray-box row-box'>
        <div>
          <p className='text-label'>Employment History </p>
          <p className='text-label text-title'>Experience</p>
          <div className='wrap-exp'>
            <FormItem
              label='Don’t have a CV? Please give us information of your last 3 jobs. '
              name='leave'
              required={!(localFile || urlCv)}
              rules={[
                {
                  required: !(localFile || urlCv),
                  message: 'Please enter experience',
                },
              ]}
              className={classNames({ 'hide-error': localFile || urlCv })}
              initialValue=''
            >
              <Textarea
                rows={10}
                placeholder='Please include start date, finish date, job title and responsibilities'
              />
            </FormItem>
          </div>
        </div>
        <div>
          <FormItem
            required={!leave && !urlCv}
            rules={[
              { required: !leave && !urlCv, message: 'Please upload your CV' },
            ]}
            name='cvUpload'
            className={classNames('cv-upload', {
              'hide-error': leave && urlCv,
            })}
          >
            <Upload
              name='file'
              fileList={fileList}
              onChange={changeFile}
              showUploadList={false}
              customRequest={dummyRequest}
              beforeUpload={beforeUploadFile}
            >
              <Button className='text-label btn-upload' icon={<PlusOutlined />}>
                CV Upload
              </Button>
            </Upload>
          </FormItem>
          <p className='text-label text-title'>CV</p>
          {renderUploadFile()}
        </div>
      </div>
    </div>
  );
};

export default ApplicantExperience;

ApplicantExperience.propTypes = {
  urlCv: PropTypes.string,
};
