import { useQuery } from 'react-query';

import { fetchProviders } from '@/services/dropdownServices/getProviders';

const useProviders = (active = false) => {
  const { data } = useQuery(['providers'], () => fetchProviders(active), {
    refetchOnWindowFocus: false,
  });

  return data;
};

export default useProviders;
