import React from 'react';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';

import {
  WorkInformation,
  // ContactInformation,
  // PersonalInformation,
  // Documents,
  // HolidaysAndSickness,
  // RolesAndPermissions,
} from '../components';

import './styles.scss';

const { Panel } = Collapse;

const UserCollapse = ({ activeKey, setActiveKey }) => {
  const onChangeCollapse = (key) => {
    setActiveKey(key);
  };

  return (
    <Collapse
      className='user-collapse'
      expandIconPosition='right'
      activeKey={activeKey}
      defaultActiveKey={activeKey}
      onChange={onChangeCollapse}
    >
      <Panel header='Work Information' forceRender key='workInformation'>
        <WorkInformation />
      </Panel>
      {/* <Panel header="Contact Information" forceRender key="contactInformation">
        <ContactInformation />
      </Panel>
      <Panel
        header="Personal Information"
        forceRender
        key="personalInformation"
      >
        <PersonalInformation />
      </Panel>
      <Panel header="Documents" forceRender key="documents">
        <Documents />
      </Panel> */}
      {/* waiting for confirm */}
      {/* <Panel
        header="Holidays and Sickness"
        forceRender
        key="holidaysAndSickness"
      >
        <HolidaysAndSickness />
      </Panel> */}
      {/* <Panel
        header="Roles and Permissions"
        forceRender
        key="rolesAndPermissions"
      >
        <RolesAndPermissions />
      </Panel> */}
    </Collapse>
  );
};

UserCollapse.propTypes = {
  activeKey: PropTypes.any,
  setActiveKey: PropTypes.func,
};

export default UserCollapse;
