import request from '@/request';

/**
 * Enum for the status of a job seeker.
 * @readonly
 * @enum {string}
 */
export const UPDATE_STATUS = {
  CHECK_IN: 'checked_in',
  CHECK_OUT: 'checked_out',
  NO_SHOW: 'no_show',
  DECLINE: 'declined',
};

/**
 * @typedef {Object} AssignedScheduleAttributesParams
 * @property {number | string} id
 * @property {UPDATE_STATUS} status
 */

/**
 *
 * @param {Object} JobSeekerParams
 * @param {string|number} JobSeekerParams.positionId
 * @param {Array<AssignedScheduleAttributesParams>} JobSeekerParams.assignedSchedulesAttributes
 * @returns {Promise<unknown>}
 */
export const assignJobSeeker = async ({
  positionId,
  assignedSchedulesAttributes,
}) => {
  const payload = { position: { assignedSchedulesAttributes } };
  const endpoint = `/jobs/positions/${positionId}/job_responses/assigned_schedules`;
  const res = await request.patch(endpoint, payload);
  return res;
};
