import React, { useState } from 'react';

import { message } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { Button, Textarea, FormItem } from '@/components';
import { useFetch } from '@/hooks';
import { comments } from '@/services/timesheetService';

import './styles.scss';
import History from '../History';

const Comments = () => {
  const { id } = useParams();
  const [comment, setComment] = useState('');

  const columnsTableComment = [
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: '60%',
    },
    {
      title: 'User',
      dataIndex: 'createdBy',
      width: '20%',
    },
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      width: '20%',
      render: (dt) => (
        <span>
          {moment(dt).format('DD/MM/YYYY')} {moment(dt).format('hh:mm')}
        </span>
      ),
    },
    {
      title: '',
      className: 'close-icon-container',
      render: (_, record) => (
        <ClosedIcon
          onClick={() => deleteComment(record.id)}
          className='close-icon-style'
        />
      ),
    },
  ];

  const {
    data: commentList,
    isFetching: commentFetching,
    refetch: refetchComment,
  } = useFetch(['show-comments', id], () => comments.getComments(id), {
    initialData: [],
  });

  const { mutate: addComment } = useMutation(
    (cmt) => comments.createComment(id, cmt),
    {
      onSuccess: () => {
        refetchComment();
        setComment('');
      },
      onError: () => message.error('Add Seeker failed'),
    },
  );

  const { mutate: deleteComment } = useMutation(
    (cmtId) => comments.deleteComment(id, cmtId),
    {
      onSuccess: () => refetchComment(),
      onError: () => message.error('Delete Seeker failed'),
    },
  );

  return (
    <div className='comments'>
      <FormItem className='comments-input' label='Comments'>
        <Textarea
          onChange={(e) => setComment(e.target.value)}
          placeholder='Add comment here'
          value={comment}
        />
      </FormItem>
      <Button
        className='yellow-button add-button'
        onClick={() =>
          comment.trim().length > 0 ? addComment(comment) : undefined
        }
      >
        Add Comment
      </Button>

      <History
        title='History'
        dataSource={commentList}
        columns={columnsTableComment}
        loading={commentFetching}
      />
    </div>
  );
};
export default Comments;
