import React, { useMemo } from 'react';

import { Select } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as CalendarIcon } from '@/assets/icons/calendarIcon.svg';
import { Button } from '@/components';

import './styles.scss';
import { buttons } from '../constants';

const CalendarHeader = ({ value, onChange }) => {
  const renderButtons = () => (
    <div className='row-container'>
      {buttons.map((button) => {
        const { content, color } = button;
        return (
          <Button
            key={content}
            className={classNames(color, 'shared-button', 'button-style')}
          >
            {content}
          </Button>
        );
      })}
    </div>
  );

  const year = value.year();
  const month = value.month();

  const memoizeDate = useMemo(() => {
    const localeData = value.localeData();
    const current = value.clone();

    const months = [];
    const years = [];

    for (let i = 0; i < 12; i += 1) {
      current.month(i);
      months.push({
        value: i,
        label: localeData.months(current),
      });
    }

    for (let i = year - 1000; i < year + 1001; i += 1) {
      years.push({
        value: i,
        label: i,
      });
    }
    return { months, years };
  }, [value, year]);

  const { months, years } = memoizeDate;

  return (
    <div className='holidays-header'>
      <div className='calendar-header'>
        <Select
          options={months}
          value={month}
          className='picker-style month'
          suffixIcon={null}
          onChange={(newMonth) => {
            const now = value.clone().month(newMonth);
            onChange(moment(now));
          }}
        />
        <Select
          options={years}
          value={year}
          className='picker-style year'
          suffixIcon={null}
          onChange={(newYear) => {
            const now = value.clone().year(newYear);
            onChange(now);
          }}
        />
        <CalendarIcon />
      </div>
      <div className='button-container'>
        <div className='buttons'>{renderButtons()}</div>
        <div className='add-button-container'>
          <Button className='yellow-button add-btn'>
            <span className='plus-icon'>+</span>
            <span>Add Announcement</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

CalendarHeader.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

CalendarHeader.defaultProps = {
  value: {},
  onChange: () => {},
};
export default CalendarHeader;
