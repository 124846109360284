/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

import { Row, Col } from 'antd';

const AWR = () => (
  <>
    <div className='awr-title'>AWR</div>
    {/* // TODO: Hard code UI, wait meeting discuss logic */}
    <Row gutter={16}>
      <Col className='columns' span={7}>
        <Row className='title'>Provider</Row>
        {/* {data.map((item) => (
          <Row className="row-data">{item.name}</Row>
        ))} */}
      </Col>
      <Col span={1} />
      <Col className='columns' span={7}>
        <Row className='title'>Total Weeks Worked at provider</Row>
        {/* {data.map((item) => (
          <Row className="row-data">{item.totalWeeksWorked}</Row>
        ))} */}
      </Col>
      <Col span={1} />
      <Col className='columns' span={7}>
        <Row className='title'>Total consecutive Weeks not working</Row>
        {/* {data.map((item) => (
          <Row className="row-data">{item.totalConsecutiveWeekOff}</Row>
        ))} */}
      </Col>
    </Row>
  </>
);
export default AWR;
