import { message } from 'antd';
import slgify from 'slugify';

import isValidHttpUrl from './isValidHttpUrl';
import { combineFieldNameAndFileName } from './upLoadFiles';
import uploadFileS3 from './uploadFileS3';

export const beforeUploadImage = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    return message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    return message.error('Image must smaller than 2MB!');
  }
  return file;
};

export const beforeUploadPDF = (file) => file;

export const beforeUploadFile = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (isJpgOrPng) {
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      return message.error('Image must smaller than 2MB!');
    }
  }

  return file;
};

export const getBase64 = (originFileObj, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(originFileObj);
};

export const convertFileToBase64Sync = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const checkURLIsAnImage = (url) =>
  url.match(/\.(jpe?g|gif|png|webp)$/) != null;

export const createImageUrl = async (item) => {
  if (isValidHttpUrl(item)) {
    return checkURLIsAnImage(item)
      ? {
          blob: item,
          type: 'image',
        }
      : {
          blob: item,
          type: 'application',
        };
  }

  if (!item?.file) return { type: '', blob: '' };
  const base64 = await convertFileToBase64Sync(item.file.originFileObj);

  return {
    type: item.file.type,
    blob: base64,
  };
};

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const renameBeforeUpload = (fileName) => {
  fileName = fileName
    .toLowerCase()
    .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    .replace(/đ/g, 'd')
    .replace(/[&*+~()'"!:@/]/g, ' ');

  return slgify(fileName, { lower: true });
};

export const uploadFile = async ({ file, fieldName }) => {
  const fileName = combineFieldNameAndFileName(fieldName, file.name);

  const originFile = file.originFileObj || file;
  const { getUrl } = await uploadFileS3(fileName, originFile);

  return getUrl;
};

/**
 * Extract file info from url
 * @param {string} url
 * @returns {Object} file info
 */
export const extractFileInfoFromUrl = (url) => {
  const fileName = url.split('/').pop() || '';
  const type = fileName.split('.').pop();
  return {
    name: fileName,
    type: `image/${type}` || 'image/png',
  };
};

/**
 * upload multiple files
 * @param {Object} params
 * @param {Array} params.fileList files list
 * @param {string} params.fieldName folder name
 * @returns {Promise<Array<string>>} list of file urls
 */
export const uploadMultipleFile = async ({ fileList, fieldName }) => {
  const urls = await Promise.all(
    fileList.map((file) => uploadFile({ file, fieldName })),
  );
  return urls;
};

/**
 * generate uploaded file list for antd upload component
 * @param {string} urls list url
 * @returns {Array<Object>} list file
 */
export const formatUploadedList = (urls) => {
  if (typeof urls !== 'string' || urls.trim().length === 0) {
    return [];
  }

  return urls.split(',').map((item, index) => {
    const { name, type } = extractFileInfoFromUrl(item);
    return {
      uid: -(index + 1),
      name,
      status: 'done',
      url: item,
      type,
    };
  });
};
