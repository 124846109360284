import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button as CommonButton } from '@/components';

const Button = ({ name, onClick, disabled, title, className }) => (
  <CommonButton
    className={classNames(
      ' button__add-more',
      name === 'employerTrustedEmployee' ? 'yellow-button' : 'black-button',
      className,
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {title}
  </CommonButton>
);

Button.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
