import React, { useState } from 'react';

import { Divider, Form, message, Row, Spin } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Modal, GeneralTemplate, ModalConfirm } from '@/components';
import { LeadService } from '@/services';
import { switchStatusLead } from '@/utils/switchStatus';
import useModal from '@/utils/useModal';

import LeadForm from '../LeadForm';
import './styles.scss';

const EditLead = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const [form] = Form.useForm();
  const history = useHistory();

  const { leadId: leadIdParam } = useParams();

  const { data: lead, isLoading } = useQuery(
    ['lead', leadIdParam],
    () => LeadService.getOne(leadIdParam),
    { cacheTime: 0, refetchOnWindowFocus: false },
  );

  const { mutate: mutateUpdateLead } = useMutation(
    ({ leadId, dataFields }) =>
      LeadService.update({ id: leadId, ...dataFields }),
    {
      onSuccess: () => {
        setVisibleModal(false);
        message.success('Lead has been updated successfully');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const onFormSubmitted = (formData) =>
    formData.status === undefined || formData.status === null
      ? undefined
      : setVisibleModal(true);

  const onConfirmSave = () =>
    mutateUpdateLead({
      leadId: leadIdParam,
      dataFields: form.getFieldsValue(),
    });

  return (
    <GeneralTemplate
      mainItem='Provider'
      buttonContent='Create Lead'
      headerText={lead?.company ? `Lead - ${lead.company}` : 'Lead'}
      data={[
        { item: 'Leads', to: '/view-all-leads' },
        { item: 'Edit/View Lead', to: `/edit-lead/${leadIdParam}` },
      ]}
      buttonLink='/create-new-lead'
      subTemplateClassName='provider-container'
      className='lead-profile-template'
    >
      <div className='edit-lead-container'>
        {isLoading ? (
          <Row align='center'>
            <Spin />
          </Row>
        ) : (
          <>
            <Form
              form={form}
              onFinish={onFormSubmitted}
              initialValues={{ ...lead }}
            >
              <LeadForm />
              <div className='edit-lead-info-input-container'>
                <Divider />
                <div className='edit-lead-buttons'>
                  <Button
                    onClick={showModal}
                    className='black-button action-button'
                  >
                    Cancel
                  </Button>
                  <Button
                    className='yellow-button action-button'
                    htmlType='submit'
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
            <Modal
              onClickNo={() => setVisibleModal(false)}
              onClickYes={onConfirmSave}
              visible={visibleModal}
              title={switchStatusLead(form.getFieldValue('status')).title}
            >
              {switchStatusLead(form.getFieldValue('status')).content}
            </Modal>

            <ModalConfirm
              title="Don't Want to Save Updates?"
              visible={isDisplayModal}
              onClickYes={() => history.push('/view-all-leads')}
              onClickNo={hiddenModal}
            >
              Are you sure you want to leave without saving? You will lost any
              uploads and changes you have made.
            </ModalConfirm>
          </>
        )}
      </div>
    </GeneralTemplate>
  );
};
export default EditLead;
