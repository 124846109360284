import _ from 'lodash';

import request from '@/request';

export async function pastGig({ seekerId, status }) {
  const endpoint = `seekers/employees/${seekerId}/gig`;
  const { data } = await request.get(endpoint, {
    params: {
      filter: { status },
    },
  });

  return _.uniqWith(data?.data, _.isEqual);
}
