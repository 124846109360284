import moment from 'moment';

import { LIMIT_HOURS } from '../constant';

const isTimeCanCheckIn = ({ startTime, endTime, value }) =>
  moment(value).isBetween(
    moment(startTime).subtract(LIMIT_HOURS.beforeStart, 'hours'),
    moment(endTime).add(LIMIT_HOURS.afterEnd, 'hours'),
  );

const isTimeCanCheckOut = ({ startTime, endTime, value }) =>
  moment(value).isBefore(moment(endTime).add(LIMIT_HOURS.afterEnd, 'hours')) &&
  moment(value).isAfter(
    moment(startTime).subtract(LIMIT_HOURS.beforeStart, 'hours'),
  );

export { isTimeCanCheckIn, isTimeCanCheckOut };
