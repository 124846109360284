import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

// This set default for tempo to 100 item, waiting for api to get all
const DEFAULT_SIZE = 100;

const getProvidersOfPayTemplate = async (params) => {
  const endpoint = 'pay_templates/payroll_templates';

  const res = await request.get(endpoint, {
    // This params set temporary, waiting api get all done to remove it
    params: {
      page: {
        size: DEFAULT_SIZE,
      },
    },
  });

  const { data } = normalizeIndexResponse(res);
  const dropDownResponse = data.reduce(
    (totalProviders, { employerId, employerName }) => {
      const isExistProvider = totalProviders.some(
        (provider) => provider.value === employerId,
      );
      if (!isExistProvider) {
        const providerDropdownInfo = {
          value: employerId,
          label: employerName,
        };
        return [...totalProviders, providerDropdownInfo];
      }
      return totalProviders;
    },
    [],
  );

  return dropDownResponse;
};

export default getProvidersOfPayTemplate;
