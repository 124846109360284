import React from 'react';

import PropTypes from 'prop-types';

import {
  FormItem,
  MultipleSelect,
  Input,
  Button,
  Select,
  InputNumber,
} from '@/components';
import { useProviders } from '@/hooks/dropdowns';

import './styles.scss';
import { TAB_KEY, listWeek, listYear } from '../../constants';

const FormFields = ({ activeKey }) => {
  const listProviders = useProviders();
  const isHiddenInvoiceNumber = activeKey === TAB_KEY.createInvoice.key;

  return (
    <div className='input-container'>
      <FormItem name='year' className='form-item' label='Choose Year'>
        <Select options={listYear} placeholder='Choose year' />
      </FormItem>
      <FormItem className='form-item' label='Choose Week' name='weekNum'>
        <Select options={listWeek} placeholder='Choose Week' />
      </FormItem>
      <FormItem name='searchTerm' className='form-item' label='Search'>
        <Input placeholder='Name or email address' />
      </FormItem>
      <FormItem name='employerId' className='form-item' label='Provider'>
        <MultipleSelect
          name='employerId'
          options={listProviders}
          placeholder='Provider'
        />
      </FormItem>
      <FormItem
        hidden={isHiddenInvoiceNumber}
        name='invoiceId'
        className='form-item'
        label='Invoice Number'
      >
        <InputNumber placeholder='Invoice Number' />
      </FormItem>
      <div className='buttons'>
        <Button
          className='yellow-button input-height style-btn'
          htmlType='submit'
        >
          Search
        </Button>
        <Button
          className='filter-button input-heigh style-btn'
          htmlType='reset'
        >
          Clear Filter
        </Button>
      </div>
    </div>
  );
};

export default FormFields;

FormFields.propTypes = {
  activeKey: PropTypes.string,
};
