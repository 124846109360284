import request from '@/request';

const updateSickNoShow = async ({
  payrollId,
  timecardIds,
  noShowAttributes,
}) => {
  const endpoint = `trackers/timekeeping/timesheets/${payrollId}/timecards/sick_no_show`;
  const res = await request.patch(endpoint, {
    timecardIds,
    noShowAttributes,
  });

  return res?.data?.data ?? {};
};

export default updateSickNoShow;
