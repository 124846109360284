import React from 'react';

import { Form } from 'antd';

import { FormItem, Input } from '@/components';
import {
  rulesEmail,
  rulesPhone,
  rulesPostcode,
  rulesTextInput,
} from '@/utils/rulesInput';

const NextOfKin = () => {
  const form = Form.useFormInstance();

  return (
    <div className='next-of-lin'>
      <p className='employment-history-label'>Next of Kin</p>
      <div className='seeker-next-of-kin'>
        <FormItem
          label='Next of Kin - Name'
          name={['vacancyApplicantAttributes', 'kinFirstname']}
          rules={rulesTextInput('Next of Kin - Name')}
          required
        >
          <Input placeholder='Next of Kin - Name' />
        </FormItem>

        <FormItem
          label='Next of Kin - Email'
          name={['vacancyApplicantAttributes', 'kinEmail']}
          rules={rulesEmail(false)}
          required
        >
          <Input placeholder='Next of Kin - Email' />
        </FormItem>

        <FormItem
          label='Next of Kin - Relationship'
          name={['vacancyApplicantAttributes', 'kinRelation']}
          required
          rules={rulesTextInput('Next of Kin - Relationship')}
        >
          <Input placeholder='Next of Kin - Relationship' />
        </FormItem>

        <FormItem
          required
          label='Next of Kin - Phone'
          name={['vacancyApplicantAttributes', 'kinPhone']}
          rules={rulesPhone()}
        >
          <Input placeholder='Next of Kin - Phone' />
        </FormItem>

        <FormItem
          required
          label='Next of Address - Building'
          name={['vacancyApplicantAttributes', 'kinAddress']}
          rules={rulesTextInput('Next of Address - Building')}
        >
          <Input placeholder='Next of Address - Building' />
        </FormItem>

        <FormItem
          label='Next of Kin - Street'
          name={['vacancyApplicantAttributes', 'kinAddress2']}
        >
          <Input placeholder='Next of Kin - Street' />
        </FormItem>

        <FormItem
          required
          label='Next of Kin - Postcode'
          name={['vacancyApplicantAttributes', 'kinZip']}
          rules={rulesPostcode(form, ['vacancyApplicantAttributes', 'kinZip'])}
        >
          <Input placeholder='Next of Kin - Postcode' />
        </FormItem>
      </div>
    </div>
  );
};

export default NextOfKin;
