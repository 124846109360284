import request from '@/request';

export const getStatisticalSeeker = async ({ id, ...params }) => {
  const res = await request.get(
    `seekers/employees/${id}/timesheets/statistical`,
    {
      params: {
        params,
      },
    },
  );

  return res.data?.data ?? [];
};
