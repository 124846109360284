import React from 'react';

import { GeneralTemplate, Input } from '@/components';

import './styles.scss';

const Dashboard = () => (
  <div className='dashboard-page'>
    <GeneralTemplate
      hasButton={false}
      mainItem='Dashboard'
      data={[{ item: 'Dashboard', to: '/' }]}
      subTemplateClassName='template-dashboard-container'
    >
      <div className='dashboard-container'>
        <div className='right-section-container'>
          <div className='seeker-finder-container'>
            <h3>Seeker Finder</h3>
            <Input placeholder='Name' />
          </div>
        </div>
      </div>
    </GeneralTemplate>
  </div>
);

export default Dashboard;
