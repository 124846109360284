import { useQuery } from 'react-query';

import { fetchStatus } from '@/services/dropdownServices/getStatus';

const useStatus = () => {
  const { data: resStatus } = useQuery(['status'], fetchStatus, {
    refetchOnWindowFocus: false,
  });

  return { ...resStatus };
};

export default useStatus;
