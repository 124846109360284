import React from 'react';

import { Select, Tooltip } from 'antd';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';

import {
  FormItem,
  Input,
  InputNumber,
  TimePicker,
  RangePicker,
} from '@/components';
import { cellColors, daysOfWeek } from '@/pages/PayTemplate/constants';
import Formatter from '@/utils/Formatter';

const renderCellValue = ({
  initialValue,
  label,
  type = 'header',
  name,
  prefix = '',
  column = '',
  isOverTime = false,
  rules = [],
  optionsSelect = [],
  isEdit = false,
  ...props
}) => {
  const elementInput = {
    text: (
      <FormItem
        initialValue={initialValue}
        {...(!isNil(name) && { name })}
        {...(!isEmpty(rules) && { rules })}
      >
        <Input
          {...(isOverTime && { className: 'overtime-color' })}
          readOnly={!isEdit}
          {...props}
        />
      </FormItem>
    ),
    number: (
      <FormItem
        name={name}
        initialValue={initialValue}
        {...(!isEmpty(rules) && { rules })}
      >
        <InputNumber
          {...(isOverTime && { className: 'overtime-color' })}
          placeholder={`${prefix}00.00`}
          readOnly={!isEdit}
          formatter={(numberValue) =>
            `${numberValue ? prefix : ''}${numberValue}`
          }
          {...props}
        />
      </FormItem>
    ),
    date: (
      <FormItem name={name} initialValue={initialValue}>
        <TimePicker
          {...(isOverTime && { className: 'overtime-color' })}
          readOnly={!isEdit}
          {...props}
        />
      </FormItem>
    ),
    range: (
      <FormItem name={name} initialValue={initialValue}>
        <RangePicker
          {...(isOverTime && { className: 'overtime-color' })}
          order={false}
          format='HH:mm'
          disabled={!isEdit}
          {...props}
        />
      </FormItem>
    ),
    select: (
      <FormItem
        name={name}
        initialValue={initialValue}
        className='table-payroll__selector-custom'
      >
        <Select
          {...(isOverTime && { className: 'overtime-color' })}
          disabled={!isEdit}
          options={optionsSelect}
          suffixIcon={null}
          bordered={false}
          dropdownMatchSelectWidth={false}
          showArrow={false}
          showSearch={false}
          {...props}
        />
      </FormItem>
    ),
    header: (
      <span {...(isOverTime && { className: 'overtime-color' })}>
        {prefix}
        {label ?? initialValue}
      </span>
    ),
  };

  return (
    <div
      className={classNames('cell-item', {
        'total-color': cellColors
          .concat([
            'hoursPayrollTotals',
            'payPayrollTotals',
            'chargePayrollTotals',
          ])
          .includes(column),
      })}
    >
      <Tooltip
        className='cell-tooltip'
        placement='topLeft'
        title={!isNil(label) ? label : initialValue}
      >
        {elementInput[type]}
      </Tooltip>
    </div>
  );
};

const renderDay = daysOfWeek.map((day) => ({
  title: renderCellValue({ label: day.label, type: 'header' }),
  width: '4%',
  render: (_, record) =>
    renderCellValue({
      label: Formatter.fixedNumber({ value: record[day.dataIndex] }),
      isOverTime: record?.isOverTime,
    }),
}));

export { renderDay, renderCellValue };
