import moment from 'moment';

import { DATA_PAYPMENT_REPORT_KEYS, DATA_PROTECTION_KEYS } from '@/constants';
import request from '@/request';
import { getNumberMoneyInput, getNumberPercentInput } from '@/utils';
import { uploadFile, uploadMultipleFile } from '@/utils/uploadFileUtil';

import { getAddressInfomation } from '../getAddressInfomation';

const convertContactType = (
  /** @type {Array} */ values,
  /** @type {string} */ type,
) =>
  (values || []).map(({ name, phone, email, jobTitle, department }) => ({
    name,
    phone,
    email,
    type,
    jobTitle,
    department,
  }));

/**
 *  @function createRequestObject
 * @param {{
 * city: string,
 * street: string,
 * postCode: string,
 * building: string,
 * providerName: string,
 * vatNumber: string,
 * registeredCompanyName: string,
 * companyNumber: string,
 * contactId: string,
 * accountManagerId: string,
 * contactName: string,
 * contactEmail: string,
 * contactPhone: string,
 * contactJobTitle: string,
 * contactDepartment: string,
 * contactManualPassword: string,
 * accountManagerName: string,
 * accountManagerPhone: string,
 * accountManagerGIGEmail: string,
 * accountManagerSecondaryManagerEmail: string,
 * contactForBookings: Array,
 * contactForInvoices: Array,
 * contactForAccounts: Array,
 * contactForTimesheets: Array,
 * otherCosts: string,
 * marginFixed: string,
 * marginPercent: string,
 * pensionAndApprentice: string,
 * chargeRatesAttributes: Array,
 * marginIsCostType: string,
 * creditCard: boolean,
 * postingAlerts: Array,
 * paymentReport: Array,
 * CVV: string,
 * nameOnCard: string,
 * expiryDate: string,
 * longCardNumber: string,
 * aboutUs: string,
 * offices: string,
 * locations: Array,
 * industries: Array,
 * dataProtection: Array,
 * historyComments: Array,
 * jobTemplatesAttributes: Array,
 * logoThumbnail: string,
 * contract: string,
 * customerSageCode: string,
 * }} props
 */

const createRequestObject = async ({
  // Company
  city,
  street,
  postCode,
  building,
  providerName,
  vatNumber,
  registeredCompanyName,
  companyNumber,
  // Contact Information
  contactPhone,
  contactName,
  contactEmail,
  contactJobTitle,
  contactDepartment,
  contactManualPassword,
  accountManagerName,
  accountManagerPhone,
  accountManagerGIGEmail,
  accountManagerSecondaryManagerEmail,
  contactForBookings,
  contactForInvoices,
  contactForAccounts,
  contactForTimesheets,
  // rates
  otherCosts,
  marginFixed,
  marginPercent,
  pensionAndApprentice,
  chargeRatesAttributes,
  marginIsCostType,
  // Bank and official information
  creditCard = false,
  postingAlerts,
  paymentReport,
  CVV,
  nameOnCard,
  expiryDate,
  longCardNumber,
  // Locations
  aboutUs,
  offices,
  locations,
  industries,

  // Job Template
  jobTemplatesAttributes,

  // Comment and status update
  dataProtection,
  historyComments,
  paymentStartWeek,
  logoThumbnail,
  contract,
  customerSageCode,
}) => {
  const historyCommentsConverted = (historyComments || []).map(
    ({ content }) => ({
      content,
    }),
  );

  const contactForTimesheetsConverted = convertContactType(
    contactForTimesheets,
    'timesheet',
  );

  const contactForBookingsConverted = convertContactType(
    contactForBookings,
    'booking',
  );

  const contactForInvoicesConverted = convertContactType(
    contactForInvoices,
    'invoice',
  );

  const contactForAccountsConverted = convertContactType(
    contactForAccounts,
    'account',
  );

  const chargeRatesAttributesConverted = (chargeRatesAttributes || []).map(
    ({ payRate, chargeRate, skillId, rateType }) => ({
      payRate,
      skillId,
      chargeRate,
      rateType,
    }),
  );

  const jobTemplatesWithLocation = {};

  jobTemplatesAttributes.forEach((template) => {
    const { locationId, licenses, ...rest } = template;
    jobTemplatesWithLocation[locationId] = (
      jobTemplatesWithLocation[locationId] ?? []
    ).concat({
      ...rest,
      licenses: licenses ? licenses.filter((e) => e !== 'all') : undefined,
    });
  });

  const locationsConvertedConverted = Promise.all(
    (locations || []).map(async (location) => {
      const geogecode = await getAddressInfomation(
        // eslint-disable-next-line max-len
        `${location.buildingNumber} ${location.street} ${location.city} ${location.postCode}, United Kingdom`,
      );

      const { geometry } = geogecode[0];
      return {
        city: location.city,
        street: location.street,
        name: location.locationName,
        postcode: location.postCode,
        building: location.buildingNumber,
        /**
      These attributes are needed for creating a provider
      */
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
        // territory_id is id of country, default is 1 (UK)
        territory_id: 1,
        email_invoice: 'backend_hardcode@gmail.com', // TODO: Need to confirm with backend.
        jobTemplatesAttributes: jobTemplatesWithLocation[location.id],
      };
    }),
  );

  const employerLocationsAttributes = await locationsConvertedConverted;

  const industriesConverted = (industries || []).map((industryId) => ({
    industryId,
  }));

  const expMonth = moment(expiryDate, 'MM/YYYY').month();
  const expYear = moment(expiryDate, 'MM/YYYY').year();

  const geogecode = await getAddressInfomation(
    // eslint-disable-next-line max-len
    `${building} ${street} ${city} ${postCode}, United Kingdom`,
  );
  const { geometry } = geogecode[0];
  const locationAttributes = {
    latitude: geometry.location.lat,
    longitude: geometry.location.lng,
  };

  const provider = {
    hear: aboutUs,
    ba_city: city,
    ba_street: street,
    ba_phone: contactPhone,
    name: providerName,
    office_id: offices,
    ba_postcode: postCode,
    ba_building: building,
    vat_number: vatNumber,
    posting_alert: postingAlerts,
    paymentStartWeek,
    company_number: companyNumber,
    abbrv_name: registeredCompanyName,
    wages_instant_payment: true,
    creditCard,
    locationAttributes,
    show_awr: (paymentReport || []).includes(
      DATA_PAYPMENT_REPORT_KEYS.SHOW_AWR_REPORT,
    ),
    pay_weekend: (paymentReport || []).includes(
      DATA_PAYPMENT_REPORT_KEYS.SUNDAY_TO_SATURDAY_PAYMENT_WEEK,
    ),
    is_accepted_receiving_updates: (dataProtection || []).includes(
      DATA_PROTECTION_KEYS.ACCEPTED_RECIEVING_UPDATES,
    ),
    is_verified_read_terms: (dataProtection || []).includes(
      DATA_PROTECTION_KEYS.VERIFIED_READ_TERMS_AND_CONDITIONS,
    ),
    is_verified_store_and_use: (dataProtection || []).includes(
      DATA_PROTECTION_KEYS.VERIFIED_STORE_AND_USE,
    ),
    comments_attributes: historyCommentsConverted,
    contacts_attributes: [
      {
        name: contactName,
        phone: contactPhone,
        email: contactEmail,
        jobTitle: contactJobTitle,
        department: contactDepartment,
        type: 'user',
      },
      {
        name: Number(accountManagerName),
        phone: accountManagerPhone,
        email: accountManagerSecondaryManagerEmail,
        type: 'omni_user',
      },
      ...contactForBookingsConverted,
      ...contactForAccountsConverted,
      ...contactForInvoicesConverted,
      ...contactForTimesheetsConverted,
    ],
    charge_rate_property_attributes: {
      other_cost: getNumberMoneyInput(otherCosts),
      margin_fixed: getNumberMoneyInput(marginFixed),
      margin_percent: getNumberPercentInput(marginPercent),
      pension_apprenticeship_percent:
        getNumberPercentInput(pensionAndApprentice),
      marginIsCostType,
    },
    employerLocationsAttributes,
    charge_rates_attributes: chargeRatesAttributesConverted,
    user_attributes: {
      password: contactManualPassword,
    },
    employer_industries_attributes: industriesConverted,
    logoThumbnail,
    contract_file: contract,
    customer_sage_code: customerSageCode,
  };

  if (creditCard) {
    provider.card = {
      cvc: CVV,
      name: nameOnCard,
      exp_year: expYear,
      exp_month: expMonth,
      number: longCardNumber,
    };
  }

  return {
    provider,
  };
};

/**
 * @function create Create A Provider
 *
 * @param {Object} payload
 * @returns
 */
const create = async (payload = {}) => {
  const { jobTemplatesAttributes = [], logoThumbnail, contractFile } = payload;
  const jobTemplatesAttributesConvert = jobTemplatesAttributes.map((item) => {
    const {
      shirt,
      jean,
      shoe,
      additionalUniform,
      employerId,
      skillId,
      locations,
      ...rest
    } = item;
    return rest;
  });

  const data = {
    ...payload,
    jobTemplatesAttributes: jobTemplatesAttributesConvert,
  };

  if (logoThumbnail) {
    const url = await uploadFile({
      file: logoThumbnail.file,
      fieldName: 'logoThumbnail',
    });
    data.logoThumbnail = url;
  }

  if (contractFile) {
    const urls = await uploadMultipleFile({
      fileList: contractFile.fileList,
      fieldName: 'contract',
    });
    data.contract = urls.join(',');
  }

  return (
    (
      await request.post(
        '/providers/employers',
        await createRequestObject(data),
      )
    ).data?.data || {}
  );
};
export default create;
