import { useQuery } from 'react-query';

import { getWorkTypes } from '@/services/payrollTemplateService';

const useWorkerTypes = (active = false) => {
  const { data } = useQuery(['workerTypes'], () => getWorkTypes(), {
    refetchOnWindowFocus: false,
  });

  return data;
};

export default useWorkerTypes;
