import React from 'react';

import './styles.scss';
import { useTenantContext } from '../../TenantWrapper';

// eslint-disable-next-line react/prop-types
export const FormHeader = () => {
  const { mainLogo } = useTenantContext();

  return (
    <div className='form-header-container'>
      <div className='gig-logo-container'>
        <img src={mainLogo.url} alt='gig-log' />
      </div>
    </div>
  );
};

export default FormHeader;
