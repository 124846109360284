import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { ReactComponent as ClosedCircle } from '@/assets/icons/closedCircle.svg';
import { Button, Checkbox } from '@/components';

import { ATTENDANCES_STATUS } from '../constants';

const confirmedColumns = ({
  checkedInList,
  checkInSeekers,
  disableCheckedIn,
  disableCheckedOut,
  selectAllCheckedIn,
  selectCheckedInSeekers,
  selectAllCheckedOut,
  selectCheckedOutSeekers,
  noShowSeekers,
  declineSeekers,
  checkOutSeekers,
  checkedOutList,
  removedList,
  selectAllDeclined,
  selectDeclinedSeekers,
  openModalSeekerInfo,
}) => {
  const columns = [
    {
      width: '10%',
      render: (data) => (
        <div>
          <span>{data.employeeName}</span>
          <InfoCircleOutlined
            className='info-icon'
            onClick={() => openModalSeekerInfo(data.employeeId)}
          />
        </div>
      ),
    },
    {
      title: (
        <Button
          disabled={checkedInList.length === 0}
          onClick={() => checkInSeekers(checkedInList)}
          className='black-button'
        >
          Check in
        </Button>
      ),
      dataIndex: 'checkIn',
      width: '8%',
      render: (_, record) => (
        <Button
          disabled={disableCheckedIn(record)}
          onClick={() => checkInSeekers([record.id])}
          className='black-button'
        >
          Check in
        </Button>
      ),
    },
    {
      title: (
        <Checkbox
          onChange={(e) => selectAllCheckedIn(e)}
          className='checkbox-item'
        />
      ),
      dataIndex: 'checkInAll',
      width: '6%',
      render: (_, record) => (
        <Checkbox
          disabled={disableCheckedIn(record)}
          checked={checkedInList.includes(record.id)}
          onChange={(e) => selectCheckedInSeekers(e, record)}
          className='checkbox-item'
        />
      ),
    },
    {
      title: (
        <Button
          disabled={checkedOutList.length === 0}
          onClick={() => checkOutSeekers(checkedOutList)}
          className='black-button'
        >
          Check Out
        </Button>
      ),
      dataIndex: 'checkOut',
      width: '8%',
      render: (_, record) => (
        <Button
          disabled={disableCheckedOut(record.attendanceStatus)}
          className='black-button'
          onClick={() => checkOutSeekers([record.id])}
        >
          Check Out
        </Button>
      ),
    },
    {
      title: (
        <Checkbox
          onChange={(e) => selectAllCheckedOut(e)}
          className='checkbox-item'
        />
      ),
      dataIndex: 'checkOutAll',
      width: '6%',
      render: (_, record) => (
        <Checkbox
          disabled={disableCheckedOut(record.attendanceStatus)}
          checked={checkedOutList.includes(record.id)}
          onChange={(e) => selectCheckedOutSeekers(e, record)}
          className='checkbox-item'
        />
      ),
    },
    {
      dataIndex: 'noShow',
      width: '8%',
      render: (_, record) => (
        <Button
          onClick={() => noShowSeekers([record.id])}
          className='yellow-button'
          disabled={record.attendanceStatus === ATTENDANCES_STATUS.NO_SHOW}
        >
          No Show
        </Button>
      ),
    },
    {
      title: (
        <ClosedCircle
          onClick={() => !!removedList.length && declineSeekers(removedList)}
          className={classNames('icon-style', {
            disabled: !removedList.length,
          })}
        />
      ),
      dataIndex: 'removeSeeker',
      width: '3%',
      render: (_, record) => (
        <ClosedCircle
          onClick={() =>
            record.attendanceStatus === ATTENDANCES_STATUS.UNCHECKED &&
            declineSeekers([record.id])
          }
          className={classNames('icon-style', {
            disabled: record.attendanceStatus !== ATTENDANCES_STATUS.UNCHECKED,
          })}
        />
      ),
    },
    {
      title: (
        <Checkbox
          onChange={(e) => selectAllDeclined(e)}
          className='checkbox-item'
        />
      ),
      dataIndex: 'removetAll',
      width: '5%',
      render: (_, record) => (
        <Checkbox
          onChange={(e) => selectDeclinedSeekers(e, record)}
          className='checkbox-item'
          disabled={record.attendanceStatus !== ATTENDANCES_STATUS.UNCHECKED}
          checked={removedList.includes(record.id)}
        />
      ),
    },
  ];
  return columns;
};
export default confirmedColumns;
