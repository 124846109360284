import React, { useEffect, useState } from 'react';

import { Form, Pagination } from 'antd';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  AutoComplete,
  Button,
  FormItem,
  GeneralTemplate,
  ListView,
  MultipleSelect,
} from '@/components';
import { DEBOUNCE_WAIT, PAGE_SIZE_OPTIONS } from '@/constants';
import { useFetch, usePagination, useSessionStorage } from '@/hooks';
import { SupplierService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import CostAndSupplierTab from '../../components/Tabs';
import { supplierRoles } from '../mockData';
import { breadCrumb, columns } from './constants';
import './styles.scss';

const Suppliers = () => {
  const {
    currentPage,
    handleShowSizeChange,
    paginationPageSize,
    setCurrentPage,
  } = usePagination();

  const [searchTerm, setSearchTerm] = useState();

  const headerTable = generateHeaderTable(columns);

  const history = useHistory();
  const [form] = Form.useForm();
  const [sortOrder, setSortOrder] = useState('desc');

  const { data: emailAndNameOfSuppliers } = useFetch(
    ['emailAndNameOfSuppliers', searchTerm],
    () => [],
  );

  const [storedValue, setSession, removeSession] =
    useSessionStorage('searchJobs');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: supplierInfo,
    refetch,
    isFetching,
  } = useFetch(
    ['getSuppliers', currentPage, currentPage, paginationPageSize, sortOrder],
    () =>
      SupplierService.getAll({
        ...form.getFieldsValue(),
        sortOrder,
        pageNumber: currentPage,
        pageSize: paginationPageSize,
      }),
  );

  const handleSearchTermChange = debounce(setSearchTerm, DEBOUNCE_WAIT);

  const handleNavigate = (supplierId) =>
    history.push(`edit-supplier/${supplierId}`);

  const handleSort = (order) =>
    setSortOrder(Formatter.makeAbbrevSortType(order));

  const clearFilter = () => {
    form.resetFields();
    removeSession();
    refetch();
  };

  const onFilter = () => {
    setSession(form.getFieldsValue());
    refetch();
  };

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadCrumb}
      headerText='Suppliers'
      buttonContent='Create Supplier'
      buttonLink='/create-supplier'
      subTemplateClassName='supplier-container'
    >
      <CostAndSupplierTab defaultActiveKey={breadCrumb[1].to} />

      <div className='supplier-detail-container'>
        <div className='supplier-detail-box-shadow'>
          <Form
            form={form}
            className='supplier-info-input-container'
            onFinish={onFilter}
          >
            <FormItem name='searchTerm' label='Search' className='form-item'>
              <AutoComplete
                options={emailAndNameOfSuppliers}
                onSearch={handleSearchTermChange}
                placeholder='Name or email address'
                defaultActiveFirstOption
              />
            </FormItem>

            <FormItem
              name='supplierTypes'
              label='Supplier type'
              className='form-item'
            >
              <MultipleSelect
                // TODO: Update later, waiting for api to be done
                options={supplierRoles}
                placeholder='Choose Supplier type'
                name='supplierTypes'
              />
            </FormItem>

            <div className='form-button'>
              <FormItem className='form-item'>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='yellow-button input-height button-search'
                >
                  Search
                </Button>
              </FormItem>
              <FormItem className='form-item' onClick={clearFilter}>
                <Button className='filter-button input-height button'>
                  Clear Filters
                </Button>
              </FormItem>
            </div>
          </Form>
          <ListView
            onRow={handleNavigate}
            headerColumns={headerTable}
            infoDetail={supplierInfo?.data}
            scroll={{ y: 650 }}
            loading={isFetching}
            handleSort={handleSort}
            locale={{
              emptyText: 'No Suppliers match your search criteria',
            }}
          />
        </div>
        <div className='table-footer-container'>
          <div className='pagination-container'>
            <Pagination
              responsive
              showSizeChanger
              current={currentPage}
              total={supplierInfo?.totalCount}
              pageSize={paginationPageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              onChange={setCurrentPage}
              onShowSizeChange={handleShowSizeChange}
            />
          </div>
        </div>
      </div>
    </GeneralTemplate>
  );
};

export default Suppliers;
