import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const getDocuments = async (/** @type {string|number} */ id) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/documents`;
  const res = await request.get(endpoint);
  const data = normalizeIndexResponse(res);
  return data?.data;
};

export const createDocument = async (/** @type {string/number} */ id, file) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/documents`;
  const res = await request.post(endpoint, { timesheet_upload: { file } });
  return res;
};

export const deleteDocument = async (
  /** @type {string/number} */ id,
  documentId,
) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/documents/${documentId}`;
  const res = await request.delete(endpoint);
  return res;
};
