import React from 'react';

import { Form, message, Image as AntdImage, Popover } from 'antd';

import InfoIcon from '@/assets/icons/infoIcon.png';
import { Checkbox, FormItem, Select } from '@/components';
import { ACCESS_TYPES, ROTA_TYPES } from '@/constants';
import { useOmniUsers } from '@/hooks/dropdowns';

import RotaTypeInfo from './RotaTypeInfo';

const RotaType = () => {
  const formInstance = Form.useFormInstance();
  const selectedTypes = Form.useWatch('selectedTypes', formInstance) ?? [];

  const managers = useOmniUsers();

  const handleCombineTypes = (currentSelectedTypes, selectedType) => {
    if (currentSelectedTypes.length === 2) {
      message.warning('Can not combine all types');
      return currentSelectedTypes;
    }
    if (
      [...currentSelectedTypes, selectedType].includes(ROTA_TYPES[0].value) &&
      [...currentSelectedTypes, selectedType].includes(ROTA_TYPES[1].value)
    ) {
      return [selectedType];
    }

    return [...currentSelectedTypes, selectedType];
  };

  const handleSelectType = (e, type) => {
    let currentSelectedTypes = [...selectedTypes];
    if (e.target.checked) {
      currentSelectedTypes = handleCombineTypes(
        currentSelectedTypes,
        type.value,
      );
    } else {
      currentSelectedTypes.length === 1
        ? (currentSelectedTypes = [ROTA_TYPES[0].value])
        : (currentSelectedTypes = currentSelectedTypes.filter(
            (item) => item !== type.value,
          ));
    }

    handleAccessType(currentSelectedTypes);

    formInstance.setFields([
      {
        name: 'selectedTypes',
        value: currentSelectedTypes,
      },
    ]);
  };

  const isChecked = (value) => selectedTypes.includes(value);

  const handleAccessType = (types) => {
    let accessType = '';
    if (types.length === 1) {
      accessType = ACCESS_TYPES[types[0]];
    }
    if (
      types.includes(ROTA_TYPES[0].value) &&
      types.includes(ROTA_TYPES[2].value)
    ) {
      accessType = ACCESS_TYPES.Flexible_Rota;
    }

    if (
      types.includes(ROTA_TYPES[1].value) &&
      types.includes(ROTA_TYPES[2].value)
    ) {
      accessType = ACCESS_TYPES.Specific_Site_Rota;
    }
    formInstance.setFields([
      {
        name: 'accessType',
        value: accessType,
      },
    ]);
  };

  return (
    <div className='rota-type'>
      <FormItem
        name='selectedTypes'
        hidden
        initialValue={[ROTA_TYPES[0].value]}
      />

      <FormItem name='accessType' hidden initialValue={ROTA_TYPES[0].value} />
      <div className='label'>Rota Type</div>
      <div className='rota-type-box'>
        <div className='title-container'>
          <div className='title'>Worker Rota Type</div>
          <Popover placement='topRight' title='' content={RotaTypeInfo}>
            <AntdImage src={InfoIcon} className='info-icon' preview={false} />
          </Popover>
        </div>
        <div className='types'>
          <div className='group-left'>
            {ROTA_TYPES.map((type) => (
              <div className='document-item-checkbox'>
                <Checkbox
                  onClick={(e) => handleSelectType(e, type)}
                  className='item-checkbox'
                  checked={isChecked(type.value)}
                >
                  <div>{type.label}</div>
                  <Checkbox
                    checked={isChecked(type.value)}
                    onClick={(e) => handleSelectType(e, type)}
                  />
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      </div>

      <FormItem label='Allocated Manager' className='allocated-manager'>
        <Select options={managers} />
      </FormItem>
    </div>
  );
};
export default RotaType;
