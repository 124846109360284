import request from '@/request';

export const getSeekerAutoComplete = async (params) => {
  const res = await request.get('/seekers/employees/search', { params });
  const listItems = res.data.data;
  const convertListItemsToObj = listItems.map(
    ({
      attributes: { employee_fname: firstName, employee_sname: lastName },
    }) => ({
      value: `${firstName} ${lastName}`,
      label: `${firstName} ${lastName}`,
    }),
  );
  return convertListItemsToObj;
};
