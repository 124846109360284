import React, { useState } from 'react';

import { Form, Typography, message } from 'antd';
import fileDownload from 'js-file-download';
import { get, head, isEmpty, isNil, keys, map, sortBy, values } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useToggle } from 'usehooks-ts';

import { FormItem, Modal, TimePicker } from '@/components';
import ModalJobSeeker from '@/components/JobSeeker/ModalJobSeeker';
import { useFetch, useRequest } from '@/hooks';
import CheckInCheckOutTable from '@/pages/Jobs/components/CheckInCheckOutTable';
import getAssignedSchedules from '@/services/dropdownServices/getAssignedSchedules';
import { downloadSeekerListCsv } from '@/services/jobServices';
import { assignJobSeeker } from '@/services/jobServices/assignJobSeeker';
import getPositionJobs from '@/services/jobServices/getPositionJobs';
import getShiftsWithStatus from '@/services/jobServices/getShiftsWithStatus';
import { updateJobSeeker } from '@/services/jobServices/updateJobSeeker';
import { convertToISOString } from '@/utils/date';

import {
  ASSIGNED_SCHEDULES_STATUSES,
  CHECK_IN_TYPE,
  TYPE_CHECKING,
  DATE_FORMAT,
  CHECK_OUT_TYPE,
  DECLINED_TYPE,
  NO_SHOW_TYPE,
  ERROR_CHECK_IN_NOT_VALID_TIME,
  ERROR_CHECK_OUT_NOT_VALID_TIME,
} from './constant';
import genConfirmedCols from './genConfirmedCols';
import PreviewJobPdf from './PreviewJobPdf';
import PrepareData from './utils/prepare';
import Rules from './utils/rules';
import { isTimeCanCheckIn, isTimeCanCheckOut } from './utils/validateTime';
import './styles.scss';

/**
 * @typedef {{isPassword: boolean}} AdditionalProps
 */

/**
 * @param {{startAt: number, endAt: number}[]} schedule
 * selectedShift has format as 'startTime - endTime'
 * @param {string} selectedShift
 * @returns {{
 * startTime: moment.Moment | null,
 * endTime: moment.Moment | null
 * }}
 */
function prepareStartDateEndDate(schedule, selectedShift) {
  let result = {
    startTime: null,
    endTime: null,
  };
  if (!selectedShift) return result;

  const startTime = selectedShift.split(' - ')[0]?.trim();

  if (!schedule) return result;

  schedule.forEach((shift) => {
    if (moment.unix(shift.startAt).format('HH:mm') === startTime) {
      result = {
        startTime: moment.unix(shift.startAt),
        endTime: moment.unix(shift.endAt),
      };
    }
  });

  return result;
}

/**
 * Renders the Confirmed component.
 *
 * @param {Object} props - The component props.
 * @param {number|string} props.positionId - The ID of the position.
 * @param {object} props.jobDetail - The details of the job.
 * @param {boolean} props.isCompletedTab - Indicates if the tab is completed.
 * @return {JSX.Element} The rendered Confirmed component.
 */
const Confirmed = ({ positionId, jobDetail, isCompletedTab }) => {
  const [form] = Form.useForm();
  const selectedShift = Form.useWatch('selectedShift', form);
  const actionKey = Form.useWatch('actionKey', form) ?? CHECK_IN_TYPE;
  const prevSelectedShift = Form.useWatch('prevSelectedShift', form);
  const prevActionKey = Form.useWatch('prevActionKey', form);
  const timePicker = Form.useWatch('timePicker', form);

  const queryClient = useQueryClient();
  const [isVisibleAssignSchedule, setIsVisibleAssignSchedule] = useState(false);
  const [isPreviewJobPdf, togglePreviewJobPdf] = useToggle();

  const [isOpenDeclinedModal, setIsOpenDeclinedModal] = useState(false);
  const date = Form.useWatch(['datePicker', 'selected'], form);
  const [selectedNoShowId, setSelectedNoShowId] = useState();
  const [seekerId, setSeekerId] = useState();
  const schedule = get(jobDetail, 'schedule.items', null);

  const { startTime, endTime } = prepareStartDateEndDate(
    schedule,
    selectedShift,
  );

  if (
    selectedShift &&
    (selectedShift !== prevSelectedShift || actionKey !== prevActionKey)
  ) {
    actionKey === CHECK_OUT_TYPE &&
      form.setFieldsValue({
        timePicker: endTime,
        prevActionKey: CHECK_OUT_TYPE,
        prevSelectedShift: selectedShift,
      });
    actionKey === CHECK_IN_TYPE &&
      form.setFieldsValue({
        timePicker: startTime,
        prevActionKey: CHECK_IN_TYPE,
        prevSelectedShift: selectedShift,
      });
  }

  const setSelectedShift = (shift) =>
    form.setFieldsValue({ selectedShift: shift });

  const setActionKey = (key) => form.setFieldsValue({ actionKey: key });

  const openModalSeekerInfo = (value) => setSeekerId(value);

  const handleCloseDeclinedModal = () => setIsOpenDeclinedModal(false);

  const { data: dateRange, isFetched: dateRangeFetched } = useFetch(
    ['jobs', 'confirmed', positionId],
    () => getAssignedSchedules({ positionId }),
    {
      onSuccess: (dateRangeAttrs) => {
        const beginDate = get(
          head(dateRangeAttrs),
          'date',
          moment().format(DATE_FORMAT),
        );
        form.setFields([
          {
            name: ['datePicker', 'selected'],
            value: beginDate,
          },
        ]);
      },
    },
  );

  const { data: shifts } = useFetch(
    ['jobs', 'confirmed', positionId, date],
    () => getShiftsWithStatus({ positionId, date }),
    {
      onSuccess: (res) => {
        const firstShift = get(head(res), 'shiftTime', null);
        setSelectedShift(firstShift);
      },
      enabled: dateRangeFetched,
    },
  );

  const { data: seekers, isLoading } = useFetch(
    ['positionJobs', positionId, date],
    () => getPositionJobs({ positionId, date }),
    {
      onSuccess: (res) => {
        const initialValues = PrepareData.initialValuesForm(res);
        const firstShift = selectedShift ?? head(keys(initialValues));
        setSelectedShift(firstShift);
        form.setFieldsValue(initialValues);
      },
      enabled: !isNil(date) || !isEmpty(dateRange),
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  const { mutate: checkInCheckOutMutation, isLoading: isLoadingAssign } =
    useRequest(
      (assignedSchedulesAttributes) =>
        assignJobSeeker({ positionId, assignedSchedulesAttributes }),
      {
        onSuccess: () => {
          message.success(`${TYPE_CHECKING[actionKey].label} Successfully!`);
          setIsVisibleAssignSchedule(false);
          form.resetFields();
          queryClient.invalidateQueries({
            queryKey: ['positionJobs', positionId, date],
          });
          queryClient.invalidateQueries({
            queryKey: ['jobs', 'confirmed', positionId],
          });
        },
      },
    );

  const handleCheckInCheckout = () => {
    const formData = form.getFieldsValue();
    const timePicked = get(formData, 'timePicker');
    const createdAt = convertToISOString(timePicked);
    const seekersSelected = PrepareData.getSelectedIds({
      form,
      selectedShift,
      type: TYPE_CHECKING[actionKey].fieldItem,
    });

    const assignedSchedulesAttributes = map(seekersSelected, ({ id }) => ({
      id,
      status: TYPE_CHECKING[actionKey].updateStatus,
      createdAt,
    }));

    checkInCheckOutMutation(assignedSchedulesAttributes);
  };

  const handleNoShow = () => {
    const assignedSchedulesAttributes = [
      {
        id: selectedNoShowId,
        status: TYPE_CHECKING[NO_SHOW_TYPE].updateStatus,
      },
    ];
    checkInCheckOutMutation(assignedSchedulesAttributes);
  };

  const { refetch: downloadCSVJobs } = useFetch(
    ['download-seeker-job-csv'],
    () =>
      downloadSeekerListCsv({
        positionId,
        dateExport: date,
        status: 'confirmed',
      }),
    {
      onSuccess: (res) => {
        fileDownload(res, `download-seeker-job-${date}.xls`);
      },
      enabled: false,
    },
  );

  const { mutate: updateSeekerForJob, isLoading: isLoadingDecline } =
    useMutation(
      ({ selectedIds, status }) =>
        updateJobSeeker(positionId, selectedIds, status),
      {
        onError: () => message.error('Decline Seeker failure'),
        onSuccess: () => {
          message.success('Decline Seeker success');
          setIsOpenDeclinedModal(false);
          form.resetFields();
          queryClient.invalidateQueries({
            queryKey: ['positionJobs', positionId, date],
          });
          queryClient.invalidateQueries({
            queryKey: ['jobs', 'confirmed', positionId],
          });
        },
      },
    );

  const handleDecline = () => {
    const seekersSelected = PrepareData.getSelectedIds({
      form,
      selectedShift,
      type: 'decline',
    });

    const selectedIds = map(seekersSelected, 'jobResponsesId');
    updateSeekerForJob({ selectedIds, status: DECLINED_TYPE });
  };

  const inforDetail = values(
    get(PrepareData.initialValuesForm(seekers), selectedShift, {}),
  );

  const handleConfirm = () => {
    if (actionKey === CHECK_IN_TYPE) {
      return isTimeCanCheckIn({ startTime, endTime, value: timePicker })
        ? handleCheckInCheckout()
        : message.error(ERROR_CHECK_IN_NOT_VALID_TIME);
    }
    if (actionKey === CHECK_OUT_TYPE) {
      return isTimeCanCheckOut({ startTime, endTime, value: timePicker })
        ? handleCheckInCheckout()
        : message.error(ERROR_CHECK_OUT_NOT_VALID_TIME);
    }
    return handleNoShow();
  };

  return (
    <Form
      form={form}
      initialValues={PrepareData.initialValuesForm(seekers)}
      className='confirmed-container'
    >
      <FormItem name='selectedShift' hidden />
      <FormItem name='prevSelectedShift' hidden />
      <FormItem name='actionKey' initialValue={CHECK_IN_TYPE} hidden />
      <FormItem name='prevActionKey' initialValue={CHECK_IN_TYPE} hidden />
      <CheckInCheckOutTable
        columns={genConfirmedCols({
          form,
          dateRange,
          shiftTimes: shifts ?? [],
          setActionKey,
          setIsVisibleAssignSchedule,
          downloadCSVJobs,
          togglePreviewJobPdf,
          setIsOpenDeclinedModal,
          openModalSeekerInfo,
          selectedShift,
          setSelectedShift,
          inforDetail,
          setSelectedNoShowId,
          isCompletedTab,
        })}
        inforDetail={inforDetail}
        isLoading={isLoading}
      />
      <PreviewJobPdf
        isVisible={isPreviewJobPdf}
        toggleVisible={togglePreviewJobPdf}
        jobDetail={jobDetail}
        date={date}
        inforDetail={sortBy(seekers, 'startTime')}
      />
      <Modal
        visible={isVisibleAssignSchedule}
        onClickNo={() => setIsVisibleAssignSchedule(false)}
        onClickYes={handleConfirm}
        isLoading={isLoadingAssign}
      >
        <Typography.Title level={3}>
          {TYPE_CHECKING[actionKey].label}?
        </Typography.Title>
        <div className='time-picker-modal'>
          <p>
            Are you sure you want to {TYPE_CHECKING[actionKey].label} all
            selected workers at:
          </p>
          {ASSIGNED_SCHEDULES_STATUSES.includes(actionKey) && (
            <FormItem
              name='timePicker'
              rules={Rules.validateBeforeAssign({
                startTime,
                endTime,
                actionKey,
              })}
            >
              <TimePicker format='HH:mm' placeholder='00:00' />
            </FormItem>
          )}
        </div>
      </Modal>
      <Modal
        visible={isOpenDeclinedModal}
        onClickNo={handleCloseDeclinedModal}
        onClickYes={handleDecline}
        isLoading={isLoadingDecline}
      >
        <Typography.Title level={3}>Decline?</Typography.Title>
        <div className='time-picker-modal'>
          <p>Are you sure you want to decline all selected workers</p>
        </div>
      </Modal>
      {seekerId && (
        <ModalJobSeeker
          visibleModal={seekerId}
          setVisibleModal={setSeekerId}
          seekerId={seekerId}
        />
      )}
    </Form>
  );
};

export default Confirmed;

Confirmed.propTypes = {
  positionId: PropTypes.number,
  jobDetail: PropTypes.object,
  isCompletedTab: PropTypes.bool,
};

Confirmed.defaultProps = {
  positionId: null,
  jobDetail: {},
};
