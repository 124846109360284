import React from 'react';

import { Card, Col, Divider, Form, Row, message } from 'antd';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';

import { Button, Input, Radio } from '@/components';
import FormItem from '@/components/FormItem';
import Select from '@/components/Select';
import Textarea from '@/components/Textarea';
import TimePicker from '@/components/TimePicker';
import { useFetch } from '@/hooks';
import useUniforms from '@/hooks/dropdowns/useUniforms';
import { JOB_DESCRIPTION_NO_LIMIT } from '@/pages/Jobs/JobModal/constant';
import { getOneRotaShiftPlan, updateRotaShiftPlan } from '@/services/rota';
import useBuildRotaStore from '@/store/rotaStore';
import './styles.scss';
import Formatter from '@/utils/Formatter';
import { rulesDescription } from '@/utils/rulesInput';

const EditView = ({ data, rotaShiftId }) => {
  const [form] = Form.useForm();
  const { data: listUniforms } = useUniforms();

  const queryClient = useQueryClient();

  useFetch(
    ['rota_shift_plans', rotaShiftId],
    () => getOneRotaShiftPlan(rotaShiftId),
    {
      onSuccess: (response) => {
        setRequired(response.numberOfRequired);

        form.setFieldsValue(response);
      },
    },
  );

  let color;
  const agency = useBuildRotaStore((state) => state.agency);
  const required = useBuildRotaStore((state) => state.required);
  const setRequired = useBuildRotaStore((state) => state.setRequired);
  const setAgency = useBuildRotaStore((state) => state.setAgency);

  const { contracted, hours } = data;
  const difference = contracted - required + agency;
  if (difference < 0) {
    color = '#DD9DA0';
  } else if (difference > 0 && difference !== contracted) {
    color = '#F6E6A7';
  } else {
    color = '#BAD0CA';
  }
  const { mutate: mutateRotaShiftPlan } = useMutation(
    async (values) => updateRotaShiftPlan({ id: rotaShiftId, values }),
    {
      onError: (error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        message.success('Update rota shift plan successfully!');
        queryClient.invalidateQueries({ queryKey: ['getBuildRota'] });
      },
    },
  );

  const handleSubmit = () => {
    mutateRotaShiftPlan(form.getFieldsValue());
  };

  return (
    <Form form={form} className='attendance' onFinish={handleSubmit}>
      <Row>
        <Col span={8}>
          <Card className='event-tag'>Columns</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>People</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>Hours</Card>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Card className='event-tag'>Contracted</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>{contracted}</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>{contracted * hours}</Card>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Card className='event-tag'>Required</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>
            <Input
              value={required}
              onChange={(e) => setRequired(e.target.value)}
              className='input-field'
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>{required * hours}</Card>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Card className='event-tag'>Agency</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>
            <Input
              defaultValue={agency}
              onChange={(e) => setAgency(e.target.value)}
              className='input-field'
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>{agency * hours}</Card>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Card
            style={{
              backgroundColor: color,
            }}
            className='event-tag'
          >
            Difference
          </Card>
        </Col>
        <Col span={8}>
          <Card
            className='event-tag'
            style={{
              backgroundColor: color,
            }}
          >
            {difference}
          </Card>
        </Col>
        <Col span={8}>
          <Card
            className='event-tag'
            style={{
              backgroundColor: color,
            }}
          >
            {difference * hours}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Card className='event-tag'>Confirmed</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>TBC</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>TBC</Card>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Card className='event-tag'>Attended</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>TBC</Card>
        </Col>
        <Col span={8}>
          <Card className='event-tag'>TBC</Card>
        </Col>
      </Row>
      <Divider />
      <h3>Edit Shift Details</h3>
      <div className='edit-time'>
        <FormItem
          className='seeker-date-item'
          name='startTime'
          label='Start Time'
        >
          <TimePicker
            format='HH:mm'
            className='time-picker'
            placeholder='start time'
          />
        </FormItem>
        <FormItem className='seeker-date-item' name='endTime' label='End Time'>
          <TimePicker
            format='HH:mm'
            className='time-picker'
            placeholder='End time'
          />
        </FormItem>
        <FormItem className='seeker-date-item' name='breakTime' label='Break'>
          <Input className='time-picker' placeholder='Break' />
        </FormItem>
      </div>
      <div className='job-description'>
        <FormItem
          name='jobDescription'
          className='textarea'
          label='Job Description'
          required
          rules={rulesDescription()}
          normalize={Formatter.removeExtraSpaces}
        >
          <Textarea
            size='large'
            placeholder='Job Description'
            className='input-view input-area'
            maxLength={JOB_DESCRIPTION_NO_LIMIT}
          />
        </FormItem>
        <FormItem
          name='specialRequirements'
          className='textarea'
          label='Special Requirements'
        >
          <Textarea
            size='small'
            placeholder='Special Requirements'
            className='input-view input-area'
          />
        </FormItem>
      </div>

      <h3>Uniform</h3>
      <div className='edit-time'>
        <FormItem
          className='seeker-date-item'
          name={['uniformAttributes', 0, 'id']}
        >
          <Select
            options={listUniforms?.listShirts}
            dropdownClassName='dropdown-select'
          />
        </FormItem>
        <FormItem
          className='seeker-date-item'
          name={['uniformAttributes', 1, 'id']}
        >
          <Select
            options={listUniforms?.listJeans}
            dropdownClassName='dropdown-select'
          />
        </FormItem>
        <FormItem
          className='seeker-date-item'
          name={['uniformAttributes', 2, 'id']}
        >
          <Select
            options={listUniforms?.listShoes}
            dropdownClassName='dropdown-select'
          />
        </FormItem>
      </div>
      <div className='additional-uniform'>
        <FormItem
          className='seeker-date-item'
          label='Additional Uniform'
          name={['uniformAttributes', 3, 'id']}
        >
          <Select
            options={listUniforms?.listAdditionalUniforms}
            dropdownClassName='dropdown-select'
          />
        </FormItem>
        <FormItem className='radio-item' label='Update Job Information' name=''>
          <Radio
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
          />
        </FormItem>
      </div>
      <div className='update-button'>
        <Button className='yellow-button' htmlType='submit'>
          Update Shift
        </Button>
      </div>
    </Form>
  );
};

EditView.propTypes = {
  data: PropTypes.object,
  rotaShiftId: PropTypes.string,
};

export default EditView;
