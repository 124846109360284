import React, { cloneElement, useState } from 'react';

import { Collapse, Form, Modal } from 'antd';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { FormItem, ModalConfirm } from '@/components';
import { useProviders, useRoleItems } from '@/hooks/dropdowns';
import useUniforms from '@/hooks/dropdowns/useUniforms';
import { JobServices } from '@/services';
import useModal from '@/utils/useModal';

import { FORM_NAME } from '../JobListPage/constants';
import { checkErrorShift, coverCeilTo15Minutes } from './constant';
import DatesAndTimes from './DatesAndTimes';
import JobDescription from './JobDescription';
import LicencesAndTraining from './licencesAndTraining';
import ProviderAndLocation from './ProviderAndLocation';
import ReviewAndPost from './ReviewAndPost';
import Seeker from './Seeker';
import { useTenantContext } from '../../../TenantWrapper';

const { Panel } = Collapse;

/**
 *
 * @typedef {Object} Props
 * @property {boolean} showPopup
 * @property {Function} hidePopup
 * @property {Function} onSubmit
 * @property {string} status
 * @property {boolean} isLoading
 * @property {import('antd').FormInstance} form
 * @property {boolean} disabled
 */

/**
 * @param {Props} props
 */
const JobModal = ({
  disabled,
  form,
  hidePopup,
  isLoading,
  onSubmit,
  showPopup,
  status,
}) => {
  const { defaultLimitPayRate, currency } = useTenantContext();
  const listProviders = useProviders(true);
  const { data: listSkill, group } = useRoleItems();
  const providerId = Form.useWatch('employerId', form);
  const skillId = Form.useWatch('skillId', form);
  const locationId = Form.useWatch('locationId', form);
  const schedule = Form.useWatch(['schedule', 'items'], form) ?? [];
  const { data: listUniforms } = useUniforms();
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const [activeKey, setActiveKey] = useState(['Provider and Location']);

  const now = moment();
  const startMoment = coverCeilTo15Minutes(now);
  const endMoment = moment(startMoment).add(1, 'h');

  const handleFinish = async () => {
    if (!isNotValidate) {
      const seekersPayTemplates =
        form.getFieldValue('seekersPayTemplates') ?? [];
      const errorsSchedule = checkErrorShift(schedule, seekersPayTemplates);

      const isError = errorsSchedule.some((e) => e !== '');

      form.setFields([
        {
          name: 'items',
          errors: errorsSchedule,
        },
      ]);

      if (isError) {
        await scrollToFistErrorField('items');
      } else {
        showModalConfirmPayrate();
      }
    } else {
      showModalConfirmPayrate();
    }
  };

  const showModalConfirmPayrate = () => {
    const { payrate } = form.getFieldsValue();
    if (!isDisplayModal && parseFloat(payrate) < defaultLimitPayRate) {
      return showModal();
    }

    return onSubmit();
  };

  const onSubmitModal = () => {
    hiddenModal();
    onSubmit();
  };

  const scrollToFistErrorField = async (name) => {
    setActiveKey([
      'Provider and Location',
      'Dates and Times',
      'Job Description',
      'Licences and Training',
      'Seekers',
      'Review and Post',
    ]);

    setTimeout(() => {
      form.scrollToField(name, {
        skipOverflowHiddenElements: true,
        block: 'center',
        behavior: 'smooth',
      });
    }, 500);
  };

  const handelFinishFailed = () => {
    const err = form.getFieldsError();

    const error = err.find((e) => e.errors.length);

    const seekersPayTemplates = form.getFieldValue('seekersPayTemplates') ?? [];
    const errorsSchedule = checkErrorShift(schedule, seekersPayTemplates);

    form.setFields([
      {
        name: 'items',
        errors: errorsSchedule,
      },
    ]);

    scrollToFistErrorField(error.name);
  };

  const panels = [
    {
      header: (
        <div className='header-title'>
          <span className='number-container'>1.</span>
          <span>Provider and Location</span>
        </div>
      ),
      component: <ProviderAndLocation />,
      key: 'Provider and Location',
    },
    {
      header: (
        <div className='header-title'>
          <span className='number-container'>2.</span>
          <span>Dates and Times</span>
        </div>
      ),
      component: <DatesAndTimes />,
      key: 'Dates and Times',
    },
    {
      header: (
        <div className='header-title'>
          <span className='number-container'>3.</span>
          <span>Job Description</span>
        </div>
      ),
      component: <JobDescription />,
      key: 'Job Description',
    },
    {
      header: (
        <div className='header-title'>
          <span className='number-container'>4.</span>
          <span>Licences and Training</span>
        </div>
      ),
      component: <LicencesAndTraining />,
      key: 'Licences and Training',
    },
    {
      header: (
        <div className='header-title'>
          <span className='number-container'>5.</span>
          <span>Seeker</span>
        </div>
      ),
      component: <Seeker />,
      key: 'Seeker',
    },
    {
      header: (
        <div className='header-title'>
          <span className='number-container'>6.</span>
          <span>Review and Post</span>
        </div>
      ),
      component: <ReviewAndPost />,
      key: 'Review and Post',
    },
  ];

  const { mutate: getTemplate, isLoading: isLoadingGetATemplate } = useMutation(
    ({ skillIdValue, locationIdValue }) =>
      JobServices.getTemplateDataJob(providerId, {
        positionId: skillIdValue ?? skillId,
        locationId: locationIdValue ?? locationId,
      }),
    {
      onSuccess: (data) => {
        const { listJeans, listShirts, listShoes } = listUniforms;
        const shirtValues = listShirts.map((item) => item.value);
        const jeanValues = listJeans.map((item) => item.value);
        const shoeValues = listShoes.map((item) => item.value);
        const { jobTemplatesUniforms, preRequisitJobTemplates, breakTime } =
          data;

        const items = schedule.map((e) => ({
          ...e,
          break: breakTime,
        }));

        const errorsSchedule = checkErrorShift(items);

        form.setFields([
          {
            name: 'items',
            errors: errorsSchedule,
          },
        ]);

        const preRequisits = preRequisitJobTemplates.map((e) => ({
          employerId: e.employerId,
          skillId: e.skillId,
        }));

        const uniformValues = jobTemplatesUniforms?.map((item) =>
          parseInt(item.uniformId, 10),
        );

        const shirt = uniformValues.find((uniformId) =>
          shirtValues.includes(uniformId),
        );

        const jean = uniformValues.find((uniformId) =>
          jeanValues.includes(uniformId),
        );

        const shoe = uniformValues.find((uniformId) =>
          shoeValues.includes(uniformId),
        );

        const additionalUniform = uniformValues.filter(
          (item) => !(item === shirt || item === jean || item === shoe),
        );

        form.setFieldsValue({
          ...data,
          shirt,
          jean,
          shoe,
          additionalUniform,
          preRequisits,
          schedule: {
            items,
          },
        });
      },
      onError: () => {
        const items = schedule.map((e) => ({ ...e, break: 0 }));
        const formData = { ...form.getFieldsValue(), schedule: { items } };

        form.resetFields();
        form.setFieldsValue(formData);
      },
    },
  );

  const initialValues = {
    posted: 1,
    requestedNumber: 1,
    schedule: {
      items: [
        {
          startAt: startMoment.valueOf(),
          endAt: endMoment.valueOf(),
          break: 0,
          posted: 1,
        },
      ],
    },
    allowWithoutLicences: false,
    shiftType: 'group_shift',
    repeatType: 'weeks',
    repeatFor: 0,
    jobResponsesAttributes: [],
    nudgesAttributes: [],
    preRequisits: [],
    licenses: [],
    payrate: defaultLimitPayRate,
  };

  const onChangeCollapse = (key) => setActiveKey(key);

  const titlePopup = status === 'edit' ? 'Edit' : 'Job Posting';

  const isNotValidate = status === 'edit' && disabled;

  return (
    <>
      <ModalConfirm
        visible={isDisplayModal}
        onClickNo={hiddenModal}
        onClickYes={onSubmitModal}
        zIndex={10_000}
      >
        Are you sure you want to update the pay rate to below minimum wage of{' '}
        {currency}
        {defaultLimitPayRate}?
      </ModalConfirm>

      <Modal
        onCancel={() => hidePopup()}
        footer={null}
        className='job-posting-modal'
        visible={showPopup}
        confirmLoading={isLoading || isLoadingGetATemplate}
      >
        <div className='popup-header'>
          <h3 className='popup-title'>{titlePopup}</h3>
        </div>
        <div className='popup-body'>
          <Form
            initialValues={initialValues}
            form={form}
            onFinish={handleFinish}
            noValidate={isNotValidate}
            onFinishFailed={handelFinishFailed}
            name={FORM_NAME.POSTING_FORM}
          >
            <FormItem name='status' noStyle />
            <Collapse
              activeKey={activeKey}
              onChange={onChangeCollapse}
              expandIcon={() => null}
              className='job-posting-collapse'
            >
              {panels.map((panel) => (
                <Panel header={panel.header} key={panel.key} forceRender>
                  {cloneElement(panel.component, {
                    listProviders,
                    listSkill,
                    status,
                    disabled,
                    getTemplate,
                    isLoading,
                    isLoadingGetATemplate,
                    group,
                  })}
                </Panel>
              ))}
            </Collapse>
          </Form>
        </div>
      </Modal>
    </>
  );
};

JobModal.propTypes = {
  hidePopup: PropTypes.func,
  showPopup: PropTypes.bool,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  isLoading: PropTypes.bool,
  form: PropTypes.any,
  disabled: PropTypes.bool,
};

JobModal.defaultProps = {
  onSubmit: () => {},
  showPopup: false,
  hidePopup: () => {},
  status: '',
  isLoading: false,
  disabled: false,
};

export default JobModal;
