import React from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { ReactComponent as CircleIconBlockedSeeker } from '@/assets/icons/circleIconBlockedSeeker.svg';
import under18 from '@/assets/icons/under18.png';
import { Tabs } from '@/components';
import SeekerTemplate from '@/components/SeekerTemplate';
import { useFetch, useQueryString } from '@/hooks';
import { getSeekerProfile } from '@/services/seeker/getSeekerProfile';

import Attendance from './Attendance';
import SeekerJos from './SeekerJobs';
import SeekerProfile from './SeekerProfile';
import Timesheets from './Timesheets';
import './styles.scss';

const tabPanes = [
  {
    tab: 'Profile',
    key: 'seeker-profile',
    child: <SeekerProfile />,
  },
  {
    tab: 'Jobs',
    key: 'seeker-jobs',
    child: <SeekerJos />,
  },
  {
    tab: 'Timesheets',
    key: 'seeker-timesheets',
    child: <Timesheets />,
  },
  {
    tab: 'Attendance',
    key: 'seeker-attendance',
    child: <Attendance />,
  },
];

const SeekerProfilePage = () => {
  const { id } = useParams();
  const breadcrumb = [
    { item: 'Seeker', to: '/seeker' },
    { item: 'View / Edit', to: `/seeker-profile/${id}` },
  ];

  const [queryString, setQueryString] = useQueryString(
    () => {},
    () => {},
    false,
  );

  const { data: seekerInfo, isLoading } = useFetch(['seekerInfo'], () =>
    getSeekerProfile(id),
  );

  const onChangeTabKey = (key) => {
    setQueryString({ tab: key });
  };

  return (
    <SeekerTemplate
      header={
        isLoading ? (
          <Spin />
        ) : (
          <div className='seeker-header-text-custom'>
            <span className='seeker-name'>
              Seeker - {seekerInfo?.employeeFname} {seekerInfo?.employeeSname}
            </span>
            <span className='block-icon'>
              {seekerInfo?.isBlocked && <CircleIconBlockedSeeker />}
            </span>
            <span className='block-icon'>
              {!seekerInfo?.isOver18 && <img src={under18} alt='under18' />}
            </span>
          </div>
        )
      }
      data={breadcrumb}
      subTemplateClassName='seeker-container seeker-profile-body'
      hasButton={false}
    >
      <Tabs
        tabPanes={tabPanes}
        activeKey={queryString?.tab ?? 'seeker-profile'}
        onChange={onChangeTabKey}
        defaultActiveKey='seeker-profile'
      />
    </SeekerTemplate>
  );
};
export default SeekerProfilePage;
