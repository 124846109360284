import React, { useState } from 'react';

import { Form } from 'antd';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useToggle } from 'usehooks-ts';

import { Modal } from '@/components';
import SickNoShowModal from '@/pages/Payroll/components/SickNoShowModal';
import { ACTIONS } from '@/pages/Timesheet/constants';
import { scrollToFirstError } from '@/pages/Timesheet/utils/rules';
import { getAllTimeCardIsChecked } from '@/pages/Timesheet/utils/select';

import GroupButtons from './GroupButtons';
import { useButtons } from './hooks/useButtons';

import './styles.scss';

const TimecardFooter = ({
  status,
  jobTypeId,
  isEdit,
  setIsEdit,
  actions,
  isPushed,
  isCurrentWeekPushed,
  rowData,
}) => {
  const formInstance = Form.useFormInstance();
  const [actionKey, setActionKey] = useState('');
  const actionText = get(ACTIONS, [actionKey, 'label']);
  const formData = Form.useWatch(jobTypeId, formInstance);
  const [isConfirm, toggleConfirm, setConfirm] = useToggle();
  const { timecardIds, timecardIdsSickNoShow, timecardIdsWithoutNoShow } =
    getAllTimeCardIsChecked({
      formData,
    });
  const [isSickNoShow, toggleSickNoShow, setSickNoShow] = useToggle();

  const isDisabledButton = isEmpty(timecardIds);
  const isDisabledSickNoShowButton =
    !isEmpty(timecardIdsSickNoShow) && isEmpty(timecardIdsWithoutNoShow);

  const isOriginalWeekPaid = rowData.every(
    (item) => item?.pushFromTimesheet?.isOriginalPaid,
  );

  const buttons = useButtons({
    isEdit,
    isCurrentWeekPushed,
    isDisabledButton,
    toggleConfirm,
    setIsEdit,
    setActionKey,
    toggleSickNoShow,
    formInstance,
    scrollToFirstError,
    isPushed,
    timecardIdsSickNoShow,
    isDisabledSickNoShowButton,
    isDisabledPullBackButton: isOriginalWeekPaid,
  });

  const STATUS_BUTTONS = {
    unapproved: (
      <GroupButtons
        buttonProperties={[
          buttons.undo,
          buttons.sick,
          buttons.noShow,
          buttons.deleteHours,
          buttons.approveHours,
          buttons.edit,
          buttons.save,
          buttons.pullBack,
        ]}
      />
    ),
    approved: (
      <GroupButtons
        buttonProperties={[
          buttons.undo,
          buttons.sick,
          buttons.noShow,
          buttons.deleteHours,
          buttons.confirmHours,
          buttons.edit,
          buttons.save,
          buttons.pullBack,
        ]}
      />
    ),
    confirmed: (
      <GroupButtons buttonProperties={[buttons.confirmed, buttons.seeDetail]} />
    ),
    finalised: (
      <GroupButtons buttonProperties={[buttons.confirmed, buttons.seeDetail]} />
    ),
    paid: <GroupButtons buttonProperties={[buttons.seeDetail]} />,
  };

  const handleAction = () => {
    actions[actionKey]();
    setConfirm(false);
    setSickNoShow(false);
  };

  return (
    <div className='footer'>
      <div className='container'>
        <div className='btn-group'>{STATUS_BUTTONS[status]}</div>
        <Modal
          visible={isConfirm}
          title={actionText}
          onClickYes={handleAction}
          onCancel={toggleConfirm}
          onClickNo={toggleConfirm}
        >
          Are you sure you want to {actionText} for all selected seekers?
        </Modal>
        <SickNoShowModal
          name='comment'
          type={actionKey}
          visible={isSickNoShow}
          onCancel={toggleSickNoShow}
          onConfirm={handleAction}
        />
      </div>
    </div>
  );
};

TimecardFooter.propTypes = {
  status: PropTypes.string,
  jobTypeId: PropTypes.number,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  actions: PropTypes.object,
  isPushed: PropTypes.bool,
  isCurrentWeekPushed: PropTypes.bool,
  rowData: PropTypes.array,
};

TimecardFooter.defaultProps = {
  status: 'unapproved',
  isEdit: false,
  setIsEdit: () => {},
  isPushed: false,
  isCurrentWeekPushed: false,
  rowData: [],
};

export default TimecardFooter;
