import { get, groupBy, head } from 'lodash';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const getDetail = async (id) => {
  const endpoint = `pay_templates/invoices/${id}`;
  const res = await request(endpoint);
  const { data } = normalizeShowResponse(res);

  const groupedSections = groupBy(data?.invoiceDetails, 'invoiceDetailType');
  const labourDesc = get(head(groupedSections?.labour), 'description', null);

  return {
    ...data,
    ...groupedSections,
    labourDesc,
  };
};

export default getDetail;
