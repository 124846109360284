import React, { useMemo } from 'react';

import { Select, Tag, Form } from 'antd';
import { sumBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as CalendarIcon } from '@/assets/icons/calendarIcon.svg';

import CustomCalendar from './CustomCalendar';

const toValidArray = (value) => {
  const arr = Array.isArray(value) ? value : [value];
  return arr.filter((e) => e != null && e !== undefined);
};

const renderTextMonthFormatted = (dateText, isEnd, isBold) => {
  const dateTextCustom = isEnd ? `${dateText}.` : `${dateText},`;

  return isBold ? (
    <span className='date-bold'>{dateTextCustom}</span>
  ) : (
    dateTextCustom
  );
};

const convertValues = (dates = [], monthFormat = 'Do') =>
  dates.map((elm, idx) => ({
    value: {
      monthText: moment(elm.date).format(monthFormat),
      isEnd: idx === dates.length - 1,
      isBold: elm.time === 4, // 4 = half day
    },
  }));

const renderTag = ({ value, onClose }) => {
  const { monthText, isEnd, isBold } = value;

  return (
    <Tag onClose={onClose} closable={false} style={{ marginRight: 3 }}>
      {renderTextMonthFormatted(monthText, isEnd, isBold)}
    </Tag>
  );
};

const MultipleDatePicker = ({ fieldIndex, value, disabledDate }) => {
  const formInstance = Form.useFormInstance();
  const arrValues = useMemo(() => toValidArray(value), [value]);

  const onChange = (dates) => {
    const { holidays } = formInstance.getFieldsValue();

    const numberOfHolidayDays = sumBy(dates, (date) => date.time / 8);

    holidays[fieldIndex] = {
      ...holidays[fieldIndex],
      daysRequested: dates,
      numberOfHolidayDays,
    };

    return formInstance.setFieldsValue({
      holidays,
    });
  };

  return (
    <Select
      className='select-days-holiday'
      mode='tags'
      value={convertValues(arrValues)}
      dropdownMatchSelectWidth={false}
      tagRender={renderTag}
      maxTagCount={7}
      suffixIcon={<CalendarIcon />}
      onInputKeyDown={(event) => {
        event.preventDefault();
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={() => (
        <CustomCalendar
          arrValues={arrValues}
          onChange={onChange}
          disabledDate={disabledDate}
        />
      )}
    />
  );
};

MultipleDatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  fieldIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabledDate: PropTypes.func,
};

export default MultipleDatePicker;
