import isEmpty from 'lodash/isEmpty';

export const parseELearnings = (vacancyApplicant) => {
  const {
    manualHanding,
    foodSafetyAllergens,
    spectatorsSafety,
    llopTraining,
    induction,
    manualHandingDate,
    foodSafetyAllergensDate,
    spectatorsSafetyDate,
    alcoholLicenseDate,
    llopTrainingDate,
    alcoholLicense,
    inductionDate,
    blankBoxes,
  } = vacancyApplicant;

  return [
    {
      date: manualHandingDate,
      fileBlob: manualHanding,
      fileValue: manualHanding,
      typeValue: 'manualHanding',
      typeLabel: 'Manual Handling',
    },
    {
      date: foodSafetyAllergensDate,
      fileBlob: foodSafetyAllergens,
      fileValue: foodSafetyAllergens,
      typeValue: 'foodSafetyAllergens',
      typeLabel: 'Food Safety',
    },
    {
      date: spectatorsSafetyDate,
      fileBlob: spectatorsSafety,
      fileValue: spectatorsSafety,
      typeValue: 'spectatorsSafety',
      typeLabel: 'Spectator Safety',
    },
    {
      date: alcoholLicenseDate,
      fileBlob: alcoholLicense,
      fileValue: alcoholLicense,
      typeValue: 'alcoholLicense',
      typeLabel: 'Alcohol Licence',
    },
    {
      date: llopTrainingDate,
      fileBlob: llopTraining,
      fileValue: llopTraining,
      typeValue: 'llopTraining',
      typeLabel: 'LLOP Training',
    },
    {
      date: inductionDate,
      fileBlob: induction,
      fileValue: induction,
      typeValue: 'induction',
      typeLabel: 'Induction',
    },
    ...blankBoxes.map((item) => ({
      date: item.date,
      fileBlob: item.link,
      fileValue: item.link,
      typeValue: `blankBox-${item.name}`,
      typeLabel: isEmpty(item.name) ? 'Blank Box' : item.name,
      id: item.id,
    })),
  ].filter((item) => item.fileBlob);
};
