/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Collapse, Form } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { Button } from '@/components';

import { OPACITY_COLORS_TIMESHEET } from '../constants';
import { generateColor } from '../utils';
import ListTimecards from './ListTimecards';
import TotalHeader from './TotalHeader';

import './styles.scss';

const { Panel } = Collapse;

const renderTotal = ({
  status,
  statusColor,
  panelInfo,
  timesheetId,
  timesheetRefetch,
}) => (
  <TotalHeader
    status={status}
    statusColor={statusColor}
    panelInfo={panelInfo}
    timesheetId={timesheetId}
    timesheetRefetch={timesheetRefetch}
  />
);

const ListJobTypeStatus = ({
  isHourlyTemplate,
  isLoadingPayTemplate,
  timesheetRefetch,
  data = [],
  status = 'unapproved',
  statusColor = 'red',
}) => {
  const [form] = Form.useForm();
  const { id: timesheetId } = useParams();

  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleExpandAll = (list) => {
    if (expandedPanels.length === list.length) {
      setExpandedPanels([]);
    } else {
      setExpandedPanels(list);
    }
  };

  const handlePanelToggle = (panelId) => {
    setExpandedPanels((prevState) =>
      prevState.includes(panelId)
        ? prevState.filter((id) => id !== panelId)
        : [...prevState, panelId],
    );
  };
  const listPanels = [];

  return (
    <>
      {!isEmpty(data) &&
        data.map((panelInfo) => {
          const panelTitle = `${panelInfo?.seekerName} -
        ${panelInfo?.timecards?.[0]?.payrollTemplate?.name}`;
          const backgroundColor = generateColor({
            string: `${panelInfo?.skillName}-${status}`,
            opacity: OPACITY_COLORS_TIMESHEET,
          });
          listPanels.push(panelInfo.id);
          return (
            <Collapse
              expandIconPosition='right'
              ghost={isHourlyTemplate}
              activeKey={[...expandedPanels]}
              onChange={() => handlePanelToggle(panelInfo.id)}
            >
              <Panel
                className={classNames('panel-item', {
                  'hours-per-day': !isHourlyTemplate,
                })}
                header={panelTitle}
                key={panelInfo.id}
                extra={renderTotal({
                  status,
                  statusColor,
                  panelInfo,
                  timesheetId,
                  timesheetRefetch,
                })}
              >
                <Form
                  form={form}
                  className='panel-container'
                  style={{
                    backgroundColor,
                  }}
                >
                  <ListTimecards
                    status={status}
                    isHourlyTemplate={isHourlyTemplate}
                    timesheetId={timesheetId}
                    isLoadingPayTemplate={isLoadingPayTemplate}
                    panelInfo={panelInfo}
                    timesheetRefetch={timesheetRefetch}
                  />
                </Form>
              </Panel>
            </Collapse>
          );
        })}
      <Button
        className='expand-button'
        onClick={() => handleExpandAll(listPanels)}
      >
        {expandedPanels.length === listPanels.length
          ? 'Collapse all'
          : 'Expand all'}
      </Button>
    </>
  );
};

export default ListJobTypeStatus;
