import request from '@/request';

const updateTimecard = async ({
  /** @type {string|number} */ timesheetId,
  /** @type {string|number} */ panelId,
  /** @type {object} */ timesheetDetail,
}) => {
  const res = await request.patch(
    `pay_templates/timesheets/${timesheetId}/timesheet_details/${panelId}`,
    {
      ...timesheetDetail,
    },
  );
  return res.data;
};

export default updateTimecard;
