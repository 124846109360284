import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { FormItem, Button, CSV, ModalConfirm } from '@/components';
import { useDownloadCsv } from '@/hooks';
import { downloadEmployeesCsv } from '@/services/payrollService';

export const NewEmployeeCsv = ({ filter }) => {
  const [visible, setVisible] = useState(false);
  const {
    refCSVLink,
    data: newEmployeeCsv,
    refetch: refetchNewEmployeeCsv,
    isFetching: isFetchingDownload,
  } = useDownloadCsv(['download-new-employee-csv'], () =>
    downloadEmployeesCsv({ isNew: true, ...filter }),
  );

  const handleOpenModal = () => setVisible(true);

  const handleCloseModal = () => setVisible(false);

  const handleDownloadCsv = () => {
    handleCloseModal();
    refetchNewEmployeeCsv();
  };

  return (
    <div className='show-details '>
      <FormItem label='New Employee CSV'>
        <Button
          onClick={handleOpenModal}
          className='yellow-button new-employee-download input-height'
        >
          {isFetchingDownload ? 'Loading Csv...' : 'Download New Employee'}
        </Button>
      </FormItem>
      <CSV ref={refCSVLink} filename='new-employee.csv' data={newEmployeeCsv} />

      <ModalConfirm
        title='Download Employee Details'
        visible={visible}
        onClickNo={handleCloseModal}
        onClickYes={handleDownloadCsv}
      >
        Do you want to download the details for all new Employees for this week?
      </ModalConfirm>
    </div>
  );
};

NewEmployeeCsv.propTypes = {
  filter: PropTypes.object,
};

NewEmployeeCsv.defaultProps = {
  filter: {},
};

export default NewEmployeeCsv;
