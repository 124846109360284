import React, { useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Textarea, ModalConviction } from '@/components';

import './styles.scss';

const AdditionalInformation = ({
  nextStep,
  onValuesChange,
  initialValues,
  form,
}) => {
  const [visibleExtraInfo, setVisibleExtraInfo] = useState(false);
  const hasConvict = Form.useWatch('hasConvict', form);

  return (
    <div className='addition-info-container flex-one-column'>
      <div className='title'>Additional Information</div>
      <span>
        Have you any convictions (other than spent convictions under the
        Rehabilitation of Offenders Act 1974)?*{' '}
        <InfoCircleOutlined
          onClick={() => setVisibleExtraInfo(true)}
          className='additional-info-icon'
        />
      </span>

      <ModalConviction
        visible={visibleExtraInfo}
        onCancel={() => setVisibleExtraInfo(false)}
      />
      <Form
        onFinish={nextStep}
        form={form}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <FormItem name='hasConvict'>
          <Radio.Group className='additional-info-radio' defaultValue={0}>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </Radio.Group>
        </FormItem>
        <FormItem
          label='Please give as much detail as you can.'
          hidden={!hasConvict}
          name='convict'
          rules={
            hasConvict && [
              {
                required: true,
                message: 'Please enter more detail.',
              },
            ]
          }
        >
          <Textarea
            rows={3}
            maxLength={255}
            placeholder='Please add more details'
          />
        </FormItem>
        <Button htmlType='submit' className='shared-button confirm-button'>
          Next Step
        </Button>
      </Form>
    </div>
  );
};
AdditionalInformation.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default AdditionalInformation;
