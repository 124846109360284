import React, { useState } from 'react';

import { Form, Pagination } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Button, CSV, FormItem, ListView } from '@/components';
import { PAGE_SIZE_OPTIONS } from '@/constants';
import { useDownloadCsv, useFetch } from '@/hooks';
import usePagination from '@/hooks/usePagination';
import JobModalEdit from '@/pages/Jobs/JobModal/Edit';
import downloadJobCsv from '@/services/seeker/downloadJobCsv';
import { getJobsSeeker } from '@/services/seeker/getJobsSeeker';
import { array } from '@/utils';
import Formatter from '@/utils/Formatter';

import { columns } from './constants';
import SeekerJobsSearchFields from './SeekerJobsSearchFields';

const JobSeekers = ({ statuses }) => {
  const [form] = Form.useForm();

  const { id } = useParams();

  const [sortOrder, setSortOrder] = useState('desc');
  const [jobIdEdit, setJobIdEdit] = useState('');

  const {
    currentPage,
    paginationPageSize,
    handlePagination,
    handleShowSizeChange,
  } = usePagination();

  const {
    searchTerm,
    role,
    provider,
    registrationDateFrom,
    registrationDateTo,
    industry,
  } = form.getFieldsValue();

  const filter = {
    statuses,
    searchTerm,
    role,
    provider,
    registrationDateFrom:
      registrationDateFrom && moment(registrationDateFrom).format('YYYY-MM-DD'),
    registrationDateTo:
      registrationDateTo && moment(registrationDateTo).format('YYYY-MM-DD'),
    industry: array.toStringOfNumber(industry),
  };

  const dependencies = {
    statuses,
    currentPage,
    paginationPageSize,
    sortOrder,
    searchTerm,
    role,
    provider,
    industry,
    registrationDateFrom,
    registrationDateTo,
  };

  const params = {
    id,
    filter,
    sortOrder,
    orderBy: 'shift_time',
    pageNumber: currentPage,
    pageSize: paginationPageSize,
  };

  const {
    isFetching,
    data: dataJobsSeeker,
    refetch,
  } = useFetch(['seekers', { ...dependencies }], () => getJobsSeeker(params));

  const {
    refCSVLink,
    data: dataSeekerExportCSV,
    refetch: refetchSeekerExportCSV,
    isFetching: isFetchingDownloadCsv,
  } = useDownloadCsv(['provider-export-csv'], () => downloadJobCsv(params));

  const onRow = (rowId) => {
    setJobIdEdit(rowId);
  };

  const handleSort = (ord) => setSortOrder(Formatter.makeAbbrevSortType(ord));
  const clearFilter = () => {
    form.resetFields();
    refetch();
  };

  return (
    <div className='seeker'>
      {jobIdEdit !== '' && (
        <JobModalEdit
          hidePopup={() => setJobIdEdit('')}
          showPopup={jobIdEdit !== ''}
          jobId={jobIdEdit}
        />
      )}
      <Form className='seeker-jobs-fields' form={form} onFinish={refetch}>
        <SeekerJobsSearchFields />
        <div className='seeker-field'>
          <div className='seeker-field-button'>
            <FormItem>
              <Button
                type='primary'
                htmlType='submit'
                className='button button-search'
              >
                Search
              </Button>
            </FormItem>
            <FormItem>
              <Button className='button button-clear' onClick={clearFilter}>
                Clear Filter
              </Button>
            </FormItem>
          </div>
        </div>
      </Form>
      <ListView
        loading={isFetching}
        infoDetail={dataJobsSeeker?.data}
        headerColumns={columns}
        onRow={onRow}
        handleSort={handleSort}
      />
      <div className='seeker-footer'>
        <Pagination
          className='seeker-pagination'
          responsive
          showSizeChanger
          current={currentPage}
          total={dataJobsSeeker?.totalCount}
          pageSize={paginationPageSize}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onChange={handlePagination}
          onShowSizeChange={handleShowSizeChange}
        />
        <Button
          className='seeker-download-csv yellow-button'
          onClick={refetchSeekerExportCSV}
          disabled={isFetchingDownloadCsv}
        >
          {isFetchingDownloadCsv ? 'Loading csv...' : 'Download CSV'}
        </Button>
        <CSV
          ref={refCSVLink}
          filename='data-seeker.csv'
          data={dataSeekerExportCSV}
        />
      </div>
    </div>
  );
};

JobSeekers.propTypes = {
  statuses: PropTypes.array,
};

JobSeekers.defaultProps = [];

export default JobSeekers;
