import normalize from 'json-api-normalizer';

import request from '@/request';

const getViewSend = async ({
  size,
  number,
  weekNum,
  searchTerm,
  employerId,
  orderDirection,
}) => {
  const response = await request.get('trackers/invoices/export', {
    params: {
      page: {
        size,
        number,
      },
      order: {
        orderBy: 'invoice_id',
        orderDirection,
      },
      filter: {
        invoiceWeek: weekNum,
        searchTerm,
        employerId,
      },
    },
  });

  const data = normalize(response.data);
  const invoices = Object.values(data?.invoiceCollection ?? []).map(
    ({
      id,
      attributes: {
        invoiceWeek,
        invoiceYear,
        printed,
        exported,
        providerName,
        paymentWeekDate,
        lastEditedBy,
        clientId,
      },
    }) => ({
      id,
      year: invoiceYear,
      key: id,
      clientId,
      lastEditedBy,
      provider: providerName,
      paymentWeek: `Week ${invoiceWeek}`,
      paymentWeekDates: paymentWeekDate,
    }),
  );

  return {
    invoices,
    currentPage: response.data?.meta?.pagy?.page,
    totalPage: response.data?.meta?.pagy?.pages,
    totalCount: response.data?.meta?.pagy?.count,
  };
};

export default getViewSend;
