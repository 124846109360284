/* eslint-disable no-underscore-dangle */
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';

import request from '@/request';
import { time } from '@/utils';

const getDayOfWeek = (date) => moment.utc(date, 'YYYY-MM-DD').format('ddd');
const parseDay = {
  mon: 'mon',
  tue: 'tues',
  wed: 'wed',
  thu: 'thurs',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun',
};

const formatTimeCard = ({ timesheet, jobType, isCurrentWeekTimesheet }) => {
  const { status } = timesheet;
  const formatedTimeCard = timesheet.date_range_timecard.reduce(
    (prev, curr) => {
      const timecardItems = timesheet.timecards.data.find(
        (timecard) =>
          getDayOfWeek(timecard.attributes.schedule.checkin) ===
          getDayOfWeek(curr),
      );
      const dayInWeek = getDayOfWeek(curr).toLowerCase();
      const value = timecardItems?.attributes?.schedule?.value ?? 0;
      const cellValue = time.convertTimeHoursCell(value);
      const isPushed = !isCurrentWeekTimesheet ? 'pushed ' : '';
      const dayLabel = parseDay[dayInWeek];
      const key = `${isPushed}${dayLabel} ${jobType} ${status}`;
      const addedHourValue = timecardItems?.attributes?.no_show
        ? 0
        : Number(value);

      return {
        ...prev,
        total: prev.total + addedHourValue,
        timecardFormated: {
          ...prev.timecardFormated,
          ...(timecardItems && {
            [key]: [
              {
                ...timecardItems?.attributes?.schedule,
                id: timecardItems?.id,
                checkin: moment(timecardItems?.attributes?.schedule?.checkin),
                checkout: moment(timecardItems?.attributes?.schedule.checkout),
                noShow: timecardItems?.attributes?.no_show,
                comment: timecardItems?.attributes?.comment,
              },
            ],
          }),
          [`${key} total`]: timecardItems ? cellValue : 0,
          [`${key} sickness`]: !addedHourValue,
        },
      };
    },
    { total: 0 },
  );
  return formatedTimeCard;
};

const filterJobs = (timesheetDetails) =>
  timesheetDetails.data.reduce((totalDetails, currDetail) => {
    const isPushedRecord = currDetail.attributes.push_from_timesheet_id;
    const isExistPush = !isEmpty(totalDetails.pushedRecords);
    const isExistJobs = !isEmpty(totalDetails.jobs);

    if (isPushedRecord) {
      if (isExistPush) {
        return {
          ...totalDetails,
          pushedRecords: [...totalDetails.pushedRecords, currDetail.attributes],
          pushedToTimesheetId: timesheetDetails.timesheet_id,
        };
      }
      return {
        ...totalDetails,
        pushedRecords: [currDetail.attributes],
        pushedToTimesheetId: timesheetDetails.timesheet_id,
      };
    }

    if (isExistJobs) {
      return {
        ...totalDetails,
        jobs: [...totalDetails.jobs, currDetail.attributes],
      };
    }

    return {
      ...totalDetails,
      jobs: [currDetail.attributes],
    };
  }, {});

const formatJobs = (obj) =>
  obj.reduce((prevValue, currTimesheet) => {
    const {
      status,
      timecards,
      omni_employee: employee,
      skill,
      push_from_timesheet_id: pushedFromTimesheetId,
    } = currTimesheet;
    const isCurrentWeekTimesheet = isNil(pushedFromTimesheetId);

    if (isEmpty(timecards.data[0])) {
      return prevValue;
    }

    const { payroll } = timecards.data[0].attributes;

    const { total, timecardFormated } = formatTimeCard({
      timesheet: currTimesheet,
      jobType: skill.name,
      isCurrentWeekTimesheet,
    });

    const updateTimesheet = {
      ...currTimesheet,
      ...timecardFormated,
      job_type: skill.name,
      name: employee.name,
      payroll,
      total,
    };

    prevValue[status] = prevValue[status] || {};
    prevValue[status][skill.name] = prevValue[status][skill.name] || [];
    prevValue[status][skill.name].push(updateTimesheet);

    return prevValue;
  }, {});

const parseJobsFormat = (data) => {
  const {
    employer,
    employer_location: location,
    payroll_status: payrollStatus,
  } = data.attributes;

  const timesheetDetails = filterJobs(data.attributes.timesheet_details);
  const pushedTimesheetDetails = filterJobs(
    data.attributes.timesheet_details_pushed,
  );
  const currJobs = formatJobs(timesheetDetails.jobs ?? []);
  const currPushedRecords = formatJobs(
    pushedTimesheetDetails.pushedRecords ?? [],
  );
  currPushedRecords.pushedToTimesheetId =
    pushedTimesheetDetails.pushedToTimesheetId;
  const pushedRecords = formatJobs(timesheetDetails.pushedRecords ?? []);

  return {
    ...data.attributes,
    employer,
    location,
    payrollStatus,
    jobs: currJobs,
    pushedRecords,
    currPushedRecords,
  };
};

const timesheetPdf = async (/** @type {string|number} */ id) => {
  const res = await request.get(`trackers/timekeeping/timesheets/${id}`);
  const { data } = res.data ?? {};
  return parseJobsFormat(data);
};

export default timesheetPdf;
