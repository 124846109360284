import React from 'react';

import Medical from './medical';
import Reference from './reference';
import './styles.scss';

const MedicalInformation = () => (
  <div className='ref-medical'>
    <Reference />
    <Medical />
  </div>
);
export default MedicalInformation;
