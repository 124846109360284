import React from 'react';

import { Image as AntdImage } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import defaultAvatar from '@/assets/images/defaultAvatar.png';

import './styles.scss';

/**
 * @typedef {{
 * src: string;
 * className: string;
 * alt: string;
 * containerClassName: string;
 * }} AdditionalProps
 */

/**
 *
 * @param {import ('antd').ImageProps & AdditionalProps} props
 * @returns
 */
const Image = ({ src, className, alt, containerClassName, ...props }) => (
  <div className={classNames('img-container', containerClassName)}>
    <AntdImage
      {...props}
      className={className}
      src={src ?? defaultAvatar}
      alt={alt}
      fallback={defaultAvatar}
    />
  </div>
);

Image.defaultProps = {
  src: '',
  className: '',
  containerClassName: '',
  alt: '',
};

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;
