import { fetchPresignedUrl } from '@/services/presignedUrlService';
import { uploadS3 } from '@/services/uploadS3Service';

const uploadFileS3 = async (fileName, file, setUploadedProgress) => {
  const presignedLink = await fetchPresignedUrl({
    filename: fileName,
    filetype: file.type,
  });

  const { post_url: postUrl, get_url: getUrl } = presignedLink;

  await uploadS3({ url: postUrl, file, setUploadedProgress });

  return { fileName, postUrl, getUrl };
};

export default uploadFileS3;
