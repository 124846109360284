import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

/**
 *
 * @param {{
 *   providerId: string;
 *   updatedTemplate: Object
 * }} params
 * @returns
 */
export const updateJobTemplates = async ({
  providerId,
  updatedTemplate = {},
}) => {
  const { id, jobTemplatesUniformsAttributes } = updatedTemplate;
  const uniformIds = jobTemplatesUniformsAttributes.map(
    (item) => item.uniformId,
  );
  const data = { job_template: { ...updatedTemplate, uniformIds } };
  const res = await request.put(
    `providers/employers/${providerId}/job_templates/${id}`,
    data,
  );
  return normalizeShowResponse(res);
};

/**
 *
 * @param {{
 *   providerId: string;
 *   updatedTemplate: Object
 * }} params
 * @returns
 */
export const createJobTemplate = async ({
  providerId,
  updatedTemplate = {},
}) => {
  const { jobTemplatesUniformsAttributes } = updatedTemplate;
  const uniformIds = jobTemplatesUniformsAttributes.map(
    (item) => item.uniformId,
  );
  const data = { job_template: { ...updatedTemplate, uniformIds } };
  const res = await request.post(
    `providers/employers/${providerId}/job_templates`,
    data,
  );
  return normalizeShowResponse(res)?.data;
};
