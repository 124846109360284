import React from 'react';

import classNames from 'classnames';
import { compact } from 'lodash';

import { Button } from '@/components';

const GroupButtons = ({ buttonProperties = [] }) =>
  compact(
    buttonProperties.map(
      ({
        label,
        hidden = false,
        className: classNameButton,
        ...properties
      }) => (
        <Button
          className={classNames('action-btn', classNameButton, {
            hidden,
            'min-width': true,
          })}
          {...properties}
        >
          {label}
        </Button>
      ),
    ),
  );

export default GroupButtons;
