import request from '@/request';

import { getAddressInfomation } from '../getAddressInfomation';

const update = async ({
  id,
  company,
  email,
  fullName,
  phoneNumber,
  status,
  tellUs,
  sitePostCode,
  localOffice,
  city,
  note,
  allocatedAccountManager,
}) => {
  const geogecode = await getAddressInfomation(`${sitePostCode}-uk`);

  const { geometry } = geogecode[0];

  request.put(`/providers/leads/${id}`, {
    lead: {
      city,
      email,
      status: Number(status),
      company,
      name: fullName,
      tellUs,
      phone: phoneNumber,
      internalNote: note,
      postCode: sitePostCode,
      localOffice: Number(localOffice),
      managerId: Number(allocatedAccountManager),
      locationAttributes: {
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
      },
    },
  });
};

export default update;
