import React, { useState } from 'react';

import { Form } from 'antd';
import classNames from 'classnames';

import { Button } from '@/components';

import ExperienceDetail from './ExperienceDetail';
import ExperienceDetailShort from './ExperienceDetailShort';
import './styles.scss';

const INIT_VALUES = {
  country: '',
  isVerifyExperience: false,
  leave: '',
  name: '',
  pos: '',
  town: '',
  still_employed: null,
  start_at: null,
  finish_at: null,
};

// TODO: need validate fields when change to short detail.
const Experiences = () => {
  const form = Form.useFormInstance();

  const [toggleViewFull, setToggleViewFull] = useState(false);

  const listEmployment =
    Form.useWatch('vacancyApplicantEmploymentsAttributes', form) || [];

  const handleDelete = (key) => {
    form.setFields([
      {
        name: ['vacancyApplicantEmploymentsAttributes', key, '_destroy'],
        value: true,
      },
    ]);
  };

  const toggleViewFullExperience = () => setToggleViewFull((prev) => !prev);

  return (
    <div className='seeker-experiences'>
      <div
        className={classNames({
          'employment-history-scroll': listEmployment.length > 1,
        })}
      >
        <Form.List
          name='vacancyApplicantEmploymentsAttributes'
          initialValue={[INIT_VALUES]}
        >
          {(fields, { add, remove }) => (
            <div>
              <p className='employment-history-label'>Experience</p>
              {fields.map((field, index) => {
                // eslint-disable-next-line no-underscore-dangle
                if (listEmployment[index]?._destroy) return null;
                return toggleViewFull ? (
                  <ExperienceDetail
                    key={field?.key}
                    fieldName={field?.name}
                    length={listEmployment.length}
                    index={index}
                    onDelete={() => handleDelete(index)}
                    isStillEmployed={
                      listEmployment && listEmployment[index]?.stillEmployed
                    }
                  />
                ) : (
                  <ExperienceDetailShort
                    key={field?.key}
                    fieldName={field?.name}
                    listEmployment={listEmployment}
                    index={index}
                    isStillEmployed={
                      listEmployment && listEmployment[index]?.stillEmployed
                    }
                  />
                );
              })}

              {toggleViewFull && (
                <div className='add-btn-container'>
                  <Button
                    className='btn-add yellow-button'
                    onClick={() => add(INIT_VALUES)}
                  >
                    Add New Experience
                  </Button>
                </div>
              )}
            </div>
          )}
        </Form.List>
        <Button
          className='yellow-button view-full-experience'
          onClick={toggleViewFullExperience}
        >
          {toggleViewFull ? 'View Less' : 'View Full Experience'}
        </Button>
      </div>
    </div>
  );
};

export default Experiences;
