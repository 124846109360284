import moment from 'moment';

import request from '@/request';

const getHolidays = async () => {
  const { data: holidays } = await (await request.get('/holidays')).data;
  return convertHolidaysObject(holidays);
};

const convertHolidaysObject = (holidays) => {
  const result = {};

  holidays?.forEach(({ id, name, type, endDate, startDate, date, title }) => {
    if (date) {
      const template = {
        id,
        type,
        title,
        name,
        date,
      };

      const day = moment(date, 'DD/MM/YYYY').date();
      result[day] = [...(result[day] ?? []), template];
    } else {
      const template = {
        id,
        type,
        name,
        startDate,
        endDate,
      };

      const startDay = moment(startDate, 'DD/MM/YYYY').date();
      const endDay = moment(endDate, 'DD/MM/YYYY').date();

      for (let day = startDay; day <= endDay; day += 1) {
        result[day] = [...(result[day] ?? []), template];
      }
    }
  });

  return result;
};

export default getHolidays;
