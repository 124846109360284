/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Form, message } from 'antd';
import PropTypes from 'prop-types';

import { Button, Select, FormItem } from '@/components';
import { languages, levelLanguage } from '@/constants';
import { rulesSelect } from '@/utils/rulesInput';

import './styles.scss';

const LanguageRecruitment = ({
  nextStep,
  onValuesChange,
  initialValues,
  form,
}) => {
  const vacancyApplicantLanguagesAttributes =
    Form.useWatch('vacancyApplicantLanguagesAttributes', form) ?? [];

  const nameLanguageAttributesSelected =
    vacancyApplicantLanguagesAttributes.map((e) => e.name);

  const checkDataNull = () => {
    const isValidate = vacancyApplicantLanguagesAttributes.every(
      (element) => element.name !== null && element.level !== null,
    );

    if (isValidate) {
      nextStep();
    } else {
      message.error('Please fill in the blank field');
    }
  };

  return (
    <div className='flex-one-column language-recruitment'>
      <div className='title' style={{ marginBottom: '40px' }}>
        Languages
      </div>
      <p className='text-bold'>
        Due to operational health and safety reasons, many of our clients
        require candidates to have the necessary basic level of understanding in
        both written and spoken English. Please, therefore, confirm your level
        of competency with both written and spoken English. Please also confirm
        any other languages which you have an understanding in and confirm the
        level of competency you have with this language
      </p>
      <Form
        onFinish={checkDataNull}
        form={form}
        onValuesChange={(_changedValues, allValues) =>
          onValuesChange(_changedValues, allValues, true)
        }
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='detail-form-container'
      >
        <FormItem
          noStyle
          name={['vacancyApplicantLanguageMainAttributes', 'id']}
          initialValue='English'
        />
        <FormItem
          noStyle
          name={['vacancyApplicantLanguageMainAttributes', 'name']}
          initialValue='English'
        />
        <FormItem
          label='English'
          className='input-container'
          name={['vacancyApplicantLanguageMainAttributes', 'level']}
          rules={rulesSelect('language Level')}
        >
          <Select
            options={levelLanguage}
            placeholder='English(UK) - level of Competence'
            required
          />
        </FormItem>
        <Form.List name='vacancyApplicantLanguagesAttributes'>
          {(fields, { add, remove }) => {
            const filtedLanguages = languages.filter(
              (language) =>
                !nameLanguageAttributesSelected.includes(language.value) &&
                language.value !== 'English',
            );

            return (
              <div className='additional-language-container'>
                <h2 className='addition-language-title'>
                  Additional Languages
                </h2>
                {fields.map((_field, index) => (
                  <div className='additional-language'>
                    <FormItem name={[index, 'id']} noStyle />
                    <FormItem name={[index, '_destroy']} noStyle />
                    <FormItem className='select-style' name={[index, 'name']}>
                      <Select
                        options={filtedLanguages}
                        placeholder='Language'
                      />
                    </FormItem>
                    <FormItem className='select-style' name={[index, 'level']}>
                      <Select
                        options={levelLanguage}
                        placeholder='Level of competence'
                      />
                    </FormItem>
                    <div className='remove-btn-container'>
                      <Button
                        onClick={() => remove([index])}
                        className='shared-button remove-btn'
                      >
                        Remove Language
                      </Button>
                    </div>
                  </div>
                ))}
                <Button
                  className='black-button'
                  onClick={() =>
                    add({ id: null, name: null, level: null, _destroy: false })
                  }
                >
                  Add Language
                </Button>
              </div>
            );
          }}
        </Form.List>
        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

LanguageRecruitment.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default LanguageRecruitment;
