/* eslint-disable no-nested-ternary */
import React from 'react';

import { Col, Form, Row, Tag } from 'antd';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { Button, FormItem, Select } from '@/components';
import { useProviderLocations } from '@/hooks/dropdowns';
import { rulesSelect } from '@/utils/rulesInput';
import { useCertificates } from '@/hooks/useCertificates';

import SeekerItemRow from './component/SeekerSelected/index';
import Shifts from './component/Shifts';
import { JOB_RESPONSE_STATUS, checkErrorShift } from './constant';

const ReviewAndPost = ({
  listProviders,
  listSkill,
  disabled,
  status,
  getTemplate,
  isLoading,
}) => {
  const formInstance = Form.useFormInstance();
  const providerId = Form.useWatch('employerId', formInstance);
  const payrollTemplate = Form.useWatch('payrollTemplate', formInstance);
  const { data: locations } = useProviderLocations(providerId);
  const { data: certificates } = useCertificates();

  const schedule = Form.useWatch(['schedule', 'items'], formInstance) ?? [];

  const listPreRequisit = Form.useWatch('preRequisits', formInstance) ?? [];

  const licenses = (Form.useWatch('licenses', formInstance) ?? []).filter(
    (e) => e !== 'all',
  );
  const shiftType = Form.useWatch('shiftType', formInstance);

  const sortedSchedule = sortBy(schedule, 'startAt');

  const addSeekers =
    Form.useWatch('jobResponsesAttributes', formInstance)?.filter(
      (seeker) =>
        seeker?.status === undefined ||
        seeker.status === JOB_RESPONSE_STATUS.ACCEPTED,
    ) ?? [];
  const nudgeSeekers = Form.useWatch('nudgesAttributes', formInstance) ?? [];
  const skillId = Form.useWatch('skillId', formInstance);
  const locationId = Form.useWatch('locationId', formInstance);

  const checkboxRepeat = Form.useWatch('checkboxRepeat', formInstance) ?? 0;

  const onRemoveAddSeeker = (item) => {
    formInstance.setFieldsValue({
      jobResponsesAttributes: addSeekers.filter((e) => e.id !== item.id),
    });
  };

  const onRemoveNudgeSeeker = (item) => {
    formInstance.setFieldsValue({
      nudgesAttributes: nudgeSeekers.filter((e) => e.id !== item.id),
    });
  };

  const handleUpdateTemplate = ({ skillIdValue, locationIdValue }) => {
    const updateSkillId = skillIdValue ?? skillId;
    const updateLocationId = locationIdValue ?? locationId;
    if (updateSkillId && providerId && updateLocationId && !payrollTemplate) {
      getTemplate({ skillIdValue, locationIdValue });
    }
  };

  const onRemoveLicencesType = (licence) => {
    formInstance.setFieldsValue({
      licenses: licenses.filter((l) => l !== licence.slug),
    });
  };

  const onRemovePreRequisit = (index, data) => {
    const dataUpdate = [...data];

    dataUpdate.splice(index, 1);

    formInstance.setFieldsValue({ preRequisits: dataUpdate });
  };

  const onChangeShifts = (items) => {
    formInstance.setFieldsValue({
      schedule: {
        items,
      },
    });

    const seekersPayTemplates =
      formInstance.getFieldValue('seekersPayTemplates') ?? [];
    const errors = checkErrorShift(items, seekersPayTemplates);

    formInstance.setFields([
      {
        name: 'items',
        errors,
      },
    ]);
  };

  const onChangeEmployerId = () => {
    formInstance.setFieldsValue({ locationId: undefined });
  };

  return (
    <div className='review-and-post'>
      <Row className='full-width review-and-post-row' gutter={25}>
        <Col span={8}>
          <FormItem
            required
            name='employerId'
            label='Provider'
            rules={!disabled && rulesSelect('Provider')}
          >
            <Select
              placeholder='Provider'
              options={listProviders}
              disabled={disabled}
              onChange={onChangeEmployerId}
              required
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            required
            name='skillId'
            label='Position Needed'
            rules={!disabled && rulesSelect('Position Needed')}
          >
            <Select
              placeholder='Position Needed'
              options={listSkill}
              disabled={disabled}
              required
              onChange={(value) =>
                handleUpdateTemplate({ skillIdValue: value })
              }
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            required
            name='locationId'
            label='Choose Location'
            rules={!disabled && rulesSelect('Location')}
          >
            <Select
              options={locations}
              placeholder='Choose Location'
              disabled={disabled}
              required
              onChange={(value) =>
                handleUpdateTemplate({ locationIdValue: value })
              }
            />
          </FormItem>
        </Col>
      </Row>

      <div className='title-job-row'>Shift</div>
      <Row className='full-width review-and-post-row'>
        <Shifts
          items={sortedSchedule}
          onChange={onChangeShifts}
          disablePosted={shiftType === 'group_shift'}
          disabled={disabled || checkboxRepeat !== 0}
        />
      </Row>

      {(listPreRequisit.length > 0 || licenses.length > 0) && (
        <div className='full-width'>
          <div className='title-job-row'>Pre-Requisit</div>
          <Row className='full-width review-and-post-row'>
            <Col span={12}>
              <div className='pre-requisit-list'>
                {listPreRequisit.map((preRequisit, index) => {
                  const provider = listProviders.find(
                    (e) => e.value === preRequisit.employerId,
                  );
                  const position = listSkill.find(
                    (e) => e.value === preRequisit.skillId,
                  );

                  return (
                    <Tag
                      key={`${preRequisit.employerId}-${preRequisit.skillId}`}
                      closable
                      closeIcon={<XIconBlack />}
                      className='option-selected-tag pre-requisit-selected-tag'
                      onClose={() =>
                        disabled
                          ? null
                          : onRemovePreRequisit(index, listPreRequisit)
                      }
                    >
                      <div className='pre-requisit-item'>
                        <span className='pre-requisit-item-provider'>
                          {provider?.label ?? ''}
                        </span>
                        <span>{position?.label ?? ''}</span>
                      </div>
                    </Tag>
                  );
                })}
              </div>
            </Col>

            <Col span={12}>
              {licenses.map((type) => {
                const licence = certificates[type];

                return (
                  <div className='lincence-tags' key={licence.id}>
                    <Tag
                      closable
                      closeIcon={<XIconBlack />}
                      key={licence?.slug}
                      className='option-selected-tag'
                      onClose={() =>
                        disabled ? null : onRemoveLicencesType(licence)
                      }
                    >
                      <span className='option-selected-text'>
                        {licence?.name}
                      </span>
                    </Tag>
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
      )}
      {(addSeekers.length > 0 || nudgeSeekers.length > 0) && (
        <Row className='full-width review-and-post-row'>
          <Col span={10}>
            <div className='title-job-row'>Add Seeker</div>
            <div className='list-seeker-selected seekers-align-start'>
              {addSeekers.map((seeker) => (
                <SeekerItemRow
                  seeker={seeker}
                  onClose={disabled ? null : onRemoveAddSeeker}
                  disabled={disabled}
                />
              ))}
            </div>
          </Col>
          <Col span={10}>
            <div className='title-job-row'>Nudge Seeker</div>
            <div className='list-seeker-selected seekers-align-start'>
              {nudgeSeekers.map((seeker) => (
                <SeekerItemRow
                  seeker={seeker}
                  onClose={disabled ? null : onRemoveNudgeSeeker}
                  disabled={disabled}
                />
              ))}
            </div>
          </Col>
        </Row>
      )}

      <Button
        className='yellow-button'
        loading={isLoading}
        onClick={() => formInstance.submit()}
      >
        {status === 'create'
          ? 'Post'
          : status === 'edit' && disabled
            ? 'Repost'
            : 'Edit'}
      </Button>
    </div>
  );
};

ReviewAndPost.propTypes = {
  listProviders: PropTypes.array,
  listSkill: PropTypes.array,
  disabled: PropTypes.bool,
  status: PropTypes.string,
  getTemplate: PropTypes.func,
  isLoading: PropTypes.bool,
};

ReviewAndPost.defaultProps = {
  listProviders: [],
  listSkill: [],
  disabled: false,
  status: '',
  getTemplate: () => {},
  isLoading: false,
};

export default ReviewAndPost;
