import React from 'react';

import PropTypes from 'prop-types';

import csvDownloadIcon from '@/assets/icons/csvDownloadIcon.svg';
import pdfDownloadIcon from '@/assets/icons/pdfDownloadIcon.svg';
import { Button } from '@/components';

import './styles.scss';

/**
 * A component that manages worker download features.
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.togglePreviewJobPdf - function to toggle the preview of the job PDF.
 * @param {Function} props.downloadCSVJobs - function to download jobs as a CSV file.
 * @returns {JSX.Element} The rendered component.
 */
const ManageWorkerDownloadFeature = ({
  togglePreviewJobPdf,
  downloadCSVJobs,
}) => (
  <div className='manage-worker-download-feature'>
    <Button className='input-height download-btn' onClick={togglePreviewJobPdf}>
      <img src={pdfDownloadIcon} alt='seekers-pdf' />
    </Button>
    <Button onClick={downloadCSVJobs} className='input-height download-btn'>
      <img src={csvDownloadIcon} alt='seekers-csv' />
    </Button>
    <span>Download list</span>
  </div>
);

ManageWorkerDownloadFeature.propTypes = {
  togglePreviewJobPdf: PropTypes.func,
  downloadCSVJobs: PropTypes.func,
};

ManageWorkerDownloadFeature.defaultProps = {
  togglePreviewJobPdf: () => {},
  downloadCSVJobs: () => {},
};

export default ManageWorkerDownloadFeature;
