import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, Select, Input, FormItem, DatePicker } from '@/components';
import { REFERENCE_BY_FRIEND, listGenders, listHears } from '@/constants';
import { useLocations } from '@/hooks/dropdowns';
import { date } from '@/utils';
import {
  rulesBirth,
  rulesPostcode,
  rulesSelect,
  rulesSelectHearAboutUs,
  rulesTextInput,
} from '@/utils/rulesInput';

import './styles.scss';

const AboutYou = ({
  nextStep,
  form,
  onValuesChange,
  initialValues,
  handleChangeYear,
}) => {
  const { listLocations } = useLocations();
  const hearWatching = Form.useWatch('hear', form);
  const isHiddenFriend = hearWatching !== REFERENCE_BY_FRIEND.value;

  const handleResetFieldFriend = () => {
    if (isHiddenFriend) {
      form.setFields([
        {
          name: 'friendName',
          value: null,
        },
      ]);
    }
  };

  return (
    <div className='flex-one-column'>
      <div className='title'>About You</div>

      <Form
        onFinish={() => nextStep()}
        form={form}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='detail-form-container'
      >
        <FormItem
          label='Address - Building Name/Flat Number'
          className='input-container'
          name='address'
          rules={rulesTextInput('Building number')}
          required
        >
          <Input placeholder='Address - Building Name/Flat Number' />
        </FormItem>

        <FormItem
          className='input-container'
          name='address2'
          label='Street Name'
          required
          rules={rulesTextInput('Street Name')}
        >
          <Input placeholder='Street' />
        </FormItem>

        <FormItem
          className='input-container'
          name='city'
          rules={rulesTextInput('City')}
          label='City'
          required
        >
          <Input placeholder='City' />
        </FormItem>

        <FormItem
          label='Post Code'
          className='input-container'
          name='zip'
          rules={rulesPostcode(form, 'zip')}
          required
        >
          <Input placeholder='Post Code' />
        </FormItem>

        <FormItem
          className='input-container'
          name='birth'
          normalize={date.convertDateTime}
          rules={rulesBirth}
          label='Date of Birth'
          required
        >
          <DatePicker
            name='birth'
            handleChangeYear={handleChangeYear}
            className='inputDate experience_description'
            placeholder='Date of Birth'
          />
        </FormItem>

        <FormItem
          className='input-container'
          name={['omniEmployeeAttributes', 'employeeGender']}
          required
          label='Gender at Birth (or on Documentation)'
          rules={rulesSelect('Gender')}
        >
          <Select placeholder='Gender' options={listGenders} required />
        </FormItem>

        <FormItem
          className='input-container'
          name='hear'
          rules={rulesSelectHearAboutUs()}
          label='How did you hear about us?'
          required
        >
          <Select
            options={listHears}
            placeholder='How did you hear about us?'
            required
            onChange={handleResetFieldFriend}
          />
        </FormItem>

        <FormItem
          label='Friends Name'
          className='input-container'
          name='friendName'
          hidden={isHiddenFriend}
        >
          <Input placeholder='Friends Name' />
        </FormItem>

        <FormItem
          className='input-container'
          name='officeId'
          rules={[
            {
              required: true,
              message: 'Please choose your GIG Office',
            },
          ]}
          label='What is your nearest location'
          required
        >
          <Select
            options={listLocations}
            placeholder='What is your nearest location'
            required
          />
        </FormItem>

        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

AboutYou.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
  handleChangeYear: PropTypes.func,
};

export default AboutYou;
