import React, { useState } from 'react';

import { Form, Checkbox } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Button,
  Select,
  Input,
  FormItem,
  DatePicker,
  Textarea,
} from '@/components';
import { useVacancyApplicantEmploymentPosition } from '@/hooks/dropdowns';
import { date } from '@/utils';
import { rulesSelect, rulesTextInput } from '@/utils/rulesInput';

import './styles.scss';

const Experience = ({ nextStep, onValuesChange, initialValues, form }) => {
  const listRoles = useVacancyApplicantEmploymentPosition();
  const [isVerifyExperience, setIsVerifyExperience] = useState(false);
  const initialValue = {
    name: '',
    pos: '',
    town: '',
    country: '',
    positionId: undefined,
  };

  const handleChangeYear = (name, index, value) => {
    const vacancyApplicantEmployments =
      form.getFieldValue(['vacancyApplicantEmployments']) ?? [];
    const arr = [];

    vacancyApplicantEmployments[index][name] = value;

    arr[index] = { [name]: value };

    onValuesChange(
      { vacancyApplicantEmployments: arr },
      { vacancyApplicantEmployments },
    );
  };

  return (
    <div className='experience'>
      <div className='flex-one-column'>
        <div className='title' style={{ marginBottom: '20px' }}>
          Your Previous Experience
        </div>
        <p>Please provide us with details of your employment history.</p>
        <p>
          Please select the role/job title from the drop-down menu that best
          identifies with the previous employment role/job title you held.
        </p>
        <p>
          Please complete the responsibilities field with a short sentence that
          best summarising the duties you undertook on a day-to-day basis when
          performing this role/job title
        </p>
        <p>
          If none of the roles/job titles within the drop-down menu match the
          previous employment role/job title you held then please select other
          and manually enter the role/job title in the text field provided
        </p>
      </div>
      <Form
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        form={form}
      >
        <Form.List
          initialValue={[initialValue]}
          name='vacancyApplicantEmployments'
        >
          {(fields, { add, remove }) => (
            <>
              <div className='roles-section'>
                {fields.map((field, index) => (
                  <div className='role-items-container'>
                    <FormItem
                      label='Company'
                      className='input-container'
                      name={[index, 'name']}
                      rules={!isVerifyExperience && rulesTextInput('company')}
                    >
                      <Input placeholder='Company' />
                    </FormItem>
                    <FormItem noStyle name={[index, 'pos']} initialValue='' />
                    <FormItem noStyle name={[index, 'town']} initialValue='' />
                    <FormItem
                      noStyle
                      name={[index, 'country']}
                      initialValue=''
                    />
                    <FormItem
                      label='Job Title'
                      className='input-container'
                      name={[index, 'positionId']}
                      rules={!isVerifyExperience && rulesSelect('role')}
                    >
                      <Select
                        options={listRoles}
                        placeholder='Roles'
                        noSelectText='Please Select'
                      />
                    </FormItem>
                    <div className='date-container'>
                      <FormItem
                        label='Start date'
                        className='input-container date-width'
                        name={[index, 'startAt']}
                        normalize={date.convertDateTime}
                      >
                        <DatePicker
                          name={[index, 'startAt']}
                          className='inputDate'
                          placeholder='Start date'
                          suffix={null}
                          handleChangeYear={(v) =>
                            handleChangeYear('startAt', index, v)
                          }
                          disabledDate={(currentDate) =>
                            currentDate.isSameOrAfter(moment(), 'day')
                          }
                        />
                      </FormItem>
                      <FormItem
                        label='End date'
                        className='input-container date-width'
                        name={[index, 'finishAt']}
                        normalize={date.convertDateTime}
                      >
                        <DatePicker
                          name={[index, 'finishAt']}
                          className='inputDate'
                          placeholder='End date'
                          suffix={null}
                          handleChangeYear={(v) =>
                            handleChangeYear('finishAt', index, v)
                          }
                          disabledDate={(currentDate) =>
                            currentDate.isSameOrAfter(moment(), 'day')
                          }
                        />
                      </FormItem>
                    </div>
                    <FormItem
                      label='Responsibilities'
                      className='input-container'
                      name={[index, 'leave']}
                      rules={
                        !isVerifyExperience &&
                        rulesTextInput('Responsibilities')
                      }
                    >
                      <Textarea
                        maxLength={255}
                        placeholder='Responsibilities'
                      />
                    </FormItem>
                    {index > 0 && (
                      <div className='remove-btn-container'>
                        <Button
                          onClick={() => remove(field.name)}
                          className='shared-button delete-button'
                        >
                          Remove Position
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <Button
                className='black-button add-more-experience'
                onClick={() => add(initialValue)}
              >
                Add More Experience
              </Button>
            </>
          )}
        </Form.List>

        <FormItem
          name='isVerifyExperience'
          className='form-item-recruitment-checkbox'
        >
          <Checkbox
            className='recruitment-checkbox'
            onChange={() => setIsVerifyExperience(!isVerifyExperience)}
          >
            This is all my past experience or this would be my first job.
          </Checkbox>
        </FormItem>

        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

Experience.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default Experience;
