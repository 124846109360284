import React, { memo } from 'react';

import { Modal as AntdModal } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from '@/assets/icons/warningIcon.svg';
import Button from '@/components/Button';

import './styles.scss';

/**
 *
 * @param {{
 * title: string;
 * children: string | React.ReactNode;
 * onClickNo: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
 * visible: boolean;
 * onClickYes: Function;
 * isSuccess?: boolean;
 * isLoading?: boolean;
 * isShowFooter?: boolean;
 * footer?: React.ReactNode;
 * isWarning?: boolean;
 * confirmLoading?: boolean;
 * }} props
 * @returns
 */
const Modal = ({
  title,
  children,
  onClickNo,
  onClickYes,
  isSuccess,
  isLoading,
  isShowFooter,
  footer,
  isWarning,
  confirmLoading,
  ...props
}) => (
  <AntdModal
    centered
    onCancel={onClickNo}
    wrapClassName='modal-container'
    footer={
      footer ||
      (isShowFooter && [
        <Button
          onClick={onClickYes}
          key='yes'
          className='yellow-button style-btn'
          loading={isLoading || confirmLoading}
        >
          Yes
        </Button>,
        !isSuccess ? (
          <Button
            onClick={onClickNo}
            key='no'
            className='black-button style-btn'
            disabled={confirmLoading || isLoading}
          >
            No
          </Button>
        ) : null,
      ])
    }
    className='status-modal-container'
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {isWarning && <WarningIcon className='warning-icon' />}
    <h1 className='text-title'>{title}</h1>
    <p className='text-content'>{children}</p>
  </AntdModal>
);

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  isShowFooter: PropTypes.bool,
  isWarning: PropTypes.bool,
  confirmLoading: PropTypes.bool,
  footer: PropTypes.element,
};

Modal.defaultProps = {
  title: '',
  children: '',
  onClickNo: () => {},
  onClickYes: () => {},
  isSuccess: false,
  isLoading: false,
  isShowFooter: true,
  isWarning: true,
  confirmLoading: false,
};

export default memo(Modal);
