import React, { useEffect, useState } from 'react';

import { Form, message, Modal, Tooltip } from 'antd';
import { camelCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  Button,
  ColorCheckbox,
  ModalConfirm,
  Pagination,
  PDF,
} from '@/components';
import { ORDER_DIRECTION_ASC, PAGE_SIZE_OPTIONS } from '@/constants';
import { useFetch, usePagination, useSessionStorage } from '@/hooks';
import useWindowSize from '@/hooks/useWindow';
import { InvoicePayTemplateService, InvoiceService } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import openFilePDFNewTab from '@/utils/openFilePDFNewTab';

import ListInvoices from '../components/InvoicesTable';
import { INVOICE_TYPES } from '../constants';
import FormFields from '../formFields';

import './styles.scss';

const TIMESHEET_PATHS = {
  flexiblePayRate: 'timesheet-detail',
  payTemplate: 'pay-template-timesheet',
};

const CreateInvoice = ({ activeKey, form }) => {
  const history = useHistory();

  const year = Form.useWatch('year', form);

  const stateWeekNum = Form.useWatch('weekNum', form);

  const [storedValue, setSession, removeSession] = useSessionStorage(
    'invoicingCreateSearch',
  );

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const windowSize = useWindowSize();
  const [invoiceFile, setInvoiceFile] = useState();
  const [createdRecord, setCreatedRecord] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [isShowInvoice, setIsShowInvoice] = useState(false);
  const [orderDirection, setOrderDirection] = useState(ORDER_DIRECTION_ASC);
  const [showModalCreateInvocies, setShowModalCreateInvoices] = useState(false);

  const {
    currentPage: number = 1,
    paginationPageSize: size = 25,
    handlePagination,
    handleShowSizeChange,
  } = usePagination();

  const { mutate: createOneInvoice, isLoading: isCreatingInvoice } =
    useMutation((invoice) => InvoiceService.createInvoice(invoice), {
      onError: (error) => message.error(error.message),
      onSuccess: (data) => {
        message.success('Create Invoices Successfully');
        history.push(`/edit-invoice/${data.invoiceId}`);
      },
    });

  const {
    mutate: createPayTemplateInvoice,
    isLoading: isCreatingInvoicePayTemplate,
  } = useMutation(
    (invoice) => InvoicePayTemplateService.createInvoice(invoice),
    {
      onError: (error) => message.error(error.message),
      onSuccess: (data) => {
        message.success('Create Invoices Successfully');
        history.push(`/payroll-templates-invoice/${data.invoiceId}`);
      },
    },
  );

  const {
    data: timesheet,
    refetch: refetchGetInvoices,
    isFetching,
  } = useFetch(
    [
      'page-create-invoice-get-invoies',
      size,
      number,
      orderDirection,
      activeKey,
      year,
      stateWeekNum,
    ],
    () => {
      const { searchTerm, employerId } = form.getFieldsValue();

      return InvoiceService.getInvoices({
        size,
        number,
        orderDirection,
        weekNum: stateWeekNum,
        searchTerm,
        employerId,
        year,
      });
    },
    {
      onSuccess: () => setSession({ ...form.getFieldsValue() }),
    },
  );

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'providerName',
      align: 'center',
      sorter: (a, b) => a.providerName.localeCompare(b.providerName),
      ellipsis: true,
      render: (providerName) => (
        <Tooltip placement='topLeft' title={providerName}>
          {providerName}
        </Tooltip>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      align: 'center',
      render: (locationName) => (
        <Tooltip placement='topLeft' title={locationName}>
          {locationName}
        </Tooltip>
      ),
    },

    {
      title: 'Client ID',
      dataIndex: 'clientId',
      align: 'center',
    },
    {
      title: 'Year',
      align: 'center',
    },
    {
      title: 'Payment Week',
      align: 'center',
      render: (_, record) => (
        <Tooltip placement='topLeft' title={`Week ${record?.weekNum}`}>
          Week {record?.weekNum}
        </Tooltip>
      ),
    },
    {
      title: 'Payment Week Dates',
      dataIndex: 'paymentWeekDate',
      align: 'center',
      render: (paymentWeekDate) => (
        <Tooltip placement='topLeft' title={paymentWeekDate}>
          {paymentWeekDate}
        </Tooltip>
      ),
    },
    {
      title: 'Last Edited By',
      align: 'center',
      render: (editedBy) => (
        <Tooltip placement='topLeft' title={editedBy}>
          {editedBy}
        </Tooltip>
      ),
    },
    {
      title: 'Last Edited At',
      align: 'center',
      render: (_, record) => (
        <Tooltip
          placement='topLeft'
          title={moment(record?.updatedAt).format('DD/MM/YYYY')}
        >
          {moment(record?.updatedAt).format('DD/MM/YYYY')}
        </Tooltip>
      ),
    },
    {
      title: 'View Timesheet',
      align: 'center',
      render: (_, { id, paymentType }) => {
        const timesheetPath = TIMESHEET_PATHS[camelCase(paymentType)];
        return (
          <Button
            type='link'
            className='link-color'
            onClick={() => history.push(`/${timesheetPath}/${id}`)}
          >
            View Timesheet
          </Button>
        );
      },
    },
    {
      title: 'Preview Invoice',
      align: 'center',
      render: (_, record) =>
        record?.invoiceFile && (
          <Button
            type='link'
            onClick={() => {
              openInvoicePreview(record?.invoiceFile);
            }}
            className='link-color'
          >
            Preview Invoice
          </Button>
        ),
    },
    {
      title: 'Edit Invoice',
      align: 'center',
      render: (_, record) => {
        const { paymentType } = record;
        const invoicePath =
          camelCase(paymentType) === INVOICE_TYPES.flexiblePayRate.key
            ? 'edit-invoice'
            : 'payroll-templates-invoice';
        return (
          record.invoiceId &&
          !record.invoiceFile && (
            <Button
              type='link'
              className='link-color'
              onClick={() =>
                history.push(`/${invoicePath}/${record.invoiceId}`)
              }
            >
              Edit Invoice
            </Button>
          )
        );
      },
    },
    {
      title: 'Create Invoice',
      align: 'center',
      render: (_, record) => {
        const { id, invoiceId } = record;
        return (
          !invoiceId && (
            <div className='check-container'>
              <ColorCheckbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedInvoice(id);
                    setCreatedRecord(record);
                  }
                }}
                checked={id === selectedInvoice}
              />
            </div>
          )
        );
      },
    },
  ];

  const headerTable = generateHeaderTable(columns);

  const openInvoicePreview = (pdfUrl) => {
    setInvoiceFile(pdfUrl);
    setIsShowInvoice(true);
  };

  const handleSort = (order) => {
    setOrderDirection(Formatter.makeAbbrevSortType(order));
  };

  const handleSearchInvoices = () => {
    setSession({ ...form.getFieldsValue() });
    refetchGetInvoices();
  };

  const handleResetSearch = () => {
    handlePagination(1);
    removeSession();
    form.resetFields();
    refetchGetInvoices();
  };

  const handleCreateInvoices = () => {
    const { id, paymentType } = createdRecord;
    const invoiceCreate = { timesheetId: id, filename: null };

    if (camelCase(paymentType) === INVOICE_TYPES.flexiblePayRate.key) {
      createOneInvoice(invoiceCreate);
    } else {
      createPayTemplateInvoice(invoiceCreate);
    }

    setShowModalCreateInvoices(false);
  };

  const handleClickPreviewInvoice = () => {
    openFilePDFNewTab(invoiceFile);
  };

  const isCreating = isCreatingInvoice || isCreatingInvoicePayTemplate;

  return (
    <div className='create-invoice-container'>
      <Form
        form={form}
        onFinish={handleSearchInvoices}
        onReset={handleResetSearch}
        initialValues={{
          year: moment().year(),
          weekNum: moment().week(),
        }}
      >
        <FormFields activeKey={activeKey} />
      </Form>
      <ListInvoices
        isFetching={isFetching}
        handleSort={handleSort}
        headerTable={headerTable}
        data={timesheet?.data}
      />
      <div className='table-footer-container'>
        <div className='pagination-container'>
          <Pagination
            responsive
            showSizeChanger
            current={number}
            pageSize={size}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            total={timesheet?.totalCount || 0}
            onChange={handlePagination}
            onShowSizeChange={handleShowSizeChange}
          />
        </div>
      </div>
      <div className='create-invoice-footer'>
        <Button
          disabled={!selectedInvoice}
          className='create-invoice-btn yellow-button'
          loading={isCreating}
          onClick={() => setShowModalCreateInvoices(true)}
        >
          Create Invoice
        </Button>
      </div>
      <Modal
        visible={isShowInvoice}
        onOk={() => setIsShowInvoice(false)}
        onCancel={() => setIsShowInvoice(false)}
        wrapClassName='invoice-preview'
        footer={null}
      >
        <button
          type='button'
          style={{ all: 'unset', width: '100%' }}
          onClick={handleClickPreviewInvoice}
        >
          <PDF fileName={invoiceFile} width={windowSize.width / 1.7} />
        </button>
      </Modal>
      <ModalConfirm
        visible={showModalCreateInvocies}
        onClickYes={handleCreateInvoices}
        onClickNo={() => setShowModalCreateInvoices(false)}
        footer={null}
        title='Create Invoice?'
      >
        Are you sure you want to create an invoice for the selected Providers?
      </ModalConfirm>
    </div>
  );
};

CreateInvoice.propTypes = {
  form: PropTypes.object,
  activeKey: PropTypes.string,
};

export default CreateInvoice;
