/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import {
  DownloadOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Form, List, Typography, message } from 'antd';
import { get, groupBy, head, isEmpty, isNil, values } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';

import { ReactComponent as DeclineButton } from '@/assets/icons/closedCircle.svg';
import { Button, FormItem, Modal, TimePicker } from '@/components';
import ModalJobSeeker from '@/components/JobSeeker/ModalJobSeeker';
import { useFetch } from '@/hooks';
import { DECLINED_TYPE } from '@/pages/Jobs/CheckinCheckoutJobsDrawer/AssignList/Jobs/Confirmed/constant';
import {
  UPDATE_STATUS,
  assignJobSeeker,
} from '@/services/jobServices/assignJobSeeker';
import getPositionJobs from '@/services/jobServices/getPositionJobs';
import { updateJobSeeker } from '@/services/jobServices/updateJobSeeker';
import { time } from '@/utils';
import { formatISOString } from '@/utils/time';
import useModal from '@/utils/useModal';

import './styles.scss';

const STATUSES = {
  checked_in: 'Checked In',
  checked_out: 'Checked Out',
  no_show: 'No Show',
  declined: 'Declined',
  unchecked: 'Unchecked',
};

const ACTION_KEYS = {
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
  NO_SHOW: 'NO_SHOW',
  DECLINE: 'DECLINE',
  VIEW_INFO: 'VIEW_INFO',
};

const assignKeys = [ACTION_KEYS.CHECK_IN, ACTION_KEYS.CHECK_OUT];

const getActionLabel = (actionKey) => {
  switch (actionKey) {
    case ACTION_KEYS.CHECK_IN:
      return 'Checked In';
    case ACTION_KEYS.CHECK_OUT:
      return 'Checked Out';
    case ACTION_KEYS.NO_SHOW:
      return 'No Show';
    case ACTION_KEYS.DECLINE:
      return 'Declined';
    case ACTION_KEYS.VIEW_INFO:
      return 'View Info';
    default:
      return null;
  }
};

/**
 * @typedef {Object} AssignedScheduleAttributesParams
 * @property {number | string} id
 * @property {UPDATE_STATUS} status
 */

/**
 * Represents an array of arrays containing job response data.
 * Each inner array contains objects with specific properties.
 * @typedef {Array<Array<{
 *   id: number,
 *   fullname: string,
 *   status: string,
 *   jobResponsesId: number,
 *   employeeId: number,
 *   startTime: number,
 *   endTime: number
 * }>>} JobSeekersProps
 */

/**
 * Represents the type of `seekerId`.
 * @typedef {string|number} SeekerIdType
 */

/**
 *
 * @param {Object} param
 * @param {string|number} param.eventId
 * @param {string} param.date
 * @param {Function} param.refetchCalendarJobs
 * @param {Function} param.downloadCSVJobs
 * @returns
 */
const ConfirmedTab = ({
  eventId,
  date,
  refetchCalendarJobs,
  downloadCSVJobs,
}) => {
  const { data: jobSeekers } = useFetch(
    ['eventsJobSeeker', eventId, date],
    () => getPositionJobs({ positionId: eventId, date }),
  );

  const groupedEmployee = values(groupBy(jobSeekers, 'employeeId'));

  const formInstance = Form.useFormInstance();
  const [actionKey, setActionKey] = useState('');
  const [seekerId, setSeekerId] = useState(null);
  const [positionId, setPositionId] = useState(null);
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const [assignedId, setAssignedId] = useState();

  const queryClient = useQueryClient();

  const handleViewModal = ({ shiftId, employeeId, action }) => {
    setPositionId(shiftId);
    setSeekerId(employeeId);
    setActionKey(action);
  };

  const handleViewInfo = ({ employeeId, action }) => {
    setSeekerId(employeeId);
    setActionKey(action);
  };

  const { mutate: assignJobSeekerMutation, isLoading: isAssigningJob } =
    useMutation(
      (
        /** @type {Array<AssignedScheduleAttributesParams>} */ assignedSchedulesAttributes,
      ) =>
        assignJobSeeker({ positionId: eventId, assignedSchedulesAttributes }),
      {
        onSuccess: () => {
          message.success(`Successfully ${getActionLabel(actionKey)} seeker`);
          setSeekerId(null);
          queryClient.invalidateQueries(['eventsJobSeeker', eventId, date]);
        },
      },
    );

  const handleAssign = () => {
    if (isNil(seekerId) || isNil(positionId)) {
      return;
    }

    const createdAt = formatISOString(formInstance.getFieldValue('createdAt'));
    const isAssign = [ACTION_KEYS.CHECK_IN, ACTION_KEYS.CHECK_OUT].includes(
      actionKey,
    );

    const assignedSchedulesAttributes = [
      {
        id: positionId,
        status: UPDATE_STATUS[actionKey],
        ...(isAssign && { createdAt }),
      },
    ];

    assignJobSeekerMutation(assignedSchedulesAttributes);
  };

  const { mutate: updateSeekerForJob, isLoading: isLoadingDecline } =
    useMutation(
      ({ selectedIds, status }) =>
        updateJobSeeker(eventId, selectedIds, status),
      {
        onError: () => {
          message.error('Decline Seeker failure');
        },
        onSuccess: () => {
          message.success('Decline Seeker success');
          hiddenModal();
          refetchCalendarJobs();
        },
      },
    );

  const handleDecline = () => {
    updateSeekerForJob({ selectedIds: [assignedId], status: DECLINED_TYPE });
  };

  const declineSeekr = (jobResponsesId) => {
    setAssignedId(jobResponsesId);
    showModal();
  };

  const handleDownload = () => {
    downloadCSVJobs('confirmed');
  };
  return (
    <div className='event-confirmed-container'>
      <div className='event-confirmed-download'>
        <div className='event-confirmed-row-container' onClick={handleDownload}>
          <DownloadOutlined />
          <div>Download List</div>
        </div>
      </div>
      <List
        dataSource={groupedEmployee}
        renderItem={(item) => {
          if (isEmpty(item)) {
            return null;
          }
          const seekerInfo = head(item);
          const seekerStatus = get(seekerInfo, 'status', '');
          const statusLabel = get(STATUSES, seekerStatus, '');
          const jobResponsesId = get(seekerInfo, 'jobResponsesId');
          const employeeId = get(seekerInfo, 'employeeId', null);
          const seekerFullName = get(seekerInfo, 'fullname', '');
          const isNoShow = seekerStatus === 'no_show';
          const isCheckedIn = seekerStatus === 'checked_in';
          const isCheckedOut = seekerStatus === 'checked_out';
          const isUnchecked = seekerStatus === 'unchecked';
          return (
            <div>
              <List.Item
                actions={[
                  <DeclineButton
                    height={20}
                    width={20}
                    onClick={() => declineSeekr(jobResponsesId)}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar size={30} src={<UserOutlined />} />}
                  title={
                    <div className='event-confirmed-title'>
                      <div
                        title={`${seekerFullName}`}
                        className='event-confirmed-title-fullname'
                      >
                        {seekerFullName}
                      </div>
                      <InfoCircleOutlined
                        onClick={() =>
                          handleViewInfo({
                            employeeId,
                            action: ACTION_KEYS.VIEW_INFO,
                          })
                        }
                      />
                    </div>
                  }
                  description={
                    <div className='event-confirmed-description'>
                      <div title={`${statusLabel}`}>{statusLabel}</div>
                    </div>
                  }
                />
              </List.Item>
              <div className='schedules-item-shift'>
                {item.map((shift) => {
                  const startDate = moment
                    .unix(shift.startTime)
                    .format('Do MMM');
                  const shiftTime = `${time.formatDate(
                    moment.unix(shift.startTime),
                    'HH:mm',
                  )} - ${time.formatDate(moment.unix(shift.endTime), 'HH:mm')}`;
                  return (
                    <div className='shift-item'>
                      <div className='shift-item-info'>
                        <div>{startDate}</div>
                        <div className='shift-item-time'>{shiftTime}</div>
                      </div>
                      <div className='shift-item-actions'>
                        <Button
                          onClick={() =>
                            handleViewModal({
                              shiftId: shift.id,
                              employeeId: shift.employeeId,
                              action: ACTION_KEYS.CHECK_IN,
                            })
                          }
                          disabled={isCheckedIn || isCheckedOut || isNoShow}
                          className='yellow-button action-event-shift'
                        >
                          Check-In
                        </Button>
                        <Button
                          onClick={() =>
                            handleViewModal({
                              shiftId: shift.id,
                              employeeId: shift.employeeId,
                              action: ACTION_KEYS.CHECK_OUT,
                            })
                          }
                          disabled={isUnchecked || isCheckedOut || isNoShow}
                          className='yellow-button action-event-shift'
                        >
                          Check-Out
                        </Button>
                        <Button
                          onClick={() =>
                            handleViewModal({
                              shiftId: shift.id,
                              employeeId: shift.employeeId,
                              action: ACTION_KEYS.NO_SHOW,
                            })
                          }
                          disabled={isNoShow}
                          className='yellow-button action-event-shift'
                        >
                          No Show
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      />
      {seekerId && actionKey === ACTION_KEYS.VIEW_INFO && (
        <ModalJobSeeker
          seekerId={seekerId}
          visibleModal
          setVisibleModal={setSeekerId}
        />
      )}
      {!isNil(seekerId) &&
        !isNil(positionId) &&
        [
          ACTION_KEYS.CHECK_IN,
          ACTION_KEYS.CHECK_OUT,
          ACTION_KEYS.NO_SHOW,
          ACTION_KEYS.DECLINE,
        ].includes(actionKey) && (
          <Modal
            title={`${getActionLabel(actionKey)}?`}
            onClickYes={handleAssign}
            onClickNo={() => setSeekerId(null)}
            visible
            isLoading={isAssigningJob}
          >
            <p>Are you sure want to {getActionLabel(actionKey)} this seeker</p>
            {assignKeys.includes(actionKey) && (
              <FormItem
                className='assign-seeker-timepicker'
                name='createdAt'
                initialValue={moment()}
              >
                <TimePicker format='HH:mm' />
              </FormItem>
            )}
          </Modal>
        )}
      <Modal
        visible={isDisplayModal}
        onClickNo={hiddenModal}
        onClickYes={handleDecline}
        isLoading={isLoadingDecline}
      >
        <Typography.Title level={3}>Decline?</Typography.Title>
        <div className='time-picker-modal'>
          <p>Are you sure you want to decline all seleted workers</p>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmedTab;

ConfirmedTab.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  refetchCalendarJobs: PropTypes.func,
  downloadCSVJobs: PropTypes.func,
};

ConfirmedTab.defaultProps = {
  eventId: null,
  date: '',
};
