import React, { useMemo, useState } from 'react';

import { Form, Spin, message } from 'antd';
import { isArray, isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import successIcon from '@/assets/icons/successIcon.png';
import under18 from '@/assets/icons/under18.png';
import {
  ModalConfirm,
  GeneralTemplate,
  ErrorsAlert,
  Button,
  Modal,
  FormItem,
} from '@/components';
import { useCheckUkIreland } from '@/features/nationality/hooks';
import { useFetch } from '@/hooks';
import { CandidatesService } from '@/services';
import { checkExistEmploymentOrCV } from '@/utils/seeker';
import useModal from '@/utils/useModal';

import CandidateContext from '../CandidateContext';
import { FORM_TYPES } from '../constants';
import FormCandidate from '../FormCandidate';
import convertData from './convertData';

import '../styles.scss';

const missingFields = {
  passport: 'Please Upload Passport or Birth certificate and ni proof.',
  birthCertificate: 'Please Upload Passport or Birth certificate and ni proof.',
  niProof: 'Please Upload Passport or Birth certificate and ni proof.',
  contract: 'Missing Contract file',
  comments: 'Please add comments',
  shareCode: 'Missing Share Code',
  employeeWpExpiry: 'The share code expiration date is required',
  shareCodeDocument: 'Share code document is required',
};

const APPROVED_STATUS_VALUE = 1;

/**
 *
 * @param {Array<[boolean, string]>} values
 * @returns {String}
 */
const returnContent = (values = []) => {
  if (!isArray(values)) return '';
  const value = values.find(([item]) => item);
  return value?.at(1) ?? '';
};

const EditCandidate = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const history = useHistory();
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const [modalStatus, showModalStatus, hiddenModalStatus] = useModal();

  const breadcrumb = [
    { item: 'Candidate', to: '/recruitment/candidate' },
    { item: 'Edit Candidate', to: `/edit-candidate/${id}` },
  ];

  const [modalContent, setModalContent] = useState();

  const [isShowErr, setShowErr] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [isWarningConviction, setIsWarningConviction] = useState(false);
  const [listErrorDetails, setListErrorDetails] = useState([]);
  const [activeKey, setActiveKey] = useState(['Contact Information']);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const checkUkIreland = useCheckUkIreland();

  const {
    data: dataCandidate,
    isLoading: isLoadingCandidate,
    refetch: refetchCandidate,
  } = useFetch(
    ['get-candidate-by-id', id],
    () => CandidatesService.getCandidateById(id),
    {
      onSuccess: (data) => {
        const hasConvict = !!data?.convict;
        const isUkOrIreland = checkUkIreland(data?.nationality);
        const formValue = convertData(data, isUkOrIreland);
        form.setFieldsValue(formValue);

        setIsWarningConviction(hasConvict);
      },
    },
  );
  const {
    mutate: updatedCandidate,
    isLoading,
    isSuccess,
    reset,
  } = useMutation(
    async (candidateInformation) =>
      CandidatesService.updateCandidateUpdated({
        candidateInformation,
        id,
      }),
    {
      onSuccess: () => {
        const data = form.getFieldsValue();
        if (data.candidateStatus === APPROVED_STATUS_VALUE) {
          setShowRedirectModal(true);
        }

        refetchCandidate();
      },
      onError: (err) => {
        setShowModal(false);
        const { status, data: dataError } = err;

        if (status) {
          const listErrors = dataError.errors.reduce(
            (acc, cur) => [...acc, cur.detail],
            [],
          );

          setListErrorDetails(listErrors);
          setShowErr(true);
          setShowModal(false);
          window.scrollTo(0, 0);
        }
      },
    },
  );

  const handleSubmit = () => {
    const candidateStatus = form.getFieldValue('candidateStatus');
    const shareCode = form.getFieldValue('shareCode');
    const employeeWpExpiry = form.getFieldValue('employeeWpExpiry');
    const comments = form.getFieldValue('comments');
    const nationality = form.getFieldValue('nationality');
    const isUkOrIreland = checkUkIreland(nationality);

    // field from documentation section
    const documentationDocs = form.getFieldValue('documentationDocs');
    const contractFileFound = (documentationDocs ?? []).find(
      (item) => item.typeValue === 'contractFile',
    );

    // field from RTW section
    const rightToWorkDocs = form.getFieldValue('rightToWorkDocs');
    const passportId1FileFound = rightToWorkDocs.find(
      (item) => item.typeValue === 'passportId1File',
    );
    const birthCertificateFound = rightToWorkDocs.find(
      (item) => item.typeValue === 'birthCertificate',
    );
    const niProofFound = rightToWorkDocs.find(
      (item) => item.typeValue === 'niProof',
    );

    const shareCodeFileValue = form.getFieldValue('shareCodeFileValue');

    // Case 1: includes uk or ireland
    // Checks: is approved
    // is include uk or ireland
    // not empty Passport file uploaded or birth certificate and ni proof
    const hasMissingFields =
      (!passportId1FileFound?.fileValue &&
        (!birthCertificateFound?.fileValue || !niProofFound?.fileValue)) ||
      !contractFileFound?.fileValue ||
      isEmpty(comments);

    if (
      candidateStatus === APPROVED_STATUS_VALUE &&
      isUkOrIreland &&
      hasMissingFields
    ) {
      const fields = [];

      if (
        !passportId1FileFound?.fileValue &&
        (!birthCertificateFound?.fileValue || !niProofFound?.fileValue)
      ) {
        fields.push([!passportId1FileFound?.fileValue, missingFields.passport]);
        fields.push([
          !birthCertificateFound?.fileValue,
          missingFields.birthCertificate,
        ]);
        fields.push([!niProofFound?.fileValue, missingFields.niProof]);
      } else if (!contractFileFound?.fileValue) {
        fields.push([!contractFileFound?.fileValue, missingFields.contract]);
      } else {
        fields.push([isEmpty(comments), missingFields.comments]);
      }

      setModalContent(returnContent(fields));
      return showModalStatus();
    }

    // Case 2: not includes uk or ireland
    // Checks: is approved
    // not empty share code or contract file or comments
    if (
      candidateStatus === APPROVED_STATUS_VALUE &&
      !isUkOrIreland &&
      (!shareCode ||
        !contractFileFound?.fileValue ||
        isEmpty(comments) ||
        isEmpty(employeeWpExpiry) ||
        isEmpty(shareCodeFileValue))
    ) {
      const content = returnContent([
        [!shareCode, missingFields.shareCode],
        [!contractFileFound?.fileValue, missingFields.contract],
        [isEmpty(comments), missingFields.comments],
        [isEmpty(employeeWpExpiry), missingFields.employeeWpExpiry],
        [isEmpty(shareCodeFileValue), missingFields.shareCodeDocument],
      ]);
      setModalContent(content);

      return showModalStatus();
    }

    const dataForm = form.getFieldsValue();
    const hasConvict = form.getFieldValue('hasConvict');
    const convict = hasConvict ? form.getFieldValue('convict') : '';

    return updatedCandidate({ ...dataForm, convict });
  };

  const validateBeforeSubmit = () => {
    const isExistCVOrPastjobs = checkExistEmploymentOrCV(form);
    if (!isExistCVOrPastjobs) {
      // eslint-disable-next-line quotes
      message.warning("Please provide Employment History OR seeker's CV");
      return;
    }
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };

  const value = useMemo(() => {
    if (!isEmpty(dataCandidate)) {
      return {
        hear: dataCandidate?.hear ?? '',
      };
    }

    return {};
  }, [dataCandidate]);
  const handleConfirmSuccess = () => {
    if (showRedirectModal) {
      history.push(`/seeker-profile/${dataCandidate.employeeAttributes.id}`);
    }
    handleCloseModal();
  };

  return (
    <GeneralTemplate
      mainItem='Recruitment'
      headerText={
        isLoadingCandidate ? (
          <Spin />
        ) : (
          <div className='candidate-header-text-custom'>
            <span className='candidate-name'>
              Candidate - {dataCandidate?.firstname} {dataCandidate?.lastname}
            </span>
            <span className='block-icon'>
              {!dataCandidate?.isOver18 && <img src={under18} alt='' />}
              {!dataCandidate?.isOver18}
            </span>
          </div>
        )
      }
      data={breadcrumb}
      hasButton={false}
      subTemplateClassName='candidate-body'
    >
      {isShowErr && (
        <ErrorsAlert
          message='Oops! - Something went wrong'
          details={listErrorDetails}
          closable
          afterClose={() => setShowErr(false)}
        />
      )}
      <Modal
        visible={isWarningConviction}
        onCancel={() => setIsWarningConviction(false)}
        isShowFooter={false}
        title='Conviction'
      >
        There is a criminal conviction on this profile. Please review.
      </Modal>
      <Spin size='large' spinning={isLoadingCandidate}>
        <CandidateContext.Provider value={value}>
          <Form
            form={form}
            onFinish={validateBeforeSubmit}
            onFinishFailed={(err) => {
              const errorName = err.errorFields[0].name;

              setActiveKey([
                'Contact Information',
                'Licences and Docs',
                'Past Work Details',
                'Worker Payroll and AWR information',
                'References and Medical Information',
                'Comments and Status updates',
              ]);
              setTimeout(() => {
                form.scrollToField(errorName, {
                  skipOverflowHiddenElements: true,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 500);
            }}
            initialValues={{
              formType: FORM_TYPES.edit,
              convict: 'Yes',
              maximumWeeklyHours: '20 Hours',
            }}
          >
            <FormItem name='formType' hidden />
            <FormCandidate activeKey={activeKey} setActiveKey={setActiveKey} />
            <div className='candidate-footer'>
              <Button className='btn-cancel' onClick={showModal}>
                Cancel
              </Button>
              <Button className='btn-save' htmlType='submit'>
                Save
              </Button>
            </div>
          </Form>
        </CandidateContext.Provider>
      </Spin>
      <ModalConfirm
        title='Edit Candidate?'
        titleSuccess={
          showRedirectModal
            ? 'Successfully Approved'
            : 'Candidate edited successfully'
        }
        successIcon={
          showRedirectModal && <img src={successIcon} alt='success-icon' />
        }
        extraContent={
          showRedirectModal && (
            <p className='modal-desc'>
              The account was successfully approved and the Candidate is now a
              Seeker
              <br />
              They will shortly receive their welcome email with their log in
              details
            </p>
          )
        }
        visible={isShowModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleConfirmSuccess : handleSubmit}
        onClickNo={handleCloseModal}
      >
        Are you sure you want to edit Candidate?
      </ModalConfirm>

      <ModalConfirm
        title="Don't Want to Save Updates?"
        visible={isDisplayModal}
        onClickYes={() => history.push('/recruitment/candidate')}
        onClickNo={hiddenModal}
      >
        Are you sure you want to leave without saving? You will lost any uploads
        and changes you have made.
      </ModalConfirm>

      <ModalConfirm
        titleSuccess={modalContent}
        visible={modalStatus}
        onClickYes={hiddenModalStatus}
        isSuccess
      />
    </GeneralTemplate>
  );
};

export default EditCandidate;
