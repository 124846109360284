import normalize from 'json-api-normalizer';

export const formatResponse = (response, { endpoint }) => {
  // append options for nomalize api
  let options;
  if (endpoint) {
    options = { endpoint };
  }
  const data = normalize(response.data, options);

  if (endpoint) {
    data.endpoint = endpoint;
  }
  return data;
};
