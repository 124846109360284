import request from '@/request';

// Get list name or email of lead
const getListEmailAndName = async (searchTerm) => {
  const res = await request.get('/providers/leads', {
    params: {
      filter: {
        searchTerm,
      },
    },
  });
  const itemsSearchTerm = res.data.list_items || [];
  const itemsSearchTermConverted = itemsSearchTerm.map((item) => ({
    value: item,
    label: item,
  }));
  return itemsSearchTermConverted;
};

export default getListEmailAndName;
