import React from 'react';

import { Form, Radio } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, Input, FormItem } from '@/components';
import {
  rulesAccountNumber,
  rulesSortCode,
  rulesTextInput,
} from '@/utils/rulesInput';

import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const BankDetails = ({ nextStep, onValuesChange, initialValues, form }) => {
  const tenant = useTenantContext();
  const employeePaymentMethod = Form.useWatch(
    ['omniEmployeeAttributes', 'employeePaymentMethod'],
    form,
  );

  const applyRules = employeePaymentMethod === 'bacs_bank';

  return (
    <div className='flex-one-column bank-details'>
      <div className='title'>Bank Details</div>
      <p>
        Your bank details are required for any future payments if you work with{' '}
        {tenant.name}. This must be registered in your name. Please note, we
        will not be able to pay into a bank account In someone elses name.
      </p>
      <Form
        form={form}
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <FormItem
          required
          rules={[{ required: true, message: 'Please choose an option' }]}
          name={['omniEmployeeAttributes', 'employeePaymentMethod']}
        >
          <Radio.Group
            name={['omniEmployeeAttributes', 'employeePaymentMethod']}
            className='recruitment-radio'
          >
            <Radio value='bacs_bank'>I have a bank account</Radio>
            <Radio value='cheque'>
              I do not have a bank account, and accept I may be paid by cheque
              or other
            </Radio>
            <Radio value='bacs_bs'>
              I have a bank account, but will provide my bank details at
              interview stage
            </Radio>
          </Radio.Group>
        </FormItem>

        <FormItem
          rules={applyRules && rulesTextInput('account holder')}
          className={classNames('input-container detail-form-container', {
            'remove-rule': !applyRules,
          })}
          name='bankAccName'
          label='Account holder'
          required={applyRules}
        >
          <Input placeholder='Account holder' />
        </FormItem>
        <FormItem
          rules={applyRules && rulesTextInput('name of bank')}
          className={classNames('input-container detail-form-container', {
            'remove-rule': !applyRules,
          })}
          name='bank'
          label='Name of bank'
          required={applyRules}
        >
          <Input placeholder='Name of bank' />
        </FormItem>
        <FormItem
          className={classNames('input-container detail-form-container', {
            'remove-rule': !applyRules,
          })}
          name='bankSort'
          rules={applyRules && rulesSortCode()}
          label='Sort code'
          required={applyRules}
        >
          <Input placeholder='Sort code' maxLength={16} />
        </FormItem>
        <FormItem
          className={classNames('input-container detail-form-container', {
            'remove-rule': !applyRules,
          })}
          name='bankAcc'
          rules={applyRules && rulesAccountNumber()}
          label='Account number'
          required={applyRules}
        >
          <Input placeholder='Account number' />
        </FormItem>
        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

BankDetails.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default BankDetails;
