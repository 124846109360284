import React from 'react';

import { Form, Table } from 'antd';

import { useRoleItems } from '@/hooks/dropdowns';
import Formatter from '@/utils/Formatter';

import { ADDITIONAL_TYPES } from '../../constants';
import { calTotal } from '../../utils/calculation';
import generateColumns from './generateColumns';
import { useTenantContext } from '../../../../../TenantWrapper';

const Body = () => {
  const { data: roles } = useRoleItems();
  const { invoiceInfo = {} } = useTenantContext();
  const formInstance = Form.useFormInstance();
  const { contact = {}, registeredCompany = {} } = invoiceInfo;
  const { phoneNumber } = contact;
  const { name, regNumber, vatRegNumber } = registeredCompany;

  const hoursSections =
    Form.useWatch(ADDITIONAL_TYPES.labour.key, formInstance) ?? [];
  const costSections =
    Form.useWatch(ADDITIONAL_TYPES.cost.key, formInstance) ?? [];
  const labourDesc = Form.useWatch('labourDesc', formInstance);

  const { totalNet, totalVat, totalInvoice } = calTotal([
    ...hoursSections,
    ...costSections,
  ]);

  return (
    <div className='preview-invoice-body'>
      <div className='section-container'>
        <Table
          columns={generateColumns({
            desc: labourDesc,
            roles,
            type: ADDITIONAL_TYPES.labour.key,
          })}
          dataSource={hoursSections}
          pagination={false}
          bordered
        />
      </div>
      {costSections.map(({ description }, index) => (
        <div className='section-container'>
          <Table
            columns={generateColumns({
              desc: description,
              type: ADDITIONAL_TYPES.cost.key,
            })}
            dataSource={[costSections[index]]}
            pagination={false}
            bordered
          />
        </div>
      ))}

      <div className='total-container'>
        <div className='left-info'>
          <p className='text'>Invoice Enquiries Tel: {phoneNumber}</p>
          <p className='text'>VAT REG NUMBER: {vatRegNumber}</p>
          <p className='text'>{name}</p>
          <p className='text'>Company Registration No: {regNumber}</p>
        </div>
        <div className='right-info'>
          <div className='detail'>
            <div className='item'>
              Total NET
              <span>{Formatter.currencyGBP(totalNet)}</span>
            </div>
            <div className='item'>
              Total VAT
              <span>{Formatter.currencyGBP(totalVat)}</span>
            </div>
          </div>

          <div className='total'>
            Invoice Total
            <span>{Formatter.currencyGBP(totalInvoice)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
