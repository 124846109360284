import React from 'react';

import PropTypes from 'prop-types';

import { FormItem, Select, Button } from '@/components';
import { listWeek } from '@/constants';
import { listYear } from '@/pages/Timesheet/constants';

export const InputFieldsWage = ({
  handleClearFilter,
  setWeekNumber,
  weekNumber,
}) => (
  <div className='row-container'>
    <FormItem label='Choose year' name='year' className='form-item'>
      <Select
        name='year'
        className='input-height'
        placeholder='Roles'
        options={listYear}
      />
    </FormItem>
    <FormItem className='form-item' label='Choose Week'>
      <Select
        className='input-height'
        value={weekNumber}
        placeholder='Choose Week'
        options={listWeek}
        onChange={(_, option) => {
          setWeekNumber(option.value);
        }}
      />
    </FormItem>
    <Button
      htmlType='submit'
      type='primary'
      className='yellow-button button-item'
    >
      Search
    </Button>
    <Button onClick={handleClearFilter} className='filter-button button-item'>
      Clear Filter
    </Button>
  </div>
);

InputFieldsWage.propTypes = {
  handleClearFilter: PropTypes.func,
  setWeekNumber: PropTypes.func,
  weekNumber: PropTypes.number,
};

InputFieldsWage.defaultProps = {
  handleClearFilter: () => {},
  setWeekNumber: () => {},
};

export default InputFieldsWage;
