import React, { Suspense, useState } from 'react';

import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { FormItem } from '@/components';
import { toArray } from '@/utils/array';

import Timecard from './Timecard';
import TimecardFooter from './TimecardFooter';

const ListTimecards = ({
  status,
  timesheetId,
  panelInfo,
  isHourlyTemplate,
  isLoadingPayTemplate,
  timesheetRefetch,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const summaryFunc = (pageData = []) =>
    pageData.reduce(
      (totals, { totalHours, totalPay, totalCharge, breakInfo }) => {
        const {
          breakDuration = 0,
          payRate = 0,
          chargeRate = 0,
        } = breakInfo || {};
        const hoursSubBreak =
          Number(totalHours) - (Number(breakDuration) ?? 0) / 60;

        totals.charge += chargeRate ?? 0;
        totals.pay += payRate ?? 0;
        totals.break += breakDuration ?? 0;
        totals.totalHours += totalHours ?? 0;
        totals.totalPay += Number(totalPay) ?? 0;
        totals.totalCharge += Number(totalCharge) ?? 0;
        totals.totalHoursSubTotalBreak += Number(hoursSubBreak) ?? 0;

        return totals;
      },
      {
        break: 0,
        totalHours: 0,
        totalPay: 0,
        totalCharge: 0,
        charge: 0,
        pay: 0,
        totalHoursSubTotalBreak: 0,
      },
    );

  const summaryWeeklyFunc = (dataSummary = []) => ({
    startAt: 'Weekly total',
    ...dataSummary.reduce(
      (totals, e) => {
        const {
          totalHours,
          pay,
          charge,
          break: breakTime,
          totalHoursSubTotalBreak,
        } = summaryFunc(toArray(e));

        totals.totalHours += totalHours;
        totals.break += breakTime;
        totals.pay += pay;
        totals.charge += charge;
        totals.totalHoursSubTotalBreak += totalHoursSubTotalBreak;
        return totals;
      },
      {
        break: 0,
        totalHours: 0,
        charge: 0,
        pay: 0,
        totalHoursSubTotalBreak: 0,
      },
    ),
  });
  return (
    <>
      <div className='panel-detail'>
        <FormItem name='idsActive' />
        {!isEmpty(panelInfo.timecards) &&
          (isHourlyTemplate ? (
            panelInfo.timecards.map((timecard, index) => (
              <Suspense key={timecard.id} fallback={<Spin />}>
                <Timecard
                  isHourlyTemplate={isHourlyTemplate}
                  loading={isLoadingPayTemplate}
                  panelId={panelInfo.id}
                  seekerName={panelInfo?.seekerName}
                  timecard={timecard}
                  key={timecard.id}
                  timesheetData={panelInfo.payrollTemplateHours}
                  timecardIndex={index}
                  totalTimecards={panelInfo.timecards.length}
                  isEdit={isEdit}
                  summaryFunc={summaryFunc}
                  weeklyTotals={
                    index === panelInfo.timecards.length - 1 &&
                    summaryWeeklyFunc(panelInfo.payrollTemplateHours)
                  }
                  skillName={panelInfo?.skillName}
                />
              </Suspense>
            ))
          ) : (
            <Suspense fallback={<Spin />}>
              <Timecard
                isHourlyTemplate={isHourlyTemplate}
                loading={isLoadingPayTemplate}
                panelId={panelInfo.id}
                seekerName={panelInfo?.seekerName}
                timecard={panelInfo.payrollTemplateHours}
                timesheetData={panelInfo.payrollTemplateHours}
                timecardIndex={0}
                totalTimecards={panelInfo.timecards.length}
                isEdit={isEdit}
                weeklyTotals={() =>
                  summaryWeeklyFunc(panelInfo.payrollTemplateHours)
                }
                summaryFunc={summaryFunc}
                skillName={panelInfo?.skillName}
              />
            </Suspense>
          ))}
      </div>
      <TimecardFooter
        status={status}
        panelInfo={panelInfo}
        isHourlyTemplate={isHourlyTemplate}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        timesheetId={timesheetId}
        timesheetRefetch={timesheetRefetch}
      />
    </>
  );
};

export default ListTimecards;

ListTimecards.propTypes = {
  timesheetId: PropTypes.number,
  status: PropTypes.string,
  isLoadingPayTemplate: PropTypes.bool,
  panelInfo: PropTypes.object,
  isHourlyTemplate: PropTypes.bool,
  timesheetRefetch: PropTypes.func,
};

ListTimecards.defaultProps = {
  timesheetId: null,
  status: '',
  panelInfo: {},
  isHourlyTemplate: false,
  timesheetRefetch: () => {},
};
