import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { FormItem, DatePicker } from '@/components';
import useHandleCancelDatePicker from '@/hooks/useCancelDatePicker';
import { disabledDateOf, date } from '@/utils';

const DateSeekerFields = ({
  title,
  nameStartDate,
  nameEndDate,
  startDate,
  endDate,
}) => {
  const form = Form.useFormInstance();

  const handleCancelClickFrom = useHandleCancelDatePicker(form, nameStartDate);
  const handleCancelClickTo = useHandleCancelDatePicker(form, nameEndDate);

  return (
    <div>
      <div className='seeker-label-field'>
        <span>{title}</span>
      </div>
      <div className='seeker-date-field'>
        <FormItem name={nameStartDate} normalize={date.convertDateTime}>
          <DatePicker
            placeholder='Select Date'
            disabledDate={(disabledDate) =>
              disabledDateOf.Start(disabledDate, endDate)
            }
            name={nameStartDate}
            onCancelClick={handleCancelClickFrom}
          />
        </FormItem>
        <FormItem name={nameEndDate} normalize={date.convertDateTime}>
          <DatePicker
            placeholder='Select Date'
            disabledDate={(disabledDate) =>
              disabledDateOf.End(disabledDate, startDate)
            }
            name={nameEndDate}
            onCancelClick={handleCancelClickTo}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default DateSeekerFields;

DateSeekerFields.propTypes = {
  title: PropTypes.string,
  nameStartDate: PropTypes.string,
  nameEndDate: PropTypes.string,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
};
