import React from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from '@/components';

import './style.scss';

const PLReport = () => {
  const history = useHistory();

  return (
    <div className='left-section-container'>
      <div className='pl-report-container'>
        <h3>P&L Report</h3>
        <Button
          onClick={() => history.push('/report-weekly')}
          className='yellow-button button-style'
        >
          View P&L Weekly
        </Button>
        <Button
          onClick={() => history.push('/report-yearly')}
          className='yellow-button button-style'
        >
          View P&L Yearly
        </Button>
      </div>
    </div>
  );
};

export default PLReport;
