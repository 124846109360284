import request from '@/request';

const weeklyWagesCsvConfirmPaid = async (filter) => {
  const endpoint = 'payments/wages/csv';

  const res = await request.get(endpoint, {
    params: {
      filter,
      confirmPaid: true,
    },
  });

  return res?.data;
};
export default weeklyWagesCsvConfirmPaid;
