/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';

import _, { groupBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Input, Select } from '@/components';
import { COST_ROLES } from '@/constants';
import { useRoleItems } from '@/hooks/dropdowns';
import Formatter from '@/utils/Formatter';
import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const PreviewInvoice = ({
  invoice,
  total,
  totalNetAmount,
  totalOmniVat,
  invoiceDetails,
  createAt,
  pdfRef,
}) => {
  const { mainLogo, invoiceInfo = {} } = useTenantContext();
  const descriptions = useMemo(
    () => groupBy(invoiceDetails, 'description'),
    [invoiceDetails],
  );

  const { data: roleItems } = useRoleItems();

  const {
    id,
    postCode,
    providerName,
    providerLocationAddress,
    customerSageCode,
  } = invoice;

  const {
    contact = {},
    address = {},
    registeredCompany = {},
    payment = {},
    bank = {},
  } = invoiceInfo;
  const { website, email, phoneNumber } = contact;
  const { buildingNumber, street, city, postcode } = address;
  const { name, regNumber, vatRegNumber } = registeredCompany;
  const { accountHolderName, sortCode, paymentTerm } = payment;
  const { name: bankName, accountNumber } = bank;

  const renderHeader = () => (
    <div className='preview-invoice-header'>
      <img className='gig-logo' src={mainLogo.base64} alt='logo' />
      <p className='text'>{website}</p>
      <p className='text'>
        {[buildingNumber, street].filter((s) => !!s).join(' ')}
      </p>
      <p className='text'>{city}</p>
      <p className='text'>{postcode}</p>
      <p className='email-info'>
        {phoneNumber && <span>T: {phoneNumber}</span>}
        {email && <span style={{ marginLeft: '5px' }}>E: {email}</span>}
      </p>
      <div className='sub-info-container'>
        <div className='info'>
          <p className='text'>{providerName}</p>
          <p className='text'>{providerLocationAddress}</p>
          <p className='text'>{postCode}</p>
        </div>
        <div className='provider-name'>{providerName}</div>
        <div className='info'>
          <p className='text'>Invoice No: {id}</p>
          <p className='text'>Date/Tax Point: {createAt}</p>
          <p className='text'>Account: {customerSageCode}</p>
        </div>
      </div>
    </div>
  );

  const renderBody = () => (
    <div className='preview-invoice-body'>
      {_.toPairs(descriptions).map(([desc, invoiceInfos]) => {
        const filtedInvoiceInfos = _.filter(
          invoiceInfos,
          (invoiceInfo) => !invoiceInfo?._destroy,
        );
        return (
          !isEmpty(filtedInvoiceInfos) && (
            <div className='section-container'>
              <div className='row-container above'>
                <div className='width large border-right custom-break'>
                  {desc}
                </div>
                <Input className='width border-right' value='Net Amount' />
                <Input className='width' value='20% VAT' />
              </div>
              {filtedInvoiceInfos.map((item) => (
                <div className='row-container'>
                  <div className='width large column-container border-right'>
                    <Select
                      options={
                        item.invoiceDetailType === 'labour'
                          ? roleItems
                          : COST_ROLES
                      }
                      className='hide-border border-right select-style'
                      value={item.labourTypeId}
                      disabled
                    />
                    <Input
                      className='hide-border border-right padding-none'
                      value={item.invoiceLine}
                      bordered={false}
                    />
                  </div>
                  <Input
                    className='width height-large border-right'
                    value={Number(item.omniNetPay ?? 0).toFixed(2)}
                  />
                  <Input
                    className='width height-large'
                    bordered={false}
                    value={Number(item.omniVat).toFixed(2)}
                  />
                </div>
              ))}
            </div>
          )
        );
      })}

      <div className='total-container'>
        <div className='left-info'>
          <p className='text'>Invoice Enquiries Tel: {phoneNumber}</p>
          <p className='text'>VAT REG NUMBER: {vatRegNumber}</p>
          <p className='text'>{name}</p>
          <p className='text'>Company Registration No: {regNumber}</p>
        </div>
        <div className='right-info'>
          <div className='detail'>
            <div className='item'>
              Total NET
              <span>{Formatter.currencyGBP(totalNetAmount)}</span>
            </div>
            <div className='item'>
              Total VAT
              <span>{Formatter.currencyGBP(totalOmniVat)}</span>
            </div>
          </div>

          <div className='total'>
            Invoice Total
            <span>{Formatter.currencyGBP(total)}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderFooter = () => (
    <div className='preview-invoice-footer'>
      <div className='text-above'>
        <p className='text'>
          Our Standard Terms are Payment within {paymentTerm} Days by BACS. Our
          Bank details are as follows:
        </p>
        <p className='text'>
          A/C Name: {accountHolderName}. Bank: {bankName}. Bank Account No.:{' '}
          {accountNumber} Sort Code : {sortCode}
        </p>
      </div>
      <div className='text-below'>
        <p className='text'>
          Any Changes To Bank Details Will Be Confirmed In Writting Via Post And
          E-mail And
        </p>
        <p className='text'>
          Need To Be VerufuedVerbally By Calling Accounts On {phoneNumber} And
          Writting To
        </p>
        <p className='text'>
          The Above Address. Subject To Our StandardTerms And Conditions
        </p>
      </div>
    </div>
  );

  return (
    <div
      ref={pdfRef}
      id='invoice-pdf-container'
      className='preview-invoice-container'
    >
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </div>
  );
};

PreviewInvoice.propTypes = {
  invoice: PropTypes.object,
  total: PropTypes.number,
  totalNetAmount: PropTypes.number,
  totalOmniVat: PropTypes.number,
  invoiceDetails: PropTypes.array,
  createAt: PropTypes.string,
  pdfRef: PropTypes.object,
};

PreviewInvoice.defaultProps = {
  invoice: {},
  total: 0,
  totalNetAmount: 0,
  totalOmniVat: 0,
  invoiceDetails: [],
  createAt: '',
  pdfRef: null,
};

export default PreviewInvoice;
