import React, { useEffect } from 'react';

import { Form, Radio } from 'antd';
import { upperCase } from 'lodash';
import PropTypes from 'prop-types';

import { Button, Select, Input, FormItem, Checkbox } from '@/components';
import { MARITAL } from '@/constants';
import {
  useNationalities,
  useCheckUkIreland,
} from '@/features/nationality/hooks';

import './styles.scss';

const AboutYou2 = ({ nextStep, onValuesChange, initialValues, form }) => {
  const { nationalities } = useNationalities();
  const checkUkIreland = useCheckUkIreland();

  const disableInsurenceNumber = (
    /** @type {{ target: { checked: boolean; }; }} */ e,
  ) => {
    form.setFieldsValue({
      hasNi: e.target.checked,
      ni1: '',
      ni2: '',
      ni3: '',
      ni4: '',
      ni5: '',
    });
  };

  const hasNi = Form.useWatch('hasNi', form);
  const nationalityNumber = Form.useWatch('nationality', form);
  const isPermit = Form.useWatch('permit', form);
  const isLimitationsOrSpecialRequirementsVisa =
    Form.useWatch('limitVisa', form) === 1;
  const isHours = Form.useWatch('omniEmployeeAttributes', form);
  const isUkOrIreland = checkUkIreland(nationalityNumber);

  const niItems = [
    {
      name: 'ni1',
      rules: [
        {
          required: !hasNi,
          message: '2 letters',
        },
        {
          pattern: /^[a-z]{2}/i,
          message: '2 letters',
        },
      ],
      normalize: upperCase,
      maxLength: 2,
    },
    {
      name: 'ni2',
      rules: [
        {
          required: !hasNi,
          message: '2 numbers',
        },
        {
          pattern: /^[0-9]{2}/i,
          message: '2 numbers',
        },
      ],
      maxLength: 2,
    },
    {
      name: 'ni3',
      rules: [
        {
          required: !hasNi,
          message: '2 numbers',
        },
        {
          pattern: /^[0-9]{2}/i,
          message: '2 numbers',
        },
      ],
      maxLength: 2,
    },
    {
      name: 'ni4',
      rules: [
        {
          required: !hasNi,
          message: '2 numbers',
        },
        {
          pattern: /^[0-9]{2}/i,
          message: '2 numbers',
        },
      ],
      maxLength: 2,
    },
    {
      name: 'ni5',
      rules: [
        {
          required: !hasNi,
          message: '1 letter',
        },
        {
          pattern: /^[a-z]{1}/i,
          message: '1 letter',
        },
      ],
      normalize: upperCase,
      maxLength: 1,
    },
  ];

  const optionsSharedCode = {
    rules: [
      {
        validator: async (_, value) => {
          const pattern = /^([a-zA-Z0-9])/gm;
          const noSpecialChars = value.replace(/[^a-zA-Z0-9 ]/g, '');
          if (!pattern.test(value) || noSpecialChars.length !== 3) {
            throw new Error('3 characters');
          }
        },
        message: '3 characters',
      },
    ],
    normalize: (codeText) => codeText.toUpperCase(),
    maxLength: 3,
  };

  const shareCodeInputs = [
    {
      name: 'code1',
      isPrefix: false,
      ...optionsSharedCode,
    },
    {
      name: 'code2',
      isPrefix: true,
      ...optionsSharedCode,
    },
    {
      isPrefix: true,
      name: 'code3',
      ...optionsSharedCode,
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      permit: isUkOrIreland,
    });
  }, [form, hasNi, isUkOrIreland]);

  return (
    <div className='flex-one-column about-you-2'>
      <div className='title'>About You</div>
      <Form
        form={form}
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='detail-form-container'
      >
        <FormItem
          className='input-container'
          name='nationality'
          rules={[
            {
              required: true,
              message: 'Please select your nationality',
            },
          ]}
          scrollToFirstError={{
            skipOverflowHiddenElements: true,
            block: 'center',
          }}
          label='Nationality'
        >
          <Select options={nationalities} placeholder='Nationality' required />
        </FormItem>

        {isUkOrIreland && (
          <div>
            Are you a British or Irish citizen for Right to Work purposes. (Eg.
            Do you hold a British Passport or birth certificate)?
          </div>
        )}

        {isUkOrIreland && (
          <FormItem name='permit'>
            <Radio.Group name='permit' className='recruitment-radio'>
              <Radio value>
                <span>Yes</span>
              </Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </FormItem>
        )}

        {isPermit && (
          <div className='caption'>
            Thank you, please remember that you will need to have your original
            British or Irish Passport OR birth certificate AND proof of national
            insurance with you when attending your interview. These must be
            originals and copies can not be accepted
          </div>
        )}

        {!isPermit && !!nationalityNumber && (
          <>
            {isLimitationsOrSpecialRequirementsVisa ? (
              <div className='caption'>
                Please enter your Share Code. Note: This is only valid for 30
                days. If you need an up to date code please follow this{' '}
                <a href='https://www.gov.uk/view-prove-immigration-status'>
                  link.
                </a>
              </div>
            ) : (
              <div className='caption'>
                To work in the UK you must have a valid and in date Share Code.
                Please note that a Share Code is a unique 9-digit number that is
                used to verify your right to work, it is only valid for 30 days
                from the point at which it is generated. If you do not have an
                up to date Share Code you can create one via the government
                portal which can be accessed by clicking{' '}
                <a href='https://www.gov.uk/view-prove-immigration-status'>
                  HERE.
                </a>
              </div>
            )}

            <div className='form-shared-code'>
              <div className='shared-code'>
                {shareCodeInputs.map((item) => (
                  <>
                    {item.isPrefix && <div className='prefix' />}
                    <FormItem
                      key={item.name}
                      rules={item.rules}
                      name={item.name}
                      normalize={item.normalize}
                      required
                    >
                      <Input maxLength={item.maxLength} />
                    </FormItem>
                  </>
                ))}
              </div>
            </div>

            <div className='caption'>
              Does your visa / right to work in the UK have any limitations or
              special requirements e.g. do you hold a student visa which limits
              you to working 20 hours per week or do you hold a visa which only
              allows you to work within certain specific employment sectors.
            </div>

            <FormItem name='limitVisa'>
              <Radio.Group name='limitVisa' className='recruitment-radio m-2 '>
                <Radio value={1}>
                  <span>Yes</span>
                </Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </FormItem>

            {isLimitationsOrSpecialRequirementsVisa && (
              <>
                <div className='caption'>
                  Are you on a limited 20 hours student visa?
                </div>

                <FormItem name={['omniEmployeeAttributes', 'employeeStudent']}>
                  <Radio.Group name='hours' className='recruitment-radio m-2'>
                    <Radio value={1}>
                      <span>Yes</span>
                    </Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </FormItem>
              </>
            )}

            {isHours !== undefined && isHours.employeeStudent === false && (
              <>
                <div className='caption'>
                  Please enter the type of restriction on your visa.
                </div>

                <FormItem
                  className='type-of-restriction'
                  required
                  name='restriction'
                >
                  <Input placeholder='Type of restriction on your visa' />
                </FormItem>
              </>
            )}
          </>
        )}

        <div className='form-national-number'>
          <span>National Insurance Number</span>
          <div className='ni'>
            {niItems.map((item) => (
              <FormItem
                key={item.name}
                required
                rules={item.rules}
                name={item.name}
                normalize={item.normalize}
              >
                <Input maxLength={item.maxLength} disabled={hasNi} />
              </FormItem>
            ))}
          </div>

          <FormItem
            className='input-container'
            name='hasNi'
            valuePropName='checked'
          >
            <Checkbox
              onChange={disableInsurenceNumber}
              className='recruitment-checkbox'
              label='I do not have a national insurance number'
            />
          </FormItem>
        </div>
        <FormItem
          className='input-container'
          name='marital'
          rules={[
            {
              required: true,
              message: 'Please select your marial status',
            },
          ]}
          label='Marital Status'
        >
          <Select options={MARITAL} placeholder='Marital status' required />
        </FormItem>

        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

AboutYou2.propTypes = {
  form: PropTypes.any,
  nextStep: PropTypes.func,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default AboutYou2;
