/* eslint-disable max-len */
import { omit } from 'lodash';
import moment from 'moment';

import { INDUSTRY } from '@/constants';
import { PAYMENT_METHOD } from '@/pages/RecruitmentForm/constant';
import request from '@/request';

import { getAddressInfomation } from '../getAddressInfomation';

const updateCandidate = async ({ id, values }) => {
  const zip = values?.zip;
  const {
    ni1,
    ni2,
    ni3,
    ni4,
    ni5,
    birth,
    code1,
    code2,
    code3,
    vacancyApplicantReferencesAttributes,
    industryExperienceOther,
    omniEmployeeAttributes,
  } = values;
  const ni = ni1 ? `${ni1}${ni2}${ni3}${ni4}${ni5}` : '';
  const shareCode = code1 + code2 + code3;

  const geogecode = await getAddressInfomation(`${zip}-uk`);
  const { location } = geogecode[0].geometry;
  const { lat, lng } = location;

  let vacancyApplicantLanguagesAttributes = [
    ...values.vacancyApplicantLanguagesAttributes,
    values.vacancyApplicantLanguageMainAttributes,
  ];

  let destroyVacancyApplicantLanguages = null;
  if (values.vacancyApplicantLanguages) {
    destroyVacancyApplicantLanguages = values.vacancyApplicantLanguages
      .filter(
        (item) =>
          !vacancyApplicantLanguagesAttributes.find(
            (language) => language.id === item.id,
          ),
      )
      .map((item) => ({ ...item, _destroy: true }));
  }

  if (destroyVacancyApplicantLanguages) {
    vacancyApplicantLanguagesAttributes = [
      ...vacancyApplicantLanguagesAttributes,
      ...destroyVacancyApplicantLanguages,
    ];
  }

  const formatDate = (date) => moment(date).format('YYYY-MM-DD');

  let { industryExperiencesVacancyApplicantsAttributes } = values;
  industryExperiencesVacancyApplicantsAttributes =
    industryExperiencesVacancyApplicantsAttributes?.map(
      (/** @type {string|number} */ item) => ({
        industry_experience_id: item,
        otherValue: item === INDUSTRY.OTHER ? industryExperienceOther : '',
      }),
    );

  const reference = vacancyApplicantReferencesAttributes?.map((item) =>
    omit(item, 'isStillWork'),
  );

  const { employeeGender, employeePaymentMethod } = omniEmployeeAttributes;

  const parseValues = {
    ...values,
    omniEmployeeAttributes: {
      employeeGender,
      employeePaymentMethod: PAYMENT_METHOD[employeePaymentMethod],
    },
    ni,
    locationAttributes: {
      latitude: lat,
      longitude: lng,
    },
    birth: birth && formatDate(birth),
    preferredRoles: values.preferredRoles?.join(','),
    vacancyApplicantEmploymentsAttributes: values.vacancyApplicantEmployments,
    vacancyApplicantReferencesAttributes: reference,
    vacancyApplicantLanguagesAttributes,
    industryExperiencesVacancyApplicantsAttributes,
    shareCode,
    isCandidate: true,
  };

  await request.patch(`/guests/candidates/${id}`, {
    applicant: parseValues,
  });
};

export default updateCandidate;
