/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  FormItem,
  Input,
  Radio,
  DatePicker,
  Select,
  DatePickerInline,
  Checkbox,
} from '@/components';
import { DEBOUNCE_WAIT } from '@/constants';

import Shifts from './component/Shifts';
import { OPTIONS_TYPE_SHIFTS, TYPE_SHIFTS, checkErrorShift } from './constant';

const DatesAndTimes = ({ disabled, status }) => {
  const form = Form.useFormInstance();

  const schedule = Form.useWatch(['schedule', 'items'], form) ?? [];
  const repeatFor = Form.useWatch('repeatFor', form) ?? [];
  const untilEndDate = Form.useWatch('untilEndDate', form);
  const shiftType = Form.useWatch('shiftType', form);
  const checkboxRepeat = Form.useWatch('checkboxRepeat', form) ?? 0;
  const posted = Form.useWatch('posted', form) ?? 0;

  const [listRepeat, setListRepeat] = useState([]);

  useEffect(() => {
    debounceUpdateListRepeat(checkboxRepeat, untilEndDate, repeatFor);
  }, [checkboxRepeat, untilEndDate, repeatFor]);

  const onChangeCheckboxRepeat = (value) => {
    if (value === checkboxRepeat) {
      form.setFieldsValue({
        checkboxRepeat: 0,
      });
      debounceUpdateListRepeat(0, untilEndDate, repeatFor);
    } else {
      form.setFieldsValue({
        checkboxRepeat: parseInt(value, 10),
      });

      if (value === 1) {
        debounceUpdateListRepeat(1, undefined, repeatFor);
      } else {
        debounceUpdateListRepeat(2, untilEndDate, undefined);
      }
    }
  };

  const onChangeShifts = (items) => {
    form.setFieldsValue({
      schedule: {
        items,
      },
    });
    const seekersPayTemplates = form.getFieldValue('seekersPayTemplates') ?? [];

    const errors = checkErrorShift(items, seekersPayTemplates);

    form.setFields([
      {
        name: 'items',
        errors,
      },
    ]);
  };

  const debounceUpdateListRepeat = debounce(
    (checkBox, endDate, repeat) =>
      onUpdateListRepeat(checkBox, endDate, repeat),
    DEBOUNCE_WAIT,
  );

  const handleChangeShiftType = (event) => {
    if (event.target.value === TYPE_SHIFTS.INDIVIDUAL) {
      const items = schedule.map((e) => ({
        ...e,
        posted,
      }));

      form.setFieldsValue({
        schedule: {
          items,
        },
      });
    }
  };

  const onUpdateListRepeat = (checkBox, endDate, repeat) => {
    if (checkBox === 1) {
      if (!repeat) {
        setListRepeat([]);
      } else {
        const listRepeatUpdate = [];
        schedule.forEach((element) => {
          for (let index = 1; index <= repeat; index += 1) {
            const startAt = moment(element.startAt);

            listRepeatUpdate.push(
              startAt.add(index * 7, 'd').format('YYYY-MM-DD'),
            );
          }
        });

        setListRepeat(listRepeatUpdate);
      }
    } else if (checkBox === 2 && endDate) {
      const listRepeatUpdate = [];

      const endDateMoment = moment(endDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      const schedulesStart = schedule.map((e) => e.startAt);
      schedulesStart.forEach((startAt) => {
        const startAtMoment = moment(startAt).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        while (endDateMoment.diff(startAtMoment, 'w') > 0) {
          startAtMoment.add(1, 'week');

          listRepeatUpdate.push(startAtMoment.format('YYYY-MM-DD'));
        }
      });

      setListRepeat(listRepeatUpdate);
    } else {
      setListRepeat([]);
    }
  };

  const onSelectDatePicker = (date) => {
    const scheduleUpdate = [...schedule];
    const lastSchedule = scheduleUpdate.lastItem;
    const endMoment = moment(lastSchedule.endAt);
    const startMoment = moment(lastSchedule.startAt);

    const diffMoment = endMoment.diff(startMoment, 'minute');
    const startAt = moment(date).set({
      hour: startMoment.hour(),
      minute: startMoment.minute(),
      second: 0,
      millisecond: 0,
    });
    const endAt = moment(startAt).add(diffMoment, 'minute');
    scheduleUpdate.push({
      startAt: startAt.valueOf(),
      endAt: endAt.valueOf(),
      break: lastSchedule.break,
      posted: lastSchedule.posted,
    });
    onChangeShifts(scheduleUpdate);
  };

  const disabledDate = (current) =>
    current && current < moment().startOf('day');

  return (
    <div className='date-and-time row-container'>
      <Row gutter={20}>
        <Col span={8}>
          <FormItem name={['schedule', 'items']} noStyle />
          <DatePickerInline
            showToday={false}
            alwaysShow
            listSelected={schedule.map((e) =>
              moment(e.startAt).format('YYYY-MM-DD'),
            )}
            onDateClick={
              disabled || checkboxRepeat !== 0 ? null : onSelectDatePicker
            }
            disabledDate={disabledDate}
            disabled={disabled || checkboxRepeat !== 0}
            listSecond={listRepeat}
            value={schedule[0]?.startAt}
          />
        </Col>
        <Col span={16}>
          <Shifts
            items={schedule}
            onChange={onChangeShifts}
            disablePosted={shiftType === 'group_shift'}
            disabled={disabled || checkboxRepeat !== 0}
          />
        </Col>
      </Row>
      <Row className='type-shifts-row'>
        <Col span={8}>
          <FormItem name='shiftType'>
            <Radio
              options={OPTIONS_TYPE_SHIFTS}
              disabled={disabled}
              name='shiftType'
              onChange={handleChangeShiftType}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        {!!checkboxRepeat && (
          <div>If you check repeat, you can&apos;t edit shift</div>
        )}
      </Row>
      <Row className='bottom-row'>
        <FormItem name='checkboxRepeat' noStyle />
        <Col span={14}>
          <Row gutter={15} className='repeat-row'>
            <Col span={1}>
              <Checkbox
                checked={checkboxRepeat === 1}
                onChange={() => onChangeCheckboxRepeat(1)}
                disabled={disabled}
              />
            </Col>
            <Col span={4}>Repeat for</Col>
            <Col span={3}>
              <FormItem name='repeatFor' className='repeat-number'>
                <Input
                  disabled={disabled || checkboxRepeat !== 1}
                  onChange={(e) =>
                    debounceUpdateListRepeat(
                      1,
                      undefined,
                      parseInt(e.target.value, 10),
                    )
                  }
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem name='repeatType' className='repeat-type'>
                <Select
                  options={[
                    {
                      label: 'Weeks',
                      value: 'weeks',
                    },
                  ]}
                  disabled
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={15} className='end-date-row'>
            <Col span={1}>
              <Checkbox
                checked={checkboxRepeat === 2}
                onChange={() => onChangeCheckboxRepeat(2)}
                disabled={disabled}
              />
            </Col>
            <Col span={4}>Or until</Col>
            <Col span={3}>End Date</Col>

            <Col span={5}>
              <FormItem name='untilEndDate'>
                <DatePicker
                  suffixIcon={null}
                  format='DD MMM'
                  name='untilEndDate'
                  disabled={disabled || checkboxRepeat !== 2}
                  onChange={(value) =>
                    debounceUpdateListRepeat(2, value, undefined)
                  }
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

DatesAndTimes.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.string,
};

DatesAndTimes.defaultProps = { disabled: false, status: '' };

export default DatesAndTimes;
