const ADDITIONAL_TYPES = {
  labour: {
    key: 'labour',
    label: 'Hours',
  },
  cost: {
    key: 'cost',
    label: 'Cost',
  },
};

const EMPTY_SECTIONS = {
  hours: 0,
  omniVat: 0,
  omniRate: 0,
  omniNetPay: 0,
};

const VAT_PERCENT = 0.2;

export { ADDITIONAL_TYPES, EMPTY_SECTIONS, VAT_PERCENT };
