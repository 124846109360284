import React, { useState } from 'react';

import { Table, Form, Radio, message } from 'antd';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button, FormItem, Textarea } from '@/components';
import changeEvaluation from '@/services/candidateService/changeEvaluation';

import { FORM_TYPES } from '../constants';
import { columnsComment } from './constants';

const rating = [
  {
    content: (
      <>
        A<sup>*</sup>
      </>
    ),
    color: '#6EB02E',
    value: 'a-start',
  },
  {
    content: 'A',
    color: '#8BC456',
    value: 'a',
  },
  {
    content: 'B',
    color: '#FECE57',
    value: 'b',
  },
  {
    content: 'C',
    color: '#FC957C',
    value: 'c',
  },
  {
    content: 'D',
    color: '#E66960',
    value: 'd',
  },
];

const Comments = () => {
  const { id } = useParams();
  const formInstance = Form.useFormInstance();
  const [comment, setComment] = useState(null);
  const listComment = Form.useWatch('comments', formInstance) ?? [];
  const evaluation = Form.useWatch('evaluation', formInstance) ?? [];
  const formType = Form.useWatch('formType', formInstance);

  const isCreateCandidate = formType === FORM_TYPES.create;

  const { mutate: mutateChangeEvaluation } = useMutation(changeEvaluation, {
    onSuccess: () => message.success('Changed evaluation successfully'),
    onError: (error) =>
      message.error(error?.message ?? 'Can not change evaluation'),
  });

  const handleAddComment = () => {
    if (!comment || (listComment && listComment?.length >= 5)) return;

    formInstance.setFieldsValue({
      comments: [
        {
          content: comment,
        },
        ...listComment,
      ],
    });

    setComment(null);
  };

  const handleChangeEvaluation = ({ target }) =>
    mutateChangeEvaluation({
      evaluationText: target.value,
      candidateId: id,
    });

  return (
    <div>
      <FormItem label='Comments' required>
        <Textarea
          rows={7}
          value={comment}
          placeholder='Add comment here'
          onChange={(e) => setComment(e.target.value)}
          maxLength={Number.POSITIVE_INFINITY}
        />
      </FormItem>
      <div className='wrap-btn'>
        <Button className='btn-req' onClick={handleAddComment}>
          Add Comments
        </Button>
      </div>
      <FormItem
        name='comments'
        label='History'
        rules={[
          {
            required: true,
            message: 'Please add comments history',
          },
        ]}
      >
        <Table
          className='table-comments'
          dataSource={listComment}
          columns={columnsComment}
          pagination={{ hideOnSinglePage: true }}
        />
      </FormItem>

      <div className='rating'>
        <FormItem name='evaluation' label='Rating'>
          <Radio.Group
            value={evaluation}
            {...(!isCreateCandidate && { onChange: handleChangeEvaluation })}
          >
            {rating.map((item) => (
              <Radio value={item.value} style={{ backgroundColor: item.color }}>
                {item.content}
              </Radio>
            ))}
          </Radio.Group>
        </FormItem>
      </div>
    </div>
  );
};

export default Comments;
