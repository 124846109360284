import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const MyTimeSlotWrapper = ({ children }) => (
  <div className='slot-wrapper'>{children}</div>
);
MyTimeSlotWrapper.propTypes = {
  children: PropTypes.any,
};

export default MyTimeSlotWrapper;
