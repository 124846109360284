import React, { useState } from 'react';

import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from '@/components';

import { EXPAND_LABEL } from '../../../constants';
import PanelItem from './PanelItem';

const ListJobTypeStatus = ({ data, status, statusColor, isHourlyTemplate }) => {
  const [activePanels, setActivePanels] = useState([]);

  const panelIds = map(data, 'timesheetDetailId');
  const isExpandAll = activePanels.length === panelIds.length;
  const exPandLabel = isExpandAll ? EXPAND_LABEL.collapse : EXPAND_LABEL.expand;

  const handleExpand = () => {
    if (isExpandAll) {
      setActivePanels([]);
    } else {
      setActivePanels(panelIds);
    }
  };
  return (
    <>
      {!isEmpty(data) &&
        data.map((panelInfo) => (
          <PanelItem
            status={status}
            panelInfo={panelInfo}
            statusColor={statusColor}
            activePanels={activePanels}
            setActivePanels={setActivePanels}
            isHourlyTemplate={isHourlyTemplate}
          />
        ))}
      <Button className='expand-button' onClick={handleExpand}>
        {exPandLabel}
      </Button>
    </>
  );
};

export default ListJobTypeStatus;

ListJobTypeStatus.propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  isHourlyTemplate: PropTypes.bool,
};

ListJobTypeStatus.defaultProps = {
  data: [],
  status: '',
  statusColor: '',
  isHourlyTemplate: false,
};
