export const initValues = [
  {
    dayOfWeek: 'M',
  },
  {
    dayOfWeek: 'T',
  },
  {
    dayOfWeek: 'W',
  },
  {
    dayOfWeek: 'T',
  },
  {
    dayOfWeek: 'F',
  },
  {
    dayOfWeek: 'S',
  },
  {
    dayOfWeek: 'S',
  },
];

export const daysOfWeek = [
  { label: 'M', dataIndex: 'monday' },
  { label: 'T', dataIndex: 'tuesday' },
  {
    label: 'W',
    dataIndex: 'wednesday',
  },
  { label: 'T', dataIndex: 'thursday' },
  { label: 'F', dataIndex: 'friday' },
  {
    label: 'S',
    dataIndex: 'saturday',
  },
  {
    label: 'S',
    dataIndex: 'sunday',
  },
];

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const payrollTemplateAttr = 'payrollTemplateRuleAttributes';

export const times = [
  { label: '00:00', value: 0 },
  { value: 1 },
  { value: 2 },
  { label: '03:00', value: 3 },
  { value: 4 },
  { value: 5 },
  { label: '06:00', value: 6 },
  { value: 7 },
  { value: 8 },
  { label: '09:00', value: 9 },
  { value: 10 },
  { value: 11 },
  { label: '12:00', value: 12 },
  { value: 13 },
  { value: 14 },
  { label: '15:00', value: 15 },
  { value: 16 },
  { value: 17 },
  { label: '18:00', value: 18 },
  { value: 19 },
  { value: 20 },
  { label: '21:00', value: 21 },
  { value: 22 },
  { label: '23:59', value: 23 },
];

export const START_OF_DAY = 0;
export const END_OF_DAY = 23.99;

export const renderDay = daysOfWeek.map((day) => ({
  title: day.label,
  dataIndex: day.dataIndex,
  width: '4%',
}));

export const columns = [
  {
    children: [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '15%',
      },
    ],
  },
  {
    children: [
      {
        title: 'Shift Times',
        dataIndex: 'shiftTimes',
      },
    ],
  },
  {
    title: 'Breakdown of total hours by day',
    children: [
      {
        title: 'Start',
        dataIndex: 'start',
      },
      {
        title: 'End',
        dataIndex: 'end',
      },
      {
        title: 'Time in pay template',
        children: [
          {
            dataIndex: 'timeInPayTemplate',
          },
          {
            dataIndex: 'unit',
          },
        ],
      },
      {
        title: 'Pay',
        dataIndex: 'pay',
        width: '4%',
      },
      {
        title: 'Charge',
        dataIndex: 'charge',
        width: '4%',
      },
      {
        title: 'Template',
        dataIndex: 'templateName',
        width: '10%',
      },
      {
        title: 'Pay',
        dataIndex: 'totalPay',
        width: '4%',
      },
      {
        title: 'Charge',
        dataIndex: 'totalCharge',
        width: '4%',
      },
    ],
  },
  {
    title: 'Amendemnts for Breaks',
    children: [
      {
        title: 'break',
        dataIndex: 'Break',
        width: '4%',
      },
      {
        title: 'Time',
        dataIndex: 'time',
        width: '4%',
      },
      {
        title: 'Pay',
        dataIndex: 'pay',
        width: '4%',
      },
      {
        title: 'Charge',
        dataIndex: 'charge',
        width: '4%',
      },
    ],
  },
];

export const hourlyColumns = [
  {
    children: [
      {
        title: 'Name',
        dataIndex: 'name',
      },
    ],
  },
  {
    title: 'Breakdown of total hours by day',
    children: renderDay,
  },
  {
    children: [
      {
        title: 'Charge',
        dataIndex: 'charge',
      },
      {
        title: 'Hours',
        dataIndex: 'hours',
      },
    ],
  },
  {
    title: 'Breaks',
    children: [
      {
        title: 'Hours',
        dataIndex: 'breakHours',
      },
      {
        title: 'Pay',
        dataIndex: 'breakPay',
      },
      {
        title: 'Charge',
        dataIndex: 'breakCharge',
      },
    ],
  },
];

export const templateRow = {
  templateName: null,
  startAt: null,
  endAt: null,
  pay: 0,
  charge: 0,
  payOT: 0,
  chargeOT: 0,
};

export const totalCellNames = [
  'shiftTimes',
  'startAt',
  'endAt',
  'hours',
  'break',
  'totalHours',
  'pay',
  'charge',
  'totalHoursSubTotalBreak',
  'totalPay',
  'totalCharge',
];

export const weeklyTotalCellNames = [
  'startAt',
  'endAt',
  'hours',
  'totalHours',
  'totalHoursSubTotalBreak',
  'pay',
  'charge',
];

export const cellColors = [
  'totalTime',
  'pay',
  'charge',
  'totalHours',
  'totalHoursSubTotalBreak',
  'totalPay',
  'totalCharge',
  'totalHours',
  'break',
];

export const allocateWorkersModalFields = [
  'provider',
  'locations',
  'searchSeeker',
  'templateName',
  'appliedSeekers',
];

export const statuses = [
  'unapproved',
  'approved',
  'confirmed',
  'finalised',
  'paid',
];

export const STATUS_UPDATE_TYPE = {
  unapproved: {
    status: 'unapproved',
    action: 'approved',
    contentOnHeader: 'Approve Hours',
  },
  approved: {
    action: 'confirmed',
    contentOnHeader: 'Confirm Hours',
    status: 'approved',
  },
  confirmed: {
    status: 'confirmed',
    contentOnHeader: 'Confirmed',
    action: 'confirmed',
  },
  finalised: {
    status: 'finalised',
    contentOnHeader: 'Finalised',
    action: 'paid',
  },
  paid: { status: 'paid', contentOnHeader: 'Paid', action: null },
};

export const STATUS_UPDATE_TYPE_PAYROLL = {
  confirmed: {
    status: 'confirmed',
    contentOnHeader: 'Finalise',
    action: 'finalised',
  },
  finalised: {
    status: 'finalised',
    contentOnHeader: 'Finalised',
    action: null,
  },
  paid: { status: 'paid-disable', contentOnHeader: 'Paid', action: null },
};

export const STATUS_CONTENTS = {
  unapproved: 'Approve Hours',
  approved: 'Confirm Hours',
  confirmed: 'Confirmed',
  finalised: 'Finalised',
  paid: 'Paid',
};

export const hourlyDataIndex = [
  'dayOfWeek',
  'shiftTimes',
  'startAt',
  'endAt',
  'totalHours',
  'totalTimeUnit',
  'payRate',
  'chargeRate',
  'templateName',
  'payTemplateName',
  'totalPay',
  'totalCharge',
  'break',
  'time',
  'totalHoursSubTotalBreak',
  'pay',
  'charge',
];

export const defaultDataIndex = [
  'name',
  'dayInfo_monday',
  'dayInfo_tuesday',
  'dayInfo_wednesday',
  'dayInfo_thursday',
  'dayInfo_friday',
  'dayInfo_saturday',
  'dayInfo_sunday',
  'templateName',
  'payTemplateName',
  'payRate',
  'chargeRate',
  'totalHours',
  'break',
  'time',
  'totalHoursSubTotalBreak',
  'pay',
  'charge',
];
export const timeFieldsToUpdate = [
  'overtime_paid_after',
  'guaranteed_minimum_shift_length',
  'guaranteed_minimum_weekly_hours',
];

export const STATUS_TO_ACTION_PAYROLL = {
  confirmed: {
    label: 'Finalise',
    className: 'finalise',
    actionType: 'finalise',
  },
  finalised: { label: 'Finalised' },
};

export const STATUS_TO_ACTION = {
  unapproved: {
    actionType: 'approved',
    label: 'Approve Hours',
    className: 'approve',
  },
  approved: {
    actionType: 'confirmed',
    label: 'Confirm Hours',
    className: 'confirm',
  },
  confirmed: {
    label: 'Finalise',
    actionType: 'finalised',
    className: 'finalise',
  },
  finalised: {
    label: 'Finalised',
    className: 'finalised',
  },
  deletable: {
    actionType: 'deleteHours',
    label: 'Delete Hours',
    className: 'delete',
  },
  editable: {
    actionType: 'edit',
    label: 'Edit',
    className: 'edit',
  },
  cancel: {
    actionType: 'cancel',
    label: 'Cancel',
    className: 'edit',
  },
  save: {
    actionType: 'save',
    label: 'Save',
    className: 'save',
  },
};

export const ERROR_TEMPLATE_COVER =
  'The template must cover all 24 hour periods across 7 days. Please update the template to ensure all hours are covered';

export const MIN_PAY_RATE = 11.44;

export const ERROR_PAY_RATE = `Value must be greater than ${MIN_PAY_RATE}`;

export const OPACITY_COLORS_TIMESHEET = 0.1;

export const BREAK_DEDUCTED_OPTIONS = [
  {
    value: 0,
    label: 'beginning',
  },
  {
    value: 1,
    label: 'end',
  },
];
