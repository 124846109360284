import moment from 'moment';

import { VACANCY_APPLICANT_EMPLOYMENT_POSITION_DEFAULT } from '@/constants';
import request from '@/request';

import { getAddressInfomation } from '../getAddressInfomation';

/**
 * @function editApplicant
 *
 * @param {date} newApplicant.applicantDate,
 * @param {bool} newApplicant.awayInclude,
 * @param {string} newApplicant.email,
 * @param {string} newApplicant.firstname,
 * @param {string} newApplicant.generalRole,
 * @param {string} newApplicant.internalNote,
 * @param {string} newApplicant.lastname,
 * @param {number} newApplicant.officeId,
 * @param {string} newApplicant.otherRolesSuitableFor,
 * @param {number} newApplicant.phone,
 * @param {string} newApplicant.status,
 * @param {string} newApplicant.tellUs,
 * @param {string} newApplicant.zip,
 * @param {string} newApplicant.cvUpload,
 * @param {array} newApplicant.preferredRoles,
 * @param {string} newApplicant.leave,
 * @param {files} listFileUpload
 * @param {number} idLeave
 * @param {number} applicantID
 * @returns
 */
export const editApplicant = async (
  newApplicant,
  listFileUpload,
  idLeave,
  applicantID,
) => {
  const { cvUpload, preferredRoles, leave, zip } = newApplicant;
  const geogecode = await getAddressInfomation(`${zip}-uk`);

  const { geometry } = geogecode[0];
  const resData = await request.patch(
    `/recruitments/applicants/${applicantID}`,
    {
      applicant: {
        ...newApplicant,
        preferredRoles: preferredRoles?.toString(),
        cvUpload: listFileUpload?.cvUpload || cvUpload,
        vacancyApplicantEmploymentsAttributes: [
          {
            leave,
            id: idLeave,
            pos: '',
            startAt: moment().format('DD/MM/YYYY'),
            finishAt: moment().format('DD/MM/YYYY'),
            name: '',
            town: '',
            country: '',
            isVerifyExperience: 0,
            positionId: newApplicant.generalRole,
          },
        ],
        locationAttributes: {
          latitude: geometry.location.lat,
          longitude: geometry.location.lng,
        },
      },
    },
  );
  return resData;
};
