import React from 'react';

import { Form, Popover } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Textarea } from '@/components';

import './styles.scss';
import { TYPES_SICK_NO_SHOW } from '../../constants';

const TitlePopover = ({ type, title }) => (
  <div className={`comment-popover-title-container ${type}`}>
    <span>{title}</span>
  </div>
);

const BodyPopover = ({ skillId, timecardId, handleEdit, isLoading }) => (
  <div className='comment-popover-body-container'>
    <FormItem name={[skillId, timecardId, 'comment', 'comment_id']} hidden />
    <FormItem
      name={[skillId, timecardId, 'comment', 'assigned_schedule_id']}
      hidden
    />
    <FormItem name={[skillId, timecardId, 'comment', 'content']}>
      <Textarea
        placeholder='Example: Worker sent sick note to confirm'
        disabled={isLoading}
      />
    </FormItem>
    <Button
      className='yellow-button custom-button'
      loading={isLoading}
      onClick={() => handleEdit({ skillId, timecardId })}
    >
      Edit
    </Button>
  </div>
);

const CommentPopover = ({
  isOpen,
  setIsOpen,
  type,
  title,
  skillId,
  timecardId,
  handleEdit,
  isLoading,
  children,
  originalContent,
  ...props
}) => {
  const formInstance = Form.useFormInstance();

  const handleOpenChange = (opened) => {
    if (!opened) {
      const contentField = [skillId, timecardId, 'comment', 'content'];
      formInstance.setFields([{ name: contentField, value: originalContent }]);
    }
    setIsOpen(opened);
  };
  return (
    <Popover
      className='comment-popover'
      title={<TitlePopover type={type} title={title} />}
      visible={isOpen}
      content={
        <BodyPopover
          skillId={skillId}
          timecardId={timecardId}
          handleEdit={handleEdit}
          isLoading={isLoading}
        />
      }
      onVisibleChange={(opened) => handleOpenChange(opened)}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default CommentPopover;

CommentPopover.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  originalContent: PropTypes.string,
  skillId: PropTypes.number,
  timecardId: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  handleEdit: PropTypes.func,
  isLoading: PropTypes.bool,
  isSucceed: PropTypes.bool,
};

CommentPopover.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  originalContent: '',
  skillId: null,
  timecardId: null,
  type: TYPES_SICK_NO_SHOW.sick,
  title: '',
  children: null,
  handleEdit: () => {},
  isLoading: false,
  isSucceed: false,
};

TitlePopover.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
};

TitlePopover.defaultProps = {
  type: TYPES_SICK_NO_SHOW.sick,
  title: '',
};

BodyPopover.propTypes = {
  skillId: PropTypes.number,
  timecardId: PropTypes.number,
  handleEdit: PropTypes.func,
  isLoading: PropTypes.bool,
};

BodyPopover.defaultProps = {
  handleEdit: () => {},
};
