import React from 'react';

import { Form } from 'antd';

import { FormItem, Input } from '@/components';

import ResultCard from '../../ResultCard';
import HoursSectionItem from './HoursSectionItem';

import './styles.scss';

const HourSections = () => (
  <div className='section-container'>
    <div className='description-container'>
      <FormItem
        name='labourDesc'
        label='Description'
        className='large-width'
        required
      >
        <Input placeholder='Description' />
      </FormItem>
    </div>
    <div className='hour-inputs'>
      <Form.List name='labour'>
        {(fields, { remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className='hour-section-container'>
                <HoursSectionItem name={name} remove={remove} />
                <ResultCard name={name} />
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  </div>
);

export default HourSections;
