import { useQuery } from 'react-query';

import { fetchSuppliers } from '@/services/dropdownServices/getSuppliers';

const useSuppliers = () => {
  const { data } = useQuery(['suppliers'], fetchSuppliers, {
    refetchOnWindowFocus: false,
    initialData: [],
  });

  return data;
};

export default useSuppliers;
