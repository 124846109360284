import React, { useState, useEffect } from 'react';

import { Form, Input, Table } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const TableMedical = ({ dataSource, setDataMedical }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey] = useState('');

  useEffect(() => {
    if (!isEmpty(dataSource)) {
      setData(dataSource);
    }
  }, [dataSource]);

  const isEditing = (record) => record.key === editingKey;

  const editableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );

  const columns = [
    {
      title: 'Medical Condition',
      dataIndex: 'medicalCondition',
      width: '25%',
      editable: false,
      render: (text) => <div className='block-box'>{text}</div>,
    },
    {
      title: 'Medication',
      dataIndex: 'medication',
      width: '25%',
      editable: false,
      render: (text) => <div className='block-box'>{text}</div>,
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      width: '25%',
      editable: false,
      render: (text) => <div className='block-box'>{text}</div>,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        className='table-medical-conditions'
        components={{
          body: {
            cell: editableCell,
          },
        }}
        dataSource={data}
        columns={mergedColumns}
        pagination={{ hideOnSinglePage: true }}
      />
    </Form>
  );
};

export default TableMedical;

TableMedical.propTypes = {
  dataSource: PropTypes.array,
  setDataMedical: PropTypes.func,
};
