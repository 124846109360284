import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const EventContainerWrapper = ({
  children,
  view,
  postRotaMutate,
  formDataArray,
  saveAsDraftMutate,
}) => <div>{children}</div>;

EventContainerWrapper.propTypes = {
  children: PropTypes.any,
  view: PropTypes.string,
  postRotaMutate: PropTypes.func,
  formDataArray: PropTypes.array,
  saveAsDraftMutate: PropTypes.func,
};
EventContainerWrapper.defaultProps = {
  view: '',
  postRotaMutate: () => {},
  formDataArray: [],
  saveAsDraftMutate: () => {},
};
export default EventContainerWrapper;
