import getLocationsByProviderId from '@/services/provider/getLocationsByProviderId';

import useFetch from '../useFetch';

const useProviderLocations = (providerId) =>
  useFetch(
    ['provider-location', providerId],
    () => getLocationsByProviderId(providerId),
    {
      enabled: !!providerId,
    },
  );

export default useProviderLocations;
