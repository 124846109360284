import { sumBy } from 'lodash';

const calTotal = (sections = []) => {
  const totalNet = sumBy(sections, (section) =>
    Number(section.omniNetPay ?? 0),
  );
  const totalVat = sumBy(sections, (section) => Number(section.omniVat ?? 0));
  const totalInvoice = totalNet + totalVat;
  return {
    totalVat,
    totalNet,
    totalInvoice,
  };
};

export { calTotal };
