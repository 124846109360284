import request from '@/request';

/**
 * Update status for timecards in a timesheet.
 *
 * @typedef {Object} UpdateStatusParams
 * @property {number | string} timesheetId - The ID of the timesheet.
 * @property {number[] | string[]} timecardIds - An array of timecard IDs.
 * @property {string} status - The new status for the timecards.
 *
 * @typedef {Object} UpdateStatusResponse
 * @property {*} data - The response data from the server.
 *
 * @param {UpdateStatusParams} params - The parameters for the updateStatus function.
 * @returns {Promise<UpdateStatusResponse>} A promise that resolves to the response data.
 */
const updateStatus = async ({ timesheetId, timecardIds, status }) => {
  const res = await request.patch(
    `trackers/timekeeping/timesheets/${timesheetId}/timecards/update_status`,
    {
      timecardIds,
      status,
    },
  );
  return res?.data;
};

export default updateStatus;
