import React from 'react';

import PropTypes from 'prop-types';

import Timecard from '@/pages/PayTemplate/Payroll/components/Timecard';

import payrollPayTemplateColumns from './payrollPayTemplateColumns';

const HoursPerDay = ({ hoursPerDayData, isEdit, panelId }) => {
  const columns = payrollPayTemplateColumns.genDefaultColumns({
    isEdit,
    panelId,
    timecard: hoursPerDayData,
  });

  return <Timecard inforDetail={hoursPerDayData} columns={columns} />;
};

export default HoursPerDay;

HoursPerDay.propTypes = {
  hoursPerDayData: PropTypes.object,
  isEdit: PropTypes.bool,
  panelId: PropTypes.number,
};

HoursPerDay.defaultProps = {
  hoursPerDayData: null,
  isEdit: false,
  panelId: null,
};
