const statuses = [
  {
    value: 0,
    label: 'Not Approved',
    desc: 'No action token',
    color: 'red',
  },
  {
    value: 1,
    label: 'Approved',
    desc: 'Timesheets approved in WebApp',
    color: 'yellow',
  },
  {
    value: 2,
    label: 'Comfirmed',
    desc: 'Timesheets comfirmed for payroll',
    color: 'green',
  },
  {
    value: 3,
    label: 'Paid',
    desc: 'Payroll has been processed and paid',
    color: 'green',
  },
  {
    value: 4,
    label: 'Pushed',
    desc: 'Timesheet has been pushed',
    color: 'gray',
  },
  {
    value: 5,
    label: 'Sick - No show',
    desc: 'Mark as Blank',
    color: 'gray',
  },
];

export { statuses };
