import React, { useState } from 'react';

import { Form } from 'antd';
import { find, get, map, max, min } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Views, momentLocalizer } from 'react-big-calendar';

import { CustomCalendar, FormItem, ModalConfirm } from '@/components';
import { STATUS_FILTER_DROPDOWN_OPTIONS } from '@/constants';
import { useFetch } from '@/hooks';
import { JobServices } from '@/services';
import { processDate } from '@/services/jobServices';

import CheckinCheckoutJobsDrawer from '../../CheckinCheckoutJobsDrawer';
import { CalendarOptions } from '../../components';
import { filterJobByStatus } from '../../components/JobListView/status';
import { FORM_NAME } from '../constants';
import customCalendarView from './customCalendarView';

import './styles.scss';

const momentLocalized = momentLocalizer(moment);

/**
 * @param {{
 * setRepostId: Function,
 * setJobIdEdit: Function,
 * setShowPopupCreate: Function,
 * openManageWorkerModal: Function,
 * rowId: number | string
 * isCompletedTab: boolean
 * providers: Array
 * locations: Array
 * allFetched: boolean
 * }} param
 */

const CalendarView = ({
  setRepostId,
  setJobIdEdit,
  setShowPopupCreate,
  openManageWorkerModal,
  rowId,
  isCompletedTab,
  providers,
  locations,
  allFetched,
}) => {
  const [form] = Form.useForm();
  const [showAllEvents, setShowAllEvents] = useState(false);

  const viewMode = Form.useWatch('viewMode', form);
  const isMothlyView = viewMode === Views.MONTH;
  const initStartDate = isMothlyView
    ? moment().startOf('month')
    : moment().startOf('week');
  const initEndDate = isMothlyView
    ? moment().endOf('month')
    : moment().endOf('week');
  const startDateWatching = Form.useWatch('startDate', form);
  const startDateString = moment(startDateWatching).format('YYYY-MM-DD');

  const endDateWatching = Form.useWatch('endDate', form);
  const endDateString = moment(endDateWatching).format('YYYY-MM-DD');
  const provider = Form.useWatch('provider', form);
  const statusFilter = Form.useWatch('status', form);
  const isCompletedTabWatching = Form.useWatch('isCompletedTab', form);

  const [modalContent, setModalContent] = useState({ visible: false });
  const { visible, title, content, extraContent, titleSuccess, onClickYes } =
    modalContent;

  const jobStatuses = isCompletedTab
    ? ['completed']
    : map(STATUS_FILTER_DROPDOWN_OPTIONS, 'value');

  if (isCompletedTab !== isCompletedTabWatching) {
    form.setFieldsValue({ isCompletedTab });
  }

  const {
    data: calendarJobs = [],
    refetch: refetchCalendarJobs,
    isFetching: isFetchingCalendarJobs,
  } = useFetch(
    [
      'calendarJobs',
      provider,
      startDateString,
      endDateString,
      isMothlyView,
      jobStatuses,
    ],
    () => {
      const formData = form.getFieldsValue();

      const location = get(formData, 'location');

      return JobServices.getCalendarJobs({
        provider,
        location,
        statuses: jobStatuses,
        shiftPostedFrom: startDateString,
        shiftPostedTo: endDateString,
      });
    },
    {
      enabled:
        !!provider && !!startDateString && !!endDateString && !!allFetched,
    },
  );

  let events = [];
  if (!isMothlyView) {
    events = map(calendarJobs, (item) => ({
      ...item,
      start: item.schedule[0]?.start,
      end: item.schedule[0]?.start,
    }));
  } else {
    events = map(calendarJobs, (item) => ({
      ...item,
      start: item.schedule[0]?.start,
      end: item.schedule.lastItem?.end,
    }));
  }

  if (statusFilter) {
    events = filterJobByStatus([statusFilter], events);
  }

  const jobInfo = find(calendarJobs, { id: rowId });
  const createdAt = get(jobInfo, 'createdAt', '');
  const updatedAt = get(jobInfo, 'updatedAt', '');

  const startAtArray = map(jobInfo?.schedule, 'startAt');
  const endAtArray = map(jobInfo?.schedule, 'endAt');

  const minStartDate = min(startAtArray);
  const maxEndDate = max(endAtArray);
  const jobDetail = {
    ...jobInfo,
    shiftDateAndTime: processDate({
      schedule: jobInfo?.schedule,
      startAt: minStartDate,
      endAt: maxEndDate,
    }),
    filled: jobInfo?.totalFills,
  };

  return (
    <>
      <Form form={form} name={FORM_NAME.CALENDAR_FORM}>
        <div className='calendar-view-container'>
          <FormItem name='viewMode' initialValue={Views.WEEK} hidden />
          <FormItem name='startDate' initialValue={initStartDate} hidden />
          <FormItem name='endDate' initialValue={initEndDate} hidden />
          <FormItem name='isCompletedTab' hidden />
          <FormItem name='showManageModal' initialValue={false} hidden />

          <CalendarOptions
            isLoading={isFetchingCalendarJobs}
            refetchCalendarJobs={refetchCalendarJobs}
            providers={providers}
            locations={locations}
          />
          <CustomCalendar
            view={viewMode}
            onShowMore={() => setShowAllEvents(true)}
            showAllEvents={viewMode === Views.MONTH && showAllEvents}
            customComponents={customCalendarView({
              isLoading: isFetchingCalendarJobs,
              setJobIdEdit,
              setRepostId,
              setShowPopupCreate,
              refetchCalendarJobs,
              openManageWorkerModal,
              setModalContent,
            })}
            events={events}
            localizer={momentLocalized}
          />

          <CheckinCheckoutJobsDrawer
            createdAt={createdAt}
            jobDetail={jobDetail}
            rowId={rowId}
            refetchJobsListview={refetchCalendarJobs}
            setModalContent={setModalContent}
            updatedAt={updatedAt}
          />
        </div>
      </Form>
      <ModalConfirm
        visible={visible}
        title={title}
        titleSuccess={titleSuccess}
        onClickNo={() => setModalContent({ visible: false })}
        onClickYes={onClickYes}
        extraContent={extraContent}
        className='share-modal-container'
      >
        {content}
      </ModalConfirm>
    </>
  );
};

CalendarView.propTypes = {
  setRepostId: PropTypes.func,
  setJobIdEdit: PropTypes.func,
  setShowPopupCreate: PropTypes.func,
  openManageWorkerModal: PropTypes.func,
  rowId: PropTypes.string || PropTypes.number,
  isCompletedTab: PropTypes.bool,
  locations: PropTypes.array,
  providers: PropTypes.array,
  allFetched: PropTypes.bool,
};

export default CalendarView;
