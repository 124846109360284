/* eslint-disable default-param-last */

import build from 'redux-object';

const buildOne = (reduxState, resourceType, id, ...params) =>
  build(reduxState, toCamel(resourceType), id, ...params);

const buildAll = (reduxState, resourceType) =>
  build(reduxState, toCamel(resourceType))?.filter((ele) => ele !== null);

const buildEndpoint = (endpoint, reduxState = {}, resourceType, ...params) => {
  const currentMeta = reduxState.meta[endpoint] || {};
  const { data = [], currentPage, totalPage, totalCount } = currentMeta;
  const ids = data.map((dt) => dt.id);

  const builtData =
    // use filter bellow, so we should add || [] when function `build` return null
    (build(reduxState, toCamel(resourceType), ids, ...params) || []).filter(
      (ele) => ele !== null,
    );

  return {
    data: builtData,
    currentPage,
    totalPage,
    totalCount,
  };
};

const getMetaIds = (metaData) => (metaData || []).map((dt) => dt?.id);

const mergeMeta = (stateMeta = {}, endpoint, meta = {}) => {
  if (!endpoint) {
    return stateMeta;
  }
  const currentEndpoint = stateMeta[endpoint] || {};

  return {
    ...stateMeta,
    [endpoint]: {
      ...currentEndpoint,
      data: meta[endpoint]?.data ?? currentEndpoint?.data ?? [],
      currentPage:
        meta[endpoint]?.meta?.pagy?.page ?? currentEndpoint.currentPage,
      totalPage: meta[endpoint]?.meta?.pagy?.pages ?? currentEndpoint.totalPage,
      totalCount:
        meta[endpoint]?.meta?.pagy?.count ?? currentEndpoint.totalCount,
    },
  };
};

const clearMeta = (stateMeta = {}, endpointList = []) => {
  const newStateMeta = stateMeta;
  endpointList.forEach((endpoint) => {
    delete newStateMeta[endpoint];
  });
  return { ...newStateMeta };
};

const clearMetaData = (stateMeta = {}, endpoint) => {
  if (!stateMeta[endpoint]) {
    return stateMeta;
  }

  return {
    ...stateMeta,
    [endpoint]: {
      ...stateMeta[endpoint],
      data: [],
    },
  };
};

const toCamel = (str) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

const capitalizeWords = (data) =>
  data.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

const makeAbbrevSortType = (sortOrder) => {
  if (sortOrder === 'descend') return 'desc';
  if (sortOrder === 'ascend') return 'asc';
  return sortOrder;
};

/**
 *
 * @param {number} number
 * @param {string} locales
 * @param {string} currencyFormat
 * @returns string
 */
const currency = (number, locales, currencyFormat) =>
  new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currencyFormat,
  }).format(number);

/**
 *
 * @param {number} number
 * @returns number
 */
const currencyGBP = (number = 0) => currency(number, 'en-GB', 'GBP');

function removeExtraSpaces(inputString) {
  return typeof inputString === 'string'
    ? inputString.replace(/ {2,}/g, ' ')
    : '';
}

const fixedNumber = ({ value, numberOfFixed = 2 }) =>
  Number(value ?? 0).toFixed(numberOfFixed);

const Formatter = {
  toCamel,
  fixedNumber,
  capitalizeWords,
  buildOne,
  currencyGBP,
  buildEndpoint,
  buildAll,
  getMetaIds,
  mergeMeta,
  clearMeta,
  clearMetaData,
  makeAbbrevSortType,
  removeExtraSpaces,
};

export default Formatter;
