import React, { createContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

export const ContextDetail = createContext();

const originData = [];

// eslint-disable-next-line no-plusplus
for (let i = 0; i < 4; i++) {
  originData.push({
    key: i.toString(),
    jobType: `Warehouse Operative ${i}`,
    payRate: i + 1 * Math.random() * 10,
    chargeRate: i + 1 * Math.random() * 11.1,
  });
}

export const ProviderDetail = ({ children }) => {
  const [listJobTypes, setListJobTypes] = useState(originData);

  const contextProps = useMemo(
    () => ({
      listJobTypes,
      setListJobTypes,
    }),
    [listJobTypes],
  );

  return (
    <ContextDetail.Provider value={contextProps}>
      {children}
    </ContextDetail.Provider>
  );
};

ProviderDetail.propTypes = {
  children: PropTypes.element,
};
