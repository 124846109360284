import React from 'react';

import { get, head, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import sort from '@/pages/PayTemplate/Payroll/utils/sort';

import HourlyTemplate from './HourlyTemplate';
import HoursPerDay from './HoursPerDay';

const ListTimecards = ({ isEdit, panelInfo, isHourlyTemplate }) => {
  const hoursPerDayData = get(panelInfo, 'payrollTemplateHours', []);
  const hourlyTemplateData = get(panelInfo, 'timecards', []);
  const sortedDayHourlyTemplateData = sort.sortByDay(hourlyTemplateData);

  const skillName = get(panelInfo, 'skillName', '');
  const panelId = get(panelInfo, 'timesheetDetailId');
  const seekerName = get(head(hoursPerDayData), 'seekerName', '');

  return (
    <div>
      {!isEmpty(panelInfo.timecards) && isHourlyTemplate ? (
        <HourlyTemplate
          isEdit={isEdit}
          panelId={panelId}
          skillName={skillName}
          seekerName={seekerName}
          hourlyTemplateData={sortedDayHourlyTemplateData}
        />
      ) : (
        <HoursPerDay
          hoursPerDayData={hoursPerDayData}
          isEdit={isEdit}
          panelId={panelId}
        />
      )}
    </div>
  );
};

export default ListTimecards;

ListTimecards.propTypes = {
  isEdit: PropTypes.bool,
  panelInfo: PropTypes.string,
  isHourlyTemplate: PropTypes.bool,
};

ListTimecards.defaultProps = {
  panelInfo: '',
  isEdit: false,
  isHourlyTemplate: false,
};
