import request from '@/request';

const createTimecards = async (
  /** @type {object} */ jobTypeInfo,
  /** @type {object} */ timesheetAttributes,
  /** @type {string} */ employerId,
) => {
  const {
    jobResponsesAttributes,
    items,
    locationId,
    content,
    breakTime,
    payrate,
    posted,
    chargeRate,
    skillId,
  } = jobTypeInfo;

  const resAttributes = jobResponsesAttributes.map((attribute) => {
    const { seekerName, ...res } = attribute;
    return res;
  });

  const formattedItems = items.map((item) => {
    const { startTime, endTime } = item;

    const startAt = startTime.unix();
    const endAt = endTime.unix();

    return {
      startAt,
      endAt,
    };
  });

  const positionAttributes = {
    shiftAttributes: {
      locationId,
      employerId,
    },
    commentsAttributes: [{ content }],
    schedule: { items: formattedItems },
    jobResponsesAttributes: resAttributes,
    breakTime,
    payrate,
    posted,
    chargeRate,
    skillId,
  };
  const res = await request.post(
    'trackers/timekeeping/timesheets/add_additional_job',
    { timesheetAttributes, positionAttributes },
  );

  return res.data;
};

export default createTimecards;
