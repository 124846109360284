import React from 'react';

import { Table } from 'antd';
import { get, isNil } from 'lodash';

import { SICK_NO_SHOW_LABEL } from '@/pages/Payroll/constants';
import CheckBoxCell from '@/pages/PayTemplate/Payroll/components/CheckBoxCell';
import CommentPopover from '@/pages/Timesheet/components/CommentPopover';
import { SICK_NO_SHOW_CLASS } from '@/pages/Timesheet/Detail/ListJobTypes/constant';
import { time } from '@/utils';
import Formatter from '@/utils/Formatter';

const renderSummary = ({
  panelId,
  timecard,
  noShowAttrs,
  handleEditComment,
  isOpen,
  setOpen,
  dailyTotals,
  isUpdateSickNoShowComment,
}) => {
  const { timecardId } = timecard;
  const namePath = [`${panelId}`, 'sickNoShowAttrs', `${timecardId}`];

  const {
    totalPay,
    totalCharge,
    shiftDuration,
    breakTotalPay,
    payrollTotalPay,
    breakTotalCharge,
    payrollTotalHours,
    payrollTotalCharge,
  } = dailyTotals;

  const noShowType = get(noShowAttrs, 'noShowType', '');
  const noShowLabel = SICK_NO_SHOW_LABEL?.[noShowType];
  const noShowClass = SICK_NO_SHOW_CLASS?.[noShowType];
  const isEmptyNoShow = isNil(noShowAttrs?.id);
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell className='empty-cell' />
      {isEmptyNoShow ? (
        <Table.Summary.Cell className='empty-cell' />
      ) : (
        <Table.Summary.Cell className={noShowClass}>
          <CommentPopover
            namePath={namePath}
            type={noShowType}
            handleEdit={() => handleEditComment({ namePath })}
            isLoading={isUpdateSickNoShowComment}
            isOpen={isOpen}
            setIsOpen={setOpen}
          >
            {noShowLabel}
          </CommentPopover>
        </Table.Summary.Cell>
      )}
      <Table.Summary.Cell colSpan={2}>Daily Totals</Table.Summary.Cell>
      <Table.Summary.Cell>
        {time.convertTimeHoursCell(shiftDuration)}
      </Table.Summary.Cell>
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell>
        £{Formatter.fixedNumber({ value: totalPay })}
      </Table.Summary.Cell>
      <Table.Summary.Cell>
        £{Formatter.fixedNumber({ value: totalCharge })}
      </Table.Summary.Cell>
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell>
        £{Formatter.fixedNumber({ value: breakTotalPay })}
      </Table.Summary.Cell>
      <Table.Summary.Cell>
        £{Formatter.fixedNumber({ value: breakTotalCharge })}
      </Table.Summary.Cell>
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell className='empty-cell' />
      <Table.Summary.Cell>
        {Formatter.fixedNumber({ value: payrollTotalHours })}
      </Table.Summary.Cell>
      <Table.Summary.Cell>
        £{Formatter.fixedNumber({ value: payrollTotalPay })}
      </Table.Summary.Cell>
      <Table.Summary.Cell>
        £{Formatter.fixedNumber({ value: payrollTotalCharge })}
      </Table.Summary.Cell>
      <CheckBoxCell panelId={panelId} timecard={timecard} />
    </Table.Summary.Row>
  );
};

export { renderSummary };
