import React, { useContext, useEffect, useState } from 'react';

import { Form } from 'antd';
import { isEmpty } from 'lodash';

import { FormItem, Input, Select } from '@/components';
import { useLocations } from '@/hooks/dropdowns';
import {
  rulesAccountNumber,
  rulesNationalInsuranceNo,
  rulesSelect,
  rulesSortCode,
  rulesTextInput,
} from '@/utils/rulesInput';

import CandidateContext from '../CandidateContext';
import { hearAboutUs, paymentMethods } from './constants';

const WorkerPayrollAndBankDetails = () => {
  const { listLocations } = useLocations();
  const context = useContext(CandidateContext);
  const [isHiddenText, setHiddenValue] = useState(true);

  const paymentCheque = Form.useWatch([
    'omniEmployeeAttributes',
    'employeePaymentMethod',
  ]);
  const formInstance = Form.useFormInstance();

  useEffect(() => {
    if (!isEmpty(context) && context.hear === 'Referred By A Friend') {
      setHiddenValue(false);
    }
  }, [context]);

  const handleShowBoxFriend = (value = '') => {
    if (value === 'Referred By A Friend') {
      setHiddenValue(false);
    } else {
      setHiddenValue(true);
    }
  };

  const onChange = (item) => {
    if (item === 'cheque') {
      formInstance.setFields([
        { name: 'bankAccName', errors: [] },
        { name: 'bankSort', errors: [] },
        { name: 'bank', errors: [] },
        { name: 'bankAcc', errors: [] },
        { name: 'ni', errors: [] },
      ]);
    }
  };

  const isPaymentCheque = paymentCheque === 'cheque';

  return (
    <div className='worker-payroll-and-bank-details'>
      <div className='label'>Worker Payroll and Bank Details</div>

      <div className='items'>
        <FormItem name='employee_number' label='Employee Number'>
          <Input placeholder='Employee Number' />
        </FormItem>
        <FormItem name='pastEmployeeNumber' label='Past Employee Numbers'>
          <Input placeholder='Past Employee Numbers' />
        </FormItem>
        <FormItem
          name={['omniEmployeeAttributes', 'employeePaymentMethod']}
          label='Payment Method'
          required
          rules={rulesSelect('Payment Method')}
        >
          <Select
            placeholder='Payment Method'
            options={paymentMethods}
            onChange={onChange}
            required
          />
        </FormItem>
        <FormItem
          name='bankAccName'
          label='Account Holder Name'
          required={!isPaymentCheque}
          rules={
            !isPaymentCheque ? rulesTextInput('Account Holder') : undefined
          }
        >
          <Input placeholder='Account Holder Name' />
        </FormItem>
        <FormItem
          name='bankAcc'
          label='Account Number'
          required={!isPaymentCheque}
          rules={!isPaymentCheque ? rulesAccountNumber() : undefined}
        >
          <Input placeholder='Account Number' />
        </FormItem>
        <FormItem
          name='bankSort'
          label='Sort Code'
          required={!isPaymentCheque}
          rules={!isPaymentCheque ? rulesSortCode(true) : undefined}
        >
          <Input placeholder='Sort Code' maxLength={16} />
        </FormItem>
        <FormItem
          name='bank'
          label='Full Name of Bank'
          required={!isPaymentCheque}
          rules={!isPaymentCheque ? rulesTextInput('Full Name') : undefined}
        >
          <Input placeholder='Full Name of Bank' />
        </FormItem>
        <FormItem
          name={['omniEmployeeAttributes', 'employeeBuildingSocNo']}
          label='Building Society No:'
        >
          <Input placeholder='Building Society No' />
        </FormItem>
        <FormItem
          name='ni'
          label='National Insuarance No'
          required={!isPaymentCheque}
          rules={
            !isPaymentCheque
              ? rulesNationalInsuranceNo('National Insurance No')
              : undefined
          }
        >
          <Input placeholder='National Insuarance No' />
        </FormItem>
        <FormItem
          name={['omniEmployeeAttributes', 'employeeTaxCode']}
          label='Tax Code'
        >
          <Input placeholder='Tax Code' />
        </FormItem>
        <FormItem
          rules={rulesSelect('location')}
          name='officeId'
          label='Location'
          required
        >
          <Select placeholder='Location' options={listLocations} required />
        </FormItem>
        <FormItem
          required
          rules={rulesSelect('hear about us')}
          name='hear'
          label='How did you hear about us?'
        >
          <Select
            placeholder='How did you hear about us'
            options={hearAboutUs}
            onChange={handleShowBoxFriend}
            required
          />
        </FormItem>
      </div>
      <div className='friends-name-item'>
        <FormItem
          className='input-container friends-name-form'
          name='friendName'
          hidden={isHiddenText}
        >
          <Input placeholder='Friends Name' />
        </FormItem>
      </div>
    </div>
  );
};

export default WorkerPayrollAndBankDetails;
