export const getNumberPercentInput = (/** @type {string} */ value) =>
  Number.parseFloat(`${value}`.replace('%', '')) / 100;

export const getNumberMoneyInput = (/** @type {string} */ value) =>
  Number.parseFloat(`${value}`.replace('£', ''));

export const preventInvalidValueInput = (
  /** @type {React.KeyboardEvent<HTMLInputElement>} */ e,
  /** @type {string[]} */ exceptionKeys = [],
) => exceptionKeys.includes(e.key) && e.preventDefault();
