/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import classNames from 'classnames';
import { camelCase, get, head, last, map } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { CHECK_IN_SHIFT_TYPES } from '@/pages/Jobs/CheckinCheckoutJobsDrawer/AssignList/Jobs/Confirmed/constant';
import { time } from '@/utils';

import FormItem from '../FormItem';

import './styles.scss';

const START_DATE = 'startDate';
const SELECTED = 'selected';

const CLASS_TYPE = {
  [CHECK_IN_SHIFT_TYPES.unchecked]: 'not-available',
  [CHECK_IN_SHIFT_TYPES.completedCheckedOut]: 'completed-checked-out',
  [CHECK_IN_SHIFT_TYPES.availableCheckinCheckout]: 'available',
  [CHECK_IN_SHIFT_TYPES.completedCheckedIn]: 'completed-checked-in',
  [CHECK_IN_SHIFT_TYPES.timesheetProcessed]: 'processed',
};

const HorizontalDatePicker = ({ name, dateRange }) => {
  const formInstance = Form.useFormInstance();
  const startDate = moment(get(head(dateRange), 'date'));
  const endDate = moment(get(last(dateRange), 'date'));

  const start =
    Form.useWatch([name, START_DATE], formInstance) ?? startDate.clone();

  const selectedDate = Form.useWatch([name, SELECTED], formInstance);
  const currWeek = time.getDatesFromMonToSun({ date: start });
  const startOfWeek = moment(get(head(currWeek), 'dateString'));
  const endOfWeek = moment(get(last(currWeek), 'dateString'));

  const handleNextWeek = () => {
    const nextWeek = start.clone().add(1, 'week');
    formInstance.setFields([{ name: [name, START_DATE], value: nextWeek }]);
  };
  const handlePrevWeek = () => {
    const prevWeek = start.clone().subtract(1, 'week');
    formInstance.setFields([{ name: [name, START_DATE], value: prevWeek }]);
  };

  const handleSelectDate = (date) => {
    formInstance.setFields([{ name: [name, SELECTED], value: date }]);
  };

  return (
    <div className='horizontal-date-picker'>
      <FormItem name={[name, START_DATE]} hidden />
      <FormItem name={[name, SELECTED]} hidden />
      <div className='horizontal-date-picker-body'>
        <Button
          type='primary'
          shape='circle'
          icon={<LeftOutlined />}
          onClick={handlePrevWeek}
          hidden={startOfWeek <= startDate}
        />
        <div className='date-range'>
          {map(currWeek, ({ dayOfWeek, dateNumber, dateString }) => {
            const matchDate = dateRange.find(({ date }) => date === dateString);

            const status = get(matchDate, 'status', null);
            const statusClass = get(CLASS_TYPE, camelCase(status), 'disabled');
            return (
              <div
                key={dateString}
                className={classNames(`date-box ${statusClass}`, {
                  active: dateString === selectedDate,
                })}
                onClick={() => handleSelectDate(dateString)}
              >
                <div>{dayOfWeek}</div>
                <div className='date'>{dateNumber}</div>
              </div>
            );
          })}
        </div>
        <Button
          type='primary'
          shape='circle'
          icon={<RightOutlined />}
          onClick={handleNextWeek}
          hidden={endOfWeek >= endDate}
        />
      </div>
    </div>
  );
};

export default HorizontalDatePicker;

HorizontalDatePicker.propTypes = {
  dateRange: PropTypes.array,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

HorizontalDatePicker.defaultProps = {
  name: '',
  dateRange: [],
};
