const HEAR_ABOUT_US = [
  {
    label: 'Google',
    value: 'Google',
  },
  {
    label: 'Job Boards',
    value: 'Job Boards',
  },
  {
    label: 'Social Media',
    value: 'Social Media',
  },
  {
    label: 'Word of Mouth',
    value: 'Word of Mouth',
  },
  {
    label: 'I was contacted by a member of the GIG sales team',
    value: 'I was contacted by a member of the GIG sales team',
  },
  {
    label: 'None of the above',
    value: 'None of the above',
  },
];

const PAYMENT_START_WEEK = [
  {
    value: 1,
    label: 'Monday - Sunday',
  },
  {
    value: 2,
    label: 'Tuesday - Monday',
  },
  {
    value: 3,
    label: 'Wednesday - Tuesday',
  },
  {
    value: 4,
    label: 'Thursday - Wednesday',
  },
  {
    value: 5,
    label: 'Friday - Thursday',
  },
  {
    value: 6,
    label: 'Saturday - Friday',
  },
  {
    value: 0,
    label: 'Sunday - Saturday',
  },
];
export { HEAR_ABOUT_US, PAYMENT_START_WEEK };
