import request from '@/request';

const getManagerInfoById = async (id) => {
  const response = await request.get(
    `/providers/employers/show_manager_info?manager_id=${id}`,
  );

  const { email = null, phone = null } = response.data?.data || {};
  return { accountManagerGIGEmail: email, accountManagerPhone: phone };
};

export default getManagerInfoById;
