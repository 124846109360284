import React from 'react';

import PropTypes from 'prop-types';

import { useTenantContext } from '../../../../../TenantWrapper';

const BodyTotal = ({ totalNetAmount, totalOmniVat, total }) => {
  const { currency, invoiceInfo } = useTenantContext();
  const {
    contact = {},
    registeredCompany = {},
    payment = {},
    bank = {},
  } = invoiceInfo;
  const { phoneNumber } = contact;
  const { vatRegNumber, name, regNumber } = registeredCompany;
  const { paymentTerm, sortCode, accountHolderName } = payment;
  const { name: bankName, accountNumber } = bank;

  return (
    <div className='invoice-total'>
      <div className='invoice-total-left'>
        <div className='basic-info'>
          <p>Invoice Enquiries Tel: {phoneNumber}</p>
          <p>VAT REG NUMBER: {vatRegNumber}</p>
          <p>{name}</p>
          <p>Company Registration No: {regNumber}</p>
        </div>
        <div className='total-info'>
          <p>
            Total NET {currency}
            {totalNetAmount.toFixed(2)}
          </p>
          <p>
            Total VAT {currency}
            {totalOmniVat.toFixed(2)}
          </p>
          <p>
            Invoice Total {currency}
            {total.toFixed(2)}
          </p>
        </div>
      </div>
      <div className='invoice-total-right'>
        <p>Holder Test - Cannot be edited here:</p>
        <p>
          Our Standard Terms are Payment within {paymentTerm} Days by BACS. Our
          Bank details are as follows: A/C Name: {accountHolderName}. Bank:{' '}
          {bankName}. Bank Account No.: {accountNumber} Sort Code : {sortCode}
        </p>
        <p>
          Any Changes To Bank Details Will Be Confirmed In Writting Via Post And
          E-mail And Need To Be Verified Verbally By Calling Accounts On{' '}
          {phoneNumber} And Writting To The Above Address. Subject To Our
          Standard Terms And Conditions
        </p>
      </div>
    </div>
  );
};

BodyTotal.propTypes = {
  totalNetAmount: PropTypes.number,
  totalOmniVat: PropTypes.number,
  total: PropTypes.number,
};

BodyTotal.defaultProps = {
  totalNetAmount: 0,
  totalOmniVat: 0,
  total: 0,
};
export default BodyTotal;
