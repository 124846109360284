/* eslint-disable no-undef */
import { isNumber, isString } from 'lodash';
import moment from 'moment';

import { DATA_PAYPMENT_REPORT_KEYS, DATA_PROTECTION_KEYS } from '@/constants';
import request from '@/request';
import { getNumberMoneyInput, getNumberPercentInput } from '@/utils';
import { uploadFile, uploadMultipleFile } from '@/utils/uploadFileUtil';

import { getAddressInfomation } from '../getAddressInfomation';

const checkValuesAreStringOrNumber = (attributes) =>
  Object.values(attributes ?? {}).some(
    (value) => isNumber(value) || isString(value),
  );

const convertContactArray = (oldData, newData, typeString) => {
  const oldDataConverted = (oldData || [])
    .map(({ name, email, phone, id, jobTitle, department }) => {
      const found = (newData || []).find(
        (value) => String(value.id) === String(id),
      );

      if (found) {
        return null;
      }

      return {
        name,
        email,
        phone,
        type: typeString,
        id,
        jobTitle,
        department,
        // If data was not on UI, we will delete it by bellow param
        _destroy: true,
      };
    })
    .filter((item) => item !== null);

  const newDataConverted = (newData || []).map(
    ({ name, email, phone, id, jobTitle, department }) => ({
      name,
      email,
      phone,
      type: typeString,
      id,
      jobTitle,
      department,
    }),
  );

  return [...oldDataConverted, ...newDataConverted];
};

/**
 * Generates a request object based on the provided payload and old data provider.
 * @param {{
 * payload: {
 *  city: string,
 *  street: string,
 *  postCode: string,
 *  building: string,
 *  providerName: string,
 *  vatNumber: string,
 *  registeredCompanyName: string,
 *  companyNumber: string,
 *  customerSageCode: string,
 *  contactId: string,
 *  accountManagerId: string,
 *  contactName: string,
 *  contactEmail: string,
 *  contactPhone: string,
 *  contactJobTitle: string,
 *  contactDepartment: string,
 *  contactManualPassword: string,
 *  accountManagerName: string,
 *  accountManagerPhone: string,
 *  accountManagerSecondaryManagerEmail: string,
 *  contactForBookings: Array,
 *  contactForInvoices: Array,
 *  contactForAccounts: Array,
 *  contactForTimesheets: Array,
 *  chargeRateId: string,
 *  otherCosts: string,
 *  marginFixed: string,
 *  marginPercent: string,
 *  pensionPercent: string,
 *  pensionThreshold: string,
 *  apprenticeshipLevyPercent: string,
 *  employersNiPerWeekPercent: string,
 *  employersNiHourWeek: string,
 *  employersNiPerWeekThreshold: string,
 *  holidayDays: string,
 *  chargeRatesAttributes: Array,
 *  marginIsCostType: string,
 *  industryIds: Array,
 *  creditCard: Array,
 *  postingAlerts: Array,
 *  paymentReport: Array,
 *  CVV: string,
 *  nameOnCard: string,
 *  expiryDate: string,
 *  longCardNumber: string,
 *  aboutUs: string,
 *  offices: string,
 *  paymentStartWeek: number,
 *  locations: Array,
 *  industries: Array,
 *  industriesHidden: Array,
 *  dataProtection: Array,
 *  historyComments: Array,
 *  note: string,
 *  jobTemplatesAttributes: Array,
 *  status: string,
 *  logoThumbnail: string,
 *  contract: string,
 * chargeRatesList: Array,
 * };
 * oldDataProvider: Object;
 * }} props
 */
const createRequestObject = async ({
  payload: {
    city,
    street,
    postCode,
    building,
    providerName,
    vatNumber,
    registeredCompanyName,
    companyNumber,
    customerSageCode,

    contactId,
    accountManagerId,
    contactName,
    contactEmail,
    contactPhone,
    contactJobTitle,
    contactDepartment,
    contactManualPassword,
    accountManagerName,
    accountManagerPhone,
    accountManagerSecondaryManagerEmail,
    contactForBookings,
    contactForInvoices,
    contactForAccounts,
    contactForTimesheets,

    chargeRateId,
    otherCosts,
    marginFixed,
    marginPercent,
    pensionPercent,
    pensionThreshold,
    apprenticeshipLevyPercent,
    employersNiPerWeekPercent,
    employersNiHourWeek,
    employersNiPerWeekThreshold,
    holidayDays,

    chargeRatesAttributes,
    marginIsCostType,

    postingAlerts,
    paymentReport,
    CVV,
    nameOnCard,
    expiryDate,
    longCardNumber,
    creditCard,

    aboutUs,
    offices,
    paymentStartWeek,
    locations,
    industries,
    industriesHidden,
    note,

    jobTemplatesAttributes,

    dataProtection,
    historyComments,
    status,
    logoThumbnail,
    contract,
    chargeRatesList,
  },
  oldDataProvider = {},
}) => {
  const {
    contactForTimesheets: oldContactForTimesheets,
    contactForBookings: oldContactForBookings,
    contactForInvoices: oldContactForInvoices,
    contactForAccounts: oldContactForAccounts,
    locations: oldLocations,
  } = oldDataProvider;

  const historyCommentsConverted = (historyComments || []).map(
    ({ content, id, userId }) => ({
      id,
      content,
      omni_user_id: userId,
    }),
  );

  const resultContactForTimesheets = convertContactArray(
    oldContactForTimesheets,
    contactForTimesheets,
    'timesheet',
  );

  const resultContactForBooking = convertContactArray(
    oldContactForBookings,
    contactForBookings,
    'booking',
  );

  const resultContactForInvoices = convertContactArray(
    oldContactForInvoices,
    contactForInvoices,
    'invoice',
  );

  const resultContactForAccounts = convertContactArray(
    oldContactForAccounts,
    contactForAccounts,
    'account',
  );
  const oldLocationsConverted = (oldLocations || [])
    .map((location) => {
      const found = (locations ?? []).find((l) => l.id === location.id);
      if (!found) {
        return {
          id: location.id,
          _destroy: true,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const jobTemplatesWithLocation = {};

  jobTemplatesAttributes.forEach((template) => {
    const { locationId, licenses, ...rest } = template;
    jobTemplatesWithLocation[locationId] = [
      ...(jobTemplatesWithLocation[locationId] ?? []),
      {
        ...rest,
        licenses: licenses ? licenses.filter((e) => e !== 'all') : undefined,
      },
    ];
  });

  const locationsConverted = Promise.all(
    (locations ?? []).map(async (location) => {
      const geogecode = await getAddressInfomation(
        // eslint-disable-next-line max-len
        `${location.buildingNumber} ${location.street} ${location.city} ${location.postCode}, United Kingdom`,
      );
      const { geometry } = geogecode[0];

      const isNewLocation =
        typeof location?.id === 'string' && location?.id.includes('customId');

      const result = {
        id: !isNewLocation ? location.id : undefined,
        city: location.city,
        street: location.street,
        name: location.locationName,
        postcode: location.postCode,
        building: location.buildingNumber,
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
        // territory_id is id of country, default is 1 (UK)
        territory_id: 1,
        email_invoice: 'backend_hardcode@gmail.com', // TODO: Need to confirm with backend.
        jobTemplatesAttributes: jobTemplatesWithLocation[location.id],
      };

      return result;
    }),
  );

  const employerLocationsAttributes = [
    ...(await locationsConverted),
    ...oldLocationsConverted,
  ];

  chargeRatesList.forEach((item) => {
    if (!chargeRatesAttributes.some((ele) => ele.id === item.id)) {
      chargeRatesAttributes.push({
        ...item,
        _destroy: true,
      });
    }
  });

  const chargeRatesAttributesConverted = (chargeRatesAttributes || [])
    .map(
      ({ payRate, chargeRate, id, skillId, rateType, _destroy }) =>
        checkValuesAreStringOrNumber({
          payRate,
          chargeRate,
          skillId,
          rateType,
          _destroy,
        }) && {
          id,
          payRate,
          skillId,
          chargeRate,
          rateType,
          _destroy,
        },
    )
    .filter((valueFilter) => valueFilter !== false);

  const expMonth = moment(expiryDate, 'MM/YYYY').month();
  const expYear = moment(expiryDate, 'MM/YYYY').year();
  const provider = {
    status,
    hear: aboutUs,
    ba_city: city,
    ba_phone: contactPhone,
    ba_street: street,
    name: providerName,
    office_id: offices,
    payment_start_week: paymentStartWeek,
    ba_postcode: postCode,
    ba_building: building,
    vat_number: vatNumber,
    customer_sage_code: customerSageCode,
    posting_alert: postingAlerts,
    company_number: companyNumber,
    abbrv_name: registeredCompanyName,
    wages_instant_payment: false, // TODO: Hard-code. Update later. confirm with Team
    note,

    show_awr: (paymentReport || []).includes(
      DATA_PAYPMENT_REPORT_KEYS.SHOW_AWR_REPORT,
    ),
    pay_weekend: (paymentReport || []).includes(
      DATA_PAYPMENT_REPORT_KEYS.SUNDAY_TO_SATURDAY_PAYMENT_WEEK,
    ),
    is_verified_read_terms: (dataProtection || []).includes(
      DATA_PROTECTION_KEYS.VERIFIED_READ_TERMS_AND_CONDITIONS,
    ),
    is_verified_store_and_use: (dataProtection || []).includes(
      DATA_PROTECTION_KEYS.VERIFIED_STORE_AND_USE,
    ),
    is_accepted_receiving_updates: (dataProtection || []).includes(
      DATA_PROTECTION_KEYS.ACCEPTED_RECIEVING_UPDATES,
    ),
    comments_attributes: historyCommentsConverted,

    contacts_attributes: [
      {
        id: contactId,
        name: contactName,
        phone: contactPhone,
        email: contactEmail,
        jobTitle: contactJobTitle,
        department: contactDepartment,
        type: 'user',
      },
      {
        id: accountManagerId,
        name: Number(accountManagerName),
        phone: accountManagerPhone,
        email: accountManagerSecondaryManagerEmail,
        type: 'omni_user',
      },
      ...resultContactForBooking,
      ...resultContactForAccounts,
      ...resultContactForInvoices,
      ...resultContactForTimesheets,
    ],
    charge_rate_property_attributes: {
      id: chargeRateId,
      other_cost: getNumberMoneyInput(otherCosts),
      margin_fixed: getNumberMoneyInput(marginFixed),
      margin_percent: getNumberPercentInput(marginPercent),
      pension_percent: getNumberPercentInput(pensionPercent),
      pension_threshold: Number.parseFloat(pensionThreshold),
      apprenticeship_levy_percent: getNumberPercentInput(
        apprenticeshipLevyPercent,
      ),

      employers_ni_hour_week: Number.parseFloat(employersNiHourWeek),
      employers_ni_per_week_threshold: Number.parseFloat(
        employersNiPerWeekThreshold,
      ),
      employers_ni_per_week_percent: getNumberPercentInput(
        employersNiPerWeekPercent,
      ),
      marginIsCostType,
      holiday: holidayDays,
    },
    employerLocationsAttributes,
    charge_rates_attributes: chargeRatesAttributesConverted,
    user_attributes: {
      password: contactManualPassword,
    },

    industryIds: Array.from(new Set(industries)),
    logoThumbnail,
    contract_file: contract,
  };

  if (creditCard) {
    provider.card = {
      cvc: CVV,
      name: nameOnCard,
      exp_year: expYear,
      exp_month: expMonth,
      number: longCardNumber,
    };
  }

  return {
    provider,
  };
};

/** 

 * @param {{
 *   payload: Object,
 *   providerId: String,
 *   oldDataProvider: Object
 }} props
 * @return
 */
const update = async ({ payload = {}, providerId, oldDataProvider = {} }) => {
  const { jobTemplatesAttributes = [], logoThumbnail, contractFile } = payload;

  const jobTemplatesAttributesConvert = jobTemplatesAttributes.map((item) => {
    const {
      shirt,
      jean,
      shoe,
      additionalUniform,
      employerId,
      skillId,
      jobTemplatesUniformsAttributes = [],
      ...rest
    } = item;

    return {
      ...rest,
      jobTemplatesUniformsAttributes: jobTemplatesUniformsAttributes.map(
        (uniform) => ({
          uniformId: uniform.uniformId,
        }),
      ),
    };
  });

  const data = {
    ...payload,
    jobTemplatesAttributes: jobTemplatesAttributesConvert,
  };

  if (logoThumbnail && !isString(logoThumbnail)) {
    const url = await uploadFile({
      file: logoThumbnail.file,
      fieldName: 'logoThumbnail',
    });
    data.logoThumbnail = url;
  }

  if (contractFile && !isString(contractFile)) {
    const urls = await uploadMultipleFile({
      fileList: contractFile.fileList,
      fieldName: 'contract',
    });
    data.contract = urls.join(',');
  }

  return (
    await request.put(
      `/providers/employers/${providerId}`,
      await createRequestObject({ payload: data, oldDataProvider }),
    )
  ).data;
};

export default update;
