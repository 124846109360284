import React, { useCallback, useMemo, useState } from 'react';

import { Form, message, Modal, Spin } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/warningIcon.svg';
import {
  DatePicker,
  Button,
  FormItem,
  Input,
  Select,
  AutoComplete,
} from '@/components';
import { DEBOUNCE_WAIT } from '@/constants';
import { useFetch } from '@/hooks';
import { SeekerService, HolidayService } from '@/services';
import { disabledDateOf, date } from '@/utils';

import './styles.scss';
import { numberOfDaysHolidayToProcessOptions } from '../constants';
import MultipleDatePicker from './MultipleDatePicker';

const AddHolidayRequest = ({
  isDisplayModal,
  hiddenModal,
  setShouldRefetchHolidays,
  form,
}) => {
  const [searchTerm, setSearchTerm] = useState();

  const { data, isFetching } = useFetch(['getSeeker', searchTerm], () =>
    SeekerService.getSeekers({ searchTerm }),
  );

  const { mutate: createEmployeeHolidays } = useMutation(
    HolidayService.createEmployeeHolidays,
    {
      onSuccess: () => {
        setShouldRefetchHolidays(true);
        hiddenModal();
        message.success('Create employee holidays successfully');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const listSeeker = useMemo(() => data?.data ?? [], [data]);
  const seekerOptions = useMemo(
    () =>
      listSeeker.map(({ id, fullname, accruedDay }) => ({
        id,
        accruedDay,
        label: fullname,
        value: fullname,
      })),
    [listSeeker],
  );

  const handleFindSeeker = async (_seekerId, option) => {
    const { value: fullName, id, accruedDay } = option;
    const holidays = form.getFieldValue('holidays');

    form.setFieldsValue({
      holidays: [
        ...holidays,
        {
          id,
          name: fullName,
          daysAccrued: accruedDay,
          weekRequested: undefined,
          daysRequested: undefined,
          numberOfHolidayDays: undefined,
        },
      ],
    });
  };

  const disabledDate = useCallback(
    (currentDate, index) => {
      const { holidays } = form.getFieldsValue();
      const { weekRequested } = holidays[index] ?? {};
      const { start, end } = date.datesOfWeek(weekRequested);

      return (
        disabledDateOf.Start(currentDate, end) ||
        disabledDateOf.End(currentDate, start)
      );
    },
    [form],
  );

  const handleChangeDaysRequested = (index) => {
    const { holidays } = form.getFieldsValue();

    holidays[index] = {
      ...holidays[index],
      daysRequested: [],
    };

    return form.setFieldsValue({
      holidays,
    });
  };

  const handleSubmitFormAddHolidays = (values) => {
    const { holidays } = values;

    if (!holidays || !holidays.length) {
      return message.error('Please choose 1 seeker');
    }

    const holidayAttributes = holidays.map(
      ({ id, weekRequested, daysRequested }) => {
        const holidayRequestsAttributes = (daysRequested || []).map(
          ({ time, date: holidayDate }) => ({
            hours: time,
            date: moment(holidayDate).format('DD/MM/YYYY'),
          }),
        );

        return {
          employeeId: id,
          startOn: date.datesOfWeek(weekRequested).start.format('DD/MM/YYYY'),
          endOn: date.datesOfWeek(weekRequested).end.format('DD/MM/YYYY'),
          status: 'approved',
          holidayRequestsAttributes,
        };
      },
    );

    return createEmployeeHolidays(holidayAttributes);
  };

  const handleOnSearchSeeker = debounce(setSearchTerm, DEBOUNCE_WAIT);

  return (
    <Modal
      width='65vw'
      className='add-holiday-request'
      visible={isDisplayModal}
      onOk={hiddenModal}
      onCancel={hiddenModal}
      footer=''
      closeIcon={<ClosedIcon />}
    >
      <div className='warning-icon'>
        <WarningIcon />
      </div>
      <h1 className='title'>Add Holiday Request</h1>
      <p className='cap'>
        Can only post timecards for hours within this current week
      </p>
      <div className='search-seeker'>
        <div>Search Seeker</div>
        <AutoComplete
          optionFilterProp='label'
          options={seekerOptions}
          placeholder='Enter Name'
          className='auto-complete-seeker'
          onSearch={handleOnSearchSeeker}
          onSelect={handleFindSeeker}
        />
        {isFetching && <Spin />}
      </div>
      <Form form={form} onFinish={handleSubmitFormAddHolidays}>
        <Form.List name='holidays' initialValue={[]}>
          {(fields, { _add, remove }) => (
            <>
              <div className='form-list-holiday'>
                <div className='form-list-holiday-title days-requested' />
                <div className='form-list-holiday-title days-accrued'>
                  Days Accrued
                </div>
                <div className='form-list-holiday-title'>Week Requested</div>
                <div className='form-list-holiday-title days-requested'>
                  Days Requested
                </div>
                <div className='form-list-holiday-title'>
                  Number of holiday days
                </div>
                <div className='form-list-holiday-hidden' />
              </div>
              <div className='list-holiday'>
                {fields.map((field, index) => (
                  <div className='form-list-holiday'>
                    <FormItem name={[field.name, 'id']} noStyle />

                    <FormItem
                      name={[field.name, 'name']}
                      className='form-list-field days-requested'
                    >
                      <Input disabled />
                    </FormItem>
                    <FormItem
                      name={[field.name, 'daysAccrued']}
                      className='form-list-field days-accrued'
                    >
                      <Input disabled />
                    </FormItem>

                    <FormItem
                      name={[field.name, 'weekRequested']}
                      className='form-list-field'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill this field',
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        datePickerMode='week'
                        name={[field.name, 'weekRequested']}
                        format={date.customWeekStartEndFormat}
                        onChange={() => handleChangeDaysRequested(index)}
                        dropdownClassName='holiday-week-requested'
                        disabledDate={(currentDate) =>
                          disabledDateOf.End(
                            currentDate,
                            moment().subtract(1, 'days'), // still select current day.
                          )
                        }
                        defaultValue={moment()}
                      />
                    </FormItem>

                    <FormItem
                      name={[field.name, 'daysRequested']}
                      className='form-list-field days-requested'
                      rules={[
                        {
                          required: true,
                          message: 'Please fill this field',
                        },
                      ]}
                    >
                      <MultipleDatePicker
                        disabledDate={(currentDate) =>
                          disabledDate(currentDate, index)
                        }
                        fieldIndex={index}
                      />
                    </FormItem>
                    <FormItem
                      name={[field.name, 'numberOfHolidayDays']}
                      className='form-list-field'
                    >
                      <Select
                        disabled
                        options={numberOfDaysHolidayToProcessOptions}
                      />
                    </FormItem>

                    <Button onClick={() => remove(field.name)} type='text'>
                      <ClosedIcon />
                    </Button>
                  </div>
                ))}
              </div>
            </>
          )}
        </Form.List>

        <div className='button-post-holidays-wrapper'>
          <Button
            htmlType='submit'
            className='yellow-button button-post-holidays'
          >
            Post Holidays
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

AddHolidayRequest.propTypes = {
  isDisplayModal: PropTypes.bool,
  hiddenModal: PropTypes.func,
  setShouldRefetchHolidays: PropTypes.func,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
};

AddHolidayRequest.defaultProps = {
  isDisplayModal: '',
  hiddenModal: () => {},
  setShouldRefetchHolidays: () => {},
};

export default AddHolidayRequest;
