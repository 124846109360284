import React from 'react';

import { Checkbox, Col, Form, message, Row } from 'antd';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button, FormHeader, FormItem, Input, Textarea } from '@/components';
import GuestFormCard from '@/components/GuestFormCard';
import { GuestService } from '@/services';
import { rulesAcceptPrivacyPolicy } from '@/utils/rulesInput';

import FormLogo from './components/Header';
import {
  companyAddress,
  companyDetailInputs,
  contactDetailInputs,
  contactForAccountsInput,
  contactForBookingsInput,
  contactForInvoicesInput,
  contactForTimesheetsInput,
  siteLocationsInputs,
} from './constants';
import './styles.scss';
import { useTenantContext } from '../../TenantWrapper';

const NewClientOnboardingForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { name, contact = {} } = useTenantContext();

  const isAcceptedReceivingUpdates = Form.useWatch(
    'isAcceptedReceivingUpdates',
    form,
  );

  const { mutate: createProvider } = useMutation(
    (provider) => GuestService.createProvider(provider),
    {
      onError: (error) => message.error(error?.message),
      onSuccess: () => {
        message.success('Create new provider success!');
        history.push('/thank-you-for-submitting-provider-form');
      },
    },
  );

  return (
    <div className='form-new-client'>
      <div className='onboarding-form-container'>
        <FormHeader />
        <FormLogo />
        <GuestFormCard>
          <div className='form-content-container'>
            <h2 className='title'>New Client Form</h2>
            <h2 className='sub-title'>
              Please fill in the form to create a provider profile with {name}.
              This will allow us to begin posting shifts for you and providing
              you with the staff you require, Once filled in the team will have
              a discussion with you regarding the type of staff you require and
              sign off rates.
            </h2>
            <Form
              form={form}
              className='form-container'
              name='need-staff'
              layout='vertical'
              onFinish={createProvider}
              scrollToFirstError={{
                skipOverflowHiddenElements: true,
                block: 'center',
              }}
            >
              <div className='section'>
                <p className='section-title'>Company Details:</p>
                <p className='section-title'>
                  Please provide information required to raise an invoice for
                  workers hours.
                </p>
                <div className='section-content'>
                  <Row gutter={24}>
                    {companyDetailInputs.map((item) => (
                      <Col span={8}>
                        <FormItem
                          name={item.name}
                          label={item.label}
                          rules={item.rules}
                          key={item.name}
                          className={item.className}
                          required={!!item.require}
                        >
                          <Input
                            placeholder={item.placeholder}
                            maxLength={item.maxLength}
                          />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <p className='section-title'>Your Contact Details:</p>
                <div className='section-content'>
                  <Row gutter={24}>
                    {contactDetailInputs.map((item) => (
                      <Col className='gutter-row' xs={12} xl={8}>
                        <FormItem
                          name={item.name}
                          label={item.label}
                          rules={item.rules}
                          key={item.name}
                          className={item.className}
                          required={item.required}
                        >
                          <Input
                            placeholder={item.label}
                            maxLength={item.maxLength}
                          />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <p className='section-title'>Registered Company Address</p>
                <p className='section-title'>
                  This does not need to be the same as the location you require
                  staff
                </p>
                <div className='section-content'>
                  <Row gutter={24}>
                    {companyAddress(form).map((item) => (
                      <Col className='gutter-row' xs={24} xl={8}>
                        <FormItem
                          name={item.name}
                          label={item.label}
                          rules={item.rules}
                          key={item.name}
                          className={item.className}
                          required={item.required}
                        >
                          <Input
                            placeholder={item.label}
                            maxLength={item.maxLength}
                          />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <p className='section-title'>Additional Contacts</p>
                <p className='section-title'>
                  Please provide the information of additional contacts where
                  relevant.
                </p>
                <div className='section-content'>
                  <div className='section-sub-title'>Bookings</div>
                  <div className='section-notes'>
                    This is the main contact that will sign off on weekly
                    timesheets.
                  </div>
                  <Row gutter={24}>
                    {contactForBookingsInput.map((item) => (
                      <Col className='gutter-row' xs={12} xl={8}>
                        <FormItem
                          name={item.name}
                          rules={item.rules}
                          key={item.name}
                          className={item.className}
                          label={item.label}
                        >
                          <Input
                            placeholder={item.label}
                            maxLength={item.maxLength}
                          />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <div className='section-content'>
                  <div className='section-sub-title'>Timesheets</div>
                  <div className='section-notes'>
                    This is the main contact that will sign off on weekly
                    timesheets.
                  </div>
                  <Row gutter={24}>
                    {contactForTimesheetsInput.map((item) => (
                      <Col className='gutter-row' xs={12} xl={8}>
                        <FormItem
                          name={item.name}
                          rules={item.rules}
                          key={item.name}
                          className={item.className}
                          label={item.label}
                        >
                          <Input
                            placeholder={item.label}
                            maxLength={item.maxLength}
                          />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <div className='section-content'>
                  <div className='section-sub-title'>Accounts</div>
                  <div className='section-notes'>
                    This is the main contact that will be sent any Accounts
                    queries.
                  </div>
                  <Row gutter={24}>
                    {contactForAccountsInput.map((item) => (
                      <Col className='gutter-row' xs={12} xl={8}>
                        <FormItem
                          name={item.name}
                          rules={item.rules}
                          key={item.name}
                          className={item.className}
                          label={item.label}
                        >
                          <Input
                            placeholder={item.label}
                            maxLength={item.maxLength}
                          />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <div className='section-content'>
                  <div className='section-sub-title'>Invoices</div>
                  <div className='section-notes'>
                    This is the main contact that will receive weekly Invoices.
                    Additional contacts can be added later.
                  </div>
                  <Row gutter={24}>
                    {contactForInvoicesInput.map((item) => (
                      <Col className='gutter-row' xs={12} xl={8}>
                        <FormItem
                          name={item.name}
                          rules={item.rules}
                          key={item.name}
                          className={item.className}
                          label={item.label}
                        >
                          <Input
                            placeholder={item.label}
                            maxLength={item.maxLength}
                          />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <p className='section-title'>Site Locations:</p>
                <p className='section-title'>
                  Please enter the address for staff to attend site (if
                  different from your main billing address). You can add
                  additional site locations later.
                </p>
                <div className='section-content'>
                  <Row gutter={24}>
                    {siteLocationsInputs(form).map((item) => (
                      <Col className='gutter-row' xs={24} xl={8}>
                        <FormItem
                          name={item.name}
                          label={item.label}
                          rules={item.rules}
                          key={item.name}
                          required={item.required}
                          className={item.className}
                        >
                          <Input placeholder={item.label} />
                        </FormItem>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              <div className='section'>
                <div className='section-content section-content_none_background'>
                  <p className='text-area-label'>
                    Please add any notes relevant to your account, any further
                    locations or other contacts that we should have on file.
                  </p>
                  <FormItem
                    className='primary-input-container text-area-container'
                    name='note'
                  >
                    <Textarea placeholder='Add extra notes here' />
                  </FormItem>
                </div>
              </div>
              <FormItem name='status' initialValue='inactivate' />

              <div className='footer-text'>
                <p>
                  One of the team will be in touch to discuss rates and any
                  further information and requirements.
                </p>
                <p>
                  If you have any issues whist filling in this form please email
                  {contact.email} or call {contact.phoneNumber} (opt 2)
                </p>
              </div>
              <FormItem
                name='isAcceptedReceivingUpdates'
                className='checkbox-container accept-privacy-policy'
                rules={[{ validator: rulesAcceptPrivacyPolicy }]}
                valuePropName='checked'
              >
                <Checkbox className='accept_privacy_policy primary-input-container'>
                  <span>I accept the </span>
                  <a
                    target='_blank'
                    href='https://www.gigtogig.co.uk/privacy-policy'
                    rel='noreferrer'
                  >
                    <span className='privacy-policy'>Privacy Policy</span>{' '}
                  </a>
                </Checkbox>
              </FormItem>
              <div className='button-container'>
                <Button
                  className='yellow-button submit-btn'
                  disabled={!isAcceptedReceivingUpdates}
                  htmlType='submit'
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </GuestFormCard>
      </div>
    </div>
  );
};

export default NewClientOnboardingForm;
