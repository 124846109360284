/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as EyeIcon } from '@/assets/icons/eyeIcon.svg';
import { ReactComponent as InvisibleEyeIcon } from '@/assets/icons/invisibleEyeIcon.svg';

import './styles.scss';

/**
 * @typedef {{isPassword: boolean}} AdditionalProps
 */

/**
 * @param {import('antd').InputProps & import('antd').InputProps & AdditionalProps } props
 * @return {JSX.Element}
 */
const Input = ({ className, isPassword, ...props }) => {
  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleVisiblePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const renderEyeicon = () =>
    visiblePassword ? (
      <InvisibleEyeIcon onClick={handleVisiblePassword} />
    ) : (
      <EyeIcon onClick={handleVisiblePassword} />
    );

  if (isPassword) {
    return (
      <AntdInput
        type={visiblePassword ? 'text' : 'password'}
        suffix={isPassword && renderEyeicon()}
        className={classNames('primary-input input-height', className)}
        {...props}
      />
    );
  }

  return (
    <AntdInput
      className={classNames('primary-input input-height', className)}
      {...props}
    />
  );
};

Input.propTypes = {
  className: PropTypes.string,
  isPassword: PropTypes.bool,
};

Input.defaultProps = {
  className: '',
  isPassword: false,
};

export default Input;
