import React from 'react';

import LeftSection from './leftSection';
import RightSection from './rightSection';

import './styles.scss';

const LeadForm = () => (
  <div className='create-lead-input-fields'>
    <LeftSection />
    <RightSection />
  </div>
);
export default LeadForm;
