/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Form, Table, message } from 'antd';
import classNames from 'classnames';
import {
  flatMap,
  flatMapDeep,
  get,
  isEmpty,
  isEqual,
  map,
  values,
} from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';

import { Button, FormItem, InputNumber, ModalConfirm } from '@/components';
import { updateBreak } from '@/services/timesheetService';

import { UPDATE_KEY } from '../constants';

import './styles.scss';

const DEFAULT_BREAK_VALUE = 0;

const BreakSection = ({ payrollStatus }) => {
  const queryClient = useQueryClient();
  const { id: timesheetDetailId } = useParams();
  const formInstance = Form.useFormInstance();
  const [isConfirm, toggleConfirm] = useToggle();
  const [updateKey, setUpdateKey] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { mutate: updateBreaksMutation, isLoading: isUpdatingBreak } =
    useMutation(
      ({ timecardIds, breakValue }) =>
        updateBreak({ id: timesheetDetailId, timecardIds, breakValue }),
      {
        onSuccess: () => {
          message.success('Update breaks success');
          queryClient.invalidateQueries({
            queryKey: ['timesheetDetail', timesheetDetailId],
          });
        },
        onError: (error) => message.error(error?.message ?? 'Update failed !'),
        onSettled: () => {
          toggleConfirm();
          setIsEdit(false);
        },
      },
    );

  const checkShouldUpdate = (prevValues, currentValues) => {
    const prevValuesJobType = get(prevValues, 'selectedTimecardIds');
    const currValuesJobType = get(currentValues, 'selectedTimecardIds');

    return !isEqual(prevValuesJobType, currValuesJobType);
  };

  const columns = [
    {
      title: '',
      render: () => <span>Add or update breaks</span>,
      width: '20%',
    },
    {
      title: '',
      render: () => (
        <FormItem
          name='breakValue'
          className='break-column'
          initialValue={DEFAULT_BREAK_VALUE}
        >
          <InputNumber min={0} className='break-input' readOnly={!isEdit} />
        </FormItem>
      ),
      width: '20%',
    },
    { title: '', render: () => null, width: '20%' },
    {
      title: '',
      width: '10%',
      render: () => (
        <Button
          className={classNames({
            'gray-button': !isEdit,
            'black-button': isEdit,
          })}
          onClick={() => setIsEdit(!isEdit)}
          disabled={payrollStatus !== 'pending'}
        >
          {isEdit ? 'Cancel' : 'Edit'}
        </Button>
      ),
    },
    {
      title: '',
      width: '15%',
      render: () => (
        <Button
          className={classNames({
            'yellow-button': true,
            hide: !isEdit,
          })}
          onClick={() => {
            setUpdateKey(UPDATE_KEY.all);
            toggleConfirm();
          }}
        >
          Update All
        </Button>
      ),
    },
    {
      title: '',
      width: '15%',
      render: () => (
        <FormItem shouldUpdate={checkShouldUpdate}>
          {({ getFieldValue }) => {
            const allTimecardIds = getFieldValue('selectedTimecardIds');
            return (
              <Button
                className={classNames({
                  'yellow-button': true,
                  hide: !isEdit,
                })}
                onClick={() => {
                  setUpdateKey(UPDATE_KEY.selected);
                  toggleConfirm();
                }}
                disabled={isEmpty(allTimecardIds)}
              >
                Update Selected
              </Button>
            );
          }}
        </FormItem>
      ),
    },
  ];

  const getAllTimecardIds = (formData) => {
    const timecardIds = flatMapDeep(formData, (jobs) =>
      flatMap(jobs, (job) =>
        flatMap(job, (timesheet) =>
          map(values(timesheet.timecards), (timecard) => timecard.id),
        ),
      ),
    );
    return timecardIds;
  };

  const getTimecardIds = {
    [UPDATE_KEY.all]: (formData) => {
      const currentJobs = formData?.jobTypeAdded?.current ?? {};
      return getAllTimecardIds(currentJobs);
    },
    [UPDATE_KEY.selected]: () =>
      formInstance.getFieldValue('selectedTimecardIds'),
  };

  const handleUpdateBreak = () => {
    const formPayRateData = formInstance.getFieldsValue();
    const timecardIds = getTimecardIds[updateKey](formPayRateData);
    const breakValue = get(formPayRateData, 'breakValue', 0);

    updateBreaksMutation({ timecardIds, breakValue });
  };

  return (
    <>
      <Table
        className='break-table'
        bordered
        showHeader={false}
        dataSource={[{}]}
        columns={columns}
        pagination={false}
        scroll={{ y: 200 }}
      />

      <ModalConfirm
        visible={isConfirm}
        isLoading={isUpdatingBreak}
        title='Update Breaks'
        onClickYes={handleUpdateBreak}
        onClickNo={toggleConfirm}
      >
        Are you sure you want to update the breaks to all selected records.
      </ModalConfirm>
    </>
  );
};

export default BreakSection;
