import React from 'react';

import { Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Button,
  DatePicker,
  FormItem,
  Input,
  MultipleSelect,
  Select,
} from '@/components';
import { listWeek } from '@/constants';
import { useProviders, useRoleItems } from '@/hooks/dropdowns';
import useHandleCancelDatePicker from '@/hooks/useCancelDatePicker';
import { listYear } from '@/pages/Timesheet/constants';
import { disabledDateOf } from '@/utils';

export const InputFields = ({ handleClearFilter }) => {
  const { data: listRoles } = useRoleItems();
  const formInstance = Form.useFormInstance();

  const handleCancelClickFrom = useHandleCancelDatePicker(
    formInstance,
    'weekStartDate',
  );
  const handleCancelClickTo = useHandleCancelDatePicker(
    formInstance,
    'weekEndDate',
  );

  const listStatuses = [
    {
      value: 1,
      label: 'Confirmed',
    },
    {
      value: 2,
      label: 'Finalised',
    },
  ];

  const listProviders = useProviders();
  const startDate = Form.useWatch('weekStartDate', formInstance);
  const endDate = Form.useWatch('weekEndDate', formInstance);

  return (
    <>
      <div className='row-container'>
        <FormItem
          label='Choose year'
          name='year'
          className='form-item'
          initialValue={moment().year()}
        >
          <MultipleSelect
            name='year'
            placeholder='Choose year'
            options={listYear}
            enableCustomTag={false}
          />
        </FormItem>
        <FormItem
          className='form-item'
          label='Choose Week'
          name='weekNum'
          initialValue={moment().week()}
        >
          <Select placeholder='Choose Week' options={listWeek} />
        </FormItem>
      </div>
      <div className='row-container'>
        <FormItem name='searchTerm' className='form-item' label='Search'>
          <Input placeholder='Name or email address' />
        </FormItem>
        <FormItem name='skillIds' className='form-item' label='Roles'>
          <MultipleSelect
            placeholder='Role'
            options={listRoles}
            name='skillIds'
          />
        </FormItem>
        <FormItem name='providerId' className='form-item' label='Provider'>
          <MultipleSelect
            placeholder='Provider'
            options={listProviders}
            name='providerId'
          />
        </FormItem>
        <FormItem name='payrollStatus' className='form-item' label='Status'>
          <MultipleSelect
            placeholder='Status'
            options={listStatuses}
            name='payrollStatus'
          />
        </FormItem>
        <div className='form-item date-container'>
          <FormItem name='weekStartDate' className='date-item' label='Job Date'>
            <DatePicker
              name='weekStartDate'
              disabledDate={(disabledDate) =>
                disabledDateOf.Start(disabledDate, endDate)
              }
              onCancelClick={handleCancelClickFrom}
            />
          </FormItem>
          <FormItem name='weekEndDate' className='date-item'>
            <DatePicker
              name='weekEndDate'
              disabledDate={(disabledDate) =>
                disabledDateOf.End(disabledDate, startDate)
              }
              onCancelClick={handleCancelClickTo}
            />
          </FormItem>
        </div>
        <div className='date-button-container'>
          <Button
            htmlType='submit'
            type='primary'
            className='yellow-button button-item'
          >
            Search
          </Button>
          <Button
            onClick={handleClearFilter}
            className='filter-button button-item'
          >
            Clear Filter
          </Button>
        </div>
      </div>
    </>
  );
};

InputFields.propTypes = {
  handleClearFilter: PropTypes.func,
};

InputFields.defaultProps = {
  handleClearFilter: () => {},
};

export default InputFields;
