import request from '@/request';
import {
  FormatIndexStrategy,
  Normalize,
  normalizeDropdownResponse,
} from '@/utils/normalizeResponse';

const getListPayTemplate = async (providerId, locationId) => {
  const res = await request.get('options/payroll_templates', {
    params: {
      filter: {
        provider: providerId,
        location: locationId,
      },
    },
  });

  const normalize = new Normalize();
  const strategy = new FormatIndexStrategy(res);
  normalize.setStrategy(strategy);
  const payTemplatesDetail = normalize.formatResponse()?.data;

  return {
    templates: normalizeDropdownResponse(res),
    payTemplatesDetail,
  };
};

export default getListPayTemplate;
