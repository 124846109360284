import normalize from 'json-api-normalizer';
import moment from 'moment';

import request from '@/request';

const getOne = async (leadId) => {
  const response = await request.get(`/providers/leads/${leadId}`);

  const { lead } = normalize(response.data);

  /**
   * @attributes attributes
   * @property {string} attributes.name,
   * @property {string} attributes.phone,
   * @property {string} attributes.postCode,
   * @property {number} attributes.managerId,
   * @property {string} attributes.internalNote
   * @property {string} attributes.city,
   * @property {string} attributes.company,
   * @property {string} attributes.createdAt,
   * @property {string} attributes.email,
   * @property {number} attributes.id,
   * @property {number} attributes.localOffice,
   * @property {number} attributes.status,
   * @property {string} attributes.tellUs,
   */
  const {
    type,
    attributes: { name, phone, postCode, managerId, internalNote, ...attrs },
  } = lead[`${leadId}`];

  // Handle text status response from server
  return {
    ...attrs,
    type,
    fullName: name,
    phoneNumber: phone,
    note: internalNote,
    sitePostCode: postCode,
    allocatedAccountManager: managerId,
    registrationDate: moment(attrs.createdAt),
  };
};

export default getOne;
