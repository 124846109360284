import React from 'react';

import moment from 'moment';
import * as PropTypes from 'prop-types';

import ListView from '@/components/ListView';
import './styles.scss';

const columns = [
  {
    title: 'Author',
    dataIndex: 'createdBy',
  },
  {
    title: 'Comment',
    dataIndex: 'content',
  },
  {
    title: 'Created at',
    render: (_, record) => (
      <div>{moment(record.createdAt).format('DD/MM/YYYY HH:MM')}</div>
    ),
  },
];

const Comments = ({ employeeFname, employeeSname, comments }) => (
  <div className='comment-container'>
    <h3>
      {employeeFname} {employeeSname} comments
    </h3>

    {comments.length > 0 ? (
      <ListView infoDetail={comments} headerColumns={columns} />
    ) : (
      <p className='text-gray'>No comment</p>
    )}
  </div>
);

Comments.propTypes = {
  employeeFname: PropTypes.string,
  employeeSname: PropTypes.string,
  comments: PropTypes.array,
};

Comments.defaultProps = {
  comments: [],
};

export default Comments;
