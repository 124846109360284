import request from '@/request';

const validateProvider = async ({ id, phone, email }) => {
  const { data } = await request.get('/guests/providers/validate', {
    params: {
      id,
      phone,
      email,
    },
  });
  return data?.data;
};

export default validateProvider;
