import React from 'react';

import { MinusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Input, FormItem, DatePicker, TimePicker, Select } from '@/components';
import { disabledDateOf } from '@/utils';
import './styles.scss';
import { convertTimeMoment } from '@/utils/convertTimeMoment';

import { coverDateToStringFormatDMY, postedOption } from '../../constant';

const ShiftInput = ({
  name,
  value,
  required,
  label,
  disabled,
  isDisableTime,
  fixedStartDate,
  fixedEndDate,
  onChange,
  onRemove,
  disablePosted,
  errors,
}) => {
  const formInstance = Form.useFormInstance();

  const postedGroup = Form.useWatch('posted', formInstance);
  const payrollTemplate = Form.useWatch('payrollTemplate', formInstance);

  value.break = payrollTemplate ? 0 : value?.break ?? 0;

  const handleChangeDate = (vl) => {
    onChange({
      ...value,
      endDate: moment(vl),
      endTime: convertTimeMoment(vl, value?.endTime.format('HH:mm a')),
    });
  };

  const handleChangeTime = (timeString, isStartTime = true) => {
    if (isStartTime) {
      value = {
        ...value,
        startTime: convertTimeMoment(value.startDate, timeString),
      };
    } else {
      value = {
        ...value,
        endTime: convertTimeMoment(value.endDate, timeString),
      };
    }

    onChange(value);
  };

  const onChangeBreakTime = (event) => {
    onChange({ ...value, break: event.target.value });
  };

  const onChangePosted = (changeValue) => {
    onChange({
      ...value,
      posted: changeValue,
    });
  };

  return (
    <>
      <Row className='job-datetime' gutter={5}>
        <Col span={4} className='date-picker-style startDate'>
          {coverDateToStringFormatDMY(value.startDate)}
        </Col>
        <Col span={3}>
          <FormItem
            name={name.startTime}
            label={label.startTime}
            required={required}
          >
            <TimePicker
              value={moment(value?.startTime, 'HH:mm')}
              disabled={isDisableTime || disabled}
              onChange={(time, timeString) =>
                handleChangeTime(timeString, true)
              }
              name={name?.startTime}
              format='HH:mm'
              suffixIcon={null}
              className='date-picker-style'
            />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem
            name={name.endDate}
            required={required}
            label={label.endDate}
          >
            <DatePicker
              className='date-picker-style end-date'
              value={value.endDate}
              suffixIcon={null}
              format='DD MMM'
              onChange={handleChangeDate}
              name={name.endDate}
              disabled={isDisableTime || disabled}
              disabledDate={(disabledDate) => {
                const fixedEndDateWeek =
                  fixedEndDate && moment(fixedEndDate).week();
                const currentWeekNumber = moment().week();

                if (currentWeekNumber === fixedEndDateWeek) {
                  return (
                    disabledDateOf.Start(disabledDate, moment()) ||
                    disabledDateOf.End(disabledDate, moment(fixedStartDate))
                  );
                }

                if (fixedStartDate && fixedEndDate) {
                  return (
                    disabledDateOf.Start(disabledDate, moment(fixedEndDate)) ||
                    disabledDateOf.End(disabledDate, moment(fixedStartDate))
                  );
                }
                return false;
              }}
            />
          </FormItem>
        </Col>
        <Col span={3}>
          <FormItem
            name={name.endTime}
            label={label.endTime}
            required={required}
          >
            <TimePicker
              value={moment(value?.endTime, 'HH:mm')}
              suffixIcon={null}
              className='date-picker-style'
              disabled={isDisableTime || disabled}
              onChange={(time, timeString) =>
                handleChangeTime(timeString, false)
              }
              name={name?.endTime}
              format='HH:mm'
            />
          </FormItem>
        </Col>
        <Col span={4}>
          <FormItem name={name.break}>
            <Input
              value={value.break ?? 0}
              className='date-picker-style'
              onChange={onChangeBreakTime}
              type='number'
              disabled={disabled || payrollTemplate}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-', ',', '.'].includes(evt.key) &&
                evt.preventDefault()
              }
            />
          </FormItem>
        </Col>

        <Col span={4}>
          <FormItem name={name.posted}>
            <Select
              value={disablePosted ? postedGroup : value.posted ?? 0}
              className='date-picker-style'
              options={postedOption}
              onChange={onChangePosted}
              disabled={disablePosted || disabled}
            />
          </FormItem>
        </Col>
        <Col span={2}>
          <FormItem>
            <Button
              type='primary'
              shape='circle'
              icon={<MinusOutlined />}
              size={20}
              onClick={onRemove}
              disabled={disabled}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Form.ErrorList
          errors={errors}
          className='ant-form-item-explain-error'
        />
      </Row>
    </>
  );
};

ShiftInput.propTypes = {
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.object,
  value: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.object,
  isDisableTime: PropTypes.bool,
  fixedStartDate: PropTypes.string,
  fixedEndDate: PropTypes.string,
  disablePosted: PropTypes.bool,
  errors: PropTypes.array,
};

ShiftInput.defaultProps = {
  onChange: () => {},
  onRemove: () => {},
  name: {},
  value: {},
  required: false,
  label: {},
  disabled: false,
  isDisableTime: false,
  disablePosted: false,
  errors: [],
};

export default ShiftInput;
