import React from 'react';

import { Table as AntTable } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const ListView = ({ infoDetail, columns, pagination, ...props }) => (
  <AntTable
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className='list-view-container'
    dataSource={infoDetail}
    columns={columns}
    dividers={{ left: true, right: true }}
    pagination={pagination}
  />
);

export default ListView;

ListView.propTypes = {
  infoDetail: PropTypes.array,
  columns: PropTypes.array,
  pagination: PropTypes.bool,
};

ListView.defaultProps = {
  infoDetail: [],
  columns: [],
  pagination: false,
};
