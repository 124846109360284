/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Table as AntTable } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const TemplatesTable = ({
  infoDetail,
  columns,
  pagination,
  borderCell,
  ...props
}) => (
  <AntTable
    {...props}
    className={classNames('pay-templates-table', {
      borderCell,
    })}
    dataSource={infoDetail}
    columns={columns}
    dividers={{ left: true, right: true }}
    pagination={pagination}
  />
);

export default TemplatesTable;

TemplatesTable.propTypes = {
  infoDetail: PropTypes.array,
  columns: PropTypes.array,
  pagination: PropTypes.bool,
  borderCell: PropTypes.bool,
};

TemplatesTable.defaultProps = {
  infoDetail: [],
  columns: [],
  pagination: false,
  borderCell: false,
};
