import React from 'react';

import { Col, Row } from 'antd';
import FormList from 'antd/lib/form/FormList';
import moment from 'moment';
import PropTypes from 'prop-types';

import { convertTimeMoment } from '@/utils/convertTimeMoment';

import ShiftInput from './ShiftInput';

import './styles.scss';

const Shifts = ({ items, onChange, disabledTime, disablePosted, disabled }) => {
  const listShift = items.map((e) => {
    const dateStart = moment(e.startAt).toDate();
    const hourStart = moment(e.startAt).toDate().getHours();
    const minuteStart = moment(e.startAt).toDate().getMinutes();

    const dateEnd = moment(e.endAt).toDate();
    const hourEnd = moment(e.endAt).toDate().getHours();
    const minuteEnd = moment(e.endAt).toDate().getMinutes();

    return {
      startDate: dateStart,
      startTime: convertTimeMoment(dateStart, `${hourStart}:${minuteStart}`),
      endDate: dateEnd,
      endTime: convertTimeMoment(dateEnd, `${hourEnd}:${minuteEnd}`),
      break: e.break,
      posted: e.posted,
    };
  });

  const onChangeShift = (item, index) => {
    const startTime = moment(item.startTime);
    const endTime = moment(item.endTime);

    const startDate = moment(item.startDate).set({
      hour: startTime.get('hour'),
      minute: startTime.get('minute'),
    });

    const endDate = moment(item.endDate).set({
      hour: endTime.get('hour'),
      minute: endTime.get('minute'),
    });

    items[index] = {
      startAt: startDate.valueOf(),
      endAt: endDate.valueOf(),
      break: item.break,
      posted: item.posted,
    };

    onChange(items);
  };

  const onRemoveShift = (index) => {
    const dataUpdate = [...items];
    if (dataUpdate.length > 1) {
      dataUpdate.splice(index, 1);
      onChange(dataUpdate);
    }
  };

  return (
    <div className='shifts-list' id='items'>
      <Row gutter={5}>
        <Col span={4} />
        <Col span={3}>Start</Col>
        <Col span={4}>End Date</Col>
        <Col span={3}>Finish</Col>
        <Col span={4}>Break</Col>
        <Col span={4}>Posted</Col>
        <Col span={2} />
      </Row>

      <FormList name='items'>
        {(fields, _, { errors }) =>
          listShift.map((shift, index) => (
            <ShiftInput
              value={shift}
              onChange={(change) => onChangeShift(change, index)}
              onRemove={() => onRemoveShift(index)}
              disabledTime={disabledTime}
              disablePosted={disablePosted}
              disabled={disabled}
              errors={[errors[index]]}
            />
          ))
        }
      </FormList>
    </div>
  );
};

Shifts.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  disabledTime: PropTypes.bool,
  disablePosted: PropTypes.bool,
  disabled: PropTypes.bool,
};

Shifts.defaultProps = {
  items: [],
  onChange: () => {},
  disabledTime: false,
  disablePosted: false,
  disabled: false,
};

export default Shifts;
