import { isEmpty, max } from 'lodash';
import moment from 'moment';

import { INDUSTRY } from '@/constants';
import request from '@/request';
import { mergePreferredRoles } from '@/utils/forcePreferredRoles';
import { handleUploadFiles } from '@/utils/upLoadFiles';

import { getAddressInfomation } from '../getAddressInfomation';

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

const seekerUploadFiles = async ({
  eLearningDocs,
  documentationDocs,
  licenceDocs,
  rightToWorkDocs,
  blankBoxs = [],
}) => {
  const eLearningDocsFilesObject = {};
  const documentationDocsFilesObject = {};
  const licenceDocsFilesObject = {};
  const rightToWorkDocsFilesObject = {};
  const blankBoxsObject = blankBoxs.map((item) => [
    item.fileBlob,
    `${item.boxType}-${item.typeValue}`,
  ]);

  eLearningDocs.forEach((item) => {
    eLearningDocsFilesObject[item.typeValue] = item.fileBlob;
  });
  documentationDocs.forEach((item) => {
    documentationDocsFilesObject[item.typeValue] = item.fileBlob;
  });
  licenceDocs.forEach((item) => {
    licenceDocsFilesObject[item.typeValue] = item.fileBlob;
  });
  rightToWorkDocs.forEach((item) => {
    rightToWorkDocsFilesObject[item.typeValue] = item.fileBlob;
  });

  const filesUploaded = await handleUploadFiles([
    [documentationDocsFilesObject?.contractFile, 'contractFile'],
    [documentationDocsFilesObject?.cvUpload, 'cvUpload'],
    [documentationDocsFilesObject?.profilepic, 'profilepic'],
    [documentationDocsFilesObject?.studentLetter, 'studentLetter'],
    [documentationDocsFilesObject?.visaBrpFile, 'visaBrpFile'],
    [documentationDocsFilesObject?.p45Document, 'p45Document'],
    [documentationDocsFilesObject?.proofAddress, 'proofAddress'],
    [documentationDocsFilesObject?.proofNi, 'proofNi'],
    [documentationDocsFilesObject?.secondaryId, 'secondaryId'],
    [documentationDocsFilesObject?.dbsCheck, 'dbsCheck'],
    [licenceDocsFilesObject?.drivingLicense, 'drivingLicense'],
    [licenceDocsFilesObject?.scooterLicense, 'scooterLicense'],
    [licenceDocsFilesObject?.forkLiftTruck, 'forkLiftTruck'],
    [licenceDocsFilesObject?.hgvLicense, 'hgvLicense'],
    [licenceDocsFilesObject?.sia, 'sia'],
    [licenceDocsFilesObject?.welder, 'welder'],
    [eLearningDocsFilesObject?.manualHanding, 'manualHanding'],
    [eLearningDocsFilesObject?.foodSafetyAllergens, 'foodSafetyAllergens'],
    [eLearningDocsFilesObject?.alcoholLicense, 'alcoholLicense'],
    [eLearningDocsFilesObject?.llopTraining, 'llopTraining'],
    [eLearningDocsFilesObject?.induction, 'induction'],
    [eLearningDocsFilesObject?.spectatorsSafety, 'spectatorsSafety'],
    [rightToWorkDocsFilesObject?.shareCodeFile, 'shareCodeFile'],
    [rightToWorkDocsFilesObject?.passportId1File, 'passportId1File'],
    [rightToWorkDocsFilesObject?.universityLetter, 'universityLetter'],
    [rightToWorkDocsFilesObject?.passportId2File, 'passportId2File'],
    [rightToWorkDocsFilesObject?.birthCertificate, 'birthCertificate'],
    [rightToWorkDocsFilesObject?.visaRtw, 'visaRtw'],
    [
      rightToWorkDocsFilesObject?.universityTermDateLetterRtw,
      'universityTermDateLetterRtw',
    ],
    [rightToWorkDocsFilesObject?.niProof, 'niProof'],
    ...blankBoxsObject,
  ]);

  return filesUploaded;
};

export const updateSeekerProfile = async ({ id, seekerForm }) => {
  let updatedData = seekerForm.getFieldsValue();

  const {
    rightToWorkDocs,
    shareCodeFileValue,
    passportId1FileValue,
    passportId1FileBlob,
    shareCodeFileBlob,
    employeeWpExpiry,
    universityLetterFileBlob,
    universityLetterFileValue,
    studentHolidaysAttributes,
    vacancyApplicantReferencesAttributes,
    birth,
    isFirstJobNoReference,
    eLearnings,
    licences,
    siteAllocation,
    p46s,
  } = updatedData;

  const locationIds = siteAllocation.map((item) => ({
    employerLocationId: item.employerLocationId,
    ...(item.id && { id: item.id }),
    // eslint-disable-next-line no-underscore-dangle
    ...(item._destroy && { _destroy: true }),
  }));
  updatedData.omniEmployeeAccessSitesAttributes = locationIds;

  const formattedVacancyApplicantReferencesAttributes =
    vacancyApplicantReferencesAttributes.map((item) => {
      const { startAt, endAt } = item;
      if (startAt && endAt) {
        return {
          ...item,
          startAt: moment(item.startAt).format('DD-MM-YYYY'),
          endAt: moment(item.endAt).format('DD-MM-YYYY'),
        };
      }

      return item;
    });

  // Upload files
  const blankBoxs = [];
  const documentationDocs = [];

  updatedData.documentationDocs.forEach((doc) => {
    if (doc?.typeValue.includes('blankBox') ?? false) {
      doc.boxType = 'documentation';
      blankBoxs.push(doc);
    } else {
      documentationDocs.push(doc);
    }
  });

  const rightToWorkDocsMapped = rightToWorkDocs ?? [];

  if (passportId1FileValue) {
    rightToWorkDocsMapped.push({
      fileBlob: passportId1FileBlob,
      typeValue: 'passportId1File',
      fileValue: 'passportId1FileValue',
      typeLabel: 'Passport',
    });
  }

  if (shareCodeFileValue) {
    rightToWorkDocsMapped.push({
      fileBlob: shareCodeFileBlob,
      typeValue: 'shareCodeFile',
      fileValue: 'shareCodeFileValue',
      typeLabel: 'Passport',
    });
  }

  if (universityLetterFileValue) {
    rightToWorkDocsMapped.push({
      fileBlob: universityLetterFileBlob,
      typeValue: 'universityLetter',
      fileValue: 'universityLetterValue',
      typeLabel: 'Student Holiday',
    });
  }

  const listFileUpload = await seekerUploadFiles({
    eLearningDocs: [],
    documentationDocs,
    licenceDocs: [],
    rightToWorkDocs: rightToWorkDocsMapped,
    blankBoxs,
  });

  const blankBoxesAttributes = blankBoxs
    .map((item) => ({
      name: item.typeLabel,
      boxType: item.boxType,
      link: listFileUpload[
        `${item.boxType}-${item.typeValue.replaceAll(' ', '_')}`
      ],
      date: item.date ? moment(item.date)?.format() : null,
      id: item.id,
    }))
    .filter((item) => item.link);

  updatedData = {
    ...updatedData,

    passportId1File: listFileUpload?.passportId1File,
    employeeId1Expiry: moment.isMoment(updatedData?.employeeId1Expiry)
      ? updatedData.employeeId1Expiry.format()
      : updatedData?.employeeId1Expiry,
    passportId2File: listFileUpload?.passportId2File,

    p45Document: listFileUpload?.p45Document,
    contractFile: listFileUpload?.contractFile,
    profilepic: listFileUpload?.profilepic,

    visaRtw: listFileUpload?.visaRtw,
    universityTermDateLetterRtw: listFileUpload?.universityTermDateLetterRtw,

    visaBrpFile: listFileUpload?.visaBrpFile,
    blankBoxesAttributes,

    employeeWpDoctype: listFileUpload?.employeeWpDoctype,
  };

  updatedData.employeeAttributes = {
    ...updatedData.employeeAttributes,
    profilepic: listFileUpload?.profilepic,
    employeeCertificatesAttributes: [
      ...eLearnings.map((eLearning) => {
        return {
          ...eLearning,
          expiresAt: moment(eLearning.expiresAt).format(),
          attachment: eLearning.attachment?.signedId,
        };
      }),
      ...licences.map((licence) => {
        return {
          ...licence,
          expiresAt: moment(licence.expiresAt).format(),
          attachment: licence.attachment?.signedId,
        };
      }),
    ],
  };

  // Handle Student Holiday
  const listStudentHoliday = (studentHolidaysAttributes ?? []).map((item) => ({
    id: item?.id,
    holiday_from: item?.startDate && formatDate(item?.startDate),
    holiday_until: item?.endDate && formatDate(item?.endDate),
  }));

  updatedData = {
    ...updatedData,
    omniEmployeeStudentVacationsAttributes: listStudentHoliday,
  };

  updatedData.vacancyApplicantAttributes = {
    ...updatedData.vacancyApplicantAttributes,
    birth: moment(birth).format('DD/MM/YYYY'),
    isFirstJobNoReference,
    omniEmployeeStudentVacationsAttributes: listStudentHoliday,
    universityLetter: listFileUpload?.universityLetter,
    studentLetter: listFileUpload?.studentLetter,
    proofAddress: listFileUpload?.proofAddress,
    proofNi: listFileUpload?.proofNi,
    niProof: listFileUpload?.niProof,
    secondaryId: listFileUpload?.secondaryId,
    dbsCheck: listFileUpload?.dbsCheck,

    drivingLicense: listFileUpload?.drivingLicense,
    scooterLicense: listFileUpload?.scooterLicense,
    forkLiftTruck: listFileUpload?.forkLiftTruck,
    hgvLicense: listFileUpload?.hgvLicense,

    manualHanding: listFileUpload?.manualHanding,
    foodSafetyAllergens: listFileUpload?.foodSafetyAllergens,
    alcoholLicense: listFileUpload?.alcoholLicense,
    llopTraining: listFileUpload?.llopTraining,
    induction: listFileUpload?.induction,

    birthCertificate: listFileUpload?.birthCertificate,

    spectatorsSafety: listFileUpload?.spectatorsSafety,
    sia: listFileUpload?.sia,
    welder: listFileUpload?.welder,

    shareCodeFile: listFileUpload?.shareCodeFile,

    cvUpload: listFileUpload?.cvUpload,
    blankBoxesAttributes,
    p46s,
  };

  updatedData.omniEmployeeAttributes = {
    ...updatedData.omniEmployeeAttributes,
    visaBrpFile: listFileUpload?.visaBrpFile,
    p45Document: listFileUpload?.p45Document,
    passportId1File: listFileUpload?.passportId1File,
    passportId2File: listFileUpload?.passportId2File,
  };

  const {
    address,
    address2,
    city,
    zip,
    hasConvict,
    convict,
    industryExperiencesVacancyApplicantsAttributes,
    industryExperienceValueInput,
  } = updatedData.vacancyApplicantAttributes;

  const geogecode = await getAddressInfomation(
    `${address} ${address2} ${city} ${zip}, United Kingdom`,
  );

  const { geometry } = geogecode[0];
  if (isEmpty(updatedData.strikeStatus)) updatedData.strikeStatus = 0;
  else updatedData.strikeStatus = max(updatedData.strikeStatus) + 1;

  updatedData.vacancyApplicantReferencesAttributes =
    formattedVacancyApplicantReferencesAttributes;

  updatedData.vacancyApplicantAttributes = {
    ...updatedData.vacancyApplicantAttributes,
    preferredRoles: mergePreferredRoles(
      updatedData.vacancyApplicantAttributes.preferredRoles,
    ),
    vacancyApplicantLanguagesAttributes:
      updatedData.vacancyApplicantLanguagesAttributes,
    vacancyApplicantEmploymentsAttributes:
      updatedData.vacancyApplicantEmploymentsAttributes,
    vacancyApplicantReferencesAttributes:
      formattedVacancyApplicantReferencesAttributes,
    locationAttributes: {
      latitude: geometry.location.lat,
      longitude: geometry.location.lng,
    },
    convict: hasConvict ? convict : '',
    industryExperiencesVacancyApplicantsAttributes:
      industryExperiencesVacancyApplicantsAttributes.map((item) => ({
        industryExperienceId: item,
        otherValue: item === INDUSTRY.OTHER ? industryExperienceValueInput : '',
      })),

    employeeWpExpiry:
      typeof employeeWpExpiry === 'object'
        ? employeeWpExpiry?.format()
        : employeeWpExpiry, // this is a moment,
  };

  /* This is must check equal undefined because block type can equal 0 */
  if (updatedData.omniUserBlockedOmniEmployeeAttributes.blockType === undefined)
    delete updatedData.omniUserBlockedOmniEmployeeAttributes;
  delete updatedData.rightToWorkDocs;
  delete updatedData.documentationDocs;
  delete updatedData.eLearningDocs;
  delete updatedData.licenceDocs;
  delete updatedData.shareCodeFileBlob;
  delete updatedData.shareCodeFileValue;
  delete updatedData.studentHolidaysAttributes;

  await request.patch(`/seekers/employees/${id}`, {
    employee: updatedData,
  });
};
