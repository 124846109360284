import request from '@/request';

const sickNoShow = async ({ timesheetId, timecardIds, noShowAttributes }) => {
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/timecards/sick_no_show`;
  const payload = {
    timecardIds,
    noShowAttributes,
  };

  const res = await request.patch(endpoint, {
    ...payload,
  });
  return res.data;
};

export default sickNoShow;
