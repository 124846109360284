import React from 'react';

import { Form, InputNumber } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ColorCheckbox, FormItem, TimePicker } from '@/components';
import { time } from '@/utils';

import { DISABLED_STATUS } from '../../constants';
import {
  handleBreakChange,
  handleCheckinCheckoutChange,
} from '../../utils/inputChange';
import { checkValue } from '../../utils/rules';
import { checkAndSelectAll, checkAndSelectRows } from '../../utils/select';
import SickNoShowDetail from './SickNoShowDetail';

import './styles.scss';

const HoursCell = ({ status, jobTypeId, date, record, isEdit }) => {
  const formInstance = Form.useFormInstance();
  const { key } = record;
  const isShowCell = _.has(record.timecards, date);
  const isDisabledStatus = DISABLED_STATUS.includes(status);

  const sicknessInfo = _.get(
    record,
    ['timecards', date, 'attributes', 'noShow'],
    null,
  );
  const isSickness = !_.isEmpty(sicknessInfo);
  const isDisabledStatusNoShow = isDisabledStatus || isSickness;

  const HIDE_TYPES = {
    inOverview: !(isEdit && isShowCell),
    inEdit: !isShowCell && isEdit,
  };

  const HOURS_CELL_PATHS = [
    `${jobTypeId}`,
    `${key}`,
    'timecards',
    `${date}`,
    'attributes',
  ];

  const handleCheckCell = () => {
    const formData = formInstance.getFieldsValue();

    checkAndSelectRows({ formData, jobTypeId, timesheetDetailId: key });
    checkAndSelectAll({ formData, jobTypeId });

    formInstance.setFieldsValue(formData);
  };

  const handleCheckValue = () =>
    checkValue({ form: formInstance, namePath: HOURS_CELL_PATHS });

  return (
    <div className='hour-cell-container'>
      <FormItem
        {...(isShowCell && { name: HOURS_CELL_PATHS.concat(['id']) })}
        hidden
      />
      <FormItem
        {...(isShowCell && {
          name: HOURS_CELL_PATHS.concat(['schedule', 'checkin']),
        })}
        hidden={HIDE_TYPES.inOverview}
        rules={[handleCheckValue]}
        getValueFromEvent={(value) =>
          handleCheckinCheckoutChange({
            value,
            form: formInstance,
            namePath: HOURS_CELL_PATHS,
            checkType: 'checkin',
          })
        }
      >
        <TimePicker disabled={isDisabledStatusNoShow} format='HH:mm' />
      </FormItem>
      <FormItem
        {...(isShowCell && {
          name: HOURS_CELL_PATHS.concat(['schedule', 'checkout']),
        })}
        hidden={HIDE_TYPES.inOverview}
        rules={[handleCheckValue]}
        getValueFromEvent={(value) =>
          handleCheckinCheckoutChange({
            value,
            form: formInstance,
            namePath: HOURS_CELL_PATHS,
            checkType: 'checkout',
          })
        }
      >
        <TimePicker disabled={isDisabledStatusNoShow} format='HH:mm' />
      </FormItem>
      <FormItem
        {...(isShowCell && {
          name: HOURS_CELL_PATHS.concat(['schedule', 'break']),
        })}
        hidden={HIDE_TYPES.inOverview}
        rules={[handleCheckValue]}
        getValueFromEvent={(value) =>
          handleBreakChange({
            value,
            form: formInstance,
            namePath: HOURS_CELL_PATHS,
          })
        }
      >
        <InputNumber
          disabled={isDisabledStatusNoShow}
          min={0}
          keyboard={false}
          controls={false}
        />
      </FormItem>
      <FormItem
        {...(isShowCell && {
          name: HOURS_CELL_PATHS.concat(['schedule', 'value']),
        })}
        hidden={HIDE_TYPES.inEdit}
      >
        <InputNumber
          readOnly
          bordered={false}
          className='hide-shadow'
          formatter={time.convertTimeHoursCell}
        />
      </FormItem>
      <FormItem
        {...(isShowCell && {
          name: HOURS_CELL_PATHS.concat(['isCheckedCell']),
        })}
        className='cell-select color-select yellow checkbox'
        hidden={HIDE_TYPES.inOverview}
        valuePropName='checked'
        dependencies={[`${jobTypeId}`, `${key}`, 'isCheckedRow']}
      >
        <ColorCheckbox disabled={isDisabledStatus} onChange={handleCheckCell} />
      </FormItem>
      <FormItem
        {...(isShowCell &&
          isSickness && {
            name: HOURS_CELL_PATHS.concat(['noShow', 'noShowType']),
          })}
        hidden={!isSickness}
      >
        <SickNoShowDetail
          namePath={HOURS_CELL_PATHS}
          sicknessInfo={sicknessInfo}
        />
      </FormItem>
    </div>
  );
};

export default HoursCell;

HoursCell.propTypes = {
  status: PropTypes.string,
  jobTypeId: PropTypes.number,
  date: PropTypes.string,
  record: PropTypes.object,
  isEdit: PropTypes.bool,
};

HoursCell.defaultProps = {
  status: '',
  jobTypeId: null,
  date: '',
  record: {},
  isEdit: false,
};
