import request from '@/request';

const postRota = async (payload) => {
  const endpoint = '/rotas/rota_shift_plans/create_real_shifts';

  const rotaShiftPlansAttributes = payload?.map((dt) => ({
    id: dt?.id,
    numberOfRequired: parseFloat(dt.numberOfRequired),
  }));

  const resData = await request.post(endpoint, {
    rotaShiftPlans: { rotaShiftPlansAttributes },
  });

  return resData;
};

export default postRota;
