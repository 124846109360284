import React from 'react';
import { useTenantContext } from '../../../../../TenantWrapper';

const EntryCodes = () => {
  const { currency } = useTenantContext();

  return (
    <div className='card'>
      <div className='title'>
        <span>Payroll Entry Codes</span>
      </div>
      <div className='body'>
        <div className='text'>
          <span>Deduction Hours</span>
          <span>DH</span>
        </div>
        <div className='text'>
          <span>Deduction Money</span>
          <span>D{currency}</span>
        </div>
        <div className='text'>
          <span>Bonus Hours</span>
          <span>BH</span>
        </div>
        <div className='text'>
          <span>Bonus {currency}.</span>
          <span>B{currency}</span>
        </div>
      </div>
    </div>
  );
};

export default EntryCodes;
