import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';

export const updateJobSeeker = async (rowId, seekerList, status) => {
  try {
    const formatedData = seekerList?.map((id) => ({ id, status }));
    const endpoint = `/jobs/positions/${rowId}/job_responses/status`;
    const payload = { position: { jobResponsesAttributes: formatedData } };
    return await request.patch(endpoint, payload);
  } catch (error) {
    throw new ServiceError(error);
  }
};
