import React from 'react';

import Medical from './medical';
import Reference from './reference';
import './styles.scss';

const MedicalInformation = () => (
  <div className='ref-medical'>
    <div>
      <Reference />
    </div>
    <div>
      <Medical />
    </div>
  </div>
);
export default MedicalInformation;
