import React from 'react';

import { find, get } from 'lodash';

import { COST_ROLES } from '@/constants';
import Formatter from '@/utils/Formatter';

import { ADDITIONAL_TYPES } from '../../constants';

const getRoleNameById = ({ roles, roleId }) =>
  get(
    find(roles, (role) => role.value === roleId),
    'label',
    '',
  );

const getServiceById = (serviceId) =>
  find(COST_ROLES, (costRole) => costRole.value === serviceId)?.label;

const getLabel = {
  [ADDITIONAL_TYPES.labour.key]: ({ roles, roleId }) =>
    getRoleNameById({ roles, roleId }),
  [ADDITIONAL_TYPES.cost.key]: ({ serviceId }) => getServiceById(serviceId),
};

const generateColumns = ({ desc, roles, type }) => [
  {
    title: desc,
    width: '60%',
    render: (_, record) => {
      const { role: roleId, service: serviceId } = record;
      const label = getLabel[type]({ roleId, roles, serviceId });

      const sectionInfo = {
        labour: (
          <div>
            <div>{label}</div>
            <div>{record.templateName}</div>
            <div>{record.hourTemplateName}</div>
          </div>
        ),
        cost: <div>{label}</div>,
      };

      return sectionInfo[type];
    },
  },
  {
    title: 'Net Amount',
    width: '20%',
    dataIndex: 'omniNetPay',
    render: (omniNetPay) => Formatter.currencyGBP(omniNetPay),
  },
  {
    title: '20% VAT',
    width: '20%',
    dataIndex: 'omniVat',
    render: (omniVat) => Formatter.currencyGBP(omniVat),
  },
];

export default generateColumns;
