import React from 'react';

import classNames from 'classnames';

import { checkURLIsAnImage } from '@/utils/uploadFileUtil';

import ThumbnailFile from '../ThumbnailFile';

import './styles.scss';

// eslint-disable-next-line react/prop-types
const AutoRenderImageOrPDF = ({ data, containerClassName, isImage }) => {
  // check base64 or url is a valid image
  const checkIfImage =
    checkURLIsAnImage(data) || String(data).includes('data:image') || isImage;

  return (
    <ThumbnailFile
      src={data}
      type={checkIfImage ? 'image' : 'application'}
      containerClassName={classNames('applicant-file', containerClassName)}
    />
  );
};

export default AutoRenderImageOrPDF;
