import request from '@/request';

const createGIGProviderUser = async (dataFormSubmited) => {
  const payload = {
    lastName: dataFormSubmited.lastName,
    password: dataFormSubmited.password,
    position: dataFormSubmited.position,
    workEmail: dataFormSubmited.workEmail,
    firstName: dataFormSubmited.firstName,
    accessName: dataFormSubmited.accessName,
    department: dataFormSubmited.department,
    workNumber: dataFormSubmited.workNumber,
    accessLavel: dataFormSubmited.accessLavel,
    listAccessName: dataFormSubmited.listAccessName,
    repeatPassword: dataFormSubmited.repeatPassword,
  };
  return request.post('/create-gigprovider-user', payload);
};

export default createGIGProviderUser;
