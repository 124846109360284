import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Form, Pagination, Button, Spin } from 'antd';
import moment from 'moment';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { GeneralTemplate, Modal, ListView, CSV } from '@/components';
import { candidateStatuses } from '@/constants';
import { useSessionStorage } from '@/hooks';
import { ChangeStatusCandidateService, CandidatesService } from '@/services';
import downloadCandidateCSV from '@/services/candidateService/downloadCSV';
import { getAddressInfomation } from '@/services/getAddressInfomation';
import { switchStatus, array } from '@/utils';

import CandidateFields from './candidateFields';

import './styles.scss';

const breadCrumb = [{ item: 'Candidate', to: '/recruitment/candidate' }];

const CandidateView = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const refCSVLink = useRef(null);

  const [rowKey, setRowKey] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc');
  const [statusValue, setStatusValue] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const [visibleStatusModal, setVisibleStatusModal] = useState({
    title: '',
    content: '',
    visible: false,
  });

  const [storedValue, setSession, removeSession] =
    useSessionStorage('candidateSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { visible, title, content } = visibleStatusModal;

  // Fetch candidate page
  const {
    data: dataApplicantPage,
    refetch,
    isFetching,
  } = useQuery(
    ['applicant-page', currentPage, pageSize, sortOrder],
    async () => {
      const {
        status,
        officeId,
        generalRole,
        preferredRoles,
        registrationDateFrom,
        registrationDateTo,
        zip,
        ...restFormValues
      } = form.getFieldsValue();

      const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

      const filter = {
        generalRole,
        preferredRoles,
        officeId,
        registrationDateFrom:
          registrationDateFrom &&
          moment(registrationDateFrom).format('YYYY-MM-DD'),
        registrationDateTo:
          registrationDateTo && moment(registrationDateTo).format('YYYY-MM-DD'),
        status: array.toStringOfNumber(status),
        latitude: geogecode?.[0]?.geometry?.location?.lat,
        longitude: geogecode?.[0]?.geometry?.location?.lng,
        zip: geogecode?.[0]?.address_components?.[0]?.long_name,
        ...restFormValues,
      };

      const params = {
        page: {
          number: currentPage,
          size: pageSize,
        },
        order: {
          orderBy: 'created_at',
          orderDirection: sortOrder,
        },
        filter,
      };

      return CandidatesService.getCandidates(params);
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      initialData: [],
    },
  );

  // Set change status
  const { mutate: changeStatus } = useMutation(
    // eslint-disable-next-line no-shadow
    ({ rowKey, statusValue }) =>
      ChangeStatusCandidateService.changeStatusCandidate(rowKey, statusValue),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  // Fetch CSV
  const {
    isFetching: isFetchingDownloadCsv,
    data: dataCandidateExportCsv = [],
    refetch: refetchCandidateExportCSV,
  } = useQuery(
    ['downloadCsv'],
    async () => {
      const {
        status,
        officeId,
        generalRole,
        preferredRoles,
        registrationDateFrom,
        registrationDateTo,
        zip,
        ...restFormValues
      } = form.getFieldsValue();

      const geogecode = zip ? await getAddressInfomation(`${zip}-uk`) : [];

      const filter = {
        generalRole,
        preferredRoles,
        officeId,
        registrationDateFrom:
          registrationDateFrom &&
          moment(registrationDateFrom).format('YYYY-MM-DD'),
        registrationDateTo:
          registrationDateTo && moment(registrationDateTo).format('YYYY-MM-DD'),
        status: array.toStringOfNumber(status),
        latitude: geogecode?.[0]?.geometry?.location?.lat,
        longitude: geogecode?.[0]?.geometry?.location?.lng,
        zip: geogecode?.[0]?.address_components?.[0]?.long_name,
        ...restFormValues,
      };

      const params = {
        order: {
          orderBy: 'created_at',
          orderDirection: sortOrder,
        },
        filter,
      };

      return downloadCandidateCSV(params);
    },
    {
      cacheTime: 0,
      retry: false,
      enabled: false,
      onSuccess: () => {
        setIsDownload(true);
      },
    },
  );

  useEffect(() => {
    if (isDownload) {
      refCSVLink.current.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const handleSearchData = () => {
    setCurrentPage(1);
    setSession(form.getFieldsValue());
    refetch();
  };

  const handleChangePage = (page) => setCurrentPage(page);

  const handleSort = (order) => {
    setCurrentPage(1);
    return order === 'ascend' ? setSortOrder('asc') : setSortOrder('desc');
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const handleChangeStatus = (id, value) => {
    setStatusValue(value);
    setRowKey(id);
    const status = switchStatus.switchCandidateStatus(value);

    setVisibleStatusModal({
      visible: true,
      title: status.title,
      content: status.content,
    });
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    removeSession();
    form.resetFields();
    refetch();
  };

  const handleConfirmStatus = useCallback(() => {
    changeStatus({ rowKey, statusValue });
    setVisibleStatusModal({ visible: false });
  }, [changeStatus, rowKey, statusValue]);

  const onRow = (row) => {
    setRowKey(row);
    history.push(`/edit-candidate/${row}`);
  };

  return (
    <GeneralTemplate
      mainItem='Recruitment'
      buttonContent='Create Candidate'
      headerText='Candidates'
      data={breadCrumb}
      buttonLink='/create-candidate'
      className='candidate-page'
    >
      <Spin spinning={isFetchingDownloadCsv}>
        <Form className='wrap-content' form={form} onFinish={handleSearchData}>
          <CandidateFields
            setCurrentPage={setCurrentPage}
            onClearFilter={handleClearFilter}
          />
        </Form>
        <div className='wrap-content'>
          <div className='view-box'></div>
        </div>
        <ListView
          pagination={false}
          scroll={{ y: 650 }}
          loading={isFetching}
          options={candidateStatuses}
          locale={{
            emptyText: 'No Candidate match your search criteria',
          }}
          handlePropagation={handlePropagation}
          infoDetail={dataApplicantPage?.applicantListInfo ?? []}
          onRow={(row) => onRow(row)}
          handleSort={handleSort}
          onChange={handleChangeStatus}
          isCandidate
        />
        <div className='candidate-paginations'>
          <div className='pagination-container'>
            <Pagination
              responsive
              pageSize={pageSize}
              current={currentPage}
              total={dataApplicantPage?.totalCount}
              onChange={handleChangePage}
              showSizeChanger
              onShowSizeChange={(current, pageSizes) => {
                setCurrentPage(current);
                setPageSize(pageSizes);
              }}
            />
          </div>
          <Button
            className='yellow-button download-csv-btn'
            onClick={refetchCandidateExportCSV}
            disabled={isFetchingDownloadCsv}
          >
            {isFetchingDownloadCsv ? 'Loading csv...' : 'Download CSV'}
          </Button>
          <CSV
            ref={refCSVLink}
            filename='data-candidate.csv'
            data={dataCandidateExportCsv}
          />
        </div>
      </Spin>
      <Modal
        visible={visible}
        title={title}
        onClickNo={() => setVisibleStatusModal({ visible: false })}
        onClickYes={handleConfirmStatus}
      >
        {content}
      </Modal>
    </GeneralTemplate>
  );
};

export default CandidateView;
