/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Table as AntdTable } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const Table = ({ className, columns, infoDetail, ...props }) => (
  <AntdTable
    {...props}
    dataSource={infoDetail}
    className={classNames('table', className)}
    pagination={false}
    columns={columns}
  />
);

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  infoDetail: PropTypes.array,
};

Table.defaultProps = {
  className: '',
  columns: [],
  infoDetail: [],
};

export default Table;
