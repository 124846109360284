import React from 'react';

import { Col, Form, Row } from 'antd';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { Button, FormItem, InputNumber, Select } from '@/components';
import { DEFAULT_PAYRATE, RATE_TYPES, REGEX_PAY_RATE } from '@/constants';
import useRoleItems from '@/hooks/dropdowns/useRoleItems';

import { GUTTER_FOR_ANTD } from '../../constant';
import RateTable from './RateTable';
import { useTenantContext } from '../../../../../../../TenantWrapper';

import './styles.scss';

const RateCard = () => {
  const { data: roleItems } = useRoleItems();
  const { currency } = useTenantContext();

  const formInstance = Form.useFormInstance();
  const chargeRatesAttributes =
    Form.useWatch('chargeRatesAttributes', formInstance) ?? [];

  const validateDuplicate = (/** @type {number} */ index) => ({
    validator: async () => {
      const existedIndex = chargeRatesAttributes.findIndex(
        (item) =>
          item.skillId === chargeRatesAttributes[index]?.skillId &&
          item.rateType === chargeRatesAttributes[index]?.rateType,
      );

      if (existedIndex !== index) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          'Rate with this position and  this type is exist',
        );
      }
      return Promise.resolve();
    },
    message: 'Rate with this position and  this type is exist',
  });

  const rulesPayRate = [
    {
      required: true,
      message: 'Please enter Pay Rate',
    },
    {
      pattern: REGEX_PAY_RATE,
      message: 'Pay Rate is not valid',
    },
    {
      validator: async (_, val) => {
        const valNumber = parseFloat(val);

        if (valNumber < Number(DEFAULT_PAYRATE)) {
          throw new Error(
            `Pay rate must be larger than or equal to ${DEFAULT_PAYRATE}`,
          );
        }
      },
      message: `Pay rate must be larger than or equal to ${DEFAULT_PAYRATE}`,
    },
  ];

  const rulesChargeRate = [
    {
      required: true,
      message: 'Please enter Charge Rate',
    },
    {
      pattern: REGEX_PAY_RATE,
      message: 'Charge Rate is not valid',
    },
    {
      validator: async (_, val) => {
        const valNumber = parseFloat(val);

        if (valNumber < Number(DEFAULT_PAYRATE)) {
          throw new Error(
            `Charge rate must be larger than or equal to ${DEFAULT_PAYRATE}`,
          );
        }
      },
      message: `Charge rate must be larger than or equal to ${DEFAULT_PAYRATE}`,
    },
  ];
  const onChangeRateType = (index, val) => {
    const existedIndex = chargeRatesAttributes.findIndex(
      (item) =>
        item.skillId === chargeRatesAttributes[index]?.skillId &&
        item.rateType === val,
    );

    if (existedIndex < 0) {
      formInstance.setFields([
        {
          name: ['chargeRatesAttributes', index, 'skillId'],
          errors: [],
        },
      ]);
    }
  };

  const onChangePosition = (index, val) => {
    const existedIndex = chargeRatesAttributes.findIndex(
      (item) =>
        item.skillId === val &&
        item.rateType === chargeRatesAttributes[index]?.rateType,
    );

    if (existedIndex < 0) {
      formInstance.setFields([
        {
          name: ['chargeRatesAttributes', index, 'rateType'],
          errors: [],
        },
      ]);
    }
  };

  return (
    <div className='rate-card profile-provider__collapse'>
      <Row gutter={GUTTER_FOR_ANTD.SMALL}>
        <RateTable />
        <FormItem name='chargeRatesList' hidden />
        <Col span={12} className='rate-card-table__wrapper '>
          <div className='profile-provider__collapse__box'>
            <div className='input-group__label'>Rates</div>
            <Form.List name='chargeRatesAttributes' initialValue={[]}>
              {(fields, { remove, add }) => (
                <div className='rate-card--calculator__params'>
                  <Row gutter={GUTTER_FOR_ANTD.SMALL}>
                    <Col span={8} className='header-row'>
                      Position
                    </Col>
                    <Col span={5} className='header-row'>
                      Rate type
                    </Col>
                    <Col span={5} className='header-row'>
                      Pay Rate
                    </Col>
                    <Col span={5} className='header-row'>
                      Charge Rate
                    </Col>
                    <Col span={1} />
                  </Row>
                  {fields.map((_field, index) => (
                    <Row
                      align='middle'
                      justify='center'
                      className='rates-item___row profile-provider__collapse__row'
                      gutter={GUTTER_FOR_ANTD.SMALL}
                    >
                      <Col span={8}>
                        <FormItem
                          className='custom-select'
                          name={[index, 'skillId']}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose Position',
                            },
                            validateDuplicate(index),
                          ]}
                        >
                          <Select
                            value={chargeRatesAttributes[index]?.skillId}
                            options={roleItems}
                            placeholder='Position'
                            onChange={(val) => onChangePosition(index, val)}
                          />
                        </FormItem>
                      </Col>
                      <Col span={5}>
                        <FormItem
                          className='custom-select'
                          name={[index, 'rateType']}
                          rules={[
                            {
                              required: true,
                              message: 'Please choose type',
                            },
                            validateDuplicate(index),
                          ]}
                          initialValue={RATE_TYPES[0].value}
                        >
                          <Select
                            value={chargeRatesAttributes[index]?.rateType}
                            options={RATE_TYPES}
                            defaultValue={RATE_TYPES[0].value}
                            placeholder='Rate type'
                            onChange={(val) => onChangeRateType(index, val)}
                          />
                        </FormItem>
                      </Col>
                      <Col span={5}>
                        <FormItem
                          name={[index, 'payRate']}
                          initialValue={0}
                          rules={rulesPayRate}
                        >
                          <InputNumber
                            placeholder='Pay rate'
                            type='number'
                            prefix={currency}
                            precision={2}
                            size='large'
                          />
                        </FormItem>
                      </Col>
                      <Col span={5}>
                        <FormItem
                          name={[index, 'chargeRate']}
                          initialValue={0}
                          rules={rulesChargeRate}
                        >
                          <InputNumber
                            placeholder='Charge Rate'
                            type='number'
                            prefix={currency}
                            size='large'
                            precision={2}
                          />
                        </FormItem>
                      </Col>

                      <Col span={1}>
                        <div className='delete-column'>
                          <XIconBlack
                            onClick={() => {
                              remove([index]);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
                  <Row justify='end'>
                    <Button
                      className='yellow-button button__add-more'
                      onClick={() => add()}
                    >
                      Add Another
                    </Button>
                  </Row>
                </div>
              )}
            </Form.List>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default RateCard;
