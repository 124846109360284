import React from 'react';

import { Tooltip } from 'antd';

import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

export const columns = generateHeaderTable([
  {
    title: 'Provider',
    dataIndex: 'providerName',
    key: 'providerName',
  },
  {
    title: 'Location',
    dataIndex: 'locationName',
    key: 'locationName',
  },
  {
    title: 'Job Type',
    dataIndex: 'jobRole',
    key: 'jobRole',
  },
  {
    title: 'Job Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ value) => (
      <Tooltip placement='topLeft' title={value}>
        {value}
      </Tooltip>
    ),
  },
  {
    title: 'Date Posted',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Pay Rate',
    dataIndex: 'payrate',
    key: 'payrate',
  },
  {
    title: 'Shift Date and Time',
    dataIndex: 'scheduleItem',
    key: 'scheduleItem',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a - b,
    render: (item) => (
      <div>
        <p>
          {item.startDate}
          {item.endDate && '-'}
        </p>
        <p>{item.endDate}</p>
        <p>{item.time}</p>
      </div>
    ),
  },
  {
    title: 'Total Hours',
    dataIndex: 'scheduleItem',
    key: 'scheduleItem',
    render: (item) => (
      <div>
        <p>{item?.totalHours}</p>
      </div>
    ),
  },
  {
    title: 'Gross Pay',
    dataIndex: 'grossPay',
    key: 'grossPay',
  },
  {
    title: 'NET Pay',
    dataIndex: 'netPay',
    key: 'netPay',
    render: (item) => <p>{Formatter.fixedNumber({ value: item })}</p>,
  },
]);

export const mockupData = [];

for (let i = 0; i < 10; i += 1) {
  mockupData.push({
    key: i.toString(),
    provider: 'London',
    location: 'iForce, Geddington Road',
    jobType: 'Warehouse Operative',
    jobDesc: 'We are looking for enthusiastic waite…',
    datePosted: '01/06/2020',
    payRate: '£10.00',
    shiftDate: '01/06/2022 10:00 - 22:00',
    totalHours: 10,
    grossPay: '£10.00',
    netPay: '£10.00',
  });
}
