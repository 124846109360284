/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Form } from 'antd';
import { debounce } from 'lodash';

import { FormItem, Input } from '@/components';
import { rateCard } from '@/utils';

const EditTableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  weekNumber,
  employerId,
  providerRateCard,
  ...props
}) => {
  const { id } = record ?? {};

  const formInstance = Form.useFormInstance();

  const payrateWatching = Form.useWatch('payrate', formInstance);

  const handleOnPayRateChange = debounce(({ target = {} }, name) => {
    if (name === 'payrate') {
      const { value: payrate } = target;

      const chargeRate = rateCard.calcChargeRate(
        providerRateCard,
        parseFloat(payrate),
      );

      formInstance.setFieldsValue({ chargeRate });
    }
  }, 500);

  return (
    <td {...props}>
      {editing ? (
        <FormItem
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
            {
              validator: async (_, value) => {
                if (dataIndex === 'chargeRate') {
                  if (Number(value) <= Number(payrateWatching)) {
                    return Promise.reject(
                      new Error('Value must be greater than payrate'),
                    );
                  }
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            className={`input-height input-edit ${dataIndex}_${id}`}
            onKeyDown={(event) =>
              [('e', 'E', '+', '-')].includes(event.key) &&
              event.preventDefault()
            }
            onChange={(e) => handleOnPayRateChange(e, dataIndex)}
          />
        </FormItem>
      ) : (
        <FormItem name={dataIndex} className={`${dataIndex}_${id}`}>
          {children}
        </FormItem>
      )}
    </td>
  );
};

export default EditTableCell;
