import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Form, Popover, message } from 'antd';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useToggle } from 'usehooks-ts';

import { ReactComponent as CalendarIcon } from '@/assets/icons/calendarIcon.svg';
import { useFetch } from '@/hooks';
import { JobServices } from '@/services';
import { downloadSeekerListCsv } from '@/services/jobServices';
import { time } from '@/utils';

import { getBackgroundColor } from '../utils';
import Actions from './Actions';
import AssignEvent from './WeekView/AssignEvent';

/**
 *
 * @param {{
 * event: Object,
 * title: String,
 * setRepostId: Function,
 * setJobIdEdit: Function,
 * setShowPopupCreate: Function
 * refetchCalendarJobs: Function,
 * openManageWorkerModal: Function,
 * setModalContent: Function,
 * }} params- The event object containing information about the event.
 * @return {JSX.Element} The rendered event component.
 */
const Event = ({
  event,
  title,
  setRepostId,
  setJobIdEdit,
  setShowPopupCreate,
  refetchCalendarJobs,
  openManageWorkerModal,
  setModalContent,
}) => {
  const formInstance = Form.useFormInstance();
  const isCompletedTab = Form.useWatch('isCompletedTab', formInstance);

  const [isExpandAssignEvent, toggleExpandAssignEvent] = useToggle();
  const date = time.formatDate(event.start, 'Do MMM');
  const endTime = time.formatDate(event.schedule.lastItem?.end, 'HH:mm');
  const startTime = time.formatDate(event.start, 'HH:mm');

  const locationName = get(event, 'locationName', '');

  const totalAssignments = get(event, 'totalAssignments', 0);
  const applied = get(event, 'applied', 0);
  const totalFills = get(event, 'totalFills', 0);
  const status = get(event, 'status', null);

  const background = getBackgroundColor(status, totalFills, totalAssignments);

  const { mutate: deleteJobShift } = useMutation(
    (id) => JobServices.deleteJob(id),
    {
      onSuccess: () => {
        refetchCalendarJobs();
        setModalContent({ visible: false });
      },

      onError: () => {
        message.error('Delete Job fail');
      },
    },
  );

  const { mutate: promote } = useMutation(
    () => JobServices.promoteJob(event.id),
    {
      onSuccess: () => {
        setModalContent({ visible: false });
        refetchCalendarJobs();
      },
    },
  );

  const deleteShift = () =>
    setModalContent({
      visible: true,
      titleModal: 'Delete?',
      content: (
        <div>
          Are you sure you want to delete this shift? <br /> Applied and
          confirmed worker lists cannot be retrieved.
        </div>
      ),
      titleSuccess: 'Deleted shift succesfully',
      onClickYes: () => deleteJobShift(event.id),
    });

  const promoteSelectedJob = () => {
    setModalContent({
      visible: true,
      titleModal: 'Promote?',
      content: 'Are you sure you want to promote this job?',
      titleSuccess: 'Promoted job successfully',
      onClickYes: () => promote(),
    });
  };

  const { refetch: downloadCSVJobs } = useFetch(
    ['download-seeker-job-csv'],
    (activeTab) =>
      downloadSeekerListCsv({
        positionId: event.id,
        dateExport: date,
        status: activeTab,
      }),
    {
      onSuccess: (res) => {
        fileDownload(res, `download-seeker-job-${date}.xls`);
      },
      enabled: false,
    },
  );

  return (
    <div className='calendar-event-container'>
      <div className={classNames('calendar-event', background)}>
        <div className='event-header'>
          <div className='left'>
            <div className='job-role'>{title}</div>
          </div>
          <div className='right'>
            {event.schedule?.length > 1 && (
              <Popover
                trigger='click'
                className='event-popover'
                content={
                  <div className='event-schedule'>
                    {event.schedule?.map((item) => (
                      <div className='event-schedule-item'>
                        <div className='event-schedule-item-date'>
                          {moment(item.start).format('DD MMM')}
                        </div>
                        <div className='event-schedule-item-hour'>
                          {moment(item.start).format('hh:mm')} -
                          {moment(item.end).format('hh:mm')}
                        </div>
                      </div>
                    ))}
                  </div>
                }
              >
                <CalendarIcon className='calendar-icon' />
              </Popover>
            )}

            <Actions
              deleteShift={deleteShift}
              promoteSelectedJob={promoteSelectedJob}
              setJobIdEdit={setJobIdEdit}
              setRepostId={setRepostId}
              setShowPopupCreate={setShowPopupCreate}
              event={event}
              openManageWorkerModal={openManageWorkerModal}
              isCompletedTab={isCompletedTab}
            />
          </div>
        </div>
        <div className='event-location-container'>
          <h4 className='event-location'>{locationName}</h4>
          <div>
            <h6 className='event-rate'>{event.hours}hrs at</h6>
            <h6 className='event-rate'>£{event.chargeRate}</h6>
          </div>
        </div>

        <div className='between-row'>
          <div className='left'>{date}</div>
          <div className='right'>A: {applied}</div>
        </div>
        <div className='between-row'>
          <div className='left'>
            {startTime} - {endTime}
          </div>
          <div className='right'>
            F: {totalFills}/{totalAssignments}
          </div>
        </div>
        <div className='expand-event'>
          <DownOutlined onClick={toggleExpandAssignEvent} />
        </div>
      </div>
      {isExpandAssignEvent && (
        <AssignEvent
          isExpandAssignEvent={isExpandAssignEvent}
          eventId={event.id}
          date={moment(event.start).format('YYYY-MM-DD')}
          refetchCalendarJobs={refetchCalendarJobs}
          downloadCSVJobs={downloadCSVJobs}
        />
      )}
    </div>
  );
};

export default Event;

Event.propTypes = {
  event: PropTypes.object,
  title: PropTypes.string,
  setJobIdEdit: PropTypes.func,
  setRepostId: PropTypes.func,
  setShowPopupCreate: PropTypes.func,
  refetchCalendarJobs: PropTypes.func,
  openManageWorkerModal: PropTypes.func,
  setModalContent: PropTypes.func,
};

Event.defaultProps = {
  event: {},
  title: '',
};
