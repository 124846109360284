import { camelizeKeys } from 'humps';
import { groupBy } from 'lodash';

import { ORDER_BY_PROVIDER } from '@/constants';
import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getInvoices = async ({
  size,
  number,
  weekNum,
  searchTerm,
  employerId,
  orderDirection,
  year,
  invoiceNumbers,
}) => {
  const response = await request.get('/trackers/invoicing/timesheets', {
    params: {
      page: {
        size,
        number,
      },
      order: {
        orderBy: ORDER_BY_PROVIDER,
        orderDirection,
      },
      filter: {
        weekNum,
        searchTerm,
        employerId,
        year,
        invoiceNumbers,
      },
    },
  });

  const normalizedData = normalizeIndexResponse(response);
  const groupedInvoices = camelizeKeys(
    groupBy(normalizedData?.data, 'paymentType'),
  );

  return {
    ...normalizedData,
    data: groupedInvoices,
  };
};

export default getInvoices;
