export const extendRole = (
  /** @type {number} */ roleId,
  /** @type {{label: string, value: number}[]} */ roles,
) => {
  if (roles.map((item) => item.value).includes(roleId)) return roles;

  return roles.concat({
    label: 'Other role',
    value: roleId,
  });
};
