import React, { useMemo } from 'react';

import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal as AntdModal, Collapse, Skeleton, Spin, message } from 'antd';
import { map, startCase, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import jobs from '@/assets/icons/jobs.png';
import defaultAvt from '@/assets/images/defaultAvatar.png';
import { Image, ListView } from '@/components';
import { ACCESS_TYPES } from '@/constants';
import { useFetch, usePagination } from '@/hooks';
import { useVacancyApplicantEmploymentPosition } from '@/hooks/dropdowns';
import { getJobsSeeker } from '@/services/seeker/getJobsSeeker';
import { getSeekerProfile } from '@/services/seeker/getSeekerProfile';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import { useCertificates } from '@/hooks/useCertificates';

import Comments from './Comments';
import { toArray } from '../../../utils/array';
import { SEEKER_JOB_STATUS, levelLanguage } from './constants';

import './styles.scss';

const { Panel } = Collapse;

const columns = generateHeaderTable([
  {
    title: 'Date',
    dataIndex: 'scheduleItem',
    render: ({ startDate, endDate }) =>
      endDate ? `${startDate} - ${endDate}` : startDate,
  },
  {
    title: 'Role',
    dataIndex: 'jobRole',
    key: 'jobRole',
  },
  {
    title: 'Provider',
    dataIndex: 'providerName',
    key: 'providerName',
  },
]);

const ModalJobSeeker = ({ visibleModal, setVisibleModal, seekerId }) => {
  const listRoles = useVacancyApplicantEmploymentPosition();

  const {
    currentPage,
    handlePagination,
    setCurrentPage,
    setPaginationPageSize,
    paginationPageSize,
  } = usePagination();

  const onShowSizeChange = (current, pageSize) => {
    setPaginationPageSize(pageSize);
    setCurrentPage(current);
  };

  const { data: certificates } = useCertificates();

  const { data: seekerInfo, isFetching } = useFetch(
    ['seekers', seekerId],
    () => getSeekerProfile(seekerId),
    {
      onError: () => message.error('Load seeker information failed'),
      initialData: {},
    },
  );

  const defaultPageNumber = 1;
  const defaultPageSize = 100;

  const params = {
    sortOrder: 'desc',
    orderBy: 'shift_time',
    pageNumber: defaultPageNumber,
    pageSize: defaultPageSize,
  };

  const {
    data: { data: appliedJobs },
    isLoading: isLoadingApplied,
  } = useFetch(
    [seekerId, SEEKER_JOB_STATUS.APPLIED],
    () =>
      getJobsSeeker({
        id: seekerId,
        ...params,
        filter: {
          statuses: [SEEKER_JOB_STATUS.APPLIED],
        },
      }),
    {
      onError: () => message.error('Load applied failed'),
      initialData: [],
    },
  );

  const {
    data: { data: confirmedJobs },
    isLoading: isLoadingConfirmed,
  } = useFetch(
    [seekerId, SEEKER_JOB_STATUS.CONFIRMED],
    () =>
      getJobsSeeker({
        id: seekerId,
        ...params,
        filter: {
          statuses: [SEEKER_JOB_STATUS.CONFIRMED],
        },
      }),
    {
      onError: () => message.error('Load confirmed failed'),
      initialData: [],
    },
  );

  const {
    data: { data: pastJobs },
    isLoading: isLoadingPastGigInfo,
  } = useFetch(
    [seekerId, SEEKER_JOB_STATUS.PAST],
    () =>
      getJobsSeeker({
        id: seekerId,
        ...params,
        filter: {
          statuses: [SEEKER_JOB_STATUS.PAST],
        },
      }),
    {
      onError: () => message.error('Load pastGIG failed'),
      initialData: [],
    },
  );

  const {
    employeeRefNo,
    employeeFname,
    employeeSname,
    profilepic,
    vacancyApplicantLanguagesAttributes,
    vacancyApplicantAttributes: employee,
    vacancyApplicantEmploymentsAttributes,
    rotaTypes,
    omniEmployeeAccessSiteAttributes,
    rotas,
    commentAttributes: comments,
    employeeTotalActiveAssignments,
  } = seekerInfo;

  const renderComments = () => (
    <Comments
      comments={comments}
      currentPage={currentPage}
      employeeFname={employeeFname}
      employeeSname={employeeSname}
      handlePagination={handlePagination}
      onShowSizeChange={onShowSizeChange}
      paginationPageSize={paginationPageSize}
      totalCount={comments?.totalCount}
    />
  );

  const renderExperience = useMemo(
    () =>
      (vacancyApplicantEmploymentsAttributes ?? []).map((employment) => {
        const position = listRoles.find(
          ({ value }) => value === employment?.positionId,
        );

        return (
          <div key={employment.leave} className='experience-container'>
            <h3>
              {position?.label ?? 'No experience'} at {employment?.name}
            </h3>
            <p className='text-gray'>{employment?.leave ?? 'No Description'}</p>
          </div>
        );
      }),
    [listRoles, vacancyApplicantEmploymentsAttributes],
  );

  const renderLicences = () => {
    return (
      <div className='licences-and-doc-container'>
        {toArray(seekerInfo?.employeeCertificateAttributes || []).reduce(
          (result, { id, name, slug }) => {
            const certificate = certificates[slug];

            if (!certificate) {
              return result;
            }

            result.push(
              <div key={id} className='icon-item'>
                <img
                  src={certificate.icon}
                  alt=''
                  style={{ width: '24px', height: '24px' }}
                />
                <span className='text-item-style'>{name}</span>
              </div>,
            );

            return result;
          },
          [],
        )}
      </div>
    );
  };

  const renderLanguages = () =>
    Object.values(vacancyApplicantLanguagesAttributes ?? {}).map((lang) => {
      if (lang === undefined) {
        return null;
      }

      const { name, level } = lang;

      return (
        <p className='languages seeker-text lang'>
          {name} - {levelLanguage[level]}
        </p>
      );
    });

  const convertNameRotaType = (/** @type {string} */ text) => {
    const newText = text.replace(/_only$/, ''); // Replace underscore and "only" at the end with empty string
    return startCase(newText.replace('_', ' '));
  };

  const renderRotaType = () =>
    map(rotaTypes, (rotaType) => (
      <>
        <div className='seeker-rota-type'>{convertNameRotaType(rotaType)}</div>
        {rotaType === ACCESS_TYPES.Specific_Site && (
          <>
            {map(
              uniqBy(omniEmployeeAccessSiteAttributes, 'employerId'),
              (item) => (
                <div className='specific-site'>{item?.employerName}</div>
              ),
            )}
          </>
        )}
        {rotaType === ACCESS_TYPES.Rota_Only && (
          <>
            {map(rotas, (item) => (
              <div className='specific-site'>
                {item?.employerName} - {item?.employerLocationName} -{' '}
                {item?.contractType}
              </div>
            ))}
          </>
        )}
      </>
    ));

  const renderExpandIcon = () => <DownOutlined />;

  return (
    <AntdModal
      centered
      visible={visibleModal}
      footer={null}
      width={1000}
      onOk={() => setVisibleModal(false)}
      onCancel={() => setVisibleModal(false)}
      wrapClassName='modal-job-seeker'
    >
      <div className='seeker-container'>
        {(isFetching ||
          isLoadingPastGigInfo ||
          isLoadingApplied ||
          isLoadingConfirmed) && (
          <div className='spin-loading'>
            <Spin
              size='large'
              indicator={<LoadingOutlined className='spin-loading-coin' spin />}
            />
          </div>
        )}
        <div className='personal-info'>
          <img
            className='avatar-seeker'
            src={profilepic ?? defaultAvt}
            alt='profile'
          />
          <div className='seeker-basic-info'>
            <Link to={`/seeker-profile/${employeeRefNo}`}>
              <Skeleton
                title={{ width: '100%' }}
                active
                loading={isFetching}
                paragraph={{ rows: 0 }}
              >
                <div className='seeker-name'>{`${employeeFname} ${employeeSname}`}</div>
              </Skeleton>
            </Link>
            <div className='total-jobs'>
              <Image
                containerClassName='seeker-jobs-icon-container'
                src={jobs}
                alt='seeker-total-jobs'
              />
              {employeeTotalActiveAssignments}
            </div>
            <div className='basic-info'>
              <div>
                <p className='seeker-text'>
                  <span>Home Office:</span>
                  <span>London</span>
                </p>
                <p className='seeker-text'>
                  <span>Phone Number:</span>
                  <Skeleton
                    title={{ width: '100%' }}
                    active
                    loading={isFetching}
                    paragraph={{ rows: 0 }}
                  >
                    <span>{`${employee?.phone}`}</span>
                  </Skeleton>
                </p>
              </div>
              <div className='seeker-language'>
                <div className='language-title'>
                  <p className='seeker-text'>Languages:</p>
                </div>

                <Skeleton
                  paragraph={{ rows: 2, width: '100%' }}
                  title={{ width: '100%' }}
                  active
                  loading={isFetching}
                >
                  {renderLanguages()}
                </Skeleton>
              </div>
              <div className='seeker-language'>
                <div className='language-title'>
                  <p className='seeker-text'>Rota Type:</p>
                </div>

                <Skeleton
                  paragraph={{ rows: 2, width: '100%' }}
                  title={{ width: '100%' }}
                  active
                  loading={isFetching}
                >
                  {renderRotaType()}
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
        <div className='general-info'>
          <Collapse expandIconPosition='right' expandIcon={renderExpandIcon}>
            <Panel header='Licences/ Training' key='Licences/ Training'>
              {renderLicences()}
            </Panel>
            <Panel header='Applied' key='Applied'>
              <ListView
                headerColumns={columns}
                infoDetail={appliedJobs}
                scroll={{ y: 300 }}
              />
            </Panel>
            <Panel header='Confirmed' key='Confirmed'>
              <ListView
                headerColumns={columns}
                infoDetail={confirmedJobs}
                scroll={{ y: 300 }}
              />
            </Panel>
            <Panel header='Past Shifts' key='Past Jobs'>
              <ListView
                headerColumns={columns}
                infoDetail={pastJobs}
                scroll={{ y: 300 }}
              />
            </Panel>
            <Panel header='Experience' key='Experience'>
              {renderExperience}
            </Panel>
            <Panel header='Comments' key='Comments'>
              {renderComments()}
            </Panel>
          </Collapse>
        </div>
      </div>
    </AntdModal>
  );
};

export default ModalJobSeeker;

ModalJobSeeker.propTypes = {
  visibleModal: PropTypes.bool,
  setVisibleModal: PropTypes.func,
  seekerId: PropTypes.number,
};
