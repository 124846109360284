import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { DatePicker, FormItem } from '@/components';
import { disabledDateOf } from '@/utils';

const SelectDate = ({ startDateFieldName, endDateFieldName, label }) => {
  const form = Form.useFormInstance();
  const startDate = Form.useWatch('registrationDateFrom', form);
  const endDate = Form.useWatch('registrationDateTo', form);

  return (
    <div className='date-selecter'>
      <FormItem name={startDateFieldName} className='form-item' label={label}>
        <DatePicker
          disabledDate={(disabledDate) =>
            disabledDateOf.Start(disabledDate, endDate)
          }
          placeholder='Select Date'
          value={startDate}
          name={startDateFieldName}
        />
      </FormItem>

      <FormItem name={endDateFieldName} className='form-item'>
        <DatePicker
          disabledDate={(disabledDate) =>
            disabledDateOf.End(disabledDate, startDate)
          }
          placeholder='Select Date'
          value={endDate}
          name={endDateFieldName}
        />
      </FormItem>
    </div>
  );
};

SelectDate.propTypes = {
  startDateFieldName: PropTypes.string,
  endDateFieldName: PropTypes.string,
  label: PropTypes.string,
};

SelectDate.defaultProps = {
  startDateFieldName: '',
  endDateFieldName: '',
  label: '',
};

export default SelectDate;
