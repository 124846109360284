import { camelizeKeys } from 'humps';

import request from '@/request';

const pullBackRecord = async ({
  /** @type {string/number} */ timesheetId,
  /** @type {array} */ timecardIds,
  /** @type {string/number} */ pushedToTimesheetId,
}) => {
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/timecards/pull_records`;
  const res = await request.post(endpoint, {
    timecardIds,
    pushedToTimesheetId,
  });

  return camelizeKeys(res.data);
};
export default pullBackRecord;
