import axios from 'axios';

import { ServiceError } from '@/utils/errorUtil';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const getAddressInfomation = async (address) => {
  try {
    const response = await axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json',
      {
        params: {
          address,
          key: GOOGLE_API_KEY,
        },
      },
    );
    const { data } = response;
    return data.results;
  } catch (error) {
    throw new ServiceError(error);
  }
};
