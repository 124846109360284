import request from '@/request';

const pushRecord = async ({
  /** @type {string/number} */ id,
  /** @type {array} */ timecardIds,
}) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/timecards/push_records`;
  const res = await request.post(endpoint, { timecardIds });
  return res;
};
export default pushRecord;
