/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

const DateRender = ({
  crrDate,
  selectedYear,
  name,
  formInstance,
  setDatetime,
  weekSelected,
}) => {
  const onDateClick = (date) => {
    const formatedDate = date.format('MM-DD');
    const fullDatetime = `${selectedYear}-${formatedDate}`;

    formInstance.setFieldsValue({ [name]: fullDatetime });

    setDatetime(moment(fullDatetime));
  };

  const isSameWeek = weekSelected === crrDate?.weeks();
  return (
    <div
      onClick={() => onDateClick(crrDate)}
      className={classNames('date-cell-custom', {
        'add-holiday-request-week-selected': isSameWeek,
      })}
    >
      {crrDate.format('DD')}
    </div>
  );
};

DateRender.propTypes = {
  selectedYear: PropTypes.number,
  name: PropTypes.string,
  formInstance: PropTypes.object,
  setDatetime: PropTypes.func,
  crrDate: PropTypes.any,
  weekSelected: PropTypes.any,
};

DateRender.defaultProps = {
  name: '',
  setDatetime: () => {},
};
export default DateRender;
