const levelLanguage = {
  1: 'Basic',
  2: 'Intermediate',
  3: 'Advanced',
  4: 'Fluent',
  5: 'Native',
};

const SEEKER_JOB_STATUS = {
  APPLIED: 'applied',
  CONFIRMED: 'confirmed',
  PAST: 'past',
};

export { levelLanguage, SEEKER_JOB_STATUS };
