import { cloneDeepWith, has } from 'lodash';

import request from '@/request';

const addUniqKey = (data) =>
  cloneDeepWith(data, (value, key) => {
    if (has(value, 'timesheet_detail_id')) {
      return {
        ...value,
        key,
      };
    }

    return undefined;
  });

const getTimesheetDetail = async (/** @type {string|number} */ id) => {
  const res = await request.get(`payments/payrolls/${id}`);
  const addedUniqKey = addUniqKey(res?.data?.data ?? {});
  return addedUniqKey;
};

export default getTimesheetDetail;
