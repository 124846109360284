import { isArray } from 'lodash';

import request from '@/request';
import { FormatShowStrategy, Normalize } from '@/utils/normalizeResponse';

const show = async (/** @type {string|number} */ id) => {
  const res = await request.get(`/trackers/expense/suppliers/${id}`);
  const normalize = new Normalize();
  const strategy = new FormatShowStrategy(res);
  normalize.setStrategy(strategy);
  const data = normalize.formatResponse();

  const values = data?.data;
  const { comments, supplierDocuments } = values;

  return {
    ...values,
    commentsAttributes: isArray(comments) ? comments : [...comments],
    supplierDocumentsAttributes: isArray(supplierDocuments)
      ? supplierDocuments
      : [...supplierDocuments],
  };
};

export default show;
