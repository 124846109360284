import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getPayrollTemplates = async (params) => {
  const endpoint = 'pay_templates/payroll_templates';

  const res = await request.get(endpoint, { params });

  const { data = [], meta } = normalizeIndexResponse(res);
  return { data, paginations: meta?.pagy ?? {} };
};

export default getPayrollTemplates;
