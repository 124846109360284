import { floor } from 'lodash';

const ratingMapKeyString = new Map([
  ['a-start', 1],
  ['a', 2],
  ['b', 3],
  ['c', 4],
  ['d', 5],
]);

const ratingMapKeyNumber = new Map([
  [1, 'a-start'],
  [2, 'a'],
  [3, 'b'],
  [4, 'c'],
  [5, 'd'],
]);

export const evaluationTextToNumber = (evaluationText) =>
  ratingMapKeyString.get(evaluationText);

export const evaluationNumberToText = (evaluationNumber) =>
  ratingMapKeyNumber.get(evaluationNumber);

export const convertNumber = (number) => (isNaN(number) ? 0 : number);

export const convertIntNumber = (number) => (isNaN(number) ? 0 : floor(number));
