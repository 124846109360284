import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';

import Button from '@/components/Button';

import './styles.scss';

const ModalDocFields = ({
  title,
  titleSuccess,
  children,
  visible,
  onClickNo,
  onClickYes,
  isLoading,
  isSuccess,
  ...props
}) => (
  <Modal
    centered
    title={null}
    footer={null}
    visible={visible}
    onCancel={onClickNo}
    wrapClassName='modal-doc-fields'
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <div className='modal-wrapper'>
      <p className='modal-title'>{title}</p>
      <p className='modal-desc'>{children}</p>
      <div className='modal-footer'>
        <Button className='btn-save' onClick={onClickYes}>
          Yes
        </Button>
        <Button className='btn-cancel' onClick={onClickNo}>
          No
        </Button>
      </div>
    </div>
  </Modal>
);

ModalDocFields.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  titleSuccess: PropTypes.string,
};

export default ModalDocFields;
