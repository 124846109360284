import React from 'react';

import { Form } from 'antd';

import { FormItem } from '@/components';

import ListSeeker from './component/ListSeeker';
import './styles.scss';

const TrustedAndBlocked = () => {
  const form = Form.useFormInstance();
  const employerTrustedEmployees =
    Form.useWatch('employerTrustedEmployees', form) ?? [];

  const employerBlockedEmployees =
    Form.useWatch('employerBlockedEmployees', form) ?? [];

  return (
    <div className='trust-and-block-seeker'>
      <FormItem name='employerTrustedEmployees' hidden />
      <FormItem name='employerBlockedEmployees' hidden />
      <div className='trust-list trust-and-block-seeker__inner'>
        <ListSeeker title='Trusted Providers' list={employerTrustedEmployees} />
      </div>
      <div className='trust-list trust-and-block-seeker__inner'>
        <ListSeeker title='Blocked Providers' list={employerBlockedEmployees} />
      </div>
    </div>
  );
};

export default TrustedAndBlocked;
