import { isEmpty } from 'lodash';

import { RESOURCE_TYPE } from '@/constants';
import request from '@/request';
import Formatter from '@/utils/Formatter';
import { formatResponse } from '@/utils/thunkUtil';

export const getApplicantID = async (applicantID) => {
  const endpoint = `recruitments/applicants/${applicantID}`;
  const resData = await request.get(endpoint);

  const dataInfo = resData?.data?.data;

  if (isEmpty(dataInfo)) return {};

  const applicantData = formatResponse(resData, { endpoint });

  const { data } = Formatter.buildEndpoint(
    applicantData.endpoint,
    applicantData,
    RESOURCE_TYPE.vacancyApplicant,
  );

  const applicantDetail = data?.[0];

  const result = {
    firstname: applicantDetail?.firstname,
    lastname: applicantDetail?.lastname,
    phone: applicantDetail?.phone,
    email: applicantDetail?.email,
    officeId: applicantDetail?.officeId,
    zip: applicantDetail?.zip,
    awayInclude: applicantDetail?.awayInclude,
    tellUs: applicantDetail?.tellUs,
    preferredRoles: applicantDetail?.preferredRoles,
    generalRole: applicantDetail?.generalRole,
    otherRolesSuitableFor: applicantDetail?.otherRolesSuitableFor,
    status: applicantDetail?.status,
    candidateStatus: applicantDetail?.candidateStatus,
    internalNote: applicantDetail?.internalNote,
    leave: applicantDetail?.vacancyApplicantEmployments[0]?.leave,
    idLeave: applicantDetail?.vacancyApplicantEmployments[0]?.id,
    cvUpload: applicantDetail?.cvUpload,
    applicantDate: applicantDetail?.createdAt,
  };

  return result;
};
