export const convertLocationsToOption = (locations = []) =>
  locations
    .filter(
      (item) =>
        item &&
        item.city &&
        item.locationName &&
        item.street &&
        item.buildingNumber,
    )
    .map((locationItem) => ({
      ...locationItem,
      label: locationItem.locationName,
      value: locationItem.id,
    }));
