import request from '@/request';
import { FormatIndexStrategy, Normalize } from '@/utils/normalizeResponse';

export const getTimesheetSeeker = async ({ id, ...params }) => {
  const res = await request.get(`seekers/employees/${id}/timesheets`);

  const normalize = new Normalize();
  const strategy = new FormatIndexStrategy(res);
  normalize.setStrategy(strategy);
  const data = normalize.formatResponse();

  const seekerTimesheets = data?.data?.filter(
    (dt) =>
      (dt.payrollStatus === 'finalised' || dt.payrollStatus === 'paid') && dt,
  );
  return seekerTimesheets;
};
