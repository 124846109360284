import React from 'react';

import { Form } from 'antd';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button, GeneralTemplate, ModalConfirm } from '@/components';
import { SupplierService } from '@/services';
import useModal from '@/utils/useModal';

import BasicInfo from '../components/BasicInfo';
import Comments from '../components/Comments';
import InvoiceDocument from '../components/InvoiceDocument';
import Roles from '../components/Roles';

import '../styles.scss';

const breadCrumb = [
  { item: 'Timesheets', to: '/timesheets' },
  { item: 'Suppliers', to: '/suppliers' },
  { item: 'Create New Supplier', to: '/create-supplier' },
];

const CreateSupplier = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [displayModal, showModal, hiddenModal] = useModal();

  const handleGoBack = () => history.push('/suppliers');

  const {
    mutate: createSupplier,
    isLoading,
    isSuccess,
  } = useMutation(async () => SupplierService.create(form.getFieldsValue()), {
    onSuccess: () => handleGoBack(),
    onError: (err) => {
      if (err.status && err.message) {
        hiddenModal();
        window.scrollTo(0, 0);
      }
    },
  });

  const handleSubmit = () => {
    createSupplier(form.getFieldsValue());
  };

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadCrumb}
      headerText='Create New Supplier'
      buttonContent='Create Supplier'
      buttonLink='/create-supplier'
      subTemplateClassName='supplier-container'
    >
      <Form
        form={form}
        onFinish={showModal}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        className='form-supplier'
      >
        <div className='form-wrapper'>
          <div className='fields-left'>
            <div className='basic'>
              <BasicInfo />
            </div>
            <div className='role'>
              <Roles />
            </div>
          </div>
          <div className='fields-right'>
            <Comments />
          </div>
        </div>
        <div className='invoice-document'>
          <InvoiceDocument />
        </div>
        <div className='actions'>
          <Button onClick={handleGoBack} className='black-button input-height'>
            Cancel
          </Button>
          <Button className='yellow-button input-height' htmlType='submit'>
            Create
          </Button>
        </div>
      </Form>
      <ModalConfirm
        title='Create New Supplier?'
        titleSuccess='Supplier created successfully'
        visible={displayModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleGoBack : handleSubmit}
        onClickNo={hiddenModal}
      >
        Are you sure you want to create new Supplier?
      </ModalConfirm>
    </GeneralTemplate>
  );
};

export default CreateSupplier;
