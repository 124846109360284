/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';

import { Card, Col, Form, message, Row, Spin, Modal as AntdModal } from 'antd';
import classNames from 'classnames';
import { keyBy } from 'lodash';
import moment from 'moment';
import QueryString from 'qs';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from '@/components';
import Modal from '@/components/ModalCalendar';
import ModalEditEventView from '@/components/ModalEditEventView';
import { useFetch } from '@/hooks';
import { useForceUpdate } from '@/hooks/useForceUpdate';
import { getBuildRota, postRota, saveAsDraft } from '@/services/rota';
import useBuildRotaStore from '@/store/rotaStore';
import { convertNumber } from '@/utils/evaluation';

import CustomDateCellWrapper from './CustomDateCellWrapper';
import Event from './Event';
import EventContainerWrapper from './EventContainerWrapper';
import { eventWrapperGetter } from './GetterandSetters';
import InputForm from './InputForm';
import CustomTimeSlotWrapper from './timeHeaderGutter';
import MyTimeSlotWrapper from './TimeSlotWrapper';
import CustomToolbar from './Toolbar';

const localizer = momentLocalizer(moment);
const CustomHeader = () => null;
const MyCalendar = () => {
  const forceUpdate = useForceUpdate();

  const history = useHistory();
  const [form] = Form.useForm();
  const [view, setView] = useState('day');
  const [date, setDate] = useState(moment().utc().format());

  const [selectEvent, setSelectEvent] = useState(false);
  const [event, setEvent] = useState(null);
  const [eventPosition, setEventPosition] = useState({ x: 0, y: 0 });
  const [editPopup, setEditPopup] = useState(false);
  const required = useBuildRotaStore((state) => state.required);
  const kpi = Form.useWatch('kpi', form);
  const volume = Form.useWatch('volume', form);

  const week = Form.useWatch('week', form);
  const employerId = Form.useWatch('employerId', form);
  const employerLocationId = Form.useWatch('employerLocationId', form);
  const skillId = Form.useWatch('skillId', form);

  const shiftLength = Form.useWatch('shiftLength', form);
  const formData = form.getFieldsValue(true);
  const queryClient = useQueryClient();

  const formDataArray = Object.keys(formData)
    .map((key) => formData[parseInt(key, 10)])
    .filter((key) => !!key);

  const handleViewChange = (views) => {
    setView(views);
  };
  const handleDateChange = (dates) => {
    setDate(dates);
    form.setFieldsValue({
      week: moment(dates).week(),
    });
  };
  const handleEventSelect = (currentEvent, e) => {
    const { clientX, clientY } = e;
    setEventPosition({ x: clientX, y: clientY });
    setSelectEvent(true);
    setEvent(currentEvent);
  };

  const formats = {
    dayHeaderFormat: (dates, culture, options) =>
      `${moment(dates).format('Do MMM YY')} - ${moment(dates)
        .endOf('week')
        .format('Do MMM')}`,
    weekdayFormat: (dates, culture) =>
      `${moment(dates).format('Do MMM YY')} - ${moment(dates)
        .endOf('week')
        .format('Do MMM')}`,
  };

  const dayStyleGetter = (dates) => {
    const today = moment().startOf('day');
    const isToday = moment(dates).isSame(today, 'day');
    const backgroundColor = isToday ? '#fff' : undefined; // Set the desired background color for the current day

    const style = {
      backgroundColor,
    };

    return {
      style,
    };
  };

  const { refetch: refetchGetBuildRota } = useFetch(
    ['getBuildRota', date, view, kpi, shiftLength],
    () =>
      getBuildRota({
        employerId,
        employerLocationId,
        skillId,
        week,
        date,
        view,
      }),
    {
      onSuccess: ({ data, statistical, rotaTotals }) => {
        form.setFieldsValue({
          ...keyBy(data, 'id'),
          ...keyBy(statistical, 'dateKey'),
          ...rotaTotals,
        });

        if (event && data) {
          const newEvent = (data ?? []).find(
            (e) => e.id === event.id && event.weekDay === e.weekDay,
          );

          setEvent(newEvent);
        }
      },
    },
  );

  const { mutate: postRotaMutate, isLoading: isCreating } = useMutation(
    () => postRota(formDataArray),
    {
      onSuccess: () => {
        const queryString = QueryString.stringify({
          tab: 'live-rota',
          kpi,
          shiftLength,
          week,
          employerId,
          employerLocationId,
          skillId,
        });

        queryClient.invalidateQueries('getBuildRota');
        message.success('Post Rota successfully');

        history.push(`/rota?${queryString}`);
      },
    },
  );

  const { mutate: saveAsDraftMutate, isLoading: isSaving } = useMutation(
    () => saveAsDraft(formDataArray),
    {
      onSuccess: () => message.success('Save as draft successfully'),
    },
  );

  const handleSearchRota = () => {
    const dataChange = moment().week(week).startOf('week');
    setDate(dataChange);

    refetchGetBuildRota();
  };

  let customTimeGutterHeader;

  if (view === 'week') customTimeGutterHeader = CustomTimeSlotWrapper;
  const eventStyleGetter = (e) => {
    let color;
    const { id } = e;
    const dateKey = moment(e?.start).format('DD/MM/YYYY');
    const weekVolume = form.getFieldValue([dateKey, 'weekVolume']);
    const contracted = form.getFieldValue([id, 'numberOfEmployees']);

    const requiredPeople = convertNumber(
      Math.ceil(weekVolume / (kpi * shiftLength)),
    );
    const difference = contracted - requiredPeople;

    if (difference > 0) {
      color = '#F6E6A7';
    } else if (difference < 0) {
      color = '#DD9DA0';
    } else {
      color = '#BAD0CA';
    }

    const style = {
      backgroundColor: color,
      color: '#000000',
      borderRadius: '10px',
      border: '1px solid #707070',

      // Add any additional styling properties you need
    };

    return {
      style,
    };
  };
  const handleClearFilter = () => {
    form.resetFields();

    const dataChange = moment().week(week).startOf('week');
    setDate(dataChange);

    refetchGetBuildRota();
  };

  useEffect(() => {
    if (kpi && shiftLength && volume && view === 'day') {
      const formValues = form.getFieldsValue(true);
      const formValuesArray = Object.keys(formValues)
        .map((key) => formValues[parseInt(key, 10)])
        .filter((key) => !!key);
      const requiredPeople = volume / (kpi * shiftLength);
      const requiredHour = requiredPeople * shiftLength;

      const data = formValuesArray.map((dt) => ({
        name: [dt.id, 'numberOfRequired'],
        value: requiredPeople,
      }));

      form.setFields(data);

      form.setFieldsValue({
        requiredPeople,
        requiredHour,
      });
    }
  }, [form, kpi, shiftLength, volume, view]);

  return (
    <Form
      form={form}
      initialValues={{
        requiredPeople: 0,
        requiredHour: 0,
        numberOfEmployees: 0,
        numberOfEmployeesHour: 0,
        differencePeople: 0,
        differenceHour: 0,
        week: moment().week(),
      }}
      className='job-list-view'
    >
      <AntdModal className='loading-modal' visible={isCreating} footer={false}>
        <Spin />
        <p className='loading-text'>Creating</p>
      </AntdModal>
      <AntdModal className='loading-modal' visible={isSaving} footer={false}>
        <Spin />
        <p className='loading-text'>Saving</p>
      </AntdModal>
      <div className='input-container'>
        <InputForm
          handleSearchRota={handleSearchRota}
          handleClearFilter={handleClearFilter}
          date={date}
        />
      </div>
      <div className={classNames('calendar', { 'day-view': view === 'day' })}>
        <Calendar
          formats={formats}
          localizer={localizer}
          events={formDataArray}
          startAccessor='start'
          endAccessor='end'
          views={['day', 'week']}
          date={date}
          onNavigate={handleDateChange}
          view={view}
          onView={handleViewChange}
          eventPropGetter={(e) => eventStyleGetter(e)}
          eventWrapperGetter={eventWrapperGetter}
          onSelectEvent={handleEventSelect}
          dayPropGetter={dayStyleGetter}
          components={{
            toolbar: CustomToolbar,
            event: (props) => (
              <Event {...props} view={view} formData={formData} />
            ),
            // eslint-disable-next-line
            eventContainerWrapper: (props) => (
              <EventContainerWrapper
                {...props}
                view={view}
                postRotaMutate={postRotaMutate}
                formDataArray={formDataArray}
                saveAsDraftMutate={saveAsDraftMutate}
              />
            ),
            dateCellWrapper: (props) => (
              <CustomDateCellWrapper
                {...props}
                forceUpdate={forceUpdate}
                refetchGetBuildRota={refetchGetBuildRota}
              />
            ),
            header: CustomHeader,
            timeGutterHeader: customTimeGutterHeader,
            timeSlotWrapper: MyTimeSlotWrapper,
          }}
        />
        <div className='bottom-cards'>
          <Row>
            <Col span={12}>
              <Card className='right-cards'>
                <h2>Rota Totals</h2>

                <div className='individual-card'>
                  <h4>Hours</h4>
                  <Card className='card'>{form.getFieldValue('hours')}</Card>
                </div>
                <div className='individual-card'>
                  <h4>Cost</h4>
                  <Card className='card'>{form.getFieldValue('cost')}</Card>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <div className='left-items'>
                <Card className='right-cards'>
                  <div className='individual-card'>
                    <h4>Required</h4>
                    <Card className='card'>0</Card>
                  </div>
                  <div className='individual-card'>
                    <h4>Contract</h4>
                    <Card className='card'>0</Card>
                  </div>
                  <div className='individual-card'>
                    <h4>Difference</h4>
                    <Card className='card'>0</Card>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <div className='card-buttons'>
            <Button
              disabled={formDataArray.length === 0}
              onClick={saveAsDraftMutate}
              className='yellow-button'
            >
              Save as Draft
            </Button>
          </div>
          <div className='card-buttons'>
            <Button
              disabled={formDataArray.length === 0}
              onClick={postRotaMutate}
              className='yellow-button'
            >
              Post Rota
            </Button>
          </div>
        </div>
      </div>

      <Modal
        visible={selectEvent}
        onClickNo={() => {
          setSelectEvent(false);
          setEvent(null);
        }}
        position={eventPosition}
        data={{ contracted: 0 }}
        onClick={() => setEditPopup(true)}
        event={event}
        formBuildRota={form}
      />
      {editPopup && (
        <ModalEditEventView
          formBuildRota={form}
          visible={editPopup}
          rotaShiftId={event?.id}
          rotaWeekId={event?.rotaWeekPlanId}
          data={{ required }}
          onClickNo={() => setEditPopup(false)}
          event={event}
        />
      )}
    </Form>
  );
};

export default MyCalendar;
