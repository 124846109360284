import { isArray, isEmpty } from 'lodash';

import { ACCESS_TYPES, INDUSTRY, ROTA_TYPES } from '@/constants';
import { toArray } from '@/utils/array';
import { parseCertificate } from '../../../../utils/seeker';

const boxTypes = {
  eLearning: 'learning',
  license: 'license',
  documentation: 'documentation',
};

const convertSelectTypes = (accessType) => {
  switch (accessType) {
    case ACCESS_TYPES.Flexible:
      return [ROTA_TYPES[0].value];
    case ACCESS_TYPES.Specific_Site:
      return [ROTA_TYPES[1].value];
    case ACCESS_TYPES.Rota_Only:
      return [ROTA_TYPES[2].value];
    case ACCESS_TYPES.Flexible_Rota:
      return [ROTA_TYPES[0].value, ROTA_TYPES[2].value];
    case ACCESS_TYPES.Specific_Site_Rota:
      return [ROTA_TYPES[1].value, ROTA_TYPES[2].value];
    default:
      return [ROTA_TYPES[0].value];
  }
};

export const convertData = (data = {}, isUkOrIreland = false) => {
  const {
    vacancyApplicantAttributes = {},
    employeeStudent,
    employeeCertificateAttributes,
    omniEmployeeAccessSites = [],
    accessType = ACCESS_TYPES.Flexible,
    rotas = [],
    employerTrustedEmployees,
    employerBlockedEmployees,
  } = data;

  const seekerRota = rotas.map((item) => ({
    ...item,
    ...item.attributes,
  }));

  const {
    birth,
    cvUpload,
    universityLetter,
    studentLetter,
    proofAddress,
    proofNi,
    niProof,
    secondaryId,
    birthCertificate,
    shareCodeFile,
    restriction,
  } = vacancyApplicantAttributes;

  const industries = isArray(
    data?.industryExperiencesVacancyApplicantsAttributes,
  )
    ? data?.industryExperiencesVacancyApplicantsAttributes
    : [data?.industryExperiencesVacancyApplicantsAttributes];

  // Show other value
  const otherExperience = (industries ?? [])?.find(
    (industry = {}) =>
      industry?.industryExperienceId === INDUSTRY.OTHER && industry?.otherValue,
  );
  data.vacancyApplicantAttributes.industryExperienceValueInput =
    otherExperience?.otherValue;

  const contractFile = data?.contractFile;
  const profilepic = data?.employeeAttributes?.profilepic;
  const dbsCheck = data?.employeeAttributes?.dbsCheck;
  const alcoholLicense = data?.employeeAttributes?.alcoholLicense;
  const visaBrpFile = data?.visaBrpFile;
  const p45Document = data?.p45Document;
  const passportId1File = data?.passportId1File;
  const visaRtw = data?.visaRtw;
  const universityTermDateLetterRtw = data?.universityTermDateLetterRtw;

  const blankBoxesAttributes = toArray(data?.blankBoxAttributes);

  const phone = data?.vacancyApplicantAttributes?.phone;

  if (!phone?.startsWith('0') ?? false) {
    data.vacancyApplicantAttributes.phone = `0${data.vacancyApplicantAttributes.phone}`;
  }

  const shareCode = data?.vacancyApplicantAttributes?.shareCode;

  if (isEmpty(shareCode)) {
    data.vacancyApplicantAttributes.shareCode = '000000000';
  }

  // End Files

  const blankBoxDocumentation = blankBoxesAttributes.filter(
    (item) => item.boxType === boxTypes.documentation,
  );

  const documentationDocs = [
    {
      fileBlob: studentLetter,
      fileValue: studentLetter,
      typeValue: 'studentLetter',
      typeLabel: 'Student letter',
    },
    {
      fileBlob: visaBrpFile,
      fileValue: visaBrpFile,
      typeValue: 'visaBrpFile',
      typeLabel: 'Visa Application Letter',
    },
    {
      fileBlob: p45Document,
      fileValue: p45Document,
      typeValue: 'p45Document',
      typeLabel: 'Past P45',
    },
    {
      fileBlob: proofAddress,
      fileValue: proofAddress,
      typeValue: 'proofAddress',
      typeLabel: 'Proof of Address',
    },
    {
      fileBlob: proofNi,
      fileValue: proofNi,
      typeValue: 'proofNi',
      typeLabel: 'Proof of NI',
    },
    {
      fileBlob: secondaryId,
      fileValue: secondaryId,
      typeValue: 'secondaryId',
      typeLabel: 'Secondary ID',
    },
    {
      fileBlob: dbsCheck,
      fileValue: dbsCheck,
      typeValue: 'dbsCheck',
      typeLabel: 'DBS',
    },
    ...blankBoxDocumentation.map((item) => ({
      fileBlob: item.link,
      fileValue: item.link,
      typeValue: `blankBox-${item.name}`,
      typeLabel: isEmpty(item.name) ? 'Blank Box' : item.name,
      id: item.id,
    })),
  ].filter((item) => item.fileBlob);

  const [eLearnings, licences] = parseCertificate(
    employeeCertificateAttributes,
  );

  const rightToWorkDocs = isUkOrIreland
    ? [
        {
          fileBlob: passportId1File,
          fileValue: passportId1File,
          typeValue: 'passportId1File',
          typeLabel: 'Passport',
        },
        {
          fileBlob: birthCertificate,
          fileValue: birthCertificate,
          typeValue: 'birthCertificate',
          typeLabel: 'Birth Certificate',
        },
        {
          fileBlob: niProof,
          fileValue: niProof,
          typeValue: 'niProof',
          typeLabel: 'NI Proof',
        },
      ]
    : [
        {
          fileBlob: passportId1File,
          fileValue: passportId1File,
          typeValue: 'passportId1File',
          typeLabel: 'Passport',
        },
        {
          fileBlob: visaRtw,
          fileValue: visaRtw,
          typeValue: 'visaRtw',
          typeLabel: 'Visa',
        },
        {
          fileBlob: universityTermDateLetterRtw,
          fileValue: universityTermDateLetterRtw,
          typeValue: 'universityTermDateLetterRtw',
          typeLabel: 'University Term Date Letter',
        },
      ].filter((item) => item.fileBlob);

  const studentHolidaysAttributes = !isEmpty(data?.studentHolidays)
    ? data.studentHolidays
    : [
        {
          name: 0,
          key: 0,
          isListField: true,
          fieldKey: 0,
        },
      ];

  return {
    ...data,
    employeeStudent,
    birth,
    documentationDocs: [
      {
        fileBlob: contractFile,
        fileValue: contractFile,
        typeValue: 'contractFile',
        typeLabel: 'Contract',
      },
      {
        fileBlob: profilepic,
        fileValue: profilepic,
        typeValue: 'profilepic',
        typeLabel: 'Profile Picture',
      },
      {
        fileBlob: cvUpload,
        fileValue: cvUpload,
        typeValue: 'cvUpload',
        typeLabel: 'CV',
      },
      ...documentationDocs,
    ],
    vacancyApplicantAttributes: {
      ...vacancyApplicantAttributes,
      restriction,
    },
    eLearnings,
    licences,
    rightToWorkDocs,
    studentHolidaysAttributes,
    universityLetterFileBlob: universityLetter,
    universityLetterFileValue: universityLetter,
    passportId1FileBlob: passportId1File,
    passportId1FileValue: passportId1File,
    shareCodeFileBlob: shareCodeFile,
    shareCodeFileValue: shareCodeFile,
    seekerRota,
    selectedTypes: convertSelectTypes(accessType),
    siteAllocation: omniEmployeeAccessSites,
    accessType,
    employerTrustedEmployees,
    employerBlockedEmployees,
  };
};
