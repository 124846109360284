import React, { useState } from 'react';

import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { GeneralTemplate } from '@/components';
import PayrollDetail from '@/pages/Payroll/Detail/PayrollDetail';

const Detail = () => {
  const { id } = useParams();
  const [weekNumber, setWeekNumber] = useState();
  const [providerName, setProviderName] = useState();

  const breadcrumb = [
    { item: 'Payroll', to: '/payroll' },
    {
      item: providerName ?? <Spin />,
      to: `/payroll-detail/${id}`,
    },
    { item: weekNumber ? `Week ${weekNumber}` : <Spin />, to: '#' },
  ];

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadcrumb}
      headerText={breadcrumb.at(2).item}
      hasButton={false}
      className='timesheets-container'
    >
      <PayrollDetail
        weekNumber={weekNumber}
        setWeekNumber={setWeekNumber}
        setProviderName={setProviderName}
      />
    </GeneralTemplate>
  );
};

export default Detail;
