import React from 'react';

import { Form } from 'antd';

import { FormItem, Textarea, Input } from '@/components';
import { LIMIT_PHONE_NUMBER, REGEX_PHONE_NUMBER } from '@/constants';
import { rulesPostcode } from '@/utils/rulesInput';

const LeftSection = () => {
  const formInstance = Form.useFormInstance();
  return (
    <div className='create-lead-input-fields--left'>
      <FormItem
        required
        name='fullName'
        className='form-item create-lead-form-item'
        label='Full Name'
        rules={[
          { required: true, message: 'Please enter Full Name' },
          {
            whitespace: true,
            message: 'Full Name can not be empty',
          },
        ]}
      >
        <Input placeholder='Full Name' />
      </FormItem>
      <FormItem
        required
        name='email'
        className='form-item create-lead-form-item'
        label='Email'
        rules={[
          { type: 'email', message: 'Email is invalid' },
          { required: true, message: 'Email is required' },
        ]}
      >
        <Input placeholder='Email' />
      </FormItem>
      <FormItem
        required
        name='phoneNumber'
        className='form-item create-lead-form-item'
        label='Phone Number'
        rules={[
          {
            pattern: REGEX_PHONE_NUMBER,
            message:
              'Phone numbers must be starting with 0 and Enter only 11 digits phone number',
          },
          {
            required: true,
            message: 'Please enter Phone Number',
          },
        ]}
      >
        <Input maxLength={LIMIT_PHONE_NUMBER} placeholder='Phone Number' />
      </FormItem>
      <FormItem
        required
        name='company'
        className='form-item create-lead-form-item'
        label='Company'
        rules={[
          {
            required: true,
            message: 'Please enter Company',
          },
          {
            whitespace: true,
            message: 'Company can not be empty',
          },
        ]}
      >
        <Input placeholder='Company' />
      </FormItem>
      <FormItem
        required
        name='city'
        className='form-item create-lead-form-item'
        label='City'
        rules={[
          {
            required: true,
            message: 'Please enter City',
          },
          {
            whitespace: true,
            message: 'City can not be empty',
          },
        ]}
      >
        <Input placeholder='City' />
      </FormItem>
      <FormItem
        required
        name='sitePostCode'
        className='form-item create-lead-form-item'
        label='Site Post Code'
        rules={rulesPostcode(formInstance, 'sitePostCode')}
      >
        <Input placeholder='Site Post Code' />
      </FormItem>
      <div className='about-your-needs'>
        <div className='about-your-needs-label'>
          Tell us a little about your needs:
        </div>

        <FormItem name='tellUs' className='form-item create-lead-form-item'>
          <Textarea
            rows={7}
            placeholder='We need Waiting staff for Saturday nights'
          />
        </FormItem>
      </div>
    </div>
  );
};
export default LeftSection;
