import React from 'react';

import { Table } from 'antd';
import PropTypes from 'prop-types';

import { FormItem } from '@/components';

import './styles.scss';

const History = ({
  formItemPropertyName,
  dataSource,
  columns,
  pagination,
  isLoading,
}) => (
  <div className='history-container'>
    <p className='history-title'>History</p>
    <FormItem name={formItemPropertyName}>
      <Table
        className='history-table'
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
      />
    </FormItem>
  </div>
);

History.propTypes = {
  formItemPropertyName: PropTypes.string,
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  pagination: PropTypes.bool,
  isLoading: PropTypes.bool,
};

History.defaultProps = {
  formItemPropertyName: null,
  dataSource: [],
  columns: [],
  pagination: false,
  isLoading: false,
};

export default History;
