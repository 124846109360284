import React, { useState } from 'react';

import { Form, message } from 'antd';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button } from '@/components';
import { UserService } from '@/services';

import UserCollapse from '../UserCollapse';

import './styles.scss';

const CreateNewUser = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [activeKey, setActiveKey] = useState(['workInformation']);

  const { mutate: mutateCreateNewUser } = useMutation(
    UserService.createOmniUser,
    {
      onError: (error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        message.success('Create Omni User successfully');
        handleGoBackToListView();
      },
    },
  );

  const handleGoBackToListView = () => history.push('/users');

  const handleFinishFailed = () =>
    setActiveKey(['workInformation', 'contactInformation']);
  return (
    <Form
      form={form}
      className='create-user'
      onFinish={mutateCreateNewUser}
      onFinishFailed={handleFinishFailed}
      initialValues={{
        languages: [],
      }}
    >
      <UserCollapse activeKey={activeKey} setActiveKey={setActiveKey} />
      <div className='create-user-footer'>
        <Button
          className='black-button button-style'
          onClick={handleGoBackToListView}
        >
          Cancel
        </Button>
        <Button className='yellow-button button-style' htmlType='submit'>
          Save
        </Button>
      </div>
    </Form>
  );
};
export default CreateNewUser;
