import moment from 'moment';

import acoholLicenceYellow from '@/assets/icons/acoholLicenceYellow.png';
import dbsCheckYellow from '@/assets/icons/dbsCheckYellow.png';
import drivingLicenceYellow from '@/assets/icons/drivingLicenceYellow.png';
import hgvLicenceYellow from '@/assets/icons/hgvLicenceYellow.png';
import iconWelderBlack from '@/assets/icons/iconWelderBlack.png';
import manualHandlingYellow from '@/assets/icons/manualHandlingYellow.png';
import scooterLicenceYellow from '@/assets/icons/scooterLicenceYellow.png';
import siaIcon from '@/assets/icons/siaIcon.png';

const ENV = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  POST_CODE_URL: process.env.REACT_APP_POST_CODE_URL,
};

const LoadingStatus = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

const StorageKey = {
  authUser: '@auth:user',
  authToken: '@auth:token',
  authUserId: '@auth:userId',
};

const RECRUITMENT_VIEW = {
  APPLICATION: 'application',
  CANDIDATE: 'candidate',
};

const listGenders = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Non-Binary', value: 'N' },
  { label: 'Other', value: 'O' },
  { label: 'Prefer not to say', value: 'S' },
];

const STATUS = {
  NOT_ENOUGH_INFORMATION: 0,
  CONTACTED: 1,
  SUCCESSFUL_AUTO_EMAIL: 2,
  SUCCESSFUL_PERSONAL: 3,
  REJECT_PERMENANT: 4,
  REJECT_NO_ROLES: 5,
};

const STATUS_CANDIDATE = {
  REJECTED: 0,
  APPROVED: 1,
  HOLD: 2,
};

const ACCEPT_TYPE = {
  doc: 'application/msword',
  pdf: 'application/pdf',
  allImage: 'image/*',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  odt: 'application/vnd.oasis.opendocument.text',
};

const candidateStatuses = [
  {
    value: 0,
    label: 'Rejected',
  },
  {
    value: 1,
    label: 'Approved',
  },
  {
    value: 2,
    label: 'Hold',
  },
];

const STATUS_JOB_CODE = {
  posted: 0,
  live: 1,
  completed: 2,
};

const STATUS_OPTIONS = {
  posted: {
    value: 'posted',
    label: 'Posted',
  },
  live: {
    value: 'live',
    label: 'In progress',
  },
  filled: {
    value: 'filled',
    label: 'Filled',
  },
  part_filled: {
    value: 'part_filled',
    label: 'Part filled',
  },
};

const STATUS_FILTER_DROPDOWN_OPTIONS = Object.values(STATUS_OPTIONS);

const LEAD_STATUS_KEY = {
  NOT_ENOUGH_INFOMATION: 0,
  CONTACTED: 1,
  SUCCESSFUL_AUTO: 2,
  REJECT_LEAD: 3,
};

const DEPARTMENT = [
  {
    value: 1,
    label: 'Warehouse - London',
  },
  {
    value: 2,
    label: 'Warehouse - GIGHub',
  },
  {
    value: 3,
    label: 'Hospitality',
  },
  {
    value: 4,
    label: 'Hospitality - GIGHub',
  },
  {
    value: 5,
    label: 'Head Office',
  },
  {
    value: 6,
    label: 'Scotland',
  },
  {
    value: 7,
    label: 'Northern England',
  },
  {
    value: 8,
    label: 'Recruitment',
  },
];

const MANAGER = [
  {
    value: 1,
    label: 'Daniel Woodcock',
  },
  {
    value: 2,
    label: 'Antony Woodcock',
  },
  {
    value: 3,
    label: 'Esther Jenkinson',
  },
  {
    value: 4,
    label: 'Bianca Barker',
  },
  {
    value: 5,
    label: 'Oluwadare Adesina',
  },
  {
    value: 6,
    label: 'Virag Arany',
  },
  {
    value: 7,
    label: 'Judyta Noga',
  },
];

const COST_ROLES = [
  {
    value: 1,
    label: 'Transport',
  },
  {
    value: 2,
    label: 'Accommodation',
  },

  {
    value: 3,
    label: 'Uniform',
  },

  {
    value: 4,
    label: 'Training',
  },

  {
    value: 5,
    label: 'Other',
  },
];

const ACCESS_LEVEL = [
  {
    value: 1,
    label: 'Recruiter',
  },
  {
    value: 2,
    label: 'Account Manager',
  },
  {
    value: 3,
    label: 'Senior Manager',
  },
  {
    value: 4,
    label: 'Payroll',
  },
];

const MARITAL = [
  { value: 1, label: 'Single' },
  { value: 2, label: 'Married' },
  { value: 3, label: 'Divorced' },
  { value: 4, label: 'Widowed' },
  { value: 0, label: 'Other' },
];

const LICENCES = [
  {
    value: 'manual_handing',
    label: 'Manual Handling',
  },
  {
    value: 'food_safety_allergens',
    label: 'Food Safety',
  },
  {
    value: 'spectators_safety',
    label: 'Spectator Safety',
  },
  {
    value: 'alcohol_license',
    label: 'Alcohol Licence',
  },
  {
    value: 'scooter_license',
    label: 'Scooter License',
  },
  {
    value: 'fork_lift_truck',
    label: 'Fork Lift Truck',
  },
  {
    value: 'driving_license',
    label: 'Driving License',
  },
  {
    value: 'hgv_license',
    label: 'HGV License',
  },
];

const LEAD_STATUS_MODAL_CONTENT = {
  [LEAD_STATUS_KEY.NOT_ENOUGH_INFOMATION]: {
    title: 'Not enough information?',
    content:
      'Are you sure you do not have enough information? An email will be sent to the Lead to request further information',
  },
  [LEAD_STATUS_KEY.CONTACTED]: {
    title: 'Contacted?',
    content:
      'Have you contacted the Lead? No action will be taken on this profile until further update.',
  },
  [LEAD_STATUS_KEY.SUCCESSFUL_AUTO]: {
    title: 'Onboarding?',
    content:
      'Are you sure you want to onboard this Lead as a provider? They will receive an email to complete the full Provider onboarding form  ',
  },
  [LEAD_STATUS_KEY.REJECT_LEAD]: {
    title: 'Reject Lead?',
    content:
      'Are you sure you want to Reject this Lead? They will receive an email to say we will not be providing staff going forward.',
  },
};

const RESOURCE_TYPE = {
  vacancyApplicant: 'vacancy_applicant',
  vacancyCandidate: 'vacancy_candidate',
  lead: 'lead',
  omni_employee: 'omni_employee',
  providers: 'employer',
  cost: 'cost',
  supplier: 'supplier',
  timesheet: 'timesheet',
  job: 'position_collection',
  updateJob: 'position',
  jobSeeker: 'job_seeker',
  jobTimecard: 'job_timecard',
  user: 'user',
  chargeRate: 'chargeRateProperty',
  invoice: 'invoice',
  payTemplateType: 'pay_template',
};

const DATA_PROTECTION_KEYS = {
  VERIFIED_STORE_AND_USE: 'Verified Store & Use',
  VERIFIED_READ_TERMS_AND_CONDITIONS: 'Verified Read Terms & Conditions',
  ACCEPTED_RECIEVING_UPDATES: 'Accepted Received Updates',
};

const DATA_PROTECTION = [
  {
    value: DATA_PROTECTION_KEYS.VERIFIED_STORE_AND_USE,
    label: DATA_PROTECTION_KEYS.VERIFIED_STORE_AND_USE,
  },
  {
    value: DATA_PROTECTION_KEYS.VERIFIED_READ_TERMS_AND_CONDITIONS,
    label: DATA_PROTECTION_KEYS.VERIFIED_READ_TERMS_AND_CONDITIONS,
  },
  {
    value: DATA_PROTECTION_KEYS.ACCEPTED_RECIEVING_UPDATES,
    label: DATA_PROTECTION_KEYS.ACCEPTED_RECIEVING_UPDATES,
  },
];

const DATA_PAYPMENT_REPORT_KEYS = {
  SUNDAY_TO_SATURDAY_PAYMENT_WEEK: 'Sunday to Saturday payment week',
  SHOW_AWR_REPORT: 'Show AWR report',
};

const languagesItems = [
  { code: 'en', label: 'English', value: 'English', nativeName: 'abc' },
  {
    code: 'zh',
    label: 'Chinese',
    value: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
  },
  { code: 'hi', label: 'Hindi', value: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
  {
    code: 'es',
    label: 'Spanish',
    value: 'Spanish',
    nativeName: 'español, castellano',
  },
  {
    code: 'fr',
    label: 'French',
    value: 'French',
    nativeName: 'français, langue française',
  },
  { code: 'ar', label: 'Arabic', value: 'Arabic', nativeName: 'العربية' },
  { code: 'bn', label: 'Bengali', value: 'Bengali', nativeName: 'বাংলা' },
  {
    code: 'ru',
    label: 'Russian',
    value: 'Russian',
    nativeName: 'русский язык',
  },
  {
    code: 'pt',
    label: 'Portuguese',
    value: 'Portuguese',
    nativeName: 'Português',
  },
  {
    code: 'id',
    label: 'Indonesian',
    value: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
  },
  { code: 'ur', label: 'Urdu', value: 'Urdu', nativeName: 'اردو' },
  { code: 'de', label: 'German', value: 'German', nativeName: 'Deutsch' },
  {
    code: 'ja',
    label: 'Japanese',
    value: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  {
    code: 'ko',
    label: 'Korean',
    value: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  {
    code: 'vi',
    label: 'Vietnamese',
    value: 'Vietnamese',
    nativeName: 'Tiếng Việt',
  },
  { code: 'jv', label: 'Javanese', value: 'Javanese', nativeName: 'basa Jawa' },
  { code: 'it', label: 'Italian', value: 'Italian', nativeName: 'Italiano' },
  { code: 'ha', label: 'Hausa', value: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { code: 'th', label: 'Thai', value: 'Thai', nativeName: 'ไทย' },
  { code: 'gu', label: 'Gujarati', value: 'Gujarati', nativeName: 'ગુજરાતી' },
  { code: 'kn', label: 'Kannada', value: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { code: 'fa', label: 'Persian', value: 'Persian', nativeName: 'فارسی' },
  { code: 'my', label: 'Burmese', value: 'Burmese', nativeName: 'ဗမာစာ' },
  { code: 'pl', label: 'Polish', value: 'Polish', nativeName: 'polski' },
  { code: 'yo', label: 'Yoruba', value: 'Yoruba', nativeName: 'Yorùbá' },
  { code: 'ml', label: 'Malayalam', value: 'Malayalam', nativeName: 'മലയാളം' },
  {
    code: 'uk',
    label: 'Ukrainian',
    value: 'Ukrainian',
    nativeName: 'українська',
  },
  {
    code: 'pa',
    label: 'Panjabi, Punjabi',
    value: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  {
    code: 'su',
    label: 'Sundanese',
    value: 'Sundanese',
    nativeName: 'Basa Sunda',
  },
  { code: 'ig', label: 'Igbo', value: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { code: 'am', label: 'Amharic', value: 'Amharic', nativeName: 'አማርኛ' },
  {
    code: 'uz',
    label: 'Uzbek',
    value: 'Uzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  {
    code: 'sd',
    label: 'Sindhi',
    value: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  { code: 'ne', label: 'Nepali', value: 'Nepali', nativeName: 'नेपाली' },
  {
    code: 'ro',
    label: 'Romanian, Moldavian, Moldovan',
    value: 'Romanian, Moldavian, Moldovan',
    nativeName: 'română',
  },
  {
    code: 'tl',
    label: 'Tagalog',
    value: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  {
    code: 'nl',
    label: 'Dutch',
    value: 'Dutch',
    nativeName: 'Nederlands, Vlaams',
  },
  { code: 'ps', label: 'Pashto', value: 'Pashto, Pushto', nativeName: 'پښتو' },
  { code: 'xh', label: 'Xhosa', value: 'Xhosa', nativeName: 'isiXhosa' },
  {
    code: 'ms',
    label: 'Malay',
    value: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
  },
  { code: 'km', label: 'Khmer', value: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  {
    code: 'af',
    label: 'Afrikaans',
    value: 'Afrikaans',
    nativeName: 'Afrikaans',
  },
  {
    code: 'si',
    label: 'Sinhala',
    value: 'Sinhala, Sinhalese',
    nativeName: 'සිංහල',
  },
  {
    code: 'so',
    label: 'Somali',
    value: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali',
  },
  { code: 'as', label: 'Assamese', value: 'Assamese', nativeName: 'অসমীয়া' },
  { code: 'bm', label: 'Bambara', value: 'Bambara', nativeName: 'bamanankan' },
  {
    code: 'az',
    label: 'Azerbaijani',
    value: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
  },
  {
    code: 'st',
    label: 'Southern Sotho',
    value: 'Southern Sotho',
    nativeName: 'Sesotho',
  },
  { code: 'tn', label: 'Tswana', value: 'Tswana', nativeName: 'Setswana' },
  { code: 'cs', label: 'Czech', value: 'Czech', nativeName: 'česky, čeština' },
  {
    code: 'el',
    label: 'Greek, Modern',
    value: 'Greek, Modern',
    nativeName: 'Ελληνικά',
  },
  { code: 'kk', label: 'Kazakh', value: 'Kazakh', nativeName: 'Қазақ тілі' },
  { code: 'sv', label: 'Swedish', value: 'Swedish', nativeName: 'svenska' },
  { code: 'hu', label: 'Hungarian', value: 'Hungarian', nativeName: 'Magyar' },
  {
    code: 'rw',
    label: 'Kinyarwanda',
    value: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  {
    code: 'bg',
    label: 'Bulgarian',
    value: 'Bulgarian',
    nativeName: 'български език',
  },
  { code: 'da', label: 'Danish', value: 'Danish', nativeName: 'dansk' },
  { code: 'hr', label: 'Croatian', value: 'Croatian', nativeName: 'hrvatski' },
  {
    code: 'fi',
    label: 'Finnish',
    value: 'Finnish',
    nativeName: 'suomi, suomen kieli',
  },
  {
    code: 'lt',
    label: 'Lithuanian',
    value: 'Lithuanian',
    nativeName: 'lietuvių kalba',
  },
  {
    code: 'lv',
    label: 'Latvian',
    value: 'Latvian',
    nativeName: 'latviešu valoda',
  },
  { code: 'no', label: 'Norwegian', value: 'Norwegian', nativeName: 'Norsk' },
  { code: 'sk', label: 'Slovak', value: 'Slovak', nativeName: 'slovenčina' },
];

const languages = languagesItems.map(({ value, label, nativeName }) => ({
  value,
  label: `${label} ${nativeName}`,
}));

const DATA_PAYPMENT_REPORT = [
  {
    value: DATA_PAYPMENT_REPORT_KEYS.SUNDAY_TO_SATURDAY_PAYMENT_WEEK,
    label: DATA_PAYPMENT_REPORT_KEYS.SUNDAY_TO_SATURDAY_PAYMENT_WEEK,
  },
  {
    value: DATA_PAYPMENT_REPORT_KEYS.SHOW_AWR_REPORT,
    label: DATA_PAYPMENT_REPORT_KEYS.SHOW_AWR_REPORT,
  },
];

const REGEX_PHONE_NUMBER = /^(0)[0-9]{10}$/;

const REGEX_SORT_CODE = /^[0-9]{6}$/;

const REGEX_ACCOUNT_NUMBER = /^[0-9]{6,8}$/;

const REGEX_NATIONAL_INSURANCE_NO = /^[a-z]{2}\d{6}[a-z]$/i;

const REGEX_POST_CODE =
  // eslint-disable-next-line max-len
  /^(([a-zA-Z]{1,2}[0-9][a-zA-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][a-zA-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[a-zA-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;

const REGEX_COMPANY_REGISTRATION_NUMBER = /^[0-9]{8}$/;

const REGEX_VAT_NUMBER = /^([GB])*(([0-9]\d{8})|([1-9]\d{11}))$/;

const REGEX_CVV = /^\d{3,4}$/;

const REGEX_PAY_RATE = /^-?\d+?(\.\d+)?$/;

const REGEX_EMAIL =
  // eslint-disable-next-line max-len, no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// 500 milliseconds
const DEBOUNCE_WAIT = 500;

const PAGE_SIZE_OPTIONS = [10, 20, 25, 50, 100];

const DAY_OF_MONTH = 1;

const LIMITED_AGE = 16;

const REGEX_EXPIRY_DATE = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;

const REGEX_VALIDATE_TIME = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$$/;

const ORDER_BY = 'created_at';

const ORDER_BY_PROVIDER = 'provider_name';

const ORDER_DIRECTION = 'desc';

const ORDER_DIRECTION_ASC = 'asc';

const DEFAULT_PAYRATE = 11.44;

const DEFAULT_RATES = {
  OTHER_COSTS: 0,
  MARGIN_FIXED: 1.2,
  MARGIN_PERCENT: 10,
  MARGIN_COST_TYPE: 'percent',
  HOLIDAY_DAYS: 28,
  PENSION_PERCENT: 3,
  PENSION_THRESHOLD: 120,
  APPRENTICESHIP: 0.5,
  EMPLOYER_NI_PER_WEEK_PERCENT: 13.8,
  EMPLOYER_NI_HOURS_WEEK: 42,
  EMPLOYER_NI_THRESHOLD: 175,
};

const weeknumber = 52;

const week = `Week ${weeknumber}`;

const listWeek = [];
for (let i = 1; i <= weeknumber; i += 1) {
  listWeek.push({
    label: `Week ${i}`,
    value: i,
  });
}

const LIST_YEAR = [];
for (let i = moment().year(); i >= 2006; i -= 1) {
  LIST_YEAR.push({
    label: i,
    value: i,
  });
}

const icons = [
  {
    className: 'acohol-licence-icon',
    src: acoholLicenceYellow,
  },
  {
    className: 'driving-licence-icon',
    src: drivingLicenceYellow,
  },
  {
    className: 'scooter-licence-icon',
    src: scooterLicenceYellow,
  },
  {
    className: 'hgv-licence-icon',
    src: hgvLicenceYellow,
  },
  {
    className: 'manual-handling-icon',
    src: manualHandlingYellow,
  },
  {
    className: 'dbs-check-icon',
    src: dbsCheckYellow,
  },
  {
    className: 'welder-icon',
    src: iconWelderBlack,
  },
  {
    className: 'sia-icon',
    src: siaIcon,
  },
];

const RADIUSES = [
  {
    value: 1,
    label: '1 Mile',
  },
  {
    value: 5,
    label: '5 Miles',
  },
  {
    value: 10,
    label: '10 Miles',
  },
  {
    value: 20,
    label: '20 Miles',
  },
];

const licencesAndDocsIcons = [
  {
    className: 'acohol-licence-icon',
    src: acoholLicenceYellow,
    name: 'Alcohol',
    dataIndex: 'alcoholLicense',
  },
  {
    className: 'driving-licence-icon',
    src: drivingLicenceYellow,
    name: 'Driving',
    dataIndex: 'drivingLicense',
  },
  {
    className: 'welder-icon',
    src: iconWelderBlack,
    name: 'Welder',
    dataIndex: 'welder',
  },
  {
    className: 'scooter-licence-icon',
    src: scooterLicenceYellow,
    name: 'Scooter',
    dataIndex: 'scooterLicense',
  },
  {
    className: 'hgv-licence-icon',
    src: hgvLicenceYellow,
    name: 'HGV Driver',
    dataIndex: 'hgvLicense',
  },
  {
    className: 'sia-icon',
    src: siaIcon,
    name: 'Sia',
    dataIndex: 'sia',
  },
  {
    className: 'manual-handling-icon',
    src: manualHandlingYellow,
    name: 'Manual Handling',
    dataIndex: 'manualHanding',
  },
  {
    className: 'dbs-check-icon',
    src: dbsCheckYellow,
    name: 'DS Checked',
    dataIndex: 'dbsCheck',
  },
  {
    className: 'driving-licence-icon',
    name: 'Fork Lift',
    dataIndex: 'forkLiftTruck',
    src: drivingLicenceYellow,
  },
];

const LIST_ROLE_KEYS = {
  CHEF: 14,
  WELDER: 26,
  WAITER_WAITRESS: 10,
  BARTENDER: 11,
  EVENT_STAFF: 18,
  KITCHEN_PORTER: 13,
  OTHER: 24,
  ROOM_ATTENDANT: 16,
  CLEANER: 15,
  RETAIL: 9,
  WAREHOUSE_OPERATIVE: 17,
  MHE_OPERATOR: 27,
};

const listRoles = [
  { label: 'Chef', value: LIST_ROLE_KEYS.CHEF },
  { label: 'Welder', value: LIST_ROLE_KEYS.WELDER },
  { label: 'Waiter/Waitress', value: LIST_ROLE_KEYS.WAITER_WAITRESS },
  { label: 'Bartender', value: LIST_ROLE_KEYS.BARTENDER },
  { label: 'Event Staff', value: LIST_ROLE_KEYS.EVENT_STAFF },
  { label: 'Kitchen Porter', value: LIST_ROLE_KEYS.KITCHEN_PORTER },
  { label: 'Other', value: LIST_ROLE_KEYS.OTHER },
  { label: 'Room Attendant', value: LIST_ROLE_KEYS.ROOM_ATTENDANT },
  { label: 'Cleaner', value: LIST_ROLE_KEYS.CLEANER },
  { label: 'Retail', value: LIST_ROLE_KEYS.RETAIL },
  { label: 'Warehouse Operative', value: LIST_ROLE_KEYS.WAREHOUSE_OPERATIVE },
  { label: 'MHE Operator', value: LIST_ROLE_KEYS.MHE_OPERATOR },
];

const vocativeTitles = [
  { value: 1, label: 'Mr.' },
  { value: 2, label: 'Mrs.' },
  { value: 3, label: 'Miss.' },
  { value: 4, label: 'Ms.' },
];

/**
 * @type {Array<Object>}
 * @property {string} label
 * @property {string} value
 */
const listHears = [
  {
    value: 'Social Media (Instagram etc.)',
    label: 'Social Media (Instagram etc.)',
  },
  { value: 'Google', label: 'Google' },
  { value: 'Indeed Job Board', label: 'Indeed Job Board' },
  { value: 'Other Job Board', label: 'Other Job Board' },
  { value: 'Referred By A Friend', label: 'Referred By A Friend' },
  { value: 'Other', label: 'Other' },
];

const REFERENCE_BY_FRIEND = listHears[4];

const listNationalities = [
  { value: 1, label: 'American' },
  { value: 2, label: 'London' },
  { value: 3, label: 'Afghan' },
];

const listEnglishLevels = [
  { value: 2, label: 'Intermediate' },
  { value: 3, label: 'Advanced' },
  { value: 4, label: 'Fluent' },
  { value: 5, label: 'Native' },
];

const levelLanguage = [
  { value: 1, label: 'Basic' },
  { value: 2, label: 'Intermediate' },
  { value: 3, label: 'Advanced' },
  { value: 4, label: 'Fluent' },
  { value: 5, label: 'Native' },
];

const listLocation = [
  { value: 1, label: 'London' },
  { value: 2, label: 'Glasgow' },
  { value: 4, label: 'Edinburgh' },
  { value: 5, label: 'Manchester' },
  { value: 6, label: 'Preston' },
  { value: 7, label: 'Northampton' },
  { value: 7, label: 'Stroke on Trent' },
  { value: 7, label: 'Rugby' },
  { value: 7, label: 'Kent' },
];

const rolesTrainingRequired = [14, 26];

const PAYROLL_ENTRY_CODES = [
  { value: 'no_bonus', label: '' },
  { value: 'deduction_hours', label: 'DH' },
  { value: 'deduction_money', label: 'D£', disabled: true },
  { value: 'bonus_hours', label: 'BH' },
  { value: 'bonus_money', label: 'B£' },
];

const PAYROLL_ENTRY_CODES_PT = [
  { value: 0, label: 'No Bonus', key: 'noBonus' },
  { value: 1, label: 'DH', key: 'deductionHours' },
  { value: 2, label: 'D£', key: 'deductionMoney' },
  { value: 3, label: 'BH', key: 'bonusHours' },
  { value: 4, label: 'B£', key: 'bonusValue' },
];

// TODO: Update when API had been done.
const DAYS = {
  mon: moment().isoWeekday(1).format('DD/MM/YYYY'),
  tues: moment().isoWeekday(2).format('DD/MM/YYYY'),
  wed: moment().isoWeekday(3).format('DD/MM/YYYY'),
  thurs: moment().isoWeekday(4).format('DD/MM/YYYY'),
  fri: moment().isoWeekday(5).format('DD/MM/YYYY'),
  sat: moment().isoWeekday(6).format('DD/MM/YYYY'),
  sun: moment().isoWeekday(7).format('DD/MM/YYYY'),
};

const VACANCY_APPLICANT_EMPLOYMENT_POSITION_DEFAULT = 9999;

const TRAINING = 182;

const INDUSTRY = {
  OTHER: 10,
};

const PAYMENT_TYPE = {
  payrollTemplate: 'pay_template',
  flexiblePayRate: 'flexible_pay_rate',
};

const ENABLE_DATES_AFTER_DATE_NUMBER = 90;

const LIMIT_PHONE_NUMBER = 11;
const LIMIT_INPUT = {
  sortCode: 6,
  shareCode: 9,
  phoneNumber: 11,
  taxCode: 9,
  companyNumber: 8,
  vatNumber: 9,
};

const DATE_FORMAT = 'DD/MM/YYYY';
/**
 * @type {Array<Object>}
 * @property {string} label
 * @property {string} value
 */
const ROTA_TYPES = [
  {
    label: 'Flexible',
    value: 'Flexible',
  },
  {
    label: 'Specific Sites / Roles',
    value: 'Specific_Site',
  },
  {
    label: 'Rota',
    value: 'Rota_Only',
  },
];

const ACCESS_TYPES = {
  Flexible: 'flexible',
  Specific_Site: 'specific_site',
  Rota_Only: 'rota_only',
  Flexible_Rota: 'flexible_rota',
  Specific_Site_Rota: 'specific_site_rota',
};

const SEEKER_ROTA_TYPES = [
  {
    value: ACCESS_TYPES.Flexible,
    types: [ACCESS_TYPES.Flexible],
  },
  {
    value: ACCESS_TYPES.Specific_Site,
    types: [ACCESS_TYPES.Specific_Site],
  },
  {
    value: ACCESS_TYPES.Rota_Only,
    types: [ACCESS_TYPES.Rota_Only],
  },
  {
    value: ACCESS_TYPES.Flexible_Rota,
    types: [ACCESS_TYPES.Flexible, ACCESS_TYPES.Rota_Only],
  },
  {
    value: ACCESS_TYPES.Specific_Site_Rota,
    types: [ACCESS_TYPES.Specific_Site, ACCESS_TYPES.Rota_Only],
  },
];

/**
 * @type {Array<Object>}
 * @property {string} label
 * @property {string} value
 */
export const RATE_TYPES = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Overtime',
    value: 'overtime',
  },
  {
    label: 'Incentive',
    value: 'incentive',
  },
];

const RANGE_CHECK_IN = 2;
const RANGE_CHECK_OUT = 6;

const EXPIRED_VISA_DAYS = 14;

export {
  DATE_FORMAT,
  ENABLE_DATES_AFTER_DATE_NUMBER,
  ENV,
  INDUSTRY,
  LoadingStatus,
  StorageKey,
  languages,
  STATUS,
  STATUS_CANDIDATE,
  RECRUITMENT_VIEW,
  RESOURCE_TYPE,
  REGEX_PHONE_NUMBER,
  DEBOUNCE_WAIT,
  LEAD_STATUS_KEY,
  LEAD_STATUS_MODAL_CONTENT,
  ORDER_BY,
  ORDER_BY_PROVIDER,
  ORDER_DIRECTION,
  ORDER_DIRECTION_ASC,
  REGEX_POST_CODE,
  REGEX_COMPANY_REGISTRATION_NUMBER,
  REGEX_VAT_NUMBER,
  REGEX_CVV,
  PAGE_SIZE_OPTIONS,
  DATA_PROTECTION,
  DATA_PROTECTION_KEYS,
  REGEX_PAY_RATE,
  DATA_PAYPMENT_REPORT,
  DATA_PAYPMENT_REPORT_KEYS,
  REGEX_VALIDATE_TIME,
  REGEX_EMAIL,
  week,
  weeknumber,
  listWeek,
  listRoles,
  LIST_YEAR,
  vocativeTitles,
  listHears,
  listNationalities,
  listEnglishLevels,
  levelLanguage,
  listLocation,
  icons,
  licencesAndDocsIcons,
  candidateStatuses,
  rolesTrainingRequired,
  RADIUSES,
  PAYMENT_TYPE,
  REGEX_ACCOUNT_NUMBER,
  COST_ROLES,
  REGEX_SORT_CODE,
  REGEX_NATIONAL_INSURANCE_NO,
  DAY_OF_MONTH,
  REGEX_EXPIRY_DATE,
  LIMITED_AGE,
  DEPARTMENT,
  ACCESS_LEVEL,
  MANAGER,
  MARITAL,
  listGenders,
  PAYROLL_ENTRY_CODES,
  DAYS,
  DEFAULT_PAYRATE,
  LIST_ROLE_KEYS,
  VACANCY_APPLICANT_EMPLOYMENT_POSITION_DEFAULT,
  TRAINING,
  ACCEPT_TYPE,
  LICENCES,
  LIMIT_PHONE_NUMBER,
  REFERENCE_BY_FRIEND,
  LIMIT_INPUT,
  PAYROLL_ENTRY_CODES_PT,
  ROTA_TYPES,
  ACCESS_TYPES,
  DEFAULT_RATES,
  RANGE_CHECK_IN,
  RANGE_CHECK_OUT,
  SEEKER_ROTA_TYPES,
  STATUS_JOB_CODE,
  STATUS_FILTER_DROPDOWN_OPTIONS,
  STATUS_OPTIONS,
  EXPIRED_VISA_DAYS,
};
