import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { date } from '@/utils';

import ShiftInput from './ShiftInput';

const headerContents = [
  { title: 'Start Date' },
  { title: 'Start Time' },
  { title: 'Finish Date' },
  { title: 'Finish Time' },
  { title: 'Duration' },
];

const Shifts = ({
  shiftList,
  startDate,
  startTime,
  endTime,
  endDate,
  duration,
  hour,
  minute,
  removeShiftIcon,
  isEditable,
  isDisableTime,
  rulesStartDate,
  rulesEndDate,
}) => {
  const form = Form.useFormInstance();

  const name = (index) => ({
    startDate: [index, 'startDate'],
    startTime: [index, 'startTime'],
    endDate: [index, 'endDate'],
    endTime: [index, 'endTime'],
    duration: [index, 'duration'],
  });

  const editShift = (valueChange, index) => {
    // if start time of end time has changed => duration must be changed as well
    const selectedShift = shiftList[index];

    const fullStartTime = date.toString(
      selectedShift.startDate,
      selectedShift.startTime,
    );

    const fullEndTime = date.toString(
      selectedShift.endDate,
      selectedShift.endTime,
    );

    // Handle if start date is changed
    if (valueChange.startDate) {
      const fullStartTimeUpdate = date.toString(
        valueChange.startDate,
        selectedShift.startTime,
      );

      const updatedDuration = date.getDuration(
        fullStartTimeUpdate,
        fullEndTime,
      );

      shiftList[index] = {
        ...shiftList[index],
        ...valueChange,
        duration: updatedDuration,
      };
    }

    // Handle if end date is changed
    if (valueChange.endDate) {
      const fullEndTimeUpdate = date.toString(
        valueChange.endDate,
        selectedShift.endTime,
      );

      const updatedDuration = date.getDuration(
        fullStartTime,
        fullEndTimeUpdate,
      );

      shiftList[index] = {
        ...shiftList[index],
        ...valueChange,
        duration: updatedDuration,
      };
    }

    // Handle if start time is changed
    if (valueChange.startTime) {
      const fullStartTimeUpdate = date.toString(
        selectedShift.startDate,
        valueChange.startTime,
      );

      const endTimeConvert = date.getEndTime(valueChange.startTime);

      const fullEndTimeUpdate = date.toString(
        selectedShift.endDate,
        endTimeConvert,
      );

      const updatedDuration = date.getDuration(
        fullStartTimeUpdate,
        fullEndTimeUpdate,
      );

      shiftList[index] = {
        ...shiftList[index],
        ...valueChange,
        endTime: endTimeConvert,
        duration: updatedDuration,
      };
    }

    // Handle if finish time is changed
    if (valueChange.endTime) {
      const fullEndTimeUpdate = date.toString(
        selectedShift.endDate,
        valueChange.endTime,
      );

      const updatedDuration = date.getDuration(
        fullStartTime,
        fullEndTimeUpdate,
      );

      shiftList[index] = {
        ...shiftList[index],
        ...valueChange,
        duration: updatedDuration,
      };
    }

    shiftList[index] = {
      ...shiftList[index],
      ...valueChange,
    };

    form.setFieldsValue({
      items: shiftList,
    });
  };

  return (
    <div className='shifts'>
      <div className='shift-header'>
        {headerContents.map((content) => (
          <div className='content-container input-height' key={content.title}>
            <p key={content.title} className='text-header'>
              {content.title}
            </p>
          </div>
        ))}
      </div>
      <Form.List
        name='items'
        initialValue={[
          {
            id: Math.floor(Math.random() * 100),
            startDate,
            startTime,
            endTime,
            endDate,
            duration,
          },
        ]}
      >
        {() =>
          shiftList.map((shift, index) => (
            <ShiftInput
              key={shift.id}
              value={shift}
              name={name(index)}
              startTime={startTime}
              hour={hour}
              minute={minute}
              duration
              isEditable={isEditable}
              action={() => removeShiftIcon(shift.id, index)}
              onStartDateChange={(vl) => {
                editShift({ startDate: vl }, index);
              }}
              onStartTimeChange={(e) => {
                editShift({ startTime: e.target.value }, index);
              }}
              onEndTimeChange={(e) => {
                editShift({ endTime: e.target.value }, index);
              }}
              onEndDateChange={(vl) => {
                editShift({ endDate: vl }, index);
              }}
              isDisableTime={isDisableTime}
              rulesStartDate={rulesStartDate}
              rulesEndDate={rulesEndDate}
            />
          ))
        }
      </Form.List>
    </div>
  );
};

Shifts.propTypes = {
  shiftList: PropTypes.array,
  startDate: PropTypes.instanceOf(Date),
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  endDate: PropTypes.instanceOf(Date),
  duration: PropTypes.string,
  hour: PropTypes.number,
  minute: PropTypes.number,
  isEditable: PropTypes.bool,
  isDisableTime: PropTypes.bool,
  removeShiftIcon: PropTypes.func,
  rulesStartDate: PropTypes.array,
  rulesEndDate: PropTypes.array,
};

Shifts.defaultProps = {
  shiftList: [],
  duration: '1',
  isEditable: false,
  isDisableTime: false,
  rulesStartDate: [],
  rulesEndDate: [],
};
export default Shifts;
