import { get, sumBy } from 'lodash';

import { time } from '@/utils';

const calTotal = (record) => {
  const payRate = Number(get(record, 'payRate', 0));
  const chargeRate = Number(get(record, 'chargeRate', 0));
  const hours = Number(get(record, 'totalHours', 0));

  const totalPay = payRate * hours;
  const totalCharge = chargeRate * hours;
  return { totalPay, totalCharge };
};

const calTotalWithBreak = ({ value, hours, breakDuration }) => {
  const parseBreakMinsToHrs = breakDuration / 60;
  return (hours - parseBreakMinsToHrs) * value;
};

const calTotalPay = ({ payRate, hours }) => Number(payRate) * Number(hours);

const calTotalCharge = ({ chargeRate, hours }) =>
  Number(chargeRate) * Number(hours);

const calDailySummary = (data) => {
  const shiftDuration = sumBy(data, (record) => {
    const { startAt, endAt } = record;
    const duration = time.durationBetweenTimes({
      startTime: startAt,
      endTime: endAt,
    });
    return duration;
  });
  const totalPay = sumBy(data, (record) => {
    const { adjustedPayrate, startAt, endAt } = record;
    const hours = time.durationBetweenTimes({
      startTime: startAt,
      endTime: endAt,
    });
    return calTotalPay({ payRate: adjustedPayrate, hours });
  });
  const totalCharge = sumBy(data, (record) => {
    const { chargeRate, startAt, endAt } = record;
    const hours = time.durationBetweenTimes({
      startTime: startAt,
      endTime: endAt,
    });
    return calTotalCharge({ chargeRate, hours });
  });
  const payrollTotalHours = sumBy(data, 'hourWithBonus');
  const payrollTotalPay = sumBy(data, 'payRateWithBonus');
  const payrollTotalCharge = sumBy(data, 'chargeRateWithBreak');
  const breakTotalPay = sumBy(data, 'payRateWithBreak');
  const breakTotalCharge = sumBy(data, 'chargeRateWithBreak');

  return {
    totalPay,
    totalCharge,
    breakTotalPay,
    shiftDuration,
    payrollTotalPay,
    breakTotalCharge,
    payrollTotalHours,
    payrollTotalCharge,
  };
};

const calMargin = ({ totalPay, totalCost, totalCharge }) => {
  const payAndCost = totalPay + totalCost;
  const difference = totalCharge - payAndCost;
  return totalCharge ? (difference / totalCharge) * 100 : 0;
};

const calculation = {
  calTotal,
  calMargin,
  calTotalPay,
  calTotalCharge,
  calDailySummary,
  calTotalWithBreak,
};

export default calculation;
