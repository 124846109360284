import React, { useMemo, useState } from 'react';

import { GeneralTemplate, Tabs } from '@/components';

import { Users, Holidays, UsersGIGProvider } from './components';

import './styles.scss';

const UserListView = () => {
  // TODO: Update later, waiting for confirmation to customer
  const adminName = 'Esther Jenkinson';

  const USER_TABS = useMemo(
    () => ({
      users: {
        key: 'users',
        title: 'Users',
        child: <Users />,
        headerText: 'Users',
        buttonLink: '/create-new-user',
        buttonContent: 'Create User',
        breadCrumb: [{ item: 'Users', to: '/users' }],
      },
      holidays: {
        key: 'holidays',
        title: 'Holidays',
        child: <Holidays />,
        headerText: `User - ${adminName}`,
        buttonLink: '/create-new-holiday',
        buttonContent: 'Request Holiday',
        breadCrumb: [
          { item: 'Users', to: '/users' },
          { item: 'holidays', to: '/users' },
        ],
      },
      gig_provider: {
        key: 'gig_provider',
        title: 'GIGProvider',
        child: <UsersGIGProvider />,
        headerText: 'Users',
        buttonLink: '/create-new-gig-provider',
        buttonContent: 'Create GIG Provider log in',
        breadCrumb: [{ item: 'Users', to: '/users' }],
      },
      holidays_requests: {
        key: 'holidays_requests',
        title: 'Holidays Requests',
        child: null,
        headerText: 'Users',
        buttonLink: '/create-new-holiday-requests',
        buttonContent: 'Create GIG Provider log in',
        breadCrumb: [{ item: 'Users', to: '/users' }],
      },
    }),
    [],
  );

  const tabPanes = useMemo(
    () => [
      {
        key: USER_TABS.users.key,
        tab: USER_TABS.users.title,
        child: USER_TABS.users.child,
      },

      // TODO: Customer must hidden these bellow tabs, show when implements phase 2
      // {
      //   key: USER_TABS.holidays.key,
      //   tab: USER_TABS.holidays.title,
      //   child: USER_TABS.holidays.child,
      // },
      // {
      //   key: USER_TABS.gig_provider.key,
      //   tab: USER_TABS.gig_provider.title,
      //   child: USER_TABS.gig_provider.child,
      // },
      // {
      //   key: USER_TABS.holidays_requests.key,
      //   tab: USER_TABS.holidays_requests.title,
      //   child: USER_TABS.holidays_requests.child,
      // },
    ],
    [USER_TABS],
  );

  const [headerContent, setHeaderContent] = useState(USER_TABS.users);

  const handleTabChange = (tabKey) => setHeaderContent(USER_TABS[tabKey]);

  return (
    <GeneralTemplate
      mainItem='Users'
      data={headerContent.breadCrumb}
      headerText={headerContent.headerText}
      buttonLink={headerContent.buttonLink}
      buttonContent={headerContent.buttonContent}
      subTemplateClassName='user-list-sub-template'
    >
      <div className='user-list-container'>
        <Tabs
          tabPanes={tabPanes}
          defaultActiveKey={USER_TABS.users.key}
          onChange={handleTabChange}
        />
      </div>
    </GeneralTemplate>
  );
};
export default UserListView;
