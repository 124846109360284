/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Radio } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 *
 * @param {import('antd').RadioGroupProps & { options?: Array }} props
 * @returns
 */

const RadioCheckbox = ({ options, className, value, ...props }) => (
  // Note: options = [{value: 'Yes, label: 'Yes'}, {value: 'No', label: 'No'}]

  <Radio.Group
    className={classNames('radio-checkbox', className)}
    options={options}
    value={value}
    {...props}
  />
);

RadioCheckbox.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

RadioCheckbox.defaultProps = {
  options: [],
  className: '',
};

export default RadioCheckbox;
