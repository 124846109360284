import React from 'react';

import { message, Form } from 'antd';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { FormItem } from '@/components';
import { ProviderService } from '@/services';

import ListSeeker from './component/ListSeeker';
import './styles.scss';

const TrustAndBlockSeeker = ({ setLoading, isEditProvider }) => {
  const form = Form.useFormInstance();
  const employerTrustedEmployee = Form.useWatch(
    'employerTrustedEmployee',
    form,
  );

  const employerBlockedEmployee = Form.useWatch(
    'employerBlockedEmployee',
    form,
  );

  const { providerId } = useParams();
  const { mutate: addTrustedSeeker } = useMutation(
    (trustedSeekers) =>
      ProviderService.addTrustedSeekers(providerId, trustedSeekers),
    {
      onSuccess: (_data, variables) => {
        setLoading(false);
        message.success('Add trusted seekers successfully');
        form.setFields([
          {
            name: 'employerTrustedEmployee',
            value: [...employerTrustedEmployee, ...variables],
          },
        ]);
      },
      onError: () => {
        setLoading(false);
        message.error('Add trusted seekers failure');
      },
    },
  );

  const { mutate: addBlockedSeekers } = useMutation(
    (blockedSeekers) =>
      ProviderService.addBlockedSeekers(providerId, blockedSeekers),
    {
      onSuccess: (_data, variables) => {
        setLoading(false);
        message.success('Add blocked seekers successfully');
        form.setFields([
          {
            name: 'employerBlockedEmployee',
            value: [...employerBlockedEmployee, ...variables],
          },
        ]);
      },
      onError: () => {
        setLoading(false);
        message.error('Add blocked seekers failure');
      },
    },
  );

  const { mutate: removeTrustedSeeker } = useMutation(
    (seekerId) => ProviderService.removeTrustedSeeker(providerId, seekerId),
    {
      onSuccess: (_data, variables) => {
        setLoading(false);
        message.success('Delete trusted seeker successfully');
        const index = (employerTrustedEmployee ?? []).findIndex(
          (seeker) => seeker.employeeId === variables,
        );
        employerTrustedEmployee.splice(index, 1);
        form.setFields([
          {
            name: 'employerTrustedEmployee',
            value: [...employerTrustedEmployee],
          },
        ]);
      },
      onError: () => {
        setLoading(false);
        message.error('Delete trusted seeker failure');
      },
    },
  );

  const { mutate: removeBlockedSeeker } = useMutation(
    (seekerId) => ProviderService.removeBlockedSeeker(providerId, seekerId),
    {
      onSuccess: (_data, variables) => {
        setLoading(false);
        message.success('Delete blocked seeker successfully');
        const index = (employerBlockedEmployee ?? []).findIndex(
          (seeker) => seeker.employeeId === variables,
        );
        employerBlockedEmployee.splice(index, 1);
        form.setFields([
          {
            name: 'employerBlockedEmployee',
            value: [...employerBlockedEmployee],
          },
        ]);
      },
      onError: () => {
        setLoading(false);
        message.error('Delete blocked seeker failure');
      },
    },
  );

  return (
    <div className='trust-and-block-seeker'>
      <div className='trust-list trust-and-block-seeker__inner'>
        <FormItem name='employerTrustedEmployee'>
          <ListSeeker
            title='Worker Pool'
            list={employerTrustedEmployee}
            handleAddSeekers={addTrustedSeeker}
            handleRemoveSeeker={removeTrustedSeeker}
            setLoading={setLoading}
            isEditProvider={isEditProvider}
            name='employerTrustedEmployee'
          />
        </FormItem>
      </div>
      <div className='trust-list trust-and-block-seeker__inner'>
        <FormItem name='employerBlockedEmployee'>
          <ListSeeker
            title='Blocked Seekers'
            list={employerBlockedEmployee}
            handleAddSeekers={addBlockedSeekers}
            handleRemoveSeeker={removeBlockedSeeker}
            setLoading={setLoading}
            isEditProvider={isEditProvider}
            name='employerBlockedEmployee'
          />
        </FormItem>
      </div>
    </div>
  );
};

export default TrustAndBlockSeeker;

TrustAndBlockSeeker.propTypes = {
  setLoading: PropTypes.func,
  isEditProvider: PropTypes.bool,
};
