import React, { useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Form, Row, message } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  Button,
  CheckboxGroup,
  FormItem,
  Input,
  Modal,
  Textarea,
  Select,
  AutoComplete,
  Checkbox,
} from '@/components';
import Upload from '@/components/Upload';
import { DATA_PROTECTION } from '@/constants';
import { useOmniUsers } from '@/hooks/dropdowns';
import { useUploadFileS3 } from '@/hooks/useUploadFile';
import { ProviderService } from '@/services';
import {
  dummyRequest,
  beforeUploadFile,
  formatUploadedList,
} from '@/utils/uploadFileUtil';

import Comments from '../Comments';
import './styles.scss';

const CommentsAndStatusUpdates = ({ isEditProvider }) => {
  const formInstance = Form.useFormInstance();
  const contractFiles = Form.useWatch('contractFile', formInstance);
  const { base64, fileList, changeFile, file } = useUploadFileS3();
  const defaultFileList = formatUploadedList(contractFiles);
  const listFile = fileList.length > 0 ? fileList : defaultFileList;

  const { providerId } = useParams();
  const managers = useOmniUsers();

  const [prevBase64Contract, setPrevBase64Contract] = useState(base64);
  if (base64 && base64 !== prevBase64Contract) {
    formInstance.setFieldsValue({
      contractFileName: file.file.name,
    });

    setPrevBase64Contract(base64);
  }

  const [isVisibleModalActivate, setIsVisibleModalActivate] = useState(false);
  const statusWatching = Form.useWatch('status');
  const [isVisibleModalDeactivate, setIsVisibleModalDeactivate] =
    useState(false);

  const { mutate: activateProvider } = useMutation(ProviderService.activate, {
    onError: () => message.error('Failed activate provider'),
    onSuccess: (data) => {
      formInstance.setFieldsValue({
        status: data.status,
      });
      message.success('Activate successfully!');
    },
  });

  const { mutate: deactivateProvider } = useMutation(
    ProviderService.deactivate,
    {
      onError: () => message.error('Failed deactivate provider'),
      onSuccess: (data) => {
        formInstance.setFieldsValue({
          status: data.status,
        });
        message.success('Deactivate successfully!');
      },
    },
  );

  const handleClickActivate = () => {
    setIsVisibleModalActivate(false);
    activateProvider(providerId);
  };

  const handleClickDeactivate = () => {
    setIsVisibleModalDeactivate(false);
    deactivateProvider(providerId);
  };

  return (
    <div className='comments-and-status-updates'>
      <div className='comments-and-status-updates__wrapper'>
        <div className='comments-and-status-updates__wrapper-container flex gap'>
          <div className='wrapper-container--left'>
            <div className='input-group'>
              <Comments />
              <div className='contract'>
                <FormItem name='contractFile'>
                  <Upload
                    name='contractFile'
                    fileList={listFile}
                    onChange={changeFile}
                    showUploadList={{
                      showRemoveIcon: true,
                      removeIcon: <CloseOutlined />,
                    }}
                    customRequest={dummyRequest}
                    beforeUpload={beforeUploadFile}
                    accept='.doc, application/msword, .pdf, application/pdf, image/*'
                    multiple
                    openFileDialogOnClick
                  >
                    <Button className='yellow-button upload-button'>
                      Upload Contract
                    </Button>
                  </Upload>
                </FormItem>
              </div>
            </div>
          </div>
          <div className='wrapper-container--right'>
            <div className='input-group input-group__shadow '>
              <div className='list__input list__input-data-protection'>
                <FormItem
                  name='dataProtection'
                  className='form-item'
                  label='Data Protection'
                >
                  <CheckboxGroup>
                    {DATA_PROTECTION.map((item) => (
                      <Row className='data-protection-row'>
                        <Checkbox value={item.value}>{item.label}</Checkbox>
                      </Row>
                    ))}
                  </CheckboxGroup>
                </FormItem>
              </div>
            </div>
            <FormItem
              name='note'
              className='note-lead-container'
              label='Notes - From Leads'
            >
              <Textarea placeholder='Notes - From Leads' />
            </FormItem>
            <div className='input-group'>
              <div className='list__input date-of-last-booking'>
                <FormItem
                  name='lastPostingJob'
                  className='form-item'
                  label='Date of Last Booking'
                >
                  <AutoComplete disabled />
                </FormItem>
                <FormItem
                  name='accountManagerName'
                  className='form-item'
                  label='Allocated Account Manager'
                  rules={[
                    {
                      required: true,
                      message: 'Please choose Allocated Account Manager',
                    },
                  ]}
                  required
                >
                  <Select options={managers} placeholder='Name' required />
                </FormItem>
              </div>
              <div className='list__input date-of-last-booking'>
                <div className='button-activated__wrapper'>
                  {isEditProvider ? (
                    <>
                      <FormItem name='status' hidden>
                        <Input />
                      </FormItem>
                      <Button
                        className={classNames('yellow-button', {
                          activate: statusWatching !== 'active',
                          'button-deactivate': statusWatching === 'active',
                        })}
                        onClick={() =>
                          statusWatching !== 'active'
                            ? setIsVisibleModalActivate(true)
                            : null
                        }
                      >
                        {statusWatching === 'active' ? 'Activated' : 'Activate'}
                      </Button>
                      <Button
                        className={classNames('yellow-button', {
                          'button-deactivate': statusWatching === 'blocked',
                          deactivate: statusWatching !== 'blocked',
                        })}
                        onClick={() =>
                          statusWatching !== 'blocked'
                            ? setIsVisibleModalDeactivate(true)
                            : null
                        }
                      >
                        {statusWatching === 'blocked'
                          ? 'Deactivated'
                          : 'Deactivate'}
                      </Button>

                      <Modal
                        visible={isVisibleModalActivate}
                        title='Activate Client?'
                        onClickNo={() => setIsVisibleModalActivate(false)}
                        onClickYes={handleClickActivate}
                      >
                        Are you sure you want to Activate this new client? Main
                        contact, and contacts for timesheets, bookings and
                        accounts will receive their log ins via email and the
                        clients will be available to post jobs.
                      </Modal>
                      <Modal
                        visible={isVisibleModalDeactivate}
                        title='Deactivate Client?'
                        onClickNo={() => setIsVisibleModalDeactivate(false)}
                        onClickYes={handleClickDeactivate}
                      >
                        Are you sure you want to Deactivate this new client?
                        They will no longer have access to the WebApp and will
                        not be able to book any shifts.
                      </Modal>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CommentsAndStatusUpdates.propTypes = {
  isEditProvider: PropTypes.bool,
};

export default CommentsAndStatusUpdates;
