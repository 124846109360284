import React, { useState } from 'react';

import { Row, Form, Card, Input, Col, Space, message, Spin, Modal } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Button,
  FormItem,
  GeneralTemplate,
  Select,
  Tabs,
  TimePicker,
} from '@/components';
import AllocateToRotaModal from '@/components/AllocateToRotaModal';
import {
  useProviders,
  useRoleItems,
  useProviderLocations,
} from '@/hooks/dropdowns';
import { editRota } from '@/services/rota/editRota';
import { rulesSelect, rulesTextInput } from '@/utils/rulesInput';

import MyCalendar from '../BuildRota/BuildRota';
import { breadcrumb } from '../constants';

import './styles.scss';

const style = {
  borderRadius: '25px',
  border: '2px solid #bebebe',
  marginBottom: '10px',
  marginTop: '10px',
  width: '124px',
  textAlign: 'center',
  color: 'black',
};

const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const formatDate = (startTime, endTime) =>
  `${moment(startTime).format('HH:mm')} - ${moment(endTime).format('HH:mm')}`;
const EditRotaTable = ({
  headerText,
  createButton,
  cancelButton,
  allocateButton,
  startTime,
  endTime,
  form,
  refetchGetRota,
}) => {
  const employerId = Form.useWatch('employerId', form);

  const { id } = useParams();
  const listProviders = useProviders();
  const { data: positions } = useRoleItems();
  const { data: locations } = useProviderLocations(employerId);
  const history = useHistory();

  const [showAllocationPopup, setShowAllocationPopup] = useState(false);

  const options = [
    {
      label: formatDate(startTime, endTime),
      value: formatDate(startTime, endTime),
    },
    { label: 'Off', value: 'off' },
  ];

  const tabPanes = [
    {
      key: 'Worker-rota',
      tab: 'Worker Rota',
      forceRender: true,
    },
    {
      key: 'Build-rota',
      tab: 'Build Rota',
      child: <MyCalendar />,
      forceRender: true,
    },
    {
      key: 'live-rota',
      tab: 'Live Rota',
      forceRender: true,
    },
  ];

  const onChangeTab = (key) => {
    if (key === 'Build-rota') return history.push('/rota?tab=build-rota');
    if (key === 'live-rota') return history.push('/rota?tab=live-rota');

    return null;
  };

  const { mutate: editRotaMutate, isLoading } = useMutation(
    (values) => editRota({ id, values }),
    {
      onSuccess: () => {
        message.success('Rota edited successfully');
      },
    },
  );

  const onChangeDate = (value, weekNumber, shiftNumber) =>
    form.setFields([
      {
        name: [
          'rotaWeeksAttributes',
          weekNumber,
          'rotaShiftsAttributes',
          shiftNumber,
          'dateSelect',
        ],
        value,
      },
      {
        name: [
          'rotaWeeksAttributes',
          weekNumber,
          'rotaShiftsAttributes',
          shiftNumber,
          'status',
        ],
        value: value === 'off' ? 'off' : 'work',
      },
      {
        name: [
          'rotaWeeksAttributes',
          weekNumber,
          'rotaShiftsAttributes',
          shiftNumber,
          'startTime',
        ],
        value: value.split('-')[0],
      },

      {
        name: [
          'rotaWeeksAttributes',
          weekNumber,
          'rotaShiftsAttributes',
          shiftNumber,
          'endTime',
        ],
        value: value.split('-')[1],
      },
    ]);

  const handleSubmit = (values) => {
    editRotaMutate(values);
  };

  const state = useLocation();
  const tabKey = state?.tabKey;

  const handleClickAllocateBtn = () => {
    setShowAllocationPopup(true);
  };

  return (
    <GeneralTemplate
      mainItem='Rota'
      data={breadcrumb}
      buttonContent='Post New Shift'
      headerText={headerText}
      buttonLink='/new-shift'
    >
      <Tabs
        className='job-tabs'
        tabPanes={tabPanes}
        defaultActiveKey={tabKey}
        onChange={onChangeTab}
      />
      <Modal className='loading-modal' footer={null} visible={isLoading}>
        <Spin />
      </Modal>

      <Form form={form} onFinish={handleSubmit}>
        <div className='job-list-view'>
          <div style={{ width: '100%', display: 'flex', gap: 10 }}>
            <div style={{ display: 'flex', gap: 10, width: '50%' }}>
              <FormItem
                required
                rules={rulesSelect('Provider')}
                name='employerId'
                className='input-item'
                label='Provider'
              >
                <Select
                  options={listProviders}
                  className='input-height'
                  placeholder='provider'
                />
              </FormItem>
              <FormItem
                required
                rules={rulesSelect('Location')}
                name='employerLocationId'
                className='input-item'
                label='Location'
              >
                <Select
                  options={locations}
                  className='input-height'
                  placeholder='location'
                />
              </FormItem>
              <FormItem
                required
                rules={rulesSelect('Positions')}
                name='skillId'
                className='input-item'
                label='Positions'
              >
                <Select
                  className='input-height'
                  placeholder='Positions'
                  options={positions}
                />
              </FormItem>
            </div>
            <div className='contract-type-container'>
              <FormItem
                name={['contactTypeAttributes', 'name']}
                className='contract-input'
                label='Contract Type'
                required
                rules={rulesTextInput('Contract Type')}
              >
                <Input placeholder='contract type' />
              </FormItem>
              <div className='date-container'>
                <div className='seeker-text-label'>Shift Time</div>
                <div className='date-items'>
                  <FormItem name='startTime' className='seeker-date-item'>
                    <TimePicker format='HH:mm' placeholder='start time' />
                  </FormItem>
                  <FormItem className='seeker-date-item' name='endTime'>
                    <TimePicker format='HH:mm' placeholder='End time' />
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card className='edit-rota-card'>
          <Row>
            <Col flex={1}>
              <Card className='event-tag'>Pattern</Card>
            </Col>
            <Col flex={7}>
              <Row span={6}>
                {weekDays.map((day) => (
                  <Col key={day} flex={1}>
                    <Card className='event-tag'>{day}</Card>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col flex={1}>
              <Card className='event-tag'>Target Rota</Card>
            </Col>
            <Col flex={1}>
              <Card className='event-tag'>Total on Rota</Card>
            </Col>
          </Row>

          {Array.from({ length: 12 }).map((week, weekNumber) => (
            <Row>
              <Col flex={1}>
                <Card className='event-tag-pattern'>
                  <FormItem
                    name={['rotaWeeksAttributes', weekNumber, 'id']}
                    noStyle
                  />
                  <span>
                    {form.getFieldValue([
                      'rotaWeeksAttributes',
                      weekNumber,
                      'pattern',
                    ])}
                  </span>
                </Card>
              </Col>

              <Col flex={7}>
                <Row span={6}>
                  {Array.from({ length: 7 }).map((shift, shiftNumber) => (
                    <Col flex={1}>
                      <Card className='event-tag'>
                        <FormItem
                          className='edit-rota-select'
                          name={[
                            'rotaWeeksAttributes',
                            weekNumber,
                            'rotaShiftsAttributes',
                            shiftNumber,
                            'dateSelect',
                          ]}
                        >
                          <Select
                            options={options}
                            onChange={(value) =>
                              onChangeDate(value, weekNumber, shiftNumber)
                            }
                            required
                          />
                        </FormItem>
                      </Card>

                      <FormItem
                        name={[
                          'rotaWeeksAttributes',
                          weekNumber,
                          'rotaShiftsAttributes',
                          shiftNumber,
                          'id',
                        ]}
                        noStyle
                      />
                      <FormItem
                        name={[
                          'rotaWeeksAttributes',
                          weekNumber,
                          'rotaShiftsAttributes',
                          shiftNumber,
                          'status',
                        ]}
                        noStyle
                      />

                      <FormItem
                        name={[
                          'rotaWeeksAttributes',
                          weekNumber,
                          'rotaShiftsAttributes',
                          shiftNumber,
                          'startTime',
                        ]}
                        noStyle
                      />
                      <FormItem
                        name={[
                          'rotaWeeksAttributes',
                          weekNumber,
                          'rotaShiftsAttributes',
                          shiftNumber,
                          'endTime',
                        ]}
                        noStyle
                      />

                      <FormItem
                        name={[
                          'rotaWeeksAttributes',
                          weekNumber,
                          'rotaShiftsAttributes',
                          shiftNumber,
                          'weekDay',
                        ]}
                        noStyle
                      />
                      <FormItem
                        name={[
                          'rotaWeeksAttributes',
                          weekNumber,
                          'rotaShiftsAttributes',
                          shiftNumber,
                          '_destroy',
                        ]}
                        noStyle
                        initialValue={false}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col flex={1}>
                <Card className='event-tag-pattern'>
                  <span>
                    {form.getFieldValue([
                      'rotaWeeksAttributes',
                      weekNumber,
                      'numberTargetRotas',
                    ])}
                  </span>
                </Card>
              </Col>
              <Col flex={1}>
                <Card className='event-tag-pattern'>
                  <span>0</span>
                </Card>
              </Col>
            </Row>
          ))}
          <Row justify='end' align='middle'>
            <Col>
              <Space size={16}>
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
              </Space>
            </Col>
          </Row>
          <Row justify='end' align='middle'>
            <Col>
              <Space size={16}>
                <h3>Total Contacted Hours</h3>
                <Input disabled value={0} style={style} />
                {allocateButton === '' ? (
                  ''
                ) : (
                  <Button
                    className='yellow-button'
                    onClick={handleClickAllocateBtn}
                  >
                    {allocateButton}
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
          <Row justify='end' align='middle'>
            <Col>
              <Space size={16}>
                <Button className='black-button'>{cancelButton}</Button>
                <Button htmlType='submit' className='yellow-button'>
                  {createButton}
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </Form>
      <AllocateToRotaModal
        rotaId={id}
        visible={showAllocationPopup}
        refetchGetRota={refetchGetRota}
        onClickNo={() => setShowAllocationPopup(false)}
      />
    </GeneralTemplate>
  );
};
EditRotaTable.propTypes = {
  headerText: PropTypes.string,
  createButton: PropTypes.string,
  cancelButton: PropTypes.string,
  allocateButton: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  form: PropTypes.any,
  refetchGetRota: PropTypes.func,
};

EditRotaTable.defaultProps = {
  headerText: '',
  createButton: '',
  cancelButton: '',
  allocateButton: '',
  refetchGetRota: () => {},
};

export default EditRotaTable;
