import moment from 'moment';

import request from '@/request';

import { getAddressInfomation } from '../getAddressInfomation';

export const createApplicant = async (formValue, listFileUpload) => {
  const { preferredRoles, leave, zip } = formValue;

  const geogecode = await getAddressInfomation(`${zip}-uk`);

  const { geometry } = geogecode[0];

  // Need these hard-code below to run
  const data = await request.post('/recruitments/applicants', {
    applicant: {
      ...formValue,
      createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.000Z'),
      isAcceptedReceivingUpdate: 0,
      cvUpload: listFileUpload?.cvUpload,
      preferredRoles: `${preferredRoles}`,
      vacancyApplicantEmploymentsAttributes: [
        {
          leave,
          pos: '',
          name: '',
          town: '',
          country: '',
          isVerifyExperience: 0,
          startAt: moment().format('YYYY-MM-DDTHH:mm:ss.000Z'),
          finishAt: moment().format('YYYY-MM-DDTHH:mm:ss.000Z'),
        },
      ],
      locationAttributes: {
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
      },
    },
  });

  return data;
};
