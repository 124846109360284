import request from '@/request';

const showPayrate = async (params) => {
  const res = await request.get('/providers/employers/show_payrate', {
    params,
  });

  return res.data.data;
};

export default showPayrate;
