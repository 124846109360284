import React from 'react';

import { Form } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import shallow from 'zustand/shallow';

import admin from '@/assets/images/admin.png';
import { Button, Input } from '@/components';
import FormItem from '@/components/FormItem';
import { useAuthStore } from '@/store/authStore';

import './index.scss';
import { useTenantContext } from '../../TenantWrapper';

const LoginPage = () => {
  const { mainLogo } = useTenantContext();
  const { token, error, loading } = useAuthStore(
    (state) => ({
      token: state.token,
      loading: state.loading,
      error: state.error,
    }),
    shallow,
  );

  const login = useAuthStore((state) => state.login);

  const handleLogin = (credentials) => {
    login(credentials);
  };

  if (token) {
    return <Redirect to='/' />;
  }

  return (
    <div className='login-page'>
      <img className='gig-logo' src={mainLogo.url} alt='organization-logo' />
      <img className='admin-logo' src={admin} alt='admin' />
      <div className='form'>
        <Form
          className='input-container'
          name='login'
          layout='vertical'
          onFinish={handleLogin}
          initialValues={{
            email: '',
            password: '',
          }}
        >
          <FormItem
            label='Email'
            name='email'
            className='primary-input-container'
            required
          >
            <Input className='input-item' placeholder='Input your email' />
          </FormItem>
          {error && (
            <p className='error-msg'>Your email or password is incorrect</p>
          )}
          <FormItem
            label='Password'
            name='password'
            className='primary-input-container'
            required
            rules={[{ required: true, message: 'Password is required' }]}
          >
            <Input
              className='input-item'
              placeholder='Input your password'
              isPassword
            />
          </FormItem>
          <Button htmlType='submit' loading={loading} className='black-button'>
            Login
          </Button>
          <Link className='forgot-password' to=''>
            Forgot Password
          </Link>
        </Form>
        <div style={{ width: '1px', height: '46px' }} />
      </div>
    </div>
  );
};

export default LoginPage;
