import { useQuery } from 'react-query';

import { fetchLocations } from '@/services/dropdownServices/getLocations';

const useLocations = () => {
  const { data: resStatus } = useQuery(['locations'], fetchLocations, {
    refetchOnWindowFocus: false,
  });

  return { ...resStatus };
};

export default useLocations;
