import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

import { convertFileToBase64 } from '../components/RemoteUploader/utils';

export const getTenant = async () => {
  const res = await request.get('/organization');

  const { data } = normalizeShowResponse(res);
  const { mainLogo } = data;

  const image = await fetch(mainLogo.url, { mode: 'no-cors' });
  const blob = await image.blob();
  const base64 = await convertFileToBase64(blob);

  mainLogo.base64 = base64;

  return data;
};
