/* eslint-disable max-len */
import React from 'react';

import { Skeleton as AntdSkeleton } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * @component
 *
 * Skeleton component.
 *
 * @param {object} props - Component props.
 * @param {number} [props.lines=1] - Number of lines to display.
 * @param {boolean} [props.hiddenTitle=false] - Hide title, default as false
 * @param {import('antd').SkeletonProps} [props.props] - Other props from Ant Design Skeleton component.
 *
 * @returns {JSX.Element} - Rendered component.
 */
const Skeleton = ({ lines = 1, hiddenTitle = false, ...props }) => (
  <div
    className={classNames('skeleton-container', {
      'hidden-title': hiddenTitle,
    })}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <AntdSkeleton active paragraph={{ rows: lines }} {...props} />
  </div>
);

export default Skeleton;

Skeleton.propTypes = {
  lines: PropTypes.number,
  hiddenTitle: PropTypes.bool,
};

Skeleton.defaultProps = {
  lines: 1,
  hiddenTitle: false,
};
