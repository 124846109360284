import React from 'react';

import { Col, Form, Row, Radio } from 'antd';
import classNames from 'classnames';

import { FormItem, Input, InputNumber } from '@/components';
import { preventInvalidValueInput } from '@/utils';
import Formatter from '@/utils/Formatter';

import { GUTTER_FOR_ANTD } from '../../constant';
import calcRates from './calcRates';
import { useTenantContext } from '../../../../../../../TenantWrapper';

const moneyFormatter = (currency) => {
  return (value = 0) => {
    return `${currency || '£'} ${Formatter.fixedNumber({ value })}`;
  };
};

const formatPercent = (/** @type {string|number} */ value = 0) => `${value}%`;

const RateTable = () => {
  const formInstance = Form.useFormInstance();
  const grossPayRate = Number(Form.useWatch('grossPayRate'));
  const holidayPayRate = Number(Form.useWatch('holidayPayRate'));
  const { currency } = useTenantContext();
  const employersNiPerWeekPercent = Number(
    Form.useWatch('employersNiPerWeekPercent'),
  );
  const employersNiPerWeekThreshold = Number(
    Form.useWatch('employersNiPerWeekThreshold'),
  );
  const employersNiHourWeek = Number(Form.useWatch('employersNiHourWeek'));
  const pensionPercent = Number(Form.useWatch('pensionPercent'));
  const pensionThreshold = Number(Form.useWatch('pensionThreshold'));
  const holidayDays = Number(Form.useWatch('holidayDays'));
  const apprenticeshipLevyPercent = Number(
    Form.useWatch('apprenticeshipLevyPercent'),
  );
  const otherCosts = Number(Form.useWatch('otherCosts'));
  const marginIsCostType = Form.useWatch('marginIsCostType');
  const marginPercent = Number(Form.useWatch('marginPercent'));
  const marginFixed = Number(Form.useWatch('marginFixed'));
  const formatMoney = moneyFormatter(currency);

  calcRates({
    grossPayRate,
    holidayPayRate,
    employersNiPerWeekPercent,
    employersNiPerWeekThreshold,
    employersNiHourWeek,
    pensionPercent,
    pensionThreshold,
    holidayDays,
    apprenticeshipLevyPercent,
    otherCosts,
    marginFixed,
    marginPercent,
    marginIsCostType,
    formInstance,
  });

  const formatInputValue = (
    /** @type {string} */ inputName,
    /** @type {string|number} */ value,
    /** @type {number} */ precision = 2,
  ) =>
    formInstance.setFieldsValue({
      [inputName]: Formatter.fixedNumber({ value, numberOfFixed: precision }),
    });

  const isMarginPercent = marginIsCostType === 'percent';

  return (
    <Col
      span={12}
      className='rate-card__wrapper rate-card--calculator__wrapper'
    >
      <div className='profile-provider__collapse__box'>
        <div className='rate-card--calculator'>
          <div className='rate-card--calculator__label'>Rate Calculator</div>
          <div className='rate-card--calculator__params'>
            <Row
              className='profile-provider__collapse__row'
              gutter={GUTTER_FOR_ANTD.SMALL}
              justify='start'
              align='bottom'
            >
              <Col span={18}>
                <div className='note'>
                  Any rates for a job type not set in the &apos;Rates&apos;
                  section will be posted based on the below calculation
                </div>
              </Col>
              <Col span={1} />
              <Col span={5}>
                <FormItem
                  name='grossPayRate'
                  label='Pay Rate'
                  className='payRate'
                >
                  <InputNumber precision={2} prefix={currency} />
                </FormItem>
              </Col>
            </Row>

            <Row
              className='profile-provider__collapse__row__no_spacing'
              gutter={GUTTER_FOR_ANTD.SMALL}
              justify='start'
              align='bottom'
            >
              <Col span={6}>
                <div className='header-row'>Holiday Pay</div>
              </Col>

              <Col span={5}>
                <FormItem name='holidayDays' className='holidayPay'>
                  <Input
                    className='shadow'
                    type='number'
                    suffix={<div>Days</div>}
                    min={1}
                    step={1}
                    onKeyDown={(e) => preventInvalidValueInput(e, ['.', ','])}
                  />
                </FormItem>
              </Col>
              <Col span={8} />
              <Col span={5}>
                <FormItem name='holidayPayRate' className='holidayPayRate'>
                  <InputNumber
                    className='shadow'
                    readOnly
                    formatter={formatMoney}
                    precision={2}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              className='profile-provider__collapse__row'
              gutter={GUTTER_FOR_ANTD.SMALL}
              justify='start'
              align='bottom'
            >
              <Col span={6}>
                <div className='header-row'>Pension</div>
              </Col>
              <Col span={5}>
                <FormItem
                  name='pensionPercent'
                  className='form-item form-item__text-color--red'
                >
                  <InputNumber
                    className='shadow'
                    formatter={formatPercent}
                    controls={false}
                    precision={2}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <Row gutter={GUTTER_FOR_ANTD.SMALL}>
                  <Col span={12}>
                    <FormItem
                      name='pensionThreshold'
                      className='form-item-label-no-spacing'
                      label='Threshold'
                    >
                      <InputNumber className='shadow' precision={2} />
                    </FormItem>
                  </Col>
                  <Col span={12} />
                </Row>
              </Col>
              <Col span={5}>
                <FormItem name='pensionPayRate' className='pensionPayRate'>
                  <InputNumber
                    className='shadow'
                    precision={2}
                    readOnly
                    formatter={formatMoney}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              className='profile-provider__collapse__row__no_spacing'
              gutter={GUTTER_FOR_ANTD.SMALL}
              justify='start'
              align='bottom'
            >
              <Col span={6}>
                <div className='header-row'>Apprenticeship Levy</div>
              </Col>
              <Col span={5}>
                <FormItem
                  name='apprenticeshipLevyPercent'
                  className='form-item form-item__text-color--red'
                  initialValue={0}
                >
                  <InputNumber
                    className='shadow'
                    formatter={formatPercent}
                    precision={2}
                    controls={false}
                  />
                </FormItem>
              </Col>
              <Col span={8} />
              <Col span={5}>
                <FormItem
                  name='apprenticeshipPayRate'
                  className='apprenticeshipPayRate'
                >
                  <InputNumber
                    className='shadow'
                    precision={2}
                    readOnly
                    formatter={formatMoney}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              className='profile-provider__collapse__row'
              gutter={GUTTER_FOR_ANTD.SMALL}
              justify='center'
              align='bottom'
            >
              <Col span={6}>
                <div className='header-row'>Employers NI</div>
              </Col>
              <Col span={5}>
                <FormItem
                  name='employersNiPerWeekPercent'
                  className='form-item'
                >
                  <InputNumber
                    precision={2}
                    placeholder='Employers NI'
                    formatter={formatPercent}
                    className='shadow'
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <Row gutter={GUTTER_FOR_ANTD.SMALL}>
                  <Col span={12}>
                    <FormItem
                      name='employersNiHourWeek'
                      label='Hours'
                      className='form-item-label-no-spacing'
                    >
                      <InputNumber precision={2} className='shadow' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name='employersNiPerWeekThreshold'
                      label='Threshold'
                      className='form-item-label-no-spacing'
                    >
                      <InputNumber precision={2} className='shadow' />
                    </FormItem>
                  </Col>
                </Row>
              </Col>

              <Col span={5}>
                <FormItem name='employersNIPayRate'>
                  <InputNumber
                    className='shadow'
                    readOnly
                    precision={2}
                    formatter={formatMoney}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row
              className='profile-provider__collapse__row'
              gutter={GUTTER_FOR_ANTD.SMALL}
              justify='end'
            >
              <Col span={5}>
                <FormItem name='totalStatutoryPayRate'>
                  <InputNumber
                    className='shadow'
                    readOnly
                    precision={2}
                    formatter={formatMoney}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
          <div className='profile-provider__collapse__box rate-card--calculator__params'>
            <FormItem name='marginIsCostType'>
              <Radio.Group>
                <Row
                  gutter={GUTTER_FOR_ANTD.SMALL}
                  className='profile-provider__collapse__row'
                  align='middle'
                >
                  <Col span={6}>
                    <div className='header-row'>Margin Fixed £</div>
                  </Col>

                  <Col span={5}>
                    <FormItem
                      name='marginFixed'
                      className={classNames('input-margin', {
                        'input-margin-checked': !isMarginPercent,
                      })}
                    >
                      <Input
                        className='shadow'
                        type='number'
                        suffix={<Radio value='fixed' />}
                        onBlur={(e) =>
                          formatInputValue('marginFixed', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <Row align='middle' gutter={GUTTER_FOR_ANTD.SMALL}>
                      <Col span={1} />
                      <Col span={4}>
                        <div className='header-row'>%</div>
                      </Col>

                      <Col span={15}>
                        <FormItem
                          name='marginPercent'
                          className={classNames('input-margin', {
                            'input-margin-checked': isMarginPercent,
                          })}
                        >
                          <Input
                            className='shadow'
                            type='number'
                            suffix={<Radio value='percent' />}
                            min={1}
                            onBlur={(e) =>
                              formatInputValue('marginPercent', e.target.value)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col span={3} />
                    </Row>
                  </Col>
                  <Col span={5}>
                    <FormItem name='marginPercentPayRate'>
                      <InputNumber
                        className='custom-input-number shadow'
                        readOnly
                        precision={2}
                        formatter={formatMoney}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Radio.Group>
            </FormItem>

            <Row
              gutter={GUTTER_FOR_ANTD.SMALL}
              className='profile-provider__collapse__row'
              align='middle'
            >
              <Col span={6}>
                <div className='header-row'>Other Costs</div>
              </Col>
              <Col span={5}>
                <FormItem name='otherCosts'>
                  <InputNumber precision={2} prefix={currency} />
                </FormItem>
              </Col>
            </Row>
            <Row
              gutter={GUTTER_FOR_ANTD.SMALL}
              className='profile-provider__collapse__row'
              justify='end'
            >
              <Col span={5}>
                <FormItem name='chargeRate' label='Charge Rate'>
                  <InputNumber
                    formatter={formatMoney}
                    className='shadow'
                    disabled
                    precision={2}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default RateTable;
