/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Card, Form, Modal, Radio } from 'antd';
import { isNil, isObject } from 'lodash';
import PropTypes from 'prop-types';

import { Button, FormItem, InputNumber, Select } from '@/components';
import './styles.scss';

import { BREAK_DEDUCTED_OPTIONS, payrollTemplateAttr } from '../../constants';
import { hasValue } from '../../utils';

const options = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const RulesModal = ({ isShow, onApply, onCancel, ...props }) => {
  const payrollTemplateWatching = Form.useWatch(payrollTemplateAttr);

  const disabledApply = !Object.values(payrollTemplateWatching ?? {}).some(
    (rule) => {
      if (isObject(rule)) {
        return hasValue(rule);
      }
      return !isNil(rule);
    },
  );

  const renderFooter = (
    <div>
      <Button
        htmlType='submit'
        key='submit'
        disabled={disabledApply}
        className='apply-btn'
        onClick={onApply}
      >
        Apply Rules
      </Button>
    </div>
  );

  return (
    <Modal
      className='rules-modal-wrapper'
      title='Rate Rules'
      visible={isShow}
      onCancel={onCancel}
      centered
      footer={renderFooter}
      {...props}
    >
      <div className='rules-modal--description'>
        <div>Set up the rules to go alongside the pay templates.</div>
        <div>These rules will automatically apply at timesheets.</div>
      </div>
      <div className='rules-modal--body'>
        <div className='section-layout'>
          <div className='section-body'>
            <div className='form-row'>
              <Card size='default'>
                <span>Breaks</span>
                <div className='card-row'>
                  Break duration
                  <FormItem
                    className='card-row-item'
                    name={[payrollTemplateAttr, 'breakDuration']}
                  >
                    <InputNumber />
                  </FormItem>
                  minutes
                </div>
                <div className='card-row'>
                  Breaks will apply to shifts longer than
                  <FormItem
                    className='card-row-item'
                    name={[payrollTemplateAttr, 'thresholdApply']}
                  >
                    <InputNumber />
                  </FormItem>
                  Hours
                </div>
                <div className='card-row'>
                  Are breaks paid?
                  <FormItem
                    className='card-row-item'
                    name={[payrollTemplateAttr, 'breakPaid']}
                    initialValue={false}
                  >
                    <Radio.Group options={options} />
                  </FormItem>
                </div>
                <div className='card-row'>
                  Breaks are deducted from the
                  <FormItem
                    className='card-row-item card-select'
                    name={[payrollTemplateAttr, 'deductedFrom']}
                  >
                    <Select options={BREAK_DEDUCTED_OPTIONS} required />
                  </FormItem>
                  of the shift
                </div>
              </Card>
              <Card size='default'>
                <span>Overtime</span>
                <div className='card-row'>
                  Overtime will be paid after
                  <FormItem
                    className='card-row-item'
                    name={[payrollTemplateAttr, 'overtimePaidAfter', 'hrs']}
                  >
                    <InputNumber />
                  </FormItem>
                  Hours
                  <FormItem
                    className='card-row-item'
                    name={[payrollTemplateAttr, 'overtimePaidAfter', 'mins']}
                  >
                    <InputNumber min={0} max={59} />
                  </FormItem>
                  Minutes
                </div>
              </Card>
            </div>
            <div className='form-row'>
              <Card size='default'>
                <span>Guaranteed</span>
                <div className='card-row'>
                  Guaranteed minimum shift length
                  <FormItem
                    className='card-row-item'
                    name={[
                      payrollTemplateAttr,
                      'guaranteedMinimumShiftLength',
                      'hrs',
                    ]}
                  >
                    <InputNumber />
                  </FormItem>
                  Hours
                  <FormItem
                    className='card-row-item'
                    name={[
                      payrollTemplateAttr,
                      'guaranteedMinimumShiftLength',
                      'mins',
                    ]}
                  >
                    <InputNumber min={0} max={59} />
                  </FormItem>
                  Minutes
                </div>
                <div className='card-row'>
                  Guaranteed minimum Weekly Hours
                  <FormItem
                    className='card-row-item'
                    name={[
                      payrollTemplateAttr,
                      'guaranteedMinimumWeeklyHours',
                      'hrs',
                    ]}
                  >
                    <InputNumber type='number' />
                  </FormItem>
                  Hours
                  <FormItem
                    className='card-row-item'
                    name={[
                      payrollTemplateAttr,
                      'guaranteedMinimumWeeklyHours',
                      'mins',
                    ]}
                  >
                    <InputNumber min={0} max={59} />
                  </FormItem>
                  Minutes
                </div>
              </Card>
              <Card size='default'>
                <span>Holiday</span>
                <div className='card-row'>
                  Annual holiday allowance
                  <FormItem
                    className='card-row-item'
                    name={[payrollTemplateAttr, 'annualHolidayAllowance']}
                  >
                    <InputNumber />
                  </FormItem>
                  Days
                </div>
                <div className='card-row'>
                  Holiday hours per day
                  <FormItem
                    className='card-row-item'
                    name={[payrollTemplateAttr, 'holidayHoursPerDay']}
                  >
                    <InputNumber />
                  </FormItem>
                  Hours
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RulesModal;

RulesModal.propTypes = {
  isShow: PropTypes.bool,
  onApply: PropTypes.func,
  children: PropTypes.node,
  onCancel: PropTypes.func,
};

RulesModal.defaultProps = {
  isShow: false,
  onApply: () => {},
  onCancel: () => {},
  children: null,
};
