import { STATUES_ACTIONS } from '@/pages/PayTemplate/Payroll/constants';

const useDefineButtons = ({
  isEdit,
  setIsEdit,
  setActionKey,
  setVisibleModal,
  actionMutations,
  isHourlyTemplate,
  isSeletedCheckbox,
  setVisibleSickNoShowModal,
}) => ({
  finalised: {
    label: 'Finalise',
    className: 'yellow-button',
    disabled: !isSeletedCheckbox && isHourlyTemplate,
    loading: actionMutations[STATUES_ACTIONS.finalise.key].loading,
    onClick: () => {
      const updateKey = isHourlyTemplate
        ? STATUES_ACTIONS.finalise.key
        : STATUES_ACTIONS.finaliseAtPanel.key;
      setActionKey(updateKey);
      setVisibleModal(true);
    },
  },
  sick: {
    label: 'Sick',
    className: 'green-button',
    disabled: !isSeletedCheckbox,
    hidden: !isEdit || !isHourlyTemplate,
    loading: actionMutations[STATUES_ACTIONS.sick.key].loading,
    onClick: () => {
      setActionKey(STATUES_ACTIONS.sick.key);
      setVisibleSickNoShowModal(true);
    },
  },
  noShow: {
    label: 'No show',
    className: 'blue-button',
    disabled: !isSeletedCheckbox,
    hidden: !isEdit || !isHourlyTemplate,
    loading: actionMutations[STATUES_ACTIONS.noShow.key].loading,
    onClick: () => {
      setActionKey(STATUES_ACTIONS.noShow.key);
      setVisibleSickNoShowModal(true);
    },
  },
  cancel: {
    label: 'Cancel',
    className: 'shared-button',
    hidden: !isEdit || !isHourlyTemplate,
    onClick: () => setIsEdit(false),
  },
  save: {
    label: 'Save',
    className: 'black-button',
    disabled: !isSeletedCheckbox,
    hidden: !isEdit || !isHourlyTemplate,
    loading: actionMutations[STATUES_ACTIONS.save.key].loading,
    onClick: () => {
      setActionKey(STATUES_ACTIONS.save.key);
      setVisibleModal(true);
    },
  },
  edit: {
    label: 'Edit',
    className: 'black-button',
    hidden: isEdit || !isHourlyTemplate,
    onClick: () => setIsEdit(true),
  },
});

export default useDefineButtons;
