import { ORDER_BY_PROVIDER, ORDER_DIRECTION_ASC } from '@/constants';
import { statuses } from '@/pages/Timesheet/constants';
import request from '@/request';
import { array } from '@/utils';
import { ServiceError } from '@/utils/errorUtil';
import { FormatIndexStrategy, Normalize } from '@/utils/normalizeResponse';

const getTimesheetList = async ({
  sortOrder,
  pageNumber,
  pageSize,
  role,
  year,
  status,
  weekNum,
  employerId,
  ...filter
}) => {
  try {
    const page = {
      size: pageSize,
      number: pageNumber,
    };

    const order = {
      orderBy: ORDER_BY_PROVIDER,
      orderDirection: sortOrder || ORDER_DIRECTION_ASC,
    };

    filter.role = array.remove(role, 'all');
    filter.year = array.remove(year, 'all');
    filter.status = array.remove(status, 'all');
    filter.weekNum = array.remove(weekNum, 'all');
    filter.employerId = array.remove(employerId, 'all');
    let updatedStatus = filter.status ? [...filter.status] : [];
    const paidStatus = statuses.find((item) => item.label === 'Paid')?.value;
    const confirmedStatus = statuses.find(
      (item) => item.label === 'Confirmed',
    )?.value;
    const finalizedStatus = 3; // It get from databases
    if (updatedStatus?.includes(paidStatus)) {
      updatedStatus = updatedStatus.filter(
        (/** @type {number | undefined} */ item) => item !== paidStatus,
      );
      filter.paid = true;
    }
    if (updatedStatus?.includes(confirmedStatus)) {
      updatedStatus = [...updatedStatus, finalizedStatus];
    }

    const endpoint = '/trackers/timekeeping/timesheets';
    const res = await request.get(endpoint, {
      params: {
        page,
        filter: { ...filter, status: updatedStatus },
        order,
      },
    });
    const normalize = new Normalize();
    const strategy = new FormatIndexStrategy(res);
    normalize.setStrategy(strategy);
    const data = normalize.formatResponse();

    return data;
  } catch (error) {
    throw new ServiceError(error);
  }
};
export default getTimesheetList;
