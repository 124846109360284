/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Form, message, Row, Col, Spin } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button, FormItem, Select, ModalConfirm } from '@/components';
import { useProviders, useRoleItems } from '@/hooks/dropdowns';
import useUniforms from '@/hooks/dropdowns/useUniforms';
import { ProviderService } from '@/services';
import { convertLocationsToOption } from '@/utils';
import useModal from '@/utils/useModal';

import ListTemplates from './ListTemplates';
import TemplateDetail from './TemplateDetail';
import './styles.scss';

const convertUniforms = (
  /** @type {{ listJeans: Array; listShirts: Array; listShoes: Array; }} */ listUniforms,
  /** @type {Object} */ data,
) => {
  const { listJeans, listShirts, listShoes } = listUniforms;

  const shirtValues = listShirts.map((item) => item.value);
  const jeanValues = listJeans.map((item) => item.value);
  const shoeValues = listShoes.map((item) => item.value);
  /**
   * @type {Object}
   */
  const jobTemplatesUniformsAttributes = uniqBy(
    data.jobTemplatesUniforms,
    'uniformId',
  );
  const uniformValues = jobTemplatesUniformsAttributes.map((item) =>
    parseInt(item.uniformId, 10),
  );

  const shirt = uniformValues.find((uniformId) =>
    shirtValues.includes(uniformId),
  );

  const jean = uniformValues.find((uniformId) =>
    jeanValues.includes(uniformId),
  );

  const shoe = uniformValues.find((uniformId) =>
    shoeValues.includes(uniformId),
  );

  const additionalUniform = uniformValues.filter(
    (item) => !(item === shirt || item === jean || item === shoe),
  );
  return {
    ...data,
    shirt,
    shoe,
    jean,
    additionalUniform,
    preRequisitJobTemplatesAttributes: data.preRequisitJobTemplates,
  };
};

const JobTemplate = () => {
  const { providerId } = useParams();
  const formInstance = Form.useFormInstance();

  const listProviders = useProviders();
  const { data: listSkill } = useRoleItems();
  const { data: listUniforms } = useUniforms();

  const [isDisplayModal, showModal, hiddenModal] = useModal();

  const location = Form.useWatch('location', formInstance);
  const position = Form.useWatch('position', formInstance);
  const selectedTemplate = Form.useWatch('selectedTemplate', formInstance);

  const listLocations = convertLocationsToOption(
    Form.useWatch('locations', formInstance) ?? [],
  );

  const jobTemplatesAttributes =
    Form.useWatch('jobTemplatesAttributes', formInstance) ?? [];

  const jobTemplates = jobTemplatesAttributes.filter(
    (jobTemplate) => !jobTemplate._destroy,
  );

  const { isLoading, mutate: mutateSaveTemplate } = useMutation(
    (template) => {
      if (template?.id) {
        return ProviderService.updateJobTemplates({
          providerId,
          updatedTemplate: template,
        });
      }
      return ProviderService.createJobTemplate({
        providerId,
        updatedTemplate: template,
      });
    },
    {
      onError: (error) => {
        message.error(error?.message ?? 'Update job template has been failed');
      },
      onSuccess: (data) => {
        message.success('Job Template has been saved successfully');
        const indexSaved = jobTemplatesAttributes.findIndex(
          (item) =>
            item.positionId === data.position &&
            item.locationId === data.locationId,
        );

        jobTemplatesAttributes.splice(
          indexSaved,
          1,
          convertUniforms(listUniforms, data),
        );
        formInstance.setFields([
          {
            name: 'selectedIndex',
            value: null,
          },
          {
            name: 'selectedTemplate',
            value: null,
          },
          { name: 'jobTemplatesAttributes', value: jobTemplatesAttributes },
        ]);
      },
    },
  );

  const disableSaveTemplate = (index) => {
    const currenJobTemplate = jobTemplatesAttributes[index];
    return (
      currenJobTemplate &&
      (currenJobTemplate?.jobTemplatesUniformsAttributes?.length < 3 ||
        currenJobTemplate?.description?.length < 100)
    );
  };

  const deleteTemplate = async () => {
    const index = jobTemplatesAttributes.findIndex((item) =>
      selectedTemplate.id
        ? selectedTemplate.id === item.id
        : item.positionId === selectedTemplate.positionId &&
          item.locationId === selectedTemplate.locationId,
    );
    formInstance.setFields([
      {
        name: ['jobTemplatesAttributes', index],
        value: {
          _destroy: true,
          id: selectedTemplate.id,
          locationId: selectedTemplate.locationId,
        },
      },
      {
        name: ['jobTemplatesTable', index],
        value: {
          _destroy: true,
          id: selectedTemplate.id,
          locationId: selectedTemplate.locationId,
        },
      },
      {
        name: 'selectedTemplate',
        value: undefined,
      },
    ]);
    hiddenModal();
  };

  // eslint-disable-next-line consistent-return
  const isDuplicated = (positionValue, locationValue) => {
    if (!isEmpty(jobTemplatesAttributes)) {
      return jobTemplatesAttributes.some(
        (item) =>
          item.positionId?.toString() === positionValue.toString() &&
          item.locationId?.toString() === locationValue.toString(),
      );
    }
    return false;
  };

  const createTemplate = () => {
    if (isDuplicated(position, location)) {
      return message.warn('This location and position existed');
    }

    const updatedJobTemplates = jobTemplatesAttributes.map((item) => {
      item.isEdit = false;
      return item;
    });

    return formInstance.setFieldsValue({
      jobTemplatesAttributes: [
        ...updatedJobTemplates,
        {
          positionId: position,
          locationId: location,
          allowWithoutLicences: false,
          licenses: [],
          description: '',
          isOver18: false,
          jobTemplatesUniformsAttributes: [],
          preRequisitJobTemplatesAttributes: [],
          isEdit: true,
        },
      ],
      position: undefined,
      location: undefined,
      selectedTemplate: {
        positionId: position,
        locationId: location,
      },
      selectedIndex: updatedJobTemplates.length,
    });
  };

  const uniforms = [
    {
      label: 'Uniform',
      name: 'shirt',
      placeholder: 'Choose Shirt / T-Shirt',
      options: listUniforms?.listShirts,
    },
    {
      name: 'jean',
      placeholder: 'Choose Trousers / Jeans',
      options: listUniforms?.listJeans,
    },
    {
      name: 'shoe',
      placeholder: 'Choose Shoes',
      options: listUniforms?.listShoes,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <FormItem name='selectedTemplate' hidden />
      <FormItem name='selectedIndex' hidden />
      <div className='job-template-container'>
        <div className='left-section'>
          <h3 className='sub-title'>Job Template</h3>
          <div className='left-body'>
            <FormItem className='job-table' name='jobTemplatesTable'>
              <Row>
                <Col span={9}>Position</Col>
                <Col span={9}>Location</Col>
              </Row>
              <ListTemplates
                jobTemplatesAttributes={jobTemplatesAttributes}
                jobTemplates={jobTemplates}
                listSkill={listSkill}
                listLocations={listLocations}
                selectedTemplate={selectedTemplate}
                showModal={showModal}
                isDuplicated={isDuplicated}
              />
            </FormItem>
            <Row gutter={8} className='row-fullWidth'>
              <Col span={9}>
                <FormItem className='position-item' name='position'>
                  <Select options={listSkill} placeholder='Position' />
                </FormItem>
              </Col>

              <Col span={9}>
                <FormItem className='location-item' name='location'>
                  <Select
                    options={listLocations}
                    placeholder='Location'
                    name='location'
                    value={location}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row className='row__reserves'>
              <Button
                disabled={!position || !location}
                onClick={() => {
                  createTemplate();
                }}
                className='yellow-button create-btn'
              >
                Create Template
              </Button>
            </Row>
          </div>
        </div>

        <TemplateDetail
          jobTemplatesAttributes={jobTemplatesAttributes}
          jobTemplates={jobTemplates}
          listProviders={listProviders}
          listSkill={listSkill}
          listUniforms={listUniforms}
          uniforms={uniforms}
          selectedTemplate={selectedTemplate}
          disableSaveTemplate={disableSaveTemplate}
          mutateSaveTemplate={mutateSaveTemplate}
        />
        <ModalConfirm
          visible={isDisplayModal}
          title='Delete Job Template'
          titleSuccess='Delete Job Template successfully'
          onClickNo={hiddenModal}
          onClickYes={() => deleteTemplate()}
        >
          Are you sure you want to delete the Job Template.
          <br />
          <br />
          You cannot post Rota Shifts for a job type that does not have job
          template
        </ModalConfirm>
      </div>
    </Spin>
  );
};
export default JobTemplate;
