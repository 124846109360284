import React, { createContext, useContext } from 'react';
import { Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';

import { useFetch } from '@/hooks';
import { getTenant } from '@/services/tenantService';

const TenantContext = createContext({});

export function TenantProvider({ children }) {
  const { data, isLoading, isError } = useFetch(
    ['getTenant'],
    () => getTenant(),
    {
      onSuccess: (tenant) => {
        document.documentElement.style.setProperty(
          '--brand-colors-primary',
          tenant.colors.primary,
        );
        document.documentElement.style.setProperty(
          '--brand-colors-secondary',
          tenant.colors.secondary,
        );
        document.documentElement.style.setProperty(
          '--brand-colors-tertiary',
          tenant.colors.primary,
        );
        const link = document.querySelector('link[rel="icon"]');
        const apple = document.querySelector('link[rel="apple-touch-icon"]');
        link.setAttribute('href', tenant.favicon.url);
        apple.setAttribute('href', tenant.favicon.url);
      },
    },
  );

  if (isLoading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>This organization is not existing at the moment.</h1>
      </div>
    );
  }

  let style = {};

  if (!isEmpty(data?.colors)) {
    style = {
      '--brand-colors-primary': data.colors.primary,
      '--brand-colors-secondary': data.colors.secondary,
      '--brand-colors-tertiary': data.colors.primary,
    };
  }

  return (
    <TenantContext.Provider value={{ tenant: data }}>
      <Helmet>
        <title>{data.name}</title>
      </Helmet>
      <div style={style}>{children}</div>
    </TenantContext.Provider>
  );
}

export function useTenantContext() {
  return useContext(TenantContext).tenant;
}
