import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import classNames from 'classnames';
import { camelCase, size } from 'lodash';
import PropTypes from 'prop-types';

import { HorizontalDatePicker } from '@/components';
import useDynamicTableHeight from '@/hooks/useDynamicTableHeight';

import StatusNotes from '../StatusNotes';

import './styles.scss';

const GAP_BETWEEN_TAGS = 10;
const LIMIT_ROWS = 3;

const CheckInShifts = ({
  dateRange,
  shiftTimes,
  selectedShift,
  setSelectedShift,
}) => {
  const isHideShiftTime = size(shiftTimes) <= 1;

  const { nodesRef, tableHeight } = useDynamicTableHeight();
  const gapContainer = LIMIT_ROWS * GAP_BETWEEN_TAGS;

  return (
    <div className='checkin-shift-container'>
      <div className='title'>
        <div className='title-text'>Check in shifts</div>
        <Popover trigger='click' content={<StatusNotes />}>
          <InfoCircleOutlined />
        </Popover>
      </div>
      <div className='shift'>
        <HorizontalDatePicker name='datePicker' dateRange={dateRange} />
      </div>
      <div
        hidden={isHideShiftTime}
        className='shift-range'
        style={{
          height: tableHeight + gapContainer,
          gap: GAP_BETWEEN_TAGS,
        }}
      >
        {shiftTimes.map(({ shiftTime, status }) => (
          <Tag
            className={classNames('shift-tag', camelCase(status), {
              selected: selectedShift === shiftTime,
            })}
            ref={(node) => {
              nodesRef.current.push(node);
              return node;
            }}
            onClick={() => setSelectedShift(shiftTime)}
          >
            {shiftTime}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default CheckInShifts;

CheckInShifts.propTypes = {
  dateRange: PropTypes.array,
  shiftTimes: PropTypes.array,
  selectedShift: PropTypes.string,
  setSelectedShift: PropTypes.func,
};

CheckInShifts.defaulProps = {
  dateRange: [],
  shiftTimes: [],
  selectedShift: '',
  setSelectedShift: () => {},
};
