/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { TimePicker as AntdTimePicker } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * TimePicker
 *
 * @param {import('antd').TimePickerProps} CustomTimePickerProps
 * @prop {string} className
 * @returns {JSX.Element}
 */
const TimePicker = ({ className, popupClassName, ...props }) => (
  <AntdTimePicker
    popupClassName={classNames('time-picker-popup')}
    className={classNames('primary-time-picker', className)}
    clearIcon={null}
    use12Hours={false}
    {...props}
  />
);

TimePicker.propTypes = {
  className: PropTypes.string,
  popupClassName: PropTypes.string,
  format: PropTypes.string,
};

TimePicker.defaultProps = {
  className: '',
  popupClassName: '',
  format: 'HH:mm a',
};

export default TimePicker;
