import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const getEmployerLocations = async (
  /** @type {number[]} */ provider,
) => {
  const res = await request.get('/options/employer_locations', {
    params: {
      filter: {
        provider,
      },
    },
  });
  const data = normalizeIndexResponse(res);
  const result = data?.data.map(({ id, name }) => ({ value: id, label: name }));
  return result || [];
};
