import request from '@/request';

export const getAutoCompleteItems = async (params) => {
  const res = await request.get('/recruitments/candidates/predict', {
    params,
  });
  const listItems = res.data.data;

  const convertListItemsToObj = listItems.map((item) => ({
    value: item,
    label: item,
  }));

  return convertListItemsToObj;
};
