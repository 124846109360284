/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Form } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as RequiredIcon } from '@/assets/icons/requiredIcon.svg';
import './styles.scss';

/**
 * @typedef {{colon: boolean;}} AdditionalProps
 */

/**
 * @param {import('antd').FormItemProps & AdditionalProps} props
 */
const FormItem = ({
  children,
  className,
  colon,
  label,
  required,
  ...props
}) => (
  <Form.Item
    {...props}
    className={classNames('form-item-container', className)}
    colon={colon}
    label={
      label && (
        <div className='custom-form-item-label'>
          <p>{label}</p>
          {required && <RequiredIcon />}
        </div>
      )
    }
  >
    {children}
  </Form.Item>
);

FormItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colon: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
};

FormItem.defaultProps = {
  className: '',
  colon: false,
  required: false,
};

export default FormItem;
