import React, { useState } from 'react';

import { Form } from 'antd';

import { Input, Button, CSV } from '@/components';
import { useDownloadCsv } from '@/hooks';
import { downloadEmployeesCsv } from '@/services/payrollService';

export const AllEmployeeCsv = () => {
  const formInstance = Form.useFormInstance();
  const weekNum = Form.useWatch('weekNum', formInstance);
  const [toEmployeeId, setToEmployeeId] = useState('');
  const [fromEmployeeId, setFromEmployeeId] = useState('');

  const {
    refCSVLink,
    data: allEmployeeCsv,
    refetch: refetchAllEmployeeCsv,
    isFetching: isFetchingAllEmployeeDownload,
  } = useDownloadCsv(
    ['download-all-employee-csv', weekNum, toEmployeeId, fromEmployeeId],
    () => downloadEmployeesCsv({ weekNum, toEmployeeId, fromEmployeeId }),
    {
      initialData: '',
    },
  );

  return (
    <div className='show-details'>
      <p className='detail-title'>All Employee CSV</p>
      <div className='id-inputs'>
        <p className='text-title'>Employee ID Range</p>
        <div className='row-container'>
          <Input
            onChange={(e) => setFromEmployeeId(e.target.value)}
            value={fromEmployeeId}
            placeholder='From ID'
            className='input-item input-height'
          />
          <Input
            onChange={(e) => setToEmployeeId(e.target.value)}
            value={toEmployeeId}
            placeholder='To ID'
            className='input-item input-height'
          />
        </div>
      </div>
      <Button
        onClick={refetchAllEmployeeCsv}
        className='yellow-button input-height download-btn'
      >
        {isFetchingAllEmployeeDownload ? '...Loading Csv' : 'Download'}
      </Button>
      <CSV
        ref={refCSVLink}
        filename='all-employees.csv'
        data={allEmployeeCsv}
      />
    </div>
  );
};
export default AllEmployeeCsv;
