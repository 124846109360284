import React from 'react';

import { Col, Row, Avatar, Popover } from 'antd';
import { get, isArray, isNil } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import { ReactComponent as CalendarIcon } from '@/assets/icons/calendarIcon.svg';
import defaultAvatar from '@/assets/images/defaultAvatar.png';
import { DATE_FORMAT } from '@/constants';

import './styles.scss';

const SPECIFIC_FORMAT = 'dddd Do MMMM';

/**
 * @param {string | Date} time
 * @returns {string}
 */
const formatSpecificTime = (time) =>
  moment(time, DATE_FORMAT).format(SPECIFIC_FORMAT);

const Header = ({ jobDetail }) => {
  const jobRole = get(jobDetail, 'jobRole', null);
  const applied = get(jobDetail, 'applied', null);
  const filled = get(jobDetail, 'filled', null);
  const posted = get(jobDetail, 'totalAssignments', null);
  const startDate = get(jobDetail, 'shiftDateAndTime.date.startDate', null);
  const endDate = get(jobDetail, 'shiftDateAndTime.date.endDate', null);
  const requested = get(jobDetail, 'requestedNumber', null);
  const hours = get(jobDetail, 'hours', null);
  const scheduleAttribute = get(jobDetail, 'schedule', []);
  const time = get(jobDetail, 'shiftDateAndTime.time', null);
  const location = get(jobDetail, 'locationName', null);
  const logoThumbnail = get(jobDetail, 'logoThumbnail', defaultAvatar);
  const schedule = isArray(scheduleAttribute)
    ? scheduleAttribute
    : scheduleAttribute?.items;
  const shifts = schedule?.length;

  const isMultipleShifts = shifts > 1;

  const shiftFrom = formatSpecificTime(startDate);
  const shiftTo =
    isMultipleShifts && !endDate ? shiftFrom : formatSpecificTime(endDate);

  const renderPopoverContent = () =>
    schedule.map((item) => (
      <div className='event-schedule-item' key={item}>
        <div className='event-schedule-item-date'>
          {moment.unix(item.startAt).format('DD MMM')}
        </div>
        <div className='event-schedule-item-hour'>
          {moment.unix(item.startAt).format('hh:mm')} -
          {moment.unix(item.endAt).format('hh:mm')}
        </div>
      </div>
    ));

  return (
    <Row gutter={16} className='assign-drawer-container' align='middle'>
      <Col span={2} className='assign-drawer-col'>
        <Avatar
          className='gig-logo'
          size={100}
          src={isNil(logoThumbnail) ? defaultAvatar : logoThumbnail}
          alt='gig-logo'
        />
      </Col>
      <Col span={22} className='assign-drawer-detail'>
        <Row gutter={16}>
          <Col span={6} className='assign-drawer-col'>
            <h3>{jobRole}</h3>
          </Col>
          <Col span={14} className='assign-drawer-col location'>
            {location}
          </Col>
          <Col span={4}>
            <Popover
              trigger='click'
              className='event-popover'
              placement='leftTop'
              content={renderPopoverContent()}
            >
              <CalendarIcon className='calendar-icon' />
            </Popover>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6} className='assign-drawer-col'>
            {shifts > 1 ? (
              <>
                From: {shiftFrom} <br />
                To: {shiftTo}
              </>
            ) : (
              <div>Date: {formatSpecificTime(startDate)}</div>
            )}
          </Col>
          <Col span={6} className='assign-drawer-col'>
            <div>
              {shifts} {pluralize('shift', shifts)}
            </div>
          </Col>
          <Col span={6} className='assign-drawer-col'>
            <div>Applied: {applied}</div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={6} className='assign-drawer-col'>
            <div>
              Time: {time}
              {!isMultipleShifts && endDate && (
                <span className='assign-drawer-more-schedule'>+ 1</span>
              )}
            </div>
          </Col>
          <Col span={6} className='assign-drawer-col'>
            <div>
              {hours} {pluralize('hour', hours)}
            </div>
          </Col>
          <Col span={6} className='assign-drawer-col'>
            <div>
              Filled: {filled}/{posted}
            </div>
          </Col>
          <Col span={6} className='assign-drawer-col'>
            <div>Requested: {requested}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;

Header.propTypes = {
  jobDetail: PropTypes.object,
};

Header.defaultProps = {
  jobDetail: {},
};
