import {
  calcPayRateHoliday,
  calcPayRatePension,
  calcPayRateApprenticeship,
  calcPayRateEmployerNI,
  calcPayRateMarginPercent,
} from '@/utils';
import Formatter from '@/utils/Formatter';

const calcRates = ({
  grossPayRate,
  holidayPayRate,
  employersNiPerWeekPercent,
  employersNiPerWeekThreshold,
  employersNiHourWeek,
  pensionPercent,
  pensionThreshold,
  holidayDays,
  apprenticeshipLevyPercent,
  marginIsCostType,
  marginPercent,
  marginFixed,
  otherCosts,
  formInstance,
}) => {
  const updatedHolidayPayRate = calcPayRateHoliday({
    payRate: grossPayRate,
    days: holidayDays,
  });

  const updatedPensionPayRate = calcPayRatePension({
    employersNiHourWeek,
    payRate: grossPayRate,
    holidayPayRate,
    pensionPercent: pensionPercent / 100,
    pensionThreshold,
  });

  const updatedApprenticeshipPayRate = calcPayRateApprenticeship({
    payRate: grossPayRate,
    apprenticeship: apprenticeshipLevyPercent / 100,
    holidayPayRate,
  });

  const updatedEmployersNIPayRate = calcPayRateEmployerNI({
    payRate: grossPayRate,
    holidayPayRate,
    employersNiPerWeekPercent: employersNiPerWeekPercent / 100,
    employersNiPerWeekThreshold,
    employersNiHourWeek,
  });

  const totalStatutoryPayRate =
    updatedHolidayPayRate +
    updatedPensionPayRate +
    updatedEmployersNIPayRate +
    updatedApprenticeshipPayRate;

  const totalStatutory = totalStatutoryPayRate;

  const marginPercentPayRate = calcPayRateMarginPercent({
    payRate: grossPayRate,
    totalStatutory,
    marginPercent: marginPercent / 100,
  });
  const marginPayRate =
    marginIsCostType === 'percent'
      ? Number(Formatter.fixedNumber({ value: marginPercentPayRate }))
      : marginFixed;

  const chargeRate = grossPayRate + totalStatutory + marginPayRate + otherCosts;

  formInstance.setFields([
    {
      name: 'holidayPayRate',
      value: Formatter.fixedNumber({ value: updatedHolidayPayRate }),
    },
    {
      name: 'pensionPayRate',
      value: Formatter.fixedNumber({ value: updatedPensionPayRate }),
    },
    {
      name: 'apprenticeshipPayRate',
      value: Formatter.fixedNumber({ value: updatedApprenticeshipPayRate }),
    },
    {
      name: 'employersNIPayRate',
      value: Formatter.fixedNumber({ value: updatedEmployersNIPayRate }),
    },
    {
      name: 'totalStatutoryPayRate',
      value: Formatter.fixedNumber({
        value: totalStatutory,
      }),
    },
    {
      name: 'marginPercentPayRate',
      value: Formatter.fixedNumber({
        value: marginPercentPayRate,
      }),
    },
    {
      name: 'chargeRate',
      value: Formatter.fixedNumber({ value: chargeRate }),
    },
  ]);
};
export default calcRates;
