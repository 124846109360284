import React from 'react';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Card, Col, Input, Row, Select, Form } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { FormItem } from '@/components';

import './styles.scss';

const { Option } = Select;

const CustomToolbar = ({ label, onView, onNavigate, view, date }) => {
  const formInstance = Form.useFormInstance();
  const differencePeople = Form.useWatch('differencePeople', formInstance);
  const handleViewChange = (value) => {
    onView(value);
  };
  const valueTimeString = moment(date).format('DD/MM/YYYY');

  return (
    <>
      <div className='top-header'>
        <div className='select-input'>
          <Select
            defaultValue='day'
            onChange={handleViewChange}
            style={{ width: 200 }}
          >
            <Option value='week'>Week</Option>
            <Option value='day'>Day</Option>
          </Select>
        </div>
        <div className='top-content'>
          {view === 'day' ? (
            <>
              <ArrowLeftOutlined
                className={view === 'day' ? 'left-arrow' : 'left-arrow-weekly'}
                onClick={() => onNavigate('PREV')}
              />
              <span className='label'>{moment(date).format('DD/MM/YYYY')}</span>
              <ArrowRightOutlined
                className='right-arrow'
                onClick={() => onNavigate('NEXT')}
              />
            </>
          ) : (
            <>
              <ArrowLeftOutlined
                className={view === 'day' ? 'left-arrow' : 'left-arrow-weekly'}
                onClick={() => onNavigate('PREV')}
              />
              <span className='label'>{label}</span>
              <ArrowRightOutlined
                className='right-arrow'
                onClick={() => onNavigate('NEXT')}
              />
            </>
          )}
        </div>
      </div>

      {view === 'day' && (
        <div className='weekly-view'>
          <Row>
            <Col span={4}>
              <Card className='header-card'>
                <Card className='card'>
                  <h4 className='input-headers'>Day</h4>
                  <h4 className='input-headers'>Date</h4>
                </Card>
                <Card className='card-volume'>Volume</Card>
                <Card className='card'>Contract</Card>
                <Card className='card'>Required</Card>
                <Card className='card'>Agency</Card>
                <Card className='card'>Difference</Card>
              </Card>
            </Col>
            <Col span={20}>
              <Card className='header-card'>
                <Card className='card'>
                  <h4 className='input-headers'>
                    {moment(date).format('dddd')}
                  </h4>
                  <h4 className='input-headers'>
                    {moment(date).format('DD/MM/YYYY')}
                  </h4>
                </Card>
                <FormItem name='volume' className='card'>
                  <Input className='input-field' />
                </FormItem>
                <Row>
                  <Col span={12}>
                    <Card className='card-end'>People</Card>
                    <FormItem
                      className='card-end'
                      name={[valueTimeString, 'numberOfEmployees']}
                    >
                      <Input disabled />
                    </FormItem>
                    <FormItem className='card-end' name='requiredPeople'>
                      <Input disabled />
                    </FormItem>
                    <FormItem name='agencyPeople' className='card-end'>
                      <Input />
                    </FormItem>
                    <FormItem
                      className={classNames('card-end difference', {
                        yellow: differencePeople > 0,
                        red: differencePeople < 0,
                        green: differencePeople === 0,
                      })}
                      name='differencePeople'
                    >
                      <Input disabled />
                    </FormItem>
                  </Col>

                  <Col span={12}>
                    <Card className='card-start'>Hours</Card>
                    <FormItem
                      className='card-start'
                      name='numberOfEmployeesHour'
                    >
                      <Input disabled />
                    </FormItem>
                    <FormItem className='card-start' name='requiredHour'>
                      <Input disabled />
                    </FormItem>
                    <FormItem className='card-start' name='agencyHour'>
                      <Input />
                    </FormItem>
                    <FormItem
                      className={classNames('card-start difference', {
                        yellow: differencePeople > 0,
                        red: differencePeople < 0,
                        green: differencePeople === 0,
                      })}
                      name='differenceHour'
                    >
                      <Input disabled />
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
CustomToolbar.propTypes = {
  label: PropTypes.string,
  onView: PropTypes.func,
  onNavigate: PropTypes.func,
  view: PropTypes.string,
  date: PropTypes.any,
};
CustomToolbar.defaultProps = {
  label: '',
  onView: () => {},
  onNavigate: () => {},
  view: '',
};

export default CustomToolbar;
