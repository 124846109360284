import React from 'react';

import { Checkbox, Popconfirm, Tag, Tooltip, message } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { Button } from '@/components';
import { updateStatus } from '@/services/payrollTemplateService';

import { STATUS_UPDATE_TYPE } from '../constants';
import { useTenantContext } from '../../../TenantWrapper';

const TotalHeader = ({
  timesheetId,
  statusColor = '',
  status = '',
  panelInfo = {},
  isPayroll = false,
  timesheetRefetch = () => {},
}) => {
  const { currency } = useTenantContext();
  const totalPay = get(panelInfo, 'totalPay', 0);
  const totalCharge = get(panelInfo, 'totalCharges', 0);
  const totalHours = get(panelInfo, 'totalValue', 0);

  const listStatusDisabled = [
    STATUS_UPDATE_TYPE.confirmed.status,
    STATUS_UPDATE_TYPE.finalised.status,
    STATUS_UPDATE_TYPE.paid.status,
  ];

  const isDisabledAction = listStatusDisabled.includes(status) && !isPayroll;

  const buttonLabel =
    isPayroll && status === STATUS_UPDATE_TYPE.confirmed.status
      ? 'Finalise'
      : STATUS_UPDATE_TYPE[status].contentOnHeader;

  const { mutate: updateStatusMutation, isLoading: updateStatusLoading } =
    useMutation(
      ({ listTimecardIds, updatedStatus }) =>
        updateStatus({ timesheetId, listTimecardIds, status: updatedStatus }),
      {
        onSuccess: () => {
          message.success('Update success');
          timesheetRefetch();
        },
      },
    );

  const handleActionOnPanel = ({ actionType }) => {
    const timecardIds = panelInfo.timecards.map((timecard) => timecard.id);

    updateStatusMutation({
      listTimecardIds: timecardIds,
      updatedStatus: actionType,
    });
  };

  return (
    <div className='total-cards-container'>
      <Tag className='sm-width'>
        <Tooltip
          placement='topRight'
          title={`Total Hours: ${panelInfo.totalValue}`}
        >
          Total Hours: {totalHours}
        </Tooltip>
      </Tag>
      <Tag className='md-width'>
        <Tooltip
          placement='topRight'
          title={`Total Pay: ${currency}${totalPay}`}
        >
          Total Pay: {currency}
          {totalPay}
        </Tooltip>
      </Tag>
      <Tag className='lg-width'>
        <Tooltip
          placement='topRight'
          title={`Total Pay: ${currency}${totalCharge}`}
        >
          Total Charge: {currency}
          {totalCharge}
        </Tooltip>
      </Tag>
      <div className='btn-container'>
        <Popconfirm
          title='Do you want to update?'
          onConfirm={(e) => {
            handleActionOnPanel({
              actionType: STATUS_UPDATE_TYPE[status].action,
              panelInfo,
            });
            e.stopPropagation();
          }}
          onCancel={(e) => e.stopPropagation()}
        >
          <Button
            className={`${status}-btn`}
            disabled={isDisabledAction}
            loading={updateStatusLoading}
            onClick={(e) => e.stopPropagation()}
          >
            {buttonLabel}
          </Button>
        </Popconfirm>
      </div>

      <div className='timesheets-status'>
        <Checkbox className={`status-${statusColor}`} checked />
      </div>
    </div>
  );
};

export default TotalHeader;

TotalHeader.propTypes = {
  status: PropTypes.string,
  isPayroll: PropTypes.bool,
  panelInfo: PropTypes.object,
  timesheetId: PropTypes.number,
  statusColor: PropTypes.string,
  timesheetRefetch: PropTypes.func,
};

TotalHeader.defaultProps = {
  status: '',
  panelInfo: {},
  statusColor: '',
  isPayroll: false,
  timesheetId: null,
  timesheetRefetch: () => {},
};
