import React from 'react';

import { Form } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Comments, Document, TotalCard } from '@/components';
import { useFetch } from '@/hooks';
import { ProviderDetail } from '@/pages/Timesheet/context/ContextProviderDetail';
import getPayrollDetail from '@/services/payrollTemplateService/payrollDetail';
import Formatter from '@/utils/Formatter';

import EntryCodes from '../components/EntryCodes';
import { listStatues } from '../constants';
import calculation from '../utils/calculation';
import { splitTimesheetToShowData } from '../utils/splitTimesheet';
import ListSeekerPayTemplate from './ListSeekerPayTemplate';

import './styles.scss';

const PayrollDetailPayTemplate = ({
  setWeekNumber,
  setProviderName,
  setLocationName,
  isHourlyTemplate,
}) => {
  const { id } = useParams();

  const { data: dataListTimeSheet } = useFetch(
    ['listTimeSheet', id],
    () => getPayrollDetail(id),
    {
      onSuccess: (res) => {
        const weekNum = get(res, 'weekNum', null);
        const locationName = get(res, 'locationName', '');
        const employerName = get(res, 'employerName', '');
        setProviderName(employerName);
        setWeekNumber(weekNum);
        setLocationName(locationName);
      },
    },
  );

  const totalCost = get(dataListTimeSheet, 'totalCost', 0);
  const totalPay = get(dataListTimeSheet, 'totalPay', 0);
  const totalCharge = get(dataListTimeSheet, 'totalCharge', 0);
  const margin = calculation.calMargin({ totalPay, totalCost, totalCharge });

  const timeSheetShowData = splitTimesheetToShowData({
    dataListTimeSheet,
    listStatues,
  });

  return (
    <ProviderDetail>
      <div className='timesheet-detail'>
        <div className='row'>
          <div className='right'>
            <EntryCodes />
          </div>
        </div>

        <ListSeekerPayTemplate
          listStatues={listStatues}
          timeSheetShowData={timeSheetShowData}
          isHourlyTemplate={isHourlyTemplate}
        />

        <div className='row'>
          <div className='column'>
            <Form>
              <Comments />
            </Form>

            <Form>
              <Document />
            </Form>
          </div>

          <div className='column'>
            <div className='total-card-row'>
              <div className='total-card-column'>
                <TotalCard
                  title='Total Costs'
                  amount={Formatter.currencyGBP(totalCost)}
                />
              </div>
              <div className='total-card-column'>
                <TotalCard
                  title='Total Pay'
                  amount={Formatter.currencyGBP(totalPay)}
                />
              </div>
              <div className='total-card-column'>
                <TotalCard
                  title='Total Charge'
                  amount={Formatter.currencyGBP(totalCharge)}
                  isRed
                />
              </div>
              <div className='total-card-column'>
                <TotalCard
                  title='Margin'
                  amount={Formatter.fixedNumber({ value: margin })}
                  unit='%'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProviderDetail>
  );
};

PayrollDetailPayTemplate.propTypes = {
  setWeekNumber: PropTypes.func,
  setProviderName: PropTypes.func,
  setLocationName: PropTypes.func,
  isHourlyTemplate: PropTypes.bool,
};

PayrollDetailPayTemplate.defaultProps = {
  setWeekNumber: () => {},
  setLocationName: () => {},
  isHourlyTemplate: false,
  setProviderName: () => {},
};

export default PayrollDetailPayTemplate;
