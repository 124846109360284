import React from 'react';

import PropTypes from 'prop-types';

import { INVOICE_TYPES } from '../../constants';
import InvoicesTable from './InvoiceTable';

const ListInvoices = ({ isFetching, handleSort, headerTable, data }) => (
  <div className='table-sections-container'>
    <InvoicesTable
      isFetching={isFetching}
      handleSort={handleSort}
      headerTable={headerTable}
      type={INVOICE_TYPES.flexiblePayRate.key}
      infoDetail={data?.flexiblePayRate ?? []}
    />
    <InvoicesTable
      isFetching={isFetching}
      handleSort={handleSort}
      headerTable={headerTable}
      type={INVOICE_TYPES.payTemplate.key}
      infoDetail={data?.payTemplate ?? []}
    />
  </div>
);

ListInvoices.propTypes = {
  isFetching: PropTypes.bool,
  handleSort: PropTypes.func,
  headerTable: PropTypes.object,
  data: PropTypes.object,
};

export default ListInvoices;
