/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Upload as UploadAntd } from 'antd';
import PropTypes from 'prop-types';

import { ACCEPT_TYPE } from '@/constants';

const Upload = ({ children, accept, ...props }) => {
  const defaultAcceptType = [
    ACCEPT_TYPE.doc,
    ACCEPT_TYPE.pdf,
    ACCEPT_TYPE.allImage,
    ACCEPT_TYPE.docx,
  ];

  return (
    <UploadAntd accept={accept ?? defaultAcceptType.join(', ')} {...props}>
      {children}
    </UploadAntd>
  );
};

Upload.propTypes = {
  children: PropTypes.node,
  accept: PropTypes.string,
};

Upload.defaultProps = {};

export default Upload;
