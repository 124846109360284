import React from 'react';

import { FileOutlined, PrinterFilled } from '@ant-design/icons';
import { Card, Col, Row, Form } from 'antd';
import PropTypes from 'prop-types';

import CSV from '@/components/CSV';
import { useDownloadCsv, useFetch } from '@/hooks';
import { getOneRotaShiftPlan } from '@/services/rota';
import { downloadRotaCsv } from '@/services/rota/editRota';
import { getDuration } from '@/utils/date';
import './styles.scss';

const Attendances = ({ rotaShiftId, event }) => {
  const { data: rotaShiftPlanData } = useFetch(
    ['rota_shift_plans', rotaShiftId],
    () => getOneRotaShiftPlan(rotaShiftId),
  );

  const contracted = Form.useWatch([rotaShiftId, 'numberOfEmployees']) ?? 0;
  const hours = getDuration(event?.start, event?.end);
  const plannedHours = contracted * hours;
  const attendances = rotaShiftPlanData?.employeeAttributes ?? [];

  const {
    refCSVLink,
    data: dataRotaExportCSV,
    refetch: refetchRotaCSV,
  } = useDownloadCsv(['download-job-csv', rotaShiftId], () =>
    downloadRotaCsv(rotaShiftId),
  );

  return (
    <div className='attendance'>
      <div className='main-card'>
        <CSV
          ref={refCSVLink}
          filename='data-rota.csv'
          data={dataRotaExportCSV}
        />
        {attendances.map((attendance) => (
          <Row key={attendance.id}>
            <Col span={6}>
              <Card className='event-tag'>
                <p>{attendance.fullname}</p>
              </Card>
            </Col>
            <Col span={4}>
              <Card className='event-tag'>
                <p>{attendance.contactTypeName}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card className='event-tag'>
                <p>
                  {attendance.startTime} - {attendance.endTime}
                </p>
              </Card>
            </Col>
            <Col span={4}>
              <Card
                className='event-tag'
                style={{
                  backgroundColor:
                    attendance.clockIn === 'No Show' ? '#D49FA1' : '',
                }}
              >
                <p>{attendance.clockIn}</p>
              </Card>
            </Col>
            <Col span={4}>
              <Card
                className='event-tag'
                style={{
                  backgroundColor:
                    attendance.clockIn === 'No Show' ? '#D49FA1' : '',
                }}
              >
                <p>{attendance.clockOut}</p>
              </Card>
            </Col>
          </Row>
        ))}

        <div className='attendance-footer'>
          <div className='left-content'>
            <PrinterFilled className='icons' onClick={refetchRotaCSV} />
            <FileOutlined className='icons' />
          </div>
          <div className='right-content'>
            <div className='right-cards'>
              <div className='individual-card'>
                <h4>Planned Hours</h4>
                <Card className='card'>{plannedHours}</Card>
              </div>
              <div className='individual-card'>
                <h4 className='second-heading'>Actual Hours</h4>
                <Card className='card'>0</Card>
              </div>
              <div className='individual-card'>
                <h4 className='third-heading'>Difference</h4>
                <Card className='card'>{-plannedHours}</Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Attendances.propTypes = {
  rotaShiftId: PropTypes.string,
  event: PropTypes.object,
};

export default Attendances;
