import request from '@/request';

export const fetchCostWeek = async ({ employerId, locationId }) => {
  const res = await request.get('/options/week_timesheets', {
    params: {
      employerId,
      locationId,
    },
  });
  const result = res?.data?.data.map((/** @type {string|number} */ item) => ({
    value: item,
    label: item,
  }));

  return result;
};
