import React, { useState } from 'react';

import { Form } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';

import { SICK_NO_SHOW_TYPE } from '@/pages/Timesheet/Detail/ListJobTypes/constant';
import { PayrollService } from '@/services';

import CommentPopover from '../../components/CommentPopover';
import { SICK_NO_SHOW_LABEL, TYPES_SICK_NO_SHOW } from '../../constants';

const SickNoShowDetail = ({ item, skillId, timecardId, payrollId }) => {
  const queryClient = useQueryClient();
  const formInstance = Form.useFormInstance();
  const [isOpenComment, setIsOpenComment] = useState(false);
  const type =
    get(item, ['no_show', 'no_show_type']) ?? SICK_NO_SHOW_TYPE.NO_SHOW;

  const {
    mutate: updateSickNoShowComment,
    isLoading: isUpdateSickNoShowComment,
  } = useMutation(
    ({ comment }) =>
      PayrollService.updateSickNoShowComment({ payrollId, comment }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['PayrollDetail', payrollId],
        });
        setIsOpenComment(false);
      },
    },
  );

  const handleEdit = () => {
    const commentInfo = formInstance.getFieldValue([
      skillId,
      timecardId,
      'comment',
    ]);

    const updateCommentInfo = {
      id: commentInfo.comment_id,
      content: commentInfo?.content ?? '',
      assignedScheduleId: commentInfo?.assigned_schedule_id,
    };

    updateSickNoShowComment({ comment: updateCommentInfo });
  };

  return (
    <CommentPopover
      trigger='click'
      type={type}
      skillId={skillId}
      timecardId={timecardId}
      title={`Comment - ${SICK_NO_SHOW_LABEL[type]}`}
      handleEdit={handleEdit}
      isLoading={isUpdateSickNoShowComment}
      originalContent={item?.comment?.content ?? ''}
      setIsOpen={setIsOpenComment}
      isOpen={isOpenComment}
    >
      <span className={`tag-cell ${TYPES_SICK_NO_SHOW[type]}`}>
        {SICK_NO_SHOW_LABEL[type]}
      </span>
    </CommentPopover>
  );
};

export default SickNoShowDetail;

SickNoShowDetail.propTypes = {
  item: PropTypes.object,
  skillId: PropTypes.number,
  timecardId: PropTypes.number,
  payrollId: PropTypes.number,
};

SickNoShowDetail.defaultProps = {
  item: {},
  skillId: null,
  timecardId: null,
  payrollId: null,
};
