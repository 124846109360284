import request from '@/request';

const updateCheckinCheckout = async ({ payrollId, timecardId, time }) => {
  const payload = {
    time,
  };
  const { data } = await request.patch(
    `trackers/timekeeping/timesheets/${payrollId}/timecards/${timecardId}`,
    payload,
  );

  return data?.data ?? [];
};

export default updateCheckinCheckout;
