import React, { useState } from 'react';

import { Form, Table } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import TableFooter from '@/components/TableFooter';
import { usePayCharge } from '@/hooks';

import BreakSection from './BreakSection';
import jobTypeAddColumn from './jobTypeAddColumns';

import './styles.scss';

const ListJobTypeAdded = ({
  locationId,
  employerId,
  weekStartDate,
  weekEndDate,
  weekNumber,
  timesheetRefetch,
  payrollStatus,
}) => {
  const formInstance = Form.useFormInstance();
  const { id: timesheetId } = useParams();
  const [editKey, setEditKey] = useState(null);

  const { listPayCharge, refetch: refetchListPayCharge } = usePayCharge({
    id: timesheetId,
    form: formInstance,
    resource: 'timesheet',
  });

  const footer = () => (
    <div>
      <TableFooter
        locationId={locationId}
        employerId={employerId}
        weekStartDate={weekStartDate}
        weekEndDate={weekEndDate}
        weekNumber={weekNumber}
        refetchListPayCharge={refetchListPayCharge}
        timesheetRefetch={timesheetRefetch}
      />
      <BreakSection payrollStatus={payrollStatus} />
    </div>
  );

  return (
    <Table
      className='list-job-type-added'
      bordered
      dataSource={listPayCharge}
      columns={jobTypeAddColumn({ editKey, setEditKey })}
      rowClassName='editable-row'
      footer={footer}
      pagination={false}
    />
  );
};

ListJobTypeAdded.propTypes = {
  locationId: PropTypes.number,
  employerId: PropTypes.number,
  weekStartDate: PropTypes.number,
  weekEndDate: PropTypes.number,
  weekNumber: PropTypes.number,
  payrollStatus: PropTypes.string,
  timesheetRefetch: PropTypes.func,
};

ListJobTypeAdded.defaultProps = {
  locationId: null,
  employerId: null,
  payrollStatus: '',
  timesheetRefetch: () => {},
};

export default ListJobTypeAdded;
