import React from 'react';

import { LicenceInput, ELearningInput } from '@/features/certificate';

import Documentation from './documentation';
import RightToWorkSection from './RightToWorkSection';

import './styles.scss';

const LicencesAndDocs = () => (
  <div className='licences-and-docs-container'>
    <h3>Document Upload</h3>
    <div className='block-items'>
      <RightToWorkSection />
      <Documentation />
      <ELearningInput />
      <LicenceInput />
    </div>
  </div>
);
export default LicencesAndDocs;
