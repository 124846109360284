import React from 'react';

import { Switch } from 'react-router-dom';

import ScrollToTop from '@/components/ScrollToTop';
import Dashboard from '@/pages/Dashboard';
import JobApplicationForm from '@/pages/JobApplicationForm';
import JobListPage from '@/pages/Jobs/JobListPage';
import LoginPage from '@/pages/LoginPage';
import NeedStaff from '@/pages/NeedStaff';
import NewClientOnboardingForm from '@/pages/NewClientOnboardingForm';
import NotFoundPage from '@/pages/NotFoundPage';
import PayrollPage from '@/pages/Payroll';
import PayrollDetail from '@/pages/Payroll/Detail';
import PayTemplates from '@/pages/PayTemplate';
import PayTemplateInvoice from '@/pages/PayTemplate/Invoice';
import PayrollPayTemplateDetail from '@/pages/PayTemplate/Payroll';
import AllocatedWorkers from '@/pages/PayTemplate/Templates/AllocateWorkers';
import ClonePayTemplate from '@/pages/PayTemplate/Templates/Clone';
import CreatePayTemplate from '@/pages/PayTemplate/Templates/Create';
import EditPayTemplate from '@/pages/PayTemplate/Templates/Edit';
import PayTimesheet from '@/pages/PayTemplate/Timesheet';
import CreateLead from '@/pages/Provider/Leads/Create';
import EditLead from '@/pages/Provider/Leads/Edit';
import ViewLeads from '@/pages/Provider/Leads/View';
import CreateProvider from '@/pages/Provider/Providers/Create';
import EditProvider from '@/pages/Provider/Providers/Edit';
import ViewProviders from '@/pages/Provider/Providers/View';
import CreateApplicant from '@/pages/Recruitment/Applicant/CreateApplication';
import EditApplicant from '@/pages/Recruitment/Applicant/EditApplicant';
import ApplicationListPage from '@/pages/Recruitment/ApplicationListPage';
import CreateNewCandidate from '@/pages/Recruitment/Candidate/Create';
import EditCandidate from '@/pages/Recruitment/Candidate/Edit';
import CandidateListPage from '@/pages/Recruitment/CandidateListPage';
import RecruitmentForm from '@/pages/RecruitmentForm';
import RotaListPage from '@/pages/Rota';
import CreateNewRota from '@/pages/Rota/CreateNewRota/CreateNewRota';
import EditRota from '@/pages/Rota/EditRota/EditRota';
import SeekerProfilePage from '@/pages/Seeker/SeekerDetail';
import SeekerListPage from '@/pages/Seeker/SeekerView/SeekerListPage';
import ThankPage from '@/pages/ThankPage';
import ThankPageLeadForm from '@/pages/ThankPageLeadForm';
import ThankPageProviderForm from '@/pages/ThankPageProviderForm';
import CreateCost from '@/pages/Timesheet/Costs/Create';
import EditCost from '@/pages/Timesheet/Costs/Edit';
import Costs from '@/pages/Timesheet/Costs/View';
import Detail from '@/pages/Timesheet/Detail';
import Invoice from '@/pages/Timesheet/Invoice';
import EditInvoice from '@/pages/Timesheet/Invoice/EditInvoice';
import ViewTimesheetPDF from '@/pages/Timesheet/Invoice/ViewTimesheetPDF';
import CreateSupplier from '@/pages/Timesheet/Suppliers/Create';
import EditSupplier from '@/pages/Timesheet/Suppliers/Edit';
import Suppliers from '@/pages/Timesheet/Suppliers/View';
import TimeSheetsPage from '@/pages/Timesheet/View';
import User from '@/pages/User';
import CreateGIGProvider from '@/pages/User/CreateGIGProvider';
import NewUser from '@/pages/User/NewUser';
import EditUser from '@/pages/User/NewUser/EditUser';
import WeeklyReport from '@/pages/WeeklyReport';
import YearlyReport from '@/pages/YearlyReport';
import AuthRoute from '@/routes/AuthRoute';
import GuestRoute from '@/routes/GuestRoute';

const Routes = () => (
  <>
    <ScrollToTop />
    <Switch>
      <AuthRoute exact path='/pay-templates' component={PayTemplates} />
      <AuthRoute
        exact
        path='/create-payroll-templates'
        component={CreatePayTemplate}
      />
      <AuthRoute
        exact
        path='/edit-payroll-templates/:templateId'
        component={EditPayTemplate}
      />
      <AuthRoute
        exact
        path='/clone-payroll-templates/:templateId'
        component={ClonePayTemplate}
      />
      <AuthRoute
        exact
        path='/allocate-workers/:templateId'
        component={AllocatedWorkers}
      />
      {/* <AuthRoute exact path='/' component={Dashboard} /> */}
      <AuthRoute exact path='/report-yearly' component={YearlyReport} />
      <AuthRoute exact path='/report-weekly' component={WeeklyReport} />
      <GuestRoute path='/login' component={LoginPage} />
      <GuestRoute path='/recruitment-form/:id' component={RecruitmentForm} />
      {/* TODO: test -> will update when integrate candidate url */}
      <GuestRoute path='/application-form' component={JobApplicationForm} />
      <GuestRoute path='/thank-you-for-submitting' component={ThankPage} />
      <GuestRoute
        path='/thank-you-for-submitting-lead-form'
        component={ThankPageLeadForm}
      />
      <GuestRoute
        path='/thank-you-for-submitting-provider-form'
        component={ThankPageProviderForm}
      />
      <GuestRoute path='/i-need-staff' component={NeedStaff} />
      <GuestRoute path='/new-client' component={NewClientOnboardingForm} />
      <AuthRoute
        path='/recruitment/application'
        component={ApplicationListPage}
      />
      <AuthRoute exact path='/' component={ApplicationListPage} />
      <AuthRoute path='/recruitment/candidate' component={CandidateListPage} />
      <AuthRoute path='/create-new-applicant' component={CreateApplicant} />
      <AuthRoute path='/seeker' component={SeekerListPage} />
      <AuthRoute path='/seeker-profile/:id' component={SeekerProfilePage} />

      <AuthRoute path='/edit-applicant/:id' component={EditApplicant} />
      <AuthRoute path='/create-candidate' component={CreateNewCandidate} />
      <AuthRoute path='/edit-candidate/:id' component={EditCandidate} />
      <AuthRoute path='/jobs' component={JobListPage} />
      <AuthRoute path='/view-all-leads' component={ViewLeads} />
      <AuthRoute path='/view-all-providers' component={ViewProviders} />
      <AuthRoute path='/create-new-provider' component={CreateProvider} />
      <AuthRoute path='/edit-provider/:providerId' component={EditProvider} />
      <AuthRoute path='/edit-lead/:leadId' component={EditLead} />
      <AuthRoute path='/create-new-lead' component={CreateLead} />
      {/* <AuthRoute exact path='/rota' component={RotaListPage} /> */}
      <AuthRoute path='/create-new-rota' component={CreateNewRota} />
      <AuthRoute path='/edit-rota/:id' component={EditRota} />
      <AuthRoute path='/timesheets' component={TimeSheetsPage} />
      <AuthRoute path='/timesheet-detail/:id' component={Detail} />
      <AuthRoute path='/invoicing' component={Invoice} />
      <AuthRoute path='/pay-template-timesheet/:id' component={PayTimesheet} />
      <AuthRoute
        exact
        path='/payroll-templates-invoice/:id'
        component={PayTemplateInvoice}
      />
      <AuthRoute
        path='/view-timesheet-pdf/:timesheetId'
        component={ViewTimesheetPDF}
        enableHeader={false}
      />
      <AuthRoute path='/payroll' component={PayrollPage} />
      <AuthRoute path='/edit-invoice/:invoiceId' component={EditInvoice} />
      <AuthRoute path='/payroll-detail/:id' component={PayrollDetail} />
      <AuthRoute
        path='/pay-template-payroll-detail/:id'
        component={PayrollPayTemplateDetail}
      />
      <AuthRoute path='/users' component={User} />
      <AuthRoute path='/create-new-user' component={NewUser} />
      <AuthRoute path='/edit-user/:userId' component={EditUser} />
      <AuthRoute
        path='/create-new-gig-provider'
        component={CreateGIGProvider}
      />
      <AuthRoute path='/suppliers' component={Suppliers} />
      <AuthRoute path='/create-supplier' component={CreateSupplier} />
      <AuthRoute path='/edit-supplier/:supplierId' component={EditSupplier} />
      <AuthRoute path='/costs' component={Costs} />
      <AuthRoute path='/create-cost' component={CreateCost} />
      <AuthRoute path='/edit-cost/:costId' component={EditCost} />
      <AuthRoute path='*' component={NotFoundPage} />
    </Switch>
  </>
);

export default Routes;
