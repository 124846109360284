/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Header from '@/components/Header';
import { AuthLayout } from '@/components/Layouts';
import { useAuth } from '@/hooks';

const AuthRoute = ({ component: Component, enableHeader, ...rest }) => {
  const { isAuth } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuth) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <AuthLayout>
            {enableHeader && <Header />}
            <Component {...props} />
          </AuthLayout>
        );
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  enableHeader: PropTypes.bool,
};

AuthRoute.defaultProps = {
  enableHeader: true,
};

export default AuthRoute;
