import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

/**
 * @typedef {'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'} Day
 */
/**
 * @typedef {Object} DateCwday
 * @property {Day} end_cwday
 * @property {Day} start_cwday
 */

/**
 * @typedef {Object} Attributes
 * @property {number} id
 * @property {'active'|'blocked'|'inactivate'} status
 * @property {string} name
 * @property {DateCwday} dateCwday
 */

/**
 * @typedef {Object} Data
 * @property {string} id
 * @property {'employer'} type
 * @property {Attributes} attributes
 */

/**
 * @typedef {Object} Response
 * @property {Array<Data>} data
 */
export const fetchProviders = async (active = false) => {
  /** @type {import('axios').AxiosResponse<Response>} */
  const res = await request.get('/options/employers');

  /** @type {{data: Array<Attributes>}} */
  const data = normalizeIndexResponse(res);
  const result = data?.data
    .filter((e) => (active ? e.status === 'active' : true))
    .map(({ id, name, dateCwday: { startCwday, endCwday } }) => ({
      value: id,
      label: name,
      startCwday,
      endCwday,
    }));

  return result || [];
};
