import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const fetchIndustries = async () => {
  const res = await request.get('/options/industries');
  const { data } = normalizeIndexResponse(res);
  const result = data.map(({ id, name }) => ({
    value: id,
    label: name.charAt(0).toUpperCase() + name.slice(1),
  }));

  return { listIndustries: result };
};
