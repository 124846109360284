import React from 'react';

import { Popover, Menu, Form } from 'antd';
import PropTypes from 'prop-types';

import CopyIcon from '@/assets/icons/copyIcon.png';
import DeleteIcon from '@/assets/icons/deleteIcon.png';
import EditIcon from '@/assets/icons/editIcon.png';
import ManageIcon from '@/assets/icons/manageIcon.png';
import { ReactComponent as MoreIcon } from '@/assets/icons/moreIcon.svg';
import RepostIcon from '@/assets/icons/repostIcon.png';
import WhiteStar from '@/assets/icons/whiteStar.png';

/**
 * @param {{
 * event: Object,
 * setJobIdEdit: Function,
 * setRepostId: Function,
 * setShowPopupCreate: Function,
 * deleteShift: () => void,
 * promoteSelectedJob: () => void,
 * openManageWorkerModal: Function,
 * isCompletedTab : boolean
 * }} props
 */
const Actions = ({
  event,
  setJobIdEdit,
  setRepostId,
  setShowPopupCreate,
  deleteShift,
  promoteSelectedJob,
  openManageWorkerModal,
  isCompletedTab,
}) => {
  const formInstance = Form.useFormInstance();
  return (
    <Popover
      className='event-popover'
      trigger='click'
      content={
        <div className='event-menu-container'>
          <Menu>
            <Menu.Item
              key='manageWorkers'
              icon={
                <div>
                  <img
                    src={ManageIcon}
                    width={24}
                    height={24}
                    alt='manageWorkers'
                  />
                </div>
              }
              onClick={() => openManageWorkerModal(formInstance, event.id)}
            >
              Manage Workers
            </Menu.Item>
            <Menu.Item
              key='editShift'
              disabled={isCompletedTab}
              icon={
                <div>
                  <img src={EditIcon} width={24} height={24} alt='edit' />
                </div>
              }
              onClick={() => setJobIdEdit(event.id)}
            >
              Edit Shift
            </Menu.Item>
            <Menu.Item
              key='repostShift'
              icon={
                <div>
                  <img src={RepostIcon} width={24} height={24} alt='repost' />
                </div>
              }
              onClick={() => {
                setRepostId(event.id);
                setShowPopupCreate(true);
              }}
            >
              Repost Shift
            </Menu.Item>
            <Menu.Item
              key='delete'
              disabled={isCompletedTab}
              onClick={deleteShift}
              icon={
                <div>
                  <img src={DeleteIcon} width={20} height={20} alt='delete' />
                </div>
              }
            >
              Delete
            </Menu.Item>
            <Menu.Item
              key='copyLink'
              disabled={isCompletedTab}
              icon={
                <div>
                  <img src={CopyIcon} width={20} height={20} alt='copy' />
                </div>
              }
            >
              Copy link
            </Menu.Item>
            <Menu.Item
              key='promote'
              disabled={isCompletedTab}
              onClick={promoteSelectedJob}
              icon={
                <div>
                  <img src={WhiteStar} width={20} height={20} alt='promote' />
                </div>
              }
            >
              Promote
            </Menu.Item>
          </Menu>
        </div>
      }
    >
      <div>
        <MoreIcon height={20} width={20} />
      </div>
    </Popover>
  );
};
Actions.propTypes = {
  event: PropTypes.object,
  setJobIdEdit: PropTypes.func,
  setRepostId: PropTypes.func,
  setShowPopupCreate: PropTypes.func,
  deleteShift: PropTypes.func,
  promoteSelectedJob: PropTypes.func,
  openManageWorkerModal: PropTypes.func,
  isCompletedTab: PropTypes.bool,
};

export default Actions;
