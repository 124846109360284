import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, CheckboxGroup } from '@/components';

const Footer = ({
  setIsCreateInvoice,
  isCreateInvoice,
  isPreview,
  setIsPreview,
  setIsShowInvoice,
  blobPdf,
}) => {
  const formInstance = Form.useFormInstance();

  return (
    <div className='invoice-footer'>
      <div className='footer-item-left'>
        <Button
          onClick={async () => {
            try {
              await formInstance.validateFields();
              setIsShowInvoice(true);
              setIsPreview(true);
            } catch (error) {
              const errorName = error.errorFields[0].name;
              formInstance.scrollToField(errorName, {
                skipOverflowHiddenElements: true,
                block: 'center',
                behavior: 'smooth',
              });
            }
          }}
          className='yellow-button preview-invoice'
        >
          Preview Invoice
        </Button>
      </div>
      <div className='footer-item-right'>
        <FormItem>
          <CheckboxGroup
            onChange={() => setIsCreateInvoice((prevState) => !prevState)}
            options={[
              {
                label: 'Confirm changes and create Invoice',
                value: '1',
              },
            ]}
            className='confirm-check'
            disabled={!blobPdf}
          />
        </FormItem>
        <Tooltip
          title='Please preview and confirm invoice'
          placement='topRight'
        >
          <InfoCircleOutlined className='info-icon' />
        </Tooltip>
        <Button
          className='yellow-button create-invoice-btn'
          disabled={!isCreateInvoice}
          htmlType='submit'
        >
          Create Invoice
        </Button>
      </div>
    </div>
  );
};

Footer.propTypes = {
  setIsCreateInvoice: PropTypes.func,
  isCreateInvoice: PropTypes.bool,
  isPreview: PropTypes.bool,
  setIsPreview: PropTypes.func,
  setIsShowInvoice: PropTypes.func,
  blobPdf: PropTypes.object,
};

Footer.defaultProps = {
  setIsCreateInvoice: () => {},
  isCreateInvoice: false,
  isPreview: false,
  setIsPreview: () => {},
  setIsShowInvoice: () => {},
};
export default Footer;
