import React, { useState } from 'react';

import { Form, message } from 'antd';
import { get } from 'lodash';
import QueryString from 'qs';
import { useQueryClient } from 'react-query';
import { useToggle } from 'usehooks-ts';

import { Tabs, GeneralTemplate } from '@/components';
import { useQueryString, useSessionStorage } from '@/hooks';

import JobListView from '../components/JobListView';
import { useFetchJobsOptions } from '../components/JobListView/hooks/useFetchJobsOptions';
import JobModalCreate from '../JobModal/Create';
import JobModalEdit from '../JobModal/Edit';
import { breadCrumb, FORM_NAME } from './constants';

import './styles.scss';

const JobListPage = () => {
  const [jobsFilter, setJobsFilter] = useSessionStorage('searchJobs');
  const switchMode = get(jobsFilter, 'jobMode', false);
  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [jobIdEdit, setJobIdEdit] = useState();
  const [repostId, setRepostId] = useState();

  const [rowId, setRowId] = useState();

  const [isCalendarMode, toggleCalendarMode] = useToggle(switchMode);

  const onChangeTabKey = (key) => {
    setQuery({ tab: key });
  };

  const [query, setQuery] = useQueryString();

  const { roles, providers, locations, industries, allFetched } =
    useFetchJobsOptions();

  const tabPanes = [
    {
      key: 'posted/live',
      tab: 'Posted/Live',
      child: (
        <JobListView
          query={query}
          isCalendarMode={isCalendarMode}
          onClickEditJob={(id) => setJobIdEdit(id)}
          setShowPopupCreate={setShowPopupCreate}
          setRepostId={setRepostId}
          setJobIdEdit={setJobIdEdit}
          toggleCalendarMode={toggleCalendarMode}
          rowId={rowId}
          setRowId={setRowId}
          roles={roles}
          providers={providers}
          locations={locations}
          industries={industries}
          allFetched={allFetched}
        />
      ),
      forceRender: true,
    },
    {
      key: 'completed',
      tab: 'Completed',
      child: (
        <JobListView
          query={query}
          isCalendarMode={isCalendarMode}
          setRepostId={setRepostId}
          setShowPopupCreate={setShowPopupCreate}
          toggleCalendarMode={toggleCalendarMode}
          rowId={rowId}
          setRowId={setRowId}
          isCompletedTab
          roles={roles}
          providers={providers}
          locations={locations}
          industries={industries}
          allFetched={allFetched}
        />
      ),
      forceRender: true,
    },
  ];

  const queryClient = useQueryClient();

  const onFinish = (name, { forms }) => {
    if (name === FORM_NAME.POSTING_FORM) {
      const { listJobsForm, calendarForm, jobPostingForm } = forms;
      const provider = jobPostingForm?.getFieldValue('employerId');
      listJobsForm && listJobsForm.setFieldsValue({ provider: [provider] });
      calendarForm && calendarForm.setFieldsValue({ provider });
      setJobsFilter({ ...jobsFilter, provider });
    }
  };

  return (
    <GeneralTemplate
      mainItem='Jobs'
      data={breadCrumb}
      buttonContent='Create Job'
      headerText='Jobs'
      buttonLink={`?${QueryString.stringify(query ?? { tab: 'posted/live' })}`}
      buttonCallback={() => setShowPopupCreate(true)}
    >
      <Form.Provider onFormFinish={onFinish}>
        <Tabs
          className='job-tabs'
          defaultActiveKey='posted/live'
          tabPanes={tabPanes}
          activeKey={query?.tab ?? 'posted/live'}
          onChange={onChangeTabKey}
        />
        {showPopupCreate && (
          <JobModalCreate
            onPostSuccess={() => {
              onChangeTabKey('posted/live');
              queryClient.invalidateQueries({ queryKey: ['get-jobs'] });
              queryClient.invalidateQueries({ queryKey: ['calendarJobs'] });
            }}
            hidePopup={() => {
              setShowPopupCreate(false);
              setRepostId();
            }}
            onPostFailed={(errorMsg) => {
              message.error(errorMsg);
            }}
            showPopup={showPopupCreate}
            repostId={repostId}
          />
        )}

        {!!jobIdEdit && (
          <JobModalEdit
            onPostSuccess={() => {
              onChangeTabKey('posted/live');
              queryClient.invalidateQueries({ queryKey: ['get-jobs'] });
              queryClient.invalidateQueries({ queryKey: ['calendarJobs'] });
            }}
            hidePopup={setJobIdEdit}
            showPopup={!!jobIdEdit}
            jobId={jobIdEdit}
          />
        )}
      </Form.Provider>
    </GeneralTemplate>
  );
};

export default JobListPage;
