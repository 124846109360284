import _ from 'lodash';

import request from '@/request';

export const getYearlyReport = async (params) => {
  const endpoint = '/dashboard/reports/invoices/yearly';
  const resData = await request.get(endpoint, { params });
  const data = resData?.data?.data;

  const dataLength = data?.length ?? 0;

  const sumYearly = (key) => {
    const sum = _.sumBy(data, (item) => item[key]);
    return dataLength > 0 && sum;
  };

  const calDataItems =
    dataLength > 0
      ? data.map((item) => {
          const totalInvoiced = item?.total_invoiced ?? 0;
          const vat = item?.vat ?? 0;
          const workerPay = item?.worker_pay ?? 0;
          const holidayPay = item?.holiday_pay ?? 0;
          const totalCost = item?.total_cost ?? 0;
          const niCost = item?.ni_cost ?? 0;
          const profitLoss =
            totalInvoiced - vat - workerPay - holidayPay - totalCost - niCost;

          const payAndCost = workerPay + holidayPay + totalCost;
          const difference = totalInvoiced - payAndCost;
          const margin = (difference / totalInvoiced) * 100;
          item.profit_loss = profitLoss;
          item.margin = margin;
          return item;
        })
      : [];

  const sumNetTotal = sumYearly('net_total');
  const sumVat = sumYearly('vat');
  const sumTotalInvoiced = sumYearly('total_invoiced');
  const sumWorkerPay = sumYearly('worker_pay');
  const sumHolidayPay = sumYearly('holiday_pay');
  const sumTotalCost = sumYearly('total_cost');
  const sumNiCost = sumYearly('ni_cost');
  const sumProfitLoss = sumYearly('profit_loss') ?? 0;
  const sumMargin = sumYearly('margin') ?? 0;
  const everageItems = {
    net_total: sumNetTotal,
    vat: sumVat,
    total_invoiced: sumTotalInvoiced,
    worker_pay: sumWorkerPay,
    holiday_pay: sumHolidayPay,
    total_cost: sumTotalCost,
    ni_cost: sumNiCost,
    profit_loss: sumProfitLoss,
    margin: sumMargin,
  };

  dataLength > 0 && calDataItems.push(everageItems);
  return calDataItems;
};
