const STATUS_CODES = {
  unapproved: {
    content: 'Not Approved - No action taken',
    color: 'green',
  },
  approved: {
    content: 'Approved - Timesheets approved in WebApp',
    color: 'green',
  },
};

const listStatues = [
  { status: 'confirmed', color: 'yellow' },
  { status: 'finalised', color: 'green' },
  { status: 'paid', color: 'gray' },
];

const STATUES_ACTIONS = {
  finalise: {
    key: 'finalise',
    updateStatus: 'finalised',
    label: 'Finalise',
  },
  finaliseAtPanel: {
    key: 'finaliseAtPanel',
    updateStatus: 'finalised',
    label: 'Finalise',
  },
  sick: {
    key: 'sick',
    label: 'Sick',
  },
  noShow: {
    key: 'noShow',
    label: 'No Show',
    subKey: 'hide',
  },
  save: {
    key: 'save',
    label: 'Update',
  },
};

const EXPAND_LABEL = {
  expand: 'Expand All',
  collapse: 'Collapse All',
};

export { STATUS_CODES, STATUES_ACTIONS, listStatues, EXPAND_LABEL };
