import React from 'react';

import RotaType from './RotaType';
import RollingPattern from './TypeDetail';

import './styles.scss';

const SetRotaAndWorkInformation = () => (
  <div className='set-rota-and-work-information'>
    <div className='left'>
      <RotaType />
    </div>

    <div className='right'>
      <RollingPattern />
    </div>
  </div>
);

export default SetRotaAndWorkInformation;
