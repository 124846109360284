import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Breadcrumb, Button } from '@/components';

import './styles.scss';

const GeneralTemplate = ({
  className,
  data,
  headerText,
  hasButton,
  buttonClassName,
  children,
  subTemplateClassName,
  buttonContent,
  buttonLink,
  mainItem,
  buttonCallback,
  buttonCustom,
  buttonLoading,
}) => (
  <div className={classNames('template-container', className)}>
    <Breadcrumb
      className='recruitment-breadcrumb'
      mainItem={mainItem}
      data={data}
    />
    <div className='sub-template-container'>
      <div className='sub-template-header'>
        <p className='header-text'>{headerText}</p>
        {hasButton &&
          (!buttonCustom ? (
            <Link className='create-button' to={buttonLink}>
              <Button
                className={classNames('yellow-button', {
                  buttonClassName,
                })}
                onClick={buttonCallback}
                loading={buttonLoading}
              >
                <p className='plus-icon'>+</p>
                <p className='button-content'>{buttonContent}</p>
              </Button>
            </Link>
          ) : (
            buttonCustom
          ))}
      </div>
      <div className={classNames('sub-template-body', subTemplateClassName)}>
        {children}
      </div>
    </div>
  </div>
);

GeneralTemplate.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  subTemplateClassName: PropTypes.string,
  buttonContent: PropTypes.string,
  buttonCallback: PropTypes.func,
  data: PropTypes.array,
  headerText: PropTypes.string,
  buttonLink: PropTypes.string,
  mainItem: PropTypes.string,
  hasButton: PropTypes.bool,
  children: PropTypes.node,
  buttonCustom: PropTypes.node,
  buttonLoading: PropTypes.bool,
};

GeneralTemplate.defaultProps = {
  className: '',
  buttonClassName: '',
  subTemplateClassName: '',
  buttonContent: '',
  buttonCallback: () => {},
  data: [],
  headerText: '',
  buttonLink: '',
  mainItem: '',
  hasButton: true,
  buttonLoading: false,
};

export default GeneralTemplate;
