/* eslint-disable react-hooks/exhaustive-deps */
/* eslint react/no-unused-prop-types: 0 */
import React, { useEffect, useMemo, useState } from 'react';

import { Form, message, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import {
  flatMap,
  get,
  groupBy,
  head,
  isEmpty,
  isNil,
  isObject,
  keyBy,
  map,
  mapValues,
  merge,
  reduce,
  round,
  sum,
  sumBy,
  toPairs,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import {
  Button,
  ColorCheckbox,
  FormItem,
  Input,
  ModalConfirm,
  Select,
} from '@/components';
import ListView from '@/components/ListView';
import useDynamicTableHeight from '@/hooks/useDynamicTableHeight';
import { BORDER_HEIGHT } from '@/pages/Timesheet/Detail/constants';
import { PayrollService } from '@/services';
import { undoSickNoShow } from '@/services/timesheetService';
import { time } from '@/utils';
import { calculateHoursInShift } from '@/utils/date';
import { calculateTotalPayAndTotalCharge } from '@/utils/payrollHelper';

import SickNoShowModal from '../../components/SickNoShowModal';
import {
  ACTIONS,
  disableStatus,
  MODAL_TYPES,
  TOTAL_TYPES,
} from '../../constants';
import SickNoShowDetail from './SickNoShowDetail';
import TableButton from './TablePayrollFooter';
import { usePayrollEntryCodes } from './hooks';
import { useTenantContext } from '../../../../TenantWrapper';

import './styles.scss';

const formatTime = (/** @type {moment.MomentInput} */ timeValue) =>
  moment(timeValue).format('HH:mm:ss');

const calculateWorkInDay = ({ dayworks, type = TOTAL_TYPES.days }) => {
  if (!dayworks) return 0;

  const hours = dayworks?.map((item) => {
    if (type === TOTAL_TYPES.sickOrNoShow && !isNil(item?.no_show)) {
      return 0;
    }

    return calculateHoursInShift({
      checkinTime: item.checkin_time,
      checkoutTime: item.checkout_time,
      breakTime: item.break,
      bonusType: item.bonus_type,
      bonusValue: item.bonus_value,
    });
  });
  const totalHours = parseFloat(sum(hours));

  return totalHours;
};

const calculateHoursInWeek = (dayworks) =>
  round(
    sum(
      Object.keys(dayworks).map((item) =>
        calculateWorkInDay({
          dayworks: dayworks[item],
          type: TOTAL_TYPES.sickOrNoShow,
        }),
      ),
    ),
    2,
  );

const ListJobTypesPayroll = ({
  labelTotal,
  tableColor,
  infoDetail,
  statusColor,
  colorButtonApprove,
  textButtonApprove,
  dateRange,
  loading,
  status,
  handleFormFinished,
  payrollId,
}) => {
  const queryClient = useQueryClient();
  const [{ skill_id: jobTypeId }] = infoDetail;

  const form = Form.useFormInstance();
  const jobTypeWatching = Form.useWatch(jobTypeId, form) ?? {};

  const [isEdit, setIsEdit] = useState(false);
  const [isLargeHeight, setIsLargeHeight] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [modalType, setModalType] = useState(MODAL_TYPES.sick);
  const [isShowSickNoShow, setIsShowSickNoShow] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const [actionKey, setActionKey] = useState(null);
  const { nodesRef, tableHeight } = useDynamicTableHeight();
  const { currency } = useTenantContext();
  const payrollEntryCodes = usePayrollEntryCodes();

  const isSelectedAllTimecards = !Object.values(jobTypeWatching).some(
    (timecard) => {
      if (!isNil(timecard?.no_show) && timecard.isChecked) {
        return false;
      }

      return (
        dateRange.includes(timecard.date) &&
        status === timecard.status &&
        !timecard.isChecked
      );
    },
  );

  const isAllSickNoShow = !Object.values(jobTypeWatching).some((timecard) => {
    if (dateRange.includes(timecard.date) && status === timecard.status) {
      return isNil(timecard?.no_show?.no_show_type) || timecard.isChecked;
    }
    return false;
  });

  const { mutate: sickNoShowMutation, isLoading: isUpdateSickNoShow } =
    useMutation(
      ({ timecardIds, noShowAttributes }) =>
        PayrollService.updateSickNoShow({
          payrollId,
          timecardIds,
          noShowAttributes,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['PayrollDetail', payrollId],
          });
          setIsShowSickNoShow(false);
          setIsEdit(false);
          form.resetFields();
        },
      },
    );

  const { mutate: onSaveMutation, isLoading: updateTimecardLoading } =
    useMutation(
      (timecardAttributes) =>
        PayrollService.updateTimeCardAttributes({
          payrollId,
          timecardAttributes,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['PayrollDetail', payrollId],
          });
          form.resetFields();
        },
      },
    );

  const { mutate: undoSickNoShowMutation, isLoading: isUndoing } = useMutation(
    (timecardIds) => undoSickNoShow({ timecardIds, timesheetId: payrollId }),
    {
      onSuccess: () => {
        message.success('Undo successfully');
        queryClient.invalidateQueries({
          queryKey: ['PayrollDetail', payrollId],
        });
        setShowConfirm(false);
        form.resetFields();
      },
      onError: (error) => message.error(error.message),
    },
  );

  const showPaid = () => (
    <div type='text' className='button-paid'>
      Paid
    </div>
  );

  const checkAllTimecards = (listTimecards) =>
    !listTimecards.some((timecard) => {
      if (isObject(timecard)) {
        return !timecard.isChecked;
      }
      return false;
    });

  const handleCheckTimecard = (timecardId) => {
    const formData = form.getFieldsValue();
    const jobTypeData = formData[jobTypeId];
    const isCheckedAll = checkAllTimecards(Object.values(jobTypeData));
    jobTypeData.isCheckedAll = isCheckedAll;

    form.setFieldsValue(formData);
  };

  const selectAllTimecards = (jobTypeData, value) => {
    Object.keys(jobTypeData).forEach((timecardId) => {
      if (isObject(jobTypeData[timecardId])) {
        const { date, status: timecardStatus } = jobTypeData[timecardId];
        if (dateRange.includes(date) && status === timecardStatus) {
          jobTypeData[timecardId].isChecked = value;
        }
      }
    });
  };

  const handleSelectAll = ({ target: { checked } }) => {
    const formData = form.getFieldsValue();
    const jobTypeData = formData[jobTypeId];

    selectAllTimecards(jobTypeData, checked);

    form.setFieldsValue(formData);
  };

  const selectAllTimecardsInRow = ({ jobTypeData, key, value }) => {
    Object.keys(jobTypeData).forEach((timecardId) => {
      if (isObject(jobTypeData[timecardId])) {
        if (jobTypeData[timecardId].key === key) {
          jobTypeData[timecardId].isChecked = value;
        }
      }
    });
  };

  const handleSelectRow = (value, record) => {
    const { key } = record;
    const formData = form.getFieldsValue();
    const jobTypeData = formData[jobTypeId];

    selectAllTimecardsInRow({ jobTypeData, key, value });
    const isCheckedAll = checkAllTimecards(Object.values(jobTypeData));
    jobTypeData.isCheckedAll = isCheckedAll;

    form.setFieldsValue(formData);
  };

  const checkSelectTimecardsRow = (key) =>
    !Object.values(jobTypeWatching ?? {}).some((timecard) => {
      const isTimecardSelected =
        timecard?.key === key &&
        status === timecard?.status &&
        dateRange.includes(timecard?.date);

      if (!isNil(timecard?.no_show?.no_show_type)) {
        return false;
      }

      if (isObject(timecard) && isTimecardSelected) {
        return !timecard.isChecked;
      }
      return false;
    });

  const checkFullSickNoShow = (key) =>
    !Object.values(jobTypeWatching ?? {}).some((timecard) => {
      const isTimecardSelected =
        timecard?.key === key &&
        status === timecard?.status &&
        dateRange.includes(timecard?.date);

      if (isObject(timecard) && isTimecardSelected) {
        return isNil(timecard?.no_show?.no_show_type) || timecard.isChecked;
      }
      return false;
    });

  const validateBonusType = ({ bonusType, skillId, timecardId }) => {
    const bonusValue = form.getFieldValue([skillId, timecardId, 'bonus_value']);

    const isNoBonus = bonusType === 'no_bonus' || isNil(bonusType);

    if (isNoBonus && Number(bonusValue) > 0) {
      return Promise.reject(new Error('Please select Bonus Type'));
    }

    return Promise.resolve();
  };

  const validateBonusValue = ({ bonusValue, skillId, timecardId }) => {
    const selectValue = form.getFieldValue([skillId, timecardId, 'bonus_type']);
    const isNoBonus = selectValue === 'no_bonus' || isNil(selectValue);

    if (isNoBonus && Number(bonusValue) > 0) {
      form.setFields([
        {
          name: [skillId, timecardId, 'bonus_type'],
          errors: ['Please select Bonus Type'],
        },
      ]);
      return Promise.reject();
    }

    form.setFields([
      {
        name: [skillId, timecardId, 'bonus_type'],
        errors: [],
      },
    ]);
    return Promise.resolve();
  };

  const renderHoursCell = (record, isEditing, color, day) => {
    const { key, timesheet_detail_id: timesheetDetailId } = record;
    const skillId = record?.skill_id;
    const dayworks = record?.dayworks[day] ?? [];
    const type = isEdit ? 'text' : 'hidden';
    const typeNumber = isEdit ? 'number' : 'hidden';
    const totalHours = time.convertTimeHoursCell(
      round(calculateWorkInDay({ dayworks }), 2),
    );

    return (
      <div className={classNames('time-input-container', { bottom: isEdit })}>
        {!isEdit && <div className='time-style'>{totalHours}</div>}
        {dayworks?.map((item) => {
          const { no_show: noShow } = item;
          const id = `${item?.id}`;

          const isDisabled = disableStatus.includes(status);

          const isHiddenSickNoShow = !isNil(noShow);
          const isDisabledSickNoShow = isDisabled || isHiddenSickNoShow;
          const isShowField = type !== 'hidden';

          return (
            <>
              <FormItem
                name={[skillId, id, 'timesheet_detail_id']}
                initialValue={timesheetDetailId}
                noStyle
              />
              <FormItem
                name={[skillId, id, 'key']}
                initialValue={key}
                noStyle
              />
              <FormItem name={[skillId, id, 'id']} noStyle />
              <FormItem name={[skillId, id, 'no_show']} noStyle />
              <FormItem name={[skillId, id, 'date']} noStyle />
              <FormItem name={[skillId, id, 'value']} noStyle />
              <FormItem name={[skillId, id, 'adjusted_payrate']} noStyle />
              <FormItem name={[skillId, id, 'checkin_time']} noStyle disabled />
              {isShowField && (
                <span className='input-time'>
                  {formatTime(
                    form.getFieldValue([skillId, id, 'checkin_time']),
                  )}
                </span>
              )}
              <FormItem
                name={[skillId, id, 'checkout_time']}
                noStyle
                disabled
              />
              <FormItem
                name={[skillId, id, 'status']}
                initialValue={status}
                noStyle
              />
              {isShowField && (
                <span className='input-time'>
                  {formatTime(
                    form.getFieldValue([skillId, id, 'checkout_time']),
                  )}
                </span>
              )}
              <FormItem name={[skillId, id, 'break']} noStyle disabled>
                <Input className='input-time' type={type} disabled />
              </FormItem>
              <FormItem
                name={[skillId, id, 'bonus_type']}
                hidden={!isEdit}
                rules={[
                  {
                    validator: (_, bonusType) =>
                      validateBonusType({
                        bonusType,
                        skillId,
                        timecardId: id,
                      }),
                  },
                ]}
              >
                <Select
                  disabled={isDisabledSickNoShow}
                  options={payrollEntryCodes}
                />
              </FormItem>
              <FormItem
                name={[skillId, id, 'bonus_value']}
                noStyle
                rules={[
                  {
                    validator: (_, bonusValue) =>
                      validateBonusValue({
                        bonusValue,
                        skillId,
                        timecardId: id,
                      }),
                  },
                ]}
              >
                <Input
                  disabled={isDisabledSickNoShow}
                  className='input-time'
                  type={typeNumber}
                />
              </FormItem>
              <FormItem
                name={[skillId, id, 'isChecked']}
                hidden={!(isEdit && dayworks.length > 0)}
                valuePropName='checked'
              >
                <ColorCheckbox
                  className={classNames(`cell-select ${color} color-select`)}
                  disabled={isDisabled}
                  onChange={() => handleCheckTimecard(id)}
                />
              </FormItem>
              {noShow && (
                <SickNoShowDetail
                  item={item}
                  skillId={skillId}
                  timecardId={id}
                  payrollId={payrollId}
                />
              )}
            </>
          );
        })}
      </div>
    );
  };

  const weekTitles = dateRange.map((date) => ({
    date: moment.utc(date).format('DD/MM/YYYY'),
    dayName: moment.utc(date).format('ddd'),
  }));

  const payrollSortedBySeekerName = useMemo(
    () =>
      Object.values(infoDetail).sort((a, b) =>
        (a?.employee_name ?? '')
          .toString()
          .toLowerCase()
          .localeCompare((b.employee_name ?? '').toLowerCase()),
      ),
    [infoDetail],
  );

  const [{ push_from_timesheet_id: pushFromTimesheetId }] =
    payrollSortedBySeekerName;

  const columns = [
    {
      title: 'Job type',
      dataIndex: 'job_type',
      width: '10%',
      render: (name) => (
        <Tooltip placement='topLeft' title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Employee',
      dataIndex: 'employee_name',
      width: '12%',
      render: (name, record) => (
        <div
          className='employee-container'
          ref={(node) => {
            nodesRef.current.push(node);
            return node;
          }}
        >
          <Tooltip placement='topLeft' title={name}>
            <Link to={`/seeker-profile/${record.employee_id}`}>
              <span className='seeker-name'>{name}</span>
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Payroll',
      dataIndex: 'employee_id',
      width: '6.4%',
    },
    {
      title: (
        <div>
          <div>{weekTitles[0].dayName}</div>
          <div>{weekTitles[0].date}</div>
        </div>
      ),
      dataIndex: weekTitles[0].dayName,
      width: '6.4%',
      render: (_, record) =>
        renderHoursCell(record, isEdit, statusColor, dateRange[0]),
    },
    {
      title: (
        <div>
          <div>{weekTitles[1].dayName}</div>
          <div>{weekTitles[1].date}</div>
        </div>
      ),
      dataIndex: weekTitles[1].dayName,
      width: '6.4%',
      render: (_, record) =>
        renderHoursCell(record, isEdit, statusColor, dateRange[1]),
    },
    {
      title: (
        <div>
          <div>{weekTitles[2].dayName}</div>
          <div>{weekTitles[2].date}</div>
        </div>
      ),
      dataIndex: weekTitles[2].dayName,
      width: '6.4%',
      render: (_, record) =>
        renderHoursCell(record, isEdit, statusColor, dateRange[2]),
    },
    {
      title: (
        <div>
          <div>{weekTitles[3].dayName}</div>
          <div>{weekTitles[3].date}</div>
        </div>
      ),
      dataIndex: weekTitles[3].dayName,
      width: '6.4%',
      render: (_, record) =>
        renderHoursCell(record, isEdit, statusColor, dateRange[3]),
    },
    {
      title: (
        <div>
          <div>{weekTitles[4].dayName}</div>
          <div>{weekTitles[4].date}</div>
        </div>
      ),
      dataIndex: weekTitles[4].dayName,
      width: '6.4%',
      render: (_, record) =>
        renderHoursCell(record, isEdit, statusColor, dateRange[4]),
    },
    {
      title: (
        <div>
          <div>{weekTitles[5].dayName}</div>
          <div>{weekTitles[5].date}</div>
        </div>
      ),
      dataIndex: weekTitles[5].dayName,
      width: '6.4%',
      render: (_, record) =>
        renderHoursCell(record, isEdit, statusColor, dateRange[5]),
    },
    {
      title: (
        <div>
          <div>{weekTitles[6].dayName}</div>
          <div>{weekTitles[6].date}</div>
        </div>
      ),
      dataIndex: weekTitles[6].dayName,
      width: '6.4%',
      render: (_, record) =>
        renderHoursCell(record, isEdit, statusColor, dateRange[6]),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '6.4%',
      render: (_, record) =>
        time.convertTimeHoursCell(calculateHoursInWeek(record?.dayworks)),
    },
    {
      title: 'Pay & Charge',
      dataIndex: 'payAndCharge',
      width: '6.4%',
      render: (_, record) => (
        <div>
          <div className='pay-and-charge'>
            {currency}
            {round(record.adjusted_payrate, 2)}
          </div>
          <div className='pay-and-charge'>
            {currency}
            {round(record.charge_rate, 2)}{' '}
          </div>
        </div>
      ),
    },
    {
      title: 'Total Pay',
      dataIndex: 'total_pay',
      width: '6.4%',
      render: (totalPay, record) => {
        const roundedTotalPay = Number(totalPay ?? 0).toFixed(2);
        return (
          <Tooltip placement='leftTop' title={roundedTotalPay}>
            {currency}
            {roundedTotalPay}
          </Tooltip>
        );
      },
    },
    {
      title: 'Total Charge',
      dataIndex: 'total_charge',
      width: '7%',
      render: (/** @type {string} */ totalCharge) => (
        <Tooltip placement='topLeft' title={totalCharge}>
          {currency}
          {totalCharge}
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '6%',
      render: (_text, _record) => (
        <ColorCheckbox className={`cell-select ${statusColor}`} />
      ),
    },
    {
      title: 'Select',
      dataIndex: 'select',
      width: '6%',
      render: (_text, record) => {
        const { payroll_status: payrollStatus } = record;

        if (payrollStatus === 'paid') {
          return showPaid();
        }

        if (record.exported) {
          return (
            <div type='text' className='button-paid'>
              Paid
            </div>
          );
        }

        if (!record.exported && record.payrollStatus === 'finalised') {
          return null;
        }

        const checkedRow = checkSelectTimecardsRow(record.key);
        const isFullSickNoShow = checkFullSickNoShow(record.key);

        return (
          <ColorCheckbox
            onChange={(/** @type {{ target: { checked: boolean; }; }} */ e) => {
              const value = e.target.checked;
              return handleSelectRow(value, record);
            }}
            checked={checkedRow && !isFullSickNoShow}
            className={`cell-select ${statusColor} color-select`}
            disabled={disableStatus.includes(status)}
          />
        );
      },
    },
  ];

  const { totalCharge, totalPay } = calculateTotalPayAndTotalCharge(
    infoDetail,
    form,
  );

  const renderShowMoreButton = () => (
    <Button
      onClick={() => setIsShowAll((prev) => !prev)}
      className={classNames('button-edit', {
        'black-button': isShowAll,
        'shared-button': !isShowAll,
      })}
    >
      {isShowAll ? 'View Less' : 'View More'}
    </Button>
  );

  const renderSeeButton = () => (
    <div>
      <Button
        className='black-button button-edit fixed-btn'
        onClick={() => {
          setIsEdit(true);
          setIsLargeHeight(true);
        }}
      >
        See details
      </Button>
    </div>
  );

  const handleClickFinalise = () => {
    if (handleFormFinished) {
      const skillId = infoDetail?.[0]?.skill_id;

      const jobsSkill = form.getFieldValue([skillId]) ?? {};

      const jobsSelected = Object.values(jobsSkill).filter((e) => e.isChecked);

      if (!isEmpty(jobsSelected)) {
        setActionKey(ACTIONS.finalised.key);
        setShowConfirm(true);
      } else message.error('Please select 1 row');
    }
  };

  const handleConfirmFinalise = async () => {
    await handleFormFinished(form, infoDetail?.[0].skill_id);
    setShowConfirm(false);
    setIsEdit(false);
    form.resetFields();
  };

  const handleSickNoShow = () => {
    const jobsSkill = form.getFieldValue(jobTypeId) ?? {};

    const filterTimecardIds = Object.values(jobsSkill).filter(
      (timecard) =>
        timecard.isChecked &&
        timecard.status === status &&
        isNil(timecard?.no_show),
    );

    const timecardIds = filterTimecardIds.map((timecard) => timecard.id);
    const noShowAttributes = {
      noShowType: modalType === 'sick' ? 'sick' : 'hide',
      comment: form.getFieldValue('comment'),
    };

    sickNoShowMutation({
      timecardIds,
      noShowAttributes,
    });
  };

  const handleUndo = () => {
    const skillId = get(head(infoDetail), 'skill_id');

    const jobsSkill = form.getFieldValue([skillId]);

    const timecardAttributes = Object.values(jobsSkill).filter(
      (timecard) => timecard.isChecked && !isNil(timecard.no_show),
    );
    const timecardIds = map(timecardAttributes, 'id');

    undoSickNoShowMutation(timecardIds);
  };

  const groupedTimecards = mapValues(
    groupBy(infoDetail, 'skill_id'),
    (timesheets) => {
      const mappedTimesheet = timesheets.map((timesheet) => {
        const pairedDayworks = toPairs(timesheet.dayworks);
        const flatted = flatMap(pairedDayworks, ([_, timecards]) => timecards);
        return keyBy(flatted, 'id');
      });
      const timesheetsObj = reduce(
        mappedTimesheet,
        (total, timecard) => merge(total, timecard),
        {},
      );

      return timesheetsObj;
    },
  );

  useEffect(() => {
    form.setFieldsValue(groupedTimecards);
  }, [infoDetail]);

  const footer = () => (
    <TableButton
      jobTypeId={jobTypeId}
      hasShowMoreBtn={infoDetail?.length > 3}
      labelTotal={labelTotal}
      infoDetail={infoDetail}
      totalPay={totalPay}
      totalCharge={totalCharge}
      status={status}
      handleClickFinalise={handleClickFinalise}
      colorButtonApprove={colorButtonApprove}
      textButtonApprove={textButtonApprove}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      isLargeHeight={isLargeHeight}
      setIsLargeHeight={setIsLargeHeight}
      renderSeeButton={renderSeeButton}
      renderShowMoreButton={renderShowMoreButton}
      setIsShowSickNoShow={setIsShowSickNoShow}
      setType={setModalType}
      dateRange={dateRange}
      onSaveMutation={onSaveMutation}
      updateTimecardLoading={updateTimecardLoading}
      handleUndo={handleUndo}
      setActionKey={setActionKey}
      setShowConfirm={setShowConfirm}
    />
  );

  const calTotalSummary = (data, dateRangeTimecards) =>
    dateRangeTimecards.reduce((result, date) => {
      const totalValue = data.reduce((total, period) => {
        const { dayworks } = period;

        const dayworkArray = dayworks[date] || [];

        const dateTotal = sumBy(dayworkArray, (daywork) => {
          if (!isNil(daywork?.no_show)) {
            return 0;
          }

          return calculateHoursInShift({
            checkinTime: daywork.checkin_time,
            checkoutTime: daywork.checkout_time,
            breakTime: daywork.break,
            bonusType: daywork.bonus_type,
            bonusValue: daywork.bonus_value,
          });
        });
        return total + dateTotal;
      }, 0);

      result[date] = totalValue || 0;
      return result;
    }, {});

  const renderSummary = (columnsData) => {
    const dateRangeTimecards = columnsData[0]?.date_range_time_card || [];

    const daysSummary = calTotalSummary(columnsData, dateRangeTimecards) ?? 0;
    const totalHoursSummary =
      sumBy(Object.values(daysSummary ?? {}), (value) => Number(value)) ?? 0;

    const totalPaySummary =
      sumBy(columnsData, (timesheetDetail) =>
        Number(timesheetDetail?.total_pay ?? 0),
      ) ?? 0;
    const totalChargeSummary = sumBy(columnsData, (timesheetDetail) =>
      Number(timesheetDetail?.total_charge ?? 0),
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={pushFromTimesheetId ? 3 : 2}>
            <div className='view-more-container'>
              {infoDetail?.length > 3 && renderShowMoreButton()}
              <span className='total-jobtypes'>
                {infoDetail?.length} {labelTotal}
              </span>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>Totals</Table.Summary.Cell>
          {Object.values(daysSummary ?? {}).map((dayTotal) => (
            <Table.Summary.Cell>
              {time.convertTimeHoursCell(dayTotal ?? 0)}
            </Table.Summary.Cell>
          ))}
          <Table.Summary.Cell>
            {time.convertTimeHoursCell(totalHoursSummary ?? 0)}
          </Table.Summary.Cell>
          <Table.Summary.Cell />
          <Table.Summary.Cell>
            <div className='total-cell'>
              {currency}
              {Number(totalPaySummary ?? 0).toFixed(2)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className='total-cell'>
              {currency}
              {Number(totalChargeSummary ?? 0).toFixed(2)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell />
          <Table.Summary.Cell>
            <span className='cell-text'>Select all</span>
            <ColorCheckbox
              disabled={disableStatus.includes(status)}
              className={`cell-select ${statusColor} color-select`}
              onChange={handleSelectAll}
              checked={isSelectedAllTimecards && !isAllSickNoShow}
            />
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const extraColumns = columns.map((col) => ({
    ...col,
    ...(col?.title !== 'Employee' && { width: '5.8%' }),
  }));

  extraColumns.splice(3, 0, {
    title: 'Pushed Record',
    dataIndex: 'push_from_timesheet_id',
    width: '6.4%',
    render: (_, record) => (
      <div className='pushed-record__container'>
        <Tooltip
          className='pushed-record__column'
          placement='topLeft'
          title={`Week ${record.prev_week_num} - Week ${record.current_week_num}`}
        >
          Week {record.prev_week_num} - Week {record.current_week_num}
        </Tooltip>
      </div>
    ),
  });

  const isLoading =
    loading || isUpdateSickNoShow || updateTimecardLoading || isUndoing;

  const actions = {
    [ACTIONS.finalised.key]: handleConfirmFinalise,
    [ACTIONS.undo.key]: handleUndo,
  };

  return (
    <Form
      className='list-job-types'
      form={form}
      initialValues={groupedTimecards}
    >
      <div className='form-list-job-types'>
        <ListView
          bordered
          headerColumns={pushFromTimesheetId ? extraColumns : columns}
          infoDetail={payrollSortedBySeekerName}
          rowClassName='editable-row'
          className={classNames(`list-job-types-table payroll ${tableColor}`, {
            gray: pushFromTimesheetId,
            'large-height': isLargeHeight && !isShowAll,
            'show-all': isShowAll,
          })}
          pagination={false}
          scroll={{ y: tableHeight + BORDER_HEIGHT }}
          footer={footer}
          loading={isLoading}
          summary={renderSummary}
        />

        <SickNoShowModal
          name='comment'
          type={modalType}
          visible={isShowSickNoShow}
          onCancel={() => setIsShowSickNoShow(false)}
          onConfirm={handleSickNoShow}
          isLoading={isUpdateSickNoShow}
        />

        <ModalConfirm
          visible={isShowConfirm}
          title={`${ACTIONS?.[actionKey]?.label} Payroll Record`}
          onClickNo={() => {
            setShowConfirm(false);
          }}
          onClickYes={actions[actionKey]}
          isLoading={isLoading}
        >
          Are you sure you want to {ACTIONS?.[actionKey]?.label} all selected
          records?
          <br />
          <br />
          No futher changes can be made after this time
        </ModalConfirm>
      </div>
    </Form>
  );
};

ListJobTypesPayroll.propTypes = {
  payrollId: PropTypes.number,
  loading: PropTypes.bool,
  infoDetail: PropTypes.array,
  labelTotal: PropTypes.string,
  tableColor: PropTypes.string,
  statusColor: PropTypes.string,
  colorButtonApprove: PropTypes.string,
  textButtonApprove: PropTypes.string,
  handleFormFinished: PropTypes.func,
  dateRange: PropTypes.array,
  jobTypeName: PropTypes.string,
  nextWeekIds: PropTypes.array,
  setNextWeekIds: PropTypes.func,
  pullBackIds: PropTypes.array,
  setPullBackIds: PropTypes.func,
  pullBackRecord: PropTypes.func,
  listTimecardsSelected: PropTypes.object,
  setListTimecardsSelected: PropTypes.func,
  status: PropTypes.string,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

ListJobTypesPayroll.defaultProps = {
  payrollId: null,
  tableColor: '',
  labelTotal: '',
  statusColor: '',
  textButtonApprove: '',
  colorButtonApprove: '',
  infoDetail: [],
  nextWeekIds: [],
  loading: false,
  isSuccess: false,
  isLoading: false,
  dateRange: [],
  setNextWeekIds: () => {},
};

export default ListJobTypesPayroll;
