import React from 'react';

import { Form } from 'antd';

import { FormItem, Input } from '@/components';
import {
  rulesEmail,
  rulesPhone,
  rulesPostcode,
  rulesTextInput,
} from '@/utils/rulesInput';

const NextOfKin = () => {
  const form = Form.useFormInstance();

  return (
    <div className='seeker-next-of-kin'>
      <p className='employment-history-label'>Next of Kin</p>

      <div className='seeker-next-of-kin-content'>
        <FormItem
          label='Next of Kin - First Name'
          name='kinFirstname'
          rules={rulesTextInput('Next of Kin - First Name')}
          required
        >
          <Input maxLength={48} placeholder='Next of Kin - First Name' />
        </FormItem>

        <FormItem
          label='Next of Kin - Email'
          name='kinEmail'
          rules={rulesEmail(false)}
        >
          <Input placeholder='Next of Kin - Email' />
        </FormItem>

        <FormItem
          label='Next of Kin - Relationship'
          name='kinRelation'
          required
          rules={rulesTextInput('Next of Kin - Relationship')}
        >
          <Input placeholder='Next of Kin - Relationship' />
        </FormItem>

        <FormItem
          required
          label='Next of Kin - Phone'
          name='kinPhone'
          rules={rulesPhone()}
        >
          <Input placeholder='Next of Kin - Phone' />
        </FormItem>

        <FormItem
          required
          label='Next of Address - Building'
          name='kinAddress'
          rules={rulesTextInput('Next of Address - Building')}
        >
          <Input placeholder='Next of Address - Building' />
        </FormItem>

        <FormItem label='Next of Kin - Street' name='kinAddress2'>
          <Input placeholder='Next of Kin - Street' />
        </FormItem>

        <FormItem
          required
          name='kinZip'
          rules={rulesPostcode(form, 'kinZip')}
          label='Next of Kin - Postcode'
        >
          <Input placeholder='Next of Kin - Postcode' />
        </FormItem>
      </div>
    </div>
  );
};

export default NextOfKin;
