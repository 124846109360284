import create from 'zustand';

import { AuthService } from '@/services';
import { errorToObject } from '@/utils/errorUtil';
import {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  setUserName,
  removeUserName,
  getUserName,
  setUserId,
  removeUserId,
  getUserId,
} from '@/utils/localStorageUtil';

const defaultState = {
  token: null,
  error: null,
  loading: false,
  userName: null,
};

const initialState = {
  ...defaultState,
  token: getAuthToken(),
  userName: getUserName(),
  userId: getUserId(),
};

export const useAuthStore = create((set) => ({
  ...initialState,
  login: async ({ email, password }) => {
    try {
      set(() => ({ loading: true }));
      const { token, userName, userEmail, userId } = await AuthService.login({
        email,
        password,
      });
      const nameOrEmail = userName || userEmail;
      setAuthToken(token);
      setUserName(nameOrEmail);
      setUserId(userId);
      set(() => ({ token, userName: nameOrEmail, loading: false }));
    } catch (err) {
      const error = errorToObject(err).message;
      set(() => ({ error, loading: false }));
    }
  },
  logout: () => {
    removeAuthToken();
    removeUserName();
    removeUserId();

    set(() => ({ ...defaultState }));

    return true;
  },
}));
