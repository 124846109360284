const STATUS_CODES = {
  unapproved: {
    content: 'Not Approved - No action taken',
    color: 'red',
  },
  approved: {
    content: 'Approved - Timesheets approved in WebApp',
    color: 'yellow',
  },
  confirmed: {
    content: 'Confirmed - Timesheet confirmed for payroll',
    color: 'green',
  },
  paid: {
    content: 'Paid - Payroll has been processed and paid',
    color: 'yellow',
  },
};

const mockDataNotApproved = [];
const mockDataApproved = [];
const mockDataConfirmed = [];
const mockDataPaid = [];

const times = [
  {
    id: 1,
    value: '10:55',
  },
  {
    id: 2,
    value: '20:55',
  },
  {
    id: 3,
    value: '1:55',
  },
];

const mockDataEditJobType = {
  jobType: 'Ware house Operative',
  employee: 'Isabelle Smith',
  payroll: '12345',
  mon: null,
  tues: {
    // Do not know logic here
    totalOfTues: 10,
    times,
  },
  wed: null,
  thurs: null,
  fri: null,
  sat: null,
  sun: 10,
  total: 28,
  payAndCharge: [10.0, 14.43],
  totalPay: 280.03,
  totalCharge: 404.04,
};

const mockDataCommonObject = {
  jobType: 'Ware house Operative',
  employee: 'Isabelle Smith',
  payroll: '12345',
  mon: null,
  tues: 10,
  wed: null,
  thurs: null,
  fri: null,
  sat: null,
  sun: 10,
  total: 28,
  payAndCharge: [10.0, 14.43],
  totalPay: 280.03,
  totalCharge: 404.04,
};

// eslint-disable-next-line no-plusplus
for (let i = 0; i < 4; i++) {
  mockDataNotApproved.push({
    id: i,
    status: STATUS_CODES.unapproved.content,
    ...mockDataCommonObject,
  });
  mockDataApproved.push({
    id: i,
    status: STATUS_CODES.approved.content,
    ...mockDataCommonObject,
  });
  mockDataConfirmed.push({
    id: i,
    status: STATUS_CODES.confirmed.content,
    ...mockDataCommonObject,
  });
  mockDataPaid.push({
    id: i,
    status: STATUS_CODES.paid.content,
    ...mockDataEditJobType,
  });
}

const pushedRecordTables = [
  {
    status: 'unapproved',
    tableColor: 'gray',
    statusColor: STATUS_CODES.unapproved.color,
    pullBack: true,
  },
  {
    status: 'approved',
    tableColor: 'gray',
    statusColor: STATUS_CODES.approved.color,
    pullBack: true,
  },
  {
    status: 'confirmed',
    tableColor: 'gray',
    statusColor: STATUS_CODES.confirmed.color,
    pullBack: true,
  },
  {
    status: 'finalised',
    tableColor: 'gray',
    statusColor: STATUS_CODES.paid.color,
    colorButtonApprove: 'black',
    extraCol: ' ', // just show a black button without the content
    pullBack: true,
  },
];

// TODO: will refactor function later
const getListTimesheetDetailIds = (formInstance, infoDetail) => {
  const listIndexs = Object.values(formInstance.getFieldsValue())
    ?.flat(1)
    ?.map((element, index) => {
      if (element?.jobTypeSelected) {
        return index;
      }
      return -1;
    })
    ?.filter((element) => element >= 0);

  return listIndexs
    .map((timecardId) =>
      infoDetail[timecardId].dayworks
        ?.filter(({ id }) => id)
        ?.map(({ id }) => id),
    )
    .flat(Infinity);
};

const handleCombineInvoice = (listInvoice) => {
  const combinedData = listInvoice.reduce((acc, currData) => {
    const key = `${currData.invoiceLine}-${currData.invoiceDetailType}`;
    if (acc[key]) {
      acc[key].hours += currData.hours;
      acc[key].omniNetPay += currData.omniNetPay;
    } else {
      acc[key] = { ...currData };
    }

    return acc;
  }, {});

  return Object.values(combinedData);
};

const UPDATE_KEY = {
  all: 'updateAll',
  selected: 'updateSelected',
};
const BORDER_HEIGHT = 3;

export {
  BORDER_HEIGHT,
  STATUS_CODES,
  getListTimesheetDetailIds,
  handleCombineInvoice,
  pushedRecordTables,
  UPDATE_KEY,
};
