/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Checkbox, Form, Switch, Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import {
  Button,
  FormItem,
  GeneralTemplate,
  Input,
  ListView,
  MultipleSelect,
  Pagination,
  Select,
  TooltipStatus,
} from '@/components';
import ModalAddAdditionalJobType from '@/components/ModalAddAdditionalJobType';
import { PAGE_SIZE_OPTIONS, PAYMENT_TYPE } from '@/constants';
import { useFetch, usePagination, useSessionStorage } from '@/hooks';
import { useProviders, useRoleItems } from '@/hooks/dropdowns';
import {
  TimesheetListView,
  getCountTimeSheetByWeek,
} from '@/services/timesheetService';
import { time } from '@/utils';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import { listWeek, listYear, mappingStatuses, statuses } from '../constants';

import './styles.scss';

const preparePaymentWeek = (record) => {
  if (!record.pushedTimesheetsWeekNum) return record.weekNum;
  if (record.onlyContainPushedRecord) return record.pushedTimesheetsWeekNum;

  return `${record.pushedTimesheetsWeekNum} ,${record.weekNum}`;
};

const columnsTable = generateHeaderTable([
  {
    title: 'Provider',
    width: '10%',
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => a.provider.localeCompare(b.provider),
    render: (/** @type {string} */ name) => (
      <Tooltip placement='topLeft' title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    title: 'Location',
    width: '10%',
    ellipsis: {
      showTitle: false,
    },
    dataIndex: 'locationName',
    render: (/** @type {string} */ location) => (
      <Tooltip placement='topLeft' title={location}>
        {location}
      </Tooltip>
    ),
  },
  {
    title: 'Year',
    width: '4%',
    render: (year) => (
      <Tooltip placement='topLeft' title={year}>
        {year}
      </Tooltip>
    ),
  },
  {
    title: 'Payment Week',
    width: '8%',
    dataIndex: 'weekNum',
    render: (_week, record) => preparePaymentWeek(record),
  },
  {
    title: 'Payment Week Dates',
    width: '15%',
    ellipsis: {
      showTitle: false,
    },
    render: (/** @type {string} */ date, record) => {
      const title = `${record?.weekStartDate}-${record?.weekEndDate}`;
      return (
        <Tooltip placement='topLeft' title={title}>
          {title}
        </Tooltip>
      );
    },
  },
  {
    title: 'Last Edited By',
    dataIndex: 'userFullname',
    render: (data) => (
      <Tooltip placement='topLeft' title={data}>
        {data}
      </Tooltip>
    ),
  },
  {
    title: 'Last Edited At',
    width: '9%',
    dataIndex: 'lastEditedOn',
    render: (data) => {
      const date = moment(data);
      const content = (
        <div className='last-edited-at'>
          <p>{date.format('DD/MM/YYYY')}</p>
          <p>{date.format('HH:mm:ss')}</p>
        </div>
      );

      return (
        <Tooltip placement='topLeft' title={content}>
          {content}
        </Tooltip>
      );
    },
  },
  { title: 'Number of Seekers', width: '8%', dataIndex: 'countEmployees' },
  {
    title: 'Total Hours',
    width: '6%',
    render: (totalHours) => time.convertTimeHoursCell(totalHours ?? 0),
  },
  {
    title: 'Total Pay',
    width: '6%',
    render: (data) => {
      const content = Formatter.currencyGBP(data);
      return (
        <Tooltip placement='topLeft' title={content}>
          {content}
        </Tooltip>
      );
    },
  },
  {
    title: <TooltipStatus />,
    id: 'Status',
    width: '7%',
    render: (_, record) => (
      <div
        className={`timesheets-status status-${
          mappingStatuses[record.status]?.color
        }`}
      >
        <Checkbox defaultChecked />
      </div>
    ),
  },
  {
    title: 'Status Detail',
    width: '7%',
    dataIndex: 'status',
    render: (data) => {
      const status = mappingStatuses[data]?.label;
      return <Tooltip title={status}>{status}</Tooltip>;
    },
  },
]);

const TimeSheetsPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const listProviders = useProviders();
  const { data: listRoles } = useRoleItems();
  const weekNumWatching = Form.useWatch('weekNum', form) ?? moment().week();
  const yearWatching = Form.useWatch('year', form) ?? moment().year();

  const [openAddTimeCard, setOpenAddTimeCard] = useState(false);
  const payrollTemplate = Form.useWatch('payrollTemplate', form);

  const [storedValue, setSession, removeSession] =
    useSessionStorage('timesheetSearch');

  useEffect(() => {
    form.setFieldsValue(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumb = [
    { item: 'Timesheets', to: '/timesheets' },
    { item: `Week ${weekNumWatching}`, to: '#' },
  ];

  const {
    currentPage,
    handlePagination,
    setCurrentPage,
    setPaginationPageSize,
    paginationPageSize,
  } = usePagination();

  const {
    data: timesheets,
    isLoading,
    refetch,
  } = useFetch(
    [
      'timesheets',
      weekNumWatching,
      currentPage,
      payrollTemplate,
      yearWatching,
      paginationPageSize,
    ],
    () => {
      setSession(form.getFieldsValue());

      return TimesheetListView({
        ...form.getFieldsValue(),
        weekNum: [form.getFieldValue('weekNum')],
        year: [form.getFieldValue('year') ?? moment().year()],
        pageNumber: currentPage,
        pageSize: paginationPageSize,
        paymentType: payrollTemplate
          ? PAYMENT_TYPE.payrollTemplate
          : PAYMENT_TYPE.flexiblePayRate,
      });
    },
  );

  const { data: countData, refetch: refetchCountData } = useFetch(
    ['getCountTimeSheet', weekNumWatching, weekNumWatching],
    () => getCountTimeSheetByWeek(yearWatching, weekNumWatching),
  );

  const handleClear = () => {
    form.resetFields();
    removeSession();
    refetch();
    refetchCountData();
  };

  const onShowSizeChange = (current, pageSize) => {
    setPaginationPageSize(pageSize);
    setCurrentPage(current);
  };

  const handleNavigate = (id, row) => {
    const {
      provider,
      weekNum,
      year,
      weekStartDate,
      weekEndDate,
      locationName,
      paymentType,
    } = row;
    if (paymentType === PAYMENT_TYPE.payrollTemplate) {
      history.push(`/pay-template-timesheet/${id}`, {
        provider,
        weekNum,
        year,
        weekStartDate,
        weekEndDate,
        locationName,
      });
    } else {
      history.push(`/timesheet-detail/${id}`, {
        provider,
        weekNum,
        year,
        weekStartDate,
        weekEndDate,
      });
    }
  };

  const onFilter = () => {
    setSession(form.getFieldsValue());
    refetch();
    refetchCountData();
  };

  const renderToggleMode = () => (
    <Form form={form}>
      <div className='header-actions'>
        <div className='template-mode'>
          <div>
            <div className='title-template-mode'>
              {countData && (
                <div
                  className={classNames('number-count', {
                    enable: !payrollTemplate,
                  })}
                >
                  {countData?.flexible_pay_rate}
                </div>
              )}
              <p>Flexible Pay Rate</p>
            </div>
          </div>
          <FormItem name='payrollTemplate' valuePropName='checked'>
            <Switch />
          </FormItem>
          <div className='title-template-mode'>
            {countData && (
              <div
                className={classNames('number-count', 'right', {
                  enable: payrollTemplate,
                })}
              >
                {countData?.pay_template}
              </div>
            )}
            <p>Pay Template</p>
          </div>
        </div>
        <div className='add-timecard-btn'>
          <Button
            className='yellow-button'
            onClick={() => setOpenAddTimeCard(true)}
          >
            + Add timecards
          </Button>
        </div>
      </div>
    </Form>
  );

  const highlightClassName = (record) =>
    record.status === 'no_show' && 'highlight-row';

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadcrumb}
      headerText={`Week ${weekNumWatching}`}
      hasButton
      buttonCustom={renderToggleMode()}
    >
      <Form
        form={form}
        onFinish={onFilter}
        initialValues={{
          weekNum: moment().week(),
          year: moment().year(),
        }}
      >
        <div className='timesheets-page'>
          <div className='timesheets-header'>
            <div className='timesheets-field'>
              <FormItem label='Choose year' name='year'>
                <Select
                  placeholder='Choose year'
                  options={listYear}
                  name='year'
                  defaultValue={moment().year()}
                />
              </FormItem>
              <FormItem label='Search' name='searchTerm'>
                <Input placeholder='Search' />
              </FormItem>
            </div>
            <div className='timesheets-field'>
              <FormItem label='Choose week' name='weekNum'>
                <Select
                  placeholder='Choose week'
                  options={listWeek}
                  name='weekNum'
                />
              </FormItem>
              <FormItem label='Roles' name='role'>
                <MultipleSelect
                  placeholder='Roles'
                  options={listRoles}
                  name='role'
                />
              </FormItem>
            </div>
            <div className='timesheets-field'>
              <FormItem label='Provider' name='employerId'>
                <MultipleSelect
                  name='employerId'
                  options={listProviders}
                  placeholder='Provider'
                />
              </FormItem>
            </div>
            <div className='timesheets-field'>
              <FormItem label='Status' name='status'>
                <MultipleSelect
                  placeholder='Status'
                  options={statuses}
                  name='status'
                />
              </FormItem>
            </div>
            <div className='button-fields'>
              <Button
                className='yellow-button input-height style-btn'
                htmlType='submit'
              >
                Search
              </Button>
              <Button
                className='button button-clear style-btn'
                onClick={handleClear}
              >
                Clear Filters
              </Button>
            </div>
          </div>
          <ListView
            className='timesheet-listview'
            headerColumns={columnsTable}
            infoDetail={timesheets?.data}
            rowClassName={highlightClassName}
            loading={isLoading}
            onRow={handleNavigate}
            locale={{
              emptyText: 'No Timesheets match your search criteria',
            }}
          />
          <div className='pagination-container'>
            <Pagination
              current={currentPage}
              total={timesheets?.totalCount}
              pageSize={paginationPageSize}
              onChange={handlePagination}
              onShowSizeChange={onShowSizeChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
            />
          </div>
          <ModalAddAdditionalJobType
            isInListView
            visible={openAddTimeCard}
            setIsVisible={setOpenAddTimeCard}
            isCreateTimecard
            year={form.getFieldValue('year')}
            onOk={() => setOpenAddTimeCard(false)}
            onCancel={() => setOpenAddTimeCard(false)}
            timesheetRefetch={refetch}
            weekNumber={form.getFieldValue('weekNum')}
            listProviders={listProviders}
          />
        </div>
      </Form>
    </GeneralTemplate>
  );
};

export default TimeSheetsPage;
