import React, { useEffect, useState } from 'react';

import { Form, message, Radio } from 'antd';
import classNames from 'classnames';
import { debounce, isEmpty } from 'lodash';

import {
  Button,
  Checkbox,
  FormItem,
  ModalDocFields,
  Input,
  UploadFileThumbnail,
} from '@/components';
import Upload from '@/components/Upload';
import { DEBOUNCE_WAIT } from '@/constants';
import { useUploadFileS3 } from '@/hooks/useUploadFile';
import { dummyRequest, beforeUploadFile } from '@/utils/uploadFileUtil';
import useModal from '@/utils/useModal';

const documentationTypes = [
  {
    value: 'studentLetter',
    label: 'Student letter',
  },
  {
    value: 'visaBrpFile',
    label: 'Visa Application Letter',
  },
  {
    value: 'p45Document',
    label: 'Past P45',
  },
  {
    value: 'proofAddress',
    label: 'Proof of Address',
  },
  {
    value: 'proofNi',
    label: 'Proof of NI',
  },
  {
    value: 'secondaryId',
    label: 'Secondary ID',
  },
  {
    value: 'dbsCheck',
    label: 'DBS',
  },
];

const Documentation = () => {
  const { base64, setBase64, fileList, changeFile, file, setFile } =
    useUploadFileS3();

  const formInstance = Form.useFormInstance();
  const prevValues = Form.useWatch('documentationDocs', formInstance) ?? [];
  const [visible, showModal, hideModal] = useModal();
  const [documentationType, setRightToWorkType] = useState();

  const [fileState, setFileState] = useState({
    field: '',
    label: '',
    value: undefined,
    file: undefined,
  });
  const [blankBoxName, setBlankBoxName] = useState('');

  // if change field and change field name
  useEffect(() => {
    if (base64 && fileState.field) {
      const documentationDocs =
        formInstance.getFieldValue('documentationDocs') ?? [];

      const findIndex = documentationDocs.findIndex(
        (doc) => doc.typeValue === fileState.field,
      );

      if (findIndex === -1) return;

      documentationDocs[findIndex] = {
        ...documentationDocs[findIndex],
        checked: true,
        fileValue: base64,
        fileBlob: file,
      };

      formInstance.setFieldsValue({
        documentationDocs,
      });

      setFileState((prev) => ({ ...prev, value: base64, file }));
      setBase64();
      setFile();
    }
  }, [base64, fileState.field, formInstance, setBase64, file, setFile]);

  const handleAddDocumentationType = debounce(() => {
    if (documentationType) {
      if (documentationType === 'blankBox' && !blankBoxName) {
        return message.success('Blank box must have value!');
      }

      const type =
        documentationTypes.find((t) => t.label === documentationType) ?? {};

      const value = prevValues.find((t) =>
        documentationType === 'blankBox'
          ? t.typeLabel === blankBoxName
          : t.typeLabel === documentationType,
      );

      if (value) {
        return message.success('Already exist');
      }

      formInstance.setFieldsValue({
        documentationDocs: [
          ...prevValues,
          {
            typeValue: type.value ?? `blankBox-${blankBoxName}`, //  this is field name
            typeLabel: type.label ?? blankBoxName,
            fileValue: undefined, // default when add to list is empty object
            checked: false,
          },
        ],
      });

      setBlankBoxName('');
      setRightToWorkType(undefined);
      hideModal();
    }

    return undefined;
  }, DEBOUNCE_WAIT);

  const handleRightToWorkTypeChange = ({ target }) => {
    setRightToWorkType(target.value);
  };

  const handleSelectDoc = (index) => {
    const documentationDocs =
      formInstance.getFieldValue('documentationDocs') ?? [];

    if (!documentationDocs) {
      return;
    }

    if (documentationDocs[index].checked) {
      return;
    }

    // get fieldName
    const { fileValue, typeLabel, typeValue } = documentationDocs[index];

    setFileState({
      field: typeValue,
      label: typeLabel,
      value: fileValue,
    });

    const newRightToWorkDocs = documentationDocs.map((doc, idx) => ({
      ...doc,
      checked: index === idx,
    }));

    formInstance.setFieldsValue({
      documentationDocs: newRightToWorkDocs,
    });
  };

  return (
    <div className='block-container'>
      <h3>Documentation</h3>
      <div className='body-block documentation'>
        <div className='left-block'>
          <div className='documents'>
            <Form.List
              name='documentationDocs'
              initialValue={[
                {
                  fileBlob: {},
                  fileValue: undefined,
                  typeValue: 'contractFile',
                  typeLabel: 'Contract',
                },
                {
                  fileBlob: {},
                  fileValue: undefined,
                  typeValue: 'profilepic',
                  typeLabel: 'Profile Picture',
                },
                {
                  fileBlob: {},
                  fileValue: undefined,
                  typeValue: 'cvUpload',
                  typeLabel: 'CV',
                },
              ]}
            >
              {(fields) => (
                <>
                  {fields.map((field, index) => {
                    const value =
                      formInstance.getFieldValue('documentationDocs')[index];

                    return (
                      <React.Fragment key={field.key}>
                        <FormItem name={[field.name, 'fileBlob']} noStyle />
                        <FormItem name={[field.name, 'fileValue']} noStyle />
                        <FormItem name={[field.name, 'typeValue']} noStyle />
                        <FormItem name={[field.name, 'typeLabel']} noStyle />

                        <FormItem
                          className='document-item-checkbox'
                          valuePropName='checked'
                        >
                          <Checkbox
                            onClick={() => handleSelectDoc(index)}
                            className='item-checkbox'
                            checked={value?.checked}
                          >
                            <div>{value.typeLabel}</div>
                            <Checkbox checked={!isEmpty(value.fileValue)} />
                          </Checkbox>
                        </FormItem>
                      </React.Fragment>
                    );
                  })}

                  <Button
                    onClick={showModal}
                    className='yellow-button add-another-document'
                  >
                    Add Another Document
                  </Button>
                </>
              )}
            </Form.List>
            <ModalDocFields
              visible={visible}
              onClickYes={handleAddDocumentationType}
              onClickNo={hideModal}
              isWarning={false}
              className='modal-for-types'
            >
              <Radio.Group
                className={classNames('radio-checkbox', 'types')}
                onChange={handleRightToWorkTypeChange}
                value={documentationType}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {documentationTypes.map((item) => (
                    <Radio value={item.label}>{item.label}</Radio>
                  ))}
                  <Radio value='blankBox'>
                    <Input
                      value={blankBoxName}
                      placeholder='Blank Box'
                      onChange={(e) => setBlankBoxName(e.target.value)}
                    />
                  </Radio>
                </div>
              </Radio.Group>
            </ModalDocFields>
          </div>
        </div>
        <div className='right-block'>
          <FormItem
            className='style-container'
            label={fileState.label}
            name={fileState.field}
          >
            <UploadFileThumbnail src={fileState.value} file={fileState.file} />

            <Upload
              showUploadList={false}
              customRequest={dummyRequest}
              beforeUpload={beforeUploadFile}
              onChange={changeFile}
              fileList={fileList}
              disabled={!fileState.field}
              required
            >
              <div className='choose-file'>
                <Button disabled={!fileState.field} className='white-button'>
                  Choose File
                </Button>
                {!fileState.value && <span>No file chosen</span>}
              </div>
            </Upload>
          </FormItem>
        </div>
      </div>
    </div>
  );
};
export default Documentation;
