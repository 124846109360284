/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { isNil } from 'lodash';

import { times } from '../../constants';

const trackingTableColumns = () => {
  const initTimeColumns = times.map((time, indexColum) => ({
    title: time?.label ? time.label : '',
    dataIndex: indexColum,
    width: '3%',
    render: (_, record) => {
      const timeRangeInfo = record?.timeRange?.find(
        (timeInfo) =>
          time.value >= timeInfo?.startAt && time.value < timeInfo?.endAt,
      );

      const color = !isNil(timeRangeInfo) && timeRangeInfo.color;

      return (
        <div
          className='cell--empty'
          {...(color && {
            style: {
              backgroundColor: color,
            },
          })}
        />
      );
    },
  }));

  const initColumns = [
    {
      title: null,
      dataIndex: 'dayOfWeek',
      width: '3%',
      render: (dayOfWeek) => <span>{dayOfWeek}</span>,
    },
    ...initTimeColumns,
  ];

  return initColumns;
};

export default trackingTableColumns;
