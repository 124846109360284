import request from '@/request';

const updateTimeCardAttributes = async ({ payrollId, timecardAttributes }) => {
  const payload = {
    payroll: {
      timecardAttributes,
    },
  };

  const { data } = await request.patch(
    `payments/payrolls/${payrollId}`,
    payload,
  );

  return data?.data ?? [];
};

export default updateTimeCardAttributes;
