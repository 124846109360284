import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import Routes from '@/routes';

import ErrorBoundary from './components/ErrorBoundary';

import 'antd/dist/antd.less';
import '@/assets/scss/global.scss';
import '@/utils/customPrototypes';

const App = () => (
  <Router>
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
  </Router>
);

export default App;
