import { isEmpty } from 'lodash';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const getApplicant = async (id) => {
  const endpoint = `guests/applicants/${id}`;
  const res = await request.get(endpoint);
  const data = normalizeShowResponse(res);
  const result = data?.data;
  const { ni, shareCode } = result;
  const ni1 = ni?.slice(0, 2);
  const ni2 = ni?.slice(2, 4);
  const ni3 = ni?.slice(4, 6);
  const ni4 = ni?.slice(6, 8);
  const ni5 = ni?.slice(8, 10);
  const code1 = shareCode?.slice(0, 3);
  const code2 = shareCode?.slice(3, 6);
  const code3 = shareCode?.slice(6, 9);

  if (isEmpty(result)) return {};

  let vacancyApplicantLanguagesAttributes = result.vacancyApplicantLanguages;
  const vacancyApplicantLanguageMainAttributes =
    vacancyApplicantLanguagesAttributes?.find(
      (item) => item.name === 'English',
    ) || { id: '', name: 'English', level: undefined };

  // eslint-disable-next-line max-len
  vacancyApplicantLanguagesAttributes =
    vacancyApplicantLanguagesAttributes?.filter(
      (item) => item.id !== vacancyApplicantLanguageMainAttributes.id,
    );

  const parseData = {
    ...result,
    ni1,
    ni2,
    ni3,
    ni4,
    ni5,
    code1,
    code2,
    code3,
    vacancyApplicantLanguageMainAttributes,
    vacancyApplicantLanguagesAttributes,
    preferredRoles: result.preferredRoles
      ?.split(',')
      ?.map((role) => parseInt(role, 10)),
  };

  return parseData;
};

export default getApplicant;
