import React from 'react';

import { Form } from 'antd';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';

import {
  Input,
  Button,
  DatePicker,
  FormItem,
  MultipleSelect,
} from '@/components';
import { STATUS_FILTER_DROPDOWN_OPTIONS } from '@/constants';
import useHandleCancelDatePicker from '@/hooks/useCancelDatePicker';
import { disabledDateOf } from '@/utils';

import FilterLabel from './FilterLabel';

/**
 * Renders an input form with various fields for filtering and searching.
 *
 * @param {Object} props - The props object containing the following properties:
 *   @param {boolean} props.isShowStatus - Indicates whether to show the status filter.
 *   @param {Array} props.listRoles - An array of role objects for the role filter.
 *   @param {Array} props.listProviders - An array of provider objects for the provider filter.
 *   @param {Array} props.locations - An array of location objects for the location filter.
 *   @param {Array} props.listIndustries - An array of industry objects for the industry filter.
 *   @param {Array} props.providerIds - An array of selected provider IDs.
 *   @param {Array} props.roleIds - An array of selected role IDs.
 * @return {JSX.Element} The rendered input form.
 */
const InputForm = ({
  isShowStatus,
  listRoles,
  listProviders,
  locations,
  listIndustries,
  providerIds,
  roleIds,
}) => {
  const formInstance = Form.useFormInstance();

  const handleCancelClickFrom = useHandleCancelDatePicker(
    formInstance,
    'shiftPostedFrom',
  );
  const handleCancelClickTo = useHandleCancelDatePicker(
    formInstance,
    'shiftPostedTo',
  );

  const startDate = Form.useWatch('shiftPostedFrom', formInstance);
  const endDate = Form.useWatch('shiftPostedTo', formInstance);

  const renderInputItemsAbove = () => (
    <div className='input-items'>
      <FormItem name='searchTerm' className='input-item' label='Search'>
        <Input className='input-height' placeholder='Name or email address' />
      </FormItem>
      <FormItem name='role' className='input-item' label='Positions'>
        <MultipleSelect
          options={listRoles}
          className='input-height'
          placeholder='Position'
          name='role'
          hideTag
        />
      </FormItem>
      <FormItem name='provider' className='input-item' label='Provider'>
        <MultipleSelect
          className='input-height'
          placeholder='Provider'
          options={listProviders}
          name='provider'
          noSelectAll
          hideTag
        />
      </FormItem>
      <FormItem name='location' className='input-item' label='Locations'>
        <MultipleSelect
          className='input-height'
          placeholder='Locations'
          options={locations}
          name='location'
          noSelectAll
          hideTag
        />
      </FormItem>
    </div>
  );

  const renderInputItemsBelow = () => (
    <div className='input-items'>
      <div className='date-pickers input-item'>
        <p className='shift-date'>Shift Date</p>
        <div className='date-container'>
          <FormItem name='shiftPostedFrom' className='date-picker'>
            <DatePicker
              name='shiftPostedFrom'
              disabledDate={(disabledDate) =>
                disabledDateOf.Start(disabledDate, endDate)
              }
              className='input-height'
              onCancelClick={handleCancelClickFrom}
            />
          </FormItem>
          <FormItem name='shiftPostedTo' className='date-picker'>
            <DatePicker
              name='shiftPostedTo'
              disabledDate={(disabledDate) =>
                disabledDateOf.End(disabledDate, startDate)
              }
              className='input-height'
              onCancelClick={handleCancelClickTo}
            />
          </FormItem>
        </div>
      </div>
      <FormItem name='industry' className='input-item' label='Industry'>
        <MultipleSelect
          options={listIndustries}
          className='input-height'
          placeholder='Industry'
          name='industry'
          hideTag
        />
      </FormItem>
      {isShowStatus && (
        <FormItem name='status' className='input-item' label='Status'>
          <MultipleSelect
            className='input-height'
            placeholder='Posted/ Filled'
            options={STATUS_FILTER_DROPDOWN_OPTIONS}
            name='status'
            hideTag
          />
        </FormItem>
      )}
      <div className='buttons input-item'>
        <Button
          className='yellow-button input-height style-btn'
          type='primary'
          htmlType='submit'
        >
          Search
        </Button>
        <Button
          htmlType='reset'
          className='filter-button input-height style-btn'
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
  return (
    <>
      {renderInputItemsAbove()}
      {renderInputItemsBelow()}
      <div className='filter-review-container'>
        <FilterLabel
          fieldName='filterLabel'
          label='Filters'
          options={{
            provider: keyBy(listProviders, 'value'),
            role: keyBy(listRoles, 'value'),
            location: keyBy(locations, 'value'),
            industry: keyBy(listIndustries, 'value'),
            status: keyBy(STATUS_FILTER_DROPDOWN_OPTIONS, 'value'),
          }}
          selectedIds={{
            provider: providerIds,
            role: roleIds ?? [],
            location: Form.useWatch('location', formInstance) ?? [],
            industry: Form.useWatch('industry', formInstance) ?? [],
            status: Form.useWatch('status', formInstance) ?? [],
          }}
        />
      </div>
    </>
  );
};

InputForm.propTypes = {
  isShowStatus: PropTypes.bool,
  listRoles: PropTypes.array,
  listProviders: PropTypes.array,
  locations: PropTypes.array,
  listIndustries: PropTypes.array,
  providerIds: PropTypes.array,
  roleIds: PropTypes.array,
};

InputForm.defaultProps = {
  isShowStatus: false,
  listRoles: [],
  listProviders: [],
  locations: [],
  listIndustries: [],
  providerIds: [],
  roleIds: [],
};

export default InputForm;
