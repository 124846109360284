import React, { useState, useEffect, useMemo } from 'react';

import { Form } from 'antd';
import { isEmpty, uniqueId } from 'lodash';

import { Button, Textarea, FormItem } from '@/components';

import TableMedical from './tableMedical';

const Medical = () => {
  const form = Form.useFormInstance();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const medicalConditionsAttributes =
    Form.useWatch(
      ['vacancyApplicantAttributes', 'medicalConditionsAttributes'],
      form,
    ) || [];

  const [medical, setMedical] = useState();
  const [medicalNote, setMedicalNote] = useState();
  const [medicalCondition, setMedicalCondition] = useState();
  const [dataMedical, setDataMedical] = useState([]);

  useEffect(() => {
    if (!isEmpty(dataMedical)) {
      form.setFieldsValue({
        vacancyApplicantAttributes: {
          medicalConditionsAttributes: dataMedical,
        },
      });
    }
  }, [dataMedical, form]);

  useMemo(() => {
    if (!isEmpty(medicalConditionsAttributes)) {
      const mapMedicalConditions = medicalConditionsAttributes?.map((item) => ({
        id: item?.id,
        key: item?.id,
        medicalCondition: item?.medicalCondition,
        medication: item?.medication,
        note: item?.note,
      }));

      setDataMedical(mapMedicalConditions ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalConditionsAttributes]);

  const handleAddMedical = () => {
    if (dataMedical.length >= 5) {
      return;
    }

    if (medical && medicalNote && medicalCondition) {
      setDataMedical([
        {
          key: uniqueId(Math.random()),
          medicalCondition,
          medication: medical,
          note: medicalNote,
        },
        ...dataMedical,
      ]);
    }
  };

  return (
    <div className='wrap-medical-conditions'>
      <p className='text-label'>Medical Conditions</p>
      <div className='medical-fields'>
        <FormItem label='Medical Condition'>
          <Textarea
            className='text-area-field'
            placeholder='Add Medical Issues'
            onChange={(e) => setMedicalCondition(e.target.value)}
          />
        </FormItem>
        <FormItem label='Medication'>
          <Textarea
            className='text-area-field'
            placeholder='Medication'
            onChange={(e) => setMedical(e.target.value)}
          />
        </FormItem>
        <FormItem label='Notes'>
          <Textarea
            className='text-area-field'
            placeholder='Notes'
            onChange={(e) => setMedicalNote(e.target.value)}
          />
        </FormItem>
      </div>
      <div>
        <Button className='btn-add fr yellow-button' onClick={handleAddMedical}>
          Add Medical Condition
        </Button>
      </div>
      <FormItem
        name={['vacancyApplicantAttributes', 'medicalConditionsAttributes']}
      />
      <TableMedical dataSource={dataMedical} setDataMedical={setDataMedical} />
    </div>
  );
};

export default Medical;
