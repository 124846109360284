import React, { useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Form, Pagination } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Tabs,
  FormItem,
  Input,
  Select,
  DatePicker,
  Button,
} from '@/components';
import ListView from '@/components/ListView';
import { PAGE_SIZE_OPTIONS } from '@/constants';
import { useFetch, usePagination, useQueryString } from '@/hooks';
import { useRoleItems } from '@/hooks/dropdowns';
import JobModalEdit from '@/pages/Jobs/JobModal/Edit';
import { JobServices } from '@/services';
import { disabledDateOf } from '@/utils';

import './styles.scss';
import { useColumns } from './hooks';

const ProviderJobs = () => {
  const [tabKey, setTabKey] = useState();

  const [queryString, setQueryString] = useQueryString();

  const tabPanes = [
    {
      tab: 'Filled/Posted',
      key: 'posted',
      child: <ProviderJob tabKey={tabKey} statuses={['posted']} />,
    },
    {
      tab: 'Pending/ In progress',
      key: 'live',
      child: <ProviderJob tabKey={tabKey} statuses={['live']} />,
    },
    {
      tab: 'Completed',
      key: 'completed',
      child: <ProviderJob tabKey={tabKey} statuses={['completed']} />,
    },
  ];

  const handleOnTabsChange = (key) => {
    setQueryString({ tabStatus: key });
    setTabKey(key);
  };

  return (
    <div className='provider-jobs'>
      <Tabs
        onChange={handleOnTabsChange}
        className='provider-tabs'
        tabPanes={tabPanes}
        activeKey={queryString?.tabStatus ?? 'posted'}
      />
    </div>
  );
};

const ProviderJob = ({ tabKey, statuses }) => {
  const { providerId } = useParams();
  const [jobIdEdit, setJobIdEdit] = useState('');
  const columns = useColumns();

  const [form] = Form.useForm();

  const {
    currentPage,
    paginationPageSize,
    handlePagination,
    handleShowSizeChange,
  } = usePagination();

  const { data: listRoles } = useRoleItems();

  const {
    data: jobs,
    isFetching: isFetchingJobs,
    refetch,
  } = useFetch(
    ['getJobs', currentPage, paginationPageSize, statuses],
    () => {
      const { searchTerm, roles, jobDateFrom, jobDateTo } =
        form.getFieldsValue();

      const filter = {
        statuses,
        searchTerm,
        roles,
        jobDateFrom: jobDateFrom && moment(jobDateFrom).format('YYYY-MM-DD'),
        jobDateTo: jobDateTo && moment(jobDateTo).format('YYYY-MM-DD'),
      };

      const page = {
        size: paginationPageSize,
        number: currentPage,
      };

      return JobServices.getJobsOfProvider({
        providerId,
        filter,
        page,
      });
    },
    {
      enabled: !!providerId,
    },
  );

  const handleSearchProviderJobs = () => {
    refetch();
  };

  const clearFilter = () => {
    form.resetFields();
    refetch();
  };

  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);

  const onRow = (rowId) => setJobIdEdit(rowId);

  return (
    <div className='body-container'>
      <Form
        className='inputs-container'
        form={form}
        onFinish={handleSearchProviderJobs}
      >
        <div className='search-container input-item'>
          <FormItem label='Search' name='searchTerm' className='input-search'>
            <Input
              className='input-style'
              placeholder='Name or email address'
            />
          </FormItem>
          <div className='search-icon-container input-height'>
            <SearchOutlined className='search-icon' />
          </div>
        </div>
        <FormItem className='input-item' label='Roles' name='roles'>
          <Select
            className='input-height'
            placeholder='Roles'
            options={listRoles}
          />
        </FormItem>
        <div className='date-container input-item'>
          <p className='job-date'>Job Date</p>
          <div className='date-pickers'>
            <FormItem className='date-picker-item' name='jobDateFrom'>
              <DatePicker
                className='input-height'
                placeholder='Select Date'
                name='jobDateFrom'
                disabledDate={(disabledDate) =>
                  disabledDateOf.Start(disabledDate, endDate)
                }
              />
            </FormItem>
            <FormItem className='date-picker-item' name='jobDateTo'>
              <DatePicker
                className='input-height'
                placeholder='Select Date'
                name='jobDateTo'
                disabledDate={(disabledDate) =>
                  disabledDateOf.End(disabledDate, startDate)
                }
              />
            </FormItem>
          </div>
        </div>
        <div className='buttons input-item'>
          <Button
            className='yellow-button button-styling input-height'
            htmlType='submit'
          >
            Search
          </Button>
          <Button
            className='filter-button button-styling input-height'
            htmlType='reset'
            onClick={clearFilter}
          >
            Clear Filters
          </Button>
        </div>
      </Form>
      <ListView
        className='job-completed-listview'
        infoDetail={jobs?.jobInfo ?? []}
        headerColumns={columns}
        loading={isFetchingJobs}
        locale={{
          emptyText: 'No Jobs match your search criteria',
        }}
        onRow={onRow}
      />
      <div className='table-footer-container'>
        <div className='pagination-container'>
          <Pagination
            responsive
            showSizeChanger
            current={currentPage}
            pageSize={paginationPageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            total={jobs?.totalCount ?? 0}
            onChange={handlePagination}
            onShowSizeChange={handleShowSizeChange}
          />
        </div>
      </div>
      {jobIdEdit !== '' && (
        <JobModalEdit
          hidePopup={() => setJobIdEdit('')}
          showPopup={jobIdEdit !== ''}
          jobId={jobIdEdit}
        />
      )}
    </div>
  );
};

ProviderJob.propTypes = {
  statuses: PropTypes.array,
  tabKey: PropTypes.string,
};

export default ProviderJobs;
