import { kebabCase } from 'lodash';

export const keys = {
  [kebabCase('Head Office')]: {
    key: kebabCase('Head Office'),
    color: 'yellow',
  },
  [kebabCase('Hospitality')]: {
    key: kebabCase('Hospitality'),
    color: 'red',
  },
  [kebabCase('Northern England')]: {
    key: kebabCase('Northern England'),
    color: 'green',
  },
  [kebabCase('Warehouse - London')]: {
    key: kebabCase('Warehouse - London'),
    color: 'purple',
  },
  [kebabCase('Hospitality - GIGHub')]: {
    key: kebabCase('Hospitality - GIGHub'),
    color: 'skyblue',
  },
  [kebabCase('Other')]: {
    key: kebabCase('Other'),
    color: 'yellow',
  },
  [kebabCase('Warehouse - GIGHub')]: {
    key: kebabCase('Warehouse - GIGHub'),
    color: 'blue',
  },
  [kebabCase('Scotland')]: {
    key: kebabCase('Scotland'),
    color: 'green',
  },
};

export const buttons = [
  {
    id: keys[kebabCase('Head Office')],
    content: 'Head Office',
    color: 'yellow-button',
  },
  {
    id: keys[kebabCase('Hospitality')],
    content: 'Hospitality',
    color: 'red-button',
  },
  {
    id: keys[kebabCase('Northern England')],
    content: 'Northern England',
    color: 'green-button',
  },
  {
    id: keys[kebabCase('Warehouse - London')],
    content: 'Warehouse - London',
    color: 'purple-button',
  },
  {
    id: keys[kebabCase('Hospitality - GIGHub')],
    content: 'Hospitality - GIGHub',
    color: 'skyblue-button',
  },
  {
    id: keys[kebabCase('Other')],
    content: 'Other',
    color: 'yellow-button',
  },
  {
    id: keys[kebabCase('Warehouse - GIGHub')],
    content: 'Warehouse - GIGHub',
    color: 'blue-button',
  },
  {
    id: keys[kebabCase('Scotland')],
    content: 'Scotland',
    color: 'green-button',
  },
];
