import React from 'react';

import { Form, message } from 'antd';
import { debounce } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  AutoComplete,
  Button,
  DatePicker,
  FormItem,
  Input,
  Select,
} from '@/components';
import { DEBOUNCE_WAIT, LIMIT_PHONE_NUMBER, listGenders } from '@/constants';
import { getPostCode } from '@/services/dropdownServices/postcodeAutoComplete';
import resetPassword from '@/services/seeker/resetPassword';
import { date } from '@/utils';
import {
  rulesBirth,
  rulesConfirmPassword,
  rulesEmail,
  rulesPhone,
  rulesPostcode,
  rulesSelect,
  rulesTextInput,
} from '@/utils/rulesInput';

import { marital, title } from './constants';

import './styles.scss';

const BasicInformation = () => {
  const { id } = useParams();
  const form = Form.useFormInstance();
  const { data: listPostCode, refetch: refetchPostcode } = useQuery(
    'getPostcode',
    () => {
      const postcode = form.getFieldValue('employeePostCode');

      return getPostCode({
        filter: {
          zipcode: postcode,
        },
      });
    },
    { refetchOnWindowFocus: false, enabled: false },
  );

  const { mutate: resetPasswordMutation, isLoading: isLoadingResetPassword } =
    useMutation(() => resetPassword(id), {
      onSuccess: () => message.success('Reset password successfully!'),
      onError: (error) => message.error(error?.message),
    });

  const handleSearchPostcode = debounce(refetchPostcode, DEBOUNCE_WAIT);

  const gender = Form.useWatch('employeeGender', form);

  return (
    <div className='seeker-basic-information'>
      <FormItem
        required
        label='First Name'
        name={['vacancyApplicantAttributes', 'firstname']}
        rules={rulesTextInput('First Name')}
      >
        <Input placeholder='First Name' maxLength={48} />
      </FormItem>

      <FormItem
        label='Last Name'
        name={['vacancyApplicantAttributes', 'lastname']}
        rules={rulesTextInput('Last Name')}
        required
      >
        <Input placeholder='Last Name' maxLength={48} />
      </FormItem>

      <FormItem
        required
        label='Email'
        name={['vacancyApplicantAttributes', 'email']}
        rules={rulesEmail()}
      >
        <Input placeholder='Email' />
      </FormItem>
      <FormItem
        required
        label='Phone Number'
        name={['vacancyApplicantAttributes', 'phone']}
        rules={rulesPhone()}
      >
        <Input maxLength={LIMIT_PHONE_NUMBER} placeholder='Phone Number' />
      </FormItem>
      <FormItem label='Password'>
        <Button
          className='reset-password yellow-button'
          onClick={resetPasswordMutation}
          loading={isLoadingResetPassword}
        >
          Reset Password
        </Button>
      </FormItem>

      <FormItem label='Manual Reset Password' className='manual-reset-password'>
        <div className='password-and-repeat'>
          <FormItem name={['user_attributes', 'password']}>
            <Input isPassword placeholder='Password' />
          </FormItem>
          <FormItem
            name='passwordConfirm'
            dependencies={['user_attributes', 'password']}
            rules={rulesConfirmPassword(['user_attributes', 'password'])}
          >
            <Input isPassword placeholder='Repeat Password' />
          </FormItem>
        </div>
      </FormItem>

      <FormItem
        label='Title'
        name='employeeTitle'
        required
        rules={rulesSelect('Title')}
      >
        <Select options={title} placeholder='Title' />
      </FormItem>
      <FormItem
        label='Marital Status'
        name='employeeMaritalStatus'
        required
        rules={rulesSelect('Marital Status')}
      >
        <Select options={marital} placeholder='Marital Status' />
      </FormItem>
      <FormItem
        label='Date of Birth'
        name='birth'
        required
        rules={rulesBirth}
        className='birth-form-item'
        normalize={date.convertDateTime}
      >
        <DatePicker name='birth' />
      </FormItem>
      <FormItem
        className='gender-form-item'
        name='employeeGender'
        required
        rules={rulesTextInput('Gender')}
        label='Gender at Birth (or on Documentation)'
      >
        <Select
          value={gender}
          options={listGenders}
          placeholder='Gender'
          onChange={(value) =>
            form.setFieldsValue({
              employeeGender: value,
            })
          }
        />
      </FormItem>

      <FormItem
        label='Address - Building'
        name={['vacancyApplicantAttributes', 'address']}
        rules={rulesTextInput('Address - Building')}
        required
      >
        <Input placeholder='Address - Building' />
      </FormItem>
      <FormItem
        label='Street'
        name={['vacancyApplicantAttributes', 'address2']}
        required
        rules={rulesTextInput('Street')}
      >
        <Input placeholder='Street' />
      </FormItem>
      <FormItem
        label='City'
        name={['vacancyApplicantAttributes', 'city']}
        required
        rules={rulesTextInput('City')}
      >
        <Input placeholder='City' />
      </FormItem>
      <FormItem
        label='Postcode'
        name={['vacancyApplicantAttributes', 'zip']}
        required
        rules={rulesPostcode(form, ['vacancyApplicantAttributes', 'zip'])}
      >
        <AutoComplete
          placeholder='Postcode'
          onSearch={handleSearchPostcode}
          options={listPostCode}
        />
      </FormItem>
      <FormItem
        required
        label='Registration Date'
        name={['vacancyApplicantAttributes', 'createdAt']}
      >
        <DatePicker
          name={['vacancyApplicantAttributes', 'createdAt']}
          disabled
        />
      </FormItem>
    </div>
  );
};

export default BasicInformation;
