import React from 'react';

import { Form, message, Spin } from 'antd';
import { useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';

import { Button, GeneralTemplate, ModalConfirm } from '@/components';
import { useFetch } from '@/hooks';
import { useSuppliers } from '@/hooks/dropdowns';
import { update, getOne } from '@/services/cost';
import useModal from '@/utils/useModal';

import CostDetail from '../components/CostDetail';

import '../styles.scss';

const EditCost = () => {
  const [form] = Form.useForm();
  const { costId } = useParams();
  const [displayModal, showModal, hiddenModal] = useModal();
  const [isDisplayModal, showModalCancelSave, hiddenModalCancelSave] =
    useModal();
  const history = useHistory();
  const suppliers = useSuppliers();
  const supplierId = form.getFieldValue('supplierId');
  const supplierItem = suppliers.find((item) => item.value === supplierId);
  const breadCrumb = [
    { item: 'Timesheets', to: '/timesheets' },
    { item: 'Costs', to: '/costs' },
    {
      item: supplierItem?.label ?? '',
      to: '#',
    }, // TODO: update later
  ];

  const { isFetching } = useFetch(
    ['costDetail', costId],
    () => getOne(costId),
    {
      onSuccess: (dt) => {
        form.setFieldsValue(dt);
      },
    },
  );

  const {
    mutate: editCost,
    isLoading,
    isSuccess,
  } = useMutation(
    () => {
      const costDetail = form.getFieldsValue();
      return update(costId, costDetail);
    },
    {
      onError: () => message.error('Can not find suitable timesheet'),
    },
  );
  const handleSubmit = () => {
    editCost();
  };
  const handleGoBack = () => history.push('/costs');

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadCrumb}
      headerText={costId ? 'Costs' : 'Create New Cost'}
      buttonContent='Create Cost'
      buttonLink='/create-cost'
      subTemplateClassName='cost-container'
    >
      <Form form={form} onFinish={showModal} className='form-cost'>
        {isFetching ? <Spin /> : <CostDetail />}

        <div className='actions'>
          <Button
            className='black-button input-height'
            onClick={showModalCancelSave}
          >
            Cancel
          </Button>
          <Button className='yellow-button input-height' htmlType='submit'>
            Allocate Cost
          </Button>
        </div>
      </Form>
      <ModalConfirm
        title='Edit Cost?'
        titleSuccess='Cost edited successfully'
        visible={displayModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleGoBack : handleSubmit}
        onClickNo={hiddenModal}
      >
        Are you sure you want to edit supplier?
      </ModalConfirm>

      <ModalConfirm
        title="Don't Want to Save Updates?"
        visible={isDisplayModal}
        onClickYes={handleGoBack}
        onClickNo={hiddenModalCancelSave}
      >
        Are you sure you want to leave without saving? You will lost any uploads
        and changes you have made.
      </ModalConfirm>
    </GeneralTemplate>
  );
};

export default EditCost;
