import React from 'react';

import Comments from './comment';
import InternalCode from './internalCode';

import './styles.scss';

const CommentAndStatusUpdate = () => (
  <div className='comment-status-candidate'>
    <div className='comment-container'>
      <Comments />
    </div>
    <div>
      <InternalCode />
    </div>
  </div>
);
export default CommentAndStatusUpdate;
