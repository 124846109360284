import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getEmployeesSeeker = async (name) => {
  const endpoint = '/seekers/employees';

  const res = await request.get(endpoint, {
    params: {
      filter: {
        searchTerm: name,
      },
    },
  });

  const { data = [] } = normalizeIndexResponse(res);

  return data;
};

export default getEmployeesSeeker;
