import React from 'react';

import PropTypes from 'prop-types';
import { Calendar } from 'react-big-calendar';
import { useWindowSize } from 'usehooks-ts';

import './styles.scss';

/**
 * @typedef {Object} CustomCalendarProps
 * @property {import('react-big-calendar').Components} customComponents
 */

/**
 *
 * @param {import('react-big-calendar').CalendarProps & CustomCalendarProps} CustomCalendarProps
 */
const CustomCalendar = ({ customComponents, ...props }) => {
  const { height = 0 } = useWindowSize();
  return (
    <div style={{ height }}>
      <Calendar components={customComponents} {...props} />
    </div>
  );
};

export default CustomCalendar;

CustomCalendar.propTypes = {
  customComponents: PropTypes.object,
};

CustomCalendar.defaultProps = {
  customComponents: {},
};
