const eventWrapperGetter = (event, start, end, isSelected) => {
  const style = {
    paddingRight: '100px',
    backgroundColor: '#fff', // add horizontal spacing to the right
    width: '30px',
  };
  return {
    style,
  };
};

const events = [
  {
    start: new Date('2023-05-01T10:00:00'),
    end: new Date('2023-05-01T12:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-04T10:00:00'),
    end: new Date('2023-05-04T18:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-04T06:00:00'),
    end: new Date('2023-05-04T09:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-04T00:00:00'),
    end: new Date('2023-05-04T05:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-04T14:00:00'),
    end: new Date('2023-05-04T21:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-10T00:00:00'),
    end: new Date('2023-05-10T06:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-10T00:00:00'),
    end: new Date('2023-05-10T06:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-10T00:00:00'),
    end: new Date('2023-05-10T03:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-10T00:00:00'),
    end: new Date('2023-05-10T04:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-10T00:00:00'),
    end: new Date('2023-05-10T06:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-14T00:00:00'),
    end: new Date('2023-05-14T03:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-15T00:00:00'),
    end: new Date('2023-05-15T06:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-15T06:00:00'),
    end: new Date('2023-05-15T14:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-15T06:00:00'),
    end: new Date('2023-05-15T14:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-17T00:00:00'),
    end: new Date('2023-05-17T06:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-17T00:00:00'),
    end: new Date('2023-05-17T14:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-17T00:00:00'),
    end: new Date('2023-05-17T14:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-25T00:00:00'),
    end: new Date('2023-05-25T06:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-26T00:00:00'),
    end: new Date('2023-05-26T06:00:00'),
    contracted: 15,
  },
  {
    start: new Date('2023-05-30T00:00:00'),
    end: new Date('2023-05-30T06:00:00'),
    contracted: 15,
  },
];

export { eventWrapperGetter, events };
