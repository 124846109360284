/* eslint-disable no-underscore-dangle */
import { camelizeKeys } from 'humps';
import _ from 'lodash';
import moment from 'moment';

import request from '@/request';

// Parse time to moment()
const parseCheckInCheckOutTimeCards = (/** @type {Array} */ timecards) =>
  _.map(timecards, (timecard) =>
    _.cloneDeepWith(timecard, (value) => {
      if (_.isObject(value) && 'checkin' in value && 'checkout' in value) {
        return {
          ...value,
          checkin: moment(value.checkin),
          checkout: moment(value.checkout),
        };
      }
      return undefined;
    }),
  );

const groupJobs = (timesheetDetails) => {
  const groupedStatus = _.groupBy(
    timesheetDetails,
    (timesheet) => timesheet.attributes.status,
  );
  const groupedSkill = _.mapValues(groupedStatus, (statusItem) =>
    _.groupBy(statusItem, (timesheet) => timesheet.attributes.skill.id),
  );

  const groupedTimesheetDetail = _.mapValues(groupedSkill, (timesheet) =>
    _.mapValues(timesheet, (timesheetInfo) => {
      const groupedTimecardInfos = _.map(
        timesheetInfo,
        (timesheetDetail, index) => {
          const { timesheetDetailId } = timesheetDetail.attributes;
          const key = `${timesheetDetailId}-${index}`;
          const momentTimecards = parseCheckInCheckOutTimeCards(
            timesheetDetail.attributes.timecards.data,
          );
          const groupedTimecards = _.keyBy(momentTimecards, (timecard) =>
            moment(timecard.attributes.schedule.checkin).format('ddd'),
          );
          return {
            ...timesheetDetail.attributes,
            key,
            timecards: groupedTimecards,
          };
        },
      );

      return _.keyBy(groupedTimecardInfos, 'key');
    }),
  );
  return groupedTimesheetDetail;
};

const separateJobs = (timesheetDetails) =>
  timesheetDetails.reduce(
    (total, timesheet) => {
      if (timesheet?.attributes?.pushFromTimesheetId) {
        total.pushed.push(timesheet);
        return total;
      }
      total.current.push(timesheet);
      return total;
    },
    {
      pushed: [],
      current: [],
    },
  );

const getTimesheetDetail = async (/** @type {string|number} */ id) => {
  const res = await request.get(`trackers/timekeeping/timesheets/${id}`);
  const { data } = camelizeKeys(res.data) ?? {};
  const {
    attributes: { timesheetDetails, timesheetDetailsPushed },
  } = data;

  const separatedJobs = separateJobs(timesheetDetails.data);
  const groupedTimesheetDetail = groupJobs(separatedJobs?.current ?? []);
  const groupedTimesheetCurrentPushed = groupJobs(separatedJobs?.pushed ?? []);
  const groupedTimesheetPushed = groupJobs(timesheetDetailsPushed?.data ?? []);

  return {
    data: data.attributes,
    timesheetDetails: groupedTimesheetDetail,
    timesheetCurrPushed: groupedTimesheetCurrentPushed,
    timesheetPushed: groupedTimesheetPushed,
  };
};

export default getTimesheetDetail;
