import request from '@/request';

export const fetchTimesheetDetailWeeks = async (
  /** @type {string | number} */ id,
) => {
  const res = await request.get('options/timesheet_detail_weeks', {
    params: {
      timesheetId: id,
    },
  });

  const result = res?.data?.data.map((/** @type {string | number} */ item) => ({
    value: item,
    label: item,
  }));

  return result || [];
};
