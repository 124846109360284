/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Input } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

const Textarea = ({ className, ...props }) => (
  <Input.TextArea
    className={classNames('primary-textarea', className)}
    maxLength={255}
    {...props}
  />
);

Textarea.propTypes = {
  className: PropTypes.string,
};

Textarea.defaultProps = {
  className: '',
};

export default Textarea;
