import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@/components';

import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const Registration = ({ nextStep, initialValues, ...props }) => {
  const { isCandidate } = initialValues;
  const tenant = useTenantContext();

  return (
    <div
      className={classNames('registration-container', {
        padding50: isCandidate,
      })}
    >
      <div className={classNames('title', { small: isCandidate })}>
        {isCandidate
          ? 'You have previously submitted your candidate form, If you have not done so already please book your interview.'
          : 'New Registration'}
      </div>

      <div className='confirm-btn-container'>
        {isCandidate ? (
          tenant.contact.interviewUrl ? (
            <Button className='shared-button confirm-button registration-btn'>
              <a
                className='book-interview'
                href={tenant.contact.interviewUrl}
                target='_blank'
                rel='noreferrer'
              >
                Book Interview
              </a>
            </Button>
          ) : (
            <h2>One of the team will be in touch to book an interview.</h2>
          )
        ) : (
          <Button
            onClick={nextStep}
            className='shared-button confirm-button registration-btn'
          >
            Start new application
          </Button>
        )}
      </div>

      <div className={classNames('title', { small: isCandidate })}>
        {isCandidate &&
          'You will only be able to access the Staff Hive App once you have completed a successful interview.'}
      </div>
    </div>
  );
};

Registration.propTypes = {
  nextStep: PropTypes.func,
  initialValues: PropTypes.object,
};
export default Registration;
