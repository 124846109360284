import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { keys } from '../constants';
import './styles.scss';

const RequestCard = ({
  type,
  name,
  title,
  endDate,
  startDate,
  border,
  background,
}) => (
  <div
    className={classNames(
      'request-card',
      {
        [`card-bg-${keys[`${type}`].color}`]: background,
      },
      {
        [`card-border-${keys[`${type}`].color}`]: border,
      },
    )}
  >
    {title && <p className='capital'>{title}</p>}
    {name && <p>{name}</p>}
    {startDate && endDate && (
      <p>
        {startDate} - {endDate}
      </p>
    )}
  </div>
);

RequestCard.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  background: PropTypes.bool,
  border: PropTypes.bool,
};

export default RequestCard;
