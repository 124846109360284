import React from 'react';

import { useTenantContext } from '../../../../../TenantWrapper';

const Footer = () => {
  const { invoiceInfo = {} } = useTenantContext();
  const { payment = {}, bank = {}, contact = {} } = invoiceInfo;
  const { accountHolderName, sortCode, paymentTerm } = payment;
  const { phoneNumber } = contact;
  const { name, accountNumber } = bank;

  return (
    <div className='preview-invoice-footer'>
      <div className='text-above'>
        <p className='text'>
          Our Standard Terms are Payment within {paymentTerm} Days by BACS. Our
          Bank details are as follows:
        </p>
        <p className='text'>
          A/C Name: {accountHolderName}. Bank: {name}. Bank Account No.:{' '}
          {accountNumber} Sort Code : {sortCode}
        </p>
      </div>
      <div className='text-below'>
        <p className='text'>
          Any Changes To Bank Details Will Be Confirmed In Writting Via Post And
          E-mail And
        </p>
        <p className='text'>
          Need To Be VerufuedVerbally By Calling Accounts On {phoneNumber} And
          Writting To
        </p>
        <p className='text'>
          The Above Address. Subject To Our StandardTerms And Conditions
        </p>
      </div>
    </div>
  );
};

export default Footer;
