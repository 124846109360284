import React, { useState } from 'react';

import { Form } from 'antd';
import { useParams } from 'react-router-dom';

import { Select, FormItem, Textarea, ModalConfirm } from '@/components';
import { useStatus } from '@/hooks/dropdowns';
import { switchStatus } from '@/utils';

const InternalCode = () => {
  const { lisCandidateStatuses } = useStatus();
  const params = useParams();
  const form = Form.useFormInstance();
  const initCandidateStatus = Form.useWatch('initCandidateStatus', form);

  const [visibleStatusModal, setVisibleStatusModal] = useState({
    title: '',
    content: '',
    visible: false,
  });

  const { visible, title, content } = visibleStatusModal;

  const handleChangeStatus = (value) => {
    const status = switchStatus.switchCandidateStatus(value);

    setVisibleStatusModal({
      visible: true,
      title: status.title,
      content: status.content,
    });
  };

  return (
    <div>
      <ModalConfirm
        visible={visible}
        title={title}
        onClickNo={() => {
          setVisibleStatusModal({ visible: false });
          form.setFieldsValue({ candidateStatus: undefined });
        }}
        onClickYes={() => setVisibleStatusModal({ visible: false })}
      >
        {content}
      </ModalConfirm>
      <FormItem label='Internal note from Application' name='internalNote'>
        <Textarea rows={3} placeholder='Note' disabled={!!params?.id} />
      </FormItem>
      <FormItem name='initCandidateStatus' />
      <div className='status'>
        <div className='status-content'>
          <div className='status-fields'>
            <FormItem label='Status' name='candidateStatus'>
              <Select
                placeholder='Select Status'
                options={lisCandidateStatuses}
                onChange={handleChangeStatus}
                disabled={
                  initCandidateStatus === 0 || initCandidateStatus === 1
                }
                required
              />
            </FormItem>
          </div>
          <div className='status-note'>
            <p>Note: You must add a comment to action a profile.</p>
            <p>
              If a profile is Blocked choose the reason but also add comment for
              more information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalCode;
