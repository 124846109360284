import React, { useEffect, useState } from 'react';

import { Divider, Button, Checkbox, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DatePicker, Select, Textarea, Input, FormItem } from '@/components';
import { useVacancyApplicantEmploymentPosition } from '@/hooks/dropdowns';
import { date as dateUtils } from '@/utils';
import { extendRole } from '@/utils/roles';

const ExperienceDetail = ({
  onDelete,
  fieldName,
  length,
  index,
  isStillEmployed,
}) => {
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const form = Form.useFormInstance();
  const listRoles = useVacancyApplicantEmploymentPosition();
  const listExtendRoles = extendRole(
    form.getFieldValue([
      'vacancyApplicantEmploymentsAttributes',
      index,
      'positionId',
    ]),
    listRoles,
  );
  const formInstance = Form.useFormInstance();

  const disabledStartDate = (disabledDates) =>
    disabledDates > moment().subtract(1, 'day');
  const disabledEndDate = (disabledDates) => {
    if (!startDate) return disabledDates > moment().subtract(1, 'day');

    return (
      disabledDates < startDate || disabledDates > moment().subtract(1, 'day')
    );
  };

  useEffect(() => {
    if (isStillEmployed) {
      formInstance.setFields([
        {
          name: ['vacancyApplicantEmploymentsAttributes', fieldName, 'startAt'],
          errors: [],
        },
        {
          name: [
            'vacancyApplicantEmploymentsAttributes',
            fieldName,
            'finishAt',
          ],
          errors: [],
        },
      ]);
    }
  }, [isStillEmployed, fieldName, index, formInstance]);

  const handleChangeYear = (field, value) => {
    field === 'startAt' ? setStartDate(value) : setEndDate(value);
    formInstance.setFields([
      {
        name: ['vacancyApplicantEmploymentsAttributes', index, field],
        value,
      },
    ]);
  };

  return (
    <div className='seeker-experience-detail'>
      {length > 1 && fieldName !== 0 && (
        <Button className='close-icon' onClick={() => onDelete(fieldName)}>
          &#215;
        </Button>
      )}
      <div className='history'>
        {/* <FormItem name={[fieldName, 'applicantId']} noStyle /> */}
        <FormItem name={[fieldName, '_destroy']} noStyle />
        <FormItem name={[fieldName, 'pos']} initialValue='' noStyle />
        <FormItem name={[fieldName, 'town']} initialValue='' noStyle />
        <FormItem name={[fieldName, 'country']} initialValue='' noStyle />
        <FormItem label='Role' name={[fieldName, 'positionId']}>
          <Select options={listExtendRoles} placeholder='Role' />
        </FormItem>
        <FormItem label='Company' name={[fieldName, 'name']} initialValue=''>
          <Input placeholder='Company' />
        </FormItem>
        <FormItem
          className='half-width'
          label='Start Date:'
          name={[fieldName, 'startAt']}
          normalize={dateUtils.convertDateTime}
        >
          <DatePicker
            value={startDate}
            disabled={isStillEmployed}
            name={[fieldName, 'startAt']}
            disabledDate={disabledStartDate}
            onChange={(date) => setStartDate(date)}
            handleChangeYear={(time) => handleChangeYear('startAt', time)}
          />
        </FormItem>
        <FormItem
          className='half-width'
          label='End Date:'
          name={[fieldName, 'finishAt']}
          normalize={dateUtils.convertDateTime}
        >
          <DatePicker
            value={endDate}
            disabled={isStillEmployed}
            name={[fieldName, 'finishAt']}
            disabledDate={disabledEndDate}
            onChange={(date) => setEndDate(date)}
            handleChangeYear={(time) => handleChangeYear('finishAt', time)}
          />
        </FormItem>
        <div className='align-end'>
          <p className='text-label'>Overview of responsibilities</p>
        </div>
        <FormItem name={[fieldName, 'stillEmployed']} valuePropName='checked'>
          <Checkbox className='still-employed'>Still Employed</Checkbox>
        </FormItem>
        <FormItem
          className='span-col-2'
          name={[fieldName, 'leave']}
          initialValue=''
        >
          <Textarea placeholder='Overview of responsibilities' />
        </FormItem>
      </div>
      <Divider />
    </div>
  );
};

export default ExperienceDetail;

ExperienceDetail.propTypes = {
  onDelete: PropTypes.func,
  fieldName: PropTypes.number,
  length: PropTypes.number,
  index: PropTypes.number,
  isStillEmployed: PropTypes.bool,
};

ExperienceDetail.defaultProps = {
  onDelete: () => {},
  length: 0,
  isStillEmployed: false,
};
