import React from 'react';

import { Row, Form, Card, Input, Col, Space, message, Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import QueryString from 'qs';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  FormItem,
  GeneralTemplate,
  Select,
  Table,
  Tabs,
} from '@/components';
import {
  useProviderLocations,
  useProviders,
  useRoleItems,
} from '@/hooks/dropdowns';
import { createRota } from '@/services/rota';
import { rulesSelect } from '@/utils/rulesInput';
import { formatISOString } from '@/utils/time';

import { breadcrumb } from '../constants';
import InputDropdown from '../CreateNewRota/InputForm';

import './styles.scss';

const style = {
  borderRadius: '25px',
  border: '2px solid #bebebe',
  marginBottom: '10px',
  width: '140px',
  textAlign: 'center',
  color: 'black',
};

const RotaTable = ({
  columns,
  dataSource,
  headerText,
  createButton,
  cancelButton,
  allocateButton,
  setStartTime,
  setEndTime,
  startTime,
  endTime,
}) => {
  const [form] = Form.useForm();

  const listProviders = useProviders();
  const { data: positions } = useRoleItems();
  const history = useHistory();

  const location = Form.useWatch('location', form);
  const positionsValue = Form.useWatch('positions', form);
  const provider = Form.useWatch('provider', form);
  const { data: locations } = useProviderLocations(provider);

  const tabPanes = [
    {
      key: 'worker-rota',
      tab: 'Worker Rota',
      forceRender: true,
    },
    {
      key: 'build-rota',
      tab: 'Build Rota',
      forceRender: true,
    },
    {
      key: 'live-rota',
      tab: 'Live Rota',
      forceRender: true,
    },
  ];

  const { mutate: createRotaMutate, isLoading } = useMutation(
    (value) => createRota(value),
    {
      onSuccess: (data) => {
        const queryString = QueryString.stringify({
          location,
          provider,
          positions: positionsValue,
        });

        message.success('Create Rota successfully');
        history.push(`/rota?${queryString}`);
      },
    },
  );

  const handleSubmit = async (value) => {
    const { rotaWeeksAttributes = [], ...rest } = value;
    const transformedValues = rotaWeeksAttributes.map((el) => {
      const { pattern, numberTargetRotas, rotaShiftsAttributes } = el;
      const transformedRotaShift = rotaShiftsAttributes.map((item, index) => {
        const { dataSelect = '' } = item;

        if (dataSelect === 'off') {
          return {
            startTime: '',
            endTime: '',
            weekDay: index,
            status: dataSelect,
          };
        }

        const times = dataSelect.split(' - ');

        return {
          startTime: formatISOString(times[0]),
          endTime: formatISOString(times[1]),
          weekDay: index,
        };
      });

      return {
        pattern,
        numberTargetRotas: Number(numberTargetRotas || 0),
        rotaShiftsAttributes: transformedRotaShift,
      };
    });

    await createRotaMutate({
      rotaWeeksAttributes: transformedValues,
      startTime: formatISOString(startTime),
      endTime: formatISOString(endTime),
      ...rest,
    });
  };
  const onChangeTab = (key) => {
    if (key === 'build-rota') return history.push('/rota?tab=build-rota');
    if (key === 'live-rota') return history.push('/rota?tab=live-rota');

    return null;
  };

  const state = useLocation();
  const tabKey = state?.tabKey;

  return (
    <GeneralTemplate
      mainItem='Rota'
      data={breadcrumb}
      buttonContent='Post New Shift'
      headerText={headerText}
      buttonLink='/new-shift'
    >
      <Tabs
        className='job-tabs'
        tabPanes={tabPanes}
        defaultActiveKey={tabKey}
        onChange={onChangeTab}
      />
      <Modal className='loading-modal' footer={null} visible={isLoading}>
        <Spin />
      </Modal>
      <Form
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
        form={form}
        onFinish={handleSubmit}
      >
        <div className='job-list-view'>
          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: 10,
              marginLeft: '30px',
            }}
          >
            <div style={{ display: 'flex', gap: 10, width: '50%' }}>
              <FormItem
                required
                rules={rulesSelect('Provider')}
                name='provider'
                className='input-item'
                label='Provider'
              >
                <Select
                  options={listProviders}
                  className='input-height'
                  placeholder='provider'
                  name='provider'
                />
              </FormItem>
              <FormItem
                required
                rules={rulesSelect('Location')}
                name='location'
                className='input-item'
                label='Location'
              >
                <Select
                  options={locations}
                  className='input-height'
                  placeholder='location'
                  name='location'
                />
              </FormItem>
              <FormItem
                required
                rules={rulesSelect('Positions')}
                name='positions'
                className='input-item'
                label='Positions'
              >
                <Select
                  className='input-height'
                  placeholder='Positions'
                  options={positions}
                  name='positions'
                />
              </FormItem>
            </div>
            <InputDropdown
              setStartTime={setStartTime}
              setEndTime={setEndTime}
            />
          </div>
        </div>
        <Card className='rota-list-card'>
          <Table
            bordered
            className='create-rota-table'
            infoDetail={dataSource}
            columns={columns}
            pagination={false}
            style={{ columnGap: '40px' }}
          />
          <Row justify='end' align='middle'>
            <Col>
              <Space size={16}>
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
                <Input disabled value={0} style={style} />
              </Space>
            </Col>
          </Row>
          <Row justify='end' align='middle'>
            <Col>
              <Space size={16}>
                <h3>Total Contacted Hours</h3>
                <Input disabled value={0} style={style} />
                {allocateButton === '' ? (
                  ''
                ) : (
                  <Button className='yellow-button'>{allocateButton}</Button>
                )}
              </Space>
            </Col>
          </Row>
          <Row justify='end' align='middle'>
            <Col>
              <Space size={16}>
                <Button className='black-button'>{cancelButton}</Button>
                <Button htmlType='submit' className='yellow-button'>
                  {createButton}
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </Form>
    </GeneralTemplate>
  );
};
RotaTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  headerText: PropTypes.string,
  createButton: PropTypes.string,
  cancelButton: PropTypes.string,
  allocateButton: PropTypes.string,
  setStartTime: PropTypes.func,
  setEndTime: PropTypes.func,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

RotaTable.defaultProps = {
  columns: [],
  dataSource: [],
  headerText: '',
  createButton: '',
  cancelButton: '',
  allocateButton: '',
  setStartTime: () => {},
  setEndTime: () => {},
};

export default RotaTable;
