import { useQuery } from 'react-query';

import { getRoleItems } from '@/services/dropdownServices/getRoleItems';

const useSkillNoLimitPayrate = () =>
  useQuery(
    ['SkillNoLimitPayrate'],
    () => getRoleItems({ limitPayRate: false }),
    {
      refetchOnWindowFocus: false,
    },
  );

export default useSkillNoLimitPayrate;
