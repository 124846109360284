import React, { useState } from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { FormItem } from '@/components';
import ModalJobSeeker from '@/components/JobSeeker/ModalJobSeeker';

import SeekerItem from './component/SeekerItem';
import { JOB_RESPONSE_STATUS, TYPE_SEEKER_ITEM, TYPE_SHIFTS } from './constant';

const Seeker = ({ disabled }) => {
  const [seekerId, setSeekerId] = useState();
  const [prevShiftType, setPrevShiftType] = useState();

  const formInstance = Form.useFormInstance();

  const blockedSeekers = Form.useWatch('blockedSeekers', formInstance) ?? [];
  const workerPool = Form.useWatch('workerPool', formInstance) ?? [];
  const shiftType = Form.useWatch('shiftType', formInstance);

  const seekersIndividualShifts =
    Form.useWatch('seekersIndividualShifts', formInstance) ?? [];

  const seekersGroupShifts =
    Form.useWatch('seekersGroupShifts', formInstance) ?? [];

  if (prevShiftType !== shiftType) {
    setPrevShiftType(shiftType);
    formInstance.setFields([
      {
        name: 'jobResponsesAttributes',
        value:
          shiftType === TYPE_SHIFTS.INDIVIDUAL
            ? seekersIndividualShifts
            : seekersGroupShifts,
      },
    ]);
  }

  const jobResponsesAttributes =
    Form.useWatch('jobResponsesAttributes', formInstance) ?? [];

  const addSeekers =
    jobResponsesAttributes.filter(
      (seeker) =>
        seeker?.status === undefined ||
        seeker.status === JOB_RESPONSE_STATUS.ACCEPTED,
    ) ?? [];

  const nudgeSeekers = Form.useWatch('nudgesAttributes', formInstance) ?? [];

  const schedule = Form.useWatch(['schedule', 'items'], formInstance) ?? [];

  const individualCondition =
    shiftType === TYPE_SHIFTS.INDIVIDUAL && schedule.length > 1;

  const onChangeAddSeeker = (seekers) => {
    formInstance.setFieldsValue({
      jobResponsesAttributes: seekers,
      ...(shiftType === TYPE_SHIFTS.GROUP && { seekersGroupShifts: seekers }),
      ...(shiftType === TYPE_SHIFTS.INDIVIDUAL && {
        seekersIndividualShifts: seekers,
      }),
    });
  };

  const onChangeNudgeSeeker = (seekers) => {
    formInstance.setFieldsValue({
      nudgesAttributes: seekers,
    });
  };

  const openModalSeekerInfo = (value) => setSeekerId(value);

  return (
    <div className='seeker'>
      <FormItem name='initJobAttributes' noStyle />
      <FormItem name='initNudgesAttributes' noStyle />
      <FormItem name='jobResponsesAttributes' noStyle />
      <FormItem name='nudgesAttributes' noStyle />
      <FormItem name='seekersPayTemplates' noStyle />
      <FormItem name='seekersGroupShifts' noStyle />
      <FormItem name='seekersIndividualShifts' noStyle />
      <div className='border-bottom'>
        <SeekerItem
          title='Add Seeker'
          onChange={onChangeAddSeeker}
          listSeekerSelected={addSeekers}
          removeSeekersList={nudgeSeekers}
          type={TYPE_SEEKER_ITEM.ADD}
          disabled={disabled || individualCondition}
          openModalSeekerInfo={openModalSeekerInfo}
          blockedSeekers={blockedSeekers}
          workerPool={workerPool}
        />
      </div>

      <SeekerItem
        title='Nudge Seeker'
        onChange={onChangeNudgeSeeker}
        listSeekerSelected={nudgeSeekers}
        removeSeekersList={addSeekers}
        type={TYPE_SEEKER_ITEM.NUDGE}
        disabled={disabled}
        openModalSeekerInfo={openModalSeekerInfo}
        blockedSeekers={blockedSeekers}
        workerPool={workerPool}
      />
      {seekerId && (
        <ModalJobSeeker
          visibleModal={seekerId}
          setVisibleModal={setSeekerId}
          seekerId={seekerId}
        />
      )}
    </div>
  );
};

Seeker.propTypes = {
  disabled: PropTypes.bool,
};

Seeker.defaultProps = {
  disabled: false,
};

export default Seeker;
