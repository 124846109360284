import React from 'react';

import { FormHeader } from '@/components';
import FormLogo from '@/components/FormLogo';
import GuestFormCard from '@/components/GuestFormCard';

import './styles.scss';
import { useTenantContext } from '../../TenantWrapper';

const ThankPageLeadForm = () => {
  const tenant = useTenantContext();

  return (
    <div className='thank-page-container'>
      <FormHeader />
      <FormLogo />
      <GuestFormCard>
        <div className='form-content-container'>
          <h2 className='title'>Thank you for Submitting</h2>
          <p className='sub-title'>
            Thank you for showing interest in working with {tenant.name}. You
            should hear from the team within 5 working days. Please note, if you
            are looking for a job and not a company looking for staff, you will
            not receive a response via this form, please go to the Jobs page and
            apply there.
          </p>
        </div>
      </GuestFormCard>
    </div>
  );
};

export default ThankPageLeadForm;
