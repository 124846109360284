import React from 'react';

import { FormItem, Select } from '@/components';
import { useSkillTypes } from '@/hooks/dropdowns';

const SpecificRoleApplied = () => {
  const listRoles = useSkillTypes();

  return (
    <div className='specific-role-applied'>
      <FormItem
        label='Specific Role Applied for'
        className='protection-other-roles'
        name='generalRole'
      >
        <Select options={listRoles} placeholder='Specific Role Applied for' />
      </FormItem>
      <p className='cap'>Confirmed from the website</p>
    </div>
  );
};

export default SpecificRoleApplied;
