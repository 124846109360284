import request from '@/request';

const updateRotaShiftPlan = async ({ id, values }) => {
  const endpoint = `/rotas/rota_shift_plans/${id}`;

  await request.patch(endpoint, {
    rotaShiftPlans: {
      ...values,
      startTime: values.startTime.toISOString(),
      endTime: values.endTime.toISOString(),
    },
  });
};

export default updateRotaShiftPlan;
