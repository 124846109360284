const usersGIGProvider = [];

for (let i = 0; i < 16; i += 1) {
  usersGIGProvider.push({
    name: 'Jeremiah Richard',
    company: 'GIG',
    accessLevel: 'Super Admin',
    providers:
      'Wincanton Co-op; Wincanton for Greenford BOND; Wincanton for Greenford CFC; Wincanton for Greenford CFC;',
    lastAction: { date: '14/06/2022', time: '11:00 - 23:00' },
  });
}

export { usersGIGProvider };
