/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';

import { upperFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import ListView from '@/components/ListView';
import { DAYS_OF_WEEK } from '@/pages/Timesheet/Invoice/constants';
import { time } from '@/utils';
import Formatter from '@/utils/Formatter';
import { formatMinutes } from '@/utils/time';

import './styles.scss';

const convertToFormatHour = (value) => {
  const hours = Math.floor(value);

  const minutes = Math.round((value - hours) * 60);

  const formattedTime = `${hours}.${formatMinutes(minutes)}`;
  return formattedTime;
};

const WeeklyTotals = ({
  tableColor,
  infoDetail,
  totalWeeklyHours,
  totalCharge,
  weeklyTotal,
  dateRange,
}) => {
  const renderDay = () =>
    dateRange.map((day, index) => {
      const parsedDate = moment(day, 'DD/MM/YYYY');
      const formattedDayOfWeek = parsedDate.format('ddd').toLowerCase();
      const dataIndex = DAYS_OF_WEEK[formattedDayOfWeek];
      return {
        title: (
          <div>
            <div>{upperFirst(formattedDayOfWeek)}</div>
            <div>{parsedDate.format('DD/MM/YYYY')}</div>
          </div>
        ),
        dataIndex,
        width: '6.4',
        render: (cellData) => <span>{convertToFormatHour(cellData)}</span>,
      };
    });

  const columns = [
    {
      title: (
        <div>
          <p>Weekly Totals</p>
        </div>
      ),
      dataIndex: 'jobType',
      width: '15%',
    },
    ...renderDay(),
    {
      title: 'Charge Per hour',
      dataIndex: 'chargeRate',
      width: '6.4',
      render: (cellData) => (
        <span className='total-charge'>{Formatter.currencyGBP(cellData)}</span>
      ),
    },
    {
      title: 'Total Weekly hours',
      dataIndex: 'totalWeeklyHours',
      width: '6.4',
      render: (cellData) => <span>{convertToFormatHour(cellData)}</span>,
    },
    {
      title: 'Total Charge',
      dataIndex: 'totalCharge',
      width: '6.4',
      render: (cellData) => (
        <span className='total-charge'>{Formatter.currencyGBP(cellData)}</span>
      ),
    },
  ];

  const TableButton = () =>
    useMemo(
      () => (
        <div className='table-footer-timesheets'>
          <div className='job-type'>Weekly Totals: {weeklyTotal}</div>
          <div className='total-pay'>
            Total Hours: {time.convertTimeHoursCell(totalWeeklyHours ?? 0)}
          </div>
          <div className='total-charge'>
            Total Charge {Formatter.currencyGBP(totalCharge) ?? 0}
          </div>
        </div>
      ),
      [totalWeeklyHours, totalCharge],
    );

  return (
    <div className='weekly-totals'>
      <ListView
        bordered
        headerColumns={columns}
        infoDetail={infoDetail}
        className={`weekly-total-table ${tableColor}`}
        pagination={false}
        footer={TableButton}
      />
    </div>
  );
};

WeeklyTotals.propTypes = {
  infoDetail: PropTypes.array,
  tableColor: PropTypes.string,
  totalWeeklyHours: PropTypes.number,
  totalCharge: PropTypes.number,
  weeklyTotal: PropTypes.number,
  dateRange: PropTypes.array,
};

WeeklyTotals.defaultProps = {
  infoDetail: [],
  tableColor: '',
  weeklyTotal: 0,
  dateRange: [],
};

export default WeeklyTotals;
