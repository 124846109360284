/**
 * An array of objects containing the label and fieldName for different types of contacts.
 *
 * @type {Array<Object>}
 * @property {string} label - The label of the contact type.
 * @property {string} fieldName - The field name of the contact type.
 */
export const contactTypes = [
  {
    label: 'Main Contacts',
    fieldName: 'mainContacts',
  },
  {
    label: 'Booking Contacts',
    fieldName: 'contactForBookings',
  },
  {
    label: 'Timesheet Contacts',
    fieldName: 'contactForTimesheets',
  },
  {
    label: 'Accounts Contacts',
    fieldName: 'contactForAccounts',
  },
  {
    label: 'Invoices Contacts',
    fieldName: 'contactForInvoices',
  },
];

export const EXCEPTION_MESSAGE = {
  missFields: 'Missed necessary information.',
  invalidValues: 'Please provide valid values.',
  blank: 'Contact information can not be blank.',
};
