import { isEmpty } from 'lodash';

import { ACTIONS } from '@/pages/Timesheet/constants';

export const useButtons = ({
  isEdit,
  isCurrentWeekPushed,
  isDisabledButton,
  toggleConfirm,
  setIsEdit,
  setActionKey,
  toggleSickNoShow,
  formInstance,
  scrollToFirstError,
  isPushed,
  timecardIdsSickNoShow,
  isDisabledSickNoShowButton,
  isDisabledPullBackButton,
}) => ({
  edit: {
    label: isEdit ? 'Cancel' : 'Edit',
    hidden: isCurrentWeekPushed,
    className: 'black-button button-edit',
    onClick: () => setIsEdit(!isEdit),
  },
  sick: {
    label: 'Sick',
    className: 'green-button',
    hidden: !isEdit,
    disabled: isDisabledButton || isDisabledSickNoShowButton,
    onClick: () => {
      setActionKey(ACTIONS.sick.key);
      toggleSickNoShow();
    },
  },
  noShow: {
    label: 'No Show',
    className: 'blue-button',
    hidden: !isEdit,
    disabled: isDisabledButton || isDisabledSickNoShowButton,
    onClick: () => {
      setActionKey(ACTIONS.noShow.key);
      toggleSickNoShow();
    },
  },
  deleteHours: {
    label: 'Delete Hours',
    className: 'red-button',
    hidden: !isEdit,
    disabled: isDisabledButton,
    onClick: () => {
      setActionKey(ACTIONS.deleteHours.key);
      toggleConfirm();
    },
  },
  approveHours: {
    label: 'Approve Hours',
    className: 'yellow-button',
    disabled: isDisabledButton,
    hidden: isCurrentWeekPushed,
    onClick: () => {
      setActionKey(ACTIONS.approveHours.key);
      toggleConfirm();
    },
  },
  confirmHours: {
    label: 'Confirm Hours',
    className: 'green-button',
    disabled: isDisabledButton,
    hidden: isCurrentWeekPushed,
    onClick: () => {
      setActionKey(ACTIONS.confirmHours.key);
      toggleConfirm();
    },
  },
  confirmed: {
    label: 'Confirmed',
    className: 'shared-button',
    disabled: true,
  },
  seeDetail: {
    label: isEdit ? 'Cancel' : 'See detail',
    className: 'black-button',
    onClick: () => setIsEdit(!isEdit),
  },
  save: {
    label: 'Save',
    className: 'black-button',
    hidden: !isEdit,
    disabled: isDisabledButton,
    onClick: async () => {
      try {
        await formInstance.validateFields();
        setActionKey(ACTIONS.updateTimecards.key);
        toggleConfirm();
      } catch (error) {
        scrollToFirstError({ form: formInstance, error });
      }
    },
  },
  pullBack: {
    label: 'Pull back',
    hidden: !isPushed,
    className: 'black-button',
    disabled: isDisabledButton || isDisabledPullBackButton,
    onClick: () => {
      setActionKey(ACTIONS.pullBack.key);
      toggleConfirm();
    },
  },
  undo: {
    label: 'Undo',
    hidden: !isEdit,
    disabled: isDisabledButton || isEmpty(timecardIdsSickNoShow),
    className: 'blue-button',
    onClick: () => {
      setActionKey(ACTIONS.undo.key);
      toggleConfirm();
    },
  },
});
