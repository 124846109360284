import React from 'react';

import { Form } from 'antd';

import { FormItem, InputNumber, P46sStatement, Radio } from '@/components';

import AWR from './AWR';
import { workingTimeDirective as workingTimeDirectiveList } from './constants';

const WorkingTimeDirective = () => {
  const formInstance = Form.useFormInstance();

  const workTime = Form.useWatch(
    ['vacancyApplicantAttributes', 'worktime'],
    formInstance,
  );

  return (
    <>
      <div className='working-time-directive'>
        <div className='left'>
          <FormItem
            label='Working Time Directive'
            name={['vacancyApplicantAttributes', 'worktime']}
            className='working-time'
            required
            rules={[
              {
                required: true,
                message: 'Please select working time directive',
              },
            ]}
          >
            <Radio options={workingTimeDirectiveList} />
          </FormItem>

          <FormItem
            className='maximum-weekly-hours'
            label='Maximum weekly hours'
            name={['vacancyApplicantAttributes', 'maxWeeklyHours']}
            hidden={workTime !== 1}
            rules={[
              {
                required: true,
                message: 'Please input maximum weekly hours',
              },
            ]}
          >
            <InputNumber placeholder='Maximum weekly hours' controls={false} />
          </FormItem>
        </div>
        <div className='right'>
          <AWR />
        </div>
      </div>
      <P46sStatement />
    </>
  );
};

export default WorkingTimeDirective;
