import React from 'react';

import { Collapse } from 'antd';
import { isEmpty, toPairs } from 'lodash';

import ListJobTypeStatus from './ListJobTypeStatus';

const { Panel } = Collapse;

const ListSeekerPayTemplate = ({
  listStatues,
  timeSheetShowData,
  isHourlyTemplate,
}) =>
  listStatues.map(
    ({ status, color: statusColor }) =>
      !isEmpty(timeSheetShowData[status]) && (
        <div className='timesheet-detail-body'>
          {toPairs(timeSheetShowData[status]).map(([jobName, data]) => {
            const headerTitle = `${data.length} workers - ${jobName}`;
            return (
              <>
                <Panel
                  className='total-worker'
                  header={headerTitle}
                  key='header'
                  collapsible='disabled'
                  showArrow={false}
                />

                <ListJobTypeStatus
                  status={status}
                  statusColor={statusColor}
                  isHourlyTemplate={isHourlyTemplate}
                  data={data}
                />
              </>
            );
          })}
        </div>
      ),
  );

export default ListSeekerPayTemplate;
