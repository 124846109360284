/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Form, message } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { Button, Select } from '@/components';

const Template = ({
  positionLabel,
  locationLabel,
  selectedTemplate,
  template,
  showModal,
  jobTemplatesAttributes,
  listLocations,
  listSkill,
  isDuplicated,
}) => {
  const formInstance = Form.useFormInstance();

  const index = jobTemplatesAttributes.findIndex(
    (item) =>
      item.positionId === template.positionId &&
      item.locationId === template.locationId,
  );

  const isEdit = jobTemplatesAttributes[index]?.isEdit;

  const onChangePosition = (value) => {
    if (isDuplicated(value, jobTemplatesAttributes[index].locationId)) {
      return message.warn('This location and position existed');
    }
    jobTemplatesAttributes.splice(index, 1, {
      ...jobTemplatesAttributes[index],
      positionId: value,
    });
    return formInstance.setFields([
      {
        name: 'jobTemplatesAttributes',
        value: [...jobTemplatesAttributes],
      },
      {
        name: 'selectedTemplate',
        value: jobTemplatesAttributes[index],
      },
    ]);
  };

  const onChangeLocation = (value) => {
    if (isDuplicated(jobTemplatesAttributes[index].positionId, value)) {
      return message.warn('This location and position existed');
    }
    jobTemplatesAttributes.splice(index, 1, {
      ...jobTemplatesAttributes[index],
      locationId: value,
    });
    return formInstance.setFields([
      {
        name: 'jobTemplatesAttributes',
        value: [...jobTemplatesAttributes],
      },
      {
        name: 'selectedTemplate',
        value: jobTemplatesAttributes[index],
      },
    ]);
  };

  const handleEdit = () => {
    const updatedJobTemplates = jobTemplatesAttributes.map(
      (item, templateIndex) => {
        item.isEdit = templateIndex === index;

        return item;
      },
    );

    formInstance.setFields([
      {
        name: 'jobTemplatesAttributes',
        value: [...updatedJobTemplates],
      },
      {
        name: 'selectedTemplate',
        value: template,
      },
      {
        name: 'selectedIndex',
        value: index,
      },
    ]);
  };

  const isCurrentSelected =
    selectedTemplate?.positionId === template.positionId &&
    selectedTemplate?.locationId === template.locationId;

  const removeTemplate = () => {
    formInstance.setFields([
      {
        name: 'selectedTemplate',
        value: template,
      },
    ]);
    showModal();
  };

  const onSelectTemplate = () => {
    const selectedIndex = formInstance.getFieldValue('selectedIndex');
    if (selectedIndex) {
      jobTemplatesAttributes[selectedIndex].isEdit = false;
    }
    if (!isCurrentSelected) {
      formInstance.setFields([
        {
          name: 'selectedTemplate',
          value: template,
        },
        {
          name: 'jobTemplatesAttributes',
          value: [...jobTemplatesAttributes],
        },
        {
          name: 'selectedIndex',
          value: index,
        },
      ]);
    }
  };

  return (
    <Row className='row' gutter={8}>
      <Col span={9}>
        <Select
          value={positionLabel}
          options={listSkill}
          disabled={!isEdit || !isCurrentSelected}
          onChange={onChangePosition}
        />
      </Col>
      <Col span={9}>
        <Select
          options={listLocations}
          value={locationLabel}
          disabled={!isEdit || !isCurrentSelected}
          onChange={onChangeLocation}
        />
      </Col>

      <Col
        span={1}
        className={classNames({ 'col-hide': !isCurrentSelected })}
        onClick={() => !isCurrentSelected && onSelectTemplate()}
      >
        {isCurrentSelected ? <RightOutlined /> : <div />}
      </Col>

      <Col span={4}>
        <Button
          onClick={handleEdit}
          className='yellow-button'
          block
          hidden={isCurrentSelected && isEdit}
        >
          Edit
        </Button>
      </Col>
      <Col span={1} className='delete-icon'>
        <XIconBlack onClick={removeTemplate} />
      </Col>
    </Row>
  );
};

const ListTemplates = ({
  jobTemplates,
  listLocations,
  listSkill,
  selectedTemplate,
  showModal,
  jobTemplatesAttributes,
  isDuplicated,
}) =>
  jobTemplates.length > 0 &&
  jobTemplates.map((template) => {
    const positionLabel = listSkill?.find(
      (item) => item.value === template.positionId,
    )?.label;

    const locationLabel = listLocations.find(
      (item) => item.value === `${template.locationId}`,
    )?.label;
    return (
      <Template
        positionLabel={positionLabel}
        locationLabel={locationLabel}
        selectedTemplate={selectedTemplate}
        template={template}
        showModal={showModal}
        jobTemplatesAttributes={jobTemplatesAttributes}
        listSkill={listSkill}
        listLocations={listLocations}
        isDuplicated={isDuplicated}
      />
    );
  });

ListTemplates.propTypes = {
  jobTemplates: PropTypes.array,
  listLocations: PropTypes.array,
  listSkill: PropTypes.array,
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  showModal: PropTypes.func,
  jobTemplatesAttributes: PropTypes.array,
  isDuplicated: PropTypes.func,
};

Template.propTypes = {
  positionLabel: PropTypes.string,
  locationLabel: PropTypes.string,
  template: PropTypes.object,
  showModal: PropTypes.func,
  selectedTemplate: PropTypes.object,
  jobTemplatesAttributes: PropTypes.array,
  listLocations: PropTypes.array,
  listSkill: PropTypes.array,
  isDuplicated: PropTypes.func,
};

export default ListTemplates;
