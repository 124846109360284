/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { Form } from 'antd';
import moment from 'moment';

import { convertNumber } from '@/utils/evaluation';
import './styles.scss';

const Event = (data) => {
  const dateKey = moment(data?.event?.start).format('DD/MM/YYYY');

  const form = Form.useFormInstance();
  const weekVolume = Form.useWatch([dateKey, 'weekVolume'], form) ?? 0;

  const countRota = data?.formData[dateKey]?.countRota ?? 0;

  const kpi = Form.useWatch('kpi', form) ?? 0;
  const shiftLength = Form.useWatch('shiftLength', form) ?? 0;
  const required = convertNumber(
    Math.ceil(weekVolume / (kpi * shiftLength * countRota)),
  );

  const contracted = data?.event?.contracted ?? 0;
  const difference = contracted - required;

  return (
    <div>
      <p>Contracted: {contracted}</p>
      <p>Required:{required}</p>
      <p>Difference:{difference}</p>
    </div>
  );
};

export default Event;
