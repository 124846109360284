import moment from 'moment';

import request from '@/request';
import { buildNestedObject } from '@/utils/upLoadFiles';
import uploadFileS3 from '@/utils/uploadFileS3';

import { getAddressInfomation } from '../getAddressInfomation';

const listFieldsMaybeUpload = ['cvUpload'];

const uploadCV = async (form, listFieldsTouched) => {
  const result = await Promise.all(
    listFieldsTouched
      .filter(
        (/** @type {string} */ fieldName) =>
          form.getFieldValue(fieldName)?.file?.originFileObj,
      )
      .map(async (fieldName) => {
        const originFile = form.getFieldValue(fieldName).file.originFileObj;

        return {
          ...(await uploadFileS3(originFile.name, originFile)),
          fieldName,
        };
      }),
  );

  const imagesUpload = {};

  result.forEach((item) => {
    form.setFieldsValue(buildNestedObject(item.fieldName, item.getUrl));
  });

  return imagesUpload;
};

const createAplication = async ({ form }) => {
  const listFieldsTouched = listFieldsMaybeUpload.filter((field) =>
    form.isFieldTouched(field),
  );
  await uploadCV(form, listFieldsTouched);

  const formData = form.getFieldsValue();
  const zip = formData?.zip;

  const geogecode = await getAddressInfomation(`${zip}-uk`);
  const { location } = geogecode[0].geometry;
  const { lat, lng } = location;

  const { data } = await request.post('/guests/applicants', {
    applicant: {
      ...formData,
      preferredRoles: formData.preferredRoles?.join(',') || '',
      vacancyApplicantEmploymentsAttributes: [
        {
          leave: formData?.leave,
          pos: '',
          name: '',
          town: '',
          country: '',
          isVerifyExperience: 0,
          positionId: 1,
          startAt: moment().format('YYYY-MM-DDTHH:mm:ss.000Z'),
          finishAt: moment().format('YYYY-MM-DDTHH:mm:ss.000Z'),
        },
      ],
      locationAttributes: {
        latitude: lat,
        longitude: lng,
      },
      isAcceptedReceivingUpdate:
        formData.isAcceptedReceivingUpdate?.[0] || false,
    },
  });

  return data?.data?.id;
};

export default createAplication;
