import React, { useState } from 'react';

import { Form } from 'antd';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import { Modal } from '@/components';
import SickNoShowModal from '@/pages/Payroll/components/SickNoShowModal';
import GroupButtons from '@/pages/PayTemplate/Payroll/components/GroupButtons';
import { STATUES_ACTIONS } from '@/pages/PayTemplate/Payroll/constants';

import useDefineButtons from './hooks/useDefineButtons';

import './styles.scss';

const TimecardFooter = ({
  status,
  isEdit,
  panelId,
  setIsEdit,
  actionMutations,
  isHourlyTemplate,
}) => {
  const formInstance = Form.useFormInstance();
  const [actionKey, setActionKey] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isVisibleSickNoShowModal, setVisibleSickNoShowModal] = useState(false);

  const timecardsWatching = Form.useWatch(
    [`${panelId}`, 'timecards'],
    formInstance,
  );
  const isSeletedCheckbox = values(timecardsWatching).some(
    ({ select }) => select,
  );

  const buttons = useDefineButtons({
    isEdit,
    setIsEdit,
    setActionKey,
    setVisibleModal,
    actionMutations,
    isHourlyTemplate,
    setVisibleSickNoShowModal,
    isSeletedCheckbox,
  });

  const STATUS_BUTTONS = {
    confirmed: (
      <GroupButtons
        buttonProperties={[
          buttons.finalised,
          buttons.sick,
          buttons.noShow,
          buttons.cancel,
          buttons.save,
          buttons.edit,
        ]}
      />
    ),
  };

  const handleCancel = () => {
    setActionKey(null);
    setVisibleModal(false);
    setVisibleSickNoShowModal(false);
  };

  const handleAction = () => {
    actionMutations[actionKey].action({ panelId });
    handleCancel();
  };
  return (
    <>
      <div className='actions-timecards'>{STATUS_BUTTONS[status]}</div>
      <Modal
        visible={visibleModal}
        onClickYes={handleAction}
        onClickNo={handleCancel}
      >
        Do you want to update {STATUES_ACTIONS?.[actionKey]?.label}?
      </Modal>
      <SickNoShowModal
        name='comment'
        type={actionKey}
        visible={isVisibleSickNoShowModal}
        onCancel={handleCancel}
        onConfirm={handleAction}
      />
    </>
  );
};

export default TimecardFooter;

TimecardFooter.propTypes = {
  isEdit: PropTypes.bool,
  status: PropTypes.string,
  panelId: PropTypes.number,
  setIsEdit: PropTypes.func,
  isHourlyTemplate: PropTypes.bool,
  actionMutations: PropTypes.object,
};

TimecardFooter.defaultProps = {
  status: '',
  isEdit: false,
  panelId: null,
  setIsEdit: () => {},
  actionMutations: {},
  isHourlyTemplate: false,
};
