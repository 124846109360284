import request from '@/request';

const updatePayrollTimecard = async ({
  timesheetId,
  payrollTemplateHoursAttributes,
}) => {
  const endpoint = `pay_templates/payrolls/${timesheetId}`;
  const payload = {
    payroll: { payrollTemplateHoursAttributes },
  };

  const res = await request.put(endpoint, {
    ...payload,
  });
  return res.data;
};

export default updatePayrollTimecard;
