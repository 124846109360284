import { useQuery } from 'react-query';

import { fetchIndustries } from '@/services/dropdownServices/getIndustries';

const useIndustries = () => {
  const { data: resIndustries } = useQuery(['industries'], fetchIndustries, {
    refetchOnWindowFocus: false,
  });

  return { ...resIndustries };
};

export default useIndustries;
