import React from 'react';

import Event from './Event';
import { EventMonthView } from './MonthView';
import { HeaderWeekView, ToolbarWeekView } from './WeekView';

/**
 * @param {{
 * isLoading: boolean,
 * setJobIdEdit: Function,
 * setRepostId: Function,
 * setShowPopupCreate: Function,
 * refetchCalendarJobs: Function,
 * openManageWorkerModal: Function
 * setModalContent: Function,
 * }} param
 * @returns {import('react-big-calendar').Components}
 */
const customCalendarView = ({
  isLoading,
  setJobIdEdit,
  setRepostId,
  setShowPopupCreate,
  refetchCalendarJobs,
  openManageWorkerModal,
  setModalContent,
}) => ({
  toolbar: ({ label, onNavigate, date }) => (
    <ToolbarWeekView label={label} onNavigate={onNavigate} date={date} />
  ),
  week: {
    header: ({ date, localizer }) => (
      <HeaderWeekView isLoading={isLoading} date={date} localizer={localizer} />
    ),
    event: ({ event, title }) => (
      <Event
        event={event}
        title={title}
        setJobIdEdit={setJobIdEdit}
        setRepostId={setRepostId}
        setShowPopupCreate={setShowPopupCreate}
        refetchCalendarJobs={refetchCalendarJobs}
        openManageWorkerModal={openManageWorkerModal}
        setModalContent={setModalContent}
      />
    ),
  },
  month: {
    event: ({ event, title }) => (
      <EventMonthView
        title={title}
        event={event}
        setJobIdEdit={setJobIdEdit}
        setRepostId={setRepostId}
        setShowPopupCreate={setShowPopupCreate}
        refetchCalendarJobs={refetchCalendarJobs}
        openManageWorkerModal={openManageWorkerModal}
        setModalContent={setModalContent}
      />
    ),
  },
});

export default customCalendarView;
