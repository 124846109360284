import React, { useState } from 'react';

import { Form, Spin } from 'antd';
import PropTypes from 'prop-types';

import { Button, Modal } from '@/components';

import CostsSection from './CostsSection';
import HoursSection from './HoursSection';

const BodySection = ({
  fetchingSection,
  rangeWeekDate,
  onChangeNetCost,
  onChangeService,
}) => {
  const formInstance = Form.useFormInstance();
  const [isShowAdditionalSection, setIsShowAdditionalSection] = useState(false);
  const costsSection = Form.useWatch('costsSection', formInstance) ?? [];
  const hoursSection = Form.useWatch('hoursSection', formInstance) ?? [];

  const addHours = () => {
    hoursSection.push({
      labourTypeId: null,
      invoiceDetailType: 'labour',
      description: `For services provided - ${rangeWeekDate}`,
    });

    formInstance.setFieldsValue({
      hoursSection,
    });

    setIsShowAdditionalSection(false);
  };

  const addCosts = () => {
    costsSection.push({
      invoiceDetailType: 'cost',
    });

    formInstance.setFieldsValue({
      costsSection,
    });

    setIsShowAdditionalSection(false);
  };

  return fetchingSection ? (
    <Spin />
  ) : (
    <div className='body-container'>
      <HoursSection />
      <CostsSection
        onChangeService={onChangeService}
        onChangeNetCost={onChangeNetCost}
      />
      <div className='add-another-section'>
        <Button
          onClick={() => setIsShowAdditionalSection(true)}
          className='yellow-button add-section-btn'
        >
          Add Another Section
        </Button>
        <Modal
          isShowFooter={false}
          title='Additional Section'
          visible={isShowAdditionalSection}
          onCancel={() => setIsShowAdditionalSection(false)}
        >
          <Button onClick={addHours} className='yellow-button additional-btn'>
            Additional Hours
          </Button>
          <Button onClick={addCosts} className='yellow-button additional-btn'>
            Additional Cost
          </Button>
        </Modal>
      </div>
    </div>
  );
};

BodySection.propTypes = {
  fetchingSection: PropTypes.bool,
  onChangeService: PropTypes.func,
  rangeWeekDate: PropTypes.string,
  onChangeNetCost: PropTypes.func,
};

BodySection.defaultProps = {
  fetchingSection: false,
  onChangeNetCost: () => {},
  onChangeService: () => {},
  rangeWeekDate: '',
};
export default BodySection;
