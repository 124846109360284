/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';

import { Card, Col, Form, Row } from 'antd';
import { keyBy } from 'lodash';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Button } from '@/components';
import Modal from '@/components/ModalCalendar';
import ModalEditEventView from '@/components/ModalEditEventView';
import { useFetch, useQueryString } from '@/hooks';
import { getLiveRota } from '@/services/rota';
import useBuildRotaStore from '@/store/rotaStore';

import CustomDateCellWrapper from '../BuildRota/CustomDateCellWrapper';
import Event from '../BuildRota/Event';
import EventContainerWrapper from '../BuildRota/EventContainerWrapper';
import { eventWrapperGetter } from '../BuildRota/GetterandSetters';
import InputForm from '../BuildRota/InputForm';
import CustomTimeSlotWrapper from '../BuildRota/timeHeaderGutter';
import MyTimeSlotWrapper from '../BuildRota/TimeSlotWrapper';
import CustomToolbar from '../BuildRota/Toolbar';

const localizer = momentLocalizer(moment);
const CustomHeader = () => null;

const LiveRota = () => {
  const [form] = Form.useForm();
  const [view, setView] = useState('day');
  const [dateKey, setDateKey] = useState();
  const [date, setDate] = useState(moment().utc().format());
  const [selectEvent, setSelectEvent] = useState(false);
  const [event, setEvent] = useState(null);
  const [eventPosition, setEventPosition] = useState({ x: 0, y: 0 });
  const [editPopup, setEditPopup] = useState(false);
  const required = useBuildRotaStore((state) => state.required);
  const agency = useBuildRotaStore((state) => state.agency);
  const kpi = Form.useWatch('kpi', form);
  const shiftLength = Form.useWatch('shiftLength', form);
  const volume = Form.useWatch('volume', form);
  const formData = form.getFieldsValue(true);
  const weekVolume = Form.useWatch([dateKey, 'weekVolume'], form);
  const week = Form.useWatch('week', form);
  const [query] = useQueryString();

  useEffect(() => {
    const {
      employerId,
      week: weekQuery,
      employerLocationId,
      skillId,
      kpi: kpiQuery,
      shiftLength: shiftLengthQuery,
    } = query;

    form.setFieldsValue({
      employerId: employerId?.toInt(),
      kpi: kpiQuery?.toInt(),
      shiftLength: shiftLengthQuery?.toInt(),
      week: weekQuery?.toInt() ?? moment().week(),
      employerLocationId: employerLocationId?.toInt(),
      skillId: skillId?.toInt(),
    });
  }, []);

  const formDataArray = Object.keys(formData)
    .map((key) => formData[parseInt(key, 10)])
    .filter((key) => !!key);

  const handleViewChange = (views) => {
    setView(views);
  };
  const handleDateChange = (dates) => {
    setDate(dates);
  };
  const handleEventSelect = (currentEvent, e) => {
    const { clientX, clientY } = e;
    setEventPosition({ x: clientX, y: clientY });
    setSelectEvent(true);
    setEvent(currentEvent);
  };

  const formats = {
    dayHeaderFormat: (dates, culture, options) =>
      `${moment(dates).format('Do MMM YY')} - ${moment(dates)
        .endOf('week')
        .format('Do MMM')}`,
    weekdayFormat: (dates, culture) =>
      `${moment(dates).format('Do MMM YY')} - ${moment(dates)
        .endOf('week')
        .format('Do MMM')}`,
  };
  const dayStyleGetter = (dates) => {
    const today = moment().startOf('day');
    const isToday = moment(dates).isSame(today, 'day');
    const backgroundColor = isToday ? '#fff' : undefined; // Set the desired background color for the current day

    const style = {
      backgroundColor,
    };

    return {
      style,
    };
  };

  const { refetch: refetchGetLiveRota } = useFetch(
    ['getBuildRota', date, view, kpi, shiftLength],
    () => {
      const {
        employerId,
        employerLocationId,
        skillId,
        week: weekQuery,
      } = form.getFieldsValue();

      return getLiveRota({
        employerId,
        employerLocationId,
        skillId,
        week: weekQuery,
        date,
        view,
      });
    },
    {
      onSuccess: ({ data, statistical, rotaTotals }) => {
        form.setFieldsValue({
          ...keyBy(data, 'id'),
          ...keyBy(statistical, 'dateKey'),
          ...rotaTotals,
        });
        if (event && data) {
          const newEvent = (data ?? []).find(
            (e) => e.id === event.id && event.weekDay === e.weekDay,
          );

          setEvent(newEvent);
        }
      },
    },
  );

  useEffect(() => {
    if (kpi && shiftLength && volume && view === 'day') {
      const formValues = form.getFieldsValue(true);
      const formValuesArray = Object.keys(formValues)
        .map((key) => formValues[parseInt(key, 10)])
        .filter((key) => !!key);
      const requiredPeople = volume / (kpi * shiftLength);
      const requiredHour = requiredPeople * shiftLength;

      const data = formValuesArray.map((dt) => ({
        name: [dt.id, 'numberOfRequired'],
        value: requiredPeople,
      }));

      form.setFields(data);

      form.setFieldsValue({
        requiredPeople,
        requiredHour,
      });
    }
  }, [form, kpi, shiftLength, volume, view]);

  useEffect(() => {
    const formValues = form.getFieldsValue(true);
    const formValuesArray = Object.keys(formValues)
      .map((key) => formValues[parseInt(key, 10)])
      .filter((key) => !!key);

    if (view === 'week') {
      if (shiftLength && kpi && weekVolume) {
        const weekRequiredPeople = weekVolume / (kpi * shiftLength);
        const weekRequiredHour = weekRequiredPeople * shiftLength;
        const data = formValuesArray
          .filter((dt) => {
            const dateChange = moment(dt?.start).format('DD/MM/YYYY');
            return dateChange === dateKey;
          })
          .map((dt) => ({
            name: [dt?.id, 'numberOfRequired'],
            value: weekRequiredPeople,
          }));

        form.setFields(data);

        form.setFields([
          {
            name: [dateKey, 'weekRequiredPeople'],
            value: weekRequiredPeople,
          },
          {
            name: [dateKey, 'weekRequiredHour'],
            value: weekRequiredHour,
          },
        ]);
      }
    }
  }, [dateKey, form, kpi, shiftLength, view, weekVolume]);
  const handleSearchRota = () => {
    const dataChange = moment().week(week).startOf('week');
    setDate(dataChange);

    refetchGetLiveRota();
  };

  let customTimeGutterHeader;

  if (view === 'week') customTimeGutterHeader = CustomTimeSlotWrapper;
  const eventStyleGetter = () => {
    let color;

    const contracted = 50;

    const difference = contracted - required + agency;

    if (difference > 0 && difference !== contracted) {
      color = '#F6E6A7';
    } else if (difference < 0) {
      color = '#DD9DA0';
    } else if (required === 0) {
      color = '#BAD0CA';
    } else {
      color = '#BAD0CA';
    }

    const style = {
      backgroundColor: color,
      color: '#000000',
      borderRadius: '10px',
      border: '1px solid #707070',

      // Add any additional styling properties you need
    };

    return {
      style,
    };
  };

  const handleClearFilter = () => {
    form.resetFields();
    refetchGetLiveRota();
  };

  return (
    <Form
      form={form}
      initialValues={{
        requiredPeople: 0,
        requiredHour: 0,
        week: moment().week(),
      }}
      className='job-list-view'
    >
      <div className='input-container'>
        <InputForm
          handleSearchRota={handleSearchRota}
          handleClearFilter={handleClearFilter}
        />
      </div>
      <div className='calendar'>
        <Calendar
          formats={formats}
          localizer={localizer}
          events={formDataArray}
          startAccessor='start'
          endAccessor='end'
          views={['day', 'week']}
          date={date}
          onNavigate={handleDateChange}
          view={view}
          onView={handleViewChange}
          eventPropGetter={eventStyleGetter}
          eventWrapperGetter={eventWrapperGetter}
          onSelectEvent={handleEventSelect}
          dayPropGetter={dayStyleGetter}
          components={{
            toolbar: CustomToolbar,
            event: (props) => (
              <Event {...props} view={view} formData={formData} />
            ),
            // eslint-disable-next-line
            eventContainerWrapper: (props) => (
              <EventContainerWrapper
                {...props}
                view={view}
                formDataArray={formDataArray}
              />
            ),
            dateCellWrapper: (props) => (
              <CustomDateCellWrapper
                {...props}
                formDataArray={formDataArray}
                setDateKey={setDateKey}
              />
            ),
            header: CustomHeader,
            timeGutterHeader: customTimeGutterHeader,
            timeSlotWrapper: MyTimeSlotWrapper,
          }}
        />
        {view === 'week' && (
          <div className='bottom-cards'>
            <Row>
              <Col span={12}>
                <Card className='right-cards'>
                  <h2>Rota Totals</h2>

                  <div className='individual-card'>
                    <h4>Hours</h4>
                    <Card className='card'>0</Card>
                  </div>
                  <div className='individual-card'>
                    <h4>Cost</h4>
                    <Card className='card'>0</Card>
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <div className='left-items'>
                  <Card className='right-cards'>
                    <div className='individual-card'>
                      <h4>Required</h4>
                      <Card className='card'>0</Card>
                    </div>
                    <div className='individual-card'>
                      <h4>Contract</h4>
                      <Card className='card'>0</Card>
                    </div>
                    <div className='individual-card'>
                      <h4>Difference</h4>
                      <Card className='card'>0</Card>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
            <div className='card-buttons'>
              <Button
                disabled={formDataArray.length === 0}
                className='yellow-button'
              >
                Save as Draft
              </Button>
            </div>
            <div className='card-buttons'>
              <Button
                disabled={formDataArray.length === 0}
                className='yellow-button'
              >
                Post Rota
              </Button>
            </div>
          </div>
        )}
      </div>

      <Modal
        visible={selectEvent}
        onClickNo={() => {
          setSelectEvent(false);
          setEvent(null);
        }}
        position={eventPosition}
        data={{ contracted: 0 }}
        onClick={() => setEditPopup(true)}
        event={event}
      />
      <ModalEditEventView
        visible={editPopup}
        rotaShiftId={event?.id}
        rotaWeekId={event?.rotaWeekPlanId}
        data={{ contracted: 50, required }}
        onClickNo={() => setEditPopup(false)}
        event={event}
      />
    </Form>
  );
};

export default LiveRota;
