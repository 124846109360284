/* eslint-disable no-unused-vars */
import { upperFirst } from 'lodash';
import moment from 'moment';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const statues = ['unapproved', 'approved', 'confirmed', 'finalised', 'paid'];

const updateTimecards = ({ timecards, seekerName }) =>
  (timecards || []).map((timecard) => {
    const { startTime, endTime } = timecard;
    const templateName = timecard?.payrollTemplate?.name ?? '';

    const shiftTimes = `${moment(startTime).format('HH:mm')} - ${moment(
      endTime,
    ).format('HH:mm')}`;
    const startDate = moment(startTime);
    const endDate = moment(endTime);
    const startShift = moment(startTime).format('HH:mm');
    const endShift = moment(endTime).format('HH:mm');

    const timecardsInfo = timecard.payrollTemplateHours.map((row, indexRow) => {
      const { payRate, chargeRate, totalHours } = row;
      const totalPay = payRate * totalHours;
      const totalCharge = chargeRate * totalHours;
      const dayOfWeek = upperFirst(row.weekday);

      return {
        ...row,
        name: seekerName,
        templateName,
        startDate,
        endDate,
        shiftTimes: indexRow === 0 ? shiftTimes : null,
        startShift: indexRow === 0 ? startShift : null,
        endShift: indexRow === 0 ? endShift : null,
        payTemplateName: row.templateName,
        startAt: moment(row.startAt).format('HH:mm'),
        endAt: moment(row.endAt).format('HH:mm'),
        dayOfWeek: indexRow === 0 ? dayOfWeek : null,
        totalHours,
        totalPay,
        totalCharge,
      };
    });

    return {
      ...timecard,
      shiftTimes,
      timecardsInfo,
    };
  });

const updateRes = (normalizeResponse) =>
  statues.reduce((acc, status) => {
    const updateStatusInfo = normalizeResponse[status].map((panelInfo) => {
      const seekerName = panelInfo?.omniEmployee?.fullname;

      const updatedTimecards = updateTimecards({
        timecards: panelInfo?.timecards || [],
        seekerName,
      });

      return {
        ...panelInfo,
        seekerName,
        timecards: updatedTimecards,
      };
    });

    acc[status] = updateStatusInfo || [];
    return acc;
  }, {});

const getTimesheetDetail = async (/** @type {string|number} */ id) => {
  const res = await request.get(`pay_templates/timesheets/${id}`);
  const normalizeResponses = normalizeShowResponse(res);

  const updatedRes = {
    ...(normalizeResponses?.data ?? {}),
    ...updateRes(normalizeResponses?.data ?? {}),
  };

  return updatedRes;
};

export default getTimesheetDetail;
