/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { message, Modal, Spin, Form } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/warningIcon.svg';
import { Button, FormItem, SeekerPopout, Input } from '@/components';
import { useFetch } from '@/hooks';
import { JobServices, SeekerService } from '@/services';
import { getSeekerProfile } from '@/services/seeker/getSeekerProfile';

import SeekerProfile from '../SeeekProfile';
import './styles.scss';

const AddSeekerModal = ({
  className,
  setVisibleAddmodal,
  visibleAddmodal,
  refetchSeeker,
  seekerLength,
  requested,
  ...props
}) => {
  const formInstance = Form.useFormInstance();
  const isOver18 = Form.useWatch('isOver18', formInstance)?.[0];

  const { jobId } = useParams();
  const [seekerId, setSeekerId] = useState('');
  const [value, setValue] = useState('');
  const [seekers, setSeekers] = useState([]);
  const [isShowPopout, setIsShowPopout] = useState(false);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const newSeekerLength = seekers.length;
  const totalLength = seekerLength + newSeekerLength;
  const isExceedRequested = totalLength >= requested;

  const { data, isFetching, refetch } = useFetch(
    ['getSeeker', value],
    () =>
      SeekerService.getSeekers({
        searchTerm: value,
        employee_status: 'active',
        positionId: jobId,
      }),
    { initialData: {} },
  );

  const seekerList = data.data ?? [];

  const { mutate: addSeeker } = useMutation(
    (seekerIds /* @params string[] seekerIds */) =>
      JobServices.addSeekerForJob(jobId, seekerIds),
    {
      onError: (error) =>
        message.error(
          error.status === 404
            ? 'This job has been filled, cannot add more seeker'
            : error.message,
        ),
      onSuccess: () => {
        message.success('Added Seeker success');
        refetchSeeker();
      },
    },
  );

  const renderSeekersFound = () => {
    if (isFetching) {
      return (
        <div className='text-style'>
          <Spin />
        </div>
      );
    }

    if (seekerList.length !== 0) {
      return seekerList.map((dt) => (
        <SeekerPopout
          setIsShowProfile={setIsShowProfile}
          setSeekerId={setSeekerId}
          id={dt.id}
          src={dt.profilepic}
          name={dt.fullname}
          like={dt.like}
          rate={dt.rate}
          onClick={async () => {
            if (isExceedRequested) {
              return message.warn(
                'You have exceeded the number of seeker requested',
              );
            }
            const seekerProfile = (await getSeekerProfile(dt.id)) ?? {};
            const { employeeDob } = seekerProfile;
            const age = moment().diff(moment(employeeDob), 'year');

            if (isOver18 && age < 18) {
              return message.warn('Please choose seekers over 18 years old');
            }

            setSeekers([...seekers, { id: dt.id, fullname: dt.fullname }]);
            return setIsShowPopout(false);
          }}
        />
      ));
    }

    return <h3 className='text-style'>No Employees Found</h3>;
  };

  const onPressEnter = (e) => {
    if (e.target.value === '') {
      return setIsShowPopout(false);
    }

    setValue(e.target.value);
    return setIsShowPopout(true);
  };

  const onClickAddSeeker = () => {
    const seekerIds = seekers.map((seeker) => parseInt(seeker.id, 10));

    addSeeker(seekerIds);
    setSeekers([]);
    refetch();
    return setVisibleAddmodal(false);
  };

  const removeSeeker = (id) => {
    const updatedSeekers = seekers.filter((sk) => sk.id !== id);
    setSeekers(updatedSeekers);
  };

  return (
    <Modal
      {...props}
      footer={null}
      wrapClassName={classNames('add-seeker-modal', className)}
      onCancel={() => {
        setSeekers([]);
        setVisibleAddmodal(false);
      }}
      closable={false}
    >
      <div className='modal-header container-style'>
        <WarningIcon />
        <h1>Add Seeker</h1>
      </div>
      <div className='modal-body container-style'>
        <div className='search-container'>
          <FormItem className='search-item' label='Search Seeker'>
            <Input
              dropdownClassName='options-container'
              placeholder='Enter Name'
              onPressEnter={onPressEnter}
            />
          </FormItem>
        </div>
      </div>

      <ul className='seeker-popout-container'>
        {isShowPopout ? (
          renderSeekersFound()
        ) : (
          <div className='seekers-container'>
            {seekers.map((seeker) => (
              <div key={seeker.id} className='seeker-item'>
                <span className='seeker-name'>{seeker.fullname}</span>
                <ClosedIcon onClick={() => removeSeeker(seeker.id)} />
              </div>
            ))}
          </div>
        )}
      </ul>

      <div className='add-seeker-btn-container'>
        <Button onClick={onClickAddSeeker} className='yellow-button add-seeker'>
          Add Seeker
        </Button>
      </div>
      <div className='modal-footer'>
        <Button
          onClick={() => {
            setSeekers([]);
            setVisibleAddmodal(false);
          }}
          className='black-button close-modal'
        >
          Close
        </Button>
      </div>

      <Modal
        footer={null}
        wrapClassName='seeker-profile-modal'
        visible={isShowProfile}
        onCancel={() => setIsShowProfile(false)}
        closable={false}
      >
        <SeekerProfile
          setIsShowProfile={setIsShowProfile}
          isShowProfile={isShowProfile}
          seekerId={seekerId}
        />
      </Modal>
    </Modal>
  );
};

AddSeekerModal.propTypes = {
  className: PropTypes.string,
  setVisibleAddmodal: PropTypes.func,
  visibleAddmodal: PropTypes.bool,
  isExceedRequested: PropTypes.bool,
  refetchSeeker: PropTypes.func,
  requested: PropTypes.number,
  seekerLength: PropTypes.number,
};

AddSeekerModal.defaultProps = {
  className: '',
  setVisibleAddmodal: () => {},
  visibleAddmodal: false,
  isExceedRequested: false,
  requested: 0,
  seekerLength: 0,
};

export default AddSeekerModal;
