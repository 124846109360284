import request from '@/request';
import { formatResponse } from '@/utils/thunkUtil';

export async function changeStatusApplication(id, status) {
  const endpoint = `/recruitments/applicants/${id}`;

  const response = await request.put(endpoint, { status });

  return formatResponse(response, { endpoint });
}
