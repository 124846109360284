import { useQuery } from 'react-query';

import { getSkillTypes } from '@/services/dropdownServices/getSkillTypes';

const useSkillTypes = () => {
  const { data } = useQuery(['getSkillTypes'], getSkillTypes, {
    refetchOnWindowFocus: false,
  });

  return data ?? [];
};

export default useSkillTypes;
