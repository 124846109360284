/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

import { Form, Popconfirm, Table } from 'antd';
import { isEmpty, merge, round } from 'lodash';
import { useParams } from 'react-router-dom';

import { Button, TableFooter, EditTableCell, ModalConfirm } from '@/components';
import { usePayCharge } from '@/hooks';
import useModal from '@/utils/useModal';

import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const UPDATE_TYPE = {
  SELECTED: 'selected',
  ALL: 'all',
};

const ListJobTypePayrollAdded = ({
  listJobTypes,
  loading,
  onUpdateSelected,
  onUpdateAll,
  employerId,
  weekNumber,
  weekStartDate,
  weekEndDate,
  timesheetRefetch,
  locationId,
  refetchJobTypeAdd,
  jobs,
}) => {
  const { id: timesheetId } = useParams();
  const { providerRateCard } = usePayCharge({ id: timesheetId, employerId });
  const [form] = Form.useForm();
  const chargeRateRef = useRef();
  const [editingKey, setEditingKey] = useState('');
  const [selectedRecord, setSelectedRecord] = useState({});
  const [updateButtonType, setUpdateButtonType] = useState('');

  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const checkIsEditing = (key) => key === editingKey;
  const { currency, defaultLimitPayRate } = useTenantContext();

  useEffect(() => {
    if (listJobTypes) {
      listJobTypes.forEach(({ skillId, payrate, chargeRate }) => {
        const data = form.getFieldsValue();
        form.setFieldsValue({
          [skillId]: {
            ...data[skillId],
            payrate: round(payrate, 2),
            chargeRate: round(chargeRate, 2),
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listJobTypes]);

  const edit = (record, key) => {
    form.setFieldsValue({
      jobType: record.jobType,
      payrate: Number(record?.payrate ?? 0).toFixed(2),
      chargeRate: Number(record?.chargeRate ?? 0).toFixed(2),
    });

    chargeRateRef.current = Number(record?.chargeRate ?? 0).toFixed(2);
    setEditingKey(key);
    setSelectedRecord(record);
  };

  const cancel = () => {
    form.resetFields();
    setEditingKey('');
    setSelectedRecord({});
    setUpdateButtonType('');
  };

  const handleUpdateAllTimeCardJobTypes = async (skillId, payrate) => {
    await onUpdateAll(skillId, payrate);
    cancel();
  };

  const handleUpdateAllTimecardJobTypesSelected = async (skillId, payrate) => {
    await onUpdateSelected(skillId, payrate);
    cancel();
  };

  const isDisabledButton = (record = {}) => {
    const { jobType: jobTypeName } = record;
    const approvedJobTypes = jobs?.approved?.[jobTypeName] ?? {};
    const unApprovedJobTypes = jobs?.unapproved?.[jobTypeName] ?? {};
    const confirmedJobTypes = jobs?.confirmed?.[jobTypeName] ?? {};

    const allRecords = merge(
      {},
      unApprovedJobTypes,
      approvedJobTypes,
      confirmedJobTypes,
    );

    return isEmpty(allRecords);
  };

  const updatePayrate = async (updateType) => {
    const { skillId } = selectedRecord;
    const { payrate } = form.getFieldsValue();
    if (!isDisplayModal && parseFloat(payrate) < defaultLimitPayRate) {
      return showModal();
    }

    (updateType ?? updateButtonType) === UPDATE_TYPE.SELECTED
      ? await handleUpdateAllTimecardJobTypesSelected(skillId, payrate)
      : await handleUpdateAllTimeCardJobTypes(skillId, payrate);
    return hiddenModal();
  };

  const onClickButton = (type) => {
    updatePayrate(type);
    setUpdateButtonType(type);
  };

  const columns = [
    {
      title: 'Job Type',
      dataIndex: 'jobType',
    },
    {
      title: 'Pay Rate',
      dataIndex: 'payrate',
      editable: true,
      render: (data) => `${currency}${data || 0}`,
    },
    {
      title: 'Charge Rate',
      dataIndex: 'chargeRate',
      editable: false,
      render: (data) => `${currency}${data}`,
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '10%',
      render: (_, record, index) => {
        const editable = checkIsEditing(index);

        return editable ? (
          <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
            <Button className='black-button'>Cancel</Button>
          </Popconfirm>
        ) : (
          <Button
            disabled={editingKey !== '' || isDisabledButton(record)}
            onClick={() => edit(record, index)}
            className='gray-button'
          >
            Edit
          </Button>
        );
      },
    },
    {
      title: 'Select All job type',
      dataIndex: 'buttonUpdateAll',
      width: '15%',
      render: (_, _record, index) => {
        const editable = checkIsEditing(index);

        return (
          editable && (
            <Button
              className='yellow-button'
              onClick={() => onClickButton(UPDATE_TYPE.ALL)}
            >
              Update All
            </Button>
          )
        );
      },
    },
    {
      title: 'Select All Selected in job type',
      dataIndex: 'buttonUpdateSelected',
      width: '15%',
      render: (_, _record, index) => {
        const editable = checkIsEditing(index);

        return (
          <div>
            {editable && (
              <Button
                className='yellow-button'
                onClick={() => onClickButton(UPDATE_TYPE.SELECTED)}
              >
                Update Selected
              </Button>
            )}
            <ModalConfirm
              visible={isDisplayModal}
              onClickNo={hiddenModal}
              onClickYes={() => updatePayrate()}
            >
              Are you sure you want to update the pay rate to below minimum wage
              of {currency}
              {defaultLimitPayRate}?
            </ModalConfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType:
          col.dataIndex === 'payrate' || col.dataIndex === 'chargeRate'
            ? 'number'
            : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: checkIsEditing(index) && col.dataIndex !== 'chargeRate',
        employerId,
        providerRateCard,
      }),
    };
  });

  return (
    <Form form={form}>
      <Table
        className='list-job-type-added'
        components={{
          body: {
            cell: EditTableCell,
          },
        }}
        bordered
        dataSource={listJobTypes}
        columns={mergedColumns}
        rowClassName='editable-row'
        footer={() =>
          TableFooter({
            employerId,
            weekNumber,
            weekStartDate,
            weekEndDate,
            isPayroll: true,
            timesheetRefetch,
            locationId,
            refetchJobTypeAdd,
          })
        }
        pagination={false}
        scroll={{ y: 200 }}
        loading={loading}
      />
    </Form>
  );
};

export default ListJobTypePayrollAdded;
