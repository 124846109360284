import React from 'react';

import { Form } from 'antd';

import { Radio, FormItem, Textarea } from '@/components';
import { rulesSelect, rulesTextInput } from '@/utils/rulesInput';

import { convictions } from './constants';

const CriminalConvictions = () => {
  const formInstance = Form.useFormInstance();
  const hasConvict = Form.useWatch('hasConvict', formInstance);

  return (
    <div className='seeker-criminal-convictions'>
      <FormItem
        label='Criminal Convictions'
        required
        className='form-item-criminal-convictions'
      />
      <div className='has-convict'>
        <div className='text-label'>Do you have any convictions</div>
        <FormItem name='hasConvict' rules={rulesSelect('Convictions')}>
          <Radio options={convictions} />
        </FormItem>
      </div>
      {hasConvict && (
        <div className='flex-box'>
          <FormItem rules={rulesTextInput('details')} name='convict'>
            <Textarea rows={3} placeholder='Please add more details' />
          </FormItem>
        </div>
      )}
    </div>
  );
};

export default CriminalConvictions;
