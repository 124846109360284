import { useTenantContext } from '../../../../../TenantWrapper';

export const useColumns = () => {
  const { currency } = useTenantContext();

  return [
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
    },
    {
      title: 'Job Type',
      dataIndex: 'jobTypes',
      key: 'jobTypes',
    },
    {
      title: 'Job Description',
      dataIndex: 'jobDescriptions',
      key: 'jobDescriptions',
      ellipsis: true,
    },
    {
      title: 'Date Posted',
      dataIndex: 'datePosted',
      key: 'datePosted',
    },
    {
      title: 'Pay Rate',
      dataIndex: 'payRate',
      key: 'payRate',
      width: '10%',
      render: (item) => `${currency}${item || 0}`,
    },
    {
      title: 'Charge Rate',
      dataIndex: 'chargeRate',
      key: 'chargeRate',
      render: (item) => `${currency}${item || 0}`,
    },
    {
      title: (
        <div className='shift-date-container'>
          <div>Shift</div>
          <div>Date and Time</div>
        </div>
      ),
      dataIndex: 'shift',
      key: 'shift',
      width: '20%',
      render: (item) => (
        <div className='shift-date-container'>
          <p>{item.date}</p>
          <p>{item.time}</p>
        </div>
      ),
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
  ];
};
