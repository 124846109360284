import React from 'react';

import { Tooltip } from 'antd';
import moment from 'moment';

const breadCrumb = [
  { item: 'Timesheets', to: '/timesheets' },
  { item: 'Costs', to: '/costs' },
];

// createdAt
// :
// "2022-12-11T03:10:18.248Z"
// id
// :
// "1"
// supplierEmail
// :
// "harveya@gmail.com"
// supplierName
// :
// "1"
// supplierPhone
// :
// "012312312312"
// suppliersTypeId
// :
// 1
// totalNetCost
// :
// 160
// weekSpreadCost
// :
// 5

const columns = [
  {
    title: 'Supplier Name',
    width: '20%',
    dataIndex: 'supplierName',
    render: (name) => (
      <Tooltip placement='topLeft' title={name}>
        {name}
      </Tooltip>
    ),
  },

  {
    title: 'Email Address',
    width: '15%',
    dataIndex: 'supplierEmail',
    render: (email) => (
      <Tooltip placement='topLeft' title={email}>
        {email}
      </Tooltip>
    ),
  },
  {
    title: 'Phone Number',
    width: '10%',
    dataIndex: 'supplierPhone',
    render: (phoneNumber) => (
      <Tooltip placement='topLeft' title={phoneNumber}>
        {phoneNumber}
      </Tooltip>
    ),
  },
  {
    title: 'Payment Week',
    width: '7%',
    dataIndex: 'week',
  },
  {
    title: 'Supply Type',
    width: '15%',
    dataIndex: 'supplierType',
    render: (type) => (
      <Tooltip placement='topLeft' title={type}>
        {type}
      </Tooltip>
    ),
  },
  {
    title: 'Date Created',
    width: '15%',
    defaultSortOrder: 'descend',
    sorter: true,
    dataIndex: 'createdAt',
    render: (/** @type {string[]} */ datetime) =>
      moment(datetime).format('DD/MM/YYYY'),
  },
  {
    title: 'Total Invoice Cost',
    dataIndex: 'totalNetCost',
  },
];

export { breadCrumb, columns };
