import request from '@/request';

const searchProvider = async (providerName) => {
  const res = await request.get('/providers/search_provider', {
    params: {
      filter: { searchTerm: providerName },
    },
  });

  const listItems = res.data.data;

  return listItems.map((item) => ({
    value: item,
    label: item,
  }));
};

export default searchProvider;
