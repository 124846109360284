import React from 'react';

import { Form } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Checkbox, FormItem } from '@/components';
import { isRequireKeysValueTruthy, isValidAllEmail } from '@/utils/array';

import { EXCEPTION_MESSAGE } from './constants';

const ContactType = ({
  type,
  handleSelectContactType,
  selectedContactType,
}) => {
  const formInstance = Form.useFormInstance();

  const typeValue = Form.useWatch(type.fieldName, formInstance);

  const rules = [
    {
      /**
       * @param {any} _ - The first parameter (unused)
       * @param {object} value - The value to be validated
       * @return {Promise} Resolves if validation passes, rejects with an error otherwise
       */
      validator: (_, value) => {
        if (
          !isEmpty(value) &&
          !isRequireKeysValueTruthy(value, ['name', 'email'])
        )
          return Promise.reject(new Error(EXCEPTION_MESSAGE.missFields));
        if (!isEmpty(value) && !isValidAllEmail(value)) {
          return Promise.reject(new Error(EXCEPTION_MESSAGE.invalidValues));
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <>
      <div className='document-item-checkbox'>
        <FormItem name={type.fieldName} rules={rules}>
          <Checkbox
            onClick={() => handleSelectContactType(type)}
            className='item-checkbox'
            checked={type?.label === selectedContactType?.label}
          >
            <div>{type.label}</div>
            <Checkbox checked={!isEmpty(typeValue)} />
          </Checkbox>
        </FormItem>
      </div>
      <FormItem name={`${type.fieldName}Index`} hidden />
    </>
  );
};

ContactType.propTypes = {
  selectedContactType: {
    label: PropTypes.string,
    fieldName: PropTypes.string,
  },
  fieldName: PropTypes.string,
  handleSelectContactType: PropTypes.func,

  type: PropTypes.object,
};

export default ContactType;
