import React from 'react';

import { Table } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const CheckInCheckOutTable = ({
  columns,
  inforDetail,
  isLoading,
  ...props
}) => (
  <Table
    className='assign-table'
    columns={columns}
    dataSource={inforDetail}
    pagination={false}
    loading={isLoading}
    {...props}
  />
);

export default CheckInCheckOutTable;

CheckInCheckOutTable.propTypes = {
  columns: PropTypes.array,
  isLoading: PropTypes.bool,
  inforDetail: PropTypes.array,
};

CheckInCheckOutTable.defaultProps = {
  columns: [],
  isLoading: false,
  inforDetail: [],
};
