import request from '@/request';

const validateApplication = async ({ id, phone, email }) => {
  const { data } = await request.get('/guests/applicants/validate', {
    params: {
      id,
      phone,
      email,
    },
  });
  return data?.data;
};

export default validateApplication;
