import { useCallback, useState } from 'react';

import { isEmpty } from 'lodash';

import { getBase64 } from '@/utils/uploadFileUtil';

const switchFileType = (type) => {
  switch (type) {
    case 'image/png':
    case 'image/jpeg':
      return 'image';
    case 'application/pdf':
      return 'application';
    default:
      return type;
  }
};

export const useUploadFile = () => {
  const [url, setFileUrl] = useState();
  const [type, setFileType] = useState();
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState();

  const changeFile = useCallback((info) => {
    if (!isEmpty(info)) {
      setFileType(switchFileType(info.file.type));
      setFileList(info.fileList);
      if (info.file.status === 'uploading') {
        return setFileUrl('');
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (value) => setFileUrl(value));

        setFile(info.file);
      }
    }

    return setFileUrl('');
  }, []);

  return [url, changeFile, fileList, type, setFileUrl, file];
};

export const useUploadFileS3 = () => {
  const [base64, setBase64] = useState();
  const [file, setFile] = useState();
  const [type, setFileType] = useState();
  const [fileList, setFileList] = useState([]);

  const changeFile = useCallback((info) => {
    if (!isEmpty(info)) {
      setFileType(switchFileType(info.file.type));
      setFileList(info.fileList);
      if (info.file.status === 'uploading') {
        return setBase64('');
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        setFile(info);

        getBase64(info.file.originFileObj, (value) => setBase64(value));
      }
    }

    return setBase64('');
  }, []);

  return {
    base64,
    setBase64,
    fileList,
    changeFile,
    type,
    file,
    setFile,
    setFileList,
  };
};
