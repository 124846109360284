/* eslint-disable import/order */
import React, { memo, useState } from 'react';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { Modal as AntdModal, Card, Divider, Input, Spin, message } from 'antd';
import PropTypes from 'prop-types';

import Button from '@/components/Button';

import './styles.scss';
import { useFetch } from '@/hooks';
import {
  addAllocateWeeks,
  getDetailRota,
  getEmployeesSeeker,
  deleteAllocateWeeks,
} from '@/services/rota';

import Table from '../Table';
import { debounce, isEmpty, sortBy } from 'lodash';
import { useMutation } from 'react-query';
import Image from '../ImageAllocate';
import { icons } from '@/constants';
import { ModalConfirm } from '..';

const Modal = ({
  children,
  visible,
  onClickNo,
  onClickYes,
  isShowFooter,
  isWarning,
  rotaId = 0,
  refetchGetRota,
  ...props
}) => {
  const [currentRecord, setCurrentRecord] = useState({});
  const [employeesSeekerName, setEmployeesSeekerName] = useState('');
  const [selectedForAllocation, setSelectedForAllocation] = useState([]);
  const [showAllocate, setShowAllocate] = useState(false);
  const [deleteWorker, setDeleteWorker] = useState(false);
  const [workerId, setWorkerId] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const handleCellClick = (record, rowIndex) => {
    setSelectedCell(rowIndex);
  };
  const column = [
    {
      title: 'Pattern',
      dataIndex: 'pattern',
      key: 'pattern',
      width: 100,
      onCell: (record, rowIndex) => ({
        onClick: () => handleCellClick(record, rowIndex),
        style: {
          background: rowIndex === selectedCell ? '#84BAAB' : 'transparent',
        },
      }),
    },
    {
      title: 'Target on Rota',
      dataIndex: 'numberTargetRotas',
      key: 'numberTargetRotas',
      width: 100,
    },
    {
      title: 'Total on Rota',
      dataIndex: 'totalRotas',
      key: 'totalRotas',
      width: 100,
    },
  ];

  const {
    data: dataDetail,
    isFetching,
    refetch: refetchRotaDetail,
  } = useFetch(['getRotaDetail', rotaId], () => getDetailRota(rotaId), {
    enabled: !!rotaId,
  });

  const { data: dataEmployeesSeeker, isFetching: isFetchEmployeesSeeker } =
    useFetch(
      ['getEmployeesSeeker', employeesSeekerName],
      () => getEmployeesSeeker(employeesSeekerName),
      {
        enabled: !!employeesSeekerName,
      },
    );

  const dataAllocatedWorkers = sortBy(
    dataDetail?.employeesRotasAttributes ?? [],
    ['pattern'],
  );

  const {
    mutate: mutateCreateAllocate,
    isLoading,
    isSuccess,
    reset: resetCreate,
  } = useMutation(
    (id) => {
      const employeesRotasAttributes = selectedForAllocation.map((item) => ({
        employeeId: Number(item.employeeId),
      }));

      return addAllocateWeeks({ employeesRotasAttributes, id });
    },
    {
      onSuccess: () => {
        refetchRotaDetail();
        refetchGetRota();
        setSelectedForAllocation([]);
      },
      onError: (error) => {
        handleCloseModalCreate();
        message.error(error.message);
      },
    },
  );

  const {
    mutate: mutateDeleteWorker,
    isSuccess: successDeleteWorker,
    isLoading: loadingDeleteWorker,
    reset: resetDelete,
  } = useMutation(
    (id) => {
      const employeesRotasAttributes = dataAllocatedWorkers.map((item) => {
        const { id: idWorkder, employee } = item;
        if (idWorkder === id) {
          return {
            id: idWorkder,
            employeeId: Number(employee.id),
            _destroy: true,
          };
        }

        return { id: idWorkder, employeeId: Number(employee.id) };
      });
      return deleteAllocateWeeks({
        employeesRotasAttributes,
        id,
      });
    },
    {
      onSuccess: () => {
        refetchRotaDetail();
        refetchGetRota();
        setSelectedForAllocation([]);
      },
    },
  );

  const searchEmployeesSeeker = debounce(
    (event) => {
      setEmployeesSeekerName(event.target.value);
    },
    [250],
  );

  const selectForAllocation = (data) => {
    const exists = selectedForAllocation.some((item) => item.id === data.id);
    if (!exists) {
      setSelectedForAllocation([...selectedForAllocation, data]);
      setEmployeesSeekerName('');
    }
  };

  const removeFromAllocation = (data) => {
    const newData = selectedForAllocation.filter((item) => item.id !== data.id);
    setSelectedForAllocation(newData);
  };

  const handleCloseModalCreate = () => {
    setShowAllocate(false);
    resetCreate();
  };

  const handleCloseModalDelete = () => {
    setDeleteWorker(false);
    resetDelete();
  };

  return (
    <AntdModal
      visible={visible}
      centered
      onCancel={() => {
        onClickNo();
        setCurrentRecord({});
        setSelectedForAllocation([]);
        setEmployeesSeekerName('');
      }}
      wrapClassName='modal-container'
      className='rota-status-modal-container'
      footer={null}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Spin spinning={isFetching}>
        <h1 className='text-title'>Allocate workers to a Rota</h1>
        <p className='text-content'>{dataDetail?.title}</p>
        <div className='table-content'>
          <Table
            className='allocate-rota-table'
            columns={column}
            infoDetail={dataDetail?.rotaWeeks}
            rowKey='id'
            onCell={(record) => ({
              disabled: true,
            })}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                setCurrentRecord(record);
              },
            })}
          />
          <Card style={{ width: '100%', marginLeft: '30px' }}>
            <h4>Allocated Workers</h4>
            <Divider />
            {!isEmpty(dataAllocatedWorkers) && (
              <>
                {dataAllocatedWorkers.map((item) => {
                  const { id, employee } = item;
                  return (
                    <div className='allocate-container'>
                      <li key={id} className={classNames('seeker-popout')}>
                        <div className='pattern'>{item?.pattern}</div>
                        <Image
                          containerClassName='ava-container'
                          className='seeker-img'
                          src={employee.profilepic}
                          alt='avatar'
                        />
                        <div className='info-container'>
                          <h3>{employee.fullname}</h3>
                        </div>

                        <div className='status-icon-container'>
                          <InfoCircleOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            className='status-icon'
                          />
                        </div>
                      </li>
                      <div className='delete-icon-container'>
                        <CloseOutlined
                          onClick={() => {
                            setDeleteWorker(true);
                            setWorkerId(id);
                          }}
                          className='delete-icon'
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </Card>
          {currentRecord?.id && (
            <Card style={{ width: '100%', marginLeft: '30px' }}>
              <h4>Search and Allocate</h4>
              <Divider />
              <Input
                className='input-height'
                placeholder='Name'
                onChange={searchEmployeesSeeker}
              />
              <Spin spinning={isFetchEmployeesSeeker}>
                {!isEmpty(dataEmployeesSeeker) && (
                  <>
                    {dataEmployeesSeeker.map((item) => (
                      <div className='allocate-container'>
                        {
                          // eslint-disable-next-line max-len
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                          <li
                            key={item.key}
                            onClick={() => selectForAllocation(item)}
                            className={classNames('seeker-popout')}
                          >
                            <Image
                              containerClassName='list-container'
                              className='seeker-img'
                              src=''
                              alt='avatar'
                            />
                            <div className='info-container'>
                              <h3>{item.fullname}</h3>
                              <div className='icons'>
                                {icons.map((icon) => (
                                  <img
                                    className={classNames(
                                      'seeker-icon',
                                      icon.className,
                                    )}
                                    src={icon.src}
                                    alt={icon.className}
                                    key={icon.className}
                                  />
                                ))}
                              </div>
                            </div>
                          </li>
                        }
                      </div>
                    ))}
                  </>
                )}
              </Spin>
              <h4 style={{ marginTop: '20px' }}>Allocate</h4>
              <Divider />
              {selectedForAllocation.map((item) => (
                <div className='allocate-container'>
                  <li key={item.key} className={classNames('seeker-popout')}>
                    <Image
                      containerClassName='all-container'
                      className='seeker-img'
                      src=''
                      alt='avatar'
                    />
                    <div className='info-container'>
                      <h3>{item.fullname}</h3>
                    </div>
                  </li>
                  <div className='delete-icon-container'>
                    <CloseOutlined
                      onClick={() => removeFromAllocation(item)}
                      className='delete-icon'
                    />
                  </div>
                </div>
              ))}
              {selectedForAllocation.length > 0 && (
                <Button
                  onClick={() => setShowAllocate(true)}
                  className='yellow-button'
                >
                  Allocate
                </Button>
              )}
            </Card>
          )}
        </div>
      </Spin>
      <ModalConfirm
        title='Allocate Workers'
        titleSuccess='Create allocate workers successfully'
        visible={showAllocate}
        onClickNo={handleCloseModalCreate}
        onClickYes={() =>
          isSuccess
            ? handleCloseModalCreate()
            : mutateCreateAllocate(currentRecord?.id)
        }
        isLoading={isLoading}
        isSuccess={isSuccess}
      >
        Are you sure you want to Allocate all the selected workers onto this
        Rota Pattern?
        <br />
        All workers will receive the next 12 weeks provisional rota patterns in
        their Apps.
      </ModalConfirm>
      <ModalConfirm
        visible={deleteWorker}
        title='Delete Workers'
        titleSuccess='Delete workers successfully'
        onClickNo={handleCloseModalDelete}
        onClickYes={() =>
          successDeleteWorker
            ? handleCloseModalDelete()
            : mutateDeleteWorker(workerId)
        }
        isSuccess={successDeleteWorker}
        isLoading={loadingDeleteWorker}
      >
        Are you sure you want to Delete the selected worker from this Rota?
        <br />
        All future provisional shifts will be removed from their App
      </ModalConfirm>
    </AntdModal>
  );
};

Modal.propTypes = {
  children: PropTypes.string,
  visible: PropTypes.bool,
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
  isShowFooter: PropTypes.bool,
  isWarning: PropTypes.bool,
  rotaId: PropTypes.number,
  refetchGetRota: PropTypes.func,
};

Modal.defaultProps = {
  children: '',
  visible: false,
  onClickNo: () => {},
  onClickYes: () => {},
  isShowFooter: true,
  isWarning: true,
  refetchGetRota: () => {},
};

export default memo(Modal);
