import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useToggle } from 'usehooks-ts';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { FormItem, Input, InputNumber, Modal, Select } from '@/components';
import { COST_ROLES } from '@/constants';

import { formatNumber, handleChangeNetCosts } from '../utils/handle';

const CostsSectionItem = ({ name, remove }) => {
  const formInstance = Form.useFormInstance();
  const [visibleDeleteModal, toggleDeleteModal] = useToggle();

  const handleCalNetGross = () =>
    handleChangeNetCosts({ formInstance, name: ['cost', `${name}`] });
  return (
    <div className='left-section card'>
      <FormItem name={[name, 'description']} label='Description' required>
        <Input placeholder='Description' />
      </FormItem>
      <FormItem name={[name, 'service']} label='Service' required>
        <Select size='large' placeholder='Service' options={COST_ROLES} />
      </FormItem>
      <FormItem
        name={[name, 'netCost']}
        label='Net Cost'
        required
        className='small-width'
      >
        <InputNumber
          placeholder='Net Cost'
          min={0}
          formatter={(numberValue, { userTyping }) =>
            formatNumber({ numberValue, userTyping, prefix: '£' })
          }
          onChange={handleCalNetGross}
        />
      </FormItem>
      <FormItem
        name={[name, 'grossCost']}
        label='GROSS Cost'
        required
        className='small-width'
      >
        <InputNumber
          placeholder='GROSS Cost'
          min={0}
          disabled
          formatter={(numberValue, { userTyping }) =>
            formatNumber({ numberValue, userTyping, prefix: '£' })
          }
        />
      </FormItem>
      <div className='exit-box'>
        <ClosedIcon onClick={toggleDeleteModal} />
      </div>
      <Modal
        visible={visibleDeleteModal}
        onClickNo={toggleDeleteModal}
        onClickYes={() => remove(name)}
      >
        Are you sure to want to delete this section ?
      </Modal>
    </div>
  );
};

export default CostsSectionItem;

CostsSectionItem.propTypes = {
  name: PropTypes.string,
  remove: PropTypes.func,
};

CostsSectionItem.defaultProps = {
  name: '',
  remove: () => {},
};
