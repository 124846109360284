import { message } from 'antd';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

const NOT_FOUND_CODE = 404;

/**
 * @return {import("react-query").UseQueryResult<object|Array,{status:number, message:string}>}
 */
const useFetch = (
  /** @type {any} */ queryKey,
  /** @type {import("react-query").QueryFunction<unknown, any>} */ queryFn,
  // eslint-disable-next-line max-len
  /** @type {Omit<import("react-query").UseQueryOptions<unknown, {status:number, message:string}, unknown, any>, "queryKey" | "queryFn"> | undefined} */ options = {},
) => {
  const history = useHistory();
  const { parentPath, ...queryOptions } = options;
  const state = useQuery(queryKey, queryFn, {
    onError: (error) => {
      message.error(error?.message);
      if (error.status === NOT_FOUND_CODE && parentPath) {
        history.push(parentPath);
      }
    },
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

  return state;
};

export default useFetch;
