import { find } from 'lodash';

import { EVENT_STATUSES, STATUS_KEY } from '../constants';

const getJobPostedStatus = (
  /** @type {number} */ totalFills,
  /** @type {number} */ totalAssignments,
) => {
  if (totalFills) {
    return totalFills === totalAssignments
      ? STATUS_KEY.filled
      : STATUS_KEY.notFilled;
  }
  return STATUS_KEY.posted;
};

const getBackgroundColor = (
  /** @type {number} */ status,
  /** @type {number} */ totalFills,
  /** @type {number} */ totalAssignments,
) => {
  if (status !== EVENT_STATUSES.posted.value) {
    return find(Object.values(EVENT_STATUSES), { value: status })?.className;
  }
  return getJobPostedStatus(totalFills, totalAssignments);
};

export { getBackgroundColor };
