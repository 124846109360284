import request from '@/request';

const allocateWorkers = async ({ templateId, payload = {} }) => {
  const res = await request.patch(
    `pay_templates/payroll_templates/${templateId}/allocate`,
    {
      payroll_template: {
        ...payload,
      },
    },
  );

  return res.data?.data;
};

export default allocateWorkers;
