import React, { useEffect, useState } from 'react';

import { Form, message } from 'antd';
import classNames from 'classnames';
import { debounce, isArray, isEmpty } from 'lodash';
import moment from 'moment';

import {
  Button,
  Checkbox,
  DatePicker,
  FormItem,
  Input,
  ModalDocFields,
  Radio,
  Select,
  UploadFileThumbnail,
} from '@/components';
import Upload from '@/components/Upload';
import { DEBOUNCE_WAIT, ENABLE_DATES_AFTER_DATE_NUMBER } from '@/constants';
import {
  useNationalities,
  useCheckUkIreland,
} from '@/features/nationality/hooks';
import { useUploadFileS3 } from '@/hooks/useUploadFile';
import { date } from '@/utils';
import { rulesSelect } from '@/utils/rulesInput';
import { beforeUploadFile, dummyRequest } from '@/utils/uploadFileUtil';
import useModal from '@/utils/useModal';

const rightToWorkTypes = [
  {
    value: 'passportId1File',
    label: 'Passport',
  },
  {
    value: 'visaRtw',
    label: 'Visa',
  },
  {
    value: 'universityTermDateLetterRtw',
    label: 'University Term Date Letter',
  },
];

const RightToWorkSection = () => {
  const formInstance = Form.useFormInstance();
  const [visible, showModal, hideModal] = useModal();
  const [rightToWorkType, setRightToWorkType] = useState();
  const [fileState, setFileState] = useState({
    field: '',
    label: '',
    value: undefined,
    file: undefined,
  });

  const { base64, setBase64, fileList, changeFile, file, setFile } =
    useUploadFileS3();
  const [passportCheckBox, setPassportCheckBox] = useState(false);
  const [shareCodeCheckBox, setShareCodeCheckBox] = useState(false);
  const [studentHolidayCheckBox, setStudentHolidayCheckBox] = useState(false);
  const { nationalities } = useNationalities();
  const checkUkIreland = useCheckUkIreland();
  const limitVisa = Form.useWatch(
    ['vacancyApplicantAttributes', 'limitVisa'],
    formInstance,
  );
  const employeeStudent = Form.useWatch('employeeStudent', formInstance);
  const passportId1FileValue = Form.useWatch(
    'passportId1FileValue',
    formInstance,
  );
  const universityLetterFileValue = Form.useWatch(
    'universityLetterFileValue',
    formInstance,
  );
  const shareCodeFileValue = Form.useWatch('shareCodeFileValue', formInstance);
  const employeeWpExpiry = Form.useWatch('employeeWpExpiry', formInstance);
  const nationality = Form.useWatch(
    ['vacancyApplicantAttributes', 'nationality'],
    formInstance,
  );
  const isUkOrIreland = checkUkIreland(nationality);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const studentList = Form.useWatch(
    'studentHolidaysAttributes',
    formInstance,
  ) ?? [
    {
      name: 0,
      key: 0,
      isListField: true,
      fieldKey: 0,
    },
  ];

  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (isArray(studentList) && !isEmpty(studentList)) {
      const lastIndex = studentList.length - 1;
      if (studentList.at(lastIndex)?.startDate) {
        setStartDate(studentList.at(lastIndex)?.startDate);
      }
      if (studentList.at(lastIndex)?.endDate) {
        setEndDate(studentList.at(lastIndex)?.endDate);
      }
    }
  }, [studentList]);

  const onChangeNationality = (item) => {
    const value = checkUkIreland(item);

    if (value) {
      formInstance.setFieldsValue({
        limitVisa: false,
        omniEmployeeAttributes: { employeeStudent: 'not_student' },
        rightToWorkDocs: [
          {
            fileBlob: {},
            fileValue: undefined,
            typeValue: 'passportId1File',
            typeLabel: 'Passport',
          },
          {
            fileBlob: {},
            fileValue: undefined,
            typeValue: 'birthCertificate',
            typeLabel: 'Birth Certificate',
          },
          {
            fileBlob: {},
            fileValue: undefined,
            typeValue: 'niProof',
            typeLabel: 'NI Proof',
          },
        ],
      });
    } else {
      formInstance.setFieldsValue({
        rightToWorkDocs: [],
      });
    }
  };

  // if change field and change field name
  useEffect(() => {
    if (
      base64 &&
      fileState.field &&
      fileState.field === 'universityLetterFileValue'
    ) {
      formInstance.setFieldsValue({
        universityLetterFileValue: base64,
        universityLetterFileBlob: file,
      });

      setFileState((prev) => ({ ...prev, value: base64, file }));
      setBase64();
      setFile();
    } else if (
      base64 &&
      fileState.field &&
      fileState.field === 'shareCodeFileValue'
    ) {
      formInstance.setFieldsValue({
        shareCodeFileValue: base64,
        shareCodeFileBlob: file,
      });

      setFileState((prev) => ({ ...prev, value: base64, file }));
      setBase64();
      setFile();
    } else if (
      base64 &&
      fileState.field &&
      fileState.field === 'passportId1FileValue'
    ) {
      formInstance.setFieldsValue({
        passportId1FileValue: base64,
        passportId1FileBlob: file,
      });

      setFileState((prev) => ({ ...prev, value: base64, file }));
      setBase64();
      setFile();
    } else if (base64 && fileState.field) {
      const rightToWorkDocs =
        formInstance.getFieldValue('rightToWorkDocs') ?? [];

      const findIndex = rightToWorkDocs.findIndex(
        (doc) => doc.typeValue === fileState.field,
      );

      if (findIndex === -1) return;

      rightToWorkDocs[findIndex] = {
        ...rightToWorkDocs[findIndex],
        checked: true,
        fileValue: base64,
        fileBlob: file,
      };

      formInstance.setFieldsValue({
        rightToWorkDocs,
      });

      setFileState((prev) => ({ ...prev, value: base64, file }));
      setBase64();
      setFile();
    }
  }, [base64, fileState.field, formInstance, setBase64, file, setFile]);

  const handleAdd = () =>
    formInstance.setFieldsValue({
      studentHolidaysAttributes: [
        ...studentList,
        {
          name: studentList.length,
          key: studentList.length,
          isListField: true,
          fieldKey: studentList.length,
        },
      ],
    });

  const handleAddRightToWorkType = debounce(() => {
    if (rightToWorkType) {
      const prevValues = formInstance.getFieldValue('rightToWorkDocs') ?? [];

      const type =
        rightToWorkTypes.find((t) => t.value === rightToWorkType) ?? {};

      const value = prevValues.find((t) => t.typeValue === type.value);

      if (value) {
        return message.success('Already exist');
      }

      formInstance.setFieldsValue({
        rightToWorkDocs: [
          ...prevValues,
          {
            typeValue: type.value, //  this is field name
            typeLabel: type.label,
            fileValue: undefined, // default when add to list is empty object
            checked: false,
          },
        ],
      });

      setRightToWorkType(undefined);
      hideModal();
    }

    return undefined;
  }, DEBOUNCE_WAIT);

  const handleRightToWorkTypeChange = ({ target }) => {
    setRightToWorkType(target.value);
  };

  const handleSelectDoc = (index) => {
    setPassportCheckBox(false);
    setShareCodeCheckBox(false);
    setStudentHolidayCheckBox(false);

    const rightToWorkDocs = formInstance.getFieldValue('rightToWorkDocs') ?? [];

    if (!rightToWorkDocs) {
      return;
    }

    if (rightToWorkDocs[index].checked) {
      return;
    }

    // get fieldName
    const { fileValue, typeLabel, typeValue, fileBlob } =
      rightToWorkDocs[index];

    setFileState({
      field: typeValue,
      label: typeLabel,
      value: fileValue,
      file: fileBlob,
    });

    const newRightToWorkDocs = rightToWorkDocs.map((doc, idx) => ({
      ...doc,
      checked: index === idx,
    }));

    formInstance.setFieldsValue({
      rightToWorkDocs: newRightToWorkDocs,
    });
  };

  const handlePassportCheckBoxChange = ({ target }) => {
    setPassportCheckBox(target.checked);
    setStudentHolidayCheckBox(false);
    setShareCodeCheckBox(false);

    if (target.checked) {
      setFileState({
        field: 'passportId1FileValue',
        label: 'Passport',
        value: passportId1FileValue,
      });
    } else {
      setFileState({
        field: '',
        label: '',
        value: undefined,
      });
    }

    const rightToWorkDocs = formInstance.getFieldValue('rightToWorkDocs') ?? [];
    const newRightToWorkDocs = rightToWorkDocs.map((doc) => ({
      ...doc,
      checked: false,
    }));

    formInstance.setFieldsValue({
      rightToWorkDocs: newRightToWorkDocs,
    });
  };

  const handleShareCodeCheckBoxChange = ({ target }) => {
    setShareCodeCheckBox(target.checked);
    setStudentHolidayCheckBox(false);
    setPassportCheckBox(false);

    if (target.checked) {
      setFileState({
        field: 'shareCodeFileValue',
        label: 'Share Code',
        value: shareCodeFileValue,
      });
    } else {
      setFileState({
        field: '',
        label: '',
        value: undefined,
      });
    }

    const rightToWorkDocs = formInstance.getFieldValue('rightToWorkDocs') ?? [];
    const newRightToWorkDocs = rightToWorkDocs.map((doc) => ({
      ...doc,
      checked: false,
    }));

    formInstance.setFieldsValue({
      rightToWorkDocs: newRightToWorkDocs,
    });
  };

  const handleStudentHolidayCheckBoxChange = ({ target }) => {
    setStudentHolidayCheckBox(target.checked);
    setShareCodeCheckBox(false);
    setPassportCheckBox(false);
    if (target.checked) {
      setFileState({
        field: 'universityLetterFileValue',
        label: 'Student Holiday',
        value: universityLetterFileValue,
      });
    } else {
      setFileState({
        field: '',
        label: '',
        value: undefined,
      });
    }

    const rightToWorkDocs = formInstance.getFieldValue('rightToWorkDocs') ?? [];
    const newRightToWorkDocs = rightToWorkDocs.map((doc) => ({
      ...doc,
      checked: false,
    }));

    formInstance.setFieldsValue({
      rightToWorkDocs: newRightToWorkDocs,
    });
  };

  return (
    <div className='block-container'>
      <h3>Right to Work</h3>
      <div className='rtw'>
        <div className='left'>
          <FormItem
            name={['vacancyApplicantAttributes', 'nationality']}
            rules={rulesSelect('Nationality')}
            label='Nationality'
          >
            <Select
              options={nationalities}
              placeholder='Nationality'
              onChange={onChangeNationality}
            />
          </FormItem>

          {/* TODO: need to update name for this field */}
          <FormItem className='uk-or-another'>
            <Radio
              options={[
                { value: true, label: 'UK/Ireland' },
                { value: false, label: 'Rest of the World' },
              ]}
              value={isUkOrIreland}
            />
          </FormItem>

          {!isUkOrIreland && (
            <>
              <div className='share-code'>
                <FormItem className='share-code-checkbox-item'>
                  <Checkbox
                    checked={shareCodeCheckBox}
                    onChange={handleShareCodeCheckBoxChange}
                    className='share-code-checkbox'
                  >
                    <span>Share Code</span>
                    <Checkbox checked={shareCodeFileValue} />
                  </Checkbox>
                </FormItem>

                <FormItem name='shareCodeFileBlob' noStyle />
                <FormItem name='shareCodeFileValue' noStyle />

                <FormItem
                  name='employeeWpExpiry'
                  normalize={date.convertDateTime}
                >
                  <DatePicker
                    name='employeeWpExpiry'
                    normalize={date.convertDateTime}
                    handleChangeYear={() => {
                      formInstance.validateFields();
                    }}
                  />
                </FormItem>
                <FormItem
                  name={['vacancyApplicantAttributes', 'shareCode']}
                  normalize={(codeText) => codeText.toUpperCase()}
                  rules={[
                    {
                      required: true,
                      message: '9 characters',
                    },
                    {
                      pattern: /^[a-zA-Z0-9]{9}$/i,
                      message: '9 characters',
                    },
                  ]}
                >
                  <Input placeholder='Share Code from form' />
                </FormItem>

                {employeeWpExpiry &&
                  moment()
                    .add(ENABLE_DATES_AFTER_DATE_NUMBER, 'days')
                    .isAfter(employeeWpExpiry) && (
                    <p className='share-code-warning'>
                      The expiration date is less than 3 months
                    </p>
                  )}
              </div>

              <p className='long-label'>
                Are there limitations on the visa? Eg. Student 20 hours or
                limited employers
              </p>
              <FormItem
                className='limitations-visa'
                name={['vacancyApplicantAttributes', 'limitVisa']}
              >
                <Radio
                  className='radio-checkbox'
                  options={[
                    { value: 1, label: 'Yes' },
                    { value: 0, label: 'No' },
                  ]}
                />
              </FormItem>

              {limitVisa ? (
                <>
                  <FormItem
                    className='limitation-hours'
                    label='Are they on a student visa with limited hours?'
                    name='employeeStudent'
                  >
                    <Radio
                      options={[
                        { value: 'student', label: 'Yes' },
                        { value: 'no_student', label: 'No' },
                      ]}
                    />
                  </FormItem>

                  {!!employeeStudent && (
                    <div className='maximum-weekly-hours'>
                      <span>Maximum weekly hours</span>

                      <FormItem name='studentMaxHours'>
                        <Input defaultValue={20} />
                      </FormItem>
                    </div>
                  )}

                  {!employeeStudent && (
                    <div className='other-visa-restrictions'>
                      <FormItem
                        label='Other visa restrictions'
                        name={['vacancyApplicantAttributes', 'restriction']}
                      >
                        <Input />
                      </FormItem>

                      <FormItem
                        label='Limited hours'
                        name={[
                          'vacancyApplicantAttributes',
                          'visaRestrictionsLimitedHours',
                        ]}
                      >
                        <Input />
                      </FormItem>
                    </div>
                  )}
                </>
              ) : (
                <div className='passport-checkbox-and-date'>
                  <FormItem className='passport-checkbox-item'>
                    <Checkbox
                      checked={passportCheckBox}
                      onChange={handlePassportCheckBoxChange}
                      className='passport-checkbox'
                    >
                      <span>Passport</span>
                      <Checkbox checked={passportId1FileValue} />
                    </Checkbox>
                  </FormItem>
                  <FormItem name='passportId1FileBlob' noStyle />
                  <FormItem name='passportId1FileValue' noStyle />

                  <FormItem
                    className='passport-date-picker'
                    name='employeeId1Expiry'
                  >
                    <DatePicker
                      name='employeeId1Expiry'
                      normalize={date.convertDateTime}
                    />
                  </FormItem>
                </div>
              )}
            </>
          )}
        </div>

        <div className='right'>
          <FormItem
            className='style-container'
            label={fileState.label}
            name={fileState.field}
          >
            <UploadFileThumbnail src={fileState.value} file={fileState.file} />

            <Upload
              name='file'
              showUploadList={false}
              customRequest={dummyRequest}
              beforeUpload={beforeUploadFile}
              onChange={changeFile}
              fileList={fileList}
              disabled={!fileState.field}
              required
            >
              <div className='choose-file'>
                <Button disabled={!fileState.field} className='white-button'>
                  Choose File
                </Button>
                {!fileState.value && <span>No file chosen</span>}
              </div>
            </Upload>
          </FormItem>
        </div>

        {!!limitVisa && !!employeeStudent && (
          <div className='bottom'>
            <FormItem name='universityLetterFileBlob' noStyle />
            <FormItem name='universityLetterFileValue' noStyle />

            <Form.List name='studentHolidaysAttributes'>
              {() => (
                <div className='student-holidays'>
                  <div className='student-actions'>
                    <FormItem className='student-holiday-checkbox-item'>
                      <Checkbox
                        checked={studentHolidayCheckBox}
                        onChange={handleStudentHolidayCheckBoxChange}
                        className='student-holiday-checkbox'
                      >
                        <span>Add Student Holiday</span>
                        <Checkbox checked={universityLetterFileValue} />
                      </Checkbox>
                    </FormItem>

                    <div className='add-student-btn'>
                      <Button
                        className='btn-add ant-btn input-height yellow-button'
                        onClick={handleAdd}
                        disabled={!(startDate && endDate)}
                      >
                        Add Student Holiday
                      </Button>
                    </div>
                  </div>

                  <div className='list-holiday'>
                    {studentList.map((field, index) => {
                      const { length } = studentList;
                      const endDateVal = studentList[length - 1]?.endDate;

                      const disableStartDate = (disabledDate) => {
                        if (endDateVal) {
                          const afterEndDate = moment(endDateVal).add(
                            1,
                            'days',
                          );

                          const isLessThanEndDate =
                            disabledDate >= afterEndDate;

                          if (isLessThanEndDate) {
                            return true;
                          }
                        }

                        return false;
                      };

                      const disableEndDate = (disabledDate) => {
                        if (startDate) {
                          const isLessThanStartDate =
                            disabledDate <= moment(startDate);
                          if (isLessThanStartDate) {
                            return true;
                          }
                        }

                        return false;
                      };

                      return (
                        <div className='student-holiday-dates' key={field.key}>
                          <FormItem
                            name={[index, 'startDate']}
                            normalize={date.convertDateTime}
                          >
                            <DatePicker
                              disabledDate={(disabledDate) =>
                                disableStartDate(disabledDate)
                              }
                              onChange={(dateValue) => setStartDate(dateValue)}
                              value={startDate}
                              name={[index, 'startDate']}
                            />
                          </FormItem>
                          <span>to</span>

                          <FormItem
                            name={[index, 'endDate']}
                            normalize={date.convertDateTime}
                          >
                            <DatePicker
                              disabledDate={(disabledDate) =>
                                disableEndDate(disabledDate)
                              }
                              onChange={(dateValue) => setEndDate(dateValue)}
                              value={endDate}
                              name={[index, 'endDate']}
                            />
                          </FormItem>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Form.List>
          </div>
        )}

        <div className={classNames('documents', { 'grid-doc': isUkOrIreland })}>
          <Form.List name='rightToWorkDocs'>
            {(fields) => (
              <>
                {fields.map((field, index) => {
                  const value =
                    formInstance.getFieldValue('rightToWorkDocs')[index];

                  return (
                    <React.Fragment key={field.key}>
                      <FormItem name={[field.name, 'fileBlob']} noStyle />
                      <FormItem name={[field.name, 'fileValue']} noStyle />
                      <FormItem name={[field.name, 'typeValue']} noStyle />
                      <FormItem name={[field.name, 'typeLabel']} noStyle />

                      <div className='document-item-checkbox'>
                        <Checkbox
                          onClick={() => handleSelectDoc(index)}
                          className='item-checkbox'
                          checked={value?.checked}
                        >
                          <div>{value.typeLabel}</div>
                          <Checkbox checked={!isEmpty(value.fileValue)} />
                        </Checkbox>
                      </div>
                    </React.Fragment>
                  );
                })}

                {!isUkOrIreland && (
                  <Button
                    onClick={showModal}
                    className='yellow-button add-another-document'
                  >
                    Add Another Document
                  </Button>
                )}
              </>
            )}
          </Form.List>
          <ModalDocFields
            visible={visible}
            onClickYes={handleAddRightToWorkType}
            onClickNo={hideModal}
            isWarning={false}
            className='modal-for-types'
          >
            <Radio
              className='types'
              value={rightToWorkType}
              onChange={handleRightToWorkTypeChange}
              options={!isUkOrIreland ? rightToWorkTypes : []}
            />
          </ModalDocFields>
        </div>
      </div>
    </div>
  );
};

export default RightToWorkSection;
