import normalize from 'json-api-normalizer';
import _ from 'lodash';
import moment from 'moment';

import {
  DATA_PAYPMENT_REPORT_KEYS,
  DATA_PROTECTION_KEYS,
  DEFAULT_RATES,
} from '@/constants';
import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const createRequestObject = ({
  dataFormated,
  providerId,
  logoThumbnail,
  lastPostingJob,
  contractFile,
}) => {
  const {
    chargeRateProperty,
    comment,
    contact,
    employer,
    chargeRate,
    employerIndustry,
    employerLocation,
    jobTemplates,
    employerTrustedEmployee,
    employerBlockedEmployee,
  } = dataFormated;

  const jobTemplatesTable = jobTemplates?.map((item) => ({
    positionId: item.positionId,
    locationId: item.locationId,
  }));

  const attrs = employer[`${providerId}`]?.attributes || {};

  const paymentReport = [];
  const dataProtection = [];
  const contactForAccounts = [];
  const contactForBookings = [];
  const contactForInvoices = [];
  const contactForTimesheets = [];
  let contactUser = {
    id: null,
    email: null,
    name: null,
    phone: null,
    jobTitle: null,
    department: null,
  };
  let contactEmployer = {
    id: null,
    email: null,
    name: null,
    phone: null,
    jobTitle: null,
    department: null,
  };

  const {
    card,
    hear,
    note,
    name,
    baCity,
    baStreet,
    status,
    abbrvName,
    creditCard,
    baBuilding,
    baPostcode,
    vatNumber,
    customerSageCode,
    companyNumber,
    // clientNumber, // TODO: Update when backend update client name for table Lead.
    createdAt,
    industry,
    officeId,
    showAwr,
    payWeekend,
    postingAlert,
    isVerifiedReadTerms,
    isVerifiedStoreAndUse,
    isAcceptedReceivingUpdates,
    paymentStartWeek,
  } = attrs;

  if (showAwr) {
    paymentReport.push(DATA_PAYPMENT_REPORT_KEYS.SHOW_AWR_REPORT);
  }

  if (payWeekend) {
    paymentReport.push(
      DATA_PAYPMENT_REPORT_KEYS.SUNDAY_TO_SATURDAY_PAYMENT_WEEK,
    );
  }

  if (isVerifiedReadTerms) {
    dataProtection.push(
      DATA_PROTECTION_KEYS.VERIFIED_READ_TERMS_AND_CONDITIONS,
    );
  }

  if (isVerifiedStoreAndUse) {
    dataProtection.push(DATA_PROTECTION_KEYS.VERIFIED_STORE_AND_USE);
  }

  if (isAcceptedReceivingUpdates) {
    dataProtection.push(DATA_PROTECTION_KEYS.ACCEPTED_RECIEVING_UPDATES);
  }

  Object.values(contact || {}).forEach(({ attributes, id }) => {
    switch (`${attributes.type}`) {
      case 'user':
        contactUser = { ...attributes, id };
        break;
      case 'omni_user':
        contactEmployer = { ...attributes, id };
        break;
      case 'account':
        contactForAccounts.push({
          ...attributes,
          id,
          contact: attributes.name,
        });
        break;
      case 'invoice':
        contactForInvoices.push({
          ...attributes,
          id,
          contact: attributes.name,
        });
        break;
      case 'booking':
        contactForBookings.push({
          ...attributes,
          id,
          contact: attributes.name,
        });
        break;
      case 'timesheet':
        contactForTimesheets.push({
          ...attributes,
          id,
          contact: attributes.name,
        });
        break;
      default:
        break;
    }
  });

  const chargeRatesAttributes = Object.values(chargeRate || {}).map(
    ({ attributes, id }) => ({
      id,
      ...attributes,
      position: attributes.skillId,
    }),
  );

  const chargeRateId = Object.values(chargeRateProperty ?? {}).at(0)?.id;
  const chargeRateProperties =
    Object.values(chargeRateProperty || {})
      // normalize create this property to an array with length is one
      .at(0)?.attributes ?? {};

  const {
    otherCost: otherCosts = DEFAULT_RATES.OTHER_COSTS,
    marginIsCostType = DEFAULT_RATES.MARGIN_COST_TYPE,
    pensionThreshold = DEFAULT_RATES.PENSION_THRESHOLD,
    holiday = DEFAULT_RATES.HOLIDAY_DAYS,
    employersNiHourWeek = DEFAULT_RATES.EMPLOYER_NI_HOURS_WEEK,
    employersNiPerWeekThreshold = DEFAULT_RATES.EMPLOYER_NI_THRESHOLD,
  } = chargeRateProperties;

  const locationsConverted = Object.values(employerLocation ?? {}).map(
    ({
      id,
      attributes: {
        city,
        street,
        postcode,
        name: locationName,
        building: buildingNumber,
      },
    }) => ({
      id,
      locationName,
      city,
      street,
      buildingNumber,
      postCode: postcode,
    }),
  );

  const historyComments = Object.values(comment ?? {}).map(
    ({
      id,
      attributes: { content, userId, createdAt: commentCreatedAt, createdBy },
    }) => ({
      id,
      key: id,
      content,
      userId,
      createdAt: commentCreatedAt,
      createdBy,
    }),
  );

  // property `name` is `id` of `manager`
  const accountManagerName =
    contactEmployer.name && Number(contactEmployer.name);

  const provider = {
    // Credit card details
    creditCard,

    // Company information
    city: baCity,
    street: baStreet,
    postCode: baPostcode,
    building: baBuilding,
    providerName: name,
    vatNumber,
    customerSageCode,
    registeredCompanyName: abbrvName,
    companyNumber,
    clientNumber: providerId,
    dateOfRegistration: moment(createdAt),

    // Contact Information
    contactId: contactUser.id,
    contactName: contactUser.name,
    contactEmail: contactUser.email,
    contactPhone: contactUser.phone,
    contactJobTitle: contactUser.jobTitle,
    contactDepartment: contactUser.department,
    accountManagerId: contactEmployer.id, // this is contact `id`.
    accountManagerName,
    accountManagerPhone: contactEmployer.phone,
    accountManagerSecondaryManagerEmail: contactEmployer.email,
    contactForAccounts,
    contactForBookings,
    contactForInvoices,
    contactForTimesheets,

    // Rates
    chargeRatesAttributes,
    chargeRateId,
    otherCosts,
    marginFixed:
      Number(chargeRateProperties.marginFixed) || DEFAULT_RATES.MARGIN_FIXED,
    marginPercent:
      Number(chargeRateProperties.marginPercent) * 100 ||
      DEFAULT_RATES.MARGIN_PERCENT,
    pensionPercent:
      Number(chargeRateProperties.pensionPercent) * 100 ||
      DEFAULT_RATES.PENSION_PERCENT,
    pensionThreshold,
    holidayDays: Number(holiday),
    apprenticeshipLevyPercent:
      Number(chargeRateProperties.apprenticeshipLevyPercent) * 100 ||
      DEFAULT_RATES.APPRENTICESHIP,
    employersNiPerWeekPercent:
      Number(chargeRateProperties.employersNiPerWeekPercent) * 100 ||
      DEFAULT_RATES.EMPLOYER_NI_PER_WEEK_PERCENT,
    employersNiPerWeekThreshold,
    employersNiHourWeek,
    marginIsCostType,

    // Job Temsplate
    jobTemplatesTable,
    jobTemplatesAttributes: jobTemplates,

    // Bank And Official Information
    postingAlerts: postingAlert,
    paymentReport,

    // Locations
    aboutUs: hear,
    industries: industry,
    industriesHidden: employerIndustry,
    locations: locationsConverted,

    // Comments And Status Updates
    dataProtection,
    historyComments,
    offices: officeId,
    status,
    note,

    employerTrustedEmployee: employerTrustedEmployee
      ? Object.values(employerTrustedEmployee).map((item) => item?.attributes)
      : [],

    employerBlockedEmployee: employerBlockedEmployee
      ? Object.values(employerBlockedEmployee).map((item) => item?.attributes)
      : [],

    paymentStartWeek,
    logoThumbnail,
    lastPostingJob,
    contractFile,
    chargeRatesList: chargeRatesAttributes,
  };

  const { name: nameOnCard, number, expMonth, expYear, cvc } = card;
  if (nameOnCard && expYear && expMonth) {
    const expiryDate = `${expMonth}/${expYear}`;

    provider.CVV = cvc;
    provider.nameOnCard = nameOnCard;
    provider.expiryDate = expiryDate;
    provider.longCardNumber = number;

    provider.oldCard = JSON.stringify({
      CVV: cvc,
      nameOnCard,
      expiryDate,
      longCardNumber: number,
    });
  }
  return provider;
};

/**
 *
 * @param {number} providerId
 * @returns
 */
const getOne = async (providerId) => {
  const res = await request.get(`providers/employers/${providerId}`);

  const dataFormated = normalize(res.data);
  const dataConvert = normalizeShowResponse(res);

  const { jobTemplates, logoThumbnail, lastPostingJob, contractFile } =
    dataConvert.data;

  const jobTemplatesConvert = jobTemplates?.map((template) => {
    const { preRequisitJobTemplates, jobTemplatesUniforms, ...restTemplate } =
      template;

    const jobTemplatesUniformsAttributes = _.uniqBy(
      template.jobTemplatesUniforms,
      'uniformId',
    );

    return {
      ...restTemplate,
      preRequisitJobTemplatesAttributes: template.preRequisitJobTemplates,
      jobTemplatesUniformsAttributes,
    };
  });

  return createRequestObject({
    dataFormated: {
      ...dataFormated,
      jobTemplates: jobTemplatesConvert,
    },
    lastPostingJob,
    providerId,
    logoThumbnail,
    contractFile,
  });
};

export default getOne;
