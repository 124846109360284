import React from 'react';

import classNames from 'classnames';
import { range } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

const YearMode = ({
  years,
  selectedYear,
  yearRef,
  datetime,
  setDatetime,
  setMode,
  setSelectedYear,
  formInstance,
  name,
  defaultYearLength,
  setIsDisableNextIcon,
}) => {
  const defaultYears = range(1940, 2101, 1);

  // flexible array of years
  const yearsRender = years.length ? years : defaultYears;

  const handleYear = (year) => {
    const date = datetime?.format('MM-DD') ?? moment().format('MM-DD');
    const fullDatetime = `${year}-${date}`;

    setDatetime(moment(fullDatetime));
    setMode('date');

    formInstance.setFields([{ name, value: moment(fullDatetime) }]);
  };

  const onYearClick = (year) => {
    setSelectedYear(year);
    handleYear(year);

    // hanlding to click next icon whenever the year = max
    const changedMonth = moment(datetime).month();
    const crrMonth = moment().month();
    const maxYear =
      years.length > 0
        ? years[years.length - 1]
        : defaultYears[defaultYearLength - 1];

    if (year === maxYear) {
      if (changedMonth <= crrMonth) {
        return setIsDisableNextIcon(false);
      }

      return setIsDisableNextIcon(true);
    }

    if (year < maxYear) {
      return setIsDisableNextIcon(false);
    }

    return setIsDisableNextIcon(true);
  };

  return (
    <div className='year-container'>
      {yearsRender.map((year) => (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={classNames('year-number', {
            'selected-year': selectedYear === year,
          })}
          name={year}
          onClick={() => onYearClick(year)}
          key={year}
          ref={year === selectedYear ? yearRef : null}
        >
          {year}
        </div>
      ))}
    </div>
  );
};

YearMode.propTypes = {
  years: PropTypes.array,
  selectedYear: PropTypes.any,
  yearRef: PropTypes.object,
  datetime: PropTypes.any,
  setDatetime: () => {},
  setMode: PropTypes.func,
  setSelectedYear: PropTypes.func,
  formInstance: PropTypes.object,
  name: PropTypes.any,
  defaultYearLength: PropTypes.number,
  setIsDisableNextIcon: PropTypes.func,
};

YearMode.defaultProps = {
  years: [],
  setDatetime: () => {},
  setMode: () => {},
  setSelectedYear: () => {},
  name: '',
  defaultYearLength: 161,
  setIsDisableNextIcon: () => {},
};
export default YearMode;
