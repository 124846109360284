import {
  CHECK_IN_TYPE,
  CHECK_OUT_TYPE,
  ERROR_CHECK_IN_NOT_VALID_TIME,
  ERROR_CHECK_OUT_NOT_VALID_TIME,
} from '../constant';
import { isTimeCanCheckIn, isTimeCanCheckOut } from './validateTime';

const validateBeforeAssign = ({ startTime, endTime, actionKey }) => [
  {
    validator: async (_, val) => {
      if (
        actionKey === CHECK_IN_TYPE &&
        !isTimeCanCheckIn({ startTime, endTime, value: val })
      ) {
        return Promise.reject(new Error(ERROR_CHECK_IN_NOT_VALID_TIME));
      }
      if (
        actionKey === CHECK_OUT_TYPE &&
        !isTimeCanCheckOut({ startTime, endTime, value: val })
      ) {
        return Promise.reject(new Error(ERROR_CHECK_OUT_NOT_VALID_TIME));
      }
      return Promise.resolve();
    },
  },
];

const Rules = {
  validateBeforeAssign,
};

export default Rules;
