import React from 'react';

import { Form } from 'antd';

import { GeneralTemplate, Tabs } from '@/components';
import { useQueryString } from '@/hooks';

import { TAB_KEY } from '../constants';
import Export from './Export';
import CreateInvoice from './ListView';
import ViewSend from './ViewSend';

import './styles.scss';

const Invoice = () => {
  const [form] = Form.useForm();

  const weekNumWatching = Form.useWatch('weekNum', form);

  const [queryString, setQueryString] = useQueryString();

  const onChangeTabKey = (key) => {
    setQueryString({ tab: key });
  };

  const breadcrumb = [
    { item: 'Invoices', to: '/invoicing', key: 'invoices' },
    { item: `Week ${weekNumWatching}`, to: '/invoicing', key: 'invoices' },
    { item: queryString.tab, to: '', key: 'invoices' },
  ];

  const tabPanes = [
    {
      tab: TAB_KEY.createInvoice.label,
      key: TAB_KEY.createInvoice.key,
      child: (
        <CreateInvoice
          activeKey={queryString.tab}
          stateWeekNum={weekNumWatching}
          form={form}
        />
      ),
    },
    {
      tab: TAB_KEY.viewSend.label,
      key: TAB_KEY.viewSend.key,
      child: (
        <ViewSend
          form={form}
          activeKey={queryString.tab}
          stateWeekNum={weekNumWatching}
        />
      ),
    },
    {
      tab: TAB_KEY.export.label,
      key: TAB_KEY.export.key,
      child: (
        <Export
          form={form}
          activeKey={queryString.tab}
          stateWeekNum={weekNumWatching}
        />
      ),
    },
  ];

  return (
    <GeneralTemplate
      mainItem='Invoices'
      className='invoice-template'
      data={breadcrumb}
      hasButton={false}
      headerText={`Week ${weekNumWatching}`}
    >
      <Tabs
        tabPanes={tabPanes}
        defaultActiveKey={TAB_KEY.createInvoice.key}
        className='invoice-tab-pane'
        activeKey={queryString?.tab ?? TAB_KEY.createInvoice.key}
        onTabClick={onChangeTabKey}
      />
    </GeneralTemplate>
  );
};

export default Invoice;
