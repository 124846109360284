/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

const DateRender = ({
  crrDate,
  selectedYear,
  name,
  formInstance,
  setDatetime,
  weekSelected,
  isSelected,
  isInListSecond,
  onClick,
  disabled,
}) => {
  const onDateClick = (date) => {
    const formatedDate = date.format('MM-DD');
    const fullDatetime = `${selectedYear}-${formatedDate}`;

    formInstance.setFieldsValue({ [name]: moment(fullDatetime) });

    setDatetime(moment(fullDatetime));
    onClick();
  };

  const isSameWeek = weekSelected === crrDate?.weeks();
  return (
    <div
      className='date-cell-custom-wrapper'
      onClick={() => (disabled ? null : onDateClick(crrDate))}
    >
      <div
        className={classNames('date-cell-custom', {
          'add-holiday-request-week-selected': isSameWeek,
          'ant-picker-cell-selected-custom': isSelected,
          'ant-picker-cell-list-second-custom': !isSelected && isInListSecond,
          'date-cell-custom-disabled': disabled,
        })}
      >
        {crrDate.format('DD')}
      </div>
    </div>
  );
};

DateRender.propTypes = {
  selectedYear: PropTypes.number,
  name: PropTypes.string,
  formInstance: PropTypes.object,
  setDatetime: PropTypes.func,
  crrDate: PropTypes.any,
  weekSelected: PropTypes.any,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  isInListSecond: PropTypes.bool,
  disabled: PropTypes.bool,
};

DateRender.defaultProps = {
  name: '',
  setDatetime: () => {},
  isSelected: false,
  onClick: () => {},
  isInListSecond: false,
  disabled: false,
};
export default DateRender;
