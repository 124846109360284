import React from 'react';

import { Form } from 'antd';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { Button, Textarea, FormItem } from '@/components';
import { useAuthStore } from '@/store/authStore';

import History from '../History';
import './styles.scss';

const columnsTableComment = [
  {
    title: 'Comment',
    dataIndex: 'content',
    key: 'content',
    width: '55%',
  },
  {
    title: 'User',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: '20%',
  },
  {
    title: 'Date/Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '25%',
    render: (stringDateTime) =>
      moment(stringDateTime).format('DD/MM/YYYY hh:mm A'),
  },
];

const Comments = () => {
  const form = Form.useFormInstance();

  const listComments = Form.useWatch('historyComments', form) ?? [];
  const { userName, userId } = useAuthStore();

  const comment = Form.useWatch('comment', form) ?? '';

  const handleAddComment = () => {
    // Recommend hard-code for comment length less than 5
    if (!comment || listComments.length >= 5) {
      return;
    }

    const data = [
      {
        key: uniqueId(Math.random()),
        content: comment,
        userId,
        created_at: moment().format('DD/MM/YYYY hh:mm'),
        createdBy: userName,
      },
      ...listComments,
    ];

    form.setFieldsValue({ historyComments: data, comment: '' });
  };

  return (
    <div className='comments'>
      <FormItem className='comments-input' label='Comments' name='comment'>
        <Textarea placeholder='Add comment here' />
      </FormItem>
      <Button className='yellow-button add-button' onClick={handleAddComment}>
        Add Comment
      </Button>

      <History
        dataSource={listComments}
        formItemPropertyName='historyComments'
        columns={columnsTableComment}
      />
    </div>
  );
};
export default Comments;
