import React, { useState } from 'react';

import { Form, message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { GeneralTemplate, Button, ModalConfirm } from '@/components';
import { UserService, UsersServices } from '@/services';
import useModal from '@/utils/useModal';

import UserCollapse from '../UserCollapse';

import './styles.scss';

const EditUser = () => {
  const [form] = Form.useForm();
  const { userId } = useParams();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(['workInformation']);
  const [isDisplayModal, showModal, hiddenModal] = useModal();

  const breadcrumb = [
    { item: 'User', to: '/users' },
    { item: 'View/Edit', to: '' },
  ];

  const { data: userInfo } = useQuery(
    ['userInfo', userId],
    () => UsersServices.getUserById(userId),
    {
      cacheTime: 0,
      retry: false,
      // TODO: enable comment when work with real data
      // enabled: false,
      onSuccess: (info) => form.setFieldsValue(info),
      onError: (err) => message.error(err),
    },
  );

  const firstName = userInfo?.userFullname;

  const { mutate: mutateEditOmniUser } = useMutation(
    () => UserService.editOmniUser(userId, form.getFieldsValue()),
    {
      onError: (error) => {
        message.error(error.message);
      },
      onSuccess: () => {
        message.success('Edit Omni User successfully');
      },
    },
  );

  return (
    <GeneralTemplate
      mainItem='User'
      headerText={`User - ${firstName}`}
      data={breadcrumb}
      hasButton={false}
      subTemplateClassName='user-profile-container'
    >
      <Form
        form={form}
        className='edit-user'
        onFinish={mutateEditOmniUser}
        initialValues={{
          languages: [],
        }}
      >
        <UserCollapse activeKey={activeKey} setActiveKey={setActiveKey} />
        <div className='edit-user-footer'>
          <Button
            className='black-button button-style'
            htmlType='reset'
            onClick={showModal}
          >
            Cancel
          </Button>
          <Button className='yellow-button button-style' htmlType='submit'>
            Save
          </Button>
        </div>
      </Form>

      <ModalConfirm
        title="Don't Want to Save Updates?"
        visible={isDisplayModal}
        onClickYes={() => history.push('/users')}
        onClickNo={hiddenModal}
      >
        Are you sure you want to leave without saving? You will lost any uploads
        and changes you have made.
      </ModalConfirm>
    </GeneralTemplate>
  );
};
export default EditUser;
