import React from 'react';

import { Form, Table } from 'antd';
import classNames from 'classnames';
import _, { get, size } from 'lodash';
import PropTypes from 'prop-types';

import { Button, ColorCheckbox, FormItem } from '@/components';
import { time } from '@/utils';

import { DISABLED_STATUS, FORM_SELECT_NAMES } from '../../constants';
import { selectAll } from '../../utils/select';
import { calTotalHrsSummary } from '../../utils/summary';
import { useTenantContext } from '../../../../TenantWrapper';

const Summary = ({
  jobTypeId,
  pageData = [],
  status,
  isExpand,
  isPushed,
  toggleExpand,
}) => {
  const formInstance = Form.useFormInstance();
  const { currency } = useTenantContext();

  const isDisabledStatus = DISABLED_STATUS.includes(status);
  const timesheetInfo = _.head(pageData);
  const jobTypeName = _.get(timesheetInfo, 'skill.name', '');
  const dateRange = _.get(timesheetInfo, 'dateRangeTimecard', []);
  const {
    totalRangeDay,
    totalHours,
    totalPay,
    totalCharge,
    countUniqEmployees,
  } = calTotalHrsSummary({
    dateRange,
    timesheetDetails: pageData,
  });

  const handleSelectAll = () => {
    const formData = formInstance.getFieldsValue();
    const checkedAllValue = get(
      formData,
      [jobTypeId, FORM_SELECT_NAMES.all],
      false,
    );

    const checkedAllRow = selectAll({
      object: formData,
      checkboxType: FORM_SELECT_NAMES.rows,
      value: checkedAllValue,
    });

    const checkedAllCells = _.mapValues(checkedAllRow, (rows) =>
      selectAll({
        object: rows,
        checkboxType: FORM_SELECT_NAMES.cell,
        value: checkedAllValue,
      }),
    );
    formInstance.setFieldsValue(checkedAllCells);
  };

  const handleExpand = () => toggleExpand();

  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={isPushed ? 3 : 2}>
          <div className='flex flex-gap'>
            {size(pageData) > 3 && (
              <Button
                className={classNames('button-edit shared-button', {
                  'black-button': isExpand,
                  'shared-button': !isExpand,
                })}
                onClick={handleExpand}
              >
                {isExpand ? 'View Less' : 'View More'}
              </Button>
            )}
            <span className='flex'>{`${countUniqEmployees} ${jobTypeName}`}</span>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>Total</Table.Summary.Cell>

        {totalRangeDay.map((totalHrs) => (
          <Table.Summary.Cell>
            {time.convertTimeHoursCell(totalHrs)}
          </Table.Summary.Cell>
        ))}
        <Table.Summary.Cell>
          {time.convertTimeHoursCell(totalHours)}
        </Table.Summary.Cell>
        <Table.Summary.Cell />
        <Table.Summary.Cell>
          {currency}
          {totalPay}
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          {currency}
          {totalCharge}
        </Table.Summary.Cell>
        <Table.Summary.Cell />
        <Table.Summary.Cell>
          <span className='cell-text'>Select all</span>
          <FormItem
            name={[`${jobTypeId}`, 'isCheckedAll']}
            valuePropName='checked'
          >
            <ColorCheckbox
              disabled={isDisabledStatus}
              className={`cell-select ${status} color-select`}
              onChange={handleSelectAll}
            />
          </FormItem>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};

export default Summary;

Summary.propTypes = {
  jobTypeId: PropTypes.number,
  pageData: PropTypes.array,
  status: PropTypes.bool,
  isExpand: PropTypes.bool,
  isPushed: PropTypes.bool,
  toggleExpand: PropTypes.func,
};

Summary.defaultProps = {
  jobTypeId: null,
  pageData: [],
  status: null,
  isExpand: false,
  isPushed: false,
  toggleExpand: () => {},
};
