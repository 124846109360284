import React from 'react';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';

import CommentAndStatusUpdate from './CommentAndStatusUpdate';
import ContactInformation from './ContactInformation';
import LicencesAndDocs from './LicencesAndDocs';
import MedicalInformation from './MedicalInformation';
import PastWorkDetails from './PastWorkDetails';
// import SetRotaAndWorkInformation from './SetRotaAndWorkInformation';
import WorkerPayrollAndAWRInformation from './WorkerPayrollAndAWRInformation';

const { Panel } = Collapse;

const FormCandidate = ({ activeKey, setActiveKey }) => {
  const onChangeCollapse = (key) => setActiveKey(key);

  return (
    <Collapse
      expandIconPosition='right'
      activeKey={activeKey}
      onChange={onChangeCollapse}
    >
      <Panel header='Contact Information' key='Contact Information' forceRender>
        <ContactInformation />
      </Panel>
      <Panel
        accordion
        header='Past Work Details'
        key='Past Work Details'
        forceRender
      >
        <PastWorkDetails />
      </Panel>
      <Panel header='Licences and Docs' key='Licences and Docs' forceRender>
        <LicencesAndDocs />
      </Panel>

      {/* <Panel
        header="Set Rota and work information"
        key="Set Rota and work information"
        forceRender
      >
        <SetRotaAndWorkInformation />
      </Panel> */}
      <Panel
        header='Worker Payroll and AWR information'
        key='Worker Payroll and AWR information'
        forceRender
      >
        <WorkerPayrollAndAWRInformation />
      </Panel>

      <Panel
        header='References and Medical Information'
        key='References and Medical Information'
        forceRender
      >
        <MedicalInformation />
      </Panel>
      <Panel
        header='Comments and Status updates'
        key='Comments and Status updates'
        forceRender
      >
        <CommentAndStatusUpdate />
      </Panel>
    </Collapse>
  );
};

export default FormCandidate;

FormCandidate.propTypes = {
  activeKey: PropTypes.array,
  setActiveKey: PropTypes.func,
};
