import httpPostCode from '@/httpPostCode';

const autocompletePostCode = async (postcode) => {
  if (!postcode) return [];

  const res = await httpPostCode.get(`/postcodes/${postcode}/autocomplete`);
  const result =
    res?.data?.result?.map((item) => ({
      key: item,
      label: item,
      value: item,
    })) ?? [];

  return result;
};

export default autocompletePostCode;
