import request from '@/request';

const updateBreak = async ({
  /** @type {string/number} */ id,
  /** @type {array} */ timecardIds,
  /** @type {number} */ breakValue,
}) => {
  const endpoint = `trackers/timekeeping/timesheets/${id}/timecards/break_time`;
  const res = await request.patch(endpoint, {
    timecardIds,
    break: breakValue,
  });

  return res?.data;
};
export default updateBreak;
