import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getPayrollList = async ({ page, filter, order }) => {
  try {
    const endpoint = '/payments/payrolls';

    const res = await request.get(endpoint, {
      params: {
        page,
        filter,
        order,
      },
    });

    const data = normalizeIndexResponse(res);

    return data;
  } catch (error) {
    throw new ServiceError(error);
  }
};
export default getPayrollList;
