import React from 'react';

import { Form, Popover } from 'antd';
import PropTypes from 'prop-types';

import { TYPES_SICK_NO_SHOW } from '@/pages/Payroll/constants';

import BodyPopover from './BodyPopover';
import TitlePopover from './TitlePopOver';

import './styles.scss';

const CommentPopover = ({
  isOpen,
  setIsOpen,
  type,
  title,
  skillId,
  timecardId,
  handleEdit,
  isLoading,
  children,
  originalContent,
  namePath,
  updateCommentMutation,
  ...props
}) => {
  const formInstance = Form.useFormInstance();
  const handleOpenChange = (opened) => {
    if (!opened) {
      formInstance.resetFields();
    }
    setIsOpen(opened);
  };
  return (
    <Popover
      trigger='click'
      visible={isOpen}
      className='comment-popover'
      title={<TitlePopover type={type} title={title} />}
      content={
        <BodyPopover
          namePath={namePath}
          skillId={skillId}
          timecardId={timecardId}
          handleEdit={handleEdit}
          isLoading={isLoading}
        />
      }
      onVisibleChange={handleOpenChange}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default CommentPopover;

CommentPopover.propTypes = {
  namePath: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  originalContent: PropTypes.string,
  skillId: PropTypes.number,
  timecardId: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  handleEdit: PropTypes.func,
  isLoading: PropTypes.bool,
  isSucceed: PropTypes.bool,
  updateCommentMutation: PropTypes.func,
};

CommentPopover.defaultProps = {
  updateCommentMutation: () => {},
  namePath: [],
  isOpen: false,
  setIsOpen: () => {},
  originalContent: '',
  skillId: null,
  timecardId: null,
  type: TYPES_SICK_NO_SHOW.sick,
  title: '',
  children: null,
  handleEdit: () => {},
  isLoading: false,
  isSucceed: false,
};
