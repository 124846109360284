import React, { useState } from 'react';

import { Table, Form } from 'antd';
import fileDownload from 'js-file-download';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useToggle } from 'usehooks-ts';

import ModalJobSeeker from '@/components/JobSeeker/ModalJobSeeker';
import { useFetch } from '@/hooks';
import { downloadSeekerListCsv } from '@/services/jobServices';

import PreviewJobPdf from '../../CheckinCheckoutJobsDrawer/AssignList/Jobs/Confirmed/PreviewJobPdf';
import ManageWorkerDownloadFeature from '../../CheckinCheckoutJobsDrawer/AssignList/Jobs/DownloadFeature';
import appliedColumns from './appliedColumns';
import confirmedColumns from './confirmedCloumns';
import declinedColumns from './declinedColumns';

import './styles.scss';

/**
 * Renders the Seeker component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.seekers - The list of seekers.
 * @param {string} props.activeKey - The active key.
 * @param {Array} props.appliedList - The list of applied seekers.
 * @param {Array} props.removedList - The list of removed seekers.
 * @param {Array} props.checkedInList - The list of checked-in seekers.
 * @param {Array} props.checkedOutList - The list of checked-out seekers.
 * @param {Function} props.declineSeekers - The function to decline seekers.
 * @param {Function} props.acceptSeekers - The function to accept seekers.
 * @param {Function} props.selectAllApplied - The function to select all applied seekers.
 * @param {Function} props.selectAppliedSeekers - The function to select applied seekers.
 * @param {Function} props.selectAllDeclined - The function to select all declined seekers.
 * @param {Function} props.selectDeclinedSeekers - The function to select declined seekers.
 * @param {Function} props.checkOutSeekers - The function to check out seekers.
 * @param {Function} props.checkInSeekers - The function to check in seekers.
 * @param {Function} props.noShowSeekers - The function to mark seekers as no-show.
 * @param {Function} props.disableCheckedIn - The function to disable checking in.
 * @param {Function} props.disableCheckedOut - The function to disable checking out.
 * @param {Function} props.selectAllCheckedIn - The function to select all checked-in seekers.
 * @param {Function} props.selectCheckedInSeekers - The function to select checked-in seekers.
 * @param {Function} props.selectAllCheckedOut - The function to select all checked-out seekers.
 * @param {Function} props.selectCheckedOutSeekers - The function to select checked-out seekers.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @param {object} props.jobDetail
 * @param {number|string} props.positionId - The position ID.
 * @return {JSX.Element} The rendered Seeker component.
 */
const Seeker = ({
  positionId,
  seekers,
  activeKey,
  appliedList,
  removedList,
  checkedInList,
  checkedOutList,
  declineSeekers,
  acceptSeekers,
  selectAllApplied,
  selectAppliedSeekers,
  selectAllDeclined,
  selectDeclinedSeekers,
  checkOutSeekers,
  checkInSeekers,
  noShowSeekers,
  disableCheckedIn,
  disableCheckedOut,
  selectAllCheckedIn,
  selectCheckedInSeekers,
  selectAllCheckedOut,
  selectCheckedOutSeekers,
  isLoading,
  jobDetail,
}) => {
  const formInstance = Form.useFormInstance();
  const isCompletedTabWatching = Form.useWatch('isCompletedTab', formInstance);

  const [seekerId, setSeekerId] = useState();
  const [isPreviewJobPdf, togglePreviewJobPdf] = useToggle();
  const renderColumns = () => {
    if (activeKey === 'applied') {
      return appliedColumns({
        acceptSeekers,
        appliedList,
        declineSeekers,
        removedList,
        selectAllApplied,
        selectAppliedSeekers,
        selectAllDeclined,
        selectDeclinedSeekers,
        openModalSeekerInfo,
        isCompletedTabWatching,
      });
    }

    if (activeKey === 'confirmed') {
      return confirmedColumns({
        checkedInList,
        checkInSeekers,
        disableCheckedIn,
        disableCheckedOut,
        selectAllCheckedIn,
        selectCheckedInSeekers,
        selectAllCheckedOut,
        selectCheckedOutSeekers,
        noShowSeekers,
        declineSeekers,
        checkOutSeekers,
        checkedOutList,
        removedList,
        selectAllDeclined,
        selectDeclinedSeekers,
        openModalSeekerInfo,
      });
    }

    return declinedColumns({ openModalSeekerInfo });
  };

  const openModalSeekerInfo = (value) => setSeekerId(value);

  const { refetch: downloadCSVJobs } = useFetch(
    ['download-seeker-job-csv', activeKey],
    () =>
      downloadSeekerListCsv({
        positionId,
        dateExport: undefined,
        status: activeKey,
      }),
    {
      onSuccess: (res) => {
        fileDownload(res, `download-seeker-job-${activeKey}.xls`);
      },
      enabled: false,
    },
  );

  return (
    <div className='seeker-container'>
      <div className='header-container'>
        <ManageWorkerDownloadFeature
          togglePreviewJobPdf={togglePreviewJobPdf}
          downloadCSVJobs={downloadCSVJobs}
        />
      </div>
      <Table
        className='seeker-table'
        columns={renderColumns()}
        dataSource={seekers}
        pagination={false}
        loading={isLoading}
      />

      <PreviewJobPdf
        isVisible={isPreviewJobPdf}
        toggleVisible={togglePreviewJobPdf}
        jobDetail={jobDetail}
        date={jobDetail.datePosted}
        inforDetail={sortBy(seekers, 'startTime')}
      />

      {seekerId && (
        <ModalJobSeeker
          visibleModal={seekerId}
          setVisibleModal={setSeekerId}
          seekerId={seekerId}
        />
      )}
    </div>
  );
};

Seeker.propTypes = {
  seekers: PropTypes.array,
  isLoading: PropTypes.bool,
  activeKey: PropTypes.string,
  appliedList: PropTypes.array,
  removedList: PropTypes.array,
  checkedInList: PropTypes.array,
  checkedOutList: PropTypes.array,
  declineSeekers: PropTypes.func,
  acceptSeekers: PropTypes.func,
  selectAllApplied: PropTypes.func,
  selectAppliedSeekers: PropTypes.func,
  selectAllDeclined: PropTypes.func,
  selectDeclinedSeekers: PropTypes.func,
  checkOutSeekers: PropTypes.func,
  checkInSeekers: PropTypes.func,
  noShowSeekers: PropTypes.func,
  disableCheckedIn: PropTypes.func,
  disableCheckedOut: PropTypes.func,
  selectAllCheckedIn: PropTypes.func,
  selectCheckedInSeekers: PropTypes.func,
  selectAllCheckedOut: PropTypes.func,
  selectCheckedOutSeekers: PropTypes.func,
  jobDetail: PropTypes.object,
  positionId: PropTypes.number,
};

Seeker.defaultProps = {
  seekers: [],
  activeKey: 'applied',
  appliedList: [],
  removedList: [],
  checkedInList: [],
  isLoading: false,
  checkedOutList: [],
  declineSeekers: () => {},
  acceptSeekers: () => {},
  selectAllApplied: () => {},
  selectAppliedSeekers: () => {},
  selectAllDeclined: () => {},
  selectDeclinedSeekers: () => {},
  checkOutSeekers: () => {},
  checkInSeekers: () => {},
  noShowSeekers: () => {},
  disableCheckedIn: () => {},
  disableCheckedOut: () => {},
  selectAllCheckedIn: () => {},
  selectCheckedInSeekers: () => {},
  selectAllCheckedOut: () => {},
  selectCheckedOutSeekers: () => {},
  jobDetail: {},
  positionId: null,
};
export default Seeker;
