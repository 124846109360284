import { message } from 'antd';

import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

export const fetchNationalities = async ({ ukIreland = false } = {}) => {
  try {
    const res = await request.get('/options/nationalities', {
      params: {
        filter: {
          ukIreland,
        },
      },
    });
    const data = normalizeIndexResponse(res);
    const result = data?.data?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    return { nationalities: result };
  } catch (error) {
    message.error('Something went wrong!');
    throw error;
  }
};
