import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';
import { formatResponse } from '@/utils/thunkUtil';

export async function changeStatusCandidate(id, candidateStatus) {
  try {
    const endpoint = `/recruitments/candidates/${id}/update_status`;

    const response = await request.patch(endpoint, {
      candidate: { candidateStatus },
    });

    return formatResponse(response, { endpoint });
  } catch (error) {
    throw new ServiceError({
      message: ['Change status fails'],
      details: ['Change status fails'],
    });
  }
}
