import { isArray, isEmpty, isString } from 'lodash';

import isValidHttpUrl from './isValidHttpUrl';
import uploadFileS3 from './uploadFileS3';

const listFileParams = [
  'profilepic',
  'cvUpload',
  'passportId1File',
  'passportId2File',
  'visaBrpFile',
  'p45Document',
  'contractFile',
  'foodSafetyAllergens',
  'manualHanding',
  'alcoholLicense',
  'drivingLicense',
  'scooterLicense',
  'hgvLicense',
  'forkLiftTruck',
  'induction',
  'signedRiskAssessment',
  'dbsCheck',
  'employeeId1Expiry',
  'employeeId2Expiry',
];

const buildNestedObjectWithKeyArray = (
  /** @type {string[]} */ namePath,
  /** @type {string} */ value,
) => {
  const result = {};
  let temp = result;
  namePath.forEach(
    (
      /** @type {string} */ name,
      /** @type {number} */ idx,
      /** @type {string[]} */ arr,
    ) => {
      if (idx === arr.length - 1) {
        temp[name] = value;
      } else {
        temp[name] = {};
        temp = temp[name];
      }
    },
  );
  return result;
};

export const buildNestedObject = (
  /** @type {string|string[]} */ name,
  /** @type {string} */ value,
) => {
  if (isArray(name)) {
    return buildNestedObjectWithKeyArray(name, value);
  }

  return { [name]: value };
};

export const combineFieldNameAndFileName = (fieldName, fileName) => {
  let result = '';
  if (fieldName) {
    result += fieldName;
  }
  if (fileName) {
    result += `/${fileName}`;
  }

  return result;
};

export const splitFieldNameAndFileName = (url) => {
  const result = {
    fieldName: '',
    fileName: '',
  };

  if (isValidHttpUrl(url)) {
    const { pathname } = new URL(url);

    const listPath = pathname.split('/');
    const lengthListPath = listPath.length;

    if (lengthListPath >= 2) {
      result.fileName = listPath.pop();
      result.fieldName = listPath.pop();
    }
  }

  return result;
};

const handleUploadFile = async (form, listFieldsUpload = listFileParams) => {
  const result = await Promise.all(
    listFieldsUpload
      .filter((fieldName) => form.getFieldValue(fieldName)?.file?.originFileObj)
      .map((fieldName) => {
        const fileName = combineFieldNameAndFileName(
          fieldName,
          form.getFieldValue(fieldName).file.name,
        );

        const originFile = form.getFieldValue(fieldName).file.originFileObj;

        return uploadFileS3(fileName, originFile);
      }),
  );

  const imagesUpload = {};

  result.forEach((item) => {
    const fieldNameAndFileName = splitFieldNameAndFileName(item.getUrl);
    form.setFieldsValue(buildNestedObject(item.fileName, item.getUrl));
    imagesUpload[fieldNameAndFileName.fieldName] = item.getUrl;
  });

  return imagesUpload;
};

/**
 * @typedef {{
 * fieldValue: string | object;
 * fieldName: string;
 * }} UploadFileProps
 */

/**
 * Handles the upload of multiple files, processes them, and returns the URLs of the uploaded files.
 *
 * @param {Array<Array<UploadFileProps>>} files - Array of files to upload
 */

export const handleUploadFiles = async (files) => {
  const uploadFilesUrl = {};
  const filesToUpload = [];
  files.forEach(([fieldValue, fieldName]) => {
    if (isString(fieldValue)) {
      uploadFilesUrl[fieldName] = fieldValue;
      return;
    }
    if (isEmpty(fieldValue)) return;
    const fileName = combineFieldNameAndFileName(
      fieldName,
      fieldValue?.file?.name,
    );
    const originFile = fieldValue?.file?.originFileObj;
    filesToUpload.push(uploadFileS3(fileName, originFile));
  });
  const results = await Promise.all(filesToUpload);

  results.forEach((item) => {
    const fieldNameAndFileName = splitFieldNameAndFileName(item.getUrl);
    uploadFilesUrl[fieldNameAndFileName.fieldName] = item.getUrl;
  });
  return uploadFilesUrl;
};

export default handleUploadFile;
