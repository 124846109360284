/* eslint-disable no-underscore-dangle */
import request from '@/request';

const updateTimeCard = async ({
  /** @type {string|number} */ timesheetId,
  /** @type {string[]} */ timecardAttributes,
  /** @type {bool} */ checkTime,
}) => {
  const res = await request.patch(
    `trackers/timekeeping/timesheets/${timesheetId}`,
    {
      timesheet: {
        timecards_attributes: timecardAttributes,
      },
    },
  );
  return res.data;
};

const updateSelectedTimecards = async ({
  /** @type {string|number} */ timesheetId,
  /** @type {string[]|number[]} */ listSelected,
  /** @type {string|number} */ payrate,
  /** @type {string|number} */ chargeRate,
}) => {
  const res = await request.post(
    `trackers/timekeeping/timesheets/${timesheetId}/timecards/update_rates`,
    {
      timecard_ids: listSelected,
      payrate: parseFloat(payrate),
      charge_rate: parseFloat(chargeRate),
    },
  );
  return res.data;
};
export { updateTimeCard, updateSelectedTimecards };
