import React from 'react';

import { Form, message } from 'antd';
import classNames from 'classnames';
import { filter, isEmpty, isEqual, isNil } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from '@/components';
import { ACTIONS, MODAL_TYPES, disableStatus } from '@/pages/Payroll/constants';

const TablePayrollFooter = ({
  status,
  jobTypeId,
  handleClickFinalise,
  colorButtonApprove,
  textButtonApprove,
  isEdit,
  setIsEdit,
  setIsLargeHeight,
  renderSeeButton,
  setType,
  setIsShowSickNoShow,
  dateRange,
  onSaveMutation,
  updateTimecardLoading,
  setActionKey,
  setShowConfirm,
}) => {
  const formInstance = Form.useFormInstance();

  const jobTypeWatching = Form.useWatch(jobTypeId, formInstance) ?? {};

  const isCheckedTimecard = Object.values(jobTypeWatching).some(
    (timecard) => timecard.status === status && timecard.isChecked,
  );

  const sickNoShowSelected = Object.values(jobTypeWatching).filter(
    (timecard) =>
      timecard.status === status &&
      !isNil(timecard.no_show) &&
      timecard.isChecked,
  );

  const timecardsCheckedWithoutSickNoShow = filter(
    jobTypeWatching,
    (timecard) =>
      isEqual(timecard.status, status) &&
      isNil(timecard.no_show) &&
      timecard.isChecked,
  );

  const expandTheTable = (value) => {
    setIsEdit(value);
    setIsLargeHeight(value);
  };

  const onScrollToFields = (name) => {
    formInstance.scrollToField(name, {
      skipOverflowHiddenElements: true,
      block: 'center',
      behavior: 'smooth',
    });
  };

  const onSaveInfo = (jobTypeData) => {
    const updatedValue = Object.values(jobTypeData).filter(
      (timecard) => timecard?.isChecked,
    );

    if (isEmpty(updatedValue)) {
      message.warning('Please select at least one timecard to update!');
      return;
    }
    expandTheTable(false);
    onSaveMutation(updatedValue);
  };

  const getValidateFieldsName = (jobTypeData) =>
    Object.entries(jobTypeData).reduce((acc, [innerKey]) => {
      acc.push([jobTypeId, innerKey, 'bonus_type']);
      return acc;
    }, []);

  const handleSave = async () => {
    try {
      const jobTypeData = formInstance.getFieldValue(jobTypeId);
      const fieldsName = getValidateFieldsName(jobTypeData);
      await formInstance.validateFields(fieldsName);
      onSaveInfo(jobTypeData);
    } catch (error) {
      const errorName = error.errorFields[0].name;
      onScrollToFields(errorName);
    }
  };

  const handleSickOrNoShow = (type) => {
    setType(type);
    setIsShowSickNoShow(true);
  };

  const shouldDisabledSickNoShow =
    !isCheckedTimecard || isEmpty(timecardsCheckedWithoutSickNoShow);

  const handleUndo = () => {
    setActionKey(ACTIONS.undo.key);
    setShowConfirm(true);
  };

  return (
    <div className='table-footer-job-types'>
      {isEdit && !disableStatus.includes(status) && (
        <>
          <div>
            <Button
              className='button-noshow fixed-btn'
              onClick={handleUndo}
              disabled={isEmpty(sickNoShowSelected)}
            >
              Undo
            </Button>
          </div>
          <div>
            <Button
              className='button-sick fixed-btn'
              onClick={() => handleSickOrNoShow(MODAL_TYPES.sick)}
              disabled={shouldDisabledSickNoShow}
            >
              Sick
            </Button>
          </div>
          <div>
            <Button
              className='button-noshow fixed-btn'
              onClick={() => handleSickOrNoShow(MODAL_TYPES.noShow)}
              disabled={shouldDisabledSickNoShow}
            >
              No Show
            </Button>
          </div>
        </>
      )}
      <div>
        <Button
          className={`yellow-button button-approve fixed-btn ${colorButtonApprove}`}
          onClick={handleClickFinalise}
          disabled={updateTimecardLoading}
        >
          {textButtonApprove}
        </Button>
      </div>
      {status === 'paid' && !isEdit && renderSeeButton()}
      {status === 'finalised' && !isEdit && renderSeeButton()}
      {!isEdit ? (
        <div>
          <Button
            onClick={() => {
              if (status !== 'finalised') {
                expandTheTable(true);
              }
            }}
            loading={updateTimecardLoading}
            className={classNames('black-button button-edit fixed-btn', {
              finalised: status === 'finalised',
            })}
          >
            Edit
          </Button>
        </div>
      ) : (
        <>
          <div>
            <Button
              onClick={() => expandTheTable(false)}
              className='black-button button-edit fixed-btn'
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              onClick={handleSave}
              loading={updateTimecardLoading}
              className='black-button button-edit fixed-btn'
              hidden={status === 'paid' || status === 'finalised'}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TablePayrollFooter;

TablePayrollFooter.propTypes = {
  jobTypeId: PropTypes.number,
  status: PropTypes.string,
  handleClickFinalise: PropTypes.func,
  colorButtonApprove: PropTypes.string,
  textButtonApprove: PropTypes.string,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  setIsLargeHeight: PropTypes.func,
  renderSeeButton: PropTypes.func,
  setType: PropTypes.func,
  setIsShowSickNoShow: PropTypes.func,
  dateRange: PropTypes.array,
  onSaveMutation: PropTypes.func,
  updateTimecardLoading: PropTypes.bool,
  setActionKey: PropTypes.func,
  setShowConfirm: PropTypes.func,
};

TablePayrollFooter.defaultProps = {
  jobTypeId: null,
  dateRange: [],
  status: '',
  setType: () => {},
  handleClickFinalise: () => {},
  colorButtonApprove: '',
  textButtonApprove: '',
  isEdit: false,
  updateTimecardLoading: false,
  setIsEdit: () => {},
  setIsLargeHeight: () => {},
  renderSeeButton: () => {},
  setIsShowSickNoShow: () => {},
  onSaveMutation: () => {},
  setActionKey: () => {},
  setShowConfirm: () => {},
};
