import request from '@/request';

const weeklyWagesCsv = async (filter) => {
  const endpoint = 'payments/wages/csv';
  const res = await request.get(endpoint, {
    params: {
      filter,
    },
  });

  return res?.data;
};
export default weeklyWagesCsv;
