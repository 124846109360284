import * as htmlToImage from 'html-to-image';
import JsPdf from 'jspdf';

const useReactToPdf = ({
  targetRef,
  x = 0,
  y = 0,
  scrollX = 0,
  scrollY = 0,
  scale = 1,
}) => {
  const toPdf = async ({ width, height }) => {
    const targetComponent = targetRef.current;

    if (!targetComponent) {
      throw new Error(
        'Target ref must be used or informed. See https://github.com/ivmarcos/react-to-pdf#usage.',
      );
    }

    const maxWidth = targetComponent.scrollWidth;
    const maxHeight = targetComponent.scrollHeight;

    const canvas = await htmlToImage.toCanvas(targetComponent, {
      quality: 1,
      width: width ?? maxWidth,
      height: height ?? maxHeight,
      x,
      y,
      scrollX,
      scrollY,
      scale,
      foreignObjectRendering: true,
    });

    const imgData = canvas.toDataURL('image/png');

    const pdf = new JsPdf({
      orientation: maxWidth > maxHeight ? 'landscape' : 'portrait',
      unit: 'px',
      format: [maxWidth, maxHeight],
    });

    pdf.addImage(imgData, 'PNG', 0, 0, maxWidth, maxHeight);
    const blob = pdf.output('blob');

    return { blob, pdf };
  };

  return { toPdf };
};

export default useReactToPdf;
