import React from 'react';

import WorkerPayrollAndBankDetails from './WorkerPayrollAndBankDetails';
import WorkingTimeDirective from './WorkingTimeDirective';

import './styles.scss';

const WorkerPayrollAndAWRInformation = () => (
  <div className='worker-payroll-and-awr-information'>
    <div className='left'>
      <WorkingTimeDirective />
    </div>
    <div className='right'>
      <WorkerPayrollAndBankDetails />
    </div>
  </div>
);

export default WorkerPayrollAndAWRInformation;
