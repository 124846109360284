import React from 'react';

import { Col, Form, Row } from 'antd';
import { get, head, size, without } from 'lodash';
import PropTypes from 'prop-types';

import { Button, FormItem, Select } from '@/components';
import { STATUS_FILTER_DROPDOWN_OPTIONS } from '@/constants';
import { useSessionStorage } from '@/hooks';

import './styles.scss';

/**
 * Renders the calendar options component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @param {function} props.refetchCalendarJobs - The function to refetch calendar jobs.
 * @param {Array} props.providers - The list of providers.
 * @param {Array} props.locations - The list of locations.
 * @return {JSX.Element} The rendered calendar options component.
 */
const CalendarOptions = ({
  isLoading,
  refetchCalendarJobs,
  providers,
  locations,
}) => {
  const formInstance = Form.useFormInstance();
  const provider = Form.useWatch('provider', formInstance);
  const providerName = Form.useWatch('providerName', formInstance);
  const isCompletedTab = Form.useWatch('isCompletedTab', formInstance);

  const [jobsFilter, setJobsFilter, removeJobsFilter] =
    useSessionStorage('searchJobs');

  const storedProvider = get(jobsFilter, 'provider', []);
  const isSingleProvider = size(storedProvider) <= 1;

  const providerLabel = without(providers).find(
    (item) => item.value === provider,
  )?.label;

  if (providerLabel !== providerName)
    formInstance.setFieldsValue({ providerName: providerLabel });

  const handleSearch = () => {
    setJobsFilter({
      provider,
    });
    refetchCalendarJobs();
  };

  const handleClearFilter = () => {
    removeJobsFilter();
    formInstance.setFieldsValue({
      provider: null,
      providerName: null,
      locations: null,
      status: null,
    });
  };

  return (
    <Row gutter={24} className='calendar-options'>
      <FormItem name='providerName' hidden />
      <Col span={6}>
        <FormItem
          name='provider'
          label='Provider'
          initialValue={isSingleProvider ? head(storedProvider) : null}
        >
          <Select required placeholder='Select Provider' options={providers} />
        </FormItem>
      </Col>
      <Col span={6}>
        <FormItem name='location' label='Location'>
          <Select
            required
            placeholder='Select Location'
            options={locations?.listLocations}
          />
        </FormItem>
      </Col>
      <Col span={6}>
        {!isCompletedTab && (
          <FormItem name='status' label='Status'>
            <Select
              required
              placeholder='Select Status'
              options={STATUS_FILTER_DROPDOWN_OPTIONS}
            />
          </FormItem>
        )}
      </Col>
      <Col span={3}>
        <Button
          loading={isLoading}
          type='primary'
          onClick={handleSearch}
          className='yellow-button'
        >
          Search
        </Button>
      </Col>
      <Col span={3}>
        <Button className='filter-button' onClick={handleClearFilter}>
          Clear Filter
        </Button>
      </Col>
    </Row>
  );
};

export default CalendarOptions;

CalendarOptions.propTypes = {
  isLoading: PropTypes.bool,
  refetchCalendarJobs: PropTypes.func,
  providers: PropTypes.array,
  locations: PropTypes.array,
};

CalendarOptions.defaultProps = {
  providers: [],
  locations: [],
};

CalendarOptions.defaultProps = { isLoading: false };
