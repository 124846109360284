import { camelizeKeys } from 'humps';

import request from '@/request';

const deleteHours = async ({
  /** @type { string | number } */ timesheetId,
  /** @type { string[] | number[]} */ timecardIds,
}) => {
  const endpoint = `trackers/timekeeping/timesheets/${timesheetId}/timecards/delete_hours`;
  const res = await request.post(endpoint, {
    timecardIds,
  });
  return camelizeKeys(res?.data?.data);
};

export default deleteHours;
