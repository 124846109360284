import request from '@/request';
import { evaluationTextToNumber } from '@/utils/evaluation';

const changeEvaluation = async ({ evaluationText, candidateId }) => {
  const endpoint = `recruitments/candidates/${candidateId}`;

  return request.patch(endpoint, {
    candidate: {
      evaluation: evaluationTextToNumber(evaluationText),
    },
  });
};

export default changeEvaluation;
