import React from 'react';

import PropTypes from 'prop-types';

import { FormItem, MultipleSelect, Select, Textarea } from '@/components';
import useUniforms from '@/hooks/dropdowns/useUniforms';
import Formatter from '@/utils/Formatter';
import { rulesDescription } from '@/utils/rulesInput';

import { JOB_DESCRIPTION_NO_LIMIT } from './constant';

const JobDescription = ({ disabled }) => {
  const { data: listUniforms } = useUniforms();

  const { listShirts, listJeans, listShoes } = listUniforms ?? {};

  const uniforms = [
    {
      label: 'Uniform',
      name: 'shirt',
      placeholder: 'Choose Shirt / T-Shirt',
      options: listShirts,
    },
    {
      name: 'jean',
      placeholder: 'Choose Trousers / Jeans',
      options: listJeans,
    },
    {
      name: 'shoe',
      placeholder: 'Choose Shoes',
      options: listShoes,
    },
  ];

  return (
    <div className='job-description-container'>
      <FormItem
        rules={!disabled && rulesDescription()}
        required
        name='description'
        label='Job Description'
        className='xl-form-item'
        normalize={Formatter.removeExtraSpaces}
      >
        <Textarea
          size='large'
          placeholder='Job Description'
          disabled={disabled}
          maxLength={JOB_DESCRIPTION_NO_LIMIT}
        />
      </FormItem>
      <FormItem
        className='xl-form-item'
        name='specialRequirements'
        label='Special Requirements'
      >
        <Textarea
          size='large'
          placeholder='Special Requirements'
          maxLength={undefined}
          disabled={disabled}
        />
      </FormItem>
      <div className='uniform xl-form-item'>
        {uniforms.map(({ name, placeholder, options, label }) => (
          <>
            <FormItem hidden name={`${name}Id`} />
            <FormItem key={placeholder} label={label} name={name}>
              <Select
                placeholder={placeholder}
                options={options}
                disabled={disabled}
              />
            </FormItem>
          </>
        ))}
      </div>
      <div className='uniform xl-form-item'>
        <FormItem
          name='additionalUniform'
          className='sm-form-item'
          label='Additional Uniform'
        >
          <MultipleSelect
            placeholder='Choose'
            options={listUniforms?.listAdditionalUniforms}
            name='additionalUniform'
            disabled={disabled}
          />
        </FormItem>
      </div>
    </div>
  );
};

JobDescription.propTypes = {
  disabled: PropTypes.bool,
};

JobDescription.defaultProps = { disabled: false };

export default JobDescription;
