/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { Modal, message } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from '@/assets/icons/warningIcon.svg';
import { languages } from '@/constants';
import useLanguageLevel from '@/hooks/dropdowns/useLanguageLevel';

import { Button, Select } from '../index';

import './styles.scss';

const LanguageModal = ({
  className,
  setIsShowLanguageModal,
  onLanguageModalClickOk,
  vacancyApplicantLanguages,
  setIsEdit,
  isEdit,
  index,
  languageEdit,
  isShowLanguageModal,
  deleteLanguage,
  ...props
}) => {
  const { listLanguageLevel } = useLanguageLevel();
  const [language, setLanguage] = useState();
  const [competency, setCompetency] = useState();

  const onClickOk = () => {
    if (!language || !competency) {
      return message.error('Please select both language and competency');
    }

    if (isEdit) {
      vacancyApplicantLanguages[index].name = language;
      vacancyApplicantLanguages[index].level = competency;
    }

    onLanguageModalClickOk(
      {
        name: language,
        level: competency,
      },
      isEdit,
    );

    setLanguage();
    setCompetency();
    setIsEdit(false);
    return setIsShowLanguageModal(false);
  };

  return (
    <Modal
      onCancel={() => setIsShowLanguageModal(false)}
      className={classNames('language-modal', className)}
      footer={null}
      {...props}
    >
      <WarningIcon />
      <h1>Add Language</h1>
      <Select
        className='options margin'
        options={languages}
        placeholder='Select Language'
        onChange={(value) => setLanguage(value)}
        value={language}
      />
      <Select
        className='options'
        options={listLanguageLevel}
        placeholder='Select Competency'
        onChange={setCompetency}
        value={competency}
      />
      <Button className='yellow-button ok-button' onClick={onClickOk}>
        OK
      </Button>
    </Modal>
  );
};

LanguageModal.propTypes = {
  className: PropTypes.string,
  setIsShowLanguageModal: PropTypes.func,
  onLanguageModalClickOk: PropTypes.func,
  setIsEdit: PropTypes.func,
  isEdit: PropTypes.bool,
  isShowLanguageModal: PropTypes.bool,
  index: PropTypes.number,
  languageEdit: PropTypes.object,
  vacancyApplicantLanguages: PropTypes.array,
  deleteLanguage: PropTypes.func,
};

LanguageModal.defaultProps = {
  className: '',
  setIsShowLanguageModal: () => {},
  onLanguageModalClickOk: () => {},
  setIsEdit: () => {},
  isEdit: false,
  isShowLanguageModal: false,
  languageEdit: {},
  vacancyApplicantLanguages: [],
  index: 0,
  deleteLanguage: () => {},
};

export default LanguageModal;
