import React, { useState } from 'react';

import { Radio, Pagination, Tooltip, Divider } from 'antd';
import classNames from 'classnames';
import { isObject, round } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Select, ListView, Table } from '@/components';
import { useFetch } from '@/hooks';
import { getPayrollSeeker } from '@/services/seeker/getPayrollSeeker';
import { getStatisticalSeeker } from '@/services/seeker/getStatisticalSeeker';
import { getTimesheetSeeker } from '@/services/seeker/getTimesheetSeeker';
import { getListYear } from '@/utils';
import Formatter from '@/utils/Formatter';

import { useHardCodeSeekers } from '../../seekerMockData';

import './styles.scss';

const years = getListYear({
  startYear: 2018,
  endYear: moment().year(),
  step: 1,
});

const renderTitle = (/** @type {string | number} */ index) => {
  const titles = {
    0: 'Total Hours Accrued',
    1: 'Average Daily Hours',
    2: 'Average hourly Pay ',
    3: 'Average Daily Pay ',
    4: 'GIG Holiday Entitlement',
    5: 'AWR Holiday Entitlement ',
    6: 'Holiday Taken ',
  };
  return titles[index] || '';
};

const columns = [
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    width: '7%',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: '12%',
  },
  {
    title: 'Week',
    dataIndex: 'weekNum',
    key: 'week',
    width: '10%',
    sorter: true,
  },
  {
    title: 'Week Start',
    dataIndex: 'weekStartDate',
    key: 'weekStart',
    width: '10%',
    render: (item) => moment(item).format('DD/MM/YYYY'),
  },
  {
    title: 'Week End',
    dataIndex: 'weekEndDate',
    key: 'weekEnd',
    width: '7%',
    render: (item) => moment(item).format('DD/MM/YYYY'),
  },
  {
    title: 'Total Pay',
    dataIndex: 'payTotal',
    key: 'payTotal',
    width: '10%',
    render: (item) => Formatter.currencyGBP(item),
  },
  {
    title: 'Last Edited By',
    dataIndex: 'lastEditedByUser',
    key: 'lastEditedBy',
  },
  {
    title: 'Total Hours',
    dataIndex: 'hours',
    key: 'totalHours',
  },
  {
    title: <div className='authorised-title'>Authorised</div>,
    dataIndex: 'authorised',
    key: 'authorised',
    render: () => (
      <div className='authorised-container'>
        <Radio className='authorised' checked />
      </div>
    ),
  },
  {
    title: 'Paid',
    dataIndex: 'payrollStatus',
    key: 'paid',
    render: (/** @type {string} */ status) =>
      status === 'paid' && (
        <div className='paid-container'>
          <h4 className='paid'>PAID</h4>
        </div>
      ),
  },
];

const tooltipHolidayTaken = ({ days, avgHourly, avgHours, totalPaid }) => (
  <div className='tooltip-holiday-taken'>
    <p>{days} days</p>
    <Divider />
    <p>Avg hourly {avgHourly}</p>
    <Divider />
    <p>Avg hours {avgHours}</p>
    <Divider />
    <p>Total paid: {totalPaid}</p>
  </div>
);

const isRedText = (data, index) => {
  if (index === 6 && !!data) {
    return isObject(data) ? (
      <Tooltip
        placement='bottom'
        color='#ffffff'
        title={tooltipHolidayTaken(data ?? {})}
      >
        <span className='red-text'>{round(data?.days, 2)}</span>
      </Tooltip>
    ) : (
      <span className='red-text'>{round(data, 2)}</span>
    );
  }

  return <span>{round(data, 2)}</span>;
};

const timesheetColumns = [
  {
    title: '',
    dataIndex: 'title',
    key: 'title',
    width: '15%',
    render: (text, record, index) => renderTitle(index),
  },
  {
    title: 'Jan',
    dataIndex: 'month1',
    key: 'month1',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'Feb',
    dataIndex: 'month2',
    key: 'month2',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'Mar',
    dataIndex: 'month3',
    key: 'month3',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'April',
    dataIndex: 'month4',
    key: 'month4',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'May',
    dataIndex: 'month5',
    key: 'month5',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'June',
    dataIndex: 'month6',
    key: 'month6',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'July',
    dataIndex: 'month7',
    key: 'month7',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'August',
    dataIndex: 'month8',
    key: 'month8',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'Sept',
    dataIndex: 'month9',
    key: 'month9',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'Oct',
    dataIndex: 'month10',
    key: 'month10',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'Nov',
    dataIndex: 'month11',
    key: 'month11',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'Dec',
    dataIndex: 'month12',
    key: 'month12',
    render: (text, _, index) => isRedText(text, index),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (text, _, index) => isRedText(text, index),
  },
];

const payrollColumns = [
  {
    title: 'Week Number',
    dataIndex: 'weekNumber',
    key: 'weekNumber',
  },
  {
    title: 'Payroll Wages',
    dataIndex: 'payrollWages',
    key: 'payrollWages',
    render: (item) => (
      <span className='payroll-wages'>{Formatter.currencyGBP(item)}</span>
    ),
  },
];

const Timesheets = ({ className }) => {
  const [year, setYear] = useState(moment().year());
  const { id } = useParams();

  const { totalCount } = useHardCodeSeekers();

  const { data: timesheetTable } = useFetch(['seekerStatistical', year], () =>
    getStatisticalSeeker({ id, year }),
  );

  const { data: payroll } = useFetch(['seekerPayroll', year], () =>
    getPayrollSeeker({ id, year }),
  );

  const { data: timesheet } = useFetch(['seekerTimesheet', year], () =>
    getTimesheetSeeker({ id, year }),
  );

  const subPayroll = ({ title, infoDetail }) => (
    <div className={classNames('sub-payroll', { 'padding-style': !title })}>
      <p className='payroll-title'>{title}</p>
      <Table
        className='payroll-table'
        columns={payrollColumns}
        infoDetail={infoDetail}
      />
    </div>
  );

  return (
    <div className={classNames('timesheets', className)}>
      <div className='year-container'>
        <p className='year'>Year</p>
        <Select
          value={year}
          onChange={(val) => setYear(val)}
          className='year-options'
          options={years}
          placeholder='Choose Week'
        />
      </div>
      <Table
        className='timesheets-table'
        columns={timesheetColumns}
        infoDetail={timesheetTable}
        rowClassName={(_, index) => {
          if (index === 3) {
            return 'white-bg';
          }
          return '';
        }}
      />
      <div className='table-container'>
        <ListView
          className='timesheets-table'
          headerColumns={columns}
          infoDetail={timesheet}
        />
        <div className='pagination-container'>
          <Pagination
            total={totalCount}
            pageSize={25}
            hideOnSinglePage
            responsive
          />
        </div>
      </div>
      <div className='payrolls-container'>
        {subPayroll({
          title: 'Payroll totals',
          infoDetail: payroll?.slice(0, 18),
        })}
        {subPayroll({ infoDetail: payroll?.slice(18, 36) })}
        {subPayroll({ infoDetail: payroll?.slice(36) })}
      </div>
    </div>
  );
};

Timesheets.propTypes = {
  className: PropTypes.string,
};

Timesheets.defaultProps = {};

export default Timesheets;
