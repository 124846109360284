import React from 'react';

import './styles.scss';

// eslint-disable-next-line react/prop-types
const GuestFormCard = ({ children }) => (
  <div className='card-container'>
    <div className='card-content-container'>{children}</div>
  </div>
);

export default GuestFormCard;
