import React from 'react';

import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import Seeker from '../../components/seeker';
import { MANAGE_WORKER_TABS } from '../constants';
import Confirmed from './Jobs/Confirmed';

import './styles.scss';

/**
 * Renders the AssignList component.
 *
 * @param {{
 * positionId: number|string
 * seekers: Array
 * jobDetail: object
 * isLoading: boolean
 * activeKey: string
 * setActiveKey: Function
 * appliedList: Array
 * removedList: Array
 * declineSeekers: Function
 * acceptSeekers: Function
 * selectAllApplied: Function
 * selectAppliedSeekers: Function
 * selectAllDeclined: Function
 * selectDeclinedSeekers: Function
 * isCompletedTab: boolean
 * }} props
 */
const AssignList = ({
  positionId,
  seekers,
  jobDetail,
  isLoading,
  activeKey,
  setActiveKey,
  appliedList,
  removedList,
  declineSeekers,
  acceptSeekers,
  selectAllApplied,
  selectAppliedSeekers,
  selectAllDeclined,
  selectDeclinedSeekers,
  isCompletedTab,
}) => (
  <Tabs
    className='primary-tabs'
    defaultActiveKey={MANAGE_WORKER_TABS.APPLIED}
    onChange={(key) => setActiveKey(key)}
  >
    <Tabs.TabPane tab='Applied' key={MANAGE_WORKER_TABS.APPLIED}>
      <Seeker
        seekers={seekers}
        activeKey={activeKey}
        appliedList={appliedList}
        removedList={removedList}
        declineSeekers={declineSeekers}
        acceptSeekers={acceptSeekers}
        selectAllApplied={selectAllApplied}
        selectAppliedSeekers={selectAppliedSeekers}
        selectAllDeclined={selectAllDeclined}
        selectDeclinedSeekers={selectDeclinedSeekers}
        isLoading={isLoading}
        jobDetail={jobDetail}
        positionId={positionId}
      />
    </Tabs.TabPane>
    <Tabs.TabPane tab='Confirmed' key={MANAGE_WORKER_TABS.CONFIRMED}>
      <Confirmed
        positionId={positionId}
        jobDetail={jobDetail}
        isCompletedTab={isCompletedTab}
      />
    </Tabs.TabPane>
    <Tabs.TabPane tab='Declined' key={MANAGE_WORKER_TABS.DECLINED}>
      <Seeker
        seekers={seekers}
        activeKey={activeKey}
        jobDetail={jobDetail}
        isLoading={isLoading}
        positionId={positionId}
      />
    </Tabs.TabPane>
  </Tabs>
);

export default AssignList;

AssignList.propTypes = {
  isLoading: PropTypes.bool,
  positionId: PropTypes.number,
  seekers: PropTypes.array,
  jobDetail: PropTypes.object,
  activeKey: PropTypes.string,
  appliedList: PropTypes.array,
  setActiveKey: PropTypes.func,
  removedList: PropTypes.array,
  declineSeekers: PropTypes.func,
  acceptSeekers: PropTypes.func,
  selectAllApplied: PropTypes.func,
  selectAppliedSeekers: PropTypes.func,
  selectAllDeclined: PropTypes.func,
  selectDeclinedSeekers: PropTypes.func,
  isCompletedTab: PropTypes.bool,
};

AssignList.defaultProps = {
  positionId: null,
  isLoading: false,
  seekers: [],
  jobDetail: {},
  activeKey: 'applied',
  setActiveKey: () => {},
  appliedList: [],
  removedList: [],
  declineSeekers: () => {},
  acceptSeekers: () => {},
  selectAllApplied: () => {},
  selectAppliedSeekers: () => {},
  selectAllDeclined: () => {},
  selectDeclinedSeekers: () => {},
};
