import { camelizeKeys } from 'humps';
import { groupBy } from 'lodash';

import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getViewSend = async ({
  size,
  number,
  weekNum,
  searchTerm,
  employerId,
  orderDirection,
  year,
  invoiceId,
}) => {
  const response = await request.get('/trackers/invoicing/invoices', {
    params: {
      page: {
        size,
        number,
      },
      order: {
        orderBy: 'invoice_id',
        orderDirection,
      },
      filter: {
        invoiceWeek: weekNum,
        searchTerm,
        employerId,
        invoiceYear: year,
        invoiceId,
      },
    },
  });

  const normalizedData = normalizeIndexResponse(response);

  const groupedInvoices = camelizeKeys(
    groupBy(normalizedData?.data, 'paymentType'),
  );

  return {
    ...normalizedData,
    data: groupedInvoices,
  };
};

export default getViewSend;
