import request from '@/request';

const saveAsDraft = async (payload) => {
  const endpoint = '/rotas/rota_shift_plans/save_as_draft';

  const rotaShiftPlansAttributes = payload?.map((dt) => ({
    id: dt?.id,
    numberOfRequired: parseFloat(dt.numberOfRequired),
  }));

  const resData = await request.patch(endpoint, {
    rotaShiftPlans: { rotaShiftPlansAttributes },
  });

  return resData;
};

export default saveAsDraft;
