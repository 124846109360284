import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const HeaderStep = ({ current }) => (
  <div className='header-steps'>
    <div className='line-wrapper'>
      <div className='line line-1 active' />
      <div className={classNames('line line-2', { active: current > 10 })} />
      <div className={classNames('line line-23', { active: current > 13 })} />
    </div>
    <div className='step step-1 active' />
    <div className={classNames('step step-2', { active: current > 2 })} />
    <div className={classNames('step step-3', { active: current > 10 })} />
    <div className={classNames('step step-4', { active: current > 13 })} />
  </div>
);

HeaderStep.propTypes = {
  current: PropTypes.func,
};

HeaderStep.defaultProps = {
  current: 0,
};

export default HeaderStep;
