/* eslint-disable max-len */
import { isArray, isEmpty, keyBy, merge } from 'lodash';
import moment from 'moment';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const groupShiftByShiftId = (shifts) => {
  const shiftWithRotaShiftPlanId = shifts.map((item) => ({
    rotaShiftPlanId: item.id,
  }));

  return keyBy(shiftWithRotaShiftPlanId, 'rotaShiftPlanId');
};

const groupEmployeeByShiftId = (employees, date) => {
  const employeesWithDate = (employees ?? []).map((item) => ({
    ...item,
    dateSelect: date,
  }));

  return keyBy(employeesWithDate, 'rotaShiftPlanId');
};

const addDateOffForEmployees = (employees) =>
  employees.map((employee) => ({
    dateSelect: 'off',
    ...employee,
  }));

const formatDate = (rotaWeekPlan) =>
  `${moment(rotaWeekPlan.startAt).format('HH:mm')} - ${moment(
    rotaWeekPlan.endAt,
  ).format('HH:mm')}`;

const getOneRotaWeekPlan = async (/** @type {string|number} */ id) => {
  const endpoint = `/rotas/rota_week_plans/${id}`;

  const res = await request.get(endpoint);
  const data = normalizeShowResponse(res);
  const rotaWeekPlan = data?.data;
  const dateSelect = formatDate(rotaWeekPlan);

  const rotaShiftPlan = groupShiftByShiftId(
    rotaWeekPlan.rotaShiftPlanAttributes,
  );
  let employeesAttributes = !isArray(rotaWeekPlan.employeeAttributes)
    ? [{ ...rotaWeekPlan.employeeAttributes }]
    : rotaWeekPlan.employeeAttributes;

  employeesAttributes = employeesAttributes.map(
    (/** @type {{object}} */ employee) => {
      const employeesAttr = groupEmployeeByShiftId(
        employee.employeesRotaShiftPlans,
        dateSelect,
      );
      if (isEmpty(employeesAttr)) return null;

      return {
        ...employee,
        employeesRotaShiftPlansAttributes: addDateOffForEmployees(
          Object.values(merge(employeesAttr, rotaShiftPlan)),
        ),
      };
    },
  );

  const differencePeopleList = Array(7).fill();

  (rotaWeekPlan?.rotaShiftPlanAttributes ?? []).forEach((element) => {
    differencePeopleList[element.weekDay] = element?.numberOfRequired ?? 0;
  });

  return {
    ...rotaWeekPlan,
    dateSelect,
    employeesAttributes: employeesAttributes ?? [],
    differencePeopleList,
  };
};

export default getOneRotaWeekPlan;
