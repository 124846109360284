/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem } from '@/components';

import './styles.scss';

const Declarations = ({ nextStep, onValuesChange, initialValues, form }) => {
  const { p46s } = initialValues;
  return (
    <div className='flex-one-column declarations'>
      <div className='title'>Declarations</div>
      <div className='label'>
        <p>P46 Statement:</p>
        <p>
          Read all the following statements carefully and select the one that
          applies to you.*
        </p>
      </div>

      <Form
        form={form}
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <FormItem
          rules={[
            {
              required: true,
              message: 'Please select at least one',
            },
          ]}
          required
          name='p46s'
        >
          <Radio.Group className='recruitment-radio'>
            <Radio value={1}>
              This is my first job since last 6 April and I have not been
              receiving taxable Jobseeker's Allowance, Employement and Support
              Allowance or taxable Incapacity Benefit or a state of occupational
              pension
            </Radio>
            <Radio value={2}>
              This is now my only job, but since last 6 April I have had another
              job, or have received taxable Jobseeker's Allowance, Employement
              and Support Allowance or taxable Incapacity Benefit or a state of
              occupational pension.
            </Radio>
            <Radio value={3}>
              I have another job or receive state of occupational pension.
            </Radio>
          </Radio.Group>
        </FormItem>
        <Button
          htmlType='submit'
          className='shared-button confirm-button '
          disabled={!p46s}
        >
          Next Step
        </Button>
      </Form>
    </div>
  );
};

Declarations.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.func,
};

export default Declarations;
