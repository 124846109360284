/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { ColorCheckbox, FormItem } from '@/components';
import { time } from '@/utils';

import { DISABLED_STATUS, FORM_SELECT_NAMES } from '../../constants';
import { checkAndSelectAll, selectAll } from '../../utils/select';
import HoursCell from '../HoursCell';
import { useTenantContext } from '../../../../TenantWrapper';

const PAID = 'paid';

const ListJobTypeColumns = ({
  form,
  status,
  isEdit,
  nodesRef,
  jobTypeId,
  payrollStatus,
  timesheetDetails,
  isCurrentWeekPushed,
}) => {
  const { currency } = useTenantContext();
  const { dateRangeTimecard } = _.head(_.values(timesheetDetails));
  const isDisabledStatus = DISABLED_STATUS.includes(status);

  const handleSelectRow = (key) => {
    const formData = form.getFieldsValue();
    const timesheetDetailFormData = formData[jobTypeId][key];
    const isCheckedRowValue = _.get(
      timesheetDetailFormData,
      FORM_SELECT_NAMES.rows,
      false,
    );
    formData[jobTypeId][key] = selectAll({
      object: timesheetDetailFormData,
      checkboxType: FORM_SELECT_NAMES.cell,
      value: isCheckedRowValue,
    });
    checkAndSelectAll({ formData, jobTypeId });

    form.setFieldsValue(formData);
  };

  const showPaid = () => (
    <div type='text' className='button-paid'>
      Paid
    </div>
  );

  const calTotalHours = (record) =>
    _.sumBy(_.values(record.timecards), (timecardInfo) => {
      const dayValue = Number(
        _.get(timecardInfo, 'attributes.schedule.value', 0),
      );
      return _.get(timecardInfo, 'attributes.noShow') ? 0 : dayValue;
    });

  const rangeDateColumns = dateRangeTimecard.map((date) => {
    const dayOfWeek = moment(date).format('ddd');
    return {
      title: (
        <div>
          <div>{dayOfWeek}</div>
          <div>{moment(date).format('DD/MM/YYYY')}</div>
        </div>
      ),
      width: '6.4%',
      render: (__, record) => (
        <div
          className={classNames('cell-container', {
            'started-columns': isEdit,
          })}
        >
          <HoursCell
            status={status}
            jobTypeId={jobTypeId}
            isEdit={isEdit}
            date={dayOfWeek}
            record={record}
          />
        </div>
      ),
    };
  });

  const columns = [
    {
      title: 'Job type',
      dataIndex: ['skill', 'name'],
      width: '6.4%',
      render: (data) => (
        <Tooltip placement='topLeft' title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: 'Employee',
      dataIndex: ['omniEmployee', 'name'],
      width: '10%',
      render: (name, record) => {
        const { employeeId } = record.omniEmployee;
        return (
          <div
            ref={(node) => {
              nodesRef.current.push(node);
              return node;
            }}
            className='cell-container'
          >
            <Tooltip placement='topLeft' title={name}>
              <Link to={`/seeker-profile/${employeeId}`}>
                <span className='seeker-name'>{name}</span>
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Payroll',
      width: '6.4%',
      render: (__, record) => {
        const payroll = _.get(
          _.head(_.values(record.timecards)),
          'attributes.payroll',
          null,
        );
        return (
          <Tooltip placement='topLeft' title={payroll}>
            {payroll}
          </Tooltip>
        );
      },
    },
    ...rangeDateColumns,
    {
      title: 'Total',
      dataIndex: 'total',
      width: '6.4%',
      render: (__, record) => {
        const totalHours = calTotalHours(record);
        return (
          <Tooltip placement='topLeft'>
            {time.convertTimeHoursCell(totalHours)}
          </Tooltip>
        );
      },
    },
    {
      title: 'Pay & Charge',
      dataIndex: 'payAndCharge',
      width: '6.4%',
      render: (__, record) => {
        const roundedPayrate = Number(record?.payrate ?? 0).toFixed(2);
        const roundedChargerate = Number(record?.chargeRate ?? 0).toFixed(2);
        return (
          <Tooltip
            placement='topLeft'
            title={`${currency}${roundedPayrate} - ${currency}${roundedChargerate}`}
          >
            <div className='pay-and-charge'>
              {currency}
              {roundedPayrate}
            </div>
            <div className='pay-and-charge'>
              {currency}
              {roundedChargerate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Total Pay',
      dataIndex: 'totalPay',
      width: '6.4%',
      render: (totalPay) => {
        const formatedTotalPay = Number(totalPay ?? 0).toFixed(2);
        return (
          <Tooltip placement='topLeft' title={`${currency}${formatedTotalPay}`}>
            {currency}
            {formatedTotalPay}
          </Tooltip>
        );
      },
    },
    {
      title: 'Total Charge',
      dataIndex: 'totalCharge',
      width: '6.4%',
      render: (totalCharge) => {
        const formatedTotalCharge = Number(totalCharge ?? 0).toFixed(2);
        return (
          <Tooltip
            placement='topLeft'
            title={`${currency}${formatedTotalCharge}`}
            className='total-charge'
          >
            {currency}
            {formatedTotalCharge}
          </Tooltip>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '6%',
      render: () => (
        <ColorCheckbox checked className={`cell-select ${status}`} />
      ),
    },
    {
      title: 'Select',
      dataIndex: 'key',
      width: '6%',
      render: (key, { currentPayrollStatus }) => (
        <FormItem
          name={[`${jobTypeId}`, `${key}`, 'isCheckedRow']}
          valuePropName='checked'
        >
          {(payrollStatus === PAID && !isCurrentWeekPushed) ||
          (isCurrentWeekPushed && currentPayrollStatus === PAID) ? (
            showPaid()
          ) : (
            <ColorCheckbox
              disabled={isDisabledStatus}
              className={`cell-select color-select ${status}`}
              onChange={() => handleSelectRow(key)}
            />
          )}
        </FormItem>
      ),
    },
  ];

  return columns;
};

export default ListJobTypeColumns;
