import React, { useEffect, useRef, useState } from 'react';

import { Pagination, Form, Spin, message, Tooltip } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  FormItem,
  AutoComplete,
  MultipleSelect,
  Button,
  CSV,
} from '@/components';
import Listview from '@/components/ListView';
import {
  DEBOUNCE_WAIT,
  ORDER_BY,
  PAGE_SIZE_OPTIONS,
  ACCESS_LEVEL,
} from '@/constants';
import { UserService, UsersServices } from '@/services';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';

import { useDepartmentOptions } from '../../hooks';
import './styles.scss';

const columns = [
  {
    title: 'Name',
    dataIndex: 'userFullname',
    render: (/** @type {string} */ name) => (
      <Tooltip placement='topLeft' title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    title: 'Department',
    dataIndex: 'department',
    render: (department) => {
      return (
        <Tooltip placement='topLeft' title={department}>
          {department}
        </Tooltip>
      );
    },
  },
  {
    title: 'Role',
    dataIndex: 'userPosition',
    render: (/** @type {string} */ name) => (
      <Tooltip placement='topLeft' title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    title: 'Access level',
    dataIndex: 'userLevelId',
    render: (/** @type {string} */ name) => (
      <Tooltip placement='topLeft' title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    title: 'Line Manager',
    dataIndex: 'holidayApproval',
    render: (/** @type {string} */ name) => (
      <Tooltip placement='topLeft' title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    title: 'Last Action',
    defaultSortOrder: 'descend',
    sorter: true,
    render: (dt) => {
      const content = (
        <div className='last-action'>
          <p>{moment(dt?.last_active_at).format('DD/MM/YYYY')} </p>
        </div>
      );

      return (
        <Tooltip placement='topLeft' title={content}>
          {content}
        </Tooltip>
      );
    },
  },
];

const Users = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const refCSVLink = useRef(null);
  const [wSearchTerm, setWatchSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sortOrder, setSortOrder] = useState('desc');
  const [isDownload, setIsDownload] = useState(false);
  const departments = useDepartmentOptions();

  const { data, refetch } = useQuery(
    ['users', currentPage, pageSize],
    () => {
      const { searchTerm, departmentId, accessLevel } = form.getFieldsValue();
      const page = { number: currentPage, size: pageSize };
      const filter = { searchTerm, departmentId, accessLevel };
      const order = { orderBy: ORDER_BY, orderDirection: sortOrder };

      return UsersServices.getUsers(page, filter, order);
    },
    {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const {
    isFetching: isFetchingDownloadCsv,
    data: dataUsersExportCSv = [],
    refetch: refetchCandidateExportCSV,
  } = useQuery(
    ['user:download-csv', wSearchTerm],
    () => UserService.downloadCSV(form.getFieldsValue()),
    {
      cacheTime: 0,
      retry: false,
      enabled: false,
      onSuccess: () => {
        setIsDownload(true);
      },
      onError: () => {
        // eslint-disable-next-line quotes
        message.error("Can't download CSV");
      },
    },
  );

  const { data: listSearchTerms = [] } = useQuery(
    ['get-list-users-search-terms', wSearchTerm],
    () => UserService.getListSearchTerms(wSearchTerm),
    {
      retry: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (error) => message.error(error.message),
    },
  );

  useEffect(() => {
    if (isDownload) {
      refCSVLink.current.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const headerTable = generateHeaderTable(columns);

  const handleSearchData = () => {
    setCurrentPage(1);
    refetch();
  };

  const handleSort = (order) => {
    setSortOrder(Formatter.makeAbbrevSortType(order));
  };

  const handleChangePage = (page) => setCurrentPage(page);

  const handleClearFilter = () => {
    setCurrentPage(1);
    form.resetFields();
    refetch();
  };

  const handleSearchTermChange = debounce(setWatchSearchTerm, DEBOUNCE_WAIT);
  return (
    <div className='users'>
      <Spin spinning={isFetchingDownloadCsv}>
        <Form
          className='input-container'
          form={form}
          onFinish={handleSearchData}
        >
          <FormItem name='searchTerm' className='form-item' label='Search'>
            <AutoComplete
              defaultActiveFirstOption
              onSearch={handleSearchTermChange}
              options={listSearchTerms}
              placeholder='Name or email address'
            />
          </FormItem>
          <FormItem
            name='departmentId'
            className='form-item'
            label='Department'
          >
            <MultipleSelect
              name='departmentId'
              placeholder='Department'
              options={departments}
            />
          </FormItem>
          <FormItem
            name='accessLevel'
            className='form-item'
            label='Access Level'
          >
            <MultipleSelect
              name='accessLevel'
              placeholder='Access Level'
              options={ACCESS_LEVEL}
            />
          </FormItem>
          <div className='buttons form-item'>
            <Button className='yellow-button style-btn' htmlType='submit'>
              Search
            </Button>
            <Button
              onClick={handleClearFilter}
              className='filter-button style-btn'
            >
              Clear Filter
            </Button>
          </div>
        </Form>
        <Listview
          headerColumns={headerTable}
          infoDetail={data?.data ?? []}
          onRow={(key) => history.push(`/edit-user/${key}`)}
          locale={{
            emptyText: 'No Users match your search criteria',
          }}
          handleSort={handleSort}
        />
        <div className='user-footer'>
          <div className='pagination-container'>
            <Pagination
              responsive
              showSizeChanger
              current={currentPage}
              pageSize={pageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              total={data?.totalCount || 0}
              onChange={handleChangePage}
              onShowSizeChange={(current, pageSizes) => {
                setCurrentPage(current);
                setPageSize(pageSizes);
              }}
            />
          </div>
          <Button
            className='yellow-button download-csv-btn'
            onClick={refetchCandidateExportCSV}
            disabled={isFetchingDownloadCsv}
          >
            {isFetchingDownloadCsv ? 'Loading csv...' : 'Download CSV'}
          </Button>

          <CSV
            ref={refCSVLink}
            filename='data-candidate.csv'
            data={dataUsersExportCSv}
          />
        </div>
      </Spin>
    </div>
  );
};
export default Users;
