import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import moment from 'moment';
import qs from 'qs';

import { ENV } from '@/constants';

import { CustomError } from './utils/errorUtil';

const httpPostCode = axios.create({
  baseURL: ENV.POST_CODE_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

httpPostCode.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  ({ response }) => Promise.reject(new CustomError(response)),
);

httpPostCode.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.url = `${config.url}`;

    newConfig.paramsSerializer = (params) =>
      qs.stringify(params, {
        encode: false,
        serializeDate: (date) => moment(date).format('YYYY-MM-DD'),
      });

    if (newConfig.headers['Content-Type'] === 'multipart/form-data')
      return newConfig;

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params);
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default httpPostCode;
