import React from 'react';

import { useParams } from 'react-router-dom';

import { GeneralTemplate, Tabs } from '@/components';

import CreateNewUser from './CreateNewUser';

import './styles.scss';

const NewUser = () => {
  const { userId } = useParams();

  const data = [
    { item: 'User', to: '/users' },
    { item: userId ? 'View/Edit' : 'Create New User' },
  ];

  const tabPanes = [
    {
      tab: 'Users',
      key: 'Users',
      child: <CreateNewUser />,
    },
    // TODO: Customer must hidden these bellow tabs, show when implements phase 2
    // {
    //   tab: 'Holidays',
    //   key: 'Holidays',
    //   child: null,
    // },
    // {
    //   tab: 'GIGProvider',
    //   key: 'GIGProvider',
    //   child: null,
    // },
  ];

  return (
    <GeneralTemplate
      data={data}
      mainItem='User'
      headerText='Create New User'
      hasButton={false}
      subTemplateClassName='new-user-sub-template'
    >
      <Tabs className='new-user-tab' tabPanes={tabPanes} />
    </GeneralTemplate>
  );
};

export default NewUser;
