import React from 'react';

import { Form } from 'antd';
import { useToggle } from 'usehooks-ts';

import { Button, Modal } from '@/components';
import {
  ADDITIONAL_TYPES,
  EMPTY_SECTIONS,
} from '@/pages/PayTemplate/Invoice/constants';

import './styles.scss';

const AddSections = () => {
  const formInstance = Form.useFormInstance();
  const [visibleAddModal, toggleAddModal] = useToggle();

  const handleAddSection = (type) => {
    const sections = formInstance.getFieldValue(type) ?? [];
    formInstance.setFields([
      {
        name: type,
        value: [...sections, EMPTY_SECTIONS],
      },
    ]);
    toggleAddModal();
  };

  return (
    <div className='add-another-section'>
      <Button
        className='yellow-button add-section-btn'
        onClick={toggleAddModal}
      >
        Add Another Section
      </Button>
      <Modal
        isShowFooter={false}
        visible={visibleAddModal}
        title='Additional Section'
        onClickNo={toggleAddModal}
      >
        <Button
          className='yellow-button additional-btn'
          onClick={() => handleAddSection(ADDITIONAL_TYPES.labour.key)}
        >
          Additional Hours
        </Button>
        <Button
          className='yellow-button additional-btn'
          onClick={() => handleAddSection(ADDITIONAL_TYPES.cost.key)}
        >
          Additional Cost
        </Button>
      </Modal>
    </div>
  );
};

export default AddSections;
