import React, { useEffect, useState } from 'react';

import { Form, Button, Alert, message } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ModalConfirm, GeneralTemplate } from '@/components';
import { RECRUITMENT_VIEW } from '@/constants';
import { createApplicant } from '@/services/applicantService/create';
import handleUploadFile from '@/utils/upLoadFiles';
import useModal from '@/utils/useModal';

import ApplicantContact from '../applicantContact';
import ApplicantExperience from '../applicantExp';
import ApplicantRole from '../applicantRole';

import '../styles.scss';

const breadcrumb = [
  { item: 'Applicant', to: `/recruitment/${RECRUITMENT_VIEW.APPLICATION}` },
  {
    item: 'Create New Applicant',
    to: '/create-new-applicant',
  },
];

const CreateApplicant = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [displayModal, showModal, hiddenModal] = useModal();

  const [error, setError] = useState('');

  const {
    mutate: createNewApplicant,
    isLoading,
    isSuccess,
    reset,
  } = useMutation(
    async (newApplicant) => {
      const listFileUpload = await handleUploadFile(form);
      return createApplicant(newApplicant, listFileUpload);
    },
    {
      onSuccess: () => {
        message.success('Applicant created successfully');
        history.push('/recruitment/application');
      },
      onError: (err) => {
        if (err.status && err.message) {
          setError(err.message);
          hiddenModal();
          window.scrollTo(0, 0);
        }
      },
    },
  );

  const onFinish = () => showModal();
  const handleGoBack = () => history.goBack();

  useEffect(() => {
    if (error) {
      reset();
    }
  }, [error, reset, hiddenModal]);

  const handleConfirm = async () => {
    const formValue = form.getFieldsValue();
    createNewApplicant(formValue);
  };

  return (
    <GeneralTemplate
      mainItem='Recruitment'
      headerText='Create New Applicant'
      hasButton={false}
      data={breadcrumb}
      subTemplateClassName='applicant-form'
    >
      <Form
        form={form}
        onFinish={onFinish}
        className='applicant-form'
        initialValues={{
          applicantDate: moment(),
        }}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <div className='applicant-body'>
          {error && (
            <Alert
              message='Error'
              description={error}
              type='error'
              showIcon
              closable
              onClose={() => setError('')}
            />
          )}
          <ApplicantContact />
          <ApplicantRole />
          <ApplicantExperience />
          <div className='applicant-footer'>
            <Button className='btn-cancel' onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button className='btn-save' htmlType='submit'>
              Save
            </Button>
          </div>
        </div>
      </Form>
      <ModalConfirm
        title='Create New Applicant?'
        titleSuccess='Applicant created successfully'
        visible={displayModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleGoBack : handleConfirm}
        onClickNo={isSuccess ? handleGoBack : hiddenModal}
      >
        Are you sure you want to create new Applicant?
      </ModalConfirm>
    </GeneralTemplate>
  );
};
export default CreateApplicant;
