import { find, isArray, map } from 'lodash';

import { INDUSTRY, SEEKER_ROTA_TYPES } from '@/constants';
import request from '@/request';
import { checkAllFieldsTruthy } from '@/utils/array';
import { forcePreferredRoles } from '@/utils/forcePreferredRoles';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

export const getSeekerProfile = async (id) => {
  const res = await request.get(`seekers/employees/${id}`);
  const data = normalizeShowResponse(res);

  const studentHolidaysMapped = (res.data?.included ?? [])
    .filter((item) => item.type === 'omni_employee_student_vacation')
    .map(({ attributes }) => ({
      createdAt: attributes?.created_at,
      endDate: attributes?.holiday_until,
      startDate: attributes?.holiday_from,
      id: attributes?.id,
    }));

  const seekerInfo = data?.data;

  const { vacancyApplicantAttributes, accessType, rotas } = seekerInfo;

  const { preferredRoles, internalNote, vacancyApplicantReferences, p46S } =
    vacancyApplicantAttributes ?? {};

  const convict = vacancyApplicantAttributes?.convict;
  // TODO: will write 1 function parse all data
  const vacancyApplicantLanguagesAttributes = isArray(
    seekerInfo.vacancyApplicantLanguageAttributes,
  )
    ? { ...seekerInfo.vacancyApplicantLanguageAttributes }
    : { 0: seekerInfo.vacancyApplicantLanguageAttributes };

  const vacancyApplicantEmploymentsAttributes = !isArray(
    seekerInfo.vacancyApplicantEmploymentAttributes,
  )
    ? [{ ...seekerInfo.vacancyApplicantEmploymentAttributes }]
    : seekerInfo.vacancyApplicantEmploymentAttributes;

  const medicalConditionsAttributes = !isArray(
    seekerInfo.medicalConditionAttributes,
  )
    ? [seekerInfo.medicalConditionAttributes]
    : seekerInfo.medicalConditionAttributes;

  const comments = seekerInfo.commentAttributes || [];
  const commentsAttributes = !isArray(comments) ? [comments] : comments;

  const employeeNotes = seekerInfo.employeeNotes || [];
  const employeeNotesAttributes = !isArray(employeeNotes)
    ? [employeeNotes]
    : employeeNotes;

  const industryExperiencesVacancyApplicants = !isArray(
    seekerInfo.industryExperiencesVacancyApplicantsAttributes,
  )
    ? []
    : seekerInfo.industryExperiencesVacancyApplicantsAttributes;

  const industryExperiencesVacancyApplicantsAttributes =
    industryExperiencesVacancyApplicants.map(
      ({ industryExperienceId = null } = {}) => industryExperienceId,
    );

  const { otherValue } =
    industryExperiencesVacancyApplicants.find(
      (item) => item?.industryExperienceId === INDUSTRY.OTHER,
    ) ?? {};

  const { employeeAttributes } = seekerInfo;

  const isEmployeeBlocked = () =>
    seekerInfo.omniUserBlockedOmniEmployee !== null &&
    seekerInfo.omniUserBlockedOmniEmployeeAttributes.blockType !== null;

  return {
    ...seekerInfo,
    strikeStatus: [...Array(seekerInfo.strikeStatus).keys()],
    vacancyApplicantReferencesAttributes: vacancyApplicantReferences,
    isFirstJobNoReference: !checkAllFieldsTruthy(vacancyApplicantReferences),
    vacancyApplicantAttributes: {
      ...vacancyApplicantAttributes,
      medicalConditionsAttributes,
      preferredRoles: forcePreferredRoles(preferredRoles),
      hasConvict: !!convict,
      nationality: vacancyApplicantAttributes?.nationality ?? 1,
      ...employeeAttributes,
      industryExperiencesVacancyApplicantsAttributes,
      industryExperienceAttributesOld: industryExperiencesVacancyApplicants,
      industryExperienceValueInput: otherValue,
    },
    vacancyApplicantLanguagesAttributes,
    vacancyApplicantEmploymentsAttributes,
    commentsAttributes: [],
    fullCommentsAttributes: [...commentsAttributes, ...employeeNotesAttributes],
    internalNote,
    studentHolidays: studentHolidaysMapped,
    isBlocked: isEmployeeBlocked(),
    p46s: p46S,
    rotaTypes: find(SEEKER_ROTA_TYPES, { value: accessType })?.types ?? [],
    rotas: map(rotas, (item) => item?.attributes),
  };
};
