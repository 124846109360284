import { ORDER_BY, ORDER_DIRECTION } from '@/constants';
import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';
import { Normalize, FormatIndexStrategy } from '@/utils/normalizeResponse';

export default async function getCosts({
  sortOrder,
  pageNumber,
  pageSize,
  ...filter
}) {
  try {
    const page = {
      size: pageSize,
      number: pageNumber,
    };

    const order = {
      orderBy: ORDER_BY,
      orderDirection: sortOrder || ORDER_DIRECTION,
    };

    const endpoint = '/trackers/expense/costs';
    const res = await request.get(endpoint, {
      params: {
        page,
        filter,
        order,
      },
    });
    const normalize = new Normalize();
    const strategy = new FormatIndexStrategy(res);
    normalize.setStrategy(strategy);
    const data = normalize.formatResponse();

    return data;
  } catch (error) {
    throw new ServiceError(error);
  }
}
