import React, { useState } from 'react';

import { Form, message } from 'antd';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { useFetch } from '@/hooks';
import { JobServices } from '@/services';

import JobModal from '..';
import { TYPE_SHIFTS, coverCeilTo15Minutes } from '../constant';

const JobModalEdit = ({ showPopup, hidePopup, jobId, onPostSuccess }) => {
  const [form] = Form.useForm();

  const statusJob = Form.useWatch('status', form);

  const [repostMode, setRepostMode] = useState(false);

  const { isLoading } = useFetch(
    ['getJobId', jobId],
    () => JobServices.getJobIdWithJobModal(jobId),
    {
      onSuccess: (dt) => {
        const { shiftType, jobResponsesAttributes } = dt;
        form.setFieldsValue({
          ...dt,
          seekersIndividualShifts:
            shiftType === TYPE_SHIFTS.INDIVIDUAL ? jobResponsesAttributes : [],
          seekersGroupShifts:
            shiftType === TYPE_SHIFTS.GROUP ? jobResponsesAttributes : [],
        });
      },
      initialData: {},
    },
  );

  const { mutate: editJob, isLoading: isLoadingEditJob } = useMutation(
    (newJobInfo /* @params {object} newJobInfo */) =>
      JobServices.editJob(jobId, newJobInfo),
    {
      onError: (errors) => {
        message.error(errors.message);
      },
      onSuccess: () => {
        message.success('Edit Job successfully');

        hidePopup();
        onPostSuccess();
      },
    },
  );

  const { mutate: createJob, isLoading: isLoadingCreateJob } = useMutation(
    JobServices.createJob,
    {
      onError: (errors) => {
        message.error(errors.message);
      },
      onSuccess: () => {
        message.success('Create job successfully');

        hidePopup();
        onPostSuccess();
      },
    },
  );

  const scrollToTop = () =>
    form.scrollToField('employerId', { block: 'center', behavior: 'smooth' });

  const isNotEdit = statusJob === 'completed' || statusJob === 'live';

  const openRepostMode = () => {
    setRepostMode(true);
    scrollToTop();

    const now = moment();
    const startMoment = coverCeilTo15Minutes(now);
    const endMoment = moment(startMoment).add(1, 'h');

    form.setFieldsValue({
      schedule: {
        items: [
          {
            startAt: startMoment,
            endAt: endMoment,
            break: 0,
            posted: 1,
          },
        ],
      },
    });
  };

  const onSubmitJobPosting = () => {
    if (isNotEdit) {
      if (!repostMode) {
        openRepostMode();
      } else {
        createJob(form.getFieldsValue());
      }
    } else {
      editJob(form.getFieldsValue());
    }
  };

  return (
    <JobModal
      onSubmit={() => onSubmitJobPosting()}
      form={form}
      isLoading={isLoading || isLoadingCreateJob || isLoadingEditJob}
      showPopup={showPopup}
      status={repostMode ? 'create' : 'edit'}
      disabled={repostMode ? false : isNotEdit}
      hidePopup={hidePopup}
    />
  );
};

JobModalEdit.propTypes = {
  showPopup: PropTypes.bool,
  hidePopup: PropTypes.func,
  jobId: PropTypes.string,
  onPostSuccess: PropTypes.func,
};

JobModalEdit.defaultProps = {
  jobId: '',
  showPopup: false,
  hidePopup: () => {},
  onPostSuccess: () => {},
};

export default JobModalEdit;
