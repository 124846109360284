import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import moment from 'moment';
import qs from 'qs';

import { ENV } from '@/constants';
import { CustomError } from '@/utils/errorUtil';
import { getAuthToken, removeAuthToken } from '@/utils/localStorageUtil';

const request = axios.create({
  baseURL: ENV.API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

request.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  async (error) => {
    const { config, response } = error;
    if (config?.url !== '/login' && response?.status === 401) {
      removeAuthToken();
      window.location.reload();
    }
    return Promise.reject(new CustomError(response));
  },
);

request.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    const newConfig = { ...config };
    newConfig.url = `${config.url}`;

    if (authToken) {
      newConfig.headers.Authorization = `Bearer ${authToken}`;
      newConfig.headers.Timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    newConfig.paramsSerializer = (params) =>
      qs.stringify(params, {
        encode: false,
        serializeDate: (date) => moment(date).format('YYYY-MM-DD'),
        arrayFormat: 'brackets',
      });

    if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
      return newConfig;
    }

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params);
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default request;
