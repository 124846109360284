/* eslint-disable no-useless-catch */
import { get } from 'lodash';
import moment from 'moment';

import { INDUSTRY, RESOURCE_TYPE } from '@/constants';
import request from '@/request';
import { ServiceError } from '@/utils/errorUtil';
import { evaluationTextToNumber } from '@/utils/evaluation';
import Formatter from '@/utils/Formatter';
import { normalizeShowResponse } from '@/utils/normalizeResponse';
import { formatResponse } from '@/utils/thunkUtil';
import { handleUploadFiles } from '@/utils/upLoadFiles';

import { getAddressInfomation } from './getAddressInfomation';

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

export const getCandidates = async (params) => {
  const endpoint = '/recruitments/candidates';
  const resData = await request.get(endpoint, { params });

  const candidatePageData = formatResponse(resData, { endpoint });

  const { data } = Formatter.buildEndpoint(
    candidatePageData.endpoint,
    candidatePageData,
    RESOURCE_TYPE.vacancyApplicant,
  );

  const { totalCount, totalPage, currentPage } = Formatter.mergeMeta(
    candidatePageData.vacancyApplicant,
    endpoint,
    candidatePageData.meta,
  )[endpoint];

  const candidateListInfo =
    data?.map((item) => ({
      key: item?.id,
      number: item?.id,
      fullName: `${item?.firstname} ${item?.lastname}`,
      emailAddress: item?.email,
      phoneNumber: item?.phone,
      postcode: item?.zip,
      specificRoleApplied: item?.generalRole,
      otherRoles: `${item?.otherRoles ?? []}`,
      registrationDate: item?.createdAt
        ? moment(item?.createdAt).utc().format('DD/MM/YYYY')
        : '',
      location: item?.location,
      status: item?.candidateStatus,
      isOver18: item?.isOver18,
    })) ?? [];

  return {
    applicantListInfo: candidateListInfo,
    totalCount: totalCount ?? 0,
    totalPage: totalPage ?? 0,
    currentPage: currentPage ?? 0,
  };
};

export const getCandidateById = async (candidateId) => {
  try {
    if (!candidateId) return {};

    const attributesType = 'omni_employee';
    const endpoint = `/recruitments/candidates/${candidateId}`;
    const res = await request.get(endpoint);
    const foundAttributesType = res.data.included.find(
      (item) => item.type === attributesType,
    );
    const candidateAttributes = get(foundAttributesType, 'attributes', {});
    const resNormalized = normalizeShowResponse(res);
    return (
      {
        ...resNormalized?.data,
        p46s: resNormalized?.data?.p46S,
        isOver18: candidateAttributes?.is_over18 ?? true,
      } ?? {}
    );
  } catch (error) {
    throw new ServiceError(error);
  }
};

const candidateUploadFiles = async ({
  eLearningDocs,
  documentationDocs,
  licenceDocs,
  rightToWorkDocs,
  blankBoxs = [],
}) => {
  const eLearningDocsFilesObject = {};
  const documentationDocsFilesObject = {};
  const licenceDocsFilesObject = {};
  const rightToWorkDocsFilesObject = {};
  const blankBoxsObject = blankBoxs.map((item) => [
    item.fileBlob,
    `${item.boxType}-${item.typeValue}`,
  ]);

  eLearningDocs.forEach((item) => {
    eLearningDocsFilesObject[item.typeValue] = item.fileBlob;
  });
  documentationDocs.forEach((item) => {
    documentationDocsFilesObject[item.typeValue] = item.fileBlob;
  });
  licenceDocs.forEach((item) => {
    licenceDocsFilesObject[item.typeValue] = item.fileBlob;
  });
  rightToWorkDocs.forEach((item) => {
    rightToWorkDocsFilesObject[item.typeValue] = item.fileBlob;
  });

  const filesUploaded = await handleUploadFiles([
    [documentationDocsFilesObject?.contractFile, 'contractFile'],
    [documentationDocsFilesObject?.cvUpload, 'cvUpload'],
    [documentationDocsFilesObject?.profilepic, 'profilepic'],
    [documentationDocsFilesObject?.studentLetter, 'studentLetter'],
    [documentationDocsFilesObject?.visaBrpFile, 'visaBrpFile'],
    [documentationDocsFilesObject?.p45Document, 'p45Document'],
    [documentationDocsFilesObject?.proofAddress, 'proofAddress'],
    [documentationDocsFilesObject?.proofNi, 'proofNi'],
    [documentationDocsFilesObject?.secondaryId, 'secondaryId'],
    [documentationDocsFilesObject?.dbsCheck, 'dbsCheck'],

    [licenceDocsFilesObject?.drivingLicense, 'drivingLicense'],
    [licenceDocsFilesObject?.scooterLicense, 'scooterLicense'],
    [licenceDocsFilesObject?.forkLiftTruck, 'forkLiftTruck'],
    [licenceDocsFilesObject?.hgvLicense, 'hgvLicense'],
    [licenceDocsFilesObject?.sia, 'sia'],
    [licenceDocsFilesObject?.welder, 'welder'],
    [licenceDocsFilesObject?.blankboxLicence, 'blankboxLicence'],
    [eLearningDocsFilesObject?.manualHanding, 'manualHanding'],
    [eLearningDocsFilesObject?.foodSafetyAllergens, 'foodSafetyAllergens'],
    [eLearningDocsFilesObject?.alcoholLicense, 'alcoholLicense'],
    [eLearningDocsFilesObject?.llopTraining, 'llopTraining'],
    [eLearningDocsFilesObject?.induction, 'induction'],
    [eLearningDocsFilesObject?.spectatorsSafety, 'spectatorsSafety'],
    [eLearningDocsFilesObject?.blankBox, 'blankBox'],
    [rightToWorkDocsFilesObject?.shareCodeFile, 'shareCodeFile'],
    [rightToWorkDocsFilesObject?.passportId1File, 'passportId1File'],
    [rightToWorkDocsFilesObject?.universityLetter, 'universityLetter'],
    [rightToWorkDocsFilesObject?.passportId2File, 'passportId2File'],
    [rightToWorkDocsFilesObject?.birthCertificate, 'birthCertificate'],
    [rightToWorkDocsFilesObject?.niProof, 'niProof'],
    [
      rightToWorkDocsFilesObject?.universityTermDateLetterRtw,
      'universityTermDateLetterRtw',
    ],
    [rightToWorkDocsFilesObject?.visaRtw, 'visaRtw'],
    ...blankBoxsObject,
  ]);

  // Files need for logic "approved candidate".

  return {
    contractFile: documentationDocsFilesObject?.contractFile,
    passportId1File: rightToWorkDocsFilesObject?.passportId1File,
    passportId2File: rightToWorkDocsFilesObject?.passportId2File,
    birthCertificate: rightToWorkDocsFilesObject?.birthCertificate,
    niProof: rightToWorkDocsFilesObject?.niProof,
    ...filesUploaded,
  };
};

export const createCandidateUpdated = async (candidateInformation) => {
  const {
    createdAt,
    employeeAttributes,
    evaluation,
    comments,
    medicalConditions,
    limitVisa,
    vacancyApplicantReferencesAttributes,
    industryExperiences,
    industryExperienceValueInput,
    visaRestrictionsLimitedHours,
    omniEmployeeAttributes,
    address,
    address2,
    city,
    zip,
    isFirstJobNoReference,

    // object files
    rightToWorkDocs,

    passportId1FileBlob,
    passportId1FileValue,
    shareCodeFileBlob,
    shareCodeFileValue,
    birth,
    employeeWpExpiry,
    eLearnings,
    licences,
  } = candidateInformation;

  const formattedVacancyApplicantReferencesAttributes =
    vacancyApplicantReferencesAttributes.map((item) => {
      const { startAt, endAt } = item;
      if (startAt && endAt) {
        return {
          ...item,
          startAt: moment(item.startAt).format('DD-MM-YYYY'),
          endAt: moment(item.endAt).format('DD-MM-YYYY'),
        };
      }

      return item;
    });

  const blankBoxs = [];
  const documentationDocs = [];

  candidateInformation.documentationDocs.forEach((doc) => {
    if (doc?.typeValue.includes('blankBox') ?? false) {
      doc.boxType = 'documentation';
      blankBoxs.push(doc);
    } else {
      documentationDocs.push(doc);
    }
  });

  let rightToWorkDocsMapped = [...rightToWorkDocs];

  if (passportId1FileValue) {
    rightToWorkDocsMapped = [
      ...rightToWorkDocs,
      {
        fileBlob: passportId1FileBlob,
        typeValue: 'passportId1File',
        fileValue: 'passportId1FileValue',
        typeLabel: 'Passport',
      },
    ];
  }

  if (shareCodeFileValue) {
    rightToWorkDocsMapped = [
      ...rightToWorkDocs,
      {
        fileBlob: shareCodeFileBlob,
        typeValue: 'shareCodeFile',
        fileValue: 'shareCodeFileValue',
        typeLabel: 'Passport',
      },
    ];
  }

  const listFileUpload = await candidateUploadFiles({
    eLearningDocs: [],
    documentationDocs,
    licenceDocs: [],
    rightToWorkDocs: rightToWorkDocsMapped,
    blankBoxs,
  });

  const blankBoxesAttributes = blankBoxs
    .map((item) => ({
      name: item.typeLabel,
      boxType: item.boxType,
      link: listFileUpload[
        `${item.boxType}-${item.typeValue.replaceAll(' ', '_')}`
      ],
      date: item.date ? moment(item.date)?.format() : null,
      id: item.id,
    }))
    .filter((item) => item.link);

  const industryExperienceAttributesMapped = industryExperiences.map(
    (value) => {
      if (value === INDUSTRY.OTHER) {
        return {
          industryExperienceId: value,
          otherValue: industryExperienceValueInput,
        };
      }

      return {
        industryExperienceId: value,
      };
    },
  );

  const evaluationNumber = evaluationTextToNumber(evaluation);

  const geogecode = await getAddressInfomation(
    `${address} ${address2} ${city} ${zip}, United Kingdom`,
  );

  const { geometry } = geogecode[0] ?? {};

  const requestDataCreateNewCandidate = {
    candidate: {
      ...candidateInformation,
      birth: moment(birth).format('DD/MM/YYYY'),
      universityLetter: listFileUpload?.universityLetter,
      proofAddress: listFileUpload?.proofAddress,
      proofNi: listFileUpload?.proofNi,
      niProof: listFileUpload?.niProof,
      secondaryId: listFileUpload?.secondaryId,
      dbsCheck: listFileUpload?.dbsCheck,
      shareCodeFile: listFileUpload?.shareCodeFile,

      drivingLicense: listFileUpload?.drivingLicense,
      scooterLicense: listFileUpload?.scooterLicense,
      forkLiftTruck: listFileUpload?.forkLiftTruck,
      hgvLicense: listFileUpload?.hgvLicense,

      manualHanding: listFileUpload?.manualHanding,
      foodSafetyAllergens: listFileUpload?.foodSafetyAllergens,
      alcoholLicense: listFileUpload?.alcoholLicense,
      llopTraining: listFileUpload?.llopTraining,
      induction: listFileUpload?.induction,
      cvUpload: listFileUpload?.cvUpload,

      birthCertificate: listFileUpload?.birthCertificate,

      spectators_safety: listFileUpload?.spectators_safety,
      sia: listFileUpload?.sia,
      welder: listFileUpload?.welder,

      visaRestrictionsLimitedHours,

      blankBox: listFileUpload?.blankBox,
      blankBoxDocumentation: listFileUpload?.blankBoxDocumentation,
      blankboxLicence: listFileUpload?.blankboxLicence,

      limitVisa,
      vacancyApplicantReferencesAttributes:
        formattedVacancyApplicantReferencesAttributes,

      omniEmployeeAttributes: {
        ...omniEmployeeAttributes,
        isFirstJobNoReference,
        visaBrpFile: listFileUpload?.visaBrpFile,
        p45Document: listFileUpload?.p45Document,
        employeeId1Expiry: omniEmployeeAttributes?.employeeId1Expiry?.format(),
        passportId1File: listFileUpload?.passportId1File,
        passportId2File: listFileUpload?.passportId2File,
        employeePaymentMethod: omniEmployeeAttributes?.employeePaymentMethod,
        contractFile: listFileUpload?.contractFile,
        visaRtw: listFileUpload?.visaRtw,
        universityTermDateLetterRtw:
          listFileUpload?.universityTermDateLetterRtw,
        employeeWpExpiry:
          typeof employeeWpExpiry === 'object'
            ? employeeWpExpiry?.format()
            : employeeWpExpiry,
      },
      createAt: createdAt,
      industryExperiencesVacancyApplicantsAttributes: [
        ...industryExperienceAttributesMapped,
      ],
      worktime: employeeAttributes?.worktime,
      evaluation: evaluationNumber,
      commentsAttributes: comments,
      medicalConditionsAttributes: medicalConditions,
      isCandidate: true,
      employeeAttributes: {
        profilepic: listFileUpload?.profilepic,
        employeeCertificatesAttributes: [
          ...eLearnings.map((eLearning) => {
            return {
              ...eLearning,
              expiresAt: moment(eLearning.expiresAt).format(),
              attachment: eLearning.attachment?.signedId,
            };
          }),
          ...licences.map((licence) => {
            return {
              ...licence,
              expiresAt: moment(licence.expiresAt).format(),
              attachment: licence.attachment?.signedId,
            };
          }),
        ],
      },
      locationAttributes: {
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
      },
      blankBoxesAttributes,
    },
  };

  // Delete base64s and files after uploaded
  delete requestDataCreateNewCandidate.candidate.rightToWorkDocs;
  delete requestDataCreateNewCandidate.candidate.documentationDocs;
  delete requestDataCreateNewCandidate.candidate.licenceDocs;
  delete requestDataCreateNewCandidate.candidate.passportId1FileBlob;
  delete requestDataCreateNewCandidate.candidate.passportId1FileValue;
  delete requestDataCreateNewCandidate.candidate.shareCodeFileBlob;
  delete requestDataCreateNewCandidate.candidate.shareCodeFileValue;
  delete requestDataCreateNewCandidate.candidate.universityLetterFileBlob;
  delete requestDataCreateNewCandidate.candidate.universityLetterFileValue;

  const endpoint = 'recruitments/candidates';

  const res = await request.post(endpoint, requestDataCreateNewCandidate);

  return res;
};

export const updateCandidateUpdated = async ({ candidateInformation, id }) => {
  const {
    createdAt,
    evaluation,
    comments,
    medicalConditions,
    limitVisa,
    vacancyApplicantReferencesAttributes,
    industryExperiences,
    industryExperienceValueInput,
    employeeWpExpiry,
    visaRestrictionsLimitedHours,
    omniEmployeeAttributes,
    address,
    address2,
    city,
    zip,
    isFirstJobNoReference,

    // object files
    rightToWorkDocs,

    passportId1FileBlob,
    passportId1FileValue,
    shareCodeFileBlob,
    shareCodeFileValue,
    universityLetterFileBlob,
    universityLetterFileValue,
    studentHolidaysAttributes,
    birth,
    eLearnings,
    licences,
  } = candidateInformation;

  const formattedVacancyApplicantReferencesAttributes =
    vacancyApplicantReferencesAttributes.map((item) => {
      const { startAt, endAt } = item;
      if (startAt && endAt) {
        return {
          ...item,
          startAt: moment(item.startAt).format('DD-MM-YYYY'),
          endAt: moment(item.endAt).format('DD-MM-YYYY'),
        };
      }

      return item;
    });

  const blankBoxs = [];
  const documentationDocs = [];
  const eLearningDocs = [];
  const licenceDocs = [];

  candidateInformation.documentationDocs.forEach((doc) => {
    if (doc?.typeValue.includes('blankBox') ?? false) {
      doc.boxType = 'documentation';
      blankBoxs.push(doc);
    } else {
      documentationDocs.push(doc);
    }
  });

  const rightToWorkDocsMapped = rightToWorkDocs ?? [];

  if (passportId1FileValue) {
    rightToWorkDocsMapped.push({
      fileBlob: passportId1FileBlob,
      typeValue: 'passportId1File',
      fileValue: 'passportId1FileValue',
      typeLabel: 'Passport',
    });
  }

  if (shareCodeFileValue) {
    rightToWorkDocsMapped.push({
      fileBlob: shareCodeFileBlob,
      typeValue: 'shareCodeFile',
      fileValue: 'shareCodeFileValue',
      typeLabel: 'Passport',
    });
  }

  if (universityLetterFileValue) {
    rightToWorkDocsMapped.push({
      fileBlob: universityLetterFileBlob,
      typeValue: 'universityLetter',
      fileValue: 'universityLetterValue',
      typeLabel: 'Student Holiday',
    });
  }

  const listFileUpload = await candidateUploadFiles({
    eLearningDocs,
    documentationDocs,
    licenceDocs,
    rightToWorkDocs: rightToWorkDocsMapped,
    blankBoxs,
  });

  const blankBoxesAttributes = blankBoxs
    .map((item) => ({
      name: item.typeLabel,
      boxType: item.boxType,
      link: listFileUpload[
        `${item.boxType}-${item.typeValue.replaceAll(' ', '_')}`
      ],
      date: item.date ? moment(item.date)?.format() : null,
      id: item.id,
    }))
    .filter((item) => item.link);

  // Handle Industry Experiences
  const industryExperienceAttributesMapped = Array.from(
    new Set(industryExperiences),
  ).map((value) => ({
    industryExperienceId: value,
    otherValue: industryExperienceValueInput,
  }));
  // End Handle Industry Experiences

  // Handle Student Holiday
  const listStudentHoliday = (studentHolidaysAttributes ?? []).map((item) => ({
    ...item,
    startDate: item?.startDate && formatDate(item?.startDate),
    endDate: item?.endDate && formatDate(item?.endDate),
  }));

  const evaluationNumber = evaluationTextToNumber(evaluation);

  const geogecode = await getAddressInfomation(
    `${address} ${address2} ${city} ${zip}, United Kingdom`,
  );

  const { geometry } = geogecode[0] ?? {};

  const updateCandidateInfo = {
    candidate: {
      ...candidateInformation,
      birth: moment(birth).format('DD/MM/YYYY'),
      universityLetter: listFileUpload?.universityLetter,
      studentLetter: listFileUpload?.studentLetter,
      proofAddress: listFileUpload?.proofAddress,
      proofNi: listFileUpload?.proofNi,
      niProof: listFileUpload?.niProof,
      secondaryId: listFileUpload?.secondaryId,
      dbsCheck: listFileUpload?.dbsCheck,
      shareCodeFile: listFileUpload?.shareCodeFile,

      drivingLicense: listFileUpload?.drivingLicense,
      scooterLicense: listFileUpload?.scooterLicense,
      forkLiftTruck: listFileUpload?.forkLiftTruck,
      hgvLicense: listFileUpload?.hgvLicense,
      blankboxLicence: listFileUpload?.blankboxLicence,

      manualHanding: listFileUpload?.manualHanding,
      foodSafetyAllergens: listFileUpload?.foodSafetyAllergens,
      alcoholLicense: listFileUpload?.alcoholLicense,
      llopTraining: listFileUpload?.llopTraining,
      induction: listFileUpload?.induction,
      blankBox: listFileUpload?.blankBox,

      birthCertificate: listFileUpload?.birthCertificate,

      spectatorsSafety: listFileUpload?.spectatorsSafety,
      sia: listFileUpload?.sia,
      welder: listFileUpload?.welder,
      cvUpload: listFileUpload?.cvUpload,
      blankBoxDocumentation: listFileUpload?.blankBoxDocumentation,

      visaRestrictionsLimitedHours,

      limitVisa,
      vacancyApplicantReferencesAttributes:
        formattedVacancyApplicantReferencesAttributes,
      omniEmployeeAttributes: {
        ...omniEmployeeAttributes,
        vacancyApplicantReferencesAttributes:
          formattedVacancyApplicantReferencesAttributes,
        isFirstJobNoReference,
        visaBrpFile: listFileUpload?.visaBrpFile,
        p45Document: listFileUpload?.p45Document,
        employeeId1Expiry:
          typeof omniEmployeeAttributes?.employeeId1Expiry === 'object'
            ? omniEmployeeAttributes?.employeeId1Expiry?.format()
            : omniEmployeeAttributes?.employeeId1Expiry,
        passportId1File: listFileUpload?.passportId1File,
        passportId2File: listFileUpload?.passportId2File,
        contractFile: listFileUpload?.contractFile,
        employeePaymentMethod: omniEmployeeAttributes?.employeePaymentMethod,
        visaRtw: listFileUpload?.visaRtw,
        universityTermDateLetterRtw:
          listFileUpload?.universityTermDateLetterRtw,

        employeeWpExpiry:
          typeof employeeWpExpiry === 'object'
            ? employeeWpExpiry?.format()
            : employeeWpExpiry,
      },

      studentHolidaysAttributes: listStudentHoliday,

      createAt: createdAt,

      industryExperiencesVacancyApplicantsAttributes:
        industryExperienceAttributesMapped,
      evaluation: evaluationNumber,

      commentsAttributes: comments,

      medicalConditionsAttributes: medicalConditions,
      isCandidate: true,
      worktime: candidateInformation?.worktime,
      employeeAttributes: {
        profilepic: listFileUpload?.profilepic,
        employeeCertificatesAttributes: [
          ...eLearnings.map((eLearning) => {
            return {
              ...eLearning,
              expiresAt: moment(eLearning.expiresAt).format(),
              attachment: eLearning.attachment?.signedId,
            };
          }),
          ...licences.map((licence) => {
            return {
              ...licence,
              expiresAt: moment(licence.expiresAt).format(),
              attachment: licence.attachment?.signedId,
            };
          }),
        ],
      },
      locationAttributes: {
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
      },

      blankBoxesAttributes,
    },
  };

  // Delete base64s and files after uploaded
  delete updateCandidateInfo.candidate.rightToWorkDocs;
  delete updateCandidateInfo.candidate.documentationDocs;
  delete updateCandidateInfo.candidate.eLearningDocs;
  delete updateCandidateInfo.candidate.licenceDocs;
  delete updateCandidateInfo.candidate.passportId1FileBlob;
  delete updateCandidateInfo.candidate.passportId1FileValue;
  delete updateCandidateInfo.candidate.shareCodeFileBlob;
  delete updateCandidateInfo.candidate.shareCodeFileValue;
  delete updateCandidateInfo.candidate.universityLetterFileBlob;
  delete updateCandidateInfo.candidate.universityLetterFileValue;

  const endpoint = `recruitments/candidates/${id}`;
  const res = await request.patch(endpoint, updateCandidateInfo);

  return res;
};
