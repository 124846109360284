import React, { useState } from 'react';

import { Divider, Form, message, Spin } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button, ModalConfirm } from '@/components';
import { useFetch } from '@/hooks';
import useUniforms from '@/hooks/dropdowns/useUniforms';
import { ProviderService } from '@/services';
import Formatter from '@/utils/Formatter';
import useModal from '@/utils/useModal';

import { INITIAL_RATE } from '../../constants';
import Profile from '../Profile';

const EditProfileWrapper = ({ providerId, setBreadCrumb }) => {
  const [activeKey, setActiveKey] = useState(['company-information']);
  const [form] = Form.useForm();
  const history = useHistory();
  const [displayEdit, showEdit, hiddenEdit] = useModal();
  const [isDisplayModal, showModal, hiddenModal] = useModal();
  const { data: listUniforms } = useUniforms();
  const [isLoading, setLoading] = useState(false);

  const {
    isLoading: isLoadingGetOneProvider,
    data: providerProfile,
    refetch: refetchGetOneProvider,
  } = useFetch(
    ['getOneProvider', providerId],
    () => ProviderService.getOne(providerId),
    {
      onSuccess: (prData) => {
        setBreadCrumb([
          { item: 'Provider', to: '/view-all-providers' },
          { item: prData.providerName, to: '#' },
        ]);

        form.setFieldsValue({
          ...prData,
          jobTemplatesAttributes: prData.jobTemplateConvert,
        });

        form.setFields([
          {
            name: ['chargeRatesAttributes', 0, 'payRate'],
            touched: false,
          },
        ]);
      },
      onError: (error) => message.error(error.message),
      select: (data) => {
        const {
          jobTemplatesAttributes,
          lastPostingJob,
          contractFile,
          marginFixed,
          marginPercent,
        } = data;
        const { listJeans, listShirts, listShoes } = listUniforms;
        const shirtValues = listShirts.map((item) => item.value);
        const jeanValues = listJeans.map((item) => item.value);
        const shoeValues = listShoes.map((item) => item.value);

        const jobTemplateConvert = jobTemplatesAttributes?.map((template) => {
          const { jobTemplatesUniformsAttributes } = template;

          const uniformValues = jobTemplatesUniformsAttributes?.map((item) =>
            parseInt(item.uniformId, 10),
          );

          const shirt = uniformValues.find((uniformId) =>
            shirtValues.includes(uniformId),
          );

          const jean = uniformValues.find((uniformId) =>
            jeanValues.includes(uniformId),
          );

          const shoe = uniformValues.find((uniformId) =>
            shoeValues.includes(uniformId),
          );

          const additionalUniform = uniformValues.filter(
            (item) => !(item === shirt || item === jean || item === shoe),
          );

          return {
            ...template,
            shirt,
            jean,
            shoe,
            additionalUniform,
          };
        });

        return {
          ...data,
          jobTemplateConvert,
          lastPostingJob:
            lastPostingJob && moment(lastPostingJob).format('DD/MM/YYYY hh:mm'),
          contractFileName: contractFile
            ? contractFile.split('contract/').lastItem
            : '',
          marginFixed: Formatter.fixedNumber({ value: marginFixed }),
          marginPercent: Formatter.fixedNumber({ value: marginPercent }),
        };
      },
    },
  );

  const {
    isLoading: isLoadingUpdate,
    isSuccess,
    mutate: mutateUpdateProvider,
    reset,
  } = useMutation(ProviderService.update, {
    onError: (error) => {
      message.error(error?.message ?? 'Update provider has been failed');
    },
    onSuccess: (data) => {
      refetchGetOneProvider();
      message.success('Provider has been updated successfully');
    },
  });

  const handleUpdateProvider = () => {
    mutateUpdateProvider({
      payload: form.getFieldsValue(),
      providerId,
      oldDataProvider: providerProfile,
    });
  };

  const handleFinishFailed = () =>
    setActiveKey([
      'company-information',
      'contact-information',
      'rates',
      'bank-and-official-information',
      'locations',
      'job-template',
      'comments-and-status-updates',
    ]);

  const handleCloseModal = () => {
    hiddenEdit();
    reset();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={showEdit}
        onFinishFailed={handleFinishFailed}
        className='provider-view-profile'
        initialValues={{
          dateOfRegistration: moment(),
          grossPayRate: 10,
          ...INITIAL_RATE,
        }}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <Spin
          spinning={isLoadingUpdate || isLoadingGetOneProvider || isLoading}
        >
          <Profile
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            refetchGetOneProvider={refetchGetOneProvider}
            isLoading={isLoadingUpdate || isLoadingGetOneProvider || isLoading}
            setLoading={setLoading}
          />
          <Divider />
          <div className='footer-button-container'>
            <Button onClick={showModal} className='black-button action-button'>
              Cancel
            </Button>
            <Button className='yellow-button action-button' htmlType='submit'>
              Save
            </Button>
          </div>
        </Spin>
      </Form>
      <ModalConfirm
        title='Save Updates?'
        titleSuccess='Save updates successfully'
        visible={displayEdit}
        isLoading={isLoadingUpdate}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleCloseModal : handleUpdateProvider}
        onClickNo={hiddenEdit}
      >
        Are you sure you want to save the updates made to the profile?
      </ModalConfirm>

      <ModalConfirm
        title="Don't Want to Save Updates?"
        visible={isDisplayModal}
        onClickYes={() => history.push('/view-all-providers')}
        onClickNo={hiddenModal}
      >
        Are you sure you want to leave without saving? You will lost any uploads
        and changes you have made.
      </ModalConfirm>
    </>
  );
};

EditProfileWrapper.propTypes = {
  providerId: PropTypes.number,
  setBreadCrumb: PropTypes.func,
};

export default EditProfileWrapper;
