import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';

import { RANGE_CHECK_IN, RANGE_CHECK_OUT } from '@/constants';
import './styles.scss';

const StatusNotes = () => (
  <div className='status-notes-container'>
    <div className='status-title'>
      <InfoCircleOutlined />
      <div>Check in shifts</div>
    </div>
    <p className='status-text'>
      Each shift will be available to select and check-in {RANGE_CHECK_IN} hours
      before the shift start time and up to {RANGE_CHECK_OUT} hours after the
      shift end time. A shift can be selected once it turns orange.
    </p>
    <div className='status-text'>Status</div>
    <div className='status-note'>
      <div className='status-circle status-circle-not-available' />
      <div>Not available for Check in</div>
    </div>
    <div className='status-note'>
      <div className='status-circle status-circle-available' />
      <div>Available for Check in</div>
    </div>
    <div className='status-note'>
      <div className='status-circle status-circle-in-progress' />
      <div>In Progress (Check in complete)</div>
    </div>
    <div className='status-note'>
      <div className='status-circle status-circle-completed' />
      <div>Completed Check in /out</div>
    </div>
    <div className='status-note'>
      <div className='status-circle status-circle-processed' />
      <div>Timesheets processed</div>
    </div>
  </div>
);

export default StatusNotes;
