/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem } from '@/components';
import { useTenantContext } from '../../../../TenantWrapper';

const Declarations2 = ({ nextStep, onValuesChange, form }) => {
  const tenant = useTenantContext();
  const { name, contact } = tenant;

  return (
    <div className='flex-one-column'>
      <p className='title'>Working Time Directive</p>
      <div>
        <p>
          UK working regulations mean that workers are restricted to a maximum
          of 48 hours working per week under the Working Time Directive (WTD).
          Due to the nature of {name} and the Flexibility we want for our
          workers, {name} will automatically Opt-Out all workers from the WTD,
          meaning there will be no restriction on your working hours (Within
          other safe working practices). If you would like to
        </p>
        <p>
          Opt-In to the directive and therefore restrict your hours, please tick
          Opt-In below.
        </p>
      </div>
      <Form
        form={form}
        onFinish={nextStep}
        onValuesChange={onValuesChange}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <FormItem name='worktime'>
          <Radio.Group className='recruitment-radio m-2'>
            <Radio value={0}>Opt-Out (No maximum restriction)</Radio>
            <Radio value={1}>Opt-In (Maximum 48 hours per week)</Radio>
          </Radio.Group>
        </FormItem>
        <p>
          Please Note: You have the right to update this at any time. You can
          change it in your App, or email{' '}
          <a href={`mailto:${contact.email}`}>{contact.email}</a>.
        </p>
        <Button htmlType='submit' className='shared-button confirm-button '>
          Next Step
        </Button>
      </Form>
    </div>
  );
};

Declarations2.propTypes = {
  nextStep: PropTypes.func,
  form: PropTypes.object,
  onValuesChange: PropTypes.func,
};

export default Declarations2;
