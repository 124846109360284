import React from 'react';

import { Form } from 'antd';
import moment from 'moment';

import { Button, Textarea, FormItem } from '@/components';
import { useAuthStore } from '@/store/authStore';

import History from '../History';
import './styles.scss';

const columnsTableComment = [
  {
    title: 'Comment',
    dataIndex: 'content',
    width: '60%',
  },
  {
    title: 'User',
    dataIndex: 'createdBy',
    width: '20%',
  },
  {
    title: 'Date/Time',
    dataIndex: 'createdAt',
    width: '20%',
  },
];

const Comments = () => {
  const form = Form.useFormInstance();
  const userName = useAuthStore((state) => state.userName);

  const listComment = Form.useWatch('commentsAttributes', form) ?? [];
  const comment = Form.useWatch('comment', form) ?? '';

  const handleAddComment = () => {
    // Recommend hard-code for comment length less than 5
    if (!comment || listComment.length >= 5) {
      return;
    }

    const data = [
      {
        content: comment,
        createdBy: userName,
        createdAt: moment().format('DD/MM/YYYY hh:mm'),
      },
      ...listComment,
    ];

    form.setFieldsValue({ commentsAttributes: data });
    form.setFieldsValue({ comment: '' });
  };

  return (
    <div className='comments'>
      <FormItem className='comments-input' label='Comments' name='comment'>
        <Textarea placeholder='Add comment here' />
      </FormItem>
      <Button className='yellow-button add-button' onClick={handleAddComment}>
        Add Comment
      </Button>

      <History
        dataSource={listComment}
        formItemPropertyName='commentsAttributes'
        columns={columnsTableComment}
      />
    </div>
  );
};
export default Comments;
