import request from '@/request';

const downloadCSV = async ({ searchTerm, department, accessLevel }) => {
  const filter = { searchTerm, department, accessLevel };

  const response = await request.get('omniusers/users/csv', {
    params: {
      filter,
    },
  });

  const responseData = response.data;

  return responseData;
};

export default downloadCSV;
