import React, { useState } from 'react';

import { Tabs, GeneralTemplate } from '@/components';
import { useQueryString } from '@/hooks';
import JobModalCreate from '@/pages/Jobs/JobModal/Create';

import CreateProfileWrapper from '../components/CreateProfileWrapper';
import ProviderJobs from '../components/Jobs';

import './styles.scss';

const CreateProvider = () => {
  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [buttonCreateNew, setButtonCreateNew] = useState({
    headerText: 'Create New Provider',
    buttonLink: '/create-new-provider',
  });

  const [queryString, setQueryString] = useQueryString();

  const breadCrumb = [
    { item: 'Provider', to: '/view-all-providers' },
    { item: 'Create New Provider', to: '/create-new-provider' },
  ];

  const tabPanes = [
    {
      tab: 'View Profile',
      key: 'viewProfile',
      child: <CreateProfileWrapper />,
    },
    {
      tab: 'View Jobs',
      key: 'viewJobs',
      child: <ProviderJobs />,
    },
  ];

  const handleOnProviderTabsChange = (tabKey) => {
    setQueryString({ tab: tabKey });

    if (tabPanes.at(0).key === tabKey) {
      setButtonCreateNew({
        headerText: 'Create New Provider',
        buttonLink: '/create-new-provider',
        buttonCallback: undefined,
      });
    }

    if (tabPanes.at(1).key === tabKey) {
      setButtonCreateNew({
        headerText: 'Create Job',
        buttonLink: '?tab=viewJobs',
        buttonCallback: () => setShowPopupCreate(true),
      });
    }
  };

  return (
    <GeneralTemplate
      data={breadCrumb}
      mainItem='Provider'
      headerText='Create new Provider'
      buttonLink={buttonCreateNew.buttonLink}
      buttonContent={buttonCreateNew.headerText}
      buttonCallback={buttonCreateNew.buttonCallback}
      subTemplateClassName='provider-container'
    >
      <div className='create-provider-container'>
        <div className='create-provider-info-input-container'>
          <Tabs
            onChange={handleOnProviderTabsChange}
            className='provider-tabs'
            tabPanes={tabPanes}
            activeKey={queryString?.tab ?? 'viewProfile'}
          />
        </div>
      </div>
      {showPopupCreate && (
        <JobModalCreate
          hidePopup={() => {
            setShowPopupCreate(false);
          }}
          showPopup={showPopupCreate}
        />
      )}
    </GeneralTemplate>
  );
};
export default CreateProvider;
