import React, { useMemo } from 'react';

import { Calendar, Col, Row, Select } from 'antd';
import PropTypes from 'prop-types';

import ArrowLeft from '@/assets/icons/ArrowLeft.png';
import ArrowRight from '@/assets/icons/ArrowRight.png';
import { Button } from '@/components';

import './styles.scss';

// only check day,month,year, no check the hour,minute,seconds
const isSameDayMonthYear = (momentA, momentB) => {
  if (!momentA || !momentB) {
    return false;
  }
  return (
    momentA.isSame(momentB, 'day') &&
    momentA.isSame(momentB, 'month') &&
    momentA.isSame(momentB, 'year')
  );
};

const HeaderRender = ({ value, onChange: headerOnChange }) => {
  const year = value.year();

  const options = useMemo(() => {
    const items = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      items.push(
        <Select.Option key={i} value={i} className='year-item'>
          {i}
        </Select.Option>,
      );
    }

    return items;
  }, [year]);

  return (
    <div style={{ padding: 8 }}>
      <Row>
        <Col>
          <Select
            size='small'
            value={year}
            suffixIcon=''
            bordered={false}
            dropdownMatchSelectWidth={false}
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              headerOnChange(now);
            }}
            className='year-select'
            dropdownClassName='header-render year-dropdown-select'
          >
            {options}
          </Select>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <Button
            type='text'
            onClick={() => {
              const now = value.clone().month(value.month() - 1);
              headerOnChange(now);
            }}
            className='button-arrow-left'
          >
            <img
              src={ArrowLeft}
              alt='left-arrow'
              className='arrow-date img-arrow-left'
            />
          </Button>
        </Col>

        <Col span={8} className='month-and-year'>
          <div className='month-text'>{value.format('MMMM')}</div>
        </Col>

        <Col span={8}>
          <Button
            type='text'
            onClick={() => {
              const now = value.clone().month(value.month() + 1);
              headerOnChange(now);
            }}
            className='button-arrow-right'
          >
            <img
              src={ArrowRight}
              alt='right-arrow'
              className='arrow-date img-arrow-right'
            />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

HeaderRender.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

const CustomCalendar = ({ arrValues = [], disabledDate, onChange }) => {
  // set value into state.
  const onSelect = (selected) => {
    const index = arrValues.findIndex((elm) =>
      isSameDayMonthYear(elm?.date, selected),
    );

    // add new if not found and is disabled time.
    if (index === -1 && !disabledDate(selected)) {
      return onChange([...arrValues, { count: 1, time: 4, date: selected }]);
    }

    // if found date, calc the time, count, and return new updated
    const result = arrValues
      .map((value, idx) => {
        if (idx === index) {
          const { date } = value;
          const count = value.count + 1; // 1 is step number.
          const time = value.time + 4; // 4 is half day work

          // if count > 2 or time > 8 means work over full day.
          // it's meant we return the {}
          if (count > 2 || time > 8) {
            return null;
          }

          return {
            count,
            time,
            date,
          };
        }

        return value;
      })
      .filter((elm) => !!elm); // remove all element is null.

    return onChange(result);
  };

  const getClass = (key) => {
    switch (key) {
      case 1:
        return 'half-circle';
      case 2:
        return 'full-circle';
      default:
        return '';
    }
  };

  const renderDateFullCell = (current) => {
    if (arrValues.some((e) => isSameDayMonthYear(current, e.date))) {
      const dateFound = arrValues.find((e) =>
        isSameDayMonthYear(current, e.date),
      );

      return (
        <div className='selected-date'>
          <div className={`box-color ${getClass(dateFound?.count)}`}>
            <span className='date-content'>{current.format('DD')}</span>
          </div>
        </div>
      );
    }

    return (
      <div className='selected-date'>
        <div className='box-color'>
          <span className='date-content'>{current.format('DD')}</span>
        </div>
      </div>
    );
  };

  return (
    <div className='calendar'>
      <Calendar
        // eslint-disable-next-line react/no-unstable-nested-components
        headerRender={({
          value,
          type,
          onChange: headerOnChange,
          onTypeChange,
        }) => (
          <HeaderRender
            value={value}
            type={type}
            onChange={headerOnChange}
            onTypeChange={onTypeChange}
          />
        )}
        disabledDate={disabledDate}
        fullscreen={false}
        dateFullCellRender={renderDateFullCell}
        onSelect={onSelect}
      />
    </div>
  );
};

CustomCalendar.propTypes = {
  arrValues: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabledDate: PropTypes.func,
};

CustomCalendar.defaultProps = {
  onChange: () => {},
  disabledDate: () => {},
};

export default CustomCalendar;
