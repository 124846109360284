import _ from 'lodash';
import moment from 'moment';

const calTotalHrsSummary = ({ dateRange, timesheetDetails }) => {
  const totalRangeDay = dateRange.map((date) => {
    const dayOfWeek = moment(date).format('ddd');
    const VALUE_PATH = [
      'timecards',
      dayOfWeek,
      'attributes',
      'schedule',
      'value',
    ];
    const NO_SHOW_PATH = ['timecards', dayOfWeek, 'attributes', 'noShow'];
    return _.sumBy(timesheetDetails, (timesheetDetail) => {
      const totalHrsDay = _.get(timesheetDetail, VALUE_PATH, 0);
      return _.get(timesheetDetail, NO_SHOW_PATH) ? 0 : totalHrsDay;
    });
  });
  const totalHours = _.sum(totalRangeDay);
  const totalPay = _.sumBy(timesheetDetails, 'totalPay').toFixed(2);
  const totalCharge = _.sumBy(timesheetDetails, 'totalCharge').toFixed(2);
  const countUniqEmployees = _.size(
    _.uniqBy(timesheetDetails, (item) => item.omniEmployee.employeeId),
  );
  return {
    totalRangeDay,
    totalHours,
    totalPay,
    totalCharge,
    countUniqEmployees,
  };
};

export { calTotalHrsSummary };
