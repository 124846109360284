import React from 'react';

import { Switch } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FormItem } from '@/components';

import './styles.scss';

/**
 * @typedef {Object} SwitchModeProps
 * @property {string} leftTitle
 * @property {string} rightTitle
 * @property {boolean} isChecked
 * @property {(checked: boolean, event: MouseEvent) => void} onChange
 * @property {string | number | (string | number)[]} name
 * @property {string} className
 */

/**
 * @param {SwitchModeProps} CustomSwitchModeProps
 */
const SwitchMode = ({
  name,
  leftTitle,
  rightTitle,
  onChange,
  isChecked,
  className,
}) => (
  <div className={classNames('switch-mode-container', className)}>
    <div className='switch-mode'>
      <div className='switch-mode-title'>{leftTitle}</div>
      <FormItem name={name} valuePropName='checked'>
        <Switch onChange={onChange} defaultChecked={isChecked} />
      </FormItem>
      <div className='switch-mode-title'>{rightTitle}</div>
    </div>
  </div>
);

export default SwitchMode;

SwitchMode.propTypes = {
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
};

SwitchMode.defaultProps = {
  name: '',
  leftTitle: '',
  rightTitle: '',
  isChecked: false,
  onChange: () => {},
  className: '',
};
