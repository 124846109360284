import React, { useMemo } from 'react';

import { Select as AntdSelect } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from '@/assets/icons/arrowDown.svg';
import { ReactComponent as ArrowDownBlack } from '@/assets/icons/arrowDownBlack.svg';
import './styles.scss';

/**
 * @typedef {{
 * required: boolean;
 * noSelectText?: string;
 * hideSuffixIcon: boolean;
 * showSearch: boolean;
 * disabled: boolean;
 * name?: string;
 * options: Array
 * }} AdditionalProps
 */

/**
 * @param {import('antd').SelectProps & AdditionalProps } props
 * @return {JSX.Element}
 */
const Select = ({
  className,
  value,
  dropdownClassName,
  showSearch,
  disabled,
  hideSuffixIcon,
  options: optionsProp,
  mode,
  required,
  noSelectText = 'No Select',
  ...props
}) => {
  const [isFocus, setIsFocus] = React.useState(false);

  const renderSuffixIcon = () => {
    if (hideSuffixIcon) {
      return null;
    }
    return isFocus ? <ArrowDownBlack /> : <ArrowDown />;
  };

  const options = useMemo(() => {
    if (
      !mode &&
      !required &&
      !optionsProp?.find((e) => e.label === noSelectText)
    ) {
      return [
        {
          label: noSelectText,
          value: null,
        },
        ...optionsProp,
      ];
    }

    return optionsProp;
  }, [mode, noSelectText, optionsProp, required]);

  return (
    <AntdSelect
      {...props}
      {...(mode && { mode })}
      options={options}
      showSearch={showSearch}
      filterOption
      value={value}
      optionFilterProp='label'
      suffixIcon={renderSuffixIcon()}
      dropdownMatchSelectWidth={false}
      defaultValue={props.defaultValue}
      className={classNames('primary-select input-height', className)}
      dropdownClassName={classNames('select-dropdown', dropdownClassName)}
      onDropdownVisibleChange={(isOpen) => setIsFocus(isOpen)}
      disabled={disabled}
    />
  );
};

Select.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  hideSuffixIcon: PropTypes.bool,
  options: PropTypes.array,
  mode: PropTypes.string,
  required: PropTypes.bool,
  noSelectText: PropTypes.string,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  className: '',
  dropdownClassName: '',
  showSearch: true,
  disabled: false,
  hideSuffixIcon: false,
  options: [],
  required: false,
};

export default Select;
