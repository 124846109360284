import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const getLocationsByProviderId = async (providerId) => {
  const res = await request.get(
    `providers/employers/${providerId}/employer_locations`,
  );
  const { data } = normalizeIndexResponse(res);
  const result = data.map(({ id, name }) => ({
    value: Number(id),
    label: name,
  }));

  return result;
};

export default getLocationsByProviderId;
