/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Form, Select as AntSelect, Tag } from 'antd';
import classNames from 'classnames';
import { filter, head } from 'lodash';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from '@/assets/icons/arrowDown.svg';
import { ReactComponent as ArrowDownBlack } from '@/assets/icons/arrowDownBlack.svg';
import './styles.scss';

const { Option } = AntSelect;

const OptionAll = {
  label: 'Select All',
  value: 'all',
};

/**
 *
 * @typedef {Object} CustomTagProps
 * @property {React.ReactNode} label
 * @property {string | number} value
 * @property {boolean} disabled
 * @property {(event?: React.MouseEvent<HTMLElement, MouseEvent>) => void} onClose
 * @property {boolean} closable
 */

/**
 * @function createOptions Create options
 * @param {Object[]} options [{value: 1, label: 'option 1'}]
 * @returns
 */
const createOptions = (options = []) =>
  options.map((option = {}) => (
    <Option
      key={option.value}
      value={option.value}
      label={option.label}
      className='select-all'
    >
      {option.label}
    </Option>
  ));

/**
 * @typedef {{
 * name: string;
 * label: string;
 * noSelectAll: boolean;
 * options: array;
 * showSearch: boolean;
 * hideTag?: boolean;
 * enableCustomTag?: boolean
 * }} AdditionalProps
 */

/**
 * @param {import('antd').SelectProps & AdditionalProps } props
 * @return {JSX.Element}
 */
const MultipleSelect = ({
  name,
  label,
  options,
  className,
  dropdownClassName,
  showSearch,
  noSelectAll,
  hideTag,
  enableCustomTag,
  ...props
}) => {
  const form = Form.useFormInstance();
  const [isFocus, setIsFocus] = React.useState(false);

  let values = [];
  let listOption = null;
  const optionsLength = options.length;
  const isOptionLengthGreaterThanZero = optionsLength > 0;

  if (isOptionLengthGreaterThanZero) {
    listOption = createOptions(options);
    values = options.map(({ value }) => value);
  }

  if (listOption !== null && !noSelectAll)
    listOption.unshift(
      <Option
        key={OptionAll.value}
        value={OptionAll.value}
        label={OptionAll.label}
        className='select-all'
      >
        {OptionAll.label}
      </Option>,
    );

  const setEmpty = () => form.setFields([{ name, value: [] }]);

  const setAllValues = () =>
    form.setFields([{ name, value: [...values, OptionAll.value] }]);

  const handleDeselectItem = (optionValue) =>
    optionValue === OptionAll.value && setEmpty();

  const handleSelectItem = (optionValue) =>
    optionValue === OptionAll.value && setAllValues();

  const handleSelectChange = (optionValues = []) => {
    const valuesWithoutOptionAll = optionValues.filter(
      (optionValue) => optionValue !== OptionAll.value,
    );

    if (valuesWithoutOptionAll.length === optionsLength) return setAllValues();

    return form.setFields([{ name, value: [...valuesWithoutOptionAll] }]);
  };

  const getDataWithoutAll = () =>
    filter(form.getFieldValue(name), (item) => item !== 'all');

  const handleRemoveTag = (optionValue) => {
    const formData = getDataWithoutAll();
    const firstValue = head(formData);
    if (optionValue === firstValue) {
      const newData = formData.filter((item) => item !== optionValue);
      form.setFields([{ name, value: newData }]);
    } else {
      form.setFields([{ name, value: [firstValue] }]);
    }
  };

  const tagRender = ({ label: labelTag, value, closable }) => (
    <Tag
      className='tag-custom'
      closable={closable}
      onClose={() => handleRemoveTag(value)}
    >
      {labelTag || value}
    </Tag>
  );

  /**
   * @param {CustomTagProps} params - Parameters passed to the tag rendering function.
   */
  const tagRenderCustom = (params) => {
    if (hideTag) return null;
    const { value: valueCustom } = params;
    const formData = getDataWithoutAll();
    const tagCount = formData.length;

    if (formData.indexOf(valueCustom) === 0) {
      return tagRender(params);
    }

    if (formData.indexOf(valueCustom) === tagCount - 1 && tagCount > 1) {
      return (
        <Tag
          className='tag-custom'
          closable={params.closable}
          onClose={() => handleRemoveTag(valueCustom)}
        >
          {`+${tagCount - 1}`} &hellip;
        </Tag>
      );
    }

    return null;
  };

  return (
    <AntSelect
      {...props}
      showArrow
      showSearch={showSearch}
      filterOption
      mode='multiple'
      {...(!hideTag && { maxLength: 15 })}
      {...(enableCustomTag && { tagRender: tagRenderCustom })}
      optionFilterProp='label'
      defaultActiveFirstOption
      suffixIcon={isFocus ? <ArrowDownBlack /> : <ArrowDown />}
      dropdownMatchSelectWidth={false}
      className={classNames('primary-select input-height', className)}
      dropdownClassName={classNames('select-dropdown', dropdownClassName)}
      onSelect={handleSelectItem}
      onDeselect={handleDeselectItem}
      onChange={handleSelectChange}
      onDropdownVisibleChange={(isOpen) => setIsFocus(isOpen)}
    >
      {listOption}
    </AntSelect>
  );
};

MultipleSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  showSearch: PropTypes.bool,
  noSelectAll: PropTypes.bool,
  hideTag: PropTypes.bool,
  enableCustomTag: PropTypes.bool,
};

MultipleSelect.defaultProps = {
  name: null,
  label: null,
  options: [],
  className: null,
  dropdownClassName: null,
  showSearch: true,
  noSelectAll: false,
  hideTag: false,
  enableCustomTag: true,
};

export default MultipleSelect;
