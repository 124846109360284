import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Select, Input } from '@/components';
import { listWeek } from '@/constants';
import {
  useProviderLocations,
  useProviders,
  useRoleItems,
} from '@/hooks/dropdowns';

import { setFieldsFromKpiAndShift } from './helper';

const InputForm = ({ handleClearFilter, handleSearchRota, date }) => {
  const form = Form.useFormInstance();

  const listProviders = useProviders();
  const { data: positions } = useRoleItems();
  const providerId = Form.useWatch('employerId');
  const { data: locations } = useProviderLocations(providerId);

  return (
    <>
      <div className='input-items'>
        <FormItem name='week' className='input-item' label='Week Number'>
          <Select
            options={listWeek}
            className='input-height'
            placeholder='Week Number'
            name='week'
          />
        </FormItem>
        <FormItem name='employerId' className='input-item' label='Site'>
          <Select
            options={listProviders}
            className='input-height'
            placeholder='Provider'
            name='employerId'
          />
        </FormItem>
        <FormItem
          name='employerLocationId'
          className='input-item'
          label='Location'
        >
          <Select
            options={locations}
            className='input-height'
            placeholder='Location'
            name='employerLocationId'
          />
        </FormItem>
        <FormItem name='skillId' className='input-item' label='Positions'>
          <Select
            className='input-height'
            placeholder='Positions'
            options={positions}
            name='skillId'
          />
        </FormItem>
        <div className='buttons input-item'>
          <Button
            className='yellow-button input-height style-btn'
            type='primary'
            htmlType='submit'
            onClick={handleSearchRota}
          >
            Search
          </Button>
          <Button
            onClick={handleClearFilter}
            className='filter-button input-height style-btn'
          >
            Clear Filters
          </Button>
        </div>
      </div>
      <div
        className='input-items'
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <FormItem
          className='input-item'
          label={'KPI \n (no of Units Per Person Per Hour)'}
          name='kpi'
        >
          <Input
            className='input-height'
            placeholder='KPI'
            onChange={(e) => {
              const initKpi = isNaN(parseInt(e.target.value, 10))
                ? ''
                : parseInt(e.target.value, 10);
              return setFieldsFromKpiAndShift({
                date,
                form,
                initKpi,
              });
            }}
          />
        </FormItem>
        <FormItem
          className='input-item'
          name='shiftLength'
          label='Productive Shift Length'
          // normalize={(value) => (value ? parseInt(value, 10) : '')}
        >
          <Input
            className='input-height'
            placeholder='Productive Shift Length'
            onChange={(e) => {
              const initShiftLength = isNaN(parseInt(e.target.value, 10))
                ? ''
                : parseInt(e.target.value, 10);

              return setFieldsFromKpiAndShift({
                date,
                form,
                initShiftLength,
              });
            }}
          />
        </FormItem>
      </div>
    </>
  );
};

InputForm.propTypes = {
  handleClearFilter: PropTypes.func,
  handleSearchRota: PropTypes.func,
  date: PropTypes.string,
};

InputForm.defaultProps = {
  handleClearFilter: () => {},
  handleSearchRota: () => {},
};

export default InputForm;
