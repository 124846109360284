import React from 'react';

import { Form } from 'antd';

import {
  CheckboxGroup,
  FormItem,
  Textarea,
  Select,
  Button,
  Modal,
} from '@/components';
import { useSkillTypes, useStatus } from '@/hooks/dropdowns';
import useShowSpecificRoles from '@/hooks/useShowSpecificRoles';

import { statuses } from './constants';

const ApplicantRole = () => {
  const { listStatuses } = useStatus();
  const listRoles = useSkillTypes();

  const formInstance = Form.useFormInstance();
  const status = formInstance.getFieldValue('status');
  const preferredRoles = Form.useWatch('preferredRoles', formInstance) ?? [];

  const [modalVisible, handleModalClose, handlePreferredRolesChange] =
    useShowSpecificRoles({ roles: preferredRoles });

  return (
    <div className='applicant-roles'>
      <div className='row-box'>
        <div className='gray-box'>
          <div className='preferred-roles'>
            <FormItem label='Previous Experience' name='preferredRoles'>
              <CheckboxGroup
                onChange={handlePreferredRolesChange}
                options={listRoles}
              />
            </FormItem>
          </div>
        </div>
        <div className='white-box'>
          <div className='status-roles'>
            <FormItem label='Status' name='status'>
              <Select
                options={listStatuses}
                placeholder='Status'
                disabled={
                  status &&
                  ![statuses.notEnough, statuses.contacted].includes(status)
                }
              />
            </FormItem>
          </div>
          <FormItem label='Internal note:' name='internalNote'>
            <Textarea rows={10} placeholder='Note' />
          </FormItem>
        </div>
      </div>
      <Modal
        footer={
          <Button
            className='yellow-button ok-button'
            onClick={handleModalClose}
          >
            Ok
          </Button>
        }
        onClickNo={handleModalClose}
        visible={modalVisible}
        title='Training Required'
      >
        This is a role that requires the worker to have previous training and
        experience. Do not add this role unless you have seen evidence of this
        training/experience.
      </Modal>
    </div>
  );
};

export default ApplicantRole;
