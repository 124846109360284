import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

/**
 * @function getSkillTypes role items are
 * @returns
 */
export const getSkillTypes = async () => {
  const res = await request.get('/options/skill_types');
  const data = normalizeIndexResponse(res);
  const result = data?.data.map(({ id, name }) => ({ value: id, label: name }));

  return result || [];
};
