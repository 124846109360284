import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { Button } from '@/components';
import ListView from '@/components/ListView';
import Formatter from '@/utils/Formatter';
import { generateHeaderTable } from '@/utils/generateHeaderTable';
import './styles.scss';

const JobTimecard = ({ timecards, loading }) => {
  const columns = [
    {
      title: 'Job Type',
    },
    {
      title: 'Employee',
    },
    {
      title: 'Payroll',
    },
    {
      title: 'Check In',
      render: (data, record) => (
        <div className='check-in'>
          <p>{moment(data).format('ddd mmm')}</p>
          <p>{moment(data).format('hh:mm')}</p>
          <Button className='shared-button check-in-out'>Checked In</Button>
          <p>
            Approved by <span className='admin'>{record.approvedBy}</span>
          </p>
        </div>
      ),
    },
    {
      title: 'Check Out',
      render: (data, record) => (
        <div className='check-in'>
          <p>{moment(data).format('ddd mmm')}</p>
          <p>{moment(data).format('hh:mm')}</p>
          <Button className='shared-button check-in-out'>Auto</Button>
          <p>
            Approved by <span className='admin'>{record.approvedBy}</span>
          </p>
        </div>
      ),
    },
    {
      title: 'Duration',
    },
    {
      title: 'Timecard',
      render: (data) => (
        <div className='check-in'>
          <p>{data.date}</p>
          <p>{data.startTime}</p>
          <p>{data.endTime}</p>
          <p>Break {data.break}</p>
          <p>{data.hours} hours</p>
        </div>
      ),
    },
    {
      title: 'Total Pay',
      render: (data) => Formatter.currencyGBP(data),
    },
    {
      title: 'Total Charge',
      render: (data) => Formatter.currencyGBP(data),
    },
  ];

  const headerTable = generateHeaderTable(columns);

  return (
    <ListView
      className='timecard-table'
      infoDetail={timecards}
      headerColumns={headerTable}
      loading={loading}
      locale={{
        emptyText: 'No Timecards match your search criteria',
      }}
    />
  );
};

JobTimecard.propTypes = {
  timecards: PropTypes.array,
  loading: PropTypes.bool,
};

JobTimecard.defaultProps = {
  timecards: [],
  loading: false,
};
export default JobTimecard;
