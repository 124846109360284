/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { TimePicker as AntdTimePicker } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const RangePicker = ({ className, popupClassName, format, ...props }) => (
  <AntdTimePicker.RangePicker
    {...props}
    format={format}
    popupClassName={classNames('time-picker-popup')}
    className={classNames('primary-time-picker', className)}
    clearIcon={null}
    use12Hours={false}
  />
);

RangePicker.propTypes = {
  className: PropTypes.string,
  popupClassName: PropTypes.string,
  format: PropTypes.string,
};

RangePicker.defaultProps = {
  className: '',
  popupClassName: '',
  format: 'HH:mm a',
};

export default RangePicker;
