/* eslint-disable max-len */
import moment from 'moment';

import request from '@/request';
import { normalizeShowResponse } from '@/utils/normalizeResponse';

const getOneRotaShiftPlan = async (/** @type {string|number} */ id) => {
  const endpoint = `/rotas/rota_shift_plans/${id}`;

  const res = await request.get(endpoint);
  const { data } = normalizeShowResponse(res);

  return {
    ...data,
    startTime: moment(data?.startTime),
    endTime: moment(data?.endTime),
    employeeAttributes: data?.employees?.map((employee) => ({
      ...employee,
      contactTypeName: data?.contactTypeName,
      startTime: moment(data?.startTime).format('HH:mm'),
      endTime: moment(data?.endTime).format('HH:mm'),
    })),
    rotaShiftPlansUniformsAttributes: data?.uniformAttributes?.map(
      (uniform) => ({
        uniformId: uniform.id,
      }),
    ),
  };
};

export default getOneRotaShiftPlan;
