import React, { memo } from 'react';

import { Modal, Table } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const columns = [
  {
    title: 'Sentence',
    dataIndex: 'sentence',
    width: '40%',
  },
  {
    title: 'End of rehabilitation period for adult offenders',
    dataIndex: 'offendersOver18',
    width: '30%',
  },
  {
    title:
      'End of rehabilitation period for offenders under 18 at date of conviction',
    dataIndex: 'convictionUnder18',
    width: '30%',
  },
];

const dataSource = [
  {
    sentence:
      'A custodial sentence of more than 30 months and up to, or consisting of, 30 months',
    offendersOver18:
      'The end of the period of 7 years beginning with the day on which the senrence (including any licence period) is completed',
    convictionUnder18:
      'The end of the period of 42 months beginning with the day on which the sentence (including any licence period) is completed',
  },
  {
    sentence:
      'A custodial sentence of more than 6 months and up to, or consisting of, 30 months',
    offendersOver18:
      'The end of the period of 48 months beginning with the day on which the senrence (including any licence period) is completed',
    convictionUnder18:
      'The end of the period of 24 months beginning with the day on which the sentence (including any licence period) is completed',
  },
  {
    sentence: 'A custodial sentence of 6 months or less',
    offendersOver18:
      'The end of the period of 24 months beginning with the day on which the senrence (including any licence period) is completed',
    convictionUnder18:
      'The end of the period of 18 months beginning with the day on which the sentence (including any licence period) is completed',
  },
  {
    sentence: 'Removeal from Her Majesty&apos;s service',
    offendersOver18:
      'The end of the period of 12 months beginning with the day of the conviction in respect of which the sentence is imposed',
    convictionUnder18:
      'The end of the period of 6 months beginning with the day of the conviction in respect of which the sentence is imposed',
  },
  {
    sentence: 'A sentence of service detention',
    offendersOver18:
      'The end of the period of 12 months beginning with the day on which the sentence is completed',
    convictionUnder18:
      'The end of the period of 6 months beginning with the day on which the sentence is completed',
  },
];

const ModalConviction = ({ visible, onCancel, ...props }) => (
  <Modal
    className='info-modal-container'
    visible={visible}
    onCancel={onCancel}
    footer={false}
  >
    <Table
      bordered={false}
      className='info-table'
      pagination={false}
      columns={columns}
      dataSource={dataSource}
    />
  </Modal>
);

ModalConviction.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

ModalConviction.defaultProps = {
  visible: false,
  onCancel: () => {},
};

export default memo(ModalConviction);
