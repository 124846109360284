/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Popconfirm, Tooltip } from 'antd';
import { isNil } from 'lodash';
import moment from 'moment';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { FormItem, Input, InputNumber, TimePicker } from '@/components';
import { rulesTextInput } from '@/utils/rulesInput';

import { ERROR_PAY_RATE, MIN_PAY_RATE, daysOfWeek } from '../../constants';
import CircleCheckBox from '../TemplatesCheckBox';

const FORMAT_TYPE = {
  time: 'time',
  currencyUnit: 'currencyUnit',
  default: 'default',
};

const templateTableColumns = ({
  dataTable,
  setDataTable,
  form,
  hourTemplatesWatching = {},
  currency = '',
}) => {
  const handleDeleteRow = (rowId) => {
    const getObject = form.getFieldsValue();

    getObject.hourTemplates[rowId] = undefined;
    const deletedRows = dataTable.filter((row) => row.id !== rowId);
    form.setFieldsValue(getObject);
    setDataTable(deletedRows);
  };

  const renderDays = () =>
    daysOfWeek.map(({ label, dataIndex }) => ({
      title: label,
      dataIndex,
      width: '5%',
      render: (dayCheck, record) => (
        <FormItem
          initialValue={dayCheck ?? false}
          name={['hourTemplates', `${record.id}`, dataIndex]}
          valuePropName='checked'
          className='pay-form-item'
        >
          <CircleCheckBox />
        </FormItem>
      ),
    }));

  const formatNumber = (numberValue, { userTyping }) => {
    if (!numberValue) {
      return '';
    }
    if (userTyping) {
      return `${currency}${numberValue}`;
    }
    return `${currency}${Number(numberValue).toFixed(2)}`;
  };

  const handleKeyDown = (e) => {
    const keys = ['e', 'E', '+', '-'];
    const inputKey = keys.includes(e.key);
    if (inputKey) {
      e.preventDefault();
    }
  };

  const validateField = ({ record, condition, field, errors }) => {
    const name = ['hourTemplates', `${record.id}`, field];
    if (condition) {
      form.setFields([
        {
          name,
          errors: [''],
        },
      ]);
    } else {
      form.setFields([
        {
          name,
          errors,
        },
      ]);
    }
  };

  const formatTitle = ({ type, value = null }) => {
    const formatCell = {
      time: moment(value).format('HH:mm'),
      currencyUnit: `${currency}${Number(value).toFixed(2)}`,
      default: value,
    };

    return formatCell[type];
  };

  const renderTitle = ({ type = FORMAT_TYPE.default, value }) =>
    !isNil(value) && formatTitle({ type, value });

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'name',
      width: '15%',
      render: (name, record) => (
        <Tooltip
          placement='topLeft'
          title={renderTitle({
            value: hourTemplatesWatching?.[record.id]?.name,
          })}
        >
          <FormItem
            initialValue={record?.isAdded ?? false}
            name={['hourTemplates', `${record.id}`, 'isAdded']}
            hidden
          />
          <FormItem
            initialValue={name}
            className='pay-form-item'
            name={['hourTemplates', `${record.id}`, 'name']}
            rules={rulesTextInput('Name')}
          >
            <Input
              className='pay-form-item-input'
              placeholder='Name template'
            />
          </FormItem>
        </Tooltip>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'startAt',
      width: '11%',
      render: (startAt, record) => (
        <Tooltip
          placement='topLeft'
          title={renderTitle({
            type: FORMAT_TYPE.time,
            value: hourTemplatesWatching?.[record.id]?.startAt,
          })}
        >
          <FormItem
            {...(startAt && { initialValue: moment(startAt) })}
            className='pay-form-item'
            name={['hourTemplates', `${record.id}`, 'startAt']}
            rules={[
              {
                required: true,
                message: 'Please choose Start Time',
              },
            ]}
          >
            <TimePicker
              className='pay-form-item-input'
              format='HH:mm'
              placeholder='00:00'
            />
          </FormItem>
        </Tooltip>
      ),
    },
    {
      title: 'End Time',
      dataIndex: 'endAt',
      width: '11%',
      render: (endAt, record) => (
        <Tooltip
          placement='topLeft'
          title={renderTitle({
            type: FORMAT_TYPE.time,
            value: hourTemplatesWatching?.[record.id]?.endAt,
          })}
        >
          <FormItem
            {...(endAt && { initialValue: moment(endAt) })}
            className='pay-form-item'
            name={['hourTemplates', `${record.id}`, 'endAt']}
            rules={[
              {
                required: true,
                message: 'Please choose End Time',
              },
            ]}
          >
            <TimePicker
              className='pay-form-item-input'
              format='HH:mm'
              placeholder='00:00'
            />
          </FormItem>
        </Tooltip>
      ),
    },
    ...renderDays(),
    {
      title: 'Pay',
      dataIndex: 'payRate',
      width: '13%',
      render: (payRate, record) => (
        <Tooltip
          placement='topLeft'
          title={renderTitle({
            type: FORMAT_TYPE.currencyUnit,
            value: hourTemplatesWatching?.[record.id]?.payRate,
          })}
        >
          <FormItem
            initialValue={payRate}
            className='pay-form-item'
            name={['hourTemplates', `${record.id}`, 'payRate']}
            rules={[
              {
                required: true,
                message: 'Please Enter Pay Rate',
              },
              {
                validator: async (_, value) => {
                  if (Number(value) >= MIN_PAY_RATE) {
                    const hourTemplates = form.getFieldValue('hourTemplates');
                    if (!isNil(hourTemplates[record.id].chargeRate)) {
                      const condition =
                        hourTemplates[record.id].chargeRate > value;
                      const errors = ['Value must be greater than Pay rate'];

                      validateField({
                        record,
                        condition,
                        field: 'chargeRate',
                        errors,
                      });
                    }

                    if (!isNil(hourTemplates[record.id].otPayRate)) {
                      const condition =
                        Number(value) < hourTemplates[record.id].otPayRate;
                      const errors = ['Pay (OT) must greater than Pay Rate'];

                      validateField({
                        record,
                        condition,
                        field: 'otPayRate',
                        errors,
                      });
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(ERROR_PAY_RATE));
                },
              },
            ]}
          >
            <InputNumber
              className='pay-form-item-input'
              placeholder={`${currency}00.00`}
              formatter={formatNumber}
              onKeyDown={handleKeyDown}
            />
          </FormItem>
        </Tooltip>
      ),
    },
    {
      title: 'Charge',
      dataIndex: 'chargeRate',
      width: '13%',
      render: (chargeRate, record) => (
        <Tooltip
          placement='topLeft'
          title={renderTitle({
            type: FORMAT_TYPE.currencyUnit,
            value: hourTemplatesWatching?.[record.id]?.chargeRate,
          })}
        >
          <FormItem
            initialValue={chargeRate}
            className='pay-form-item'
            name={['hourTemplates', `${record.id}`, 'chargeRate']}
            rules={[
              {
                required: true,
                message: 'Please Enter Charge Rate',
              },
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  const payRate = getFieldValue([
                    'hourTemplates',
                    `${record.id}`,
                    'payRate',
                  ]);

                  if (value > payRate) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Value must be greater than Pay rate'),
                  );
                },
              }),
            ]}
            dependencies={['hourTemplates', `${record.id}`, 'payRate']}
          >
            <InputNumber
              className='pay-form-item-input'
              placeholder={`${currency}00.00`}
              formatter={formatNumber}
              onKeyDown={handleKeyDown}
            />
          </FormItem>
        </Tooltip>
      ),
    },
    {
      title: 'Pay (OT)',
      dataIndex: 'otPayRate',
      width: '13%',
      render: (otPayRate, record) => (
        <Tooltip
          placement='topLeft'
          title={renderTitle({
            type: FORMAT_TYPE.currencyUnit,
            value: hourTemplatesWatching?.[record.id]?.otPayRate,
          })}
        >
          <FormItem
            className='pay-form-item'
            name={['hourTemplates', `${record.id}`, 'otPayRate']}
            initialValue={otPayRate}
            rules={[
              {
                required: true,
                message: 'Please Enter Pay (OT)',
              },
              {
                validator: async (_, value) => {
                  if (Number(value) >= MIN_PAY_RATE) {
                    const hourTemplates = form.getFieldValue('hourTemplates');
                    if (!isNil(hourTemplates[record.id].otChargeRate)) {
                      const condition =
                        hourTemplates[record.id].otChargeRate > value;
                      const errors = [
                        'Value must be greater than Pay rate (OT)',
                      ];

                      validateField({
                        condition,
                        field: 'otChargeRate',
                        errors,
                        record,
                      });
                    }

                    if (!isNil(hourTemplates[record.id].payRate)) {
                      if (Number(value) <= hourTemplates[record.id].payRate) {
                        return Promise.reject(
                          new Error('Pay (OT) must greater than Pay Rate'),
                        );
                      }
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(ERROR_PAY_RATE));
                },
              },
            ]}
          >
            <InputNumber
              className='pay-form-item-input'
              placeholder={`${currency}00.00`}
              formatter={formatNumber}
              onKeyDown={handleKeyDown}
            />
          </FormItem>
        </Tooltip>
      ),
    },
    {
      title: 'Charge (OT)',
      dataIndex: 'otChargeRate',
      width: '13%',
      render: (otChargeRate, record) => (
        <Tooltip
          placement='topLeft'
          title={renderTitle({
            type: FORMAT_TYPE.currencyUnit,
            value: hourTemplatesWatching?.[record.id]?.otChargeRate,
          })}
        >
          <FormItem
            className='pay-form-item'
            initialValue={otChargeRate}
            name={['hourTemplates', `${record.id}`, 'otChargeRate']}
            rules={[
              {
                required: true,
                message: 'Please Enter Charge (OT)',
              },
              {
                validator: async (_, value) => {
                  const otPayRate = form.getFieldValue([
                    'hourTemplates',
                    `${record.id}`,
                    'otPayRate',
                  ]);
                  if (value > otPayRate) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Value must be greater than Pay (OT)'),
                  );
                },
              },
            ]}
          >
            <InputNumber
              className='pay-form-item-input'
              placeholder={`${currency}00.00`}
              formatter={formatNumber}
              onKeyDown={handleKeyDown}
            />
          </FormItem>
        </Tooltip>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '10%',
      render: (_, record) => (
        <div className='actions-btn'>
          <Popconfirm
            placement='top'
            title='Are you sure to delete?'
            onConfirm={() => handleDeleteRow(record.id)}
          >
            <XIconBlack />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return columns;
};

export default templateTableColumns;
