import request from '@/request';

const filterEmployee = (employees) =>
  employees.filter((employee) => employee.id || employee.dateSelect !== 'off');

const editRotaWeekPlan = async ({ id, values }) => {
  const endpoint = `/rotas/rota_week_plans/${id}`;
  const parseValue = values.employeesAttributes.map(
    ({ employeesRotaShiftPlansAttributes, ...rest }) => ({
      ...rest,
      employeesRotaShiftPlansAttributes: filterEmployee(
        employeesRotaShiftPlansAttributes,
      ),
    }),
  );

  const resData = await request.patch(endpoint, {
    rota_week_plans: {
      ...values,
      employeesAttributes: parseValue,
    },
  });

  return resData;
};

export default editRotaWeekPlan;
