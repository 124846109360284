import React, { useEffect, useState } from 'react';

import { Collapse, Form } from 'antd';
import { get, head } from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { STATUES_ACTIONS } from '@/pages/PayTemplate/Payroll/constants';
import DataProcessing from '@/pages/PayTemplate/Payroll/utils/dataProcessing';

import useActionMutations from './hooks/useActionMutations';
import ListTimecards from './ListTimecards';
import TimecardFooter from './TimecardFooter';
import TotalHeader from './TotalHeader';

const { Panel } = Collapse;

const renderTotal = ({
  status,
  panelInfo,
  statusColor,
  updateStatusMutation,
  isUpdateStatusLoading,
}) => (
  <TotalHeader
    isPayroll
    status={status}
    panelInfo={panelInfo}
    statusColor={statusColor}
    updateStatusMutation={updateStatusMutation}
    isUpdateStatusLoading={isUpdateStatusLoading}
  />
);

const PanelItem = ({
  panelInfo,
  status,
  statusColor,
  activePanels,
  setActivePanels,
  isHourlyTemplate,
}) => {
  const [form] = Form.useForm();
  const { id: timesheetId } = useParams();

  const [isEdit, setIsEdit] = useState(false);

  const hoursPerDayData = get(panelInfo, 'payrollTemplateHours', []);

  const seekerName = get(head(hoursPerDayData), 'seekerName', null);
  const templateName = get(head(hoursPerDayData), 'payrollTemplateName', null);
  const panelTitle = `${seekerName} - ${templateName}`;

  const actionMutations = useActionMutations({ form, timesheetId, setIsEdit });

  const initialValues = DataProcessing.parseDataToForm(panelInfo);

  const handleExpandPanel = (keys) => setActivePanels(keys);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);
  return (
    <Form form={form} initialValues={initialValues}>
      <Collapse
        expandIconPosition='right'
        ghost={isHourlyTemplate}
        activeKey={activePanels}
        onChange={handleExpandPanel}
      >
        <Panel
          header={panelTitle}
          key={panelInfo.timesheetDetailId}
          extra={renderTotal({
            status,
            statusColor,
            panelInfo,
            updateStatusMutation:
              actionMutations[STATUES_ACTIONS.finaliseAtPanel.key].action,
            isUpdateStatusLoading:
              actionMutations[STATUES_ACTIONS.finaliseAtPanel.key].loading,
          })}
        >
          <div className='panel-container'>
            <div className='panel-detail'>
              <ListTimecards
                isEdit={isEdit}
                panelInfo={panelInfo}
                isHourlyTemplate={isHourlyTemplate}
              />
            </div>
            <TimecardFooter
              status={status}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              panelId={panelInfo.timesheetDetailId}
              actionMutations={actionMutations}
              isHourlyTemplate={isHourlyTemplate}
            />
          </div>
        </Panel>
      </Collapse>
    </Form>
  );
};

export default PanelItem;

PanelItem.propTypes = {
  status: PropTypes.string,
  panelInfo: PropTypes.object,
  activePanels: PropTypes.array,
  statusColor: PropTypes.string,
  setActivePanels: PropTypes.func,
  isHourlyTemplate: PropTypes.bool,
};

PanelItem.defaultProps = {
  status: '',
  panelInfo: {},
  activePanels: [],
  statusColor: '',
  isHourlyTemplate: false,
  setActivePanels: () => {},
};
