import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { Button, Input, ModalConfirm } from '@/components';
import { REGEX_EMAIL, REGEX_PHONE_NUMBER } from '@/constants';
import { isRequireKeysValueTruthy, isValidAllEmail } from '@/utils/array';
import useModal from '@/utils/useModal';

import { EXCEPTION_MESSAGE } from './constants';

const ContactAction = ({ fieldName, type, selectedContactType, isHidden }) => {
  const formInstance = Form.useFormInstance();
  const fieldValue = Form.useWatch(fieldName, formInstance) ?? [];

  const [displayEdit, showEdit, hiddenEdit] = useModal();

  const selectedContactIndex = Form.useWatch(
    `${type.fieldName}Index`,
    formInstance,
  );

  const handleAddMore = () => {
    fieldValue.push({
      name: null,
      email: null,
      jobTitle: null,
      department: null,
      phone: null,
    });

    formInstance.setFieldsValue({
      [fieldName]: fieldValue,
      [`${type.fieldName}Index`]: fieldValue.length ? fieldValue.length - 1 : 0,
    });
  };

  const setSelectedContactIndex = (index) => {
    formInstance.setFields([
      {
        name: `${type.fieldName}Index`,
        value: index,
      },
    ]);
  };

  const isHiddenArrowIndex = (index) => selectedContactIndex !== index;

  const rules = (/** @type {number} */ index) => [
    {
      validator: () => {
        const { name, phone, email } = fieldValue[index];
        if (!name || !email) {
          return Promise.reject(EXCEPTION_MESSAGE.missFields);
        }
        if (
          !REGEX_EMAIL.test(email) ||
          (phone && !REGEX_PHONE_NUMBER.test(phone))
        ) {
          return Promise.reject(EXCEPTION_MESSAGE.invalidValues);
        }
        return Promise.resolve();
      },
    },
  ];

  const removeContact = (/** @type {number} */ index) => {
    showEdit();
    setSelectedContactIndex(index);
  };

  const onClickYes = () => {
    fieldValue.splice(selectedContactIndex, 1);
    setSelectedContactIndex(null);
    hiddenEdit();

    const updatedObject = {
      name: fieldName,
      value: fieldValue,
    };
    if (
      isEmpty(fieldValue) ||
      isRequireKeysValueTruthy(fieldValue, ['name', 'email']) ||
      isValidAllEmail(fieldValue)
    ) {
      updatedObject.errors = [];
    }

    formInstance.setFields([updatedObject]);
  };

  return (
    <>
      <Form.List name={fieldName} initialValue={[]}>
        {(fields) =>
          fields.map((_field, index) => (
            <div hidden={type.fieldName !== selectedContactType?.fieldName}>
              <div className='flex' key={`${fieldName}-${_field.key}contact`}>
                <Form.Item
                  name={[index, 'contact']}
                  className='form-item form-item--full-width'
                  hidden={isHidden(type)}
                  rules={rules(index)}
                >
                  <Input
                    className='contact-input'
                    readOnly
                    onClick={() => setSelectedContactIndex(index)}
                  />
                </Form.Item>
                <div className='remove-icon'>
                  <XIconBlack onClick={() => removeContact(index)} />
                </div>
                <div>
                  <RightOutlined
                    className='right-arrow'
                    hidden={isHidden(type) || isHiddenArrowIndex(index)}
                  />
                </div>
              </div>
            </div>
          ))
        }
      </Form.List>

      <Button
        key={fieldName}
        className={classNames('yellow-button button__add-more', {
          hide: isHidden(type),
        })}
        onClick={() => handleAddMore()}
      >
        Add Another
      </Button>
      <ModalConfirm
        title='Remove Contact?'
        visible={displayEdit}
        onClickYes={onClickYes}
        onClickNo={hiddenEdit}
      >
        Are you sure you want to remove this contact?
      </ModalConfirm>
    </>
  );
};

ContactAction.propTypes = {
  fieldName: PropTypes.string,
  type: PropTypes.object,
  selectedContactType: PropTypes.object,
  isHidden: PropTypes.func,
};

export default ContactAction;
