/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

const CSV = React.forwardRef(({ filename, separator, data, ...props }, ref) => (
  <CSVLink
    ref={ref}
    separator={separator}
    filename={filename}
    headers={/^(.*)$/m.exec(data)[0].split(',')}
    data={`${data.slice(data.indexOf('\n') + 1)}`}
    {...props}
  />
));

CSV.propTypes = {
  filename: PropTypes.string,
  separator: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};

CSV.defaultProps = {
  separator: ',',
};

export default CSV;
