import _ from 'lodash';

import { convertToISOString } from '@/utils/date';

const formattedTimecardsPayload = (timecardsAttributes) => {
  const formattedToRes = _.map(timecardsAttributes, (timecard) => {
    const { id, schedule } = _.get(timecard, 'attributes', {});
    const { checkin, checkout, break: breakValue } = schedule;
    const formattedCheckIn = {
      checktype: 'checkin',
      createdAt: convertToISOString(checkin),
    };
    const formattedCheckOut = {
      checktype: 'checkout',
      createdAt: convertToISOString(checkout),
    };
    const checkinCheckoutsAttributes = [formattedCheckIn, formattedCheckOut];
    return {
      id,
      break: breakValue,
      checkinCheckoutsAttributes,
    };
  });
  return formattedToRes;
};

export { formattedTimecardsPayload };
