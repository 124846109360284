import React, { useState } from 'react';

import uniqid from 'uniqid';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { Form, Radio, message } from 'antd';

import useModal from '@/utils/useModal';
import { slugify } from '@/utils/slugify';
import { DEBOUNCE_WAIT } from '@/constants';
import { Button, Input, ModalDocFields } from '@/components';

export function AddAnotherButton({ list, name, type }) {
  const [visible, showModal, hideModal] = useModal();
  const [selectedCertificate, setSelectedCertificate] = useState();
  const [blank, setBlank] = useState({
    id: uniqid(),
  });

  const formInstance = Form.useFormInstance();
  const prevValues = Form.useWatch(name, formInstance) ?? [];

  const handleHideModal = () => {
    setBlank({
      id: uniqid(),
    });

    setSelectedCertificate(null);
    hideModal();
  };

  const handleAddCertificate = debounce(() => {
    if (!selectedCertificate) {
      return undefined;
    }

    if (selectedCertificate === 'blank' && !blank.name) {
      return message.success('Blank box must have value!');
    }

    const certificate = list[selectedCertificate] || blank;

    const value = prevValues.find((el) => el.slug === certificate.slug);

    if (value) {
      return message.success('Already exist');
    }

    formInstance.setFieldsValue({
      [name]: [
        ...prevValues,
        {
          ...certificate,
          id: undefined,
          checked: false,
          attachment: undefined,
        },
      ],
    });

    handleHideModal();
  }, DEBOUNCE_WAIT);

  const handleChangeBlank = (event) => {
    const { target } = event;

    setBlank((prev) => {
      return {
        ...prev,
        type,
        name: target.value,
        slug: slugify(target.value),
      };
    });
  };

  const handleChangeCertificate = (event) => {
    const { target } = event;

    setSelectedCertificate(target.value);
  };

  return (
    <>
      <Button
        onClick={showModal}
        className='yellow-button add-another-document'
      >
        Add Another
      </Button>
      <ModalDocFields
        visible={visible}
        isWarning={false}
        onClickNo={handleHideModal}
        className='modal-for-types'
        onClickYes={handleAddCertificate}
      >
        <Radio.Group
          value={selectedCertificate}
          onChange={handleChangeCertificate}
          className={classNames('radio-checkbox', 'types')}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {Object.values(list).map((item) => (
              <Radio key={item.slug} value={item.slug}>
                {item.name}
              </Radio>
            ))}
            <Radio value='blank' className='blank-box'>
              <Input
                value={blank.name}
                placeholder='Blank Box'
                onChange={handleChangeBlank}
              />
            </Radio>
          </div>
        </Radio.Group>
      </ModalDocFields>
    </>
  );
}
