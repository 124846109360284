import React from 'react';

import { Form, Typography } from 'antd';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import { FormItem, Input } from '@/components';
import { rulesEmail, rulesPhone, rulesTextInput } from '@/utils/rulesInput';

const { Text } = Typography;

const ContactDetails = ({ type, isHidden }) => {
  const formInstance = Form.useFormInstance();
  const selectedContactIndex = Form.useWatch(
    `${type.fieldName}Index`,
    formInstance,
  );

  const onChangeInput = (
    /** @type {string} */ fieldName,
    /** @type {number} */ index,
    inputName = '',
    inputValue = '',
  ) => {
    formInstance.setFields([
      {
        name: fieldName,
        errors: [],
      },
      {
        name: [fieldName, index, 'contact'],
        ...(inputName === 'name' && { value: inputValue }),
        errors: [],
      },
    ]);
  };

  return (
    <div
      className='input-group input-group__border'
      hidden={isHidden(type) || isNil(selectedContactIndex)}
    >
      <Form.List name={type.fieldName} initialValue={[]}>
        {(fields) =>
          fields.map((_field, index) => (
            <>
              <FormItem
                name={[index, 'name']}
                className='form-item form-item--full-width'
                hidden={isHidden(type) || selectedContactIndex !== index}
              >
                <Text className='center-wrapper-label'>
                  {formInstance.getFieldValue(type.fieldName)[index]?.name ??
                    ''}
                </Text>
              </FormItem>

              <div
                className='center-wrapper-center'
                hidden={isHidden(type) || selectedContactIndex !== index}
              >
                <div className='center-wrapper-center--left'>
                  <FormItem
                    name={[index, 'name']}
                    className='form-item form-item--full-width'
                    label='Name'
                    required
                    hidden={isHidden(type) || selectedContactIndex !== index}
                    rules={rulesTextInput('Name')}
                  >
                    <Input
                      placeholder='Name'
                      onChange={(e) =>
                        onChangeInput(
                          type.fieldName,
                          index,
                          'name',
                          e.target.value,
                        )
                      }
                    />
                  </FormItem>
                  <FormItem
                    name={[index, 'jobTitle']}
                    className='form-item form-item--full-width'
                    label='Job Title'
                    hidden={isHidden(type) || selectedContactIndex !== index}
                  >
                    <Input placeholder='Job Title' />
                  </FormItem>
                  <FormItem
                    name={[index, 'phone']}
                    className='form-item form-item--full-width'
                    label='Phone number'
                    hidden={isHidden(type) || selectedContactIndex !== index}
                    rules={rulesPhone(false)}
                  >
                    <Input placeholder='Phone' />
                  </FormItem>
                </div>
                <div className='center-wrapper-center--right'>
                  <FormItem
                    name={[index, 'email']}
                    className='form-item form-item--full-width'
                    label='Email'
                    required
                    rules={rulesEmail()}
                    hidden={isHidden(type) || selectedContactIndex !== index}
                  >
                    <Input
                      placeholder='Email'
                      onChange={() => onChangeInput(type.fieldName, index)}
                    />
                  </FormItem>
                  <FormItem
                    name={[index, 'department']}
                    className='form-item form-item--full-width'
                    label='Department'
                    hidden={isHidden(type) || selectedContactIndex !== index}
                  >
                    <Input placeholder='Department' />
                  </FormItem>
                </div>
              </div>
            </>
          ))
        }
      </Form.List>
    </div>
  );
};

ContactDetails.propTypes = {
  type: PropTypes.object,
  isHidden: PropTypes.func,
};

export default ContactDetails;
