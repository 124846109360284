import request from '@/request';
import {
  FormatShowStrategy,
  Normalize,
  FormatIndexStrategy,
} from '@/utils/normalizeResponse';

export async function getUsers(page, filter, order) {
  const res = await request.get('/omniusers/users', {
    params: {
      order,
      page,
      filter,
    },
  });

  const normalize = new Normalize();
  const strategy = new FormatIndexStrategy(res);
  normalize.setStrategy(strategy);
  return normalize.formatResponse();
}

export async function getUserById(userId) {
  const res = await request.get(`/omniusers/users/${userId}`);
  const normalizeJson = new Normalize();
  const strategy = new FormatShowStrategy(res);
  normalizeJson.setStrategy(strategy);
  const data = normalizeJson.formatResponse();
  const userInfo = data?.data;

  return {
    ...userInfo,
  };
}
