import React from 'react';

import { Form, message } from 'antd';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Button, GeneralTemplate, ModalConfirm } from '@/components';
import { create } from '@/services/cost';
import useModal from '@/utils/useModal';

import CostDetail from '../components/CostDetail';
import '../styles.scss';

const breadCrumb = [
  { item: 'Timesheets', to: '/timesheets' },
  { item: 'Costs', to: '/costs' },
  { item: 'Create New Cost', to: '/create-cost' },
];

const CreateCost = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [displayModal, showModal, hiddenModal] = useModal();
  const {
    mutate: createCost,
    isLoading,
    isSuccess,
  } = useMutation(async () => create(form.getFieldsValue()), {
    onSuccess: () => history.push('/costs'),
    onError: (err) => {
      if (err.status && err.message) {
        hiddenModal();
        window.scrollTo(0, 0);
      }
      message.error(err.message ?? 'Can not create cost');
    },
  });

  const handleSubmit = () => {
    createCost(form.getFieldsValue());
  };

  const handleGoBack = () => history.goBack();

  return (
    <GeneralTemplate
      mainItem='Timesheets'
      data={breadCrumb}
      headerText='Create New Costs'
      buttonContent='Create Cost'
      buttonLink='/create-cost'
      subTemplateClassName='cost-container'
    >
      <Form
        form={form}
        className='form-cost'
        onFinish={showModal}
        scrollToFirstError={{
          skipOverflowHiddenElements: true,
          block: 'center',
        }}
      >
        <CostDetail />

        <div className='actions'>
          <Button className='black-button input-height'>Cancel</Button>
          <Button className='yellow-button input-height' htmlType='submit'>
            Allocate Cost
          </Button>
        </div>
      </Form>
      <ModalConfirm
        title='Create New Cost?'
        titleSuccess='Cost created successfully'
        visible={displayModal}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClickYes={isSuccess ? handleGoBack : handleSubmit}
        onClickNo={hiddenModal}
      >
        Are you sure you want to create new Cost?
      </ModalConfirm>
    </GeneralTemplate>
  );
};

export default CreateCost;
