/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { AutoComplete as AntdAutoComplete } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * @param {import('antd').AutoCompleteProps & {loading?: boolean}} props
 * @return {JSX.Element}
 */
const AutoComplete = ({ className, dropdownClassName, ...props }) => (
  <AntdAutoComplete
    {...props}
    className={classNames('auto-complete-container input-height', className)}
    dropdownClassName={classNames('complete-dropdown', dropdownClassName)}
  />
);

AutoComplete.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
};

AutoComplete.defaultProps = {
  className: '',
  dropdownClassName: '',
};

export default AutoComplete;
