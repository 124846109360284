import request from '@/request';
import Formatter from '@/utils/Formatter';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';
import { formatResponse } from '@/utils/thunkUtil';

const getAll = async ({ employerId, locationId, skillId, page }) => {
  const endpoint = '/rotas/rotas';

  const res = await request.get(endpoint, {
    params: {
      filter: {
        employerId,
        employerLocationId: locationId,
        skillId,
      },
      page,
    },
  });

  const { data = [] } = normalizeIndexResponse(res);
  const convertData = data.map((item) => {
    const { contactType = {}, id, ...rest } = item;

    return {
      ...rest,
      key: id,
      contactType: contactType.name,
    };
  });

  const getAllRota = formatResponse(res, { endpoint });

  const pagination = Formatter.mergeMeta({}, endpoint, getAllRota.meta)[
    endpoint
  ];

  return {
    ...pagination,
    data: convertData,
  };
};

export default getAll;
