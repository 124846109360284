import React from 'react';

import PropTypes from 'prop-types';

import { Button, FormItem, Textarea } from '@/components';

const BodyPopover = ({ namePath, handleEdit, isLoading }) => (
  <div className='comment-popover-body-container'>
    <FormItem name={[...namePath, 'commentId']} hidden />
    <FormItem name={[...namePath, 'comment', 'assignedScheduleId']} hidden />
    <FormItem name={[...namePath, 'comment', 'comment']}>
      <Textarea
        placeholder='Example: Worker sent sick note to confirm'
        disabled={isLoading}
      />
    </FormItem>
    <Button
      className='yellow-button custom-button'
      loading={isLoading}
      onClick={handleEdit}
    >
      Edit
    </Button>
  </div>
);

export default BodyPopover;

BodyPopover.propTypes = {
  namePath: PropTypes.array,
  handleEdit: PropTypes.func,
  isLoading: PropTypes.bool,
};

BodyPopover.defaultProps = {
  handleEdit: () => {},
};
