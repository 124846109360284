import React from 'react';

import { Form } from 'antd';
import { useParams } from 'react-router-dom';

import { FormItem, Select, Textarea, DatePicker, Input } from '@/components';
import {
  useLocations,
  useManagersOfLead,
  useStatusOfLead,
} from '@/hooks/dropdowns';

const RightSection = () => {
  const { leadId: leadIdParam } = useParams();
  const form = Form.useFormInstance();
  const note = Form.useWatch('note', form);

  const { listLocations } = useLocations();
  const { data: managers } = useManagersOfLead();
  const { data: statuses } = useStatusOfLead();

  const { leadId } = useParams();

  return (
    <div className='create-lead-input-fields--right'>
      <div className='internal-note'>
        <div className='internal-note--label'>Internal note:</div>
        <FormItem name='note' className='form-item create-lead-form-item'>
          <Textarea rows={5} placeholder='Note' />
        </FormItem>
      </div>

      <FormItem
        name='status'
        className='form-item create-lead-form-item'
        label='Status'
        rules={[
          {
            required: true,
            message: 'Please enter Status',
          },
        ]}
        required
      >
        <Select
          options={statuses}
          placeholder='Status'
          disabled={!note && leadIdParam}
        />
      </FormItem>
      <FormItem
        name='allocatedAccountManager'
        className='form-item create-lead-form-item'
        label='Allocated Account Manager'
        rules={[
          {
            required: true,
            message: 'Please enter Allocated Account Manager',
          },
        ]}
        required
      >
        <Select options={managers} placeholder='Account Manage' />
      </FormItem>

      <FormItem
        label='Registration Date'
        name='registrationDate'
        className='form-item registration-form-item'
      >
        <DatePicker
          name='registrationDate'
          placeholder='Select Date'
          disabled
          showClear={false}
        />
      </FormItem>

      <FormItem
        className='form-item create-lead-form-item'
        name='clientNumber'
        label='Client Number'
        hidden={!leadId}
      >
        <Input
          placeholder='Client Number'
          disabled
          defaultValue={leadId ?? null}
        />
      </FormItem>

      <FormItem
        name='localOffice'
        className='form-item create-lead-form-item'
        label='Choose your local office'
        rules={[
          {
            required: true,
            message: 'Please enter Choose your local office',
          },
        ]}
        required
      >
        <Select
          options={listLocations}
          placeholder='Choose your local office'
        />
      </FormItem>
    </div>
  );
};
export default RightSection;
