import request from '@/request';

const getListEmailAndName = async (searchTerm) => {
  const res = await request.get('/providers/employers/search_terms', {
    params: {
      filter: {
        searchTerm,
      },
    },
  });
  const listItems = res.data.data;
  const listItemsToObjConverted = listItems.map((item) => ({
    value: item,
    label: item,
  }));
  return listItemsToObjConverted;
};

export default getListEmailAndName;
