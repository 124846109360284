import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useToggle } from 'usehooks-ts';

import { ReactComponent as ClosedIcon } from '@/assets/icons/closedIcon.svg';
import { FormItem, Input, InputNumber, Modal, Select } from '@/components';
import { useRoleItems } from '@/hooks/dropdowns';

import { formatNumber, handleChangeHoursRates } from '../utils/handle';

const HoursSectionItem = ({ name, remove }) => {
  const formInstance = Form.useFormInstance();
  const [visibleDeleteModal, toggleDeleteModal] = useToggle();
  const { data: roles } = useRoleItems();

  const handleCalHrsRate = () =>
    handleChangeHoursRates({
      formInstance,
      name: ['labour', name],
    });

  return (
    <div className='left-section card'>
      <FormItem name={[name, 'role']} label='Role' required>
        <Select size='large' required options={roles} />
      </FormItem>
      <FormItem name={[name, 'templateName']} label='Template' required>
        <Input placeholder='Template' />
      </FormItem>
      <FormItem name={[name, 'hourTemplateName']} label='Pay Template' required>
        <Input placeholder='Pay Template' />
      </FormItem>
      <FormItem
        name={[name, 'hours']}
        label='Hours'
        required
        className='small-width'
      >
        <InputNumber
          placeholder='Hours'
          min={0}
          formatter={(numberValue, { userTyping }) =>
            formatNumber({ numberValue, userTyping })
          }
          onChange={handleCalHrsRate}
        />
      </FormItem>
      <FormItem
        name={[name, 'omniRate']}
        label='Rate'
        required
        className='small-width'
      >
        <InputNumber
          placeholder='Rate'
          min={0}
          formatter={(numberValue, { userTyping }) =>
            formatNumber({ numberValue, userTyping })
          }
          onChange={handleCalHrsRate}
        />
      </FormItem>
      <div className='exit-box'>
        <ClosedIcon onClick={toggleDeleteModal} />
      </div>
      <Modal
        visible={visibleDeleteModal}
        onClickNo={toggleDeleteModal}
        onClickYes={() => remove(name)}
      >
        Are you sure to want to delete this section ?
      </Modal>
    </div>
  );
};

export default HoursSectionItem;

HoursSectionItem.propTypes = {
  name: PropTypes.string,
  remove: PropTypes.func,
};

HoursSectionItem.defaultProps = {
  name: '',
  remove: () => {},
};
