import React from 'react';

import PropTypes from 'prop-types';

import { Button } from '@/components';
import './styles.scss';
import { useTenantContext } from '../../../../TenantWrapper';

const RecruitmentWelcome = ({ nextStep, ...props }) => {
  const tenant = useTenantContext();

  return (
    <div className='recruitment-welcome'>
      <div className='title welcome'>
        Congratulations, you’re through to the next stage
      </div>
      <div className='summary'>
        <p>
          Congratulations, your initial application to join {tenant.name} and
          begin working with us has been successful.
        </p>
        <p>
          In order to finalise your application we now need you to complete the
          following onboarding form in full. This is required for employment
          purposes and will speed up the interview process stage.
        </p>
        <p>
          At the end of this onboarding form you will be asked to arrange an
          interview on a date and time that works best for you. This interview
          will be conducted by one of our {tenant.name} recruitment team and
          will be the final step in the recruitment process.
        </p>
        <p>
          Please fill in all information fully and truthfully as this will help
          to speed up the recruitment process and will enable you to get working
          with {tenant.name} quicker.
        </p>
      </div>
      <Button onClick={nextStep} className='shared-button confirm-button'>
        Start application
      </Button>
    </div>
  );
};

RecruitmentWelcome.propTypes = {
  nextStep: PropTypes.func,
};

export default RecruitmentWelcome;
