/* eslint-disable no-underscore-dangle */
import React, { useRef, useState } from 'react';

import { Button, Form, message, Modal as AntdModal, Tag } from 'antd';

import { ReactComponent as RequiredIcon } from '@/assets/icons/requiredIcon.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/warningIcon.svg';
import { ReactComponent as XIconBlack } from '@/assets/icons/XiconBlack.svg';
import { FormItem, Select, Modal } from '@/components';
import { languages } from '@/constants';
import useLanguageLevel from '@/hooks/dropdowns/useLanguageLevel';

import { levelLanguage } from './constants';

const messages = {
  langContained: 'Language already existed',
  languageAndCompetencyNull: 'Please select both language and competency',
};

const resetLanguage = {
  currentLanguage: undefined,
  currentCompetency: undefined,
  currentLanguageId: undefined,
};

const Languages = () => {
  const [isShowModal, setShowModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deletingKey, setDeletingKey] = useState();

  const ref = useRef(null);

  const { listLanguageLevel } = useLanguageLevel();
  const form = Form.useFormInstance();
  const languageId = Form.useWatch('currentLanguageId');

  const listLanguages = Object.values(
    Form.useWatch('vacancyApplicantLanguagesAttributes', form) ?? {},
  )
    .filter((item) => item && !item?._destroy)
    .map((item, index) => ({ ...item, key: index }));

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    form.setFieldsValue({ currentLanguageId: undefined });
    setShowModal(false);
  };
  const handleShowEditModal = (
    /** @type {{ name: string; level: number; }} */ data,
    /** @type {number} */ index,
  ) => {
    form.setFieldsValue({
      currentLanguageId: `${index}`,
      currentLanguage: data?.name,
      currentCompetency: data?.level,
    });
    handleShowModal();
  };

  const handleDeleteLanguage = (/** @type {number} */ key) => {
    const { vacancyApplicantLanguagesAttributes } = form.getFieldsValue();

    const deletedItem = vacancyApplicantLanguagesAttributes[deletingKey];
    form.setFieldsValue({
      vacancyApplicantLanguagesAttributes: {
        ...vacancyApplicantLanguagesAttributes,
        [deletingKey]: {
          ...deletedItem,
          _destroy: true,
        },
      },
      ...resetLanguage,
    });

    setIsDeleteModal(false);
  };

  const addLanguage = () => {
    const {
      currentLanguage,
      currentCompetency,
      vacancyApplicantLanguagesAttributes,
    } = form.getFieldsValue();

    const newValue = Object.values(vacancyApplicantLanguagesAttributes);

    newValue.push({ name: currentLanguage, level: currentCompetency });

    return form.setFieldsValue({
      vacancyApplicantLanguagesAttributes: {
        ...newValue,
      },
      ...resetLanguage,
    });
  };

  const editLanguage = () => {
    const { currentLanguage, currentCompetency, currentLanguageId } =
      form.getFieldsValue();

    form.setFieldsValue({
      vacancyApplicantLanguagesAttributes: {
        [currentLanguageId]: {
          name: currentLanguage,
          level: currentCompetency,
        },
      },
      ...resetLanguage,
    });
  };

  const handleLanguage = () => {
    const {
      currentLanguage,
      currentCompetency,
      currentLanguageId,
      vacancyApplicantLanguagesAttributes,
    } = form.getFieldsValue();

    if (!currentLanguage) {
      return message.warning('Please add language');
    }

    if (!currentCompetency) {
      return message.warning('Please add competency');
    }

    const isLanguageAndCompetencyNull = !currentLanguage && !currentCompetency;

    if (isLanguageAndCompetencyNull) {
      return message.error(messages.languageAndCompetencyNull);
    }

    const isLanguageExists =
      Object.values(vacancyApplicantLanguagesAttributes)
        .filter((item) => !item?._destroy)
        .map((item) => item?.name)
        .includes(currentLanguage) &&
      currentLanguage !==
        vacancyApplicantLanguagesAttributes[currentLanguageId]?.name;

    if (isLanguageExists) {
      return message.warning(messages.langContained);
    }

    if (currentLanguageId) {
      editLanguage();
    } else addLanguage();

    return setShowModal(false);
  };

  return (
    <div className='seeker-languages'>
      <div className='language-label'>
        Languages
        <RequiredIcon />
      </div>

      <div className='wrapper-language'>
        <div ref={ref} className='add-language'>
          <Form.List
            rules={[
              {
                validator: async () => {
                  if (listLanguages.length === 0) {
                    throw new Error('Please add at least 1 language');
                  }
                },
                message: 'Please add at least 1 language',
              },
            ]}
            name='vacancyApplicantLanguagesAttributes'
          >
            {(fields, _, { errors }) => {
              if (errors.length && ref.current) {
                ref.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }
              return (
                <>
                  {fields.map((field) => (
                    <React.Fragment key={field?.key}>
                      <FormItem name={[field?.name, 'id']} />
                      <FormItem name={[field?.name, 'name']} />
                      <FormItem name={[field?.name, 'level']} />
                      <FormItem name={[field?.name, '_destroy']} />
                    </React.Fragment>
                  ))}
                  <Form.ErrorList errors={errors} />
                </>
              );
            }}
          </Form.List>
          <Button className='yellow-button ok-button' onClick={handleShowModal}>
            Add Language
          </Button>
        </div>

        <div className='list-language'>
          {listLanguages?.map((item, index) => (
            <Tag
              closable
              closeIcon={<XIconBlack />}
              key={item?.key}
              className='language-tag'
              onClick={() => handleShowEditModal(item, index)}
              onClose={() => {
                setDeletingKey(item?.key);
                setIsDeleteModal(true);
              }}
            >
              <span className='language-text'>
                {`${item?.name} - ${levelLanguage[item?.level]}`}
              </span>
            </Tag>
          ))}
        </div>
      </div>
      <Modal
        onClickYes={handleDeleteLanguage}
        onClickNo={() => setIsDeleteModal(false)}
        visible={isDeleteModal}
        title='Delete Language?'
      >
        Are you sure you want to delete this language?
      </Modal>
      <AntdModal
        centered
        closable={false}
        visible={isShowModal}
        onCancel={handleCloseModal}
        wrapClassName='modal-languages'
        footer={null}
      >
        <div className='modal-wrapper'>
          <WarningIcon className='warning-icon' />
          <h1>{!languageId ? 'Add Language' : 'Edit Language'}</h1>
          <div className='modal-desc'>
            <FormItem name='currentLanguageId' />
            <FormItem name='currentLanguage'>
              <Select
                className='options'
                options={languages}
                placeholder='Select Language'
                required
              />
            </FormItem>
            <FormItem name='currentCompetency'>
              <Select
                className='options'
                options={listLanguageLevel}
                placeholder='Select Competency'
                required
              />
            </FormItem>
          </div>
        </div>
        <div className='modal-footer'>
          <Button className='btn-save' onClick={handleLanguage}>
            {!languageId ? 'OK' : 'Edit'}
          </Button>
        </div>
      </AntdModal>
    </div>
  );
};

export default Languages;
