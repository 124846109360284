import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { FormHeader } from '@/components';
import FormLogo from '@/components/FormLogo';
import GuestFormCard from '@/components/GuestFormCard';
import { useTenantContext } from '../../TenantWrapper';

import './styles.scss';

const ThankPage = () => {
  const { state = {} } = useLocation();
  const { id, candidateId } = state;
  const history = useHistory();
  const tenant = useTenantContext();

  if (!id && !candidateId) {
    history.goBack();
  }

  const thanksTextApplication = () => (
    <p className='sub-title'>
      Thank you for submitting an application with {tenant.name}. The team will
      be reviewing your application and if successful you should hear from us
      within 7 working days
    </p>
  );

  const thanksTextCandidate = () => (
    <p className='sub-title'>
      Thank you for submitting your form. Don&apos;t forget to book your{' '}
      <a
        target='_blank'
        className='link-style'
        href={tenant.contact.interviewUrl}
        rel='noreferrer'
      >
        interview
      </a>
      . We look forward to meeting you soon.
    </p>
  );

  return (
    <div className='thank-page-container'>
      <FormHeader />
      <FormLogo />
      <GuestFormCard>
        <div className='form-content-container'>
          <h2 className='title'>Thank you for Submitting</h2>
          {id ? thanksTextApplication() : thanksTextCandidate()}
        </div>
      </GuestFormCard>
    </div>
  );
};

export default ThankPage;
