/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Checkbox as AntCheckBox } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

/**
 * @typedef {{label: string;}} AdditionalProps
 */

/**
 *
 * @param {import('antd').CheckboxProps & AdditionalProps} props
 * @return {JSX.Element}
 */
const Checkbox = ({ label, className, children, ...props }) => (
  <AntCheckBox className={classNames('checkbox-custom', className)} {...props}>
    {label || children}
  </AntCheckBox>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.any,
};

Checkbox.defaultProps = {
  options: [],
  className: '',
  label: '',
};

export default Checkbox;
