import React from 'react';

import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Button, FormItem, Select } from '@/components';
import {
  useProviders,
  useRoleItems,
  useProviderLocations,
} from '@/hooks/dropdowns';
import { rulesSelect } from '@/utils/rulesInput';
import './styles.scss';

const InputForm = ({ handleClearFilter, handleSearchRota, isShowStatus }) => {
  const listProviders = useProviders();
  const { data: positions } = useRoleItems();
  const providerId = Form.useWatch('provider');
  const { data: locations } = useProviderLocations(providerId);

  const renderInputItems = () => (
    <div className='rota-list-view-inputs'>
      <FormItem
        rules={rulesSelect('Provider')}
        name='provider'
        className='input-item'
        label='Provider'
      >
        <Select
          options={listProviders}
          className='input-height'
          placeholder='Provider'
          name='provider'
          required
        />
      </FormItem>
      <FormItem
        rules={rulesSelect('Location')}
        name='location'
        className='input-item'
        label='Location'
      >
        <Select
          options={locations}
          className='input-height'
          placeholder='Location'
          name='location'
          required
        />
      </FormItem>
      <FormItem
        rules={rulesSelect('Positions')}
        name='positions'
        className='input-item'
        label='Positions'
      >
        <Select
          className='input-height'
          placeholder='Positions'
          options={positions}
          name='positions'
          required
        />
      </FormItem>
      <div
        className='buttons input-item'
        style={{
          display: 'flex',
          gap: 10,
          width: '50%',
          alignItems: 'flex-end',
        }}
      >
        <Button
          className='yellow-button input-height style-btn'
          type='primary'
          onClick={handleSearchRota}
        >
          Search
        </Button>
        <Button
          onClick={handleClearFilter}
          className='filter-button input-height style-btn'
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );

  return <>{renderInputItems()}</>;
};

InputForm.propTypes = {
  handleClearFilter: PropTypes.func,
  handleSearchRota: PropTypes.func,
  isShowStatus: PropTypes.bool,
};

InputForm.defaultProps = {
  handleClearFilter: () => {},
  isShowStatus: false,
};

export default InputForm;
