import React from 'react';

import { Form } from 'antd';

import { CheckboxGroup, FormItem, Input } from '@/components';
import { INDUSTRY } from '@/constants';
import { useIndustryExperiences } from '@/hooks/dropdowns';

const IndustryExperience = () => {
  const formInstance = Form.useFormInstance();
  const industryExperiencesOptions = useIndustryExperiences();

  const industryExperiences = Form.useWatch(
    'industryExperiences',
    formInstance,
  );

  const isIncludesRoleOther = Object.values(industryExperiences ?? {}).includes(
    INDUSTRY.OTHER,
  );

  return (
    <div className='roles-section'>
      <FormItem
        name='industryExperiences'
        label='Your Industry Experience'
        required
        rules={[
          {
            required: true,
            message: 'Please select all that are relevant.',
          },
        ]}
      >
        <CheckboxGroup
          className='check-roles'
          options={industryExperiencesOptions}
        />
      </FormItem>

      <FormItem name='industryExperienceAttributesOld' noStyle />

      {isIncludesRoleOther && (
        <FormItem
          name='industryExperienceValueInput'
          className='text-role-other'
        >
          <Input />
        </FormItem>
      )}
    </div>
  );
};

export default IndustryExperience;
