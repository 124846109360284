import React from 'react';

import { Table } from 'antd';
import PropTypes from 'prop-types';

const columns = [
  {
    title: 'Medical Condition',
    dataIndex: 'medicalCondition',
    width: '25%',
    editable: true,
    render: (text) => <div className='block-box'>{text}</div>,
  },
  {
    title: 'Medication',
    dataIndex: 'medication',
    width: '25%',
    editable: true,
    render: (text) => <div className='block-box'>{text}</div>,
  },
  {
    title: 'Notes',
    dataIndex: 'note',
    width: '25%',
    editable: true,
    render: (text) => <div className='block-box'>{text}</div>,
  },
];

const TableMedical = ({ dataSource }) => (
  <Table
    className='table-medical-conditions'
    dataSource={dataSource}
    columns={columns}
    pagination={{ hideOnSinglePage: true }}
  />
);

TableMedical.propTypes = {
  dataSource: PropTypes.array,
};

export default TableMedical;
