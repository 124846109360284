import { DEFAULT_RATES } from '@/constants';
import { calcPayRateHoliday } from '@/utils';

const PROVIDER_STATUSES = [
  {
    value: 0,
    label: 'Active',
  },
  {
    value: 1,
    label: 'Deactivated',
  },
];

const GROSS_PAY_RATE = 10.0;
const HOLIDAY_PAY_RATE = calcPayRateHoliday({
  days: DEFAULT_RATES.HOLIDAY_DAYS,
  payRate: GROSS_PAY_RATE,
});

const INITIAL_RATE = {
  holidayDays: DEFAULT_RATES.HOLIDAY_DAYS,
  grossPayRate: GROSS_PAY_RATE,
  holidayPayRate: HOLIDAY_PAY_RATE,
  pensionPercent: DEFAULT_RATES.PENSION_PERCENT,
  pensionThreshold: DEFAULT_RATES.PENSION_THRESHOLD,
  apprenticeshipLevyPercent: DEFAULT_RATES.APPRENTICESHIP,
  employersNiPerWeekPercent: DEFAULT_RATES.EMPLOYER_NI_PER_WEEK_PERCENT,
  employersNiHourWeek: DEFAULT_RATES.EMPLOYER_NI_HOURS_WEEK,
  employersNiPerWeekThreshold: DEFAULT_RATES.EMPLOYER_NI_THRESHOLD,
  marginIsCostType: DEFAULT_RATES.MARGIN_COST_TYPE,
  marginPercent: DEFAULT_RATES.MARGIN_PERCENT,
  marginFixed: DEFAULT_RATES.MARGIN_FIXED,
  otherCosts: DEFAULT_RATES.OTHER_COSTS,
};

export { PROVIDER_STATUSES, INITIAL_RATE };
