import request from '@/request';

const endpoint = 'payments/employee_holidays';

const createEmployeeHolidays = async (employeeHolidayAttributes) => {
  const { data } = await request.post(endpoint, {
    employeeHoliday: {
      employeeHolidayAttributes,
    },
  });

  return data?.data ?? {};
};

export default createEmployeeHolidays;
