import React from 'react';

import { Form, Tag } from 'antd';
import classNames from 'classnames';
import { flatten, get, isEmpty, keys, map, values, without } from 'lodash';
import PropTypes from 'prop-types';

/**
 * @typedef {{
 * option: {value: string|number},
 * label: string
 * }} OptionsAtt
 */

/**
 * @typedef {{
 *  options: {[key: string]: OptionsAtt[]},
 *  selectedIds: {[key: string]: number[]},
 *  fieldName?: string,
 *  label: string
 *  }} AdditionalProps
 */

/**
 *
 * @param {AdditionalProps} FilterLabelProps
 */
const FilterLabel = ({ options, selectedIds, label }) => {
  const formInstance = Form.useFormInstance();
  const selectedAllIds = flatten(values(selectedIds));

  const handleRemove = ({ remove, name, key }) => {
    remove(name);

    const selectedOptionByKey = without(
      formInstance.getFieldValue([key]),
      'all',
    );
    formInstance.setFields([{ name: key, value: selectedOptionByKey }]);
  };
  return (
    <div>
      <div className='filter-tag-container'>
        <div hidden={isEmpty(selectedAllIds)} className='filter-title'>
          {label}
        </div>
        {map(keys(selectedIds), (key) => (
          <Form.List name={key}>
            {(fields, { remove }) =>
              map(fields, ({ name, key: tagKey }) => {
                const optionId = formInstance.getFieldValue([key, name]);
                const tagLabel = get(options, [key, optionId, 'label']);
                return (
                  tagLabel && (
                    <Tag
                      key={tagKey}
                      className={classNames('filter-tag', key)}
                      closable
                      onClose={() => handleRemove({ remove, name, key })}
                    >
                      {tagLabel}
                    </Tag>
                  )
                );
              })
            }
          </Form.List>
        ))}
      </div>
    </div>
  );
};

export default FilterLabel;

FilterLabel.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  selectedIds: PropTypes.object,
};

FilterLabel.defaultProps = {
  label: '',
  options: [],
  selectedIds: {},
};
