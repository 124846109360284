import request from '@/request';
import { normalizeIndexResponse } from '@/utils/normalizeResponse';

const styleUniforms = {
  1: 'listShirts',
  2: 'listJeans',
  3: 'listShoes',
};

export const fetchUniforms = async () => {
  const res = await request.get('/options/uniforms');

  const data = normalizeIndexResponse(res);

  const result = data?.data.reduce(
    (re, item) => {
      const styleName = styleUniforms[item.styleId] || 'listAdditionalUniforms';

      re[styleName].push({
        value: item.id,
        label: item.name,
      });

      return re;
    },
    {
      listShoes: [],
      listJeans: [],
      listShirts: [],
      listAdditionalUniforms: [],
    },
  );

  return result;
};
